import { EntityType } from "../../api/document/entities/types";

export class MissingEntityError extends Error {
  constructor(
    readonly type: EntityType,
    readonly uid: string,
  ) {
    super(`Missing ${type} in Object Store`);
  }
}
