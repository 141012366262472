import { UIStateAnalyticProperties } from "../../../common/src/api/document/analytics/analytics";
import { useDocument } from "./hooks/use-document";

export function getUiStateAnalyticProperties(): UIStateAnalyticProperties {
  const doc = useDocument();
  return {
    drawingLayout: doc.uiState.drawingLayout,
    activeflowSystemUid: doc.activeflowSystemUid,
    toolHandlerName: doc.uiState.toolHandlerName,
  };
}
