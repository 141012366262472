import {
  convertMeasurementSystem,
  convertPipeDiameterFromMetric,
  Precision,
  Units,
} from "../../../lib/measurements";
import {
  assertType,
  Choice,
  cloneSimple,
  parseCatalogNumberExact,
  parseCatalogNumberOrMin,
} from "../../../lib/utils";
import { CoreContext } from "../../calculations/types";

import { Color } from "../../../lib/color";
import { Coord } from "../../../lib/coord";
import { isDrainage, isSewer, PipePhysicalMaterial } from "../../config";
import CoreRiser from "../../coreObjects/coreRiser";
import { getTooltip } from "../../tooltips/tooltips";
import {
  DEFAULT_VERTICAL_SYSTEM_NETWORKS,
  FLOW_SYSTEM_TO_CONDUIT_TYPE,
  FlowSystem,
  flowSystemHasVent,
  isNetworkTypeWithMaterial,
  VentilationFlowSystem,
  VentilationNetworkParams,
} from "../flow-systems";
import { getFlowSystem } from "../utils";
import {
  DuctConduit,
  getAvailablePipeMaterials,
  makeDuctConduitSizingFields,
  PipeConduit,
} from "./conduit-entity";
import {
  DuctFitting,
  makeDuctFittingPressureDropFields,
  PipeFitting,
} from "./fitting-entity";
import {
  ChoiceField,
  ColorField,
  FieldType,
  NumberField,
  PropertyField,
  withPropertyTracking,
} from "./property-field";
import { ConnectableEntity, NamedEntity } from "./simple-entities";
import { EntityType } from "./types";
import { getPipeManufacturer, getPipeMaterial } from "./utils";

export interface RiserEntityV1 extends ConnectableEntity, NamedEntity {
  type: EntityType.RISER;
  center: Coord;
  systemUid: string;

  isVent: boolean;
  ventHeightM: number | null;

  diameterMM: number | null;
  maximumVelocityMS: number | null; // null means default
  maximumPressureDropRateKPAM: number | null; // null means default
  material: PipePhysicalMaterial | null;
  color: Color | null;
  temperatureC: number | null;

  // The bottom, and top are the index in reference to ground floor's index
  bottomFloorRef: number | null;
  topFloorRef: number | null;
}

export interface RiserEntityBase extends ConnectableEntity, NamedEntity {
  type: EntityType.RISER;
  center: Coord;
  systemUid: string;

  color: Color | null;

  // The bottom, and top are the index in reference to ground floor's index
  bottomFloorRef: number | null;
  topFloorRef: number | null;
}

export interface PipeRiserEntity extends RiserEntityBase {
  riserType: "pipe";
  riser: PipeRiser;
}

export interface DuctRiserEntity extends RiserEntityBase {
  riserType: "duct";
  riser: DuctRiser;
}

type RiserEntity = PipeRiserEntity | DuctRiserEntity;

export default RiserEntity;

export interface PipeRiser
  extends PipeFitting,
    Omit<PipeConduit, "gradePCT" | "configurationCosmetic" | "network"> {
  isVent: boolean;
  ventHeightM: number | null;
}

export interface DuctRiser extends DuctFitting, Omit<DuctConduit, "network"> {}

export function isDuctRiserEntity(
  entity: RiserEntity,
): entity is DuctRiserEntity {
  return entity.riserType === "duct";
}

export function isPipeRiserEntity(
  entity: RiserEntity,
): entity is PipeRiserEntity {
  return entity.riserType === "pipe";
}

export function makeRiserFields(
  context: CoreContext,
  entity: RiserEntity,
): PropertyField[] {
  const { drawing, catalog } = context;
  const result = fillRiserDefaults(context, entity);

  const entityNameField: PropertyField = {
    property: "entityName",
    title: "Name",
    hasDefault: false,
    isCalculated: false,
    type: FieldType.Text,
    params: null,
    multiFieldId: "entityName",
  };
  const systemUidField = (systems: FlowSystem[]): PropertyField => ({
    property: "systemUid",
    title: "Flow System",
    hasDefault: false,
    isCalculated: false,
    type: FieldType.FlowSystemChoice,
    params: {
      systems,
    },
    multiFieldId: "systemUid",
  });

  const bottomFloor: number | "no bound" =
    entity.bottomFloorRef !== null ? entity.bottomFloorRef : "no bound";
  const topFloor: number | "no bound" =
    entity.topFloorRef !== null ? entity.topFloorRef : "no bound";

  const topFloorRefField: PropertyField = {
    property: "topFloorRef",
    title: "Top Floor",
    hint: getTooltip("Riser", "Top Floor"),
    hasDefault: false,
    isCalculated: false,
    type: FieldType.Number,
    params: { min: null, max: null },
    multiFieldId: "topFloorRef",
    units: Units.None,
    slot: true,
    description: `Top floor = ${
      topFloor !== "no bound"
        ? topFloor >= 0
          ? topFloor + " floor(s) above the ground floor"
          : -topFloor + " floor(s) below the ground floor"
        : topFloor + " above the ground floor"
    } `,
  };
  const bottomFloorRefField: PropertyField = {
    property: "bottomFloorRef",
    title: "Bottom Floor",
    hint: getTooltip("Riser", "Bottom Floor"),
    hasDefault: false,
    isCalculated: false,
    type: FieldType.Number,
    params: { min: null, max: null },
    multiFieldId: "bottomFloorRef",
    units: Units.None,
    slot: true,
    description: `Bottom floor = ${
      bottomFloor !== "no bound"
        ? bottomFloor >= 0
          ? bottomFloor + " floor(s) above the ground floor"
          : -bottomFloor + " floor(s) below the ground floor"
        : bottomFloor + " below the ground floor"
    } `,
  };

  switch (result.riserType) {
    case "pipe": {
      const materials = Object.keys(catalog.pipes).map((mat) => {
        const c: Choice = {
          disabled: false,
          key: mat,
          name: catalog.pipes[mat].name,
        };
        return c;
      });
      const manufacturer = getPipeManufacturer(
        drawing.metadata.catalog,
        getPipeMaterial(result),
      );
      const diameters = Object.keys(
        catalog.pipes[result.riser.material!].pipesBySize[manufacturer],
      ).map((d) => {
        const val = convertPipeDiameterFromMetric(
          drawing.metadata.units,
          parseCatalogNumberExact(d),
        );
        const c: Choice = {
          disabled: false,
          key: parseCatalogNumberOrMin(d),
          name: val[1] + val[0],
        };
        return c;
      });

      const iAmSewer = isSewer(drawing.metadata.flowSystems[entity.systemUid]);

      if (result.riser.isVent) {
        let sortLevelAcending = [...Object.values(drawing.levels)].sort(
          (a, b) => a.floorHeightM - b.floorHeightM,
        );
        let absHeight: number | undefined =
          sortLevelAcending[
            sortLevelAcending.findIndex((level) => level.uid === "ground") +
              (entity.topFloorRef !== null ? entity.topFloorRef : NaN)
          ]?.floorHeightM;
        if (result.riser.ventHeightM !== null) {
          // entity.ventHeightM is a string
          absHeight += Number(result.riser.ventHeightM);
        }

        const [units, unitAbsHeight] = convertMeasurementSystem(
          drawing.metadata.units,
          Units.Meters,
          absHeight || 0,
          Precision.DISPLAY,
        );

        return [
          {
            property: "entityName",
            title: "Name",
            hasDefault: false,
            isCalculated: false,
            type: FieldType.Text,
            params: null,
            multiFieldId: "entityName",
          },
          {
            property: "systemUid",
            title: "Flow System",
            hasDefault: false,
            isCalculated: false,
            type: FieldType.FlowSystemChoice,
            params: {
              systems: drawing.metadata.flowSystemUidsInOrder.map(
                (uid) => drawing.metadata.flowSystems[uid],
              ),
            },
            multiFieldId: "systemUid",
          },
          {
            property: "topFloorRef",
            title: "Top Floor Reference",
            hasDefault: false,
            isCalculated: false,
            type: FieldType.Number,
            params: { min: null, max: null },
            multiFieldId: "topFloorRef",
            units: Units.Meters,
            slot: true,
            description: `Top floor = ${
              topFloor !== "no bound"
                ? topFloor >= 0
                  ? topFloor + " floor(s) above the ground floor"
                  : -topFloor + " floor(s) below the ground floor"
                : topFloor + " above the ground floor"
            } \n Asbsolute top height = ${
              absHeight !== undefined ? unitAbsHeight : "??"
            }${units}
          `,
          },
          {
            property: "riser.diameterMM",
            title: "Diameter",
            highlightOnOverride: true,
            hasDefault: false,
            isCalculated: true,
            type: FieldType.Choice,
            params: {
              choices: diameters,
              initialValue: diameters[0].key,
            },
            multiFieldId: "diameterMM",
          },

          {
            property: "riser.material",
            title: "Material",
            hasDefault: true,
            highlightOnOverride: true,
            isCalculated: false,
            type: FieldType.Choice,
            params: {
              choices: getAvailablePipeMaterials(materials, {
                pressure: false,
                drainage: true,
                ufh: false,
              }),
            },
            multiFieldId: "material",
          },

          {
            property: "color",
            title: "Color:",
            hasDefault: true,
            isCalculated: false,
            type: FieldType.Color,
            params: null,
            multiFieldId: "color",
          },
        ];
      } else {
        return [
          entityNameField,
          systemUidField(
            drawing.metadata.flowSystemUidsInOrder
              .map((uid) => drawing.metadata.flowSystems[uid])
              .filter(
                (system) => FLOW_SYSTEM_TO_CONDUIT_TYPE[system.type] === "pipe",
              ),
          ),
          topFloorRefField,
          bottomFloorRefField,

          {
            property: "riser.maximumVelocityMS",
            title: "Maximum Velocity",
            hint: getTooltip("Riser", "Maximum Velocity"),
            hasDefault: true,
            highlightOnOverride: true,
            isCalculated: false,
            type: FieldType.Number,
            params: { min: 0, max: null },
            multiFieldId: "maximumVelocityMS",
            units: Units.MetersPerSecond,
          } satisfies NumberField,
          {
            property: "riser.maximumPressureDropRateKPAM",
            title: "Max. Pressure Drop",
            hint: getTooltip("Riser", "Maximum Pressure Drop"),
            hasDefault: true,
            highlightOnOverride: true,
            isCalculated: false,
            type: FieldType.Number,
            params: { min: 0, max: null },
            multiFieldId: "maximumPressureDropRateKPAM",
            units: Units.KiloPascalsPerMeter,
          } satisfies NumberField,

          {
            property: "riser.diameterMM",
            title: "Diameter",
            hint: getTooltip("Riser", "Diameter"),
            highlightOnOverride: true,
            hasDefault: false,
            isCalculated: true,
            type: FieldType.Choice,
            params: {
              choices: diameters,
              initialValue: diameters[0].key,
            },
            multiFieldId: "diameterMM",
          } satisfies ChoiceField,
          {
            property: "riser.material",
            title: "Material",
            hasDefault: true,
            highlightOnOverride: true,
            isCalculated: false,
            type: FieldType.Choice,
            params: {
              choices: getAvailablePipeMaterials(materials, {
                pressure: !isDrainage(
                  drawing.metadata.flowSystems[entity.systemUid],
                ),
                drainage: isDrainage(
                  drawing.metadata.flowSystems[entity.systemUid],
                ),
                ufh: false,
              }),
            },
            multiFieldId: "material",
          } satisfies ChoiceField,
          {
            property: "color",
            title: "Color:",
            hasDefault: true,
            isCalculated: false,
            type: FieldType.Color,
            params: null,
            multiFieldId: "color",
          } satisfies ColorField,
        ].map(withPropertyTracking(context, entity));
      }
    }
    case "duct": {
      assertType<"duct">(entity.riserType);
      const fields: PropertyField[] = [];

      const flowSystem = context.drawing.metadata.flowSystems[
        entity.systemUid
      ] as VentilationFlowSystem;
      const page = CoreRiser.getDuctManufacturerCatalogPage(context, result);
      fields.push(
        entityNameField,
        systemUidField(
          drawing.metadata.flowSystemUidsInOrder
            .map((uid) => drawing.metadata.flowSystems[uid])
            .filter(
              (system) => FLOW_SYSTEM_TO_CONDUIT_TYPE[system.type] === "duct",
            ),
        ),
        topFloorRefField,
        bottomFloorRefField,
        {
          type: FieldType.Tabs,
          id: "riser-duct",
          tabs: [
            {
              tabId: "riser-duct-fittings",
              tabName: "Fittings",
              fields: makeDuctFittingPressureDropFields(
                context,
                result.riser,
                "riser.",
              ),
            },
            {
              tabId: "riser-duct-conduit",
              tabName: "Conduits",
              fields: makeDuctConduitSizingFields(
                context,
                { ...result.riser, network: "risers" },
                { ...entity.riser, network: "risers" },
                flowSystem,
                page,
                "riser.",
              ),
            },
          ],
        },
      );

      return fields.map(withPropertyTracking(context, entity));
    }
  }
}

export function fillRiserDefaults(context: CoreContext, entity: RiserEntity) {
  const { drawing } = context;
  const result = cloneSimple(entity);

  // get system
  // TODO: risers for flow system
  const system = getFlowSystem(drawing, entity.systemUid);

  if (system) {
    const networkKey =
      entity.riserType === "pipe" &&
      entity.riser.isVent &&
      flowSystemHasVent(system)
        ? "vents"
        : DEFAULT_VERTICAL_SYSTEM_NETWORKS[system.type];
    const network = system.networks[networkKey]!;

    if (result.riser.maximumVelocityMS == null) {
      result.riser.maximumVelocityMS =
        "velocityMS" in network ? Number(network.velocityMS) : 1e10;
    }
    if (result.riser.maximumPressureDropRateKPAM == null) {
      result.riser.maximumPressureDropRateKPAM =
        "pressureDropKPAM" in network ? Number(network.pressureDropKPAM) : 1e10;
    }
    if (
      result.riser.material == null &&
      isNetworkTypeWithMaterial(system.type, network)
    ) {
      result.riser.material = network.material;
    }
    if (result.color == null) {
      result.color = system.color;
    }

    switch (result.riserType) {
      case "pipe": {
        break;
      }
      case "duct": {
        assertType<VentilationNetworkParams>(network);

        if (result.riser.shape == null) {
          result.riser.shape = network.shape;
        }

        if (result.riser.sizingIncrementMM == null) {
          result.riser.sizingIncrementMM = network.sizingIncrementMM;
        }

        if (result.riser.sizingMode == null) {
          result.riser.sizingMode = network.sizingMode;
        }

        switch (result.riser.shape) {
          case "circular":
            break;
          case "rectangular":
            if (result.riser.targetWHRatio == null) {
              result.riser.targetWHRatio = network.rectangleWHRatio;
            }
            break;
        }

        if (result.riser.transitionAngle == null) {
          result.riser.transitionAngle = network.transitionAngle;
        }
        if (result.riser.circularElbow == null) {
          result.riser.circularElbow = network.circularElbow;
        }
        if (result.riser.rectangularElbow == null) {
          result.riser.rectangularElbow = network.rectangularElbow;
        }
        if (result.riser.rectRectTee == null) {
          result.riser.rectRectTee = network.rectRectTee;
        }
        if (result.riser.rectCircTee == null) {
          result.riser.rectCircTee = network.rectCircTee;
        }
        if (result.riser.circCircTee == null) {
          result.riser.circCircTee = network.circCircTee;
        }
        if (result.riser.elbowVanes == null) {
          result.riser.elbowVanes = network.elbowVanes;
        }
        if (result.riser.pieces == null) {
          result.riser.pieces = network.pieces;
        }
        if (result.riser.smoothElbowRadiusRatio == null) {
          result.riser.smoothElbowRadiusRatio = network.smoothElbowRadiusRatio;
        }
        break;
      }
    }
  } else {
    throw new Error(
      "Existing system not found for object " + JSON.stringify(entity),
    );
  }

  return result;
}
