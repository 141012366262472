import {
  Units,
  UnitsContext,
  convertMeasurementSystem,
  convertPipeDiameterFromMetric,
} from "../../../lib/measurements";
import {
  Choice,
  parseCatalogNumberExact,
  parseCatalogNumberOrMin,
} from "../../../lib/utils";
import { CoreContext } from "../../calculations/types";
import { getEffectiveHeatLoad } from "../../calculations/utils";
import { HeatLoadItem } from "../../catalog/heatload/types";
import { Catalog, PipeSpec } from "../../catalog/types";
import { PipePhysicalMaterial } from "../../config";
import { CoreObjectConcrete } from "../../coreObjects";
import {
  DrawingState,
  DrawingStateMetadata,
  MetadataCatalog,
  SelectedMaterialManufacturer,
  UnitsParameters,
} from "../drawing";
import {
  FlowSystem,
  FlowSystemNetworkMap,
  FlowSystemType,
  flowSystemNetworkHasExcludedPipeSize,
  getFlowSystemMinimumPipeSize,
} from "../flow-systems";
import { getFlowSystem } from "../utils";
import ConduitEntity, {
  DuctConduitEntity,
  PipeConduitEntity,
} from "./conduit-entity";
import { DoorType } from "./fenestration-entity";
import {
  ChoiceSelectChoiceEntry,
  FieldType,
  FlatPropertyFields,
  PropertyField,
} from "./property-field";
import RiserEntity from "./riser-entity";
import { SystemNodeEntity } from "./system-node-entity";
import { EntityType } from "./types";

export function getEntitySystem(
  drawing: DrawingState,
  entity: ConduitEntity | RiserEntity,
) {
  return getFlowSystem(drawing, entity.systemUid);
}

export type ConditionalPropertyField = PropertyField & {
  isShown?: Boolean;
};

export function filterConditionalFields(res: ConditionalPropertyField[]) {
  const finalRes = res.filter((r) => r.isShown);
  const finalFields: PropertyField[] = finalRes.map(
    ({ isShown, ...keepAttrs }) => keepAttrs,
  );
  return finalFields;
}

export function getEntityNetwork<T extends FlowSystemType>(
  drawing: DrawingState,
  entity: PipeConduitEntity | DuctConduitEntity,
): FlowSystemNetworkMap[T] | null {
  const system = getEntitySystem(drawing, entity);
  if (system) {
    return (system.networks[entity.conduit.network] || null) as
      | FlowSystemNetworkMap[T]
      | null;
  } else {
    return null;
  }
}

export function getPipeMaterial(
  entity: PipeConduitEntity | DuctConduitEntity | RiserEntity,
): string | null {
  return entity.type === EntityType.RISER
    ? entity.riser.material
    : entity.conduit.material;
}

export function getPipeManufacturer(
  metadataCatalog: MetadataCatalog,
  material: string | null,
): string {
  const selectedMaterial = metadataCatalog.pipes.find(
    (pipe: SelectedMaterialManufacturer) => pipe.uid === material,
  );
  return selectedMaterial ? selectedMaterial.manufacturer : "generic";
}

export function getPipeExcludedSizes(
  drawingMetadataCatalog: MetadataCatalog,
  material: string | null,
  system?: FlowSystem,
): Set<number> {
  if (system && !flowSystemNetworkHasExcludedPipeSize(system)) {
    return new Set();
  }
  const selectedMaterial = drawingMetadataCatalog.pipes.find(
    (pipe: SelectedMaterialManufacturer) => pipe.uid === material,
  );
  if (!selectedMaterial || !selectedMaterial.excludedSizes) {
    return new Set();
  }
  return new Set(
    Object.entries(selectedMaterial.excludedSizes)
      .filter((entry) => !!entry[1])
      .map((entry) => Number(entry[0])),
  );
}

export function getPipesBySizeRecord(
  metadataCatalog: MetadataCatalog,
  catalog: Catalog,
  material: string | null,
) {
  const manufacturer = getPipeManufacturer(metadataCatalog, material);
  if (!catalog.pipes[material!]) {
    console.warn({
      message: "Pipe material not found in catalog",
      material,
      catalog: catalog.pipes,
    });
  }
  return catalog.pipes[material!].pipesBySize[manufacturer];
}

export function filterEnabledPipeSizes<T>(
  items: T[],
  getPipeSize: (item: T) => number | null,
  opts?: {
    minimumPipeSize?: number;
    excludedSizes?: Set<number>;
    ensureNonEmpty?: boolean;
  },
): T[] {
  const filter = <U>(
    minimumPipeSize_: number,
    excludedSizes_: Set<number>,
    default_: U,
  ): T[] | U => {
    const result = items.filter((item) => {
      const pipeSize = getPipeSize(item);
      return (
        pipeSize !== null &&
        isPipeSizeEnabled(pipeSize, minimumPipeSize_, excludedSizes_)
      );
    });
    return result.length === 0 ? default_ : result;
  };
  const {
    minimumPipeSize = 0,
    excludedSizes = new Set<number>(),
    ensureNonEmpty = false,
  } = opts ?? {};
  return ensureNonEmpty
    ? (filter(minimumPipeSize, excludedSizes, null) ??
        filter(minimumPipeSize, new Set(), null) ??
        items)
    : filter(minimumPipeSize, excludedSizes, []);
}

export interface FilterPipesBySizeEntriesOpts {
  material: string | null;
  metadataCatalog: MetadataCatalog;
  catalog: Catalog;
  flowSystem?: FlowSystem;
  entity?: PipeConduitEntity | DuctConduitEntity;
  ensureNonEmpty: boolean;
}

export function filterPipesBySizeEntries(
  opts: FilterPipesBySizeEntriesOpts,
): [string, PipeSpec][] {
  const {
    material,
    metadataCatalog,
    catalog,
    flowSystem,
    entity,
    ensureNonEmpty,
  } = opts;
  const minimumPipeSize =
    flowSystem && entity ? getFlowSystemMinimumPipeSize(flowSystem, entity) : 0;
  const pipesBySize = getPipesBySizeRecord(metadataCatalog, catalog, material);
  const excludedSizes = getPipeExcludedSizes(
    metadataCatalog,
    material,
    flowSystem,
  );
  return filterEnabledPipeSizes(
    Object.entries(pipesBySize),
    ([key]) => parseCatalogNumberOrMin(key),
    { minimumPipeSize, excludedSizes, ensureNonEmpty },
  );
}

export function getPipesChoices(
  opts: Omit<FilterPipesBySizeEntriesOpts, "metadataCatalog"> & {
    metadata: DrawingStateMetadata;
  },
): Choice<number>[] {
  return filterPipesBySizeEntries({
    material: opts.material,
    metadataCatalog: opts.metadata.catalog,
    catalog: opts.catalog,
    flowSystem: opts.flowSystem,
    entity: opts.entity,
    ensureNonEmpty: opts.ensureNonEmpty,
  }).map(([sizeStrKey]) => {
    const val = convertPipeDiameterFromMetric(
      opts.metadata.units,
      parseCatalogNumberExact(sizeStrKey),
    );
    const c: Choice<number> = {
      disabled: false,
      key: parseCatalogNumberOrMin(sizeStrKey)!,
      name: val[1] + val[0],
    };
    return c;
  });
}

export function isPipeSizeEnabled(
  pipeSize: number,
  minimumPipeSize: number,
  excludedSizes: Set<number>,
): boolean {
  return (
    pipeSize >= minimumPipeSize && !isPipeSizeExcluded(pipeSize, excludedSizes)
  );
}

export function isPipeSizeExcluded(
  pipeSize: number,
  excludedSizes: Set<number>,
): boolean {
  return excludedSizes.has(pipeSize);
}

export function getPipeManufacturerByMaterial(
  drawing: DrawingState,
  material: string,
): string {
  const selectedMaterial = drawing.metadata.catalog.pipes.find(
    (pipe: SelectedMaterialManufacturer) => pipe.uid === material,
  );
  return selectedMaterial ? selectedMaterial.manufacturer : "generic";
}

export function getPipeManufacturerRecord(
  catalog: Catalog,
  pipeMaterial: PipePhysicalMaterial,
  selectedManufacturer: string,
) {
  return catalog.pipes[pipeMaterial].manufacturer.find(
    (i) => i.uid === selectedManufacturer,
  );
}

export function flattenTabFields(
  fields: PropertyField[],
): FlatPropertyFields[] {
  return fields.flatMap((f) => {
    if (f.type === FieldType.Tabs) {
      return f.tabs.flatMap((t) => flattenTabFields(t.fields));
    } else {
      return [f];
    }
  });
}

// TODO: generalize this to non-pipe conduits.
export function getDiametersOptions(
  catalog: Catalog,
  result: PipeConduitEntity,
  manufacturer: string,
  flowSystemSettings: FlowSystem,
  units: UnitsParameters,
): Choice[] {
  return Object.keys(
    catalog.pipes[result.conduit.material!].pipesBySize[manufacturer],
  )
    .map((d) => {
      const val = convertPipeDiameterFromMetric(
        units,
        parseCatalogNumberExact(d),
      );
      const c: Choice = {
        disabled: false,
        key: parseCatalogNumberOrMin(d),
        name: val[1] + val[0],
      };
      return c;
    })
    .filter((d) => {
      const n =
        flowSystemSettings.networks[
          result.conduit.network as keyof typeof flowSystemSettings.networks
        ];
    });
}

export enum SystemNodeRole {
  FIXTURE = "fixture",
  PLANT = "plant",
  RECIRCULATION_PUMP = "recirculation pump",
  OTHER = "other",
}

export function findSystemNodeRole(
  nodeEntity: SystemNodeEntity,
  context: CoreContext,
): SystemNodeRole {
  const parentUid = nodeEntity.parentUid;
  if (parentUid) {
    let parentEntity = context.globalStore.get(parentUid) as CoreObjectConcrete;
    switch (parentEntity.type) {
      case EntityType.FIXTURE:
        return SystemNodeRole.FIXTURE;
      case EntityType.PLANT:
        // Check if's a plant
        let pumpUids = parentEntity.getRecirculationPumpIds();
        if (pumpUids.includes(nodeEntity.uid)) {
          return SystemNodeRole.RECIRCULATION_PUMP;
        }
        return SystemNodeRole.PLANT;
    }
  }
  return SystemNodeRole.OTHER;
}

export function getFloorOrder(drawing: DrawingState) {
  return Object.values(drawing.levels)
    .slice()
    .sort((a, b) => -(a.floorHeightM - b.floorHeightM))
    .reverse();
}

export function getLevelAbove(drawing: DrawingState, uid: string) {
  let floorOrder = getFloorOrder(drawing);
  let index = floorOrder.findIndex((f) => f.uid === uid);
  if (index === -1) {
    return null;
  }

  return floorOrder[index + 1]?.uid ?? null;
}

export function isButtomFloor(floorUid: string, drawing: DrawingState) {
  let floorOrder = getFloorOrder(drawing);
  return floorOrder[floorOrder.length - 1].uid === floorUid;
}

export function getLevelBelowUid(
  drawing: DrawingState,
  uid: string | null,
): string | null {
  let floorOrder = getFloorOrder(drawing);
  let index = floorOrder.findIndex((f) => f.uid === uid);
  if (index === -1) {
    return null;
  }

  return floorOrder[index - 1]?.uid ?? null;
}

export function getAvailableHeatLoadComponentMaterial(
  context: CoreContext,
  component: HeatLoadItem,
): Choice[] {
  let { material } = getEffectiveHeatLoad(context.catalog, context.drawing);

  let materialsChoice = material[component].table;

  return Object.entries(materialsChoice).map(([key, value]) => {
    const [thermalTransmittanceUnits, thermalTransmittanceValue] =
      convertMeasurementSystem(
        context.drawing.metadata.units,
        Units.WattsPerSquareMeterKelvin,
        value.thermal_transmittance_W_per_m2K,
        2,
      );
    return {
      name: `${key} ${thermalTransmittanceValue} ${thermalTransmittanceUnits}`,
      key: key,
    };
  });
}

export function getAvailableHeatSource(
  context: CoreContext,
): ChoiceSelectChoiceEntry[] {
  let heatSources = context.drawing.metadata.heatLoss.internalHeatSource;
  let results: ChoiceSelectChoiceEntry[] = [];

  for (let [key, heatSourcesEntry] of Object.entries(heatSources)) {
    results.push({
      name: `${key}`,
      value: heatSourcesEntry.heatSourceWatts,
      units: Units.Watts,
      unitsContext: UnitsContext.HEAT_LOAD_ENERGY_MEASUREMENT,
    });
  }
  return results;
}

export function getAvailableDoorTypes() {
  return Object.values(DoorType).map((doorType) => {
    return {
      name: `${doorType}`,
      key: doorType,
    };
  });
}
