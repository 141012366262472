import { validate, version } from "uuid";

export interface PropertyReference {
  reference: string;
  propertyPath: string;
}

/**
 * Naive Implementation
 *
 * Assumes all uids are references.
 *
 * The pros of this are that developers dont need to explicitly specify references when developing.
 * The cons is that theres a performance cost iterating through all the props of an entity.
 */
export function findReferences(
  object: object,
  pathPrefix: string = "",
): PropertyReference[] {
  let references: PropertyReference[] = [];
  for (let [property, value] of Object.entries(object)) {
    if (value === null || value === undefined) {
      continue;
    }
    if (
      typeof value === "string" &&
      property !== "uid" &&
      isEntityReference(value)
    ) {
      references.push({
        reference: value,
        propertyPath: `${pathPrefix}${property}`,
      });
    } else if (Array.isArray(value)) {
      value.forEach((v, index) => {
        if (
          typeof v === "string" &&
          property !== "uid" &&
          isEntityReference(v)
        ) {
          references.push({
            reference: v,
            propertyPath: `${pathPrefix}${property}[${index}]`,
          });
        } else if (typeof v === "object" && v !== null && v !== undefined) {
          references.push(
            ...findReferences(v, `${pathPrefix}${property}[${index}].`),
          );
        }
      });
    } else if (typeof value === "object") {
      references.push(...findReferences(value, `${pathPrefix}${property}.`));
    }
  }
  return references;
}

/**
 * TODO(SEED-2044): Migrate this to be based on the new reference type
 *
 * Naive Implementation
 *
 * Assumes all uuid.v4s are references.
 */
export function isEntityReference(value: string) {
  return validate(value) && version(value) === 4;
}
