import {
  DrawingState,
  Level,
  MetadataCatalog,
} from "../../src/api/document/drawing";
import { WithID } from "../../src/api/document/entities/simple-entities";
import { SupportedLocales } from "../../src/api/locale";
import { GlobalStore } from "../../src/lib/globalstore/global-store";
import { NewDocumentSettings } from "../fixtures/documentTemplates";
import {
  BodyKeyPress,
  ButtonClick,
  CanvasMouseDown,
  CanvasMouseMove,
  CanvasMouseUp,
  MouseWheelEvent,
  TestProcedure,
  WindowResize,
  inputKeyPress,
} from "../fixtures/testProcedure";

/**
 * @deprecated Prefer writing native playwright tests.
 *
 * The issue with Test generation is selectors and page interaction can change easily,
 * causing every test to manually be recreated.
 *
 * By using native playwright tests, the amount of places to change can be minimized.
 */
export class AutomatedTestGenerator {
  testProcedures: TestProcedure[];
  newDocumentSettings: NewDocumentSettings;
  windowWidth: number;
  windowHeight: number;
  isFinalResult: boolean;
  documentTitle: string;
  fileHeader: string;
  fileFooter: string;

  constructor(
    isFinalResult: boolean,
    windowWidth?: number,
    windowHeight?: number,
  ) {
    this.testProcedures = [];
    this.isFinalResult = isFinalResult;

    if (windowWidth && windowHeight) {
      this.windowWidth = windowWidth;
      this.windowHeight = windowHeight;
      const windowResize: WindowResize = {
        type: "windowResize",
        windowWidth: windowWidth,
        windowHeight: windowHeight,
      };
      this.testProcedures.push(windowResize);
    }
  }

  setProjectConfiguration(
    title: string,
    locale: SupportedLocales,
    catalog: MetadataCatalog,
  ) {
    this.newDocumentSettings = {
      title: title,
      locale: locale,
      manufacturers: Object.keys(catalog ?? {}).flatMap((catalogId) =>
        (catalog?.[catalogId] ?? []).map((product) => ({
          catalogId: catalogId,
          productId: product.uid,
          manufacturer: product.manufacturer,
        })),
      ),
    };
    this.documentTitle = title;
  }

  static sortedLevels(levels: Record<string, Level>) {
    return Object.values(levels).sort(
      (a, b) => a.floorHeightM - b.floorHeightM,
    );
  }

  static buildResultMaps(
    drawing: DrawingState,
    globalStore: GlobalStore,
  ): Array<Record<string, WithID>> {
    const resultMaps: Array<Record<string, WithID>> = [];

    for (const level of this.sortedLevels(drawing.levels)) {
      const currLevelEntities = Object.values(level.entities) as WithID[];
      const currLevelContentMap = this.getContentHashmap(
        currLevelEntities,
        globalStore,
      );
      resultMaps.push(currLevelContentMap);
    }

    //Shared level
    resultMaps.push(
      this.getContentHashmap(
        Object.values(drawing.shared) as WithID[],
        globalStore,
      ),
    );

    return resultMaps;
  }

  static getContentHashmap(
    currentDrawingResult: WithID[],
    globalStore: GlobalStore,
  ) {
    const contentHashMap: Record<string, WithID> = {};
    currentDrawingResult.forEach((entity) => {
      contentHashMap[globalStore.get(entity.uid).getHash()] = entity;
    });
    return contentHashMap;
  }

  //FileName have to include extensions
  generateFile(fileName: string, fileContent: string, fileType: string) {
    const blob = new Blob([fileContent], { type: fileType });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(url);
  }

  addTestProcedure(
    event: WheelEvent | MouseEvent | PointerEvent | KeyboardEvent,
  ) {
    if (event.type === "keydown") {
      if (
        event.target instanceof HTMLBodyElement ||
        event.target instanceof HTMLCanvasElement
      ) {
        //Canvas key press
        const keyEvent: BodyKeyPress = {
          type: "bodyKeyPress",
          target: "[data-testid=drawing-canvas]",
          keyCode: (<KeyboardEvent>event).keyCode,
          key: (<KeyboardEvent>event).key,
          x: (<MouseEvent>event).clientX,
          y: (<MouseEvent>event).clientY,
        };
        this.testProcedures.push(keyEvent);
      } else if (event.target instanceof HTMLInputElement) {
        //Input key press
        const targetInput = event.target as HTMLInputElement;
        const keyEvent: inputKeyPress = {
          type: "inputKeyPress",
          target: event.target.dataset.testid
            ? `[data-testid=${CSS.escape(event.target.dataset.testid)}]` //Get by data-testid
            : `input[placeholder='${targetInput.placeholder}']`, //Get by innerHtml text
          key: (<KeyboardEvent>event).key,
        };
        this.testProcedures.push(keyEvent);
      }
    } else if (event.type === "click") {
      if (event.target instanceof HTMLCanvasElement) {
        if (
          this.testProcedures.length > 2 &&
          this.testProcedures[this.testProcedures.length - 1].type ===
            "canvasMouseUp" &&
          this.testProcedures[this.testProcedures.length - 2].type ===
            "canvasMouseDown"
        ) {
          //Canvas mouse click event

          const target: TestProcedure = {
            type: "canvasClick",
            target: "[data-testid=drawing-canvas]",
            x: (<PointerEvent>event).clientX,
            y: (<PointerEvent>event).clientY,
            buttons: mouseButtonToText((<PointerEvent>event).buttons),
          };
          this.testProcedures.push(target);
        }
      } else {
        //Button Click
        let eventTarget = event.target as HTMLElement | null;
        if (eventTarget == null) {
          return;
        }
        if (
          (eventTarget.localName == "div" ||
            eventTarget.localName == "ul" ||
            eventTarget.localName == "label" ||
            eventTarget.localName == "li") &&
          !eventTarget.dataset.testid
        ) {
          //Discard div clicks
          return;
        }

        //Get parent element if it is an path or svg element
        while (
          eventTarget?.localName == "svg" ||
          eventTarget?.localName == "path"
        ) {
          eventTarget = eventTarget.parentElement;
        }
        let target = eventTarget?.dataset.testid
          ? `[data-testid=${CSS.escape(eventTarget.dataset.testid)}]` //Get by data-testid
          : `${eventTarget?.localName}:contains(${eventTarget?.innerText})`; //Get by innerHtml text

        if (
          eventTarget?.localName == "input" &&
          !eventTarget.dataset.testid &&
          eventTarget.id
        ) {
          target = `[id=${eventTarget.id}]`;
        }

        const buttonEvent: ButtonClick = {
          type: "buttonClick",
          target: target,
        };
        this.testProcedures.push(buttonEvent);
      }
    } else if (
      event.type === "mouseup" &&
      event.target instanceof HTMLCanvasElement
    ) {
      // Canvas mouse up
      const target: CanvasMouseUp = {
        type: "canvasMouseUp",
        target: "[data-testid=drawing-canvas]",
        x: (<MouseEvent>event).clientX,
        y: (<MouseEvent>event).clientY,
        buttons: mouseButtonToText((<MouseEvent>event).button),
      };
      if (
        this.testProcedures.length > 1 &&
        this.testProcedures[this.testProcedures.length - 1].type ===
          "canvasMouseMove" &&
        this.testProcedures[this.testProcedures.length - 2].type ===
          "canvasMouseDown" &&
        Math.abs(
          (<CanvasMouseDown>this.testProcedures[this.testProcedures.length - 2])
            .x - (<MouseEvent>event).clientX,
        ) <= 2 &&
        Math.abs(
          (<CanvasMouseDown>this.testProcedures[this.testProcedures.length - 2])
            .y - (<MouseEvent>event).clientY,
        ) <= 2
      ) {
        //Special case for canvas click

        const clickX = (<CanvasMouseDown>(
          this.testProcedures[this.testProcedures.length - 2]
        )).x;
        const clickY = (<CanvasMouseDown>(
          this.testProcedures[this.testProcedures.length - 2]
        )).y;

        //Mouse move,remove possible drag
        this.testProcedures.pop();

        const target2: TestProcedure = {
          type: "canvasClick",
          target: "[data-testid=drawing-canvas]",
          x: clickX,
          y: clickY,
          buttons: mouseButtonToText((<MouseEvent>event).buttons),
        };
        this.testProcedures.push(target2);
      } else {
        this.testProcedures.push(target);
      }
    } else if (
      event.type === "mousemove" &&
      event.target instanceof HTMLCanvasElement
    ) {
      //Canvas mouse move
      const target: CanvasMouseMove = {
        type: "canvasMouseMove",
        target: "[data-testid=drawing-canvas]",
        x: (<MouseEvent>event).clientX,
        y: (<MouseEvent>event).clientY,
        buttons: mouseButtonToText((<MouseEvent>event).buttons),
      };

      if (
        this.testProcedures.length > 0 &&
        this.testProcedures[this.testProcedures.length - 1].type ===
          "canvasMouseMove"
      ) {
        this.testProcedures.pop();
      }
      this.testProcedures.push(target);
    } else if (
      event.type === "mousedown" &&
      event.target instanceof HTMLCanvasElement
    ) {
      //Canvas Mouse Down
      const target: CanvasMouseDown = {
        type: "canvasMouseDown",
        target: "[data-testid=drawing-canvas]",
        x: (<MouseEvent>event).clientX,
        y: (<MouseEvent>event).clientY,
        button: mouseButtonToText((<MouseEvent>event).button),
        shiftKey: (<MouseEvent>event).shiftKey,
        ctrlKey: (<MouseEvent>event).ctrlKey,
      };
      this.testProcedures.push(target);
    } else if (
      event.type === "wheel" &&
      event.target instanceof HTMLCanvasElement
    ) {
      //canvas Wheel Event
      const target: MouseWheelEvent = {
        type: "wheelEvent",
        target: "[data-testid=drawing-canvas]",
        x: (<WheelEvent>event).clientX,
        y: (<WheelEvent>event).clientY,
        deltaY: (<WheelEvent>event).deltaY,
      };
      this.testProcedures.push(target);
    }
  }

  saveToFile() {
    let fileContent = "";
    fileContent += this.fileHeader;

    fileContent +=
      `const tests: TestProcedure[] = ` +
      JSON.stringify(this.testProcedures) +
      `;\n`;
    fileContent +=
      "const documentSetting: NewDocumentSettings = " +
      JSON.stringify(this.newDocumentSettings) +
      ";\n";
    fileContent +=
      "const isFinalResult: boolean = " + this.isFinalResult + ";\n\n\n";
    fileContent += this.fileFooter;

    this.generateFile(this.documentTitle + ".ts", fileContent, "text/plain");
  }
}

function mouseButtonToText(n: number): "left" | "right" | "middle" {
  switch (n) {
    case 0:
    default:
      return "left";
    case 1:
      return "middle";
    case 2:
      return "right";
  }
}
