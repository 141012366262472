import Flatten from "@flatten-js/core";
import { DrawingLayout } from "../../../../common/src/api/calculations/types";
import { fillDefaultWallFields } from "../../../../common/src/api/document/entities/wall-entity";
import { Coord } from "../../../../common/src/lib/coord";
import { GlobalStore } from "../../../../common/src/lib/globalstore/global-store";
import { DEFAULT_FONT_NAME } from "../../config";
import { DocumentState, ToolInfoPanel } from "../../store/document/types";
import CanvasContext from "../lib/canvas-context";
import { DrawingContext } from "../lib/types";
import DrawableEdge from "../objects/drawableEdge";
import DrawableWall from "../objects/drawableWall";
import { KeyCode, keyCode2Text } from "../utils";
import { EdgeCache, buildWallCache } from "./lib/wall-cache";

export interface BaseKeyHandler {
  name: string;
  /** whether this keybind should be omitted from the keybinds list presented to the user */
  hide?: boolean;
}

export interface KeyHandler extends BaseKeyHandler {
  fn(
    event: KeyboardEvent,
    onRefresh: () => void,
    keyDownMap: Map<KeyCode, boolean>,
  ): void;
}

// eg. [[Ctrl, Shift, Z], [Ctrl, Y]] means Ctrl+Shift+Z or Ctrl+Y
export type KeySpec = Array<Array<KeyCode>>;

export type KeyHandlers = Array<[KeySpec, KeyHandler]>;

export function keyspecMatches(event: KeyboardEvent, spec: KeySpec): boolean {
  for (const combo of spec) {
    let matches = true;
    for (const k of combo) {
      switch (k) {
        case KeyCode.CONTROL:
          if (!event.getModifierState("Control")) {
            matches = false;
          }
          break;
        case KeyCode.SHIFT:
          if (!event.getModifierState("Shift")) {
            matches = false;
          }
          break;
        case KeyCode.ALT:
          if (!event.getModifierState("Alt")) {
            matches = false;
          }
          break;
        default:
          if (event.keyCode !== k && event.key.toUpperCase() !== KeyCode[k]) {
            matches = false;
          }
      }
    }
    if (matches) {
      return true;
    }
  }
  return false;
}

export function drawToolInfoPanel(
  context: DrawingContext,
  toolInfoPanel: ToolInfoPanel,
) {
  const { ctx, vp } = context;
  let top = vp.height - 50 - toolInfoPanel.keyHandlers.length * 30;

  ctx.fillStyle = "#000";
  ctx.font = "14px " + DEFAULT_FONT_NAME;

  let stop = top - 30 * toolInfoPanel.information.length;
  toolInfoPanel.information.forEach((s) => {
    ctx.fillTextStable(s, 30, stop);
    stop += 30;
  });

  ctx.lineWidth = 1;

  toolInfoPanel.keyHandlers
    .filter(([_keySpec, h]) => !h.hide)
    .forEach(([keySpec, h]) => {
      let width = 0;
      for (const combo of keySpec) {
        for (const k of combo) {
          const keyText = keyCode2Text(k);
          const radius = 5;
          const height = ctx.measureText("M").width + radius * 2;
          const thisWidth = ctx.measureText(keyText).width + radius * 2;

          // draw rounded rectangle around the text
          ctx.strokeStyle = "#000";
          ctx.fillStyle = "#fff";
          const effTop = top + 4;
          ctx.beginPath();
          ctx.moveTo(25 + width + radius, effTop);
          ctx.lineTo(25 + width + thisWidth - radius, effTop);
          ctx.quadraticCurveTo(
            25 + width + thisWidth,
            effTop,
            25 + width + thisWidth,
            effTop + radius,
          );
          ctx.lineTo(25 + width + thisWidth, effTop + height - radius);
          ctx.quadraticCurveTo(
            25 + width + thisWidth,
            effTop + height,
            25 + width + thisWidth - radius,
            effTop + height,
          );
          ctx.lineTo(25 + width + radius, effTop + height);
          ctx.quadraticCurveTo(
            25 + width,
            effTop + height,
            25 + width,
            effTop + height - radius,
          );
          ctx.lineTo(25 + width, effTop + radius);
          ctx.quadraticCurveTo(25 + width, effTop, 25 + width + radius, effTop);
          ctx.closePath();
          ctx.fill();
          ctx.stroke();

          ctx.fillStyle = "#000";
          ctx.fillTextStable(keyText, 30 + width, top + 20);

          width += thisWidth + 5;
          // draw plus if not last
          if (k !== combo[combo.length - 1]) {
            ctx.fillTextStable("+", 25 + width, top + 20);
            width += 10;
          }
        }
        // draw comma if not last
        if (combo !== keySpec[keySpec.length - 1]) {
          ctx.fillTextStable(",", 25 + width, top + 20);
          width += 10;
        }
      }
      context.ctx.fillTextStable(h.name, 40 + width, top + 20);
      top += 30;
    });
}

export function drawAngleMarkers(
  context: DrawingContext,
  screenCoord: [number, number],
  angleBounds: { min: number; max: number } | null,
) {
  const { ctx } = context;

  // draw a pie from angleDegMin to angleDegMax clockwise
  if (angleBounds !== null) {
    ctx.fillStyle = "rgba(0, 0, 0, 0.2)";
    ctx.beginPath();
    ctx.moveTo(screenCoord[0], screenCoord[1]);
    ctx.arc(
      screenCoord[0],
      screenCoord[1],
      100,
      (angleBounds.min * Math.PI) / 180 - Math.PI / 2,
      (angleBounds.max * Math.PI) / 180 - Math.PI / 2,
      false,
    );
    ctx.closePath();
    ctx.fill();
  }
}

export function ventFix(context: CanvasContext) {
  const levelUid =
    context.document.drawing.levels[context.document.uiState.levelUid!].uid;
  const groundUid = "ground";

  const levelsSortedAcending = Object.values(
    context.document.drawing.levels,
  ).sort((a, b) => {
    return a.floorHeightM - b.floorHeightM;
  });

  return (
    levelsSortedAcending.findIndex((level) => {
      return level.uid === levelUid;
    }) -
    levelsSortedAcending.findIndex((level) => {
      return level.uid === groundUid;
    })
  );
}

export function getLinkToEntity(
  docId: number,
  levelUid: string | null,
  entityUid: string,
): string {
  const levelUidString = levelUid === null ? "" : `,loc:${levelUid}`;
  return `/document/${docId}#sel:${entityUid}${levelUidString}`;
}

export function getHash({
  globalStore,
  selectedIds,
  document,
  drawingLayout,
  flowSystemUid,
}: {
  globalStore: GlobalStore;
  selectedIds: string[];
  document: DocumentState | null;
  drawingLayout: DrawingLayout | null;
  flowSystemUid: string | null;
}): string {
  const hashParts: string[] = ["#"];

  if (selectedIds.length > 0 && selectedIds.length < 50) {
    const selectedPart = selectedIds
      .map((id) => {
        const short = id.substring(0, 7);
        return globalStore.search(short).length > 1 ? id : short;
      })
      .join(",");
    hashParts.push(`sel:${selectedPart}`);
  } else if (document !== null) {
    hashParts.push(
      document.uiState.viewPort!.toHash(document.uiState.levelUid!),
    );
  }

  hashParts.push(
    `;layout:${drawingLayout !== null ? drawingLayout : (document?.uiState?.drawingLayout ?? "mechanical")}`,
  );

  if (flowSystemUid) {
    hashParts.push(`;flowSystem:${flowSystemUid}`);
  }

  return hashParts.join("");
}

export function getSnapToWallCoords(
  context: CanvasContext,
  wallCache: EdgeCache | null,
  currPt: Coord,
  snapDistanceCutoffMM: number,
  wallOffsetDistMM: number,
): {
  center: Coord;
  rotationDEG: number;
  wallUid: string;
} | null {
  if (!wallCache) {
    wallCache = buildWallCache(context);
  }

  // snap to wall if possible.
  const walls = wallCache!.tree.search({
    minX: currPt.x - snapDistanceCutoffMM,
    minY: currPt.y - snapDistanceCutoffMM,
    maxX: currPt.x + snapDistanceCutoffMM,
    maxY: currPt.y + snapDistanceCutoffMM,
  });

  let closestWall = null;
  let closestWallObj = null;
  let closestDistance = Infinity;
  for (const { uid, edgeUid: wallUid } of walls) {
    const wall = wallCache!.segments[uid];
    const distance = wall.distanceTo(Flatten.point(currPt.x, currPt.y))[0];
    if (distance < closestDistance) {
      closestDistance = distance;
      closestWall = wall;
      closestWallObj = context.globalStore.get<DrawableWall>(wallUid);
    }
  }

  if (closestWall && closestWallObj) {
    const rotation =
      -Flatten.vector(closestWall.start, closestWall.end).angleTo(
        Flatten.vector(1, 0),
      ) *
      (180 / Math.PI);

    // Snap close to wall
    const wallProjection = closestWall.distanceTo(
      Flatten.point(currPt.x, currPt.y),
    )[1].ps;
    let wallToWcVec = Flatten.vector(
      currPt.x - wallProjection.x,
      currPt.y - wallProjection.y,
    );
    if (wallToWcVec.length > 0) {
      wallToWcVec = wallToWcVec.normalize();
    } else {
      // dummy value
      wallToWcVec = Flatten.vector(closestWall.start, closestWall.end)
        .rotate90CCW()
        .normalize();
    }
    if (closestWallObj.isInternalWall()) {
      const filledWall = fillDefaultWallFields(context, closestWallObj.entity);
      wallOffsetDistMM += filledWall.widthMM! / 2;
    } else {
      const roomEdgeObj = context.globalStore.get<DrawableEdge>(
        closestWallObj.entity.polygonEdgeUid[0],
      );
      wallToWcVec = roomEdgeObj.normal.normalize();
    }
    const wallSnapLine = Flatten.line(
      wallProjection.translate(wallToWcVec.multiply(wallOffsetDistMM)),
      wallToWcVec,
    );

    const newPoint = wallSnapLine.distanceTo(
      Flatten.point(currPt.x, currPt.y),
    )[1].ps;

    return {
      center: { x: newPoint.x, y: newPoint.y },
      rotationDEG: rotation,
      wallUid: closestWallObj.uid,
    };
  } else {
    return null;
  }
}

export function drawCrossHairLine(
  context: DrawingContext,
  lastEvent: MouseEvent,
): void {
  const { ctx } = context;

  // Set the dash pattern
  ctx.setLineDash([3, 3]);
  ctx.strokeStyle = "#000";
  ctx.lineWidth = 1;

  ctx.beginPath();
  // vertical line
  ctx.moveTo(lastEvent.clientX, -10000);
  ctx.lineTo(lastEvent.clientX, 10000);
  // horizontal line
  ctx.moveTo(-10000, lastEvent.clientY);
  ctx.lineTo(10000, lastEvent.clientY);

  ctx.stroke();
  ctx.setLineDash([]);
}
