import {
  InsulationJackets,
  InsulationMaterials,
  StandardFlowSystemUids,
} from "../../config";

import { FlowSystem } from "../flow-systems";
import { DRAINAGE_FLOW_SYSTEMS } from "./drainage-flow-systems";
import { MECHANICAL_FLOW_SYSTEMS } from "./mechanical-flow-systems";
import { STORMWATER_SYSTEM } from "./stormwater-flow-system";
import { UNDERFLOOR_FLOW_SYSTEMS } from "./underfloor-flow-system";
import { VENTILATION_SYSTEM } from "./ventilation-flow-system";

export const DEFAULT_RETURN_COLOR_HEX = "#ff8800";

export const initialFlowSystems: FlowSystem[] = [
  // TODO: these values should get got from the database.
  {
    type: "pressure",
    role: "coldwater",
    name: "Cold Water",
    temperatureC: 20,
    color: { hex: "#009CE0" },
    uid: "cold-water",
    fluid: "water",
    defaultPipeHeightM: 3,

    hasReturnSystem: false,

    return: {
      color: { hex: DEFAULT_RETURN_COLOR_HEX },

      maxVelocityMS: 1,
      insulated: false,

      insulation: {
        material: InsulationMaterials.mmKemblaInsulation,
        jacket: InsulationJackets.allServiceJacket,
        thicknessMM: 25,
      },
    },

    networks: {
      risers: {
        spareCapacityPCT: 0,
        velocityMS: 1.5,
        pressureDropKPAM: 0.35,
        material: "copperTypeB",
        minimumPipeSize: 15,
      },
      reticulations: {
        spareCapacityPCT: 0,
        velocityMS: 1.5,
        pressureDropKPAM: 0.35,
        material: "copperTypeB",
        minimumPipeSize: 15,
      },
      connections: {
        spareCapacityPCT: 0,
        velocityMS: 3,
        pressureDropKPAM: 0.35,
        material: "pexSdr74",
        minimumPipeSize: 16,
      },
    },
  },
  {
    type: "pressure",
    role: "hotwater",
    name: "Hot Water",
    temperatureC: 65,
    color: { hex: "#F44E3B" },
    uid: "hot-water",
    fluid: "water",
    hasReturnSystem: true,
    defaultPipeHeightM: 3,

    return: {
      color: { hex: DEFAULT_RETURN_COLOR_HEX },

      maxVelocityMS: 1,
      insulated: true,

      insulation: {
        material: InsulationMaterials.mmKemblaInsulation,
        jacket: InsulationJackets.allServiceJacket,
        thicknessMM: 25,
      },
    },

    networks: {
      risers: {
        spareCapacityPCT: 0,
        velocityMS: 1.2,
        pressureDropKPAM: 0.35,
        material: "copperTypeB",
        minimumPipeSize: 15,
      },
      reticulations: {
        spareCapacityPCT: 0,
        velocityMS: 1.2,
        pressureDropKPAM: 0.35,
        material: "copperTypeB",
        minimumPipeSize: 15,
      },
      connections: {
        spareCapacityPCT: 0,
        velocityMS: 3,
        pressureDropKPAM: 0.35,
        material: "pexSdr74",
        minimumPipeSize: 16,
      },
    },
  },
  {
    type: "pressure",
    role: "warmwater",
    name: "Warm Water",
    temperatureC: 50,
    color: { hex: "#F49000" },
    uid: "warm-water",
    fluid: "water",
    hasReturnSystem: false,
    defaultPipeHeightM: 3,

    return: {
      color: { hex: DEFAULT_RETURN_COLOR_HEX },

      maxVelocityMS: 1,
      insulated: false,

      insulation: {
        material: InsulationMaterials.mmKemblaInsulation,
        jacket: InsulationJackets.allServiceJacket,
        thicknessMM: 25,
      },
    },

    networks: {
      risers: {
        spareCapacityPCT: 0,
        velocityMS: 1.2,
        pressureDropKPAM: 0.35,
        material: "copperTypeB",
        minimumPipeSize: 15,
      },
      reticulations: {
        spareCapacityPCT: 0,
        velocityMS: 1.2,
        pressureDropKPAM: 0.35,
        material: "copperTypeB",
        minimumPipeSize: 15,
      },
      connections: {
        spareCapacityPCT: 0,
        velocityMS: 3,
        pressureDropKPAM: 0.35,
        material: "pexSdr74",
        minimumPipeSize: 16,
      },
    },
  },
  {
    type: "gas",
    role: "gas",
    name: "Gas",
    color: { hex: "#FCDC00" },
    uid: StandardFlowSystemUids.Gas,
    temperatureC: 20,
    fluid: "naturalGas",
    defaultPipeHeightM: 3,

    networks: {
      risers: {
        velocityMS: 20,
        pressureDropKPAM: 0.35,
        material: "copperTypeB",
        minimumPipeSize: 15,
      },
      reticulations: {
        velocityMS: 20,
        pressureDropKPAM: 0.35,
        material: "copperTypeB",
        minimumPipeSize: 15,
      },
      connections: {
        velocityMS: 3,
        pressureDropKPAM: 0.35,
        material: "pexSdr74",
        minimumPipeSize: 16,
      },
    },
  },
  {
    type: "fire",
    role: "fire",
    name: "Fire Hydrant",
    temperatureC: 20,
    color: { hex: "#9F0500" },
    uid: StandardFlowSystemUids.FireHydrant,
    fluid: "water",
    defaultPipeHeightM: 3,

    networks: {
      risers: {
        spareCapacityPCT: 0,
        velocityMS: 4,
        pressureDropKPAM: 0.35,
        material: "gmsMedium",
        minimumPipeSize: 100,
      },
      reticulations: {
        spareCapacityPCT: 0,
        velocityMS: 4,
        pressureDropKPAM: 0.35,
        material: "gmsMedium",
        minimumPipeSize: 100,
      },
      connections: {
        spareCapacityPCT: 0,
        velocityMS: 4,
        pressureDropKPAM: 0.35,
        material: "gmsMedium",
        minimumPipeSize: 100,
      },
    },
  },
  {
    type: "fire",
    role: "fire",
    name: "Fire Sprinkler",
    temperatureC: 20,
    color: { hex: "#653294" },
    uid: StandardFlowSystemUids.FireSprinkler,
    fluid: "water",
    defaultPipeHeightM: 3,

    networks: {
      risers: {
        spareCapacityPCT: 0,
        velocityMS: 4,
        pressureDropKPAM: 0.35,
        material: "gmsMedium",
        minimumPipeSize: 15,
      },
      reticulations: {
        spareCapacityPCT: 0,
        velocityMS: 4,
        pressureDropKPAM: 0.35,
        material: "gmsMedium",
        minimumPipeSize: 15,
      },
      connections: {
        spareCapacityPCT: 0,
        velocityMS: 4,
        pressureDropKPAM: 0.35,
        material: "gmsMedium",
        minimumPipeSize: 15,
      },
    },
  },
  {
    type: "fire",
    role: "fire",
    name: "Fire Hose Reel",
    temperatureC: 20,
    color: { hex: "#FCDC00" },
    uid: StandardFlowSystemUids.FireHoseReel,
    fluid: "water",
    defaultPipeHeightM: 3,

    networks: {
      risers: {
        spareCapacityPCT: 0,
        velocityMS: 1.5,
        pressureDropKPAM: 0.35,
        material: "copperTypeB",
        minimumPipeSize: 25,
      },
      reticulations: {
        spareCapacityPCT: 0,
        velocityMS: 1.5,
        pressureDropKPAM: 0.35,
        material: "copperTypeB",
        minimumPipeSize: 25,
      },
      connections: {
        spareCapacityPCT: 0,
        velocityMS: 1.5,
        pressureDropKPAM: 0.35,
        material: "copperTypeB",
        minimumPipeSize: 25,
      },
    },
  },
  {
    type: "pressure",
    role: "coldwater",
    name: "Rainwater Reuse",
    temperatureC: 20,
    color: { hex: "#bf00ff" },
    uid: "rainwater-reuse",
    fluid: "water",
    defaultPipeHeightM: 3,

    hasReturnSystem: false,

    return: {
      color: { hex: DEFAULT_RETURN_COLOR_HEX },

      maxVelocityMS: 1,
      insulated: false,

      insulation: {
        material: InsulationMaterials.mmKemblaInsulation,
        jacket: InsulationJackets.allServiceJacket,
        thicknessMM: 25,
      },
    },

    networks: {
      risers: {
        spareCapacityPCT: 0,
        velocityMS: 1.5,
        pressureDropKPAM: 0.35,
        material: "stainlessSteel",
        minimumPipeSize: 15,
      },
      reticulations: {
        spareCapacityPCT: 0,
        velocityMS: 1.5,
        pressureDropKPAM: 0.35,
        material: "stainlessSteel",
        minimumPipeSize: 15,
      },
      connections: {
        spareCapacityPCT: 0,
        velocityMS: 3,
        pressureDropKPAM: 0.35,
        material: "stainlessSteel",
        minimumPipeSize: 16,
      },
    },
  },
  {
    type: "pressure",
    role: "coldwater",
    name: "Reverse Osmosis",
    temperatureC: 20,
    color: { hex: "#999999" },
    uid: "reverse-osmosis",
    fluid: "water",
    defaultPipeHeightM: 3,

    hasReturnSystem: false,

    return: {
      color: { hex: DEFAULT_RETURN_COLOR_HEX },

      maxVelocityMS: 1,
      insulated: false,

      insulation: {
        material: InsulationMaterials.mmKemblaInsulation,
        jacket: InsulationJackets.allServiceJacket,
        thicknessMM: 25,
      },
    },

    networks: {
      risers: {
        spareCapacityPCT: 0,
        velocityMS: 3,
        pressureDropKPAM: 0.35,
        material: "stainlessSteel",
        minimumPipeSize: 15,
      },
      reticulations: {
        spareCapacityPCT: 0,
        velocityMS: 3,
        pressureDropKPAM: 0.35,
        material: "stainlessSteel",
        minimumPipeSize: 15,
      },
      connections: {
        spareCapacityPCT: 0,
        velocityMS: 3,
        pressureDropKPAM: 0.35,
        material: "stainlessSteel",
        minimumPipeSize: 16,
      },
    },
  },
  ...MECHANICAL_FLOW_SYSTEMS(),
  ...UNDERFLOOR_FLOW_SYSTEMS(),
  ...DRAINAGE_FLOW_SYSTEMS(),
  ...STORMWATER_SYSTEM(),
  ...VENTILATION_SYSTEM(),
];
