import { uniq } from "lodash";
import { collect } from "../../../../lib/array-utils";
import { collectRecord, mapRecord } from "../../../../lib/record-utils";
import {
  assertUnreachable,
  betterObjectKeys,
  betterObjectValues,
} from "../../../../lib/utils";
import {
  Catalog,
  FloorFinish,
  Manufacturer,
  ManufacturerUid,
  PipeMaterial,
  PipeSpec,
  UnderfloorHeatingData,
} from "../../../catalog/types";
import {
  CoilModel,
  ComponentId,
  EdgeExpansionFoamModel,
} from "../../../catalog/underfloor-heating/ufh-types";
import { PipePhysicalMaterial } from "../../../config";
import { UnderfloorHeatingLoopCalculation } from "../../calculations-objects/underfloor-heating-loop-calculation";
import {
  RoomEntity,
  RoomEntityConcrete,
  UFHLoopDesignParameters,
  isRoomRoomEntity,
} from "./room-entity";

export interface CoilSpec {
  manufacturer: Manufacturer<"Underfloor Heating Coils">;
  model: CoilModel;
  pipeSpec: PipeSpec;
  pipeMaterial: PipeMaterial;
}

export function getUnderfloorDatasheet(
  catalog: Catalog,
  manufacturer: ManufacturerUid | null,
): UnderfloorHeatingData {
  const manuf = manufacturer ?? "generic";
  return catalog.heatEmitters.ufh.datasheet[manuf];
}

export function getUnderfloorHeatingEdgeExpansionFoams(catalog: Catalog): {
  manufacturer: Manufacturer<"UFH Edge Expansion Foam">;
  model: EdgeExpansionFoamModel;
}[] {
  const record = catalog.underfloorHeating.edgeExpansionFoam;
  return record.manufacturer.flatMap((manuf) => {
    let datasheetElement = record.datasheet[manuf.uid];
    return betterObjectValues(datasheetElement).map((model) => ({
      manufacturer: manuf,
      model,
    }));
  });
}

function getPipeSpecForCoil(
  model: CoilModel,
  catalog: Catalog,
  manuf: Manufacturer<"Underfloor Heating Coils">,
): PipeSpec | null {
  if (!model.pipeMaterial || !model.pipeSizeMM) {
    return null;
  }
  const material = catalog.pipes[model.pipeMaterial];

  if (!material) {
    console.warn("Missing Material: " + model.pipeMaterial);
    return null;
  }

  const byManufacturer = material.pipesBySize[manuf.uid];

  if (!byManufacturer) {
    console.warn(
      `Missing Manufacturer for ${model.pipeMaterial}: ` + manuf.uid,
    );
    return null;
  }

  return byManufacturer[model.pipeSizeMM];
}

// Only use this if you dot have a rollLength. Otherwise use getLoopCoilSpec
// The pipe specs should be the same for all compatible coils
export function getLoopPipeSpec(
  loop: UFHLoopDesignParameters,
  catalog: Catalog,
): PipeSpec | null {
  return getCompatibleCoilSpecs(loop, catalog).at(0)?.pipeSpec ?? null;
}

export function getAvailableCoilLengths(
  loop: UFHLoopDesignParameters | UnderfloorHeatingLoopCalculation,
  catalog: Catalog,
): number[] {
  if (loop.coilManufacturer === "generic") {
    return loop.genericCoilRollLengthsM ?? [];
  }
  return collect(
    getCompatibleCoilSpecs(loop, catalog),
    (coilSpec) => coilSpec.model.rollLengthM,
  );
}

export function getCompatibleCoilSpecs(
  loop: UFHLoopDesignParameters | UnderfloorHeatingLoopCalculation,
  catalog: Catalog,
): CoilSpec[] {
  if (!loop.coilManufacturer) {
    console.warn(
      "Missing Coil manufacturer (Are you sure this is a filled entity?): " +
        loop.coilManufacturer,
    );
    return [] as CoilSpec[];
  }
  return getUnderfloorHeatingCoilsForManufacturer(
    catalog,
    loop.coilManufacturer,
  ).filter(
    (coilSpec) =>
      coilSpec.model.pipeMaterial === loop.pipeMaterial &&
      coilSpec.model.pipeSizeMM === loop.pipeDiameterMM,
  );
}

export function getLoopCoilSpec(
  loop: UFHLoopDesignParameters | UnderfloorHeatingLoopCalculation,
  catalog: Catalog,
  rollLengthM: number,
): CoilSpec | null {
  if (!loop.coilManufacturer) {
    console.warn(
      "Missing Coil manufacturer (Are you sure this is a filled entity?): " +
        loop.coilManufacturer,
    );
    return null;
  }

  return (
    getUnderfloorHeatingCoilsForManufacturer(
      catalog,
      loop.coilManufacturer,
    ).find(
      (coilSpec) =>
        coilSpec.model.pipeMaterial === loop.pipeMaterial &&
        coilSpec.model.pipeSizeMM === loop.pipeDiameterMM &&
        (loop.coilManufacturer == "generic" ||
          coilSpec.model.rollLengthM === rollLengthM),
    ) ?? null
  );
}

export function getCoilSpec(
  [manufacturerId, modelId]: ComponentId,
  catalog: Catalog,
): CoilSpec | null {
  const model =
    catalog.underfloorHeating.coil.datasheet[manufacturerId][modelId];
  if (!model) {
    return null;
  }

  const manufacturer = catalog.underfloorHeating.coil.manufacturer.find(
    (x) => x.uid === manufacturerId,
  );

  const pipeMaterial = model.pipeMaterial
    ? catalog.pipes[model.pipeMaterial]
    : null;

  if (!pipeMaterial) {
    return null;
  }

  const pipeSpec = manufacturer
    ? getPipeSpecForCoil(model, catalog, manufacturer)
    : null;
  if (!model || !manufacturer || !pipeSpec) {
    console.warn("Missing Coil Spec: " + manufacturerId + " / " + modelId);
    return null;
  }
  return {
    manufacturer,
    model,
    pipeSpec,
    pipeMaterial,
  };
}

export function getUnderfloorHeatingCoils(catalog: Catalog): CoilSpec[] {
  const record = catalog.underfloorHeating.coil;
  return record.manufacturer.flatMap((manufacturer) => {
    return getUnderfloorHeatingCoilsForManufacturer(catalog, manufacturer.uid);
  });
}

export function getUnderfloorHeatingCoilsForManufacturer(
  catalog: Catalog,
  manufacturerUid: string,
): CoilSpec[] {
  const record = catalog.underfloorHeating.coil;
  return collect(
    betterObjectValues(record.datasheet[manufacturerUid]),
    (model) => getCoilSpec([manufacturerUid, model.model], catalog),
  );
}

export function getUnderfloorHeatingEdgeExpansionFoam(
  catalog: Catalog,
  [manufacturer, id]: ComponentId,
): {
  manufacturer: Manufacturer<"UFH Edge Expansion Foam">;
  model: EdgeExpansionFoamModel;
} {
  const record = catalog.underfloorHeating.edgeExpansionFoam;
  const datasheet = record.datasheet[manufacturer];
  return {
    manufacturer: record.manufacturer.find((x) => x.uid === manufacturer)!,
    model: betterObjectValues(datasheet).find((x) => x.model === id)!,
  };
}

export function getUnderfloorHeatingFloorFinish(
  catalog: Catalog,
): FloorFinish[] {
  const data = getUnderfloorDatasheet(catalog, null);
  return betterObjectKeys<FloorFinish>(data);
}

export function getUnderFloorPipeMaterials(catalog: Catalog): PipeMaterial[] {
  const data = getUnderfloorHeatingCoils(catalog);
  return uniq(data.map((x) => x.pipeMaterial));
}

export function getCoilManufacturersForMaterial(
  catalog: Catalog,
  material: PipePhysicalMaterial,
): Manufacturer<"Underfloor Heating Coils">[] {
  return collectRecord(
    getCoilMaterialsByManufacturerUid(catalog),
    ([manufacturer, materials]) => {
      if (materials.includes(material)) {
        return catalog.underfloorHeating.coil.manufacturer.find(
          (x) => x.uid === manufacturer,
        );
      }
      return undefined;
    },
  );
}

export function getCoilMaterialsByManufacturerUid(
  catalog: Catalog,
): Record<string, PipePhysicalMaterial[]> {
  return mapRecord(
    catalog.underfloorHeating.coil.datasheet,
    ([manufacturer]) => {
      return [
        manufacturer,
        getUnderFloorPipeMaterialsForManufacturer(
          catalog,
          String(manufacturer),
        ),
      ];
    },
  );
}

export function getUnderFloorPipeMaterialsForManufacturer(
  catalog: Catalog,
  manufacturer: ManufacturerUid,
): PipePhysicalMaterial[] {
  const coils: CoilModel[] = betterObjectValues(
    catalog.underfloorHeating.coil.datasheet[manufacturer],
  );
  return uniq(collect(coils, (x) => x.pipeMaterial));
}

export function getUnderFloorPipeMaterialsCIBSE(
  catalog: Catalog,
  floorFinish: FloorFinish,
): PipePhysicalMaterial[] {
  const data = getUnderfloorDatasheet(catalog, "generic");
  const keys = Object.keys(data[floorFinish] ?? {});

  if (keys.length === 0) {
    return [];
  }
  return keys as PipePhysicalMaterial[];
}

export function getDefaultUnderFloorPipeDiameter(
  catalog: Catalog,
  floorFinish: FloorFinish,
  pipeMaterial: PipePhysicalMaterial,
): number {
  return (
    getUnderFloorPipeDiameters(catalog, floorFinish, pipeMaterial).at(0) ?? 16
  );
}

export function getUnderFloorPipeDiameters(
  catalog: Catalog,
  floorFinish: FloorFinish,
  pipeMaterial: PipePhysicalMaterial,
): number[] {
  const data = getUnderfloorDatasheet(catalog, "generic");
  const keys = Object.keys(data[floorFinish]?.[pipeMaterial] ?? {});
  if (keys.length === 0) {
    return [];
  }
  return keys.map(Number);
}

export function getUnderFloorLoopSpacing(
  catalog: Catalog,
  manufacturer: ManufacturerUid | null = null,
): number[] {
  const data = getUnderfloorDatasheet(catalog, manufacturer);

  const ret: Set<number> = new Set();
  for (const a of Object.values(data)) {
    for (const b of Object.values(a)) {
      for (const c of Object.values(b)) {
        for (const d of Object.values(c)) {
          // @ts-ignore fix this later
          for (const e of Object.values(d)) {
            // @ts-ignore fix this later
            for (const f of e) {
              ret.add(f.pipeSpacingMM);
            }
          }
        }
      }
    }
  }

  return Array.from(ret);
}

export function floorTypeToMaterialType(
  floorType: RoomEntityConcrete["floorType"],
): "Bottom Floor" | "Suspended Floor" | "Party Floor" {
  switch (floorType) {
    case "bottom":
    case null:
      return "Bottom Floor";
    case "suspended":
      return "Suspended Floor";
    case "party":
      return "Party Floor";
  }
  assertUnreachable(floorType);
}

export function getRoomFloorMaterialType(room: RoomEntity) {
  if (isRoomRoomEntity(room)) {
    return floorTypeToMaterialType(room.room.floorType);
  }
  return "Bottom Floor";
}
