import { Coord } from "../../lib/coord";
import { cloneSimple } from "../../lib/utils";
import { GetPressureLossOptions } from "../calculations/entity-pressure-drops";
import { CoreContext, PressureLossResult } from "../calculations/types";
import { ArchitectureElementLiveCalculation } from "../document/calculations-objects/architectureElement-calculation";
import { CalculationConcrete } from "../document/calculations-objects/calculation-concrete";
import {
  ArchitectureElementEntity,
  fillDefaultArcFields,
} from "../document/entities/architectureElement-entity";
import { EntityType } from "../document/entities/types";
import { FlowSystem } from "../document/flow-systems";
import { CoreBoxed } from "./core-traits/coreBoxed";
import CoreConduit from "./coreConduit";
import { CoreCalculatableObject } from "./lib/CoreCalculatableObject";
import CoreBaseBackedObject from "./lib/coreBaseBackedObject";
import { SelectionTarget } from "./lib/types";

const Base = CoreBoxed(CoreCalculatableObject<ArchitectureElementEntity>);

export default class CoreArchitectureElement extends Base {
  type: EntityType.ARCHITECTURE_ELEMENT = EntityType.ARCHITECTURE_ELEMENT;

  get refPath(): string {
    return `${this.entity.type}.${this.entity.arcType}`;
  }

  get filledEntity(): ArchitectureElementEntity {
    return fillDefaultArcFields(this.context, this.entity);
  }

  getFrictionPressureLossKPA(
    options: GetPressureLossOptions,
  ): PressureLossResult {
    throw new Error("Method not implemented.");
  }

  costBreakdown(context: CoreContext): null {
    return null;
  }

  getCalculationEntities(context: CoreContext) {
    let res = cloneSimple(this.entity);
    res.uid = this.getCalculationUid(context);
    return [res];
  }
  collectCalculations(context: CoreContext): CalculationConcrete {
    return context.globalStore.getOrCreateCalculation(
      this.getCalculationEntities(context)[0],
    );
  }

  collectLiveCalculations(
    context: CoreContext,
  ): ArchitectureElementLiveCalculation {
    const result = context.globalStore.getOrCreateLiveCalculation(
      this.getCalculationEntities(context)[0],
    );

    return result;
  }

  preCalculationValidation(context: CoreContext): SelectionTarget | null {
    return null;
  }

  getConnectedPipe(
    connectionUid: string,
    flowSystem: FlowSystem,
  ): CoreConduit | null {
    for (const itemUid of this.globalStore.getConnections(connectionUid)) {
      const item = this.globalStore.getObjectOfType(
        EntityType.CONDUIT,
        itemUid,
      );
      if (item && item.entity.systemUid === flowSystem.uid) {
        return item;
      }
    }
    return null;
  }

  getCoreNeighbours(): CoreBaseBackedObject[] {
    const parent = this.globalStore.getSafe(this.entity.parentUid!);
    if (parent) {
      return [parent];
    }
    return [];
  }

  getPolygonCW(): Coord[] {
    return [
      {
        x: this.minX,
        y: this.minY,
      },
      {
        x: this.maxX,
        y: this.minY,
      },
      {
        x: this.maxX,
        y: this.maxY,
      },
      {
        x: this.minX,
        y: this.maxY,
      },
    ];
  }
}
