import { betterObjectEntries, isNotNullAndUndefined } from "./utils";

/**
 * Transform a record into a new record.
 *
 * Reminder: This can work on objects.
 */
export function mapRecord<
  K1 extends number | string,
  K2 extends number | string,
  V1,
  V2,
>(record: Record<K1, V1>, mapper: (v: [K1, V1]) => [K2, V2]): Record<K2, V2> {
  const result: Record<K2, V2> = {} as Record<K2, V2>;
  for (const [k, v] of betterObjectEntries<K1, V1>(record)) {
    const [newKey, newValue] = mapper([k, v]);
    result[newKey] = newValue;
  }
  return result;
}

export function reduceRecord<K extends number | string, V, T>(
  record: Record<K, V>,
  mapper: (
    previousValue: T,
    currentValue: [K, V],
    currentIndex: number,
    array: [K, V][],
  ) => T,
  initialValue: T,
): T {
  return betterObjectEntries<K, V>(record).reduce(mapper, initialValue);
}

export function collectRecord<K extends number | string, V, T>(
  record: Record<K, V>,
  mapper: (v: [K, V]) => T | null | undefined,
): T[] {
  return reduceRecord(
    record,
    (acc, [k, v]) => {
      const result = mapper([k, v]);
      if (isNotNullAndUndefined(result)) {
        acc.push(result);
      }
      return acc;
    },
    new Array<T>(),
  );
}
