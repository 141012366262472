import { assertUnreachable } from "../../../lib/utils";
import { AnnotationEntity } from "./annotations/annotation-entity";
import { ArchitectureElementEntity } from "./architectureElement-entity";
import AreaSegmentEntity from "./area-segment-entity";
import { BackgroundEntity } from "./background-entity";
import BigValveEntity from "./big-valve/big-valve-entity";
import { CompoundEntity } from "./compound-entities/compound-entity";
import ConduitEntity, { PipeEntityV1 } from "./conduit-entity";
import DamperEntity from "./damper-entity";
import DirectedValveEntity from "./directed-valves/directed-valve-entity";
import { EdgeEntity } from "./edge-entity";
import { FenEntity } from "./fenestration-entity";
import { FittingEntity } from "./fitting-entity";
import FixtureEntity from "./fixtures/fixture-entity";
import FlowSourceEntity from "./flow-source-entity";
import GasApplianceEntity from "./gas-appliance";
import { LineEntity } from "./lines/line-entity";
import LoadNodeEntity from "./load-node-entity";
import { MultiwayValveEntity } from "./multiway-valves/multiway-valve-entity";
import PlantEntity from "./plants/plant-entity";
import RiserEntity from "./riser-entity";
import { RoomEntity } from "./rooms/room-entity";
import { DrawableEntity } from "./simple-entities";
import { SystemNodeEntity } from "./system-node-entity";
import { EntityType } from "./types";
import { VertexEntity } from "./vertices/vertex-entity";
import { WallEntity } from "./wall-entity";

export type DrawableEntityConcrete =
  | BackgroundEntity
  | FittingEntity
  | ConduitEntity
  | RiserEntity
  | SystemNodeEntity
  | BigValveEntity
  | FixtureEntity
  | DirectedValveEntity
  | LoadNodeEntity
  | FlowSourceEntity
  | PlantEntity
  | GasApplianceEntity
  | CompoundEntity
  | MultiwayValveEntity
  | EdgeEntity
  | VertexEntity
  | RoomEntity
  | WallEntity
  | FenEntity
  | LineEntity
  | AnnotationEntity
  | ArchitectureElementEntity
  | DamperEntity
  | AreaSegmentEntity;

export type DrawableEntityConcreteV1 = DrawableEntityConcrete | PipeEntityV1;

export type ConnectableEntityConcrete =
  | FittingEntity
  | RiserEntity
  | SystemNodeEntity
  | DirectedValveEntity
  | LoadNodeEntity
  | FlowSourceEntity
  | MultiwayValveEntity
  | VertexEntity;

export type ConduitConnectableEntityConcrete = Exclude<
  ConnectableEntityConcrete,
  VertexEntity
>;

export type HasExplicitSystemUid =
  | FittingEntity
  | RiserEntity
  | SystemNodeEntity
  | ConduitEntity
  | FlowSourceEntity;

export type CenteredEntityConcrete =
  | BackgroundEntity
  | FittingEntity
  | RiserEntity
  | SystemNodeEntity
  | BigValveEntity
  | FixtureEntity
  | DirectedValveEntity
  | LoadNodeEntity
  | FlowSourceEntity
  | PlantEntity
  | GasApplianceEntity
  | CompoundEntity
  | MultiwayValveEntity
  | VertexEntity
  | AnnotationEntity
  | AreaSegmentEntity;

export type PositionedEntityConcrete =
  | BackgroundEntity
  | GasApplianceEntity
  | BigValveEntity
  | CompoundEntity
  | FixtureEntity
  | PlantEntity;

export type CalculatableEntityConcrete =
  | RiserEntity
  | ConduitEntity
  | BigValveEntity
  | FittingEntity
  | FixtureEntity
  | DirectedValveEntity
  | SystemNodeEntity
  | LoadNodeEntity
  | FlowSourceEntity
  | PlantEntity
  | GasApplianceEntity
  | CompoundEntity
  | MultiwayValveEntity
  | EdgeEntity
  | VertexEntity
  | RoomEntity
  | WallEntity
  | FenEntity
  | BoxedEntityConcrete
  | DamperEntity
  | AreaSegmentEntity;

export type EdgeLikeEntity =
  | ConduitEntity
  | EdgeEntity
  | FixtureEntity
  | BigValveEntity
  | PlantEntity
  | GasApplianceEntity
  | WallEntity
  | FenEntity
  | RoomEntity
  | LoadNodeEntity;

export type PolygonEntityConcrete = RoomEntity | AreaSegmentEntity;
export type BoxedEntityConcrete = ArchitectureElementEntity;

export type CoolDragEntityConcrete =
  | ConnectableEntityConcrete
  | EdgeLikeEntity
  | PolygonEntityConcrete;

export type NamedEntityConcrete =
  | FittingEntity
  | ConduitEntity
  | RiserEntity
  | BigValveEntity
  | FixtureEntity
  | DirectedValveEntity
  | FlowSourceEntity
  | CompoundEntity
  | MultiwayValveEntity
  | EdgeEntity
  | RoomEntity
  | WallEntity
  | FenEntity
  | ArchitectureElementEntity
  | DamperEntity
  | AreaSegmentEntity;

export type AttachableBackgroundEntityConcrete = BackgroundEntity | RoomEntity;

export function isAttachableBackgroundEntity(
  entity: DrawableEntity,
): entity is AttachableBackgroundEntityConcrete {
  switch (entity.type) {
    case EntityType.BACKGROUND_IMAGE:
    case EntityType.ROOM:
      return true;
    case EntityType.FITTING:
    case EntityType.SYSTEM_NODE:
    case EntityType.RISER:
    case EntityType.DIRECTED_VALVE:
    case EntityType.FLOW_SOURCE:
    case EntityType.VERTEX:
    case EntityType.LOAD_NODE:
    case EntityType.MULTIWAY_VALVE:
    case EntityType.BIG_VALVE:
    case EntityType.FIXTURE:
    case EntityType.GAS_APPLIANCE:
    case EntityType.EDGE:
    case EntityType.PLANT:
    case EntityType.CONDUIT:
    case EntityType.COMPOUND:
    case EntityType.WALL:
    case EntityType.FENESTRATION:
    case EntityType.LINE:
    case EntityType.ANNOTATION:
    case EntityType.ARCHITECTURE_ELEMENT:
    case EntityType.DAMPER:
    case EntityType.AREA_SEGMENT:
      return false;
  }
  assertUnreachable(entity.type);
}

export type EdgeEntityConcrete = ConduitEntity | EdgeEntity | LineEntity;
export type VirtualEdgeEntityConcrete = WallEntity | FenEntity;
export function isConnectableEntityType(
  eType: EntityType,
): eType is ConnectableEntityConcrete["type"] {
  switch (eType) {
    case EntityType.FITTING:
    case EntityType.SYSTEM_NODE:
    case EntityType.RISER:
    case EntityType.DIRECTED_VALVE:
    case EntityType.FLOW_SOURCE:
    case EntityType.VERTEX:
    case EntityType.LOAD_NODE:
    case EntityType.MULTIWAY_VALVE:
      return true;
    case EntityType.BIG_VALVE:
    case EntityType.FIXTURE:
    case EntityType.GAS_APPLIANCE:
    case EntityType.EDGE:
    case EntityType.PLANT:
    case EntityType.BACKGROUND_IMAGE:
    case EntityType.CONDUIT:
    case EntityType.COMPOUND:
    case EntityType.ROOM:
    case EntityType.WALL:
    case EntityType.FENESTRATION:
    case EntityType.LINE:
    case EntityType.ANNOTATION:
    case EntityType.ARCHITECTURE_ELEMENT:
    case EntityType.DAMPER:
    case EntityType.AREA_SEGMENT:
      return false;
  }
  assertUnreachable(eType);
}

export function isConnectableEntity(
  e: DrawableEntity,
): e is ConnectableEntityConcrete {
  switch (e.type) {
    case EntityType.FITTING:
    case EntityType.SYSTEM_NODE:
    case EntityType.RISER:
    case EntityType.DIRECTED_VALVE:
    case EntityType.FLOW_SOURCE:
    case EntityType.LOAD_NODE:
    case EntityType.MULTIWAY_VALVE:
    case EntityType.VERTEX:
      return true;
    case EntityType.BIG_VALVE:
    case EntityType.FIXTURE:
    case EntityType.GAS_APPLIANCE:
    case EntityType.PLANT:
    case EntityType.BACKGROUND_IMAGE:
    case EntityType.CONDUIT:
    case EntityType.COMPOUND:
    case EntityType.EDGE:
    case EntityType.ROOM:
    case EntityType.WALL:
    case EntityType.FENESTRATION:
    case EntityType.LINE:
    case EntityType.ANNOTATION:
    case EntityType.ARCHITECTURE_ELEMENT:
    case EntityType.DAMPER:
    case EntityType.AREA_SEGMENT:
      return false;
  }
  assertUnreachable(e.type);
}

export function isConduitConnectableEntity(
  e: DrawableEntity,
): e is ConduitConnectableEntityConcrete {
  switch (e.type) {
    case EntityType.FITTING:
    case EntityType.SYSTEM_NODE:
    case EntityType.RISER:
    case EntityType.DIRECTED_VALVE:
    case EntityType.FLOW_SOURCE:
    case EntityType.LOAD_NODE:
    case EntityType.MULTIWAY_VALVE:
      return true;
    case EntityType.BIG_VALVE:
    case EntityType.FIXTURE:
    case EntityType.GAS_APPLIANCE:
    case EntityType.PLANT:
    case EntityType.BACKGROUND_IMAGE:
    case EntityType.CONDUIT:
    case EntityType.COMPOUND:
    case EntityType.EDGE:
    case EntityType.VERTEX:
    case EntityType.ROOM:
    case EntityType.WALL:
    case EntityType.FENESTRATION:
    case EntityType.LINE:
    case EntityType.ANNOTATION:
    case EntityType.ARCHITECTURE_ELEMENT:
    case EntityType.DAMPER:
    case EntityType.AREA_SEGMENT:
      return false;
  }
  assertUnreachable(e.type);
}

export function isCenteredEntity(
  e: DrawableEntity,
): e is CenteredEntityConcrete {
  switch (e.type) {
    case EntityType.FITTING:
    case EntityType.SYSTEM_NODE:
    case EntityType.RISER:
    case EntityType.DIRECTED_VALVE:
    case EntityType.FLOW_SOURCE:
    case EntityType.LOAD_NODE:
    case EntityType.BIG_VALVE:
    case EntityType.FIXTURE:
    case EntityType.GAS_APPLIANCE:
    case EntityType.PLANT:
    case EntityType.COMPOUND:
    case EntityType.MULTIWAY_VALVE:
    case EntityType.VERTEX:
    case EntityType.ANNOTATION:
    case EntityType.AREA_SEGMENT:
      return true;
    case EntityType.BACKGROUND_IMAGE:
    case EntityType.CONDUIT:
    case EntityType.EDGE:
    case EntityType.ROOM:
    case EntityType.WALL:
    case EntityType.FENESTRATION:
    case EntityType.LINE:
    case EntityType.ARCHITECTURE_ELEMENT:
    case EntityType.DAMPER:
      return false;
  }
  assertUnreachable(e.type);
}

export function isBoxedEntity(e: DrawableEntity): e is BoxedEntityConcrete {
  switch (e.type) {
    case EntityType.ARCHITECTURE_ELEMENT:
      return true;
    case EntityType.FITTING:
    case EntityType.SYSTEM_NODE:
    case EntityType.RISER:
    case EntityType.DIRECTED_VALVE:
    case EntityType.FLOW_SOURCE:
    case EntityType.LOAD_NODE:
    case EntityType.BIG_VALVE:
    case EntityType.FIXTURE:
    case EntityType.GAS_APPLIANCE:
    case EntityType.PLANT:
    case EntityType.BACKGROUND_IMAGE:
    case EntityType.CONDUIT:
    case EntityType.COMPOUND:
    case EntityType.MULTIWAY_VALVE:
    case EntityType.VERTEX:
    case EntityType.EDGE:
    case EntityType.ROOM:
    case EntityType.WALL:
    case EntityType.FENESTRATION:
    case EntityType.LINE:
    case EntityType.ANNOTATION:
    case EntityType.DAMPER:
    case EntityType.AREA_SEGMENT:
      return false;
  }
  assertUnreachable(e.type);
}

export function isPositionedEntity(
  e: DrawableEntity,
): e is PositionedEntityConcrete {
  switch (e.type) {
    case EntityType.BACKGROUND_IMAGE:
    case EntityType.GAS_APPLIANCE:
    case EntityType.BIG_VALVE:
    case EntityType.COMPOUND:
    case EntityType.FIXTURE:
    case EntityType.PLANT:
      return true;
    case EntityType.FITTING:
    case EntityType.SYSTEM_NODE:
    case EntityType.RISER:
    case EntityType.DIRECTED_VALVE:
    case EntityType.FLOW_SOURCE:
    case EntityType.LOAD_NODE:
    case EntityType.MULTIWAY_VALVE:
    case EntityType.VERTEX:
    case EntityType.ANNOTATION:
    case EntityType.CONDUIT:
    case EntityType.EDGE:
    case EntityType.ROOM:
    case EntityType.WALL:
    case EntityType.FENESTRATION:
    case EntityType.LINE:
    case EntityType.ARCHITECTURE_ELEMENT:
    case EntityType.DAMPER:
    case EntityType.AREA_SEGMENT:
      return false;
  }
  assertUnreachable(e.type);
}

export function hasExplicitSystemUid(
  e: DrawableEntity,
): e is HasExplicitSystemUid {
  switch (e.type) {
    case EntityType.FITTING:
    case EntityType.SYSTEM_NODE:
    case EntityType.RISER:
    case EntityType.FLOW_SOURCE:
    case EntityType.CONDUIT:
      return true;
    case EntityType.DIRECTED_VALVE:
    case EntityType.LOAD_NODE:
    case EntityType.BIG_VALVE:
    case EntityType.FIXTURE:
    case EntityType.GAS_APPLIANCE:
    case EntityType.PLANT:
    case EntityType.BACKGROUND_IMAGE:
    case EntityType.COMPOUND:
    case EntityType.MULTIWAY_VALVE:
    case EntityType.VERTEX:
    case EntityType.EDGE:
    case EntityType.ROOM:
    case EntityType.WALL:
    case EntityType.FENESTRATION:
    case EntityType.LINE:
    case EntityType.ANNOTATION:
    case EntityType.ARCHITECTURE_ELEMENT:
    case EntityType.DAMPER:
    case EntityType.AREA_SEGMENT:
      return false;
  }
  assertUnreachable(e.type);
}

export function isCoolDragEntity(
  e: DrawableEntity,
): e is CoolDragEntityConcrete {
  switch (e.type) {
    case EntityType.FITTING:
    case EntityType.SYSTEM_NODE:
    case EntityType.RISER:
    case EntityType.DIRECTED_VALVE:
    case EntityType.FLOW_SOURCE:
    case EntityType.LOAD_NODE:
    case EntityType.MULTIWAY_VALVE:
    case EntityType.CONDUIT:
    case EntityType.FIXTURE:
    case EntityType.BIG_VALVE:
    case EntityType.PLANT:
    case EntityType.GAS_APPLIANCE:
    case EntityType.COMPOUND:
    case EntityType.VERTEX:
    case EntityType.ANNOTATION:
    case EntityType.LINE:
    case EntityType.ARCHITECTURE_ELEMENT:
    case EntityType.AREA_SEGMENT:
    case EntityType.EDGE:
    case EntityType.FENESTRATION:
      return true;
    case EntityType.WALL:
    case EntityType.ROOM:
    case EntityType.BACKGROUND_IMAGE:
    case EntityType.DAMPER:
      return false;
  }
  assertUnreachable(e.type);
}

// entities that can be connected to connectables.
export function isEdgeEntity(e: DrawableEntity): e is EdgeEntityConcrete {
  switch (e.type) {
    case EntityType.CONDUIT:
    case EntityType.EDGE:
    case EntityType.LINE:
      return true;
    case EntityType.FIXTURE:
    case EntityType.WALL:
    case EntityType.FENESTRATION:
    case EntityType.BIG_VALVE:
    case EntityType.PLANT:
    case EntityType.GAS_APPLIANCE:
    case EntityType.BACKGROUND_IMAGE:
    case EntityType.FITTING:
    case EntityType.SYSTEM_NODE:
    case EntityType.RISER:
    case EntityType.DIRECTED_VALVE:
    case EntityType.FLOW_SOURCE:
    case EntityType.LOAD_NODE:
    case EntityType.COMPOUND:
    case EntityType.MULTIWAY_VALVE:
    case EntityType.VERTEX:
    case EntityType.ROOM:
    case EntityType.ANNOTATION:
    case EntityType.ARCHITECTURE_ELEMENT:
    case EntityType.DAMPER:
    case EntityType.AREA_SEGMENT:
      return false;
  }
  assertUnreachable(e.type);
}
export function isVirtualEdgeEntity(
  e: DrawableEntity,
): e is VirtualEdgeEntityConcrete {
  switch (e.type) {
    case EntityType.ROOM:
    case EntityType.EDGE:
    case EntityType.FIXTURE:
    case EntityType.BIG_VALVE:
    case EntityType.PLANT:
    case EntityType.GAS_APPLIANCE:
    case EntityType.BACKGROUND_IMAGE:
    case EntityType.FITTING:
    case EntityType.SYSTEM_NODE:
    case EntityType.RISER:
    case EntityType.DIRECTED_VALVE:
    case EntityType.FLOW_SOURCE:
    case EntityType.LOAD_NODE:
    case EntityType.CONDUIT:
    case EntityType.COMPOUND:
    case EntityType.MULTIWAY_VALVE:
    case EntityType.VERTEX:
    case EntityType.LINE:
    case EntityType.ANNOTATION:
    case EntityType.ARCHITECTURE_ELEMENT:
    case EntityType.DAMPER:
    case EntityType.AREA_SEGMENT:
      return false;
    case EntityType.WALL:
    case EntityType.FENESTRATION:
      return true;
  }
  assertUnreachable(e.type);
}

export function isPolygonEntity(e: DrawableEntity): e is PolygonEntityConcrete {
  switch (e.type) {
    case EntityType.ROOM:
    case EntityType.AREA_SEGMENT:
      return true;
    case EntityType.EDGE:
    case EntityType.FIXTURE:
    case EntityType.BIG_VALVE:
    case EntityType.PLANT:
    case EntityType.GAS_APPLIANCE:
    case EntityType.BACKGROUND_IMAGE:
    case EntityType.FITTING:
    case EntityType.SYSTEM_NODE:
    case EntityType.RISER:
    case EntityType.DIRECTED_VALVE:
    case EntityType.FLOW_SOURCE:
    case EntityType.LOAD_NODE:
    case EntityType.CONDUIT:
    case EntityType.COMPOUND:
    case EntityType.MULTIWAY_VALVE:
    case EntityType.VERTEX:
    case EntityType.WALL:
    case EntityType.FENESTRATION:
    case EntityType.LINE:
    case EntityType.ANNOTATION:
    case EntityType.ARCHITECTURE_ELEMENT:
    case EntityType.DAMPER:
      return false;
  }
  assertUnreachable(e.type);
}

export function isArchitectureElementEntity(
  e: DrawableEntity,
): e is ArchitectureElementEntity {
  switch (e.type) {
    case EntityType.ARCHITECTURE_ELEMENT:
      return true;
    case EntityType.EDGE:
    case EntityType.FIXTURE:
    case EntityType.BIG_VALVE:
    case EntityType.PLANT:
    case EntityType.GAS_APPLIANCE:
    case EntityType.BACKGROUND_IMAGE:
    case EntityType.FITTING:
    case EntityType.SYSTEM_NODE:
    case EntityType.RISER:
    case EntityType.DIRECTED_VALVE:
    case EntityType.FLOW_SOURCE:
    case EntityType.LOAD_NODE:
    case EntityType.CONDUIT:
    case EntityType.COMPOUND:
    case EntityType.MULTIWAY_VALVE:
    case EntityType.VERTEX:
    case EntityType.WALL:
    case EntityType.FENESTRATION:
    case EntityType.LINE:
    case EntityType.ANNOTATION:
    case EntityType.ROOM:
    case EntityType.DAMPER:
    case EntityType.AREA_SEGMENT:
      return false;
  }
  assertUnreachable(e.type);
}

export type AttachableEntityConcrete =
  | RiserEntity
  | BigValveEntity
  | FixtureEntity
  | DirectedValveEntity
  | LoadNodeEntity
  | FlowSourceEntity
  | PlantEntity
  | GasApplianceEntity
  | MultiwayValveEntity
  | AnnotationEntity;

export function isAttachableEntity(
  e: DrawableEntity,
): e is AttachableEntityConcrete {
  switch (e.type) {
    case EntityType.RISER:
    case EntityType.BIG_VALVE:
    case EntityType.FIXTURE:
    case EntityType.DIRECTED_VALVE:
    case EntityType.LOAD_NODE:
    case EntityType.FLOW_SOURCE:
    case EntityType.PLANT:
    case EntityType.GAS_APPLIANCE:
    case EntityType.MULTIWAY_VALVE:
    case EntityType.ANNOTATION:
      return true;
    case EntityType.EDGE:
    case EntityType.BACKGROUND_IMAGE:
    case EntityType.FITTING:
    case EntityType.SYSTEM_NODE:
    case EntityType.CONDUIT:
    case EntityType.COMPOUND:
    case EntityType.VERTEX:
    case EntityType.WALL:
    case EntityType.FENESTRATION:
    case EntityType.LINE:
    case EntityType.ROOM:
    case EntityType.ARCHITECTURE_ELEMENT:
    case EntityType.DAMPER:
    case EntityType.AREA_SEGMENT:
      return false;
  }
  assertUnreachable(e.type);
}

export type TerminusEntityConcrete = DamperEntity;

export function isTerminusEntity(
  e: DrawableEntity,
): e is TerminusEntityConcrete {
  switch (e.type) {
    case EntityType.DAMPER:
      return true;
    case EntityType.RISER:
    case EntityType.BIG_VALVE:
    case EntityType.FIXTURE:
    case EntityType.DIRECTED_VALVE:
    case EntityType.LOAD_NODE:
    case EntityType.FLOW_SOURCE:
    case EntityType.PLANT:
    case EntityType.GAS_APPLIANCE:
    case EntityType.MULTIWAY_VALVE:
    case EntityType.ANNOTATION:
    case EntityType.EDGE:
    case EntityType.BACKGROUND_IMAGE:
    case EntityType.FITTING:
    case EntityType.SYSTEM_NODE:
    case EntityType.CONDUIT:
    case EntityType.COMPOUND:
    case EntityType.VERTEX:
    case EntityType.WALL:
    case EntityType.FENESTRATION:
    case EntityType.LINE:
    case EntityType.ROOM:
    case EntityType.ARCHITECTURE_ELEMENT:
    case EntityType.AREA_SEGMENT:
      return false;
  }
  assertUnreachable(e.type);
}

export function isNamedEntity(e: DrawableEntity): e is NamedEntityConcrete {
  switch (e.type) {
    case EntityType.FITTING:
    case EntityType.CONDUIT:
    case EntityType.RISER:
    case EntityType.BIG_VALVE:
    case EntityType.FIXTURE:
    case EntityType.DIRECTED_VALVE:
    case EntityType.FLOW_SOURCE:
    case EntityType.COMPOUND:
    case EntityType.MULTIWAY_VALVE:
    case EntityType.EDGE:
    case EntityType.VERTEX:
    case EntityType.ROOM:
    case EntityType.WALL:
    case EntityType.FENESTRATION:
    case EntityType.ARCHITECTURE_ELEMENT:
    case EntityType.DAMPER:
    case EntityType.AREA_SEGMENT:
      return true;
    case EntityType.LINE:
    case EntityType.ANNOTATION:
    case EntityType.GAS_APPLIANCE:
    case EntityType.SYSTEM_NODE:
    case EntityType.LOAD_NODE:
    case EntityType.PLANT:
    case EntityType.BACKGROUND_IMAGE:
      return false;
  }
  assertUnreachable(e.type);
}
