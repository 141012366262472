export const RADIATOR_CAPACITY_LKW = 11;
export const AHU_CAPACITY_LKW = 11;
export const AHU_DEFAULT_SUPPLY_PRESSURE_DROP_PA = 10;
export const AHU_DEFAULT_EXTRACT_PRESSURE_DROP_PA = 10;
export const DEFAULT_SCOP = 4;
export const DEFAULT_ROOMS_BELOW_TRANSPARENCY_PCT = 30;
export const DEFAULT_SPARE_HEAT_CAP = 0;
export const INTERNAL_WALL_WIDTH_MM = 150;
export const PARTY_WALL_WIDTH_MM = 150;
export const EXTERNAL_WALL_WIDTH_MM = 200;
export const DEFAULT_BASE_TEMPERATURE_C = 15.5;
export const DEFAULT_HEATING_DEGREE_DAYS = 2222;
export const DEFAULT_COOLING_DEGREE_DAYS = 2222;
export const DEFAULT_WINTER_TEMP_CUTOFF_PCT_THRESHOLD = 99;
export const DEFAULT_SUMMER_TEMP_CUTOFF_PCT_THRESHOLD = 99;
export const DEFAULT_WINTER_PARTY_TEMPERATURE_C = 10;
export const DEFAULT_SUMMER_PARTY_TEMPERATURE_C = 20;

export const DEFAULT_EPC_INFORMATION = {
  isDraft: false,
  epcNumber: "",
};
