import { isGas, isVentilation } from "../../../../../common/src/api/config";
import {
  Units,
  UnitsContext,
} from "../../../../../common/src/lib/measurements";
import { CoreContext } from "../../calculations/types";
import {
  DuctPressureLossPrimitive,
  DuctworkPrimitive,
} from "../../coreObjects/lib/ductFittingPrimitives";
import { FittingEntity } from "../entities/fitting-entity";
import { CalculationField, FieldCategory } from "./calculation-field";
import {
  CalcFieldSelector,
  Calculation,
  CalculationType,
  LiveCalculation,
  NameCalculation,
  PressureCalculation,
  addPressureCalculationFields,
} from "./types";
import { getFlowSystemLayouts } from "./utils";

export default interface FittingCalculation
  extends Calculation,
    PressureCalculation,
    NameCalculation {
  type: CalculationType.FittingCalculation;
  flowRateLS: number | null;
  pressureDropKPA: number | [number, number] | null;
  pressureByEndpointKPA: { [key: string]: number | null };
  kvValue: number | null;
  isVentExit: boolean | null;

  // TODO: give this love to risers too.
  // This is by network UID of all the connected conduits represented here.
  zetaByConnection: { [key: string]: number | null };
  // CIBSE probably wants this.
  pressureDropByConnectionKPA: { [key: string]: number | null };

  physicalDuctPrimitives: DuctworkPrimitive[] | null;
  pressureLossDuctPrimitives: DuctPressureLossPrimitive[] | null;
}

export interface FittingLiveCalculation extends LiveCalculation {
  isVentExit: boolean | null;

  physicalDuctPrimitives: DuctworkPrimitive[] | null;
  pressureLossDuctPrimitives: DuctPressureLossPrimitive[] | null;
}

export const FastLiveFittingCalculationFields: CalcFieldSelector<FittingLiveCalculation> =
  {
    isVentExit: true,
    connected: true,

    physicalDuctPrimitives: true,
    pressureLossDuctPrimitives: true,
  };

export function makeFittingCalculationFields(
  context: CoreContext,
  entity: FittingEntity,
): CalculationField[] {
  const { drawing, globalStore, catalog } = context;
  if (globalStore.getConnections(entity.uid).length === 0) {
    return [];
  }
  const result: CalculationField[] = [];
  const fittingIsGas =
    catalog && isGas(drawing.metadata.flowSystems[entity.systemUid!]);

  const system = drawing.metadata.flowSystems[entity.systemUid];
  const { layouts, layoutsExcept, onlyLayouts } = getFlowSystemLayouts(system);

  const iAmVentilation = isVentilation(system);

  result.push({
    property: "reference",
    title: "Reference",
    short: "",
    shortTitle: "",
    units: Units.None,
    category: FieldCategory.EntityName,
    systemUid: entity.systemUid,
    layouts: layouts,
    defaultEnabled: false,
  });

  if (!fittingIsGas) {
    result.push({
      property: "flowRateLS",
      title: "Peak Flow Rate",
      short: "",
      shortTitle: "",
      units: Units.LitersPerSecond,
      unitContext: iAmVentilation ? UnitsContext.VENTILATION : undefined,
      systemUid: entity.systemUid,
      category: FieldCategory.FlowRate,
      layouts: layoutsExcept("drainage"),
    });

    result.push({
      property: "pressureDropKPA",
      title: "Pressure Drop (PD)",
      short: "",
      shortTitle: "PD",
      units: Units.KiloPascals,
      systemUid: entity.systemUid,
      category: FieldCategory.Pressure,
      layouts: layoutsExcept("drainage"),
      unitContext: UnitsContext.SMALL_PRESSURE,
      format: (v: number | [number, number] | null) => {
        if (typeof v === "number") {
          return v.toFixed(3);
        } else if (v === null) {
          return "??";
        } else {
          return v[0].toFixed(3) + " to " + v[1].toFixed(3);
        }
      },
    });

    addPressureCalculationFields(context, result, entity.systemUid, layouts);
  }

  return result;
}

export function emptyFittingCalculation(): FittingCalculation {
  return {
    type: CalculationType.FittingCalculation,
    costBreakdown: null,
    cost: null,
    expandedEntities: null,

    flowRateLS: null,
    pressureDropKPA: null,
    pressureKPA: null,
    staticPressureKPA: null,
    warnings: null,
    pressureByEndpointKPA: {},
    entityName: null,
    kvValue: null,
    zetaByConnection: {},
    pressureDropByConnectionKPA: {},
    isVentExit: null,
    physicalDuctPrimitives: null,
    pressureLossDuctPrimitives: null,
  };
}

export function emptyFittingLiveCalculation(): FittingLiveCalculation {
  return {
    isVentExit: null,
    connected: null,
    warnings: [],
    physicalDuctPrimitives: null,
    pressureLossDuctPrimitives: null,
  };
}
