import Vue from "vue";
import { MutationTree } from "vuex";
import { Workflow } from "../../../../common/src/api/document/drawing";
import { BackgroundEntity } from "../../../../common/src/api/document/entities/background-entity";
import { SupportedLocales } from "../../../../common/src/api/locale";
import { betterObjectEntries } from "../../../../common/src/lib/utils";
import { AddressRecord } from "../../components/editor/lib/settings2/settingsfields2";
import {
  LevelWithoutEntities,
  ProjectSetupState,
  getInitProjectSetupState,
} from "./state";
const objectAssignDeep = require(`object-assign-deep`);

type BackgroundId = string;
type LevelId = string;
export const mutations: MutationTree<ProjectSetupState> = {
  setAddress(state, address: AddressRecord | null) {
    state.address = address;

    // set project name based on address
    const potentialProjectName = (() => {
      if (!address) return null;
      const split = address?.formattedAddress.split(",");
      const preFirstComma = split[0];
      return preFirstComma ?? null;
    })();
    if (!state.name && potentialProjectName) {
      state.name = potentialProjectName;
    }

    // set locale based on address
    const potentialLocale = (() => {
      if (!address) return null;
      if (address.country == "Australia") return SupportedLocales.AU;
      if (address.country == "United Kingdom") return SupportedLocales.UK;
      if (address.country == "United States") return SupportedLocales.US;
      return null;
    })();
    if (potentialLocale) {
      state.locale = potentialLocale;
    }
  },
  setName(state, name: string | null) {
    state.name = name;
  },
  setNumber(state, number: string | null) {
    state.number = number;
  },
  setLocale(state, locale: SupportedLocales | null) {
    state.locale = locale;
  },
  setAnyDocuments(state, anyDocuments: boolean) {
    state.anyDocuments = anyDocuments;
  },
  setWorkflowEnabled(
    state,
    { workflowKey, value }: { workflowKey: Workflow; value: boolean },
  ) {
    state.workflows[workflowKey].enabled = value;
  },
  toggleWorkflow(state, workflowKey: Workflow) {
    state.workflows[workflowKey].enabled =
      !state.workflows[workflowKey].enabled;
  },
  setShouldImportSettingsFromAnotherProject(state, shouldImport: boolean) {
    state.shouldImportSettingsFromAnotherProject = shouldImport;
  },
  setImportSettingsFrom(state, documentId: number) {
    state.importSettingsFrom = documentId;
  },
  addLevel(state, level: LevelWithoutEntities) {
    state.levels.push(level);
  },
  deleteLevel(state, levelId: LevelId) {
    // unassign all backgrounds that were on this level
    betterObjectEntries(state.backgroundToLevelAssignments).forEach(
      ([backgroundUid, levelUid]) => {
        if (levelUid == levelId) {
          Vue.set(state.backgroundToLevelAssignments, backgroundUid, undefined);
        }
      },
    );
    state.levels = state.levels.filter((l) => l.uid != levelId);
  },
  addBackground(state, background: BackgroundEntity) {
    state.backgrounds.push(background);
  },
  deleteBackground(state, backgroundId: BackgroundId) {
    state.backgrounds = state.backgrounds.filter((b) => b.uid != backgroundId);
    Vue.set(state.backgroundToLevelAssignments, backgroundId, undefined);
  },
  assignBackgroundToLevel(
    state,
    {
      backgroundUid,
      levelUid,
    }: { backgroundUid: BackgroundId; levelUid: LevelId },
  ) {
    Vue.set(state.backgroundToLevelAssignments, backgroundUid, levelUid);
  },
  unassignBackgroundFromLevel(
    state,
    { backgroundUid }: { backgroundUid: string },
  ) {
    Vue.set(state.backgroundToLevelAssignments, backgroundUid, undefined);
  },
  setMagicplanCustomerId(state, customerId: string) {
    state.magicplanCustomerId = customerId;
  },
  setMagicplanAccessToken(state, accessToken: string) {
    state.magicplanAccessToken = accessToken;
  },
  setShouldImportFromMagicplan(state, shouldImport: boolean) {
    state.shouldImportFromMagicplan = shouldImport;
  },
  reset(state) {
    // vue sucks.
    // ideally we could just: state = {...initProjectSetupState}
    // due to reactivity we have to mutate all fields instead.
    // shallowly, we can do this using Object.assign.
    // this breaks for deeper structures zzz eg. state.workflows.blah.enabled
    // so we use a deep version of Object.assign
    objectAssignDeep(state, getInitProjectSetupState());
  },
};
