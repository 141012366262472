import Flatten from "@flatten-js/core";
import { sumBy } from "lodash";
import RBush from "rbush";
import { Coord, coordSub } from "../../../lib/coord";
import {
  getAreaM2,
  polygonClipping,
  polygonIntersectionAreaM2,
} from "../../../lib/mathUtils/mathutils";
import {
  EPS,
  assertType,
  assertUnreachable,
  assertUnreachableAggressive,
  cloneSimple,
  interpolateTable,
} from "../../../lib/utils";
import {
  HeatLoadItem,
  HeatLoadSpecFiltered,
} from "../../catalog/heatload/types";
import { getManufacturerRecord } from "../../catalog/manufacturers/utils";
import {
  GenericRadiatorData,
  ManufacturerRadiatorData,
  RadiatorData,
} from "../../catalog/types";
import CoreFen from "../../coreObjects/coreFenestration";
import CorePlant from "../../coreObjects/corePlant";
import CoreRoom from "../../coreObjects/coreRoom";
import CoreWall from "../../coreObjects/coreWall";
import {
  externalSegmentDetermineDirectionCW as externalSegmentDetermineDirectionCCW,
  isFenManifestOnWall,
} from "../../coreObjects/utils";
import FenCalculation from "../../document/calculations-objects/fenestration-calculation";
import PlantCalculation from "../../document/calculations-objects/plant-calculation";
import { RoomCalculation } from "../../document/calculations-objects/room-calculation";
import SystemNodeCalculation from "../../document/calculations-objects/system-node-calculation";
import WallCalculation from "../../document/calculations-objects/wall-calculation";
import { addWarning } from "../../document/calculations-objects/warnings";
import { Level } from "../../document/drawing";
import {
  FenType,
  fillDefaultFenFields,
} from "../../document/entities/fenestration-entity";
import { fillPlantDefaults } from "../../document/entities/plants/plant-defaults";
import PlantEntity, {
  RadiatorPlantEntity,
  isHeatLoadPlant,
  isSpecifyRadiator,
} from "../../document/entities/plants/plant-entity";
import {
  HeatLoadPlant,
  PlantType,
  PressureMethod,
  ReturnSystemPlant,
  SpecifyRadiatorPlant,
} from "../../document/entities/plants/plant-types";
import {
  getPlantDatasheet,
  isDualSystemNodePlant,
  isRadiatorPlant,
  isRoomAssociatedPlant,
} from "../../document/entities/plants/utils";
import {
  RoomEntity,
  RoomEntityConcrete,
  RoomType,
  fillDefaultRoomFields,
  isRoomRoomEntity,
} from "../../document/entities/rooms/room-entity";
import { floorTypeToMaterialType } from "../../document/entities/rooms/utils";
import { EntityType } from "../../document/entities/types";
import { getFloorOrder } from "../../document/entities/utils";
import {
  WallType,
  fillDefaultWallFields,
} from "../../document/entities/wall-entity";
import { getFlowSystem } from "../../document/utils";
import { SpatialIndex } from "../../types";
import CalculationEngine from "../calculation-engine";
import { TraceCalculation } from "../flight-data-recorder";
import {
  DEFAULT_HEATING_RETURN_DELTA_C,
  DEFAULT_ROOM_TEMP_C,
  ReturnCalculations,
} from "../returns";
import { CoreContext } from "../types";
import {
  getBuildingsFromRooms,
  selectLeaderRoom,
  selectLeaderRoomsPerFloor,
} from "../underfloor-heating/utils";
import {
  getEffectiveHeatLoad,
  getSolarRadiationForDegree,
  getThermalTransmittance,
  getWallByFen,
  toTitleCase,
} from "../utils";
import {
  VentCalculations,
  VentZoneRecordWithMVHR,
} from "../ventilation/ventilation";
import { getHeatEmitterResult } from "./heat-emitter-results";
import {
  EntityHeatLoadCalculation,
  GlobalHeatLoadInfo,
  HEATLOAD_COMPONENT_TYPE_SET,
  HeatEmitterSpecs,
  HeatLoadComponentCalculationBase,
  HeatLoadComponentCalculationWithArea,
  HeatLoadComponentType,
  RoomHeatLoadCalculation,
} from "./heat-loss-result-type";
import {
  FRAGMENTAL_COMP_AREA_M2,
  doRoofCalculationsBaseOnSegmentation,
} from "./roof-calculation/roof-calculation";
import {
  CalculateRoofComponentConcrete,
  RoofComponentType,
  calculateOnSlopeHeightM,
} from "./roof-calculation/roof-types";
import { calculateCentroidCoord } from "./roof-calculation/utils";

type HeatLoadType = "Heat loss" | "Heat gain";
const LIGHTING_HEAT_GAIN_PER_M2 = 10;
const HEAT_CAPACITY_OF_AIR = 1.006;
const DENSITY_OF_AIR = 1.2;
interface HeatSourceEntry {
  entityUid: string;
  type: "heating" | "cooling";
  ratingWATT: number;
  worldCoord: Coord;
  levelUid: string;
  ratingType: "fixed" | "dynamic";
  associatedRooms: string[];
  specs: HeatEmitterSpecs[] | null;
}

interface RoomsAboveAndBelow {
  [key: string]: {
    // key = roomUid
    roomsAbove: { entity: RoomEntity; intersectAreaM2: number }[];
    roomsBelow: { entity: RoomEntity; intersectAreaM2: number }[];
  };
}

export class HeatLoadCalculations {
  /**
   * Utility functions
   */
  static heatLossEquation(
    temperatureDifference: number,
    thermalTransmittance: number,
    areaM2: number,
  ) {
    return temperatureDifference * thermalTransmittance * areaM2;
  }

  static heatLoad(context: CoreContext) {
    return context.drawing.metadata.heatLoss;
  }

  static createWallFenMapping(
    context: CoreContext,
    fensUids: string[],
    wallUids: string[],
  ): { wallToFens: Map<string, string[]>; fenToWall: Map<string, string> } {
    let wallToFens: Map<string, string[]> = new Map();
    let fenToWall: Map<string, string> = new Map();

    for (let fensUid of fensUids) {
      let fens = context.globalStore.getObjectOfTypeOrThrow(
        EntityType.FENESTRATION,
        fensUid,
      );
      if (fens.isManifested === false) continue;

      let wallUid = getWallByFen(context.globalStore, fens);
      if (wallUid === undefined) continue;

      wallToFens.set(wallUid, [fensUid, ...(wallToFens.get(wallUid) ?? [])]);
      fenToWall.set(fensUid, wallUid);
    }

    return { wallToFens, fenToWall };
  }

  static getRoomTemperature(context: CoreContext, roomEntity: RoomEntity) {
    let filledRoom = fillDefaultRoomFields(context, roomEntity);
    if (filledRoom.room.roomType === RoomType.ROOM) {
      return filledRoom.room.roomTemperatureC ?? 21;
    }
    return 21;
  }

  /**
   * This part code is not clean, but felt should be okay if made the parameter to be generic
   */
  static updateRoomHeatCalculations(
    roomCalculation: RoomHeatLoadCalculation,
    componentCalculation:
      | EntityHeatLoadCalculation
      | HeatLoadComponentCalculationBase
      | HeatLoadComponentCalculationWithArea,
    heatLoadType: HeatLoadComponentType,
    updateHeatLoss: boolean = true,
    updateHeatGain: boolean = true,
  ) {
    let getAreaM2 = (
      componentCalculation:
        | EntityHeatLoadCalculation
        | HeatLoadComponentCalculationBase
        | HeatLoadComponentCalculationWithArea,
    ): number => {
      if ("areaM2" in componentCalculation) {
        return componentCalculation.areaM2 ?? 0;
      }
      return 0;
    };

    if (roomCalculation.heatLoadResult[heatLoadType] === null) {
      switch (heatLoadType) {
        case HeatLoadComponentType.CEILING:
        case HeatLoadComponentType.EXTERNAL_DOOR:
        case HeatLoadComponentType.INTERNAL_DOOR:
        case HeatLoadComponentType.FLOOR:
        case HeatLoadComponentType.EXTERNAL_WALL:
        case HeatLoadComponentType.INTERNAL_WALL:
        case HeatLoadComponentType.PARTY_WALL:
        case HeatLoadComponentType.WINDOW:
        case HeatLoadComponentType.ROOF:
          roomCalculation.heatLoadResult[heatLoadType] = {
            headLoadType: heatLoadType,
            heatLossThroughComponentWatt: updateHeatLoss
              ? componentCalculation.heatLossThroughComponentWatt!
              : 0,
            heatGainThroughComponentWatt: updateHeatGain
              ? componentCalculation.heatGainThroughComponentWatt!
              : 0,
            areaM2: getAreaM2(componentCalculation),
          };
          break;
        case HeatLoadComponentType.VENTILATION:
        case HeatLoadComponentType.THERMAL_BRIDGING:
        case HeatLoadComponentType.INTERNAL_SOURCES:
        case HeatLoadComponentType.SOLAR_GAIN:
        case HeatLoadComponentType.SPARE_LOSS:
        case HeatLoadComponentType.SPARE_GAIN:
          roomCalculation.heatLoadResult[heatLoadType] = {
            headLoadType: heatLoadType,
            heatLossThroughComponentWatt: updateHeatLoss
              ? componentCalculation.heatLossThroughComponentWatt!
              : 0,
            heatGainThroughComponentWatt: updateHeatGain
              ? componentCalculation.heatGainThroughComponentWatt!
              : 0,
          };
          break;
        default:
          assertUnreachable(heatLoadType);
      }
    } else {
      if (updateHeatLoss) {
        roomCalculation.heatLoadResult[
          heatLoadType
        ].heatLossThroughComponentWatt =
          (roomCalculation.heatLoadResult[heatLoadType]
            .heatLossThroughComponentWatt || 0) +
          (componentCalculation.heatLossThroughComponentWatt ?? 0);
      }

      if (updateHeatGain) {
        roomCalculation.heatLoadResult[
          heatLoadType
        ].heatGainThroughComponentWatt =
          (roomCalculation.heatLoadResult[heatLoadType]
            .heatGainThroughComponentWatt || 0) +
          (componentCalculation.heatGainThroughComponentWatt ?? 0);
      }
      switch (heatLoadType) {
        case HeatLoadComponentType.CEILING:
        case HeatLoadComponentType.EXTERNAL_DOOR:
        case HeatLoadComponentType.INTERNAL_DOOR:
        case HeatLoadComponentType.FLOOR:
        case HeatLoadComponentType.EXTERNAL_WALL:
        case HeatLoadComponentType.INTERNAL_WALL:
        case HeatLoadComponentType.PARTY_WALL:
        case HeatLoadComponentType.WINDOW:
        case HeatLoadComponentType.ROOF:
          roomCalculation.heatLoadResult[heatLoadType].areaM2 =
            (roomCalculation.heatLoadResult[heatLoadType].areaM2 || 0) +
            getAreaM2(componentCalculation);
          break;
        case HeatLoadComponentType.SPARE_GAIN:
        case HeatLoadComponentType.SPARE_LOSS:
        case HeatLoadComponentType.VENTILATION:
        case HeatLoadComponentType.THERMAL_BRIDGING:
        case HeatLoadComponentType.INTERNAL_SOURCES:
        case HeatLoadComponentType.SOLAR_GAIN:
          break;
        default:
          assertUnreachable(heatLoadType);
      }
    }
  }

  static getHeatSourceEntriesByRoom(
    context: CalculationEngine,
    roomsRTree: Map<string, RBush<SpatialIndex>>,
  ) {
    let hsEntriesByRoom: Map<string, HeatSourceEntry[]> = new Map();
    for (const obj of context.networkObjects()) {
      const levelUid = context.globalStore.levelOfEntity.get(obj.uid)!;

      if (obj.type === EntityType.ROOM) {
        if (obj.entity.room.roomType !== RoomType.ROOM) continue;
        const room = obj.uid;

        const manifoldUid = obj.entity.room.underfloorHeating.manifoldUid;
        if (!manifoldUid) continue;

        const manifold = context.globalStore.getObjectOfType(
          EntityType.PLANT,
          manifoldUid,
        );
        if (!manifold) continue;

        if (!hsEntriesByRoom.has(room)) hsEntriesByRoom.set(room, []);
        const rCalc = context.globalStore.getOrCreateCalculation(obj.entity);

        hsEntriesByRoom.get(room)!.push({
          entityUid: manifoldUid,
          ratingWATT: rCalc.underfloorHeating.heatOutputW ?? 0,
          worldCoord: obj.toWorldCoord(),
          type: "heating",
          levelUid: levelUid,
          ratingType: this.getPlantRatingType(manifold),
          associatedRooms: [room],
          specs: null,
        });
      }

      if (obj.type === EntityType.PLANT) {
        const filled: PlantEntity = fillPlantDefaults(
          context,
          obj.entity,
          true,
        );
        const entityName = filled.name ?? filled.type;

        if (isHeatLoadPlant(filled.plant)) {
          const plantCalc = context.globalStore.getOrCreateCalculation(
            obj.entity,
          );

          const tree = roomsRTree.get(levelUid);
          const box = obj.shape?.box;
          let room: string | null = null;
          if (tree && box) {
            const res = tree.search({
              minX: box.xmin,
              minY: box.ymin,
              maxX: box.xmax,
              maxY: box.ymax,
            });

            const coord = obj.toWorldCoord();
            for (const r of res) {
              const roomObj = context.globalStore.getObjectOfTypeOrThrow(
                EntityType.ROOM,
                r.uid,
              );
              if (roomObj.shape.contains(Flatten.point(coord.x, coord.y))) {
                room = r.uid;
                break;
              }
            }
          }

          switch (filled.plant.type) {
            case PlantType.RADIATOR:
              if (room) {
                if (!hsEntriesByRoom.has(room)) hsEntriesByRoom.set(room, []);
                const modelName =
                  filled.plant.radiatorType === "specify" && filled.plant.model
                    ? `${filled.plant.model}`
                    : null;
                hsEntriesByRoom.get(room)!.push({
                  entityUid: obj.uid,
                  ratingWATT: (plantCalc.heatingRatingKW || 0) * 1000,
                  worldCoord: obj.toWorldCoord(),
                  type: "heating",
                  levelUid: levelUid,
                  ratingType: this.getPlantRatingType(obj),
                  associatedRooms: [room],
                  specs: this.getPlantSpecs(
                    context,
                    obj.entity,
                    filled.plant,
                    room,
                    modelName,
                    entityName,
                    plantCalc,
                    null,
                    null,
                    null,
                    null,
                  ).specs,
                });
              }
              break;
            // Note: be careful of doubling up on emitter counts when this is implemented with real
            // manifolds with perhaps an override of the manifold's own heat load output.
            case PlantType.UFH:
              if (room) {
                if (!hsEntriesByRoom.has(room)) hsEntriesByRoom.set(room, []);
                hsEntriesByRoom.get(room)!.push({
                  entityUid: obj.uid,
                  ratingWATT: (plantCalc.heatingRatingKW || 0) * 1000,
                  worldCoord: obj.toWorldCoord(),
                  type: "heating",
                  levelUid: levelUid,
                  ratingType: this.getPlantRatingType(obj),
                  associatedRooms: [room],
                  specs: this.getPlantSpecs(
                    context,
                    obj.entity,
                    filled.plant,
                    room,
                    null,
                    entityName,
                    plantCalc,
                    null,
                    null,
                    null,
                    null,
                  ).specs,
                });
              }
              break;
            case PlantType.AHU:
            case PlantType.AHU_VENT:
            case PlantType.FCU:
              // legacy FCUs have their heatingRooms and chilledRooms set to null -> they can only supply the room they are on top of
              if (
                filled.plant.heatingRooms === null ||
                filled.plant.chilledRooms === null
              ) {
                if (room) {
                  if (filled.plant.heatingInletUid) {
                    if (!hsEntriesByRoom.has(room))
                      hsEntriesByRoom.set(room, []);

                    hsEntriesByRoom.get(room)!.push({
                      entityUid: obj.uid,
                      ratingWATT: (plantCalc.heatingRatingKW || 0) * 1000,
                      worldCoord: obj.toWorldCoord(),
                      type: "heating",
                      levelUid: levelUid,
                      ratingType: this.getPlantRatingType(obj),
                      associatedRooms: [room],
                      specs: this.getPlantSpecs(
                        context,
                        obj.entity,
                        filled.plant,
                        room,
                        null,
                        entityName,
                        plantCalc,
                        null,
                        null,
                        null,
                        null,
                      ).specs,
                    });
                  }

                  if (filled.plant.chilledInletUid) {
                    if (!hsEntriesByRoom.has(room))
                      hsEntriesByRoom.set(room, []);

                    hsEntriesByRoom.get(room)!.push({
                      entityUid: obj.uid,
                      ratingWATT: (plantCalc.chilledRatingKW || 0) * 1000,
                      worldCoord: obj.toWorldCoord(),
                      type: "cooling",
                      levelUid: levelUid,
                      ratingType: this.getPlantRatingType(obj, "chilled"),
                      associatedRooms: [room],
                      specs: this.getPlantSpecs(
                        context,
                        obj.entity,
                        filled.plant,
                        room,
                        null,
                        entityName,
                        plantCalc,
                        null,
                        null,
                        null,
                        null,
                      ).specs,
                    });
                  }
                }
                continue;
              }

              if (filled.plant.heatingInletUid) {
                for (const room of filled.plant.heatingRooms ?? []) {
                  if (!hsEntriesByRoom.has(room)) hsEntriesByRoom.set(room, []);
                  hsEntriesByRoom.get(room)!.push({
                    entityUid: obj.uid,
                    ratingWATT:
                      filled.plant.heatingRating.type === "energy"
                        ? ((filled.plant.heatingRating.KW ?? 0) * 1000) /
                          filled.plant.heatingRooms.length
                        : 0,
                    worldCoord: obj.toWorldCoord(),
                    type: "heating",
                    levelUid: levelUid,
                    ratingType: this.getPlantRatingType(obj, "heating"),
                    associatedRooms: filled.plant.heatingRooms,
                    specs: this.getPlantSpecs(
                      context,
                      obj.entity,
                      filled.plant,
                      room,
                      null,
                      entityName,
                      plantCalc,
                      null,
                      null,
                      null,
                      null,
                    ).specs,
                  });
                }
              }

              if (filled.plant.chilledInletUid) {
                for (const room of filled.plant.chilledRooms ?? []) {
                  if (!hsEntriesByRoom.has(room)) hsEntriesByRoom.set(room, []);
                  hsEntriesByRoom.get(room)!.push({
                    entityUid: obj.uid,
                    ratingWATT:
                      filled.plant.chilledRating.type === "energy"
                        ? ((filled.plant.chilledRating.KW ?? 0) * 1000) /
                          filled.plant.chilledRooms.length
                        : 0,
                    worldCoord: obj.toWorldCoord(),
                    type: "cooling",
                    levelUid: levelUid,
                    ratingType: this.getPlantRatingType(obj, "chilled"),
                    associatedRooms: filled.plant.chilledRooms,
                    specs: this.getPlantSpecs(
                      context,
                      obj.entity,
                      filled.plant,
                      room,
                      null,
                      entityName,
                      plantCalc,
                      null,
                      null,
                      null,
                      null,
                    ).specs,
                  });
                }
              }
              break;
            case PlantType.MANIFOLD:
              break; // handled earlier
            case PlantType.RETURN_SYSTEM:
              break;
            default:
              assertUnreachable(filled.plant);
          }
        }
      }
    }

    return hsEntriesByRoom;
  }

  static getPlantRatingType(
    plant: CorePlant,
    supplyType: "heating" | "chilled" = "heating",
  ): "fixed" | "dynamic" {
    if (isRadiatorPlant(plant.entity.plant)) {
      switch (plant.entity.plant.radiatorType) {
        case "specify":
          if (plant.entity.plant.model) {
            return "fixed";
          }
          switch (plant.entity.plant.widthMM.type) {
            case "exact":
              return "fixed";
            case "upper":
              return "dynamic";
            default:
              assertUnreachable(plant.entity.plant.widthMM);
          }
        case "fixed":
          return "fixed";
        default:
          assertUnreachable(plant.entity.plant);
      }
    }

    if (isDualSystemNodePlant(plant.entity.plant)) {
      if (supplyType === "heating") {
        if (
          plant.entity.plant.heatingRating.type === "energy" &&
          plant.entity.plant.heatingRating.KW === null
        ) {
          return "dynamic";
        }
        return "fixed";
      }

      if (supplyType === "chilled") {
        if (
          plant.entity.plant.chilledRating.type === "energy" &&
          plant.entity.plant.chilledRating.KW === null
        ) {
          return "dynamic";
        }
        return "fixed";
      }
    }

    return "fixed";
  }

  static getPlantSpecs(
    engine: CalculationEngine,
    plantEntity: PlantEntity,
    plant: Exclude<HeatLoadPlant, ReturnSystemPlant>,
    roomUid: string | null,
    modelName: string | null,
    entityName: string | null,
    plantCalc: PlantCalculation,
    roomCalc: RoomCalculation | null,
    outputWATT: number | null,
    outputDelta50TempWATT: number | null,
    targetDemandWatt: number | null,
  ): { specs: HeatEmitterSpecs[]; ratingKW: number } {
    const filledEntity = fillPlantDefaults(engine, plantEntity);
    const filledPlant = filledEntity.plant as Exclude<
      HeatLoadPlant,
      ReturnSystemPlant
    >;
    if (entityName === null) {
      entityName = filledEntity.name ?? filledEntity.type;
    }
    let heightMM =
      plantCalc?.heightMM ?? plant?.heightMM ?? filledPlant?.heightMM ?? 0;
    if (typeof heightMM === "object" && "value" in heightMM) {
      heightMM = heightMM.value ?? 0;
    }

    let widthMM =
      plantCalc?.widthMM ??
      plant?.widthMM ??
      filledPlant?.widthMM ??
      filledEntity.widthMM ??
      0;
    if (typeof widthMM === "object" && "value" in widthMM) {
      widthMM = widthMM.value ?? 0;
    }

    let outletUid: string | null = "";
    switch (plant.type) {
      case PlantType.RADIATOR:
      case PlantType.MANIFOLD:
      case PlantType.UFH:
        outletUid = plant.outletUid;
        break;
      case PlantType.AHU:
      case PlantType.AHU_VENT:
      case PlantType.FCU:
        outletUid = plant.heatingOutletUid;
        break;
      default:
        assertUnreachable(plant);
    }

    const outletCalculation = (
      outletUid ? engine.globalStore.calculationStore.get(outletUid) : undefined
    ) as SystemNodeCalculation | undefined;

    return getHeatEmitterResult(engine.drawing.metadata.units, {
      uid: plantEntity.uid,
      roomUid,
      calc: plantCalc,
      roomCalc: roomCalc!,
      entity: plantEntity,
      name: modelName ?? entityName,
      width: widthMM,
      height: heightMM,
      type: plant.type,
      returnAverage: plantCalc.returnAverageC ?? 0,
      returnDelta: plantCalc.returnDeltaC ?? 0,
      fiftyDelta: outputDelta50TempWATT,
      heatingRatingWatt: outputWATT
        ? outputWATT
        : (plantCalc.heatingRatingKW ?? 0) * 1000,
      outletUid,
      targetDemandWatt: targetDemandWatt ?? 0,
      flowRateLS: outletCalculation?.flowRateLS ?? 0,
      loopId: undefined,
      heatingAreaM2: null,
    });
  }

  /**
   * Room Calculation
   * - Basic property
   * - Floor
   * - Roof
   * - Wall
   * - Fenestration
   * - Thermal Bridging
   * - Ventilation
   * - Internal Heat Gain
   * - Solar Gain
   * - Spare Capacity
   *   - Result!
   */
  @TraceCalculation("Calculating basic room property")
  static calculateRoomArea(
    context: CoreContext,
    entity: RoomEntity,
    concrete: RoomEntityConcrete,
  ) {
    let room = context.globalStore.getObjectOfTypeOrThrow(
      EntityType.ROOM,
      entity.uid,
    );
    let roomCalculation = context.globalStore.getOrCreateCalculation(entity);

    roomCalculation.areaM2 = room.areaM2;
    roomCalculation.perimeterM = room.perimeterM;
  }

  private static getNetHeatLossOrGain(
    heatLossW: number,
    calcType: HeatLoadType,
  ) {
    if (calcType === "Heat gain") {
      heatLossW *= -1;
    }
    return heatLossW > 0 ? heatLossW : 0;
  }

  @TraceCalculation("Calculating room's heat loss/heat gain through floor")
  static calculateFloorHeatLoadHelper(
    context: CoreContext,
    entity: RoomEntity,
    concrete: RoomEntityConcrete,
    roomsAboveAndBelow: RoomsAboveAndBelow,
    roomTemperatureCelsius: number,
    groundTemperatureC: number,
    calcType: HeatLoadType,
  ): number {
    const room = context.globalStore.getObjectOfTypeOrThrow(
      EntityType.ROOM,
      entity.uid,
    );
    const roomCalculation = context.globalStore.getOrCreateCalculation(entity);

    if (!isRoomRoomEntity(room.entity)) return 0;

    const roomsBelow = roomsAboveAndBelow[room.uid].roomsBelow ?? [];
    const floorType =
      room.entity.room.floorType ??
      (roomsBelow.length ? "suspended" : "bottom");
    roomCalculation.floorType = floorType;

    // need to get this again as floor type calculation has changed -> affects floorMaterialUid
    const filled = fillDefaultRoomFields(context, room.entity);

    let floorHeatLossW = 0;
    switch (floorType) {
      // heat loss for bottom floor type
      case "bottom": {
        const h = HeatLoadCalculations.heatLossEquation(
          roomTemperatureCelsius - groundTemperatureC,
          getThermalTransmittance(
            context.catalog,
            context.drawing,
            "Bottom Floor",
            filled.room.floorMaterialUid,
          ),
          room.areaM2,
        );
        floorHeatLossW = this.getNetHeatLossOrGain(h, calcType);
        break;
      }
      // heat loss for party floor type
      case "party": {
        const outsideTemp =
          calcType === "Heat loss"
            ? context.drawing.metadata.heatLoss.winterPartyTemperatureC
            : context.drawing.metadata.heatLoss.summerPartyTemperatureC;

        const h = HeatLoadCalculations.heatLossEquation(
          roomTemperatureCelsius - outsideTemp,
          getThermalTransmittance(
            context.catalog,
            context.drawing,
            "Party Floor",
            filled.room.floorMaterialUid,
          ),
          room.areaM2,
        );
        floorHeatLossW = this.getNetHeatLossOrGain(h, calcType);
        break;
      }
      // heat loss for suspended floor type
      case "suspended": {
        let remainingAreaM2 = room.areaM2;
        const thermalTrans = getThermalTransmittance(
          context.catalog,
          context.drawing,
          "Suspended Floor",
          filled.room.floorMaterialUid,
        );

        // add the heat loss for each overlapping room in lvl below
        for (const roomBelow of roomsBelow) {
          const roomBelowRoofThermalTrans = getThermalTransmittance(
            context.catalog,
            context.drawing,
            "Roof",
            roomBelow.entity.room.roofMaterialUid,
          );
          const roomBelowTemperatureCelsius =
            HeatLoadCalculations.getRoomTemperature(context, roomBelow.entity);

          const h = HeatLoadCalculations.heatLossEquation(
            roomTemperatureCelsius - roomBelowTemperatureCelsius,
            Math.min(roomBelowRoofThermalTrans, thermalTrans),
            roomBelow.intersectAreaM2,
          );

          floorHeatLossW += this.getNetHeatLossOrGain(h, calcType);
          remainingAreaM2 -= roomBelow.intersectAreaM2;
        }

        // if any area is uncovered, we assume outside temperature is ground
        if (remainingAreaM2 > EPS) {
          const h = HeatLoadCalculations.heatLossEquation(
            roomTemperatureCelsius - groundTemperatureC,
            thermalTrans,
            remainingAreaM2,
          );
          floorHeatLossW += this.getNetHeatLossOrGain(h, calcType);
        }

        break;
      }
      default:
        assertUnreachable(floorType);
    }

    if (filled.room.floorMaterialUid) {
      roomCalculation.materials[floorTypeToMaterialType(floorType)].push(
        filled.room.floorMaterialUid,
      );
    }

    return floorHeatLossW;
  }

  static calculateFloorHeatLoad(
    context: CoreContext,
    entity: RoomEntity,
    concrete: RoomEntityConcrete,
    roomsAboveAndBelow: RoomsAboveAndBelow,
  ) {
    let roomCalculation = context.globalStore.getOrCreateCalculation(entity);
    let filledEntity = fillDefaultRoomFields(context, entity);
    let room = context.globalStore.getObjectOfTypeOrThrow(
      EntityType.ROOM,
      entity.uid,
    );

    let heatLossWatt = HeatLoadCalculations.calculateFloorHeatLoadHelper(
      context,
      filledEntity,
      concrete,
      roomsAboveAndBelow,
      concrete.roomTemperatureC ?? 0,
      concrete.groundTemperatureC ?? 0,
      "Heat loss",
    );

    let heatGainWatt = HeatLoadCalculations.calculateFloorHeatLoadHelper(
      context,
      filledEntity,
      concrete,
      roomsAboveAndBelow,
      concrete.roomTemperatureC ?? 0,
      concrete.groundTemperatureC ?? 0,
      "Heat gain",
    );

    /**
     * Load Floor heatloss stat
     */
    roomCalculation.heatLoadResult["Floor"] = {
      headLoadType: HeatLoadComponentType.FLOOR,
      heatLossThroughComponentWatt: heatLossWatt,
      heatGainThroughComponentWatt: heatGainWatt,
      areaM2: room.areaM2,
    };
  }

  @TraceCalculation("Calculating room's heat loss/heat gain through ceiling")
  static calculatedCeilingHeatLoadHelper(
    context: CoreContext,
    entity: RoomEntity,
    concrete: RoomEntityConcrete,
    roomsAboveAndBelow: RoomsAboveAndBelow,
    roomTemperatureCelsius: number,
    calcType: HeatLoadType,
  ): {
    heatLoadWatt: number;
    areaM2: number;
  } {
    const room = context.globalStore.getObjectOfTypeOrThrow(
      EntityType.ROOM,
      entity.uid,
    );
    const roomCalc = context.globalStore.getOrCreateCalculation(room.entity);
    const roomsAbove = roomsAboveAndBelow[room.uid].roomsAbove ?? [];

    if (!isRoomRoomEntity(room.entity)) return { heatLoadWatt: 0, areaM2: 0 };

    let ceilingHeatLossW = 0;
    let remainingAreaM2 = room.areaM2;
    for (const roomAbove of roomsAbove) {
      if (!isRoomRoomEntity(roomAbove.entity)) continue;

      const roomAboveFloorType = roomAbove.entity.room.floorType ?? "suspended";
      const roomAboveTemperatureCelsius = (() => {
        switch (roomAboveFloorType) {
          case "bottom": {
            return concrete.groundTemperatureC ?? 0;
          }
          case "party":
            if (calcType === "Heat gain") {
              return context.drawing.metadata.heatLoss.summerPartyTemperatureC;
            }
            return context.drawing.metadata.heatLoss.winterPartyTemperatureC;
          case "suspended":
            return HeatLoadCalculations.getRoomTemperature(
              context,
              roomAbove.entity,
            );
        }
        assertUnreachable(roomAboveFloorType);
      })();

      const roomAboveThermalTrans = getThermalTransmittance(
        context.catalog,
        context.drawing,
        floorTypeToMaterialType(roomAboveFloorType),
        roomAbove.entity.room.floorMaterialUid,
      );

      const h = HeatLoadCalculations.heatLossEquation(
        roomTemperatureCelsius - roomAboveTemperatureCelsius,
        roomAboveThermalTrans, // room above is the one dictating the heat loss through ceiling for this room
        roomAbove.intersectAreaM2,
      );

      ceilingHeatLossW += this.getNetHeatLossOrGain(h, calcType);
      remainingAreaM2 -= roomAbove.intersectAreaM2;
    }

    if (concrete.roofMaterialUid) {
      roomCalc.materials["Roof"].push(concrete.roofMaterialUid);
    }

    return {
      heatLoadWatt: ceilingHeatLossW,
      areaM2: remainingAreaM2,
    };
  }

  static calculateCeilingHeatLoad(
    context: CoreContext,
    entity: RoomEntity,
    concrete: RoomEntityConcrete,
    roomsAboveAndBelow: RoomsAboveAndBelow,
  ) {
    let roomCalculation = context.globalStore.getOrCreateCalculation(entity);

    let { heatLoadWatt: heatLossWatt, areaM2 } =
      HeatLoadCalculations.calculatedCeilingHeatLoadHelper(
        context,
        entity,
        concrete,
        roomsAboveAndBelow,
        concrete.roomTemperatureC ?? 0,
        "Heat loss",
      );
    let { heatLoadWatt: heatGainWatt } =
      HeatLoadCalculations.calculatedCeilingHeatLoadHelper(
        context,
        entity,
        concrete,
        roomsAboveAndBelow,
        concrete.roomTemperatureC ?? 0,
        "Heat gain",
      );
    heatGainWatt *= -1;

    roomCalculation.heatLoadResult["Ceiling"] = {
      headLoadType: HeatLoadComponentType.CEILING,
      heatLossThroughComponentWatt: heatLossWatt,
      heatGainThroughComponentWatt: heatGainWatt,
      areaM2: areaM2,
    };
  }

  @TraceCalculation("Calculating room's heat loss/heat gain through roof")
  static calculateRoofHeatLoadHelper(
    context: CoreContext,
    entity: RoomEntity,
    concrete: RoomEntityConcrete,
    roomsAboveAndBelow: RoomsAboveAndBelow,
    cachePolygon: Map<string, Coord[]>,
    roomTemperatureCelsius: number,
    externalTemperatureCelsius: number,
    calcType: HeatLoadType,
  ): {
    roofHeatLoadWatt: number;
    roofAreaM2: number;
    externalWallHeatLoadWatt: number;
    externalWallAreaM2: number;
    windowHeatLoadWatt: number;
    windowAreaM2: number;
  } {
    let room = context.globalStore.getObjectOfTypeOrThrow(
      EntityType.ROOM,
      entity.uid,
    );
    let roomPolygon = cachePolygon.get(entity.uid) || [];

    let thermalTransmittance = getThermalTransmittance(
      context.catalog,
      context.drawing,
      "Roof",
      concrete.roofMaterialUid,
    );
    let roomCalculation = context.globalStore.getOrCreateCalculation(entity);

    let roofHeatLoadWattSum = 0;
    let roofAreaM2 = room.areaM2;
    let externalWallHeatLoadWatt = 0;
    let externalWallAreaM2 = 0;
    let veluxWindowHeatLoadWatt = 0;
    let veluxWindowAreaM2 = 0;
    let actualRoofAreaM2 = 0; // This considers the slope, will have larger area than plain polygon

    const roomsAbove = roomsAboveAndBelow[entity.uid]?.roomsAbove ?? [];

    for (const { entity: roofEntity, intersectAreaM2: areaM2 } of roomsAbove) {
      if (roofEntity.room.roomType !== RoomType.ROOF) {
        roofAreaM2 -= areaM2;
        continue;
      }

      let roofCalc = context.globalStore.getOrCreateCalculation(roofEntity);
      let roofComponents: CalculateRoofComponentConcrete[] =
        roofCalc.roofComponents;

      for (let component of roofComponents) {
        let intersectAreaM2 = polygonIntersectionAreaM2(
          component.polygonCw,
          roomPolygon,
        );

        if (Math.abs(intersectAreaM2) <= FRAGMENTAL_COMP_AREA_M2) {
          continue;
        }

        // Roof partly intersect with room
        roomCalculation.roomsAbove[roofEntity.uid] = roofEntity;
        switch (component.type) {
          case RoofComponentType.ROOFTOP:
            let roofComponentAreaM2 =
              intersectAreaM2 * component.slopeAreaConversionRatio;
            let roofComponentExternalWallAreaM2 =
              intersectAreaM2 * component.externalWallConversionRatio;

            if (roofComponentAreaM2 > 0) {
              let thermalTransmittanceRoof = getThermalTransmittance(
                context.catalog,
                context.drawing,
                "Roof",
                component.roofMaterialUid,
              );

              let heatLossWattThroughAboveWatt =
                HeatLoadCalculations.heatLossEquation(
                  roomTemperatureCelsius - externalTemperatureCelsius,
                  Math.min(thermalTransmittanceRoof, thermalTransmittance),
                  roofComponentAreaM2,
                );

              if (calcType === "Heat gain") heatLossWattThroughAboveWatt *= -1;
              roomCalculation.materials["Roof"].push(component.roofMaterialUid);

              if (heatLossWattThroughAboveWatt > 0) {
                roofHeatLoadWattSum += heatLossWattThroughAboveWatt;
              }
              actualRoofAreaM2 += roofComponentAreaM2;

              roofCalc.roofHeatLoad[HeatLoadComponentType.ROOF].areaM2 +=
                roofComponentAreaM2;
              if (calcType === "Heat gain") {
                roofCalc.roofHeatLoad[
                  HeatLoadComponentType.ROOF
                ].heatGainThroughComponentWatt += heatLossWattThroughAboveWatt;
              } else {
                roofCalc.roofHeatLoad[
                  HeatLoadComponentType.ROOF
                ].heatLossThroughComponentWatt += heatLossWattThroughAboveWatt;
              }
            }

            if (roofComponentExternalWallAreaM2 > 0) {
              let thermalTransmittanceWall = getThermalTransmittance(
                context.catalog,
                context.drawing,
                "External Wall",
                component.externalWallMaterialUid,
              );

              let heatLossWattThroughAboveWatt =
                HeatLoadCalculations.heatLossEquation(
                  roomTemperatureCelsius - externalTemperatureCelsius,
                  thermalTransmittanceWall,
                  roofComponentExternalWallAreaM2,
                );

              if (calcType === "Heat gain") heatLossWattThroughAboveWatt *= -1;
              roomCalculation.materials["External Wall"].push(
                component.externalWallMaterialUid,
              );

              if (heatLossWattThroughAboveWatt > 0) {
                externalWallHeatLoadWatt += heatLossWattThroughAboveWatt;
              }
              externalWallAreaM2 += roofComponentExternalWallAreaM2;

              roofCalc.roofHeatLoad[
                HeatLoadComponentType.EXTERNAL_WALL
              ].areaM2 += roofComponentExternalWallAreaM2;
              if (calcType === "Heat gain") {
                roofCalc.roofHeatLoad[
                  HeatLoadComponentType.EXTERNAL_WALL
                ].heatGainThroughComponentWatt += heatLossWattThroughAboveWatt;
              } else {
                roofCalc.roofHeatLoad[
                  HeatLoadComponentType.EXTERNAL_WALL
                ].heatLossThroughComponentWatt += heatLossWattThroughAboveWatt;
              }
            }
            roofAreaM2 -= intersectAreaM2;

            break;
          case RoofComponentType.WINDOW:
            let roofComponentWindowAreaM2 =
              intersectAreaM2 * component.slopeAreaConversionRatio;
            if (roofComponentWindowAreaM2 > 0) {
              let thermalTransmittanceWindow = getThermalTransmittance(
                context.catalog,
                context.drawing,
                "Window",
                component.windowMaterialUid,
              );

              let heatLossWattThroughAboveWatt =
                HeatLoadCalculations.heatLossEquation(
                  roomTemperatureCelsius - externalTemperatureCelsius,
                  thermalTransmittanceWindow,
                  roofComponentWindowAreaM2,
                );

              if (calcType === "Heat gain") heatLossWattThroughAboveWatt *= -1;
              roomCalculation.materials["Window"].push(
                component.windowMaterialUid,
              );

              if (heatLossWattThroughAboveWatt > 0) {
                veluxWindowHeatLoadWatt += heatLossWattThroughAboveWatt;
              }
              veluxWindowAreaM2 += roofComponentWindowAreaM2;

              roofCalc.roofHeatLoad[HeatLoadComponentType.WINDOW].areaM2 +=
                roofComponentWindowAreaM2;
              if (calcType === "Heat gain") {
                roofCalc.roofHeatLoad[
                  HeatLoadComponentType.WINDOW
                ].heatGainThroughComponentWatt += heatLossWattThroughAboveWatt;
              } else {
                roofCalc.roofHeatLoad[
                  HeatLoadComponentType.WINDOW
                ].heatLossThroughComponentWatt += heatLossWattThroughAboveWatt;
              }
            }
            break;
          default:
            assertUnreachable(component);
        }
      }
    }

    let flatRoofHeatLoadWatt = HeatLoadCalculations.heatLossEquation(
      roomTemperatureCelsius - externalTemperatureCelsius,
      thermalTransmittance,
      roofAreaM2,
    );
    if (calcType === "Heat gain") flatRoofHeatLoadWatt *= -1;
    actualRoofAreaM2 += roofAreaM2;

    if (flatRoofHeatLoadWatt > 0) roofHeatLoadWattSum += flatRoofHeatLoadWatt;

    return {
      roofHeatLoadWatt: roofHeatLoadWattSum,
      roofAreaM2: actualRoofAreaM2,
      externalWallHeatLoadWatt,
      externalWallAreaM2,
      windowAreaM2: veluxWindowAreaM2,
      windowHeatLoadWatt: veluxWindowHeatLoadWatt,
    };
  }
  static calculateRoofHeatLoad(
    context: CoreContext,
    entity: RoomEntity,
    concrete: RoomEntityConcrete,
    roomsAboveAndBelow: RoomsAboveAndBelow,
    cachePolygon: Map<string, Coord[]>,
  ) {
    let roomCalculation = context.globalStore.getOrCreateCalculation(entity);

    let {
      roofHeatLoadWatt: roofHeatLossWatt,
      externalWallHeatLoadWatt: externalWallHeatLossWatt,
      windowHeatLoadWatt: windowHeatLossWatt,
      roofAreaM2,
      externalWallAreaM2,
      windowAreaM2,
    } = HeatLoadCalculations.calculateRoofHeatLoadHelper(
      context,
      entity,
      concrete,
      roomsAboveAndBelow,
      cachePolygon,
      concrete.roomTemperatureC ?? 0,
      concrete.externalTemperatureC ??
        context.drawing.metadata.heatLoss.externalWinterTemperatureC,
      "Heat loss",
    );
    let {
      roofHeatLoadWatt: roofHeatGainWatt,
      windowHeatLoadWatt: windowHeatGainWatt,
      externalWallHeatLoadWatt: externalWallHeatGainWatt,
    } = HeatLoadCalculations.calculateRoofHeatLoadHelper(
      context,
      entity,
      concrete,
      roomsAboveAndBelow,
      cachePolygon,
      concrete.roomTemperatureC ?? 0,
      context.drawing.metadata.heatLoss.externalSummerTemperatureC,
      "Heat gain",
    );

    HeatLoadCalculations.updateRoomHeatCalculations(
      roomCalculation,
      {
        heatLossThroughComponentWatt: roofHeatLossWatt,
        heatGainThroughComponentWatt: roofHeatGainWatt,
        areaM2: roofAreaM2,
        headLoadType: HeatLoadComponentType.ROOF,
      },
      HeatLoadComponentType.ROOF,
      roofHeatLossWatt > 0,
      roofHeatGainWatt > 0,
    );
    HeatLoadCalculations.updateRoomHeatCalculations(
      roomCalculation,
      {
        heatLossThroughComponentWatt: externalWallHeatLossWatt,
        heatGainThroughComponentWatt: externalWallHeatGainWatt,
        areaM2: externalWallAreaM2,
        headLoadType: HeatLoadComponentType.EXTERNAL_WALL,
      },
      HeatLoadComponentType.EXTERNAL_WALL,
      externalWallHeatLossWatt > 0,
      externalWallHeatGainWatt > 0,
    );

    HeatLoadCalculations.updateRoomHeatCalculations(
      roomCalculation,
      {
        heatLossThroughComponentWatt: windowHeatLossWatt,
        heatGainThroughComponentWatt: windowHeatGainWatt,
        areaM2: windowAreaM2,
        headLoadType: HeatLoadComponentType.WINDOW,
      },
      HeatLoadComponentType.WINDOW,
      windowHeatLossWatt > 0,
      windowHeatGainWatt > 0,
    );
  }

  static getExternalTemperatureC(
    context: CoreContext,
    wallType: WallType,
    calcType: HeatLoadType,
    defaultExternalTemperatureC: number,
  ): number {
    if (wallType === WallType.party) {
      switch (calcType) {
        case "Heat loss":
          return context.drawing.metadata.heatLoss.winterPartyTemperatureC;
        case "Heat gain":
          return context.drawing.metadata.heatLoss.summerPartyTemperatureC;
        default:
          assertUnreachableAggressive(calcType);
      }
    }
    return defaultExternalTemperatureC;
  }

  @TraceCalculation("Calculating room's heat loss/heat gain through wall")
  static calculateIndividualWallHeatLoadHelper(
    context: CoreContext,
    entity: RoomEntity,
    concrete: RoomEntityConcrete,
    coreWall: CoreWall,
    cacheInternalWallToRoom: Map<string, CoreRoom[]>,
    cacheRoomToInternalWall: Map<string, string[]>,
    wallAttachedFensCache: Map<string, string[]>,
    externalTemperatureC: number,
    calcType: HeatLoadType,
  ) {
    let wallCalculation = context.globalStore.getOrCreateCalculation(
      coreWall.entity,
    );
    let roomCalculation = context.globalStore.getOrCreateCalculation(entity);
    let filledWall = fillDefaultWallFields(context, coreWall.entity);
    let fensUids = wallAttachedFensCache.get(coreWall.uid) || [];
    let fensAreaM2 = 0;
    for (let fensUid of fensUids) {
      let coreFen = context.globalStore.getObjectOfTypeOrThrow(
        EntityType.FENESTRATION,
        fensUid,
      );
      fensAreaM2 += coreFen.areaM2;
    }

    wallCalculation.uValueW_M2K = filledWall.uValueW_M2K;
    wallCalculation.heightM = concrete.roomHeightM;
    wallCalculation.lengthM = coreWall.lengthM;
    wallCalculation.areaM2 =
      wallCalculation.lengthM * (wallCalculation.heightM ?? 0) - fensAreaM2;

    const wallType =
      coreWall.entity.wallType === null
        ? coreWall.isInternalWall()
          ? WallType.internal
          : WallType.external
        : coreWall.entity.wallType;

    // Calculate the heatLoss from it
    let roomTemperatureC: number = concrete.roomTemperatureC ?? 0;
    wallCalculation.internalTemperatureC = roomTemperatureC;
    let uValue = wallCalculation.uValueW_M2K ?? 0;
    if (!coreWall.isManifested) {
      return wallCalculation;
    }

    if (coreWall.isInternalWall()) {
      let rooms = cacheInternalWallToRoom.get(coreWall.entity.uid);
      if (coreWall.isAutoInternalWall() && rooms) {
        let coreRoom = rooms.find((r) => r.uid !== entity.uid);
        if (!coreRoom) {
          throw new Error("Internal Wall don't have reference");
        }
        let filledAdjRoom = fillDefaultRoomFields(context, coreRoom.entity);

        let adjRoomConc = filledAdjRoom.room;
        if (adjRoomConc.roomType === RoomType.ROOF) {
          throw new Error("Roof can't attach wall");
        }

        externalTemperatureC = this.getExternalTemperatureC(
          context,
          wallType,
          calcType,
          adjRoomConc.roomTemperatureC ?? 0,
        );
        roomCalculation.roomsAdjacent[coreRoom.entity.uid] = coreRoom.entity;
      } else if (coreWall.isCustomInternalWall()) {
        externalTemperatureC =
          coreWall.entity.neighboringSpaceTemperatureC ?? 0;
      } else if (coreWall.isPartyWall()) {
        externalTemperatureC = this.getExternalTemperatureC(
          context,
          wallType,
          calcType,
          externalTemperatureC,
        );
      } else {
        throw new Error("Custom Internal Wall don't have required data");
      }

      wallCalculation.internalTemperatureC = roomTemperatureC;

      // calculate external winter/summer temperature
      // for internal walls you want this to be the minimum of the rooms the wall is in between of
      switch (calcType) {
        case "Heat loss":
          wallCalculation.externalWinterTemperatureC = Math.min(
            roomTemperatureC,
            externalTemperatureC,
          );
          break;
        case "Heat gain":
          wallCalculation.externalSummerTemperatureC = Math.max(
            roomTemperatureC,
            externalTemperatureC,
          );
          break;
        default:
          assertUnreachable(calcType);
      }
    } else {
      // external walls winter/summer temperature are always the actual external temperatures
      switch (calcType) {
        case "Heat loss":
          wallCalculation.externalWinterTemperatureC = externalTemperatureC;
          break;
        case "Heat gain":
          wallCalculation.externalSummerTemperatureC = externalTemperatureC;
          break;
        default:
          assertUnreachable(calcType);
      }
    }

    // calculate heat loss and heat gain through walls
    switch (calcType) {
      case "Heat loss": {
        const heatLossWatt = HeatLoadCalculations.heatLossEquation(
          roomTemperatureC - externalTemperatureC,
          uValue,
          wallCalculation.areaM2,
        );
        if (heatLossWatt > 0) {
          wallCalculation.heatLossThroughComponentWatt = heatLossWatt;
        }
        break;
      }
      case "Heat gain": {
        const heatGainWatt = HeatLoadCalculations.heatLossEquation(
          externalTemperatureC - roomTemperatureC,
          uValue,
          wallCalculation.areaM2,
        );
        if (heatGainWatt > 0) {
          wallCalculation.heatGainThroughComponentWatt = heatGainWatt;
        }
        break;
      }
      default:
        assertUnreachableAggressive(calcType);
    }

    if (
      filledWall.wallMaterialUid &&
      !roomCalculation.materials[wallType].includes(filledWall.wallMaterialUid)
    ) {
      roomCalculation.materials[wallType].push(filledWall.wallMaterialUid);
    }
  }

  static calculateIndividualWallHeatLoad(
    context: CoreContext,
    entity: RoomEntity,
    concrete: RoomEntityConcrete,
    coreWall: CoreWall,
    cacheInternalWallToRoom: Map<string, CoreRoom[]>,
    cacheRoomToInternalWall: Map<string, string[]>,
    wallAttachedFensCache: Map<string, string[]>,
  ): WallCalculation {
    let wallCalculation = context.globalStore.getOrCreateCalculation(
      coreWall.entity,
    );
    let filledWall = fillDefaultWallFields(context, coreWall.entity);
    HeatLoadCalculations.calculateIndividualWallHeatLoadHelper(
      context,
      entity,
      concrete,
      coreWall,
      cacheInternalWallToRoom,
      cacheRoomToInternalWall,
      wallAttachedFensCache,
      filledWall.externalTemperatureC ??
        context.drawing.metadata.heatLoss.externalWinterTemperatureC,
      "Heat loss",
    );
    HeatLoadCalculations.calculateIndividualWallHeatLoadHelper(
      context,
      entity,
      concrete,
      coreWall,
      cacheInternalWallToRoom,
      cacheRoomToInternalWall,
      wallAttachedFensCache,
      context.drawing.metadata.heatLoss.externalSummerTemperatureC,
      "Heat gain",
    );
    return wallCalculation;
  }

  static calculateWallHeatLoad(
    context: CoreContext,
    entity: RoomEntity,
    concrete: RoomEntityConcrete,
    cachePolygon: Map<string, Coord[]>,
    cacheInternalWallToRoom: Map<string, CoreRoom[]>,
    cacheRoomToInternalWall: Map<string, string[]>,
  ) {
    // Go through each edge, check the wall
    // Calculate the external wall heat loss
    let roomCalculation = context.globalStore.getOrCreateCalculation(entity);
    let polygonShapeCW = cachePolygon.get(entity.uid) || [];
    for (let edgeUid of entity.edgeUid) {
      let wallUids: string[] = context.globalStore.getWallsByRoomEdge(edgeUid);
      const fenUids = new Set<string>();
      for (const wallUid of wallUids) {
        const wall = context.globalStore.getObjectOfTypeOrThrow(
          EntityType.WALL,
          wallUid,
        );
        for (const edgeUid of wall.entity.polygonEdgeUid) {
          for (const fenUid of context.globalStore.getFensByRoomEdge(edgeUid)) {
            const fen = context.globalStore.getObjectOfTypeOrThrow(
              EntityType.FENESTRATION,
              fenUid,
            );
            if (!fenUids.has(fenUid) && isFenManifestOnWall(fen, wall)) {
              fenUids.add(fenUid);
            }
          }
        }
      }

      let { wallToFens, fenToWall } = HeatLoadCalculations.createWallFenMapping(
        context,
        Array.from(fenUids),
        wallUids,
      );

      for (let wallUid of wallUids) {
        let coreWall = context.globalStore.getObjectOfTypeOrThrow(
          EntityType.WALL,
          wallUid,
        );
        let wallCalc = HeatLoadCalculations.calculateIndividualWallHeatLoad(
          context,
          entity,
          concrete,
          coreWall,
          cacheInternalWallToRoom,
          cacheRoomToInternalWall,
          wallToFens,
        );

        if (coreWall.isInternalWall()) {
          HeatLoadCalculations.updateRoomHeatCalculations(
            roomCalculation,
            wallCalc,
            coreWall.isPartyWall()
              ? HeatLoadComponentType.PARTY_WALL
              : HeatLoadComponentType.INTERNAL_WALL,
            (concrete.roomTemperatureC ?? 0) >
              (wallCalc.externalWinterTemperatureC ?? 0), // Only update heatLoss if room have a higher temperature
            (concrete.roomTemperatureC ?? 0) <
              (wallCalc.externalSummerTemperatureC ?? 0), // Only update heatLoss if room have a higher temperature
          );
        } else {
          HeatLoadCalculations.updateRoomHeatCalculations(
            roomCalculation,
            wallCalc,
            HeatLoadComponentType.EXTERNAL_WALL,
          );
        }
      }

      for (let fens of fenUids) {
        let coreFens = context.globalStore.getObjectOfTypeOrThrow(
          EntityType.FENESTRATION,
          fens,
        );

        let isFenInternal = () => {
          let wallAttach = fenToWall.get(coreFens.entity.uid);
          let coreWall = context.globalStore.getObjectOfType(
            EntityType.WALL,
            wallAttach ?? "",
          );
          if (coreWall && coreWall.isInternalWall()) return true;
          return false;
        };
        let fenCalc = HeatLoadCalculations.calculateFensHeatLoad(
          context,
          entity,
          concrete,
          coreFens,
          cacheInternalWallToRoom,
          fenToWall,
          isFenInternal(),
        );

        switch (coreFens.entity.fenType) {
          case FenType.WINDOW:
            /**
             * Calculate solar HeatGain on top of that
             */
            HeatLoadCalculations.calculateHeatGainSolarGain(
              context,
              roomCalculation,
              coreFens,
              edgeUid,
            );

            HeatLoadCalculations.updateRoomHeatCalculations(
              roomCalculation,
              fenCalc,
              HeatLoadComponentType.WINDOW,
              isFenInternal()
                ? (concrete.roomTemperatureC ?? 0) >
                    (fenCalc.externalWinterTemperatureC ?? 0)
                : true,
              isFenInternal()
                ? (concrete.roomTemperatureC ?? 0) <
                    (fenCalc.externalSummerTemperatureC ?? 0)
                : true,
            );
            break;
          case FenType.DOOR:
            if (isFenInternal()) {
              HeatLoadCalculations.updateRoomHeatCalculations(
                roomCalculation,
                fenCalc,
                HeatLoadComponentType.INTERNAL_DOOR,
                (concrete.roomTemperatureC ?? 0) >
                  (fenCalc.externalWinterTemperatureC ?? 0), // Only update heatLoss if room have a higher temperature
                (concrete.roomTemperatureC ?? 0) <
                  (fenCalc.externalSummerTemperatureC ?? 0), // Only update heatLoss if room have a higher temperature
              );
            } else {
              HeatLoadCalculations.updateRoomHeatCalculations(
                roomCalculation,
                fenCalc,
                HeatLoadComponentType.EXTERNAL_DOOR,
              );
            }
            break;
          case FenType.LOOP_ENTRY:
            break;
          default:
            assertUnreachable(coreFens.entity);
        }
      }
    }
  }

  @TraceCalculation("Calculating fenestration's heat loss/cooling load")
  static calculateFensHeatLoad(
    context: CoreContext,
    roomEntity: RoomEntity,
    concrete: RoomEntityConcrete,
    coreFens: CoreFen,
    cacheInternalWallToRoom: Map<string, CoreRoom[]>,
    fenToWall: Map<string, string>,
    isInternal: boolean,
  ): FenCalculation {
    let fenCalculation: FenCalculation =
      context.globalStore.getOrCreateCalculation(coreFens.entity);
    let filledFens = fillDefaultFenFields(context, coreFens.entity);
    let roomCalculation =
      context.globalStore.getOrCreateCalculation(roomEntity);

    fenCalculation.lengthM = filledFens.fenestration.lengthM;
    fenCalculation.heightM = filledFens.fenestration.heightM;
    fenCalculation.areaM2 = coreFens.areaM2;
    fenCalculation.uValueW_M2K = filledFens.fenestration.uValueW_M2K ?? 0;
    fenCalculation.internalTemperatureC = concrete.roomTemperatureC ?? 0;

    let heatLossTempDiff =
      (concrete.roomTemperatureC ?? 0) -
      (filledFens.fenestration.externalTemperatureC ??
        HeatLoadCalculations.heatLoad(context).externalWinterTemperatureC);
    let heatGainTempDiff =
      HeatLoadCalculations.heatLoad(context).externalSummerTemperatureC -
      (concrete.roomTemperatureC ?? 0);

    let materialRole: "Internal Door" | "External Door" | "Window" | null =
      null;
    switch (filledFens.fenType) {
      case FenType.DOOR:
        if (isInternal) {
          materialRole = "Internal Door";
        } else {
          materialRole = "External Door";
        }
        break;
      case FenType.WINDOW:
        materialRole = "Window";
        break;
      case FenType.LOOP_ENTRY:
        materialRole = null;
        break;
      default:
        assertUnreachable(filledFens);
    }

    if (materialRole) {
      if (
        filledFens.fenestration.materialUid &&
        !roomCalculation.materials[materialRole].find(
          (material) => material === filledFens.fenestration.materialUid,
        )
      ) {
        roomCalculation.materials[materialRole].push(
          filledFens.fenestration.materialUid,
        );
      }
    }

    let wallUid = fenToWall.get(coreFens.entity.uid);
    let coreWall = context.globalStore.getObjectOfTypeOrThrow(
      EntityType.WALL,
      wallUid ?? "",
    );
    if (isInternal) {
      if (wallUid && coreWall.isInternalWall()) {
        let rooms = cacheInternalWallToRoom.get(coreWall.entity.uid);
        let concreteRoomTemperatureC = 0;
        if (rooms && coreWall.isAutoInternalWall()) {
          let room = rooms.find((r) => r.uid !== roomEntity.uid);
          if (!room) {
            throw new Error("Internal Wall don't have reference");
          }

          let otherRoomConcrete = fillDefaultRoomFields(context, room.entity);
          if (otherRoomConcrete.room.roomType === RoomType.ROOF) {
            throw new Error("Internal Wall can't be between room and roof");
          }

          concreteRoomTemperatureC =
            otherRoomConcrete.room.roomTemperatureC ?? 0;
        } else if (coreWall.isCustomInternalWall()) {
          concreteRoomTemperatureC =
            coreWall.entity.neighboringSpaceTemperatureC ?? 0;
        } else if (coreWall.isPartyWall()) {
          concreteRoomTemperatureC = this.getExternalTemperatureC(
            context,
            WallType.party,
            "Heat loss",
            concreteRoomTemperatureC,
          );
        } else {
          throw new Error("Custom Internal Wall don't have required data");
        }
        let heatLossResult = HeatLoadCalculations.heatLossEquation(
          Math.abs((concrete.roomTemperatureC ?? 0) - concreteRoomTemperatureC),
          fenCalculation.uValueW_M2K,
          fenCalculation.areaM2,
        );
        fenCalculation.externalSummerTemperatureC = Math.max(
          concrete.roomTemperatureC ?? 0,
          concreteRoomTemperatureC,
          this.getExternalTemperatureC(
            context,
            coreWall.entity.wallType ?? WallType.internal,
            "Heat gain",
            concreteRoomTemperatureC,
          ),
        );
        fenCalculation.externalWinterTemperatureC = Math.min(
          concrete.roomTemperatureC ?? 0,
          concreteRoomTemperatureC,
          this.getExternalTemperatureC(
            context,
            coreWall.entity.wallType ?? WallType.internal,
            "Heat loss",
            concreteRoomTemperatureC,
          ),
        );
        fenCalculation.heatLossThroughComponentWatt =
          fenCalculation.heatGainThroughComponentWatt = heatLossResult;
      }
    } else {
      let heatLossResult = HeatLoadCalculations.heatLossEquation(
        heatLossTempDiff,
        fenCalculation.uValueW_M2K,
        fenCalculation.areaM2,
      );
      if (heatLossResult > 0) {
        fenCalculation.heatLossThroughComponentWatt = heatLossResult;
      }
      let heatGainResult = HeatLoadCalculations.heatLossEquation(
        heatGainTempDiff,
        fenCalculation.uValueW_M2K,
        fenCalculation.areaM2,
      );
      if (heatGainResult > 0) {
        fenCalculation.heatGainThroughComponentWatt = heatGainResult;
      }
    }

    return fenCalculation;
  }

  @TraceCalculation("Calculating room's heat loss through thermal bridging")
  static calculateThermalBridgingHeatLoss(
    context: CoreContext,
    entity: RoomEntity,
  ) {
    let getMaterialHeatLoss = (calc: RoomCalculation) => {
      let sum = 0;
      for (let type of HEATLOAD_COMPONENT_TYPE_SET) {
        switch (type) {
          case HeatLoadComponentType.CEILING:
          case HeatLoadComponentType.INTERNAL_WALL:
          case HeatLoadComponentType.EXTERNAL_WALL:
          case HeatLoadComponentType.PARTY_WALL:
          case HeatLoadComponentType.ROOF:
          case HeatLoadComponentType.FLOOR:
          case HeatLoadComponentType.INTERNAL_DOOR:
          case HeatLoadComponentType.EXTERNAL_DOOR:
          case HeatLoadComponentType.WINDOW:
            sum += calc.heatLoadResult[type].heatLossThroughComponentWatt ?? 0;
            break;
          case HeatLoadComponentType.VENTILATION:
          case HeatLoadComponentType.THERMAL_BRIDGING:
          case HeatLoadComponentType.INTERNAL_SOURCES:
          case HeatLoadComponentType.SOLAR_GAIN:
          case HeatLoadComponentType.SPARE_GAIN:
          case HeatLoadComponentType.SPARE_LOSS:
            break;
          default:
            assertUnreachableAggressive(type);
        }
      }
      return sum;
    };
    let getMaterialHeatGain = (calc: RoomCalculation) => {
      let sum = 0;
      for (let type of HEATLOAD_COMPONENT_TYPE_SET) {
        switch (type) {
          case HeatLoadComponentType.CEILING:
          case HeatLoadComponentType.INTERNAL_WALL:
          case HeatLoadComponentType.EXTERNAL_WALL:
          case HeatLoadComponentType.PARTY_WALL:
          case HeatLoadComponentType.ROOF:
          case HeatLoadComponentType.FLOOR:
          case HeatLoadComponentType.INTERNAL_DOOR:
          case HeatLoadComponentType.EXTERNAL_DOOR:
          case HeatLoadComponentType.WINDOW:
            sum += calc.heatLoadResult[type].heatGainThroughComponentWatt ?? 0;
            break;
          case HeatLoadComponentType.VENTILATION:
          case HeatLoadComponentType.THERMAL_BRIDGING:
          case HeatLoadComponentType.INTERNAL_SOURCES:
          case HeatLoadComponentType.SOLAR_GAIN:
          case HeatLoadComponentType.SPARE_GAIN:
          case HeatLoadComponentType.SPARE_LOSS:
            break;
          default:
            assertUnreachableAggressive(type);
        }
      }
      return sum;
    };
    let roomCalculation = context.globalStore.getOrCreateCalculation(entity);
    let materialHeatLossWatt = getMaterialHeatLoss(roomCalculation);
    let materialHeatGainWatt = getMaterialHeatGain(roomCalculation);

    let thermalBridgingCoefficient =
      HeatLoadCalculations.heatLoad(context).thermalBridgingCoefficient;
    roomCalculation.thermalBridgingCoefficient = thermalBridgingCoefficient;
    let heatLossWatt = thermalBridgingCoefficient * materialHeatLossWatt;
    let heatGainWatt = thermalBridgingCoefficient * materialHeatGainWatt;
    roomCalculation.heatLoadResult["ThermalBridging"] = {
      headLoadType: HeatLoadComponentType.THERMAL_BRIDGING,
      heatLossThroughComponentWatt: heatLossWatt,
      heatGainThroughComponentWatt: heatGainWatt,
    };
  }

  @TraceCalculation(
    "Calculating room's heat loss/heat gain through ventilation",
  )
  static calculateVentilationHeatLoad(
    context: CoreContext,
    entity: RoomEntity,
    concrete: RoomEntityConcrete,
    effectiveHeatLoad: HeatLoadSpecFiltered,
    ventZones: VentZoneRecordWithMVHR[],
  ) {
    const roomCalculation = context.globalStore.getOrCreateCalculation(entity);
    const totalAirChangeRateLS = roomCalculation.heatingFlowRateLS ?? 0;

    let chimneyAirChangeRateLS = 0;
    if (concrete.chimneySetting !== "no") {
      chimneyAirChangeRateLS +=
        VentCalculations.calculateChimneyAirChangeRateLS(
          context,
          concrete.chimneySetting,
          roomCalculation.volumeM3 ?? 0,
        );
    }

    const roomAirChangeRateLS = totalAirChangeRateLS - chimneyAirChangeRateLS;

    const heatLossWatt =
      HeatLoadCalculations.calculateEffectiveVentHeatLossWatt(
        context,
        entity,
        concrete,
        roomAirChangeRateLS,
        chimneyAirChangeRateLS,
        ventZones,
      );

    const heatGainWatt =
      totalAirChangeRateLS *
      HEAT_CAPACITY_OF_AIR *
      DENSITY_OF_AIR *
      (HeatLoadCalculations.heatLoad(context).externalSummerTemperatureC -
        (concrete.roomTemperatureC ?? 0));

    HeatLoadCalculations.updateRoomHeatCalculations(
      roomCalculation,
      {
        headLoadType: HeatLoadComponentType.VENTILATION,
        heatLossThroughComponentWatt: heatLossWatt,
        heatGainThroughComponentWatt: heatGainWatt,
      },
      HeatLoadComponentType.VENTILATION,
      heatLossWatt > 0, // Only update heatLoss if room have a higher temperature
      heatGainWatt > 0, // Only update heatLoss if room have a higher temperature
    );
  }

  @TraceCalculation(
    "Calculating room's effective heat loss and MVHR heat recovery",
  )
  static calculateEffectiveVentHeatLossWatt(
    context: CoreContext,
    entity: RoomEntity,
    concrete: RoomEntityConcrete,
    roomAirChangeRateLS: number,
    chimneyAirChangeRateLS: number,
    ventZones: VentZoneRecordWithMVHR[],
  ): number {
    const zone = ventZones.find((z) =>
      z.rooms.some((r) => r.uid === entity.uid),
    );

    const heatLossPerLS =
      HEAT_CAPACITY_OF_AIR *
      DENSITY_OF_AIR *
      ((concrete.roomTemperatureC ?? 0) -
        HeatLoadCalculations.heatLoad(context).externalWinterTemperatureC);

    if (zone && zone.mvhrs.length > 0) {
      for (const mvhr of zone.mvhrs) {
        const rooms = mvhr.entity.plant.heatingRooms;
        const match = rooms?.some((rUid) => rUid === entity.uid);

        if (!match) continue;

        const efficiency = 1 - mvhr.efficiencyPct / 100;
        return (
          efficiency *
          heatLossPerLS *
          (roomAirChangeRateLS + chimneyAirChangeRateLS)
        );
      }
    }

    const globalPct =
      1 - context.drawing.metadata.heatLoss.ventHeatLossRecoveryPct / 100;

    return (
      globalPct * heatLossPerLS * (roomAirChangeRateLS + chimneyAirChangeRateLS)
    );
  }

  @TraceCalculation("Calculating room's heat loss/heat gain through internal")
  static calculateInternalHeatGain(
    context: CoreContext,
    entity: RoomEntity,
    concrete: RoomEntityConcrete,
  ) {
    let roomCalculation = context.globalStore.getOrCreateCalculation(entity);
    let internalHeatSource = concrete.internalHeatSource ?? {};
    let internalHeatSourceSetting =
      context.drawing.metadata.heatLoss.internalHeatSource;

    let heatGainWatt = 0;
    Object.entries(internalHeatSource).forEach(([key, heatSourceItem]) => {
      if (internalHeatSourceSetting[key]) {
        let heatLossWatts = internalHeatSourceSetting[key].heatSourceWatts;
        heatGainWatt += heatLossWatts * heatSourceItem.size;
      }
    });
    heatGainWatt += (roomCalculation.areaM2 ?? 0) * LIGHTING_HEAT_GAIN_PER_M2;
    roomCalculation.heatLoadResult["InternalSources"] = {
      headLoadType: HeatLoadComponentType.INTERNAL_SOURCES,
      heatLossThroughComponentWatt: 0,
      heatGainThroughComponentWatt: heatGainWatt,
    };
  }

  @TraceCalculation(
    "Calculating room's heat loss/heat gain through solar radiation",
  )
  static calculateHeatGainSolarGain(
    context: CoreContext,
    roomCalculation: RoomCalculation,
    coreFens: CoreFen,
    edgeUid: string,
  ) {
    let fenCalculation: FenCalculation =
      context.globalStore.getOrCreateCalculation(coreFens.entity);
    let heatGainCoefficient =
      HeatLoadCalculations.heatLoad(context).solarHeatGainCoefficient;
    let solarRadiationWPerMin =
      HeatLoadCalculations.heatLoad(context).solarRadiationWPerMin;

    // Need to know the direction
    let segmentCCW = externalSegmentDetermineDirectionCCW(
      context,
      coreFens.getWorldSegments()[0],
      [edgeUid],
    );
    let directionVector = coordSub(segmentCCW[0], segmentCCW[1]);
    let orthogonalVector = {
      x: directionVector.y,
      y: -directionVector.x,
    };
    // y is reversed in drawing
    orthogonalVector.y = -orthogonalVector.y;

    let radian = Math.atan2(orthogonalVector.y, orthogonalVector.x);

    // Measured from the top x axis, clockwise
    let externalFacingDegreeTopXCW = 270 - radian * (180 / Math.PI);
    externalFacingDegreeTopXCW = (externalFacingDegreeTopXCW + 360) % 360;

    let solarRadiationWPerMinByDegree = getSolarRadiationForDegree(
      solarRadiationWPerMin,
      externalFacingDegreeTopXCW,
    );

    let heatGainWatt =
      heatGainCoefficient *
      solarRadiationWPerMinByDegree *
      (fenCalculation.areaM2 ?? 0);
    fenCalculation.heatGainThroughComponentWatt =
      fenCalculation.heatGainThroughComponentWatt
        ? fenCalculation.heatGainThroughComponentWatt + heatGainWatt
        : heatGainWatt;
    fenCalculation.solarWPerMin = solarRadiationWPerMinByDegree;
    fenCalculation.externalFacingDegree = externalFacingDegreeTopXCW;

    if (
      roomCalculation.heatLoadResult["SolarGain"].heatGainThroughComponentWatt
    ) {
      roomCalculation.heatLoadResult[
        "SolarGain"
      ].heatGainThroughComponentWatt += heatGainWatt;
    } else {
      roomCalculation.heatLoadResult["SolarGain"].heatGainThroughComponentWatt =
        heatGainWatt;
    }
  }

  @TraceCalculation("Calculating room's Spare and Total heat loss/heat gain")
  static calculateTotalHeatLossGain(context: CoreContext, entity: RoomEntity) {
    let roomCalculation = context.globalStore.getOrCreateCalculation(entity);

    const filledRoomEntity = fillDefaultRoomFields(context, entity);
    const filledRoomConcrete = filledRoomEntity.room;
    if (filledRoomConcrete.roomType !== RoomType.ROOM) {
      throw new Error(
        "Can only calculate Spare and Total loss/heat gain for room room entities",
      );
    }

    let heatLossWattSum = 0,
      heatGainWattSum = 0;

    const gainCoe = filledRoomConcrete.spareHeatGainPercent! / 100;
    const lossCoe = filledRoomConcrete.spareHeatLossPercent! / 100;

    for (let type of HEATLOAD_COMPONENT_TYPE_SET) {
      switch (type) {
        case HeatLoadComponentType.CEILING:
        case HeatLoadComponentType.INTERNAL_WALL:
        case HeatLoadComponentType.EXTERNAL_WALL:
        case HeatLoadComponentType.PARTY_WALL:
        case HeatLoadComponentType.ROOF:
        case HeatLoadComponentType.FLOOR:
        case HeatLoadComponentType.INTERNAL_DOOR:
        case HeatLoadComponentType.EXTERNAL_DOOR:
        case HeatLoadComponentType.WINDOW:
        case HeatLoadComponentType.VENTILATION:
        case HeatLoadComponentType.THERMAL_BRIDGING:
        case HeatLoadComponentType.INTERNAL_SOURCES:
          heatLossWattSum +=
            roomCalculation.heatLoadResult[type].heatLossThroughComponentWatt ??
            0;
          heatGainWattSum +=
            roomCalculation.heatLoadResult[type].heatGainThroughComponentWatt ??
            0;
          break;
        case HeatLoadComponentType.SPARE_GAIN:
        case HeatLoadComponentType.SPARE_LOSS:
          break;
        case HeatLoadComponentType.SOLAR_GAIN:
          // Solar heat gain is duplicated in the room by the fenestration
          // so should not contribute to the total.
          break;
        default:
          assertUnreachableAggressive(type);
      }
    }

    roomCalculation.heatLoadResult[
      HeatLoadComponentType.SPARE_LOSS
    ].heatLossThroughComponentWatt = heatLossWattSum * lossCoe;

    roomCalculation.heatLoadResult[
      HeatLoadComponentType.SPARE_GAIN
    ].heatGainThroughComponentWatt = heatGainWattSum * gainCoe;

    const totalHeatLossOverrideWatt =
      entity.room.roomType === RoomType.ROOM
        ? entity.room.totalHeatLossWatt
        : null;

    roomCalculation.totalHeatLossWatt =
      totalHeatLossOverrideWatt ??
      heatLossWattSum +
        roomCalculation.heatLoadResult[HeatLoadComponentType.SPARE_LOSS]
          .heatLossThroughComponentWatt;

    roomCalculation.totalHeatGainWatt =
      heatGainWattSum +
      roomCalculation.heatLoadResult[HeatLoadComponentType.SPARE_GAIN]
        .heatGainThroughComponentWatt;
  }

  static calculateRoomsAboveAndBelow(
    context: CalculationEngine,
    cachePolygon: Map<string, Coord[]>,
    roomsConfigure: Map<string, RoomEntity[]>,
  ): RoomsAboveAndBelow {
    const result: RoomsAboveAndBelow = {};
    const sortedLevels = getFloorOrder(context.drawing);

    const getOverlappingArea = (roomUid: string, otherRoomUid: string) => {
      const roomPolygon = cachePolygon.get(roomUid) || [];
      const otherRoomPolygon = cachePolygon.get(otherRoomUid) || [];

      return polygonIntersectionAreaM2(roomPolygon, otherRoomPolygon);
    };

    for (const [levelUid, roomEntities] of roomsConfigure.entries()) {
      for (const room of roomEntities) {
        result[room.uid] = {
          roomsAbove: [],
          roomsBelow: [],
        };

        const levelBelowRooms = this.getLevelBelow(
          levelUid,
          sortedLevels,
          roomsConfigure,
        );
        const levelAboveRooms = this.getLevelAbove(
          levelUid,
          sortedLevels,
          roomsConfigure,
        );

        for (const roomBelow of levelBelowRooms) {
          const areaM2 = getOverlappingArea(room.uid, roomBelow.uid);

          if (areaM2 !== 0) {
            const calc = context.globalStore.getOrCreateCalculation(room);
            calc.roomsBelow[roomBelow.uid] = roomBelow;
            result[room.uid].roomsBelow.push({
              entity: roomBelow,
              intersectAreaM2: areaM2,
            });
          }
        }

        for (const roomAbove of levelAboveRooms) {
          const areaM2 = getOverlappingArea(room.uid, roomAbove.uid);

          if (areaM2 !== 0) {
            const calc = context.globalStore.getOrCreateCalculation(room);
            calc.roomsAbove[roomAbove.uid] = roomAbove;
            result[room.uid].roomsAbove.push({
              entity: roomAbove,
              intersectAreaM2: areaM2,
            });
          }
        }
      }
    }

    return result;
  }

  @TraceCalculation(
    "Calculating room's heat loss/heat gain",
    (_1, entity, _2, _3, _4, _5, _6) => {
      return [entity.uid];
    },
  )
  static calculateHeatLossCoolingIndividualRoom(
    context: CoreContext,
    entity: RoomEntity,
    roomsAboveAndBelow: RoomsAboveAndBelow,
    cachePolygon: Map<string, Coord[]>,
    cacheInternalWallToRoom: Map<string, CoreRoom[]>,
    cacheRoomToInternalWall: Map<string, string[]>,
    ventZones: VentZoneRecordWithMVHR[],
  ) {
    let filledRoomEntity = fillDefaultRoomFields(context, entity);
    const effectiveHeatLoad = getEffectiveHeatLoad(
      context.catalog,
      context.drawing,
    );

    switch (filledRoomEntity.room.roomType) {
      case RoomType.ROOM:
        HeatLoadCalculations.calculateRoomArea(
          context,
          entity,
          filledRoomEntity.room,
        );

        HeatLoadCalculations.calculateFloorHeatLoad(
          context,
          entity,
          filledRoomEntity.room,
          roomsAboveAndBelow,
        );

        HeatLoadCalculations.calculateCeilingHeatLoad(
          context,
          entity,
          filledRoomEntity.room,
          roomsAboveAndBelow,
        );

        HeatLoadCalculations.calculateRoofHeatLoad(
          context,
          entity,
          filledRoomEntity.room,
          roomsAboveAndBelow,
          cachePolygon,
        );

        HeatLoadCalculations.calculateWallHeatLoad(
          context,
          entity,
          filledRoomEntity.room,
          cachePolygon,
          cacheInternalWallToRoom,
          cacheRoomToInternalWall,
        );

        // Thermal Bridging only apply to material
        HeatLoadCalculations.calculateThermalBridgingHeatLoss(context, entity);

        // Assumption: Finished calculation of basic property of a room
        // Including roof's calculation
        HeatLoadCalculations.calculateVentilationHeatLoad(
          context,
          entity,
          filledRoomEntity.room,
          effectiveHeatLoad,
          ventZones,
        );
        HeatLoadCalculations.calculateInternalHeatGain(
          context,
          entity,
          filledRoomEntity.room,
        );
        HeatLoadCalculations.calculateTotalHeatLossGain(context, entity);

        // Process materials
        let roomCalc = context.globalStore.getOrCreateCalculation(entity);
        let materials: HeatLoadItem[] = [
          "External Wall",
          "Internal Wall",
          "Window",
          "External Door",
          "Internal Door",
          "Roof",
          "Bottom Floor",
          "Suspended Floor",
          "Party Floor",
        ];
        for (let materialKey of materials) {
          roomCalc.materials[materialKey] = [
            ...new Set(roomCalc.materials[materialKey]),
          ];
        }
      case RoomType.ROOF:
        break;
    }
  }

  @TraceCalculation("Calculating room volume")
  static calculateRoomVolume(
    context: CalculationEngine,
    roomsConfigure: Map<string, RoomEntity[]>,
    cachePolygon: Map<string, Coord[]>,
  ) {
    for (const room of context.networkObjects()) {
      if (room.type !== EntityType.ROOM) continue;
      if (!isRoomRoomEntity(room.entity)) continue;

      const lvlUid = context.globalStore.levelOfEntity.get(room.uid)!;
      let sortedLevels = getFloorOrder(context.drawing);
      const levelAboveEntities = this.getLevelAbove(
        lvlUid,
        sortedLevels,
        roomsConfigure,
      );
      const roomPolygon = cachePolygon.get(room.uid) || [];

      const filled = fillDefaultRoomFields(context, room.entity).room;

      const baseVolumeM3 = room.areaM2 * (filled.roomHeightM ?? 0);
      let extraVolumeL3 = 0;

      for (let roofEntity of levelAboveEntities) {
        let roofCalc = context.globalStore.getOrCreateCalculation(roofEntity);
        let { roofComponents } = roofCalc;

        for (let component of roofComponents) {
          let clippedPolygons = polygonClipping(
            roomPolygon,
            component.polygonCw,
          );

          for (let polygon of clippedPolygons) {
            let intersectAreaM2 = getAreaM2(polygon);
            if (intersectAreaM2 <= FRAGMENTAL_COMP_AREA_M2) {
              continue;
            }

            let polygonCentroid = calculateCentroidCoord(polygon);
            let centroidHeightM = calculateOnSlopeHeightM(
              component,
              polygonCentroid,
            );
            extraVolumeL3 += intersectAreaM2 * centroidHeightM;
          }
        }
      }

      const volumeM3 = baseVolumeM3 + extraVolumeL3;
      const roomCalc = context.globalStore.getOrCreateCalculation(room.entity);
      roomCalc.volumeM3 = volumeM3;
    }
  }

  static calculateRoomsConfigure(context: CalculationEngine) {
    const roomsConfigure: Map<string, RoomEntity[]> = new Map<
      string,
      RoomEntity[]
    >();

    for (const o of context.networkObjects()) {
      const levelId = context.globalStore.levelOfEntity.get(o.uid);
      if (o.type === EntityType.ROOM && levelId) {
        // Get the array for this levelId, or create it if it doesn't exist
        const entities = roomsConfigure.get(levelId) || [];

        // Add the entity to the array
        entities.push(o.entity);

        // Update the array in the map
        roomsConfigure.set(levelId, entities);
      }
    }

    return roomsConfigure;
  }

  static createCacheArchitectureToRoom(context: CalculationEngine) {
    let cacheArchitectureToRoom: Map<string, string[]> = new Map<
      string,
      string[]
    >();
    for (const o of context.networkObjects()) {
      if (o.entity.type === EntityType.ARCHITECTURE_ELEMENT) {
        if (!o.entity.parentUid) continue;
        const coreRoom = context.globalStore.getObjectOfTypeOrThrow(
          EntityType.ROOM,
          o.entity.parentUid,
        );
        const roomUid: string | null = coreRoom.getCalculationUid(context);

        if (roomUid) {
          if (cacheArchitectureToRoom.has(roomUid)) {
            cacheArchitectureToRoom.set(
              roomUid,
              [...(cacheArchitectureToRoom.get(roomUid) ?? [])].concat(
                o.entity.uid,
              ),
            );
          } else {
            cacheArchitectureToRoom.set(roomUid, [o.entity.uid]);
          }
        }
      }
    }
    return cacheArchitectureToRoom;
  }

  static createCacheRoomPolygon(context: CalculationEngine) {
    const cacheRoomPolygon: Map<string, Coord[]> = new Map<string, Coord[]>();
    for (const o of context.networkObjects()) {
      if (o.type === EntityType.ROOM) {
        // Convert the room entity to list of coordinate represent the polygon
        // Hash into a map
        cacheRoomPolygon.set(
          o.uid,
          o.collectVerticesInOrder().map((v) => {
            return v.toWorldCoord();
          }),
        );
      }
    }
    return cacheRoomPolygon;
  }

  static createRoomsRTree(
    context: CalculationEngine,
  ): Map<string, RBush<SpatialIndex>> {
    const treeBulkInsert = new Map<string, SpatialIndex[]>();
    const spatialIndex = new Map<string, RBush<SpatialIndex>>();

    for (const o of context.networkObjects()) {
      if (o.type !== EntityType.ROOM) continue;
      if (o.entity.room.roomType !== RoomType.ROOM) continue;

      const levelUid = context.globalStore.levelOfEntity.get(o.uid);
      if (!levelUid) continue;

      const box = o.shape?.box;
      if (!box || isNaN(box.xmin)) {
        if (box) console.warn("Entity bounding box is NaN", o.type, o.uid);
        continue;
      }

      if (!spatialIndex.has(levelUid)) {
        spatialIndex.set(levelUid, new RBush<SpatialIndex>());
        treeBulkInsert.set(levelUid, []);
      }

      treeBulkInsert.get(levelUid)?.push({
        minX: box.xmin,
        minY: box.ymin,
        maxX: box.xmax,
        maxY: box.ymax,
        uid: o.uid,
        levelUid: levelUid,
      });
    }

    for (const [levelUid, tree] of spatialIndex.entries()) {
      tree.load(treeBulkInsert.get(levelUid) || []);
    }

    return spatialIndex;
  }

  static calculateHeatLossCoolingLoad(
    context: CalculationEngine,
    ventZones: VentZoneRecordWithMVHR[],
    cacheArchitectureToRoom: Map<string, string[]>,
    cacheRoomPolygon: Map<string, Coord[]>,
    roomsConfigure: Map<string, RoomEntity[]>,
  ) {
    let cacheInternalWallToRoom: Map<string, CoreRoom[]> = new Map<
      string,
      CoreRoom[]
    >();
    let cacheRoomToInternalWall: Map<string, string[]> = new Map<
      string,
      string[]
    >();

    for (const o of context.networkObjects()) {
      if (o.entity.type === EntityType.WALL) {
        let coreWall = context.globalStore.getObjectOfTypeOrThrow(
          EntityType.WALL,
          o.entity.uid,
        );
        if (coreWall.isInternalWall()) {
          let roomUids: string[] = [];
          for (let edgeUid of coreWall.entity.polygonEdgeUid) {
            roomUids = roomUids.concat(
              context.globalStore.getPolygonsByEdge(edgeUid),
            );
          }

          let rooms: CoreRoom[] = [];
          for (let roomUid of roomUids) {
            const room = context.globalStore.getObjectOfType(
              EntityType.ROOM,
              roomUid,
            );
            if (room) {
              rooms.push(room);
            }

            if (cacheRoomToInternalWall.has(roomUid)) {
              cacheRoomToInternalWall.set(
                roomUid,
                [...(cacheRoomToInternalWall.get(roomUid) ?? [])].concat(
                  coreWall.uid,
                ),
              );
            } else {
              cacheRoomToInternalWall.set(roomUid, [coreWall.uid]);
            }
          }

          cacheInternalWallToRoom.set(o.entity.uid, rooms);
        }
      }
    }

    /**
     * Calculate the areaConversionRatio for each roof
     */
    for (const o of context.networkObjects()) {
      const levelId = context.globalStore.levelOfEntity.get(o.uid)!;
      if (o.type !== EntityType.ROOM) continue;
      let filledRoom = fillDefaultRoomFields(context, o.entity);
      let coreRoof = context.globalStore.getObjectOfTypeOrThrow(
        EntityType.ROOM,
        o.entity.uid,
      );

      if (filledRoom.room.roomType === RoomType.ROOF) {
        doRoofCalculationsBaseOnSegmentation(
          context,
          coreRoof,
          filledRoom,
          filledRoom.room,
          cacheArchitectureToRoom.get(o.entity.uid) ?? [],
        );
      }
    }

    /**
     * Calculate heatloss for the rooms
     */
    const roomsAboveAndBelow = this.calculateRoomsAboveAndBelow(
      context,
      cacheRoomPolygon,
      roomsConfigure,
    );

    for (let [levelUid, roomEntities] of roomsConfigure.entries()) {
      for (let room of roomEntities) {
        if (room.room.roomType === RoomType.ROOF) continue;

        HeatLoadCalculations.calculateHeatLossCoolingIndividualRoom(
          context,
          room,
          roomsAboveAndBelow,
          cacheRoomPolygon,
          cacheInternalWallToRoom,
          cacheRoomToInternalWall,
          ventZones,
        );
      }
    }

    // Calculate Floor/Building heatload info
    this.calculateBuildingFloorHeatLoad(
      context,
      roomsConfigure,
      cacheRoomPolygon,
    );
  }

  @TraceCalculation("Calculate the areaConversionRatio for each roof")
  static calculateRoofAreaRatio(
    context: CalculationEngine,
    cacheArchitectureToRoom: Map<string, string[]>,
  ) {
    for (const o of context.networkObjects()) {
      if (o.type !== EntityType.ROOM) continue;
      const filledRoom = fillDefaultRoomFields(context, o.entity);

      if (filledRoom.room.roomType === RoomType.ROOF) {
        doRoofCalculationsBaseOnSegmentation(
          context,
          o,
          filledRoom,
          filledRoom.room,
          cacheArchitectureToRoom.get(o.entity.uid) ?? [],
        );
      }
    }
  }

  static getLevelBelow(
    levelUid: string,
    floorInOrder: Level[],
    roomsConfigure: Map<string, RoomEntity[]>,
  ): RoomEntity[] {
    let currIdx = floorInOrder.findIndex((level) => {
      return level.uid === levelUid;
    });

    let levelAbove = floorInOrder[currIdx - 1];
    if (levelAbove) {
      return roomsConfigure.get(levelAbove.uid) ?? [];
    }
    return [];
  }

  static getLevelAbove(
    levelUid: string,
    floorInOrder: Level[],
    roomsConfigure: Map<string, RoomEntity[]>,
  ): RoomEntity[] {
    let currIdx = floorInOrder.findIndex((level) => {
      return level.uid === levelUid;
    });

    let levelAbove = floorInOrder[currIdx + 1];
    if (levelAbove) {
      return roomsConfigure.get(levelAbove.uid) ?? [];
    }
    return [];
  }

  static calculateHeatLoadElementContributions(
    engine: CalculationEngine,
    roomsRTree: Map<string, RBush<SpatialIndex>>,
  ) {
    const hsEntriesByRoom = HeatLoadCalculations.getHeatSourceEntriesByRoom(
      engine,
      roomsRTree,
    );

    for (const [roomUid, entries] of hsEntriesByRoom) {
      const room = engine.globalStore.getObjectOfType(EntityType.ROOM, roomUid);
      if (!room) continue;
      if (room.entity.room.roomType !== RoomType.ROOM) continue;

      const roomCalc = engine.globalStore.getOrCreateCalculation(room.entity);
      roomCalc.totalHeatLossAddressedWATT = 0;
      roomCalc.totalHeatGainAddressedWATT = 0;

      const dynamicHsEntries: HeatSourceEntry[] = [];
      const roomHsEntries: HeatSourceEntry[] = [];

      for (const entry of entries) {
        roomHsEntries.push(entry);

        const o = engine.globalStore.getObjectOfTypeOrThrow(
          EntityType.PLANT,
          entry.entityUid,
        );
        const calc = engine.globalStore.getOrCreateCalculation(o.entity);
        calc.associatedRoomUid = entry.associatedRooms;

        switch (entry.ratingType) {
          case "fixed":
            switch (entry.type) {
              case "heating":
                roomCalc.totalHeatLossAddressedWATT += entry.ratingWATT;
                if (entry.specs) {
                  roomCalc.heatEmittersStats[entry.entityUid] = entry.specs;
                }
                break;
              case "cooling":
                roomCalc.totalHeatGainAddressedWATT += entry.ratingWATT;
                break;
              default:
                assertUnreachable(entry.type);
            }
            break;

          case "dynamic":
            dynamicHsEntries.push(entry);
            break;
          default:
            assertUnreachable(entry.ratingType);
        }
      }

      // Calculate the remaining heating and cooling load for dynamic entries
      const heatingRemainingW = Math.max(
        (roomCalc.totalHeatLossWatt ?? 0) - roomCalc.totalHeatLossAddressedWATT,
        0,
      );
      const chilledRemainingW = Math.max(
        (roomCalc.totalHeatGainWatt ?? 0) - roomCalc.totalHeatGainAddressedWATT,
        0,
      );

      const heatingEntries = dynamicHsEntries.filter(
        (e) => e.type === "heating",
      );
      HeatLoadCalculations.calculateDynamicHeatSourceRatings(
        engine,
        heatingEntries,
        room.entity,
        heatingRemainingW,
      );

      const chilledEntries = dynamicHsEntries.filter(
        (e) => e.type === "cooling",
      );
      HeatLoadCalculations.calculateDynamicHeatSourceRatings(
        engine,
        chilledEntries,
        room.entity,
        chilledRemainingW,
      );

      // Fill in demand met
      for (const entry of roomHsEntries) {
        const heatEmittersStat = roomCalc.heatEmittersStats[entry.entityUid];
        if (!heatEmittersStat) continue;
        for (const stat of heatEmittersStat) {
          const allForThisRoom = heatEmittersStat.filter(
            (s) => s.name === stat.name,
          );
          const demandsMet = sumBy(
            allForThisRoom,
            (spec) =>
              (100 * (spec._heatingRatingWatt ?? 0)) /
              (roomCalc.totalHeatLossWatt ?? 1),
          );
          stat.demandMet = demandsMet.toFixed(2);
        }
      }
    }
  }

  private static calculateDynamicHeatSourceRatings(
    engine: CalculationEngine,
    dynamicHsEntries: HeatSourceEntry[],
    room: RoomEntity,
    totalRemainingWatt: number,
  ) {
    if (room.room.roomType !== RoomType.ROOM) return;
    const rFilled = fillDefaultRoomFields(engine, room);
    const roomCalc = engine.globalStore.getOrCreateCalculation(room);

    const maxRatingsW = this.getMaxHeatSourceRatings(
      engine,
      dynamicHsEntries,
      (rFilled.room as RoomEntityConcrete).roomTemperatureC,
    );

    const finalRatingsW = this.distributeRadiatorRatingWatt(
      totalRemainingWatt,
      maxRatingsW,
    );

    for (const [i, entry] of dynamicHsEntries.entries()) {
      const plant = engine.globalStore.getObjectOfTypeOrThrow(
        EntityType.PLANT,
        entry.entityUid,
      );
      const plantCalc = engine.globalStore.getOrCreateCalculation(plant.entity);
      let ratingW = finalRatingsW[i];

      if (isSpecifyRadiator(plant.entity.plant)) {
        const result = this.updateDynamicRadiatorSizeByRating(
          engine,
          entry.entityUid,
          ratingW,
          (rFilled.room as RoomEntityConcrete).roomTemperatureC,
          true,
        );
        entry.ratingWATT = result ? result.ratingKW * 1000 : 0;
        if (result) {
          roomCalc.heatEmittersStats[entry.entityUid] = result.specs;
        }
        roomCalc.totalHeatLossAddressedWATT =
          (roomCalc.totalHeatLossAddressedWATT ?? 0) + entry.ratingWATT;
        const { volumeL, internalVolumeL } =
          ReturnCalculations.calculateRadiatorEffectiveVolume(
            engine,
            plant.entity,
          );
        plantCalc.volumeL = volumeL;
        plantCalc.internalVolumeL = internalVolumeL;
      } else if (isRoomAssociatedPlant(plant.entity.plant)) {
        const roomCalc = engine.globalStore.getOrCreateCalculation(room);
        entry.ratingWATT = ratingW;
        if (entry.type === "heating") {
          roomCalc.totalHeatLossAddressedWATT =
            (roomCalc.totalHeatLossAddressedWATT ?? 0) + ratingW;
          plantCalc.heatingRatingKW =
            (plantCalc.heatingRatingKW ?? 0) + ratingW / 1000;
          roomCalc.heatEmittersStats[entry.entityUid] = this.getPlantSpecs(
            engine,
            plant.entity,
            plant.entity.plant,
            room.uid,
            null,
            null,
            plantCalc,
            roomCalc,
            plantCalc.heatingRatingKW * 1000,
            null,
            null,
          ).specs;
        } else if (entry.type === "cooling") {
          roomCalc.totalHeatGainAddressedWATT =
            (roomCalc.totalHeatGainAddressedWATT ?? 0) + ratingW;
          plantCalc.chilledRatingKW =
            (plantCalc.chilledRatingKW ?? 0) + ratingW / 1000;
        }
      }
    }
  }

  /**
   * Computes the global heatload setting for rooms for all levels.
   *
   * This function performs the following steps:
   *
   * 1. **Building Segregation with DFS**:
   *    - Uses Depth First Search (DFS) to segregate the rooms in a level into separate buildings.
   *    - Each building gets assigned a 'leader' room, which is typically the bottommost room
   *      in the building hierarchy.
   *
   * 2. **Heatload Aggregation**:
   *    - Iterates through all the rooms.
   *    - Aggregates the heatload calculations from individual rooms and adds it to
   *      the corresponding 'leader' room of each building.
   *
   * The leader room then present the info about building, floor in drawing.
   *
   * Params:
   * - 'roomConfigure' is a map of level uid to an array of rooms in that level.
   * - 'cacheRoomPolygon' is a map of room uid to an array of coordinates representing the room polygon.
   */
  static calculateBuildingFloorHeatLoad(
    context: CalculationEngine,
    roomsConfigure: Map<string, RoomEntity[]>,
    cacheRoomPolygon: Map<string, Coord[]>,
  ) {
    //getBuildingsFromRooms
    let allrooms = ([...roomsConfigure.values()].flat() as RoomEntity[]).map(
      (entity) =>
        context.globalStore.getObjectOfTypeOrThrow(EntityType.ROOM, entity.uid),
    );
    let buildings = getBuildingsFromRooms(context, allrooms).map((x) =>
      x.map((y) => y.entity),
    );

    // Then for each building, select a leader room
    for (let rooms of buildings) {
      let leaderRooms = selectLeaderRoomsPerFloor(context, Array.from(rooms));
      const leaderRoomsUidSet = new Set<string>(
        Array.from(leaderRooms).map((x) => x[1].uid),
      );
      let result: GlobalHeatLoadInfo = {
        heatLossWatt: 0,
        heatGainWatt: 0,
        areaM2: 0,
        volumeM3: 0,
      };

      for (let roomEntity of rooms) {
        let roomCalculation =
          context.globalStore.getOrCreateCalculation(roomEntity);
        result.heatLossWatt += roomCalculation.totalHeatLossWatt
          ? roomCalculation.totalHeatLossWatt
          : 0;
        result.heatGainWatt += roomCalculation.totalHeatGainWatt
          ? roomCalculation.totalHeatGainWatt
          : 0;
        if (roomEntity.room.roomType === RoomType.ROOM) {
          result.areaM2 += roomCalculation.areaM2 ? roomCalculation.areaM2 : 0;
          result.volumeM3 += roomCalculation.volumeM3
            ? roomCalculation.volumeM3
            : 0;
        }
      }
      for (let roomEntity of rooms) {
        let roomCalculation =
          context.globalStore.getOrCreateCalculation(roomEntity);
        roomCalculation.buildingHeatLoadInfo = result;
        roomCalculation.isLeaderRoomPerFloor = leaderRoomsUidSet.has(
          roomEntity.uid,
        );
      }
    }

    for (let roomsInLevel of roomsConfigure.values()) {
      // For Floor, select a leader room
      let leaderRoom = selectLeaderRoom(
        context,
        roomsInLevel,
        cacheRoomPolygon,
      );
      if (leaderRoom) {
        let leaderRoomCalculation =
          context.globalStore.getOrCreateCalculation(leaderRoom);
        leaderRoomCalculation.floorHeadLoadInfo = {
          heatLossWatt: 0,
          heatGainWatt: 0,
          areaM2: 0,
          volumeM3: 0,
        };

        for (let roomEntity of roomsInLevel) {
          let roomCalculation =
            context.globalStore.getOrCreateCalculation(roomEntity);
          leaderRoomCalculation.floorHeadLoadInfo.heatLossWatt +=
            roomCalculation.totalHeatLossWatt
              ? roomCalculation.totalHeatLossWatt
              : 0;
          leaderRoomCalculation.floorHeadLoadInfo.heatGainWatt +=
            roomCalculation.totalHeatGainWatt
              ? roomCalculation.totalHeatGainWatt
              : 0;
          leaderRoomCalculation.floorHeadLoadInfo.areaM2 +=
            roomCalculation.areaM2 ? roomCalculation.areaM2 : 0;
          leaderRoomCalculation.floorHeadLoadInfo.volumeM3 +=
            roomCalculation.volumeM3 ? roomCalculation.volumeM3 : 0;
        }
      }
    }
  }

  static getMaxHeatSourceRatings(
    engine: CalculationEngine,
    hsEntries: HeatSourceEntry[],
    roomTempC: number | null,
  ) {
    const maxRadiatorRatings: number[] = [];

    for (const entry of hsEntries) {
      const radiator = engine.globalStore.getObjectOfTypeOrThrow(
        EntityType.PLANT,
        entry.entityUid,
      );
      if (
        isRadiatorPlant(radiator.entity.plant) &&
        radiator.entity.plant.radiatorType === "specify"
      ) {
        const result = this.updateDynamicRadiatorSizeByRating(
          engine,
          entry.entityUid,
          Infinity,
          roomTempC,
          false,
        );
        maxRadiatorRatings.push(result ? result.ratingKW * 1000 : 0);
      } else {
        maxRadiatorRatings.push(Infinity);
      }
    }

    return maxRadiatorRatings;
  }

  static distributeRadiatorRatingWatt(
    totalWatt: number,
    maxCapWatt: number[],
  ): number[] {
    const maxCapWattWithIndex = maxCapWatt.map((watt, index) => ({
      watt,
      index,
    }));
    maxCapWattWithIndex.sort((a, b) => a.watt - b.watt);

    const result = Array(maxCapWatt.length).fill(0);
    let remainingWatt = totalWatt;
    let index = 0;
    while (index < maxCapWattWithIndex.length) {
      const averageWatt = remainingWatt / (maxCapWatt.length - index);
      const heatSource = maxCapWattWithIndex[index];
      const wattAssigned =
        heatSource.watt >= averageWatt ? averageWatt : heatSource.watt;
      result[heatSource.index] = wattAssigned;
      remainingWatt -= wattAssigned;
      index += 1;
    }
    return result;
  }

  static getFiftyDeltaRatingKW(
    context: CoreContext,
    filled: RadiatorPlantEntity,
    radCalc: PlantCalculation,
  ) {
    let ratingKw = 0;

    if (filled.plant.radiatorType === "specify") {
      const model: RadiatorData = getPlantDatasheet(
        filled.plant,
        context.catalog,
        false,
        {
          model:
            filled.plant.manufacturer === "generic"
              ? filled.plant.rangeType
              : filled.plant.model!,
        },
      )[0];

      if (radCalc.widthMM && radCalc.heightMM) {
        const area = (radCalc.widthMM / 1000) * (radCalc.heightMM / 1000);
        ratingKw = model
          ? ReturnCalculations.getRadiatorDataRating_KW(model, 50, area)
          : 0;
      }
    }

    return ratingKw;
  }

  static updateDynamicRadiatorSizeByRating(
    engine: CalculationEngine,
    radiatorUid: string,
    ratingWATT: number,
    roomTempC: number | null,
    update: boolean,
  ): { specs: HeatEmitterSpecs[]; ratingKW: number } | undefined {
    let radiator = engine.globalStore.getObjectOfTypeOrThrow(
      EntityType.PLANT,
      radiatorUid,
    );
    let radiatorCalc = engine.globalStore.getOrCreateCalculation(
      radiator.entity,
    );

    if (!update) {
      radiatorCalc = cloneSimple(radiatorCalc);
    }

    if (
      isRadiatorPlant(radiator.entity.plant) &&
      radiator.entity.plant.radiatorType === "specify"
    ) {
      const filled = fillPlantDefaults(
        engine,
        radiator.entity,
      ) as RadiatorPlantEntity;
      if (filled.plant.radiatorType !== "specify") {
        return undefined;
      }

      const manufacturer = radiator.entity.plant.manufacturer;
      const table: RadiatorData[] = getPlantDatasheet(
        radiator.entity.plant,
        engine.catalog,
        true,
        { rangeType: radiator.entity.plant.rangeType },
      );
      const system = getFlowSystem(
        engine.drawing.metadata.flowSystems,
        radiator.entity.inletSystemUid,
      )!;
      let name = null;

      switch (manufacturer) {
        case "generic": {
          assertType<GenericRadiatorData[]>(table);
          const model = table![0];
          const deltaTC =
            (radiatorCalc.returnAverageC ??
              system.temperatureC - DEFAULT_HEATING_RETURN_DELTA_C / 2) -
            (roomTempC ?? DEFAULT_ROOM_TEMP_C);
          const ratingKWPerM2 = interpolateTable(
            model.KWperM2ByDeltaT,
            deltaTC,
          )!;
          const ratingKwPerM2At50Delta = interpolateTable(
            model.KWperM2ByDeltaT,
            50,
          )!;

          const targetAreaM2 = ratingWATT / (ratingKWPerM2 * 1000);

          if (radiator.entity.plant.widthMM.value) {
            radiatorCalc.widthMM = radiator.entity.plant.widthMM.value;
            radiatorCalc.heightMM = this.getClosesRadiatorLengthMM(
              (targetAreaM2 / (radiatorCalc.widthMM / 1000)) * 1000,
            );
          } else if (radiator.entity.plant.heightMM.value) {
            radiatorCalc.heightMM = radiator.entity.plant.heightMM.value;
            radiatorCalc.widthMM = this.getClosesRadiatorLengthMM(
              (targetAreaM2 / (radiatorCalc.heightMM / 1000)) * 1000,
            );
          } else {
            console.error(
              "Radiator has no width or height, defaulting to save the project",
            );
            radiatorCalc.heightMM = 860;
            radiatorCalc.widthMM = this.getClosesRadiatorLengthMM(
              (targetAreaM2 / (radiatorCalc.heightMM / 1000)) * 1000,
            );
          }

          radiatorCalc.heatingRatingKW = ratingWATT / 1000;
          radiatorCalc.heatingRatingAt50DtKW =
            ratingKwPerM2At50Delta * targetAreaM2;
          radiatorCalc.model = null;
          break;
        }
        default: {
          assertType<ManufacturerRadiatorData[]>(table);
          const bestModel = this.getRadiatorModel(
            engine,
            table,
            radiator.entity as RadiatorPlantEntity,
            radiatorCalc.returnAverageC ??
              system.temperatureC - DEFAULT_HEATING_RETURN_DELTA_C / 2,
            roomTempC,
            ratingWATT / 1000,
            update,
          );

          if (bestModel) {
            // when a model is found
            radiatorCalc.widthMM = bestModel.widthMM ?? radiatorCalc.widthMM;
            radiatorCalc.heightMM = bestModel.heightMM ?? radiatorCalc.heightMM;
            radiatorCalc.depthMM = bestModel.depthMM ?? radiatorCalc.depthMM;

            const deltaTC =
              (radiatorCalc.returnAverageC ??
                system.temperatureC - DEFAULT_HEATING_RETURN_DELTA_C / 2) -
              (roomTempC ?? DEFAULT_ROOM_TEMP_C);
            radiatorCalc.heatingRatingKW =
              ReturnCalculations.getRadiatorDataRating_KW(
                bestModel,
                deltaTC,
                ((bestModel.widthMM / 1000) * bestModel.heightMM) / 1000,
              );
            radiatorCalc.heatingRatingAt50DtKW =
              ReturnCalculations.getRadiatorDataRating_KW(
                bestModel,
                50,
                ((bestModel.widthMM / 1000) * bestModel.heightMM) / 1000,
              );

            // all stelrad radiator data has model
            radiatorCalc.model = bestModel.model!;
            radiatorCalc.volumeL = bestModel.volumeL ?? null;
            radiatorCalc.internalVolumeL = radiatorCalc.volumeL;

            const kv =
              radiator.entity.plant.pressureLoss.pressureMethod ===
              PressureMethod.KV_PRESSURE_LOSS
                ? (radiator.entity.plant.pressureLoss.kvValue ??
                  bestModel.kvValue ??
                  0)
                : null;
            radiatorCalc.kvValue = kv;
            const manufacturer = getManufacturerRecord(
              radiator.entity,
              engine.catalog,
            );
            name = `${toTitleCase(
              manufacturer ? manufacturer.name : "Generic",
            )} ${radiator.entity.plant.rangeType}`;
            filled.plant.model = radiatorCalc.model;
          } else {
            // no model is found
            radiatorCalc.heatingRatingKW = 0;
            radiatorCalc.heatingRatingAt50DtKW = 0;
            radiatorCalc.model = null;
            radiatorCalc.kvValue =
              radiator.entity.plant.pressureLoss.pressureMethod ===
              PressureMethod.KV_PRESSURE_LOSS
                ? (radiator.entity.plant.pressureLoss.kvValue ?? 0)
                : 0;
            radiatorCalc.volumeL = 0;
            radiatorCalc.internalVolumeL = 0;
          }

          break;
        }
      }

      const outputDelta50TempWATT =
        (this.getFiftyDeltaRatingKW(engine, filled, radiatorCalc) ?? 0) * 1000;
      return this.getPlantSpecs(
        engine,
        filled,
        filled.plant,
        null,
        name,
        filled.name ?? filled.type,
        radiatorCalc,
        null,
        null,
        outputDelta50TempWATT,
        null,
      );
    }
    return undefined;
  }

  static getClosesRadiatorLengthMM(lengthMM: number) {
    // round up to the closest 10mm interval
    return Math.ceil(lengthMM / 10) * 10;
  }

  static getFilteredRadiatorModels(
    table: ManufacturerRadiatorData[],
    heightUpperLimitMM: number | null,
    widthUpperLimitMM: number | null,
  ): ManufacturerRadiatorData[] {
    let res: ManufacturerRadiatorData[] = [...table];

    if (heightUpperLimitMM !== null) {
      const exactHeightMatches = res.filter(
        (radiator) => radiator.heightMM === heightUpperLimitMM,
      );
      res =
        exactHeightMatches.length > 0
          ? exactHeightMatches
          : res.filter((radiator) => radiator.heightMM <= heightUpperLimitMM);
    }

    if (widthUpperLimitMM !== null) {
      const exactWidthMatches = res.filter(
        (radiator) => radiator.widthMM === widthUpperLimitMM,
      );
      res =
        exactWidthMatches.length > 0
          ? exactWidthMatches
          : res.filter((radiator) => radiator.widthMM <= widthUpperLimitMM);
    }

    return res;
  }

  static getRadiatorModel(
    engine: CalculationEngine,
    table: ManufacturerRadiatorData[],
    radiatorEntity: RadiatorPlantEntity,
    returnAverageC: number,
    roomTempC: number | null,
    heatingRatingKW: number,
    update: boolean,
  ): ManufacturerRadiatorData | undefined {
    const radiator = radiatorEntity.plant as SpecifyRadiatorPlant;
    const diffTemp = returnAverageC - (roomTempC ?? DEFAULT_ROOM_TEMP_C);

    switch (radiator.manufacturer) {
      case "generic":
        return undefined;
      default:
        // table was already filter by shape and range (in getPlantDatasheet) in updateDynamicRadiatorSizeByRating function
        // filter by width and height, group by model
        const heightUpperLimitMM = radiator.heightMM.value;
        const widthUpperLimitMM = radiator.widthMM.value;

        const models = this.getFilteredRadiatorModels(
          table,
          heightUpperLimitMM,
          widthUpperLimitMM,
        );

        if (models.length === 0) {
          if (update) {
            addWarning(engine, "RADIATOR_MODEL_NOT_FOUND", [radiatorEntity], {
              mode: "mechanical",
            });
          }
          return undefined;
        }

        const calculationModels = models
          .map((_radiator) => {
            const ratingKW = ReturnCalculations.getRadiatorDataRating_KW(
              _radiator,
              diffTemp,
              ((_radiator.widthMM / 1000) * _radiator.heightMM) / 1000,
            );

            return {
              radiator: _radiator,
              deltaTempC: diffTemp,
              kW: ratingKW,
            };
          })
          .sort((a, b) => {
            return a.kW - b.kW;
          });

        if (
          calculationModels[calculationModels.length - 1].kW < heatingRatingKW
        ) {
          if (update) {
            addWarning(
              engine,
              "RADIATOR_MODEL_RATING_INSUFFICIENT",
              [radiatorEntity],
              {
                mode: "mechanical",
              },
            );
          }
          return calculationModels[calculationModels.length - 1].radiator;
        } else {
          return calculationModels.find((model) => model.kW >= heatingRatingKW)!
            .radiator;
        }
    }
  }
}
