import Flatten from "@flatten-js/core";
import * as TM from "transformation-matrix";
import { v4 } from "uuid";
import { PipeConfiguration } from "../../../../../common/src/api/calculations/types";
import { CoreObjectConcrete } from "../../../../../common/src/api/coreObjects";
import { ICoreConnectable } from "../../../../../common/src/api/coreObjects/core-traits/coreConnectable";
import CoreBaseBackedObject from "../../../../../common/src/api/coreObjects/lib/coreBaseBackedObject";
import {
  determineConnectableSystemUid,
  flowSystemsCompatible,
} from "../../../../../common/src/api/coreObjects/utils";
import { CalculationData } from "../../../../../common/src/api/document/calculations-objects/calculation-field";
import {
  ConnectableEntityConcrete,
  DrawableEntityConcrete,
  hasExplicitSystemUid,
  isConnectableEntity,
  isConnectableEntityType,
} from "../../../../../common/src/api/document/entities/concrete-entity";
import ConduitEntity, {
  isPipeEntity,
} from "../../../../../common/src/api/document/entities/conduit-entity";
import { FittingEntity } from "../../../../../common/src/api/document/entities/fitting-entity";
import { EntityType } from "../../../../../common/src/api/document/entities/types";
import { DEFAULT_HORIZONTAL_SYSTEM_NETWORKS } from "../../../../../common/src/api/document/flow-systems";
import {
  getFlowSystem,
  getFlowSystemColor,
} from "../../../../../common/src/api/document/utils";
import { Color } from "../../../../../common/src/lib/color";
import { Coord } from "../../../../../common/src/lib/coord";
import { assertUnreachableAggressive } from "../../../../../common/src/lib/utils";
import { PIPE_HEIGHT_GRAPHIC_EPS_MM } from "../../../../src/config";
import CanvasContext from "../../../../src/htmlcanvas/lib/canvas-context";
import { EntityDrawingArgs } from "../../../../src/htmlcanvas/lib/drawable-object";
import {
  DrawingContext,
  ValidationResult,
} from "../../../../src/htmlcanvas/lib/types";
import { getDragPriority } from "../../../store/document";
import {
  DrawableObjectConcrete,
  isRotatedObject,
} from "../../objects/concrete-object";
import DrawableConduit from "../../objects/drawableConduit";
import { isFlowSystemActive } from "../../objects/utils";
import { makeConduitEntity } from "../black-magic/utils";
import { drawConnectableInHeatmap, isHeatmapEnabled } from "../heatmap/heatmap";
import { Interaction, InteractionType } from "../interaction";
import { IDrawableObject } from "./core2drawable";
import { SnapIntention, SnapTarget } from "./snappable-object";
import { isHydraulicEntity } from "./util";

const EPS = 1e-5;

interface ConnectableRenderData {
  drops: DropRenderData[];
}

interface DropRenderData {
  pipeA: DrawableConduit;
  pipeB: DrawableConduit;
  myAngle: number;
  otherAngle: number;
}

export function ConnectableObject<
  // I extends ConnectableEntityConcrete,
  Obj extends abstract new (
    ...args: any[]
  ) => CoreBaseBackedObject<ConnectableEntityConcrete> &
    IDrawableObject &
    ICoreConnectable,
>(Base: Obj) {
  // typescript currently doesn't allow extension of union classes, only intersections, even if every
  // member of the union is a static class.
  // So this constructor typecast, paired with 'this' type casts in methods, is a workaround.
  abstract class Generated extends Base {
    abstract minimumConnections: number;
    abstract maximumConnections: number | null;

    abstract dragPriority: number;

    customCopyObjects = false;

    abstract drawConnectable(
      context: DrawingContext,
      args: EntityDrawingArgs,
    ): void;

    getSnapTargets(
      request: SnapIntention[],
      _wc: Coord,
      ignoreUids: string[],
    ): SnapTarget[] {
      const { angles } = this.getSortedAnglesRAD(ignoreUids);
      angles.push(...angles.map((a) => a + Math.PI));
      if (this.type === EntityType.SYSTEM_NODE) {
        const parent = this.globalStore.get(
          this.entity.parentUid!,
        ) as DrawableObjectConcrete;
        if (parent && isRotatedObject(parent)) {
          angles.push(
            (parent.entity.rotation * Math.PI) / 180,
            ((parent.entity.rotation + 90) * Math.PI) / 180,
          );
        }
      }

      const results: SnapTarget[] = [];
      for (const r of request) {
        switch (r.accept) {
          case "point":
            {
              // Actually we don't care about system uid since here we are just making a snap location available.
              // You should still be able to align to a pipe / thing that is in a different system.
              // if (
              //   !r.systemUid ||
              //   r.systemUid ===
              //     (determineConnectableSystemUid(
              //       this.context.globalStore,
              //       this.entity
              //     ) || r.systemUid)
              // ) {
              results.push({
                type: "point",
                wc: this.toWorldCoord(),
                offset: r.offset,
                validAnglesRad: angles,
              });
            }
            break;
          // }
          case "block": {
            // no-op
            break;
          }
          default:
            assertUnreachableAggressive(r.accept);
        }
      }
      return results;
    }

    lastRenderData: ConnectableRenderData | null = null;

    lastRenderConnections: string[] | null = null;

    hasLastRenderConnectionsChanged() {
      const connections = this.globalStore.getConnections(this.uid);
      if (this.lastRenderConnections === null) {
        return true;
      }
      if (connections.length !== this.lastRenderConnections.length) {
        return true;
      }
      for (let i = 0; i < connections.length; i++) {
        if (connections[i] !== this.lastRenderConnections[i]) {
          return true;
        }
      }
      return false;
    }

    getRenderData() {
      if (!this.lastRenderData) {
        this.lastRenderData = {
          drops: [],
        };

        if (isHydraulicEntity(this.entity)) {
          // draw rings.
          const radials = this.getRadials();
          const radialAngles: Array<[number, CoreObjectConcrete]> = radials.map(
            ([wc, o]) => {
              const oo = this.toObjectCoord(wc);
              return [Math.atan2(oo.y, oo.x), o];
            },
          );

          radialAngles.sort((a, b) => a[0] - b[0]);

          let highest = -Infinity;
          radials.forEach(([_a, r]) => {
            if (r.entity.type === EntityType.CONDUIT) {
              highest = Math.max(highest, r.entity.heightAboveFloorM);
            } else {
              throw new Error("don't know how to handle non-pipe objects");
            }
          });

          const highPipes = new Set<string>();

          radials.forEach(([_a, r]) => {
            if (r.entity.type === EntityType.CONDUIT) {
              if (
                r.entity.heightAboveFloorM +
                  PIPE_HEIGHT_GRAPHIC_EPS_MM / 1000 >=
                highest
              ) {
                highPipes.add(r.entity.uid);
              }
            } else {
              throw new Error("don't know how to handle non-pipe objects");
            }
          });

          // Draw an arc for every pieslice between pipes that have a lower pipe in it.

          highPipes.forEach((huid) => {
            const ix = radialAngles.findIndex(([_a, r]) => r.uid === huid);
            let hasLowerInBetween = false;
            for (let i = 1; i <= radialAngles.length; i++) {
              const [a, r] = radialAngles[(ix + i) % radialAngles.length];
              if (!highPipes.has(r.uid)) {
                hasLowerInBetween = true;
              } else {
                if (hasLowerInBetween) {
                  this.lastRenderData!.drops.push({
                    pipeA: this.globalStore.get(r.uid) as DrawableConduit,
                    pipeB: this.globalStore.get(huid) as DrawableConduit,
                    myAngle: radialAngles[ix][0],
                    otherAngle: a,
                  });
                }
                break;
              }
            }
          });
        }
      }
      return this.lastRenderData;
    }

    onRedrawNeeded() {
      super.onRedrawNeeded();
      this.lastRenderData = null;
    }

    drawEntity(context: DrawingContext, args: EntityDrawingArgs): void {
      const { heatmapMode } = args;
      const e = this.entity;

      if (this.hasLastRenderConnectionsChanged()) {
        this.onRedrawNeeded();
        this.lastRenderConnections = this.globalStore.getConnections(this.uid);
      }

      if (
        isHeatmapEnabled(this.document) &&
        heatmapMode !== undefined &&
        !drawConnectableInHeatmap(e, heatmapMode)
      ) {
        return;
      }

      const { ctx } = context;

      this.drawConnectable(context, args);

      switch (e.type) {
        case EntityType.RISER:
          // don't do this for risers lol horseshoe
          if (!this.entity.uid.startsWith("4075e")) {
            // lucky 1 in a million
            return;
          } else {
            break;
          }
        case EntityType.FITTING:
        case EntityType.SYSTEM_NODE:
        case EntityType.LOAD_NODE:
        case EntityType.FLOW_SOURCE:
        case EntityType.DIRECTED_VALVE:
        case EntityType.MULTIWAY_VALVE:
        case EntityType.VERTEX:
          break;
        default:
          assertUnreachableAggressive(e);
      }

      const { drops } = this.getRenderData()!;

      for (const drop of drops) {
        const maxWidth =
          this.toObjectLength(
            Math.max(drop.pipeA.lastDrawnWidth, drop.pipeB.lastDrawnWidth),
          ) + 2;

        const mya = drop.myAngle;
        const a = drop.otherAngle;

        const adiff = ((a - mya + 4 * Math.PI - EPS) % (2 * Math.PI)) + EPS;
        const v1 = Flatten.vector([1, 0])
          .rotate(mya)
          .normalize()
          .multiply(maxWidth * (3 - adiff / (Math.PI * 0.95)));
        const v1perp = v1.rotate90CCW().normalize().multiply(maxWidth);
        const v2 = Flatten.vector([1, 0])
          .rotate(a)
          .normalize()
          .multiply(maxWidth * (3 - adiff / (Math.PI * 0.95)));
        const v2perp = v2.rotate90CW().normalize().multiply(maxWidth);

        const l1s = Flatten.point().translate(v1).translate(v1perp);
        const l1e = Flatten.point().translate(v1perp);
        const l2s = Flatten.point().translate(v2).translate(v2perp);
        const l2e = Flatten.point().translate(v2perp);

        ctx.lineWidth = maxWidth / 2;
        ctx.strokeStyle = "#000";
        if (adiff > Math.PI - EPS) {
          // round
          ctx.beginPath();
          ctx.moveTo(l1s.x, l1s.y);
          ctx.lineTo(l1e.x, l1e.y);
          ctx.stroke();
          ctx.beginPath();
          if (adiff > Math.PI + EPS) {
            ctx.arc(0, 0, maxWidth, a - Math.PI / 2, mya + Math.PI / 2, true);
          }
          ctx.moveTo(l2e.x, l2e.y);
          ctx.lineTo(l2s.x, l2s.y);
          ctx.stroke();
        } else {
          ctx.beginPath();
          const mp = Flatten.line(l1s, l1e).intersect(Flatten.line(l2s, l2e));
          ctx.moveTo(l1s.x, l1s.y);
          ctx.lineTo(mp[0].x, mp[0].y);
          ctx.lineTo(l2s.x, l2s.y);
          ctx.stroke();
        }
      }
    }

    validate(context: CanvasContext, tryToFix: boolean): ValidationResult {
      const conns = context.globalStore.getConnections(this.uid);

      if (
        this.maximumConnections !== null &&
        conns.length > this.maximumConnections
      ) {
        if (tryToFix) {
          // Try to fix by deleting a neighbouring pipe.
          const victim = conns[0];
          const level =
            context.document.drawing.levels[
              context.globalStore.levelOfEntity.get(victim)!
            ].name;
          const type = context.globalStore.get(victim)!.type;

          context.deleteEntity(context.globalStore.get(victim)!);

          return {
            success: false,
            message:
              "Too many connections coming out of a " +
              this.type +
              ", deleted " +
              type +
              "(" +
              victim +
              ") on level " +
              level +
              " to try to fix. Please review",
            modified: true,
          };
        } else {
          return {
            success: false,
            message: "Too many connections coming out of a " + this.type,
            modified: false,
          };
        }
      }

      if (
        this.minimumConnections !== null &&
        conns.length < this.minimumConnections
      ) {
        return {
          success: false,
          message:
            "Too few connections coming out of a " +
            this.type +
            ": " +
            this.uid,
          modified: false,
        };
      }

      return {
        success: true,
      };
    }

    offerInteraction(
      interaction: Interaction,
    ): DrawableEntityConcrete[] | null {
      let allowAllSystemUid = false;
      if (this.entity.type === EntityType.SYSTEM_NODE) {
        if (this.entity.allowAllSystems) {
          allowAllSystemUid = true;
        }
      }

      switch (interaction.type) {
        case InteractionType.INSERT:
          if (
            isConnectableEntityType(interaction.entityType) &&
            getDragPriority(interaction.entityType) >= this.dragPriority
          ) {
            if (hasExplicitSystemUid(this.entity) && interaction.systemUid) {
              if (
                !this.flowSystemsCompatible(
                  interaction.systemUid,
                  this.entity.systemUid,
                ) &&
                !allowAllSystemUid
              ) {
                return null;
              }
            }
            return [this.entity];
          }
          return null;
        case InteractionType.CONTINUING_CONDUIT:
        case InteractionType.STARTING_CONDUIT: {
          const resultingConnections =
            this.globalStore.getConnections(this.entity.uid).length + 1;
          if (this.numConnectionsInBound(resultingConnections)) {
            if (hasExplicitSystemUid(this.entity)) {
              if (
                interaction.system &&
                !this.flowSystemsCompatible(
                  interaction.system.uid,
                  this.entity.systemUid,
                ) &&
                !allowAllSystemUid
              ) {
                return null;
              }
              // load nodes can't do two of the same type.
            }
            return [this.entity];
          } else {
            return null;
          }
        }
        case InteractionType.SNAP_ONTO_RECEIVE: {
          if (interaction.src.uid === this.uid) {
            return null;
          }
          if (isConnectableEntity(interaction.src)) {
            if (getDragPriority(interaction.src.type) >= this.dragPriority) {
              return [this.entity];
            }
            if (
              this.numConnectionsInBound(
                this.numConnectionsAfterMerging(
                  interaction.src as ConnectableEntityConcrete,
                ),
              )
            ) {
              if (
                hasExplicitSystemUid(this.entity) &&
                hasExplicitSystemUid(interaction.src)
              ) {
                if (
                  !this.flowSystemsCompatible(
                    interaction.src.systemUid,
                    this.entity.systemUid,
                  ) &&
                  !allowAllSystemUid
                ) {
                  return null;
                }
              }
              return [this.entity];
            }
            return null;
          } else {
            return null;
          }
        }
        case InteractionType.SNAP_ONTO_SEND: {
          if (interaction.dest.uid === this.uid) {
            return null;
          }
          if (isConnectableEntity(interaction.dest)) {
            if (getDragPriority(interaction.dest.type) > this.dragPriority) {
              return [this.entity];
            }
            if (
              this.numConnectionsInBound(
                this.numConnectionsAfterMerging(
                  interaction.dest as ConnectableEntityConcrete,
                ),
              )
            ) {
              if (
                hasExplicitSystemUid(this.entity) &&
                hasExplicitSystemUid(interaction.dest)
              ) {
                if (
                  !this.flowSystemsCompatible(
                    interaction.dest.systemUid,
                    this.entity.systemUid,
                  ) &&
                  !allowAllSystemUid
                ) {
                  return null;
                }
              }
              return [this.entity];
            }
            return null;
          } else if (interaction.dest.type === EntityType.CONDUIT) {
            if (
              this.globalStore
                .getConnections(this.entity.uid)
                .includes(interaction.dest.uid)
            ) {
              console.log("already connected");
              return null;
            }
            if (
              this.numConnectionsInBound(
                this.globalStore.getConnections(this.entity.uid).length + 2,
              )
            ) {
              return [this.entity];
            }
          }
          return null;
        }
        case InteractionType.EXTEND_NETWORK:
          let isSystemCorrect: boolean = false;
          const entity = this.entity as ConnectableEntityConcrete;

          if (entity.type === EntityType.VERTEX) {
            // vertices should use EXTEND_WALL or other interactions.
            return null;
          }

          if (
            entity.type === EntityType.DIRECTED_VALVE ||
            entity.type === EntityType.LOAD_NODE ||
            entity.type === EntityType.MULTIWAY_VALVE
          ) {
            const suid = determineConnectableSystemUid(
              this.globalStore,
              entity,
            );
            isSystemCorrect =
              interaction.systemUid === null ||
              flowSystemsCompatible(interaction.systemUid, suid!, this.drawing);
          } else {
            isSystemCorrect =
              allowAllSystemUid ||
              interaction.systemUid === null ||
              this.flowSystemsCompatible(
                interaction.systemUid,
                entity.systemUid,
              );
          }

          if (isSystemCorrect) {
            if (
              this.numConnectionsInBound(
                this.globalStore.getConnections(this.entity.uid).length + 1,
              )
            ) {
              return [this.entity];
            } else {
              return null;
            }
          } else {
            return null;
          }
        case InteractionType.LINK_ENTITY:
          return [this.entity];
      }
    }

    numConnectionsAfterMerging(other: ConnectableEntityConcrete): number {
      let resultingConnections =
        this.globalStore.getConnections(other.uid).length +
        this.globalStore.getConnections(this.entity.uid).length;
      const inCommon = this.globalStore
        .getConnections(other.uid)
        .filter((puid) => {
          return (
            this.globalStore.getConnections(this.entity.uid).indexOf(puid) !==
            -1
          );
        }).length;
      resultingConnections -= inCommon;
      return resultingConnections;
    }

    numConnectionsInBound(num: number): boolean {
      if (
        (this.maximumConnections === null || num <= this.maximumConnections) &&
        num >= this.minimumConnections
      ) {
        return true;
      }
      return false;
    }

    prepareDeleteConnection(
      uid: string,
      context: CanvasContext,
    ): DrawableObjectConcrete[] {
      // Remove 0 Way Fittings and Tee from Deleted Pipes
      if (
        this.type === EntityType.FITTING &&
        (!this.globalStore.getConnections(this.entity.uid).length ||
          (this.globalStore.getConnections(this.entity.uid).length == 2 &&
            this.isStraight(1)))
      ) {
        return this.prepareDelete(context);
      } else {
        return [];
      }
    }

    flowSystemsCompatible(a: string, b: string) {
      return flowSystemsCompatible(a, b, this.drawing);
    }

    baseDrawnColor(context: DrawingContext): Color {
      const e = this.entity as ConnectableEntityConcrete; // vue's ts doesn't work directly on this.entity.type
      if (e.type !== EntityType.SYSTEM_NODE && e.type !== EntityType.VERTEX) {
        if (e.color) {
          return e.color;
        }
      }
      const systemUid = determineConnectableSystemUid(
        context.globalStore,
        this.entity,
      );
      const flowSystem = getFlowSystem(context.doc.drawing, systemUid);

      let network = flowSystem
        ? DEFAULT_HORIZONTAL_SYSTEM_NETWORKS[flowSystem.type]
        : "reticulations";
      let configuration = PipeConfiguration.NORMAL;
      for (const rUid of context.globalStore.getConnections(this.entity.uid)) {
        const rObj = context.globalStore.get(rUid);
        if (!(rObj.type === EntityType.CONDUIT) || !isPipeEntity(rObj.entity)) {
          continue;
        }
        network = rObj.entity.conduit.network;
        if (rObj.effectiveConfiguration) {
          configuration = rObj.effectiveConfiguration;
        }
      }
      if (!flowSystem) {
        return { hex: "#888888" };
      } else {
        return getFlowSystemColor(flowSystem, network, configuration);
      }
    }

    isActive(): boolean {
      const systemUid = determineConnectableSystemUid(
        this.globalStore,
        this.entity,
      );
      if (!systemUid) return true;
      return isFlowSystemActive(this.context, this.document.uiState, systemUid);
    }

    locateCalculationBoxWorld(
      _context: DrawingContext,
      _data: CalculationData[],
      scale: number,
    ): TM.Matrix[] {
      // Put a candidate position in each gap, starting from largest to smallest.
      const { angles } = this.getSortedAnglesRAD();
      let candidates: Array<[number, number]> = [];

      if (this.globalStore.getConnections(this.entity.uid).length >= 2) {
        for (let i = 0; i < angles.length; i++) {
          const a = angles[i];
          const b = angles[(i + 1) % angles.length];
          const diff = (b - a + Math.PI * 2) % (Math.PI * 2);
          candidates.push([a + diff / 2, diff]);
        }
        candidates.sort((a, b) => -(a[1] - b[1]));
        candidates.push(
          [candidates[0][0] + Math.PI / 4, -1],
          [candidates[0][0] - Math.PI / 4, -1],
          [candidates[0][0] + Math.PI / 2, -1],
          [candidates[0][0] - Math.PI / 2, -1],
          [candidates[0][0] + (Math.PI * 3) / 4, -1],
          [candidates[0][0] - (Math.PI * 3) / 4, -1],
        );
      } else if (
        this.globalStore.getConnections(this.entity.uid).length === 1
      ) {
        candidates = [
          [angles[0] + Math.PI, -1],
          [angles[0] + Math.PI + Math.PI / 4, -1],
          [angles[0] + Math.PI - Math.PI / 4, -1],
          [angles[0] + Math.PI + Math.PI / 2, -1],
          [angles[0] + Math.PI - Math.PI / 2, -1],
          [angles[0] + Math.PI + (Math.PI * 3) / 4, -1],
          [angles[0] + Math.PI - (Math.PI * 3) / 4, -1],
        ];
      } else {
        for (let i = 0; i < 8; i++) {
          candidates.push([(Math.PI * i) / 4, -1]);
        }
      }

      const wc = this.toWorldCoord();

      return candidates.map(([dir, _gapAngle]) => {
        return TM.transform(
          TM.identity(),
          TM.translate(wc.x, wc.y),
          TM.rotate(dir + Math.PI / 2),
          TM.scale(scale),
          TM.translate(0, -80),
          TM.rotate(-dir - Math.PI / 2),
        );
      });
    }

    isStraight(toleranceDEG: number = 1): boolean {
      const angles = this.getAngleDiffs();
      if (angles.length !== 2) {
        return false;
      }
      if (angles.some(isNaN)) {
        return true;
      }
      return Math.abs(((angles[0] + 360) % 360) - 180) <= toleranceDEG;
    }

    prepareDelete(
      context: CanvasContext,
      _calleeEntityUid?: string,
    ): DrawableObjectConcrete[] {
      const levelUid = this.globalStore.levelOfEntity.get(this.uid)!;

      // Delete all connected pipes.
      // make that work.
      // If we are not a pipe,
      const isStraight = this.isStraight();

      if (isStraight) {
        let onePipe!: ConduitEntity;
        // If we were straight, restore the pipe first
        const ends = this.globalStore
          .getConnections(this.entity.uid)
          .slice()
          .map((cuid) => {
            const c = this.globalStore.get(cuid) as DrawableConduit;
            onePipe = c.entity;
            const other =
              c.entity.endpointUid[0] === this.uid
                ? c.entity.endpointUid[1]
                : c.entity.endpointUid[0];

            return other;
          });

        const newPipe: ConduitEntity = makeConduitEntity({
          modelConduit: onePipe,
          fields: {
            endpointUid: [ends[0], ends[1]],
          },
          objectStore: this.globalStore,
        });

        context.$store.dispatch("document/addEntityOn", {
          entity: newPipe,
          levelUid,
        });
      }

      if (this.entity.type === EntityType.FITTING || isStraight) {
        const result: DrawableObjectConcrete[] = [];
        this.globalStore
          .getConnections(this.entity.uid)
          .slice()
          .forEach((c) => {
            const o = this.globalStore.get(c);
            if (o instanceof DrawableConduit) {
              result.push(...o.prepareDelete(context));
            } else {
              throw new Error(
                "Non existent connection on valve " +
                  JSON.stringify(this.entity),
              );
            }
          });

        result.push(this as any as DrawableObjectConcrete);

        return result;
      } else {
        // we are an irregular connetable. Instead of deleting, turn into a fitting instead.
        const conns = this.globalStore.getConnections(this.uid);
        if (conns.length === 0) {
          // just an hero
          return [this as any as DrawableObjectConcrete];
        } else {
          // turn into a fitting.
          let center = this.toWorldCoord();
          let parentUid = null;

          // If the floor plan is being deleted, we want this to come back to the
          // document object and be picked up again so that it will be re-deleted
          // on the second pass. Having the parent be the floor plan will make it
          // happen.
          if (
            this.entity.parentUid &&
            context.globalStore.get(this.entity.parentUid).type ===
              EntityType.BACKGROUND_IMAGE
          ) {
            center = this.entity.center;
            parentUid = this.entity.parentUid;
          }

          const fitting: FittingEntity = {
            calculationHeightM: null,
            center,
            color: null,
            parentUid,
            systemUid: determineConnectableSystemUid(
              this.globalStore,
              this.entity,
            )!,
            type: EntityType.FITTING,
            uid: v4(),
            entityName: null,

            fittingType: "pipe",
            fitting: {},
          };
          context.$store.dispatch("document/addEntity", fitting);

          for (const uid of conns.slice()) {
            const p = this.globalStore.get(uid) as DrawableConduit;
            if (p.entity.endpointUid[0] === this.uid) {
              p.entity.endpointUid[0] = fitting.uid;
            } else {
              p.entity.endpointUid[1] = fitting.uid;
            }
          }

          return [this as any as DrawableObjectConcrete];
        }
      }
    }

    getCustomCopiedObjects(): DrawableObjectConcrete[] {
      return [];
    }

    // Don't copy neighbours in our case - just copy the single component.
    getCopiedObjects(): DrawableObjectConcrete[] {
      if (this.customCopyObjects) {
        return this.getCustomCopiedObjects();
      } else {
        return [this as any as DrawableObjectConcrete];
      }
    }
  }

  return Generated;
}
