import { Color } from "../../../lib/color";
import { Coord } from "../../../lib/coord";
import { Units, UnitsContext } from "../../../lib/measurements";
import { cloneSimple } from "../../../lib/utils";
import { CoreContext } from "../../calculations/types";
import { isGas, isMechanical, isPressure, isVentilation } from "../../config";
import { SupportedLocales } from "../../locale";
import { getTooltip } from "../../tooltips/tooltips";
import { getFlowSystem } from "../utils";
import {
  FieldType,
  PropertyField,
  withPropertyTracking,
} from "./property-field";
import { ConnectableEntity, NamedEntity } from "./simple-entities";
import { EntityType } from "./types";

export interface FlowSourceEntityV11 extends ConnectableEntity {
  type: EntityType.FLOW_SOURCE;
  center: Coord;
  systemUid: string;

  heightAboveGroundM: number | null;
  color: Color | null;
  pressureKPA: number | null;
}

export default interface FlowSourceEntity
  extends ConnectableEntity,
    NamedEntity {
  type: EntityType.FLOW_SOURCE;
  center: Coord;
  systemUid: string;
  heightAboveGroundM: number | null;
  color: Color | null;
  minPressureKPA: number | null;
  maxPressureKPA: number | null;
}

export function makeFlowSourceFields(
  context: CoreContext,
  entity: FlowSourceEntity,
): PropertyField[] {
  const { drawing, catalog, locale } = context;
  const systems = drawing.metadata.flowSystems;
  const system = systems[entity.systemUid];
  const iAmVentilation = isVentilation(system);

  const res: PropertyField[] = [
    {
      property: "entityName",
      title: "Name",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Text,
      params: null,
      multiFieldId: "entityName",
    },
    {
      property: "systemUid",
      title: "Flow System",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.FlowSystemChoice,
      params: {
        systems: drawing.metadata.flowSystemUidsInOrder.map(
          (uid) => systems[uid],
        ),
      },
      multiFieldId: "systemUid",
    },
  ];

  if (isGas(systems[entity.systemUid])) {
    res.push({
      property: "minPressureKPA",
      title: "Pressure",
      hasDefault: false,
      isCalculated: false,
      requiresInput: true,
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: "minPressureKPA",
      units: Units.GasKiloPascals,
      unitContext: UnitsContext.GAS_ENERGY_MEASUREMENT,
    });
  } else if (isPressure(systems[entity.systemUid])) {
    res.push(
      {
        property: "minPressureKPA",
        title: "Residual Pressure",
        hint: getTooltip("FlowSource", "Residual Pressure"),
        hasDefault: false,
        isCalculated: false,
        requiresInput: true,
        type: FieldType.Number,
        params: { min: 0, max: null },
        multiFieldId: "minPressureKPA",
        unitContext: iAmVentilation ? UnitsContext.VENTILATION : undefined,
        units: Units.KiloPascals,
      },
      {
        property: "maxPressureKPA",
        title: "Static Pressure",
        hint: getTooltip("FlowSource", "Static Pressure"),
        hasDefault: false,
        isCalculated: false,
        requiresInput: true,
        type: FieldType.Number,
        params: { min: 0, max: null },
        multiFieldId: "maxPressureKPA",
        unitContext: iAmVentilation ? UnitsContext.VENTILATION : undefined,
        units: Units.KiloPascals,
      },
    );
  }

  if (
    isPressure(systems[entity.systemUid]) ||
    isMechanical(systems[entity.systemUid])
  ) {
    res.push({
      property: "heightAboveGroundM",
      title: "Height",
      hasDefault: false,
      isCalculated: false,
      requiresInput: true,
      type: FieldType.Number,
      params: { min: null, max: null },
      multiFieldId: "heightAboveGroundM",
      units: Units.Meters,
    });
  }

  if (
    !isGas(systems[entity.systemUid]) &&
    isPressure(systems[entity.systemUid]) &&
    locale === SupportedLocales.AU
  ) {
    res.unshift({
      type: FieldType.Image,
      title: "HTC Group",
      hasDefault: false,
      isCalculated: false,
      multiFieldId: "",
      property: "",
      params: {
        url: "https://docs.google.com/forms/d/11-yiFK2VZhiz7zIBNINtHNXqPTUw86GqfsJ76Cz8e7E",
        titleHtml:
          '<p style="font-size: small; margin:3px auto;">Request a <b>Flow & Pressure Test</b></p>',
        subtitleHtml: "",
        imagePath: "/img/3rdparty/htctestinglogo.png",
      },
    });
  }

  res.push({
    property: "color",
    title: "Color",
    hasDefault: true,
    isCalculated: false,
    type: FieldType.Color,
    params: null,
    multiFieldId: "color",
  });

  return res.map(withPropertyTracking(context, entity));
}

export function fillFlowSourceDefaults(
  context: CoreContext,
  entity: FlowSourceEntity,
) {
  const { drawing } = context;
  const result = cloneSimple(entity);

  // get system
  const system = getFlowSystem(drawing, entity.systemUid);

  if (system) {
    if (result.color == null) {
      result.color = system.color;
    }
  } else {
    throw new Error(
      "Existing system not found for object " + JSON.stringify(entity),
    );
  }

  return result;
}
