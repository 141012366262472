import { assertUnreachable, cloneSimple } from "../../../../lib/utils";
import { CoreContext } from "../../../calculations/types";
import {
  ChoiceField,
  FieldType,
  PropertyField,
  withPropertyTracking,
} from "../property-field";
import { ConnectableEntity } from "../simple-entities";
import { EntityType } from "../types";
import {
  FixedVertexEntity,
  LinkedVertexEntity,
  VertexConcrete,
  VertexContext,
  VertexShape,
  VertexType,
} from "./vertex-types";

export interface VertexEntityBase extends ConnectableEntity {
  type: EntityType.VERTEX;
  vertexType: VertexType;
  vertexContext: VertexContext;
  shape: VertexShape;
  vertex: VertexConcrete;
}

export type VertexEntity = FixedVertexEntity | LinkedVertexEntity;

export function fillDefaultVertexFields(
  context: CoreContext,
  entity: VertexEntity,
) {
  let result = cloneSimple(entity);
  if (!result.shape) {
    result.shape = "circle";
  }
  return result;
}

export function makeVertexFields(
  context: CoreContext,
  entity: VertexEntity,
): PropertyField[] {
  switch (entity.vertexContext) {
    case "annotation":
      return [
        {
          property: "shape",
          title: "Shape",
          hasDefault: false,
          isCalculated: false,
          type: FieldType.Choice,
          params: {
            choices: [
              {
                name: "Arrow",
                key: "arrow",
              },
              {
                name: "Circle",
                key: "circle",
              },
              {
                name: "Nothing",
                key: "nothing",
              },
            ],
          },
          multiFieldId: "shape",
        } satisfies ChoiceField,
      ].map(withPropertyTracking(context, entity));
    case "room":
    case "unheated-area":
    case "heated-area":
      return [];
    default:
      assertUnreachable(entity.vertexContext);
  }

  return [];
}
