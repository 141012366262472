import { uniq } from "lodash";
import {
  isDrainage,
  isMechanical,
  isPressure,
  isUnderfloor,
  isVentilation,
} from "../../../common/src/api/config";
import { Workflow, Workflows } from "../../../common/src/api/document/drawing";
import { FlowSystem } from "../../../common/src/api/document/flow-systems";
import {
  assertUnreachable,
  assertUnreachableAggressive,
  betterObjectEntries,
  notFalsy,
} from "../../../common/src/lib/utils";
import { DocumentState } from "../store/document/types";
import { filterFlowSystemsByWorkflows } from "../views/settings2/parameters/flow-system-filtering";
import { useWorkflows } from "./hooks/use-workflows";

/** Return true if at least one of the supplied workflows is enabled */
export const inclusiveWorkflowsCurried =
  (workflows: Workflows) =>
  (...keys: Workflow[]) => {
    const targetWorkflows = betterObjectEntries(workflows)
      .filter(([key, _]) => keys.includes(key))
      .map(([_, value]) => value);
    return targetWorkflows.some((w) => w.enabled);
  };

/**
 * Return true if any workflow OTHER THAN those specified is enabled.
 * ie. if we want to have something apply for every workflow other than "heat_gain" and "heat_loss", we would use this
 */
export const exclusiveWorkflowsCurried =
  (workflows: Workflows) =>
  (...keys: Workflow[]) => {
    const targetWorkflows = betterObjectEntries(workflows)
      .filter(([key, _]) => !keys.includes(key))
      .map(([_, value]) => value);
    return targetWorkflows.some((w) => w.enabled);
  };

/** returns true if any singular workflow is enabled. only useful for extreme edge case where user has disabled all workflows for some unknown reason :shrug: */
export const anyWorkflow =
  (allWorkflows: Record<string, { enabled: boolean }>) => () => {
    return Object.values(allWorkflows).some((w) => w.enabled);
  };

const getSuitableDrawingLayoutAtomic = (key: keyof Workflows) => {
  switch (key) {
    case "heat_gain":
      return "mechanical";
    case "heat_loss":
      return "mechanical";
    case "mech_heating":
      return "mechanical";
    case "mech_ventilation":
      return "ventilation";
    case "mech_underfloor_heating":
      return "mechanical";
    case "mech_chilled":
      return "mechanical";
    case "plumbing_water":
      return "pressure";
    case "plumbing_gas":
      return "pressure";
    case "plumbing_wastewater":
      return "drainage";
    case "plumbing_stormwater":
      return "drainage";
    case "fire_sprinklers":
      return "pressure";
    case "fire_hydrants":
      return "pressure";
    case "fire_hosereels":
      return "pressure";
  }
};

export const getSuitableDrawingLayout = (workflows: Workflows) => {
  const workflowPriority = (key: keyof Workflows) => {
    switch (key) {
      // lower value is higher priority
      case "heat_gain":
        return 2;
      case "heat_loss":
        return 2;
      case "mech_heating":
        return 1;
      case "mech_ventilation":
        return 1;
      case "mech_underfloor_heating":
        return 1;
      case "mech_chilled":
        return 1;
      case "plumbing_water":
        return 0;
      case "plumbing_gas":
        return 0;
      case "plumbing_wastewater":
        return 0;
      case "plumbing_stormwater":
        return 0;
      case "fire_sprinklers":
        return 3;
      case "fire_hydrants":
        return 3;
      case "fire_hosereels":
        return 3;
      default:
        return assertUnreachableAggressive(key);
    }
  };
  return betterObjectEntries(workflows)
    .filter(([_, workflow]) => workflow.enabled)
    .sort(([a, _a], [b, _b]) => {
      const priorA = workflowPriority(a);
      const priorB = workflowPriority(b);
      if (priorA > priorB) return 1;
      if (priorB > priorA) return -1;
      return 0;
    })
    .map(([key, _]) => getSuitableDrawingLayoutAtomic(key))
    .filter(Boolean)[0];
};

/** Returns enabled drawing layouts for active workflows */
export const getEnabledDrawingLayouts = (workflows: Workflows) => {
  const layouts = uniq(
    betterObjectEntries(workflows)
      .filter(([_, workflow]) => workflow.enabled)
      .map(([key, _]) => getSuitableDrawingLayoutAtomic(key))
      .filter(notFalsy),
  );

  return layouts;
};

export const heatLoadWorkflows: Workflow[] = ["heat_gain", "heat_loss"];

export const mechanicalWorkflows: Workflow[] = [
  "mech_chilled",
  "mech_heating",
  "mech_underfloor_heating",
  "mech_ventilation",
];

export const fireWorkflows: Workflow[] = [
  "fire_hosereels",
  "fire_hydrants",
  "fire_sprinklers",
];

(window as any).randomiseWorkflows = () => {
  Object.values(useWorkflows()).map(
    (v) => (v.enabled = Math.random() > 0.5 ? true : false),
  );
};

export function getActiveWorkflowsList(workflows: Workflows): string[] {
  return Object.keys(workflows).filter(
    (key) => workflows[key as keyof Workflows].enabled,
  );
}

export function getAvailableFlowSystems(doc: DocumentState): FlowSystem[] {
  let flowSystems = doc.drawing.metadata.flowSystemUidsInOrder.map(
    (uid) => doc.drawing.metadata.flowSystems[uid],
  );
  flowSystems = filterFlowSystemsByWorkflows(
    doc.drawing.metadata.workflows,
    flowSystems,
    doc.drawing.metadata.flowSystemUidsInOrder,
  )
    .map((e) => e[1])
    .flat();
  if (doc.uiState.allowedFlowSystems) {
    flowSystems = flowSystems.filter((s) =>
      doc.uiState.allowedFlowSystems!.includes(s.uid),
    );
  }
  switch (doc.uiState.drawingLayout) {
    case "mechanical":
      return flowSystems.filter((x) => isMechanical(x) || isUnderfloor(x));
    case "pressure":
      return flowSystems.filter(isPressure);
    case "drainage":
      return flowSystems.filter(isDrainage);
    case "ventilation":
      return flowSystems.filter(isVentilation);
  }
  assertUnreachable(doc.uiState.drawingLayout);
}
