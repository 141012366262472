import { toTitleCase } from "../../calculations/utils";
import { PipePhysicalMaterial } from "../../config";
import { CatalogPrice, Manufacturer } from "../types";
import { SingleVariableContinuousFunction } from "../utils-function-object";

type manufacturer = string;
type range = string;
type model = string;

export type ComponentId = readonly [manufacturer, model];

export function componentIdEquals(a: ComponentId, b: ComponentId): boolean {
  return a[0] === b[0] && a[1] === b[1];
}

export interface Model {
  model: string;
  productKey: string;

  // Note: This could (and likely should) be optional,
  // however at present all PHG UFH components are required to have a description,
  // and enforcing this means we cant accidentally omit it.
  description: string;
}

export function componentIdToString(id: ComponentId | null): string | null {
  if (!id) {
    return null;
  }
  // TODO: lookup the manufacturer name from the manufacturer id
  return toTitleCase(id[0]) + " " + id[1];
}

export interface UnderfloorHeatingSpec {
  manifold: ManifoldSpec;
  actuator: ActuatorSpec;
  blendingValve: BlendingValveSpec;
  pump: UFHPumpSpec;
  pumpPack: PumpPackSpec;
  ballValve: BallValveSpec;
  edgeExpansionFoam: EdgeExpansionFoamSpec;
  coil: CoilSpec;
}

export interface ManifoldSpec {
  type: "ufh-manifold";
  name: string;
  uid: "ufh-manifold";
  manufacturer: Manufacturer<"UFH Manifold">[];
  datasheet: {
    [key: manufacturer]: ManifoldData;
  };
}

export interface ActuatorSpec {
  type: "ufh-actuator";
  name: string;
  uid: "ufh-actuator";
  manufacturer: Manufacturer<"UFH Actuator">[];
  datasheet: {
    [key: manufacturer]: ActuatorData;
  };
}

export interface BlendingValveSpec {
  type: "ufh-blending-valve";
  name: string;
  uid: "ufh-blending-valve";
  manufacturer: Manufacturer<"UFH Blending Valve">[];
  datasheet: {
    [key: manufacturer]: BlendingValveData;
  };
}

export interface UFHPumpSpec {
  type: "ufh-pump";
  name: string;
  uid: "ufh-pump";
  manufacturer: Manufacturer<"UFH Pump">[];
  datasheet: {
    [key: manufacturer]: UFHPumpData;
  };
}

export interface PumpPackSpec {
  type: "ufh-pump-pack";
  name: string;
  uid: "ufh-pump-pack";
  manufacturer: Manufacturer<"UFH Pump Pack">[];
  datasheet: {
    [key: manufacturer]: PumpPack;
  };
}

export interface BallValveSpec {
  type: "ufh-ball-valve";
  name: string;
  uid: "ufh-ball-valve";
  manufacturer: Manufacturer<"UFH Ball Valve">[];
  datasheet: {
    [key: manufacturer]: BallValve;
  };
}

export interface EdgeExpansionFoamSpec {
  type: "ufh-edge-expansion-foam";
  name: string;
  uid: "ufh-edge-expansion-foam";
  manufacturer: Manufacturer<"UFH Edge Expansion Foam">[];
  datasheet: {
    [key: manufacturer]: EdgeExpansionFoam;
  };
}

export interface CoilSpec {
  type: "ufh-coil";
  name: string;
  uid: "ufh-coil";
  manufacturer: Manufacturer<"Underfloor Heating Coils">[];
  datasheet: {
    [key: manufacturer]: Coil;
  };
}

export interface ManifoldData {
  [key: range]: {
    [key: model]: ManifoldModel;
  };
}

export interface ManifoldModel extends Model {
  range: string;
  outletsCount: number;
  widthMM: number;
  heightMM: number;
  depthMM: number;
  kvM3PerHr: number;
  ioCentre2CentreMM: number; // is this necessary?
  allowsActuators: boolean;
  price?: CatalogPrice;
}

export interface ActuatorData {
  [key: model]: ActuatorModel;
}

export interface ActuatorModel extends Model {
  kValue: number;
  compatibleManifolds: ComponentId[];
  price?: CatalogPrice;
}

export interface BlendingValveModel extends Model {
  maxHeatOutputKW?: number;
  minFlowTempC?: number;
  maxFlowTempC?: number;
  pressureDropKPAByFlowRateLS?: SingleVariableContinuousFunction;
  price?: CatalogPrice;
}

export interface BlendingValveData {
  [key: model]: BlendingValveModel;
}

export interface UFHPumpModel extends Model {
  maxHeadKPA: number; // converted m head to kPA
  maxFlowRateLS: number;
  pressureDropKPAByFlowRateLS?: SingleVariableContinuousFunction;
  minPowerKW?: number;
  maxPowerKW?: number;
  price?: CatalogPrice;
}

export interface UFHPumpData {
  [key: model]: UFHPumpModel;
}

export interface PumpPackModel extends Model {
  // The items are arrays, because the pump pack can offer multiple different models
  // of pumps and blending valves. Each combination of the two is assumed to be compatible.
  pumps: ComponentId[];
  blendingValves: ComponentId[];
  compatibleManifolds: ComponentId[];
  price?: CatalogPrice;
}

export interface PumpPack {
  [key: model]: PumpPackModel;
}

export interface BallValve {
  [key: model]: BallValveModel;
}

export interface BallValveModel extends Model {
  sizesMM: number[];
  kValue: number;
  needsPumpPack: boolean; // if true, this valve goes on the pump, otherwise it goes on the manifold
  compatibleManifolds: ComponentId[];
}

export interface EdgeExpansionFoam {
  [key: model]: EdgeExpansionFoamModel;
}

export interface EdgeExpansionFoamModel extends Model {
  rollLengthM: number | null;
}

export interface Coil {
  [key: model]: CoilModel;
}

export interface CoilModel extends Model {
  rollLengthM: number | null;
  pipeMaterial: PipePhysicalMaterial | null;
  pipeSizeMM: number | null;
}
