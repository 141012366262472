import { MeasurementSystem } from "../lib/measurements";
import {
  assertUnreachable,
  Choice,
  cloneSimple,
  SelectField,
} from "../lib/utils";
import { CoreContext } from "./calculations/types";
import { Catalog } from "./catalog/types";
import { DrawingState, UnitsParameters } from "./document/drawing";
import { VentilationNode } from "./document/entities/load-node-entity";
import {
  PlantConcrete,
  PlantType,
} from "./document/entities/plants/plant-types";
import {
  FireFlowSystem,
  FlowSystem,
  GasFlowSystem,
  MechanicalFlowSystem,
  PipeMaterialRole,
  SewerFlowSystem,
  StormwaterFlowSystem,
  UnderfloorHeatingFlowSystem,
  VentilationFlowSystem,
} from "./document/flow-systems";
// import { isConcreteFlowSystem } from "./document/utils";

export enum SupportedPsdStandards {
  as35002021LoadingUnits = "as35002021LoadingUnits",
  barriesBookLoadingUnits = "barriesBookLoadingUnits",

  ipc2018FlushTanks = "ipc2018FlushTanks",
  ipc2018Flushometer = "ipc2018Flushometer",
  cibseGuideG = "cibseGuideG",
  upc2018FlushTanks = "upc2018FlushTanks",
  upc2018Flushometer = "upc2018Flushometer",
  bs806 = "bs806",
  bs8558 = "bs8558",
  indianStandardFlushTankPrivate = "indianStandardFlushTankPrivate",
  indianStandardFlushTankPublic = "indianStandardFlushTankPublic",
  indianStandardFlushValvePrivate = "indianStandardFlushValvePrivate",
  indianStandardFlushValvePublic = "indianStandardFlushValvePublic",

  hcaa = "hcaa",
  din1988300Residential = "din1988300Residential",
  din1988300Hospital = "din1988300Hospital",
  din1988300Hotel = "din1988300Hotel",
  din1988300School = "din1988300School",
  din1988300Office = "din1988300Office",
  din1988300AssistedLiving = "din1988300AssistedLiving",
  din1988300NursingHome = "din1988300NursingHome",
}

export type SupportedLUPsdStandards =
  | SupportedPsdStandards.as35002021LoadingUnits
  | SupportedPsdStandards.barriesBookLoadingUnits
  | SupportedPsdStandards.ipc2018FlushTanks
  | SupportedPsdStandards.ipc2018Flushometer
  | SupportedPsdStandards.cibseGuideG
  | SupportedPsdStandards.upc2018FlushTanks
  | SupportedPsdStandards.upc2018Flushometer
  | SupportedPsdStandards.bs806
  | SupportedPsdStandards.bs8558
  | SupportedPsdStandards.indianStandardFlushTankPrivate
  | SupportedPsdStandards.indianStandardFlushTankPublic
  | SupportedPsdStandards.indianStandardFlushValvePrivate
  | SupportedPsdStandards.indianStandardFlushValvePublic;

export type SupportedEquationPSDStandards =
  | SupportedPsdStandards.hcaa
  | SupportedPsdStandards.din1988300Residential
  | SupportedPsdStandards.din1988300Hospital
  | SupportedPsdStandards.din1988300Hotel
  | SupportedPsdStandards.din1988300School
  | SupportedPsdStandards.din1988300Office
  | SupportedPsdStandards.din1988300AssistedLiving
  | SupportedPsdStandards.din1988300NursingHome;

export enum SupportedDrainageMethods {
  AS2021FixtureUnits = "AS2021FixtureUnits",
  EN1205622000DischargeUnits = "EN1205622000DischargeUnits",
  UPC2018DrainageFixtureUnits = "UPC2018DrainageFixtureUnits",
  NCBIndia2016FlushTankPrivate = "NCBIndia2016FlushTankPrivate",
  NCBIndia2016FlushTankPublic = "NCBIndia2016FlushTankPublic",
  NBCIndia2016FlushValvePrivate = "NBCIndia2016FlushValvePrivate",
  NBCIndia2016FlushValvePublic = "NBCIndia2016FlushValvePublic",
}

export const DRAINAGE_METHOD_CHOICES: Choice[] = [
  {
    name: "AS/NZS3500.2:2021 Fixture Unit",
    key: SupportedDrainageMethods.AS2021FixtureUnits,
  },
  {
    name: "EN 12056-2:2000 Discharge Unit",
    key: SupportedDrainageMethods.EN1205622000DischargeUnits,
  },
  {
    name: "2018 UPC Drainage Fixture Unit",
    key: SupportedDrainageMethods.UPC2018DrainageFixtureUnits,
  },
  {
    name: "National Building Code of India 2016 - Flush Tank Private",
    key: SupportedDrainageMethods.NCBIndia2016FlushTankPrivate,
  },
  {
    name: "National Building Code of India 2016 - Flush Tank Public",
    key: SupportedDrainageMethods.NCBIndia2016FlushTankPublic,
  },
  {
    name: "National Building Code of India 2016 - Flush Valve Private",
    key: SupportedDrainageMethods.NBCIndia2016FlushValvePrivate,
  },
  {
    name: "National Building Code of India 2016 - Flush Valve Public",
    key: SupportedDrainageMethods.NBCIndia2016FlushValvePublic,
  },
];

export enum SupportedDistirctHeating {
  CIBSECP1Objective3_2 = "CIBSECP1Objective3_2",
}
export const DISTRICT_HEATING_CHOICES: Choice[] = [
  {
    name: "CIBSE CP1 - Objective 3.2",
    key: SupportedDistirctHeating.CIBSECP1Objective3_2,
  },
];

export const GRILLE_FLOW_RATE_TYPES: Choice<VentilationNode["flowRateType"]>[] =
  [
    { name: "Manual", key: "constant" },
    { name: "Percentage of load", key: "percentage" },
    { name: "Share units", key: "variable" },
    { name: "Room", key: "room" },
    { name: "Room Size", key: "room-size" },
  ];

export const GRILLE_FLOW_RATE_TYPES_HINT = `<ul style='text-align: left; padding-left: 20px;'>
               <li><strong>Share Units</strong>: Distributes the building's total required flow rate evenly among all units.</li>
               <li><strong>Percentage</strong>: Allows you to set a specific percentage of the total building flow rate to allocate.</li>
               <li><strong>Manual</strong>: Manually select the desired flow rate.</li>
               <li><strong>Room</strong>: Aligns with the specific flow rate required by each room.</li>
               <li><strong>Room Size</strong>: Matches the required flow rate of each room, and distributes any remaining building flow rate proportionally based on room area.</li>
             </ul>`;

export enum SupportedGasCalculationMethods {
  internationalFuelGasCodeEquation2018 = "intlFuelGasCodeEq2018",
}
export const GAS_CALCULATION_METHOD_CHOICES: Choice[] = [
  {
    name: "International Fuel Gas Code Equation 2018",
    key: SupportedGasCalculationMethods.internationalFuelGasCodeEquation2018,
  },
];

export enum EN12056FrequencyFactor {
  IntermittentUse = "IntermittentUse",
  FrequentUse = "FrequentUse",
  CongestedUse = "CongestedUse",
  SpecialUse = "SpecialUse",
}

export function getEN_12506_FREQUENCY_FACTOR_CHOICES(
  catalog: Catalog,
): Choice[] {
  return [
    {
      name: `Intermittent Use (${catalog.en12056FrequencyFactor.IntermittentUse})`,
      key: EN12056FrequencyFactor.IntermittentUse,
    },
    {
      name: `Frequent Use (${catalog.en12056FrequencyFactor.FrequentUse})`,
      key: EN12056FrequencyFactor.FrequentUse,
    },
    {
      name: `Congested Use (${catalog.en12056FrequencyFactor.CongestedUse})`,
      key: EN12056FrequencyFactor.CongestedUse,
    },
    {
      name: `Special Use (${catalog.en12056FrequencyFactor.SpecialUse})`,
      key: EN12056FrequencyFactor.SpecialUse,
    },
  ];
}

export function isSupportedPsdStandard(arg: any): arg is SupportedPsdStandards {
  return Object.values(SupportedPsdStandards).includes(arg);
}

export function isGermanStandard(
  psd: SupportedPsdStandards,
): psd is SupportedEquationPSDStandards {
  switch (psd) {
    case SupportedPsdStandards.as35002021LoadingUnits:
    case SupportedPsdStandards.barriesBookLoadingUnits:
    case SupportedPsdStandards.bs806:
    case SupportedPsdStandards.bs8558:
    case SupportedPsdStandards.cibseGuideG:
    case SupportedPsdStandards.ipc2018Flushometer:
    case SupportedPsdStandards.ipc2018FlushTanks:
    case SupportedPsdStandards.upc2018Flushometer:
    case SupportedPsdStandards.upc2018FlushTanks:
    case SupportedPsdStandards.indianStandardFlushTankPrivate:
    case SupportedPsdStandards.indianStandardFlushTankPublic:
    case SupportedPsdStandards.indianStandardFlushValvePrivate:
    case SupportedPsdStandards.indianStandardFlushValvePublic:
      return false;
    case SupportedPsdStandards.hcaa:
    case SupportedPsdStandards.din1988300Residential:
    case SupportedPsdStandards.din1988300Hospital:
    case SupportedPsdStandards.din1988300Hotel:
    case SupportedPsdStandards.din1988300School:
    case SupportedPsdStandards.din1988300Office:
    case SupportedPsdStandards.din1988300AssistedLiving:
    case SupportedPsdStandards.din1988300NursingHome:
      return true;
  }
  assertUnreachable(psd, false);
  return false;
}

export function isLUStandard(
  psd: SupportedPsdStandards,
): psd is SupportedLUPsdStandards {
  switch (psd) {
    case SupportedPsdStandards.as35002021LoadingUnits:
    case SupportedPsdStandards.barriesBookLoadingUnits:
    case SupportedPsdStandards.bs806:
    case SupportedPsdStandards.cibseGuideG:
    case SupportedPsdStandards.bs8558:
    case SupportedPsdStandards.ipc2018Flushometer:
    case SupportedPsdStandards.ipc2018FlushTanks:
    case SupportedPsdStandards.upc2018Flushometer:
    case SupportedPsdStandards.upc2018FlushTanks:
    case SupportedPsdStandards.indianStandardFlushTankPrivate:
    case SupportedPsdStandards.indianStandardFlushTankPublic:
    case SupportedPsdStandards.indianStandardFlushValvePrivate:
    case SupportedPsdStandards.indianStandardFlushValvePublic:
      return true;
    case SupportedPsdStandards.hcaa:
    case SupportedPsdStandards.din1988300Residential:
    case SupportedPsdStandards.din1988300Hospital:
    case SupportedPsdStandards.din1988300Hotel:
    case SupportedPsdStandards.din1988300School:
    case SupportedPsdStandards.din1988300Office:
    case SupportedPsdStandards.din1988300AssistedLiving:
    case SupportedPsdStandards.din1988300NursingHome:
      return false;
  }
  assertUnreachable(psd, false);
  return false;
}

export function isGas(system: FlowSystem | undefined): system is GasFlowSystem {
  return system?.type === "gas";
}

export function getAllGasFlowSystems(context: CoreContext): GasFlowSystem[] {
  const flowSystems = context.drawing.metadata.flowSystems;

  return Object.values(flowSystems).filter(isGas);
}

export function getAllHeatingFlowSystems(
  context: CoreContext,
): MechanicalFlowSystem[] {
  const flowSystems = context.drawing.metadata.flowSystems;

  return Object.values(flowSystems).filter(isHeating);
}

export function getAllCoolingFlowSystems(
  context: CoreContext,
): MechanicalFlowSystem[] {
  const flowSystems = context.drawing.metadata.flowSystems;

  return Object.values(flowSystems).filter(
    (s) => isCondenser(s) || isChilled(s),
  ) as MechanicalFlowSystem[];
}

export function isWater(system: FlowSystem | undefined) {
  return isPressure(system) && !isGas(system);
}

export function isDrainage(
  system: FlowSystem | undefined,
): system is SewerFlowSystem | StormwaterFlowSystem {
  if (!system) {
    return false;
  }
  switch (system.type) {
    case "sewer":
    case "stormwater":
      return true;
    default:
      return false;
  }
}

export function isFlowReversed(
  system: FlowSystem | undefined,
): system is SewerFlowSystem | StormwaterFlowSystem {
  if (!system) {
    return false;
  }
  switch (system.type) {
    case "sewer":
    case "stormwater":
      return true;
    case "ventilation":
      return (
        system.role === "vent-extract" ||
        system.role === "vent-intake" ||
        system.role === "vent-fan-exhaust"
      );
    default:
      return false;
  }
}

export function isSewer(
  system: FlowSystem | undefined,
): system is SewerFlowSystem {
  switch (system?.type) {
    case "sewer":
      return true;
    default:
      return false;
  }
}

export function isStormwater(
  system: FlowSystem | undefined,
): system is StormwaterFlowSystem {
  switch (system?.type) {
    case "stormwater":
      return true;
    default:
      return false;
  }
}

export function isPressure(system: FlowSystem | undefined) {
  return system?.type === "pressure" || isGas(system) || isFire(system);
}

export function isPressureOnly(system: FlowSystem | undefined) {
  return system?.type === "pressure";
}

// Used specifically for heating return loops and do not include hot water return loops.
export function isHeating(
  system: FlowSystem | undefined,
): system is MechanicalFlowSystem {
  return system?.type === "mechanical" && system.role === "heating";
}

export function isChilled(
  system: FlowSystem | undefined,
): system is MechanicalFlowSystem {
  return system?.type === "mechanical" && system.role === "chilled";
}

export function isCondenser(
  system: FlowSystem | undefined,
): system is MechanicalFlowSystem {
  return system?.type === "mechanical" && system.role === "condenser";
}

export function isCooling(
  system: FlowSystem | undefined,
): system is MechanicalFlowSystem {
  return isCondenser(system) || isChilled(system);
}

export function isVentilation(
  system: FlowSystem | undefined,
): system is VentilationFlowSystem {
  return system?.type === "ventilation";
}

export function isFire(
  system: FlowSystem | undefined,
): system is FireFlowSystem {
  return system?.type === "fire";
}

export function isCoolingPlantSystem(system: FlowSystem | undefined) {
  return isCooling(system);
}

export function isHeatingPlant(
  plant: PlantConcrete,
  flowSystems: DrawingState["metadata"]["flowSystems"],
) {
  switch (plant.type) {
    case PlantType.AHU:
    case PlantType.AHU_VENT:
    case PlantType.FCU:
    case PlantType.UFH:
    case PlantType.MANIFOLD:
    case PlantType.RADIATOR:
      return true;
    case PlantType.RETURN_SYSTEM:
      if (plant.outlets.length > 0) {
        return isHeatingPlantSystem(
          flowSystems[plant.outlets[0].outletSystemUid],
        );
      }
      return false;
    case PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP:
    case PlantType.DRAINAGE_PIT:
    case PlantType.PUMP:
    case PlantType.PUMP_TANK:
    case PlantType.TANK:
    case PlantType.VOLUMISER:
    case PlantType.CUSTOM:
    case PlantType.FILTER:
    case PlantType.RO:
    case PlantType.DUCT_MANIFOLD:
      return false;
    default:
      assertUnreachable(plant);
  }

  return false;
}

export function isChilledPlant(
  plant: PlantConcrete,
  flowSystems: DrawingState["metadata"]["flowSystems"],
) {
  switch (plant.type) {
    case PlantType.AHU:
    case PlantType.AHU_VENT:
    case PlantType.FCU:
      return true;
    case PlantType.RETURN_SYSTEM:
      if (plant.outlets.length > 0) {
        return isCoolingPlantSystem(
          flowSystems[plant.outlets[0].outletSystemUid],
        );
      }
      return false;
    case PlantType.UFH:
    case PlantType.MANIFOLD:
    case PlantType.RADIATOR:
    case PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP:
    case PlantType.DRAINAGE_PIT:
    case PlantType.PUMP:
    case PlantType.PUMP_TANK:
    case PlantType.TANK:
    case PlantType.VOLUMISER:
    case PlantType.CUSTOM:
    case PlantType.FILTER:
    case PlantType.RO:
    case PlantType.DUCT_MANIFOLD:
      return false;
    default:
      assertUnreachable(plant);
  }

  return false;
}

export function isHeatingPlantSystem(system: FlowSystem | undefined) {
  return !isCoolingPlantSystem(system);
}

export function isUnderfloor(
  system: FlowSystem | undefined,
): system is UnderfloorHeatingFlowSystem {
  return system?.type === "underfloor";
}

export function isMechanical(
  system: FlowSystem | undefined,
): system is MechanicalFlowSystem {
  return system?.type === "mechanical";
}

export function isClosedSystem(system: FlowSystem | undefined): boolean {
  return isHeating(system) || isCooling(system);
}

export function shouldCalculateStagnantFlowDeadleg(
  system: FlowSystem | undefined,
) {
  // include warm water because a customer has requested this.
  return system && "return" in system && system.hasReturnSystem;
}

export function shouldCalculateIsolatedRunDeadleg(
  system: FlowSystem | undefined,
) {
  return system?.role === "coldwater";
}

//
export const DISPLAY_PSD_METHODS: Choice[] = [
  {
    name: "AS3500 2021 Loading Units",
    key: SupportedPsdStandards.as35002021LoadingUnits,
  },
  {
    name: "Barrie's Book Loading Units",
    key: SupportedPsdStandards.barriesBookLoadingUnits,
  },
  {
    name: "HCAA",
    key: SupportedPsdStandards.hcaa,
  },
  { name: "CIPHE", key: SupportedPsdStandards.cibseGuideG },
  { name: "BS 806", key: SupportedPsdStandards.bs806 },
  { name: "BS 8558", key: SupportedPsdStandards.bs8558 },

  {
    name: "DIN 1988-300 - Residential",
    key: SupportedPsdStandards.din1988300Residential,
  },
  {
    name: "DIN 1988-300 - Hospital",
    key: SupportedPsdStandards.din1988300Hospital,
  },
  { name: "DIN 1988-300 - Hotel", key: SupportedPsdStandards.din1988300Hotel },
  {
    name: "DIN 1988-300 - School",
    key: SupportedPsdStandards.din1988300School,
  },
  {
    name: "DIN 1988-300 - Office",
    key: SupportedPsdStandards.din1988300Office,
  },
  {
    name: "DIN 1988-300 - Assisted Living",
    key: SupportedPsdStandards.din1988300AssistedLiving,
  },
  {
    name: "DIN 1988-300 - Nursing Home",
    key: SupportedPsdStandards.din1988300NursingHome,
  },

  {
    name: "International Plumbing Code 2018 Flushometer",
    key: SupportedPsdStandards.ipc2018Flushometer,
  },
  {
    name: "International Plumbing Code 2018 Flush Tanks",
    key: SupportedPsdStandards.ipc2018FlushTanks,
  },

  {
    name: "Uniform Plumbing Code 2018 Flushometer",
    key: SupportedPsdStandards.upc2018Flushometer,
  },
  {
    name: "Uniform Plumbing Code 2018 Flush Tanks",
    key: SupportedPsdStandards.upc2018FlushTanks,
  },
  {
    name: "Indian Standard Flush Tank Private",
    key: SupportedPsdStandards.indianStandardFlushTankPrivate,
  },
  {
    name: "Indian Standard Flush Tank Public",
    key: SupportedPsdStandards.indianStandardFlushTankPublic,
  },
  {
    name: "Indian Standard Flush Valve Private",
    key: SupportedPsdStandards.indianStandardFlushValvePrivate,
  },
  {
    name: "Indian Standard Flush Valve Public",
    key: SupportedPsdStandards.indianStandardFlushValvePublic,
  },
];

export function getPsdMethods(catalog: Catalog): Choice[] {
  const methods: Choice[] = cloneSimple(DISPLAY_PSD_METHODS);

  for (const key of Object.keys(catalog.psdStandards)) {
    const standard = catalog.psdStandards[key];
    const index = methods.findIndex((p) => p.key === key);
    if (index === -1) {
      methods.push({
        disabled: false,
        key,
        name: standard.name,
      });
    } else {
      methods[index].disabled = false;
    }
  }

  return methods;
}

export type PipePhysicalMaterial =
  | "plainCarbonSteel"
  | "castIronCoated"
  | "copperTypeB"
  | "copper"
  | "gmsMedium"
  | "hdpeSdr11"
  | "polybutylene"
  | "pexSdr74"
  | "stainlessSteel"
  | "ppr"
  | "cpvc"
  | "stainlessSteelDrainage"
  | "uPVCDrainage"
  | "hdpeSdr11Drainage"
  | "castIronDrainage"
  | "mlcp"
  | "mdpe"
  | "pertAl"
  | "pertEvoh";

export type DuctPhysicalMaterial =
  | "hotDipGalvanisedSteel"
  | "flexibleDuct"
  | "galvanisedSteel"
  | "carbonSteel"
  | "pvc"
  | "aluminium"
  | "fibrousGlass"
  | "semiRigid";

// have a record here to exhaust materials
export const PIPE_MATERIAL_ROLES: Record<
  PipePhysicalMaterial,
  Array<PipeMaterialRole> | null
> = {
  plainCarbonSteel: ["pressure"],
  castIronCoated: ["pressure"],
  copperTypeB: ["pressure"],
  copper: null,
  gmsMedium: ["pressure"],
  hdpeSdr11: ["pressure"],
  polybutylene: ["pressure", "ufh"],
  pexSdr74: ["pressure", "ufh"],
  stainlessSteel: ["pressure"],
  ppr: ["pressure"],
  cpvc: ["pressure"],

  stainlessSteelDrainage: ["drainage"],
  uPVCDrainage: ["drainage"],
  hdpeSdr11Drainage: ["drainage"],
  castIronDrainage: ["drainage"],

  mlcp: ["pressure", "ufh"],
  mdpe: ["pressure"],
  pertAl: ["ufh"],
  pertEvoh: ["ufh"],
};

export enum InsulationMaterials {
  calciumSilicate = "calciumSilicate",
  cellularGlass = "cellularGlass",
  elastomeric = "elastomeric",
  fiberglass = "fiberglass",
  mineralWool = "mineralWool",
  polyisocyanurate = "polyisocyanurate",
  mmKemblaInsulation = "mmKemblaInsulation",
}

export const INSULATION_MATERIAL_CHOICES: Choice[] = [
  { key: InsulationMaterials.calciumSilicate, name: "Calcium Silicate" },
  { key: InsulationMaterials.cellularGlass, name: "Cellular Glass" },
  { key: InsulationMaterials.elastomeric, name: "Elastomeric" },
  { key: InsulationMaterials.fiberglass, name: "Fiberglass" },
  { key: InsulationMaterials.mineralWool, name: "Mineral Wool" },
  { key: InsulationMaterials.polyisocyanurate, name: "Polyisocyanurate" },
  { key: InsulationMaterials.mmKemblaInsulation, name: "MM Kembla Insulation" },
];

export enum InsulationJackets {
  noJacket = "noJacket",
  pvcJacket = "pvcJacket",
  allServiceJacket = "allServiceJacket",
  paintedMetal = "paintedMetal",
  aluminizedPaint = "aluminizedPaint",
  stainlessSteelDull = "stainlessSteelDull",
  galvanizedSteelDippedOrDull = "galvanizedSteelDippedOrDull",
  stainlessSteelNewCleaned = "stainlessSteelNewCleaned",
  galvanizedSteelNewBright = "galvanizedSteelNewBright",
  aluminiumOxidedInService = "aluminiumOxidedInService",
  aluminiumNewBright = "aluminiumNewBright",
}

export const INSULATION_JACKET_CHOICES: Choice[] = [
  { key: InsulationJackets.noJacket, name: "No Jacket (0.90 \u03b5)" },
  { key: InsulationJackets.pvcJacket, name: "PVC Jacket (0.90 \u03b5)" },
  {
    key: InsulationJackets.allServiceJacket,
    name: "All Service Jacket (0.90 \u03b5)",
  },
  { key: InsulationJackets.paintedMetal, name: "Painted Metal (0.80 \u03b5)" },
  {
    key: InsulationJackets.aluminizedPaint,
    name: "Aluminized Paint (0.50 \u03b5)",
  },
  {
    key: InsulationJackets.stainlessSteelDull,
    name: "Stainless Steel (Dull, 0.30 \u03b5)",
  },
  {
    key: InsulationJackets.galvanizedSteelDippedOrDull,
    name: "Galvanized Steel (Dipped / Dull, 0.28 \u03b5)",
  },
  {
    key: InsulationJackets.stainlessSteelNewCleaned,
    name: "Stainless Steel (New / Cleaned, 0.13 \u03b5)",
  },
  {
    key: InsulationJackets.galvanizedSteelNewBright,
    name: "Galvanized Steel (New / Bright, 0.10 \u03b5)",
  },
  {
    key: InsulationJackets.aluminiumOxidedInService,
    name: "Aluminium Oxide (In Service, 0.10 \u03b5)",
  },
  {
    key: InsulationJackets.aluminiumNewBright,
    name: "Aluminium Oxide (Bright, 0.04 \u03b5)",
  },
];

export enum SupportedDwellingStandards {
  as35002021Dwellings = "as35002021Dwellings",
  barriesBookDwellings = "barriesBookDwellings",
}

export enum RingMainCalculationMethod {
  PSD_FLOW_RATE_DISTRIBUTED = "PSD_FLOW_RATE_DISTRIBUTED",
  ISOLATION_CASES = "ISOLATION_CASES",
  MAX_DISTRIBUTED_AND_ISOLATION_CASES = "MAX_DISTRIBUTED_AND_ISOLATION_CASES",
}

export const RING_MAIN_CALCULATION_METHODS: Choice[] = [
  {
    name: "Isolation Cases by Closing any Isolation Valve",
    disabled: false,
    key: RingMainCalculationMethod.ISOLATION_CASES,
  },
  {
    name: "PSD Flow rate distributed according to Loading/Dwelling Units",
    disabled: true,
    key: RingMainCalculationMethod.PSD_FLOW_RATE_DISTRIBUTED,
  },

  {
    name: "Max. of Distributed PSD or Isolation Method",
    disabled: true,
    key: RingMainCalculationMethod.MAX_DISTRIBUTED_AND_ISOLATION_CASES,
  },
  {
    name: "99th percentile computer simulated PSD",
    disabled: true,
    key: "monteCarlo",
  },
];
export const PIPE_SIZING_METHODS: Choice[] = [
  { name: "Maximum velocity", key: "velocity" },
  {
    name: "Maximum pressure drop",
    key: "pressure",
  },
  {
    name: "Maximum velocity and pressure drop",
    key: "both",
  },
];
export const DUCT_SIZING_METHODS: Choice[] = [
  { name: "Maximum velocity", key: "velocity" },
  {
    name: "Maximum pressure drop",
    key: "pressure",
  },
  {
    name: "Maximum velocity and pressure drop",
    key: "both",
  },
];

export enum ComponentPressureLossMethod {
  INDIVIDUALLY = "INDIVIDUALLY",
  PERCENT_ON_TOP_OF_PIPE = "PERCENT_ON_TOP_OF_PIPE",
}

export const COMPONENT_PRESSURE_LOSS_METHODS: Choice[] = [
  {
    name: "Based on all system component's specific pressure loss",
    key: ComponentPressureLossMethod.INDIVIDUALLY,
  },
  {
    name: "Percentage on top of pipe lengths only",
    key: ComponentPressureLossMethod.PERCENT_ON_TOP_OF_PIPE,
  },
];

export const LEVEL_HEIGHT_DIFF_M = 3;
export const CURRENT_VERSION = 123;

export enum StandardFluidUids {
  WATER = "water",
  NATURAL_GAS = "naturalGas",
  LPG = "LPG",
  SEWAGE = "sewage",
}

export enum StandardFlowSystemUids {
  ColdWater = "cold-water",
  RainwaterReuse = "rainwater-reuse",
  ReverseOsmosis = "reverse-osmosis",
  HotWater = "hot-water",
  WarmWater = "warm-water",

  Gas = "gas",
  FireHydrant = "fire-hydrant",
  FireHoseReel = "fire-hose-reel",
  FireSprinkler = "fire-sprinkler",

  StormWater = "stormwater",
  BackupStormWater = "backup-stormwater",

  SewerDrainage = "sewer-drainage",
  SanitaryPlumbing = "sanitary-plumbing",
  GreaseWaste = "grease-waste",
  TradeWaste = "trade-waste",
  RisingMain = "rising-main",

  Heating = "heating",
  UnderfloorHeating = "underfloor-heating",
  Chilled = "chilled",
  Condenser = "condenser",

  // Ventilation = "ventilation",
  VentilationSupply = "ventilation-supply",
  VentilationExtract = "ventilation-extract",
  VentilationIntake = "ventilation-intake",
  VentilationExhaust = "ventilation-exhaust",
  VentilationFanExhaust = "ventilation-fan-exhaust",
}

export type ProjectSetupWizardFlowSystemUidsTypes =
  | StandardFlowSystemUids.ColdWater
  | StandardFlowSystemUids.HotWater
  | StandardFlowSystemUids.WarmWater
  | StandardFlowSystemUids.Gas
  | StandardFlowSystemUids.Heating
  | StandardFlowSystemUids.Chilled
  | StandardFlowSystemUids.Condenser
  | StandardFlowSystemUids.VentilationSupply
  | StandardFlowSystemUids.VentilationExtract
  | StandardFlowSystemUids.VentilationIntake
  | StandardFlowSystemUids.VentilationExhaust;

const ProjectSetupWizardFlowSystemUidsRecord: Record<
  ProjectSetupWizardFlowSystemUidsTypes,
  void
> = {
  [StandardFlowSystemUids.ColdWater]: undefined,
  [StandardFlowSystemUids.HotWater]: undefined,
  [StandardFlowSystemUids.WarmWater]: undefined,
  [StandardFlowSystemUids.Gas]: undefined,
  [StandardFlowSystemUids.Heating]: undefined,
  [StandardFlowSystemUids.Chilled]: undefined,
  [StandardFlowSystemUids.Condenser]: undefined,
  [StandardFlowSystemUids.VentilationSupply]: undefined,
  [StandardFlowSystemUids.VentilationExtract]: undefined,
  [StandardFlowSystemUids.VentilationIntake]: undefined,
  [StandardFlowSystemUids.VentilationExhaust]: undefined,
};

export const ProjectSetupWizardFlowSystemUids: ProjectSetupWizardFlowSystemUidsTypes[] =
  Object.keys(
    ProjectSetupWizardFlowSystemUidsRecord,
  ) as ProjectSetupWizardFlowSystemUidsTypes[];

export type DrainageSystemUid =
  | StandardFlowSystemUids.SewerDrainage
  | StandardFlowSystemUids.SanitaryPlumbing
  | StandardFlowSystemUids.GreaseWaste
  | StandardFlowSystemUids.TradeWaste
  | StandardFlowSystemUids.RisingMain
  | StandardFlowSystemUids.StormWater
  | StandardFlowSystemUids.BackupStormWater;

const DrainageSystemUidsRecord: Record<DrainageSystemUid, void> = {
  [StandardFlowSystemUids.SewerDrainage]: undefined,
  [StandardFlowSystemUids.SanitaryPlumbing]: undefined,
  [StandardFlowSystemUids.GreaseWaste]: undefined,
  [StandardFlowSystemUids.TradeWaste]: undefined,
  [StandardFlowSystemUids.RisingMain]: undefined,
  [StandardFlowSystemUids.StormWater]: undefined,
  [StandardFlowSystemUids.BackupStormWater]: undefined,
};

export const ALL_DRAINAGE_SYSTEM_UIDS: DrainageSystemUid[] = Object.keys(
  DrainageSystemUidsRecord,
) as DrainageSystemUid[];

export enum StandardMaterialUids {
  Copper = "copperTypeB",
  Pex = "pexSdr74",
}

const INSULATION_THICKNESS_MMKEMBLA: SelectField[] = [
  { value: 9, text: "9" },
  { value: 13, text: "13" },
  { value: 19, text: "19" },
  { value: 25, text: "25" },
  { value: 32, text: "32" },
  { value: 38, text: "38" },
];

const INSULATION_THICKNESS_MMKEMBLA_IN: SelectField[] = [
  { value: 9, text: "⅜" },
  { value: 13, text: "½" },
  { value: 19, text: "¾" },
  { value: 25, text: "1" },
  { value: 32, text: "1¼" },
  { value: 38, text: "1½" },
];

export function getInsulationThicknessMMKEMBLA(units: UnitsParameters) {
  switch (units.lengthMeasurementSystem) {
    case MeasurementSystem.METRIC:
      return INSULATION_THICKNESS_MMKEMBLA;
    case MeasurementSystem.IMPERIAL:
      return INSULATION_THICKNESS_MMKEMBLA_IN;
  }
  assertUnreachable(units.lengthMeasurementSystem);
}
