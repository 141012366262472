
import { defineComponent } from "vue";
import {
  defaultPasswordRequirements,
  OrganizationType,
} from "../../../common/src/models/Organization";
import { AccessLevel, User } from "../../../common/src/models/User";
import ProfileMenuItem from "../../src/components/ProfileMenuItem.vue";

export default defineComponent({
  components: { ProfileMenuItem },
  data() {
    const form: {
      confirmEmail: string;
      isLoading: boolean;
    } = {
      confirmEmail: "",
      isLoading: false,
    };
    const isLoading: boolean = false;
    const emailVerificationSent: boolean = false;

    return {
      emailVerificationSent,
      isLoading,
      form,
    };
  },
  computed: {
    profile(): User {
      const profile = this.$store.getters["profile/profile"];
      if (!profile) {
        return {
          username: "",
          accessLevel: AccessLevel.USER,
          organization: {
            id: "",
            name: "",
            type: OrganizationType.BUILDING_SERVICES,
            passwordRequirements: defaultPasswordRequirements,
          },
          email: "",
          subscribed: false,
          name: "",
          passwordHash: "",
          eulaAcceptedOn: null,
          lastActivityOn: null,
          lastNoticeSeenOn: null,
          temporaryOrganizationName: null,
          temporaryUser: false,
        } as User;
      } else {
        return profile;
      }
    },
    isAdmin() {
      return this.profile.accessLevel <= AccessLevel.ADMIN;
    },
    isManufacturer() {
      return this.profile.organization?.type === OrganizationType.MANUFACTURER;
    },
    isBuildingServices() {
      return (
        this.profile.organization?.type === OrganizationType.BUILDING_SERVICES
      );
    },
  },
});
