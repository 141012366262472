import {
  fillDefaultLineFields,
  LineEntity,
} from "../document/entities/lines/line-entity";
import { AnnotationLineEntity } from "../document/entities/lines/line-types";
import { EntityType } from "../document/entities/types";
import { CoreBaseEdge } from "./core-traits/coreBaseEdge";
import CoreBaseBackedObject from "./lib/coreBaseBackedObject";

const Base = CoreBaseEdge(CoreBaseBackedObject<LineEntity>);

export default class CoreLine extends Base {
  type: EntityType.LINE = EntityType.LINE;

  get refPath(): string {
    return `${this.entity.type}`;
  }

  get filledEntity(): AnnotationLineEntity {
    return fillDefaultLineFields(this.context, this.entity);
  }
}
