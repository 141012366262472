import Flatten from "@flatten-js/core";
import * as TM from "transformation-matrix";
import { CoreConnectableObjectConcrete } from "..";
import { collect } from "../../../lib/array-utils";
import { Coord3D, coordDist } from "../../../lib/coord";
import { canonizeAngleRad } from "../../../lib/mathUtils/mathutils";
import { EPS, cloneSimple } from "../../../lib/utils";
import { Vector3 } from "../../../lib/vector3";
import { CoreContext } from "../../calculations/types";
import {
  EdgeEntityConcrete,
  isConnectableEntity,
} from "../../document/entities/concrete-entity";
import Cached from "../lib/cached";
import CoreBaseBackedObject from "../lib/coreBaseBackedObject";
import { GuessEntity } from "../lib/types";

export interface ICoreEdge {
  worldEndpoints(excludeUid?: string | null): Coord3D[];
  shape: Flatten.Segment | Flatten.Point;
}

export function CoreBaseEdge<
  T extends abstract new (...args: any[]) => CoreBaseBackedObject<I>,
  I extends EdgeEntityConcrete = GuessEntity<T>,
>(Base: T) {
  abstract class Generated extends Base implements ICoreEdge {
    getHash(): string {
      const ao = this.globalStore.getConnectable(
        this.entity.endpointUid[0],
      ) as CoreConnectableObjectConcrete;
      const bo = this.globalStore.getConnectable(
        this.entity.endpointUid[1],
      ) as CoreConnectableObjectConcrete;
      if (!ao || !bo) {
        throw new Error(
          "One of pipe's endpoints are missing. Pipe is: " +
            JSON.stringify(this.entity),
        );
      }

      const key = ao.getHash() + " " + bo.getHash();
      // const hash = createHash("sha256");
      // hash.update(key.toString());
      // return hash.digest("hex");
      return key;
    }

    get computedLengthM(): number {
      const [wa, wb] = this.worldEndpoints();
      return (
        Math.sqrt(
          (wa.x - wb.x) ** 2 + (wa.y - wb.y) ** 2 + (wa.z - wb.z) ** 2,
        ) / 1000
      );
    }

    // Returns the world coordinates of the two endpoints
    @Cached(
      (kek) => {
        return new Set(
          [
            kek,
            ...kek
              .getCoreNeighbours()
              .map((o) => o?.getParentChain())
              .flat(),
          ].map((o) => o?.uid),
        );
      },
      (excludeUid) => excludeUid,
    )
    worldEndpoints(excludeUid: string | null = null): Coord3D[] {
      const ao = this.globalStore.get(
        this.entity.endpointUid[0],
      ) as CoreConnectableObjectConcrete;
      const bo = this.globalStore.get(
        this.entity.endpointUid[1],
      ) as CoreConnectableObjectConcrete;
      if (!ao || !bo) {
        throw new Error(
          "One of pipe's endpoints are missing. Pipe is: " +
            JSON.stringify(this.entity),
        );
      }
      if (ao && bo) {
        const res: Coord3D[] = [];
        if (
          (ao.entity.calculationHeightM === null) !==
          (bo.entity.calculationHeightM === null)
        ) {
          throw new Error(
            "We are working with a 3d object and a 2d object - not allowed \n" +
              JSON.stringify(ao.entity) +
              "\n" +
              JSON.stringify(bo.entity),
          );
        }
        if (ao.uid !== excludeUid) {
          const a = ao.toWorldCoord({ x: 0, y: 0 });
          res.push({
            x: a.x,
            y: a.y,
            z: (ao.entity.calculationHeightM || 0) * 1000,
          });
        }
        if (bo.uid !== excludeUid) {
          const b = bo.toWorldCoord({ x: 0, y: 0 });
          res.push({
            x: b.x,
            y: b.y,
            z: (bo.entity.calculationHeightM || 0) * 1000,
          });
        }

        return res;
      } else {
        throw new Error(
          "One of pipe's endpoints are missing. Pipe is: " +
            JSON.stringify(this.entity),
        );
      }
    }

    get position(): TM.Matrix {
      // We don't draw by object location because the object doesn't really have an own location. Instead, its
      // location is determined by other objects.
      return TM.identity();
    }

    preCalculationValidation(context: CoreContext) {
      return null;
    }

    getCoreNeighbours(): CoreBaseBackedObject[] {
      return collect(this.entity.endpointUid, (uid) =>
        this.globalStore.getSafe(uid),
      );
    }

    getVisualDeps() {
      return super.getVisualDeps().concat(cloneSimple(this.entity.endpointUid));
    }

    get shape(): Flatten.Segment | Flatten.Point {
      const [a, b] = this.worldEndpoints();
      if (coordDist(a, b) < 1e-5) {
        return Flatten.point([(a.x + b.x) / 2, (a.y + b.y) / 2]);
      } else {
        return Flatten.segment(a.x, a.y, b.x, b.y);
      }
    }

    get vector(): Flatten.Vector {
      let a = this.globalStore
        .getCenteredOrThrow(this.entity.endpointUid[0])
        .toWorldCoord();
      let b = this.globalStore
        .getCenteredOrThrow(this.entity.endpointUid[1])
        .toWorldCoord();
      return new Flatten.Vector(b.x - a.x, b.y - a.y);
    }

    get vector3(): Vector3 {
      let ao = this.globalStore.getCenteredOrThrow(this.entity.endpointUid[0]);
      let a = ao.toWorldCoord();
      let bo = this.globalStore.getCenteredOrThrow(this.entity.endpointUid[1]);
      let b = bo.toWorldCoord();
      const az = isConnectableEntity(ao.entity)
        ? ao.entity.calculationHeightM
        : 0;
      const bz = isConnectableEntity(bo.entity)
        ? bo.entity.calculationHeightM
        : 0;
      return new Vector3(
        b.x - a.x,
        b.y - a.y,
        bz == null || az == null ? 0 : (bz - az) * 1000,
      );
    }

    get segment(): Flatten.Segment {
      let a = this.globalStore
        .getCenteredOrThrow(this.entity.endpointUid[0])
        .toWorldCoord();
      let b = this.globalStore
        .getCenteredOrThrow(this.entity.endpointUid[1])
        .toWorldCoord();
      return new Flatten.Segment(
        new Flatten.Point(a.x, a.y),
        new Flatten.Point(b.x, b.y),
      );
    }

    static calculateAngleRad(shape: Flatten.Segment) {
      if (shape.length < EPS) {
        return 0;
      }
      let angle = Flatten.vector(shape.start, shape.end).angleTo(
        Flatten.vector([1, 0]),
      );
      return canonizeAngleRad(angle);
    }

    get zeroRotationNormal(): Vector3 {
      // TODO
      return new Vector3(0, 0, 1);
    }
  }

  return Generated;
}
