import { Color } from "../../lib/color";
import {
  assertUnreachable,
  assertUnreachableAggressive,
  Choice,
  NonNullableProps,
} from "../../lib/utils";
import {
  UnderfloorHeatingSettings,
  UnderfloorHeatingSettingsV3,
} from "../calculations/underfloor-heating/types";
import {
  DuctPhysicalMaterial,
  InsulationJackets,
  InsulationMaterials,
  isSewer,
  PipePhysicalMaterial,
} from "../config";
import {
  DuctConduit,
  DuctConduitEntity,
  PipeConduit,
  PipeConduitEntity,
} from "./entities/conduit-entity";
import { DuctFittingStructureFields } from "./entities/fitting-entity";
import { WithID } from "./entities/simple-entities";

// Flow system type defines flow systems groups that are different
// enough to have different data structures to each other.
export type FlowSystemType =
  | "pressure"
  | "gas"
  | "sewer"
  | "stormwater"
  | "mechanical"
  | "fire"
  | "underfloor"
  | "ventilation";

export type PipeFlowSystemTypes =
  | "pressure"
  | "gas"
  | "sewer"
  | "stormwater"
  | "mechanical"
  | "fire";

export type DuctFlowSystemTypes = "ventilation";

export function isPipeFlowSystem(
  system: FlowSystem | undefined,
): system is FlowSystemTypeMap[PipeFlowSystemTypes] {
  if (!system) {
    return false;
  }
  switch (system.type) {
    case "pressure":
    case "gas":
    case "sewer":
    case "stormwater":
    case "mechanical":
    case "fire":
      return true;
    case "underfloor":
    case "ventilation":
      return false;
    default:
      assertUnreachableAggressive(system);
  }
}

export function isDuctFlowSystem(
  system: FlowSystem | undefined,
): system is FlowSystemTypeMap[DuctFlowSystemTypes] {
  if (!system) {
    return false;
  }
  switch (system.type) {
    case "ventilation":
      return true;
    case "pressure":
    case "gas":
    case "sewer":
    case "stormwater":
    case "mechanical":
    case "fire":
    case "underfloor":
      return false;
    default:
      assertUnreachableAggressive(system);
  }
}

export interface FlowSystemTypeMap {
  pressure: PressureFlowSystem;
  gas: GasFlowSystem;
  sewer: SewerFlowSystem;
  stormwater: StormwaterFlowSystem;
  mechanical: MechanicalFlowSystem;
  fire: FireFlowSystem;
  ventilation: VentilationFlowSystem;
  underfloor: UnderfloorHeatingFlowSystem;
}

export interface FlowSystemNetworkKeyMap {
  pressure: PressureNetworkType;
  gas: GasNetworkType;
  sewer: SewerNetworkType;
  stormwater: StormwaterNetworkType;
  mechanical: MechanicalNetworkType;
  fire: FireNetworkType;
  ventilation: VentilationNetworkType;
  underfloor: UnderfloorHeatingNetworkType;
}

export interface FlowSystemNetworkMap {
  pressure: PressureNetworkParams;
  gas: GasNetworkParams;
  sewer: SewerNetworkParams;
  stormwater: StormwaterNetworkParams;
  mechanical: MechanicalNetworkParams;
  fire: FireNetworkParams;
  ventilation: VentilationNetworkParams;
  underfloor: UnderfloorHeatingNetworkParams;
}

export interface FlowSystemConduitTypeMap {
  pressure: "pipe";
  gas: "pipe";
  sewer: "pipe";
  stormwater: "pipe";
  mechanical: "pipe";
  fire: "pipe";
  ventilation: "duct";
  underfloor: null;
}

export const FLOW_SYSTEM_TO_CONDUIT_TYPE: FlowSystemConduitTypeMap = {
  pressure: "pipe",
  gas: "pipe",
  sewer: "pipe",
  stormwater: "pipe",
  mechanical: "pipe",
  fire: "pipe",
  ventilation: "duct",

  // Null because UFH system does not produce drawn conduit entities.
  underfloor: null,
};

// Flow system roles define flow systems that act the same.
// Users are able to create custom flow systems that mimic standard
// ones by setting the same role.
export type FlowSystemRole =
  | "coldwater"
  | "warmwater"
  | "hotwater"
  | "gas"
  | "fire"
  | "heating"
  | "chilled"
  | "condenser"
  | "sewer"
  | "stormwater"
  | "underfloor-heating"
  | "vent-supply"
  | "vent-extract"
  | "vent-intake"
  | "vent-exhaust"
  | "vent-fan-exhaust";

export const FLOW_SYSTEM_ROLE_NAME: Record<FlowSystemRole, string> = {
  coldwater: "Cold Water",
  warmwater: "Warm Water",
  hotwater: "Hot Water",
  gas: "Gas",
  fire: "Fire",
  heating: "Heating",
  chilled: "Chilled",
  condenser: "Condenser",
  sewer: "Sewer",
  stormwater: "Stormwater",
  "underfloor-heating": "Underfloor Heating",
  "vent-supply": "Vent Supply",
  "vent-extract": "Vent Return",
  "vent-intake": "Vent Intake",
  "vent-exhaust": "Vent Exhaust",
  "vent-fan-exhaust": "Fan Exhaust",
};

export type PipeMaterialRole = "pressure" | "drainage" | "ufh";

export interface FlowSystemBase extends WithID {
  type: FlowSystemType;
  role: FlowSystemRole;

  temperatureC: number;
  name: string;
  color: Color;
  fluid: string;
  defaultPipeHeightM: number;
}

export type PressureNetworkType = "risers" | "reticulations" | "connections";
export type GasNetworkType = "risers" | "reticulations" | "connections";
export type SewerNetworkType = "stacks" | "pipes" | "vents";
export type StormwaterNetworkType = "stacks" | "pipes";
export type MechanicalNetworkType = "risers" | "reticulations";
export type FireNetworkType = "risers" | "reticulations" | "connections";
export type UnderfloorHeatingNetworkType = "pipes";
export type VentilationNetworkType =
  | "risers"
  | "mains"
  | "branches"
  | "connections";

export type NetworkType =
  | PressureNetworkType
  | GasNetworkType
  | SewerNetworkType
  | StormwaterNetworkType
  | MechanicalNetworkType
  | FireNetworkType
  | VentilationNetworkType;

export interface PressureNetworkParams {
  velocityMS: number;
  spareCapacityPCT: number;
  pressureDropKPAM: number;
  material: PipePhysicalMaterial;
  minimumPipeSize: number;
}

export interface GasNetworkParams {
  velocityMS: number;
  pressureDropKPAM: number;
  material: PipePhysicalMaterial;
  minimumPipeSize: number;
}

export interface SewerNetworkParams {
  material: PipePhysicalMaterial;
}

export interface StormwaterNetworkParams {
  material: PipePhysicalMaterial;
}

// tslint:disable-next-line:no-empty-interface
export interface UnderfloorHeatingNetworkParams {}

export type MechanicalNetworkParams = PressureNetworkParams;

export type FireNetworkParams = PressureNetworkParams;

export type NetworkParams =
  | PressureNetworkParams
  | GasNetworkParams
  | SewerNetworkParams
  | StormwaterNetworkParams
  | MechanicalNetworkParams
  | FireNetworkParams
  | UnderfloorHeatingNetworkParams;

export const SIZING_MODE_CHOICES: Choice[] = [
  { key: "discrete", name: "Recommended Sizes Only" },
  { key: "increment", name: "Step Increment" },
  { key: "continuous", name: "Unrestricted" },
];

export interface VentilationNetworkParams
  // Default fitting preferences for the network
  // If a fitting involves more networks, the more dominant
  // network is used.
  extends NonNullableProps<DuctFittingStructureFields> {
  velocityMS: number;
  spareCapacityPCT: number;
  pressureDropKPAM: number;
  material: DuctPhysicalMaterial; // TODO: duct physical material
  minimumDuctDiameterSizeMM: number;
  minimumDuctWidthSizeMM: number;
  minimumDuctHeightSizeMM: number;

  // Discrete looks up the catalog
  // Continuous increment looks up the catalog and increments by the sizing increment
  sizingMode: "discrete" | "increment" | "continuous";
  sizingIncrementMM: number;
  shape: "rectangular" | "circular";
  rectangleWHRatio: number;
}

export interface PressureFlowSystem extends FlowSystemBase {
  type: "pressure";
  hasReturnSystem: boolean;
  return: {
    color: Color;
    maxVelocityMS: number;
    insulated: boolean;
    insulation: {
      material: InsulationMaterials;
      jacket: InsulationJackets;
      thicknessMM: number;
    };
  };

  networks: Record<PressureNetworkType, PressureNetworkParams> &
    Partial<Record<Exclude<NetworkType, PressureNetworkType>, undefined>>;
}

export interface GasFlowSystem extends FlowSystemBase {
  type: "gas";
  networks: Record<GasNetworkType, GasNetworkParams> &
    Partial<Record<Exclude<NetworkType, GasNetworkType>, undefined>>;
}

export interface VentSizing {
  minUnits: number;
  maxUnits: number;
  sizeMM: number;
}

export interface StackPipeSizing {
  minUnits: number;
  maxUnits: number;
  sizeMM: number;
  maximumUnitsPerLevel: number;
}

export interface HorizontalPipeSizing {
  minUnits: number;
  maxUnits: number;
  sizeMM: number;
  gradePCT: number;
}

export interface DrainagePipeSizingProperties {
  availablePipeSizesMM: number[];
  horizontalPipeSizing: HorizontalPipeSizing[];
  stackPipeSizing: StackPipeSizing[];
}

export interface DrainageProperties extends DrainagePipeSizingProperties {
  ventColor: Color;

  stackSizeDiminish: boolean;
  stackDedicatedVent: boolean;
  maxUnventedLengthM: { [key: number]: number | undefined };
  maxUnventedCapacityWCs: { [key: number]: number | undefined };

  horizontalPipeSizing: HorizontalPipeSizing[];
  ventSizing: VentSizing[];
  stackPipeSizing: StackPipeSizing[];
  stackVentPipeSizing: VentSizing[];
}

export interface SewerFlowSystem extends FlowSystemBase, DrainageProperties {
  type: "sewer";

  networks: Record<SewerNetworkType, SewerNetworkParams> &
    Partial<Record<Exclude<NetworkType, SewerNetworkType>, undefined>>;
}

export interface StormwaterFlowSystem
  extends FlowSystemBase,
    DrainagePipeSizingProperties {
  type: "stormwater";

  networks: Record<StormwaterNetworkType, StormwaterNetworkParams> &
    Partial<Record<Exclude<NetworkType, StormwaterNetworkType>, undefined>>;
}
export interface UnderfloorHeatingFlowSystem
  extends FlowSystemBase,
    UnderfloorHeatingSettings,
    UnderfloorHeatingSettingsV3 {
  type: "underfloor";

  networks: Record<
    UnderfloorHeatingNetworkType,
    UnderfloorHeatingNetworkParams
  > &
    Partial<
      Record<Exclude<NetworkType, UnderfloorHeatingNetworkType>, undefined>
    >;
}

export interface MechanicalFlowSystem extends FlowSystemBase {
  type: "mechanical";

  minLoopPressureKPA: number;
  maxLoopPressureKPA: number;

  hasReturnSystem: true;
  return: {
    color: Color;
    insulated: boolean;
    maxVelocityMS: number;
    insulation: {
      material: InsulationMaterials;
      jacket: InsulationJackets;
      thicknessMM: number;
    };
  };

  networks: Record<MechanicalNetworkType, MechanicalNetworkParams> &
    Partial<Record<Exclude<NetworkType, MechanicalNetworkType>, undefined>>;
}

export interface FireFlowSystem extends FlowSystemBase {
  type: "fire";

  networks: Record<FireNetworkType, FireNetworkParams> &
    Partial<Record<Exclude<NetworkType, FireNetworkType>, undefined>>;
}

export interface VentilationFlowSystem extends FlowSystemBase {
  type: "ventilation";

  networks: Record<VentilationNetworkType, VentilationNetworkParams> &
    Partial<Record<Exclude<NetworkType, VentilationNetworkType>, undefined>>;
}

export type ExtendedFlowSystemType =
  | Exclude<FlowSystem["type"], "mechanical">
  | "heating"
  | "cooling";

export type FlowSystem =
  | PressureFlowSystem
  | GasFlowSystem
  | SewerFlowSystem
  | StormwaterFlowSystem
  | MechanicalFlowSystem
  | FireFlowSystem
  | VentilationFlowSystem
  | UnderfloorHeatingFlowSystem;

export const DEFAULT_HORIZONTAL_SYSTEM_NETWORKS: {
  [key in FlowSystemType]: FlowSystemNetworkKeyMap[key];
} = {
  pressure: "reticulations",
  gas: "reticulations",
  sewer: "pipes",
  stormwater: "pipes",
  mechanical: "reticulations",
  fire: "reticulations",
  ventilation: "mains",
  underfloor: "pipes",
};

export const DEFAULT_VERTICAL_SYSTEM_NETWORKS: {
  [key in FlowSystemType]: FlowSystemNetworkKeyMap[key];
} = {
  pressure: "risers",
  gas: "risers",
  sewer: "stacks",
  stormwater: "stacks",
  mechanical: "risers",
  fire: "risers",
  ventilation: "risers",
  underfloor: "pipes",
};

export const VERTICAL_SYSTEM_NETWORKS_BY_PRIORITY: {
  [key in FlowSystemType]: Array<FlowSystemNetworkKeyMap[key]>;
} = {
  pressure: ["risers"],
  gas: ["risers"],
  sewer: ["stacks"],
  stormwater: ["stacks"],
  mechanical: ["risers"],
  fire: ["risers"],
  ventilation: ["risers"],
  underfloor: ["pipes"],
};

export function getFirstHorizontalSystemNetwork(fs: FlowSystem): NetworkType {
  return HORIZONTAL_SYSTEM_NETWORKS_BY_PRIORITY[fs.type][0];
}

export function getLastHorizontalSystemNetwork(fs: FlowSystem): NetworkType {
  return HORIZONTAL_SYSTEM_NETWORKS_BY_PRIORITY[fs.type].at(-1)!;
}

export const HORIZONTAL_SYSTEM_NETWORKS_BY_PRIORITY: {
  [key in FlowSystemType]: Array<FlowSystemNetworkKeyMap[key]>;
} = {
  pressure: ["reticulations", "connections"],
  gas: ["reticulations", "connections"],
  sewer: ["pipes"],
  stormwater: ["pipes"],
  mechanical: ["reticulations"],
  fire: ["reticulations", "connections"],
  ventilation: ["mains", "branches", "connections"],
  underfloor: ["pipes"],
};

export const AUX_SYSTEM_NETWORKS: {
  [key in FlowSystemType]: Array<FlowSystemNetworkKeyMap[key]>;
} = {
  pressure: [],
  gas: [],
  sewer: ["vents"],
  stormwater: [],
  mechanical: [],
  fire: [],
  ventilation: [],
  underfloor: [],
};

export function getMaterialForFlowSystem(
  system: FlowSystem,
  networkType: NetworkType,
): PipePhysicalMaterial | DuctPhysicalMaterial | null {
  const networkParams = system.networks[networkType];
  if (networkParams) {
    return getMaterialForNetwork(system.type, networkParams);
  }

  return null;
}

export function getMaterialForNetwork(
  system: FlowSystemType,
  network: NetworkParams,
): PipePhysicalMaterial | DuctPhysicalMaterial | null {
  if (isNetworkTypeWithMaterial(system, network)) {
    return network.material;
  }
  return null;
}

export function getConduitMaterialOrDefault(
  flowSystem: FlowSystem | undefined,
  conduit: PipeConduit | DuctConduit,
): PipePhysicalMaterial | DuctPhysicalMaterial | null {
  if (conduit.material) {
    return conduit.material;
  }

  if (!flowSystem) {
    return null;
  }

  return getMaterialForFlowSystem(flowSystem, conduit.network);
}

// UFH doesnt have materials
export function isNetworkTypeWithMaterial(
  system: FlowSystemType,
  network: NetworkParams,
): network is { material: PipePhysicalMaterial | DuctPhysicalMaterial } {
  return system !== "underfloor";
}

export function flowSystemHasReturn(
  system: FlowSystem | undefined,
): system is PressureFlowSystem | MechanicalFlowSystem {
  if (!system) {
    return false;
  }
  switch (system.type) {
    case "mechanical":
    case "pressure":
      return system.hasReturnSystem;
    case "sewer":
    case "fire":
    case "gas":
    case "stormwater":
    case "ventilation":
    case "underfloor":
      return false;
    default:
      assertUnreachableAggressive(system);
  }
}

export function flowSystemHasRiser(
  system: FlowSystem | undefined,
): system is
  | PressureFlowSystem
  | MechanicalFlowSystem
  | FireFlowSystem
  | GasFlowSystem
  | VentilationFlowSystem {
  if (!system) {
    return false;
  }
  switch (system.type) {
    case "mechanical":
    case "pressure":
    case "fire":
    case "gas":
    case "ventilation":
      return true;
    case "sewer":
    case "stormwater":
    case "underfloor":
      return false;
    default:
      assertUnreachableAggressive(system);
  }
}

export function flowSystemNetworkHasVelocity(
  system: FlowSystem | undefined,
): system is
  | PressureFlowSystem
  | MechanicalFlowSystem
  | VentilationFlowSystem
  | GasFlowSystem
  | FireFlowSystem {
  if (!system) {
    return false;
  }
  switch (system.type) {
    case "mechanical":
    case "pressure":
    case "ventilation":
    case "gas":
    case "fire":
      return true;
    case "sewer":
    case "stormwater":
    case "underfloor":
      return false;
    default:
      assertUnreachableAggressive(system);
  }
}

export function flowSystemNetworkHasMaxPressureDrop(
  system: FlowSystem | undefined,
): system is PressureFlowSystem | MechanicalFlowSystem | VentilationFlowSystem {
  if (!system) {
    return false;
  }
  switch (system.type) {
    case "mechanical":
    case "pressure":
    case "gas":
    case "fire":
    case "ventilation":
      return true;
    case "sewer":
    case "stormwater":
    case "underfloor":
      return false;
    default:
      assertUnreachableAggressive(system);
  }
}

export function getFlowSystemMaxPressureDropKPAM(
  system: FlowSystem,
  network: NetworkType,
): number | undefined {
  if (flowSystemNetworkHasMaxPressureDrop(system)) {
    const networkParams = system.networks[network]!;
    if ("pressureDropKPAM" in networkParams) {
      return networkParams.pressureDropKPAM;
    }
  }
  return undefined;
}

export function flowSystemNetworkHasMinPipeSize(
  system: FlowSystem | undefined,
): system is
  | PressureFlowSystem
  | GasFlowSystem
  | MechanicalFlowSystem
  | FireFlowSystem {
  if (!system) {
    return false;
  }
  switch (system.type) {
    case "pressure":
    case "gas":
    case "mechanical":
    case "fire":
      return true;
    case "ventilation":
    case "sewer":
    case "stormwater":
    case "underfloor":
      return false;
    default:
      assertUnreachableAggressive(system);
  }
}

export function flowSystemNetworkHasExcludedPipeSize(
  system: FlowSystem | undefined,
): boolean {
  return flowSystemNetworkHasMinPipeSize(system);
}

export function getFlowSystemMinimumPipeSize(
  system: FlowSystem | undefined,
  entity: PipeConduitEntity | DuctConduitEntity,
): number {
  return system && flowSystemNetworkHasMinPipeSize(system)
    ? system.networks[entity.conduit.network]!.minimumPipeSize
    : 0;
}

export function flowSystemNetworkHasSpareCapacity(
  system: FlowSystem | undefined,
): system is
  | PressureFlowSystem
  | VentilationFlowSystem
  | FireFlowSystem
  | MechanicalFlowSystem {
  if (!system) {
    return false;
  }
  switch (system.type) {
    case "pressure":
    case "ventilation":
    case "fire":
    case "mechanical":
      return true;
    case "gas":
    case "sewer":
    case "stormwater":
    case "underfloor":
      return false;
    default:
      assertUnreachableAggressive(system);
  }
}

export function flowSystemHasVent(
  system: FlowSystem,
): system is SewerFlowSystem {
  return isSewer(system);
}

export function getSimilarNetwork<T extends FlowSystemType>(
  network: NetworkType,
  targetSystemType: T,
): FlowSystemNetworkKeyMap[T] {
  switch (targetSystemType) {
    case "pressure":
    case "fire":
    case "gas":
      switch (network) {
        case "branches":
        case "connections":
        case "vents":
          return "connections" as FlowSystemNetworkKeyMap[T];
        case "mains":
        case "pipes":
        case "reticulations":
          return "reticulations" as FlowSystemNetworkKeyMap[T];
        case "risers":
        case "stacks":
          return "risers" as FlowSystemNetworkKeyMap[T];
        default:
          assertUnreachable(network);
      }
    case "mechanical":
      switch (network) {
        case "branches":
        case "connections":
        case "vents":
        case "mains":
        case "pipes":
        case "reticulations":
          return "reticulations" as FlowSystemNetworkKeyMap[T];
        case "risers":
        case "stacks":
          return "risers" as FlowSystemNetworkKeyMap[T];
        default:
          assertUnreachable(network);
      }
    case "sewer":
      switch (network) {
        case "branches":
        case "connections":
        case "mains":
        case "pipes":
        case "reticulations":
          return "pipes" as FlowSystemNetworkKeyMap[T];
        case "risers":
        case "stacks":
          return "stacks" as FlowSystemNetworkKeyMap[T];
        case "vents":
          return "vents" as FlowSystemNetworkKeyMap[T];
        default:
          assertUnreachable(network);
      }
    case "stormwater":
      switch (network) {
        case "branches":
        case "connections":
        case "mains":
        case "pipes":
        case "reticulations":
        case "vents":
          return "pipes" as FlowSystemNetworkKeyMap[T];
        case "risers":
        case "stacks":
          return "stacks" as FlowSystemNetworkKeyMap[T];
        default:
          assertUnreachable(network);
      }
    case "ventilation":
      switch (network) {
        case "connections":
        case "vents":
          return "connections" as FlowSystemNetworkKeyMap[T];

        case "branches":
        case "reticulations":
        case "pipes":
          return "branches" as FlowSystemNetworkKeyMap[T];
        case "mains":
          return "mains" as FlowSystemNetworkKeyMap[T];
        case "risers":
        case "stacks":
          return "risers" as FlowSystemNetworkKeyMap[T];
        default:
          assertUnreachable(network);
      }
  }
  return DEFAULT_HORIZONTAL_SYSTEM_NETWORKS[targetSystemType];
}
