import { assertUnreachable, cloneSimple } from "../../../lib/utils";
import { CoreContext } from "../../calculations/types";
import { CoreObjectConcrete } from "../../coreObjects";
import CoreAreaSegment from "../../coreObjects/coreAreaSegment";
import CoreRoom from "../../coreObjects/coreRoom";
import { PropertyField, withPropertyTracking } from "./property-field";
import {
  RoomRoomEntity,
  RoomType,
  UFHLoopDesignParameters,
  fillDefaultRoomFields,
  getRoomUfhMode,
} from "./rooms/room-entity";
import {
  getUFHLoopDesignFlowSystem,
  getUFHLoopDesignParameterDefaults,
  makeUFHFields,
} from "./shared-fields/ufh-fields";
import {
  CenteredEntity,
  DrawableEntity,
  NamedEntity,
  PolygonEntity,
} from "./simple-entities";
import { EntityType } from "./types";

export const HEATING_AREAS: Set<AreaSegmentType> = new Set([
  "heated-area",
  "unheated-area",
]);

export type AreaSegmentType = "unheated-area" | "heated-area";

export interface AreaSegmentEntityBase
  extends DrawableEntity,
    NamedEntity,
    PolygonEntity,
    CenteredEntity {
  type: EntityType.AREA_SEGMENT;
  areaType: AreaSegmentType;
}

export interface UnheatedAreaSegmentEntity extends AreaSegmentEntityBase {
  areaType: "unheated-area";
}

export interface HeatedAreaSegmentEntity extends AreaSegmentEntityBase {
  areaType: "heated-area";
  underfloorHeating: UFHLoopDesignParameters;
  roomUid: string | null;
}

export type AreaSegmentEntity =
  | UnheatedAreaSegmentEntity
  | HeatedAreaSegmentEntity;

export default AreaSegmentEntity;

export function getDrawableRoom(context: CoreContext, networkRoomUid: string) {
  // In drawable land we have to retrieve the properties
  // ourselves without the network object room the uid is referring to.
  for (const obj of context.globalStore.entitiesOfType<CoreRoom>(
    EntityType.ROOM,
  )) {
    if (obj.getCalculationUid(context) === networkRoomUid) {
      return obj;
    }
  }
  return null;
}

export function makeAreaSegmentFields(
  context: CoreContext,
  entity: AreaSegmentEntity,
): PropertyField[] {
  const res: PropertyField[] = [];

  const liveCalcs = context.globalStore.getOrCreateLiveCalculation(entity);

  if (entity.areaType === "heated-area") {
    const parentRoomUid = liveCalcs?.roomUid;

    const parentRoom = getDrawableRoom(context, parentRoomUid!);
    const filledRoom =
      parentRoom?.entity.room.roomType === RoomType.ROOM
        ? (fillDefaultRoomFields(context, parentRoom.entity) as RoomRoomEntity)
        : null;

    const filled = fillDefaultAreaSegmentFields(context, entity, filledRoom);

    res.push(
      ...makeUFHFields(
        filledRoom ? getRoomUfhMode(context, filledRoom) : null,
        context.globalStore.levelOfEntity.get(entity.uid) ?? null,
        getUFHLoopDesignFlowSystem(
          context,
          filledRoom?.room.underfloorHeating.manifoldUid,
        ),
        context,
        {
          floorFinish: filledRoom?.room.underfloorHeating.floorFinish ?? null,
          pipeMaterial: filledRoom?.room.underfloorHeating.pipeMaterial ?? null,
          loopShape: filled.underfloorHeating.loopShape ?? "serpentine",
          coils: liveCalcs.loopsStats.length ?? 1,
        },
        "underfloorHeating",
        "heated-area",
        filled,
      ),
    );
  }

  return res.map(withPropertyTracking(context, entity));
}

export function fillDefaultAreaSegmentFields<T extends AreaSegmentEntity>(
  context: CoreContext,
  entity: T,
  // optionally provide an already filled room to avoid recalculating it,
  // or even to fill with a different room's defaults.
  filledRoom?: RoomRoomEntity | null,
): T {
  const filled = cloneSimple(entity);

  const calcs = context.globalStore.getOrCreateCalculation(entity);

  switch (filled.areaType) {
    case "heated-area": {
      const parentRoomUid = calcs?.roomUid;

      const parentRoom = parentRoomUid
        ? (context.globalStore.getObjectOfType(EntityType.ROOM, parentRoomUid)
            ?.entity ?? null)
        : null;

      const _filledRoom =
        filledRoom ??
        (parentRoom?.room.roomType === RoomType.ROOM
          ? (fillDefaultRoomFields(context, parentRoom) as RoomRoomEntity)
          : null);

      const liveCalc =
        context.globalStore.getOrCreateLiveCalculation<HeatedAreaSegmentEntity>(
          entity as HeatedAreaSegmentEntity,
        );

      filled.underfloorHeating = getUFHLoopDesignParameterDefaults(
        context,
        filled.underfloorHeating,
        liveCalc.loopsStats,
        _filledRoom?.room.underfloorHeating,
        liveCalc.loopSpacingMM,
      );

      break;
    }
    case "unheated-area":
      break;
    default:
      assertUnreachable(filled);
  }

  return filled;
}

export function isAreaSegmentEntity(
  entity: DrawableEntity,
): entity is AreaSegmentEntity {
  return entity.type === EntityType.AREA_SEGMENT;
}

export function isHeatedAreaSegmentEntity(
  entity: DrawableEntity,
): entity is HeatedAreaSegmentEntity {
  return isAreaSegmentEntity(entity) && entity.areaType === "heated-area";
}

export function isCoreAreaSegment(
  entity: CoreObjectConcrete,
): entity is CoreAreaSegment {
  return entity.type === EntityType.AREA_SEGMENT;
}
