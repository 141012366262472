import { Units } from "../../../lib/measurements";
import {
  CalculationField,
  FieldCategory,
  nestArrayCalculationFields,
} from "./calculation-field";

import Flatten from "@flatten-js/core";
import { Coord } from "../../../lib/coord";
import { CoilCoord3D } from "../../calculations/underfloor-heating/coil-coords";

export interface UnderfloorHeatingLoopCalculation {
  loopId: string;
  roomUid: string;
  areaUid: string | null;
  lengthM: number | null;
  maxLoopLengthM: number | null;
  pipeDiameterMM: number;
  pipeMaterial: string | null;
  coilManufacturer: string | null;
  genericCoilRollLengthsM: number[];
  internalPipeDiameterMM: number | null;
  colebrookWhiteCoefficient: number | null;
  heatingAreaM2: number;
  lvlUid: string;
  roomName: string;

  color: string | null;

  heatOutputW: number | null;
  manifoldUid: string | null; // null indicates no manifold - approximate loop only.

  flowRateLS: number | null;
  pressureDropKPA: number | null;
  volumeL: number | null;

  index: number;

  manifoldIndex: number | null;
  manifoldName: string | null;

  hasActuator: boolean;

  // if null for center or normal, it means the loop
  // entry is not set and we shouldn't be generating it,
  // just use phase 2 functionality.
  normal: Flatten.Vector | null;
  center: Coord | null;

  fromManifold: CoilCoord3D[];
  roomLoop: CoilCoord3D[];
  toManifold: CoilCoord3D[];
}

export interface LoopSiteCalculation {
  // 0-1 length array for now as we initially only support one loop per area. The room's other loop stats
  // will be on the heated-area entities themselves. In the future it could be a 2 or more length
  // array representing all the loops in this particular section. For rooms, it is only loops included in
  // the room and not other heated areas.
  loopsStats: UnderfloorHeatingLoopCalculation[];
}

export function getLoopStatFields<
  T extends LoopSiteCalculation,
>(): CalculationField<T>[] {
  return nestArrayCalculationFields<
    LoopSiteCalculation,
    UnderfloorHeatingLoopCalculation
  >("loopsStats", 0, [
    {
      property: "loopId",
      title: "Loop Id",
      short: "",
      units: Units.None,
      category: FieldCategory.EntityName,
      layouts: ["mechanical"],
      defaultEnabled: false,
    },
    {
      property: "pressureDropKPA",
      title: "Loop Pressure Drop",
      short: "",
      units: Units.KiloPascals,
      category: FieldCategory.Pressure,
      layouts: ["mechanical"],
      defaultEnabled: false,
    },
    {
      property: "flowRateLS",
      title: "Loop Flow Rate",
      short: "",
      units: Units.LitersPerSecond,
      category: FieldCategory.FlowRate,
      layouts: ["mechanical"],
      defaultEnabled: false,
    },
    {
      property: "lengthM",
      title: "Loop Length",
      shortTitle: "Loop Length",
      short: "",
      units: Units.Meters,
      category: FieldCategory.Length,
      layouts: ["mechanical"],
      defaultEnabled: false,
    },
    {
      property: "volumeL",
      title: "Loop Volume",
      short: "",
      units: Units.Liters,
      category: FieldCategory.Volume,
      layouts: ["mechanical"],
      defaultEnabled: false,
    },
  ]);
}
