import { angleDiffRad } from "../../../lib/mathUtils/mathutils";
import {
  EPS,
  assertType,
  assertUnreachable,
  ceilModulo,
  closestNumber,
  floorModulo,
  parseCatalogNumberExact,
} from "../../../lib/utils";
import CoreConduit from "../../coreObjects/coreConduit";
import { getDuctFittingPrimitives } from "../../coreObjects/lib/ductFittingPrimitives";
import { DuctCalculation } from "../../document/calculations-objects/conduit-calculations";
import { getFlowSystemLayouts } from "../../document/calculations-objects/utils";
import { addWarning } from "../../document/calculations-objects/warnings";
import {
  isPressureSizingEnabled,
  isVelocitySizingEnabled,
} from "../../document/drawing";
import {
  DuctConduitEntity,
  fillDefaultConduitFields,
} from "../../document/entities/conduit-entity";
import { EntityType } from "../../document/entities/types";
import {
  DuctFlowSystemTypes,
  flowSystemNetworkHasSpareCapacity,
} from "../../document/flow-systems";
import { getFlowSystem } from "../../document/utils";
import CalculationEngine from "../calculation-engine";
import { TraceCalculation } from "../flight-data-recorder";
import { binarySearch } from "../search-functions";
import { CoreContext } from "../types";

export class DuctCalculations {
  @TraceCalculation("Sizing duct", (_, duct, __) => [duct.uid])
  static setDuctSizeForFlowRate(
    context: CalculationEngine,
    duct: DuctConduitEntity,
    flowRateLS: number,
  ) {
    const cduct = fillDefaultConduitFields(context, duct);

    // apply spare capacity
    const system = getFlowSystem<DuctFlowSystemTypes>(
      context.drawing,
      cduct.systemUid!,
    );
    const spareCapacityPCT =
      system && flowSystemNetworkHasSpareCapacity(system)
        ? system.networks[duct.conduit.network]!.spareCapacityPCT
        : 0;

    const calc = context.globalStore.getOrCreateCalculation(duct);
    flowRateLS = this.fillDuctFlowRateCalcResult(
      calc,
      spareCapacityPCT,
      flowRateLS,
    );

    this.sizeDuctForFlowRate(context, cduct, [
      [flowRateLS, cduct.conduit.maximumVelocityMS!],
    ]);
  }

  static sizeDuctForFlowRate(
    context: CalculationEngine,
    filled: DuctConduitEntity,
    requirements: Array<[number | null, number]>,
  ) {
    let areaReqMM2 = Infinity;
    let highestFlowRateLS: number | null = null;
    for (const [flowRateLS, velocityMS] of requirements) {
      if (flowRateLS === null) continue;
      highestFlowRateLS = Math.max(highestFlowRateLS ?? -Infinity, flowRateLS);
      const areaMM2 = (flowRateLS / velocityMS) * 1000;
      areaReqMM2 = Math.min(areaReqMM2, areaMM2);
    }

    const calc = context.globalStore.getOrCreateCalculation(filled);
    const orig = context.globalStore.getObjectOfType(
      EntityType.CONDUIT,
      filled.uid,
    )!;
    assertType<"duct">(orig.entity.conduitType);
    const incrementMM = filled.conduit.sizingIncrementMM!;

    const isIncrement = filled.conduit.sizingMode === "increment";

    const system = getFlowSystem<"ventilation">(
      context.drawing,
      filled.systemUid!,
    )!;
    const sizeVelocity = isVelocitySizingEnabled(context.drawing, system);
    const sizePressure = isPressureSizingEnabled(context.drawing, system);
    const maxPDKPAM = filled.conduit.maximumPressureDropRateKPAM!;

    const {
      minimumDuctDiameterSizeMM,
      minimumDuctHeightSizeMM,
      minimumDuctWidthSizeMM,
    } = system.networks[filled.conduit.network]!;

    const ceilModuloIf = (value: number): number => {
      if (isIncrement) {
        return ceilModulo(value - EPS, incrementMM);
      } else {
        return value;
      }
    };

    const floorModuloIf = (value: number): number => {
      if (isIncrement) {
        return floorModulo(value + EPS, incrementMM);
      } else {
        return value;
      }
    };

    const sizeToPDKPAM = (
      param: "widthMM" | "heightMM" | "diameterMM",
      options?: {
        diameterMM?: number | ((mid: number) => number);
        widthMM?: number | ((mid: number) => number);
        heightMM?: number | ((mid: number) => number);
      },
    ) => {
      const callOrVal = (
        x: number | ((mid: number) => number) | undefined,
        mid: number,
      ) => (typeof x === "function" ? x(mid) : x);
      return binarySearch(
        (mid) => {
          const args = {
            diameterMM: callOrVal(options?.diameterMM, mid),
            widthMM: callOrVal(options?.widthMM, mid),
            heightMM: callOrVal(options?.heightMM, mid),
            [param]: mid,
          };

          return (
            CoreConduit.getDuctPressureLossKPA(
              context,
              filled,
              highestFlowRateLS!,
              1,
              args,
            ).pressureLossKPA < maxPDKPAM
          );
        },
        { low: 0 },
      ).value;
    };

    switch (filled.conduit.shape) {
      case "circular":
        let optimalDiameterMM = orig.entity.conduit.diameterMM;
        if (optimalDiameterMM == null) {
          optimalDiameterMM = minimumDuctDiameterSizeMM;
          if (sizeVelocity) {
            optimalDiameterMM = Math.max(
              optimalDiameterMM,
              Math.sqrt(areaReqMM2 / Math.PI) * 2,
            );
          }
          if (sizePressure) {
            optimalDiameterMM = Math.max(
              optimalDiameterMM,
              sizeToPDKPAM("diameterMM"),
            );
          }
        }
        switch (filled.conduit.sizingMode) {
          case "continuous":
          case "increment": {
            calc.diameterMM = ceilModuloIf(optimalDiameterMM);
            break;
          }
          case "discrete": {
            const page = CoreConduit.getDuctManufacturerCatalogPage(
              context,
              filled,
            );
            if (page) {
              const availableSizes = Object.values(page.circularByDiameter).map(
                (x) => x.internalDiameterMM,
              );
              availableSizes.sort((a, b) => a - b);
              for (const size of availableSizes) {
                if (size >= optimalDiameterMM - EPS) {
                  calc.diameterMM = size;
                  break;
                }
              }
              // when we don't have a large enough duct
              if (calc.diameterMM === null) {
                calc.diameterMM = availableSizes[availableSizes.length - 1];
                addWarning(context, "DUCT_SIZE_NOT_FOUND", [filled], {
                  mode: "ventilation",
                });
              }
            }
            break;
          }
          case null:
            break;
          default:
            assertUnreachable(filled.conduit.sizingMode);
        }
        if (calc.diameterMM != null) {
          calc.crossSectionAreaM2 = Math.PI * (calc.diameterMM / 1000 / 2) ** 2;
        }
        break;
      case "rectangular":
        switch (filled.conduit.rectSizingMethod) {
          case "fixed-dimensions": {
            switch (filled.conduit.sizingMode) {
              case "continuous":
              case "increment": {
                if (
                  orig.entity.conduit.widthMM != null &&
                  orig.entity.conduit.heightMM != null
                ) {
                  calc.widthMM = orig.entity.conduit.widthMM;
                  calc.heightMM = orig.entity.conduit.heightMM;
                } else if (orig.entity.conduit.widthMM != null) {
                  calc.widthMM = orig.entity.conduit.widthMM;
                  calc.heightMM = minimumDuctHeightSizeMM;
                  if (sizeVelocity) {
                    calc.heightMM = Math.max(
                      calc.heightM!,
                      ceilModuloIf(areaReqMM2 / calc.widthMM),
                    );
                  }
                  if (sizePressure) {
                    calc.heightMM = Math.max(
                      calc.heightMM,
                      ceilModuloIf(
                        sizeToPDKPAM("heightMM", { widthMM: calc.widthMM }),
                      ),
                    );
                  }
                } else if (orig.entity.conduit.heightMM != null) {
                  calc.heightMM = orig.entity.conduit.heightMM;
                  calc.widthMM = minimumDuctWidthSizeMM;
                  if (sizeVelocity) {
                    calc.widthMM = ceilModuloIf(areaReqMM2 / calc.heightMM);
                  }
                  if (sizePressure) {
                    calc.widthMM = Math.max(
                      calc.widthMM,
                      ceilModuloIf(
                        sizeToPDKPAM("widthMM", { heightMM: calc.heightMM }),
                      ),
                    );
                  }
                } else {
                  const ratio = filled.conduit.targetWHRatio!;
                  calc.widthMM = minimumDuctWidthSizeMM;
                  calc.heightMM = minimumDuctHeightSizeMM;
                  if (sizeVelocity) {
                    const idealWidth = ceilModuloIf(
                      Math.sqrt(areaReqMM2 * ratio),
                    );
                    const idealHeight = ceilModuloIf(idealWidth / ratio);
                    calc.widthMM = Math.max(calc.widthMM, idealWidth);
                    calc.heightMM = Math.max(calc.heightMM, idealHeight);
                  }

                  if (sizePressure) {
                    const searchWidth = sizeToPDKPAM("widthMM", {
                      heightMM: (mid) => mid / ratio,
                    });
                    const searchHeight = searchWidth / ratio;
                    const idealWidth = ceilModuloIf(searchWidth);
                    const idealHeight = ceilModuloIf(searchHeight);
                    calc.widthMM = Math.max(calc.widthMM, idealWidth);
                    calc.heightMM = Math.max(calc.heightMM, idealHeight);
                  }
                }
                break;
              }
              case "discrete": {
                const page = CoreConduit.getDuctManufacturerCatalogPage(
                  context,
                  filled,
                );

                if (page) {
                  const availableHeights = Object.keys(
                    page.rectangularByHeightWidth,
                  )
                    .map(parseCatalogNumberExact)
                    .map(Number);
                  if (availableHeights.length === 0) break;
                  const bestHeight =
                    orig.entity.conduit.heightMM != null
                      ? closestNumber(
                          availableHeights,
                          orig.entity.conduit.heightMM,
                          minimumDuctHeightSizeMM,
                        )
                      : null;

                  const availableWidths =
                    bestHeight != null
                      ? Object.keys(page.rectangularByHeightWidth[bestHeight!])
                          .map(parseCatalogNumberExact)
                          .map(Number)
                      : [];

                  if (
                    orig.entity.conduit.widthMM != null &&
                    orig.entity.conduit.heightMM != null
                  ) {
                    const bestWidth = closestNumber(
                      availableWidths,
                      orig.entity.conduit.widthMM,
                      minimumDuctWidthSizeMM,
                    );
                    calc.widthMM = bestWidth!;
                    calc.heightMM = bestHeight!;
                  } else if (orig.entity.conduit.heightMM != null) {
                    let targetWidthMM = minimumDuctWidthSizeMM;
                    if (sizeVelocity) {
                      targetWidthMM = Math.max(
                        targetWidthMM,
                        areaReqMM2 / bestHeight!,
                      );
                    }
                    if (sizePressure) {
                      targetWidthMM = Math.max(
                        targetWidthMM,
                        sizeToPDKPAM("widthMM", { heightMM: bestHeight! }),
                      );
                    }
                    const bestWidth = closestNumber(
                      availableWidths,
                      targetWidthMM,
                      targetWidthMM,
                    );
                    calc.widthMM = bestWidth!;
                    calc.heightMM = bestHeight!;
                  } else if (orig.entity.conduit.widthMM != null) {
                    let targetHeightMM = minimumDuctHeightSizeMM;
                    if (sizeVelocity) {
                      targetHeightMM = Math.max(
                        targetHeightMM,
                        areaReqMM2 / orig.entity.conduit.widthMM,
                      );
                    }
                    if (sizePressure) {
                      targetHeightMM = Math.max(
                        targetHeightMM,
                        sizeToPDKPAM("heightMM", {
                          widthMM: orig.entity.conduit.widthMM,
                        }),
                      );
                    }
                    // TODO: guarantee the requirements are met.
                    // This is tricky because we don't know what width
                    // is available before choosing the height, but the
                    // the height gets fixed first, and now we have to
                    // choose the width override which could round down in
                    // closestNumber and undershoot.
                    const bestHeight = closestNumber(
                      availableHeights,
                      targetHeightMM,
                      minimumDuctHeightSizeMM,
                    );
                    const availableWidths = Object.keys(
                      page.rectangularByHeightWidth[bestHeight!],
                    )
                      .map(parseCatalogNumberExact)
                      .map(Number);
                    const bestWidth = closestNumber(
                      availableWidths,
                      orig.entity.conduit.widthMM,
                      minimumDuctWidthSizeMM,
                    );
                    calc.widthMM = bestWidth!;
                    calc.heightMM = bestHeight!;
                  } else {
                    // TODO: size to nearest available size
                    let targetHeightMM = minimumDuctHeightSizeMM;
                    if (sizeVelocity) {
                      targetHeightMM = Math.max(
                        targetHeightMM,
                        Math.sqrt(areaReqMM2 / filled.conduit.targetWHRatio!),
                      );
                    }
                    if (sizePressure) {
                      targetHeightMM = Math.max(
                        targetHeightMM,
                        sizeToPDKPAM("heightMM", {
                          widthMM: (mid) => mid * filled.conduit.targetWHRatio!,
                        }),
                      );
                    }
                    const bestHeight = closestNumber(
                      availableHeights,
                      targetHeightMM,
                    );
                    let targetWidthMM = minimumDuctWidthSizeMM;
                    if (sizeVelocity) {
                      targetWidthMM = Math.max(
                        targetWidthMM,
                        areaReqMM2 / bestHeight!,
                      );
                    }
                    if (sizePressure) {
                      targetWidthMM = Math.max(
                        targetWidthMM,
                        sizeToPDKPAM("widthMM", { heightMM: bestHeight! }),
                      );
                    }
                    const availableWidths = Object.keys(
                      page.rectangularByHeightWidth[bestHeight!],
                    )
                      .map(parseCatalogNumberExact)
                      .map(Number);
                    const bestWidth = closestNumber(
                      availableWidths,
                      targetWidthMM,
                      targetHeightMM,
                    );
                    calc.widthMM = bestWidth!;
                    calc.heightMM = bestHeight!;
                  }
                }
                break;
              }
            }
            break;
          }
          case "max-dimensions": {
            switch (filled.conduit.sizingMode) {
              case "continuous":
              case "increment": {
                const ratio = filled.conduit.targetWHRatio!;

                calc.widthMM = minimumDuctWidthSizeMM;
                calc.heightMM = minimumDuctHeightSizeMM;

                if (sizeVelocity) {
                  const widthMM = ceilModuloIf(Math.sqrt(areaReqMM2 * ratio));
                  const heightMM = ceilModuloIf(widthMM / ratio);
                  calc.widthMM = Math.max(calc.widthMM, widthMM);
                  calc.heightMM = Math.max(calc.heightMM, heightMM);
                }
                if (sizePressure) {
                  const widthMM = ceilModuloIf(
                    sizeToPDKPAM("widthMM", { heightMM: (mid) => mid / ratio }),
                  );
                  const heightMM = ceilModuloIf(
                    sizeToPDKPAM("heightMM", { widthMM }),
                  );
                  calc.widthMM = Math.max(calc.widthMM, widthMM);
                  calc.heightMM = Math.max(calc.heightMM, heightMM);
                }

                if (
                  filled.conduit.maxWidthMM != null &&
                  calc.widthMM > filled.conduit.maxWidthMM!
                ) {
                  calc.widthMM = floorModuloIf(filled.conduit.maxWidthMM!);
                  if (sizeVelocity) {
                    calc.heightMM = Math.max(
                      calc.heightMM,
                      ceilModuloIf(areaReqMM2 / calc.widthMM),
                    );
                  }
                  if (sizePressure) {
                    calc.heightMM = Math.max(
                      calc.heightMM,
                      ceilModuloIf(
                        sizeToPDKPAM("heightMM", { widthMM: calc.widthMM }),
                      ),
                    );
                  }
                }
                if (
                  filled.conduit.maxHeightMM != null &&
                  calc.heightMM > filled.conduit.maxHeightMM!
                ) {
                  calc.heightMM = floorModuloIf(filled.conduit.maxHeightMM!);
                  if (sizeVelocity) {
                    calc.widthMM = Math.max(
                      calc.widthMM,
                      ceilModuloIf(areaReqMM2 / calc.heightMM),
                    );
                  }
                  if (sizePressure) {
                    calc.widthMM = Math.max(
                      calc.widthMM,
                      ceilModuloIf(
                        sizeToPDKPAM("widthMM", { heightMM: calc.heightMM }),
                      ),
                    );
                  }
                }
                if (
                  filled.conduit.maxWidthMM != null &&
                  calc.widthMM > filled.conduit.maxWidthMM + EPS
                ) {
                  // could only have happened when max height was also exceeded
                  calc.widthMM = floorModuloIf(filled.conduit.maxWidthMM!);
                  // So now we take the max of both bounds and the velocity
                  // is going to be exceeded.
                }

                break;
              }
              case "discrete": {
                // TODO: yikes to actually respect the max dimensions in the discrete case
                const page = CoreConduit.getDuctManufacturerCatalogPage(
                  context,
                  filled,
                );

                if (page) {
                  const availableHeights = Object.keys(
                    page.rectangularByHeightWidth,
                  )
                    .map(parseCatalogNumberExact)
                    .map(Number);

                  const ratio = filled.conduit.targetWHRatio!;
                  let targetHeightMM = minimumDuctHeightSizeMM;
                  if (sizeVelocity) {
                    targetHeightMM = Math.max(
                      targetHeightMM,
                      Math.sqrt(areaReqMM2 / ratio),
                    );
                  }
                  if (sizePressure) {
                    targetHeightMM = Math.max(
                      targetHeightMM,
                      sizeToPDKPAM("heightMM", {
                        widthMM: (mid) => mid * ratio,
                      }),
                    );
                  }
                  const bestHeight = closestNumber(
                    availableHeights,
                    targetHeightMM,
                    minimumDuctHeightSizeMM,
                  );

                  let targetWidthMM = minimumDuctWidthSizeMM;
                  if (sizeVelocity) {
                    targetWidthMM = Math.max(
                      targetWidthMM,
                      areaReqMM2 / bestHeight!,
                    );
                  }

                  if (sizePressure) {
                    targetWidthMM = Math.max(
                      targetWidthMM,
                      sizeToPDKPAM("widthMM", { heightMM: bestHeight! }),
                    );
                  }

                  const availableWidths = Object.keys(
                    page.rectangularByHeightWidth[bestHeight!],
                  )
                    .map(parseCatalogNumberExact)
                    .map(Number);

                  const bestWidth = closestNumber(
                    availableWidths,
                    targetWidthMM,
                    targetWidthMM,
                  );

                  calc.widthMM = bestWidth!;
                  calc.heightMM = bestHeight!;
                }
                break;
              }
            }
            break;
          }
          default:
            assertUnreachable(filled.conduit.rectSizingMethod);
        }
        if (calc.widthMM != null && calc.heightMM != null) {
          calc.crossSectionAreaM2 =
            (calc.widthMM / 1000) * (calc.heightMM / 1000);
        }
        break;
      case null:
        break;
      default:
        assertUnreachable(filled.conduit.shape);
    }
    if (highestFlowRateLS != null && calc.crossSectionAreaM2 != null) {
      const frM2S = highestFlowRateLS / 1000;
      calc.velocityRealMS = frM2S / calc.crossSectionAreaM2;
    }
  }

  static fillDuctFlowRateCalcResult(
    calc: DuctCalculation,
    spareCapacityPCT: number,
    flowRateLS: number,
  ): number {
    calc.rawFlowRateLS = flowRateLS;

    flowRateLS = flowRateLS * (1 + Number(spareCapacityPCT) / 100);

    calc.totalPeakFlowRateLS = flowRateLS;
    return flowRateLS;
  }

  static calculateDuctFittingPrimitives(context: CalculationEngine) {
    for (const o of context.networkObjects()) {
      if (o.type === EntityType.FITTING && o.entity.fittingType === "duct") {
        const calc = context.globalStore.getOrCreateCalculation(o.entity);
        const p = getDuctFittingPrimitives(context, o);

        if (p.success === true) {
          calc.physicalDuctPrimitives = p.physical;
          calc.pressureLossDuctPrimitives = p.pressureLoss;
        } else {
          if (p.fatal) {
            console.warn("Fitting not recognized", o.entity.uid, p.reason);
            addWarning(context, "FITTING_NOT_RECOGNIZED", [o.entity], {
              params: {
                reasons: [p.reason],
              },
              mode: getFlowSystemLayouts(
                context.drawing.metadata.flowSystems[o.entity.systemUid!],
              ).layouts,
              replaceSameWarnings: true,
            });
          }
        }
      }
    }
  }
}

export function bestVerticalDuctAxialAngleRAD(
  context: CoreContext,
  anglesRAD: number[],
) {
  const candidates = anglesRAD
    .map((a) => [a, 1])
    .concat([
      [0, 2],
      [Math.PI / 2, 2],
      [Math.PI, 2],
      [(3 * Math.PI) / 2, 2],
      [Math.PI / 4, 1.5],
      [(3 * Math.PI) / 4, 1.5],
      [(5 * Math.PI) / 4, 1.5],
      [(7 * Math.PI) / 4, 1.5],
    ]);

  // find the angle that fits as many in +/- 5 degrees as possible.
  // Penalty for distance.

  let bestAngle = anglesRAD[0];
  let bestScore = 0;
  const fiveDegInRad = (5 * Math.PI) / 180;

  for (const [angle, bias] of candidates) {
    let score = 0;
    for (const angle2 of anglesRAD) {
      const diff = Math.min(
        Math.abs(angleDiffRad(angle, angle2)),
        Math.abs(angleDiffRad(angle2, angle + Math.PI)),
      );
      score += Math.max(0, fiveDegInRad - diff) * bias;
    }
    if (score > bestScore) {
      bestScore = score;
      bestAngle = angle;
    }
  }

  return bestAngle;
}
