import { AIR_CHANGE_RATE_DATA } from "../../../../common/src/api/catalog/heatload/air-change-rate";
import { CHIMNEY_DATA } from "../../../../common/src/api/catalog/heatload/chimney";
import { HEATLOAD_MATERIAL_DATA } from "../../../../common/src/api/catalog/heatload/material";
import { ROOM_TEMPERATURE_DATA } from "../../../../common/src/api/catalog/heatload/room-temperature";
import { Catalog } from "../../../../common/src/api/catalog/types";
import { EN12056FrequencyFactor } from "../../../../common/src/api/config";

export default interface CatalogState {
  defaultCatalog: Catalog;
  upgradeCount: number;
  loaded: boolean;
}

export const defaultCatalog: Catalog = {
  valves: {},
  fixtures: {},
  pipes: {},
  mixingValves: {},
  psdStandards: {},
  en12056FrequencyFactor: {
    [EN12056FrequencyFactor.IntermittentUse]: 0.5,
    [EN12056FrequencyFactor.FrequentUse]: 0.7,
    [EN12056FrequencyFactor.CongestedUse]: 1.0,
    [EN12056FrequencyFactor.SpecialUse]: 1.2,
  },
  dwellingStandards: {},
  gasDiversification: {},
  fluids: {},
  backflowValves: {},
  prv: {
    manufacturer: [],
    size: {},
  },
  balancingValves: {
    manufacturer: [],
  },
  hotWaterPlant: {
    manufacturer: [],
    rheemVariants: [],
    grundfosPressureDrop: {},
    size: {},
    storageTanks: {},
  },
  floorWaste: {
    manufacturer: [],
    size: {},
  },
  inspectionOpening: {
    manufacturer: [],
    size: {},
  },
  pump: {
    manufacturer: [],
    datasheet: {},
  },
  pumpTank: {
    manufacturer: [],
    datasheet: {},
  },
  heatLoad: {
    material: HEATLOAD_MATERIAL_DATA,
    airChangeRate: AIR_CHANGE_RATE_DATA,
    roomTemperatureC: ROOM_TEMPERATURE_DATA,
    chimneySpec: CHIMNEY_DATA,
  },
  heatEmitters: {
    radiators: {
      name: "Radiators",
      uid: "radiators",
      type: "radiator-spec-table",
      manufacturer: [],
      datasheet: {},
    },
    ufh: {
      name: "Underfloor Heating",
      uid: "ufh",
      type: "ufh-spec-table",
      manufacturer: [],
      datasheet: {},
    },
  },
  underfloorHeating: {
    manifold: {
      type: "ufh-manifold",
      name: "Underfloor Heating",
      uid: "ufh-manifold",
      manufacturer: [],
      datasheet: {},
    },
    actuator: {
      type: "ufh-actuator",
      name: "Underfloor Heating Actuator",
      uid: "ufh-actuator",
      manufacturer: [],
      datasheet: {},
    },
    blendingValve: {
      type: "ufh-blending-valve",
      name: "Underfloor Heating Blending Valve",
      uid: "ufh-blending-valve",
      manufacturer: [],
      datasheet: {},
    },
    pump: {
      type: "ufh-pump",
      name: "Underfloor Heating Pump",
      uid: "ufh-pump",
      manufacturer: [],
      datasheet: {},
    },
    pumpPack: {
      type: "ufh-pump-pack",
      name: "Underfloor Heating Pump Pack",
      uid: "ufh-pump-pack",
      manufacturer: [],
      datasheet: {},
    },
    ballValve: {
      type: "ufh-ball-valve",
      name: "Underfloor Heating Ball Valve",
      uid: "ufh-ball-valve",
      manufacturer: [],
      datasheet: {},
    },
    edgeExpansionFoam: {
      type: "ufh-edge-expansion-foam",
      name: "Underfloor Edge Expansion Foam",
      uid: "ufh-edge-expansion-foam",
      manufacturer: [],
      datasheet: {},
    },
    coil: {
      type: "ufh-coil",
      name: "Underfloor Heating Coils",
      uid: "ufh-coil",
      manufacturer: [],
      datasheet: {},
    },
  },
  filters: {
    softener: {
      type: "water-softener-spec",
      name: "Water Softener",
      uid: "softener",
      manufacturer: [],
      datasheet: {},
    },
    backwash: {
      type: "backwash-spec",
      name: "Backwash Filter",
      uid: "backwash",
      manufacturer: [],
      datasheet: {},
    },
    cartridge: {
      type: "cartridge-spec",
      name: "Cartridge Filter",
      uid: "cartridge",
      manufacturer: [],
      datasheet: {},
    },
    uv: {
      type: "uv-spec",
      name: "UV Filter",
      uid: "uv",
      manufacturer: [],
      datasheet: {},
    },
    backwashRainwater: {
      type: "backwash-rainwater-spec",
      name: "Backwash Rainwater Reuse Filter",
      uid: "backwashRainwater",
      manufacturer: [],
      datasheet: {},
    },
  },
  roPlant: {
    manufacturer: [],
    datasheet: {},
  },

  ductFittings: {
    elbows: {},
    convergingTees: {},
    divergingTees: {},
    transitions: {},
  },

  ducts: {},
};
