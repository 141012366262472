import { sumBy } from "lodash";
import { coord3DDist } from "../../../lib/coord";
import {
  cylinderVolumeBetweenPointsMM3,
  LStoM3S,
  mm3toL,
  torusVolumeMM3,
  velocityMS,
} from "../../../lib/mathUtils/fluid-utils";
import {
  interpolateTable,
  lowerBoundTable,
  parseCatalogNumberExact,
} from "../../../lib/utils";
import { GenericBendKValues } from "../../catalog/manufacturers/generic/generic-ufh";
import { FluidsSpec } from "../../catalog/types";
import { UnderfloorHeatingLoopCalculation } from "../../document/calculations-objects/underfloor-heating-loop-calculation";
import {
  fittingFrictionLossMH,
  head2kpa,
  PressureDropCalculations,
} from "../pressure-drops";
import { fullLoopBreakdown3D, LoopBreakdown3D } from "./loop-breakdown";

export function getLoopVolumeL(loop: UnderfloorHeatingLoopCalculation): number {
  const pipeInternalDiameterMM =
    loop.internalPipeDiameterMM || loop.pipeDiameterMM;
  const breakdown = fullLoopBreakdown3D(loop);
  return sumBy(breakdown, (s) => getSegmentVolumeL(s, pipeInternalDiameterMM));
}

function getSegmentVolumeL(
  segment: LoopBreakdown3D,
  pipeInternalDiameterMM: number,
): number {
  switch (segment.type) {
    case "straight":
      return mm3toL(
        cylinderVolumeBetweenPointsMM3(
          segment.coords[0],
          segment.coords[1],
          pipeInternalDiameterMM,
        ),
      );
    case "bend":
      return mm3toL(
        torusVolumeMM3(
          segment.radiusMM,
          pipeInternalDiameterMM,
          segment.totalAngleRAD,
        ),
      );
  }
}

export function getLoopVelocityMS(loop: UnderfloorHeatingLoopCalculation) {
  if (!loop.flowRateLS || !loop.internalPipeDiameterMM) {
    console.warn("Missing Loop Spec for velocity calculation", loop);
    return 0;
  }

  return velocityMS(LStoM3S(loop.flowRateLS), loop.internalPipeDiameterMM);
}

export function calculateLoopPressureDropKPA(
  loop: UnderfloorHeatingLoopCalculation,
  fluid: FluidsSpec,
  ga: number,
  temperatureC: number,
) {
  const pressureDropKPA = 0;

  if (
    !loop.colebrookWhiteCoefficient ||
    !loop.internalPipeDiameterMM ||
    !loop.flowRateLS
  ) {
    console.warn("Missing Loop Spec for pressure drop calculation", loop);
    return pressureDropKPA;
  }

  const breakdown = fullLoopBreakdown3D(loop);

  const velMS = getLoopVelocityMS(loop);

  const dynamicViscosity = parseCatalogNumberExact(
    // TODO: get temperature of the pipe.
    interpolateTable(fluid.dynamicViscosityByTemperature, temperatureC),
  )!;

  return sumBy(breakdown, (s) =>
    getSegmentPressureDropKPA(s, loop, fluid, dynamicViscosity, velMS, ga),
  );
}

export function getLoopLengthMM(loop: UnderfloorHeatingLoopCalculation) {
  let lengthMM = 0;
  const breakdown = fullLoopBreakdown3D(loop);
  for (const segment of breakdown) {
    if (segment.type === "straight") {
      lengthMM += coord3DDist(segment.coords[0], segment.coords[1]);
    } else if (segment.type === "bend") {
      // Take worst case tension/compression scenario and assume the
      // longest possible length of pipe needed to form the bend.
      const effectiveRadiusMM = segment.radiusMM + loop.pipeDiameterMM / 2;
      lengthMM += segment.totalAngleRAD * effectiveRadiusMM;
    }
  }
  return lengthMM;
}

function getSegmentPressureDropKPA(
  segment: LoopBreakdown3D,
  loop: UnderfloorHeatingLoopCalculation,
  fluid: FluidsSpec,
  dynamicViscosity: number,
  velMS: number,
  ga: number,
): number {
  const densityKGM3 = parseCatalogNumberExact(fluid.densityKGM3)!;
  switch (segment.type) {
    case "straight":
      // Bunch of code to reconstruct this mess.
      const lengthMM = coord3DDist(segment.coords[0], segment.coords[1]);
      return head2kpa(
        PressureDropCalculations.getDarcyWeisbachFlatMH(
          loop.internalPipeDiameterMM!,
          loop.colebrookWhiteCoefficient!,
          densityKGM3,
          dynamicViscosity,
          lengthMM / 1000,
          velMS,
          ga,
        ),
        densityKGM3,
        ga,
      );
    case "bend":
      const kValue =
        lowerBoundTable<number>(GenericBendKValues, loop.pipeDiameterMM) ?? 1.2;

      return (
        (head2kpa(fittingFrictionLossMH(velMS, kValue, ga), densityKGM3, ga) *
          segment.totalAngleRAD) /
        (Math.PI / 2)
      );
  }
}
