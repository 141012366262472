import { upperBoundTable } from "../../lib/utils";
import { isDrainage, isPressure, isSewer, isStormwater } from "../config";
import CoreLoadNode from "../coreObjects/coreLoadNode";
import CoreSystemNode from "../coreObjects/coreSystemNode";
import { NoFlowAvailableReason } from "../document/calculations-objects/conduit-calculations";
import { addWarning } from "../document/calculations-objects/warnings";
import ConduitEntity, {
  PipeConduitEntity,
  fillDefaultConduitFields,
  isPipeEntity,
} from "../document/entities/conduit-entity";
import { ValveType } from "../document/entities/directed-valves/valve-types";
import { FittingEntity } from "../document/entities/fitting-entity";
import FixtureEntity, {
  fillFixtureFields,
} from "../document/entities/fixtures/fixture-entity";
import LoadNodeEntity from "../document/entities/load-node-entity";
import { EntityType } from "../document/entities/types";
import { SewerFlowSystem, flowSystemHasVent } from "../document/flow-systems";
import { getFlowSystem } from "../document/utils";
import CalculationEngine from "./calculation-engine";
import { CalculationHelper } from "./calculation-helper";
import { TraceCalculation } from "./flight-data-recorder";
import { GlobalFDR } from "./global-fdr";
import { Edge, VISIT_RESULT_WRONG_WAY } from "./graph";
import { CoreContext, EdgeType, FlowEdge, FlowNode } from "./types";
import {
  PsdCountEntry,
  addPsdCounts,
  compareDrainagePsdCounts,
  subPsdCounts,
  zeroPsdCounts,
} from "./utils";

interface VentSource {
  node: FlowNode;
  entityUid: string;
  pipeSize: number;
  system: SewerFlowSystem;
  drainageUnits: number | null;
}

export class DrainageCalculations {
  @TraceCalculation("Sizing sewage pipe", (e, _1, _2) => [e.uid])
  static fillSewagePipeCalcResult(
    entity: PipeConduitEntity,
    context: CoreContext,
    overridePsdUnits?: PsdCountEntry,
  ) {
    const calc = context.globalStore.getOrCreateCalculation(entity);

    const psdUnits = overridePsdUnits || calc.psdUnits;
    const system = getFlowSystem(context.drawing, entity.systemUid);

    if (!system || !isSewer(system)) {
      return;
    }

    const drainageUnits = psdUnits?.drainageUnits ?? null;

    CalculationHelper.sizeDrainagePipe(
      entity,
      context,
      system,
      drainageUnits,
      calc,
    );
  }

  @TraceCalculation("Sizing vent pipe", (e, _1, _2) => [e.uid])
  static sizeVentPipe(
    entity: PipeConduitEntity,
    context: CoreContext,
    psdUnits: PsdCountEntry,
  ) {
    const calc = context.globalStore.getOrCreateCalculation(entity);
    calc.psdUnits = psdUnits;

    const system = getFlowSystem<"sewer">(context.drawing, entity.systemUid)!;
    const calculatedVentSize = this.getSizeOfVent(
      system,
      psdUnits.drainageUnits,
    );
    if (entity.conduit.diameterMM == null) {
      calc.optimalInnerPipeDiameterMM = calc.realNominalPipeDiameterMM =
        calculatedVentSize;
    } else {
      if (
        calculatedVentSize &&
        entity.conduit.diameterMM < calculatedVentSize
      ) {
        addWarning(context, "OVERRIDEN_PIPE_DIAMETER_INSUFFICIENT", [entity], {
          mode: "drainage",
        });
      }
    }
    if (calc.realNominalPipeDiameterMM === null) {
      calc.noFlowAvailableReason = NoFlowAvailableReason.NO_SUITABLE_PIPE_SIZE;
    }
  }

  static getSizeOfVent(system: SewerFlowSystem, drainageUnits: number) {
    system.ventSizing.sort((a, b) => a.maxUnits - b.maxUnits);

    for (const entry of system.ventSizing) {
      if (entry.maxUnits >= drainageUnits) {
        return entry.sizeMM;
      }
    }
    return null;
  }

  @TraceCalculation("Calculating drainage (live)")
  static processDrainageLive(context: CalculationEngine) {
    const exits = this.findVentExits(context);
    this.propagateVentDirections(context, exits);
  }

  // This function must be run AFTER PSDs have been propagated.

  @TraceCalculation("Calculating drainage")
  static processDrainage(context: CalculationEngine) {
    const roots = this.processVentRoots(context);
    const exits = this.findVentExits(context);
    this.propagateVentDirections(context, exits);
    this.propagateVentedness(context, roots);
    const capacities = this.assignVentCapacities(context, roots);

    this.sizeVents(context, capacities, exits);
    // produceUnventedWarnings(context, roots);

    this.processFixedStacks(context);
    this.calculateFalls(context);

    // size I.O. and its connecting pipe going through a drainage pipe
    this.sizeIOandPipes(context);
  }

  // Loop calculations mess up vent directions sometimes, this re-corrects them.

  @TraceCalculation("Determining directions of vent pipes")
  static propagateVentDirections(
    context: CalculationEngine,
    exits: FittingEntity[],
  ) {
    const visited = new Set<string>();
    for (const exit of exits) {
      GlobalFDR.focusData([exit.uid]);
      context.flowGraph.dfs(
        {
          connectable: exit.uid,
          connection: context.globalStore.getConnections(exit.uid)[0],
        },
        undefined,
        undefined,
        (edge) => {
          if (edge.value.type !== EdgeType.CONDUIT) {
            return;
          }
          const conduit = context.globalStore.getObjectOfType(
            EntityType.CONDUIT,
            edge.value.uid,
          );
          if (!conduit || !isPipeEntity(conduit.entity)) {
            return;
          }
          if (!this.isPipeVent(context, conduit.entity)) {
            return true;
          }
          const calc = context.globalStore.getOrCreateCalculation(
            conduit.entity,
          );
          calc.flowFrom = edge.from.connectable;
        },
        undefined,
        visited,
      );
    }
  }

  @TraceCalculation("Sizing inlets and outlets, and pipes")
  static sizeIOandPipes(context: CalculationEngine) {
    for (const obj of context.networkObjects()) {
      if (
        obj.entity.type === EntityType.DIRECTED_VALVE &&
        obj.entity.valve.type === ValveType.INSPECTION_OPENING
      ) {
        const IO = obj.entity;
        const connections = context.globalStore.getConnections(obj.entity.uid);

        const pipes = new Set<PipeConduitEntity>();

        let finish = false;
        if (connections.length) {
          context.flowGraph.dfs(
            {
              connectable: obj.entity.uid,
              connection: connections[0],
            },
            undefined,
            undefined,
            (edge) => {
              if (edge.value.type !== EdgeType.CONDUIT) {
                return;
              }
              const o = context.globalStore.getObjectOfType(
                EntityType.CONDUIT,
                edge.value.uid,
              );
              if (!o || !isPipeEntity(o.entity)) {
                return;
              }
              const pipe = o.entity;
              const filled = fillDefaultConduitFields(context, pipe);
              const pc = context.globalStore.getOrCreateCalculation(pipe);

              if (pc.psdUnits?.drainageUnits && !finish) {
                const ioCalc = context.globalStore.getOrCreateCalculation(IO);
                ioCalc.sizeMM =
                  // TODO: put the HaRd CoDeD vAlUeS into the catalog.
                  (pc.realNominalPipeDiameterMM || 0) > 110 ? 160 : 110;

                pipes.forEach((i) =>
                  this.sizePipe(i, pc.realNominalPipeDiameterMM || 0, context),
                );

                finish = !finish;
              }

              if (!finish) {
                pipes.add(filled);
              }
            },
            undefined,
            undefined,
          );
        }
      }
    }
  }

  @TraceCalculation("Sizing drainage pipe")
  static sizePipe(
    filledPipe: PipeConduitEntity,
    pipSize: number,
    context: CoreContext,
  ) {
    const pc = context.globalStore.getOrCreateCalculation(filledPipe);

    pc.realNominalPipeDiameterMM =
      pipSize > 110
        ? ["castIronDrainage", "uPVCDrainage"].includes(
            filledPipe.conduit.material!,
          )
          ? 150
          : 160
        : ["castIronDrainage", "uPVCDrainage"].includes(
              filledPipe.conduit.material!,
            )
          ? 100
          : 110;
  }

  @TraceCalculation("Calculating drainage falls")
  static calculateFalls(context: CalculationEngine) {
    for (const obj of context.networkObjects()) {
      if (isPipeEntity(obj.entity)) {
        GlobalFDR.focusData([obj.entity.uid]);
        const filled = fillDefaultConduitFields(context, obj.entity);
        if (
          isPressure(context.drawing.metadata.flowSystems[filled.systemUid])
        ) {
          continue;
        }

        // grade only applies to horizontal pipes.
        // But calculate it for all - if it isn't horizontal it just won't get shown, that's all.
        const pCalc = context.globalStore.getOrCreateCalculation(obj.entity);
        if (pCalc.gradePCT && pCalc.lengthM) {
          pCalc.fallM = (pCalc.gradePCT * pCalc.lengthM) / 100;
        }
      }
    }
  }

  // Refer to https://h2xengineering.atlassian.net/secure/RapidBoard.jspa?rapidView=2&projectKey=DEV&modal=detail&selectedIssue=DEV-145
  @TraceCalculation("Assinging vent capacities")
  static assignVentCapacities(
    context: CalculationEngine,
    roots: Map<string, PsdCountEntry>,
  ): Map<string, PsdCountEntry> {
    const result = new Map<string, PsdCountEntry>();
    // keep track of pipe LUs for warnings
    const unventedLUs = new Map<string, PsdCountEntry>();

    const entryPoints: VentSource[] = [];

    const fixtureOutlets: string[] = [];

    // For every fixture, find the closest vented pipe downstream, and add it to the vent root associated.
    for (const obj of context.networkObjects()) {
      GlobalFDR.focusData([obj.entity.uid]);

      if (obj.entity.type === EntityType.FIXTURE) {
        const fixture = obj.entity;
        const filled = fillFixtureFields(context, fixture);
        const drainageUnits = filled.drainageFixtureUnits;

        for (const outletUid of fixture.roughInsInOrder) {
          if (isSewer(context.drawing.metadata.flowSystems[outletUid])) {
            const outlet = fixture.roughIns[outletUid];
            const connections = context.globalStore.getConnections(outlet.uid);
            fixtureOutlets.push(outlet.uid);

            if (connections.length > 0) {
              const pipe = context.globalStore.getObjectOfType(
                EntityType.CONDUIT,
                connections[0],
              );
              if (!pipe || !isPipeEntity(pipe.entity)) {
                continue;
              }

              const pCalcOriginal = context.globalStore.getOrCreateCalculation(
                pipe.entity,
              );
              const originalSize = pCalcOriginal.realNominalPipeDiameterMM;

              const system = getFlowSystem(
                context.drawing.metadata.flowSystems,
                pipe.entity.systemUid,
              );
              if (!system || !isSewer(system)) {
                continue;
              }

              if (!originalSize) {
                // We need the pipe to be sized to know how much venting it needs
              } else {
                entryPoints.push({
                  node: { connectable: outlet.uid, connection: connections[0] },
                  entityUid: outlet.uid,
                  pipeSize: originalSize,
                  system,
                  drainageUnits,
                });
              }
            }
          }
        }
      } else if (obj.entity.type === EntityType.LOAD_NODE) {
        const connections = context.globalStore.getConnections(obj.entity.uid);
        if (connections.length > 0) {
          for (let i = 0; i < connections.length; i++) {
            const pipe = context.globalStore.getObjectOfTypeOrThrow(
              EntityType.CONDUIT,
              connections[i],
            );
            if (
              !isPipeEntity(pipe.entity) ||
              isPressure(
                context.drawing.metadata.flowSystems[pipe.entity.systemUid],
              )
            ) {
              continue;
            } else {
              i = connections.length - 1;
            }

            const pCalcOriginal = context.globalStore.getOrCreateCalculation(
              pipe.entity,
            );

            if (pCalcOriginal.flowFrom === obj.entity.uid) {
              continue;
            }

            const originalSize = pCalcOriginal.realNominalPipeDiameterMM;
            const system = getFlowSystem(
              context.drawing,
              pipe.entity.systemUid,
            );
            if (!system || !isSewer(system)) {
              continue;
            }
            const drainageUnits = pCalcOriginal.psdUnits?.drainageUnits;

            if (!originalSize) {
              // We need the pipe to be sized to know how much venting it needs
            } else {
              entryPoints.push({
                node: {
                  connectable: obj.entity.uid,
                  connection: connections[i],
                },
                entityUid: obj.entity.uid,
                pipeSize: originalSize,
                system,
                drainageUnits: drainageUnits!,
              });
            }
          }
        }
      }
    }

    console.log("Entry points", entryPoints);

    // TODO: redo this step to calculate from the vent upwards, instead of N dfs's from fixtures
    // downwards.
    for (const ep of entryPoints) {
      GlobalFDR.focusData([ep.entityUid]);
      const parentOf = new Map<string, Edge<FlowNode, FlowEdge>>();

      const distTo = new Map<string, number>();
      const maxUnventedLengthM = upperBoundTable(
        ep.system.maxUnventedLengthM,
        ep.pipeSize,
      );

      context.flowGraph.dfsRecursive(ep.node, undefined, undefined, (edge) => {
        if (edge.value.type === EdgeType.CONDUIT) {
          const pipe = context.globalStore.getObjectOfType(
            EntityType.CONDUIT,
            edge.value.uid,
          );
          parentOf.set(edge.to.connectable, edge);
          if (
            !pipe ||
            !isPipeEntity(pipe.entity) ||
            isPressure(
              context.drawing.metadata.flowSystems[pipe.entity.systemUid],
            ) ||
            isStormwater(
              context.drawing.metadata.flowSystems[pipe.entity.systemUid],
            ) ||
            this.isPipeVent(context, pipe.entity)
          ) {
            return true;
          }

          // only go upstream
          const pCalc = context.globalStore.getOrCreateCalculation(pipe.entity);
          if (edge.to.connectable !== pCalc.flowFrom) {
            return true;
          }
          const prevUnvented =
            unventedLUs.get(edge.value.uid) || zeroPsdCounts();
          unventedLUs.set(
            edge.value.uid,
            addPsdCounts(context, prevUnvented, {
              continuousFlowLS: 0,
              dwellings: 0,
              gasMJH: 0,
              gasUndiversifiedMJH: 0,
              gasHighestMJH: 0,
              gasDiversifiedMJH: 0,
              units: 0,
              drainageUnits: ep.drainageUnits || 0,
            }),
          );

          // the whole point of this algo - assign this flow to the vent root
          // that reaches this pipe.
          if (pCalc.ventRoot) {
            const curr = result.get(pCalc.ventRoot) || zeroPsdCounts();

            result.set(
              pCalc.ventRoot,
              addPsdCounts(context, curr, {
                continuousFlowLS: 0,
                dwellings: 0,
                gasMJH: 0,
                gasUndiversifiedMJH: 0,
                gasHighestMJH: 0,
                gasDiversifiedMJH: 0,
                units: 0,
                drainageUnits: ep.drainageUnits || 0,
              }),
            );
            return true;
          } else {
            // Check that the pipe will not exceed max unvented length or FU/DU
            const unventedLength =
              (distTo.get(edge.from.connectable) || 0) + pCalc.lengthM!;

            distTo.set(edge.to.connectable, unventedLength);

            if (
              maxUnventedLengthM != null &&
              unventedLength > maxUnventedLengthM
            ) {
              const accountedFor = pCalc.ventTooFarDist;
              let curr: Edge<FlowNode, FlowEdge> | undefined = edge;
              let visitedUnventedRun: Set<string> = new Set();

              while (curr) {
                const currPipe = context.globalStore.get(curr.value.uid);
                if (currPipe && isPipeEntity(currPipe.entity)) {
                  const currPCalc = context.globalStore.getOrCreateCalculation(
                    currPipe.entity,
                  );
                  if (visitedUnventedRun.has(curr.value.uid)) {
                    addWarning(context, "INVALID_LOOP", [currPipe.entity], {
                      mode: "drainage",
                    });
                    console.warn(
                      "Loop in pipesystem detected for id: " + curr.value.uid,
                    );
                    break;
                  }
                  visitedUnventedRun.add(curr.value.uid);
                  currPCalc.ventTooFarDist = true;

                  curr = parentOf.get(curr.from.connectable);
                }
              }

              const targetOutlet = ep.entityUid;
              const sn = context.globalStore.get(targetOutlet) as
                | CoreSystemNode
                | CoreLoadNode;
              let targetUid = sn.entity.uid;
              let target: LoadNodeEntity | FixtureEntity;
              if (sn.entity.type === EntityType.SYSTEM_NODE) {
                targetUid = sn.entity.parentUid!;
                target = context.globalStore.getObjectOfTypeOrThrow(
                  EntityType.FIXTURE,
                  targetUid,
                ).entity;
              } else {
                target = sn.entity;
              }
              addWarning(context, "MAX_UNVENTED_LENGTH", [target], {
                mode: "drainage",
                params: {
                  valueM: unventedLength,
                  maxM: maxUnventedLengthM,
                },
                replaceSameWarnings: true,
              });
            }
          }
        }
      });
    }

    const unventedWCsAtPipe = new Map<string, number>();
    // Generated maximum unvented WC warnings.
    for (const ep of entryPoints) {
      GlobalFDR.focusData([ep.entityUid]);
      const e = context.globalStore.getObjectOfType(
        EntityType.SYSTEM_NODE,
        ep.entityUid,
      );
      // We care only about WCs.
      if (!e) {
        continue;
      }
      const p = context.globalStore.getObjectOfType(
        EntityType.FIXTURE,
        e.entity.parentUid!,
      );
      if (!p) {
        continue;
      }
      if (p.entity.name !== "wc") {
        continue;
      }

      // OK we are dealing with water closets now.

      context.flowGraph.dfs(ep.node, undefined, undefined, (edge) => {
        if (edge.value.type === EdgeType.CONDUIT) {
          const pipe = context.globalStore.getObjectOfTypeOrThrow(
            EntityType.CONDUIT,
            edge.value.uid,
          );
          if (!isPipeEntity(pipe.entity)) {
            return;
          }

          const pCalc = context.globalStore.getOrCreateCalculation(pipe.entity);

          if (pCalc.ventRoot) {
            return true;
          }

          if (pCalc.flowFrom !== edge.to.connectable) {
            return VISIT_RESULT_WRONG_WAY;
          }

          if (
            pCalc.warnings?.filter(
              (warning) =>
                warning.type === "MAX_UNVENTED_DRAINAGE_FLOW_EXCEEDED",
            )?.length
          ) {
            return true;
          }

          const pipeSystem = getFlowSystem(
            context.drawing.metadata.flowSystems,
            pipe.entity.systemUid,
          );

          if (!isSewer(pipeSystem)) {
            return true;
          }

          const maxUnventedWCs = upperBoundTable(
            pipeSystem!.maxUnventedCapacityWCs,
            pCalc.realNominalPipeDiameterMM || 0,
          );

          const unventedWCs = (unventedWCsAtPipe.get(edge.value.uid) || 0) + 1;
          unventedWCsAtPipe.set(edge.value.uid, unventedWCs);

          if (maxUnventedWCs && unventedWCs > maxUnventedWCs) {
            pCalc.ventTooFarWC = true;
            addWarning(
              context,
              "MAX_UNVENTED_DRAINAGE_FLOW_EXCEEDED",
              [pipe.entity],
              {
                mode: "drainage",
                params: {
                  maxWCs: maxUnventedWCs,
                  valueWCs: unventedWCs,
                },
              },
            );
          }
        }
      });
    }

    return result;
  }

  @TraceCalculation("Creating unvented pipe warnings")
  static produceUnventedWarnings(
    context: CalculationEngine,
    roots: Map<string, PsdCountEntry>,
  ) {
    this.produceUnventedLengthWarningsAndGetUnventedGroup(context);
  }

  // Make warnings for unvented fixtures that ought to be vented.
  static produceUnventedLengthWarningsAndGetUnventedGroup(
    context: CalculationEngine,
  ) {
    for (const obj of context.networkObjects()) {
      if (obj.entity.type === EntityType.FIXTURE) {
        GlobalFDR.focusData([obj.uid]);
        const fixture = obj.entity;
        for (const outletUid of fixture.roughInsInOrder) {
          if (isDrainage(context.drawing.metadata.flowSystems[outletUid])) {
            const outlet = fixture.roughIns[outletUid];
            const connections = context.globalStore.getConnections(outlet.uid);

            if (connections.length > 0) {
              // Theoretically, the vent shouldn't ever split (only combine), but we will handle it just in case.
              const lengthAtNode = new Map<string, number>();

              // Don't trust the system of the outlet - use the first pipe instead because the outlet
              // is a fixed drainage system but the user can draw any drainage system onto it.
              // const system = getFlowSystem(context.doc.drawing, outletUid);
              // if (!system) {
              //    continue;
              // }

              // outlets should only connect to one pipe. So for that pipe, use its size as a canary.
              const pipeD = context.getCalcByPipeId(connections[0]);
              if (!pipeD) {
                continue;
              }

              const originalSize = pipeD?.pCalc.realNominalPipeDiameterMM;

              const system = getFlowSystem(
                context.drawing,
                pipeD.pEntity.systemUid,
              );
              if (!system || !isSewer(system)) {
                continue;
              }

              if (!originalSize) {
                // We need the pipe to be sized to know how much venting it needs
                continue;
              }

              const maxUnventedLengthM = upperBoundTable(
                system.maxUnventedLengthM,
                originalSize,
              );

              if (
                maxUnventedLengthM === undefined ||
                maxUnventedLengthM === null
              ) {
                // it is unbounded
                continue;
              }

              let maxUnventedExceeded = false;
              let highestUnventedLengthM = 0;
              context.flowGraph.dfsRecursive(
                { connectable: outlet.uid, connection: connections[0] },
                undefined,
                undefined,
                (edge) => {
                  if (edge.value.type === EdgeType.CONDUIT) {
                    const pipe = context.globalStore.getObjectOfType(
                      EntityType.CONDUIT,
                      edge.value.uid,
                    );
                    if (
                      !pipe ||
                      !isPipeEntity(pipe.entity) ||
                      isPressure(
                        context.drawing.metadata.flowSystems[
                          pipe.entity.systemUid
                        ],
                      )
                    ) {
                      return true;
                    }

                    const pcalc = context.globalStore.getOrCreateCalculation(
                      pipe.entity,
                    );
                    if (edge.to.connectable !== pcalc.flowFrom) {
                      return true;
                    }
                    // If it is already vented, we are done with the calculation.
                    if (pcalc.ventRoot) {
                      return true;
                    }

                    // We already accomplished our goal.
                    if (maxUnventedExceeded) {
                      return true;
                    }

                    const currLength =
                      lengthAtNode.get(edge.from.connectable) || 0;
                    const newLength = currLength + (pcalc.lengthM || 0);
                    highestUnventedLengthM = Math.max(
                      highestUnventedLengthM,
                      newLength,
                    );
                    if (newLength > maxUnventedLengthM) {
                      // Too big.
                      maxUnventedExceeded = true;
                      return true;
                    }
                    lengthAtNode.set(edge.to.connectable, newLength);
                  }
                },
              );

              if (maxUnventedExceeded) {
                const fcalc =
                  context.globalStore.getOrCreateCalculation(fixture);
                addWarning(context, "MAX_UNVENTED_LENGTH", [fixture], {
                  mode: "drainage",
                  params: {
                    valueM: highestUnventedLengthM,
                    maxM: maxUnventedLengthM,
                  },
                });
              }
            }
          }
        }
      }
    }
  }

  @TraceCalculation("Propagating ventedness")
  static propagateVentedness(
    context: CalculationEngine,
    roots: Map<string, PsdCountEntry>,
  ) {
    const seen = new Set<string>();
    const seenEdges = new Set<string>();
    for (const root of Array.from(roots.keys())) {
      GlobalFDR.focusData([root]);
      const connection = context.globalStore.getConnections(root);
      context.flowGraph.dfsRecursive(
        { connectable: root, connection: connection[0] },
        undefined,
        undefined,
        (edge) => {
          if (edge.value.type === EdgeType.CONDUIT) {
            // Only go towards the source.
            const pipe = context.globalStore.getObjectOfType(
              EntityType.CONDUIT,
              edge.value.uid,
            );
            if (!pipe || !isPipeEntity(pipe.entity)) {
              return true;
            }
            const pcalc = context.globalStore.getOrCreateCalculation(
              pipe.entity,
            );
            if (edge.to.connectable !== pcalc.flowFrom) {
              seenEdges.delete(edge.uid);
              return true;
            } else {
              if (pcalc.ventRoot) {
                return true;
              }
              pcalc.ventRoot = root;
            }
          }
        },
        undefined,
        seen,
        seenEdges,
      );
    }
  }

  // Assumes that vents are arranged in a tree like pattern.
  @TraceCalculation("Sizing vents")
  static sizeVents(
    context: CalculationEngine,
    roots: Map<string, PsdCountEntry>,
    exits: FittingEntity[],
  ) {
    const seenPipes = new Set<string>();

    const exitSet = new Set<string>(exits.map((e) => e.uid));

    for (const e of exits) {
      GlobalFDR.focusData([e.uid]);
      const flowOfNode = new Map<string, PsdCountEntry>();
      let multipleVentExits = false; // the algorithm only supports a unique vent exit.
      const listOfVents: ConduitEntity[] = []; // To retroactively create warnings later.

      const connection = context.globalStore.getConnections(e.uid);

      // The strategy is for the children nodes to be populated with their loads in flowOfNode,
      // then when it comes time to exit the pipe, we would know the load of our downstream, and
      // also update our upstream node.
      // Note: Here, "downstream" means from the exit to the source. So closer to exit = upstream,
      // closer to drainage pipe = downstream.
      context.flowGraph.dfsRecursive(
        { connectable: e.uid, connection: connection[0] },
        undefined,
        undefined,
        (edge) => {
          if (edge.value.type !== EdgeType.CONDUIT) {
            return;
          }

          // Only go down vents
          const pipe = context.globalStore.get(edge.value.uid);
          if (
            !pipe ||
            !isPipeEntity(pipe.entity) ||
            pipe.entity.type !== EntityType.CONDUIT ||
            isPressure(
              context.drawing.metadata.flowSystems[pipe.entity.systemUid],
            ) ||
            pipe.entity.conduit.network !== "vents"
          ) {
            return true;
          }

          if (seenPipes.has(edge.value.uid)) {
            return true; // this shouldn't really happen unless there are multiple vent exits per group.
            // But we check for it anyway and break here to save computation in that case.
          }
          seenPipes.add(edge.value.uid);

          listOfVents.push(pipe.entity);

          // Look for terminal cases going down
          const to = edge.to.connectable;
          if (roots.has(to)) {
            flowOfNode.set(
              edge.to.connectable,
              roots.get(to) || zeroPsdCounts(),
            );
          }
          if (exitSet.has(to) && to !== e.uid) {
            // We have found a connected exit that is not us. There are two exits - this makes vent
            // sizing ambiguous and is currently not supported.
            multipleVentExits = true;
          }
        },
        (edge, wasCancelled) => {
          if (wasCancelled) {
            return;
          }

          if (edge.value.type !== EdgeType.CONDUIT) {
            return;
          }

          const downstreamFlow =
            flowOfNode.get(edge.to.connectable) || zeroPsdCounts();

          // Propagate to upstream
          const existingUpstreamFlow =
            flowOfNode.get(edge.from.connectable) || zeroPsdCounts();
          flowOfNode.set(
            edge.from.connectable,
            addPsdCounts(context, downstreamFlow, existingUpstreamFlow),
          );

          // Size the pipe. That's what we're here for, right?
          const pipe = context.globalStore.get(edge.value.uid);
          if (
            pipe &&
            isPipeEntity(pipe.entity) &&
            isSewer(
              context.drawing.metadata.flowSystems[pipe.entity.systemUid],
            ) &&
            pipe.entity.conduit.network === "vents"
          ) {
            this.sizeVentPipe(pipe.entity, context, downstreamFlow);
          } else {
            throw new Error("Leaving a pipe that wasn't a vent");
          }
        },
      );

      if (multipleVentExits) {
        // TODO: invalidate the sizings and produce warnings.
        console.error("ERROR: There are multiple vent exits");
      }
    }
  }

  @TraceCalculation("Processing vent roots")
  static processVentRoots(
    context: CalculationEngine,
  ): Map<string, PsdCountEntry> {
    const result = new Map<string, PsdCountEntry>();

    // Even though in a well drawn document, we won't visit pipes twice (everything is a tree),
    // in case it IS poorly draw, keep this seen set to help avoid taking too much time.
    const seenPipes = new Set<string>();

    let seenFlowSource = false;
    let randomPipeUid = "";

    for (const obj of context.networkObjects()) {
      GlobalFDR.focusData([obj.entity.uid]);
      if (obj.entity.type === EntityType.FLOW_SOURCE) {
        if (
          isDrainage(context.drawing.metadata.flowSystems[obj.entity.systemUid])
        ) {
          // The strategy: from a sewer connection, we will traverse the graph of pipes.
          // Using a similar method to processVents, we will propagate up the total sum
          // of nodes experienced at all immediate vented nodes downstream. Then, once we
          // have that, the unvented load at any point is the load of the pipe upstream
          // from it minus the load experienced (and neutralized) by the vented nodes below.

          seenFlowSource = true;
          const flowAtNextVent = new Map<string, PsdCountEntry>();
          const lenghtAtNextVent = new Map<string, number>();
          let multipleSewerConnections = false;
          let missingCalculations = false;
          const listOfPipes: ConduitEntity[] = [];

          const connections = context.globalStore.getConnections(
            obj.entity.uid,
          );
          if (!connections.length) {
            continue;
          }

          const catalyst = connections[0];
          context.flowGraph.dfsRecursive(
            { connectable: obj.entity.uid, connection: catalyst },
            undefined,
            undefined,
            (edge) => {
              if (edge.value.type !== EdgeType.CONDUIT) {
                return;
              }

              // Only go down drainage pipes
              const pipe = context.globalStore.get(edge.value.uid);
              if (
                !pipe ||
                !isPipeEntity(pipe.entity) ||
                isPressure(
                  context.drawing.metadata.flowSystems[pipe.entity.systemUid],
                ) ||
                pipe.entity.conduit.network === "vents"
              ) {
                return true;
              }

              if (seenPipes.has(edge.value.uid)) {
                return true; // this shouldn't really happen unless there are multiple drainages per group.
              }
              seenPipes.add(edge.value.uid);

              listOfPipes.push(pipe.entity);
            },
            (edge, wasCancelled) => {
              if (wasCancelled) {
                return;
              }
              if (edge.value.type !== EdgeType.CONDUIT) {
                return;
              }

              // Every potential vent root is represented by the pipe "upstream" from it.
              const downUid = edge.to.connectable;
              const connections = context.globalStore.getConnections(downUid);
              let isRoot = false;

              for (const cuid of connections) {
                const cobj = context.globalStore.getObjectOfType(
                  EntityType.CONDUIT,
                  cuid,
                );
                if (cobj) {
                  if (
                    isDrainage(
                      context.drawing.metadata.flowSystems[
                        cobj.entity.systemUid
                      ],
                    ) &&
                    isPipeEntity(cobj.entity) &&
                    cobj.entity.conduit.network === "vents"
                  ) {
                    isRoot = true;
                  }
                }
              }

              const upstreamUid = edge.from.connectable;

              const pipe = context.globalStore.getObjectOfType(
                EntityType.CONDUIT,
                edge.value.uid,
              );
              if (!pipe || !isPipeEntity(pipe.entity)) {
                return;
              }

              const calc = context.globalStore.getOrCreateCalculation(
                pipe.entity,
              );
              const filled = fillDefaultConduitFields(context, pipe.entity);

              const upstreamNextFlowTally =
                flowAtNextVent.get(upstreamUid) || zeroPsdCounts();
              const downstreamNextFlowTally =
                flowAtNextVent.get(downUid) || zeroPsdCounts();

              const upstreamNextLengthTally =
                lenghtAtNextVent.get(upstreamUid) || 0;
              const downstreamNextLengthTally =
                lenghtAtNextVent.get(downUid) || 0;

              if (isRoot) {
                lenghtAtNextVent.set(
                  upstreamUid,
                  Math.max(upstreamNextLengthTally, filled.lengthM!),
                );
                if (calc.psdUnits) {
                  flowAtNextVent.set(
                    upstreamUid,
                    addPsdCounts(context, upstreamNextFlowTally, calc.psdUnits),
                  );
                  // Record this root.
                  result.set(
                    edge.to.connectable,
                    subPsdCounts(calc.psdUnits, downstreamNextFlowTally),
                  );
                } else {
                  missingCalculations = true;
                }
              } else {
                flowAtNextVent.set(
                  upstreamUid,
                  addPsdCounts(
                    context,
                    upstreamNextFlowTally,
                    downstreamNextFlowTally,
                  ),
                );
              }
            },
          );

          if (missingCalculations) {
            // TODO: produce warnings for missing calculations
            console.error(
              "ERROR: There are missing PSD values along the pipes leading into the vent",
            );
          }
          if (multipleSewerConnections) {
            // TODO: produce warnings for multiple sewer connections connected to same system.
            console.error("ERROR: There are multiple sewer connections");
          }
        }
      }
      if (isPipeEntity(obj.entity)) {
        if (
          !randomPipeUid &&
          isDrainage(context.drawing.metadata.flowSystems[obj.entity.systemUid])
        ) {
          randomPipeUid = obj.entity.uid;
        }
      }
    }
    // there is no flow source, provide a warning into random Pipe
    if (!seenFlowSource && randomPipeUid) {
      const p = context.globalStore.getObjectOfTypeOrThrow(
        EntityType.CONDUIT,
        randomPipeUid,
      );
      const pCalc = context.globalStore.getOrCreateCalculation(p.entity);
      addWarning(context, "ADD_FLOW_SOURCE_TO_SYSTEM", [p.entity], {
        mode: "drainage",
      });
    }
    return result;
  }

  static isPipeVent(context: CoreContext, pipe: PipeConduitEntity) {
    return (
      flowSystemHasVent(context.drawing.metadata.flowSystems[pipe.systemUid]) &&
      pipe.conduit.network === "vents"
    );
  }

  @TraceCalculation("Finding vent exits")
  static findVentExits(context: CalculationEngine): FittingEntity[] {
    const result: FittingEntity[] = [];

    // Rules: Vent exits must be a deadleg or vertical vent. If there are multiple deadlegs/vertical vents, it must
    // be the highest elevation one.
    // If there are no deadlegs/vertical vents, pick the highest elevation connectable. If there are
    // multiple, pick any.

    const seenNodes = new Set<string>();

    for (const obj of context.networkObjects()) {
      GlobalFDR.focusData([obj.entity.uid]);
      if (obj.entity.type === EntityType.FITTING) {
        if (seenNodes.has(obj.entity.uid)) {
          continue;
        }

        const connections = context.globalStore.getConnections(obj.entity.uid);

        if (!connections.length) {
          continue;
        }

        const pipe = context.globalStore.get(connections[0])!;

        if (
          !isPipeEntity(pipe.entity) ||
          !this.isPipeVent(context, pipe.entity)
        ) {
          continue;
        }

        let bestHeight = -Infinity;
        let bestFitting: FittingEntity | null = null as any;
        let bestIsDeadleg = false;

        context.flowGraph.dfs(
          { connectable: obj.entity.uid, connection: connections[0] },
          (node) => {
            seenNodes.add(node.connectable);
            const fitting = context.globalStore.getConnectableOrThrow(
              node.connectable,
            );

            if (fitting.entity.type !== EntityType.FITTING) {
              return;
            }

            const thisConnections = context.globalStore.getConnections(
              fitting.entity.uid,
            );

            let deadleg = thisConnections.length === 1;

            let isBest = true;
            if (bestIsDeadleg && !deadleg) {
              isBest = false;
            }

            if (fitting.entity.calculationHeightM! < bestHeight) {
              isBest = false;
            }

            if (isBest) {
              bestHeight = fitting.entity.calculationHeightM!;
              bestFitting = fitting.entity;
              bestIsDeadleg = deadleg;
            }
          },
          undefined,
          (edge) => {
            if (edge.value.type === EdgeType.CONDUIT) {
              const pipe = context.globalStore.getObjectOfTypeOrThrow(
                EntityType.CONDUIT,
                edge.value.uid,
              );
              if (
                !isPipeEntity(pipe.entity) ||
                !this.isPipeVent(context, pipe.entity)
              ) {
                return true;
              }
            }
          },
          undefined,
          undefined,
          undefined,
          false,
        );

        if (bestFitting) {
          const calc = context.globalStore.getOrCreateCalculation(bestFitting);
          calc.isVentExit = true;
          result.push(bestFitting);
        }
      }
    }
    return result;
  }

  @TraceCalculation("Process fixed stacks")
  static processFixedStacks(context: CalculationEngine) {
    const seen = new Set<string>();
    for (const uid of context.networkObjectUids) {
      if (seen.has(uid)) {
        continue;
      }

      GlobalFDR.focusData([uid]);

      const object = context.globalStore.get(uid)!;
      if (isPipeEntity(object.entity)) {
        if (
          object.entity.conduit.network === "stacks" &&
          isSewer(context.drawing.metadata.flowSystems[object.entity.systemUid])
        ) {
          // Is a stack!
          this.processFixedStack(context, object.entity, seen);
        }
      }

      seen.add(uid);
    }
  }

  @TraceCalculation("Process fixed stack", (c, m, s) => [m.uid])
  static processFixedStack(
    context: CalculationEngine,
    member: PipeConduitEntity,
    seen: Set<string>,
  ) {
    let highestLU: PsdCountEntry = zeroPsdCounts();
    const fullStack: PipeConduitEntity[] = [];
    let isUndefined = false;

    const seenSystemUids = new Set<string>();

    context.flowGraph.dfs(
      { connectable: member.endpointUid[0], connection: member.uid },
      undefined,
      undefined,
      (edge) => {
        seen.add(edge.value.uid);
        if (edge.value.type === EdgeType.CONDUIT) {
          const pipe = context.globalStore.getObjectOfTypeOrThrow(
            EntityType.CONDUIT,
            edge.value.uid,
          ).entity;
          if (
            isPipeEntity(pipe) &&
            isDrainage(context.drawing.metadata.flowSystems[pipe.systemUid]) &&
            pipe.conduit.network === "stacks"
          ) {
            // Is Stack, continue DFS along it.
            const calc = context.globalStore.getOrCreateCalculation(pipe);
            if (calc.psdUnits) {
              const cmp = compareDrainagePsdCounts(calc.psdUnits, highestLU);
              if (cmp !== null && cmp > 0) {
                highestLU = calc.psdUnits;
              } else if (cmp === null) {
                isUndefined = true;
              }
            } else {
              isUndefined = true;
            }
            fullStack.push(pipe);
            seenSystemUids.add(pipe.systemUid);
          } else {
            return true; // Don't go along anything that isn't a stack.
          }
        }
      },
    );

    if (isUndefined) {
      // produce a warning TODO
      console.error(
        "Pipe in stack has undefined PSD, can't size properly now.",
      );
      return;
    }

    let allStackNotDiminishing = true;
    let allStackDiminishing = true;
    for (const suid of Array.from(seenSystemUids.values())) {
      const system = getFlowSystem(context.drawing, suid);
      if (!isSewer(system) || system.stackSizeDiminish === true) {
        allStackNotDiminishing = false;
      } else if (!isSewer(system) || system.stackSizeDiminish === false) {
        allStackDiminishing = false;
      }
    }

    if (!allStackNotDiminishing && !allStackDiminishing) {
      // produce a warning that segments in this pipe have incompatable settings for stack size diminishing
      return;
    }

    // We are good
    if (allStackNotDiminishing) {
      // OK so finally, in this case we actually want to size the stack.
      for (const pipe of fullStack) {
        const calc = context.globalStore.getOrCreateCalculation(pipe);
        this.fillSewagePipeCalcResult(pipe, context, highestLU);
      }
    }
  }
}
