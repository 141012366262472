import { betterObjectKeys } from "../../../lib/utils";
import {
  UFH_DEFAULT_COLOR_PALETTE,
  UFH_DEFAULT_TRANSIT_SPACING_MM,
} from "../../calculations/underfloor-heating/consts";
import { GenericUFH } from "../../catalog/manufacturers/generic/generic-ufh";
import { FloorFinish } from "../../catalog/types";
import { StandardFlowSystemUids } from "../../config";
import { UnderfloorHeatingFlowSystem } from "../flow-systems";

export const UNDERFLOOR_FLOW_SYSTEMS = (): UnderfloorHeatingFlowSystem[] => {
  return [
    {
      type: "underfloor",
      role: "underfloor-heating",
      uid: StandardFlowSystemUids.UnderfloorHeating,
      color: {
        hex: "#9F0500",
      },
      defaultPipeHeightM: 0,
      fluid: "water",
      name: "Underfloor Heating",
      networks: {
        pipes: {},
      },
      temperatureC: 40,
      floorFinish: betterObjectKeys<FloorFinish>(GenericUFH)[0],
      pipeDiameterMM: 16,
      maxLoopLengthM: 100,
      maxPipeSpacingMM: 200,
      pipeMaterial: "pexSdr74",
      rollLengthsM: [80, 100, 150, 200],
      loopShape: "serpentine",
      spiralChirality: "clockwise",
      minBendRadiusMM: 16 * 5,
      loopDirectionDEG: 0,
      returnTemperatureC: 35,
      transitSpacingMM: UFH_DEFAULT_TRANSIT_SPACING_MM,
      palette: UFH_DEFAULT_COLOR_PALETTE.slice(0, 4),
    },
  ];
};
