import Flatten from "@flatten-js/core";
import { assertType, assertUnreachable, cloneSimple } from "../../lib/utils";
import { GetPressureLossOptions } from "../calculations/entity-pressure-drops";
import {
  fittingFrictionLossMH,
  getFluidDensityOfSystem,
  head2kpa,
} from "../calculations/pressure-drops";
import {
  CoreContext,
  CostBreakdown,
  PressureLossResult,
} from "../calculations/types";
import { findFireSubGroup } from "../calculations/utils";
import { StandardFlowSystemUids, isDrainage, isStormwater } from "../config";
import { NoFlowAvailableReason } from "../document/calculations-objects/conduit-calculations";
import LoadNodeCalculation, {
  LoadNodeLiveCalculation,
} from "../document/calculations-objects/load-node-calculation";
import PipeEntity, {
  isDuctEntity,
  isPipeEntity,
} from "../document/entities/conduit-entity";
import { FittingEntity } from "../document/entities/fitting-entity";
import LoadNodeEntity, {
  NodeType,
  VentilationNode,
  fillDefaultLoadNodeFields,
} from "../document/entities/load-node-entity";
import { EntityType } from "../document/entities/types";
import { CoreConnectable } from "./core-traits/coreConnectable";
import CoreConduit from "./coreConduit";
import { CoreCalculatableObject } from "./lib/CoreCalculatableObject";
import { SelectionTarget } from "./lib/types";
import {
  determineConnectableSystemUid,
  getGrillNameByFlowsystem,
  getIdentityCalculationEntityUid,
} from "./utils";

export default class CoreLoadNode extends CoreConnectable(
  CoreCalculatableObject<LoadNodeEntity>,
) {
  type: EntityType.LOAD_NODE = EntityType.LOAD_NODE;

  get refPath(): string {
    return `${this.entity.type}.${this.entity.node.type}`;
  }

  get filledEntity(): LoadNodeEntity {
    return fillDefaultLoadNodeFields(this.context, this.entity);
  }

  getComponentPressureLossKPA(
    options?: GetPressureLossOptions,
  ): PressureLossResult {
    switch (this.entity.node.type) {
      case NodeType.FIRE:
        return this.getFireLoadNodePressureLossKPA();
      case NodeType.VENTILATION:
        return this.getVentLoadNodePressureLossKPA();
      case NodeType.DWELLING:
      case NodeType.LOAD_NODE:
        return { pressureLossKPA: null };
    }

    assertUnreachable(this.entity.node);
  }

  getVentLoadNodePressureLossKPA(): PressureLossResult {
    const velocityPressureKPA = this.getVentVelocityPressureKPA();

    if (velocityPressureKPA) {
      const filled = fillDefaultLoadNodeFields(this.context, this.entity);

      if (filled.node.type === NodeType.VENTILATION) {
        switch (filled.node.pressureDropMethod) {
          case "zeta":
            return {
              pressureLossKPA: velocityPressureKPA * filled.node.zeta!,
            };
          case "pressure drop":
            if (filled.node.pressureDropPA === null) {
              return { pressureLossKPA: null };
            }
            return {
              pressureLossKPA: filled.node.pressureDropPA / 1000,
            };
          default:
            assertUnreachable(filled.node.pressureDropMethod);
        }
      }
    }

    return { pressureLossKPA: null };
  }

  getFireLoadNodePressureLossKPA(): PressureLossResult {
    if (this.entity.node.type !== NodeType.FIRE) {
      throw new Error("Not a fire load node");
    }

    const calculation = this.globalStore.getOrCreateCalculation(this.entity);

    const ga =
      this.context.drawing.metadata.calculationParams.gravitationalAcceleration;
    let subGroup = findFireSubGroup(
      this.context.drawing,
      this.entity.node.customEntityId,
      this.entity.node.subGroupId,
    );

    // Use own kvValue if possible
    let kvValue = this.entity.node.kvValue ?? (subGroup.kvValue || 0);
    let internalPipeSize = this.globalStore
      .getConnections(this.entity.uid)
      .map((uid) => {
        // return calculation result instead
        const p = this.globalStore.get(uid) as CoreConduit;
        if (!p || !isPipeEntity(p.entity)) {
          throw new Error(
            "A non pipe object is connected to a valve. non-pipe conduits are not implemented yet",
          );
        }

        const pipeCalc = this.globalStore.getCalculation(p.entity);
        if (!pipeCalc || pipeCalc.realInternalDiameterMM === null) {
          return null;
        } else {
          return pipeCalc.realInternalDiameterMM;
        }
      })
      .filter((u) => u !== null) as number[];
    const volLM = (Math.max(...internalPipeSize) ** 2 * Math.PI) / 4 / 1000;
    const velocityMS = (calculation.flowRateLS ?? 0) / volLM;

    // TODO: actually understand what the hack is going on here
    const pressureLossKPA = head2kpa(
      fittingFrictionLossMH(velocityMS, kvValue, ga),
      getFluidDensityOfSystem(
        this.context,
        determineConnectableSystemUid(this.globalStore, this.entity)!,
      )!,
      ga,
    );

    return { pressureLossKPA };
  }

  getCalculationEntities(
    context: CoreContext,
  ): [LoadNodeEntity, ...Array<PipeEntity | FittingEntity>] {
    const filled = fillDefaultLoadNodeFields(context, this.entity);
    const tower = this.getCalculationTower(context);
    const proj = cloneSimple(this.entity);
    proj.parentUid = getIdentityCalculationEntityUid(context, proj.parentUid);

    if (tower.length === 0) {
      proj.uid = proj.uid + ".0";
      proj.calculationHeightM = 0;
      return [proj];
    }

    const flatTower = tower.flat();
    let diff = Infinity;
    let loadIndex = 0;

    // find the first pressure connection if it exists
    flatTower.forEach((item, index) => {
      if (item.type === EntityType.FITTING) {
        if (filled.node.type === NodeType.VENTILATION) {
          const d = Math.abs(
            Number(item.calculationHeightM) -
              Number(filled.node.heightAboveFloorM),
          );
          if (d < diff) {
            diff = d;
            loadIndex = index;
          }
        } else if (
          !isDrainage(context.drawing.metadata.flowSystems[item.systemUid])
        ) {
          loadIndex = index;
        }
      }
    });

    // move the load node to the pressure connection if it exists
    const chosenFitting = flatTower[loadIndex] as FittingEntity;
    proj.center = chosenFitting.center;
    proj.parentUid = chosenFitting.parentUid;
    proj.uid = chosenFitting.uid;
    proj.calculationHeightM = chosenFitting.calculationHeightM;

    // we have to set this to the original (canonical) so that we don't have to change children.
    proj.linkedToUid = proj.linkedToUid || this.entity.uid;

    // remove the chosen fitting from the tower
    flatTower.splice(loadIndex, 1);
    return [proj, ...flatTower];
  }

  collectCalculations(context: CoreContext): LoadNodeCalculation {
    let loadNodeCalc = context.globalStore.getOrCreateCalculation(
      this.getCalculationEntities(context)[0],
    );
    return loadNodeCalc;
  }

  collectLiveCalculations(context: CoreContext): LoadNodeLiveCalculation {
    const loadNode = this.getCalculationEntities(context)[0];
    let liveCalc = cloneSimple(
      context.globalStore.getOrCreateLiveCalculation(loadNode),
    );
    const fullCalc = context.globalStore.getOrCreateCalculation(loadNode);
    liveCalc.flowRateLS = fullCalc.flowRateLS;
    return liveCalc;
  }

  costBreakdown(context: CoreContext): CostBreakdown | null {
    const filled = fillDefaultLoadNodeFields(context, this.entity);

    switch (filled.node.type) {
      case NodeType.LOAD_NODE:
        switch (filled.systemUidOption) {
          case StandardFlowSystemUids.HotWater:
            return {
              cost: context.priceTable.Node["Load Node - Hot"],
              breakdown: [
                {
                  qty: 1,
                  path: `Node.Load Node - Hot`,
                  type: "loadNode",
                  customNodeId: filled.name ? filled.name : "",
                },
              ],
            };
          case StandardFlowSystemUids.ColdWater:
            return {
              cost: context.priceTable.Node["Load Node - Cold"],
              breakdown: [
                {
                  qty: 1,
                  path: `Node.Load Node - Cold`,
                  type: "loadNode",
                  customNodeId: filled.name ? filled.name : "",
                },
              ],
            };
          default:
            return {
              cost: context.priceTable.Node["Load Node - Other"],
              breakdown: [
                {
                  qty: 1,
                  path: `Node.Load Node - Other`,
                  type: "loadNode",
                  customNodeId: filled.name ? filled.name : "",
                },
              ],
            };
        }
      case NodeType.DWELLING:
        switch (filled.systemUidOption) {
          case StandardFlowSystemUids.HotWater:
            return {
              cost: context.priceTable.Node["Dwelling Node - Hot"],
              breakdown: [
                {
                  qty: 1,
                  path: `Node.Dwelling Node - Hot`,
                  type: "loadNode",
                  customNodeId: filled.name ? filled.name : "",
                },
              ],
            };
          case StandardFlowSystemUids.ColdWater:
            return {
              cost: context.priceTable.Node["Dwelling Node - Cold"],
              breakdown: [
                {
                  qty: 1,
                  path: `Node.Dwelling Node - Cold`,
                  type: "loadNode",
                  customNodeId: filled.name ? filled.name : "",
                },
              ],
            };
          default:
            return {
              cost: context.priceTable.Node["Dwelling Node - Other"],
              breakdown: [
                {
                  qty: 1,
                  path: `Node.Dwelling Node - Other`,
                  type: "loadNode",
                  customNodeId: filled.name ? filled.name : "",
                },
              ],
            };
        }
      case NodeType.FIRE: {
        return {
          cost: context.priceTable.Node["Fire Node - Cold"],
          breakdown: [
            {
              qty: 1,
              path: `Node.Fire Node - Cold`,
              type: "loadNode",
              customNodeId: filled.name ? filled.name : "",
            },
          ],
        };
      }
      case NodeType.VENTILATION: {
        if (!filled.systemUidOption) {
          return null;
        }

        const fsRole =
          context.drawing.metadata.flowSystems[filled.systemUidOption].role;

        switch (fsRole) {
          case "vent-supply":
            return {
              cost: context.priceTable.Node["Diffuser"],
              breakdown: [
                {
                  qty: 1,
                  path: `Node.Diffuser`,
                  type: "loadNode",
                  customNodeId: filled.name ? filled.name : "",
                },
              ],
            };
          case "vent-extract":
            return {
              cost: context.priceTable.Node["Grill"],
              breakdown: [
                {
                  qty: 1,
                  path: `Node.Grill`,
                  type: "loadNode",
                  customNodeId: filled.name ? filled.name : "",
                },
              ],
            };
          case "vent-exhaust":
            return {
              cost: context.priceTable.Node["Exhaust"],
              breakdown: [
                {
                  qty: 1,
                  path: `Node.Exhaust`,
                  type: "loadNode",
                  customNodeId: filled.name ? filled.name : "",
                },
              ],
            };
          case "vent-intake":
            return {
              cost: context.priceTable.Node["Intake"],
              breakdown: [
                {
                  qty: 1,
                  path: `Node.Intake`,
                  type: "loadNode",
                  customNodeId: filled.name ? filled.name : "",
                },
              ],
            };
          case "vent-fan-exhaust":
            return {
              cost: context.priceTable.Node["Fan Exhaust"],
              breakdown: [
                {
                  qty: 1,
                  path: `Node.Fan Exhaust`,
                  type: "loadNode",
                  customNodeId: filled.name ? filled.name : "",
                },
              ],
            };
          default:
            throw Error("Unknown Ventilation Node Type for Bill of Material");
        }
      }
    }
    assertUnreachable(filled.node);
  }
  getCalculationUid(context: CoreContext): string {
    return this.getCalculationEntities(context)[0]!.uid;
  }
  preCalculationValidation(context: CoreContext): SelectionTarget | null {
    return null;
  }

  validateConnectionPoints(): boolean {
    const connections = this.globalStore.getConnections(this.entity.uid);
    if (!connections.length) {
      return false;
    }
    for (const conn of connections) {
      const pipe = this.globalStore.getObjectOfTypeOrThrow(
        EntityType.CONDUIT,
        conn,
      );
      if (isPipeEntity(pipe.entity)) {
        const calc = this.globalStore.getCalculation(pipe.entity)!;
        if (calc?.noFlowAvailableReason === NoFlowAvailableReason.NO_SOURCE) {
          return false;
        }
      } else if (isDuctEntity(pipe.entity)) {
        // TODO duct: detect no flow available
      } else {
        throw new Error("Load node connected to non-pipe not implemented");
      }
    }
    return true;
  }

  getImplicitCalculationConnections() {
    if (this.entity.node.type === NodeType.VENTILATION) {
      return [this.uid];
    }
    return super.getImplicitCalculationConnections();
  }

  get baseRadius() {
    if (this.entity.node.type === NodeType.DWELLING) {
      return 200;
    } else if (this.entity.node.type === NodeType.VENTILATION) {
      const filled = fillDefaultLoadNodeFields(this.context, this.entity);
      assertType<VentilationNode>(filled.node);
      return (
        Math.max(
          filled.node.widthMM!,
          filled.node.heightMM!,
          filled.node.diameterMM!,
        ) / 2
      );
    } else {
      return 150;
    }
  }

  get widthMM() {
    const filled = fillDefaultLoadNodeFields(this.context, this.entity);
    switch (filled.node.type) {
      case NodeType.DWELLING:
        return 400;
      case NodeType.VENTILATION:
        if (filled.node.shape === "circ") {
          return filled.node.diameterMM!;
        }
        return filled.node.widthMM!;
      case NodeType.FIRE:
      case NodeType.LOAD_NODE:
        return 300;
    }
    assertUnreachable(filled.node);
  }

  get heightMM() {
    const filled = fillDefaultLoadNodeFields(this.context, this.entity);
    switch (filled.node.type) {
      case NodeType.DWELLING:
        return 400;
      case NodeType.VENTILATION:
        if (filled.node.shape === "circ") {
          if (filled.node.orientation === "vertical") {
            return filled.node.diameterMM! / 2;
          }
          return filled.node.diameterMM!;
        }
        return filled.node.heightMM!;
      case NodeType.FIRE:
      case NodeType.LOAD_NODE:
        return 300;
    }
    assertUnreachable(filled.node);
  }

  // @ts-ignore force override
  get shape(): Flatten.Circle {
    const wc = this.toWorldCoord();
    return Flatten.circle(Flatten.point(wc.x, wc.y), this.baseRadius);
  }

  // This is to get Ventilation grilles names as it differs by flowsystem
  get computedName(): string | null | undefined {
    const systemUid = determineConnectableSystemUid(
      this.globalStore,
      this.entity,
    );
    if (!systemUid) {
      return null;
    }

    const fs = this.drawing.metadata.flowSystems[systemUid];

    if (fs && isStormwater(fs)) {
      return "Stormwater Node";
    }

    switch (this.entity.node.type) {
      case NodeType.LOAD_NODE:
        return this.nameIfAvailable ?? "Load Node";
      case NodeType.DWELLING:
        return this.nameIfAvailable ?? "Dwelling Node";
      case NodeType.FIRE:
        return this.nameIfAvailable ?? "Fire Node";
      case NodeType.VENTILATION:
        const specialGrillName = getGrillNameByFlowsystem(
          fs,
          this.entity.node.flowDirection,
        );
        return this.entity.name
          ? `${this.entity.name} (${specialGrillName})`
          : specialGrillName;
    }

    assertUnreachable(this.entity.node);
  }

  get nameIfAvailable(): string | null {
    const name = this.entity.name;
    if (name && name !== "") {
      return name;
    }

    return null;
  }
}
