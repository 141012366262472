import { StandardFlowSystemUids } from "../../config";
import { VentilationFlowSystem } from "../flow-systems";

export const VENTILATION_SYSTEM = (): VentilationFlowSystem[] => [
  {
    name: "Supply",
    type: "ventilation",
    role: "vent-supply",
    temperatureC: 20,
    color: { hex: "#2c6ae6" },
    uid: StandardFlowSystemUids.VentilationSupply,
    fluid: "air",
    defaultPipeHeightM: 2.4,

    networks: {
      risers: {
        spareCapacityPCT: 0,
        velocityMS: 4,
        pressureDropKPAM: 0.001,
        material: "carbonSteel",
        minimumDuctDiameterSizeMM: 50,
        minimumDuctWidthSizeMM: 50,
        minimumDuctHeightSizeMM: 50,

        sizingMode: "discrete",
        sizingIncrementMM: 50,
        shape: "circular",
        rectangleWHRatio: 2,
        smoothElbowRadiusRatio: 1.5,

        transitionAngle: 45,

        circularElbow: "square",
        rectangularElbow: "square",
        elbowVanes: 1,
        pieces: 3,

        rectRectTee: "square",
        rectCircTee: "square",
        circCircTee: "square",
        shoeLengthRatio: 0.5,

        circSymmetrical: "y-piece",
        rectSymmetrical: "square",
      },
      mains: {
        spareCapacityPCT: 0,
        velocityMS: 4,
        pressureDropKPAM: 0.001,
        material: "carbonSteel",
        minimumDuctDiameterSizeMM: 50,
        minimumDuctWidthSizeMM: 50,
        minimumDuctHeightSizeMM: 50,

        sizingMode: "discrete",
        sizingIncrementMM: 50,
        shape: "circular",
        rectangleWHRatio: 2,
        smoothElbowRadiusRatio: 1.5,

        transitionAngle: 45,

        circularElbow: "square",
        rectangularElbow: "square",
        elbowVanes: 1,
        pieces: 3,

        rectRectTee: "square",
        rectCircTee: "square",
        circCircTee: "square",
        shoeLengthRatio: 0.5,

        circSymmetrical: "y-piece",
        rectSymmetrical: "square",
      },
      branches: {
        spareCapacityPCT: 0,
        velocityMS: 4,
        pressureDropKPAM: 0.001,
        material: "flexibleDuct",
        minimumDuctDiameterSizeMM: 50,
        minimumDuctWidthSizeMM: 50,
        minimumDuctHeightSizeMM: 50,
        shape: "rectangular",
        rectangleWHRatio: 2,
        smoothElbowRadiusRatio: 1.5,

        sizingMode: "discrete",
        sizingIncrementMM: 50,

        transitionAngle: 45,

        circularElbow: "square",
        rectangularElbow: "square",
        elbowVanes: 1,
        pieces: 3,

        rectRectTee: "square",
        rectCircTee: "square",
        circCircTee: "square",
        shoeLengthRatio: 0.5,

        circSymmetrical: "y-piece",
        rectSymmetrical: "square",
      },
      connections: {
        spareCapacityPCT: 0,
        velocityMS: 4,
        pressureDropKPAM: 0.001,
        material: "flexibleDuct",
        minimumDuctDiameterSizeMM: 50,
        minimumDuctWidthSizeMM: 50,
        minimumDuctHeightSizeMM: 50,
        shape: "circular",
        rectangleWHRatio: 2,
        smoothElbowRadiusRatio: 1.5,

        sizingMode: "discrete",
        sizingIncrementMM: 50,

        transitionAngle: 45,

        circularElbow: "square",
        rectangularElbow: "square",
        elbowVanes: 1,
        pieces: 3,

        rectRectTee: "square",
        rectCircTee: "square",
        circCircTee: "square",
        shoeLengthRatio: 0.5,

        circSymmetrical: "y-piece",
        rectSymmetrical: "square",
      },
    },
  },
  {
    name: "Extract",
    type: "ventilation",
    role: "vent-extract",
    temperatureC: 20,
    color: { hex: "#d94c4e" },
    uid: StandardFlowSystemUids.VentilationExtract,
    fluid: "air",
    defaultPipeHeightM: 2.4,

    networks: {
      risers: {
        spareCapacityPCT: 0,
        velocityMS: 4,
        pressureDropKPAM: 0.001,
        material: "carbonSteel",
        minimumDuctDiameterSizeMM: 50,
        minimumDuctWidthSizeMM: 50,
        minimumDuctHeightSizeMM: 50,

        sizingMode: "discrete",
        sizingIncrementMM: 50,
        shape: "circular",
        rectangleWHRatio: 2,
        smoothElbowRadiusRatio: 1.5,

        transitionAngle: 45,

        circularElbow: "square",
        rectangularElbow: "square",
        elbowVanes: 1,
        pieces: 3,

        rectRectTee: "square",
        rectCircTee: "square",
        circCircTee: "square",
        shoeLengthRatio: 0.5,

        circSymmetrical: "y-piece",
        rectSymmetrical: "square",
      },
      mains: {
        spareCapacityPCT: 0,
        velocityMS: 4,
        pressureDropKPAM: 0.001,
        material: "carbonSteel",
        minimumDuctDiameterSizeMM: 50,
        minimumDuctWidthSizeMM: 50,
        minimumDuctHeightSizeMM: 50,
        shape: "circular",
        rectangleWHRatio: 2,
        smoothElbowRadiusRatio: 1.5,

        sizingMode: "discrete",
        sizingIncrementMM: 50,

        transitionAngle: 45,

        circularElbow: "square",
        rectangularElbow: "square",
        elbowVanes: 1,
        pieces: 3,

        rectRectTee: "square",
        rectCircTee: "square",
        circCircTee: "square",
        shoeLengthRatio: 0.5,

        circSymmetrical: "y-piece",
        rectSymmetrical: "square",
      },
      branches: {
        spareCapacityPCT: 0,
        velocityMS: 4,
        pressureDropKPAM: 0.001,
        material: "flexibleDuct",
        minimumDuctDiameterSizeMM: 50,
        minimumDuctWidthSizeMM: 50,
        minimumDuctHeightSizeMM: 50,
        shape: "rectangular",
        rectangleWHRatio: 2,
        smoothElbowRadiusRatio: 1.5,

        sizingMode: "discrete",
        sizingIncrementMM: 50,

        transitionAngle: 45,

        circularElbow: "square",
        rectangularElbow: "square",
        elbowVanes: 1,
        pieces: 3,

        rectRectTee: "square",
        rectCircTee: "square",
        circCircTee: "square",
        shoeLengthRatio: 0.5,

        circSymmetrical: "y-piece",
        rectSymmetrical: "square",
      },
      connections: {
        spareCapacityPCT: 0,
        velocityMS: 4,
        pressureDropKPAM: 0.001,
        material: "flexibleDuct",
        minimumDuctDiameterSizeMM: 50,
        minimumDuctWidthSizeMM: 50,
        minimumDuctHeightSizeMM: 50,

        sizingMode: "discrete",
        sizingIncrementMM: 50,
        shape: "circular",
        rectangleWHRatio: 2,
        smoothElbowRadiusRatio: 1.5,

        transitionAngle: 45,

        circularElbow: "square",
        rectangularElbow: "square",
        elbowVanes: 1,
        pieces: 3,

        rectRectTee: "square",
        rectCircTee: "square",
        circCircTee: "square",
        shoeLengthRatio: 0.5,

        circSymmetrical: "y-piece",
        rectSymmetrical: "square",
      },
    },
  },
  {
    name: "Intake",
    type: "ventilation",
    role: "vent-intake",
    temperatureC: 20,
    color: { hex: "#68BC00" },
    uid: StandardFlowSystemUids.VentilationIntake,
    fluid: "air",
    defaultPipeHeightM: 2.4,

    networks: {
      risers: {
        spareCapacityPCT: 0,
        velocityMS: 4,
        pressureDropKPAM: 0.001,
        material: "carbonSteel",
        minimumDuctDiameterSizeMM: 50,
        minimumDuctWidthSizeMM: 50,
        minimumDuctHeightSizeMM: 50,

        sizingMode: "discrete",
        sizingIncrementMM: 50,
        shape: "circular",
        rectangleWHRatio: 2,
        smoothElbowRadiusRatio: 1.5,

        transitionAngle: 45,

        circularElbow: "square",
        rectangularElbow: "square",
        elbowVanes: 1,
        pieces: 3,

        rectRectTee: "square",
        rectCircTee: "square",
        circCircTee: "square",
        shoeLengthRatio: 0.5,

        circSymmetrical: "y-piece",
        rectSymmetrical: "square",
      },
      mains: {
        spareCapacityPCT: 0,
        velocityMS: 4,
        pressureDropKPAM: 0.001,
        material: "carbonSteel",
        minimumDuctDiameterSizeMM: 50,
        minimumDuctWidthSizeMM: 50,
        minimumDuctHeightSizeMM: 50,

        sizingMode: "discrete",
        sizingIncrementMM: 50,
        shape: "circular",
        rectangleWHRatio: 2,
        smoothElbowRadiusRatio: 1.5,

        transitionAngle: 45,

        circularElbow: "square",
        rectangularElbow: "square",
        elbowVanes: 1,
        pieces: 3,

        rectRectTee: "square",
        rectCircTee: "square",
        circCircTee: "square",
        shoeLengthRatio: 0.5,

        circSymmetrical: "y-piece",
        rectSymmetrical: "square",
      },
      branches: {
        spareCapacityPCT: 0,
        velocityMS: 4,
        pressureDropKPAM: 0.001,
        material: "flexibleDuct",
        minimumDuctDiameterSizeMM: 50,
        minimumDuctWidthSizeMM: 50,
        minimumDuctHeightSizeMM: 50,
        shape: "rectangular",
        rectangleWHRatio: 2,
        smoothElbowRadiusRatio: 1.5,

        sizingMode: "discrete",
        sizingIncrementMM: 50,

        transitionAngle: 45,

        circularElbow: "square",
        rectangularElbow: "square",
        elbowVanes: 1,
        pieces: 3,

        rectRectTee: "square",
        rectCircTee: "square",
        circCircTee: "square",
        shoeLengthRatio: 0.5,

        circSymmetrical: "y-piece",
        rectSymmetrical: "square",
      },
      connections: {
        spareCapacityPCT: 0,
        velocityMS: 4,
        pressureDropKPAM: 0.001,
        material: "flexibleDuct",
        minimumDuctDiameterSizeMM: 50,
        minimumDuctWidthSizeMM: 50,
        minimumDuctHeightSizeMM: 50,

        sizingMode: "discrete",
        sizingIncrementMM: 50,
        shape: "circular",
        rectangleWHRatio: 2,
        smoothElbowRadiusRatio: 1.5,

        transitionAngle: 45,

        circularElbow: "square",
        rectangularElbow: "square",
        elbowVanes: 1,
        pieces: 3,

        rectRectTee: "square",
        rectCircTee: "square",
        circCircTee: "square",
        shoeLengthRatio: 0.5,

        circSymmetrical: "y-piece",
        rectSymmetrical: "square",
      },
    },
  },
  {
    name: "Exhaust",
    type: "ventilation",
    role: "vent-exhaust",
    temperatureC: 20,
    color: { hex: "#666665" },
    uid: StandardFlowSystemUids.VentilationExhaust,
    fluid: "air",
    defaultPipeHeightM: 2.4,

    networks: {
      risers: {
        spareCapacityPCT: 0,
        velocityMS: 4,
        pressureDropKPAM: 0.001,
        material: "carbonSteel",
        minimumDuctDiameterSizeMM: 50,
        minimumDuctWidthSizeMM: 50,
        minimumDuctHeightSizeMM: 50,

        sizingMode: "discrete",
        sizingIncrementMM: 50,
        shape: "circular",
        rectangleWHRatio: 2,
        smoothElbowRadiusRatio: 1.5,

        transitionAngle: 45,

        circularElbow: "square",
        rectangularElbow: "square",
        elbowVanes: 1,
        pieces: 3,

        rectRectTee: "square",
        rectCircTee: "square",
        circCircTee: "square",
        shoeLengthRatio: 0.5,

        circSymmetrical: "y-piece",
        rectSymmetrical: "square",
      },
      mains: {
        spareCapacityPCT: 0,
        velocityMS: 4,
        pressureDropKPAM: 0.001,
        material: "carbonSteel",
        minimumDuctDiameterSizeMM: 50,
        minimumDuctWidthSizeMM: 50,
        minimumDuctHeightSizeMM: 50,
        shape: "circular",
        rectangleWHRatio: 2,
        smoothElbowRadiusRatio: 1.5,

        sizingMode: "discrete",
        sizingIncrementMM: 50,

        transitionAngle: 45,

        circularElbow: "square",
        rectangularElbow: "square",
        elbowVanes: 1,
        pieces: 3,

        rectRectTee: "square",
        rectCircTee: "square",
        circCircTee: "square",
        shoeLengthRatio: 0.5,

        circSymmetrical: "y-piece",
        rectSymmetrical: "square",
      },
      branches: {
        spareCapacityPCT: 0,
        velocityMS: 4,
        pressureDropKPAM: 0.001,
        material: "flexibleDuct",
        minimumDuctDiameterSizeMM: 50,
        minimumDuctWidthSizeMM: 50,
        minimumDuctHeightSizeMM: 50,
        shape: "rectangular",
        rectangleWHRatio: 2,
        smoothElbowRadiusRatio: 1.5,

        sizingMode: "discrete",
        sizingIncrementMM: 50,

        transitionAngle: 45,

        circularElbow: "square",
        rectangularElbow: "square",
        elbowVanes: 1,
        pieces: 3,

        rectRectTee: "square",
        rectCircTee: "square",
        circCircTee: "square",
        shoeLengthRatio: 0.5,

        circSymmetrical: "y-piece",
        rectSymmetrical: "square",
      },
      connections: {
        spareCapacityPCT: 0,
        velocityMS: 4,
        pressureDropKPAM: 0.001,
        material: "flexibleDuct",
        minimumDuctDiameterSizeMM: 50,
        minimumDuctWidthSizeMM: 50,
        minimumDuctHeightSizeMM: 50,

        sizingMode: "discrete",
        sizingIncrementMM: 50,
        shape: "circular",
        rectangleWHRatio: 2,
        smoothElbowRadiusRatio: 1.5,

        transitionAngle: 45,

        circularElbow: "square",
        rectangularElbow: "square",
        elbowVanes: 1,
        pieces: 3,

        rectRectTee: "square",
        rectCircTee: "square",
        circCircTee: "square",
        shoeLengthRatio: 0.5,

        circSymmetrical: "y-piece",
        rectSymmetrical: "square",
      },
    },
  },
  {
    name: "Fan Exhaust",
    type: "ventilation",
    role: "vent-fan-exhaust",
    temperatureC: 20,
    color: { hex: "#BC974F" },
    uid: StandardFlowSystemUids.VentilationFanExhaust,
    fluid: "air",
    defaultPipeHeightM: 2.4,

    networks: {
      risers: {
        spareCapacityPCT: 0,
        velocityMS: 4,
        pressureDropKPAM: 0.001,
        material: "carbonSteel",
        minimumDuctDiameterSizeMM: 50,
        minimumDuctWidthSizeMM: 50,
        minimumDuctHeightSizeMM: 50,

        sizingMode: "discrete",
        sizingIncrementMM: 50,
        shape: "circular",
        rectangleWHRatio: 2,
        smoothElbowRadiusRatio: 1.5,

        transitionAngle: 45,

        circularElbow: "square",
        rectangularElbow: "square",
        elbowVanes: 1,
        pieces: 3,

        rectRectTee: "square",
        rectCircTee: "square",
        circCircTee: "square",
        shoeLengthRatio: 0.5,

        circSymmetrical: "y-piece",
        rectSymmetrical: "square",
      },
      mains: {
        spareCapacityPCT: 0,
        velocityMS: 4,
        pressureDropKPAM: 0.001,
        material: "carbonSteel",
        minimumDuctDiameterSizeMM: 50,
        minimumDuctWidthSizeMM: 50,
        minimumDuctHeightSizeMM: 50,
        shape: "circular",
        rectangleWHRatio: 2,
        smoothElbowRadiusRatio: 1.5,

        sizingMode: "discrete",
        sizingIncrementMM: 50,

        transitionAngle: 45,

        circularElbow: "square",
        rectangularElbow: "square",
        elbowVanes: 1,
        pieces: 3,

        rectRectTee: "square",
        rectCircTee: "square",
        circCircTee: "square",
        shoeLengthRatio: 0.5,

        circSymmetrical: "y-piece",
        rectSymmetrical: "square",
      },
      branches: {
        spareCapacityPCT: 0,
        velocityMS: 4,
        pressureDropKPAM: 0.001,
        material: "flexibleDuct",
        minimumDuctDiameterSizeMM: 50,
        minimumDuctWidthSizeMM: 50,
        minimumDuctHeightSizeMM: 50,
        shape: "rectangular",
        rectangleWHRatio: 2,
        smoothElbowRadiusRatio: 1.5,

        sizingMode: "discrete",
        sizingIncrementMM: 50,

        transitionAngle: 45,

        circularElbow: "square",
        rectangularElbow: "square",
        elbowVanes: 1,
        pieces: 3,

        rectRectTee: "square",
        rectCircTee: "square",
        circCircTee: "square",
        shoeLengthRatio: 0.5,

        circSymmetrical: "y-piece",
        rectSymmetrical: "square",
      },
      connections: {
        spareCapacityPCT: 0,
        velocityMS: 4,
        pressureDropKPAM: 0.001,
        material: "flexibleDuct",
        minimumDuctDiameterSizeMM: 50,
        minimumDuctWidthSizeMM: 50,
        minimumDuctHeightSizeMM: 50,

        sizingMode: "discrete",
        sizingIncrementMM: 50,
        shape: "circular",
        rectangleWHRatio: 2,
        smoothElbowRadiusRatio: 1.5,

        transitionAngle: 45,

        circularElbow: "square",
        rectangularElbow: "square",
        elbowVanes: 1,
        pieces: 3,

        rectRectTee: "square",
        rectCircTee: "square",
        circCircTee: "square",
        shoeLengthRatio: 0.5,

        circSymmetrical: "y-piece",
        rectSymmetrical: "square",
      },
    },
  },
];
