import { cloneDeep } from "lodash";
import { Currency, CurrencySymbol } from "../../lib/currency";
import {
  AreaMeasurementSystem,
  FlowRateMeasurementSystem,
  GasEnergyMeasurementSystem,
  MeasurementSystem,
  MechanicalEnergyMeasurementSystem,
  PressureDropMeasurementSystem,
  PressureMeasurementSystem,
  RainfallMeasurementSystem,
  VelocityMeasurementSystem,
  VolumeMeasurementSystem,
  f2C,
  ft2M,
  in2MM,
  psi2KPA,
} from "../../lib/measurements";
import { assertUnreachable, cloneSimple } from "../../lib/utils";
import {
  DualSystemMechanicalNodeProps,
  MechanicalNodeProps,
} from "../../models/CustomEntity";
import {
  ExistingHeatingSystem,
  HeatingSystemAgeType,
  HeatingSystemType,
} from "../calculations/heatloss/heat-loss-result-type";
import {
  ComponentPressureLossMethod,
  DUCT_SIZING_METHODS,
  EN12056FrequencyFactor,
  InsulationMaterials,
  PIPE_SIZING_METHODS,
  RingMainCalculationMethod,
  StandardFlowSystemUids,
  SupportedDistirctHeating,
  SupportedDrainageMethods,
  SupportedGasCalculationMethods,
  SupportedPsdStandards,
  isDrainage,
  isHeating,
  isVentilation,
} from "../config";
import { DEFAULT_LINES } from "../linetypes";
import { SupportedLocales } from "../locale";
import {
  AHU_CAPACITY_LKW,
  AHU_DEFAULT_EXTRACT_PRESSURE_DROP_PA,
  AHU_DEFAULT_SUPPLY_PRESSURE_DROP_PA,
  DEFAULT_BASE_TEMPERATURE_C,
  DEFAULT_COOLING_DEGREE_DAYS,
  DEFAULT_EPC_INFORMATION,
  DEFAULT_HEATING_DEGREE_DAYS,
  DEFAULT_ROOMS_BELOW_TRANSPARENCY_PCT,
  DEFAULT_SCOP,
  DEFAULT_SPARE_HEAT_CAP,
  DEFAULT_SUMMER_PARTY_TEMPERATURE_C,
  DEFAULT_SUMMER_TEMP_CUTOFF_PCT_THRESHOLD,
  DEFAULT_WINTER_PARTY_TEMPERATURE_C,
  DEFAULT_WINTER_TEMP_CUTOFF_PCT_THRESHOLD,
  EXTERNAL_WALL_WIDTH_MM,
  INTERNAL_WALL_WIDTH_MM,
  PARTY_WALL_WIDTH_MM,
  RADIATOR_CAPACITY_LKW,
} from "./consts";
import {
  AHU_HEIGHT_ABOVE_FLOOR_MM_US,
  DrawingState,
  DuctSizingMethod,
  PipeSizingMethod,
  RoomResultsSettings,
} from "./drawing";
import { DoorType, WindowType } from "./entities/fenestration-entity";
import { isDualSystemNode } from "./entities/plants/utils";
import { INITIAL_ROOF_SPEC, RoofType } from "./entities/rooms/roof-type";
import { INITIAL_INTERNAL_HEATSOURCE } from "./entities/rooms/room-type";
import {
  FireFlowSystem,
  GasFlowSystem,
  MechanicalFlowSystem,
  PressureFlowSystem,
  SewerFlowSystem,
  UnderfloorHeatingFlowSystem,
} from "./flow-systems";
import { FlowSystemsMetadata } from "./flow-systems/flow-systems-metadata";
import { initialFlowSystems } from "./flow-systems/initial-flow-systems";
import { DEFAULT_WORKFLOWS } from "./initial-workflows";

export const DEFAULT_ROOM_RESULTS_SETTINGS: RoomResultsSettings = {
  roomName: true,
  roomTemperature: true,
  roomHeight: true,
  roomArea: true,
  roomVolume: true,
  roomPerimeter: true,
  heatLoss: true,
  heatSupplied: true,
  heatLoadPerArea: true,
  heatGain: true,
  coolingSupplied: true,
  ventAirChanges: true,
  heatingAirChanges: false,
  underfloorHeating: true,
  unheatedArea: false,
};

export const DEFAULT_EXISTING_HEATING_SYSTEM: ExistingHeatingSystem = {
  fuelHeatingSystemType: HeatingSystemType.GAS,
  hotWaterHeatingSystemType: HeatingSystemType.GAS,
  heatingSystemAge: HeatingSystemAgeType["1994_2007"],
};

export function INITIAL_VENT_AHU_NODES(): DualSystemMechanicalNodeProps[] {
  return [
    {
      name: "AHU (Indoor)",
      depthMM: 600,
      widthMM: 1000,
      heightMM: 1000,

      hasIntakeIO: true,
      hasExhaustIO: false,
      hasExtractIO: false,
      hasHeatingIO: false,
      hasChilledIO: false,

      supplyPressureDropPA: AHU_DEFAULT_SUPPLY_PRESSURE_DROP_PA,
      extractPressureDropPA: AHU_DEFAULT_EXTRACT_PRESSURE_DROP_PA,

      supplyHeightAboveFloorMM: 1000,
      extractHeightAboveFloorMM: 1000,

      heatingRatingKw: 0,
      heatingPressureDropKPA: 6,
      heatingHeightAboveFloorMM: 1000,
      heatingCapacityRateLKW: AHU_CAPACITY_LKW,

      chilledRatingKw: 0,
      chilledPressureDropKPA: 6,
      chilledHeightAboveFloorMM: 1000,
      chilledCapacityRateLKW: AHU_CAPACITY_LKW,
      type: "air-handling-vent",
    },
    {
      name: "AHU (Outdoor)",
      depthMM: 600,
      widthMM: 1000,
      heightMM: 1000,

      hasIntakeIO: false,
      hasExhaustIO: false,
      hasExtractIO: false,
      hasHeatingIO: false,
      hasChilledIO: false,

      supplyPressureDropPA: AHU_DEFAULT_SUPPLY_PRESSURE_DROP_PA,
      extractPressureDropPA: AHU_DEFAULT_EXTRACT_PRESSURE_DROP_PA,

      supplyHeightAboveFloorMM: 1000,
      extractHeightAboveFloorMM: 1000,

      heatingRatingKw: 0,
      heatingPressureDropKPA: 6,
      heatingHeightAboveFloorMM: 1000,
      heatingCapacityRateLKW: AHU_CAPACITY_LKW,

      chilledRatingKw: 0,
      chilledPressureDropKPA: 6,
      chilledHeightAboveFloorMM: 1000,
      chilledCapacityRateLKW: AHU_CAPACITY_LKW,
      type: "air-handling-vent",
    },
    {
      name: "AHU (Indoor) w/ H/C",
      depthMM: 600,
      widthMM: 1000,
      heightMM: 1000,

      hasIntakeIO: true,
      hasExhaustIO: false,
      hasExtractIO: false,
      hasHeatingIO: true,
      hasChilledIO: true,

      supplyPressureDropPA: AHU_DEFAULT_SUPPLY_PRESSURE_DROP_PA,
      extractPressureDropPA: AHU_DEFAULT_EXTRACT_PRESSURE_DROP_PA,

      supplyHeightAboveFloorMM: 1000,
      extractHeightAboveFloorMM: 1000,

      heatingRatingKw: 0,
      heatingPressureDropKPA: 6,
      heatingHeightAboveFloorMM: 1000,
      heatingCapacityRateLKW: AHU_CAPACITY_LKW,

      chilledRatingKw: 0,
      chilledPressureDropKPA: 6,
      chilledHeightAboveFloorMM: 1000,
      chilledCapacityRateLKW: AHU_CAPACITY_LKW,
      type: "air-handling-vent",
    },
    {
      name: "AHU (Outdoor) w/ H/C",
      depthMM: 600,
      widthMM: 1000,
      heightMM: 1000,

      hasIntakeIO: false,
      hasExhaustIO: false,
      hasExtractIO: false,
      hasHeatingIO: true,
      hasChilledIO: true,

      supplyPressureDropPA: AHU_DEFAULT_SUPPLY_PRESSURE_DROP_PA,
      extractPressureDropPA: AHU_DEFAULT_EXTRACT_PRESSURE_DROP_PA,

      supplyHeightAboveFloorMM: 1000,
      extractHeightAboveFloorMM: 1000,

      heatingRatingKw: 0,
      heatingPressureDropKPA: 6,
      heatingHeightAboveFloorMM: 1000,
      heatingCapacityRateLKW: AHU_CAPACITY_LKW,

      chilledRatingKw: 0,
      chilledPressureDropKPA: 6,
      chilledHeightAboveFloorMM: 1000,
      chilledCapacityRateLKW: AHU_CAPACITY_LKW,
      type: "air-handling-vent",
    },
    {
      name: "AHU (MVHR)",
      depthMM: 600,
      widthMM: 1000,
      heightMM: 1000,

      hasIntakeIO: true,
      hasExhaustIO: true,
      hasExtractIO: true,
      hasHeatingIO: false,
      hasChilledIO: false,

      supplyPressureDropPA: AHU_DEFAULT_SUPPLY_PRESSURE_DROP_PA,
      extractPressureDropPA: AHU_DEFAULT_EXTRACT_PRESSURE_DROP_PA,

      supplyHeightAboveFloorMM: 1000,
      extractHeightAboveFloorMM: 1000,

      heatingRatingKw: 0,
      heatingPressureDropKPA: 6,
      heatingHeightAboveFloorMM: 1000,
      heatingCapacityRateLKW: AHU_CAPACITY_LKW,

      chilledRatingKw: 0,
      chilledPressureDropKPA: 6,
      chilledHeightAboveFloorMM: 1000,
      chilledCapacityRateLKW: AHU_CAPACITY_LKW,
      type: "air-handling-vent",
    },
    {
      name: "AHU (MVHR) w/ H/C",
      depthMM: 600,
      widthMM: 1000,
      heightMM: 1000,

      hasIntakeIO: true,
      hasExhaustIO: true,
      hasExtractIO: true,
      hasHeatingIO: true,
      hasChilledIO: true,

      supplyPressureDropPA: AHU_DEFAULT_SUPPLY_PRESSURE_DROP_PA,
      extractPressureDropPA: AHU_DEFAULT_EXTRACT_PRESSURE_DROP_PA,

      supplyHeightAboveFloorMM: 1000,
      extractHeightAboveFloorMM: 1000,

      heatingRatingKw: 0,
      heatingPressureDropKPA: 6,
      heatingHeightAboveFloorMM: 1000,
      heatingCapacityRateLKW: AHU_CAPACITY_LKW,

      chilledRatingKw: 0,
      chilledPressureDropKPA: 6,
      chilledHeightAboveFloorMM: 1000,
      chilledCapacityRateLKW: AHU_CAPACITY_LKW,
      type: "air-handling-vent",
    },
  ];
}

function getInitialMechanicalNodeInOrder(): MechanicalNodeProps[] {
  let nodes: MechanicalNodeProps[] = [
    {
      name: "Radiator",
      ratingKW: 0.25,
      pressureDropKPA: 5,
      widthMM: 700,
      depthMM: 150,
      heightMM: 1000,
      inletHeightAboveFloorMM: 10,
      outletHeightAboveFloorMM: 10,
      capacityRateLKW: RADIATOR_CAPACITY_LKW,
      type: "radiator",
    },
    {
      name: "Radiator",
      ratingKW: 0.5,
      pressureDropKPA: 5,
      widthMM: 850,
      depthMM: 150,
      heightMM: 1000,
      inletHeightAboveFloorMM: 10,
      outletHeightAboveFloorMM: 10,
      capacityRateLKW: RADIATOR_CAPACITY_LKW,
      type: "radiator",
    },
    {
      name: "Radiator",
      ratingKW: 0.75,
      pressureDropKPA: 6,
      widthMM: 1000,
      depthMM: 150,
      heightMM: 1000,
      inletHeightAboveFloorMM: 10,
      outletHeightAboveFloorMM: 10,
      capacityRateLKW: RADIATOR_CAPACITY_LKW,
      type: "radiator",
    },
    {
      name: "Radiator",
      ratingKW: 1,
      pressureDropKPA: 6,
      widthMM: 1000,
      depthMM: 150,
      heightMM: 1000,
      inletHeightAboveFloorMM: 10,
      outletHeightAboveFloorMM: 10,
      capacityRateLKW: RADIATOR_CAPACITY_LKW,
      type: "radiator",
    },
    {
      name: "Radiator",
      ratingKW: 1.5,
      pressureDropKPA: 8,
      widthMM: 1250,
      depthMM: 150,
      heightMM: 1000,
      inletHeightAboveFloorMM: 10,
      outletHeightAboveFloorMM: 10,
      capacityRateLKW: RADIATOR_CAPACITY_LKW,
      type: "radiator",
    },

    {
      name: "Radiator",
      ratingKW: 2,
      pressureDropKPA: 10,
      widthMM: 1500,
      depthMM: 150,
      heightMM: 1000,
      inletHeightAboveFloorMM: 10,
      outletHeightAboveFloorMM: 10,
      capacityRateLKW: RADIATOR_CAPACITY_LKW,
      type: "radiator",
    },
    {
      name: "Radiator",
      ratingKW: 2.5,
      pressureDropKPA: 12,
      widthMM: 1750,
      depthMM: 150,
      heightMM: 1000,
      inletHeightAboveFloorMM: 10,
      outletHeightAboveFloorMM: 10,
      capacityRateLKW: RADIATOR_CAPACITY_LKW,
      type: "radiator",
    },
    {
      name: "Radiator",
      ratingKW: 3,
      pressureDropKPA: 30,
      widthMM: 1850,
      depthMM: 150,
      heightMM: 1000,
      inletHeightAboveFloorMM: 10,
      outletHeightAboveFloorMM: 10,
      capacityRateLKW: RADIATOR_CAPACITY_LKW,
      type: "radiator",
    },
    {
      name: "Radiator",
      ratingKW: 3.5,
      pressureDropKPA: 35,
      widthMM: 2000,
      depthMM: 150,
      heightMM: 1000,
      inletHeightAboveFloorMM: 10,
      outletHeightAboveFloorMM: 10,
      capacityRateLKW: RADIATOR_CAPACITY_LKW,
      type: "radiator",
    },
    ...INITIAL_VENT_AHU_NODES(),
  ];

  nodes.sort((a, b) => {
    let aRating = isDualSystemNode(a) ? a.heatingRatingKw : a.ratingKW;
    let bRating = isDualSystemNode(b) ? b.heatingRatingKw : b.ratingKW;

    return `${a.name} ${aRating}`.localeCompare(`${b.name} ${bRating}`);
  });
  return nodes;
}

export const INITIAL_MECHANICAL_NODE: MechanicalNodeProps[] =
  getInitialMechanicalNodeInOrder();

export function initialDrawing(
  locale: SupportedLocales,
  isFirstProject: boolean = false,
): DrawingState {
  const result = initialAustralianDrawing();
  switch (locale) {
    case SupportedLocales.AU:
      result.metadata.catalog = {
        ...result.metadata.catalog,
        pipes: [
          { uid: "copperTypeB", manufacturer: "generic", selected: null },
          { uid: "pexSdr74", manufacturer: "generic", selected: null },
          { uid: "stainlessSteel", manufacturer: "generic", selected: null },
          {
            uid: "stainlessSteelDrainage",
            manufacturer: "generic",
            selected: null,
          },
          {
            manufacturer: "allmach",
            uid: "gmsMedium",
            selected: null,
          },
        ],
        mixingValves: [
          { uid: "tmv", manufacturer: "generic", selected: null },
          { uid: "temperingValve", manufacturer: "caleffi", selected: null },
        ],
        greaseInterceptorTrap: [
          {
            uid: "greaseInterceptorTrap",
            manufacturer: "viking",
            selected: null,
          },
        ],
        pump: [
          {
            manufacturer: "generic",
            uid: "pump",
            selected: null,
          },
        ],
      };

      result.metadata.units.gasEnergyMeasurementSystem =
        GasEnergyMeasurementSystem.METRIC;
      result.metadata.units.mechanicalEnergyMeasurementSystem =
        MechanicalEnergyMeasurementSystem.UNIVERSAL;
      result.metadata.heatLoss.electricityCostPerUnit.currency = Currency.AUD;
      result.metadata.heatLoss.ventAirChangesRateStandard = "AS1668.2-2012";
      result.metadata.heatLoss.heatingAirChangesRateStandard = "AS1668.2-2012";
      break;
    case SupportedLocales.UK:
      result.metadata.calculationParams = {
        ...result.metadata.calculationParams,
        psdMethod: SupportedPsdStandards.bs806,
        drainageMethod: SupportedDrainageMethods.EN1205622000DischargeUnits,
        drainageSystem: "drainageSystem3",
        combineLUs: true,
      };
      // 0 index is cold water.
      (
        result.metadata.flowSystems[
          StandardFlowSystemUids.ColdWater
        ] as PressureFlowSystem
      ).temperatureC = 10;
      (
        result.metadata.flowSystems[StandardFlowSystemUids.Gas] as GasFlowSystem
      ).networks.risers.material = "stainlessSteel";
      (
        result.metadata.flowSystems[StandardFlowSystemUids.Gas] as GasFlowSystem
      ).networks.reticulations.material = "stainlessSteel";

      (
        result.metadata.flowSystems[
          StandardFlowSystemUids.UnderfloorHeating
        ] as UnderfloorHeatingFlowSystem
      ).pipeMaterial = "pertAl";

      result.metadata.units.currency = {
        symbol: CurrencySymbol.POUNDS,
        multiplierPct: 100,
      };

      // after converting to and from bar, and 1 vs 100m, it would be the same.
      // for (const fs of result.metadata.flowSystems) {
      //   for (const network of Object.values(fs.networks)) {
      //     network.pressureDropKPAM = bar2kpa(0.35) / 100;
      //   }
      // }

      // default pipes manufacturers
      result.metadata.catalog = {
        ...result.metadata.catalog,
        pipes: [
          { manufacturer: "bsen1057Cu", uid: "copperTypeB", selected: null },
          { manufacturer: "enIso15875pex", uid: "pexSdr74", selected: null },
          { manufacturer: "bs1387ss", uid: "stainlessSteel", selected: null },
          {
            manufacturer: "harmerSmlCICL",
            uid: "castIronDrainage",
            selected: null,
          },
          { manufacturer: "aliaxis", uid: "cpvc", selected: null },
          { manufacturer: "fluxo", uid: "mlcp", selected: null },
          { manufacturer: "generic", uid: "gmsMedium", selected: null },
          { manufacturer: "instinct", uid: "pertAl", selected: null },
          { manufacturer: "instinct", uid: "pertEvoh", selected: null },
        ],
        pump: [
          {
            manufacturer: "generic",
            uid: "pump",
            selected: null,
          },
        ],
        pumpTank: [
          {
            manufacturer: "generic",
            uid: "pumpTank",
            selected: null,
          },
        ],
        mixingValves: [
          { manufacturer: "caleffi", uid: "temperingValve", selected: null },
          { manufacturer: "caleffi", uid: "tmv", selected: null },
        ],
        hotWaterPlant: [
          { uid: "circulatingPumps", manufacturer: "generic", selected: null },
          { uid: "hotWaterPlant", manufacturer: "generic", selected: null },
        ],
        heatEmitters: [
          { uid: "radiators", manufacturer: "stelrad", selected: null },
        ],
        underfloorHeating: [
          { manufacturer: "instinct", uid: "manifold", selected: null },
          { manufacturer: "instinct", uid: "actuator", selected: null },
          { manufacturer: "instinct", uid: "blendingValve", selected: null },
          { manufacturer: "bastion", uid: "pump", selected: null },
          { manufacturer: "instinct", uid: "pumpPack", selected: null },
          { manufacturer: "instinct", uid: "ballValve", selected: null },
          {
            manufacturer: "resonate",
            uid: "edgeExpansionFoam",
            selected: null,
          },
        ],
        underfloorHeatingCoils: [
          { manufacturer: "instinct", uid: "pertAl", selected: null },
          { manufacturer: "instinct", uid: "pertEvoh", selected: null },
        ],
        filters: [
          { uid: "softener", manufacturer: "generic", selected: null },
          { uid: "backwash", manufacturer: "generic", selected: null },
          { uid: "cartridge", manufacturer: "generic", selected: null },
          { uid: "uv", manufacturer: "generic", selected: null },
          { uid: "backwashRainwater", manufacturer: "generic", selected: null },
        ],
        roPlant: [{ uid: "roPlant", manufacturer: "generic", selected: null }],
        greaseInterceptorTrap: [],
      };

      result.metadata.units.pressureMeasurementSystem =
        PressureMeasurementSystem.UK;
      result.metadata.units.pressureDropMeasurementSystem =
        PressureDropMeasurementSystem.UK;
      result.metadata.units.gasEnergyMeasurementSystem =
        GasEnergyMeasurementSystem.UNIVERSAL;
      result.metadata.units.mechanicalEnergyMeasurementSystem =
        MechanicalEnergyMeasurementSystem.UNIVERSAL;

      result.metadata.heatLoss.ventAirChangesRateStandard = "MCSPost2000";
      result.metadata.heatLoss.heatingAirChangesRateStandard = "MCSPost2000";

      result.metadata.heatLoss.electricityCostPerUnit.currency = Currency.GBP;
      break;
    case SupportedLocales.US:
      result.metadata.units = {
        gasEnergyMeasurementSystem: GasEnergyMeasurementSystem.IMPERIAL,
        mechanicalEnergyMeasurementSystem:
          MechanicalEnergyMeasurementSystem.IMPERIAL,
        lengthMeasurementSystem: MeasurementSystem.IMPERIAL,
        ventilationPressureMeasurementSystem:
          PressureMeasurementSystem.IMPERIAL,
        pressureMeasurementSystem: PressureMeasurementSystem.IMPERIAL,
        temperatureMeasurementSystem: MeasurementSystem.IMPERIAL,
        velocityMeasurementSystem: VelocityMeasurementSystem.IMPERIAL,
        volumeMeasurementSystem: VolumeMeasurementSystem.US,
        pressureDropMeasurementSystem: PressureDropMeasurementSystem.IMPERIAL,
        rainfallMeasurementSystem: RainfallMeasurementSystem.IMPERIAL,
        areaMeasurementSystem: AreaMeasurementSystem.IMPERIAL,
        flowRateMeasurementSystem: FlowRateMeasurementSystem.US,
        ventilationFlowRateMeasurementSystem:
          FlowRateMeasurementSystem.IMPERIAL_CFM,
        currency: {
          symbol: CurrencySymbol.DOLLARS,
          multiplierPct: 75,
        },
      };

      result.metadata.calculationParams = {
        ...result.metadata.calculationParams,
        psdMethod: SupportedPsdStandards.upc2018FlushTanks,
        drainageMethod: SupportedDrainageMethods.UPC2018DrainageFixtureUnits,
        combineLUs: true,
      };

      const coldWater = result.metadata.flowSystems[
        StandardFlowSystemUids.ColdWater
      ] as PressureFlowSystem;
      coldWater.temperatureC = f2C(70);
      coldWater.networks.connections.velocityMS =
        coldWater.networks.risers.velocityMS =
        coldWater.networks.reticulations.velocityMS =
          ft2M(8);

      const hotWater = result.metadata.flowSystems[
        StandardFlowSystemUids.HotWater
      ] as PressureFlowSystem;
      hotWater.temperatureC = f2C(140);
      hotWater.return.insulation.thicknessMM = in2MM(1);
      hotWater.return.insulation.material = InsulationMaterials.mineralWool;
      hotWater.return.maxVelocityMS = ft2M(3);
      hotWater.networks.risers.velocityMS =
        hotWater.networks.reticulations.velocityMS =
        hotWater.networks.connections.velocityMS =
          ft2M(5);

      const heating = result.metadata.flowSystems[
        StandardFlowSystemUids.Heating
      ] as MechanicalFlowSystem;
      heating.temperatureC = f2C(104);
      heating.return.insulation.thicknessMM = in2MM(1);
      heating.return.insulation.material = InsulationMaterials.mineralWool;
      heating.networks.risers.velocityMS =
        heating.networks.reticulations.velocityMS = ft2M(3);

      const warmWater = result.metadata.flowSystems[
        StandardFlowSystemUids.WarmWater
      ] as PressureFlowSystem;
      warmWater.temperatureC = f2C(120);
      warmWater.networks.risers.velocityMS =
        warmWater.networks.reticulations.velocityMS =
        warmWater.networks.connections.velocityMS =
          ft2M(5);

      const gas = result.metadata.flowSystems[
        StandardFlowSystemUids.Gas
      ] as GasFlowSystem;
      gas.networks.risers.velocityMS =
        gas.networks.reticulations.velocityMS =
        gas.networks.connections.velocityMS =
          ft2M(60);

      const fireHydrant = result.metadata.flowSystems[
        StandardFlowSystemUids.FireHydrant
      ] as FireFlowSystem;
      fireHydrant.networks.risers.velocityMS =
        fireHydrant.networks.reticulations.velocityMS =
        fireHydrant.networks.connections.velocityMS =
          ft2M(10);
      fireHydrant.temperatureC = f2C(70);

      const fireHoseReel = result.metadata.flowSystems[
        StandardFlowSystemUids.FireHoseReel
      ] as FireFlowSystem;
      fireHoseReel.networks.risers.velocityMS =
        fireHoseReel.networks.reticulations.velocityMS =
        fireHoseReel.networks.connections.velocityMS =
          ft2M(8);
      fireHoseReel.temperatureC = f2C(70);

      const sewageAll = Object.values(result.metadata.flowSystems).filter(
        (item) => isDrainage(item),
      ) as SewerFlowSystem[];
      for (const sewage of sewageAll) {
        sewage.temperatureC = f2C(70);
      }

      for (const fs of Object.values(result.metadata.flowSystems)) {
        for (const network of Object.values(fs.networks)) {
          if (network && "pressureDropKPAM" in network) {
            network.pressureDropKPAM = psi2KPA(3) / ft2M(100);
          }
        }
      }

      const chilled = result.metadata.flowSystems[
        StandardFlowSystemUids.Chilled
      ] as MechanicalFlowSystem;

      chilled.temperatureC = f2C(40);
      chilled.networks.risers.velocityMS =
        chilled.networks.reticulations.velocityMS = ft2M(4);
      chilled.networks.risers.pressureDropKPAM =
        chilled.networks.reticulations.pressureDropKPAM =
          psi2KPA(1.7) / ft2M(100);
      chilled.return.insulation.thicknessMM = in2MM(2);

      const condenser = result.metadata.flowSystems[
        StandardFlowSystemUids.Condenser
      ] as MechanicalFlowSystem;

      condenser.temperatureC = f2C(85);
      condenser.networks.risers.velocityMS =
        condenser.networks.reticulations.velocityMS = ft2M(4);
      condenser.return.insulation.thicknessMM = in2MM(2);

      for (const flowSystem of Object.values(result.metadata.flowSystems)) {
        if (isHeating(flowSystem) || isDrainage(flowSystem)) {
          flowSystem.defaultPipeHeightM = ft2M(-1);
        } else {
          flowSystem.defaultPipeHeightM = ft2M(9);
        }
      }

      for (const flowSystem of Object.values(result.metadata.flowSystems)) {
        if (flowSystem.type === "sewer") {
          // tslint:disable-next-line:forin
          for (const j in flowSystem.maxUnventedLengthM) {
            flowSystem.maxUnventedLengthM[j] = ft2M(30);
          }
        }
      }

      for (const fs of Object.values(result.metadata.flowSystems)) {
        if (isVentilation(fs)) {
          fs.networks.risers.velocityMS =
            fs.networks.mains.velocityMS =
            fs.networks.branches.velocityMS =
            fs.networks.connections.velocityMS =
              ft2M(12);
          fs.networks.risers.pressureDropKPAM =
            fs.networks.mains.pressureDropKPAM =
            fs.networks.branches.pressureDropKPAM =
            fs.networks.connections.pressureDropKPAM =
              psi2KPA(0.1) / ft2M(100);
        }
      }

      result.metadata.catalog.mixingValves = [
        { manufacturer: "caleffi", uid: "temperingValve", selected: null },
        { manufacturer: "caleffi", uid: "tmv", selected: null },
      ];
      result.metadata.catalog.pipes.push(
        { manufacturer: "atsmB88Cu", uid: "copperTypeB", selected: null },
        { manufacturer: "atsmF877pex", uid: "pexSdr74", selected: null },
        { manufacturer: "generic", uid: "stainlessSteel", selected: null },
        { manufacturer: "generic", uid: "castIronDrainage", selected: null },
        { manufacturer: "generic", uid: "plainCarbonSteel", selected: null },
        { manufacturer: "generic", uid: "gmsMedium", selected: null },
      );
      result.metadata.catalog.hotWaterPlant = [
        { uid: "circulatingPumps", manufacturer: "generic", selected: null },
        { uid: "hotWaterPlant", manufacturer: "generic", selected: null },
      ];
      result.metadata.catalog.pump = [
        { manufacturer: "generic", uid: "pump", selected: null },
      ];
      result.metadata.catalog.filters = [
        { uid: "softener", manufacturer: "generic", selected: null },
        { uid: "backwash", manufacturer: "generic", selected: null },
        { uid: "cartridge", manufacturer: "generic", selected: null },
        { uid: "uv", manufacturer: "generic", selected: null },
        { uid: "backwashRainwater", manufacturer: "generic", selected: null },
      ];
      result.metadata.catalog.roPlant = [
        { uid: "roPlant", manufacturer: "generic", selected: null },
      ];
      result.metadata.catalog.greaseInterceptorTrap = [];

      result.metadata.heatLoss.ventAirChangesRateStandard = "ASHRAE62.1";
      result.metadata.heatLoss.heatingAirChangesRateStandard = "ASHRAE62.1";

      result.levels = {
        ground: {
          entities: {},
          floorHeightM: 0,
          name: "Level 1",
          abbreviation: "L1",
          uid: "ground",
        },
      };

      const ventNodePropAll = result.metadata.nodes.mechanical.filter(
        (node) => node.type === "air-handling-vent",
      ) as DualSystemMechanicalNodeProps[];
      for (const ventNode of ventNodePropAll) {
        ventNode.supplyHeightAboveFloorMM =
          ventNode.extractHeightAboveFloorMM =
          ventNode.heatingHeightAboveFloorMM =
          ventNode.chilledHeightAboveFloorMM =
            AHU_HEIGHT_ABOVE_FLOOR_MM_US;
      }
      result.metadata.heatLoss.electricityCostPerUnit.currency = Currency.USD;
      break;
    default:
      assertUnreachable(locale);
  }
  return result;
}

export const initialAustralianDrawing = (): DrawingState =>
  cloneSimple({
    metadata: {
      generalInfo: {
        title: "Untitled",
        projectNumber: "",
        projectStage: "",
        designer: "",
        reviewed: "",
        approved: "",
        revision: 1,
        client: "",
        description: "",
        projectAddress: {
          formattedAddress: "",
          latitude: null,
          longitude: null,
          country: "",
        },
      },
      units: {
        lengthMeasurementSystem: MeasurementSystem.METRIC,
        volumeMeasurementSystem: VolumeMeasurementSystem.METRIC,
        velocityMeasurementSystem: VelocityMeasurementSystem.METRIC,
        pressureMeasurementSystem: PressureMeasurementSystem.METRIC,
        ventilationPressureMeasurementSystem:
          PressureMeasurementSystem.METRIC_SMALL,
        temperatureMeasurementSystem: MeasurementSystem.METRIC,
        gasEnergyMeasurementSystem: GasEnergyMeasurementSystem.METRIC,
        mechanicalEnergyMeasurementSystem:
          MechanicalEnergyMeasurementSystem.UNIVERSAL,
        pressureDropMeasurementSystem: PressureDropMeasurementSystem.METRIC,
        rainfallMeasurementSystem: RainfallMeasurementSystem.METRIC,
        areaMeasurementSystem: AreaMeasurementSystem.METRIC,
        flowRateMeasurementSystem: FlowRateMeasurementSystem.METRIC,
        ventilationFlowRateMeasurementSystem: FlowRateMeasurementSystem.METRIC,
        currency: {
          symbol: CurrencySymbol.DOLLARS,
          multiplierPct: 100,
        },
      },
      flowSystems: cloneSimple(initialFlowSystems).reduce(
        (p, c) => ({ ...p, [c.uid]: c }),
        {},
      ) as FlowSystemsMetadata,
      flowSystemUidsInOrder: initialFlowSystems.map((s) => s.uid),

      calculationParams: {
        psdMethod: SupportedPsdStandards.as35002021LoadingUnits,
        loadingUnitVariant: "low",
        dwellingMethod: null,
        drainageMethod: SupportedDrainageMethods.AS2021FixtureUnits,
        drainageSystem: "drainageSystem1",
        en12056FrequencyFactor: EN12056FrequencyFactor.CongestedUse,
        ringMainCalculationMethod: RingMainCalculationMethod.ISOLATION_CASES,
        ductSizingMethod: DUCT_SIZING_METHODS[0].key as DuctSizingMethod,
        pipeSizingMethod: PIPE_SIZING_METHODS[0].key as PipeSizingMethod,
        componentPressureLossMethod: ComponentPressureLossMethod.INDIVIDUALLY,
        pipePressureLossAddOnPCT: 0,

        roomTemperatureC: 20,
        windSpeedForHeatLossMS: 0,
        apartmentNum: 1,
        occupantNum: 1,
        gravitationalAcceleration: 9.80665,
        rainfallIntensityMm_H: 300,
        combineLUs: false,

        districtHeating: SupportedDistirctHeating.CIBSECP1Objective3_2,
        gasCalcMethod:
          SupportedGasCalculationMethods.internationalFuelGasCodeEquation2018,
        specificHeatMethod: null,
      },
      availableFixtures: [
        "basin",
        "bath",
        "shower",
        "kitchenSink",
        "wc",
        "washingMachine",
        "laundryTrough",
      ],
      nodes: {
        mechanical: cloneSimple(INITIAL_MECHANICAL_NODE),
        fire: [
          {
            name: "Fire Hydrant",
            customEntityId: "Fire Hydrant",

            subGroups: [
              {
                name: "5 Liters",
                subGroupId: "fire_hydrant_default_5",
                maxPressureKPA: 1300,
                minPressureKPA: 200,
                continuousFlowRateLS: 5,
                nodeGroupHex: "#FF5733",
                maxiumumSimutaneousNode: 2,
                kvValue: 0.3,
              },
              {
                name: "10 Liters",
                subGroupId: "fire_hydrant_default_10",
                maxPressureKPA: 1300,
                minPressureKPA: 200,
                continuousFlowRateLS: 10,
                nodeGroupHex: "#DDF611",
                maxiumumSimutaneousNode: 2,
                kvValue: 0.3,
              },
            ],
          },
          {
            name: "Fire Hose Reel",
            customEntityId: "Fire Hose Reel",
            subGroups: [
              {
                name: "Default",
                subGroupId: "fire_hose_reel_default",
                maxPressureKPA: 1000,
                minPressureKPA: 250,
                continuousFlowRateLS: 0.33,
                nodeGroupHex: "#DAF7A6",
                maxiumumSimutaneousNode: 2,
                kvValue: 0.3,
              },
            ],
          },
          {
            name: "Safety Shower",
            customEntityId: "Safety Shower",
            subGroups: [
              {
                name: "Default",
                subGroupId: "safety_shower_default",
                maxPressureKPA: 500,
                minPressureKPA: 210,
                continuousFlowRateLS: 1.25,
                nodeGroupHex: "#FFC300",
                maxiumumSimutaneousNode: 2,
                kvValue: 0.3,
              },
            ],
          },
          {
            name: "Fire Sprinkler",
            customEntityId: "Fire Sprinkler",
            subGroups: [
              {
                name: "Light Hazard",
                subGroupId: "fire_sprinkler_light_hazard_default",
                maxPressureKPA: 1200,
                minPressureKPA: 70,
                continuousFlowRateLS: 0.8,
                nodeGroupHex: "#C70039",
                maxiumumSimutaneousNode: 6,
                kvValue: 0.3,
              },
              {
                name: "Medium Hazard",
                subGroupId: "fire_sprinkler_medium_hazard_default",
                maxPressureKPA: 1200,
                minPressureKPA: 70,
                continuousFlowRateLS: 1,
                nodeGroupHex: "#900C3F",
                maxiumumSimutaneousNode: 18,
                kvValue: 0.3,
              },
              {
                name: "High Hazard",
                subGroupId: "fire_sprinkler_high_hazard_default",
                maxPressureKPA: 1200,
                minPressureKPA: 70,
                continuousFlowRateLS: 1,
                nodeGroupHex: "#581845",
                maxiumumSimutaneousNode: 30,
                kvValue: 0.3,
              },
            ],
          },
        ],
      },
      catalog: {
        pipes: [
          {
            manufacturer: "blucherSS",
            uid: "stainlessSteelDrainage",
            selected: null,
          },
        ],
        backflowValves: [
          { manufacturer: "apolloRpzd", uid: "RPZD", selected: null },
        ],
        mixingValves: [
          { manufacturer: "caleffi", uid: "temperingValve", selected: null },
        ],
        prv: [{ manufacturer: "caleffi", uid: "prv", selected: null }],
        balancingValves: [
          { manufacturer: "cimberio", uid: "balancingValves", selected: null },
        ],
        hotWaterPlant: [
          { uid: "circulatingPumps", manufacturer: "grundfos", selected: null },
          { uid: "hotWaterPlant", manufacturer: "rheem", selected: null },
        ],
        fixtures: [],
        floorWaste: [
          { manufacturer: "blucher", uid: "floorWaste", selected: null },
        ],
        inspectionOpening: [
          { manufacturer: "blucher", uid: "inspectionOpening", selected: null },
        ],
        pump: [{ manufacturer: "dab", uid: "pump", selected: null }],
        pumpTank: [{ manufacturer: "generic", uid: "tank", selected: null }],
        heatLoadMaterial: [
          { manufacturer: "generic", uid: "heatLoad", selected: null },
        ],
        heatEmitters: [
          { manufacturer: "generic", uid: "radiators", selected: null },
        ],
        underfloorHeating: [],
        underfloorHeatingCoils: [
          { manufacturer: "generic", uid: "pexSdr74", selected: null },
          { manufacturer: "generic", uid: "pertAl", selected: null },
          { manufacturer: "generic", uid: "pertEvoh", selected: null },
        ],
        filters: [
          { manufacturer: "southland", uid: "softener", selected: null },
          { manufacturer: "southland", uid: "backwash", selected: null },
          { manufacturer: "southland", uid: "cartridge", selected: null },
          { manufacturer: "southland", uid: "uv", selected: null },
          {
            manufacturer: "southland",
            uid: "backwashRainwater",
            selected: null,
          },
        ],
        roPlant: [
          { manufacturer: "southland", uid: "roPlant", selected: null },
        ],
        ducts: [],
      },
      priceTable: {},
      lines: cloneDeep(DEFAULT_LINES),
      drawingStyle: {
        highlightOverriddenConduits: true,
        overriddenConduitHighlightColor: { hex: "#FFFF00" },
      },
      heatLoss: {
        material: {
          "External Wall": {
            "Building Regs 2022 Limit": {
              uid: "Building Regs 2022 Limit",
              manufacturer: "generic",
              selected: null,
            },
            "Solid Brick": {
              uid: "Solid Brick",
              manufacturer: "generic",
              selected: null,
            },
            "Solid Brick Insulated": {
              uid: "Solid Brick Insulated",
              manufacturer: "generic",
              selected: null,
            },
            "Solid Stone": {
              uid: "Solid Stone",
              manufacturer: "generic",
              selected: null,
            },
            "Solid Stone Insulated": {
              uid: "Solid Stone Insulated",
              manufacturer: "generic",
              selected: null,
            },
            "Solid Concrete": {
              uid: "Solid Concrete",
              manufacturer: "generic",
              selected: null,
            },
            "Solid Concrete Insulated": {
              uid: "Solid Concrete Insulated",
              manufacturer: "generic",
              selected: null,
            },
            "Cavity Wall Uninsulated": {
              uid: "Cavity Wall Uninsulated",
              manufacturer: "generic",
              selected: null,
            },
            "Cavity Wall Insulated": {
              uid: "Cavity Wall Insulated",
              manufacturer: "generic",
              selected: null,
            },
            Hardwood: {
              uid: "Hardwood",
              manufacturer: "generic",
              selected: null,
            },
            Softwood: {
              uid: "Softwood",
              manufacturer: "generic",
              selected: null,
            },
            "Partition Uninsulated": {
              uid: "Partition Uninsulated",
              manufacturer: "generic",
              selected: null,
            },
            "Partition Insulated": {
              uid: "Partition Insulated",
              manufacturer: "generic",
              selected: null,
            },
          },
          "Internal Wall": {
            "Building Regs 2022 Limit": {
              uid: "Building Regs 2022 Limit",
              manufacturer: "generic",
              selected: null,
            },
            "Solid Brick": {
              uid: "Solid Brick",
              manufacturer: "generic",
              selected: null,
            },
            "Solid Brick Insulated": {
              uid: "Solid Brick Insulated",
              manufacturer: "generic",
              selected: null,
            },
            "Solid Stone": {
              uid: "Solid Stone",
              manufacturer: "generic",
              selected: null,
            },
            "Solid Stone Insulated": {
              uid: "Solid Stone Insulated",
              manufacturer: "generic",
              selected: null,
            },
            "Solid Concrete": {
              uid: "Solid Concrete",
              manufacturer: "generic",
              selected: null,
            },
            "Solid Concrete Insulated": {
              uid: "Solid Concrete Insulated",
              manufacturer: "generic",
              selected: null,
            },
            "Cavity Wall Uninsulated": {
              uid: "Cavity Wall Uninsulated",
              manufacturer: "generic",
              selected: null,
            },
            "Cavity Wall Insulated": {
              uid: "Cavity Wall Insulated",
              manufacturer: "generic",
              selected: null,
            },
            Hardwood: {
              uid: "Hardwood",
              manufacturer: "generic",
              selected: null,
            },
            Softwood: {
              uid: "Softwood",
              manufacturer: "generic",
              selected: null,
            },
            "Partition Uninsulated": {
              uid: "Partition Uninsulated",
              manufacturer: "generic",
              selected: null,
            },
            "Partition Insulated": {
              uid: "Partition Insulated",
              manufacturer: "generic",
              selected: null,
            },
          },
          "Party Wall": {
            "Building Regs 2022 Limit": {
              uid: "Building Regs 2022 Limit",
              manufacturer: "generic",
              selected: null,
            },
            "Solid Brick": {
              uid: "Solid Brick",
              manufacturer: "generic",
              selected: null,
            },
            "Solid Brick Insulated": {
              uid: "Solid Brick Insulated",
              manufacturer: "generic",
              selected: null,
            },
            "Solid Stone": {
              uid: "Solid Stone",
              manufacturer: "generic",
              selected: null,
            },
            "Solid Stone Insulated": {
              uid: "Solid Stone Insulated",
              manufacturer: "generic",
              selected: null,
            },
            "Solid Concrete": {
              uid: "Solid Concrete",
              manufacturer: "generic",
              selected: null,
            },
            "Solid Concrete Insulated": {
              uid: "Solid Concrete Insulated",
              manufacturer: "generic",
              selected: null,
            },
            "Cavity Wall Uninsulated": {
              uid: "Cavity Wall Uninsulated",
              manufacturer: "generic",
              selected: null,
            },
            "Cavity Wall Insulated": {
              uid: "Cavity Wall Insulated",
              manufacturer: "generic",
              selected: null,
            },
            Hardwood: {
              uid: "Hardwood",
              manufacturer: "generic",
              selected: null,
            },
            Softwood: {
              uid: "Softwood",
              manufacturer: "generic",
              selected: null,
            },
            Plasterboard: {
              uid: "Plasterboard",
              manufacturer: "generic",
              selected: null,
            },
            "Partition Uninsulated": {
              uid: "Partition Uninsulated",
              manufacturer: "generic",
              selected: null,
            },
            "Partition Insulated": {
              uid: "Partition Insulated",
              manufacturer: "generic",
              selected: null,
            },
          },
          Window: {
            "Building Regs 2022 Limit": {
              uid: "Building Regs 2022 Limit",
              manufacturer: "generic",
              selected: null,
            },
            "Metal Single Glazed": {
              uid: "Metal Single Glazed",
              manufacturer: "generic",
              selected: null,
            },
            "Metal Double Glazed": {
              uid: "Metal Double Glazed",
              manufacturer: "generic",
              selected: null,
            },
            "Metal Triple Glazed": {
              uid: "Metal Triple Glazed",
              manufacturer: "generic",
              selected: null,
            },
            "Wood Single Glazed": {
              uid: "Wood Single Glazed",
              manufacturer: "generic",
              selected: null,
            },
            "Wood Double Glazed": {
              uid: "Wood Double Glazed",
              manufacturer: "generic",
              selected: null,
            },
            "Wood Triple Glazed": {
              uid: "Wood Triple Glazed",
              manufacturer: "generic",
              selected: null,
            },
            "PVC Single Glazed": {
              uid: "PVC Single Glazed",
              manufacturer: "generic",
              selected: null,
            },
            "PVC Double Glazed": {
              uid: "PVC Double Glazed",
              manufacturer: "generic",
              selected: null,
            },
            "PVC Triple Glazed": {
              uid: "PVC Triple Glazed",
              manufacturer: "generic",
              selected: null,
            },
          },
          "External Door": {
            "Building Regs 2022 Limit": {
              uid: "Building Regs 2022 Limit",
              manufacturer: "generic",
              selected: null,
            },
            "Solid Wood Door": {
              uid: "Solid Wood Door",
              manufacturer: "generic",
              selected: null,
            },
            "Glazed Wood Single": {
              uid: "Glazed Wood Single",
              manufacturer: "generic",
              selected: null,
            },
            "Glazed Wood Double": {
              uid: "Glazed Wood Double",
              manufacturer: "generic",
              selected: null,
            },
            "Glazed Wood Triple": {
              uid: "Glazed Wood Triple",
              manufacturer: "generic",
              selected: null,
            },
            "Metal Single": {
              uid: "Metal Single",
              manufacturer: "generic",
              selected: null,
            },
            "Metal Double": {
              uid: "Metal Double",
              manufacturer: "generic",
              selected: null,
            },
            "Metal Triple": {
              uid: "Metal Triple",
              manufacturer: "generic",
              selected: null,
            },
            "Insulated Internal": {
              uid: "Insulated Internal",
              manufacturer: "generic",
              selected: null,
            },
          },
          "Internal Door": {
            "Building Regs 2022 Limit": {
              uid: "Building Regs 2022 Limit",
              manufacturer: "generic",
              selected: null,
            },
            "Solid Wood Door": {
              uid: "Solid Wood Door",
              manufacturer: "generic",
              selected: null,
            },
            "Glazed Wood Single": {
              uid: "Glazed Wood Single",
              manufacturer: "generic",
              selected: null,
            },
            "Glazed Wood Double": {
              uid: "Glazed Wood Double",
              manufacturer: "generic",
              selected: null,
            },
            "Glazed Wood Triple": {
              uid: "Glazed Wood Triple",
              manufacturer: "generic",
              selected: null,
            },
            "Metal Single": {
              uid: "Metal Single",
              manufacturer: "generic",
              selected: null,
            },
            "Metal Double": {
              uid: "Metal Double",
              manufacturer: "generic",
              selected: null,
            },
            "Metal Triple": {
              uid: "Metal Triple",
              manufacturer: "generic",
              selected: null,
            },
            "Insulated Internal": {
              uid: "Insulated Internal",
              manufacturer: "generic",
              selected: null,
            },
          },
          Roof: {
            "Building Regs 2022 Limit": {
              uid: "Building Regs 2022 Limit",
              manufacturer: "generic",
              selected: null,
            },
            "Aerated Concrete": {
              uid: "Aerated Concrete",
              manufacturer: "generic",
              selected: null,
            },
            Aerated: {
              uid: "Aerated",
              manufacturer: "generic",
              selected: null,
            },
            "Felt/Bitumen": {
              uid: "Felt/Bitumen",
              manufacturer: "generic",
              selected: null,
            },
            Screed: {
              uid: "Screed",
              manufacturer: "generic",
              selected: null,
            },
            "Stone Chippings": {
              uid: "Stone Chippings",
              manufacturer: "generic",
              selected: null,
            },
            "Clay Tiles": {
              uid: "Clay Tiles",
              manufacturer: "generic",
              selected: null,
            },
            "Concrete Tiles": {
              uid: "Concrete Tiles",
              manufacturer: "generic",
              selected: null,
            },
            "Wood/Wool": {
              uid: "Wood/Wool",
              manufacturer: "generic",
              selected: null,
            },
          },
          "Bottom Floor": {
            "Building Regs 2022 Limit": {
              uid: "Building Regs 2022 Limit",
              manufacturer: "generic",
              selected: null,
            },
            Concrete: {
              uid: "Concrete",
              manufacturer: "generic",
              selected: null,
            },
            Steel: {
              uid: "Steel",
              manufacturer: "generic",
              selected: null,
            },
            Screed: {
              uid: "Screed",
              manufacturer: "generic",
              selected: null,
            },
            Softwood: {
              uid: "Softwood",
              manufacturer: "generic",
              selected: null,
            },
            Hardwood: {
              uid: "Hardwood",
              manufacturer: "generic",
              selected: null,
            },
            "Wood Blocks": {
              uid: "Wood Blocks",
              manufacturer: "generic",
              selected: null,
            },
          },
          "Suspended Floor": {
            "Building Regs 2022 Limit": {
              uid: "Building Regs 2022 Limit",
              manufacturer: "generic",
              selected: null,
            },
            Concrete: {
              uid: "Concrete",
              manufacturer: "generic",
              selected: null,
            },
            Steel: {
              uid: "Steel",
              manufacturer: "generic",
              selected: null,
            },
            Screed: {
              uid: "Screed",
              manufacturer: "generic",
              selected: null,
            },
            Softwood: {
              uid: "Softwood",
              manufacturer: "generic",
              selected: null,
            },
            Hardwood: {
              uid: "Hardwood",
              manufacturer: "generic",
              selected: null,
            },
            "Wood Blocks": {
              uid: "Wood Blocks",
              manufacturer: "generic",
              selected: null,
            },
          },
          "Party Floor": {
            "Building Regs 2022 Limit": {
              uid: "Building Regs 2022 Limit",
              manufacturer: "generic",
              selected: null,
            },
            Concrete: {
              uid: "Concrete",
              manufacturer: "generic",
              selected: null,
            },
            Steel: {
              uid: "Steel",
              manufacturer: "generic",
              selected: null,
            },
            Screed: {
              uid: "Screed",
              manufacturer: "generic",
              selected: null,
            },
            Softwood: {
              uid: "Softwood",
              manufacturer: "generic",
              selected: null,
            },
            Hardwood: {
              uid: "Hardwood",
              manufacturer: "generic",
              selected: null,
            },
            "Wood Blocks": {
              uid: "Wood Blocks",
              manufacturer: "generic",
              selected: null,
            },
          },
        },
        defaultMaterial: {
          "External Wall": "Building Regs 2022 Limit",
          "Internal Wall": "Building Regs 2022 Limit",
          "Party Wall": "Building Regs 2022 Limit",
          Window: "Building Regs 2022 Limit",
          "External Door": "Building Regs 2022 Limit",
          "Internal Door": "Building Regs 2022 Limit",
          Roof: "Building Regs 2022 Limit",
          "Bottom Floor": "Building Regs 2022 Limit",
          "Suspended Floor": "Building Regs 2022 Limit",
          "Party Floor": "Building Regs 2022 Limit",
        },
        defaultColor: {
          "External Wall": {
            hex: "#000000",
          },
          "Internal Wall": {
            hex: "#969696",
          },
          "Party Wall": {
            hex: "#d3d3d3",
          },
          Window: {
            hex: "#000000",
          },
          "External Door": {
            hex: "#000000",
          },
          "Internal Door": {
            hex: "#000000",
          },
          Roof: {
            hex: "#ffffff",
          },
          "Bottom Floor": {
            hex: "#ffffff",
          },
          "Suspended Floor": {
            hex: "#ffffff",
          },
          "Party Floor": {
            hex: "#ffffff",
          },
        },
        customMaterial: {
          "External Wall": {},
          "Internal Wall": {},
          "Party Wall": {},
          Window: {},
          "External Door": {},
          "Internal Door": {},
          Roof: {},
          "Bottom Floor": {},
          "Suspended Floor": {},
          "Party Floor": {},
        },
        customRoom: {},
        roofSpec: cloneSimple(INITIAL_ROOF_SPEC),
        defaultRoofType: RoofType.Flat,
        ventAirChangesRateStandard: "CIBSE2016",
        heatingAirChangesRateStandard: "CIBSE2016",
        wallSpec: {
          roomHeightM: 2.5,
          internalWidthMM: INTERNAL_WALL_WIDTH_MM,
          externalWidthMM: EXTERNAL_WALL_WIDTH_MM,
          partyWidthMM: PARTY_WALL_WIDTH_MM,
        },
        defaultRoomTemperatureC: 20,
        externalWinterTemperatureC: -2.2,
        groundTemperatureC: 5,
        thermalBridgingCoefficient: 0.05,
        spareHeatGainPrecent: DEFAULT_SPARE_HEAT_CAP,
        spareHeatLossPrecent: DEFAULT_SPARE_HEAT_CAP,
        externalSummerTemperatureC: 28,
        solarHeatGainCoefficient: 0.6,
        roomsBelowTransparentPct: DEFAULT_ROOMS_BELOW_TRANSPARENCY_PCT,
        solarRadiationWPerMin: {
          top: 500,
          bottom: 500,
          left: 500,
          right: 500,
          rightTop: 500,
          rightBottom: 500,
          leftTop: 500,
          leftBottom: 500,
        },
        windowSpec: {
          [WindowType.WINDOW]: {
            heightM: 1.37,
            lengthM: 0.85,
          },
          [WindowType.VELUX]: {
            widthM: 1,
            lengthM: 1,
          },
        },
        doorSpec: {
          heightM: 2.03,
          lengthM: {
            [DoorType.SINGLE]: 0.81,
            [DoorType.BI_FOLDING]: 1.82,
          },
        },
        internalHeatSource: cloneSimple(INITIAL_INTERNAL_HEATSOURCE),
        baseTemperatureC: DEFAULT_BASE_TEMPERATURE_C,
        heatingDegreeDays: DEFAULT_HEATING_DEGREE_DAYS,
        coolingDegreeDays: DEFAULT_COOLING_DEGREE_DAYS,
        winterTempCutoffPctThreshold: DEFAULT_WINTER_TEMP_CUTOFF_PCT_THRESHOLD,
        summerTempCutoffPctThreshold: DEFAULT_SUMMER_TEMP_CUTOFF_PCT_THRESHOLD,
        winterPartyTemperatureC: DEFAULT_WINTER_PARTY_TEMPERATURE_C,
        summerPartyTemperatureC: DEFAULT_SUMMER_PARTY_TEMPERATURE_C,
        SCOP: DEFAULT_SCOP,
        energyPerformanceCertificateInformation: cloneSimple(
          DEFAULT_EPC_INFORMATION,
        ),
        existingHeatingSystem: cloneSimple(DEFAULT_EXISTING_HEATING_SYSTEM),
        electricityCostPerUnit: {
          value: 0,
          currency: Currency.AUD,
        },
        ventHeatLossRecoveryPct: 0,
        grilleFlowRateType: "room",
      },
      annotations: {
        fontSize: 150,
      },
      roomResultsSettings: {
        ...DEFAULT_ROOM_RESULTS_SETTINGS,
      },
      marketplaceItems: [],
      workflows: DEFAULT_WORKFLOWS,
      metaPdf: {},
    },
    levels: {
      ground: {
        entities: {},
        floorHeightM: 0,
        name: "Ground Floor",
        abbreviation: "G",
        uid: "ground",
      },
    },
    shared: {},
  });
