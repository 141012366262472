import { betterObjectKeys } from "../../lib/utils";
import { Workflows } from "./drawing";

export const DEFAULT_WORKFLOWS: Workflows = {
  heat_gain: {
    enabled: true,
  },
  heat_loss: {
    enabled: true,
  },
  mech_heating: {
    enabled: true,
  },
  mech_ventilation: {
    enabled: true,
  },
  mech_underfloor_heating: {
    enabled: true,
  },
  mech_chilled: {
    enabled: true,
  },
  plumbing_water: {
    enabled: true,
  },
  plumbing_gas: {
    enabled: true,
  },
  plumbing_wastewater: {
    enabled: true,
  },
  plumbing_stormwater: {
    enabled: true,
  },
  fire_sprinklers: {
    enabled: true,
  },
  fire_hydrants: {
    enabled: true,
  },
  fire_hosereels: {
    enabled: true,
  },
};

export const INITIAL_WORKFLOW_NAMES = betterObjectKeys(DEFAULT_WORKFLOWS);
