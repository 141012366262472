import { Primitive } from "./primitive";

/**
 * A simple implementation of a MultiMap.
 **
 * MultiMaps always return a value for `get` calls, even if there are no entries for a given key.
 */
export class MultiMap<K extends Primitive, V> {
  static fromItems<I, K extends Primitive, V>(
    items: I[],
    kf: (i: I) => K | undefined,
    vf: (i: I) => V | undefined,
  ): MultiMap<K, V> {
    const map = new MultiMap<K, V>();
    for (const i of items) {
      const key = kf(i);
      const value = vf(i);

      if (key !== undefined && value !== undefined) {
        map.add(key, value);
      }
    }
    return map;
  }

  private map: Map<K, V[]> = new Map();

  get(key: K): V[] {
    const current = this.map.get(key);
    if (!current) {
      const newValue: V[] = [];
      this.map.set(key, newValue);
      return newValue;
    }
    return current;
  }

  add(key: K, ...value: V[]): void {
    if (value.length) {
      this.get(key).push(...value);
    }
  }

  /**
   * Important: This is number of entries, not the total number of values.
   */
  length(): number {
    return this.map.size;
  }

  entries(): IterableIterator<[K, V[]]> {
    return this.map.entries();
  }

  values(): IterableIterator<V[]> {
    return this.map.values();
  }

  keys(): IterableIterator<K> {
    return this.map.keys();
  }
}
