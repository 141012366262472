import { cloneSimple } from "../../../lib/utils";
import { StandardFlowSystemUids } from "../../config";
import {
  DrainagePipeSizingProperties,
  StormwaterFlowSystem,
} from "../flow-systems";

export const initialStormwaterProperties: DrainagePipeSizingProperties = {
  availablePipeSizesMM: [40, 50, 65, 80, 100, 125, 150, 225, 300, 375],
  horizontalPipeSizing: [
    { minUnits: 0, maxUnits: 7, sizeMM: 100, gradePCT: 1 },
    { minUnits: 7, maxUnits: 20, sizeMM: 150, gradePCT: 1 },
    { minUnits: 20, maxUnits: 50, sizeMM: 225, gradePCT: 1 },
    { minUnits: 50, maxUnits: 125, sizeMM: 300, gradePCT: 1 },
  ],
  stackPipeSizing: [
    { minUnits: 0, maxUnits: 7, sizeMM: 100, maximumUnitsPerLevel: 0 },
    { minUnits: 7, maxUnits: 20, sizeMM: 150, maximumUnitsPerLevel: 0 },
    { minUnits: 20, maxUnits: 50, sizeMM: 225, maximumUnitsPerLevel: 0 },
    { minUnits: 50, maxUnits: 125, sizeMM: 300, maximumUnitsPerLevel: 0 },
  ],
};

export const STORMWATER_SYSTEM = (): StormwaterFlowSystem[] => [
  {
    type: "stormwater",
    role: "stormwater",
    name: "Stormwater",
    color: { hex: "#1d5ca3" },
    temperatureC: 20,
    uid: StandardFlowSystemUids.StormWater,
    fluid: "stormwater",
    defaultPipeHeightM: -1,

    networks: {
      stacks: {
        material: "uPVCDrainage",
      },
      pipes: {
        material: "uPVCDrainage",
      },
    },

    ...cloneSimple(initialStormwaterProperties),
  },
  {
    type: "stormwater",
    role: "stormwater",
    name: "Backup Stormwater",
    color: { hex: "#6786a8" },
    temperatureC: 20,
    uid: StandardFlowSystemUids.BackupStormWater,
    fluid: "stormwater",
    defaultPipeHeightM: -1,

    networks: {
      stacks: {
        material: "uPVCDrainage",
      },
      pipes: {
        material: "uPVCDrainage",
      },
    },

    ...cloneSimple(initialStormwaterProperties),
  },
];
