import { cloneSimple } from "../../../lib/utils";
import { StandardFlowSystemUids } from "../../config";
import { DrainageProperties, SewerFlowSystem } from "../flow-systems";

export const initialDrainageProperties: DrainageProperties = {
  ventColor: { hex: "#ff7755" },
  availablePipeSizesMM: [40, 50, 65, 80, 100, 125, 150, 225, 300, 375],
  horizontalPipeSizing: [
    { minUnits: 0, maxUnits: 165, sizeMM: 100, gradePCT: 1.65 },
    { minUnits: 166, maxUnits: 855, sizeMM: 150, gradePCT: 1 },
    { minUnits: 856, maxUnits: 1310, sizeMM: 150, gradePCT: 1.65 },
    { minUnits: 1311, maxUnits: 4500, sizeMM: 225, gradePCT: 1 },
    { minUnits: 4501, maxUnits: 11400, sizeMM: 300, gradePCT: 1 },
  ],
  maxUnventedCapacityWCs: {
    40: 2,
    50: 2,
    65: 2,
    80: 2,
    100: 2,
    125: 2,
    150: 2,
    225: 2,
    300: 2,
    375: 2,
  },
  maxUnventedLengthM: {
    40: 10,
    50: 10,
    65: 10,
    80: 10,
    100: 10,
    125: 10,
    150: 10,
    225: 10,
    300: 10,
    375: 10,
  },
  stackDedicatedVent: false,
  stackPipeSizing: [
    { minUnits: 0, maxUnits: 500, sizeMM: 100, maximumUnitsPerLevel: 125 },
    { minUnits: 501, maxUnits: 1000, sizeMM: 125, maximumUnitsPerLevel: 250 },
    { minUnits: 1001, maxUnits: 2400, sizeMM: 150, maximumUnitsPerLevel: 600 },
    { minUnits: 2401, maxUnits: 7000, sizeMM: 225, maximumUnitsPerLevel: 1750 },
  ],
  stackSizeDiminish: false,
  stackVentPipeSizing: [
    { minUnits: 0, maxUnits: 56, sizeMM: 65 },
    { minUnits: 57, maxUnits: 80, sizeMM: 80 },
    { minUnits: 81, maxUnits: 500, sizeMM: 100 },
    { minUnits: 501, maxUnits: 1100, sizeMM: 125 },
    { minUnits: 1101, maxUnits: 2400, sizeMM: 150 },
    { minUnits: 2401, maxUnits: 7000, sizeMM: 225 },
  ],
  ventSizing: [
    { minUnits: 0, maxUnits: 10, sizeMM: 40 },
    { minUnits: 11, maxUnits: 30, sizeMM: 50 },
    { minUnits: 31, maxUnits: 175, sizeMM: 65 },
    { minUnits: 176, maxUnits: 400, sizeMM: 80 },
    { minUnits: 401, maxUnits: 600, sizeMM: 100 },
  ],
};

export const DRAINAGE_FLOW_SYSTEMS = (): SewerFlowSystem[] => [
  {
    type: "sewer",
    role: "sewer",
    name: "Sewer Drainage",
    color: { hex: "#119911" },
    temperatureC: 20,
    uid: StandardFlowSystemUids.SewerDrainage,
    fluid: "sewage",
    defaultPipeHeightM: -1,

    networks: {
      stacks: {
        material: "uPVCDrainage",
      },
      pipes: {
        material: "uPVCDrainage",
      },
      vents: {
        material: "uPVCDrainage",
      },
    },

    ...cloneSimple(initialDrainageProperties),
  },

  {
    type: "sewer",
    role: "sewer",
    name: "Sanitary Plumbing",
    color: { hex: "#66FF66" },
    temperatureC: 20,
    uid: StandardFlowSystemUids.SanitaryPlumbing,
    fluid: "sewage",
    defaultPipeHeightM: -1,

    networks: {
      stacks: {
        material: "uPVCDrainage",
      },
      pipes: {
        material: "uPVCDrainage",
      },
      vents: {
        material: "uPVCDrainage",
      },
    },

    ...cloneSimple(initialDrainageProperties),
  },

  {
    type: "sewer",
    role: "sewer",
    name: "Grease Waste",
    color: { hex: "#90403e" },
    temperatureC: 20,
    uid: StandardFlowSystemUids.GreaseWaste,
    fluid: "sewage",
    defaultPipeHeightM: -1,

    networks: {
      stacks: {
        material: "hdpeSdr11Drainage",
      },
      pipes: {
        material: "hdpeSdr11Drainage",
      },
      vents: {
        material: "hdpeSdr11Drainage",
      },
    },

    ...cloneSimple(initialDrainageProperties),
  },

  {
    type: "sewer",
    role: "sewer",
    name: "Trade Waste",
    color: { hex: "#a10000" },
    temperatureC: 20,
    uid: StandardFlowSystemUids.TradeWaste,
    fluid: "sewage",
    defaultPipeHeightM: -1,

    networks: {
      stacks: {
        material: "stainlessSteelDrainage",
      },
      pipes: {
        material: "stainlessSteelDrainage",
      },
      vents: {
        material: "stainlessSteelDrainage",
      },
    },

    ...cloneSimple(initialDrainageProperties),
  },

  {
    type: "sewer",
    role: "sewer",
    name: "Rising Main",
    color: { hex: "#7100c2" },
    temperatureC: 20,
    uid: StandardFlowSystemUids.RisingMain,
    fluid: "sewage",
    defaultPipeHeightM: 3,

    networks: {
      stacks: {
        material: "uPVCDrainage",
      },
      pipes: {
        material: "uPVCDrainage",
      },
      vents: {
        material: "uPVCDrainage",
      },
    },

    ...cloneSimple(initialDrainageProperties),
  },
];
