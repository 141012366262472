import { Units, UnitsContext } from "../../../../lib/measurements";
import { Choice } from "../../../../lib/utils";
import { ReturnCalculations } from "../../../calculations/returns";
import { CoreContext } from "../../../calculations/types";
import { auCatalog } from "../../../catalog/initial-catalog/au-catalog";
import {
  getManufacturersByPlant,
  hasCustomManufacturers,
} from "../../../catalog/manufacturers/utils";
import {
  Catalog,
  HotWaterPlantSizePropsElectric,
  HotWaterPlantSizePropsHeatPump,
} from "../../../catalog/types";
import { isDrainage, isVentilation } from "../../../config";
import { getTooltip, getTooltipsEntityName } from "../../../tooltips/tooltips";
import { DrawingState } from "../../drawing";
import { FieldType, PropertyField } from "../property-field";
import { EntityType } from "../types";
import { ConditionalPropertyField, filterConditionalFields } from "../utils";
import { getCustomManufPropertyFields } from "./customManufFields";
import PlantEntity, {
  isHeatLoadPlant,
  plantHasSingleRating,
} from "./plant-entity";
import {
  DrainageGreaseInterceptorTrap,
  GreaseInterceptorTrapManufacturers,
  PlantShapes,
  PlantType,
  PressureMethod,
  PumpTank,
  RadiatorPlant,
  ReturnSystemPlant,
  ReturnSystemType,
  configurationToName,
} from "./plant-types";
import { PlantFieldHandlers } from "./types";
import {
  areDimensionsReadonly,
  getPlantConfigurations,
  getPlantModelChoices,
  isHeatPumpPlant,
  isHotWaterRheem,
  isMultiOutlets,
  isPlantPump,
  isVentilationPlant,
} from "./utils";

export function createSpecsTabFields(
  context: CoreContext,
  filled: PlantEntity,
  drawing: DrawingState,
  catalog: Catalog,
  handlers?: PlantFieldHandlers,
): PropertyField[] {
  const tooltipsEntityName = getTooltipsEntityName(filled, drawing);
  const isReturnSystem = filled.plant.type === PlantType.RETURN_SYSTEM;
  const isTank = filled.plant.type === PlantType.TANK;
  const isPumpTank = filled.plant.type === PlantType.PUMP_TANK;
  const isRO = filled.plant.type === PlantType.RO;
  const plantHasPump = isPlantPump(filled);
  const isCustom = filled.plant.type === PlantType.CUSTOM;
  const isDrainageGreaseInterTrap =
    filled.plant.type === PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP;
  const isSingleSystemEmitter =
    plantHasSingleRating(filled.plant) && !isReturnSystem;
  const o = context.globalStore.get(filled.uid);
  const isRheem =
    filled.plant.type === PlantType.RETURN_SYSTEM &&
    isHotWaterRheem(drawing, filled.plant.returnType);
  const isRheemTank =
    isRheem && (filled.plant as ReturnSystemPlant).rheemVariant === "tankpak";
  const isRheemElectric =
    isRheem && (filled.plant as ReturnSystemPlant).rheemVariant === "electric";
  const isRheemHeatPump =
    isRheem && (filled.plant as ReturnSystemPlant).rheemVariant === "heatPump";

  const hasSingleRating = plantHasSingleRating(filled.plant);
  const isEnergyLoad =
    plantHasSingleRating(filled.plant) && filled.plant.rating.type === "energy";
  const isFlowRateLoad =
    plantHasSingleRating(filled.plant) &&
    filled.plant.rating.type === "flow-rate";

  const iAmDrainage =
    !isMultiOutlets(filled.plant) &&
    (isDrainage(drawing.metadata.flowSystems[filled.plant.outletSystemUid]) ||
      isDrainage(drawing.metadata.flowSystems[filled.inletSystemUid]));

  if (isVentilationPlant(filled.plant, drawing.metadata.flowSystems)) {
    filled.plant.depthMM = 0;
  }
  const iAmVentilation = isVentilationPlant(
    filled.plant,
    drawing.metadata.flowSystems,
  );
  const pressureUnitsContext = iAmVentilation
    ? UnitsContext.VENTILATION
    : undefined;
  const isPressureMethodPumpDuty =
    !isMultiOutlets(filled.plant) &&
    !iAmDrainage &&
    !isVentilationPlant(filled.plant, drawing.metadata.flowSystems) &&
    filled.plant.pressureLoss.pressureMethod === PressureMethod.PUMP_DUTY;

  const isPressureMethodFixedPressureLoss =
    !isMultiOutlets(filled.plant) &&
    !iAmDrainage &&
    !isVentilationPlant(filled.plant, drawing.metadata.flowSystems) &&
    filled.plant.pressureLoss.pressureMethod ===
      PressureMethod.FIXED_PRESSURE_LOSS;

  const isPressureMethodStaticPressure =
    !isMultiOutlets(filled.plant) &&
    !iAmDrainage &&
    !isVentilationPlant(filled.plant, drawing.metadata.flowSystems) &&
    filled.plant.pressureLoss.pressureMethod === PressureMethod.STATIC_PRESSURE;

  const hasVolumeField = filled.plant.type === PlantType.VOLUMISER;
  const manufacturers = getManufacturersByPlant(filled.plant, catalog);
  const configurations = getPlantConfigurations(filled.plant, catalog);
  const models = getPlantModelChoices(filled.plant, catalog);
  const customManufPropertyFields = getCustomManufPropertyFields(
    filled.plant,
    false,
  );
  const heatPumpTypes: Choice<ReturnSystemType>[] = [
    {
      name: "Air Source Heat Pump",
      key: ReturnSystemType.AIR_SOURCE_HEAT_PUMP,
    },
    {
      name: "Ground Source Heat Pump",
      key: ReturnSystemType.GROUND_SOURCE_HEAT_PUMP,
    },
  ];
  const isHeatLoad = isHeatLoadPlant(filled.plant);
  const isHeatPump = isHeatPumpPlant(filled.plant);

  const inletSystemVents = isVentilation(
    drawing.metadata.flowSystems[filled.inletSystemUid],
  );

  const heatPumpFields: ConditionalPropertyField[] = [
    {
      property: "plant.returnSystemType",
      title: "Type",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Choice,
      params: {
        choices: heatPumpTypes.map((type) => ({
          name: type.name,
          key: type.key,
        })),
      },
      requiresInput: true,
      multiFieldId: "heatPumpType",
      isShown: isHeatPump,
      beforeSet: (
        newVal:
          | ReturnSystemType.AIR_SOURCE_HEAT_PUMP
          | ReturnSystemType.GROUND_SOURCE_HEAT_PUMP,
      ) => {
        const obj = context.globalStore.getObjectOfTypeOrThrow(
          EntityType.PLANT,
          filled.uid,
        );
        const plant = obj.entity.plant;
        if (isHeatPumpPlant(plant)) {
          plant.returnSystemType = newVal;
          obj.entity.name = heatPumpTypes.find((i) => i.key === newVal)!.name;
        }
      },
    },
  ];

  const res: ConditionalPropertyField[] = [
    ...heatPumpFields,
    {
      property: "plant.manufacturer",
      title: "Manufacturer",
      hasDefault: manufacturers.length > 0,
      isCalculated: false,
      requiresInput: false,
      type: FieldType.Choice,
      params: {
        choices: manufacturers.map((m) => ({
          name: m.name,
          key: m.uid,
        })),
      },
      multiFieldId: "manufacturer",
      isShown: hasCustomManufacturers(manufacturers),
    },
    {
      property: "plant.configuration",
      title: "Configuration",
      hasDefault: false,
      isCalculated: false,
      requiresInput: true,
      type: FieldType.Choice,
      params: {
        choices: configurations.map((c) => ({
          name: configurationToName(c),
          key: c,
          disabled: !getPlantConfigurations(filled.plant, catalog).includes(c),
        })),
      },
      multiFieldId: "plant-configuration",
      isShown: configurations.length > 0,
    },
    {
      property: "plant.model",
      title: "Model",
      hasDefault: false,
      isCalculated: true,
      type: FieldType.Choice,
      multiFieldId: "model",
      params: {
        initialValue: (filled.plant as PumpTank).model || "",
        choices: models.map((m) => ({
          name: m,
          key: m,
        })),
      },
      isShown: models.length > 0,
    },
    ...customManufPropertyFields,
    ...conditionallyAdd(
      isRheem && {
        property: "plant.rheemVariant",
        title: "Plant Type",
        hasDefault: false,
        isCalculated: false,
        type: FieldType.Choice,
        params: {
          choices: catalog.hotWaterPlant.rheemVariants!.map((i) => ({
            name: i.name,
            key: i.uid,
          })),
        },
        multiFieldId: "plant-rheemVariant",
        isShown: isRheem,
      },
    ),
    {
      property: "plant.rheemPeakHourCapacity",
      title: "Peak Hour Capacity",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Number,
      params: {
        min: 0,
        max: null,
      },
      multiFieldId: "plant-rheemPeakHourCapacity",
      slot: true,
      isShown: isRheemTank || isRheemElectric || isRheemHeatPump,
    },
    ...conditionallyAdd(
      isRheemElectric && {
        property: "plant.rheemMinimumInitialDelivery",
        title: "Minimum Initial Delivery",
        hasDefault: false,
        isCalculated: false,
        type: FieldType.Choice,
        params: {
          choices: [
            ...new Set(
              (
                Object.values(
                  catalog.hotWaterPlant.size.rheem![
                    (filled.plant as ReturnSystemPlant).rheemVariant!
                  ],
                ) as HotWaterPlantSizePropsElectric[]
              ).map((i) => i.minimumInitialDelivery),
            ),
          ].map((i) => ({ name: `${i}`, key: i })),
        },
        multiFieldId: "plant-rheemMinimumInitialDelivery",
        isShown: isRheemElectric,
      },
    ),
    ...conditionallyAdd(
      isRheemHeatPump && {
        property: "plant.rheemkWRating",
        title: "kW Rating",
        hasDefault: false,
        isCalculated: false,
        type: FieldType.Choice,
        params: {
          choices: [
            ...new Set(
              (
                Object.values(
                  catalog.hotWaterPlant.size.rheem![
                    (filled.plant as ReturnSystemPlant).rheemVariant!
                  ],
                ) as HotWaterPlantSizePropsHeatPump[]
              ).map((i) => i.kW),
            ),
          ].map((i) => ({ name: `${i}`, key: i })),
        },
        multiFieldId: "plant-rheemkWRating",
        isShown: isRheemHeatPump,
      },
    ),
    ...conditionallyAdd(
      isRheemHeatPump && {
        property: "plant.rheemStorageTankSize",
        title: "Storage Tank Size (L)",
        hasDefault: false,
        isCalculated: false,
        type: FieldType.Choice,
        params: {
          choices: Object.values(catalog.hotWaterPlant.storageTanks).map(
            (i) => ({
              name: `${i.capacity}`,
              key: i.capacity,
            }),
          ),
        },
        multiFieldId: "plant-rheemStorageTankSize",
        isShown: isRheemHeatPump,
      },
    ),
    {
      property: "plant.location",
      title: "Location",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Choice,
      params: {
        choices: auCatalog.greaseInterceptorTrap!.location.map((i) => ({
          name: i.name,
          key: i.uid,
        })),
      },
      multiFieldId: "plant-location",
      isShown: isDrainageGreaseInterTrap,
    },
    {
      property: "plant.capacityL",
      title: "Capacity",
      hasDefault: false,
      isCalculated: true,
      type: FieldType.Number,
      params: { min: null, max: null },
      multiFieldId: "capacityL",
      units: Units.Liters,
      isShown: isTank || isPumpTank,
    },
    ...conditionallyAdd(
      isDrainageGreaseInterTrap && {
        property: "plant.capacity",
        title: "Capacity",
        hasDefault: true,
        isCalculated: false,
        type: FieldType.Choice,
        params: {
          choices: Object.keys(
            auCatalog.greaseInterceptorTrap!.size[
              drawing.metadata.catalog.greaseInterceptorTrap![0]
                .manufacturer as GreaseInterceptorTrapManufacturers
            ]?.[(filled.plant as DrainageGreaseInterceptorTrap).location!]?.[
              (filled.plant as DrainageGreaseInterceptorTrap).position!
            ] || [],
          ).map((key) => ({
            name: key,
            key,
          })),
        },
        multiFieldId: "plant-capacity",
        slot: true,
        isShown: isDrainageGreaseInterTrap,
      },
    ),
    {
      property: "plant.peakFlowRateStorageMinutes",
      title: "Peak Flow Rate Storage",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: "peakFLowRateStorageMinutes",
      units: Units.Minutes,
      isShown: isTank || isPumpTank,
    },
    {
      property: "plant.pressureLoss.staticPressureKPA",
      title: "Static Pressure",
      hasDefault: !isPumpTank && !isRO,
      isCalculated: isPumpTank || isRO,
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: "plant-pressureLoss-staticPressureKPA",
      unitContext: pressureUnitsContext,
      units: Units.KiloPascals,
      isShown: isPressureMethodStaticPressure,
    },
    {
      property: "plant.targetPressureKPA",
      title: "Spare Pressure",
      hasDefault: true,
      hint: "Additional buffer pressure at most disadvantaged fixture/component",
      isCalculated: false,
      type: FieldType.Number,
      params: { min: null, max: null },
      units: Units.KiloPascals,
      unitContext: pressureUnitsContext,
      multiFieldId: "plant-targetPressureKPA",
      isShown: plantHasPump,
    },
    {
      property: "plant.pressureLoss.pressureMethod",
      title: "Pressure Type",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Choice,
      params: {
        choices: [
          {
            name: "Pump Duty",
            key: PressureMethod.PUMP_DUTY,
            disabled: false,
          },
          {
            name: "Dynamic Pressure Loss",
            key: PressureMethod.FIXED_PRESSURE_LOSS,
            disabled: false,
          },
        ],
      },
      multiFieldId: "plant-pressureLoss-pressureMethod",
      isShown: isCustom && !inletSystemVents,
    },
    {
      property: "plant.pressureLoss.pumpPressureKPA",
      title: "Pump Pressure",
      hasDefault:
        filled.plant.type !== PlantType.RADIATOR && !isPlantPump(filled),
      isCalculated: isPlantPump(filled),
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: "plant-pressureLoss-pumpPressureKPA",
      unitContext: pressureUnitsContext,
      units: Units.KiloPascals,
      isShown: !iAmDrainage && isPressureMethodPumpDuty,
    },
    {
      property: "plant.rating.type",
      type: FieldType.Choice,
      params: {
        choices: [
          { name: "Power", key: "energy" },
          { name: "Flow Rate", key: "flow-rate" },
        ],
      },
      hasDefault: false,
      isCalculated: false,
      multiFieldId: "plant-rating-type",
      title: "Rating Method",
      hint: getTooltip(tooltipsEntityName, "Rating Method"),
      isShown: hasSingleRating && !isReturnSystem,
      beforeSet: (newVal) => {
        const liveCalcs =
          context.globalStore.getOrCreateLiveCalculation(filled);
        const obj = context.globalStore.getObjectOfTypeOrThrow(
          EntityType.PLANT,
          filled.uid,
        );
        const plant = obj.entity.plant as RadiatorPlant;

        if (newVal === "flow-rate") {
          if (plant.rating.type === "energy") {
            if (
              liveCalcs.returnDeltaC != null &&
              liveCalcs.returnAverageC != null &&
              plant.rating.KW != null
            ) {
              plant.rating = {
                ...plant.rating,
                type: "flow-rate",
                LS: ReturnCalculations.KW2LS(
                  context,
                  obj.entity.inletSystemUid,
                  plant.rating.KW,
                  liveCalcs.returnDeltaC,
                  liveCalcs.returnAverageC,
                ),
              };
            } else {
              plant.rating = {
                ...plant.rating,
                type: "flow-rate",
                LS: null,
              };
            }
          }
        } else if (newVal === "energy") {
          if (plant.rating.type === "flow-rate") {
            if (
              liveCalcs.returnDeltaC != null &&
              liveCalcs.returnAverageC != null &&
              plant.rating.LS != null
            ) {
              plant.rating = {
                ...plant.rating,
                type: "energy",
                KW: ReturnCalculations.LS2KW(
                  context,
                  obj.entity.inletSystemUid,
                  plant.rating.LS,
                  liveCalcs.returnDeltaC,
                  liveCalcs.returnAverageC,
                ),
              };
            } else {
              plant.rating = {
                ...plant.rating,
                type: "energy",
                KW: null,
              };
            }
          }
        }
      },
    },
    {
      property: "plant.rating.KW",
      title: "Rating",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Number,
      params: {
        min: 0,
        max: null,
        initialValue: 0,
      },
      multiFieldId: "plant-ratingKW",
      units: Units.KiloWatts,
      isShown: !isReturnSystem && isEnergyLoad,
      unitContext: UnitsContext.MECHANICAL_ENERGY_MEASUREMENT,
    },
    {
      property: "plant.rating.LS",
      title: "Flow Rate Rating",
      hasDefault: false,
      isCalculated: false,
      requiresInput: isSingleSystemEmitter,
      type: FieldType.Number,
      params: {
        min: 0,
        max: null,
      },
      multiFieldId: "plant-ratingLS",
      units: Units.LitersPerSecond,
      isShown: isSingleSystemEmitter && isFlowRateLoad,
      unitContext: UnitsContext.NONE,
    },
    {
      property: "plant.capacityRateLKW",
      title: "Volume",
      hint: getTooltip(tooltipsEntityName, "Volume"),
      hasDefault: false,
      requiresInput: isSingleSystemEmitter,
      isCalculated: false,
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: "capacityRateLKW",
      units: Units.LitersPerKiloWatts,
      isShown: isSingleSystemEmitter,
      unitContext: UnitsContext.MECHANICAL_ENERGY_MEASUREMENT,
    },
    {
      property: "plant.pressureLoss.pressureLossKPA",
      title: "Pressure Loss",
      hint: getTooltip(tooltipsEntityName, "Pressure Drop"),
      hasDefault: filled.plant.type !== PlantType.RADIATOR,
      isCalculated: filled.plant.type === PlantType.FILTER,
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: "plant-pressureLoss-pressureLossKPA",
      units: Units.KiloPascals,
      unitContext: pressureUnitsContext,
      isShown: isPressureMethodFixedPressureLoss,
    },
    {
      property: "plant.volumeL",
      title: "Volume",
      hint: getTooltip(tooltipsEntityName, "Volume"),
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: "volumeL",
      units: Units.Liters,
      isShown: hasVolumeField,
    },
    {
      property: "plant.position",
      title: "Position",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Choice,
      params: {
        choices: [
          { name: "Below Ground", key: "belowGround" },
          { name: "Above Ground", key: "aboveGround" },
        ],
      },
      multiFieldId: "plant-position",
      isShown: isDrainageGreaseInterTrap,
    },
    {
      property: "plant.shape",
      title: "Shape",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Choice,
      params: {
        choices: [
          { name: "Rectangular", key: "RECTANGULAR" },
          { name: "Cylinder", key: "CYLINDER" },
        ],
      },
      multiFieldId: "plant-shape",
      isShown: isReturnSystem,
    },
    {
      property: "plant.diameterMM",
      title: "Diameter",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Number,
      params: { min: 0, max: null },
      units: Units.Millimeters,
      multiFieldId: "plant-diameter",
      readonly: areDimensionsReadonly(drawing, filled),
      isShown: filled.plant.shape === PlantShapes.CYLINDER,
      settingPropertyParam: {
        updateValue: handlers?.setWidth,
      },
    },
    {
      property: "plant.heightMM",
      title: "Height",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: null,
      units: Units.Millimeters,
      readonly: areDimensionsReadonly(drawing, filled),
      isShown: isHeatLoad && !isReturnSystem,
    },
    {
      property: "widthMM",
      title: "Width",
      hasDefault: !areDimensionsReadonly(drawing, filled),
      isCalculated: areDimensionsReadonly(drawing, filled),
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: null,
      units: Units.Millimeters,
      readonly: areDimensionsReadonly(drawing, filled),
      isShown: filled.plant.shape === PlantShapes.RECTANGULAR,
      settingPropertyParam: {
        updateValue: handlers?.setWidth,
      },
    },
    {
      property: "plant.lengthMM",
      title: "Length",
      hasDefault: !areDimensionsReadonly(drawing, filled),
      isCalculated: !!areDimensionsReadonly(drawing, filled),
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: null,
      units: Units.Millimeters,
      readonly: areDimensionsReadonly(drawing, filled),
      isShown: isDrainageGreaseInterTrap,
    },
    {
      property: "depthMM",
      title: "Depth",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: null,
      units: Units.Millimeters,
      isShown: true,
    },
    {
      property: "plant.minimumSystemVolumeL",
      title: "Minimum System Volume",
      hasDefault: false,
      isCalculated: false,
      requiresInput: false,
      type: FieldType.Number,
      params: {
        min: 0,
        max: null,
      },
      multiFieldId: null,
      units: Units.Liters,
      isShown: isHeatPump,
    },
    {
      property: "plant.maximumRecirculationPumpFlowLPS",
      title: "Maximum Recirculation Pump Flow",
      hasDefault: false,
      isCalculated: false,
      requiresInput: false,
      type: FieldType.Number,
      params: {
        min: 0,
        max: null,
      },
      multiFieldId: null,
      units: Units.LitersPerSecond,
      isShown: isHeatPump,
    },
    {
      property: "plant.maximumRecirculationPumpPressureKPA",
      title: "Maximum Recirculation Pump Pressure",
      hasDefault: false,
      isCalculated: false,
      requiresInput: false,
      type: FieldType.Number,
      params: {
        min: 0,
        max: null,
      },
      multiFieldId: null,
      units: Units.KiloPascals,
      isShown: isHeatPump,
    },
  ];

  return filterConditionalFields(res);
}

function conditionallyAdd(
  field: ConditionalPropertyField | boolean,
): ConditionalPropertyField[] {
  return (field && ([field] as ConditionalPropertyField[])) || [];
}
