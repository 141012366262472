import Vue from "vue";
import { ActionTree } from "vuex";
import { Workflow } from "../../../../common/src/api/document/drawing";
import * as OT from "../../../../common/src/api/document/operation-transforms";
import { OPERATION_NAMES } from "../../../../common/src/api/document/operation-transforms";
import { diffState } from "../../../../common/src/api/document/state-differ";
import {
  assertUnreachable,
  cloneSimple,
} from "../../../../common/src/lib/utils";
import { submitOperation } from "../../api/document";
import { dispatchIframeMessage } from "../../htmlcanvas/lib/iframe-parent-messages";
import { DrawingMode } from "../../htmlcanvas/types";
import { MainEventBus } from "../main-event-bus";
import { RootState } from "../types";
import { marshalChanges, updateOnboardingProgress } from "./mutations";
import { applyOpOntoStateVue } from "./operation-transforms/state-ot-apply";
import { DocumentState, EntityParam, UIState, blankDiffFilter } from "./types";
import { isViewOnly, shouldSyncWithServer } from "./utils";

export const actions: ActionTree<DocumentState, RootState> = {
  applyRemoteOperation({ commit }, op) {
    commit("applyRemoteOperation", op);
  },
  setPreviewMode({ commit }, value) {
    commit("setPreviewMode", value);
  },
  setActiveFlowSystem({ commit }, value) {
    commit("setActiveFlowSystem", value);
  },
  addEntity({ commit }, entity) {
    commit("addEntity", entity);
  },

  deleteEntity({ commit }, entity) {
    commit("deleteEntity", entity);
  },

  addEntityOn({ commit }, args: EntityParam) {
    commit("addEntityOn", args);
  },

  deleteEntityOn({ commit }, args: EntityParam) {
    commit("deleteEntityOn", args);
  },

  addLevel({ commit }, level) {
    commit("addLevel", level);
  },

  deleteLevel({ commit }, level) {
    commit("deleteLevel", level);
  },

  setCurrentLevelUid({ commit }, levelUid) {
    commit("setCurrentLevelUid", levelUid);
    MainEventBus.$emit("select", { uid: null });
  },

  updateHelpHub({ commit }) {
    commit("updateHelpHub");
  },

  validateAndCommit(_, logUndo: boolean = true) {
    MainEventBus.$emit("validate-and-commit", logUndo);
  },

  // Call this action to commit the current operation transforms. TODO: make that atomic.
  commit(
    { commit, state },
    { skipUndo, diffAll }: { skipUndo?: boolean; diffAll?: boolean } = {
      skipUndo: false,
      diffAll: false,
    },
  ) {
    // diffAll = true; // we are disabling diffing until it is correct.

    if (state.uiState.drawingMode === DrawingMode.History) {
      return;
    }

    if (isViewOnly(state.uiState)) {
      // commit("revert");
      return;
    }

    commit("liveCalcsCommit");

    // We have to clone to stop reactivity affecting the async post values later.
    // We choose to clone the resulting operations rather than the input for performance.
    const diff = diffState(
      state.committedDrawing,
      state.drawing,
      diffAll ? undefined : state.diffFilter,
    );

    try {
      const changes = marshalChanges(
        state.committedDrawing,
        state.drawing,
        (
          diff.filter(
            (d) => d.type === OPERATION_NAMES.DIFF_OPERATION,
          ) as OT.DiffOperation[]
        )[0]?.diff,
      );
      changes.forEach(([e, v]) => {
        updateOnboardingProgress(v.entity, e, state);
      });
    } catch (e) {
      console.error("unable to update project progress", e);
    }

    diff.forEach((v: OT.OperationTransformConcrete) => {
      if (v.type !== OPERATION_NAMES.DIFF_OPERATION) {
        throw new Error("diffState gave a weird operation");
      }
      applyOpOntoStateVue(state.committedDrawing, cloneSimple(v));
    });

    Vue.set(state, "diffFilter", blankDiffFilter());
    if (diff.length === 0) {
      return;
    }

    if (diff.length) {
      if (!skipUndo) {
        state.undoStack.splice(state.undoIndex);
        state.undoStack.push(cloneSimple(diff));
        state.undoIndex++;
      }
      diff.push({ type: OPERATION_NAMES.COMMITTED_OPERATION, id: -1 });
    }

    state.optimisticHistory.push(...cloneSimple(diff));

    const prevHistoryId = state.history.length
      ? state.history[state.history.length - 1].id
      : -1;

    if (shouldSyncWithServer(state.uiState)) {
      submitOperation(state.documentId, commit, cloneSimple(diff)).catch(
        (e) => {
          setUiStateViewOnly(
            state.uiState,
            "An error occured while saving the changes on the server",
            e,
          );
          this.dispatch("document/revert");
        },
      );

      setTimeout(() => {
        const thisHistoryId = state.history.length
          ? state.history[state.history.length - 1].id
          : -1;
        if (thisHistoryId === prevHistoryId) {
          setUiStateViewOnly(
            state.uiState,
            "Having trouble saving for the last 10 seconds, please refresh",
            null,
          );
        }
      }, 10000);
    }

    if (state.uiState.isEmbedded) {
      dispatchIframeMessage({
        type: "drawing",
        data: state.drawing,
      });
    }

    MainEventBus.$emit("committed", true);
  },

  requestLiveCalcs({ commit }) {
    commit("requestLiveCalcs");
  },

  resetPastes({ commit }) {
    commit("resetPastes");
  },

  undo(args) {
    const { commit, state, dispatch } = args;
    if (state.undoIndex && state.uiState.drawingMode !== DrawingMode.History) {
      state.undoIndex--;
      for (let i = state.undoStack[state.undoIndex].length - 1; i >= 0; i--) {
        const op = cloneSimple(state.undoStack[state.undoIndex][i]);
        switch (op.type) {
          case OPERATION_NAMES.DIFF_OPERATION:
            commit("applyDiff", op.inverse);
            break;
          case OPERATION_NAMES.COMMITTED_OPERATION:
            throw new Error("Don't know how to handle this");
          default:
            assertUnreachable(op);
        }
      }
    }

    dispatch("commit", { skipUndo: true });
  },

  redo(args) {
    const { commit, state, dispatch } = args;
    if (
      state.undoIndex < state.undoStack.length &&
      state.uiState.drawingMode !== DrawingMode.History
    ) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < state.undoStack[state.undoIndex].length; i++) {
        // get undo stack entry and clone it (not cloning it causes mutation issues down the road)
        const op = cloneSimple(state.undoStack[state.undoIndex][i]);
        switch (op.type) {
          case OPERATION_NAMES.DIFF_OPERATION:
            commit("applyDiff", op.diff);
            break;
          case OPERATION_NAMES.COMMITTED_OPERATION:
            throw new Error("Don't know how to handle this");
          default:
            assertUnreachable(op);
        }
      }
      state.undoIndex++;
    }

    dispatch("commit", { skipUndo: true });
  },

  setId({ commit }, payload) {
    commit("setId", payload);
  },

  setDocumentRecord({ commit }, payload) {
    commit("setDocumentRecord", payload);
  },

  swapDrawing({ commit }, newState) {
    commit("swapDrawing", newState);
  },

  revert({ commit }, redraw) {
    // We need to wait for entity mutation watchers to fire and update the filter.
    // Reverse all optimistic operations
    commit("revert", redraw);
  },

  revertFull({ commit }) {
    commit("revertFull");
  },

  closeAndReset({ commit }) {
    commit("closeAndReset");
  },

  resetDrawing({ commit }) {
    commit("resetDrawing");
  },

  documentLoaded({ commit, state }) {
    if (state.uiState.isEmbedded) {
      dispatchIframeMessage({
        type: "drawing",
        data: state.drawing,
      });
    }
    commit("documentLoaded");
  },

  replaceCatalog({ commit }) {
    commit("replaceCatalog");
  },

  updatePipeEndpoints({ commit }, params) {
    commit("updatePipeEndpoints", params);
  },

  setShareToken({ commit }, payload) {
    commit("setShareToken", payload);
  },

  incrementLiveCalculationRenderCounter({ commit }) {
    commit("incrementLiveCalculationRenderCounter");
  },

  enableWorkflow({ commit }, workflowKey: Workflow) {
    commit("setWorkflowEnabled", { workflowKey, enabled: true });
    commit("validateActiveFlowsystem");
  },

  disableWorkflow({ commit }, workflowKey: Workflow) {
    commit("setWorkflowEnabled", { workflowKey, enabled: false });
    commit("validateActiveFlowsystem");
  },

  toggleWorkflow({ commit, state }, workflowKey: Workflow) {
    commit("setWorkflowEnabled", {
      workflowKey,
      enabled: !state.drawing.metadata.workflows[workflowKey].enabled,
    });
    commit("validateActiveFlowsystem");
  },

  validateActiveFlowsystem({ commit }) {
    commit("validateActiveFlowsystem");
  },
};

export function setUiStateViewOnly(
  uiState: UIState,
  message: string,
  error: Error | null,
) {
  console.error("Marking UI State View Only", message, error);
  uiState.hasErrorConnecting = true;
  uiState.viewOnlyReason = message;
}
