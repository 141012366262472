import Flatten from "@flatten-js/core";
import * as TM from "transformation-matrix";
import { ICorePolygon } from "../../../../../common/src/api/coreObjects/core-traits/corePolygon";
import CoreBaseBackedObject from "../../../../../common/src/api/coreObjects/lib/coreBaseBackedObject";
import { CalculationData } from "../../../../../common/src/api/document/calculations-objects/calculation-field";
import { PolygonEntityConcrete } from "../../../../../common/src/api/document/entities/concrete-entity";
import { Coord } from "../../../../../common/src/lib/coord";
import { polygonIntersectionAreaM2 } from "../../../../../common/src/lib/mathUtils/mathutils";
import {
  DrawableObjectConcrete,
  isPolygonObject,
} from "../../objects/concrete-object";
import { createCalculationBoxes } from "../../objects/utils";
import { DrawingContext } from "../types";
import { IDrawableObject } from "./core2drawable";
import { CollisionLayer } from "./magic-draggable-object";
import { SnapIntention, SnapTarget } from "./snappable-object";

export function PolygonObject<
  // I extends ConnectableEntityConcrete,
  Obj extends abstract new (
    ...args: any[]
  ) => CoreBaseBackedObject<PolygonEntityConcrete> &
    IDrawableObject &
    ICorePolygon,
>(Base: Obj) {
  abstract class Generated extends Base {
    locateCalculationBoxWorld(
      context: DrawingContext,
      data: CalculationData[],
      scale: number,
    ): TM.Matrix[] {
      const roomEntity = this.entity;
      const coreRoom = context.globalStore.getPolygonObjectOrThrow(
        roomEntity.uid,
      );

      const polygon: Flatten.Polygon = new Flatten.Polygon();
      polygon.addFace(
        coreRoom
          .collectVerticesInOrder()
          .map(
            (p) => new Flatten.Point(p.toWorldCoord().x, p.toWorldCoord().y),
          ),
      );

      const centerX = (polygon.box.xmin + polygon.box.xmax) / 2;
      const centerY = (polygon.box.ymin + polygon.box.ymax) / 2;
      const centerPoint = new Flatten.Point(centerX, centerY);

      const coordinates: Coord[] = [];
      coordinates.push({
        x: centerPoint.x,
        y: centerPoint.y,
      });

      const angle = 0;

      return coordinates.flatMap((coord) => {
        return createCalculationBoxes({
          wc: coord,
          angle: angle,
          scale: scale,
          distanceCandidates: [200],
          data: data,
        });
      });
    }

    getSnapTargets(_request: SnapIntention[], _mouseWc: Coord): SnapTarget[] {
      throw new Error("Method not implemented.");
    }

    abstract isValid(): boolean;

    abstract get collisionLayers(): CollisionLayer[];

    collidesWithLayers(layers: CollisionLayer[]): boolean {
      console.log("Checking collision", {
        thisUid: this.uid,
        layers: layers,
        thisLayers: this.collisionLayers,
      });
      return this.collisionLayers.some((layer) => layers.includes(layer));
    }

    invalidOverlappingAreaM2(
      pointArr: { point: Flatten.Point; uid: string }[] = [],
    ) {
      let polygon = new Flatten.Polygon();
      if (pointArr.length) polygon.addFace(pointArr.map((p) => p.point));
      else polygon = this.shape;
      const box = polygon.box;
      const res = this.globalStore.spatialIndex
        .get(this.document.uiState.levelUid!)
        ?.search({
          minX: box.xmin,
          minY: box.ymin,
          maxX: box.xmax,
          maxY: box.ymax,
        })
        .map((item) => this.globalStore.get(item.uid) as DrawableObjectConcrete)
        .filter((obj) => {
          return (
            obj &&
            isPolygonObject(obj) &&
            obj.layersCollide(this.collisionLayers) &&
            obj.uid !== this.uid
          );
        });
      let myPolygon: Coord[];
      if (pointArr.length) myPolygon = pointArr.map((p) => p.point);
      else
        myPolygon = this.collectVerticesInOrder().map((v) => v.toWorldCoord());

      let intersectionAreaM2 = 0;
      for (const polygon of res || []) {
        if (!isPolygonObject(polygon)) {
          continue;
        }
        const coords: Coord[] = polygon
          .collectVerticesInOrder()
          .map((v) => v.toWorldCoord());

        intersectionAreaM2 += polygonIntersectionAreaM2(myPolygon, coords);
      }

      return intersectionAreaM2;
    }
  }

  return Generated;
}
