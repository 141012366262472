import { isNotNullAndUndefined } from "./utils";

/**
 * This is a helper from the scala programming language (hence the name)
 *
 * The simple explanation is that its `.map().filter(x => x !== null && x !== undefined)`
 * This saves an unnecessary second iteration which can really matter on large lists.
 * Some prefer to call it `mapDefined`
 *
 * The more complex explanation is that it's fun with Functors and Partial Functions.
 */
export function collect<A, B>(
  items: A[],
  f: (t: A) => B | undefined | null,
): B[] {
  const result: B[] = [];
  for (const item of items) {
    const newItem = f(item);
    if (isNotNullAndUndefined(newItem)) {
      result.push(newItem);
    }
  }
  return result;
}
