import { assertType, assertUnreachable, cloneSimple } from "../../lib/utils";
import { GetPressureLossOptions } from "../calculations/entity-pressure-drops";
import {
  AirHandlingUnitBreakdown,
  BallValveBreakdown,
  BlendingValveBreakdown,
  CoreContext,
  CostBreakdown,
  CostBreakdownEntry,
  FanCoilUnitBreakdown,
  ManifoldBreakdown,
  PressureLossResult,
  PumpPackBreakdown,
  RadiatorBreakdown,
  UfhPumpBreakdown,
  UnderFloorHeatingBreakdown,
} from "../calculations/types";
import { CalculationConcrete } from "../document/calculations-objects/calculation-concrete";
import PlantEntity, {
  FilterPlantEntity,
  ManifoldPlantEntity,
  ROPlantEntity,
  isHeatLoadPlant,
} from "../document/entities/plants/plant-entity";
import {
  ManifoldPlant,
  PlantShapes,
  PlantType,
  RadiatorPlant,
  RheemVariantValues,
  configurationToName,
} from "../document/entities/plants/plant-types";
import { EntityType } from "../document/entities/types";
import CoreCentered from "./core-traits/coreCentered";
import { SelectionTarget } from "./lib/types";
import {
  getIdentityCalculationEntityUid,
  getInletsOutletSpecsInPlant,
  getPlantPressureLoss,
  isOutletWithInletInPlant,
} from "./utils";

import Flatten from "@flatten-js/core";
import {
  getPlantDatasheet,
  isDualSystemNodePlant,
  isMultiOutlets as isPlantMultiOutlets,
} from "../../../../common/src/api/document/entities/plants/utils";
import { collect } from "../../lib/array-utils";
import { Coord } from "../../lib/coord";
import { convertCurrencyByContext } from "../../lib/currency";
import {
  getFluidDensityOfSystem,
  head2kpa,
} from "../calculations/pressure-drops";
import { HeatLossResultKW } from "../calculations/returns";
import {
  getManufacturerRecord,
  getManufacturerRecordForManifoldComponent,
} from "../catalog/manufacturers/utils";
import { ManufacturerRadiatorData, State } from "../catalog/types";
import {
  BallValveModel,
  ManifoldModel,
  PumpPackModel,
} from "../catalog/underfloor-heating/ufh-types";
import {
  lookupBlendingValve,
  lookupUFHPump,
  manifoldHasBlendingValve,
  manifoldHasPumpPack,
  manifoldHasUfhPump,
} from "../catalog/underfloor-heating/utils";
import { isClosedSystem, isHeatingPlantSystem } from "../config";
import { PlantLiveCalculation } from "../document/calculations-objects/plant-calculation";
import { full2liveLayouts } from "../document/calculations-objects/warnings";
import { fillPlantDefaults } from "../document/entities/plants/plant-defaults";
import { FlowSystem } from "../document/flow-systems";
import { getFlowSystem } from "../document/utils";
import CoreConduit from "./coreConduit";
import CoreSystemNode from "./coreSystemNode";
import { CoreCalculatableObject } from "./lib/CoreCalculatableObject";
import CoreBaseBackedObject from "./lib/coreBaseBackedObject";

export const BIG_VALVE_DEFAULT_PIPE_WIDTH_MM = 20;
export const DEFAULT_SYSTEM_NODE_SIZE = 60;
export const RECIRCULATION_SYSTEM_NODE_SIZE = 120;
export const DEFAULT_EQUIPMENT_INFLUENCE_RADIUS_MM = 500;
export const MAX_EQUIPMENT_INFLUENCE_RADIUS_MM = 2000;

export interface InletsOutletsSpec {
  uid: string;
  systemUid: string;
  type: "inlet" | "outlet";
  position: "left" | "right";
  length:
    | typeof DEFAULT_SYSTEM_NODE_SIZE
    | typeof RECIRCULATION_SYSTEM_NODE_SIZE;
  isReturn: boolean;
  isRecirculation: boolean;
  heightAboveFloorM: number;
}

export default class CorePlant extends CoreCentered(
  CoreCalculatableObject<PlantEntity>,
) {
  type: EntityType.PLANT = EntityType.PLANT;

  get refPath(): string {
    const plantSubtype = this.plantSubtype;
    if (plantSubtype) {
      return `${this.entity.type}.${this.entity.plant.type}.${plantSubtype}`;
    }
    return `${this.entity.type}.${this.entity.plant.type}`;
  }

  get plantSubtype(): string | undefined {
    switch (this.entity.plant.type) {
      case PlantType.RETURN_SYSTEM:
        return this.entity.plant.returnSystemType;
      case PlantType.RADIATOR:
        return this.entity.plant.radiatorType;
      case PlantType.FILTER:
        return this.entity.plant.filterType;
      case PlantType.TANK:
      case PlantType.PUMP:
      case PlantType.PUMP_TANK:
      case PlantType.DRAINAGE_PIT:
      case PlantType.CUSTOM:
      case PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP:
      case PlantType.VOLUMISER:
      case PlantType.MANIFOLD:
      case PlantType.UFH:
      case PlantType.AHU:
      case PlantType.AHU_VENT:
      case PlantType.FCU:
      case PlantType.RO:
      case PlantType.DUCT_MANIFOLD:
        return undefined;
    }
    assertUnreachable(this.entity.plant);
  }

  get filledEntity(): PlantEntity {
    return fillPlantDefaults(this.context, this.entity);
  }

  static readonly withGas = [
    null,
    RheemVariantValues.continuousFlow,
    RheemVariantValues.tankpak,
  ] as any[];

  getFrictionPressureLossKPA({
    context,
    flowLS,
    from,
    to,
    signed,
    pressurePushMode,
    ignoreCalculatedDefaults,
  }: GetPressureLossOptions): PressureLossResult {
    const filled = fillPlantDefaults(context, this.entity);
    const calc = context.globalStore.getOrCreateCalculation(this.entity);

    let sign = 1;
    if (flowLS < 0) {
      const oldFrom = from;
      from = to;
      to = oldFrom;
      flowLS = -flowLS;
      if (signed) {
        sign = -1;
      }
    }

    // loop through all the outlets and check
    if (from.connectable === filled.inletSystemUid) {
      if (isPlantMultiOutlets(filled.plant)) {
        if (isDualSystemNodePlant(filled.plant)) {
          // TODO HANDLE THIS CASE
          if (
            to.connectable !== filled.plant.heatingSystemUid &&
            to.connectable !== filled.plant.chilledSystemUid
          ) {
            throw new Error("Misconfigured flow network");
          }
        } else if (filled.plant.type === PlantType.DUCT_MANIFOLD) {
          if (
            !filled.plant.outlets.some(
              (outlet) => to.connectable === outlet.uid,
            )
          ) {
            throw new Error("Misconfigured flow network");
          }
        } else if (
          filled.plant.outlets.every(
            (outlet) => to.connectable !== outlet.outletSystemUid,
          )
        ) {
          throw new Error("Misconfigured flow network");
        }
      } else {
        if (to.connectable !== filled.plant.outletSystemUid) {
          throw new Error("Misconfigured flow network");
        }
      }
    }

    if (to.connectable === filled.inletSystemUid) {
      if (isPlantMultiOutlets(filled.plant)) {
        if (isDualSystemNodePlant(filled.plant)) {
          // TODO HANDLE THIS CASE
          if (
            from.connectable !== filled.plant.heatingSystemUid &&
            from.connectable !== filled.plant.chilledSystemUid
          ) {
            throw new Error("Misconfigured flow network");
          }
        } else if (filled.plant.type === PlantType.DUCT_MANIFOLD) {
          if (
            !filled.plant.outlets.some(
              (outlet) => from.connectable === outlet.uid,
            )
          ) {
            throw new Error("Misconfigured flow network");
          }
        } else if (
          filled.plant.outlets.every(
            (outlet) => from.connectable !== outlet.outletSystemUid,
          )
        ) {
          throw new Error("Misconfigured flow network");
        }
      } else {
        if (from.connectable !== filled.plant.outletSystemUid) {
          throw new Error("Misconfigured flow network");
        }
      }

      return { pressureLossKPA: sign * (1e10 + flowLS) };
    }

    const sysNode = context.globalStore.getObjectOfTypeOrThrow(
      EntityType.SYSTEM_NODE,
      from.connectable,
    );

    const pl = getPlantPressureLoss(
      context,
      filled,
      calc,
      sysNode.entity.systemUid,
      {
        flowLS,
        pressurePushMode,
        ignoreCalculatedDefaults,
        outletSystemUid: to.connectable,
      },
    );

    const ios = this.getInletsOutletSpecs();
    let srcHeightM: number | null = null;
    let destHeightM: number | null = null;
    let system: FlowSystem | null = null;

    for (const io of ios) {
      if (io.uid === from.connectable) {
        srcHeightM = io.heightAboveFloorM;
        system = context.drawing.metadata.flowSystems[io.systemUid];
      }
      if (io.uid === to.connectable) {
        destHeightM = io.heightAboveFloorM;
      }
    }

    if (
      srcHeightM !== null &&
      destHeightM !== null &&
      system &&
      pl.pressureLossKPA !== null
    ) {
      const fluid = context.catalog.fluids[system.fluid];
      // For ventilation, we are going to assume zero static pressure
      // as it is balanced out by atmospheric pressure change for all
      // intents and purposes.
      if (fluid.state !== State.AIR) {
        const deltaH = destHeightM - srcHeightM;
        if (deltaH > 0) {
          const fromHeightDifferenceKPA = head2kpa(
            deltaH,
            getFluidDensityOfSystem(context, system.uid)!,
            context.drawing.metadata.calculationParams
              .gravitationalAcceleration,
          );
          pl.pressureLossKPA += fromHeightDifferenceKPA;
        }
      }
    }

    if (pl.pressureLossKPA === null) {
      return pl;
    }

    return {
      ...pl,
      pressureLossKPA: sign * pl.pressureLossKPA,
    };
  }

  getLengthThroughPlantM(): number {
    if (this.entity.plant.type === PlantType.MANIFOLD) {
      if (!this.entity.plant.hasRecirculationPump) {
        const calc = this.context.globalStore.getOrCreateCalculation(
          this.entity,
        );
        let maxLengthM = 0;
        for (const loop of calc.manifoldLoopStats) {
          if (loop.lengthM && loop.lengthM > maxLengthM) {
            maxLengthM = loop.lengthM;
          }
        }
        return maxLengthM;
      }
    }
    return 0;
  }

  getCalculationEntities(context: CoreContext): [PlantEntity] {
    const e: PlantEntity = cloneSimple(this.entity);
    e.parentUid = getIdentityCalculationEntityUid(context, e.parentUid);
    e.uid = this.getCalculationUid(context);

    e.inletUid = e.inletUid
      ? (this.globalStore
          .getObjectOfType(EntityType.SYSTEM_NODE, e.inletUid)
          ?.getCalculationNode(context, this.uid)?.uid ?? null)
      : null;

    let manufacturer = "generic";
    switch (e.plant.type) {
      case PlantType.RETURN_SYSTEM:
        manufacturer =
          context.drawing.metadata.catalog.hotWaterPlant.find(
            (i) => i.uid === "hotWaterPlant",
          )?.manufacturer || manufacturer;

        e.plant.outlets = e.plant.outlets.map((outlet) => {
          if (outlet.outletReturnUid) {
            outlet.outletReturnUid =
              this.globalStore
                .getObjectOfType(EntityType.SYSTEM_NODE, outlet.outletReturnUid)
                ?.getCalculationNode(context, this.uid).uid ?? null;
          }
          if (outlet.outletUid) {
            outlet.outletUid =
              this.globalStore
                .getObjectOfType(EntityType.SYSTEM_NODE, outlet.outletUid)
                ?.getCalculationNode(context, this.uid).uid ?? null;
          }
          return outlet;
        });

        if (
          manufacturer === "generic" ||
          (manufacturer === "rheem" &&
            CorePlant.withGas.includes(e.plant.rheemVariant!))
        ) {
          e.plant.gasNodeUid = e.plant.gasNodeUid
            ? (this.globalStore
                .getObjectOfType(EntityType.SYSTEM_NODE, e.plant.gasNodeUid)
                ?.getCalculationNode(context, this.uid).uid ?? null)
            : null;
        }

        e.plant.preheats = e.plant.preheats.map((preheat) => {
          preheat.inletUid = this.globalStore
            .getObjectOfTypeOrThrow(EntityType.SYSTEM_NODE, preheat.inletUid)
            .getCalculationNode(context, this.uid).uid;

          preheat.returnUid = this.globalStore
            .getObjectOfTypeOrThrow(EntityType.SYSTEM_NODE, preheat.returnUid)
            .getCalculationNode(context, this.uid).uid;

          return preheat;
        });

        break;

      case PlantType.AHU_VENT:
        if (e.plant.supplyUid) {
          e.plant.supplyUid =
            this.globalStore
              .getObjectOfType(EntityType.SYSTEM_NODE, e.plant.supplyUid)
              ?.getCalculationNode(context, this.uid).uid ?? null;
        }

        if (e.plant.extractUid) {
          e.plant.extractUid =
            this.globalStore
              .getObjectOfType(EntityType.SYSTEM_NODE, e.plant.extractUid)
              ?.getCalculationNode(context, this.uid).uid ?? null;
        }

        if (e.plant.intakeUid) {
          e.plant.intakeUid =
            this.globalStore
              .getObjectOfType(EntityType.SYSTEM_NODE, e.plant.intakeUid)
              ?.getCalculationNode(context, this.uid).uid ?? null;
        }

        if (e.plant.exhaustUid) {
          e.plant.exhaustUid =
            this.globalStore
              .getObjectOfType(EntityType.SYSTEM_NODE, e.plant.exhaustUid)
              ?.getCalculationNode(context, this.uid).uid ?? null;
        }
      case PlantType.AHU:
      case PlantType.FCU:
        if (e.plant.heatingInletUid) {
          e.plant.heatingInletUid =
            this.globalStore
              .getObjectOfType(EntityType.SYSTEM_NODE, e.plant.heatingInletUid)
              ?.getCalculationNode(context, this.uid).uid ?? null;
        }

        if (e.plant.heatingOutletUid) {
          e.plant.heatingOutletUid =
            this.globalStore
              .getObjectOfType(EntityType.SYSTEM_NODE, e.plant.heatingOutletUid)
              ?.getCalculationNode(context, this.uid).uid ?? null;
        }

        if (e.plant.chilledInletUid) {
          e.plant.chilledInletUid =
            this.globalStore
              .getObjectOfType(EntityType.SYSTEM_NODE, e.plant.chilledInletUid)
              ?.getCalculationNode(context, this.uid).uid ?? null;
        }

        if (e.plant.chilledOutletUid) {
          e.plant.chilledOutletUid =
            this.globalStore
              ?.getObjectOfType(
                EntityType.SYSTEM_NODE,
                e.plant.chilledOutletUid,
              )
              ?.getCalculationNode(context, this.uid).uid ?? null;
        }

        if (e.plant.heatingRooms) {
          e.plant.heatingRooms = collect(e.plant.heatingRooms, (roomUid) => {
            return this.globalStore
              .getObjectOfType(EntityType.ROOM, roomUid)
              ?.getCalculationUid(context);
          });
        }

        if (e.plant.chilledRooms) {
          e.plant.chilledRooms = e.plant.chilledRooms
            .map((roomUid) => {
              return this.globalStore
                .getObjectOfTypeOrThrow(EntityType.ROOM, roomUid)
                .getCalculationUid(context);
            })
            .filter(Boolean);
        }
        break;
      case PlantType.DUCT_MANIFOLD:
        for (const outlet of e.plant.outlets) {
          outlet.uid = this.globalStore
            .getObjectOfTypeOrThrow(EntityType.SYSTEM_NODE, outlet.uid)
            .getCalculationNode(context, this.uid).uid;
        }
        break;
      case PlantType.TANK:
      case PlantType.CUSTOM:
      case PlantType.PUMP:
      case PlantType.DRAINAGE_PIT:
      case PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP:
      case PlantType.RADIATOR:
      case PlantType.PUMP_TANK:
      case PlantType.VOLUMISER:
      case PlantType.UFH:
      case PlantType.MANIFOLD:
      case PlantType.FILTER:
      case PlantType.RO:
        e.plant.outletUid = this.globalStore
          .getObjectOfTypeOrThrow(EntityType.SYSTEM_NODE, e.plant.outletUid)
          .getCalculationNode(context, this.uid).uid;
        break;
      default:
        assertUnreachable(e.plant);
    }

    return [e];
  }

  collectCalculations(context: CoreContext): CalculationConcrete {
    return context.globalStore.getOrCreateCalculation(
      this.getCalculationEntities(context)[0],
    );
  }

  collectLiveCalculations(context: CoreContext): PlantLiveCalculation {
    const result = context.globalStore.getOrCreateLiveCalculation(
      this.getCalculationEntities(context)[0],
    );
    const calc = context.globalStore.getOrCreateCalculation(
      this.getCalculationEntities(context)[0],
    );
    result.heatingRatingKW = calc.heatingRatingKW;
    result.chilledRatingKW = calc.chilledRatingKW;
    result.returnDeltaC = calc.returnDeltaC;
    result.returnAverageC = calc.returnAverageC;
    result.widthMM = calc.widthMM;
    result.heightMM = calc.heightMM;
    result.depthMM = calc.depthMM;
    result.volumeL = calc.volumeL;
    result.internalVolumeL = calc.internalVolumeL;
    result.kvValue = calc.kvValue;
    result.SCOP = calc.SCOP;
    result.SPF = calc.SPF;
    result.manifoldId = calc.manifoldId;
    result.associatedRoomUid = calc.associatedRoomUid;
    result.pressureDropKPA = calc.pressureDropKPA;
    result.manifoldLoopStats = calc.manifoldLoopStats;
    result.manifoldManufacturers = calc.manifoldManufacturers;
    result.problemSystemNodes = calc.problemSystemNodes;
    if (calc.warnings) {
      for (const warning of calc.warnings) {
        if (warning.type === "FLOW_SYSTEM_NOT_CONNECTED_TO_PLANT") {
          result.warnings.push({
            type: "FLOW_SYSTEM_NOT_CONNECTED_TO_PLANT",
            layouts: full2liveLayouts(warning.layouts),
          });
        }
      }
    }
    return result;
  }

  costBreakdown(context: CoreContext): CostBreakdown {
    const filled = fillPlantDefaults(context, this.entity);
    const calc = context.globalStore.getOrCreateCalculation(this.entity);

    switch (this.entity.plant.type) {
      case PlantType.RETURN_SYSTEM:
        let priceBreakdown: CostBreakdown = {
          cost: 0,
          breakdown: [],
        };
        if (this.entity.plant.returnType === "pressure") {
          priceBreakdown = {
            cost: context.priceTable.Plants["Hot Water Plant"],
            breakdown: [
              {
                qty: 1,
                path: `Plants.Hot Water Plant`,
                type: "plant",
                name: filled.name ? filled.name : "",
                manufacturer:
                  context.drawing.metadata.catalog.hotWaterPlant.find(
                    (i) => i.uid === "hotWaterPlant",
                  )?.manufacturer || "generic",
              },
            ],
          };
        } else {
          priceBreakdown = {
            cost: context.priceTable.Plants.Custom,
            breakdown: [
              {
                qty: 1,
                path: `Plants.Custom`,
                type: "plant",
                name: filled.name ? filled.name : "",
              },
            ],
          };
        }

        const outlets = this.entity.plant.outlets;
        for (const outlet of outlets) {
          if (outlet.addRecirculation) {
            priceBreakdown.cost +=
              context.priceTable.Plants["Recirculation Pump"];
            priceBreakdown.breakdown.push({
              qty: 1,
              path: `Plants.Recirculation Pump`,
              type: "plant",
              name: filled.name ? filled.name : "",
              manufacturer:
                context.drawing.metadata.catalog.hotWaterPlant.find(
                  (i) => i.uid === "circulatingPumps",
                )?.manufacturer || "generic",
            });
          }
        }
        return priceBreakdown;
      case PlantType.TANK:
        return {
          cost: context.priceTable.Plants["Storage Tank"],
          breakdown: [
            {
              qty: 1,
              path: `Plants.Storage Tank`,
              type: "plant",
              name: filled.name ? filled.name : "",
            },
          ],
        };
      case PlantType.PUMP_TANK:
        return {
          cost: context.priceTable.Plants["Pump/Tank"],
          breakdown: [
            {
              qty: 1,
              path: `Plants.Pump/Tank`,
              type: "plant",
              name: filled.name ? filled.name : "",
              manufacturer: context.drawing.metadata.catalog.pumpTank.find(
                (i) => i.uid === "tank",
              )?.manufacturer,
            },
          ],
        };
      case PlantType.CUSTOM:
        return {
          cost: context.priceTable.Plants.Custom,
          breakdown: [
            {
              qty: 1,
              path: `Plants.Custom`,
              type: "plant",
              name: filled.name ? filled.name : "",
            },
          ],
        };
      case PlantType.PUMP:
        return {
          cost: context.priceTable.Plants.Pump,
          breakdown: [
            {
              qty: 1,
              path: `Plants.Pump`,
              type: "plant",
              name: filled.name ? filled.name : "",
              manufacturer: context.drawing.metadata.catalog.pump.find(
                (i) => i.uid === "pump",
              )?.manufacturer,
            },
          ],
        };
      case PlantType.DRAINAGE_PIT:
        return {
          cost: context.priceTable.Equipment["Drainage Pit"],
          breakdown: [
            {
              qty: 1,
              path: "Equipment.Drainage Pit",
              type: "plant",
              name: filled.name ? filled.name : "",
            },
          ],
        };
      case PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP:
        return {
          cost: context.priceTable.Equipment["Grease Interceptor Trap"],
          breakdown: [
            {
              qty: 1,
              path: "Equipment.Grease Interceptor Trap",
              manufacturer:
                context.drawing.metadata.catalog.greaseInterceptorTrap!.find(
                  (i) => i.uid === "greaseInterceptorTrap",
                )?.manufacturer,
            },
          ],
        };
      case PlantType.RADIATOR:
        const filledRadiator = filled.plant as RadiatorPlant;
        let rangeType;
        let widthMM;
        let heightMM;
        let manufacturer;
        let model;
        let costOverride;
        let productKey;
        let radCost = context.priceTable.Equipment.Emitter;

        switch (filledRadiator.radiatorType) {
          case "fixed":
            rangeType = null;
            widthMM = filledRadiator.widthMM;
            heightMM = filledRadiator.heightMM;
            manufacturer = "generic";
            break;
          case "specify":
            rangeType = filledRadiator.rangeType;
            widthMM = calc.widthMM;
            heightMM = calc.heightMM;
            manufacturer =
              getManufacturerRecord(this.entity, this.context.catalog)?.name ??
              "generic";

            if (filledRadiator.manufacturer !== "generic") {
              model = calc.model;
            }

            if (model) {
              const modelData = getPlantDatasheet(
                filledRadiator,
                this.context.catalog,
                false,
                {
                  model: String(calc.model),
                  widthMM: calc.widthMM!,
                  heightMM: calc.heightMM!,
                },
              )[0] as ManufacturerRadiatorData;

              if (modelData) {
                if (modelData.productKey) {
                  productKey = modelData.productKey;
                }

                if (modelData.price) {
                  const localPrice = convertCurrencyByContext(
                    this.context,
                    modelData.price,
                  );
                  radCost = costOverride = localPrice;
                }
              }
            }
        }

        return {
          cost: radCost,
          breakdown: [
            {
              qty: 1,
              path: "Equipment.Emitter",
              type: "radiator",
              widthMM: widthMM || 0,
              heightMM: heightMM || 0,
              rangeType,
              ratingKW: calc.heatingRatingKW?.toFixed(3) || 0,
              ...(manufacturer ? { manufacturer } : {}),
              ...(model ? { model } : {}),
              ...(costOverride ? { costOverride } : {}),
              ...(productKey ? { productKey } : {}),
            } as RadiatorBreakdown,
          ],
        };
      case PlantType.AHU:
      case PlantType.AHU_VENT:
        return {
          cost: context.priceTable.Equipment.Emitter,
          breakdown: [
            {
              qty: 1,
              path: "Equipment.Emitter",
              type: "ahu",
              widthMM: filled.widthMM,
              heightMM: filled.depthMM,
              chilledRatingKW: calc.chilledRatingKW?.toFixed(3) || 0,
              heatingRatingKW: calc.heatingRatingKW?.toFixed(3) || 0,
            } as AirHandlingUnitBreakdown,
          ],
        };
      case PlantType.FCU:
        return {
          cost: context.priceTable.Equipment.Emitter,
          breakdown: [
            {
              qty: 1,
              path: "Equipment.Emitter",
              type: "fcu",
              widthMM: filled.widthMM,
              heightMM: filled.depthMM,
              chilledRatingKW: calc.chilledRatingKW?.toFixed(3) || 0,
              heatingRatingKW: calc.heatingRatingKW?.toFixed(3) || 0,
            } as FanCoilUnitBreakdown,
          ],
        };
      case PlantType.MANIFOLD:
        assertType<ManifoldPlantEntity>(filled);
        const manifoldManuf = getManufacturerRecord(
          filled,
          this.context.catalog,
          "manifold",
        );
        const ballValveManuf = getManufacturerRecord(
          filled,
          this.context.catalog,
          "ballValve",
        );
        const pumpPackManuf = getManufacturerRecord(
          filled,
          this.context.catalog,
          "pumpPack",
        );

        const ufhPumpManuf = getManufacturerRecordForManifoldComponent(
          filled,
          this.context.catalog,
          "pump",
          calc.manifoldManufacturers.pumpId,
        );

        const blendingValveManuf = getManufacturerRecordForManifoldComponent(
          filled,
          this.context.catalog,
          "blendingValve",
          calc.manifoldManufacturers.blendingValveId,
        );

        assertType<ManifoldPlant>(filled.plant);
        let manifoldModel: ManifoldModel | null = null;
        if (calc.manifoldManufacturers.manifoldModel) {
          manifoldModel =
            context.catalog.underfloorHeating.manifold.datasheet[
              filled.plant.manifoldManufacturer!
            ][filled.plant.manifoldRange!][
              calc.manifoldManufacturers.manifoldModel
            ];
        }

        let ballValveModel: BallValveModel | null = null;
        if (calc.manifoldManufacturers.ballValveModel) {
          ballValveModel =
            context.catalog.underfloorHeating.ballValve.datasheet[
              filled.plant.ballValveManufacturer!
            ][calc.manifoldManufacturers.ballValveModel];
        }

        let pumpPackModel: PumpPackModel | null = null;
        if (calc.manifoldManufacturers.pumpPackModel) {
          pumpPackModel =
            context.catalog.underfloorHeating.pumpPack.datasheet[
              filled.plant.pumpPackManufacturer!
            ][calc.manifoldManufacturers.pumpPackModel];
        }

        const ufhPumpModel = lookupUFHPump(
          context,
          calc.manifoldManufacturers.pumpId,
        );
        const blendingValveModel = lookupBlendingValve(
          context,
          calc.manifoldManufacturers.blendingValveId,
        );

        const breakdown: CostBreakdownEntry[] = [
          {
            qty: 1,
            path: "Equipment.Underfloor Manifold",
            type: "manifold",
            widthMM: filled.widthMM ?? 0,
            heightMM: filled.depthMM ?? 0,
            ports: calc.manifoldLoopStats.length,
            ratingKW:
              (calc.heatingRatingKW
                ? Number(calc.heatingRatingKW.toFixed(3))
                : 0) ?? 0,
            ...(manifoldManuf ? { manufacturer: manifoldManuf?.name } : {}),
            ...(manifoldModel ? { model: manifoldModel.model } : {}),
            ...(manifoldModel ? { productKey: manifoldModel.productKey } : {}),
            ...(manifoldModel
              ? { description: manifoldModel.description }
              : {}),
            ...(manifoldModel ? { range: manifoldModel.range } : {}),
            ...(manifoldModel && manifoldModel.price
              ? {
                  costOverride: convertCurrencyByContext(
                    this.context,
                    manifoldModel.price,
                  ),
                }
              : {}),
            uid: this.uid,
          } satisfies ManifoldBreakdown,
        ];

        if (this.context.featureAccess.fullUnderfloorHeatingLoops) {
          breakdown.push({
            qty: 2,
            path: "Equipment.Underfloor Ball Valve",
            type: "ball-valve",
            ...(ballValveManuf ? { manufacturer: ballValveManuf.name } : {}),
            ...(ballValveModel ? { model: ballValveModel.model } : {}),
            ...(ballValveModel ? { sizeMM: ballValveModel.sizesMM[0] } : {}), // need to update the size
            ...(ballValveModel
              ? { productKey: ballValveModel.productKey }
              : {}),
            ...(ballValveModel
              ? { description: ballValveModel.description }
              : {}),
            parentUid: this.uid,
          } satisfies BallValveBreakdown);

          if (manifoldHasPumpPack(context, filled)) {
            breakdown.push({
              qty: 1,
              path: "Equipment.Underfloor Pump Pack",
              type: "pump-pack",
              pumpDescription: manifoldHasUfhPump(context, filled)
                ? [ufhPumpManuf?.name, ufhPumpModel?.description]
                    .filter((x) => !!x)
                    .join(" ")
                : undefined,
              blendingValveDescription: manifoldHasBlendingValve(
                context,
                filled,
              )
                ? [blendingValveManuf?.name, blendingValveModel?.description]
                    .filter((x) => !!x)
                    .join(" ")
                : undefined,
              ...(pumpPackModel ? { model: pumpPackModel.model } : {}),
              ...(pumpPackModel ? { manufacturer: pumpPackManuf?.name } : {}),
              ...(pumpPackModel
                ? { productKey: pumpPackModel.productKey }
                : {}),
              ...(pumpPackModel
                ? { description: pumpPackModel.description }
                : {}),
              parentUid: this.uid,
              uid: this.uid + ".pumpPack",
            } satisfies PumpPackBreakdown);
          }

          if (
            !manifoldHasPumpPack(context, filled) &&
            manifoldHasUfhPump(context, filled)
          ) {
            breakdown.push({
              qty: 1,
              path: "Equipment.Underfloor Pump",
              type: "ufh-pump",
              ...(manifoldModel ? { manifoldModel: manifoldModel.model } : {}),
              ...(ufhPumpManuf ? { manufacturer: ufhPumpManuf.name } : {}),
              ...(ufhPumpModel ? { model: ufhPumpModel.model } : {}),
              ...(ufhPumpModel ? { productKey: ufhPumpModel.productKey } : {}),
              ...(ufhPumpModel
                ? { description: ufhPumpModel.description }
                : {}),
              ...(ufhPumpModel && ufhPumpModel.price
                ? {
                    costOverride: convertCurrencyByContext(
                      this.context,
                      ufhPumpModel.price,
                    ),
                  }
                : {}),
              parentUid: this.uid + ".pumpPack",
            } satisfies UfhPumpBreakdown);
          }

          if (
            !manifoldHasPumpPack(context, filled) &&
            manifoldHasBlendingValve(context, filled)
          ) {
            breakdown.push({
              qty: 1,
              path: "Equipment.Underfloor Blending Valve",
              type: "blending-valve",
              ...(blendingValveManuf
                ? { manufacturer: blendingValveManuf.name }
                : {}),
              ...(blendingValveModel
                ? { model: blendingValveModel.model }
                : {}),
              ...(blendingValveModel
                ? { productKey: blendingValveModel.productKey }
                : {}),
              ...(blendingValveModel
                ? { description: blendingValveModel.description }
                : {}),
              ...(blendingValveModel && blendingValveModel.price
                ? {
                    costOverride: convertCurrencyByContext(
                      this.context,
                      blendingValveModel.price,
                    ),
                  }
                : {}),
              parentUid: this.uid + ".pumpPack",
            } satisfies BlendingValveBreakdown);
          }

          return {
            cost: context.priceTable.Equipment["Underfloor Manifold"],

            breakdown,
          };
        }
      case PlantType.UFH:
        return {
          cost: context.priceTable.Equipment.Emitter,
          breakdown: [
            {
              qty: 1,
              path: "Equipment.Emitter",
              type: "ufh",
              widthMM: filled.widthMM ?? 0,
              heightMM: filled.depthMM ?? 0,
              ratingKW:
                (calc.heatingRatingKW
                  ? Number(calc.heatingRatingKW.toFixed(3))
                  : 0) ?? 0,
            } satisfies UnderFloorHeatingBreakdown,
          ],
        };
      case PlantType.VOLUMISER:
        return {
          cost: context.priceTable.Plants.Volumiser,
          breakdown: [
            {
              qty: 1,
              path: `Plants.Volumiser`,
              type: "plant",
              name: filled.name ?? "",
            },
          ],
        };
      case PlantType.FILTER:
        const filter = filled as FilterPlantEntity;
        const name = `${configurationToName(filter.plant.configuration!)} ${
          filter.name
        }`;
        let path = "Plants.";
        let cost = 0;

        switch (filter.plant.filterType) {
          case "softener":
            cost = context.priceTable.Plants["Water Softener"];
            path += "Water Softener";
            break;
          case "backwash":
            cost = context.priceTable.Plants["Backwash Filter"];
            path += "Backwash Filter";
            break;
          case "backwash-rainwater":
            cost = context.priceTable.Plants["Backwash Filter (Rainwater)"];
            path += "Backwash Filter (Rainwater)";
            break;
          case "cartridge":
            cost = context.priceTable.Plants["Cartridge Filter"];
            path += "Cartridge Filter";
            break;
          case "uv":
            cost = context.priceTable.Plants["UV Filter"];
            path += "UV Filter";
            break;
          default:
            assertUnreachable(filter.plant);
        }

        return {
          cost,
          breakdown: [
            {
              qty: 1,
              path,
              type: "plant",
              name,
              manufacturer: filter.plant.manufacturer ?? "generic",
            },
          ],
        };
      case PlantType.RO:
        const ro = filled as ROPlantEntity;

        return {
          cost: context.priceTable.Plants["RO Plant"],
          breakdown: [
            {
              qty: 1,
              path: `Plants.RO Plant`,
              type: "plant",
              name: ro.name ?? undefined,
              manufacturer: ro.plant.manufacturer ?? "generic",
            },
          ],
        };
      case PlantType.DUCT_MANIFOLD:
        return {
          cost: context.priceTable.Equipment["Duct Manifold"],
          breakdown: [
            {
              qty: 1,
              path: "Equipment.Duct Manifold",
              type: "plant",
              manufacturer: "generic",
            },
          ],
        };
    }
    assertUnreachable(this.entity.plant);
  }

  preCalculationValidation(context: CoreContext): SelectionTarget | null {
    return null;
  }

  isOutletWithInlet() {
    return isOutletWithInletInPlant(this.context, this.entity);
  }

  // All is set to true to get all system nodes that can possibly exist so that
  // delete function can remove them

  getInletsOutletSpecs(all: boolean = false): InletsOutletsSpec[] {
    return getInletsOutletSpecsInPlant(this.context, this.entity, all);
  }

  getInletsOutletsPositions(): Record<
    string,
    { center: Coord; systemUid: string }
  > {
    const filled = fillPlantDefaults(this.context, this.entity);
    const inletOutlets = this.getInletsOutletSpecs();
    const inlets = inletOutlets.filter((o) => o.position === "left");
    const outlets = inletOutlets.filter((o) => o.position === "right");

    const entityWidth =
      filled.plant.type === PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP
        ? filled.plant.lengthMM
        : filled.widthMM;

    const entityHeight =
      filled.plant.type === PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP
        ? filled.widthMM
        : filled.depthMM;

    const height =
      filled.plant.shape === PlantShapes.CYLINDER
        ? filled.plant.diameterMM!
        : entityHeight!;
    const width =
      filled.plant.shape === PlantShapes.CYLINDER
        ? filled.plant.diameterMM!
        : entityWidth!;

    const result: Record<string, { center: Coord; systemUid: string }> = {};

    for (let i = 0; i < inlets.length; i++) {
      const inlet = inlets[i];
      result[inlet.uid] = {
        center: {
          x: (-width / 2) * (filled.rightToLeft ? -1 : 1),
          y: this.getInletOutletYCoord(i, inlets.length, height),
        },
        systemUid: inlet.systemUid,
      };
    }

    for (let i = 0; i < outlets.length; i++) {
      const outlet = outlets[i];
      result[outlet.uid] = {
        center: {
          x: (width / 2) * (filled.rightToLeft ? -1 : 1),
          y: this.getInletOutletYCoord(i, outlets.length, height),
        },
        systemUid: outlet.systemUid,
      };
    }

    return result;
  }

  private getInletOutletYCoord(
    index: number,
    numberOfIO: number,
    height: number,
  ): number {
    if (
      this.entity.plant.type === PlantType.RETURN_SYSTEM &&
      this.entity.plant.newIoLayout
    ) {
      const fixedSpacing = 100;
      const totalIoHeight = fixedSpacing * (numberOfIO - 1);

      return index * fixedSpacing - totalIoHeight / 2;
    } else {
      const dynamicSpacing = height / (numberOfIO + 1);

      return (index + 1) * dynamicSpacing - height / 2;
    }
  }

  getCorrectPositionOfChild(childUid: string): Coord | null {
    const inletsOutlets = this.getInletsOutletsPositions();

    return inletsOutlets[childUid]?.center || null;
  }

  getConnectedPipe(
    connectionUid: string,
    flowSystem: FlowSystem,
  ): CoreConduit | null {
    for (const itemUid of this.globalStore.getConnections(connectionUid)) {
      const item = this.globalStore.getObjectOfType(
        EntityType.CONDUIT,
        itemUid,
      );
      if (item && item.entity.systemUid === flowSystem.uid) {
        return item;
      }
    }
    return null;
  }

  // This needs to be renamed as it's only handling a warning about flow source
  showFlowSourceConnectedWarning(): boolean {
    if (this.entity.plant.type === PlantType.RETURN_SYSTEM) {
      if (this.entity.plant.returnType === "heatSource") {
        return true;
      }
    }

    if (isDualSystemNodePlant(this.entity.plant)) {
      return true;
    }

    const outlets = this.getInletsOutletSpecs().filter(
      (o) => o.type === "outlet",
    );

    for (const outlet of outlets) {
      const inletFS = getFlowSystem(this.drawing, this.entity.inletSystemUid);
      const outletFS = getFlowSystem(this.drawing, outlet.systemUid);

      if (
        inletFS &&
        this.entity.inletUid &&
        !this.getConnectedPipe(this.entity.inletUid, inletFS)
      ) {
        if (this.entity.plant.type === PlantType.RETURN_SYSTEM && outletFS) {
          if (!isClosedSystem(outletFS)) {
            return false;
          }
        }
      }
      if (outletFS && !this.getConnectedPipe(outlet.uid, outletFS)) {
        return false;
      }
    }
    return true;
  }

  getCoreNeighbours(): CoreBaseBackedObject[] {
    const res: CoreBaseBackedObject[] = [];
    for (const sn of this.getCoreInletsOutlets()) {
      if (!sn) {
        console.error(`${this.uid} has no neighbour`, sn);
        console.error(this.getInletsOutletSpecs());
        continue;
      }
      res.push(...sn.getCoreNeighbours());
    }
    return res;
  }

  getCoreInletsOutlets(all: boolean = false): CoreSystemNode[] {
    return this.getInletsOutletSpecs(all).map((o) =>
      this.globalStore.getObjectOfTypeOrThrow(EntityType.SYSTEM_NODE, o.uid),
    );
  }

  get shape():
    | Flatten.Segment
    | Flatten.Point
    | Flatten.Polygon
    | Flatten.Circle
    | null {
    const filled = fillPlantDefaults(this.context, this.entity);

    const shape = filled.plant.shape!;
    switch (shape) {
      case PlantShapes.CYLINDER: {
        const center = this.toWorldCoord();

        return new Flatten.Circle(
          Flatten.point(center.x, center.y),
          filled.plant.diameterMM!,
        );
      }
      case PlantShapes.RECTANGULAR: {
        const p = new Flatten.Polygon();
        // tslint:disable-next-line:one-variable-per-declaration
        const l = -filled.widthMM! / 2;
        const r = filled.widthMM! / 2;
        const t = -filled.depthMM! / 2;
        const b = filled.depthMM! / 2;

        const tl = this.toWorldCoord({ x: l, y: t });
        const tr = this.toWorldCoord({ x: r, y: t });
        const bl = this.toWorldCoord({ x: l, y: b });
        const br = this.toWorldCoord({ x: r, y: b });
        const tlp = Flatten.point(tl.x, tl.y);
        const trp = Flatten.point(tr.x, tr.y);
        const blp = Flatten.point(bl.x, bl.y);
        const brp = Flatten.point(br.x, br.y);

        p.addFace([
          Flatten.segment(tlp, trp),
          Flatten.segment(trp, brp),
          Flatten.segment(brp, blp),
          Flatten.segment(blp, tlp),
        ]);
        return p;
      }
    }
    assertUnreachable(shape);
  }

  // Already calculated the heat loss of downstream, pass the value to upstream calculation system
  // Note: the return UNIT is KW not WATT!!!
  // We just reuse the struct here
  getHeatLossKW(systemUid: string): HeatLossResultKW | undefined {
    const filled = fillPlantDefaults(this.context, this.entity);
    const calc = this.context.globalStore.getOrCreateCalculation(this.entity);
    const isHeating = isHeatingPlantSystem(
      this.context.drawing.metadata.flowSystems[systemUid],
    );
    const totalKW = isHeating
      ? calc.heatingRatingKW || 0
      : -Number(calc.chilledRatingKW) || 0;
    if (isHeatLoadPlant(filled.plant)) {
      if (filled.plant.type === PlantType.RETURN_SYSTEM) {
        if (filled.plant.isCibseDiversified) {
          return {
            totalKW: totalKW + filled.plant.domesticWaterLoadKW!,
            closedKW: totalKW,
            domesticKW: filled.plant.domesticWaterLoadKW!,
            numberOfClosedAppliances: calc.heatingRatingKW === 0 ? 0 : 1,
            numberOfDomesticAppliances:
              filled.plant.domesticWaterLoadKW === 0 ? 0 : 1,
          };
        }
      }
      return {
        totalKW,
        closedKW: 0,
        domesticKW: 0,
        numberOfClosedAppliances: 0,
        numberOfDomesticAppliances: 0,
      };
    }
  }

  getRecirculationPumpIds(): string[] {
    const filled = fillPlantDefaults(this.context, this.entity);
    const ret = [];
    if (filled.plant.type === PlantType.RETURN_SYSTEM) {
      for (const outlet of filled.plant.outlets) {
        const fs = getFlowSystem(this.drawing, outlet.outletSystemUid);
        if (
          outlet.addRecirculation &&
          outlet.outletReturnUid &&
          outlet.outletUid &&
          fs
        ) {
          if (outlet.recircPumpOnReturn || fs.role === "hotwater") {
            ret.push(outlet.outletReturnUid);
          } else {
            ret.push(outlet.outletUid);
          }
        }
      }
    }
    return ret;
  }

  getSystemNodePositionWorld(
    nodeUid: string,
    options: {
      overshootMM?: number;
    },
  ): Coord {
    const { overshootMM = 0 } = options;
    const objCoord = this.getCorrectPositionOfChild(nodeUid);

    if (!objCoord) {
      throw new Error(`Cannot find position for system node ${nodeUid}`);
    }

    if (objCoord.x < 0) {
      return this.toWorldCoord({
        x: 0 + (objCoord.x - overshootMM),
        y: objCoord.y,
      });
    } else {
      return this.toWorldCoord({
        x: 0 + (objCoord.x + overshootMM),
        y: objCoord.y,
      });
    }
  }

  inletPositionWorld(overshootMM?: number, inletUid?: string): Coord {
    if (!inletUid) {
      if (!this.entity.inletUid) {
        throw new Error("Cannot determine inletUid");
      }
      inletUid = this.entity.inletUid;
    }

    return this.getSystemNodePositionWorld(inletUid, { overshootMM });
  }

  outletPositionWorld(overshootMM?: number, outletUid?: string): Coord {
    if (!outletUid) {
      if (this.entity.plant.type === PlantType.RETURN_SYSTEM) {
        throw new Error("outletPositionWorld does not support return systems");
      }

      if (this.entity.plant.type === PlantType.DUCT_MANIFOLD) {
        throw new Error("outletPositionWorld does not support duct manifolds");
      }

      if (isDualSystemNodePlant(this.entity.plant)) {
        throw new Error(
          "outletPositionWorld does not support dual system plants",
        );
      }

      outletUid = this.entity.plant.outletUid;
    }

    return this.getSystemNodePositionWorld(outletUid, { overshootMM });
  }
}
