import Flatten from "@flatten-js/core";
import RBush from "rbush";
import { CoreObjectConcrete } from "../../api/coreObjects";
import CoreRoom from "../../api/coreObjects/coreRoom";
import { DrawingState } from "../../api/document/drawing";
import {
  RoomRoomEntity,
  isRoomRoomEntity,
} from "../../api/document/entities/rooms/room-entity";
import { EntityType, getEntityName } from "../../api/document/entities/types";
import { findEntitiesOfTypeOnLevel } from "../../api/document/utils";
import { SpatialIndex } from "../../api/types";
import { Coord } from "../coord";
import { pointInsidePolygon } from "../mathUtils/mathutils";
import { assertUnreachable } from "../utils";
import { GlobalStore } from "./global-store";

export function deleteSpatialIndexForLevel(
  globalStore: GlobalStore,
  levelUid: string,
) {
  globalStore.spatialIndex.delete(levelUid);
}

export function queueRiserSpatialIndexUpdates(
  globalStore: GlobalStore,
  drawing: DrawingState,
) {
  const risers = Object.keys(drawing.shared);
  for (const riser of risers) {
    globalStore.spatialIndexUpdateQueue.add(riser);
  }
}

export function findRoomUnderPoint(
  drawing: DrawingState,
  globalStore: GlobalStore,
  level: string | null,
  coord: Coord,
): RoomRoomEntity | null {
  if (!level) {
    return null;
  }

  return (
    findEntitiesOfTypeOnLevel(drawing, level, isRoomRoomEntity).find((x) => {
      const coreRoom = globalStore.getObjectOfTypeOrThrow(
        EntityType.ROOM,
        x.uid,
      );
      const polygonCw = coreRoom
        .collectVerticesInOrder()
        .map((v) => v.toWorldCoord());
      return pointInsidePolygon(coord, polygonCw);
    }) ?? null
  );
}

export function getRoomsByLevel(globalStore: GlobalStore) {
  const res = new Map<string, CoreRoom[]>();

  for (const [uid, o] of globalStore) {
    if (isRoomRoomEntity(o.entity)) {
      const room = o as CoreRoom;
      const lvl = globalStore.levelOfEntity.get(uid);

      if (!lvl) {
        continue;
      }

      if (!res.has(lvl)) {
        res.set(lvl, []);
      }

      res.get(lvl)!.push(room);
    }
  }

  return res;
}

export function getEntityRoom(entityWorldCoord: Coord, roomsOnLvl: CoreRoom[]) {
  const { x, y } = entityWorldCoord;
  for (const room of roomsOnLvl) {
    if (room.shape.contains(Flatten.point(x, y))) {
      return room;
    }
  }
}

export function updateSpatialIndex(globalStore: GlobalStore) {
  const queue = Array.from(globalStore.spatialIndexUpdateQueue);
  const treeBulkInsert = new Map<string, SpatialIndex[]>();

  for (const q of queue) {
    const o = globalStore.getSafe(q);
    if (o) {
      const levels = o.context.drawing.levels;
      for (const levelUid of Object.keys(levels)) {
        treeBulkInsert.set(levelUid, []);
      }
      break;
    }
  }

  while (queue.length > 0) {
    const oUid = queue.pop()!;
    const levelUid = globalStore.levelOfEntity.get(oUid)!;
    const o = globalStore.getSafe(oUid) as CoreObjectConcrete | undefined;
    try {
      // object has been deleted
      if (!o) {
        const oldIndex = globalStore.spatialIndexObjects.get(oUid);
        if (oldIndex) {
          // everything but riser
          if (oldIndex.levelUid) {
            const tree = globalStore.spatialIndex.get(oldIndex.levelUid);
            tree?.remove(oldIndex);
          } else {
            for (const [_, tree] of globalStore.spatialIndex.entries()) {
              tree.remove(oldIndex, (a, b) => a.uid === b.uid);
            }
          }
          globalStore.spatialIndexObjects.delete(oUid);
        }
        continue;
      }

      switch (o.type) {
        case EntityType.RISER:
          // Remove all the old entries of the riser if we are updating it
          if (globalStore.spatialIndexObjects.has(oUid)) {
            const oldTreeEntry = globalStore.spatialIndexObjects.get(oUid)!;
            // We don't know what levels it is on, so we delete from all levels
            for (const [_, tree] of globalStore.spatialIndex.entries()) {
              tree.remove(oldTreeEntry, (a, b) => a.uid === b.uid);
            }
            globalStore.spatialIndexObjects.delete(oUid);
          }

          const riserBox = o.shape.box;
          if (isNaN(riserBox.xmin)) {
            console.warn("Riser bounding box is NaN", o.type, o.uid);
            continue;
          }
          const riserEntry = {
            minX: riserBox.xmin,
            minY: riserBox.ymin,
            maxX: riserBox.xmax,
            maxY: riserBox.ymax,
            uid: oUid,
            levelUid: null,
          };

          // Add the new riser entry to required levels
          const riserLevels = o.getRiserLevels();
          for (const level of riserLevels) {
            const tree =
              globalStore.spatialIndex.get(level.uid) ||
              new RBush<SpatialIndex>();
            treeBulkInsert.get(level.uid)?.push(riserEntry);
            globalStore.spatialIndex.set(level.uid, tree);
          }

          globalStore.spatialIndexObjects.set(oUid, riserEntry);

          break;
        case EntityType.SYSTEM_NODE:
        case EntityType.BACKGROUND_IMAGE:
        case EntityType.BIG_VALVE:
        case EntityType.COMPOUND:
        case EntityType.DIRECTED_VALVE:
        case EntityType.FITTING:
        case EntityType.FIXTURE:
        case EntityType.FLOW_SOURCE:
        case EntityType.GAS_APPLIANCE:
        case EntityType.LOAD_NODE:
        case EntityType.MULTIWAY_VALVE:
        case EntityType.CONDUIT:
        case EntityType.PLANT:
        case EntityType.VERTEX:
        case EntityType.EDGE:
        case EntityType.ROOM:
        case EntityType.WALL:
        case EntityType.FENESTRATION:
        case EntityType.LINE:
        case EntityType.ANNOTATION:
        case EntityType.ARCHITECTURE_ELEMENT:
        case EntityType.DAMPER:
        case EntityType.AREA_SEGMENT:
          const box = (() => {
            if (o.type === EntityType.SYSTEM_NODE) {
              const shape = o.getStubShape() ?? o.shape;
              return shape?.box;
            }
            return o.shape?.box;
          })();

          if (!box) {
            continue;
          }
          if (isNaN(box.xmin)) {
            console.warn("Entity bounding box is NaN", o.type, o.uid);
            continue;
          }
          const treeEntry = {
            minX: box.xmin,
            minY: box.ymin,
            maxX: box.xmax,
            maxY: box.ymax,
            uid: oUid,
            levelUid,
          };

          let tree: RBush<SpatialIndex>;

          // Create the tree for the level if it doesn't exist already
          if (!globalStore.spatialIndex.has(levelUid)) {
            tree = new RBush<SpatialIndex>();
            globalStore.spatialIndex.set(levelUid, tree);
          }

          tree = globalStore.spatialIndex.get(levelUid)!;

          // Delete the old values if they exist
          const oldIndex = globalStore.spatialIndexObjects.get(oUid);
          if (oldIndex) {
            tree?.remove(oldIndex);
            globalStore.spatialIndexObjects.delete(oUid);
          }

          // Insert the values into the tree
          globalStore.spatialIndexObjects.set(oUid, treeEntry);
          treeBulkInsert.get(levelUid)?.push(treeEntry);
          break;

        default:
          assertUnreachable(o);
      }
      // the most common cause of these errors is a "fillEntity" call in order
      // to calculate the shape of an entity
    } catch (e) {
      console.error("Could not add entity to spatial index", {
        error: e,
        entityName: o
          ? getEntityName(o.entity, o.context.drawing, o.context)
          : null,
        uid: oUid,
      });
    }
  }

  // bulk insert the items into trees
  for (const [levelUid, tree] of globalStore.spatialIndex.entries()) {
    tree.load(treeBulkInsert.get(levelUid) || []);
  }

  globalStore.spatialIndexUpdateQueue.clear();
}

export function getEntitiesByLevel<T extends CoreObjectConcrete>(
  globalStore: GlobalStore,
  entities: T[],
): Map<string, T[]> {
  const entitiesByLevel = new Map<string, T[]>();
  for (const entity of entities) {
    const level = globalStore.levelOfEntity.get(entity.uid)!;
    if (!entitiesByLevel.has(level)) {
      entitiesByLevel.set(level, []);
    }
    entitiesByLevel.get(level)!.push(entity);
  }
  return entitiesByLevel;
}
