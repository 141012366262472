import Flatten from "@flatten-js/core";
import { v4 } from "uuid";
import { isCoreEdgeObject } from "../../../../../common/src/api/coreObjects";
import {
  determineConnectableSystemUid,
  isValidSegment,
} from "../../../../../common/src/api/coreObjects/utils";
import {
  ConnectableEntityConcrete,
  CoolDragEntityConcrete,
  DrawableEntityConcrete,
  EdgeEntityConcrete,
  isCenteredEntity,
  isConnectableEntity,
  isCoolDragEntity,
} from "../../../../../common/src/api/document/entities/concrete-entity";
import { FittingEntity } from "../../../../../common/src/api/document/entities/fitting-entity";
import { EntityType } from "../../../../../common/src/api/document/entities/types";
import { Coord } from "../../../../../common/src/lib/coord";
import { GlobalStore } from "../../../../../common/src/lib/globalstore/global-store";
import {
  canonizeAngleRad,
  isHorizontalRad,
  isParallelRad,
  isRightAngleRad,
} from "../../../../../common/src/lib/mathUtils/mathutils";
import {
  EPS,
  assertType,
  assertUnreachable,
  cloneSimple,
} from "../../../../../common/src/lib/utils";
import {
  CenteredObjectConcrete,
  ConnectableObjectConcrete,
  CoolDragObjectConcrete,
  DrawableObjectConcrete,
  EdgeObjectConcrete,
} from "../../objects/concrete-object";
import DrawableBackgroundImage from "../../objects/drawableBackgroundImage";
import DrawableConduit from "../../objects/drawableConduit";
import DrawableSystemNode from "../../objects/drawableSystemNode";
import DrawableVertex from "../../objects/drawableVertex";
import { CONNECTABLE_SNAP_RADIUS_PX } from "../../tools/snapping-insert-tool";
import CanvasContext from "../canvas-context";
import { InteractionType } from "../interaction";
import { moveOnto } from "./move-onto";
import { addValveAndSplitPipe } from "./split-pipe";
import { makeConduitEntity } from "./utils";

const NEED_ADJUSTMENT_THRESHOLD_RAD = Math.PI / 64;
const NEED_ADJUSTMENT_THRESHOLD_MM = 7;

// add bit of fluff to avoid easily reproducable edge cases that "nice" numbers might encounter.
export const STRAIGHT_PIPES_THRESHOLD_RAD = Math.PI / 8 - 0.0152343658348;

const POST_POSITIONING_SNAP_RADIUS_MM = 5;

// When multi dragging, how close do snap points need to correspond
// to be snapped together?
export const COLLECTIVE_SNAP_TOLERANCE_MM = 5;
export interface CoolDragParams {
  context: CanvasContext;
  fromWc: Coord;
  toWc: Coord;
  entities: CoolDragEntityConcrete[];
  subject: DrawableEntityConcrete | null;
  event?: MouseEvent;
}

interface LiteralMove {
  type: "literal";
  dontPreserveAngle?: boolean;
}

interface SlideMove {
  type: "slide";
  normal: Flatten.Vector;

  // Pipes that are on either side of the connectable, going outwards in the
  // same (positive) direction as the run vector or opposite (negative) direction.
  positives: EdgeEntityConcrete[];
  negatives: EdgeEntityConcrete[];
}

export type MoveIntent = LiteralMove | SlideMove;

export type MoveType = "slide" | "literal";

export const MOVE_PRIORITIES = {
  slide: 0,
  literal: 1,
};

// Given the selected entities, and the single one being actively dragged (subject),
// generate the list of entities that should be moved, and how they should be moved.
export function generateCoolDragEntities(
  context: CanvasContext,
  entities: CoolDragEntityConcrete[],
  subject: DrawableEntityConcrete | null,
  event?: MouseEvent,
): {
  uidMoves: Record<string, MoveIntent>;
  pipesThatWereRan: Set<string>;
  wobblyConnectables: string[];
} {
  const pipesThatWereRan = new Set<string>();
  const uidMoves: Record<string, MoveIntent> = {};
  const workQueue: string[] = [];
  const seenMoves: Record<string, MoveType> = {};

  if (event?.ctrlKey) {
    // Free move
    for (const entity of entities) {
      if (entity.type === EntityType.CONDUIT) {
        uidMoves[entity.endpointUid[0]] = { type: "literal" };
        uidMoves[entity.endpointUid[1]] = { type: "literal" };
      } else {
        uidMoves[entity.uid] = { type: "literal" };
      }
    }
  } else {
    let handled = false;
    if (entities.length === 1 && entities[0].type === EntityType.VERTEX) {
      // wall edge case - if it is straight, move literally as the user's intention is to bend.
      const vertex = context.globalStore.get<DrawableVertex>(entities[0].uid);
      if (vertex.isStraight(10)) {
        uidMoves[entities[0].uid] = { type: "literal" };
        handled = true;
      }
    }
    if (!handled) {
      for (const entity of entities) {
        if (entity.type === EntityType.CONDUIT) {
          runPipe(entity.uid);
        } else {
          uidMoves[entity.uid] = { type: "literal" };
          workQueue.push(entity.uid);
        }
      }
    }
  }

  function pushMove(uid: string, move: MoveIntent) {
    if (uidMoves[uid] === undefined) {
      uidMoves[uid] = move;
      workQueue.push(uid);
    } else {
      const existingPriority = MOVE_PRIORITIES[uidMoves[uid].type];
      const newPriority = MOVE_PRIORITIES[move.type];
      if (newPriority > existingPriority) {
        uidMoves[uid] = move;
        workQueue.push(uid);
      }
    }
  }

  function runPipe(pipeUid: string) {
    if (pipesThatWereRan.has(pipeUid)) {
      return;
    }
    pipesThatWereRan.add(pipeUid);
    const pipe = context.globalStore.get(pipeUid) as EdgeObjectConcrete;
    if (isCoreEdgeObject(pipe)) {
      const run = getPipeRun(context, pipe.entity.uid);
      for (const { connectable, positives, negatives } of run.connectables) {
        const o = context.globalStore.get(connectable.uid);
        if (isConnectableEntity(o.entity)) {
          pushMove(connectable.uid, {
            type: "slide",
            normal: run.vector,
            positives,
            negatives,
          });
        }
      }
      for (const pipe of run.pipes) {
        pipesThatWereRan.add(pipe.uid);
        pipe.endpointUid.forEach((uid) => {
          const endpointObject = context.globalStore.get(uid)!;
          const endpointWc = endpointObject.toWorldCoord();
          const includedConnections = context.globalStore
            .getConnections(uid)
            .filter((u) => pipesThatWereRan.has(u))
            .map(
              (u) =>
                (
                  context.globalStore.get(u) as EdgeObjectConcrete
                ).entity.endpointUid.find((u) => u !== uid)!,
            )
            .map((u) => context.globalStore.get(u));
          const includedAngles = includedConnections.map((o) => {
            const otherWc = o.toWorldCoord();
            return Math.atan2(
              otherWc.y - endpointWc.y,
              otherWc.x - endpointWc.x,
            );
          });

          let hasMultipleAngles = false;
          for (const a1 of includedAngles) {
            for (const a2 of includedAngles) {
              if (!isParallelRad(a1, a2, STRAIGHT_PIPES_THRESHOLD_RAD)) {
                hasMultipleAngles = true;
                break;
              }
            }
          }
          if (hasMultipleAngles) {
            pushMove(uid, { type: "literal" });
          }
        });
      }
    }
  }

  const wobblyConnectables: string[] = [];
  function onNewLiteralConnectable(connectableUid: string) {
    if (
      uidMoves[connectableUid] &&
      uidMoves[connectableUid].type !== "literal"
    ) {
      return;
    }

    const connections = context.globalStore.getConnections(connectableUid);
    if (subject && subject.uid === connectableUid && entities.length === 1) {
      // If we are dragging a single connectable, it is a special case where we ignore
      // going down paths that are wobbly pipes as the user will be trying to adjust
      // the pipe to be straight. However, run the other connected pipes to keep them
      // straight.

      const angles: Flatten.Vector[] = connections
        .map((c) => {
          const edge = context.globalStore.get<EdgeObjectConcrete>(c);
          const endpoints = edge.worldEndpoints();

          const segment: [Coord, Coord] = [
            {
              x: endpoints[0].x,
              y: endpoints[0].y,
            },
            { x: endpoints[1].x, y: endpoints[1].y },
          ];
          if (!isValidSegment(segment)) return undefined;

          return Flatten.vector(
            endpoints[0].x - endpoints[1].x,
            endpoints[0].y - endpoints[1].y,
          );
        })
        .filter((v): v is Flatten.Vector => {
          return v !== undefined;
        });

      for (const c of connections) {
        // we assume this connection is wobbly until we match it - parallel or perpendicular -
        // to either any other pipe in this group, or to project north, or to a pipe that is
        // connected to the other end of the connection.
        let wobbly = true;

        const edge = context.globalStore.get<EdgeObjectConcrete>(c);
        const endpoints = edge.worldEndpoints();
        const vector = Flatten.vector(
          endpoints[0].x - endpoints[1].x,
          endpoints[0].y - endpoints[1].y,
        );
        if (vector.length > EPS) {
          const len = vector.length;

          // longer angles need tighter thresholds
          const basedOnDist = Math.asin(NEED_ADJUSTMENT_THRESHOLD_MM / len);
          const threshold = Math.min(
            NEED_ADJUSTMENT_THRESHOLD_RAD,
            basedOnDist,
          );

          if (
            isParallelRad(vector.angleTo(Flatten.vector(1, 0)), threshold) ||
            isRightAngleRad(vector.angleTo(Flatten.vector(1, 0)), threshold)
          ) {
            wobbly = false;
          }

          if (wobbly) {
            for (const a of angles) {
              if (
                isParallelRad(a.angleTo(vector), threshold) ||
                isRightAngleRad(a.angleTo(vector), threshold)
              ) {
                wobbly = false;
                break;
              }
            }
          }

          if (wobbly) {
            const otherUid = edge.entity.endpointUid.find(
              (u) => u !== connectableUid,
            )!;
            for (const c2 of context.globalStore.getConnections(otherUid)) {
              const pipe2 = context.globalStore.get(c2);
              if (!isCoreEdgeObject(pipe2)) {
                continue;
              }
              const endpoints2 = pipe2.worldEndpoints();
              const vector2 = Flatten.vector(
                endpoints2[0].x - endpoints2[1].x,
                endpoints2[0].y - endpoints2[1].y,
              );
              if (
                vector2.length &&
                (isParallelRad(
                  vector2.angleTo(vector),
                  NEED_ADJUSTMENT_THRESHOLD_RAD,
                ) ||
                  isRightAngleRad(
                    vector2.angleTo(vector),
                    NEED_ADJUSTMENT_THRESHOLD_RAD,
                  ))
              ) {
                wobbly = false;
                break;
              }
            }
          }
        }

        if (wobbly) {
          const edge = context.globalStore.get<EdgeObjectConcrete>(c);
          const otherUid = edge.entity.endpointUid.find(
            (u) => u !== connectableUid,
          )!;
          wobblyConnectables.push(otherUid);
        } else {
          runPipe(c);
        }
      }
    } else {
      // standard case - drag everything around it.
      for (const c of connections) {
        runPipe(c);
      }
    }
  }

  while (workQueue.length > 0) {
    const uid = workQueue.shift()!;

    if (seenMoves[uid] === uidMoves[uid].type) {
      continue;
    }
    seenMoves[uid] = uidMoves[uid].type;

    const o = context.globalStore.get<CoolDragObjectConcrete>(uid)!;
    const toAdd = o.getCoolDragCorrelations(uidMoves[uid]);

    for (const { object, move } of toAdd) {
      pushMove(object.uid, move);
    }

    const move = uidMoves[uid];
    if (move.type === "literal" && !move.dontPreserveAngle) {
      if (isConnectableEntity(o.entity)) {
        onNewLiteralConnectable(uid);
      }
    }
  }

  // We don't want to slide risers. We want to move them literally only, so that pipes don't get rekt.

  for (const uid of Object.keys(uidMoves)) {
    const o = context.globalStore.get<CoolDragObjectConcrete>(uid)!;
    if (o.entity.type === EntityType.RISER) {
      if (uidMoves[uid].type === "slide") {
        delete uidMoves[uid];
      }
    }
  }

  return {
    uidMoves,
    pipesThatWereRan,
    wobblyConnectables,
  };
}

interface VectorBounds {
  a: Flatten.Vector;
  b: Flatten.Vector;
}

interface SnapSuggestion {
  fromUid: string;
  toUid: string;
  type: "connectable" | "pipe";

  // The *mouse* adjustment needed to make this snap happen. Is not
  // necessarily the same as the *entity* adjustment due to the non
  // identity transformation from mouse position to entity position.
  adjustmentWC: Flatten.Vector;
}

export function performCoolDrag(params: CoolDragParams) {
  try {
    const vectorBounds = performCoolDragIteration(params, "get-vector-bounds");

    if (vectorBounds === "error") {
      return false;
    }

    params.context.$store.dispatch("document/revert", false);
    if (vectorBounds) {
      // intersect and clip mouse vector onto the line between the vector bounds.
      // It would be as if the mouse was dragged along the angles it was allowed
      // to.
      const mouseVector = new Flatten.Vector(
        params.toWc.x - params.fromWc.x,
        params.toWc.y - params.fromWc.y,
      );
      const cpam = vectorBounds.a.cross(mouseVector);
      const cpbm = vectorBounds.b.cross(mouseVector);
      const cpab = vectorBounds.a.cross(vectorBounds.b);

      if (cpam * cpbm < -EPS) {
        const t = cpam / cpab;
        const intersection = new Flatten.Vector(
          vectorBounds.a.x + t * (vectorBounds.b.x - vectorBounds.a.x),
          vectorBounds.a.y + t * (vectorBounds.b.y - vectorBounds.a.y),
        );
        params.toWc = {
          x: params.fromWc.x + intersection.x,
          y: params.fromWc.y + intersection.y,
        };
      } else if (cpab * cpam >= -EPS) {
        params.toWc = {
          x: params.fromWc.x + vectorBounds.b.x,
          y: params.fromWc.y + vectorBounds.b.y,
        };
      } else {
        params.toWc = {
          x: params.fromWc.x + vectorBounds.a.x,
          y: params.fromWc.y + vectorBounds.a.y,
        };
      }
    }

    const snapSuggestions = performCoolDragIteration(
      params,
      "get-snap-suggestions",
    );
    if (snapSuggestions === "error") {
      return false;
    }

    if (snapSuggestions && snapSuggestions.length > 0) {
      params.context.$store.dispatch("document/revert", false);
      // find closest snap suggestion and snap to it.
      const suggestions = snapSuggestions.sort((a, b) => {
        // we need to do the connections first priority instead of pipes, because
        // snapping onto pipes produces different type of vectors than snapping onto
        // connections due to the fact that snapping onto pipes project while snapping
        // onto connections just snaps to the closest point. To allow snapping onto pipes
        // and connections at the same time, we execute connection snaps verbatim and we
        // recalculate pipe snap positions according to the new connection positions.
        if (a.type !== b.type) {
          return a.type === "connectable" ? -1 : 1;
        }
        return a.adjustmentWC.length - b.adjustmentWC.length;
      });
      const closestBatch = suggestions.filter(
        (s) =>
          s.adjustmentWC.add(suggestions[0].adjustmentWC.multiply(-1)).length <
          COLLECTIVE_SNAP_TOLERANCE_MM,
      );
      const adjustmentWC = closestBatch
        .reduce((acc, s) => acc.add(s.adjustmentWC), new Flatten.Vector(0, 0))
        .multiply(1 / closestBatch.length);
      params.toWc = {
        x: params.toWc.x + adjustmentWC.x,
        y: params.toWc.y + adjustmentWC.y,
      };
      const result = performCoolDragIteration(
        params,
        closestBatch.filter((s) => s.type === "connectable"),
      );
      if (result === "error") {
        return false;
      }
    }
    return true;
  } catch (e) {
    params.context.$store.dispatch("document/revert", false);
    console.error("cool drag error:", e);
    return false;
  }
}

type SlideResult =
  | {
      didSlide: true;
      vectorWC: Flatten.Vector;
      snapSuggestion?: SnapSuggestion;
    }
  | {
      didSlide: false;
      vectorWC: Flatten.Vector;
    };

// Returns the adjusted drag position delta. Adjustments could be due to snapping, etc.
function performCoolDragIteration(
  params: CoolDragParams,
  arg: "get-vector-bounds",
): VectorBounds | null | "error";
function performCoolDragIteration(
  params: CoolDragParams,
  arg: "get-snap-suggestions",
): SnapSuggestion[] | null | "error";
function performCoolDragIteration(
  params: CoolDragParams,
  arg: SnapSuggestion[],
): Coord | null | "error";
function performCoolDragIteration(
  params: CoolDragParams,
  arg: "get-vector-bounds" | "get-snap-suggestions" | SnapSuggestion[],
): VectorBounds | SnapSuggestion[] | Coord | null | "error" {
  const { context, fromWc, toWc, entities, subject } = params;

  const mouseMoveVectorWC = { x: toWc.x - fromWc.x, y: toWc.y - fromWc.y };

  const { uidMoves, pipesThatWereRan } = generateCoolDragEntities(
    context,
    entities,
    subject,
    params.event,
  );

  function hasMovingParent(entity: CoolDragEntityConcrete): boolean {
    return Boolean(entity.parentUid && uidMoves[entity.parentUid]);
  }

  for (const [uid, move] of Object.entries(uidMoves)) {
    if (move.type === "literal") {
      const o = context.globalStore.get<ConnectableObjectConcrete>(uid);
      if (o && isCoolDragEntity(o.entity) && !hasMovingParent(o.entity)) {
        if (isCenteredEntity(o.entity)) {
          o.translateWC(mouseMoveVectorWC);
        }
      }
    }
  }

  // delete straight segments, as well as validate.
  const connectionsToAudit = new Set<string>();
  // just validate, but don't delete straight segments.
  const connectionsToValidate = new Set<string>();
  const dontSnapTo = new Set<string>(
    Object.keys(uidMoves).concat(Array.from(pipesThatWereRan)),
  );

  // Errors in mind while writing this: Pipes that are 0 length, valves that obtain too many connections.
  function validateOrRevert() {
    for (const uid of [
      ...Object.keys(uidMoves),
      ...Array.from(connectionsToAudit),
      ...Array.from(connectionsToValidate),
    ]) {
      const o = context.globalStore.get<ConnectableObjectConcrete>(uid);
      if (o && isConnectableEntity(o.entity)) {
        if (!o.validate(context, false).success) {
          context.$store.dispatch("document/revert", false);
          return false;
        }
      }
    }
    return true;
  }

  const slideResults = new Map<string, SlideResult>();
  for (const [uid, move] of Object.entries(uidMoves)) {
    if (move.type === "slide") {
      const o = context.globalStore.get(uid);
      if (o && isCoolDragEntity(o.entity) && !hasMovingParent(o.entity)) {
        if (isConnectableEntity(o.entity)) {
          slideResults.set(
            o.entity.uid,
            slideConnectable({
              context,
              connectable: o.entity,
              slideIntent: move,
              mouseMoveVectorWC: Flatten.vector(
                mouseMoveVectorWC.x,
                mouseMoveVectorWC.y,
              ),
              excludedPipeUids: pipesThatWereRan,
              connectionsToAudit,
              connectionsToValidate,
            }),
          );
        } else {
          // fixtures, etc. Slide perpendicular to vector into the ether.
          // They need to "stay put" but typically have nothing to slide against
          slideResults.set(
            o.entity.uid,
            slideEntityPerpendicular({
              context,
              entity: o.entity,
              slideIntent: move,
              mouseMoveVectorWC: Flatten.vector(
                mouseMoveVectorWC.x,
                mouseMoveVectorWC.y,
              ),
            }),
          );
        }
      }
    }
  }

  for (const uid of connectionsToAudit) {
    auditConnection(context, uid);
  }

  if (!validateOrRevert()) {
    return "error";
  }

  function getSlideViaParents(entityUid: string | null) {
    while (entityUid) {
      if (slideResults.has(entityUid)) {
        const result = slideResults.get(entityUid);
        if (result && result.didSlide) {
          return result.vectorWC;
        }
      }
      const o = context.globalStore.get(entityUid);
      entityUid = o?.entity.parentUid || null;
    }
  }

  if (arg === "get-vector-bounds") {
    if (subject && subject.type === EntityType.CONDUIT) {
      const slides = subject.endpointUid
        .map(getSlideViaParents)
        .filter(Boolean);
      if (slides.length === 2) {
        return {
          a: slides[0]!,
          b: slides[1]!,
        };
      } else if (slides.length === 1) {
        return {
          a: slides[0]!,
          b: slides[0]!,
        };
      } else {
        return null;
      }
    } else {
      return null; // no bounds
    }
  } else if (arg === "get-snap-suggestions") {
    const suggestions: SnapSuggestion[] = [];
    // snaps
    for (const [uid, move] of Object.entries(uidMoves)) {
      if (
        move.type === "literal" ||
        (uidMoves[uid].type === "slide" &&
          slideResults.get(uid)?.didSlide === false)
      ) {
        const o = context.globalStore.get<CoolDragObjectConcrete>(uid)!;
        const snapOnto = context.activeLayer.offerInteraction(
          {
            type: InteractionType.SNAP_ONTO_RECEIVE,
            src: o.entity,
            worldRadius: context.viewPort.surfaceToWorldLength(
              CONNECTABLE_SNAP_RADIUS_PX,
            ),
            worldCoord: o.toWorldCoord(),
          },
          (snapCandidate) => {
            if (
              isConnectableEntity(snapCandidate[0]) ||
              snapCandidate[0].type === EntityType.CONDUIT
            ) {
              const candidate =
                context.globalStore.get<ConnectableObjectConcrete>(
                  snapCandidate[0].uid,
                );
              if (dontSnapTo.has(snapCandidate[0].uid)) {
                return false;
              }
              const result = o.offerInteraction({
                type: InteractionType.SNAP_ONTO_SEND,
                dest: candidate.entity,
                worldRadius: CONNECTABLE_SNAP_RADIUS_PX,
                worldCoord: o.toWorldCoord(),
              });
              return result !== null && result[0].uid !== candidate.uid;
            } else {
              return false;
            }
          },
        );
        if (snapOnto) {
          const target = context.globalStore.get(snapOnto[0].uid);
          if (isConnectableEntity(target.entity)) {
            suggestions.push({
              fromUid: o.uid,
              toUid: snapOnto[0].uid,
              type: "connectable",
              adjustmentWC: Flatten.vector(
                target.toWorldCoord().x - o.toWorldCoord().x,
                target.toWorldCoord().y - o.toWorldCoord().y,
              ),
            });
          } else if (isCoreEdgeObject(target)) {
            const pipeLine = getPipeLine(
              context,
              target,
              target.entity.endpointUid[0],
            );

            const myPoint = Flatten.point(
              o.toWorldCoord().x,
              o.toWorldCoord().y,
            );

            const pointOnPipe = myPoint.distanceTo(pipeLine)[1].end;
            suggestions.push({
              fromUid: o.uid,
              toUid: target.uid,
              type: "pipe",
              adjustmentWC: Flatten.vector(
                pointOnPipe.x - o.toWorldCoord().x,
                pointOnPipe.y - o.toWorldCoord().y,
              ),
            });
          }
        }
      }
    }
    return suggestions;
  } else {
    // Execute the snaps. We are passed only the connectable snap suggestions, and the
    // pipe snaps are regenerated here later due to how the document changes after snapping
    // to pipes each time. TODO: some kind of geometric optimization to make this better
    // thank N^2.

    // UIDs of snapped TOs and FROMs, so they don't snap again.
    const snappedUids = new Set<string>();

    // Complete all the verbatim connectable snaps first.
    for (const suggestion of arg) {
      if (
        !snappedUids.has(suggestion.fromUid) &&
        !snappedUids.has(suggestion.toUid)
      ) {
        moveOnto(
          context.globalStore.get<ConnectableObjectConcrete>(
            suggestion.fromUid,
          ),
          context.globalStore.get<ConnectableObjectConcrete>(suggestion.toUid),
          context,
        );
        snappedUids.add(suggestion.fromUid);
        snappedUids.add(suggestion.toUid);
      }
    }

    // Now check every connection for potential pipe snaps again, but with very low tolerance
    // since we are already positioned to the final location and are not "snapping" in terms
    // of moving - just in terms of connecting.

    for (const [uid, move] of Object.entries(uidMoves)) {
      if (snappedUids.has(uid)) {
        continue;
      }
      snappedUids.add(uid);
      if (move.type !== "literal" && slideResults.get(uid)?.didSlide === true) {
        continue;
      }
      const o = context.globalStore.get<CoolDragObjectConcrete>(uid)!;
      if (!isConnectableEntity(o.entity)) {
        continue;
      }
      const snapOnto = context.activeLayer.offerInteraction(
        {
          type: InteractionType.SNAP_ONTO_RECEIVE,
          src: o.entity,
          worldRadius: POST_POSITIONING_SNAP_RADIUS_MM,
          worldCoord: o.toWorldCoord(),
        },
        (snapCandidate) => {
          if (snapCandidate[0].type === EntityType.CONDUIT) {
            const candidate =
              context.globalStore.get<ConnectableObjectConcrete>(
                snapCandidate[0].uid,
              );
            if (dontSnapTo.has(snapCandidate[0].uid)) {
              return false;
            }
            const result = o.offerInteraction({
              type: InteractionType.SNAP_ONTO_SEND,
              dest: candidate.entity,
              worldRadius: CONNECTABLE_SNAP_RADIUS_PX,
              worldCoord: o.toWorldCoord(),
            });
            return result !== null && result[0].uid !== candidate.uid;
          } else {
            return false;
          }
        },
      );

      if (snapOnto && snapOnto[0].type === EntityType.CONDUIT) {
        snappedUids.add(snapOnto[0].uid);
        moveOnto(
          o as ConnectableObjectConcrete,
          context.globalStore.get(snapOnto[0].uid) as DrawableConduit,
          params.context,
        );
      }
    }

    if (!validateOrRevert()) {
      return "error";
    }
    return { x: toWc.x - fromWc.x, y: toWc.y - fromWc.y };
  }
}

// Removes the connection if it is a straight pipe.
function auditConnection(context: CanvasContext, uid: string) {
  const o = context.globalStore.get<ConnectableObjectConcrete>(uid);
  if (o && o.isStraight()) {
    context.deleteEntity(o);
  } else if (context.globalStore.getConnections(uid).length <= 1) {
    context.deleteEntity(o);
  }
}

function slideEntityPerpendicular(options: {
  context: CanvasContext;
  entity: CoolDragEntityConcrete;
  slideIntent: SlideMove;
  mouseMoveVectorWC: Flatten.Vector;
}): SlideResult {
  const { context, entity, slideIntent, mouseMoveVectorWC } = options;
  const { normal } = slideIntent;
  const slideBase = normal.rotate90CW();
  const slideVector = mouseMoveVectorWC.projectionOn(slideBase);
  const slideVectorWC = { x: slideVector.x, y: slideVector.y };
  const o = context.globalStore.get<
    Exclude<CenteredObjectConcrete, DrawableBackgroundImage>
    // CenteredObjectConcrete | DrawableBackgroundImage is too complex
    // for typescript to handle
  >(entity.uid)!;
  const existingWC = o.toWorldCoord();
  o.translateWC(slideVectorWC);
  const newWC = o.toWorldCoord();
  return {
    didSlide: true,
    vectorWC: Flatten.vector(newWC.x - existingWC.x, newWC.y - existingWC.y),
  };
}

function shouldConnectableStayStill(connectable: ConnectableEntityConcrete) {
  switch (connectable.type) {
    case EntityType.RISER:
    case EntityType.FITTING:
    case EntityType.MULTIWAY_VALVE:
    case EntityType.DIRECTED_VALVE:
    case EntityType.SYSTEM_NODE:
    case EntityType.VERTEX:
      return false;
    case EntityType.LOAD_NODE:
    case EntityType.FLOW_SOURCE:
      return true;
    default:
      assertUnreachable(connectable);
  }
}

function slideConnectable(options: {
  context: CanvasContext;
  connectable: ConnectableEntityConcrete;
  slideIntent: SlideMove;
  mouseMoveVectorWC: Flatten.Vector;
  excludedPipeUids: Set<string>;
  connectionsToAudit: Set<string>;
  connectionsToValidate: Set<string>;
}): SlideResult {
  const {
    context,
    connectable,
    slideIntent,
    excludedPipeUids,
    mouseMoveVectorWC,
    connectionsToAudit,
    connectionsToValidate,
  } = options;
  const { normal, negatives } = slideIntent;
  const startWC = context.globalStore.get(connectable.uid)!.toWorldCoord();

  // For now, don't support slide splitting.

  // This vector is used to determine which pipe to run along when there are multiple
  // options available. It should take the pipe that turns towards the running pipe
  // so as to avoid intersecting pipes. However we will only support this correctly
  // with only one side, as doing it for both sides would require splitting the
  // connectable, which is too annoying.
  let acuteTarget = normal;
  if (negatives.length) {
    acuteTarget = normal.multiply(-1);
  }

  function findNextPipe(currConnectableUid: string) {
    connectionsToValidate.add(currConnectableUid);
    let candidatePipe: EdgeObjectConcrete | null = null;
    let nextConnectableUid: string | null = null;
    let candidateScore = 0;
    const connections = context.globalStore.getConnections(currConnectableUid);
    for (const cUid of connections) {
      const pipe = context.globalStore.get(cUid);
      if (!isCoreEdgeObject(pipe)) {
        continue;
      }
      if (excludedPipeUids.has(pipe.uid)) {
        continue;
      }
      const slideVector = getPipeVector(context, pipe, currConnectableUid);
      // needs to be on same side of acuteTarget vector as the mouse move vector
      const slideCross = acuteTarget.cross(slideVector);
      const mouseCross = acuteTarget.cross(options.mouseMoveVectorWC);
      if (slideCross * mouseCross < 0) {
        continue;
      }
      const angle = Math.abs(
        canonizeAngleRad(acuteTarget.angleTo(slideVector)),
      );
      if (
        angle < STRAIGHT_PIPES_THRESHOLD_RAD ||
        angle > Math.PI - STRAIGHT_PIPES_THRESHOLD_RAD
      ) {
        continue;
      }

      const score = angle;
      if (!candidatePipe || score > candidateScore) {
        candidatePipe = pipe;
        candidateScore = score;
        nextConnectableUid = pipe.entity.endpointUid.find(
          (u) => u !== currConnectableUid,
        )!;
      }
    }

    return {
      candidatePipe,
      nextConnectableUid,
    };
  }

  let distanceRemaining = mouseMoveVectorWC.length;
  let currConnectableUid = connectable.uid;
  let lastPipeVector: Flatten.Vector | null = null;
  let lastPipe: EdgeObjectConcrete | null = null;

  // There are many final state cases to consider here. targetCoordWC, and up to
  // one other entity may be filled here to then define how the entity transitions
  // to the final location.
  let targetCoordWC: Coord | null = null; // final position of our connectable.
  let targetPipe: EdgeObjectConcrete | null = null; // if we landed in the middle of a pipe.
  let targetExtendConnectableUid: string | null = null; // if we extend into the ether, this is the last connectable.
  let extendModelPipe: EdgeObjectConcrete | null = null; // if we extend into the ether, this is the pipe we extend along.

  if (mouseMoveVectorWC.length < EPS) {
    targetCoordWC = startWC;
  } else {
    while (distanceRemaining > EPS) {
      const { candidatePipe, nextConnectableUid } =
        findNextPipe(currConnectableUid);

      const wc = context.globalStore.get(currConnectableUid)!.toWorldCoord();
      const moveVector = mouseMoveVectorWC
        .normalize()
        .multiply(distanceRemaining);

      if (candidatePipe) {
        // We can move along the pipe.
        const slideVector = getPipeVector(
          context,
          candidatePipe,
          currConnectableUid,
        );

        // const projection = moveVector.projectionOn(slideVector);
        // above calculation is wrong.
        // moveVector.x + a*acuteTarget.x = b*slideVector.x
        // moveVector.y + a*acuteTarget.y = b*slideVector.y
        // a = (x3 y1 - x1 y3)/(x2 y3 - x3 y2) and b = (x2 y1 - x1 y2)/(x2 y3 - x3 y2) and x3 y2!=x2 y3 and x3!=0
        const projection = projectThroughVector(
          moveVector,
          slideVector,
          acuteTarget,
        );

        if (projection.length < slideVector.length) {
          // We ended up on the middle of this pipe.
          targetCoordWC = {
            x: wc.x + projection.x,
            y: wc.y + projection.y,
          };
          targetPipe = candidatePipe;

          distanceRemaining = 0;
        } else {
          // move onto the next pipe.

          distanceRemaining =
            distanceRemaining -
            (slideVector.length / projection.length) * distanceRemaining;
          lastPipeVector = slideVector;
          lastPipe = candidatePipe;
          currConnectableUid = nextConnectableUid!;
        }
      } else {
        // There is no pipe to slide along any further. We will eject into the ether.
        if (!lastPipeVector) {
          // we are starting on a corner - a k shaped corner perhaps.
          // choose a non-random pipe - what's most perpendicular to the
          // pipe run vector.
          const bestScore = 0;
          const connections =
            context.globalStore.getConnections(currConnectableUid);
          for (const cUid of connections) {
            const pipe = context.globalStore.get(cUid);
            if (!isCoreEdgeObject(pipe)) {
              continue;
            }
            if (excludedPipeUids.has(pipe.uid)) {
              continue;
            }
            const slideVector = getPipeVector(
              context,
              pipe,
              currConnectableUid,
            );
            const angle = Math.abs(
              canonizeAngleRad(acuteTarget.angleTo(slideVector)),
            );
            if (angle < STRAIGHT_PIPES_THRESHOLD_RAD) {
              continue;
            }
            // score is how perpendicular it is
            const score = Math.abs(angle - Math.PI / 2);

            if (!lastPipeVector || score > bestScore) {
              lastPipeVector = slideVector;
              lastPipe = pipe;
            }
          }
        }
        if (lastPipeVector) {
          const toMove = projectThroughVector(
            moveVector,
            lastPipeVector,
            acuteTarget,
          );
          targetCoordWC = {
            x: wc.x + toMove.x,
            y: wc.y + toMove.y,
          };
          targetExtendConnectableUid = currConnectableUid;
          extendModelPipe = lastPipe;
        } else {
          // This is a "dry" pipe segment.
          if (
            shouldConnectableStayStill(connectable) ||
            context.globalStore
              .getConnections(connectable.uid)
              .filter((u) => !excludedPipeUids.has(u)).length > 0
          ) {
            // We are a single pipe segment. We should stay where we are.
            const base = normal.rotate90CW();
            const toMove = moveVector.projectionOn(base);
            targetCoordWC = {
              x: wc.x + toMove.x,
              y: wc.y + toMove.y,
            };
          } else {
            targetCoordWC = {
              x: wc.x + moveVector.x,
              y: wc.y + moveVector.y,
            };
          }
        }

        distanceRemaining = 0;
      }
    }
  }

  if (!targetCoordWC) {
    console.warn("no target coord", {
      connectable,
      targetCoordWC,
      targetPipe,
      targetExtendConnectableUid,
      distanceRemaining,
    });
    throw new Error("no target coord");
  }

  function dislodgeConnectable() {
    const levelUid =
      context.globalStore.levelOfEntity.get(connectable.uid) ||
      context.document.uiState.levelUid;
    const newFitting: FittingEntity = {
      type: EntityType.FITTING,
      uid: v4(),
      calculationHeightM: null,
      center: cloneSimple(connectable.center),
      color: null,
      entityName: null,
      parentUid: connectable.parentUid,
      systemUid:
        determineConnectableSystemUid(context.globalStore, connectable) ||
        "cold-water",
      fittingType: "pipe",
      fitting: {},
    };

    context.$store.dispatch("document/addEntityOn", {
      entity: newFitting,
      levelUid,
    });

    const connections = cloneSimple(
      context.globalStore.getConnections(connectable.uid),
    );
    for (const cUid of connections) {
      const pipe = context.globalStore.get(cUid);
      if (!isCoreEdgeObject(pipe)) {
        continue;
      }
      if (excludedPipeUids.has(pipe.uid)) {
        continue;
      }

      context.$store.dispatch("document/updatePipeEndpoints", {
        entity: pipe.entity,
        endpoints: [
          pipe.entity.endpointUid[0] === connectable.uid
            ? newFitting.uid
            : pipe.entity.endpointUid[0],
          pipe.entity.endpointUid[1] === connectable.uid
            ? newFitting.uid
            : pipe.entity.endpointUid[1],
        ],
      });
    }
    connectionsToAudit.add(newFitting.uid);
    return newFitting;
  }

  if (targetPipe && targetPipe instanceof DrawableConduit) {
    if (excludedPipeUids.has(targetPipe.uid)) {
      throw new Error("target pipe is excluded");
    }

    // avoid making new conduits when fittings and connections remain the same
    const conns = context.globalStore.getConnections(connectable.uid);
    if (conns.includes(targetPipe.uid)) {
      connectable.center = targetCoordWC;
      connectable.parentUid = null;
    } else {
      dislodgeConnectable();
      targetPipe = context.globalStore.get(targetPipe.uid) as DrawableConduit;
      addValveAndSplitPipe(
        context,
        targetPipe,
        targetCoordWC,
        undefined,
        0,
        connectable,
      );
    }
    const newCoord = context.globalStore.get(connectable.uid).toWorldCoord();
    return {
      didSlide: true,
      vectorWC: Flatten.vector(newCoord.x - startWC.x, newCoord.y - startWC.y),
    };
  } else if (
    targetExtendConnectableUid &&
    extendModelPipe instanceof DrawableConduit
  ) {
    if (!extendModelPipe) {
      throw new Error("no extend model pipe");
    }

    // avoid making new conduits when fittings remain the same
    if (targetExtendConnectableUid === connectable.uid) {
      connectable.center = targetCoordWC;
      connectable.parentUid = null;
    } else {
      const newFitting = dislodgeConnectable();
      extendModelPipe = context.globalStore.get(
        extendModelPipe.uid,
      ) as DrawableConduit;
      const o = context.globalStore.get<ConnectableObjectConcrete>(
        connectable.uid,
      );
      const levelUid =
        context.globalStore.levelOfEntity.get(connectable.uid) ||
        context.document.uiState.levelUid;
      o.repositionCenterToWC(targetCoordWC);
      const newPipe = makeConduitEntity({
        fields: {
          endpointUid: [
            targetExtendConnectableUid === connectable.uid
              ? newFitting.uid
              : targetExtendConnectableUid,
            connectable.uid,
          ],
        },
        modelConduit: extendModelPipe.entity,
        objectStore: context.globalStore,
      });

      context.$store.dispatch("document/addEntityOn", {
        entity: newPipe,
        levelUid,
      });
    }

    return {
      didSlide: true,
      vectorWC: Flatten.vector(
        targetCoordWC.x - startWC.x,
        targetCoordWC.y - startWC.y,
      ),
    };
  } else {
    // straight up move.
    const o = context.globalStore.get<ConnectableObjectConcrete>(
      connectable.uid,
    );
    o.repositionCenterToWC(targetCoordWC);
    return {
      didSlide: false,
      vectorWC: Flatten.vector(
        targetCoordWC.x - startWC.x,
        targetCoordWC.y - startWC.y,
      ),
    };
  }
}

function getPipeVector(
  context: CanvasContext,
  pipe: EdgeObjectConcrete,
  fromUid: string,
): Flatten.Vector {
  const other = pipe.entity.endpointUid.find((u) => u !== fromUid)!;
  const from = context.globalStore.get(fromUid)!.toWorldCoord();
  const to = context.globalStore.get(other)!.toWorldCoord();
  return Flatten.vector(to.x - from.x, to.y - from.y);
}

function getPipeLine(
  context: CanvasContext,
  pipe: EdgeObjectConcrete,
  fromUid: string,
): Flatten.Line {
  const other = pipe.entity.endpointUid.find((u) => u !== fromUid)!;
  const from = context.globalStore.get(fromUid)!.toWorldCoord();
  const to = context.globalStore.get(other)!.toWorldCoord();
  return Flatten.line(Flatten.point(from.x, from.y), Flatten.point(to.x, to.y));
}

interface PipeRunConnectableRecord {
  connectable: ConnectableEntityConcrete;

  // Pipes that are on either side of the connectable, going outwards in the
  // same (positive) direction as the run vector or opposite (negative) direction.
  positives: EdgeEntityConcrete[];
  negatives: EdgeEntityConcrete[];
}

export function projectThroughVector(
  subject: Flatten.Vector,
  base: Flatten.Vector,
  angle: Flatten.Vector,
) {
  const b =
    (angle.x * subject.y - angle.y * subject.x) /
    (angle.x * base.y - angle.y * base.x);
  return base.multiply(b);
}

export function getCoolDragCorrelations(options: {
  globalStore: GlobalStore;
  systemNodes: DrawableSystemNode[];
  includedShape?: Flatten.Shape;
  includedDistanceMM?: number;
  self: DrawableObjectConcrete | null;
  selfMove: MoveIntent;
}) {
  const {
    globalStore,
    systemNodes,
    includedShape,
    includedDistanceMM,
    self,
    selfMove,
  } = options;

  const result: { object: DrawableObjectConcrete; move: MoveIntent }[] = [];
  const angles: number[] = [];
  for (const systemNode of systemNodes) {
    const systemNodeP = systemNode.toWorldCoord();
    const systemNodePoint = Flatten.point(systemNodeP.x, systemNodeP.y);
    result.push({
      object: systemNode,
      move: {
        type: "literal",
      },
    });
    if (includedShape || includedDistanceMM) {
      for (const connectable of getSystemNodeAccessories(
        globalStore,
        systemNode.uid,
        (c) => {
          const p = c.toWorldCoord();
          const point = Flatten.point(p.x, p.y);
          if (includedShape) {
            return (
              includedShape.distanceTo(point)[0] < (includedDistanceMM || 0)
            );
          } else if (includedDistanceMM) {
            return systemNodePoint.distanceTo(point)[0] < includedDistanceMM;
          }
          throw new Error("unreachable");
        },
      ).connectables) {
        result.push({
          object: globalStore.get(connectable.uid) as DrawableObjectConcrete,
          move: {
            type: "literal",
          },
        });
      }
    }

    const connections = globalStore.getConnections(systemNode.uid);
    for (const connection of connections) {
      const pipe = globalStore.get(connection);
      if (!isCoreEdgeObject(pipe)) {
        continue;
      }
      const otherUid = pipe.entity.endpointUid.find(
        (u) => u !== systemNode.uid,
      )!;
      const other = globalStore.get(otherUid)!;
      const otherP = other.toWorldCoord();

      angles.push(
        Math.atan2(otherP.y - systemNodeP.y, otherP.x - systemNodeP.x),
      );
    }
  }
  const allAnglesSame = angles.every((a) =>
    isParallelRad(a, angles[0], STRAIGHT_PIPES_THRESHOLD_RAD),
  );

  if (self) {
    result.push({
      object: self,
      move: allAnglesSame ? selfMove : { type: "literal" },
    });
  }
  return result;
}

export function getSystemNodeAccessories(
  globalStore: GlobalStore,
  uid: string,
  filter: (c: ConnectableObjectConcrete) => boolean = () => true,
): {
  connectables: ConnectableEntityConcrete[];
} {
  const node = globalStore.get(uid);
  if (!(node instanceof DrawableSystemNode)) {
    throw new Error(`Expected SystemNode, got ${node.entity.type}`);
  }

  assertType<DrawableSystemNode>(node);

  const connectables: ConnectableEntityConcrete[] = [];
  const seenConnectableUids = new Set<string>();

  function helper(connectable: ConnectableEntityConcrete) {
    if (seenConnectableUids.has(connectable.uid)) {
      return;
    }
    seenConnectableUids.add(connectable.uid);
    //if (!(connectable.type === EntityType.SYSTEM_NODE)) {
    connectables.push(connectable);
    //}
    const connections = globalStore.getConnections(connectable.uid);
    for (const connection of connections) {
      const o = globalStore.get(connection);
      if (o && isCoreEdgeObject(o)) {
        // if (o.computedLengthM < ACCESSORY_PIPE_LENGTH_THRESHOLD_M) {
        const other = o.entity.endpointUid.find((u) => u !== connectable.uid)!;
        const co = globalStore.get(other);
        if (
          !isConnectableEntity(co.entity) ||
          !filter(co as ConnectableObjectConcrete)
        ) {
          continue;
        }
        helper(co.entity);
        // }
      }
    }
  }

  helper(node.entity);

  return {
    connectables,
  };
}

export function getPipeRun(
  context: CanvasContext,
  uid: string,
): {
  pipes: EdgeEntityConcrete[];

  // are sorted by position along the run based on coordinate (regardless of topology)
  connectables: PipeRunConnectableRecord[];

  // vector parallel to the run, from first to last connectable
  vector: Flatten.Vector;
} {
  const o = context.globalStore.get(uid);
  if (!isCoreEdgeObject(o)) {
    throw new Error("getPipeRun: not a pipe. Is: " + o.entity.type);
  }

  const endpoints = o.entity.endpointUid.map((uid) =>
    context.globalStore.get(uid),
  );
  const endpointWcs = endpoints.map((o) => o.toWorldCoord());

  const pipeRun: EdgeEntityConcrete[] = [];
  const connectables: PipeRunConnectableRecord[] = [];

  const targetVector = new Flatten.Vector(
    endpointWcs[1].x - endpointWcs[0].x,
    endpointWcs[1].y - endpointWcs[0].y,
  );

  if (targetVector.length < EPS) {
    return {
      pipes: [],
      connectables: [],
      vector: targetVector,
    };
  }

  const includedPipeUids = new Set();
  const includedConnectableUids = new Set();

  function getPipeRunDfs(point: ConnectableEntityConcrete) {
    if (includedConnectableUids.has(point.uid)) {
      return;
    }
    includedConnectableUids.add(point.uid);
    const connections = context.globalStore.getConnections(point.uid);
    const wc = context.globalStore.get(point.uid)!.toWorldCoord();

    const positives: EdgeEntityConcrete[] = [];
    const negatives: EdgeEntityConcrete[] = [];

    for (const connection of connections) {
      const o = context.globalStore.get(connection);
      if (!isCoreEdgeObject(o)) {
        continue;
      }

      const otherConnectableUid = o.entity.endpointUid.find(
        (uid) => uid !== point.uid,
      )!;
      const otherConnectable = context.globalStore.get(otherConnectableUid);

      const otherWc = otherConnectable!.toWorldCoord();
      const outwardsVector = new Flatten.Vector(
        otherWc.x - wc.x,
        otherWc.y - wc.y,
      );
      if (outwardsVector.length < EPS) {
        return {
          pipes: [],
          connectables: [],
          vector: targetVector,
        };
      }

      const angle = targetVector.angleTo(outwardsVector);
      if (angle < Math.PI / 2) {
        positives.push(o.entity);
      } else {
        negatives.push(o.entity);
      }

      if (includedPipeUids.has(o.entity.uid)) {
        continue;
      }
      includedPipeUids.add(o.entity.uid);

      if (!isConnectableEntity(otherConnectable.entity)) {
        continue;
      }

      const thisVector = new Flatten.Vector(otherWc.x - wc.x, otherWc.y - wc.y);

      if (
        isHorizontalRad(
          canonizeAngleRad(targetVector.angleTo(thisVector)),
          STRAIGHT_PIPES_THRESHOLD_RAD,
        )
      ) {
        pipeRun.push(o.entity);
        getPipeRunDfs(otherConnectable.entity);
        // Do not simply break here - the function is still correct with multiple concurrent pipes
        // at different heights. But we do have to check for duplicates to avoid exponentially
        // including pipes if it is, say, a chain of loops.
      }
    }

    connectables.push({
      connectable: point,
      positives,
      negatives,
    });
  }

  function getPositionAlongRun(point: ConnectableEntityConcrete): number {
    const wc = context.globalStore.get(point.uid)!.toWorldCoord();
    const vector = new Flatten.Vector(
      wc.x - endpointWcs[0].x,
      wc.y - endpointWcs[0].y,
    );
    return vector.dot(targetVector) / targetVector.dot(targetVector);
  }

  connectables.sort((a, b) => {
    return (
      getPositionAlongRun(a.connectable) - getPositionAlongRun(b.connectable)
    );
  });

  getPipeRunDfs(endpoints[0].entity as ConnectableEntityConcrete);

  return {
    pipes: pipeRun,
    connectables,
    vector: targetVector,
  };
}
