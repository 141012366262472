import mixpanel from "mixpanel-browser";
import { CURRENT_VERSION } from "../../../common/src/api/config";
import { AnalyticsPayload } from "../../../common/src/api/document/analytics/analytics";
import { ENABLE_MIXPANEL } from "../config";
import { useDocument } from "../lib/hooks/use-document";

export function trackEvent(payload: AnalyticsPayload) {
  if (!ENABLE_MIXPANEL) return;

  const properties = {
    ...getCommonProps(),
    ...("props" in payload ? payload.props : {}),
  };

  try {
    mixpanel.track(payload.type, properties);
    console.log("Trying to track:", payload.type, properties);
  } catch (error) {
    console.error("Tracking Failed:", error);
  }
}

function getCommonProps(): Record<string, any> {
  const document = useDocument();

  return {
    documentId: String(document.documentId),
    historyIndex: document.uiState.historyIndex,
    documentVersion: CURRENT_VERSION,
  };
}
