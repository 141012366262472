import { immutableAppend } from "../../../../common/src/lib/utils";
import { KeyboardEventRecordT } from "../../../../flatbuffers/generated/keyboard-event-record";
import { ActiveReplayState } from "../../../src/store/document/types";
import store from "../../../src/store/store";
import { replay } from "./replays";

const getTargetTag = (target: any) => {
  if (!target) return "unknown";
  const tag = (target.tagName as string | undefined)?.toLowerCase();
  if (!tag) return "unknown";
  return tag;
};

export const initKeyboardLogging = () => {
  document.addEventListener("DOMContentLoaded", (_) => {
    document.addEventListener("keydown", (e) => {
      if (!replay.previousOrderIndex) return;
      const userName = replay.getUserName();
      if (!userName) return;
      const target = e.target;
      if (!(target instanceof HTMLElement)) return; // not sure how this would occur but playwright picks this up sometimes.
      const tag = getTargetTag(target);
      const key = e.key;
      const dataCy = !target.hasAttribute("data-testid")
        ? null
        : target.getAttribute("data-testid")!.slice(0, 100);
      const outerHTML = dataCy ? null : target.outerHTML?.slice(0, 100);
      const documentId = (store.state as any).document.documentId as number;
      if (documentId == null || documentId == undefined) return;
      if (!key || tag == null) return; // required flatbuffer fields. also doesn't really make sense to log these kinds of events. what does it even mean for 'key' to be null, they must have pressed something!!
      replay.storeKeyboardEventRecord(
        new KeyboardEventRecordT(
          BigInt(Date.now()),
          userName,
          documentId,
          replay.previousOrderIndex,
          key,
          dataCy,
          outerHTML,
          tag,
        ),
      );
    });
  });
};

export const stringifyKeyboardEventRecord = (e: KeyboardEventRecordT) => {
  const s1 = `${e.userName} pressed '${e.key}' key on `;
  const s2 = e.dataCy ?? e.outerHtml;
  return `${s1}${s2}`;
};

export const renderKeyboardEventRecord = async (
  event: KeyboardEventRecordT,
  replayState: ActiveReplayState,
) => {
  const eventsToLog = immutableAppend(replayState.eventsToLog, event);
  const newReplayState: ActiveReplayState = { ...replayState, eventsToLog };
  return newReplayState;
};
