import { v4 } from "uuid";
import { Color } from "../../../lib/color";
import { Coord } from "../../../lib/coord";
import { Units } from "../../../lib/measurements";
import {
  assertUnreachable,
  cloneSimple,
  DeepPartial,
} from "../../../lib/utils";
import { CoreContext } from "../../calculations/types";
import { isSewer } from "../../config";
import {
  FittingEntryType,
  FittingReference,
} from "../../coreObjects/coreFitting";
import { determineConnectableNetwork } from "../../coreObjects/utils";
import {
  DEFAULT_HORIZONTAL_SYSTEM_NETWORKS,
  isDuctFlowSystem,
  isPipeFlowSystem,
} from "../flow-systems";
import { getFlowSystem } from "../utils";
import { DrawableEntityConcrete } from "./concrete-entity";
import { isPipeEntity } from "./conduit-entity";
import {
  FieldType,
  PropertyField,
  withPropertyTracking,
} from "./property-field";
import { ConnectableEntity, NamedEntity } from "./simple-entities";
import { EntityType } from "./types";

export interface FittingEntityV1 extends ConnectableEntity, NamedEntity {
  type: EntityType.FITTING;
  center: Coord;
  systemUid: string;
  color: Color | null;
}
interface FittingEntityBase extends ConnectableEntity, NamedEntity {
  type: EntityType.FITTING;
  center: Coord;
  systemUid: string;
  color: Color | null;
}
export type Fittingtype = "pipe" | "duct" | "cable";

export interface PipeFittingEntity extends FittingEntityBase {
  fittingType: "pipe";
  fitting: PipeFitting;
}
export interface PipeFitting {}
export interface DuctFittingEntity extends FittingEntityBase {
  fittingType: "duct";
  fitting: DuctFitting;
}
export interface DuctFitting extends DuctFittingStructureFields {
  zetaByConnection: Record<string, number>;
}

export interface DuctFittingStructureFields {
  transitionAngle: number | null;
  circularElbow: "square" | "multi-piece" | "smooth" | null;
  rectangularElbow:
    | "square"
    | "square-vanes"
    | "smooth"
    | "smooth-vanes"
    | null;
  // default number of vanes - used only if there are vanes
  elbowVanes: number | null;
  // default number of fittings - used only if there are multiple pieces
  pieces: number | null;
  smoothElbowRadiusRatio: number | null;

  rectRectTee: "square" | "shoe" | "bell" | null;
  rectCircTee: "square" | "shoe" | "bell" | null;
  circCircTee: "square" | "shoe" | "bell" | null;

  shoeLengthRatio: number | null;

  rectSymmetrical: "square" | null;
  circSymmetrical: "square" | "breech" | "y-piece" | null;
}

export interface CableFittingEntity extends FittingEntityBase {
  fittingType: "cable";
  fitting: CableFitting;
}
export interface CableFitting {}

export type FittingEntity =
  | PipeFittingEntity
  | DuctFittingEntity
  | CableFittingEntity;

export type FittingConcrete = PipeFitting | DuctFitting | CableFitting;

export type FittingModelLevelsView = Record<
  string,
  {
    fitting: string;
    conduit: string[];
    heightM: number;
  }
>;

export function isPipeFittingEntity(
  entity: DrawableEntityConcrete | undefined,
): entity is PipeFittingEntity {
  return !!(
    entity &&
    entity.type === EntityType.FITTING &&
    entity.fittingType === "pipe"
  );
}
export function isDuctFittingEntity(
  entity: DrawableEntityConcrete | undefined,
): entity is DuctFittingEntity {
  return !!(
    entity &&
    entity.type === EntityType.FITTING &&
    entity.fittingType === "duct"
  );
}
export function isCableFittingEntity(
  entity: DrawableEntityConcrete | undefined,
): entity is CableFittingEntity {
  return !!(
    entity &&
    entity.type === EntityType.FITTING &&
    entity.fittingType === "cable"
  );
}

export function getFittingTypeName(type: Fittingtype) {
  switch (type) {
    case "pipe":
      return "Pipe";
    case "duct":
      return "Duct";
    case "cable":
      return "Cable";
  }
  assertUnreachable(type);
}

export function getFittingName(entity: FittingEntity) {
  return getFittingTypeName(entity.fittingType);
}
function makePipeFittingFields(
  context: CoreContext,
  filled: PipeFittingEntity,
): PropertyField[] {
  const { drawing } = context;
  const systems = drawing.metadata.flowSystemUidsInOrder
    .map((uid) => drawing.metadata.flowSystems[uid])
    .filter(isPipeFlowSystem);
  return [
    {
      property: "entityName",
      title: "Name",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Text,
      params: null,
      multiFieldId: "entityName",
    },
    {
      property: "systemUid",
      title: "Flow System",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.FlowSystemChoice,
      params: { systems },
      multiFieldId: "systemUid",
    },

    {
      property: "color",
      title: "Color",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Color,
      params: null,
      multiFieldId: "color",
    },
  ];
}

export function makeDuctFittingPressureDropFields(
  context: CoreContext,
  ductFitting: DuctFitting,
  prefix = "fitting.",
) {
  if (prefix !== "") {
    if (!prefix.endsWith(".")) {
      prefix += ".";
    }
  }
  const fields: PropertyField[] = [];
  fields.push({
    property: `${prefix}circularElbow`,
    title: "Circular Elbow Type",
    hasDefault: true,
    isCalculated: false,
    type: FieldType.Choice,
    multiFieldId: "circularElbow",
    params: {
      choices: [
        { key: "square", name: "Square" },
        { key: "multi-piece", name: "Multi Piece" },
        { key: "smooth", name: "Smooth" },
      ],
    },
  });

  if (ductFitting.circularElbow === "multi-piece") {
    // TODO: consider making this a choice between 3 and 5.
    fields.push({
      property: `${prefix}pieces`,
      title: "Number of Pieces",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Number,
      multiFieldId: "pieces",
      params: { min: 3, max: 5 },
    });
  }

  fields.push({
    property: `${prefix}rectangularElbow`,
    title: "Rectangular Elbow Type",
    hasDefault: true,
    isCalculated: false,
    type: FieldType.Choice,
    multiFieldId: "rectangularElbow",
    params: {
      choices: [
        { key: "square", name: "Square" },
        { key: "square-vanes", name: "Square with Vanes" },
        { key: "smooth", name: "Smooth" },
        { key: "smooth-vanes", name: "Smooth with Vanes" },
      ],
    },
  });

  if (
    ductFitting.rectangularElbow === "smooth-vanes"
    // square vanes are not counted - just a panel of turning vanes
    // ductFitting.rectangularElbow === "square-vanes"
  ) {
    fields.push({
      property: `${prefix}elbowVanes`,
      title: "Number of Vanes",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Number,
      multiFieldId: "elbowVanes",
      params: { min: 1, max: 3 },
    });
  }

  fields.push(
    {
      property: `${prefix}rectRectTee`,
      title: "Tee Type - Rect Main to Rect Branch",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Choice,
      multiFieldId: "rectRectTee",
      params: {
        choices: [
          { key: "square", name: "Square" },
          { key: "shoe", name: "Shoe" },
          { key: "bell", name: "Bell" },
        ],
      },
    },

    {
      property: `${prefix}rectCircTee`,
      title: "Tee Type - Rect Main to Circ Branch",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Choice,
      multiFieldId: "rectCircTee",
      params: {
        choices: [
          { key: "square", name: "Square" },
          { key: "shoe", name: "Shoe" },
          { key: "bell", name: "Bell" },
        ],
      },
    },

    {
      property: `${prefix}circCircTee`,
      title: "Tee Type - Circ Main to Circ Branch",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Choice,
      multiFieldId: "circCircTee",
      params: {
        choices: [
          { key: "square", name: "Square" },
          { key: "shoe", name: "Shoe" },
          { key: "bell", name: "Bell" },
        ],
      },
    },
  );

  if (
    ductFitting.rectRectTee === "shoe" ||
    ductFitting.rectCircTee === "shoe" ||
    ductFitting.circCircTee === "shoe"
  ) {
    fields.push({
      property: `${prefix}shoeLengthRatio`,
      title: "Shoe Length Ratio",
      hasDefault: true,
      hint: "Ratio of shoe length on either side to the duct's diameter/width",
      isCalculated: false,
      type: FieldType.Number,
      multiFieldId: "shoeLengthRatio",
      params: { min: 0.0, max: null },
    });
  }

  // TODO: consider making this a selection of standard angles
  fields.push({
    property: `${prefix}transitionAngle`,
    title: "Generated Transition Angle",
    hasDefault: true,
    isCalculated: false,
    type: FieldType.Choice,
    multiFieldId: "transitionAngle",
    params: {
      choices: [
        { key: 15, name: "15°" },
        { key: 30, name: "30°" },
        { key: 45, name: "45°" },
        { key: 60, name: "60°" },
      ],
    },
  });

  if (
    ductFitting.rectangularElbow === "smooth" ||
    ductFitting.circularElbow === "smooth"
  ) {
    fields.push({
      property: `${prefix}smoothElbowRadiusRatio`,
      title: "Smooth Elbow R:W",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Number,
      multiFieldId: "smoothElbowRadiusRatio",
      params: { min: 0.0, max: null },
    });
  }

  return fields;
}

function makeDuctFittingFields(
  context: CoreContext,
  filled: DuctFittingEntity,
): PropertyField[] {
  const { drawing } = context;
  const systems = drawing.metadata.flowSystemUidsInOrder
    .map((uid) => drawing.metadata.flowSystems[uid])
    .filter(isDuctFlowSystem);
  const coreFitting = context.globalStore.getObjectOfTypeOrThrow(
    EntityType.FITTING,
    filled.uid,
  );

  const crossSectionBreakdown = coreFitting.getCrossSectionBreakdown(context);

  const fields: PropertyField[] = [];
  fields.push(
    {
      property: "entityName",
      title: "Name",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Text,
      params: null,
      multiFieldId: "entityName",
    },
    {
      property: "systemUid",
      title: "Flow System",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.FlowSystemChoice,
      params: { systems },
      multiFieldId: "systemUid",
    },

    {
      property: "color",
      title: "Color",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Color,
      params: null,
      multiFieldId: "color",
    },
  );

  let createLevelsView = ({ referenceMap }: FittingReference) => {
    // Maps some level to fitting, and conduit
    let levelsView: FittingModelLevelsView = {};

    for (const [key, entry] of Object.entries(referenceMap)) {
      switch (entry.type) {
        case FittingEntryType.FITTING: {
          if (entry.heightM === null) {
            continue;
          }
          if (!levelsView[entry.entity.uid]) {
            levelsView[entry.entity.uid] = {
              fitting: "",
              conduit: Object.values(referenceMap)
                .filter(
                  (e) =>
                    e.entity.type === EntityType.CONDUIT &&
                    e.entity.endpointUid.includes(entry.entity.uid),
                )
                .map((e) => e.entity.uid),
              heightM: entry.heightM,
            };
          }
          levelsView[entry.entity.uid].fitting = key;
          break;
        }
        case FittingEntryType.CONDUIT:
        case FittingEntryType.VERTICAL_CONDUIT:
          break;

        default:
          assertUnreachable(entry);
      }
    }

    return levelsView;
  };
  let referenceMap = coreFitting.getCrossSectionBreakdown(context);
  let levelsView = createLevelsView(referenceMap);
  const connections = context.globalStore.getConnections(filled.uid);

  if (connections.length > 1) {
    const hasVerticalSegments =
      Object.values(referenceMap.referenceMap).filter(
        (r) => r.type === FittingEntryType.VERTICAL_CONDUIT,
      ).length > 0;
    if (hasVerticalSegments) {
      fields.push({
        property: "",
        title: "Friction Loss Zeta",
        hasDefault: false,
        isCalculated: false,
        type: FieldType.FittingModel,
        params: {
          // Give frontend full ability to interpret the data
          // Okay, but frontend only interested on geometry data
          reference: referenceMap,
          levelsView: levelsView,
        },
        multiFieldId: "",
        units: Units.None,
      });
    }

    fields.push({
      type: FieldType.Custom,
      property: "zetaByConnection",
      title: hasVerticalSegments ? "" : "Friction Loss Zeta",
      hasDefault: false,
      isCalculated: false,
      params: {
        reference: referenceMap,
      },
      slot: true,
      multiFieldId: "zetaByConnection",
    });
  }

  fields.push(...makeDuctFittingPressureDropFields(context, filled.fitting));

  fields.push(
    {
      property: "fitting.rectSymmetrical",
      title: "Symmetrical Tee - Rectangular",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Choice,
      multiFieldId: "rectSymmetrical",
      params: {
        choices: [{ key: "square", name: "Square" }],
      },
    },

    {
      property: "fitting.circSymmetrical",
      title: "Symmetrical Tee - Circular",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Choice,
      multiFieldId: "circSymmetrical",
      params: {
        choices: [
          { key: "square", name: "Square" },
          { key: "breech", name: "Y Breech" },
          { key: "y-piece", name: "Y Piece" },
        ],
      },
    },
  );

  return fields;
}

function makeCableFittingFields(
  context: CoreContext,
  filled: CableFittingEntity,
): PropertyField[] {
  const { drawing } = context;
  const systems = drawing.metadata.flowSystems;
  return [];
}
export function makeFittingFields(
  context: CoreContext,
  entity: FittingEntity,
): PropertyField[] {
  switch (entity.fittingType) {
    case "pipe":
      return makePipeFittingFields(context, entity).map(
        withPropertyTracking(context, entity),
      );
    case "duct":
      return makeDuctFittingFields(context, entity).map(
        withPropertyTracking(context, entity),
      );
    case "cable":
      throw new Error("not implemented");
  }
  assertUnreachable(entity);
}
function fillPipeFittingDefaultFields(
  context: CoreContext,
  entity: PipeFittingEntity,
) {
  const { drawing, globalStore } = context;
  const result = cloneSimple(entity);

  // get system
  const system = getFlowSystem(drawing, result.systemUid);

  if (system) {
    if (result.color == null) {
      result.color = system.color;
      if (isSewer(system)) {
        let iAmVent = false;
        for (const conn of globalStore.getConnections(result.uid)) {
          const pipe = globalStore.get(conn);
          if (
            isPipeEntity(pipe.entity) &&
            pipe.entity.conduit.network === "vents"
          ) {
            iAmVent = true;
          }
        }
        if (iAmVent) {
          result.color = system.ventColor;
        }
      }
    }
  } else {
    throw new Error(
      "Existing system not found for object " + JSON.stringify(entity),
    );
  }

  return result;
}
function fillDuctFittingDefaultFields(
  context: CoreContext,
  entity: DuctFittingEntity,
) {
  const { drawing, globalStore } = context;
  const result = cloneSimple(entity);
  const calc = context.globalStore.getOrCreateCalculation(entity);

  // get system
  const system = getFlowSystem<"ventilation">(drawing, result.systemUid)!;

  if (system) {
    const networkKey = determineConnectableNetwork<typeof system.type>(
      globalStore,
      result,
      system,
      true,
    );
    if (result.color == null) {
      result.color = system.color;
    }
    const network =
      system.networks[
        networkKey || DEFAULT_HORIZONTAL_SYSTEM_NETWORKS[system.type]
      ];

    if (network) {
      if (result.fitting.transitionAngle == null) {
        result.fitting.transitionAngle = network.transitionAngle;
      }
      if (result.fitting.circularElbow == null) {
        result.fitting.circularElbow = network.circularElbow;
      }
      if (result.fitting.rectangularElbow == null) {
        result.fitting.rectangularElbow = network.rectangularElbow;
      }
      if (result.fitting.rectRectTee == null) {
        result.fitting.rectRectTee = network.rectRectTee;
      }
      if (result.fitting.rectCircTee == null) {
        result.fitting.rectCircTee = network.rectCircTee;
      }
      if (result.fitting.circCircTee == null) {
        result.fitting.circCircTee = network.circCircTee;
      }
      if (result.fitting.shoeLengthRatio == null) {
        result.fitting.shoeLengthRatio = network.shoeLengthRatio;
      }
      if (result.fitting.elbowVanes == null) {
        result.fitting.elbowVanes = network.elbowVanes;
      }
      if (result.fitting.pieces == null) {
        result.fitting.pieces = network.pieces;
      }
      if (result.fitting.smoothElbowRadiusRatio == null) {
        result.fitting.smoothElbowRadiusRatio = network.smoothElbowRadiusRatio;
      }
      if (result.fitting.rectSymmetrical == null) {
        result.fitting.rectSymmetrical = network.rectSymmetrical;
      }
      if (result.fitting.circSymmetrical == null) {
        result.fitting.circSymmetrical = network.circSymmetrical;
      }
    }

    for (const con in calc?.zetaByConnection) {
      if (!(con in result.fitting.zetaByConnection)) {
        result.fitting.zetaByConnection[con] = calc.zetaByConnection[con]!;
      }
    }
  } else {
    throw new Error(
      "Existing system not found for object " + JSON.stringify(entity),
    );
  }

  return result;
}

export function fillFittingDefaultFields(
  context: CoreContext,
  entity: FittingEntity,
): FittingEntity {
  switch (entity.fittingType) {
    case "pipe":
      return fillPipeFittingDefaultFields(context, entity);
    case "duct":
      return fillDuctFittingDefaultFields(context, entity);
    case "cable":
      return cloneSimple(entity);
  }
  assertUnreachable(entity);
}

export function makeFittingEntity(
  context: CoreContext,
  fields: DeepPartial<FittingEntity> &
    Pick<FittingEntity, "fittingType" | "center" | "systemUid">,
): FittingEntity {
  return {
    uid: fields.uid ?? v4(),
    type: EntityType.FITTING,
    center: fields.center,
    systemUid: fields.systemUid,
    color: (fields.color as Color | null) ?? null,
    calculationHeightM: fields.calculationHeightM ?? null,
    parentUid: fields.parentUid ?? null,
    entityName: fields.entityName ?? null,
    ...makeInnerFittingFields(fields.fittingType),
  };
}

function makeInnerFittingFields(fittingType: Fittingtype) {
  switch (fittingType) {
    case "pipe":
    case "cable":
      return {
        fittingType,
        fitting: {},
      };
    case "duct":
      return {
        fittingType,
        fitting: {
          efficiencyClass: null,
          transitionAngle: null,
          circularElbow: null,
          rectangularElbow: null,
          elbowVanes: null,
          pieces: null,
          smoothElbowRadiusRatio: null,
          rectRectTee: null,
          rectCircTee: null,
          circCircTee: null,
          shoeLengthRatio: null,
          rectSymmetrical: null,
          circSymmetrical: null,
          zetaByConnection: {},
        },
      };
  }
  assertUnreachable(fittingType);
}

export function computeClockwiseRotateAngle(outside: Coord, center: Coord) {
  // Translate points
  const dx = outside.x - center.x;
  const dy = outside.y - center.y;

  // Compute angle using atan2
  let angle = Math.atan2(-dx, dy); // This gets the angle from the positive y-axis

  // Convert to degrees
  angle = angle * (180 / Math.PI);

  // Ensure the angle is non-negative
  if (angle < 0) angle += 360;

  return angle;
}
