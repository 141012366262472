import * as TM from "transformation-matrix";
import { StandardFlowSystemUids } from "../../../../common/src/api/config";
import CoreBigValve from "../../../../common/src/api/coreObjects/coreBigValve";
import {
  VALVE_HEIGHT_MM,
  getRpzdBigValveHeightMM,
} from "../../../../common/src/api/coreObjects/utils";
import { CalculationData } from "../../../../common/src/api/document/calculations-objects/calculation-field";
import BigValveEntity, {
  BigValveType,
  fillDefaultBigValveFields,
} from "../../../../common/src/api/document/entities/big-valve/big-valve-entity";
import { DrawableEntityConcrete } from "../../../../common/src/api/document/entities/concrete-entity";
import { EntityType } from "../../../../common/src/api/document/entities/types";
import { getFlowSystem } from "../../../../common/src/api/document/utils";
import { Color, rgb2color } from "../../../../common/src/lib/color";
import { Coord, coordDist2 } from "../../../../common/src/lib/coord";
import { assertUnreachable } from "../../../../common/src/lib/utils";
import { DEFAULT_FONT_NAME } from "../../config";
import { rgb2style } from "../../lib/utils";
import { getGlobalContext } from "../../store/globalCoreContext";
import { prepareFill, prepareStroke } from "../helpers/draw-helper";
import {
  MoveIntent,
  getCoolDragCorrelations,
} from "../lib/black-magic/cool-drag";
import CanvasContext from "../lib/canvas-context";
import { EntityDrawingArgs } from "../lib/drawable-object";
import { EntityPopupContent } from "../lib/entity-popups/types";
import { Interaction, InteractionType } from "../lib/interaction";
import { AttachableObject } from "../lib/object-traits/attachable-object";
import { CalculatedObject } from "../lib/object-traits/calculated-object";
import { FIELD_HEIGHT } from "../lib/object-traits/calculated-object-const";
import { CenteredObject } from "../lib/object-traits/centered-object";
import CoolDraggableObject from "../lib/object-traits/cool-draggable-object";
import { Core2Drawable } from "../lib/object-traits/core2drawable";
import {
  HoverSiblingResult,
  HoverableObject,
} from "../lib/object-traits/hoverable-object";
import { SelectableObject } from "../lib/object-traits/selectable";
import {
  SnapIntention,
  SnapTarget,
  SnappableObject,
  getBlockLikeSnapLocations,
} from "../lib/object-traits/snappable-object";
import { DrawingContext, ObjectConstructArgs } from "../lib/types";
import {
  VALVE_LINE_WIDTH_MM,
  drawRpzdDouble,
  getHighlightColor,
} from "../lib/utils";
import { DrawableObjectConcrete } from "./concrete-object";
import DrawableConduit from "./drawableConduit";
import DrawableSystemNode from "./drawableSystemNode";
import { applyHoverEffects, isLayoutActive } from "./utils";

export const BIG_VALVE_DEFAULT_PIPE_WIDTH_MM = 20;

const Base = CalculatedObject(
  SelectableObject(
    AttachableObject(
      CoolDraggableObject(
        HoverableObject(
          CenteredObject(SnappableObject(Core2Drawable(CoreBigValve))),
        ),
      ),
    ),
  ),
);

export default class DrawableBigValve extends Base {
  lastDrawnWorldRadius: number = 0; // for bounds detection

  getSnapTargets(request: SnapIntention[], mouseWc: Coord): SnapTarget[] {
    return getBlockLikeSnapLocations(
      request,
      mouseWc,
      this.getInletsOutlets(),
      this.toWorldCoord(),
      this.toWorldAngleDeg(0) * (Math.PI / 180),
    );
  }

  // We could not add this to the base drawable class because
  // of some typescript thing so they have to be added at the concrete class.
  constructor(args: ObjectConstructArgs<BigValveEntity>) {
    super(args.context, args.obj);
    this.onSelect = args.onSelect;
    this.onInteractionComplete = args.onInteractionComplete;
    this.document = args.document;
  }

  drawEntity(context: DrawingContext, args: EntityDrawingArgs): void {
    applyHoverEffects(context, this);

    switch (this.entity.valve.type) {
      case BigValveType.TMV:
        this.drawTmv(context, args);
        break;
      case BigValveType.TEMPERING:
        this.drawTemperingValve(context, args);
        break;
      case BigValveType.RPZD_HOT_COLD:
        this.drawHotColdRPZD(context, args);
        break;
    }

    // Display Entity Name
    this.withWorldAngle(
      context,
      { x: 0, y: this.entity.pipeDistanceMM / 2 },
      () => {
        if (this.entity.entityName) {
          const name = this.entity.entityName;
          context.ctx.font = 70 + "pt " + DEFAULT_FONT_NAME;
          const nameWidth = context.ctx.measureText(name).width;
          const offsetx = -nameWidth / 2;
          context.ctx.fillStyle = "rgba(0, 255, 20, 0.13)";
          // the 70 represents the height of the font
          const textHight = 70;
          const offsetY = this.entity.pipeDistanceMM + textHight / 2;
          context.ctx.fillRect(offsetx, offsetY, nameWidth, 70);
          context.ctx.fillStyle = "#000";
          context.ctx.fillTextStable(
            name,
            offsetx,
            offsetY - 4,
            undefined,
            "top",
          );
        }
      },
    );
  }

  drawTmv(
    context: DrawingContext,
    { selected, hasOverriddenField }: EntityDrawingArgs,
  ) {
    const { ctx } = context;

    const l = -this.entity.pipeDistanceMM;
    const r = this.entity.pipeDistanceMM;
    const b = (this.entity.pipeDistanceMM * 200) / 150;
    const bm = b / 2;
    const t = 0;

    const boxl = l * 1.2;
    const boxw = (r - l) * 1.2;
    const boxt = 0 - b * 0.1;
    const boxh = b * 1.2;

    const scale = context.vp.currToSurfaceScale(ctx);
    ctx.lineWidth = Math.max(1 / scale, 10 * this.toWorldLength(1));
    ctx.strokeStyle = "#000";
    ctx.lineCap = "round";

    ctx.fillStyle = "rgba(255, 255, 255, 0.8)";
    prepareFill(this, ctx);
    ctx.fillRect(l, t, r - l, bm);
    ctx.strokeStyle = "#000";

    if (selected || hasOverriddenField) {
      ctx.fillStyle = rgb2style(
        getHighlightColor(selected, hasOverriddenField ? [Color.YELLOW] : []),
        0.4,
      );
      prepareFill(this, ctx);
      ctx.fillRect(boxl, boxt, boxw, boxh);
    }

    // Box and open
    ctx.beginPath();
    ctx.moveTo(l, bm);
    ctx.lineTo(l, t);
    ctx.lineTo(r, t);
    ctx.lineTo(r, bm);
    ctx.lineTo(l, bm);

    ctx.moveTo(l, bm);
    ctx.lineTo(r * 0.8, b * 0.8);
    prepareStroke(this, ctx);
    ctx.stroke();
  }

  isActive(): boolean {
    return this.pressureDrainageActive();
  }

  drawTemperingValve(
    context: DrawingContext,
    { selected, hasOverriddenField }: EntityDrawingArgs,
  ) {
    const { ctx } = context;

    const l = -this.entity.pipeDistanceMM;
    const r = this.entity.pipeDistanceMM;
    const b = (this.entity.pipeDistanceMM * 200) / 150;
    const t = 0;

    ctx.lineWidth = VALVE_LINE_WIDTH_MM * 2;
    ctx.strokeStyle = "#222222";
    ctx.lineCap = "square";

    if (selected || hasOverriddenField) {
      ctx.fillStyle = rgb2style(
        getHighlightColor(selected, hasOverriddenField ? [Color.YELLOW] : []),
        0.4,
      );

      prepareFill(this, ctx);
      ctx.fillRect(l * 1.2, 0 - b * 0.1, (r - l) * 1.2, b * 1.2);
    }

    ctx.beginPath();
    ctx.moveTo(l, t);
    ctx.lineTo(r, t);
    ctx.moveTo(0, t);
    ctx.lineTo(0, b);
    prepareStroke(this, ctx);
    ctx.stroke();
  }

  drawHotColdRPZD(
    context: DrawingContext,
    { selected, hasOverriddenField }: EntityDrawingArgs,
  ) {
    const { ctx } = context;
    const hotSystem = getFlowSystem(
      context.doc.drawing,
      StandardFlowSystemUids.HotWater,
    )!;
    const coldSystem = getFlowSystem(
      context.doc.drawing,
      StandardFlowSystemUids.ColdWater,
    )!;

    let highlight: Color | undefined = undefined;
    if (selected || hasOverriddenField) {
      highlight = rgb2color(
        getHighlightColor(selected, hasOverriddenField ? [Color.YELLOW] : []),
      );
    }

    ctx.rotate(Math.PI / 2);
    ctx.translate(VALVE_HEIGHT_MM, 0);
    drawRpzdDouble(
      context,
      [coldSystem.color.hex, hotSystem.color.hex],
      highlight,
      this,
    );
    ctx.translate(-VALVE_HEIGHT_MM, 0);
    ctx.rotate(-Math.PI / 2);
  }

  locateCalculationBoxWorld(
    _context: DrawingContext,
    data: CalculationData[],
    scale: number,
  ): TM.Matrix[] {
    const angle = (this.toWorldAngleDeg(0) / 180) * Math.PI;
    const height = data.length * FIELD_HEIGHT;
    const wc = this.toWorldCoord();

    const res = [
      0,
      Math.PI / 4,
      -Math.PI / 4,
      Math.PI / 2,
      -Math.PI / 2,
      (Math.PI * 3) / 4,
      (-Math.PI * 3) / 4,
      Math.PI,
    ].map((delta) => {
      return TM.transform(
        TM.identity(),
        TM.translate(wc.x, wc.y),
        TM.rotate(angle + Math.PI + delta),
        TM.translate(0, -this.entity.pipeDistanceMM * 2),
        TM.scale(scale),
        TM.translate(0, -height / 2),
        TM.rotate(-angle - Math.PI - delta),
      );
    });
    return res;
  }

  refreshObjectInternal(_obj: BigValveEntity): void {
    //
  }

  pressureDrainageActive(): boolean {
    return isLayoutActive(this.context, this.document.uiState, "pressure");
  }

  inBounds(objectCoord: Coord) {
    if (!this.isActive()) {
      return false;
    }
    if (Math.abs(objectCoord.x) <= this.entity.pipeDistanceMM * 1.3) {
      if (objectCoord.y > -this.entity.pipeDistanceMM * 0.4) {
        if (objectCoord.y < ((this.entity.pipeDistanceMM * 200) / 150) * 1.3) {
          return true;
        }
      }
    }
    return false;
  }

  prepareDelete(
    context: CanvasContext,
    _calleeEntityUid?: string,
  ): DrawableObjectConcrete[] {
    const list: DrawableObjectConcrete[] = [];
    switch (this.entity.valve.type) {
      case BigValveType.TMV:
        list.push(
          ...(context.globalStore
            .get(this.entity.valve.coldOutputUid)
            ?.prepareDelete(context) ?? []),
        );
        list.push(
          ...(context.globalStore
            .get(this.entity.valve.warmOutputUid)
            ?.prepareDelete(context) ?? []),
        );
        break;
      case BigValveType.TEMPERING:
        list.push(
          ...(context.globalStore
            .get(this.entity.valve.warmOutputUid)
            ?.prepareDelete(context) ?? []),
        );
        break;
      case BigValveType.RPZD_HOT_COLD:
        list.push(
          ...(context.globalStore
            .get(this.entity.valve.coldOutputUid)
            ?.prepareDelete(context) ?? []),
        );
        list.push(
          ...(context.globalStore
            .get(this.entity.valve.hotOutputUid)
            ?.prepareDelete(context) ?? []),
        );
        break;
      default:
        assertUnreachable(this.entity.valve);
    }
    list.push(
      ...(context.globalStore
        .get(this.entity.coldRoughInUid)
        ?.prepareDelete(context) ?? []),
      ...(context.globalStore
        .get(this.entity.hotRoughInUid)
        ?.prepareDelete(context) ?? []),
      this,
    );

    return list.filter(Boolean);
  }

  getOutlets(): DrawableSystemNode[] {
    const result: string[] = [];
    switch (this.entity.valve.type) {
      case BigValveType.TMV:
        result.push(
          this.entity.valve.warmOutputUid,
          this.entity.valve.coldOutputUid,
        );
        break;
      case BigValveType.TEMPERING:
        result.push(this.entity.valve.warmOutputUid);
        break;
      case BigValveType.RPZD_HOT_COLD:
        result.push(
          this.entity.valve.hotOutputUid,
          this.entity.valve.coldOutputUid,
        );
        break;
      default:
        assertUnreachable(this.entity.valve);
    }
    return result.map((uid) => this.globalStore.get(uid) as DrawableSystemNode);
  }

  getInletsOutlets(): DrawableSystemNode[];
  getInletsOutlets() {
    return super.getInletsOutlets();
  }

  offerJoiningInteraction(
    requestSystemUid: string | undefined,
    interaction: Interaction,
  ): DrawableEntityConcrete[] | null {
    const inouts = this.getInletsOutlets();
    inouts.sort((a, b) => {
      const awc = a.toWorldCoord();
      const bwc = b.toWorldCoord();
      return (
        coordDist2(awc, interaction.worldCoord) -
        coordDist2(bwc, interaction.worldCoord)
      );
    });
    for (const sys of inouts) {
      if (sys.offerInteraction(interaction)) {
        return [sys.entity, this.entity];
      }
    }
    return null;
  }

  offerInteraction(interaction: Interaction): DrawableEntityConcrete[] | null {
    switch (interaction.type) {
      case InteractionType.CONTINUING_CONDUIT:
      case InteractionType.STARTING_CONDUIT:
        return this.offerJoiningInteraction(
          interaction.system?.uid,
          interaction,
        );
      case InteractionType.SNAP_ONTO_RECEIVE:
        if (interaction.src.type === EntityType.FITTING) {
          return this.offerJoiningInteraction(
            interaction.src.systemUid,
            interaction,
          );
        } else {
          return null;
        }
      case InteractionType.INSERT:
      case InteractionType.SNAP_ONTO_SEND:
      case InteractionType.EXTEND_NETWORK:
        return null;
      case InteractionType.LINK_ENTITY:
        return [this.entity];
    }
  }

  getCoolDragCorrelations(
    myMove: MoveIntent,
    _from?: DrawableObjectConcrete | undefined,
  ): { object: DrawableObjectConcrete; move: MoveIntent }[] {
    let selfMove: MoveIntent = { type: "literal" };
    if (myMove.type === "slide") {
      selfMove = {
        type: "slide",
        normal: myMove.normal,
        negatives: [],
        positives: [],
      };
    }
    return getCoolDragCorrelations({
      globalStore: this.globalStore,
      systemNodes: this.getInletsOutlets(),
      self: this,
      includedDistanceMM: 300,
      includedShape: this.shape || undefined,
      selfMove,
    });
  }

  getOutPipes(): DrawableConduit[] {
    const res: DrawableConduit[] = [];
    for (const sn of this.getOutlets()) {
      if (sn) {
        res.push(...sn.getConnetectedSidePipe(""));
      }
    }
    return res;
  }

  getCopiedObjects(): DrawableObjectConcrete[] {
    return [this, ...this.getInletsOutlets()];
  }

  onUpdate() {
    super.onUpdate();

    const filled = fillDefaultBigValveFields(getGlobalContext(), this.entity);

    const coldRoughIn = this.globalStore.get(filled.coldRoughInUid);
    const hotRoughIn = this.globalStore.get(filled.hotRoughInUid);
    let hotOutput, coldOutput, warmOutput;

    const pipeDistanceMM = filled.pipeDistanceMM!;
    const valveLengthMM = (pipeDistanceMM * 200) / 150;

    if (coldRoughIn instanceof DrawableSystemNode) {
      coldRoughIn.entity.center.x = pipeDistanceMM / 2;
    }

    if (hotRoughIn instanceof DrawableSystemNode) {
      hotRoughIn.entity.center.x = -pipeDistanceMM / 2;
    }

    switch (filled.valve.type) {
      case BigValveType.TMV:
        coldOutput = this.drawableStore.get(filled.valve.coldOutputUid);
        warmOutput = this.drawableStore.get(filled.valve.warmOutputUid);
        if (coldOutput && coldOutput instanceof DrawableSystemNode) {
          coldOutput.entity.center.x = pipeDistanceMM / 2;
          coldOutput.entity.center.y = valveLengthMM / 2;
        }

        if (warmOutput && warmOutput instanceof DrawableSystemNode) {
          warmOutput.entity.center.x = -pipeDistanceMM / 2;
          warmOutput.entity.center.y = valveLengthMM / 2;
        }
        break;
      case BigValveType.TEMPERING:
        warmOutput = this.drawableStore.get(filled.valve.warmOutputUid);
        if (warmOutput && warmOutput instanceof DrawableSystemNode) {
          warmOutput.entity.center.y = valveLengthMM / 2;
        }
        break;
      case BigValveType.RPZD_HOT_COLD:
        hotOutput = this.drawableStore.get(filled.valve.hotOutputUid);
        coldOutput = this.drawableStore.get(filled.valve.coldOutputUid);
        if (coldOutput && coldOutput instanceof DrawableSystemNode) {
          coldOutput.entity.center.x = pipeDistanceMM / 2;
          coldOutput.entity.center.y = valveLengthMM / 2;
        }

        if (hotOutput && hotOutput instanceof DrawableSystemNode) {
          hotOutput.entity.center.x = -pipeDistanceMM / 2;
          hotOutput.entity.center.y = valveLengthMM / 2;
        }
        break;
    }
  }

  getAttachCoords(): [Coord, Coord, Coord, Coord] {
    switch (this.entity.valve.type) {
      case BigValveType.TMV:
      case BigValveType.TEMPERING:
        return [
          // left
          {
            x: -this.entity.pipeDistanceMM - this.attachmentOffset,
            y: (this.entity.pipeDistanceMM * 200) / 150 / 2,
          },
          // right
          {
            x: this.entity.pipeDistanceMM + this.attachmentOffset,
            y: (this.entity.pipeDistanceMM * 200) / 150 / 2,
          },
          // top
          {
            x: 0,
            y: -this.attachmentOffset,
          },
          // bottom
          {
            x: 0,
            y: (this.entity.pipeDistanceMM * 200) / 150 + this.attachmentOffset,
          },
        ];

      case BigValveType.RPZD_HOT_COLD:
        const valveHeightMM = getRpzdBigValveHeightMM(this.entity);

        return [
          // left
          {
            x: -valveHeightMM * 2.5 - this.attachmentOffset,
            y: valveHeightMM,
          },
          // right
          {
            x: valveHeightMM * 2.5 + this.attachmentOffset,
            y: valveHeightMM,
          },
          // top
          {
            x: 0,
            y: -valveHeightMM / 2 - this.attachmentOffset,
          },
          // bottom
          {
            x: 0,
            y: valveHeightMM * 2.5 + this.attachmentOffset,
          },
        ];
      default:
        assertUnreachable(this.entity.valve);
    }

    return [
      { x: 0, y: 0 },
      { x: 0, y: 0 },
      { x: 0, y: 0 },
      { x: 0, y: 0 },
    ];
  }

  getPopupContent(): EntityPopupContent[] {
    return [];
  }

  getHoverSiblings(): HoverSiblingResult[] {
    return [];
  }
}
