import { getVisitorToken } from "../hubspot";

/*
 * This is a class to manage the state of HubSpot Live Chat
 * This is needed because the HubSpot Live Chat widget is always visible but
 * we want to hide it if it hasn't been triggered by CommandBar
 */
export class HubSpotLiveChat {
  static async init(email: string) {
    const tokenResult = await getVisitorToken();

    // @ts-ignore
    window.hsConversationsSettings = {
      loadImmediately: false,
      enableWidgetCookieBanner: true,
      disableAttachment: true,
      identificationEmail: email,
      identificationToken: tokenResult.success ? tokenResult.data : undefined,
    };

    console.log("Hubspot chat widget setup done");
  }

  static setupHooks() {
    // @ts-ignore
    window.HubSpotConversations.on("conversationClosed", (_payload) => {
      // hide hubspot chat when one of our support people closes the chat
      this.hide();
    });
    // @ts-ignore
    window.HubSpotConversations.on(
      "unreadConversationCountChanged",
      // @ts-ignore
      (_payload) => {
        // open the chat when one our support people has a new message for the user
        this.open();
      },
    );
    // @ts-ignore
    window.HubSpotConversations.on("widgetClosed", (_event) => {
      // hide hubspot chat when the user closes the chat
      this.hide();
    });

    // @ts-ignore
    window.HubSpotConversations.on("widgetLoaded", (_payload) => {
      this.open();
    });

    console.log("Hubspot chat chat setup hooks");
  }

  static hide() {
    // @ts-ignore
    // window.HubSpotConversations.widget.remove();

    // @ts-ignore
    window.HubSpotConversations.widget.close();
    let attempt = 1000;
    const interval = setInterval(() => {
      const element = this.hubspotContainer();
      if (element) {
        element.style.visibility = "hidden";
        clearInterval(interval);
        console.log("Hubspot chat widget hidden");
      }

      attempt--;
      if (attempt === 0) {
        clearInterval(interval);
        console.error("Hubspot chat hide timeout");
      }
    }, 10);
  }

  static open() {
    // @ts-ignore
    window.HubSpotConversations.widget.open();
    const element = this.hubspotContainer();
    if (element) {
      element.style.visibility = "visible";
      console.log("Hubspot chat widget shown");
    } else {
      console.error("Hubspot chat widget not found");
    }
  }

  static load() {
    // @ts-ignore
    if (window.HubSpotConversations?.widget?.status()?.loaded) {
      this.open();
    } else {
      // @ts-ignore
      window.HubSpotConversations.widget.load({ widgetOpen: true });
      this.setupHooks();
    }
    console.log("Hubspot chat widget loaded");
  }

  private static hubspotContainer() {
    return document.getElementById("hubspot-messages-iframe-container");
  }
}
