import Flatten from "@flatten-js/core";
import { cloneSimple } from "../../lib/utils";
import { GetPressureLossOptions } from "../calculations/entity-pressure-drops";
import {
  CoreContext,
  CostBreakdown,
  DrawingLayout,
  PressureLossResult,
} from "../calculations/types";
import FixtureCalculation, {
  FixtureLiveCalculation,
} from "../document/calculations-objects/fixture-calculation";
import { getFlowSystemLayouts } from "../document/calculations-objects/utils";
import { full2liveLayouts } from "../document/calculations-objects/warnings";
import FixtureEntity, {
  fillFixtureFields,
} from "../document/entities/fixtures/fixture-entity";
import { EntityType } from "../document/entities/types";
import { getFlowSystem } from "../document/utils";
import CoreCentered from "./core-traits/coreCentered";
import CoreConduit from "./coreConduit";
import CoreSystemNode from "./coreSystemNode";
import { CoreCalculatableObject } from "./lib/CoreCalculatableObject";
import CoreBaseBackedObject from "./lib/coreBaseBackedObject";
import {
  flowSystemsCompatible,
  getIdentityCalculationEntityUid,
} from "./utils";

export default class CoreFixture extends CoreCentered(
  CoreCalculatableObject<FixtureEntity>,
) {
  type: EntityType.FIXTURE = EntityType.FIXTURE;

  get refPath(): string {
    return `${this.entity.type}`;
  }

  get filledEntity(): FixtureEntity {
    return fillFixtureFields(this.context, this.entity);
  }

  getFrictionPressureLossKPA(
    options: GetPressureLossOptions,
  ): PressureLossResult {
    throw new Error("Not applicable to fixtues");
  }
  getCalculationEntities(context: CoreContext): [FixtureEntity] {
    const e: FixtureEntity = cloneSimple(this.entity);
    e.uid = this.getCalculationUid(context);
    e.parentUid = getIdentityCalculationEntityUid(
      context,
      this.entity.parentUid,
    );

    for (const suid of e.roughInsInOrder) {
      if (!this.globalStore.has(e.roughIns[suid].uid)) {
        console.log("missing", e.roughIns[suid].uid);
      }

      e.roughIns[suid].uid = this.globalStore
        .getConnectableOrThrow(e.roughIns[suid].uid)
        .getCalculationNode(context, this.uid).uid;
    }

    return [e];
  }

  collectCalculations(context: CoreContext): FixtureCalculation {
    return context.globalStore.getOrCreateCalculation(
      this.getCalculationEntities(context)[0],
    );
  }

  collectLiveCalculations(context: CoreContext): FixtureLiveCalculation {
    const result = context.globalStore.getOrCreateLiveCalculation(
      this.getCalculationEntities(context)[0],
    );
    const calc = context.globalStore.getOrCreateCalculation(
      this.getCalculationEntities(context)[0],
    );
    if (calc.warnings) {
      for (const warning of calc.warnings) {
        if (warning.type === "CONNECT_THE_FIXTURE_TO_A_FLOW_SYSTEM") {
          result.warnings.push({
            type: "CONNECT_THE_FIXTURE_TO_A_FLOW_SYSTEM",
            layouts: full2liveLayouts(warning.layouts),
          });
        }
      }
    }
    return result;
  }

  costBreakdown(context: CoreContext): CostBreakdown | null {
    const catalog = context.catalog.fixtures[this.entity.name];
    return {
      cost: context.priceTable.Fixtures[catalog.priceTableName],
      breakdown: [
        {
          qty: 1,
          path: `Fixtures.${catalog.priceTableName}`,
        },
      ],
    };
  }

  preCalculationValidation(context: CoreContext) {
    return null;
  }

  validateConnectionPoints(): DrawingLayout[] | true {
    let brokenLayouts: DrawingLayout[] = [];
    for (const systemUidIter of Object.keys(this.entity.roughIns)) {
      const flowSystem = getFlowSystem(this.drawing, systemUidIter);
      const roughIn = this.entity.roughIns[systemUidIter];
      if (
        flowSystem &&
        !this.getConnectedPipe(
          roughIn.uid,
          roughIn.allowAllSystems ? null : flowSystem.uid,
        )
      ) {
        brokenLayouts.push(
          ...getFlowSystemLayouts(
            this.drawing.metadata.flowSystems[flowSystem.uid],
          ).layouts,
        );
      }
    }
    if (brokenLayouts.length) {
      return Array.from(new Set(brokenLayouts));
    }
    return true;
  }

  getConnectedPipe(
    connectionUid: string,
    flowSystemUid: string | null,
  ): CoreConduit | undefined {
    for (const itemId of this.globalStore.getConnections(connectionUid)) {
      const item = this.globalStore.get(itemId);
      if (
        item &&
        item.entityBacked &&
        item.entityBacked.type === EntityType.CONDUIT &&
        (!flowSystemUid ||
          flowSystemsCompatible(
            (item as CoreConduit).entity.systemUid,
            flowSystemUid,
            this.drawing,
          ))
      ) {
        return item as CoreConduit;
      }
    }
  }

  getCoreNeighbours(): CoreBaseBackedObject[] {
    const res: CoreBaseBackedObject[] = [];
    for (const suid of this.entity.roughInsInOrder) {
      res.push(
        ...this.globalStore
          .get(this.entity.roughIns[suid].uid)!
          .getCoreNeighbours(),
      );
    }
    return res;
  }

  getInlets(): CoreSystemNode[] {
    return this.entity.roughInsInOrder.map((suid) =>
      this.context.globalStore.getObjectOfTypeOrThrow(
        EntityType.SYSTEM_NODE,
        this.entity.roughIns[suid].uid,
      ),
    );
  }

  get shape() {
    const p = new Flatten.Polygon();
    // tslint:disable-next-line:one-variable-per-declaration
    let l, t, r, b;
    if (this.entity.roughInsInOrder.length > 2) {
      l = (-this.entity.pipeDistanceMM * 5) / 4;
      r = (this.entity.pipeDistanceMM * 5) / 4;
      t = (-this.entity.pipeDistanceMM * 1) / 4;
      b = (this.entity.pipeDistanceMM * 7) / 4;
    } else {
      l = -this.entity.pipeDistanceMM / 2;
      r = this.entity.pipeDistanceMM / 2;
      t = -this.entity.pipeDistanceMM / 4;
      b = (this.entity.pipeDistanceMM * 4) / 4;
    }

    const tl = this.toWorldCoord({ x: l, y: t });
    const tr = this.toWorldCoord({ x: r, y: t });
    const bl = this.toWorldCoord({ x: l, y: b });
    const br = this.toWorldCoord({ x: r, y: b });
    const tlp = Flatten.point(tl.x, tl.y);
    const trp = Flatten.point(tr.x, tr.y);
    const blp = Flatten.point(bl.x, bl.y);
    const brp = Flatten.point(br.x, br.y);

    p.addFace([
      Flatten.segment(tlp, trp),
      Flatten.segment(trp, brp),
      Flatten.segment(brp, blp),
      Flatten.segment(blp, tlp),
    ]);

    return p;
  }
}
