import { v4 } from "uuid";
import { determineConnectableLivePipeCalcs } from "../../../../../common/src/api/coreObjects/utils";
import { ConduitConnectableEntityConcrete } from "../../../../../common/src/api/document/entities/concrete-entity";
import ConduitEntity, {
  ConduitType,
  DuctConduitEntity,
  PipeConduitEntity,
} from "../../../../../common/src/api/document/entities/conduit-entity";
import RiserEntity from "../../../../../common/src/api/document/entities/riser-entity";
import { EntityType } from "../../../../../common/src/api/document/entities/types";
import { GlobalStore } from "../../../../../common/src/lib/globalstore/global-store";
import {
  assertType,
  assertUnreachable,
  cloneSimple,
} from "../../../../../common/src/lib/utils";
import { DrawableObjectConcrete } from "../../objects/concrete-object";
import { determineConnectableConfigurationCosmetic } from "../../objects/utils";
import CanvasContext from "../canvas-context";
import DrawableStore from "../drawableStore";

// Get entities directely connected to this entity,
// including those connected via fittings but not
// system nodes or parent relationships.
export function getConnectedFlowComponent(
  targetUid: string,
  globalStore: DrawableStore,
  levelUid: string,
): DrawableObjectConcrete[] {
  const q = [targetUid];

  const seen = new Set<string>();
  const result: DrawableObjectConcrete[] = [];

  while (q.length) {
    const top = q.pop()!;
    if (seen.has(top)) {
      continue;
    }
    seen.add(top);

    if (globalStore.levelOfEntity.get(top) !== levelUid) {
      continue;
    }

    const o = globalStore.get(top)!;
    result.push(o);
    switch (o.entity.type) {
      case EntityType.SYSTEM_NODE:
      case EntityType.RISER:
      case EntityType.LOAD_NODE:
      case EntityType.FLOW_SOURCE:
      case EntityType.FITTING:
      case EntityType.DIRECTED_VALVE:
      case EntityType.MULTIWAY_VALVE:
        q.push(...globalStore.getConnections(o.entity.uid));
        break;
      case EntityType.CONDUIT:
        q.push(...o.entity.endpointUid);
        break;
      case EntityType.DAMPER:
        q.push(...o.entity.edgeUid);
        break;
      case EntityType.BIG_VALVE:
      case EntityType.BACKGROUND_IMAGE:
      case EntityType.FIXTURE:
      case EntityType.GAS_APPLIANCE:
      case EntityType.COMPOUND:
      case EntityType.PLANT:
      case EntityType.VERTEX:
      case EntityType.EDGE:
      case EntityType.ROOM:
      case EntityType.WALL:
      case EntityType.FENESTRATION:
      case EntityType.LINE:
      case EntityType.ANNOTATION:
      case EntityType.ARCHITECTURE_ELEMENT:
      case EntityType.AREA_SEGMENT:
        throw new Error("invalid object here");
      default:
        assertUnreachable(o.entity);
    }
  }

  return result;
}

interface MakeConduitFromConduitOptions {
  fields: Partial<Omit<ConduitEntity, "conduit">> & {
    conduit?: Partial<ConduitEntity["conduit"]>;
  } & { endpointUid: [string, string] };
  modelConduit: ConduitEntity;
  extendedFrom?: ConduitConnectableEntityConcrete;
  extendedTo?: ConduitConnectableEntityConcrete;
  objectStore?: GlobalStore;
}

interface MakeConduitFromConnectionOptions {
  fields: Partial<Omit<ConduitEntity, "conduit">> & {
    conduit?: Partial<ConduitEntity["conduit"]>;
  } & {
    endpointUid: [string, string];
    systemUid: string;
    conduitType: ConduitType;
    heightAboveFloorM: number;
  };
  modelConduit?: ConduitEntity;
  extendedFrom: ConduitConnectableEntityConcrete;
  extendedTo?: ConduitConnectableEntityConcrete;
  objectStore?: GlobalStore;
}

export function makeConduitEntity(
  options: MakeConduitFromConduitOptions | MakeConduitFromConnectionOptions,
) {
  const { fields, modelConduit, extendedFrom, objectStore, extendedTo } =
    options;

  const conduitType = modelConduit?.conduitType || fields.conduitType!;

  let connectableColor = null;
  if (extendedFrom && extendedFrom.type !== EntityType.SYSTEM_NODE) {
    connectableColor = extendedFrom.color;
  }

  let result: ConduitEntity;
  switch (conduitType) {
    case "pipe": {
      assertType<PipeConduitEntity | undefined>(modelConduit);
      assertType<
        Partial<Omit<PipeConduitEntity, "conduit">> & {
          conduit: Partial<PipeConduitEntity["conduit"]>;
        }
      >(fields);

      result = {
        type: EntityType.CONDUIT,
        conduitType: "pipe",
        uid: fields.uid !== undefined ? fields.uid : v4(),

        parentUid: fields.parentUid || null,
        systemUid: fields.systemUid || modelConduit?.systemUid!,
        lengthM: null,
        heightAboveFloorM:
          fields?.heightAboveFloorM !== undefined
            ? fields?.heightAboveFloorM
            : modelConduit?.heightAboveFloorM!,
        color:
          fields.color !== undefined
            ? fields.color
            : modelConduit?.color || connectableColor,

        conduit: {
          network:
            fields?.conduit?.network !== undefined
              ? fields?.conduit?.network
              : modelConduit?.conduit?.network!,
          material:
            fields?.conduit?.material !== undefined
              ? fields?.conduit?.material
              : modelConduit?.conduit?.material || null,
          maximumVelocityMS:
            fields?.conduit?.maximumVelocityMS !== undefined
              ? fields?.conduit?.maximumVelocityMS
              : modelConduit?.conduit?.maximumVelocityMS || null,
          maximumPressureDropRateKPAM:
            fields?.conduit?.maximumPressureDropRateKPAM !== undefined
              ? fields?.conduit?.maximumPressureDropRateKPAM
              : modelConduit?.conduit?.maximumPressureDropRateKPAM || null,
          diameterMM:
            fields?.conduit?.diameterMM !== undefined
              ? fields?.conduit?.diameterMM
              : modelConduit?.conduit?.diameterMM || null,
          gradePCT:
            fields?.conduit?.gradePCT !== undefined
              ? fields?.conduit?.gradePCT
              : modelConduit?.conduit?.gradePCT || null,
          configurationCosmetic:
            [
              fields?.conduit?.configurationCosmetic,
              modelConduit?.conduit?.configurationCosmetic,
              (objectStore &&
                extendedFrom &&
                determineConnectableConfigurationCosmetic(
                  objectStore,
                  extendedFrom,
                )) ||
                undefined,
              (objectStore &&
                extendedTo &&
                determineConnectableConfigurationCosmetic(
                  objectStore,
                  extendedTo,
                )) ||
                undefined,
            ].find((x) => x !== undefined) || null,
        },
        endpointUid: fields.endpointUid,
        entityName: fields.entityName || null,
      };
      break;
    }
    case "duct": {
      assertType<DuctConduitEntity | undefined>(modelConduit);
      assertType<
        Partial<
          Omit<DuctConduitEntity, "conduit"> & {
            conduit: Partial<DuctConduitEntity["conduit"]>;
          }
        >
      >(fields);
      result = {
        type: EntityType.CONDUIT,
        conduitType: "duct",
        uid: fields.uid !== undefined ? fields.uid : v4(),

        parentUid: fields.parentUid || null,
        systemUid: fields.systemUid || modelConduit?.systemUid!,
        lengthM: null,
        heightAboveFloorM:
          fields?.heightAboveFloorM !== undefined
            ? fields?.heightAboveFloorM
            : modelConduit?.heightAboveFloorM!,
        color:
          fields.color !== undefined
            ? fields.color
            : modelConduit?.color || connectableColor,
        conduit: {
          shape: fields?.conduit?.shape || modelConduit?.conduit?.shape || null,
          sizingMode:
            fields?.conduit?.sizingMode ||
            modelConduit?.conduit?.sizingMode ||
            null,
          sizingIncrementMM:
            fields?.conduit?.sizingIncrementMM ||
            modelConduit?.conduit?.sizingIncrementMM ||
            null,
          diameterMM:
            fields?.conduit?.diameterMM ||
            modelConduit?.conduit?.diameterMM ||
            null,
          heightMM:
            fields?.conduit?.heightMM ||
            modelConduit?.conduit?.heightMM ||
            null,
          widthMM:
            fields?.conduit?.widthMM || modelConduit?.conduit?.widthMM || null,
          targetWHRatio:
            fields?.conduit?.targetWHRatio ||
            modelConduit?.conduit?.targetWHRatio ||
            null,
          material:
            fields?.conduit?.material !== undefined
              ? fields?.conduit?.material
              : modelConduit?.conduit?.material || null,
          maximumVelocityMS:
            fields?.conduit?.maximumVelocityMS ||
            modelConduit?.conduit?.maximumVelocityMS ||
            null,
          maxHeightMM: fields?.conduit?.maxHeightMM || null,
          maxWidthMM: fields?.conduit?.maxWidthMM || null,
          rectSizingMethod:
            fields?.conduit?.rectSizingMethod || "max-dimensions",
          angleDEG: fields?.conduit?.angleDEG || null,
          network:
            fields?.conduit?.network !== undefined
              ? fields?.conduit?.network
              : modelConduit?.conduit?.network!,
          maximumPressureDropRateKPAM:
            fields?.conduit?.maximumPressureDropRateKPAM || null,
        },
        endpointUid: fields.endpointUid,
        entityName: fields.entityName || null,
      };
      break;
    }
    case "cable": {
      throw new Error("not implemented");
    }
  }

  if (objectStore) {
    const existingLiveCalcs = options.extendedFrom
      ? determineConnectableLivePipeCalcs(objectStore, options.extendedFrom)
      : options.modelConduit
        ? objectStore.getOrCreateLiveCalculation(options.modelConduit)
        : undefined;

    if (existingLiveCalcs) {
      objectStore.liveCalculationStore.set(
        result.uid,
        cloneSimple(existingLiveCalcs),
      );
    }
  }

  return result;
}

export function determineMaxMinHeight(
  riser: RiserEntity,
  context: CanvasContext,
) {
  const floors = Object.values(context.drawing.levels);
  const sortFloor = floors.sort((a, b) => {
    return a.floorHeightM - b.floorHeightM;
  });

  const groundIndex = sortFloor.findIndex((floor) => {
    return floor.uid === "ground";
  });

  let topMax = Infinity,
    bottomMin = -Infinity;
  if (
    riser.topFloorRef !== null &&
    groundIndex + riser.topFloorRef < sortFloor.length
  ) {
    topMax = sortFloor[groundIndex + riser.topFloorRef].floorHeightM;
  }
  if (riser.bottomFloorRef !== null) {
    bottomMin = sortFloor[groundIndex + riser.bottomFloorRef].floorHeightM;
  }

  return {
    topMax,
    bottomMin,
  };
}
