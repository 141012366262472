import { Units, UnitsContext } from "../../../../lib/measurements";
import { CoreContext } from "../../../calculations/types";
import { isCooling, isHeating, isVentilation } from "../../../config";
import CorePlant from "../../../coreObjects/corePlant";
import { getTooltip, getTooltipsEntityName } from "../../../tooltips/tooltips";
import { FieldType, PropertyField } from "../property-field";
import { RoomType } from "../rooms/room-entity";
import { EntityType } from "../types";
import { ConditionalPropertyField, filterConditionalFields } from "../utils";
import { fillPlantDefaults } from "./plant-defaults";
import PlantEntity, { DualSystemPlantEntity } from "./plant-entity";
import { PlantShapes, PlantType } from "./plant-types";
import { PlantFieldHandlers } from "./types";
import {
  areDimensionsReadonly,
  isDualSystemNodePlant,
  isPlantMVHR,
  isVentilationPlant,
} from "./utils";

export function makeDualSystemPlantSystemFields(
  context: CoreContext,
  entity: DualSystemPlantEntity,
  system: "heating" | "chilled",
): PropertyField[] {
  const { drawing } = context;
  const flowSystems = drawing.metadata.flowSystemUidsInOrder.map(
    (uid) => drawing.metadata.flowSystems[uid],
  );
  const availableSystems =
    system === "heating"
      ? flowSystems.filter(isHeating)
      : flowSystems.filter(isCooling);

  const showSystemFields =
    system === "heating"
      ? entity.plant.addHeatingIO
      : entity.plant.addChilledIO;

  const isMvhr = isPlantMVHR(entity.plant);
  const iAmVentilation = isVentilationPlant(
    entity.plant,
    drawing.metadata.flowSystems,
  );
  const isLegacyFCU =
    entity.plant.type === PlantType.FCU && entity.plant.heatingRooms === null;

  const { heatingCache, chilledCache } = getRoomToColorCache(context);

  const fields: ConditionalPropertyField[] = [
    {
      property: "plant.addHeatingIO",
      title: "Is there heating inlet/outlet?",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Boolean,
      params: null,
      multiFieldId: "plant-addColdWaterInlet",
      isShown: system === "heating",
    },
    {
      property: "plant.increaseFlowRateBasedOnHeatLoad",
      title: "Increase Flow Rate Based on the Heat Load?",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Boolean,
      params: null,
      multiFieldId: "plant-addColdWaterInlet",
      isShown: isMvhr && system === "heating",
    },
    {
      property: "plant.airTemperatureMVHR",
      title: "Supplied Air Temperature",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Number,
      params: { min: 0, max: 100 },
      multiFieldId: "plant-addColdWaterInlet",
      isShown: isMvhr && system === "heating",
    },
    {
      property: "plant.addChilledIO",
      title: "Is there chilled inlet/outlet?",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Boolean,
      params: null,
      multiFieldId: "plant-addColdWaterInlet",
      isShown: system === "chilled",
    },
    {
      property: `plant.${system}SystemUid`,
      title: "Flow System",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.FlowSystemChoice,
      params: { systems: availableSystems },
      multiFieldId: `${system}SystemUid`,
      isShown: showSystemFields,
    },
    {
      property: `plant.${system}Rooms`,
      title: "Associated Rooms",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.EntityPicker,
      params: {
        type: "multiple",
        levelUid: null,
        entityTypes: [EntityType.ROOM],
        emptyListPlaceholder: "No Rooms - Add in 'FloorPlan' mode",
        filter: (entity) => {
          return (
            entity.type === EntityType.ROOM &&
            entity.room.roomType === RoomType.ROOM
          );
        },
        getColor: (entity) => {
          if (system === "heating") {
            return heatingCache.get(entity.uid) ?? null;
          }
          if (system === "chilled") {
            return chilledCache.get(entity.uid) ?? null;
          }
          return null;
        },
      },
      multiFieldId: `plant-${system}Rooms`,
      isShown: showSystemFields && !isLegacyFCU,
    },
    {
      property: `plant.${system}Rooms`,
      title: "Associated Rooms",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.DisplayTextOnly,
      params: {
        text: "Legacy FCUs only supply heating/cooling to the room they are in",
      },
      multiFieldId: "plant-legacyFCU-rooms",
      isShown: showSystemFields && isLegacyFCU,
    },
    {
      property: `plant.heatRecoveryEfficiencyPct`,
      title: "Heat Recovery Efficiency",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Number,
      params: {
        min: 0,
        max: 100,
        initialValue: 0,
      },
      multiFieldId: `plant-heatRecoveryEfficiencyPct`,
      units: Units.Percent,
      isShown: showSystemFields && isMvhr,
    },
    {
      property: `plant.${system}Rating.KW`,
      title: "Rating",
      hasDefault: false,
      isCalculated: true,
      type: FieldType.Number,
      params: {
        min: 0,
        max: null,
        initialValue: 0,
      },
      multiFieldId: `plant-${system}Rating`,
      units: Units.KiloWatts,
      unitContext: UnitsContext.MECHANICAL_ENERGY_MEASUREMENT,
      isShown: showSystemFields,
    },
    {
      property: `plant.${system}CapacityRateLKW`,
      title: "Volume",
      hasDefault: false,
      requiresInput: true,
      isCalculated: false,
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: `${system}CapacityRateLKW`,
      units: Units.LitersPerKiloWatts,
      unitContext: UnitsContext.MECHANICAL_ENERGY_MEASUREMENT,
      isShown: showSystemFields,
    },
    {
      property: `plant.${system}PressureLoss.pressureLossKPA`,
      title: "Pressure Loss",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: `plant-${system}PressureLoss-pressureLossKPA`,
      unitContext: iAmVentilation
        ? UnitsContext.VENTILATION
        : UnitsContext.NONE,
      units: Units.KiloPascals,
      isShown: showSystemFields,
    },
    {
      property: `plant.${system}HeightAboveFloorM`,
      title: "Height Above Floor",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Number,
      params: { min: null, max: null },
      multiFieldId: `${system}HeightAboveFloorM`,
      units: Units.Meters,
      isShown: showSystemFields,
    },
  ];

  return filterConditionalFields(fields);
}

export function makeDualSystemPlantTabs(
  context: CoreContext,
  entity: DualSystemPlantEntity,
  handlers?: PlantFieldHandlers,
): ConditionalPropertyField {
  const filled = fillPlantDefaults(context, entity);
  const isAHUVent = filled.plant.type === PlantType.AHU_VENT;

  const res: ConditionalPropertyField = {
    type: FieldType.Tabs,
    id: "plant-tabs",
    isShown: true,
    tabs: [],
  };

  res.tabs.push(
    {
      tabId: "specs",
      tabName: "Specs",
      fields: makeDualSystemPlantSpecs(context, filled, handlers),
    },
    {
      tabId: "heating",
      tabName: "Heating",
      fields: makeDualSystemPlantSystemFields(context, filled, "heating"),
    },
    {
      tabId: "chilled",
      tabName: "Chilled",
      fields: makeDualSystemPlantSystemFields(context, filled, "chilled"),
    },
  );

  if (isAHUVent) {
    res.tabs.push(
      {
        tabId: "supply",
        tabName: "Intake/Supply",
        fields: createSupplyTabFields(filled, context),
      },
      {
        tabId: "extract",
        tabName: "Exhaust/Extract",
        fields: createExtractTabFields(filled, context),
      },
    );
  }

  return res;
}

export function makeDualSystemPlantSpecs(
  context: CoreContext,
  filled: DualSystemPlantEntity,
  handlers?: PlantFieldHandlers,
): PropertyField[] {
  const { drawing } = context;

  const res: ConditionalPropertyField[] = [
    {
      property: "plant.color",
      title: "Color",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Color,
      multiFieldId: "plant-color",
      isShown: true,
      params: null,
    },
    {
      property: "plant.shape",
      title: "Shape",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Choice,
      params: {
        choices: [
          { name: "Rectangular", key: "RECTANGULAR" },
          { name: "Cylinder", key: "CYLINDER" },
        ],
      },
      multiFieldId: "plant-shape",
      isShown: true,
    },
    {
      property: "plant.diameterMM",
      title: "Diameter",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Number,
      params: { min: 0, max: null },
      units: Units.Millimeters,
      multiFieldId: "plant-diameter",
      readonly: areDimensionsReadonly(drawing, filled),
      isShown: filled.plant.shape === PlantShapes.CYLINDER,
      settingPropertyParam: {
        updateValue: handlers?.setWidth,
      },
    },
    {
      property: "depthMM",
      title: "Depth",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: null,
      units: Units.Millimeters,
      isShown: filled.plant.shape === PlantShapes.RECTANGULAR,
    },
    {
      property: "widthMM",
      title: "Width",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: null,
      units: Units.Millimeters,
      isShown: filled.plant.shape === PlantShapes.RECTANGULAR,
      settingPropertyParam: {
        updateValue: handlers?.setWidth,
      },
    },
    {
      property: "plant.heightMM",
      title: "Height",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: null,
      units: Units.Millimeters,
      readonly: areDimensionsReadonly(drawing, filled),
      isShown: true,
    },
  ];

  return filterConditionalFields(res);
}

export function createSupplyTabFields(
  filled: PlantEntity,
  context: CoreContext,
): PropertyField[] {
  const { drawing } = context;
  const res: ConditionalPropertyField[] = [];
  const ventFlowSystems = drawing.metadata.flowSystemUidsInOrder
    .map((uid) => drawing.metadata.flowSystems[uid])
    .filter(isVentilation);
  const tooltipsEntityName = getTooltipsEntityName(filled, drawing);

  let addIntakeIO = true;
  if (filled.plant.type === PlantType.AHU_VENT) {
    addIntakeIO = filled.plant.addIntakeIO;
  }

  res.push(
    {
      property: "plant.addIntakeIO",
      title: "Is there a intake outlet?",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Boolean,
      params: null,
      multiFieldId: "plant-addIntakeIO",
      isShown: true,
    },
    {
      property: "plant.supplySystemUid",
      title: "Supply Flow System",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.FlowSystemChoice,
      params: {
        systems: ventFlowSystems.filter((v) => v.role === "vent-supply"),
      },
      multiFieldId: "plant-supplySystemUid",
      isShown: true,
    },
    {
      property: "plant.intakeSystemUid",
      title: "Intake Flow System",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.FlowSystemChoice,
      params: {
        systems: ventFlowSystems.filter((v) => v.role === "vent-intake"),
      },
      multiFieldId: "plant-intakeSystemUid",
      isShown: addIntakeIO,
    },
    {
      property: "plant.supplyPressureDropPA",
      title: "Intake/Supply Internal Pressure Drop",
      hint: getTooltip(tooltipsEntityName, "Fan Pressure Drop"),
      hasDefault: false,
      isCalculated: false,
      requiresInput: true,
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: "plant-supplyPressureDropPA",
      unitContext: UnitsContext.VENTILATION,
      units: Units.Pascals,
      isShown: true,
    },
    {
      property: "plant.supplyHeightAboveFloorM",
      title: "Supply Height Above Floor",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Number,
      params: { min: null, max: null },
      multiFieldId: "supplyHeightAboveFloorM",
      units: Units.Meters,
      isShown: true,
    },
    {
      property: "plant.intakeHeightAboveFloorM",
      title: "Intake Height Above Floor",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Number,
      params: { min: null, max: null },
      multiFieldId: "intakeHeightAboveFloorM",
      units: Units.Meters,
      isShown: addIntakeIO,
    },
  );

  return filterConditionalFields(res);
}

export function createExtractTabFields(
  filled: PlantEntity,
  context: CoreContext,
): PropertyField[] {
  const { drawing } = context;
  const res: ConditionalPropertyField[] = [];
  const ventFlowSystems = drawing.metadata.flowSystemUidsInOrder
    .map((uid) => drawing.metadata.flowSystems[uid])
    .filter(isVentilation);
  const tooltipsEntityName = getTooltipsEntityName(filled, drawing);

  let addExtractIO = true;
  let addExhaustIO = true;
  if (filled.plant.type === PlantType.AHU_VENT) {
    addExtractIO = filled.plant.addExtractIO;
    addExhaustIO = filled.plant.addExhaustIO;
  }

  res.push(
    {
      property: "plant.addExtractIO",
      title: "Is there extract inlet?",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Boolean,
      params: null,
      multiFieldId: "plant-addExtractIO",
      isShown: true,
    },
    {
      property: "plant.addExhaustIO",
      title: "Is there exhaust outlet?",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Boolean,
      params: null,
      multiFieldId: "plant-addExhaustIO",
      isShown: true,
    },
    {
      property: "plant.extractSystemUid",
      title: "Extract Flow System",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.FlowSystemChoice,
      params: {
        systems: ventFlowSystems.filter((v) => v.role === "vent-extract"),
      },
      multiFieldId: "plant-extractSystemUid",
      isShown: addExtractIO,
    },
    {
      property: "plant.exhaustSystemUid",
      title: "Exhaust Flow System",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.FlowSystemChoice,
      params: {
        systems: ventFlowSystems.filter((v) => v.role === "vent-exhaust"),
      },
      multiFieldId: "plant-exhaustSystemUid",
      isShown: addExhaustIO,
    },
    {
      property: "plant.extractPressureDropPA",
      title: "Exhaust/Extract Internal Pressure Drop",
      hint: getTooltip(tooltipsEntityName, "Fan Pressure Drop"),
      hasDefault: false,
      isCalculated: false,
      requiresInput: true,
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: "plant-extractPressureDropPA",
      unitContext: UnitsContext.VENTILATION,
      units: Units.Pascals,
      isShown: addExtractIO || addExhaustIO,
    },
    {
      property: "plant.extractHeightAboveFloorM",
      title: "Extract Height Above Floor",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Number,
      params: { min: null, max: null },
      multiFieldId: "extractHeightAboveFloorM",
      units: Units.Meters,
      isShown: addExtractIO,
    },
    {
      property: "plant.exhaustHeightAboveFloorM",
      title: "Exhaust Height Above Floor",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Number,
      params: { min: null, max: null },
      multiFieldId: "exhaustHeightAboveFloorM",
      units: Units.Meters,
      isShown: addExhaustIO,
    },
  );

  return filterConditionalFields(res);
}

export function getRoomToColorCache(context: CoreContext) {
  const heatingCache = new Map<string, string>();
  const chilledCache = new Map<string, string>();

  for (const o of context.globalStore.entitiesOfType<CorePlant>(
    EntityType.PLANT,
  )) {
    if (isDualSystemNodePlant(o.entity.plant)) {
      for (const roomUid of o.entity.plant.heatingRooms ?? []) {
        heatingCache.set(roomUid, o.entity.plant.color.hex);
      }
      for (const roomUid of o.entity.plant.chilledRooms ?? []) {
        chilledCache.set(roomUid, o.entity.plant.color.hex);
      }
    }
  }

  return { heatingCache, chilledCache };
}
