import { CoreContext } from "../../calculations/types";
import { DrawableEntityConcrete } from "../entities/concrete-entity";
import { getEntityIndividualName } from "../entities/types";
import { EntityAnalyticProperties } from "./analytics";

export function getEntityAnalyticProperties(
  context: CoreContext,
  entity: DrawableEntityConcrete,
): EntityAnalyticProperties {
  const coreObject = context.globalStore.get(entity.uid);
  return {
    uid: entity ? entity.uid : "Unknown",
    entityName: entity ? (getEntityIndividualName(entity) ?? "Unnamed") : "N/A",
    entityType: coreObject.type ?? "N/A",
    subtype: coreObject.subtype ?? "N/A",
    tertiaryType: coreObject.tertiaryType ?? "N/A",
  };
}
