import { Currency } from "../../../../lib/currency";
import { ManufacturerRadiatorData } from "../../types";

// used by UK cataloge
export const CastradsRadiators: ManufacturerRadiatorData[] = [
  {
    productKey: "CI-EML1-465-04",
    rangeType: "Emmeline I",
    model: "Emmeline I 465mm",
    depthMM: 110,
    heightMM: 465,
    widthMM: 296,
    volumeL: 2.4,
    nominalDeltaT50KW: 0.178,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 267,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-465-05",
    rangeType: "Emmeline I",
    model: "Emmeline I 465mm",
    depthMM: 110,
    heightMM: 465,
    widthMM: 363,
    volumeL: 3,
    nominalDeltaT50KW: 0.223,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 300,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-465-06",
    rangeType: "Emmeline I",
    model: "Emmeline I 465mm",
    depthMM: 110,
    heightMM: 465,
    widthMM: 429,
    volumeL: 3.6,
    nominalDeltaT50KW: 0.267,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 333,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-465-07",
    rangeType: "Emmeline I",
    model: "Emmeline I 465mm",
    depthMM: 110,
    heightMM: 465,
    widthMM: 496,
    volumeL: 4.2,
    nominalDeltaT50KW: 0.312,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 366,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-465-08",
    rangeType: "Emmeline I",
    model: "Emmeline I 465mm",
    depthMM: 110,
    heightMM: 465,
    widthMM: 562,
    volumeL: 4.8,
    nominalDeltaT50KW: 0.356,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 399,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-465-09",
    rangeType: "Emmeline I",
    model: "Emmeline I 465mm",
    depthMM: 110,
    heightMM: 465,
    widthMM: 629,
    volumeL: 5.4,
    nominalDeltaT50KW: 0.401,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 432,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-465-10",
    rangeType: "Emmeline I",
    model: "Emmeline I 465mm",
    depthMM: 110,
    heightMM: 465,
    widthMM: 695,
    volumeL: 6,
    nominalDeltaT50KW: 0.445,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 465,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-465-11",
    rangeType: "Emmeline I",
    model: "Emmeline I 465mm",
    depthMM: 110,
    heightMM: 465,
    widthMM: 762,
    volumeL: 6.6,
    nominalDeltaT50KW: 0.49,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 498,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-465-12",
    rangeType: "Emmeline I",
    model: "Emmeline I 465mm",
    depthMM: 110,
    heightMM: 465,
    widthMM: 828,
    volumeL: 7.2,
    nominalDeltaT50KW: 0.534,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 531,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-465-13",
    rangeType: "Emmeline I",
    model: "Emmeline I 465mm",
    depthMM: 110,
    heightMM: 465,
    widthMM: 895,
    volumeL: 7.8,
    nominalDeltaT50KW: 0.579,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 564,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-465-14",
    rangeType: "Emmeline I",
    model: "Emmeline I 465mm",
    depthMM: 110,
    heightMM: 465,
    widthMM: 961,
    volumeL: 8.4,
    nominalDeltaT50KW: 0.623,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 597,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-465-15",
    rangeType: "Emmeline I",
    model: "Emmeline I 465mm",
    depthMM: 110,
    heightMM: 465,
    widthMM: 1028,
    volumeL: 9,
    nominalDeltaT50KW: 0.668,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 630,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-465-16",
    rangeType: "Emmeline I",
    model: "Emmeline I 465mm",
    depthMM: 110,
    heightMM: 465,
    widthMM: 1094,
    volumeL: 9.6,
    nominalDeltaT50KW: 0.712,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 663,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-465-17",
    rangeType: "Emmeline I",
    model: "Emmeline I 465mm",
    depthMM: 110,
    heightMM: 465,
    widthMM: 1161,
    volumeL: 10.2,
    nominalDeltaT50KW: 0.757,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 766,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-465-18",
    rangeType: "Emmeline I",
    model: "Emmeline I 465mm",
    depthMM: 110,
    heightMM: 465,
    widthMM: 1227,
    volumeL: 10.8,
    nominalDeltaT50KW: 0.801,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 799,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-465-19",
    rangeType: "Emmeline I",
    model: "Emmeline I 465mm",
    depthMM: 110,
    heightMM: 465,
    widthMM: 1294,
    volumeL: 11.4,
    nominalDeltaT50KW: 0.846,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 832,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-465-20",
    rangeType: "Emmeline I",
    model: "Emmeline I 465mm",
    depthMM: 110,
    heightMM: 465,
    widthMM: 1360,
    volumeL: 12,
    nominalDeltaT50KW: 0.89,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 865,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-465-21",
    rangeType: "Emmeline I",
    model: "Emmeline I 465mm",
    depthMM: 110,
    heightMM: 465,
    widthMM: 1427,
    volumeL: 12.6,
    nominalDeltaT50KW: 0.935,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 898,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-465-22",
    rangeType: "Emmeline I",
    model: "Emmeline I 465mm",
    depthMM: 110,
    heightMM: 465,
    widthMM: 1493,
    volumeL: 13.2,
    nominalDeltaT50KW: 0.979,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 931,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-465-23",
    rangeType: "Emmeline I",
    model: "Emmeline I 465mm",
    depthMM: 110,
    heightMM: 465,
    widthMM: 1560,
    volumeL: 13.8,
    nominalDeltaT50KW: 1.024,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 964,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-465-24",
    rangeType: "Emmeline I",
    model: "Emmeline I 465mm",
    depthMM: 110,
    heightMM: 465,
    widthMM: 1626,
    volumeL: 14.4,
    nominalDeltaT50KW: 1.068,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 997,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-465-25",
    rangeType: "Emmeline I",
    model: "Emmeline I 465mm",
    depthMM: 110,
    heightMM: 465,
    widthMM: 1693,
    volumeL: 15,
    nominalDeltaT50KW: 1.113,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1030,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-465-26",
    rangeType: "Emmeline I",
    model: "Emmeline I 465mm",
    depthMM: 110,
    heightMM: 465,
    widthMM: 1759,
    volumeL: 15.6,
    nominalDeltaT50KW: 1.157,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1063,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-465-27",
    rangeType: "Emmeline I",
    model: "Emmeline I 465mm",
    depthMM: 110,
    heightMM: 465,
    widthMM: 1826,
    volumeL: 16.2,
    nominalDeltaT50KW: 1.202,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1096,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-465-28",
    rangeType: "Emmeline I",
    model: "Emmeline I 465mm",
    depthMM: 110,
    heightMM: 465,
    widthMM: 1892,
    volumeL: 16.8,
    nominalDeltaT50KW: 1.246,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1129,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-465-29",
    rangeType: "Emmeline I",
    model: "Emmeline I 465mm",
    depthMM: 110,
    heightMM: 465,
    widthMM: 1959,
    volumeL: 17.4,
    nominalDeltaT50KW: 1.291,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1162,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-465-30",
    rangeType: "Emmeline I",
    model: "Emmeline I 465mm",
    depthMM: 110,
    heightMM: 465,
    widthMM: 2025,
    volumeL: 18,
    nominalDeltaT50KW: 1.335,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1195,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-465-31",
    rangeType: "Emmeline I",
    model: "Emmeline I 465mm",
    depthMM: 110,
    heightMM: 465,
    widthMM: 2092,
    volumeL: 18.6,
    nominalDeltaT50KW: 1.38,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1228,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-465-32",
    rangeType: "Emmeline I",
    model: "Emmeline I 465mm",
    depthMM: 110,
    heightMM: 465,
    widthMM: 2158,
    volumeL: 19.2,
    nominalDeltaT50KW: 1.424,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1261,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-465-33",
    rangeType: "Emmeline I",
    model: "Emmeline I 465mm",
    depthMM: 110,
    heightMM: 465,
    widthMM: 2225,
    volumeL: 19.8,
    nominalDeltaT50KW: 1.469,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1294,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-465-34",
    rangeType: "Emmeline I",
    model: "Emmeline I 465mm",
    depthMM: 110,
    heightMM: 465,
    widthMM: 2291,
    volumeL: 20.4,
    nominalDeltaT50KW: 1.513,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1327,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-465-35",
    rangeType: "Emmeline I",
    model: "Emmeline I 465mm",
    depthMM: 110,
    heightMM: 465,
    widthMM: 2358,
    volumeL: 21,
    nominalDeltaT50KW: 1.558,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1360,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-465-36",
    rangeType: "Emmeline I",
    model: "Emmeline I 465mm",
    depthMM: 110,
    heightMM: 465,
    widthMM: 2424,
    volumeL: 21.6,
    nominalDeltaT50KW: 1.602,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1393,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-465-37",
    rangeType: "Emmeline I",
    model: "Emmeline I 465mm",
    depthMM: 110,
    heightMM: 465,
    widthMM: 2491,
    volumeL: 22.2,
    nominalDeltaT50KW: 1.647,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1426,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-465-38",
    rangeType: "Emmeline I",
    model: "Emmeline I 465mm",
    depthMM: 110,
    heightMM: 465,
    widthMM: 2557,
    volumeL: 22.8,
    nominalDeltaT50KW: 1.691,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1459,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-465-39",
    rangeType: "Emmeline I",
    model: "Emmeline I 465mm",
    depthMM: 110,
    heightMM: 465,
    widthMM: 2624,
    volumeL: 23.4,
    nominalDeltaT50KW: 1.736,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1492,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-465-40",
    rangeType: "Emmeline I",
    model: "Emmeline I 465mm",
    depthMM: 110,
    heightMM: 465,
    widthMM: 2690,
    volumeL: 24,
    nominalDeltaT50KW: 1.78,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1525,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-665-04",
    rangeType: "Emmeline I",
    model: "Emmeline I 665mm",
    depthMM: 110,
    heightMM: 665,
    widthMM: 296,
    volumeL: 3.2,
    nominalDeltaT50KW: 0.25,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 299,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-665-05",
    rangeType: "Emmeline I",
    model: "Emmeline I 665mm",
    depthMM: 110,
    heightMM: 665,
    widthMM: 363,
    volumeL: 4,
    nominalDeltaT50KW: 0.312,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 340,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-665-06",
    rangeType: "Emmeline I",
    model: "Emmeline I 665mm",
    depthMM: 110,
    heightMM: 665,
    widthMM: 429,
    volumeL: 4.8,
    nominalDeltaT50KW: 0.374,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 381,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-665-07",
    rangeType: "Emmeline I",
    model: "Emmeline I 665mm",
    depthMM: 110,
    heightMM: 665,
    widthMM: 496,
    volumeL: 5.6,
    nominalDeltaT50KW: 0.437,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 422,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-665-08",
    rangeType: "Emmeline I",
    model: "Emmeline I 665mm",
    depthMM: 110,
    heightMM: 665,
    widthMM: 562,
    volumeL: 6.4,
    nominalDeltaT50KW: 0.499,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 463,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-665-09",
    rangeType: "Emmeline I",
    model: "Emmeline I 665mm",
    depthMM: 110,
    heightMM: 665,
    widthMM: 629,
    volumeL: 7.2,
    nominalDeltaT50KW: 0.562,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 504,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-665-10",
    rangeType: "Emmeline I",
    model: "Emmeline I 665mm",
    depthMM: 110,
    heightMM: 665,
    widthMM: 695,
    volumeL: 8,
    nominalDeltaT50KW: 0.624,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 545,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-665-11",
    rangeType: "Emmeline I",
    model: "Emmeline I 665mm",
    depthMM: 110,
    heightMM: 665,
    widthMM: 762,
    volumeL: 8.8,
    nominalDeltaT50KW: 0.686,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 586,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-665-12",
    rangeType: "Emmeline I",
    model: "Emmeline I 665mm",
    depthMM: 110,
    heightMM: 665,
    widthMM: 828,
    volumeL: 9.6,
    nominalDeltaT50KW: 0.749,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 627,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-665-13",
    rangeType: "Emmeline I",
    model: "Emmeline I 665mm",
    depthMM: 110,
    heightMM: 665,
    widthMM: 895,
    volumeL: 10.4,
    nominalDeltaT50KW: 0.811,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 668,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-665-14",
    rangeType: "Emmeline I",
    model: "Emmeline I 665mm",
    depthMM: 110,
    heightMM: 665,
    widthMM: 961,
    volumeL: 11.2,
    nominalDeltaT50KW: 0.874,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 709,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-665-15",
    rangeType: "Emmeline I",
    model: "Emmeline I 665mm",
    depthMM: 110,
    heightMM: 665,
    widthMM: 1028,
    volumeL: 12,
    nominalDeltaT50KW: 0.936,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 750,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-665-16",
    rangeType: "Emmeline I",
    model: "Emmeline I 665mm",
    depthMM: 110,
    heightMM: 665,
    widthMM: 1094,
    volumeL: 12.8,
    nominalDeltaT50KW: 0.998,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 791,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-665-17",
    rangeType: "Emmeline I",
    model: "Emmeline I 665mm",
    depthMM: 110,
    heightMM: 665,
    widthMM: 1161,
    volumeL: 13.6,
    nominalDeltaT50KW: 1.061,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 902,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-665-18",
    rangeType: "Emmeline I",
    model: "Emmeline I 665mm",
    depthMM: 110,
    heightMM: 665,
    widthMM: 1227,
    volumeL: 14.4,
    nominalDeltaT50KW: 1.123,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 943,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-665-19",
    rangeType: "Emmeline I",
    model: "Emmeline I 665mm",
    depthMM: 110,
    heightMM: 665,
    widthMM: 1294,
    volumeL: 15.2,
    nominalDeltaT50KW: 1.186,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 984,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-665-20",
    rangeType: "Emmeline I",
    model: "Emmeline I 665mm",
    depthMM: 110,
    heightMM: 665,
    widthMM: 1360,
    volumeL: 16,
    nominalDeltaT50KW: 1.248,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1025,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-665-21",
    rangeType: "Emmeline I",
    model: "Emmeline I 665mm",
    depthMM: 110,
    heightMM: 665,
    widthMM: 1427,
    volumeL: 16.8,
    nominalDeltaT50KW: 1.31,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1066,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-665-22",
    rangeType: "Emmeline I",
    model: "Emmeline I 665mm",
    depthMM: 110,
    heightMM: 665,
    widthMM: 1493,
    volumeL: 17.6,
    nominalDeltaT50KW: 1.373,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1107,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-665-23",
    rangeType: "Emmeline I",
    model: "Emmeline I 665mm",
    depthMM: 110,
    heightMM: 665,
    widthMM: 1560,
    volumeL: 18.4,
    nominalDeltaT50KW: 1.435,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1148,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-665-24",
    rangeType: "Emmeline I",
    model: "Emmeline I 665mm",
    depthMM: 110,
    heightMM: 665,
    widthMM: 1626,
    volumeL: 19.2,
    nominalDeltaT50KW: 1.498,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1189,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-665-25",
    rangeType: "Emmeline I",
    model: "Emmeline I 665mm",
    depthMM: 110,
    heightMM: 665,
    widthMM: 1693,
    volumeL: 20,
    nominalDeltaT50KW: 1.56,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1230,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-665-26",
    rangeType: "Emmeline I",
    model: "Emmeline I 665mm",
    depthMM: 110,
    heightMM: 665,
    widthMM: 1759,
    volumeL: 20.8,
    nominalDeltaT50KW: 1.622,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1271,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-665-27",
    rangeType: "Emmeline I",
    model: "Emmeline I 665mm",
    depthMM: 110,
    heightMM: 665,
    widthMM: 1826,
    volumeL: 21.6,
    nominalDeltaT50KW: 1.685,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1312,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-665-28",
    rangeType: "Emmeline I",
    model: "Emmeline I 665mm",
    depthMM: 110,
    heightMM: 665,
    widthMM: 1892,
    volumeL: 22.4,
    nominalDeltaT50KW: 1.747,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1353,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-665-29",
    rangeType: "Emmeline I",
    model: "Emmeline I 665mm",
    depthMM: 110,
    heightMM: 665,
    widthMM: 1959,
    volumeL: 23.2,
    nominalDeltaT50KW: 1.81,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1394,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-665-30",
    rangeType: "Emmeline I",
    model: "Emmeline I 665mm",
    depthMM: 110,
    heightMM: 665,
    widthMM: 2025,
    volumeL: 24,
    nominalDeltaT50KW: 1.872,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1435,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-665-31",
    rangeType: "Emmeline I",
    model: "Emmeline I 665mm",
    depthMM: 110,
    heightMM: 665,
    widthMM: 2092,
    volumeL: 24.8,
    nominalDeltaT50KW: 1.934,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1476,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-665-32",
    rangeType: "Emmeline I",
    model: "Emmeline I 665mm",
    depthMM: 110,
    heightMM: 665,
    widthMM: 2158,
    volumeL: 25.6,
    nominalDeltaT50KW: 1.997,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1517,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-665-33",
    rangeType: "Emmeline I",
    model: "Emmeline I 665mm",
    depthMM: 110,
    heightMM: 665,
    widthMM: 2225,
    volumeL: 26.4,
    nominalDeltaT50KW: 2.059,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1558,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-665-34",
    rangeType: "Emmeline I",
    model: "Emmeline I 665mm",
    depthMM: 110,
    heightMM: 665,
    widthMM: 2291,
    volumeL: 27.2,
    nominalDeltaT50KW: 2.122,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1599,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-665-35",
    rangeType: "Emmeline I",
    model: "Emmeline I 665mm",
    depthMM: 110,
    heightMM: 665,
    widthMM: 2358,
    volumeL: 28,
    nominalDeltaT50KW: 2.184,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1640,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-665-36",
    rangeType: "Emmeline I",
    model: "Emmeline I 665mm",
    depthMM: 110,
    heightMM: 665,
    widthMM: 2424,
    volumeL: 28.8,
    nominalDeltaT50KW: 2.246,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1681,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-665-37",
    rangeType: "Emmeline I",
    model: "Emmeline I 665mm",
    depthMM: 110,
    heightMM: 665,
    widthMM: 2491,
    volumeL: 29.6,
    nominalDeltaT50KW: 2.309,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1722,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-665-38",
    rangeType: "Emmeline I",
    model: "Emmeline I 665mm",
    depthMM: 110,
    heightMM: 665,
    widthMM: 2557,
    volumeL: 30.4,
    nominalDeltaT50KW: 2.371,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1763,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-665-39",
    rangeType: "Emmeline I",
    model: "Emmeline I 665mm",
    depthMM: 110,
    heightMM: 665,
    widthMM: 2624,
    volumeL: 31.2,
    nominalDeltaT50KW: 2.434,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1804,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-665-40",
    rangeType: "Emmeline I",
    model: "Emmeline I 665mm",
    depthMM: 110,
    heightMM: 665,
    widthMM: 2690,
    volumeL: 32,
    nominalDeltaT50KW: 2.496,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1845,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-865-04",
    rangeType: "Emmeline I",
    model: "Emmeline I 865mm",
    depthMM: 110,
    heightMM: 865,
    widthMM: 296,
    volumeL: 3.72,
    nominalDeltaT50KW: 0.316,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 327,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-865-05",
    rangeType: "Emmeline I",
    model: "Emmeline I 865mm",
    depthMM: 110,
    heightMM: 865,
    widthMM: 363,
    volumeL: 4.65,
    nominalDeltaT50KW: 0.396,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 375,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-865-06",
    rangeType: "Emmeline I",
    model: "Emmeline I 865mm",
    depthMM: 110,
    heightMM: 865,
    widthMM: 429,
    volumeL: 5.58,
    nominalDeltaT50KW: 0.475,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 423,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-865-07",
    rangeType: "Emmeline I",
    model: "Emmeline I 865mm",
    depthMM: 110,
    heightMM: 865,
    widthMM: 496,
    volumeL: 6.51,
    nominalDeltaT50KW: 0.554,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 471,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-865-08",
    rangeType: "Emmeline I",
    model: "Emmeline I 865mm",
    depthMM: 110,
    heightMM: 865,
    widthMM: 562,
    volumeL: 7.44,
    nominalDeltaT50KW: 0.633,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 519,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-865-09",
    rangeType: "Emmeline I",
    model: "Emmeline I 865mm",
    depthMM: 110,
    heightMM: 865,
    widthMM: 629,
    volumeL: 8.37,
    nominalDeltaT50KW: 0.712,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 567,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-865-10",
    rangeType: "Emmeline I",
    model: "Emmeline I 865mm",
    depthMM: 110,
    heightMM: 865,
    widthMM: 695,
    volumeL: 9.3,
    nominalDeltaT50KW: 0.791,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 615,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-865-11",
    rangeType: "Emmeline I",
    model: "Emmeline I 865mm",
    depthMM: 110,
    heightMM: 865,
    widthMM: 762,
    volumeL: 10.23,
    nominalDeltaT50KW: 0.87,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 663,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-865-12",
    rangeType: "Emmeline I",
    model: "Emmeline I 865mm",
    depthMM: 110,
    heightMM: 865,
    widthMM: 828,
    volumeL: 11.16,
    nominalDeltaT50KW: 0.949,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 711,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-865-13",
    rangeType: "Emmeline I",
    model: "Emmeline I 865mm",
    depthMM: 110,
    heightMM: 865,
    widthMM: 895,
    volumeL: 12.09,
    nominalDeltaT50KW: 1.028,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 759,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-865-14",
    rangeType: "Emmeline I",
    model: "Emmeline I 865mm",
    depthMM: 110,
    heightMM: 865,
    widthMM: 961,
    volumeL: 13.02,
    nominalDeltaT50KW: 1.107,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 807,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-865-15",
    rangeType: "Emmeline I",
    model: "Emmeline I 865mm",
    depthMM: 110,
    heightMM: 865,
    widthMM: 1028,
    volumeL: 13.95,
    nominalDeltaT50KW: 1.187,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 855,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-865-16",
    rangeType: "Emmeline I",
    model: "Emmeline I 865mm",
    depthMM: 110,
    heightMM: 865,
    widthMM: 1094,
    volumeL: 14.88,
    nominalDeltaT50KW: 1.266,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 903,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-865-17",
    rangeType: "Emmeline I",
    model: "Emmeline I 865mm",
    depthMM: 110,
    heightMM: 865,
    widthMM: 1161,
    volumeL: 15.81,
    nominalDeltaT50KW: 1.345,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1021,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-865-18",
    rangeType: "Emmeline I",
    model: "Emmeline I 865mm",
    depthMM: 110,
    heightMM: 865,
    widthMM: 1227,
    volumeL: 16.74,
    nominalDeltaT50KW: 1.424,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1069,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-865-19",
    rangeType: "Emmeline I",
    model: "Emmeline I 865mm",
    depthMM: 110,
    heightMM: 865,
    widthMM: 1294,
    volumeL: 17.67,
    nominalDeltaT50KW: 1.503,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1117,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-865-20",
    rangeType: "Emmeline I",
    model: "Emmeline I 865mm",
    depthMM: 110,
    heightMM: 865,
    widthMM: 1360,
    volumeL: 18.6,
    nominalDeltaT50KW: 1.582,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1165,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-865-21",
    rangeType: "Emmeline I",
    model: "Emmeline I 865mm",
    depthMM: 110,
    heightMM: 865,
    widthMM: 1427,
    volumeL: 19.53,
    nominalDeltaT50KW: 1.661,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1213,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-865-22",
    rangeType: "Emmeline I",
    model: "Emmeline I 865mm",
    depthMM: 110,
    heightMM: 865,
    widthMM: 1493,
    volumeL: 20.46,
    nominalDeltaT50KW: 1.74,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1261,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-865-23",
    rangeType: "Emmeline I",
    model: "Emmeline I 865mm",
    depthMM: 110,
    heightMM: 865,
    widthMM: 1560,
    volumeL: 21.39,
    nominalDeltaT50KW: 1.819,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1309,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-865-24",
    rangeType: "Emmeline I",
    model: "Emmeline I 865mm",
    depthMM: 110,
    heightMM: 865,
    widthMM: 1626,
    volumeL: 22.32,
    nominalDeltaT50KW: 1.898,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1357,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-865-25",
    rangeType: "Emmeline I",
    model: "Emmeline I 865mm",
    depthMM: 110,
    heightMM: 865,
    widthMM: 1693,
    volumeL: 23.25,
    nominalDeltaT50KW: 1.978,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1405,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-865-26",
    rangeType: "Emmeline I",
    model: "Emmeline I 865mm",
    depthMM: 110,
    heightMM: 865,
    widthMM: 1759,
    volumeL: 24.18,
    nominalDeltaT50KW: 2.057,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1453,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-865-27",
    rangeType: "Emmeline I",
    model: "Emmeline I 865mm",
    depthMM: 110,
    heightMM: 865,
    widthMM: 1826,
    volumeL: 25.11,
    nominalDeltaT50KW: 2.136,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1501,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-865-28",
    rangeType: "Emmeline I",
    model: "Emmeline I 865mm",
    depthMM: 110,
    heightMM: 865,
    widthMM: 1892,
    volumeL: 26.04,
    nominalDeltaT50KW: 2.215,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1549,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-865-29",
    rangeType: "Emmeline I",
    model: "Emmeline I 865mm",
    depthMM: 110,
    heightMM: 865,
    widthMM: 1959,
    volumeL: 26.97,
    nominalDeltaT50KW: 2.294,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1597,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-865-30",
    rangeType: "Emmeline I",
    model: "Emmeline I 865mm",
    depthMM: 110,
    heightMM: 865,
    widthMM: 2025,
    volumeL: 27.9,
    nominalDeltaT50KW: 2.373,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1645,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-865-31",
    rangeType: "Emmeline I",
    model: "Emmeline I 865mm",
    depthMM: 110,
    heightMM: 865,
    widthMM: 2092,
    volumeL: 28.83,
    nominalDeltaT50KW: 2.452,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1693,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-865-32",
    rangeType: "Emmeline I",
    model: "Emmeline I 865mm",
    depthMM: 110,
    heightMM: 865,
    widthMM: 2158,
    volumeL: 29.76,
    nominalDeltaT50KW: 2.531,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1741,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-865-33",
    rangeType: "Emmeline I",
    model: "Emmeline I 865mm",
    depthMM: 110,
    heightMM: 865,
    widthMM: 2225,
    volumeL: 30.69,
    nominalDeltaT50KW: 2.61,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1789,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-865-34",
    rangeType: "Emmeline I",
    model: "Emmeline I 865mm",
    depthMM: 110,
    heightMM: 865,
    widthMM: 2291,
    volumeL: 31.62,
    nominalDeltaT50KW: 2.689,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1837,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-865-35",
    rangeType: "Emmeline I",
    model: "Emmeline I 865mm",
    depthMM: 110,
    heightMM: 865,
    widthMM: 2358,
    volumeL: 32.55,
    nominalDeltaT50KW: 2.769,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1885,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-865-36",
    rangeType: "Emmeline I",
    model: "Emmeline I 865mm",
    depthMM: 110,
    heightMM: 865,
    widthMM: 2424,
    volumeL: 33.48,
    nominalDeltaT50KW: 2.848,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1933,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-865-37",
    rangeType: "Emmeline I",
    model: "Emmeline I 865mm",
    depthMM: 110,
    heightMM: 865,
    widthMM: 2491,
    volumeL: 34.41,
    nominalDeltaT50KW: 2.927,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1981,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-865-38",
    rangeType: "Emmeline I",
    model: "Emmeline I 865mm",
    depthMM: 110,
    heightMM: 865,
    widthMM: 2557,
    volumeL: 35.34,
    nominalDeltaT50KW: 3.006,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 2029,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-865-39",
    rangeType: "Emmeline I",
    model: "Emmeline I 865mm",
    depthMM: 110,
    heightMM: 865,
    widthMM: 2624,
    volumeL: 36.27,
    nominalDeltaT50KW: 3.085,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 2077,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML1-865-40",
    rangeType: "Emmeline I",
    model: "Emmeline I 865mm",
    depthMM: 110,
    heightMM: 865,
    widthMM: 2690,
    volumeL: 37.2,
    nominalDeltaT50KW: 3.164,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 2125,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-470-04",
    rangeType: "Emmeline II",
    model: "Emmeline II 470mm",
    depthMM: 170,
    heightMM: 470,
    widthMM: 296,
    volumeL: 4.2,
    nominalDeltaT50KW: 0.244,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 299,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-470-05",
    rangeType: "Emmeline II",
    model: "Emmeline II 470mm",
    depthMM: 170,
    heightMM: 470,
    widthMM: 363,
    volumeL: 5.25,
    nominalDeltaT50KW: 0.305,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 340,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-470-06",
    rangeType: "Emmeline II",
    model: "Emmeline II 470mm",
    depthMM: 170,
    heightMM: 470,
    widthMM: 429,
    volumeL: 6.3,
    nominalDeltaT50KW: 0.365,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 381,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-470-07",
    rangeType: "Emmeline II",
    model: "Emmeline II 470mm",
    depthMM: 170,
    heightMM: 470,
    widthMM: 496,
    volumeL: 7.35,
    nominalDeltaT50KW: 0.426,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 422,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-470-08",
    rangeType: "Emmeline II",
    model: "Emmeline II 470mm",
    depthMM: 170,
    heightMM: 470,
    widthMM: 562,
    volumeL: 8.4,
    nominalDeltaT50KW: 0.487,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 463,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-470-09",
    rangeType: "Emmeline II",
    model: "Emmeline II 470mm",
    depthMM: 170,
    heightMM: 470,
    widthMM: 629,
    volumeL: 9.45,
    nominalDeltaT50KW: 0.548,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 504,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-470-10",
    rangeType: "Emmeline II",
    model: "Emmeline II 470mm",
    depthMM: 170,
    heightMM: 470,
    widthMM: 695,
    volumeL: 10.5,
    nominalDeltaT50KW: 0.609,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 545,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-470-11",
    rangeType: "Emmeline II",
    model: "Emmeline II 470mm",
    depthMM: 170,
    heightMM: 470,
    widthMM: 762,
    volumeL: 11.55,
    nominalDeltaT50KW: 0.67,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 586,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-470-12",
    rangeType: "Emmeline II",
    model: "Emmeline II 470mm",
    depthMM: 170,
    heightMM: 470,
    widthMM: 828,
    volumeL: 12.6,
    nominalDeltaT50KW: 0.731,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 627,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-470-13",
    rangeType: "Emmeline II",
    model: "Emmeline II 470mm",
    depthMM: 170,
    heightMM: 470,
    widthMM: 895,
    volumeL: 13.65,
    nominalDeltaT50KW: 0.792,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 668,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-470-14",
    rangeType: "Emmeline II",
    model: "Emmeline II 470mm",
    depthMM: 170,
    heightMM: 470,
    widthMM: 961,
    volumeL: 14.7,
    nominalDeltaT50KW: 0.853,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 709,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-470-15",
    rangeType: "Emmeline II",
    model: "Emmeline II 470mm",
    depthMM: 170,
    heightMM: 470,
    widthMM: 1028,
    volumeL: 15.75,
    nominalDeltaT50KW: 0.914,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 750,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-470-16",
    rangeType: "Emmeline II",
    model: "Emmeline II 470mm",
    depthMM: 170,
    heightMM: 470,
    widthMM: 1094,
    volumeL: 16.8,
    nominalDeltaT50KW: 0.974,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 791,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-470-17",
    rangeType: "Emmeline II",
    model: "Emmeline II 470mm",
    depthMM: 170,
    heightMM: 470,
    widthMM: 1161,
    volumeL: 17.85,
    nominalDeltaT50KW: 1.035,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 902,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-470-18",
    rangeType: "Emmeline II",
    model: "Emmeline II 470mm",
    depthMM: 170,
    heightMM: 470,
    widthMM: 1227,
    volumeL: 18.9,
    nominalDeltaT50KW: 1.096,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 943,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-470-19",
    rangeType: "Emmeline II",
    model: "Emmeline II 470mm",
    depthMM: 170,
    heightMM: 470,
    widthMM: 1294,
    volumeL: 19.95,
    nominalDeltaT50KW: 1.157,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 984,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-470-20",
    rangeType: "Emmeline II",
    model: "Emmeline II 470mm",
    depthMM: 170,
    heightMM: 470,
    widthMM: 1360,
    volumeL: 21,
    nominalDeltaT50KW: 1.218,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1025,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-470-21",
    rangeType: "Emmeline II",
    model: "Emmeline II 470mm",
    depthMM: 170,
    heightMM: 470,
    widthMM: 1427,
    volumeL: 22.05,
    nominalDeltaT50KW: 1.279,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1066,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-470-22",
    rangeType: "Emmeline II",
    model: "Emmeline II 470mm",
    depthMM: 170,
    heightMM: 470,
    widthMM: 1493,
    volumeL: 23.1,
    nominalDeltaT50KW: 1.34,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1107,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-470-23",
    rangeType: "Emmeline II",
    model: "Emmeline II 470mm",
    depthMM: 170,
    heightMM: 470,
    widthMM: 1560,
    volumeL: 24.15,
    nominalDeltaT50KW: 1.401,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1148,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-470-24",
    rangeType: "Emmeline II",
    model: "Emmeline II 470mm",
    depthMM: 170,
    heightMM: 470,
    widthMM: 1626,
    volumeL: 25.2,
    nominalDeltaT50KW: 1.462,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1189,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-470-25",
    rangeType: "Emmeline II",
    model: "Emmeline II 470mm",
    depthMM: 170,
    heightMM: 470,
    widthMM: 1693,
    volumeL: 26.25,
    nominalDeltaT50KW: 1.523,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1230,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-470-26",
    rangeType: "Emmeline II",
    model: "Emmeline II 470mm",
    depthMM: 170,
    heightMM: 470,
    widthMM: 1759,
    volumeL: 27.3,
    nominalDeltaT50KW: 1.583,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1271,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-470-27",
    rangeType: "Emmeline II",
    model: "Emmeline II 470mm",
    depthMM: 170,
    heightMM: 470,
    widthMM: 1826,
    volumeL: 28.35,
    nominalDeltaT50KW: 1.644,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1312,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-470-28",
    rangeType: "Emmeline II",
    model: "Emmeline II 470mm",
    depthMM: 170,
    heightMM: 470,
    widthMM: 1892,
    volumeL: 29.4,
    nominalDeltaT50KW: 1.705,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1353,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-470-29",
    rangeType: "Emmeline II",
    model: "Emmeline II 470mm",
    depthMM: 170,
    heightMM: 470,
    widthMM: 1959,
    volumeL: 30.45,
    nominalDeltaT50KW: 1.766,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1394,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-470-30",
    rangeType: "Emmeline II",
    model: "Emmeline II 470mm",
    depthMM: 170,
    heightMM: 470,
    widthMM: 2025,
    volumeL: 31.5,
    nominalDeltaT50KW: 1.827,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1435,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-470-31",
    rangeType: "Emmeline II",
    model: "Emmeline II 470mm",
    depthMM: 170,
    heightMM: 470,
    widthMM: 2092,
    volumeL: 32.55,
    nominalDeltaT50KW: 1.888,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1476,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-470-32",
    rangeType: "Emmeline II",
    model: "Emmeline II 470mm",
    depthMM: 170,
    heightMM: 470,
    widthMM: 2158,
    volumeL: 33.6,
    nominalDeltaT50KW: 1.949,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1517,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-470-33",
    rangeType: "Emmeline II",
    model: "Emmeline II 470mm",
    depthMM: 170,
    heightMM: 470,
    widthMM: 2225,
    volumeL: 34.65,
    nominalDeltaT50KW: 2.01,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1558,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-470-34",
    rangeType: "Emmeline II",
    model: "Emmeline II 470mm",
    depthMM: 170,
    heightMM: 470,
    widthMM: 2291,
    volumeL: 35.7,
    nominalDeltaT50KW: 2.071,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1599,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-470-35",
    rangeType: "Emmeline II",
    model: "Emmeline II 470mm",
    depthMM: 170,
    heightMM: 470,
    widthMM: 2358,
    volumeL: 36.75,
    nominalDeltaT50KW: 2.132,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1640,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-470-36",
    rangeType: "Emmeline II",
    model: "Emmeline II 470mm",
    depthMM: 170,
    heightMM: 470,
    widthMM: 2424,
    volumeL: 37.8,
    nominalDeltaT50KW: 2.192,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1681,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-470-37",
    rangeType: "Emmeline II",
    model: "Emmeline II 470mm",
    depthMM: 170,
    heightMM: 470,
    widthMM: 2491,
    volumeL: 38.85,
    nominalDeltaT50KW: 2.253,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1722,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-470-38",
    rangeType: "Emmeline II",
    model: "Emmeline II 470mm",
    depthMM: 170,
    heightMM: 470,
    widthMM: 2557,
    volumeL: 39.9,
    nominalDeltaT50KW: 2.314,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1763,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-470-39",
    rangeType: "Emmeline II",
    model: "Emmeline II 470mm",
    depthMM: 170,
    heightMM: 470,
    widthMM: 2624,
    volumeL: 40.95,
    nominalDeltaT50KW: 2.375,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1804,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-470-40",
    rangeType: "Emmeline II",
    model: "Emmeline II 470mm",
    depthMM: 170,
    heightMM: 470,
    widthMM: 2690,
    volumeL: 42,
    nominalDeltaT50KW: 2.436,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1845,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-670-04",
    rangeType: "Emmeline II",
    model: "Emmeline II 670mm",
    depthMM: 170,
    heightMM: 670,
    widthMM: 296,
    volumeL: 5.96,
    nominalDeltaT50KW: 0.348,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 367,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-670-05",
    rangeType: "Emmeline II",
    model: "Emmeline II 670mm",
    depthMM: 170,
    heightMM: 670,
    widthMM: 363,
    volumeL: 7.45,
    nominalDeltaT50KW: 0.435,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 425,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-670-06",
    rangeType: "Emmeline II",
    model: "Emmeline II 670mm",
    depthMM: 170,
    heightMM: 670,
    widthMM: 429,
    volumeL: 8.94,
    nominalDeltaT50KW: 0.521,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 483,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-670-07",
    rangeType: "Emmeline II",
    model: "Emmeline II 670mm",
    depthMM: 170,
    heightMM: 670,
    widthMM: 496,
    volumeL: 10.43,
    nominalDeltaT50KW: 0.608,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 541,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-670-08",
    rangeType: "Emmeline II",
    model: "Emmeline II 670mm",
    depthMM: 170,
    heightMM: 670,
    widthMM: 562,
    volumeL: 11.92,
    nominalDeltaT50KW: 0.695,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 599,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-670-09",
    rangeType: "Emmeline II",
    model: "Emmeline II 670mm",
    depthMM: 170,
    heightMM: 670,
    widthMM: 629,
    volumeL: 13.41,
    nominalDeltaT50KW: 0.782,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 657,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-670-10",
    rangeType: "Emmeline II",
    model: "Emmeline II 670mm",
    depthMM: 170,
    heightMM: 670,
    widthMM: 695,
    volumeL: 14.9,
    nominalDeltaT50KW: 0.869,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 715,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-670-11",
    rangeType: "Emmeline II",
    model: "Emmeline II 670mm",
    depthMM: 170,
    heightMM: 670,
    widthMM: 762,
    volumeL: 16.39,
    nominalDeltaT50KW: 0.956,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 773,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-670-12",
    rangeType: "Emmeline II",
    model: "Emmeline II 670mm",
    depthMM: 170,
    heightMM: 670,
    widthMM: 828,
    volumeL: 17.88,
    nominalDeltaT50KW: 1.043,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 831,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-670-13",
    rangeType: "Emmeline II",
    model: "Emmeline II 670mm",
    depthMM: 170,
    heightMM: 670,
    widthMM: 895,
    volumeL: 19.37,
    nominalDeltaT50KW: 1.13,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 889,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-670-14",
    rangeType: "Emmeline II",
    model: "Emmeline II 670mm",
    depthMM: 170,
    heightMM: 670,
    widthMM: 961,
    volumeL: 20.86,
    nominalDeltaT50KW: 1.217,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 947,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-670-15",
    rangeType: "Emmeline II",
    model: "Emmeline II 670mm",
    depthMM: 170,
    heightMM: 670,
    widthMM: 1028,
    volumeL: 22.35,
    nominalDeltaT50KW: 1.304,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1005,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-670-16",
    rangeType: "Emmeline II",
    model: "Emmeline II 670mm",
    depthMM: 170,
    heightMM: 670,
    widthMM: 1094,
    volumeL: 23.84,
    nominalDeltaT50KW: 1.39,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1063,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-670-17",
    rangeType: "Emmeline II",
    model: "Emmeline II 670mm",
    depthMM: 170,
    heightMM: 670,
    widthMM: 1161,
    volumeL: 25.33,
    nominalDeltaT50KW: 1.477,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1191,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-670-18",
    rangeType: "Emmeline II",
    model: "Emmeline II 670mm",
    depthMM: 170,
    heightMM: 670,
    widthMM: 1227,
    volumeL: 26.82,
    nominalDeltaT50KW: 1.564,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1249,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-670-19",
    rangeType: "Emmeline II",
    model: "Emmeline II 670mm",
    depthMM: 170,
    heightMM: 670,
    widthMM: 1294,
    volumeL: 28.31,
    nominalDeltaT50KW: 1.651,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1307,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-670-20",
    rangeType: "Emmeline II",
    model: "Emmeline II 670mm",
    depthMM: 170,
    heightMM: 670,
    widthMM: 1360,
    volumeL: 29.8,
    nominalDeltaT50KW: 1.738,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1365,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-670-21",
    rangeType: "Emmeline II",
    model: "Emmeline II 670mm",
    depthMM: 170,
    heightMM: 670,
    widthMM: 1427,
    volumeL: 31.29,
    nominalDeltaT50KW: 1.825,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1423,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-670-22",
    rangeType: "Emmeline II",
    model: "Emmeline II 670mm",
    depthMM: 170,
    heightMM: 670,
    widthMM: 1493,
    volumeL: 32.78,
    nominalDeltaT50KW: 1.912,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1481,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-670-23",
    rangeType: "Emmeline II",
    model: "Emmeline II 670mm",
    depthMM: 170,
    heightMM: 670,
    widthMM: 1560,
    volumeL: 34.27,
    nominalDeltaT50KW: 1.999,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1539,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-670-24",
    rangeType: "Emmeline II",
    model: "Emmeline II 670mm",
    depthMM: 170,
    heightMM: 670,
    widthMM: 1626,
    volumeL: 35.76,
    nominalDeltaT50KW: 2.086,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1597,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-670-25",
    rangeType: "Emmeline II",
    model: "Emmeline II 670mm",
    depthMM: 170,
    heightMM: 670,
    widthMM: 1693,
    volumeL: 37.25,
    nominalDeltaT50KW: 2.173,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1655,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-670-26",
    rangeType: "Emmeline II",
    model: "Emmeline II 670mm",
    depthMM: 170,
    heightMM: 670,
    widthMM: 1759,
    volumeL: 38.74,
    nominalDeltaT50KW: 2.259,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1713,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-670-27",
    rangeType: "Emmeline II",
    model: "Emmeline II 670mm",
    depthMM: 170,
    heightMM: 670,
    widthMM: 1826,
    volumeL: 40.23,
    nominalDeltaT50KW: 2.346,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1771,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-670-28",
    rangeType: "Emmeline II",
    model: "Emmeline II 670mm",
    depthMM: 170,
    heightMM: 670,
    widthMM: 1892,
    volumeL: 41.72,
    nominalDeltaT50KW: 2.433,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1829,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-670-29",
    rangeType: "Emmeline II",
    model: "Emmeline II 670mm",
    depthMM: 170,
    heightMM: 670,
    widthMM: 1959,
    volumeL: 43.21,
    nominalDeltaT50KW: 2.52,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1887,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-670-30",
    rangeType: "Emmeline II",
    model: "Emmeline II 670mm",
    depthMM: 170,
    heightMM: 670,
    widthMM: 2025,
    volumeL: 44.7,
    nominalDeltaT50KW: 2.607,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1945,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-670-31",
    rangeType: "Emmeline II",
    model: "Emmeline II 670mm",
    depthMM: 170,
    heightMM: 670,
    widthMM: 2092,
    volumeL: 46.19,
    nominalDeltaT50KW: 2.694,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 2003,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-670-32",
    rangeType: "Emmeline II",
    model: "Emmeline II 670mm",
    depthMM: 170,
    heightMM: 670,
    widthMM: 2158,
    volumeL: 47.68,
    nominalDeltaT50KW: 2.781,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 2061,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-670-33",
    rangeType: "Emmeline II",
    model: "Emmeline II 670mm",
    depthMM: 170,
    heightMM: 670,
    widthMM: 2225,
    volumeL: 49.17,
    nominalDeltaT50KW: 2.868,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 2119,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-670-34",
    rangeType: "Emmeline II",
    model: "Emmeline II 670mm",
    depthMM: 170,
    heightMM: 670,
    widthMM: 2291,
    volumeL: 50.66,
    nominalDeltaT50KW: 2.955,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 2177,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-670-35",
    rangeType: "Emmeline II",
    model: "Emmeline II 670mm",
    depthMM: 170,
    heightMM: 670,
    widthMM: 2358,
    volumeL: 52.15,
    nominalDeltaT50KW: 3.042,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 2235,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-670-36",
    rangeType: "Emmeline II",
    model: "Emmeline II 670mm",
    depthMM: 170,
    heightMM: 670,
    widthMM: 2424,
    volumeL: 53.64,
    nominalDeltaT50KW: 3.128,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 2293,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-670-37",
    rangeType: "Emmeline II",
    model: "Emmeline II 670mm",
    depthMM: 170,
    heightMM: 670,
    widthMM: 2491,
    volumeL: 55.13,
    nominalDeltaT50KW: 3.215,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 2351,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-670-38",
    rangeType: "Emmeline II",
    model: "Emmeline II 670mm",
    depthMM: 170,
    heightMM: 670,
    widthMM: 2557,
    volumeL: 56.62,
    nominalDeltaT50KW: 3.302,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 2409,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-670-39",
    rangeType: "Emmeline II",
    model: "Emmeline II 670mm",
    depthMM: 170,
    heightMM: 670,
    widthMM: 2624,
    volumeL: 58.11,
    nominalDeltaT50KW: 3.389,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 2467,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-670-40",
    rangeType: "Emmeline II",
    model: "Emmeline II 670mm",
    depthMM: 170,
    heightMM: 670,
    widthMM: 2690,
    volumeL: 59.6,
    nominalDeltaT50KW: 3.476,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 2525,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-870-04",
    rangeType: "Emmeline II",
    model: "Emmeline II 870mm",
    depthMM: 170,
    heightMM: 870,
    widthMM: 296,
    volumeL: 7.6,
    nominalDeltaT50KW: 0.454,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 435,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-870-05",
    rangeType: "Emmeline II",
    model: "Emmeline II 870mm",
    depthMM: 170,
    heightMM: 870,
    widthMM: 363,
    volumeL: 9.5,
    nominalDeltaT50KW: 0.567,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 510,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-870-06",
    rangeType: "Emmeline II",
    model: "Emmeline II 870mm",
    depthMM: 170,
    heightMM: 870,
    widthMM: 429,
    volumeL: 11.4,
    nominalDeltaT50KW: 0.68,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 585,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-870-07",
    rangeType: "Emmeline II",
    model: "Emmeline II 870mm",
    depthMM: 170,
    heightMM: 870,
    widthMM: 496,
    volumeL: 13.3,
    nominalDeltaT50KW: 0.794,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 660,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-870-08",
    rangeType: "Emmeline II",
    model: "Emmeline II 870mm",
    depthMM: 170,
    heightMM: 870,
    widthMM: 562,
    volumeL: 15.2,
    nominalDeltaT50KW: 0.907,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 735,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-870-09",
    rangeType: "Emmeline II",
    model: "Emmeline II 870mm",
    depthMM: 170,
    heightMM: 870,
    widthMM: 629,
    volumeL: 17.1,
    nominalDeltaT50KW: 1.021,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 810,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-870-10",
    rangeType: "Emmeline II",
    model: "Emmeline II 870mm",
    depthMM: 170,
    heightMM: 870,
    widthMM: 695,
    volumeL: 19,
    nominalDeltaT50KW: 1.134,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 885,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-870-11",
    rangeType: "Emmeline II",
    model: "Emmeline II 870mm",
    depthMM: 170,
    heightMM: 870,
    widthMM: 762,
    volumeL: 20.9,
    nominalDeltaT50KW: 1.247,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 960,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-870-12",
    rangeType: "Emmeline II",
    model: "Emmeline II 870mm",
    depthMM: 170,
    heightMM: 870,
    widthMM: 828,
    volumeL: 22.8,
    nominalDeltaT50KW: 1.361,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 1035,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-870-13",
    rangeType: "Emmeline II",
    model: "Emmeline II 870mm",
    depthMM: 170,
    heightMM: 870,
    widthMM: 895,
    volumeL: 24.7,
    nominalDeltaT50KW: 1.474,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 1110,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-870-14",
    rangeType: "Emmeline II",
    model: "Emmeline II 870mm",
    depthMM: 170,
    heightMM: 870,
    widthMM: 961,
    volumeL: 26.6,
    nominalDeltaT50KW: 1.588,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 1255,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-870-15",
    rangeType: "Emmeline II",
    model: "Emmeline II 870mm",
    depthMM: 170,
    heightMM: 870,
    widthMM: 1028,
    volumeL: 28.5,
    nominalDeltaT50KW: 1.701,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 1330,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-870-16",
    rangeType: "Emmeline II",
    model: "Emmeline II 870mm",
    depthMM: 170,
    heightMM: 870,
    widthMM: 1094,
    volumeL: 30.4,
    nominalDeltaT50KW: 1.814,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 1405,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-870-17",
    rangeType: "Emmeline II",
    model: "Emmeline II 870mm",
    depthMM: 170,
    heightMM: 870,
    widthMM: 1161,
    volumeL: 32.3,
    nominalDeltaT50KW: 1.928,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 1480,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-870-18",
    rangeType: "Emmeline II",
    model: "Emmeline II 870mm",
    depthMM: 170,
    heightMM: 870,
    widthMM: 1227,
    volumeL: 34.2,
    nominalDeltaT50KW: 2.041,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 1555,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-870-19",
    rangeType: "Emmeline II",
    model: "Emmeline II 870mm",
    depthMM: 170,
    heightMM: 870,
    widthMM: 1294,
    volumeL: 36.1,
    nominalDeltaT50KW: 2.155,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 1630,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-870-20",
    rangeType: "Emmeline II",
    model: "Emmeline II 870mm",
    depthMM: 170,
    heightMM: 870,
    widthMM: 1360,
    volumeL: 38,
    nominalDeltaT50KW: 2.268,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 1705,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-870-21",
    rangeType: "Emmeline II",
    model: "Emmeline II 870mm",
    depthMM: 170,
    heightMM: 870,
    widthMM: 1427,
    volumeL: 39.9,
    nominalDeltaT50KW: 2.381,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 1780,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-870-22",
    rangeType: "Emmeline II",
    model: "Emmeline II 870mm",
    depthMM: 170,
    heightMM: 870,
    widthMM: 1493,
    volumeL: 41.8,
    nominalDeltaT50KW: 2.495,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 1855,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-870-23",
    rangeType: "Emmeline II",
    model: "Emmeline II 870mm",
    depthMM: 170,
    heightMM: 870,
    widthMM: 1560,
    volumeL: 43.7,
    nominalDeltaT50KW: 2.608,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 1930,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-870-24",
    rangeType: "Emmeline II",
    model: "Emmeline II 870mm",
    depthMM: 170,
    heightMM: 870,
    widthMM: 1626,
    volumeL: 45.6,
    nominalDeltaT50KW: 2.722,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 2005,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-870-25",
    rangeType: "Emmeline II",
    model: "Emmeline II 870mm",
    depthMM: 170,
    heightMM: 870,
    widthMM: 1693,
    volumeL: 47.5,
    nominalDeltaT50KW: 2.835,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 2080,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-870-26",
    rangeType: "Emmeline II",
    model: "Emmeline II 870mm",
    depthMM: 170,
    heightMM: 870,
    widthMM: 1759,
    volumeL: 49.4,
    nominalDeltaT50KW: 2.948,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 2155,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-870-27",
    rangeType: "Emmeline II",
    model: "Emmeline II 870mm",
    depthMM: 170,
    heightMM: 870,
    widthMM: 1826,
    volumeL: 51.3,
    nominalDeltaT50KW: 3.062,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 2230,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-870-28",
    rangeType: "Emmeline II",
    model: "Emmeline II 870mm",
    depthMM: 170,
    heightMM: 870,
    widthMM: 1892,
    volumeL: 53.2,
    nominalDeltaT50KW: 3.175,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 2305,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-870-29",
    rangeType: "Emmeline II",
    model: "Emmeline II 870mm",
    depthMM: 170,
    heightMM: 870,
    widthMM: 1959,
    volumeL: 55.1,
    nominalDeltaT50KW: 3.289,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 2380,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-870-30",
    rangeType: "Emmeline II",
    model: "Emmeline II 870mm",
    depthMM: 170,
    heightMM: 870,
    widthMM: 2025,
    volumeL: 57,
    nominalDeltaT50KW: 3.402,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 2455,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-870-31",
    rangeType: "Emmeline II",
    model: "Emmeline II 870mm",
    depthMM: 170,
    heightMM: 870,
    widthMM: 2092,
    volumeL: 58.9,
    nominalDeltaT50KW: 3.515,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 2530,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-870-32",
    rangeType: "Emmeline II",
    model: "Emmeline II 870mm",
    depthMM: 170,
    heightMM: 870,
    widthMM: 2158,
    volumeL: 60.8,
    nominalDeltaT50KW: 3.629,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 2605,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-870-33",
    rangeType: "Emmeline II",
    model: "Emmeline II 870mm",
    depthMM: 170,
    heightMM: 870,
    widthMM: 2225,
    volumeL: 62.7,
    nominalDeltaT50KW: 3.742,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 2680,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-870-34",
    rangeType: "Emmeline II",
    model: "Emmeline II 870mm",
    depthMM: 170,
    heightMM: 870,
    widthMM: 2291,
    volumeL: 64.6,
    nominalDeltaT50KW: 3.856,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 2755,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-870-35",
    rangeType: "Emmeline II",
    model: "Emmeline II 870mm",
    depthMM: 170,
    heightMM: 870,
    widthMM: 2358,
    volumeL: 66.5,
    nominalDeltaT50KW: 3.969,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 2830,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-870-36",
    rangeType: "Emmeline II",
    model: "Emmeline II 870mm",
    depthMM: 170,
    heightMM: 870,
    widthMM: 2424,
    volumeL: 68.4,
    nominalDeltaT50KW: 4.082,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 2905,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-870-37",
    rangeType: "Emmeline II",
    model: "Emmeline II 870mm",
    depthMM: 170,
    heightMM: 870,
    widthMM: 2491,
    volumeL: 70.3,
    nominalDeltaT50KW: 4.196,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 2980,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-870-38",
    rangeType: "Emmeline II",
    model: "Emmeline II 870mm",
    depthMM: 170,
    heightMM: 870,
    widthMM: 2557,
    volumeL: 72.2,
    nominalDeltaT50KW: 4.309,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 3055,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-870-39",
    rangeType: "Emmeline II",
    model: "Emmeline II 870mm",
    depthMM: 170,
    heightMM: 870,
    widthMM: 2624,
    volumeL: 74.1,
    nominalDeltaT50KW: 4.423,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 3130,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML2-870-40",
    rangeType: "Emmeline II",
    model: "Emmeline II 870mm",
    depthMM: 170,
    heightMM: 870,
    widthMM: 2690,
    volumeL: 76,
    nominalDeltaT50KW: 4.536,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 3205,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-470-04",
    rangeType: "Emmeline III",
    model: "Emmeline III 470mm",
    depthMM: 230,
    heightMM: 470,
    widthMM: 296,
    nominalDeltaT50KW: 0.325,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 363,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-470-05",
    rangeType: "Emmeline III",
    model: "Emmeline III 470mm",
    depthMM: 230,
    heightMM: 470,
    widthMM: 363,
    nominalDeltaT50KW: 0.406,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 420,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-470-06",
    rangeType: "Emmeline III",
    model: "Emmeline III 470mm",
    depthMM: 230,
    heightMM: 470,
    widthMM: 429,
    nominalDeltaT50KW: 0.487,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 477,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-470-07",
    rangeType: "Emmeline III",
    model: "Emmeline III 470mm",
    depthMM: 230,
    heightMM: 470,
    widthMM: 496,
    nominalDeltaT50KW: 0.568,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 534,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-470-08",
    rangeType: "Emmeline III",
    model: "Emmeline III 470mm",
    depthMM: 230,
    heightMM: 470,
    widthMM: 562,
    nominalDeltaT50KW: 0.65,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 591,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-470-09",
    rangeType: "Emmeline III",
    model: "Emmeline III 470mm",
    depthMM: 230,
    heightMM: 470,
    widthMM: 629,
    nominalDeltaT50KW: 0.731,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 648,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-470-10",
    rangeType: "Emmeline III",
    model: "Emmeline III 470mm",
    depthMM: 230,
    heightMM: 470,
    widthMM: 695,
    nominalDeltaT50KW: 0.812,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 705,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-470-11",
    rangeType: "Emmeline III",
    model: "Emmeline III 470mm",
    depthMM: 230,
    heightMM: 470,
    widthMM: 762,
    nominalDeltaT50KW: 0.893,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 762,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-470-12",
    rangeType: "Emmeline III",
    model: "Emmeline III 470mm",
    depthMM: 230,
    heightMM: 470,
    widthMM: 828,
    nominalDeltaT50KW: 0.974,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 819,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-470-13",
    rangeType: "Emmeline III",
    model: "Emmeline III 470mm",
    depthMM: 230,
    heightMM: 470,
    widthMM: 895,
    nominalDeltaT50KW: 1.056,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 876,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-470-14",
    rangeType: "Emmeline III",
    model: "Emmeline III 470mm",
    depthMM: 230,
    heightMM: 470,
    widthMM: 961,
    nominalDeltaT50KW: 1.137,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 933,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-470-15",
    rangeType: "Emmeline III",
    model: "Emmeline III 470mm",
    depthMM: 230,
    heightMM: 470,
    widthMM: 1028,
    nominalDeltaT50KW: 1.218,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 990,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-470-16",
    rangeType: "Emmeline III",
    model: "Emmeline III 470mm",
    depthMM: 230,
    heightMM: 470,
    widthMM: 1094,
    nominalDeltaT50KW: 1.299,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1047,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-470-17",
    rangeType: "Emmeline III",
    model: "Emmeline III 470mm",
    depthMM: 230,
    heightMM: 470,
    widthMM: 1161,
    nominalDeltaT50KW: 1.38,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1174,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-470-18",
    rangeType: "Emmeline III",
    model: "Emmeline III 470mm",
    depthMM: 230,
    heightMM: 470,
    widthMM: 1227,
    nominalDeltaT50KW: 1.462,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1231,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-470-19",
    rangeType: "Emmeline III",
    model: "Emmeline III 470mm",
    depthMM: 230,
    heightMM: 470,
    widthMM: 1294,
    nominalDeltaT50KW: 1.543,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1288,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-470-20",
    rangeType: "Emmeline III",
    model: "Emmeline III 470mm",
    depthMM: 230,
    heightMM: 470,
    widthMM: 1360,
    nominalDeltaT50KW: 1.624,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1345,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-470-21",
    rangeType: "Emmeline III",
    model: "Emmeline III 470mm",
    depthMM: 230,
    heightMM: 470,
    widthMM: 1427,
    nominalDeltaT50KW: 1.705,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1402,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-470-22",
    rangeType: "Emmeline III",
    model: "Emmeline III 470mm",
    depthMM: 230,
    heightMM: 470,
    widthMM: 1493,
    nominalDeltaT50KW: 1.786,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1459,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-470-23",
    rangeType: "Emmeline III",
    model: "Emmeline III 470mm",
    depthMM: 230,
    heightMM: 470,
    widthMM: 1560,
    nominalDeltaT50KW: 1.868,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1516,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-470-24",
    rangeType: "Emmeline III",
    model: "Emmeline III 470mm",
    depthMM: 230,
    heightMM: 470,
    widthMM: 1626,
    nominalDeltaT50KW: 1.949,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1573,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-470-25",
    rangeType: "Emmeline III",
    model: "Emmeline III 470mm",
    depthMM: 230,
    heightMM: 470,
    widthMM: 1693,
    nominalDeltaT50KW: 2.03,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1630,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-470-26",
    rangeType: "Emmeline III",
    model: "Emmeline III 470mm",
    depthMM: 230,
    heightMM: 470,
    widthMM: 1759,
    nominalDeltaT50KW: 2.111,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1687,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-470-27",
    rangeType: "Emmeline III",
    model: "Emmeline III 470mm",
    depthMM: 230,
    heightMM: 470,
    widthMM: 1826,
    nominalDeltaT50KW: 2.192,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1744,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-470-28",
    rangeType: "Emmeline III",
    model: "Emmeline III 470mm",
    depthMM: 230,
    heightMM: 470,
    widthMM: 1892,
    nominalDeltaT50KW: 2.274,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1801,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-470-29",
    rangeType: "Emmeline III",
    model: "Emmeline III 470mm",
    depthMM: 230,
    heightMM: 470,
    widthMM: 1959,
    nominalDeltaT50KW: 2.355,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1858,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-470-30",
    rangeType: "Emmeline III",
    model: "Emmeline III 470mm",
    depthMM: 230,
    heightMM: 470,
    widthMM: 2025,
    nominalDeltaT50KW: 2.436,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1915,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-470-31",
    rangeType: "Emmeline III",
    model: "Emmeline III 470mm",
    depthMM: 230,
    heightMM: 470,
    widthMM: 2092,
    nominalDeltaT50KW: 2.517,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1972,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-470-32",
    rangeType: "Emmeline III",
    model: "Emmeline III 470mm",
    depthMM: 230,
    heightMM: 470,
    widthMM: 2158,
    nominalDeltaT50KW: 2.598,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 2029,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-470-33",
    rangeType: "Emmeline III",
    model: "Emmeline III 470mm",
    depthMM: 230,
    heightMM: 470,
    widthMM: 2225,
    nominalDeltaT50KW: 2.68,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 2086,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-470-34",
    rangeType: "Emmeline III",
    model: "Emmeline III 470mm",
    depthMM: 230,
    heightMM: 470,
    widthMM: 2291,
    nominalDeltaT50KW: 2.761,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 2143,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-470-35",
    rangeType: "Emmeline III",
    model: "Emmeline III 470mm",
    depthMM: 230,
    heightMM: 470,
    widthMM: 2358,
    nominalDeltaT50KW: 2.842,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 2200,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-470-36",
    rangeType: "Emmeline III",
    model: "Emmeline III 470mm",
    depthMM: 230,
    heightMM: 470,
    widthMM: 2424,
    nominalDeltaT50KW: 2.923,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 2257,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-470-37",
    rangeType: "Emmeline III",
    model: "Emmeline III 470mm",
    depthMM: 230,
    heightMM: 470,
    widthMM: 2491,
    nominalDeltaT50KW: 3.004,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 2314,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-470-38",
    rangeType: "Emmeline III",
    model: "Emmeline III 470mm",
    depthMM: 230,
    heightMM: 470,
    widthMM: 2557,
    nominalDeltaT50KW: 3.086,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 2371,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-470-39",
    rangeType: "Emmeline III",
    model: "Emmeline III 470mm",
    depthMM: 230,
    heightMM: 470,
    widthMM: 2624,
    nominalDeltaT50KW: 3.167,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 2428,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-470-40",
    rangeType: "Emmeline III",
    model: "Emmeline III 470mm",
    depthMM: 230,
    heightMM: 470,
    widthMM: 2690,
    nominalDeltaT50KW: 3.248,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 2485,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-670-04",
    rangeType: "Emmeline III",
    model: "Emmeline III 670mm",
    depthMM: 230,
    heightMM: 670,
    widthMM: 296,
    volumeL: 7.12,
    nominalDeltaT50KW: 0.449,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 467,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-670-05",
    rangeType: "Emmeline III",
    model: "Emmeline III 670mm",
    depthMM: 230,
    heightMM: 670,
    widthMM: 363,
    volumeL: 8.9,
    nominalDeltaT50KW: 0.562,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 550,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-670-06",
    rangeType: "Emmeline III",
    model: "Emmeline III 670mm",
    depthMM: 230,
    heightMM: 670,
    widthMM: 429,
    volumeL: 10.68,
    nominalDeltaT50KW: 0.674,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 633,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-670-07",
    rangeType: "Emmeline III",
    model: "Emmeline III 670mm",
    depthMM: 230,
    heightMM: 670,
    widthMM: 496,
    volumeL: 12.46,
    nominalDeltaT50KW: 0.786,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 716,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-670-08",
    rangeType: "Emmeline III",
    model: "Emmeline III 670mm",
    depthMM: 230,
    heightMM: 670,
    widthMM: 562,
    volumeL: 14.24,
    nominalDeltaT50KW: 0.898,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 799,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-670-09",
    rangeType: "Emmeline III",
    model: "Emmeline III 670mm",
    depthMM: 230,
    heightMM: 670,
    widthMM: 629,
    volumeL: 16.02,
    nominalDeltaT50KW: 1.011,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 882,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-670-10",
    rangeType: "Emmeline III",
    model: "Emmeline III 670mm",
    depthMM: 230,
    heightMM: 670,
    widthMM: 695,
    volumeL: 17.8,
    nominalDeltaT50KW: 1.123,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 965,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-670-11",
    rangeType: "Emmeline III",
    model: "Emmeline III 670mm",
    depthMM: 230,
    heightMM: 670,
    widthMM: 762,
    volumeL: 19.58,
    nominalDeltaT50KW: 1.235,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1048,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-670-12",
    rangeType: "Emmeline III",
    model: "Emmeline III 670mm",
    depthMM: 230,
    heightMM: 670,
    widthMM: 828,
    volumeL: 21.36,
    nominalDeltaT50KW: 1.348,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1201,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-670-13",
    rangeType: "Emmeline III",
    model: "Emmeline III 670mm",
    depthMM: 230,
    heightMM: 670,
    widthMM: 895,
    volumeL: 23.14,
    nominalDeltaT50KW: 1.46,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1284,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-670-14",
    rangeType: "Emmeline III",
    model: "Emmeline III 670mm",
    depthMM: 230,
    heightMM: 670,
    widthMM: 961,
    volumeL: 24.92,
    nominalDeltaT50KW: 1.572,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1367,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-670-15",
    rangeType: "Emmeline III",
    model: "Emmeline III 670mm",
    depthMM: 230,
    heightMM: 670,
    widthMM: 1028,
    volumeL: 26.7,
    nominalDeltaT50KW: 1.685,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1450,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-670-16",
    rangeType: "Emmeline III",
    model: "Emmeline III 670mm",
    depthMM: 230,
    heightMM: 670,
    widthMM: 1094,
    volumeL: 28.48,
    nominalDeltaT50KW: 1.797,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1533,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-670-17",
    rangeType: "Emmeline III",
    model: "Emmeline III 670mm",
    depthMM: 230,
    heightMM: 670,
    widthMM: 1161,
    volumeL: 30.26,
    nominalDeltaT50KW: 1.909,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1616,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-670-18",
    rangeType: "Emmeline III",
    model: "Emmeline III 670mm",
    depthMM: 230,
    heightMM: 670,
    widthMM: 1227,
    volumeL: 32.04,
    nominalDeltaT50KW: 2.021,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1699,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-670-19",
    rangeType: "Emmeline III",
    model: "Emmeline III 670mm",
    depthMM: 230,
    heightMM: 670,
    widthMM: 1294,
    volumeL: 33.82,
    nominalDeltaT50KW: 2.134,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1782,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-670-20",
    rangeType: "Emmeline III",
    model: "Emmeline III 670mm",
    depthMM: 230,
    heightMM: 670,
    widthMM: 1360,
    volumeL: 35.6,
    nominalDeltaT50KW: 2.246,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1865,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-670-21",
    rangeType: "Emmeline III",
    model: "Emmeline III 670mm",
    depthMM: 230,
    heightMM: 670,
    widthMM: 1427,
    volumeL: 37.38,
    nominalDeltaT50KW: 2.358,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1948,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-670-22",
    rangeType: "Emmeline III",
    model: "Emmeline III 670mm",
    depthMM: 230,
    heightMM: 670,
    widthMM: 1493,
    volumeL: 39.16,
    nominalDeltaT50KW: 2.471,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 2031,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-670-23",
    rangeType: "Emmeline III",
    model: "Emmeline III 670mm",
    depthMM: 230,
    heightMM: 670,
    widthMM: 1560,
    volumeL: 40.94,
    nominalDeltaT50KW: 2.583,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 2114,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-670-24",
    rangeType: "Emmeline III",
    model: "Emmeline III 670mm",
    depthMM: 230,
    heightMM: 670,
    widthMM: 1626,
    volumeL: 42.72,
    nominalDeltaT50KW: 2.695,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 2197,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-670-25",
    rangeType: "Emmeline III",
    model: "Emmeline III 670mm",
    depthMM: 230,
    heightMM: 670,
    widthMM: 1693,
    volumeL: 44.5,
    nominalDeltaT50KW: 2.808,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 2280,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-670-26",
    rangeType: "Emmeline III",
    model: "Emmeline III 670mm",
    depthMM: 230,
    heightMM: 670,
    widthMM: 1759,
    volumeL: 46.28,
    nominalDeltaT50KW: 2.92,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 2363,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-670-27",
    rangeType: "Emmeline III",
    model: "Emmeline III 670mm",
    depthMM: 230,
    heightMM: 670,
    widthMM: 1826,
    volumeL: 48.06,
    nominalDeltaT50KW: 3.032,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 2446,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-670-28",
    rangeType: "Emmeline III",
    model: "Emmeline III 670mm",
    depthMM: 230,
    heightMM: 670,
    widthMM: 1892,
    volumeL: 49.84,
    nominalDeltaT50KW: 3.144,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 2529,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-670-29",
    rangeType: "Emmeline III",
    model: "Emmeline III 670mm",
    depthMM: 230,
    heightMM: 670,
    widthMM: 1959,
    volumeL: 51.62,
    nominalDeltaT50KW: 3.257,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 2612,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-670-30",
    rangeType: "Emmeline III",
    model: "Emmeline III 670mm",
    depthMM: 230,
    heightMM: 670,
    widthMM: 2025,
    volumeL: 53.4,
    nominalDeltaT50KW: 3.369,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 2695,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-670-31",
    rangeType: "Emmeline III",
    model: "Emmeline III 670mm",
    depthMM: 230,
    heightMM: 670,
    widthMM: 2092,
    volumeL: 55.18,
    nominalDeltaT50KW: 3.481,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 2778,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-670-32",
    rangeType: "Emmeline III",
    model: "Emmeline III 670mm",
    depthMM: 230,
    heightMM: 670,
    widthMM: 2158,
    volumeL: 56.96,
    nominalDeltaT50KW: 3.594,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 2861,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-670-33",
    rangeType: "Emmeline III",
    model: "Emmeline III 670mm",
    depthMM: 230,
    heightMM: 670,
    widthMM: 2225,
    volumeL: 58.74,
    nominalDeltaT50KW: 3.706,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 2944,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-670-34",
    rangeType: "Emmeline III",
    model: "Emmeline III 670mm",
    depthMM: 230,
    heightMM: 670,
    widthMM: 2291,
    volumeL: 60.52,
    nominalDeltaT50KW: 3.818,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 3027,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-670-35",
    rangeType: "Emmeline III",
    model: "Emmeline III 670mm",
    depthMM: 230,
    heightMM: 670,
    widthMM: 2358,
    volumeL: 62.3,
    nominalDeltaT50KW: 3.931,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 3110,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-670-36",
    rangeType: "Emmeline III",
    model: "Emmeline III 670mm",
    depthMM: 230,
    heightMM: 670,
    widthMM: 2424,
    volumeL: 64.08,
    nominalDeltaT50KW: 4.043,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 3193,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-670-37",
    rangeType: "Emmeline III",
    model: "Emmeline III 670mm",
    depthMM: 230,
    heightMM: 670,
    widthMM: 2491,
    volumeL: 65.86,
    nominalDeltaT50KW: 4.155,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 3276,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-670-38",
    rangeType: "Emmeline III",
    model: "Emmeline III 670mm",
    depthMM: 230,
    heightMM: 670,
    widthMM: 2557,
    volumeL: 67.64,
    nominalDeltaT50KW: 4.267,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 3359,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-670-39",
    rangeType: "Emmeline III",
    model: "Emmeline III 670mm",
    depthMM: 230,
    heightMM: 670,
    widthMM: 2624,
    volumeL: 69.42,
    nominalDeltaT50KW: 4.38,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 3442,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-670-40",
    rangeType: "Emmeline III",
    model: "Emmeline III 670mm",
    depthMM: 230,
    heightMM: 670,
    widthMM: 2690,
    volumeL: 71.2,
    nominalDeltaT50KW: 4.492,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 3525,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-870-04",
    rangeType: "Emmeline III",
    model: "Emmeline III 870mm",
    depthMM: 230,
    heightMM: 870,
    widthMM: 296,
    volumeL: 10,
    nominalDeltaT50KW: 0.59,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 571,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-870-05",
    rangeType: "Emmeline III",
    model: "Emmeline III 870mm",
    depthMM: 230,
    heightMM: 870,
    widthMM: 363,
    volumeL: 12.5,
    nominalDeltaT50KW: 0.738,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 680,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-870-06",
    rangeType: "Emmeline III",
    model: "Emmeline III 870mm",
    depthMM: 230,
    heightMM: 870,
    widthMM: 429,
    volumeL: 15,
    nominalDeltaT50KW: 0.885,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 789,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-870-07",
    rangeType: "Emmeline III",
    model: "Emmeline III 870mm",
    depthMM: 230,
    heightMM: 870,
    widthMM: 496,
    volumeL: 17.5,
    nominalDeltaT50KW: 1.033,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 898,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-870-08",
    rangeType: "Emmeline III",
    model: "Emmeline III 870mm",
    depthMM: 230,
    heightMM: 870,
    widthMM: 562,
    volumeL: 20,
    nominalDeltaT50KW: 1.18,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1007,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-870-09",
    rangeType: "Emmeline III",
    model: "Emmeline III 870mm",
    depthMM: 230,
    heightMM: 870,
    widthMM: 629,
    volumeL: 22.5,
    nominalDeltaT50KW: 1.328,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1116,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-870-10",
    rangeType: "Emmeline III",
    model: "Emmeline III 870mm",
    depthMM: 230,
    heightMM: 870,
    widthMM: 695,
    volumeL: 25,
    nominalDeltaT50KW: 1.475,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1225,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-870-11",
    rangeType: "Emmeline III",
    model: "Emmeline III 870mm",
    depthMM: 230,
    heightMM: 870,
    widthMM: 762,
    volumeL: 27.5,
    nominalDeltaT50KW: 1.623,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1404,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-870-12",
    rangeType: "Emmeline III",
    model: "Emmeline III 870mm",
    depthMM: 230,
    heightMM: 870,
    widthMM: 828,
    volumeL: 30,
    nominalDeltaT50KW: 1.77,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1513,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-870-13",
    rangeType: "Emmeline III",
    model: "Emmeline III 870mm",
    depthMM: 230,
    heightMM: 870,
    widthMM: 895,
    volumeL: 32.5,
    nominalDeltaT50KW: 1.918,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1622,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-870-14",
    rangeType: "Emmeline III",
    model: "Emmeline III 870mm",
    depthMM: 230,
    heightMM: 870,
    widthMM: 961,
    volumeL: 35,
    nominalDeltaT50KW: 2.065,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1731,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-870-15",
    rangeType: "Emmeline III",
    model: "Emmeline III 870mm",
    depthMM: 230,
    heightMM: 870,
    widthMM: 1028,
    volumeL: 37.5,
    nominalDeltaT50KW: 2.213,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1840,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-870-16",
    rangeType: "Emmeline III",
    model: "Emmeline III 870mm",
    depthMM: 230,
    heightMM: 870,
    widthMM: 1094,
    volumeL: 40,
    nominalDeltaT50KW: 2.36,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1949,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-870-17",
    rangeType: "Emmeline III",
    model: "Emmeline III 870mm",
    depthMM: 230,
    heightMM: 870,
    widthMM: 1161,
    volumeL: 42.5,
    nominalDeltaT50KW: 2.508,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 2058,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-870-18",
    rangeType: "Emmeline III",
    model: "Emmeline III 870mm",
    depthMM: 230,
    heightMM: 870,
    widthMM: 1227,
    volumeL: 45,
    nominalDeltaT50KW: 2.655,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 2167,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-870-19",
    rangeType: "Emmeline III",
    model: "Emmeline III 870mm",
    depthMM: 230,
    heightMM: 870,
    widthMM: 1294,
    volumeL: 47.5,
    nominalDeltaT50KW: 2.803,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 2276,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-870-20",
    rangeType: "Emmeline III",
    model: "Emmeline III 870mm",
    depthMM: 230,
    heightMM: 870,
    widthMM: 1360,
    volumeL: 50,
    nominalDeltaT50KW: 2.95,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 2385,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-870-21",
    rangeType: "Emmeline III",
    model: "Emmeline III 870mm",
    depthMM: 230,
    heightMM: 870,
    widthMM: 1427,
    volumeL: 52.5,
    nominalDeltaT50KW: 3.098,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 2494,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-870-22",
    rangeType: "Emmeline III",
    model: "Emmeline III 870mm",
    depthMM: 230,
    heightMM: 870,
    widthMM: 1493,
    volumeL: 55,
    nominalDeltaT50KW: 3.245,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 2603,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-870-23",
    rangeType: "Emmeline III",
    model: "Emmeline III 870mm",
    depthMM: 230,
    heightMM: 870,
    widthMM: 1560,
    volumeL: 57.5,
    nominalDeltaT50KW: 3.393,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 2712,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-870-24",
    rangeType: "Emmeline III",
    model: "Emmeline III 870mm",
    depthMM: 230,
    heightMM: 870,
    widthMM: 1626,
    volumeL: 60,
    nominalDeltaT50KW: 3.54,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 2821,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-870-25",
    rangeType: "Emmeline III",
    model: "Emmeline III 870mm",
    depthMM: 230,
    heightMM: 870,
    widthMM: 1693,
    volumeL: 62.5,
    nominalDeltaT50KW: 3.688,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 2930,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-870-26",
    rangeType: "Emmeline III",
    model: "Emmeline III 870mm",
    depthMM: 230,
    heightMM: 870,
    widthMM: 1759,
    volumeL: 65,
    nominalDeltaT50KW: 3.835,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 3039,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-870-27",
    rangeType: "Emmeline III",
    model: "Emmeline III 870mm",
    depthMM: 230,
    heightMM: 870,
    widthMM: 1826,
    volumeL: 67.5,
    nominalDeltaT50KW: 3.983,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 3148,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-870-28",
    rangeType: "Emmeline III",
    model: "Emmeline III 870mm",
    depthMM: 230,
    heightMM: 870,
    widthMM: 1892,
    volumeL: 70,
    nominalDeltaT50KW: 4.13,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 3257,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-870-29",
    rangeType: "Emmeline III",
    model: "Emmeline III 870mm",
    depthMM: 230,
    heightMM: 870,
    widthMM: 1959,
    volumeL: 72.5,
    nominalDeltaT50KW: 4.278,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 3366,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-870-30",
    rangeType: "Emmeline III",
    model: "Emmeline III 870mm",
    depthMM: 230,
    heightMM: 870,
    widthMM: 2025,
    volumeL: 75,
    nominalDeltaT50KW: 4.425,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 3475,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-870-31",
    rangeType: "Emmeline III",
    model: "Emmeline III 870mm",
    depthMM: 230,
    heightMM: 870,
    widthMM: 2092,
    volumeL: 77.5,
    nominalDeltaT50KW: 4.573,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 3584,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-870-32",
    rangeType: "Emmeline III",
    model: "Emmeline III 870mm",
    depthMM: 230,
    heightMM: 870,
    widthMM: 2158,
    volumeL: 80,
    nominalDeltaT50KW: 4.72,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 3693,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-870-33",
    rangeType: "Emmeline III",
    model: "Emmeline III 870mm",
    depthMM: 230,
    heightMM: 870,
    widthMM: 2225,
    volumeL: 82.5,
    nominalDeltaT50KW: 4.868,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 3802,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-870-34",
    rangeType: "Emmeline III",
    model: "Emmeline III 870mm",
    depthMM: 230,
    heightMM: 870,
    widthMM: 2291,
    volumeL: 85,
    nominalDeltaT50KW: 5.015,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 3911,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-870-35",
    rangeType: "Emmeline III",
    model: "Emmeline III 870mm",
    depthMM: 230,
    heightMM: 870,
    widthMM: 2358,
    volumeL: 87.5,
    nominalDeltaT50KW: 5.163,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 4020,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-870-36",
    rangeType: "Emmeline III",
    model: "Emmeline III 870mm",
    depthMM: 230,
    heightMM: 870,
    widthMM: 2424,
    volumeL: 90,
    nominalDeltaT50KW: 5.31,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 4129,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-870-37",
    rangeType: "Emmeline III",
    model: "Emmeline III 870mm",
    depthMM: 230,
    heightMM: 870,
    widthMM: 2491,
    volumeL: 92.5,
    nominalDeltaT50KW: 5.458,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 4238,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-870-38",
    rangeType: "Emmeline III",
    model: "Emmeline III 870mm",
    depthMM: 230,
    heightMM: 870,
    widthMM: 2557,
    volumeL: 95,
    nominalDeltaT50KW: 5.605,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 4347,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-870-39",
    rangeType: "Emmeline III",
    model: "Emmeline III 870mm",
    depthMM: 230,
    heightMM: 870,
    widthMM: 2624,
    volumeL: 97.5,
    nominalDeltaT50KW: 5.753,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 4456,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-EML3-870-40",
    rangeType: "Emmeline III",
    model: "Emmeline III 870mm",
    depthMM: 230,
    heightMM: 870,
    widthMM: 2690,
    volumeL: 100,
    nominalDeltaT50KW: 5.9,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 4565,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1002-04",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1000mm",
    depthMM: 67,
    heightMM: 1102,
    widthMM: 210,
    volumeL: 4,
    nominalDeltaT50KW: 0.277,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 229,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1002-05",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1000mm",
    depthMM: 67,
    heightMM: 1102,
    widthMM: 255,
    volumeL: 5,
    nominalDeltaT50KW: 0.346,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 258,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1002-06",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1000mm",
    depthMM: 67,
    heightMM: 1102,
    widthMM: 300,
    volumeL: 6,
    nominalDeltaT50KW: 0.415,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 286,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1002-07",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1000mm",
    depthMM: 67,
    heightMM: 1102,
    widthMM: 345,
    volumeL: 7,
    nominalDeltaT50KW: 0.484,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 315,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1002-08",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1000mm",
    depthMM: 67,
    heightMM: 1102,
    widthMM: 390,
    volumeL: 8,
    nominalDeltaT50KW: 0.554,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 343,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1002-09",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1000mm",
    depthMM: 67,
    heightMM: 1102,
    widthMM: 435,
    volumeL: 9,
    nominalDeltaT50KW: 0.623,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 372,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1002-10",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1000mm",
    depthMM: 67,
    heightMM: 1102,
    widthMM: 480,
    volumeL: 10,
    nominalDeltaT50KW: 0.692,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 400,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1002-11",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1000mm",
    depthMM: 67,
    heightMM: 1102,
    widthMM: 525,
    volumeL: 11,
    nominalDeltaT50KW: 0.761,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 429,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1002-12",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1000mm",
    depthMM: 67,
    heightMM: 1102,
    widthMM: 570,
    volumeL: 12,
    nominalDeltaT50KW: 0.83,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 457,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1002-13",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1000mm",
    depthMM: 67,
    heightMM: 1102,
    widthMM: 615,
    volumeL: 13,
    nominalDeltaT50KW: 0.9,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 486,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1002-14",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1000mm",
    depthMM: 67,
    heightMM: 1102,
    widthMM: 660,
    volumeL: 14,
    nominalDeltaT50KW: 0.969,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 514,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1002-15",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1000mm",
    depthMM: 67,
    heightMM: 1102,
    widthMM: 705,
    volumeL: 15,
    nominalDeltaT50KW: 1.038,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 543,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1002-16",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1000mm",
    depthMM: 67,
    heightMM: 1102,
    widthMM: 750,
    volumeL: 16,
    nominalDeltaT50KW: 1.107,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 571,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1002-17",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1000mm",
    depthMM: 67,
    heightMM: 1102,
    widthMM: 795,
    volumeL: 17,
    nominalDeltaT50KW: 1.176,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 600,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1002-18",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1000mm",
    depthMM: 67,
    heightMM: 1102,
    widthMM: 840,
    volumeL: 18,
    nominalDeltaT50KW: 1.246,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 628,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1002-19",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1000mm",
    depthMM: 67,
    heightMM: 1102,
    widthMM: 885,
    volumeL: 19,
    nominalDeltaT50KW: 1.315,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 657,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1002-20",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1000mm",
    depthMM: 67,
    heightMM: 1102,
    widthMM: 930,
    volumeL: 20,
    nominalDeltaT50KW: 1.384,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 685,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1002-21",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1000mm",
    depthMM: 67,
    heightMM: 1102,
    widthMM: 975,
    volumeL: 21,
    nominalDeltaT50KW: 1.453,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 714,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1002-22",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1000mm",
    depthMM: 67,
    heightMM: 1102,
    widthMM: 1020,
    volumeL: 22,
    nominalDeltaT50KW: 1.522,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 742,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1002-23",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1000mm",
    depthMM: 67,
    heightMM: 1102,
    widthMM: 1065,
    volumeL: 23,
    nominalDeltaT50KW: 1.592,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 771,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1002-24",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1000mm",
    depthMM: 67,
    heightMM: 1102,
    widthMM: 1110,
    volumeL: 24,
    nominalDeltaT50KW: 1.661,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 799,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1002-25",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1000mm",
    depthMM: 67,
    heightMM: 1102,
    widthMM: 1155,
    volumeL: 25,
    nominalDeltaT50KW: 1.73,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 828,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1002-26",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1000mm",
    depthMM: 67,
    heightMM: 1102,
    widthMM: 1200,
    volumeL: 26,
    nominalDeltaT50KW: 1.799,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 856,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1002-27",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1000mm",
    depthMM: 67,
    heightMM: 1102,
    widthMM: 1245,
    volumeL: 27,
    nominalDeltaT50KW: 1.868,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 885,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1002-28",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1000mm",
    depthMM: 67,
    heightMM: 1102,
    widthMM: 1290,
    volumeL: 28,
    nominalDeltaT50KW: 1.938,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 913,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1002-29",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1000mm",
    depthMM: 67,
    heightMM: 1102,
    widthMM: 1335,
    volumeL: 29,
    nominalDeltaT50KW: 2.007,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 942,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1002-30",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1000mm",
    depthMM: 67,
    heightMM: 1102,
    widthMM: 1380,
    volumeL: 30,
    nominalDeltaT50KW: 2.076,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 970,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1002-31",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1000mm",
    depthMM: 67,
    heightMM: 1102,
    widthMM: 1425,
    volumeL: 31,
    nominalDeltaT50KW: 2.145,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 999,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1002-32",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1000mm",
    depthMM: 67,
    heightMM: 1102,
    widthMM: 1470,
    volumeL: 32,
    nominalDeltaT50KW: 2.214,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1027,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1002-33",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1000mm",
    depthMM: 67,
    heightMM: 1102,
    widthMM: 1515,
    volumeL: 33,
    nominalDeltaT50KW: 2.284,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1056,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1002-34",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1000mm",
    depthMM: 67,
    heightMM: 1102,
    widthMM: 1560,
    volumeL: 34,
    nominalDeltaT50KW: 2.353,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1084,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1002-35",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1000mm",
    depthMM: 67,
    heightMM: 1102,
    widthMM: 1605,
    volumeL: 35,
    nominalDeltaT50KW: 2.422,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1113,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1002-36",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1000mm",
    depthMM: 67,
    heightMM: 1102,
    widthMM: 1650,
    volumeL: 36,
    nominalDeltaT50KW: 2.491,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1141,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1002-37",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1000mm",
    depthMM: 67,
    heightMM: 1102,
    widthMM: 1695,
    volumeL: 37,
    nominalDeltaT50KW: 2.56,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1170,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1002-38",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1000mm",
    depthMM: 67,
    heightMM: 1102,
    widthMM: 1740,
    volumeL: 38,
    nominalDeltaT50KW: 2.63,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1198,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1002-39",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1000mm",
    depthMM: 67,
    heightMM: 1102,
    widthMM: 1785,
    volumeL: 39,
    nominalDeltaT50KW: 2.699,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1227,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1002-40",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1000mm",
    depthMM: 67,
    heightMM: 1102,
    widthMM: 1830,
    volumeL: 40,
    nominalDeltaT50KW: 2.768,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1255,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1202-04",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1200mm",
    depthMM: 67,
    heightMM: 1302,
    widthMM: 210,
    volumeL: 4.4,
    nominalDeltaT50KW: 0.33,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 269,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1202-05",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1200mm",
    depthMM: 67,
    heightMM: 1302,
    widthMM: 255,
    volumeL: 5.5,
    nominalDeltaT50KW: 0.413,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 308,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1202-06",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1200mm",
    depthMM: 67,
    heightMM: 1302,
    widthMM: 300,
    volumeL: 6.6,
    nominalDeltaT50KW: 0.495,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 346,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1202-07",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1200mm",
    depthMM: 67,
    heightMM: 1302,
    widthMM: 345,
    volumeL: 7.7,
    nominalDeltaT50KW: 0.578,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 385,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1202-08",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1200mm",
    depthMM: 67,
    heightMM: 1302,
    widthMM: 390,
    volumeL: 8.8,
    nominalDeltaT50KW: 0.66,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 423,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1202-09",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1200mm",
    depthMM: 67,
    heightMM: 1302,
    widthMM: 435,
    volumeL: 9.9,
    nominalDeltaT50KW: 0.743,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 462,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1202-10",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1200mm",
    depthMM: 67,
    heightMM: 1302,
    widthMM: 480,
    volumeL: 11,
    nominalDeltaT50KW: 0.825,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 500,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1202-11",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1200mm",
    depthMM: 67,
    heightMM: 1302,
    widthMM: 525,
    volumeL: 12.1,
    nominalDeltaT50KW: 0.908,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 539,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1202-12",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1200mm",
    depthMM: 67,
    heightMM: 1302,
    widthMM: 570,
    volumeL: 13.2,
    nominalDeltaT50KW: 0.99,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 577,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1202-13",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1200mm",
    depthMM: 67,
    heightMM: 1302,
    widthMM: 615,
    volumeL: 14.3,
    nominalDeltaT50KW: 1.073,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 616,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1202-14",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1200mm",
    depthMM: 67,
    heightMM: 1302,
    widthMM: 660,
    volumeL: 15.4,
    nominalDeltaT50KW: 1.155,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 654,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1202-15",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1200mm",
    depthMM: 67,
    heightMM: 1302,
    widthMM: 705,
    volumeL: 16.5,
    nominalDeltaT50KW: 1.238,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 693,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1202-16",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1200mm",
    depthMM: 67,
    heightMM: 1302,
    widthMM: 750,
    volumeL: 17.6,
    nominalDeltaT50KW: 1.32,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 731,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1202-17",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1200mm",
    depthMM: 67,
    heightMM: 1302,
    widthMM: 795,
    volumeL: 18.7,
    nominalDeltaT50KW: 1.403,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 770,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1202-18",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1200mm",
    depthMM: 67,
    heightMM: 1302,
    widthMM: 840,
    volumeL: 19.8,
    nominalDeltaT50KW: 1.485,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 808,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1202-19",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1200mm",
    depthMM: 67,
    heightMM: 1302,
    widthMM: 885,
    volumeL: 20.9,
    nominalDeltaT50KW: 1.568,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 847,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1202-20",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1200mm",
    depthMM: 67,
    heightMM: 1302,
    widthMM: 930,
    volumeL: 22,
    nominalDeltaT50KW: 1.65,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 885,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1202-21",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1200mm",
    depthMM: 67,
    heightMM: 1302,
    widthMM: 975,
    volumeL: 23.1,
    nominalDeltaT50KW: 1.733,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 924,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1202-22",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1200mm",
    depthMM: 67,
    heightMM: 1302,
    widthMM: 1020,
    volumeL: 24.2,
    nominalDeltaT50KW: 1.815,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 962,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1202-23",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1200mm",
    depthMM: 67,
    heightMM: 1302,
    widthMM: 1065,
    volumeL: 25.3,
    nominalDeltaT50KW: 1.898,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1001,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1202-24",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1200mm",
    depthMM: 67,
    heightMM: 1302,
    widthMM: 1110,
    volumeL: 26.4,
    nominalDeltaT50KW: 1.98,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1039,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1202-25",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1200mm",
    depthMM: 67,
    heightMM: 1302,
    widthMM: 1155,
    volumeL: 27.5,
    nominalDeltaT50KW: 2.063,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1078,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1202-26",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1200mm",
    depthMM: 67,
    heightMM: 1302,
    widthMM: 1200,
    volumeL: 28.6,
    nominalDeltaT50KW: 2.145,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1116,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1202-27",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1200mm",
    depthMM: 67,
    heightMM: 1302,
    widthMM: 1245,
    volumeL: 29.7,
    nominalDeltaT50KW: 2.228,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1155,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1202-28",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1200mm",
    depthMM: 67,
    heightMM: 1302,
    widthMM: 1290,
    volumeL: 30.8,
    nominalDeltaT50KW: 2.31,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1193,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1202-29",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1200mm",
    depthMM: 67,
    heightMM: 1302,
    widthMM: 1335,
    volumeL: 31.9,
    nominalDeltaT50KW: 2.393,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1232,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1202-30",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1200mm",
    depthMM: 67,
    heightMM: 1302,
    widthMM: 1380,
    volumeL: 33,
    nominalDeltaT50KW: 2.475,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1270,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1202-31",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1200mm",
    depthMM: 67,
    heightMM: 1302,
    widthMM: 1425,
    volumeL: 34.1,
    nominalDeltaT50KW: 2.558,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1309,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1202-32",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1200mm",
    depthMM: 67,
    heightMM: 1302,
    widthMM: 1470,
    volumeL: 35.2,
    nominalDeltaT50KW: 2.64,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1347,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1202-33",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1200mm",
    depthMM: 67,
    heightMM: 1302,
    widthMM: 1515,
    volumeL: 36.3,
    nominalDeltaT50KW: 2.723,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1386,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1202-34",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1200mm",
    depthMM: 67,
    heightMM: 1302,
    widthMM: 1560,
    volumeL: 37.4,
    nominalDeltaT50KW: 2.805,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1424,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1202-35",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1200mm",
    depthMM: 67,
    heightMM: 1302,
    widthMM: 1605,
    volumeL: 38.5,
    nominalDeltaT50KW: 2.888,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1463,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1202-36",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1200mm",
    depthMM: 67,
    heightMM: 1302,
    widthMM: 1650,
    volumeL: 39.6,
    nominalDeltaT50KW: 2.97,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1501,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1202-37",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1200mm",
    depthMM: 67,
    heightMM: 1302,
    widthMM: 1695,
    volumeL: 40.7,
    nominalDeltaT50KW: 3.053,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1540,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1202-38",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1200mm",
    depthMM: 67,
    heightMM: 1302,
    widthMM: 1740,
    volumeL: 41.8,
    nominalDeltaT50KW: 3.135,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1578,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1202-39",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1200mm",
    depthMM: 67,
    heightMM: 1302,
    widthMM: 1785,
    volumeL: 42.9,
    nominalDeltaT50KW: 3.218,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1617,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1202-40",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1200mm",
    depthMM: 67,
    heightMM: 1302,
    widthMM: 1830,
    volumeL: 44,
    nominalDeltaT50KW: 3.3,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1655,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1502-04",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1500mm",
    depthMM: 67,
    heightMM: 1602,
    widthMM: 210,
    volumeL: 5.6,
    nominalDeltaT50KW: 0.412,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 309,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1502-05",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1500mm",
    depthMM: 67,
    heightMM: 1602,
    widthMM: 255,
    volumeL: 7,
    nominalDeltaT50KW: 0.515,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 358,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1502-06",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1500mm",
    depthMM: 67,
    heightMM: 1602,
    widthMM: 300,
    volumeL: 8.4,
    nominalDeltaT50KW: 0.618,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 406,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1502-07",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1500mm",
    depthMM: 67,
    heightMM: 1602,
    widthMM: 345,
    volumeL: 9.8,
    nominalDeltaT50KW: 0.721,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 455,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1502-08",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1500mm",
    depthMM: 67,
    heightMM: 1602,
    widthMM: 390,
    volumeL: 11.2,
    nominalDeltaT50KW: 0.824,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 503,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1502-09",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1500mm",
    depthMM: 67,
    heightMM: 1602,
    widthMM: 435,
    volumeL: 12.6,
    nominalDeltaT50KW: 0.927,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 552,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1502-10",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1500mm",
    depthMM: 67,
    heightMM: 1602,
    widthMM: 480,
    volumeL: 14,
    nominalDeltaT50KW: 1.03,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 600,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1502-11",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1500mm",
    depthMM: 67,
    heightMM: 1602,
    widthMM: 525,
    volumeL: 15.4,
    nominalDeltaT50KW: 1.133,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 649,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1502-12",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1500mm",
    depthMM: 67,
    heightMM: 1602,
    widthMM: 570,
    volumeL: 16.8,
    nominalDeltaT50KW: 1.236,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 697,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1502-13",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1500mm",
    depthMM: 67,
    heightMM: 1602,
    widthMM: 615,
    volumeL: 18.2,
    nominalDeltaT50KW: 1.339,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 746,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1502-14",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1500mm",
    depthMM: 67,
    heightMM: 1602,
    widthMM: 660,
    volumeL: 19.6,
    nominalDeltaT50KW: 1.442,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 794,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1502-15",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1500mm",
    depthMM: 67,
    heightMM: 1602,
    widthMM: 705,
    volumeL: 21,
    nominalDeltaT50KW: 1.545,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 843,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1502-16",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1500mm",
    depthMM: 67,
    heightMM: 1602,
    widthMM: 750,
    volumeL: 22.4,
    nominalDeltaT50KW: 1.648,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 891,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1502-17",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1500mm",
    depthMM: 67,
    heightMM: 1602,
    widthMM: 795,
    volumeL: 23.8,
    nominalDeltaT50KW: 1.751,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 940,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1502-18",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1500mm",
    depthMM: 67,
    heightMM: 1602,
    widthMM: 840,
    volumeL: 25.2,
    nominalDeltaT50KW: 1.854,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 988,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1502-19",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1500mm",
    depthMM: 67,
    heightMM: 1602,
    widthMM: 885,
    volumeL: 26.6,
    nominalDeltaT50KW: 1.957,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1037,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1502-20",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1500mm",
    depthMM: 67,
    heightMM: 1602,
    widthMM: 930,
    volumeL: 28,
    nominalDeltaT50KW: 2.06,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1085,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1502-21",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1500mm",
    depthMM: 67,
    heightMM: 1602,
    widthMM: 975,
    volumeL: 29.4,
    nominalDeltaT50KW: 2.163,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1134,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1502-22",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1500mm",
    depthMM: 67,
    heightMM: 1602,
    widthMM: 1020,
    volumeL: 30.8,
    nominalDeltaT50KW: 2.266,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1182,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1502-23",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1500mm",
    depthMM: 67,
    heightMM: 1602,
    widthMM: 1065,
    volumeL: 32.2,
    nominalDeltaT50KW: 2.369,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1231,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1502-24",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1500mm",
    depthMM: 67,
    heightMM: 1602,
    widthMM: 1110,
    volumeL: 33.6,
    nominalDeltaT50KW: 2.472,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1279,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1502-25",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1500mm",
    depthMM: 67,
    heightMM: 1602,
    widthMM: 1155,
    volumeL: 35,
    nominalDeltaT50KW: 2.575,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1328,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1502-26",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1500mm",
    depthMM: 67,
    heightMM: 1602,
    widthMM: 1200,
    volumeL: 36.4,
    nominalDeltaT50KW: 2.678,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1376,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1502-27",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1500mm",
    depthMM: 67,
    heightMM: 1602,
    widthMM: 1245,
    volumeL: 37.8,
    nominalDeltaT50KW: 2.781,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1425,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1502-28",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1500mm",
    depthMM: 67,
    heightMM: 1602,
    widthMM: 1290,
    volumeL: 39.2,
    nominalDeltaT50KW: 2.884,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1473,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1502-29",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1500mm",
    depthMM: 67,
    heightMM: 1602,
    widthMM: 1335,
    volumeL: 40.6,
    nominalDeltaT50KW: 2.987,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1522,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1502-30",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1500mm",
    depthMM: 67,
    heightMM: 1602,
    widthMM: 1380,
    volumeL: 42,
    nominalDeltaT50KW: 3.09,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1570,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1502-31",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1500mm",
    depthMM: 67,
    heightMM: 1602,
    widthMM: 1425,
    volumeL: 43.4,
    nominalDeltaT50KW: 3.193,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1619,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1502-32",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1500mm",
    depthMM: 67,
    heightMM: 1602,
    widthMM: 1470,
    volumeL: 44.8,
    nominalDeltaT50KW: 3.296,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1667,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1502-33",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1500mm",
    depthMM: 67,
    heightMM: 1602,
    widthMM: 1515,
    volumeL: 46.2,
    nominalDeltaT50KW: 3.399,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1716,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1502-34",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1500mm",
    depthMM: 67,
    heightMM: 1602,
    widthMM: 1560,
    volumeL: 47.6,
    nominalDeltaT50KW: 3.502,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1764,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1502-35",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1500mm",
    depthMM: 67,
    heightMM: 1602,
    widthMM: 1605,
    volumeL: 49,
    nominalDeltaT50KW: 3.605,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1813,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1502-36",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1500mm",
    depthMM: 67,
    heightMM: 1602,
    widthMM: 1650,
    volumeL: 50.4,
    nominalDeltaT50KW: 3.708,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1861,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1502-37",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1500mm",
    depthMM: 67,
    heightMM: 1602,
    widthMM: 1695,
    volumeL: 51.8,
    nominalDeltaT50KW: 3.811,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1910,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1502-38",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1500mm",
    depthMM: 67,
    heightMM: 1602,
    widthMM: 1740,
    volumeL: 53.2,
    nominalDeltaT50KW: 3.914,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1958,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1502-39",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1500mm",
    depthMM: 67,
    heightMM: 1602,
    widthMM: 1785,
    volumeL: 54.6,
    nominalDeltaT50KW: 4.017,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2007,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1502-40",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1500mm",
    depthMM: 67,
    heightMM: 1602,
    widthMM: 1830,
    volumeL: 56,
    nominalDeltaT50KW: 4.12,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2055,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1802-04",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1800mm",
    depthMM: 67,
    heightMM: 1902,
    widthMM: 210,
    volumeL: 6.56,
    nominalDeltaT50KW: 0.497,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 329,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1802-05",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1800mm",
    depthMM: 67,
    heightMM: 1902,
    widthMM: 255,
    volumeL: 8.2,
    nominalDeltaT50KW: 0.622,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 383,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1802-06",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1800mm",
    depthMM: 67,
    heightMM: 1902,
    widthMM: 300,
    volumeL: 9.84,
    nominalDeltaT50KW: 0.746,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 436,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1802-07",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1800mm",
    depthMM: 67,
    heightMM: 1902,
    widthMM: 345,
    volumeL: 11.48,
    nominalDeltaT50KW: 0.87,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 490,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1802-08",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1800mm",
    depthMM: 67,
    heightMM: 1902,
    widthMM: 390,
    volumeL: 13.12,
    nominalDeltaT50KW: 0.994,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 543,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1802-09",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1800mm",
    depthMM: 67,
    heightMM: 1902,
    widthMM: 435,
    volumeL: 14.76,
    nominalDeltaT50KW: 1.119,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 597,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1802-10",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1800mm",
    depthMM: 67,
    heightMM: 1902,
    widthMM: 480,
    volumeL: 16.4,
    nominalDeltaT50KW: 1.243,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 650,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1802-11",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1800mm",
    depthMM: 67,
    heightMM: 1902,
    widthMM: 525,
    volumeL: 18.04,
    nominalDeltaT50KW: 1.367,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 704,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1802-12",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1800mm",
    depthMM: 67,
    heightMM: 1902,
    widthMM: 570,
    volumeL: 19.68,
    nominalDeltaT50KW: 1.492,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 757,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1802-13",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1800mm",
    depthMM: 67,
    heightMM: 1902,
    widthMM: 615,
    volumeL: 21.32,
    nominalDeltaT50KW: 1.616,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 811,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1802-14",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1800mm",
    depthMM: 67,
    heightMM: 1902,
    widthMM: 660,
    volumeL: 22.96,
    nominalDeltaT50KW: 1.74,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 864,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1802-15",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1800mm",
    depthMM: 67,
    heightMM: 1902,
    widthMM: 705,
    volumeL: 24.6,
    nominalDeltaT50KW: 1.865,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 918,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1802-16",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1800mm",
    depthMM: 67,
    heightMM: 1902,
    widthMM: 750,
    volumeL: 26.24,
    nominalDeltaT50KW: 1.989,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 971,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1802-17",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1800mm",
    depthMM: 67,
    heightMM: 1902,
    widthMM: 795,
    volumeL: 27.88,
    nominalDeltaT50KW: 2.113,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1025,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1802-18",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1800mm",
    depthMM: 67,
    heightMM: 1902,
    widthMM: 840,
    volumeL: 29.52,
    nominalDeltaT50KW: 2.237,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1078,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1802-19",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1800mm",
    depthMM: 67,
    heightMM: 1902,
    widthMM: 885,
    volumeL: 31.16,
    nominalDeltaT50KW: 2.362,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1132,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1802-20",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1800mm",
    depthMM: 67,
    heightMM: 1902,
    widthMM: 930,
    volumeL: 32.8,
    nominalDeltaT50KW: 2.486,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1185,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1802-21",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1800mm",
    depthMM: 67,
    heightMM: 1902,
    widthMM: 975,
    volumeL: 34.44,
    nominalDeltaT50KW: 2.61,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1239,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1802-22",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1800mm",
    depthMM: 67,
    heightMM: 1902,
    widthMM: 1020,
    volumeL: 36.08,
    nominalDeltaT50KW: 2.735,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1292,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1802-23",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1800mm",
    depthMM: 67,
    heightMM: 1902,
    widthMM: 1065,
    volumeL: 37.72,
    nominalDeltaT50KW: 2.859,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1346,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1802-24",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1800mm",
    depthMM: 67,
    heightMM: 1902,
    widthMM: 1110,
    volumeL: 39.36,
    nominalDeltaT50KW: 2.983,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1399,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1802-25",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1800mm",
    depthMM: 67,
    heightMM: 1902,
    widthMM: 1155,
    volumeL: 41,
    nominalDeltaT50KW: 3.108,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1453,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1802-26",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1800mm",
    depthMM: 67,
    heightMM: 1902,
    widthMM: 1200,
    volumeL: 42.64,
    nominalDeltaT50KW: 3.232,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1506,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1802-27",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1800mm",
    depthMM: 67,
    heightMM: 1902,
    widthMM: 1245,
    volumeL: 44.28,
    nominalDeltaT50KW: 3.356,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1560,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1802-28",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1800mm",
    depthMM: 67,
    heightMM: 1902,
    widthMM: 1290,
    volumeL: 45.92,
    nominalDeltaT50KW: 3.48,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1613,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1802-29",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1800mm",
    depthMM: 67,
    heightMM: 1902,
    widthMM: 1335,
    volumeL: 47.56,
    nominalDeltaT50KW: 3.605,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1667,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1802-30",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1800mm",
    depthMM: 67,
    heightMM: 1902,
    widthMM: 1380,
    volumeL: 49.2,
    nominalDeltaT50KW: 3.729,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1720,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1802-31",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1800mm",
    depthMM: 67,
    heightMM: 1902,
    widthMM: 1425,
    volumeL: 50.84,
    nominalDeltaT50KW: 3.853,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1774,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1802-32",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1800mm",
    depthMM: 67,
    heightMM: 1902,
    widthMM: 1470,
    volumeL: 52.48,
    nominalDeltaT50KW: 3.978,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1827,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1802-33",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1800mm",
    depthMM: 67,
    heightMM: 1902,
    widthMM: 1515,
    volumeL: 54.12,
    nominalDeltaT50KW: 4.102,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1881,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1802-34",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1800mm",
    depthMM: 67,
    heightMM: 1902,
    widthMM: 1560,
    volumeL: 55.76,
    nominalDeltaT50KW: 4.226,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1934,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1802-35",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1800mm",
    depthMM: 67,
    heightMM: 1902,
    widthMM: 1605,
    volumeL: 57.4,
    nominalDeltaT50KW: 4.351,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1988,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1802-36",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1800mm",
    depthMM: 67,
    heightMM: 1902,
    widthMM: 1650,
    volumeL: 59.04,
    nominalDeltaT50KW: 4.475,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2041,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1802-37",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1800mm",
    depthMM: 67,
    heightMM: 1902,
    widthMM: 1695,
    volumeL: 60.68,
    nominalDeltaT50KW: 4.599,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2095,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1802-38",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1800mm",
    depthMM: 67,
    heightMM: 1902,
    widthMM: 1740,
    volumeL: 62.32,
    nominalDeltaT50KW: 4.723,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2148,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1802-39",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1800mm",
    depthMM: 67,
    heightMM: 1902,
    widthMM: 1785,
    volumeL: 63.96,
    nominalDeltaT50KW: 4.848,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2202,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-1802-40",
    rangeType: "Florence 2",
    model: "Florence 2 Column 1800mm",
    depthMM: 67,
    heightMM: 1902,
    widthMM: 1830,
    volumeL: 65.6,
    nominalDeltaT50KW: 4.972,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2255,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2002-04",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2000mm",
    depthMM: 67,
    heightMM: 2102,
    widthMM: 210,
    volumeL: 7.2,
    nominalDeltaT50KW: 0.556,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 349,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2002-05",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2000mm",
    depthMM: 67,
    heightMM: 2102,
    widthMM: 255,
    volumeL: 9,
    nominalDeltaT50KW: 0.695,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 408,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2002-06",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2000mm",
    depthMM: 67,
    heightMM: 2102,
    widthMM: 300,
    volumeL: 10.8,
    nominalDeltaT50KW: 0.834,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 466,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2002-07",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2000mm",
    depthMM: 67,
    heightMM: 2102,
    widthMM: 345,
    volumeL: 12.6,
    nominalDeltaT50KW: 0.973,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 525,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2002-08",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2000mm",
    depthMM: 67,
    heightMM: 2102,
    widthMM: 390,
    volumeL: 14.4,
    nominalDeltaT50KW: 1.112,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 583,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2002-09",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2000mm",
    depthMM: 67,
    heightMM: 2102,
    widthMM: 435,
    volumeL: 16.2,
    nominalDeltaT50KW: 1.251,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 642,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2002-10",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2000mm",
    depthMM: 67,
    heightMM: 2102,
    widthMM: 480,
    volumeL: 18,
    nominalDeltaT50KW: 1.39,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 700,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2002-11",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2000mm",
    depthMM: 67,
    heightMM: 2102,
    widthMM: 525,
    volumeL: 19.8,
    nominalDeltaT50KW: 1.529,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 759,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2002-12",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2000mm",
    depthMM: 67,
    heightMM: 2102,
    widthMM: 570,
    volumeL: 21.6,
    nominalDeltaT50KW: 1.668,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 817,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2002-13",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2000mm",
    depthMM: 67,
    heightMM: 2102,
    widthMM: 615,
    volumeL: 23.4,
    nominalDeltaT50KW: 1.807,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 876,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2002-14",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2000mm",
    depthMM: 67,
    heightMM: 2102,
    widthMM: 660,
    volumeL: 25.2,
    nominalDeltaT50KW: 1.946,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 934,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2002-15",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2000mm",
    depthMM: 67,
    heightMM: 2102,
    widthMM: 705,
    volumeL: 27,
    nominalDeltaT50KW: 2.085,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 993,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2002-16",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2000mm",
    depthMM: 67,
    heightMM: 2102,
    widthMM: 750,
    volumeL: 28.8,
    nominalDeltaT50KW: 2.224,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1051,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2002-17",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2000mm",
    depthMM: 67,
    heightMM: 2102,
    widthMM: 795,
    volumeL: 30.6,
    nominalDeltaT50KW: 2.363,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1110,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2002-18",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2000mm",
    depthMM: 67,
    heightMM: 2102,
    widthMM: 840,
    volumeL: 32.4,
    nominalDeltaT50KW: 2.502,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1168,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2002-19",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2000mm",
    depthMM: 67,
    heightMM: 2102,
    widthMM: 885,
    volumeL: 34.2,
    nominalDeltaT50KW: 2.641,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1227,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2002-20",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2000mm",
    depthMM: 67,
    heightMM: 2102,
    widthMM: 930,
    volumeL: 36,
    nominalDeltaT50KW: 2.78,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1285,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2002-21",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2000mm",
    depthMM: 67,
    heightMM: 2102,
    widthMM: 975,
    volumeL: 37.8,
    nominalDeltaT50KW: 2.919,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1344,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2002-22",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2000mm",
    depthMM: 67,
    heightMM: 2102,
    widthMM: 1020,
    volumeL: 39.6,
    nominalDeltaT50KW: 3.058,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1402,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2002-23",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2000mm",
    depthMM: 67,
    heightMM: 2102,
    widthMM: 1065,
    volumeL: 41.4,
    nominalDeltaT50KW: 3.197,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1461,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2002-24",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2000mm",
    depthMM: 67,
    heightMM: 2102,
    widthMM: 1110,
    volumeL: 43.2,
    nominalDeltaT50KW: 3.336,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1519,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2002-25",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2000mm",
    depthMM: 67,
    heightMM: 2102,
    widthMM: 1155,
    volumeL: 45,
    nominalDeltaT50KW: 3.475,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1578,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2002-26",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2000mm",
    depthMM: 67,
    heightMM: 2102,
    widthMM: 1200,
    volumeL: 46.8,
    nominalDeltaT50KW: 3.614,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1636,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2002-27",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2000mm",
    depthMM: 67,
    heightMM: 2102,
    widthMM: 1245,
    volumeL: 48.6,
    nominalDeltaT50KW: 3.753,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1695,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2002-28",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2000mm",
    depthMM: 67,
    heightMM: 2102,
    widthMM: 1290,
    volumeL: 50.4,
    nominalDeltaT50KW: 3.892,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1753,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2002-29",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2000mm",
    depthMM: 67,
    heightMM: 2102,
    widthMM: 1335,
    volumeL: 52.2,
    nominalDeltaT50KW: 4.031,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1812,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2002-30",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2000mm",
    depthMM: 67,
    heightMM: 2102,
    widthMM: 1380,
    volumeL: 54,
    nominalDeltaT50KW: 4.17,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1870,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2002-31",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2000mm",
    depthMM: 67,
    heightMM: 2102,
    widthMM: 1425,
    volumeL: 55.8,
    nominalDeltaT50KW: 4.309,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1929,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2002-32",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2000mm",
    depthMM: 67,
    heightMM: 2102,
    widthMM: 1470,
    volumeL: 57.6,
    nominalDeltaT50KW: 4.448,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1987,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2002-33",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2000mm",
    depthMM: 67,
    heightMM: 2102,
    widthMM: 1515,
    volumeL: 59.4,
    nominalDeltaT50KW: 4.587,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2046,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2002-34",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2000mm",
    depthMM: 67,
    heightMM: 2102,
    widthMM: 1560,
    volumeL: 61.2,
    nominalDeltaT50KW: 4.726,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2104,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2002-35",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2000mm",
    depthMM: 67,
    heightMM: 2102,
    widthMM: 1605,
    volumeL: 63,
    nominalDeltaT50KW: 4.865,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2163,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2002-36",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2000mm",
    depthMM: 67,
    heightMM: 2102,
    widthMM: 1650,
    volumeL: 64.8,
    nominalDeltaT50KW: 5.004,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2221,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2002-37",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2000mm",
    depthMM: 67,
    heightMM: 2102,
    widthMM: 1695,
    volumeL: 66.6,
    nominalDeltaT50KW: 5.143,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2280,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2002-38",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2000mm",
    depthMM: 67,
    heightMM: 2102,
    widthMM: 1740,
    volumeL: 68.4,
    nominalDeltaT50KW: 5.282,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2338,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2002-39",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2000mm",
    depthMM: 67,
    heightMM: 2102,
    widthMM: 1785,
    volumeL: 70.2,
    nominalDeltaT50KW: 5.421,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2397,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2002-40",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2000mm",
    depthMM: 67,
    heightMM: 2102,
    widthMM: 1830,
    volumeL: 72,
    nominalDeltaT50KW: 5.56,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2455,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-194-04",
    rangeType: "Florence 2",
    model: "Florence 2 Column 200mm",
    depthMM: 67,
    heightMM: 294,
    widthMM: 210,
    volumeL: 1.2,
    nominalDeltaT50KW: 0.06,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 209,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-194-05",
    rangeType: "Florence 2",
    model: "Florence 2 Column 200mm",
    depthMM: 67,
    heightMM: 294,
    widthMM: 255,
    volumeL: 1.5,
    nominalDeltaT50KW: 0.075,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 233,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-194-06",
    rangeType: "Florence 2",
    model: "Florence 2 Column 200mm",
    depthMM: 67,
    heightMM: 294,
    widthMM: 300,
    volumeL: 1.8,
    nominalDeltaT50KW: 0.089,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 256,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-194-07",
    rangeType: "Florence 2",
    model: "Florence 2 Column 200mm",
    depthMM: 67,
    heightMM: 294,
    widthMM: 345,
    volumeL: 2.1,
    nominalDeltaT50KW: 0.104,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 280,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-194-08",
    rangeType: "Florence 2",
    model: "Florence 2 Column 200mm",
    depthMM: 67,
    heightMM: 294,
    widthMM: 390,
    volumeL: 2.4,
    nominalDeltaT50KW: 0.119,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 303,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-194-09",
    rangeType: "Florence 2",
    model: "Florence 2 Column 200mm",
    depthMM: 67,
    heightMM: 294,
    widthMM: 435,
    volumeL: 2.7,
    nominalDeltaT50KW: 0.134,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 327,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-194-10",
    rangeType: "Florence 2",
    model: "Florence 2 Column 200mm",
    depthMM: 67,
    heightMM: 294,
    widthMM: 480,
    volumeL: 3,
    nominalDeltaT50KW: 0.149,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 350,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-194-11",
    rangeType: "Florence 2",
    model: "Florence 2 Column 200mm",
    depthMM: 67,
    heightMM: 294,
    widthMM: 525,
    volumeL: 3.3,
    nominalDeltaT50KW: 0.164,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 374,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-194-12",
    rangeType: "Florence 2",
    model: "Florence 2 Column 200mm",
    depthMM: 67,
    heightMM: 294,
    widthMM: 570,
    volumeL: 3.6,
    nominalDeltaT50KW: 0.179,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 397,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-194-13",
    rangeType: "Florence 2",
    model: "Florence 2 Column 200mm",
    depthMM: 67,
    heightMM: 294,
    widthMM: 615,
    volumeL: 3.9,
    nominalDeltaT50KW: 0.194,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 421,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-194-14",
    rangeType: "Florence 2",
    model: "Florence 2 Column 200mm",
    depthMM: 67,
    heightMM: 294,
    widthMM: 660,
    volumeL: 4.2,
    nominalDeltaT50KW: 0.209,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 444,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-194-15",
    rangeType: "Florence 2",
    model: "Florence 2 Column 200mm",
    depthMM: 67,
    heightMM: 294,
    widthMM: 705,
    volumeL: 4.5,
    nominalDeltaT50KW: 0.224,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 468,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-194-16",
    rangeType: "Florence 2",
    model: "Florence 2 Column 200mm",
    depthMM: 67,
    heightMM: 294,
    widthMM: 750,
    volumeL: 4.8,
    nominalDeltaT50KW: 0.238,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 491,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-194-17",
    rangeType: "Florence 2",
    model: "Florence 2 Column 200mm",
    depthMM: 67,
    heightMM: 294,
    widthMM: 795,
    volumeL: 5.1,
    nominalDeltaT50KW: 0.253,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 515,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-194-18",
    rangeType: "Florence 2",
    model: "Florence 2 Column 200mm",
    depthMM: 67,
    heightMM: 294,
    widthMM: 840,
    volumeL: 5.4,
    nominalDeltaT50KW: 0.268,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 538,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-194-19",
    rangeType: "Florence 2",
    model: "Florence 2 Column 200mm",
    depthMM: 67,
    heightMM: 294,
    widthMM: 885,
    volumeL: 5.7,
    nominalDeltaT50KW: 0.283,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 562,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-194-20",
    rangeType: "Florence 2",
    model: "Florence 2 Column 200mm",
    depthMM: 67,
    heightMM: 294,
    widthMM: 930,
    volumeL: 6,
    nominalDeltaT50KW: 0.298,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 585,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-194-21",
    rangeType: "Florence 2",
    model: "Florence 2 Column 200mm",
    depthMM: 67,
    heightMM: 294,
    widthMM: 975,
    volumeL: 6.3,
    nominalDeltaT50KW: 0.313,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 609,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-194-22",
    rangeType: "Florence 2",
    model: "Florence 2 Column 200mm",
    depthMM: 67,
    heightMM: 294,
    widthMM: 1020,
    volumeL: 6.6,
    nominalDeltaT50KW: 0.328,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 632,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-194-23",
    rangeType: "Florence 2",
    model: "Florence 2 Column 200mm",
    depthMM: 67,
    heightMM: 294,
    widthMM: 1065,
    volumeL: 6.9,
    nominalDeltaT50KW: 0.343,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 656,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-194-24",
    rangeType: "Florence 2",
    model: "Florence 2 Column 200mm",
    depthMM: 67,
    heightMM: 294,
    widthMM: 1110,
    volumeL: 7.2,
    nominalDeltaT50KW: 0.358,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 679,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-194-25",
    rangeType: "Florence 2",
    model: "Florence 2 Column 200mm",
    depthMM: 67,
    heightMM: 294,
    widthMM: 1155,
    volumeL: 7.5,
    nominalDeltaT50KW: 0.373,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 703,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-194-26",
    rangeType: "Florence 2",
    model: "Florence 2 Column 200mm",
    depthMM: 67,
    heightMM: 294,
    widthMM: 1200,
    volumeL: 7.8,
    nominalDeltaT50KW: 0.387,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 726,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-194-27",
    rangeType: "Florence 2",
    model: "Florence 2 Column 200mm",
    depthMM: 67,
    heightMM: 294,
    widthMM: 1245,
    volumeL: 8.1,
    nominalDeltaT50KW: 0.402,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 750,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-194-28",
    rangeType: "Florence 2",
    model: "Florence 2 Column 200mm",
    depthMM: 67,
    heightMM: 294,
    widthMM: 1290,
    volumeL: 8.4,
    nominalDeltaT50KW: 0.417,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 773,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-194-29",
    rangeType: "Florence 2",
    model: "Florence 2 Column 200mm",
    depthMM: 67,
    heightMM: 294,
    widthMM: 1335,
    volumeL: 8.7,
    nominalDeltaT50KW: 0.432,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 797,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-194-30",
    rangeType: "Florence 2",
    model: "Florence 2 Column 200mm",
    depthMM: 67,
    heightMM: 294,
    widthMM: 1380,
    volumeL: 9,
    nominalDeltaT50KW: 0.447,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 820,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-194-31",
    rangeType: "Florence 2",
    model: "Florence 2 Column 200mm",
    depthMM: 67,
    heightMM: 294,
    widthMM: 1425,
    volumeL: 9.3,
    nominalDeltaT50KW: 0.462,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 844,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-194-32",
    rangeType: "Florence 2",
    model: "Florence 2 Column 200mm",
    depthMM: 67,
    heightMM: 294,
    widthMM: 1470,
    volumeL: 9.6,
    nominalDeltaT50KW: 0.477,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 867,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-194-33",
    rangeType: "Florence 2",
    model: "Florence 2 Column 200mm",
    depthMM: 67,
    heightMM: 294,
    widthMM: 1515,
    volumeL: 9.9,
    nominalDeltaT50KW: 0.492,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 891,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-194-34",
    rangeType: "Florence 2",
    model: "Florence 2 Column 200mm",
    depthMM: 67,
    heightMM: 294,
    widthMM: 1560,
    volumeL: 10.2,
    nominalDeltaT50KW: 0.507,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 914,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-194-35",
    rangeType: "Florence 2",
    model: "Florence 2 Column 200mm",
    depthMM: 67,
    heightMM: 294,
    widthMM: 1605,
    volumeL: 10.5,
    nominalDeltaT50KW: 0.522,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 938,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-194-36",
    rangeType: "Florence 2",
    model: "Florence 2 Column 200mm",
    depthMM: 67,
    heightMM: 294,
    widthMM: 1650,
    volumeL: 10.8,
    nominalDeltaT50KW: 0.536,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 961,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-194-37",
    rangeType: "Florence 2",
    model: "Florence 2 Column 200mm",
    depthMM: 67,
    heightMM: 294,
    widthMM: 1695,
    volumeL: 11.1,
    nominalDeltaT50KW: 0.551,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 985,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-194-38",
    rangeType: "Florence 2",
    model: "Florence 2 Column 200mm",
    depthMM: 67,
    heightMM: 294,
    widthMM: 1740,
    volumeL: 11.4,
    nominalDeltaT50KW: 0.566,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1008,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-194-39",
    rangeType: "Florence 2",
    model: "Florence 2 Column 200mm",
    depthMM: 67,
    heightMM: 294,
    widthMM: 1785,
    volumeL: 11.7,
    nominalDeltaT50KW: 0.581,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1032,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-194-40",
    rangeType: "Florence 2",
    model: "Florence 2 Column 200mm",
    depthMM: 67,
    heightMM: 294,
    widthMM: 1830,
    volumeL: 12,
    nominalDeltaT50KW: 0.596,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1055,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2202-04",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2200mm",
    depthMM: 67,
    heightMM: 2302,
    widthMM: 210,
    volumeL: 7.88,
    nominalDeltaT50KW: 0.617,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 393,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2202-05",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2200mm",
    depthMM: 67,
    heightMM: 2302,
    widthMM: 255,
    volumeL: 9.85,
    nominalDeltaT50KW: 0.771,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 463,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2202-06",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2200mm",
    depthMM: 67,
    heightMM: 2302,
    widthMM: 300,
    volumeL: 11.82,
    nominalDeltaT50KW: 0.925,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 532,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2202-07",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2200mm",
    depthMM: 67,
    heightMM: 2302,
    widthMM: 345,
    volumeL: 13.79,
    nominalDeltaT50KW: 1.079,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 602,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2202-08",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2200mm",
    depthMM: 67,
    heightMM: 2302,
    widthMM: 390,
    volumeL: 15.76,
    nominalDeltaT50KW: 1.234,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 671,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2202-09",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2200mm",
    depthMM: 67,
    heightMM: 2302,
    widthMM: 435,
    volumeL: 17.73,
    nominalDeltaT50KW: 1.388,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 741,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2202-10",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2200mm",
    depthMM: 67,
    heightMM: 2302,
    widthMM: 480,
    volumeL: 19.7,
    nominalDeltaT50KW: 1.542,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 810,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2202-11",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2200mm",
    depthMM: 67,
    heightMM: 2302,
    widthMM: 525,
    volumeL: 21.67,
    nominalDeltaT50KW: 1.696,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 880,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2202-12",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2200mm",
    depthMM: 67,
    heightMM: 2302,
    widthMM: 570,
    volumeL: 23.64,
    nominalDeltaT50KW: 1.85,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 949,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2202-13",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2200mm",
    depthMM: 67,
    heightMM: 2302,
    widthMM: 615,
    volumeL: 25.61,
    nominalDeltaT50KW: 2.005,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1019,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2202-14",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2200mm",
    depthMM: 67,
    heightMM: 2302,
    widthMM: 660,
    volumeL: 27.58,
    nominalDeltaT50KW: 2.159,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1088,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2202-15",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2200mm",
    depthMM: 67,
    heightMM: 2302,
    widthMM: 705,
    volumeL: 29.55,
    nominalDeltaT50KW: 2.313,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1158,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2202-16",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2200mm",
    depthMM: 67,
    heightMM: 2302,
    widthMM: 750,
    volumeL: 31.52,
    nominalDeltaT50KW: 2.467,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1227,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2202-17",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2200mm",
    depthMM: 67,
    heightMM: 2302,
    widthMM: 795,
    volumeL: 33.49,
    nominalDeltaT50KW: 2.621,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1297,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2202-18",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2200mm",
    depthMM: 67,
    heightMM: 2302,
    widthMM: 840,
    volumeL: 35.46,
    nominalDeltaT50KW: 2.776,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1366,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2202-19",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2200mm",
    depthMM: 67,
    heightMM: 2302,
    widthMM: 885,
    volumeL: 37.43,
    nominalDeltaT50KW: 2.93,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1436,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2202-20",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2200mm",
    depthMM: 67,
    heightMM: 2302,
    widthMM: 930,
    volumeL: 39.4,
    nominalDeltaT50KW: 3.084,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1505,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2202-21",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2200mm",
    depthMM: 67,
    heightMM: 2302,
    widthMM: 975,
    volumeL: 41.37,
    nominalDeltaT50KW: 3.238,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1575,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2202-22",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2200mm",
    depthMM: 67,
    heightMM: 2302,
    widthMM: 1020,
    volumeL: 43.34,
    nominalDeltaT50KW: 3.392,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1644,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2202-23",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2200mm",
    depthMM: 67,
    heightMM: 2302,
    widthMM: 1065,
    volumeL: 45.31,
    nominalDeltaT50KW: 3.547,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1714,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2202-24",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2200mm",
    depthMM: 67,
    heightMM: 2302,
    widthMM: 1110,
    volumeL: 47.28,
    nominalDeltaT50KW: 3.701,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1783,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2202-25",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2200mm",
    depthMM: 67,
    heightMM: 2302,
    widthMM: 1155,
    volumeL: 49.25,
    nominalDeltaT50KW: 3.855,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1853,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2202-26",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2200mm",
    depthMM: 67,
    heightMM: 2302,
    widthMM: 1200,
    volumeL: 51.22,
    nominalDeltaT50KW: 4.009,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1922,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2202-27",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2200mm",
    depthMM: 67,
    heightMM: 2302,
    widthMM: 1245,
    volumeL: 53.19,
    nominalDeltaT50KW: 4.163,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1992,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2202-28",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2200mm",
    depthMM: 67,
    heightMM: 2302,
    widthMM: 1290,
    volumeL: 55.16,
    nominalDeltaT50KW: 4.318,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 2061,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2202-29",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2200mm",
    depthMM: 67,
    heightMM: 2302,
    widthMM: 1335,
    volumeL: 57.13,
    nominalDeltaT50KW: 4.472,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 2131,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2202-30",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2200mm",
    depthMM: 67,
    heightMM: 2302,
    widthMM: 1380,
    volumeL: 59.1,
    nominalDeltaT50KW: 4.626,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 2200,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2202-31",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2200mm",
    depthMM: 67,
    heightMM: 2302,
    widthMM: 1425,
    volumeL: 61.07,
    nominalDeltaT50KW: 4.78,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 2270,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2202-32",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2200mm",
    depthMM: 67,
    heightMM: 2302,
    widthMM: 1470,
    volumeL: 63.04,
    nominalDeltaT50KW: 4.934,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 2339,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2202-33",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2200mm",
    depthMM: 67,
    heightMM: 2302,
    widthMM: 1515,
    volumeL: 65.01,
    nominalDeltaT50KW: 5.089,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 2409,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2202-34",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2200mm",
    depthMM: 67,
    heightMM: 2302,
    widthMM: 1560,
    volumeL: 66.98,
    nominalDeltaT50KW: 5.243,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 2478,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2202-35",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2200mm",
    depthMM: 67,
    heightMM: 2302,
    widthMM: 1605,
    volumeL: 68.95,
    nominalDeltaT50KW: 5.397,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 2548,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2202-36",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2200mm",
    depthMM: 67,
    heightMM: 2302,
    widthMM: 1650,
    volumeL: 70.92,
    nominalDeltaT50KW: 5.551,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 2617,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2202-37",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2200mm",
    depthMM: 67,
    heightMM: 2302,
    widthMM: 1695,
    volumeL: 72.89,
    nominalDeltaT50KW: 5.705,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 2687,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2202-38",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2200mm",
    depthMM: 67,
    heightMM: 2302,
    widthMM: 1740,
    volumeL: 74.86,
    nominalDeltaT50KW: 5.86,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 2756,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2202-39",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2200mm",
    depthMM: 67,
    heightMM: 2302,
    widthMM: 1785,
    volumeL: 76.83,
    nominalDeltaT50KW: 6.014,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 2826,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2202-40",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2200mm",
    depthMM: 67,
    heightMM: 2302,
    widthMM: 1830,
    volumeL: 78.8,
    nominalDeltaT50KW: 6.168,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 2895,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2502-04",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2500mm",
    depthMM: 67,
    heightMM: 2602,
    widthMM: 210,
    volumeL: 8.8,
    nominalDeltaT50KW: 0.708,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 441,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2502-05",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2500mm",
    depthMM: 67,
    heightMM: 2602,
    widthMM: 255,
    volumeL: 11,
    nominalDeltaT50KW: 0.885,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 523,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2502-06",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2500mm",
    depthMM: 67,
    heightMM: 2602,
    widthMM: 300,
    volumeL: 13.2,
    nominalDeltaT50KW: 1.062,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 604,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2502-07",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2500mm",
    depthMM: 67,
    heightMM: 2602,
    widthMM: 345,
    volumeL: 15.4,
    nominalDeltaT50KW: 1.239,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 686,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2502-08",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2500mm",
    depthMM: 67,
    heightMM: 2602,
    widthMM: 390,
    volumeL: 17.6,
    nominalDeltaT50KW: 1.416,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 767,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2502-09",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2500mm",
    depthMM: 67,
    heightMM: 2602,
    widthMM: 435,
    volumeL: 19.8,
    nominalDeltaT50KW: 1.593,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 849,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2502-10",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2500mm",
    depthMM: 67,
    heightMM: 2602,
    widthMM: 480,
    volumeL: 22,
    nominalDeltaT50KW: 1.77,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 930,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2502-11",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2500mm",
    depthMM: 67,
    heightMM: 2602,
    widthMM: 525,
    volumeL: 24.2,
    nominalDeltaT50KW: 1.947,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1012,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2502-12",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2500mm",
    depthMM: 67,
    heightMM: 2602,
    widthMM: 570,
    volumeL: 26.4,
    nominalDeltaT50KW: 2.124,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1093,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2502-13",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2500mm",
    depthMM: 67,
    heightMM: 2602,
    widthMM: 615,
    volumeL: 28.6,
    nominalDeltaT50KW: 2.301,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1175,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2502-14",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2500mm",
    depthMM: 67,
    heightMM: 2602,
    widthMM: 660,
    volumeL: 30.8,
    nominalDeltaT50KW: 2.478,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1256,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2502-15",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2500mm",
    depthMM: 67,
    heightMM: 2602,
    widthMM: 705,
    volumeL: 33,
    nominalDeltaT50KW: 2.655,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1338,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2502-16",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2500mm",
    depthMM: 67,
    heightMM: 2602,
    widthMM: 750,
    volumeL: 35.2,
    nominalDeltaT50KW: 2.832,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1419,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2502-17",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2500mm",
    depthMM: 67,
    heightMM: 2602,
    widthMM: 795,
    volumeL: 37.4,
    nominalDeltaT50KW: 3.009,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1501,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2502-18",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2500mm",
    depthMM: 67,
    heightMM: 2602,
    widthMM: 840,
    volumeL: 39.6,
    nominalDeltaT50KW: 3.186,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1582,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2502-19",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2500mm",
    depthMM: 67,
    heightMM: 2602,
    widthMM: 885,
    volumeL: 41.8,
    nominalDeltaT50KW: 3.363,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1664,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2502-20",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2500mm",
    depthMM: 67,
    heightMM: 2602,
    widthMM: 930,
    volumeL: 44,
    nominalDeltaT50KW: 3.54,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1745,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2502-21",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2500mm",
    depthMM: 67,
    heightMM: 2602,
    widthMM: 975,
    volumeL: 46.2,
    nominalDeltaT50KW: 3.717,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1827,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2502-22",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2500mm",
    depthMM: 67,
    heightMM: 2602,
    widthMM: 1020,
    volumeL: 48.4,
    nominalDeltaT50KW: 3.894,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1908,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2502-23",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2500mm",
    depthMM: 67,
    heightMM: 2602,
    widthMM: 1065,
    volumeL: 50.6,
    nominalDeltaT50KW: 4.071,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1990,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2502-24",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2500mm",
    depthMM: 67,
    heightMM: 2602,
    widthMM: 1110,
    volumeL: 52.8,
    nominalDeltaT50KW: 4.248,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 2071,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2502-25",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2500mm",
    depthMM: 67,
    heightMM: 2602,
    widthMM: 1155,
    volumeL: 55,
    nominalDeltaT50KW: 4.425,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 2153,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2502-26",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2500mm",
    depthMM: 67,
    heightMM: 2602,
    widthMM: 1200,
    volumeL: 57.2,
    nominalDeltaT50KW: 4.602,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 2234,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2502-27",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2500mm",
    depthMM: 67,
    heightMM: 2602,
    widthMM: 1245,
    volumeL: 59.4,
    nominalDeltaT50KW: 4.779,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 2316,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2502-28",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2500mm",
    depthMM: 67,
    heightMM: 2602,
    widthMM: 1290,
    volumeL: 61.6,
    nominalDeltaT50KW: 4.956,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 2397,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2502-29",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2500mm",
    depthMM: 67,
    heightMM: 2602,
    widthMM: 1335,
    volumeL: 63.8,
    nominalDeltaT50KW: 5.133,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 2479,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2502-30",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2500mm",
    depthMM: 67,
    heightMM: 2602,
    widthMM: 1380,
    volumeL: 66,
    nominalDeltaT50KW: 5.31,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 2560,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2502-31",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2500mm",
    depthMM: 67,
    heightMM: 2602,
    widthMM: 1425,
    volumeL: 68.2,
    nominalDeltaT50KW: 5.487,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 2642,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2502-32",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2500mm",
    depthMM: 67,
    heightMM: 2602,
    widthMM: 1470,
    volumeL: 70.4,
    nominalDeltaT50KW: 5.664,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 2723,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2502-33",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2500mm",
    depthMM: 67,
    heightMM: 2602,
    widthMM: 1515,
    volumeL: 72.6,
    nominalDeltaT50KW: 5.841,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 2805,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2502-34",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2500mm",
    depthMM: 67,
    heightMM: 2602,
    widthMM: 1560,
    volumeL: 74.8,
    nominalDeltaT50KW: 6.018,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 2886,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2502-35",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2500mm",
    depthMM: 67,
    heightMM: 2602,
    widthMM: 1605,
    volumeL: 77,
    nominalDeltaT50KW: 6.195,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 2968,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2502-36",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2500mm",
    depthMM: 67,
    heightMM: 2602,
    widthMM: 1650,
    volumeL: 79.2,
    nominalDeltaT50KW: 6.372,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 3049,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2502-37",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2500mm",
    depthMM: 67,
    heightMM: 2602,
    widthMM: 1695,
    volumeL: 81.4,
    nominalDeltaT50KW: 6.549,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 3131,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2502-38",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2500mm",
    depthMM: 67,
    heightMM: 2602,
    widthMM: 1740,
    volumeL: 83.6,
    nominalDeltaT50KW: 6.726,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 3212,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2502-39",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2500mm",
    depthMM: 67,
    heightMM: 2602,
    widthMM: 1785,
    volumeL: 85.8,
    nominalDeltaT50KW: 6.903,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 3294,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-2502-40",
    rangeType: "Florence 2",
    model: "Florence 2 Column 2500mm",
    depthMM: 67,
    heightMM: 2602,
    widthMM: 1830,
    volumeL: 88,
    nominalDeltaT50KW: 7.08,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 3375,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-302-04",
    rangeType: "Florence 2",
    model: "Florence 2 Column 300mm",
    depthMM: 67,
    heightMM: 402,
    widthMM: 210,
    volumeL: 1.6,
    nominalDeltaT50KW: 0.094,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 213,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-302-05",
    rangeType: "Florence 2",
    model: "Florence 2 Column 300mm",
    depthMM: 67,
    heightMM: 402,
    widthMM: 255,
    volumeL: 2,
    nominalDeltaT50KW: 0.117,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 238,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-302-06",
    rangeType: "Florence 2",
    model: "Florence 2 Column 300mm",
    depthMM: 67,
    heightMM: 402,
    widthMM: 300,
    volumeL: 2.4,
    nominalDeltaT50KW: 0.14,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 262,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-302-07",
    rangeType: "Florence 2",
    model: "Florence 2 Column 300mm",
    depthMM: 67,
    heightMM: 402,
    widthMM: 345,
    volumeL: 2.8,
    nominalDeltaT50KW: 0.164,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 287,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-302-08",
    rangeType: "Florence 2",
    model: "Florence 2 Column 300mm",
    depthMM: 67,
    heightMM: 402,
    widthMM: 390,
    volumeL: 3.2,
    nominalDeltaT50KW: 0.187,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 311,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-302-09",
    rangeType: "Florence 2",
    model: "Florence 2 Column 300mm",
    depthMM: 67,
    heightMM: 402,
    widthMM: 435,
    volumeL: 3.6,
    nominalDeltaT50KW: 0.211,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 336,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-302-10",
    rangeType: "Florence 2",
    model: "Florence 2 Column 300mm",
    depthMM: 67,
    heightMM: 402,
    widthMM: 480,
    volumeL: 4,
    nominalDeltaT50KW: 0.234,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 360,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-302-11",
    rangeType: "Florence 2",
    model: "Florence 2 Column 300mm",
    depthMM: 67,
    heightMM: 402,
    widthMM: 525,
    volumeL: 4.4,
    nominalDeltaT50KW: 0.257,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 385,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-302-12",
    rangeType: "Florence 2",
    model: "Florence 2 Column 300mm",
    depthMM: 67,
    heightMM: 402,
    widthMM: 570,
    volumeL: 4.8,
    nominalDeltaT50KW: 0.281,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 409,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-302-13",
    rangeType: "Florence 2",
    model: "Florence 2 Column 300mm",
    depthMM: 67,
    heightMM: 402,
    widthMM: 615,
    volumeL: 5.2,
    nominalDeltaT50KW: 0.304,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 434,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-302-14",
    rangeType: "Florence 2",
    model: "Florence 2 Column 300mm",
    depthMM: 67,
    heightMM: 402,
    widthMM: 660,
    volumeL: 5.6,
    nominalDeltaT50KW: 0.328,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 458,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-302-15",
    rangeType: "Florence 2",
    model: "Florence 2 Column 300mm",
    depthMM: 67,
    heightMM: 402,
    widthMM: 705,
    volumeL: 6,
    nominalDeltaT50KW: 0.351,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 483,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-302-16",
    rangeType: "Florence 2",
    model: "Florence 2 Column 300mm",
    depthMM: 67,
    heightMM: 402,
    widthMM: 750,
    volumeL: 6.4,
    nominalDeltaT50KW: 0.374,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 507,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-302-17",
    rangeType: "Florence 2",
    model: "Florence 2 Column 300mm",
    depthMM: 67,
    heightMM: 402,
    widthMM: 795,
    volumeL: 6.8,
    nominalDeltaT50KW: 0.398,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 532,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-302-18",
    rangeType: "Florence 2",
    model: "Florence 2 Column 300mm",
    depthMM: 67,
    heightMM: 402,
    widthMM: 840,
    volumeL: 7.2,
    nominalDeltaT50KW: 0.421,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 556,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-302-19",
    rangeType: "Florence 2",
    model: "Florence 2 Column 300mm",
    depthMM: 67,
    heightMM: 402,
    widthMM: 885,
    volumeL: 7.6,
    nominalDeltaT50KW: 0.445,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 581,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-302-20",
    rangeType: "Florence 2",
    model: "Florence 2 Column 300mm",
    depthMM: 67,
    heightMM: 402,
    widthMM: 930,
    volumeL: 8,
    nominalDeltaT50KW: 0.468,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 605,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-302-21",
    rangeType: "Florence 2",
    model: "Florence 2 Column 300mm",
    depthMM: 67,
    heightMM: 402,
    widthMM: 975,
    volumeL: 8.4,
    nominalDeltaT50KW: 0.491,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 630,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-302-22",
    rangeType: "Florence 2",
    model: "Florence 2 Column 300mm",
    depthMM: 67,
    heightMM: 402,
    widthMM: 1020,
    volumeL: 8.8,
    nominalDeltaT50KW: 0.515,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 654,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-302-23",
    rangeType: "Florence 2",
    model: "Florence 2 Column 300mm",
    depthMM: 67,
    heightMM: 402,
    widthMM: 1065,
    volumeL: 9.2,
    nominalDeltaT50KW: 0.538,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 679,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-302-24",
    rangeType: "Florence 2",
    model: "Florence 2 Column 300mm",
    depthMM: 67,
    heightMM: 402,
    widthMM: 1110,
    volumeL: 9.6,
    nominalDeltaT50KW: 0.562,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 703,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-302-25",
    rangeType: "Florence 2",
    model: "Florence 2 Column 300mm",
    depthMM: 67,
    heightMM: 402,
    widthMM: 1155,
    volumeL: 10,
    nominalDeltaT50KW: 0.585,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 728,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-302-26",
    rangeType: "Florence 2",
    model: "Florence 2 Column 300mm",
    depthMM: 67,
    heightMM: 402,
    widthMM: 1200,
    volumeL: 10.4,
    nominalDeltaT50KW: 0.608,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 752,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-302-27",
    rangeType: "Florence 2",
    model: "Florence 2 Column 300mm",
    depthMM: 67,
    heightMM: 402,
    widthMM: 1245,
    volumeL: 10.8,
    nominalDeltaT50KW: 0.632,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 777,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-302-28",
    rangeType: "Florence 2",
    model: "Florence 2 Column 300mm",
    depthMM: 67,
    heightMM: 402,
    widthMM: 1290,
    volumeL: 11.2,
    nominalDeltaT50KW: 0.655,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 801,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-302-29",
    rangeType: "Florence 2",
    model: "Florence 2 Column 300mm",
    depthMM: 67,
    heightMM: 402,
    widthMM: 1335,
    volumeL: 11.6,
    nominalDeltaT50KW: 0.679,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 826,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-302-30",
    rangeType: "Florence 2",
    model: "Florence 2 Column 300mm",
    depthMM: 67,
    heightMM: 402,
    widthMM: 1380,
    volumeL: 12,
    nominalDeltaT50KW: 0.702,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 850,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-302-31",
    rangeType: "Florence 2",
    model: "Florence 2 Column 300mm",
    depthMM: 67,
    heightMM: 402,
    widthMM: 1425,
    volumeL: 12.4,
    nominalDeltaT50KW: 0.725,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 875,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-302-32",
    rangeType: "Florence 2",
    model: "Florence 2 Column 300mm",
    depthMM: 67,
    heightMM: 402,
    widthMM: 1470,
    volumeL: 12.8,
    nominalDeltaT50KW: 0.749,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 899,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-302-33",
    rangeType: "Florence 2",
    model: "Florence 2 Column 300mm",
    depthMM: 67,
    heightMM: 402,
    widthMM: 1515,
    volumeL: 13.2,
    nominalDeltaT50KW: 0.772,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 924,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-302-34",
    rangeType: "Florence 2",
    model: "Florence 2 Column 300mm",
    depthMM: 67,
    heightMM: 402,
    widthMM: 1560,
    volumeL: 13.6,
    nominalDeltaT50KW: 0.796,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 948,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-302-35",
    rangeType: "Florence 2",
    model: "Florence 2 Column 300mm",
    depthMM: 67,
    heightMM: 402,
    widthMM: 1605,
    volumeL: 14,
    nominalDeltaT50KW: 0.819,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 973,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-302-36",
    rangeType: "Florence 2",
    model: "Florence 2 Column 300mm",
    depthMM: 67,
    heightMM: 402,
    widthMM: 1650,
    volumeL: 14.4,
    nominalDeltaT50KW: 0.842,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 997,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-302-37",
    rangeType: "Florence 2",
    model: "Florence 2 Column 300mm",
    depthMM: 67,
    heightMM: 402,
    widthMM: 1695,
    volumeL: 14.8,
    nominalDeltaT50KW: 0.866,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 1022,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-302-38",
    rangeType: "Florence 2",
    model: "Florence 2 Column 300mm",
    depthMM: 67,
    heightMM: 402,
    widthMM: 1740,
    volumeL: 15.2,
    nominalDeltaT50KW: 0.889,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 1046,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-302-39",
    rangeType: "Florence 2",
    model: "Florence 2 Column 300mm",
    depthMM: 67,
    heightMM: 402,
    widthMM: 1785,
    volumeL: 15.6,
    nominalDeltaT50KW: 0.913,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 1071,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-302-40",
    rangeType: "Florence 2",
    model: "Florence 2 Column 300mm",
    depthMM: 67,
    heightMM: 402,
    widthMM: 1830,
    volumeL: 16,
    nominalDeltaT50KW: 0.936,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 1095,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-402-04",
    rangeType: "Florence 2",
    model: "Florence 2 Column 400mm",
    depthMM: 67,
    heightMM: 502,
    widthMM: 210,
    volumeL: 2,
    nominalDeltaT50KW: 0.12,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 213,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-402-05",
    rangeType: "Florence 2",
    model: "Florence 2 Column 400mm",
    depthMM: 67,
    heightMM: 502,
    widthMM: 255,
    volumeL: 2.5,
    nominalDeltaT50KW: 0.151,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 238,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-402-06",
    rangeType: "Florence 2",
    model: "Florence 2 Column 400mm",
    depthMM: 67,
    heightMM: 502,
    widthMM: 300,
    volumeL: 3,
    nominalDeltaT50KW: 0.181,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 262,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-402-07",
    rangeType: "Florence 2",
    model: "Florence 2 Column 400mm",
    depthMM: 67,
    heightMM: 502,
    widthMM: 345,
    volumeL: 3.5,
    nominalDeltaT50KW: 0.211,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 287,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-402-08",
    rangeType: "Florence 2",
    model: "Florence 2 Column 400mm",
    depthMM: 67,
    heightMM: 502,
    widthMM: 390,
    volumeL: 4,
    nominalDeltaT50KW: 0.241,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 311,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-402-09",
    rangeType: "Florence 2",
    model: "Florence 2 Column 400mm",
    depthMM: 67,
    heightMM: 502,
    widthMM: 435,
    volumeL: 4.5,
    nominalDeltaT50KW: 0.271,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 336,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-402-10",
    rangeType: "Florence 2",
    model: "Florence 2 Column 400mm",
    depthMM: 67,
    heightMM: 502,
    widthMM: 480,
    volumeL: 5,
    nominalDeltaT50KW: 0.301,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 360,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-402-11",
    rangeType: "Florence 2",
    model: "Florence 2 Column 400mm",
    depthMM: 67,
    heightMM: 502,
    widthMM: 525,
    volumeL: 5.5,
    nominalDeltaT50KW: 0.331,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 385,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-402-12",
    rangeType: "Florence 2",
    model: "Florence 2 Column 400mm",
    depthMM: 67,
    heightMM: 502,
    widthMM: 570,
    volumeL: 6,
    nominalDeltaT50KW: 0.361,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 409,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-402-13",
    rangeType: "Florence 2",
    model: "Florence 2 Column 400mm",
    depthMM: 67,
    heightMM: 502,
    widthMM: 615,
    volumeL: 6.5,
    nominalDeltaT50KW: 0.391,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 434,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-402-14",
    rangeType: "Florence 2",
    model: "Florence 2 Column 400mm",
    depthMM: 67,
    heightMM: 502,
    widthMM: 660,
    volumeL: 7,
    nominalDeltaT50KW: 0.421,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 458,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-402-15",
    rangeType: "Florence 2",
    model: "Florence 2 Column 400mm",
    depthMM: 67,
    heightMM: 502,
    widthMM: 705,
    volumeL: 7.5,
    nominalDeltaT50KW: 0.452,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 483,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-402-16",
    rangeType: "Florence 2",
    model: "Florence 2 Column 400mm",
    depthMM: 67,
    heightMM: 502,
    widthMM: 750,
    volumeL: 8,
    nominalDeltaT50KW: 0.482,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 507,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-402-17",
    rangeType: "Florence 2",
    model: "Florence 2 Column 400mm",
    depthMM: 67,
    heightMM: 502,
    widthMM: 795,
    volumeL: 8.5,
    nominalDeltaT50KW: 0.512,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 532,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-402-18",
    rangeType: "Florence 2",
    model: "Florence 2 Column 400mm",
    depthMM: 67,
    heightMM: 502,
    widthMM: 840,
    volumeL: 9,
    nominalDeltaT50KW: 0.542,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 556,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-402-19",
    rangeType: "Florence 2",
    model: "Florence 2 Column 400mm",
    depthMM: 67,
    heightMM: 502,
    widthMM: 885,
    volumeL: 9.5,
    nominalDeltaT50KW: 0.572,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 581,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-402-20",
    rangeType: "Florence 2",
    model: "Florence 2 Column 400mm",
    depthMM: 67,
    heightMM: 502,
    widthMM: 930,
    volumeL: 10,
    nominalDeltaT50KW: 0.602,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 605,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-402-21",
    rangeType: "Florence 2",
    model: "Florence 2 Column 400mm",
    depthMM: 67,
    heightMM: 502,
    widthMM: 975,
    volumeL: 10.5,
    nominalDeltaT50KW: 0.632,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 630,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-402-22",
    rangeType: "Florence 2",
    model: "Florence 2 Column 400mm",
    depthMM: 67,
    heightMM: 502,
    widthMM: 1020,
    volumeL: 11,
    nominalDeltaT50KW: 0.662,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 654,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-402-23",
    rangeType: "Florence 2",
    model: "Florence 2 Column 400mm",
    depthMM: 67,
    heightMM: 502,
    widthMM: 1065,
    volumeL: 11.5,
    nominalDeltaT50KW: 0.692,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 679,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-402-24",
    rangeType: "Florence 2",
    model: "Florence 2 Column 400mm",
    depthMM: 67,
    heightMM: 502,
    widthMM: 1110,
    volumeL: 12,
    nominalDeltaT50KW: 0.722,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 703,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-402-25",
    rangeType: "Florence 2",
    model: "Florence 2 Column 400mm",
    depthMM: 67,
    heightMM: 502,
    widthMM: 1155,
    volumeL: 12.5,
    nominalDeltaT50KW: 0.753,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 728,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-402-26",
    rangeType: "Florence 2",
    model: "Florence 2 Column 400mm",
    depthMM: 67,
    heightMM: 502,
    widthMM: 1200,
    volumeL: 13,
    nominalDeltaT50KW: 0.783,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 752,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-402-27",
    rangeType: "Florence 2",
    model: "Florence 2 Column 400mm",
    depthMM: 67,
    heightMM: 502,
    widthMM: 1245,
    volumeL: 13.5,
    nominalDeltaT50KW: 0.813,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 777,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-402-28",
    rangeType: "Florence 2",
    model: "Florence 2 Column 400mm",
    depthMM: 67,
    heightMM: 502,
    widthMM: 1290,
    volumeL: 14,
    nominalDeltaT50KW: 0.843,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 801,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-402-29",
    rangeType: "Florence 2",
    model: "Florence 2 Column 400mm",
    depthMM: 67,
    heightMM: 502,
    widthMM: 1335,
    volumeL: 14.5,
    nominalDeltaT50KW: 0.873,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 826,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-402-30",
    rangeType: "Florence 2",
    model: "Florence 2 Column 400mm",
    depthMM: 67,
    heightMM: 502,
    widthMM: 1380,
    volumeL: 15,
    nominalDeltaT50KW: 0.903,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 850,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-402-31",
    rangeType: "Florence 2",
    model: "Florence 2 Column 400mm",
    depthMM: 67,
    heightMM: 502,
    widthMM: 1425,
    volumeL: 15.5,
    nominalDeltaT50KW: 0.933,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 875,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-402-32",
    rangeType: "Florence 2",
    model: "Florence 2 Column 400mm",
    depthMM: 67,
    heightMM: 502,
    widthMM: 1470,
    volumeL: 16,
    nominalDeltaT50KW: 0.963,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 899,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-402-33",
    rangeType: "Florence 2",
    model: "Florence 2 Column 400mm",
    depthMM: 67,
    heightMM: 502,
    widthMM: 1515,
    volumeL: 16.5,
    nominalDeltaT50KW: 0.993,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 924,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-402-34",
    rangeType: "Florence 2",
    model: "Florence 2 Column 400mm",
    depthMM: 67,
    heightMM: 502,
    widthMM: 1560,
    volumeL: 17,
    nominalDeltaT50KW: 1.023,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 948,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-402-35",
    rangeType: "Florence 2",
    model: "Florence 2 Column 400mm",
    depthMM: 67,
    heightMM: 502,
    widthMM: 1605,
    volumeL: 17.5,
    nominalDeltaT50KW: 1.054,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 973,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-402-36",
    rangeType: "Florence 2",
    model: "Florence 2 Column 400mm",
    depthMM: 67,
    heightMM: 502,
    widthMM: 1650,
    volumeL: 18,
    nominalDeltaT50KW: 1.084,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 997,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-402-37",
    rangeType: "Florence 2",
    model: "Florence 2 Column 400mm",
    depthMM: 67,
    heightMM: 502,
    widthMM: 1695,
    volumeL: 18.5,
    nominalDeltaT50KW: 1.114,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1022,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-402-38",
    rangeType: "Florence 2",
    model: "Florence 2 Column 400mm",
    depthMM: 67,
    heightMM: 502,
    widthMM: 1740,
    volumeL: 19,
    nominalDeltaT50KW: 1.144,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1046,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-402-39",
    rangeType: "Florence 2",
    model: "Florence 2 Column 400mm",
    depthMM: 67,
    heightMM: 502,
    widthMM: 1785,
    volumeL: 19.5,
    nominalDeltaT50KW: 1.174,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1071,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-402-40",
    rangeType: "Florence 2",
    model: "Florence 2 Column 400mm",
    depthMM: 67,
    heightMM: 502,
    widthMM: 1830,
    volumeL: 20,
    nominalDeltaT50KW: 1.204,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1095,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-502-04",
    rangeType: "Florence 2",
    model: "Florence 2 Column 500mm",
    depthMM: 68,
    heightMM: 602,
    widthMM: 210,
    volumeL: 2.4,
    nominalDeltaT50KW: 0.147,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 217,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-502-05",
    rangeType: "Florence 2",
    model: "Florence 2 Column 500mm",
    depthMM: 68,
    heightMM: 602,
    widthMM: 255,
    volumeL: 3,
    nominalDeltaT50KW: 0.184,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 243,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-502-06",
    rangeType: "Florence 2",
    model: "Florence 2 Column 500mm",
    depthMM: 68,
    heightMM: 602,
    widthMM: 300,
    volumeL: 3.6,
    nominalDeltaT50KW: 0.22,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 268,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-502-07",
    rangeType: "Florence 2",
    model: "Florence 2 Column 500mm",
    depthMM: 68,
    heightMM: 602,
    widthMM: 345,
    volumeL: 4.2,
    nominalDeltaT50KW: 0.257,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 294,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-502-08",
    rangeType: "Florence 2",
    model: "Florence 2 Column 500mm",
    depthMM: 68,
    heightMM: 602,
    widthMM: 390,
    volumeL: 4.8,
    nominalDeltaT50KW: 0.294,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 319,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-502-09",
    rangeType: "Florence 2",
    model: "Florence 2 Column 500mm",
    depthMM: 68,
    heightMM: 602,
    widthMM: 435,
    volumeL: 5.4,
    nominalDeltaT50KW: 0.33,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 345,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-502-10",
    rangeType: "Florence 2",
    model: "Florence 2 Column 500mm",
    depthMM: 68,
    heightMM: 602,
    widthMM: 480,
    volumeL: 6,
    nominalDeltaT50KW: 0.367,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 370,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-502-11",
    rangeType: "Florence 2",
    model: "Florence 2 Column 500mm",
    depthMM: 68,
    heightMM: 602,
    widthMM: 525,
    volumeL: 6.6,
    nominalDeltaT50KW: 0.404,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 396,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-502-12",
    rangeType: "Florence 2",
    model: "Florence 2 Column 500mm",
    depthMM: 68,
    heightMM: 602,
    widthMM: 570,
    volumeL: 7.2,
    nominalDeltaT50KW: 0.44,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 421,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-502-13",
    rangeType: "Florence 2",
    model: "Florence 2 Column 500mm",
    depthMM: 68,
    heightMM: 602,
    widthMM: 615,
    volumeL: 7.8,
    nominalDeltaT50KW: 0.477,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 447,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-502-14",
    rangeType: "Florence 2",
    model: "Florence 2 Column 500mm",
    depthMM: 68,
    heightMM: 602,
    widthMM: 660,
    volumeL: 8.4,
    nominalDeltaT50KW: 0.514,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 472,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-502-15",
    rangeType: "Florence 2",
    model: "Florence 2 Column 500mm",
    depthMM: 68,
    heightMM: 602,
    widthMM: 705,
    volumeL: 9,
    nominalDeltaT50KW: 0.551,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 498,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-502-16",
    rangeType: "Florence 2",
    model: "Florence 2 Column 500mm",
    depthMM: 68,
    heightMM: 602,
    widthMM: 750,
    volumeL: 9.6,
    nominalDeltaT50KW: 0.587,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 523,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-502-17",
    rangeType: "Florence 2",
    model: "Florence 2 Column 500mm",
    depthMM: 68,
    heightMM: 602,
    widthMM: 795,
    volumeL: 10.2,
    nominalDeltaT50KW: 0.624,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 549,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-502-18",
    rangeType: "Florence 2",
    model: "Florence 2 Column 500mm",
    depthMM: 68,
    heightMM: 602,
    widthMM: 840,
    volumeL: 10.8,
    nominalDeltaT50KW: 0.661,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 574,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-502-19",
    rangeType: "Florence 2",
    model: "Florence 2 Column 500mm",
    depthMM: 68,
    heightMM: 602,
    widthMM: 885,
    volumeL: 11.4,
    nominalDeltaT50KW: 0.697,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 600,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-502-20",
    rangeType: "Florence 2",
    model: "Florence 2 Column 500mm",
    depthMM: 68,
    heightMM: 602,
    widthMM: 930,
    volumeL: 12,
    nominalDeltaT50KW: 0.734,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 625,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-502-21",
    rangeType: "Florence 2",
    model: "Florence 2 Column 500mm",
    depthMM: 68,
    heightMM: 602,
    widthMM: 975,
    volumeL: 12.6,
    nominalDeltaT50KW: 0.771,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 651,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-502-22",
    rangeType: "Florence 2",
    model: "Florence 2 Column 500mm",
    depthMM: 68,
    heightMM: 602,
    widthMM: 1020,
    volumeL: 13.2,
    nominalDeltaT50KW: 0.807,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 676,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-502-23",
    rangeType: "Florence 2",
    model: "Florence 2 Column 500mm",
    depthMM: 68,
    heightMM: 602,
    widthMM: 1065,
    volumeL: 13.8,
    nominalDeltaT50KW: 0.844,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 702,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-502-24",
    rangeType: "Florence 2",
    model: "Florence 2 Column 500mm",
    depthMM: 68,
    heightMM: 602,
    widthMM: 1110,
    volumeL: 14.4,
    nominalDeltaT50KW: 0.881,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 727,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-502-25",
    rangeType: "Florence 2",
    model: "Florence 2 Column 500mm",
    depthMM: 68,
    heightMM: 602,
    widthMM: 1155,
    volumeL: 15,
    nominalDeltaT50KW: 0.918,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 753,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-502-26",
    rangeType: "Florence 2",
    model: "Florence 2 Column 500mm",
    depthMM: 68,
    heightMM: 602,
    widthMM: 1200,
    volumeL: 15.6,
    nominalDeltaT50KW: 0.954,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 778,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-502-27",
    rangeType: "Florence 2",
    model: "Florence 2 Column 500mm",
    depthMM: 68,
    heightMM: 602,
    widthMM: 1245,
    volumeL: 16.2,
    nominalDeltaT50KW: 0.991,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 804,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-502-28",
    rangeType: "Florence 2",
    model: "Florence 2 Column 500mm",
    depthMM: 68,
    heightMM: 602,
    widthMM: 1290,
    volumeL: 16.8,
    nominalDeltaT50KW: 1.028,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 829,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-502-29",
    rangeType: "Florence 2",
    model: "Florence 2 Column 500mm",
    depthMM: 68,
    heightMM: 602,
    widthMM: 1335,
    volumeL: 17.4,
    nominalDeltaT50KW: 1.064,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 855,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-502-30",
    rangeType: "Florence 2",
    model: "Florence 2 Column 500mm",
    depthMM: 68,
    heightMM: 602,
    widthMM: 1380,
    volumeL: 18,
    nominalDeltaT50KW: 1.101,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 880,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-502-31",
    rangeType: "Florence 2",
    model: "Florence 2 Column 500mm",
    depthMM: 68,
    heightMM: 602,
    widthMM: 1425,
    volumeL: 18.6,
    nominalDeltaT50KW: 1.138,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 906,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-502-32",
    rangeType: "Florence 2",
    model: "Florence 2 Column 500mm",
    depthMM: 68,
    heightMM: 602,
    widthMM: 1470,
    volumeL: 19.2,
    nominalDeltaT50KW: 1.174,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 931,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-502-33",
    rangeType: "Florence 2",
    model: "Florence 2 Column 500mm",
    depthMM: 68,
    heightMM: 602,
    widthMM: 1515,
    volumeL: 19.8,
    nominalDeltaT50KW: 1.211,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 957,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-502-34",
    rangeType: "Florence 2",
    model: "Florence 2 Column 500mm",
    depthMM: 68,
    heightMM: 602,
    widthMM: 1560,
    volumeL: 20.4,
    nominalDeltaT50KW: 1.248,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 982,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-502-35",
    rangeType: "Florence 2",
    model: "Florence 2 Column 500mm",
    depthMM: 68,
    heightMM: 602,
    widthMM: 1605,
    volumeL: 21,
    nominalDeltaT50KW: 1.285,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1008,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-502-36",
    rangeType: "Florence 2",
    model: "Florence 2 Column 500mm",
    depthMM: 68,
    heightMM: 602,
    widthMM: 1650,
    volumeL: 21.6,
    nominalDeltaT50KW: 1.321,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1033,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-502-37",
    rangeType: "Florence 2",
    model: "Florence 2 Column 500mm",
    depthMM: 68,
    heightMM: 602,
    widthMM: 1695,
    volumeL: 22.2,
    nominalDeltaT50KW: 1.358,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1059,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-502-38",
    rangeType: "Florence 2",
    model: "Florence 2 Column 500mm",
    depthMM: 68,
    heightMM: 602,
    widthMM: 1740,
    volumeL: 22.8,
    nominalDeltaT50KW: 1.395,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1084,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-502-39",
    rangeType: "Florence 2",
    model: "Florence 2 Column 500mm",
    depthMM: 68,
    heightMM: 602,
    widthMM: 1785,
    volumeL: 23.4,
    nominalDeltaT50KW: 1.431,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1110,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-502-40",
    rangeType: "Florence 2",
    model: "Florence 2 Column 500mm",
    depthMM: 68,
    heightMM: 602,
    widthMM: 1830,
    volumeL: 24,
    nominalDeltaT50KW: 1.468,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1135,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-602-04",
    rangeType: "Florence 2",
    model: "Florence 2 Column 600mm",
    depthMM: 67,
    heightMM: 702,
    widthMM: 210,
    volumeL: 2.8,
    nominalDeltaT50KW: 0.173,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 217,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-602-05",
    rangeType: "Florence 2",
    model: "Florence 2 Column 600mm",
    depthMM: 67,
    heightMM: 702,
    widthMM: 255,
    volumeL: 3.5,
    nominalDeltaT50KW: 0.216,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 243,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-602-06",
    rangeType: "Florence 2",
    model: "Florence 2 Column 600mm",
    depthMM: 67,
    heightMM: 702,
    widthMM: 300,
    volumeL: 4.2,
    nominalDeltaT50KW: 0.259,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 268,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-602-07",
    rangeType: "Florence 2",
    model: "Florence 2 Column 600mm",
    depthMM: 67,
    heightMM: 702,
    widthMM: 345,
    volumeL: 4.9,
    nominalDeltaT50KW: 0.302,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 294,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-602-08",
    rangeType: "Florence 2",
    model: "Florence 2 Column 600mm",
    depthMM: 67,
    heightMM: 702,
    widthMM: 390,
    volumeL: 5.6,
    nominalDeltaT50KW: 0.346,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 319,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-602-09",
    rangeType: "Florence 2",
    model: "Florence 2 Column 600mm",
    depthMM: 67,
    heightMM: 702,
    widthMM: 435,
    volumeL: 6.3,
    nominalDeltaT50KW: 0.389,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 345,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-602-10",
    rangeType: "Florence 2",
    model: "Florence 2 Column 600mm",
    depthMM: 67,
    heightMM: 702,
    widthMM: 480,
    volumeL: 7,
    nominalDeltaT50KW: 0.432,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 370,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-602-11",
    rangeType: "Florence 2",
    model: "Florence 2 Column 600mm",
    depthMM: 67,
    heightMM: 702,
    widthMM: 525,
    volumeL: 7.7,
    nominalDeltaT50KW: 0.475,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 396,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-602-12",
    rangeType: "Florence 2",
    model: "Florence 2 Column 600mm",
    depthMM: 67,
    heightMM: 702,
    widthMM: 570,
    volumeL: 8.4,
    nominalDeltaT50KW: 0.518,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 421,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-602-13",
    rangeType: "Florence 2",
    model: "Florence 2 Column 600mm",
    depthMM: 67,
    heightMM: 702,
    widthMM: 615,
    volumeL: 9.1,
    nominalDeltaT50KW: 0.562,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 447,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-602-14",
    rangeType: "Florence 2",
    model: "Florence 2 Column 600mm",
    depthMM: 67,
    heightMM: 702,
    widthMM: 660,
    volumeL: 9.8,
    nominalDeltaT50KW: 0.605,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 472,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-602-15",
    rangeType: "Florence 2",
    model: "Florence 2 Column 600mm",
    depthMM: 67,
    heightMM: 702,
    widthMM: 705,
    volumeL: 10.5,
    nominalDeltaT50KW: 0.648,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 498,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-602-16",
    rangeType: "Florence 2",
    model: "Florence 2 Column 600mm",
    depthMM: 67,
    heightMM: 702,
    widthMM: 750,
    volumeL: 11.2,
    nominalDeltaT50KW: 0.691,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 523,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-602-17",
    rangeType: "Florence 2",
    model: "Florence 2 Column 600mm",
    depthMM: 67,
    heightMM: 702,
    widthMM: 795,
    volumeL: 11.9,
    nominalDeltaT50KW: 0.734,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 549,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-602-18",
    rangeType: "Florence 2",
    model: "Florence 2 Column 600mm",
    depthMM: 67,
    heightMM: 702,
    widthMM: 840,
    volumeL: 12.6,
    nominalDeltaT50KW: 0.778,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 574,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-602-19",
    rangeType: "Florence 2",
    model: "Florence 2 Column 600mm",
    depthMM: 67,
    heightMM: 702,
    widthMM: 885,
    volumeL: 13.3,
    nominalDeltaT50KW: 0.821,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 600,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-602-20",
    rangeType: "Florence 2",
    model: "Florence 2 Column 600mm",
    depthMM: 67,
    heightMM: 702,
    widthMM: 930,
    volumeL: 14,
    nominalDeltaT50KW: 0.864,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 625,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-602-21",
    rangeType: "Florence 2",
    model: "Florence 2 Column 600mm",
    depthMM: 67,
    heightMM: 702,
    widthMM: 975,
    volumeL: 14.7,
    nominalDeltaT50KW: 0.907,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 651,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-602-22",
    rangeType: "Florence 2",
    model: "Florence 2 Column 600mm",
    depthMM: 67,
    heightMM: 702,
    widthMM: 1020,
    volumeL: 15.4,
    nominalDeltaT50KW: 0.95,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 676,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-602-23",
    rangeType: "Florence 2",
    model: "Florence 2 Column 600mm",
    depthMM: 67,
    heightMM: 702,
    widthMM: 1065,
    volumeL: 16.1,
    nominalDeltaT50KW: 0.994,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 702,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-602-24",
    rangeType: "Florence 2",
    model: "Florence 2 Column 600mm",
    depthMM: 67,
    heightMM: 702,
    widthMM: 1110,
    volumeL: 16.8,
    nominalDeltaT50KW: 1.037,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 727,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-602-25",
    rangeType: "Florence 2",
    model: "Florence 2 Column 600mm",
    depthMM: 67,
    heightMM: 702,
    widthMM: 1155,
    volumeL: 17.5,
    nominalDeltaT50KW: 1.08,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 753,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-602-26",
    rangeType: "Florence 2",
    model: "Florence 2 Column 600mm",
    depthMM: 67,
    heightMM: 702,
    widthMM: 1200,
    volumeL: 18.2,
    nominalDeltaT50KW: 1.123,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 778,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-602-27",
    rangeType: "Florence 2",
    model: "Florence 2 Column 600mm",
    depthMM: 67,
    heightMM: 702,
    widthMM: 1245,
    volumeL: 18.9,
    nominalDeltaT50KW: 1.166,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 804,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-602-28",
    rangeType: "Florence 2",
    model: "Florence 2 Column 600mm",
    depthMM: 67,
    heightMM: 702,
    widthMM: 1290,
    volumeL: 19.6,
    nominalDeltaT50KW: 1.21,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 829,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-602-29",
    rangeType: "Florence 2",
    model: "Florence 2 Column 600mm",
    depthMM: 67,
    heightMM: 702,
    widthMM: 1335,
    volumeL: 20.3,
    nominalDeltaT50KW: 1.253,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 855,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-602-30",
    rangeType: "Florence 2",
    model: "Florence 2 Column 600mm",
    depthMM: 67,
    heightMM: 702,
    widthMM: 1380,
    volumeL: 21,
    nominalDeltaT50KW: 1.296,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 880,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-602-31",
    rangeType: "Florence 2",
    model: "Florence 2 Column 600mm",
    depthMM: 67,
    heightMM: 702,
    widthMM: 1425,
    volumeL: 21.7,
    nominalDeltaT50KW: 1.339,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 906,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-602-32",
    rangeType: "Florence 2",
    model: "Florence 2 Column 600mm",
    depthMM: 67,
    heightMM: 702,
    widthMM: 1470,
    volumeL: 22.4,
    nominalDeltaT50KW: 1.382,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 931,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-602-33",
    rangeType: "Florence 2",
    model: "Florence 2 Column 600mm",
    depthMM: 67,
    heightMM: 702,
    widthMM: 1515,
    volumeL: 23.1,
    nominalDeltaT50KW: 1.426,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 957,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-602-34",
    rangeType: "Florence 2",
    model: "Florence 2 Column 600mm",
    depthMM: 67,
    heightMM: 702,
    widthMM: 1560,
    volumeL: 23.8,
    nominalDeltaT50KW: 1.469,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 982,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-602-35",
    rangeType: "Florence 2",
    model: "Florence 2 Column 600mm",
    depthMM: 67,
    heightMM: 702,
    widthMM: 1605,
    volumeL: 24.5,
    nominalDeltaT50KW: 1.512,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1008,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-602-36",
    rangeType: "Florence 2",
    model: "Florence 2 Column 600mm",
    depthMM: 67,
    heightMM: 702,
    widthMM: 1650,
    volumeL: 25.2,
    nominalDeltaT50KW: 1.555,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1033,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-602-37",
    rangeType: "Florence 2",
    model: "Florence 2 Column 600mm",
    depthMM: 67,
    heightMM: 702,
    widthMM: 1695,
    volumeL: 25.9,
    nominalDeltaT50KW: 1.598,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1059,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-602-38",
    rangeType: "Florence 2",
    model: "Florence 2 Column 600mm",
    depthMM: 67,
    heightMM: 702,
    widthMM: 1740,
    volumeL: 26.6,
    nominalDeltaT50KW: 1.642,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1084,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-602-39",
    rangeType: "Florence 2",
    model: "Florence 2 Column 600mm",
    depthMM: 67,
    heightMM: 702,
    widthMM: 1785,
    volumeL: 27.3,
    nominalDeltaT50KW: 1.685,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1110,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-602-40",
    rangeType: "Florence 2",
    model: "Florence 2 Column 600mm",
    depthMM: 67,
    heightMM: 702,
    widthMM: 1830,
    volumeL: 28,
    nominalDeltaT50KW: 1.728,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1135,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-687-04",
    rangeType: "Florence 2",
    model: "Florence 2 Column 685mm",
    depthMM: 67,
    heightMM: 787,
    widthMM: 210,
    volumeL: 2.8,
    nominalDeltaT50KW: 0.195,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 221,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-687-05",
    rangeType: "Florence 2",
    model: "Florence 2 Column 685mm",
    depthMM: 67,
    heightMM: 787,
    widthMM: 255,
    volumeL: 3.5,
    nominalDeltaT50KW: 0.244,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 248,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-687-06",
    rangeType: "Florence 2",
    model: "Florence 2 Column 685mm",
    depthMM: 67,
    heightMM: 787,
    widthMM: 300,
    volumeL: 4.2,
    nominalDeltaT50KW: 0.292,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 274,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-687-07",
    rangeType: "Florence 2",
    model: "Florence 2 Column 685mm",
    depthMM: 67,
    heightMM: 787,
    widthMM: 345,
    volumeL: 4.9,
    nominalDeltaT50KW: 0.341,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 301,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-687-08",
    rangeType: "Florence 2",
    model: "Florence 2 Column 685mm",
    depthMM: 67,
    heightMM: 787,
    widthMM: 390,
    volumeL: 5.6,
    nominalDeltaT50KW: 0.39,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 327,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-687-09",
    rangeType: "Florence 2",
    model: "Florence 2 Column 685mm",
    depthMM: 67,
    heightMM: 787,
    widthMM: 435,
    volumeL: 6.3,
    nominalDeltaT50KW: 0.438,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 354,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-687-10",
    rangeType: "Florence 2",
    model: "Florence 2 Column 685mm",
    depthMM: 67,
    heightMM: 787,
    widthMM: 480,
    volumeL: 7,
    nominalDeltaT50KW: 0.487,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 380,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-687-11",
    rangeType: "Florence 2",
    model: "Florence 2 Column 685mm",
    depthMM: 67,
    heightMM: 787,
    widthMM: 525,
    volumeL: 7.7,
    nominalDeltaT50KW: 0.536,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 407,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-687-12",
    rangeType: "Florence 2",
    model: "Florence 2 Column 685mm",
    depthMM: 67,
    heightMM: 787,
    widthMM: 570,
    volumeL: 8.4,
    nominalDeltaT50KW: 0.584,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 433,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-687-13",
    rangeType: "Florence 2",
    model: "Florence 2 Column 685mm",
    depthMM: 67,
    heightMM: 787,
    widthMM: 615,
    volumeL: 9.1,
    nominalDeltaT50KW: 0.633,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 460,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-687-14",
    rangeType: "Florence 2",
    model: "Florence 2 Column 685mm",
    depthMM: 67,
    heightMM: 787,
    widthMM: 660,
    volumeL: 9.8,
    nominalDeltaT50KW: 0.682,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 486,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-687-15",
    rangeType: "Florence 2",
    model: "Florence 2 Column 685mm",
    depthMM: 67,
    heightMM: 787,
    widthMM: 705,
    volumeL: 10.5,
    nominalDeltaT50KW: 0.731,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 513,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-687-16",
    rangeType: "Florence 2",
    model: "Florence 2 Column 685mm",
    depthMM: 67,
    heightMM: 787,
    widthMM: 750,
    volumeL: 11.2,
    nominalDeltaT50KW: 0.779,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 539,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-687-17",
    rangeType: "Florence 2",
    model: "Florence 2 Column 685mm",
    depthMM: 67,
    heightMM: 787,
    widthMM: 795,
    volumeL: 11.9,
    nominalDeltaT50KW: 0.828,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 566,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-687-18",
    rangeType: "Florence 2",
    model: "Florence 2 Column 685mm",
    depthMM: 67,
    heightMM: 787,
    widthMM: 840,
    volumeL: 12.6,
    nominalDeltaT50KW: 0.877,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 592,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-687-19",
    rangeType: "Florence 2",
    model: "Florence 2 Column 685mm",
    depthMM: 67,
    heightMM: 787,
    widthMM: 885,
    volumeL: 13.3,
    nominalDeltaT50KW: 0.925,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 619,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-687-20",
    rangeType: "Florence 2",
    model: "Florence 2 Column 685mm",
    depthMM: 67,
    heightMM: 787,
    widthMM: 930,
    volumeL: 14,
    nominalDeltaT50KW: 0.974,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 645,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-687-21",
    rangeType: "Florence 2",
    model: "Florence 2 Column 685mm",
    depthMM: 67,
    heightMM: 787,
    widthMM: 975,
    volumeL: 14.7,
    nominalDeltaT50KW: 1.023,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 672,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-687-22",
    rangeType: "Florence 2",
    model: "Florence 2 Column 685mm",
    depthMM: 67,
    heightMM: 787,
    widthMM: 1020,
    volumeL: 15.4,
    nominalDeltaT50KW: 1.071,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 698,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-687-23",
    rangeType: "Florence 2",
    model: "Florence 2 Column 685mm",
    depthMM: 67,
    heightMM: 787,
    widthMM: 1065,
    volumeL: 16.1,
    nominalDeltaT50KW: 1.12,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 725,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-687-24",
    rangeType: "Florence 2",
    model: "Florence 2 Column 685mm",
    depthMM: 67,
    heightMM: 787,
    widthMM: 1110,
    volumeL: 16.8,
    nominalDeltaT50KW: 1.169,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 751,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-687-25",
    rangeType: "Florence 2",
    model: "Florence 2 Column 685mm",
    depthMM: 67,
    heightMM: 787,
    widthMM: 1155,
    volumeL: 17.5,
    nominalDeltaT50KW: 1.218,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 778,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-687-26",
    rangeType: "Florence 2",
    model: "Florence 2 Column 685mm",
    depthMM: 67,
    heightMM: 787,
    widthMM: 1200,
    volumeL: 18.2,
    nominalDeltaT50KW: 1.266,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 804,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-687-27",
    rangeType: "Florence 2",
    model: "Florence 2 Column 685mm",
    depthMM: 67,
    heightMM: 787,
    widthMM: 1245,
    volumeL: 18.9,
    nominalDeltaT50KW: 1.315,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 831,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-687-28",
    rangeType: "Florence 2",
    model: "Florence 2 Column 685mm",
    depthMM: 67,
    heightMM: 787,
    widthMM: 1290,
    volumeL: 19.6,
    nominalDeltaT50KW: 1.364,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 857,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-687-29",
    rangeType: "Florence 2",
    model: "Florence 2 Column 685mm",
    depthMM: 67,
    heightMM: 787,
    widthMM: 1335,
    volumeL: 20.3,
    nominalDeltaT50KW: 1.412,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 884,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-687-30",
    rangeType: "Florence 2",
    model: "Florence 2 Column 685mm",
    depthMM: 67,
    heightMM: 787,
    widthMM: 1380,
    volumeL: 21,
    nominalDeltaT50KW: 1.461,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 910,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-687-31",
    rangeType: "Florence 2",
    model: "Florence 2 Column 685mm",
    depthMM: 67,
    heightMM: 787,
    widthMM: 1425,
    volumeL: 21.7,
    nominalDeltaT50KW: 1.51,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 937,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-687-32",
    rangeType: "Florence 2",
    model: "Florence 2 Column 685mm",
    depthMM: 67,
    heightMM: 787,
    widthMM: 1470,
    volumeL: 22.4,
    nominalDeltaT50KW: 1.558,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 963,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-687-33",
    rangeType: "Florence 2",
    model: "Florence 2 Column 685mm",
    depthMM: 67,
    heightMM: 787,
    widthMM: 1515,
    volumeL: 23.1,
    nominalDeltaT50KW: 1.607,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 990,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-687-34",
    rangeType: "Florence 2",
    model: "Florence 2 Column 685mm",
    depthMM: 67,
    heightMM: 787,
    widthMM: 1560,
    volumeL: 23.8,
    nominalDeltaT50KW: 1.656,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1016,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-687-35",
    rangeType: "Florence 2",
    model: "Florence 2 Column 685mm",
    depthMM: 67,
    heightMM: 787,
    widthMM: 1605,
    volumeL: 24.5,
    nominalDeltaT50KW: 1.705,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1043,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-687-36",
    rangeType: "Florence 2",
    model: "Florence 2 Column 685mm",
    depthMM: 67,
    heightMM: 787,
    widthMM: 1650,
    volumeL: 25.2,
    nominalDeltaT50KW: 1.753,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1069,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-687-37",
    rangeType: "Florence 2",
    model: "Florence 2 Column 685mm",
    depthMM: 67,
    heightMM: 787,
    widthMM: 1695,
    volumeL: 25.9,
    nominalDeltaT50KW: 1.802,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1096,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-687-38",
    rangeType: "Florence 2",
    model: "Florence 2 Column 685mm",
    depthMM: 67,
    heightMM: 787,
    widthMM: 1740,
    volumeL: 26.6,
    nominalDeltaT50KW: 1.851,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1122,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-687-39",
    rangeType: "Florence 2",
    model: "Florence 2 Column 685mm",
    depthMM: 67,
    heightMM: 787,
    widthMM: 1785,
    volumeL: 27.3,
    nominalDeltaT50KW: 1.899,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1149,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-687-40",
    rangeType: "Florence 2",
    model: "Florence 2 Column 685mm",
    depthMM: 67,
    heightMM: 787,
    widthMM: 1830,
    volumeL: 28,
    nominalDeltaT50KW: 1.948,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1175,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-767-04",
    rangeType: "Florence 2",
    model: "Florence 2 Column 765mm",
    depthMM: 67,
    heightMM: 867,
    widthMM: 210,
    volumeL: 3.2,
    nominalDeltaT50KW: 0.216,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 225,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-767-05",
    rangeType: "Florence 2",
    model: "Florence 2 Column 765mm",
    depthMM: 67,
    heightMM: 867,
    widthMM: 255,
    volumeL: 4,
    nominalDeltaT50KW: 0.27,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 253,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-767-06",
    rangeType: "Florence 2",
    model: "Florence 2 Column 765mm",
    depthMM: 67,
    heightMM: 867,
    widthMM: 300,
    volumeL: 4.8,
    nominalDeltaT50KW: 0.323,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 280,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-767-07",
    rangeType: "Florence 2",
    model: "Florence 2 Column 765mm",
    depthMM: 67,
    heightMM: 867,
    widthMM: 345,
    volumeL: 5.6,
    nominalDeltaT50KW: 0.377,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 308,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-767-08",
    rangeType: "Florence 2",
    model: "Florence 2 Column 765mm",
    depthMM: 67,
    heightMM: 867,
    widthMM: 390,
    volumeL: 6.4,
    nominalDeltaT50KW: 0.431,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 335,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-767-09",
    rangeType: "Florence 2",
    model: "Florence 2 Column 765mm",
    depthMM: 67,
    heightMM: 867,
    widthMM: 435,
    volumeL: 7.2,
    nominalDeltaT50KW: 0.485,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 363,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-767-10",
    rangeType: "Florence 2",
    model: "Florence 2 Column 765mm",
    depthMM: 67,
    heightMM: 867,
    widthMM: 480,
    volumeL: 8,
    nominalDeltaT50KW: 0.539,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 390,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-767-11",
    rangeType: "Florence 2",
    model: "Florence 2 Column 765mm",
    depthMM: 67,
    heightMM: 867,
    widthMM: 525,
    volumeL: 8.8,
    nominalDeltaT50KW: 0.593,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 418,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-767-12",
    rangeType: "Florence 2",
    model: "Florence 2 Column 765mm",
    depthMM: 67,
    heightMM: 867,
    widthMM: 570,
    volumeL: 9.6,
    nominalDeltaT50KW: 0.647,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 445,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-767-13",
    rangeType: "Florence 2",
    model: "Florence 2 Column 765mm",
    depthMM: 67,
    heightMM: 867,
    widthMM: 615,
    volumeL: 10.4,
    nominalDeltaT50KW: 0.701,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 473,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-767-14",
    rangeType: "Florence 2",
    model: "Florence 2 Column 765mm",
    depthMM: 67,
    heightMM: 867,
    widthMM: 660,
    volumeL: 11.2,
    nominalDeltaT50KW: 0.755,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 500,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-767-15",
    rangeType: "Florence 2",
    model: "Florence 2 Column 765mm",
    depthMM: 67,
    heightMM: 867,
    widthMM: 705,
    volumeL: 12,
    nominalDeltaT50KW: 0.809,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 528,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-767-16",
    rangeType: "Florence 2",
    model: "Florence 2 Column 765mm",
    depthMM: 67,
    heightMM: 867,
    widthMM: 750,
    volumeL: 12.8,
    nominalDeltaT50KW: 0.862,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 555,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-767-17",
    rangeType: "Florence 2",
    model: "Florence 2 Column 765mm",
    depthMM: 67,
    heightMM: 867,
    widthMM: 795,
    volumeL: 13.6,
    nominalDeltaT50KW: 0.916,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 583,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-767-18",
    rangeType: "Florence 2",
    model: "Florence 2 Column 765mm",
    depthMM: 67,
    heightMM: 867,
    widthMM: 840,
    volumeL: 14.4,
    nominalDeltaT50KW: 0.97,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 610,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-767-19",
    rangeType: "Florence 2",
    model: "Florence 2 Column 765mm",
    depthMM: 67,
    heightMM: 867,
    widthMM: 885,
    volumeL: 15.2,
    nominalDeltaT50KW: 1.024,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 638,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-767-20",
    rangeType: "Florence 2",
    model: "Florence 2 Column 765mm",
    depthMM: 67,
    heightMM: 867,
    widthMM: 930,
    volumeL: 16,
    nominalDeltaT50KW: 1.078,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 665,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-767-21",
    rangeType: "Florence 2",
    model: "Florence 2 Column 765mm",
    depthMM: 67,
    heightMM: 867,
    widthMM: 975,
    volumeL: 16.8,
    nominalDeltaT50KW: 1.132,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 693,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-767-22",
    rangeType: "Florence 2",
    model: "Florence 2 Column 765mm",
    depthMM: 67,
    heightMM: 867,
    widthMM: 1020,
    volumeL: 17.6,
    nominalDeltaT50KW: 1.186,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 720,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-767-23",
    rangeType: "Florence 2",
    model: "Florence 2 Column 765mm",
    depthMM: 67,
    heightMM: 867,
    widthMM: 1065,
    volumeL: 18.4,
    nominalDeltaT50KW: 1.24,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 748,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-767-24",
    rangeType: "Florence 2",
    model: "Florence 2 Column 765mm",
    depthMM: 67,
    heightMM: 867,
    widthMM: 1110,
    volumeL: 19.2,
    nominalDeltaT50KW: 1.294,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 775,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-767-25",
    rangeType: "Florence 2",
    model: "Florence 2 Column 765mm",
    depthMM: 67,
    heightMM: 867,
    widthMM: 1155,
    volumeL: 20,
    nominalDeltaT50KW: 1.348,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 803,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-767-26",
    rangeType: "Florence 2",
    model: "Florence 2 Column 765mm",
    depthMM: 67,
    heightMM: 867,
    widthMM: 1200,
    volumeL: 20.8,
    nominalDeltaT50KW: 1.401,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 830,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-767-27",
    rangeType: "Florence 2",
    model: "Florence 2 Column 765mm",
    depthMM: 67,
    heightMM: 867,
    widthMM: 1245,
    volumeL: 21.6,
    nominalDeltaT50KW: 1.455,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 858,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-767-28",
    rangeType: "Florence 2",
    model: "Florence 2 Column 765mm",
    depthMM: 67,
    heightMM: 867,
    widthMM: 1290,
    volumeL: 22.4,
    nominalDeltaT50KW: 1.509,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 885,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-767-29",
    rangeType: "Florence 2",
    model: "Florence 2 Column 765mm",
    depthMM: 67,
    heightMM: 867,
    widthMM: 1335,
    volumeL: 23.2,
    nominalDeltaT50KW: 1.563,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 913,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-767-30",
    rangeType: "Florence 2",
    model: "Florence 2 Column 765mm",
    depthMM: 67,
    heightMM: 867,
    widthMM: 1380,
    volumeL: 24,
    nominalDeltaT50KW: 1.617,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 940,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-767-31",
    rangeType: "Florence 2",
    model: "Florence 2 Column 765mm",
    depthMM: 67,
    heightMM: 867,
    widthMM: 1425,
    volumeL: 24.8,
    nominalDeltaT50KW: 1.671,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 968,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-767-32",
    rangeType: "Florence 2",
    model: "Florence 2 Column 765mm",
    depthMM: 67,
    heightMM: 867,
    widthMM: 1470,
    volumeL: 25.6,
    nominalDeltaT50KW: 1.725,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 995,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-767-33",
    rangeType: "Florence 2",
    model: "Florence 2 Column 765mm",
    depthMM: 67,
    heightMM: 867,
    widthMM: 1515,
    volumeL: 26.4,
    nominalDeltaT50KW: 1.779,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 1023,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-767-34",
    rangeType: "Florence 2",
    model: "Florence 2 Column 765mm",
    depthMM: 67,
    heightMM: 867,
    widthMM: 1560,
    volumeL: 27.2,
    nominalDeltaT50KW: 1.833,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 1050,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-767-35",
    rangeType: "Florence 2",
    model: "Florence 2 Column 765mm",
    depthMM: 67,
    heightMM: 867,
    widthMM: 1605,
    volumeL: 28,
    nominalDeltaT50KW: 1.887,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 1078,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-767-36",
    rangeType: "Florence 2",
    model: "Florence 2 Column 765mm",
    depthMM: 67,
    heightMM: 867,
    widthMM: 1650,
    volumeL: 28.8,
    nominalDeltaT50KW: 1.94,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 1105,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-767-37",
    rangeType: "Florence 2",
    model: "Florence 2 Column 765mm",
    depthMM: 67,
    heightMM: 867,
    widthMM: 1695,
    volumeL: 29.6,
    nominalDeltaT50KW: 1.994,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 1133,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-767-38",
    rangeType: "Florence 2",
    model: "Florence 2 Column 765mm",
    depthMM: 67,
    heightMM: 867,
    widthMM: 1740,
    volumeL: 30.4,
    nominalDeltaT50KW: 2.048,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 1160,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-767-39",
    rangeType: "Florence 2",
    model: "Florence 2 Column 765mm",
    depthMM: 67,
    heightMM: 867,
    widthMM: 1785,
    volumeL: 31.2,
    nominalDeltaT50KW: 2.102,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 1188,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-767-40",
    rangeType: "Florence 2",
    model: "Florence 2 Column 765mm",
    depthMM: 67,
    heightMM: 867,
    widthMM: 1830,
    volumeL: 32,
    nominalDeltaT50KW: 2.156,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 1215,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-902-04",
    rangeType: "Florence 2",
    model: "Florence 2 Column 900mm",
    depthMM: 67,
    heightMM: 1002,
    widthMM: 210,
    volumeL: 3.6,
    nominalDeltaT50KW: 0.251,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 225,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-902-05",
    rangeType: "Florence 2",
    model: "Florence 2 Column 900mm",
    depthMM: 67,
    heightMM: 1002,
    widthMM: 255,
    volumeL: 4.5,
    nominalDeltaT50KW: 0.314,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 253,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-902-06",
    rangeType: "Florence 2",
    model: "Florence 2 Column 900mm",
    depthMM: 67,
    heightMM: 1002,
    widthMM: 300,
    volumeL: 5.4,
    nominalDeltaT50KW: 0.376,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 280,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-902-07",
    rangeType: "Florence 2",
    model: "Florence 2 Column 900mm",
    depthMM: 67,
    heightMM: 1002,
    widthMM: 345,
    volumeL: 6.3,
    nominalDeltaT50KW: 0.439,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 308,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-902-08",
    rangeType: "Florence 2",
    model: "Florence 2 Column 900mm",
    depthMM: 67,
    heightMM: 1002,
    widthMM: 390,
    volumeL: 7.2,
    nominalDeltaT50KW: 0.502,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 335,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-902-09",
    rangeType: "Florence 2",
    model: "Florence 2 Column 900mm",
    depthMM: 67,
    heightMM: 1002,
    widthMM: 435,
    volumeL: 8.1,
    nominalDeltaT50KW: 0.564,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 363,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-902-10",
    rangeType: "Florence 2",
    model: "Florence 2 Column 900mm",
    depthMM: 67,
    heightMM: 1002,
    widthMM: 480,
    volumeL: 9,
    nominalDeltaT50KW: 0.627,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 390,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-902-11",
    rangeType: "Florence 2",
    model: "Florence 2 Column 900mm",
    depthMM: 67,
    heightMM: 1002,
    widthMM: 525,
    volumeL: 9.9,
    nominalDeltaT50KW: 0.69,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 418,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-902-12",
    rangeType: "Florence 2",
    model: "Florence 2 Column 900mm",
    depthMM: 67,
    heightMM: 1002,
    widthMM: 570,
    volumeL: 10.8,
    nominalDeltaT50KW: 0.752,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 445,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-902-13",
    rangeType: "Florence 2",
    model: "Florence 2 Column 900mm",
    depthMM: 67,
    heightMM: 1002,
    widthMM: 615,
    volumeL: 11.7,
    nominalDeltaT50KW: 0.815,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 473,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-902-14",
    rangeType: "Florence 2",
    model: "Florence 2 Column 900mm",
    depthMM: 67,
    heightMM: 1002,
    widthMM: 660,
    volumeL: 12.6,
    nominalDeltaT50KW: 0.878,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 500,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-902-15",
    rangeType: "Florence 2",
    model: "Florence 2 Column 900mm",
    depthMM: 67,
    heightMM: 1002,
    widthMM: 705,
    volumeL: 13.5,
    nominalDeltaT50KW: 0.941,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 528,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-902-16",
    rangeType: "Florence 2",
    model: "Florence 2 Column 900mm",
    depthMM: 67,
    heightMM: 1002,
    widthMM: 750,
    volumeL: 14.4,
    nominalDeltaT50KW: 1.003,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 555,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-902-17",
    rangeType: "Florence 2",
    model: "Florence 2 Column 900mm",
    depthMM: 67,
    heightMM: 1002,
    widthMM: 795,
    volumeL: 15.3,
    nominalDeltaT50KW: 1.066,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 583,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-902-18",
    rangeType: "Florence 2",
    model: "Florence 2 Column 900mm",
    depthMM: 67,
    heightMM: 1002,
    widthMM: 840,
    volumeL: 16.2,
    nominalDeltaT50KW: 1.129,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 610,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-902-19",
    rangeType: "Florence 2",
    model: "Florence 2 Column 900mm",
    depthMM: 67,
    heightMM: 1002,
    widthMM: 885,
    volumeL: 17.1,
    nominalDeltaT50KW: 1.191,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 638,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-902-20",
    rangeType: "Florence 2",
    model: "Florence 2 Column 900mm",
    depthMM: 67,
    heightMM: 1002,
    widthMM: 930,
    volumeL: 18,
    nominalDeltaT50KW: 1.254,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 665,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-902-21",
    rangeType: "Florence 2",
    model: "Florence 2 Column 900mm",
    depthMM: 67,
    heightMM: 1002,
    widthMM: 975,
    volumeL: 18.9,
    nominalDeltaT50KW: 1.317,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 693,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-902-22",
    rangeType: "Florence 2",
    model: "Florence 2 Column 900mm",
    depthMM: 67,
    heightMM: 1002,
    widthMM: 1020,
    volumeL: 19.8,
    nominalDeltaT50KW: 1.379,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 720,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-902-23",
    rangeType: "Florence 2",
    model: "Florence 2 Column 900mm",
    depthMM: 67,
    heightMM: 1002,
    widthMM: 1065,
    volumeL: 20.7,
    nominalDeltaT50KW: 1.442,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 748,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-902-24",
    rangeType: "Florence 2",
    model: "Florence 2 Column 900mm",
    depthMM: 67,
    heightMM: 1002,
    widthMM: 1110,
    volumeL: 21.6,
    nominalDeltaT50KW: 1.505,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 775,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-902-25",
    rangeType: "Florence 2",
    model: "Florence 2 Column 900mm",
    depthMM: 67,
    heightMM: 1002,
    widthMM: 1155,
    volumeL: 22.5,
    nominalDeltaT50KW: 1.568,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 803,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-902-26",
    rangeType: "Florence 2",
    model: "Florence 2 Column 900mm",
    depthMM: 67,
    heightMM: 1002,
    widthMM: 1200,
    volumeL: 23.4,
    nominalDeltaT50KW: 1.63,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 830,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-902-27",
    rangeType: "Florence 2",
    model: "Florence 2 Column 900mm",
    depthMM: 67,
    heightMM: 1002,
    widthMM: 1245,
    volumeL: 24.3,
    nominalDeltaT50KW: 1.693,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 858,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-902-28",
    rangeType: "Florence 2",
    model: "Florence 2 Column 900mm",
    depthMM: 67,
    heightMM: 1002,
    widthMM: 1290,
    volumeL: 25.2,
    nominalDeltaT50KW: 1.756,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 885,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-902-29",
    rangeType: "Florence 2",
    model: "Florence 2 Column 900mm",
    depthMM: 67,
    heightMM: 1002,
    widthMM: 1335,
    volumeL: 26.1,
    nominalDeltaT50KW: 1.818,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 913,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-902-30",
    rangeType: "Florence 2",
    model: "Florence 2 Column 900mm",
    depthMM: 67,
    heightMM: 1002,
    widthMM: 1380,
    volumeL: 27,
    nominalDeltaT50KW: 1.881,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 940,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-902-31",
    rangeType: "Florence 2",
    model: "Florence 2 Column 900mm",
    depthMM: 67,
    heightMM: 1002,
    widthMM: 1425,
    volumeL: 27.9,
    nominalDeltaT50KW: 1.944,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 968,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-902-32",
    rangeType: "Florence 2",
    model: "Florence 2 Column 900mm",
    depthMM: 67,
    heightMM: 1002,
    widthMM: 1470,
    volumeL: 28.8,
    nominalDeltaT50KW: 2.006,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 995,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-902-33",
    rangeType: "Florence 2",
    model: "Florence 2 Column 900mm",
    depthMM: 67,
    heightMM: 1002,
    widthMM: 1515,
    volumeL: 29.7,
    nominalDeltaT50KW: 2.069,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1023,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-902-34",
    rangeType: "Florence 2",
    model: "Florence 2 Column 900mm",
    depthMM: 67,
    heightMM: 1002,
    widthMM: 1560,
    volumeL: 30.6,
    nominalDeltaT50KW: 2.132,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1050,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-902-35",
    rangeType: "Florence 2",
    model: "Florence 2 Column 900mm",
    depthMM: 67,
    heightMM: 1002,
    widthMM: 1605,
    volumeL: 31.5,
    nominalDeltaT50KW: 2.195,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1078,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-902-36",
    rangeType: "Florence 2",
    model: "Florence 2 Column 900mm",
    depthMM: 67,
    heightMM: 1002,
    widthMM: 1650,
    volumeL: 32.4,
    nominalDeltaT50KW: 2.257,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1105,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-902-37",
    rangeType: "Florence 2",
    model: "Florence 2 Column 900mm",
    depthMM: 67,
    heightMM: 1002,
    widthMM: 1695,
    volumeL: 33.3,
    nominalDeltaT50KW: 2.32,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1133,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-902-38",
    rangeType: "Florence 2",
    model: "Florence 2 Column 900mm",
    depthMM: 67,
    heightMM: 1002,
    widthMM: 1740,
    volumeL: 34.2,
    nominalDeltaT50KW: 2.383,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1160,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-902-39",
    rangeType: "Florence 2",
    model: "Florence 2 Column 900mm",
    depthMM: 67,
    heightMM: 1002,
    widthMM: 1785,
    volumeL: 35.1,
    nominalDeltaT50KW: 2.445,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1188,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F2C-902-40",
    rangeType: "Florence 2",
    model: "Florence 2 Column 900mm",
    depthMM: 67,
    heightMM: 1002,
    widthMM: 1830,
    volumeL: 36,
    nominalDeltaT50KW: 2.508,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1215,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1002-04",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1000mm",
    depthMM: 103,
    heightMM: 1102,
    widthMM: 210,
    volumeL: 6,
    nominalDeltaT50KW: 0.387,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 261,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1002-05",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1000mm",
    depthMM: 103,
    heightMM: 1102,
    widthMM: 255,
    volumeL: 7.5,
    nominalDeltaT50KW: 0.484,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 298,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1002-06",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1000mm",
    depthMM: 103,
    heightMM: 1102,
    widthMM: 300,
    volumeL: 9,
    nominalDeltaT50KW: 0.581,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 334,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1002-07",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1000mm",
    depthMM: 103,
    heightMM: 1102,
    widthMM: 345,
    volumeL: 10.5,
    nominalDeltaT50KW: 0.678,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 371,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1002-08",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1000mm",
    depthMM: 103,
    heightMM: 1102,
    widthMM: 390,
    volumeL: 12,
    nominalDeltaT50KW: 0.774,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 407,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1002-09",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1000mm",
    depthMM: 103,
    heightMM: 1102,
    widthMM: 435,
    volumeL: 13.5,
    nominalDeltaT50KW: 0.871,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 444,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1002-10",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1000mm",
    depthMM: 103,
    heightMM: 1102,
    widthMM: 480,
    volumeL: 15,
    nominalDeltaT50KW: 0.968,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 480,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1002-11",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1000mm",
    depthMM: 103,
    heightMM: 1102,
    widthMM: 525,
    volumeL: 16.5,
    nominalDeltaT50KW: 1.065,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 517,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1002-12",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1000mm",
    depthMM: 103,
    heightMM: 1102,
    widthMM: 570,
    volumeL: 18,
    nominalDeltaT50KW: 1.162,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 553,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1002-13",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1000mm",
    depthMM: 103,
    heightMM: 1102,
    widthMM: 615,
    volumeL: 19.5,
    nominalDeltaT50KW: 1.258,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 590,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1002-14",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1000mm",
    depthMM: 103,
    heightMM: 1102,
    widthMM: 660,
    volumeL: 21,
    nominalDeltaT50KW: 1.355,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 626,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1002-15",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1000mm",
    depthMM: 103,
    heightMM: 1102,
    widthMM: 705,
    volumeL: 22.5,
    nominalDeltaT50KW: 1.452,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 663,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1002-16",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1000mm",
    depthMM: 103,
    heightMM: 1102,
    widthMM: 750,
    volumeL: 24,
    nominalDeltaT50KW: 1.549,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 699,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1002-17",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1000mm",
    depthMM: 103,
    heightMM: 1102,
    widthMM: 795,
    volumeL: 25.5,
    nominalDeltaT50KW: 1.646,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 736,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1002-18",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1000mm",
    depthMM: 103,
    heightMM: 1102,
    widthMM: 840,
    volumeL: 27,
    nominalDeltaT50KW: 1.742,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 772,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1002-19",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1000mm",
    depthMM: 103,
    heightMM: 1102,
    widthMM: 885,
    volumeL: 28.5,
    nominalDeltaT50KW: 1.839,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 809,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1002-20",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1000mm",
    depthMM: 103,
    heightMM: 1102,
    widthMM: 930,
    volumeL: 30,
    nominalDeltaT50KW: 1.936,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 845,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1002-21",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1000mm",
    depthMM: 103,
    heightMM: 1102,
    widthMM: 975,
    volumeL: 31.5,
    nominalDeltaT50KW: 2.033,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 882,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1002-22",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1000mm",
    depthMM: 103,
    heightMM: 1102,
    widthMM: 1020,
    volumeL: 33,
    nominalDeltaT50KW: 2.13,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 918,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1002-23",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1000mm",
    depthMM: 103,
    heightMM: 1102,
    widthMM: 1065,
    volumeL: 34.5,
    nominalDeltaT50KW: 2.226,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 955,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1002-24",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1000mm",
    depthMM: 103,
    heightMM: 1102,
    widthMM: 1110,
    volumeL: 36,
    nominalDeltaT50KW: 2.323,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 991,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1002-25",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1000mm",
    depthMM: 103,
    heightMM: 1102,
    widthMM: 1155,
    volumeL: 37.5,
    nominalDeltaT50KW: 2.42,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1028,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1002-26",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1000mm",
    depthMM: 103,
    heightMM: 1102,
    widthMM: 1200,
    volumeL: 39,
    nominalDeltaT50KW: 2.517,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1064,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1002-27",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1000mm",
    depthMM: 103,
    heightMM: 1102,
    widthMM: 1245,
    volumeL: 40.5,
    nominalDeltaT50KW: 2.614,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1101,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1002-28",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1000mm",
    depthMM: 103,
    heightMM: 1102,
    widthMM: 1290,
    volumeL: 42,
    nominalDeltaT50KW: 2.71,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1137,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1002-29",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1000mm",
    depthMM: 103,
    heightMM: 1102,
    widthMM: 1335,
    volumeL: 43.5,
    nominalDeltaT50KW: 2.807,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1174,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1002-30",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1000mm",
    depthMM: 103,
    heightMM: 1102,
    widthMM: 1380,
    volumeL: 45,
    nominalDeltaT50KW: 2.904,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1210,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1002-31",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1000mm",
    depthMM: 103,
    heightMM: 1102,
    widthMM: 1425,
    volumeL: 46.5,
    nominalDeltaT50KW: 3.001,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1247,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1002-32",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1000mm",
    depthMM: 103,
    heightMM: 1102,
    widthMM: 1470,
    volumeL: 48,
    nominalDeltaT50KW: 3.098,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1283,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1002-33",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1000mm",
    depthMM: 103,
    heightMM: 1102,
    widthMM: 1515,
    volumeL: 49.5,
    nominalDeltaT50KW: 3.194,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1320,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1002-34",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1000mm",
    depthMM: 103,
    heightMM: 1102,
    widthMM: 1560,
    volumeL: 51,
    nominalDeltaT50KW: 3.291,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1356,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1002-35",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1000mm",
    depthMM: 103,
    heightMM: 1102,
    widthMM: 1605,
    volumeL: 52.5,
    nominalDeltaT50KW: 3.388,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1393,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1002-36",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1000mm",
    depthMM: 103,
    heightMM: 1102,
    widthMM: 1650,
    volumeL: 54,
    nominalDeltaT50KW: 3.485,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1429,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1002-37",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1000mm",
    depthMM: 103,
    heightMM: 1102,
    widthMM: 1695,
    volumeL: 55.5,
    nominalDeltaT50KW: 3.582,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1466,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1002-38",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1000mm",
    depthMM: 103,
    heightMM: 1102,
    widthMM: 1740,
    volumeL: 57,
    nominalDeltaT50KW: 3.678,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1502,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1002-39",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1000mm",
    depthMM: 103,
    heightMM: 1102,
    widthMM: 1785,
    volumeL: 58.5,
    nominalDeltaT50KW: 3.775,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1539,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1002-40",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1000mm",
    depthMM: 103,
    heightMM: 1102,
    widthMM: 1830,
    volumeL: 60,
    nominalDeltaT50KW: 3.872,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1575,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1202-04",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1200mm",
    depthMM: 103,
    heightMM: 1302,
    widthMM: 210,
    volumeL: 6.8,
    nominalDeltaT50KW: 0.459,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 309,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1202-05",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1200mm",
    depthMM: 103,
    heightMM: 1302,
    widthMM: 255,
    volumeL: 8.5,
    nominalDeltaT50KW: 0.574,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 358,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1202-06",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1200mm",
    depthMM: 103,
    heightMM: 1302,
    widthMM: 300,
    volumeL: 10.2,
    nominalDeltaT50KW: 0.689,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 406,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1202-07",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1200mm",
    depthMM: 103,
    heightMM: 1302,
    widthMM: 345,
    volumeL: 11.9,
    nominalDeltaT50KW: 0.804,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 455,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1202-08",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1200mm",
    depthMM: 103,
    heightMM: 1302,
    widthMM: 390,
    volumeL: 13.6,
    nominalDeltaT50KW: 0.918,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 503,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1202-09",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1200mm",
    depthMM: 103,
    heightMM: 1302,
    widthMM: 435,
    volumeL: 15.3,
    nominalDeltaT50KW: 1.033,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 552,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1202-10",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1200mm",
    depthMM: 103,
    heightMM: 1302,
    widthMM: 480,
    volumeL: 17,
    nominalDeltaT50KW: 1.148,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 600,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1202-11",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1200mm",
    depthMM: 103,
    heightMM: 1302,
    widthMM: 525,
    volumeL: 18.7,
    nominalDeltaT50KW: 1.263,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 649,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1202-12",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1200mm",
    depthMM: 103,
    heightMM: 1302,
    widthMM: 570,
    volumeL: 20.4,
    nominalDeltaT50KW: 1.378,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 697,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1202-13",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1200mm",
    depthMM: 103,
    heightMM: 1302,
    widthMM: 615,
    volumeL: 22.1,
    nominalDeltaT50KW: 1.492,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 746,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1202-14",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1200mm",
    depthMM: 103,
    heightMM: 1302,
    widthMM: 660,
    volumeL: 23.8,
    nominalDeltaT50KW: 1.607,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 794,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1202-15",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1200mm",
    depthMM: 103,
    heightMM: 1302,
    widthMM: 705,
    volumeL: 25.5,
    nominalDeltaT50KW: 1.722,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 843,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1202-16",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1200mm",
    depthMM: 103,
    heightMM: 1302,
    widthMM: 750,
    volumeL: 27.2,
    nominalDeltaT50KW: 1.837,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 891,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1202-17",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1200mm",
    depthMM: 103,
    heightMM: 1302,
    widthMM: 795,
    volumeL: 28.9,
    nominalDeltaT50KW: 1.952,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 940,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1202-18",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1200mm",
    depthMM: 103,
    heightMM: 1302,
    widthMM: 840,
    volumeL: 30.6,
    nominalDeltaT50KW: 2.066,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 988,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1202-19",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1200mm",
    depthMM: 103,
    heightMM: 1302,
    widthMM: 885,
    volumeL: 32.3,
    nominalDeltaT50KW: 2.181,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1037,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1202-20",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1200mm",
    depthMM: 103,
    heightMM: 1302,
    widthMM: 930,
    volumeL: 34,
    nominalDeltaT50KW: 2.296,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1085,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1202-21",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1200mm",
    depthMM: 103,
    heightMM: 1302,
    widthMM: 975,
    volumeL: 35.7,
    nominalDeltaT50KW: 2.411,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1134,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1202-22",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1200mm",
    depthMM: 103,
    heightMM: 1302,
    widthMM: 1020,
    volumeL: 37.4,
    nominalDeltaT50KW: 2.526,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1182,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1202-23",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1200mm",
    depthMM: 103,
    heightMM: 1302,
    widthMM: 1065,
    volumeL: 39.1,
    nominalDeltaT50KW: 2.64,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1231,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1202-24",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1200mm",
    depthMM: 103,
    heightMM: 1302,
    widthMM: 1110,
    volumeL: 40.8,
    nominalDeltaT50KW: 2.755,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1279,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1202-25",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1200mm",
    depthMM: 103,
    heightMM: 1302,
    widthMM: 1155,
    volumeL: 42.5,
    nominalDeltaT50KW: 2.87,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1328,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1202-26",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1200mm",
    depthMM: 103,
    heightMM: 1302,
    widthMM: 1200,
    volumeL: 44.2,
    nominalDeltaT50KW: 2.985,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1376,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1202-27",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1200mm",
    depthMM: 103,
    heightMM: 1302,
    widthMM: 1245,
    volumeL: 45.9,
    nominalDeltaT50KW: 3.1,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1425,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1202-28",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1200mm",
    depthMM: 103,
    heightMM: 1302,
    widthMM: 1290,
    volumeL: 47.6,
    nominalDeltaT50KW: 3.214,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1473,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1202-29",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1200mm",
    depthMM: 103,
    heightMM: 1302,
    widthMM: 1335,
    volumeL: 49.3,
    nominalDeltaT50KW: 3.329,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1522,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1202-30",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1200mm",
    depthMM: 103,
    heightMM: 1302,
    widthMM: 1380,
    volumeL: 51,
    nominalDeltaT50KW: 3.444,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1570,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1202-31",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1200mm",
    depthMM: 103,
    heightMM: 1302,
    widthMM: 1425,
    volumeL: 52.7,
    nominalDeltaT50KW: 3.559,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1619,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1202-32",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1200mm",
    depthMM: 103,
    heightMM: 1302,
    widthMM: 1470,
    volumeL: 54.4,
    nominalDeltaT50KW: 3.674,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1667,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1202-33",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1200mm",
    depthMM: 103,
    heightMM: 1302,
    widthMM: 1515,
    volumeL: 56.1,
    nominalDeltaT50KW: 3.788,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1716,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1202-34",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1200mm",
    depthMM: 103,
    heightMM: 1302,
    widthMM: 1560,
    volumeL: 57.8,
    nominalDeltaT50KW: 3.903,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1764,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1202-35",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1200mm",
    depthMM: 103,
    heightMM: 1302,
    widthMM: 1605,
    volumeL: 59.5,
    nominalDeltaT50KW: 4.018,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1813,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1202-36",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1200mm",
    depthMM: 103,
    heightMM: 1302,
    widthMM: 1650,
    volumeL: 61.2,
    nominalDeltaT50KW: 4.133,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1861,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1202-37",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1200mm",
    depthMM: 103,
    heightMM: 1302,
    widthMM: 1695,
    volumeL: 62.9,
    nominalDeltaT50KW: 4.248,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1910,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1202-38",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1200mm",
    depthMM: 103,
    heightMM: 1302,
    widthMM: 1740,
    volumeL: 64.6,
    nominalDeltaT50KW: 4.362,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1958,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1202-39",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1200mm",
    depthMM: 103,
    heightMM: 1302,
    widthMM: 1785,
    volumeL: 66.3,
    nominalDeltaT50KW: 4.477,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2007,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1202-40",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1200mm",
    depthMM: 103,
    heightMM: 1302,
    widthMM: 1830,
    volumeL: 68,
    nominalDeltaT50KW: 4.592,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2055,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1502-04",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1500mm",
    depthMM: 103,
    heightMM: 1602,
    widthMM: 210,
    volumeL: 8.28,
    nominalDeltaT50KW: 0.567,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 373,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1502-05",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1500mm",
    depthMM: 103,
    heightMM: 1602,
    widthMM: 255,
    volumeL: 10.35,
    nominalDeltaT50KW: 0.709,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 438,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1502-06",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1500mm",
    depthMM: 103,
    heightMM: 1602,
    widthMM: 300,
    volumeL: 12.42,
    nominalDeltaT50KW: 0.85,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 502,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1502-07",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1500mm",
    depthMM: 103,
    heightMM: 1602,
    widthMM: 345,
    volumeL: 14.49,
    nominalDeltaT50KW: 0.992,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 567,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1502-08",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1500mm",
    depthMM: 103,
    heightMM: 1602,
    widthMM: 390,
    volumeL: 16.56,
    nominalDeltaT50KW: 1.134,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 631,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1502-09",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1500mm",
    depthMM: 103,
    heightMM: 1602,
    widthMM: 435,
    volumeL: 18.63,
    nominalDeltaT50KW: 1.275,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 696,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1502-10",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1500mm",
    depthMM: 103,
    heightMM: 1602,
    widthMM: 480,
    volumeL: 20.7,
    nominalDeltaT50KW: 1.417,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 760,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1502-11",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1500mm",
    depthMM: 103,
    heightMM: 1602,
    widthMM: 525,
    volumeL: 22.77,
    nominalDeltaT50KW: 1.559,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 825,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1502-12",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1500mm",
    depthMM: 103,
    heightMM: 1602,
    widthMM: 570,
    volumeL: 24.84,
    nominalDeltaT50KW: 1.7,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 889,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1502-13",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1500mm",
    depthMM: 103,
    heightMM: 1602,
    widthMM: 615,
    volumeL: 26.91,
    nominalDeltaT50KW: 1.842,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 954,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1502-14",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1500mm",
    depthMM: 103,
    heightMM: 1602,
    widthMM: 660,
    volumeL: 28.98,
    nominalDeltaT50KW: 1.984,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1018,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1502-15",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1500mm",
    depthMM: 103,
    heightMM: 1602,
    widthMM: 705,
    volumeL: 31.05,
    nominalDeltaT50KW: 2.126,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1083,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1502-16",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1500mm",
    depthMM: 103,
    heightMM: 1602,
    widthMM: 750,
    volumeL: 33.12,
    nominalDeltaT50KW: 2.267,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1147,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1502-17",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1500mm",
    depthMM: 103,
    heightMM: 1602,
    widthMM: 795,
    volumeL: 35.19,
    nominalDeltaT50KW: 2.409,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1212,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1502-18",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1500mm",
    depthMM: 103,
    heightMM: 1602,
    widthMM: 840,
    volumeL: 37.26,
    nominalDeltaT50KW: 2.551,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1276,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1502-19",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1500mm",
    depthMM: 103,
    heightMM: 1602,
    widthMM: 885,
    volumeL: 39.33,
    nominalDeltaT50KW: 2.692,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1341,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1502-20",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1500mm",
    depthMM: 103,
    heightMM: 1602,
    widthMM: 930,
    volumeL: 41.4,
    nominalDeltaT50KW: 2.834,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1405,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1502-21",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1500mm",
    depthMM: 103,
    heightMM: 1602,
    widthMM: 975,
    volumeL: 43.47,
    nominalDeltaT50KW: 2.976,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1470,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1502-22",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1500mm",
    depthMM: 103,
    heightMM: 1602,
    widthMM: 1020,
    volumeL: 45.54,
    nominalDeltaT50KW: 3.117,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1534,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1502-23",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1500mm",
    depthMM: 103,
    heightMM: 1602,
    widthMM: 1065,
    volumeL: 47.61,
    nominalDeltaT50KW: 3.259,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1599,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1502-24",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1500mm",
    depthMM: 103,
    heightMM: 1602,
    widthMM: 1110,
    volumeL: 49.68,
    nominalDeltaT50KW: 3.401,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1663,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1502-25",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1500mm",
    depthMM: 103,
    heightMM: 1602,
    widthMM: 1155,
    volumeL: 51.75,
    nominalDeltaT50KW: 3.543,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1728,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1502-26",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1500mm",
    depthMM: 103,
    heightMM: 1602,
    widthMM: 1200,
    volumeL: 53.82,
    nominalDeltaT50KW: 3.684,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1792,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1502-27",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1500mm",
    depthMM: 103,
    heightMM: 1602,
    widthMM: 1245,
    volumeL: 55.89,
    nominalDeltaT50KW: 3.826,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1857,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1502-28",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1500mm",
    depthMM: 103,
    heightMM: 1602,
    widthMM: 1290,
    volumeL: 57.96,
    nominalDeltaT50KW: 3.968,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1921,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1502-29",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1500mm",
    depthMM: 103,
    heightMM: 1602,
    widthMM: 1335,
    volumeL: 60.03,
    nominalDeltaT50KW: 4.109,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1986,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1502-30",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1500mm",
    depthMM: 103,
    heightMM: 1602,
    widthMM: 1380,
    volumeL: 62.1,
    nominalDeltaT50KW: 4.251,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2050,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1502-31",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1500mm",
    depthMM: 103,
    heightMM: 1602,
    widthMM: 1425,
    volumeL: 64.17,
    nominalDeltaT50KW: 4.393,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2115,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1502-32",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1500mm",
    depthMM: 103,
    heightMM: 1602,
    widthMM: 1470,
    volumeL: 66.24,
    nominalDeltaT50KW: 4.534,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2179,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1502-33",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1500mm",
    depthMM: 103,
    heightMM: 1602,
    widthMM: 1515,
    volumeL: 68.31,
    nominalDeltaT50KW: 4.676,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2244,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1502-34",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1500mm",
    depthMM: 103,
    heightMM: 1602,
    widthMM: 1560,
    volumeL: 70.38,
    nominalDeltaT50KW: 4.818,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2308,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1502-35",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1500mm",
    depthMM: 103,
    heightMM: 1602,
    widthMM: 1605,
    volumeL: 72.45,
    nominalDeltaT50KW: 4.96,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2373,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1502-36",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1500mm",
    depthMM: 103,
    heightMM: 1602,
    widthMM: 1650,
    volumeL: 74.52,
    nominalDeltaT50KW: 5.101,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2437,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1502-37",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1500mm",
    depthMM: 103,
    heightMM: 1602,
    widthMM: 1695,
    volumeL: 76.59,
    nominalDeltaT50KW: 5.243,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2502,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1502-38",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1500mm",
    depthMM: 103,
    heightMM: 1602,
    widthMM: 1740,
    volumeL: 78.66,
    nominalDeltaT50KW: 5.385,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2566,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1502-39",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1500mm",
    depthMM: 103,
    heightMM: 1602,
    widthMM: 1785,
    volumeL: 80.73,
    nominalDeltaT50KW: 5.526,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2631,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1502-40",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1500mm",
    depthMM: 103,
    heightMM: 1602,
    widthMM: 1830,
    volumeL: 82.8,
    nominalDeltaT50KW: 5.668,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2695,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1802-04",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1800mm",
    depthMM: 103,
    heightMM: 1902,
    widthMM: 210,
    volumeL: 9.72,
    nominalDeltaT50KW: 0.676,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 413,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1802-05",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1800mm",
    depthMM: 103,
    heightMM: 1902,
    widthMM: 255,
    volumeL: 12.15,
    nominalDeltaT50KW: 0.845,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 488,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1802-06",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1800mm",
    depthMM: 103,
    heightMM: 1902,
    widthMM: 300,
    volumeL: 14.58,
    nominalDeltaT50KW: 1.013,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 562,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1802-07",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1800mm",
    depthMM: 103,
    heightMM: 1902,
    widthMM: 345,
    volumeL: 17.01,
    nominalDeltaT50KW: 1.182,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 637,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1802-08",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1800mm",
    depthMM: 103,
    heightMM: 1902,
    widthMM: 390,
    volumeL: 19.44,
    nominalDeltaT50KW: 1.351,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 711,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1802-09",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1800mm",
    depthMM: 103,
    heightMM: 1902,
    widthMM: 435,
    volumeL: 21.87,
    nominalDeltaT50KW: 1.52,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 786,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1802-10",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1800mm",
    depthMM: 103,
    heightMM: 1902,
    widthMM: 480,
    volumeL: 24.3,
    nominalDeltaT50KW: 1.689,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 860,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1802-11",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1800mm",
    depthMM: 103,
    heightMM: 1902,
    widthMM: 525,
    volumeL: 26.73,
    nominalDeltaT50KW: 1.858,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 935,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1802-12",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1800mm",
    depthMM: 103,
    heightMM: 1902,
    widthMM: 570,
    volumeL: 29.16,
    nominalDeltaT50KW: 2.027,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1009,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1802-13",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1800mm",
    depthMM: 103,
    heightMM: 1902,
    widthMM: 615,
    volumeL: 31.59,
    nominalDeltaT50KW: 2.196,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1084,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1802-14",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1800mm",
    depthMM: 103,
    heightMM: 1902,
    widthMM: 660,
    volumeL: 34.02,
    nominalDeltaT50KW: 2.365,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1158,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1802-15",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1800mm",
    depthMM: 103,
    heightMM: 1902,
    widthMM: 705,
    volumeL: 36.45,
    nominalDeltaT50KW: 2.534,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1233,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1802-16",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1800mm",
    depthMM: 103,
    heightMM: 1902,
    widthMM: 750,
    volumeL: 38.88,
    nominalDeltaT50KW: 2.702,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1307,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1802-17",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1800mm",
    depthMM: 103,
    heightMM: 1902,
    widthMM: 795,
    volumeL: 41.31,
    nominalDeltaT50KW: 2.871,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1382,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1802-18",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1800mm",
    depthMM: 103,
    heightMM: 1902,
    widthMM: 840,
    volumeL: 43.74,
    nominalDeltaT50KW: 3.04,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1456,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1802-19",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1800mm",
    depthMM: 103,
    heightMM: 1902,
    widthMM: 885,
    volumeL: 46.17,
    nominalDeltaT50KW: 3.209,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1531,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1802-20",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1800mm",
    depthMM: 103,
    heightMM: 1902,
    widthMM: 930,
    volumeL: 48.6,
    nominalDeltaT50KW: 3.378,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1605,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1802-21",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1800mm",
    depthMM: 103,
    heightMM: 1902,
    widthMM: 975,
    volumeL: 51.03,
    nominalDeltaT50KW: 3.547,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1680,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1802-22",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1800mm",
    depthMM: 103,
    heightMM: 1902,
    widthMM: 1020,
    volumeL: 53.46,
    nominalDeltaT50KW: 3.716,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1754,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1802-23",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1800mm",
    depthMM: 103,
    heightMM: 1902,
    widthMM: 1065,
    volumeL: 55.89,
    nominalDeltaT50KW: 3.885,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1829,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1802-24",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1800mm",
    depthMM: 103,
    heightMM: 1902,
    widthMM: 1110,
    volumeL: 58.32,
    nominalDeltaT50KW: 4.054,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1903,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1802-25",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1800mm",
    depthMM: 103,
    heightMM: 1902,
    widthMM: 1155,
    volumeL: 60.75,
    nominalDeltaT50KW: 4.223,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1978,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1802-26",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1800mm",
    depthMM: 103,
    heightMM: 1902,
    widthMM: 1200,
    volumeL: 63.18,
    nominalDeltaT50KW: 4.391,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2052,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1802-27",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1800mm",
    depthMM: 103,
    heightMM: 1902,
    widthMM: 1245,
    volumeL: 65.61,
    nominalDeltaT50KW: 4.56,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2127,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1802-28",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1800mm",
    depthMM: 103,
    heightMM: 1902,
    widthMM: 1290,
    volumeL: 68.04,
    nominalDeltaT50KW: 4.729,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2201,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1802-29",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1800mm",
    depthMM: 103,
    heightMM: 1902,
    widthMM: 1335,
    volumeL: 70.47,
    nominalDeltaT50KW: 4.898,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2276,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1802-30",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1800mm",
    depthMM: 103,
    heightMM: 1902,
    widthMM: 1380,
    volumeL: 72.9,
    nominalDeltaT50KW: 5.067,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2350,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1802-31",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1800mm",
    depthMM: 103,
    heightMM: 1902,
    widthMM: 1425,
    volumeL: 75.33,
    nominalDeltaT50KW: 5.236,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2425,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1802-32",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1800mm",
    depthMM: 103,
    heightMM: 1902,
    widthMM: 1470,
    volumeL: 77.76,
    nominalDeltaT50KW: 5.405,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2499,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1802-33",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1800mm",
    depthMM: 103,
    heightMM: 1902,
    widthMM: 1515,
    volumeL: 80.19,
    nominalDeltaT50KW: 5.574,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2574,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1802-34",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1800mm",
    depthMM: 103,
    heightMM: 1902,
    widthMM: 1560,
    volumeL: 82.62,
    nominalDeltaT50KW: 5.743,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2648,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1802-35",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1800mm",
    depthMM: 103,
    heightMM: 1902,
    widthMM: 1605,
    volumeL: 85.05,
    nominalDeltaT50KW: 5.912,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2723,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1802-36",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1800mm",
    depthMM: 103,
    heightMM: 1902,
    widthMM: 1650,
    volumeL: 87.48,
    nominalDeltaT50KW: 6.08,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2797,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1802-37",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1800mm",
    depthMM: 103,
    heightMM: 1902,
    widthMM: 1695,
    volumeL: 89.91,
    nominalDeltaT50KW: 6.249,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2872,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1802-38",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1800mm",
    depthMM: 103,
    heightMM: 1902,
    widthMM: 1740,
    volumeL: 92.34,
    nominalDeltaT50KW: 6.418,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2946,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1802-39",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1800mm",
    depthMM: 103,
    heightMM: 1902,
    widthMM: 1785,
    volumeL: 94.77,
    nominalDeltaT50KW: 6.587,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 3021,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-1802-40",
    rangeType: "Florence 3",
    model: "Florence 3 Column 1800mm",
    depthMM: 103,
    heightMM: 1902,
    widthMM: 1830,
    volumeL: 97.2,
    nominalDeltaT50KW: 6.756,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 3095,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2002-04",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2000mm",
    depthMM: 103,
    heightMM: 2102,
    widthMM: 210,
    volumeL: 10.72,
    nominalDeltaT50KW: 0.749,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 433,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2002-05",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2000mm",
    depthMM: 103,
    heightMM: 2102,
    widthMM: 255,
    volumeL: 13.4,
    nominalDeltaT50KW: 0.936,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 513,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2002-06",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2000mm",
    depthMM: 103,
    heightMM: 2102,
    widthMM: 300,
    volumeL: 16.08,
    nominalDeltaT50KW: 1.123,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 592,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2002-07",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2000mm",
    depthMM: 103,
    heightMM: 2102,
    widthMM: 345,
    volumeL: 18.76,
    nominalDeltaT50KW: 1.31,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 672,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2002-08",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2000mm",
    depthMM: 103,
    heightMM: 2102,
    widthMM: 390,
    volumeL: 21.44,
    nominalDeltaT50KW: 1.498,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 751,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2002-09",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2000mm",
    depthMM: 103,
    heightMM: 2102,
    widthMM: 435,
    volumeL: 24.12,
    nominalDeltaT50KW: 1.685,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 831,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2002-10",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2000mm",
    depthMM: 103,
    heightMM: 2102,
    widthMM: 480,
    volumeL: 26.8,
    nominalDeltaT50KW: 1.872,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 910,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2002-11",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2000mm",
    depthMM: 103,
    heightMM: 2102,
    widthMM: 525,
    volumeL: 29.48,
    nominalDeltaT50KW: 2.059,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 990,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2002-12",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2000mm",
    depthMM: 103,
    heightMM: 2102,
    widthMM: 570,
    volumeL: 32.16,
    nominalDeltaT50KW: 2.246,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1069,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2002-13",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2000mm",
    depthMM: 103,
    heightMM: 2102,
    widthMM: 615,
    volumeL: 34.84,
    nominalDeltaT50KW: 2.434,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1149,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2002-14",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2000mm",
    depthMM: 103,
    heightMM: 2102,
    widthMM: 660,
    volumeL: 37.52,
    nominalDeltaT50KW: 2.621,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1228,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2002-15",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2000mm",
    depthMM: 103,
    heightMM: 2102,
    widthMM: 705,
    volumeL: 40.2,
    nominalDeltaT50KW: 2.808,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1308,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2002-16",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2000mm",
    depthMM: 103,
    heightMM: 2102,
    widthMM: 750,
    volumeL: 42.88,
    nominalDeltaT50KW: 2.995,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1387,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2002-17",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2000mm",
    depthMM: 103,
    heightMM: 2102,
    widthMM: 795,
    volumeL: 45.56,
    nominalDeltaT50KW: 3.182,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1467,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2002-18",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2000mm",
    depthMM: 103,
    heightMM: 2102,
    widthMM: 840,
    volumeL: 48.24,
    nominalDeltaT50KW: 3.37,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1546,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2002-19",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2000mm",
    depthMM: 103,
    heightMM: 2102,
    widthMM: 885,
    volumeL: 50.92,
    nominalDeltaT50KW: 3.557,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1626,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2002-20",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2000mm",
    depthMM: 103,
    heightMM: 2102,
    widthMM: 930,
    volumeL: 53.6,
    nominalDeltaT50KW: 3.744,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1705,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2002-21",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2000mm",
    depthMM: 103,
    heightMM: 2102,
    widthMM: 975,
    volumeL: 56.28,
    nominalDeltaT50KW: 3.931,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1785,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2002-22",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2000mm",
    depthMM: 103,
    heightMM: 2102,
    widthMM: 1020,
    volumeL: 58.96,
    nominalDeltaT50KW: 4.118,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1864,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2002-23",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2000mm",
    depthMM: 103,
    heightMM: 2102,
    widthMM: 1065,
    volumeL: 61.64,
    nominalDeltaT50KW: 4.306,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1944,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2002-24",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2000mm",
    depthMM: 103,
    heightMM: 2102,
    widthMM: 1110,
    volumeL: 64.32,
    nominalDeltaT50KW: 4.493,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2023,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2002-25",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2000mm",
    depthMM: 103,
    heightMM: 2102,
    widthMM: 1155,
    volumeL: 67,
    nominalDeltaT50KW: 4.68,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2103,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2002-26",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2000mm",
    depthMM: 103,
    heightMM: 2102,
    widthMM: 1200,
    volumeL: 69.68,
    nominalDeltaT50KW: 4.867,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2182,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2002-27",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2000mm",
    depthMM: 103,
    heightMM: 2102,
    widthMM: 1245,
    volumeL: 72.36,
    nominalDeltaT50KW: 5.054,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2262,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2002-28",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2000mm",
    depthMM: 103,
    heightMM: 2102,
    widthMM: 1290,
    volumeL: 75.04,
    nominalDeltaT50KW: 5.242,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2341,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2002-29",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2000mm",
    depthMM: 103,
    heightMM: 2102,
    widthMM: 1335,
    volumeL: 77.72,
    nominalDeltaT50KW: 5.429,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2421,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2002-30",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2000mm",
    depthMM: 103,
    heightMM: 2102,
    widthMM: 1380,
    volumeL: 80.4,
    nominalDeltaT50KW: 5.616,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2500,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2002-31",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2000mm",
    depthMM: 103,
    heightMM: 2102,
    widthMM: 1425,
    volumeL: 83.08,
    nominalDeltaT50KW: 5.803,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2580,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2002-32",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2000mm",
    depthMM: 103,
    heightMM: 2102,
    widthMM: 1470,
    volumeL: 85.76,
    nominalDeltaT50KW: 5.99,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2659,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2002-33",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2000mm",
    depthMM: 103,
    heightMM: 2102,
    widthMM: 1515,
    volumeL: 88.44,
    nominalDeltaT50KW: 6.178,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2739,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2002-34",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2000mm",
    depthMM: 103,
    heightMM: 2102,
    widthMM: 1560,
    volumeL: 91.12,
    nominalDeltaT50KW: 6.365,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2818,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2002-35",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2000mm",
    depthMM: 103,
    heightMM: 2102,
    widthMM: 1605,
    volumeL: 93.8,
    nominalDeltaT50KW: 6.552,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2898,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2002-36",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2000mm",
    depthMM: 103,
    heightMM: 2102,
    widthMM: 1650,
    volumeL: 96.48,
    nominalDeltaT50KW: 6.739,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2977,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2002-37",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2000mm",
    depthMM: 103,
    heightMM: 2102,
    widthMM: 1695,
    volumeL: 99.16,
    nominalDeltaT50KW: 6.926,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 3057,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2002-38",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2000mm",
    depthMM: 103,
    heightMM: 2102,
    widthMM: 1740,
    volumeL: 101.84,
    nominalDeltaT50KW: 7.114,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 3136,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2002-39",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2000mm",
    depthMM: 103,
    heightMM: 2102,
    widthMM: 1785,
    volumeL: 104.52,
    nominalDeltaT50KW: 7.301,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 3216,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2002-40",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2000mm",
    depthMM: 103,
    heightMM: 2102,
    widthMM: 1830,
    volumeL: 107.2,
    nominalDeltaT50KW: 7.488,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 3295,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-194-04",
    rangeType: "Florence 3",
    model: "Florence 3 Column 200mm",
    depthMM: 103,
    heightMM: 294,
    widthMM: 210,
    volumeL: 2,
    nominalDeltaT50KW: 0.081,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 213,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-194-05",
    rangeType: "Florence 3",
    model: "Florence 3 Column 200mm",
    depthMM: 103,
    heightMM: 294,
    widthMM: 255,
    volumeL: 2.5,
    nominalDeltaT50KW: 0.102,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 238,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-194-06",
    rangeType: "Florence 3",
    model: "Florence 3 Column 200mm",
    depthMM: 103,
    heightMM: 294,
    widthMM: 300,
    volumeL: 3,
    nominalDeltaT50KW: 0.122,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 262,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-194-07",
    rangeType: "Florence 3",
    model: "Florence 3 Column 200mm",
    depthMM: 103,
    heightMM: 294,
    widthMM: 345,
    volumeL: 3.5,
    nominalDeltaT50KW: 0.142,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 287,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-194-08",
    rangeType: "Florence 3",
    model: "Florence 3 Column 200mm",
    depthMM: 103,
    heightMM: 294,
    widthMM: 390,
    volumeL: 4,
    nominalDeltaT50KW: 0.162,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 311,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-194-09",
    rangeType: "Florence 3",
    model: "Florence 3 Column 200mm",
    depthMM: 103,
    heightMM: 294,
    widthMM: 435,
    volumeL: 4.5,
    nominalDeltaT50KW: 0.183,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 336,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-194-10",
    rangeType: "Florence 3",
    model: "Florence 3 Column 200mm",
    depthMM: 103,
    heightMM: 294,
    widthMM: 480,
    volumeL: 5,
    nominalDeltaT50KW: 0.203,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 360,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-194-11",
    rangeType: "Florence 3",
    model: "Florence 3 Column 200mm",
    depthMM: 103,
    heightMM: 294,
    widthMM: 525,
    volumeL: 5.5,
    nominalDeltaT50KW: 0.223,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 385,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-194-12",
    rangeType: "Florence 3",
    model: "Florence 3 Column 200mm",
    depthMM: 103,
    heightMM: 294,
    widthMM: 570,
    volumeL: 6,
    nominalDeltaT50KW: 0.244,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 409,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-194-13",
    rangeType: "Florence 3",
    model: "Florence 3 Column 200mm",
    depthMM: 103,
    heightMM: 294,
    widthMM: 615,
    volumeL: 6.5,
    nominalDeltaT50KW: 0.264,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 434,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-194-14",
    rangeType: "Florence 3",
    model: "Florence 3 Column 200mm",
    depthMM: 103,
    heightMM: 294,
    widthMM: 660,
    volumeL: 7,
    nominalDeltaT50KW: 0.284,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 458,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-194-15",
    rangeType: "Florence 3",
    model: "Florence 3 Column 200mm",
    depthMM: 103,
    heightMM: 294,
    widthMM: 705,
    volumeL: 7.5,
    nominalDeltaT50KW: 0.305,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 483,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-194-16",
    rangeType: "Florence 3",
    model: "Florence 3 Column 200mm",
    depthMM: 103,
    heightMM: 294,
    widthMM: 750,
    volumeL: 8,
    nominalDeltaT50KW: 0.325,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 507,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-194-17",
    rangeType: "Florence 3",
    model: "Florence 3 Column 200mm",
    depthMM: 103,
    heightMM: 294,
    widthMM: 795,
    volumeL: 8.5,
    nominalDeltaT50KW: 0.345,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 532,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-194-18",
    rangeType: "Florence 3",
    model: "Florence 3 Column 200mm",
    depthMM: 103,
    heightMM: 294,
    widthMM: 840,
    volumeL: 9,
    nominalDeltaT50KW: 0.365,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 556,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-194-19",
    rangeType: "Florence 3",
    model: "Florence 3 Column 200mm",
    depthMM: 103,
    heightMM: 294,
    widthMM: 885,
    volumeL: 9.5,
    nominalDeltaT50KW: 0.386,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 581,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-194-20",
    rangeType: "Florence 3",
    model: "Florence 3 Column 200mm",
    depthMM: 103,
    heightMM: 294,
    widthMM: 930,
    volumeL: 10,
    nominalDeltaT50KW: 0.406,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 605,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-194-21",
    rangeType: "Florence 3",
    model: "Florence 3 Column 200mm",
    depthMM: 103,
    heightMM: 294,
    widthMM: 975,
    volumeL: 10.5,
    nominalDeltaT50KW: 0.426,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 630,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-194-22",
    rangeType: "Florence 3",
    model: "Florence 3 Column 200mm",
    depthMM: 103,
    heightMM: 294,
    widthMM: 1020,
    volumeL: 11,
    nominalDeltaT50KW: 0.447,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 654,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-194-23",
    rangeType: "Florence 3",
    model: "Florence 3 Column 200mm",
    depthMM: 103,
    heightMM: 294,
    widthMM: 1065,
    volumeL: 11.5,
    nominalDeltaT50KW: 0.467,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 679,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-194-24",
    rangeType: "Florence 3",
    model: "Florence 3 Column 200mm",
    depthMM: 103,
    heightMM: 294,
    widthMM: 1110,
    volumeL: 12,
    nominalDeltaT50KW: 0.487,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 703,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-194-25",
    rangeType: "Florence 3",
    model: "Florence 3 Column 200mm",
    depthMM: 103,
    heightMM: 294,
    widthMM: 1155,
    volumeL: 12.5,
    nominalDeltaT50KW: 0.508,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 728,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-194-26",
    rangeType: "Florence 3",
    model: "Florence 3 Column 200mm",
    depthMM: 103,
    heightMM: 294,
    widthMM: 1200,
    volumeL: 13,
    nominalDeltaT50KW: 0.528,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 752,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-194-27",
    rangeType: "Florence 3",
    model: "Florence 3 Column 200mm",
    depthMM: 103,
    heightMM: 294,
    widthMM: 1245,
    volumeL: 13.5,
    nominalDeltaT50KW: 0.548,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 777,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-194-28",
    rangeType: "Florence 3",
    model: "Florence 3 Column 200mm",
    depthMM: 103,
    heightMM: 294,
    widthMM: 1290,
    volumeL: 14,
    nominalDeltaT50KW: 0.568,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 801,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-194-29",
    rangeType: "Florence 3",
    model: "Florence 3 Column 200mm",
    depthMM: 103,
    heightMM: 294,
    widthMM: 1335,
    volumeL: 14.5,
    nominalDeltaT50KW: 0.589,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 826,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-194-30",
    rangeType: "Florence 3",
    model: "Florence 3 Column 200mm",
    depthMM: 103,
    heightMM: 294,
    widthMM: 1380,
    volumeL: 15,
    nominalDeltaT50KW: 0.609,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 850,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-194-31",
    rangeType: "Florence 3",
    model: "Florence 3 Column 200mm",
    depthMM: 103,
    heightMM: 294,
    widthMM: 1425,
    volumeL: 15.5,
    nominalDeltaT50KW: 0.629,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 875,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-194-32",
    rangeType: "Florence 3",
    model: "Florence 3 Column 200mm",
    depthMM: 103,
    heightMM: 294,
    widthMM: 1470,
    volumeL: 16,
    nominalDeltaT50KW: 0.65,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 899,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-194-33",
    rangeType: "Florence 3",
    model: "Florence 3 Column 200mm",
    depthMM: 103,
    heightMM: 294,
    widthMM: 1515,
    volumeL: 16.5,
    nominalDeltaT50KW: 0.67,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 924,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-194-34",
    rangeType: "Florence 3",
    model: "Florence 3 Column 200mm",
    depthMM: 103,
    heightMM: 294,
    widthMM: 1560,
    volumeL: 17,
    nominalDeltaT50KW: 0.69,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 948,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-194-35",
    rangeType: "Florence 3",
    model: "Florence 3 Column 200mm",
    depthMM: 103,
    heightMM: 294,
    widthMM: 1605,
    volumeL: 17.5,
    nominalDeltaT50KW: 0.711,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 973,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-194-36",
    rangeType: "Florence 3",
    model: "Florence 3 Column 200mm",
    depthMM: 103,
    heightMM: 294,
    widthMM: 1650,
    volumeL: 18,
    nominalDeltaT50KW: 0.731,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 997,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-194-37",
    rangeType: "Florence 3",
    model: "Florence 3 Column 200mm",
    depthMM: 103,
    heightMM: 294,
    widthMM: 1695,
    volumeL: 18.5,
    nominalDeltaT50KW: 0.751,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1022,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-194-38",
    rangeType: "Florence 3",
    model: "Florence 3 Column 200mm",
    depthMM: 103,
    heightMM: 294,
    widthMM: 1740,
    volumeL: 19,
    nominalDeltaT50KW: 0.771,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1046,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-194-39",
    rangeType: "Florence 3",
    model: "Florence 3 Column 200mm",
    depthMM: 103,
    heightMM: 294,
    widthMM: 1785,
    volumeL: 19.5,
    nominalDeltaT50KW: 0.792,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1071,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-194-40",
    rangeType: "Florence 3",
    model: "Florence 3 Column 200mm",
    depthMM: 103,
    heightMM: 294,
    widthMM: 1830,
    volumeL: 20,
    nominalDeltaT50KW: 0.812,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1095,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2202-04",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2200mm",
    depthMM: 103,
    heightMM: 2302,
    widthMM: 210,
    volumeL: 11.68,
    nominalDeltaT50KW: 0.823,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 493,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2202-05",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2200mm",
    depthMM: 103,
    heightMM: 2302,
    widthMM: 255,
    volumeL: 14.6,
    nominalDeltaT50KW: 1.029,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 588,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2202-06",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2200mm",
    depthMM: 103,
    heightMM: 2302,
    widthMM: 300,
    volumeL: 17.52,
    nominalDeltaT50KW: 1.234,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 682,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2202-07",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2200mm",
    depthMM: 103,
    heightMM: 2302,
    widthMM: 345,
    volumeL: 20.44,
    nominalDeltaT50KW: 1.44,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 777,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2202-08",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2200mm",
    depthMM: 103,
    heightMM: 2302,
    widthMM: 390,
    volumeL: 23.36,
    nominalDeltaT50KW: 1.646,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 871,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2202-09",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2200mm",
    depthMM: 103,
    heightMM: 2302,
    widthMM: 435,
    volumeL: 26.28,
    nominalDeltaT50KW: 1.851,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 966,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2202-10",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2200mm",
    depthMM: 103,
    heightMM: 2302,
    widthMM: 480,
    volumeL: 29.2,
    nominalDeltaT50KW: 2.057,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1060,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2202-11",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2200mm",
    depthMM: 103,
    heightMM: 2302,
    widthMM: 525,
    volumeL: 32.12,
    nominalDeltaT50KW: 2.263,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1155,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2202-12",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2200mm",
    depthMM: 103,
    heightMM: 2302,
    widthMM: 570,
    volumeL: 35.04,
    nominalDeltaT50KW: 2.468,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1249,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2202-13",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2200mm",
    depthMM: 103,
    heightMM: 2302,
    widthMM: 615,
    volumeL: 37.96,
    nominalDeltaT50KW: 2.674,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1344,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2202-14",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2200mm",
    depthMM: 103,
    heightMM: 2302,
    widthMM: 660,
    volumeL: 40.88,
    nominalDeltaT50KW: 2.88,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1438,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2202-15",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2200mm",
    depthMM: 103,
    heightMM: 2302,
    widthMM: 705,
    volumeL: 43.8,
    nominalDeltaT50KW: 3.086,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1533,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2202-16",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2200mm",
    depthMM: 103,
    heightMM: 2302,
    widthMM: 750,
    volumeL: 46.72,
    nominalDeltaT50KW: 3.291,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1627,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2202-17",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2200mm",
    depthMM: 103,
    heightMM: 2302,
    widthMM: 795,
    volumeL: 49.64,
    nominalDeltaT50KW: 3.497,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1722,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2202-18",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2200mm",
    depthMM: 103,
    heightMM: 2302,
    widthMM: 840,
    volumeL: 52.56,
    nominalDeltaT50KW: 3.703,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1816,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2202-19",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2200mm",
    depthMM: 103,
    heightMM: 2302,
    widthMM: 885,
    volumeL: 55.48,
    nominalDeltaT50KW: 3.908,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1911,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2202-20",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2200mm",
    depthMM: 103,
    heightMM: 2302,
    widthMM: 930,
    volumeL: 58.4,
    nominalDeltaT50KW: 4.114,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 2005,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2202-21",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2200mm",
    depthMM: 103,
    heightMM: 2302,
    widthMM: 975,
    volumeL: 61.32,
    nominalDeltaT50KW: 4.32,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 2100,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2202-22",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2200mm",
    depthMM: 103,
    heightMM: 2302,
    widthMM: 1020,
    volumeL: 64.24,
    nominalDeltaT50KW: 4.525,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 2194,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2202-23",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2200mm",
    depthMM: 103,
    heightMM: 2302,
    widthMM: 1065,
    volumeL: 67.16,
    nominalDeltaT50KW: 4.731,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 2289,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2202-24",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2200mm",
    depthMM: 103,
    heightMM: 2302,
    widthMM: 1110,
    volumeL: 70.08,
    nominalDeltaT50KW: 4.937,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 2383,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2202-25",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2200mm",
    depthMM: 103,
    heightMM: 2302,
    widthMM: 1155,
    volumeL: 73,
    nominalDeltaT50KW: 5.143,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 2478,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2202-26",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2200mm",
    depthMM: 103,
    heightMM: 2302,
    widthMM: 1200,
    volumeL: 75.92,
    nominalDeltaT50KW: 5.348,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 2572,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2202-27",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2200mm",
    depthMM: 103,
    heightMM: 2302,
    widthMM: 1245,
    volumeL: 78.84,
    nominalDeltaT50KW: 5.554,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 2667,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2202-28",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2200mm",
    depthMM: 103,
    heightMM: 2302,
    widthMM: 1290,
    volumeL: 81.76,
    nominalDeltaT50KW: 5.76,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 2761,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2202-29",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2200mm",
    depthMM: 103,
    heightMM: 2302,
    widthMM: 1335,
    volumeL: 84.68,
    nominalDeltaT50KW: 5.965,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 2856,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2202-30",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2200mm",
    depthMM: 103,
    heightMM: 2302,
    widthMM: 1380,
    volumeL: 87.6,
    nominalDeltaT50KW: 6.171,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 2950,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2202-31",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2200mm",
    depthMM: 103,
    heightMM: 2302,
    widthMM: 1425,
    volumeL: 90.52,
    nominalDeltaT50KW: 6.377,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 3045,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2202-32",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2200mm",
    depthMM: 103,
    heightMM: 2302,
    widthMM: 1470,
    volumeL: 93.44,
    nominalDeltaT50KW: 6.582,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 3139,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2202-33",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2200mm",
    depthMM: 103,
    heightMM: 2302,
    widthMM: 1515,
    volumeL: 96.36,
    nominalDeltaT50KW: 6.788,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 3234,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2202-34",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2200mm",
    depthMM: 103,
    heightMM: 2302,
    widthMM: 1560,
    volumeL: 99.28,
    nominalDeltaT50KW: 6.994,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 3328,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2202-35",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2200mm",
    depthMM: 103,
    heightMM: 2302,
    widthMM: 1605,
    volumeL: 102.2,
    nominalDeltaT50KW: 7.2,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 3423,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2202-36",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2200mm",
    depthMM: 103,
    heightMM: 2302,
    widthMM: 1650,
    volumeL: 105.12,
    nominalDeltaT50KW: 7.405,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 3517,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2202-37",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2200mm",
    depthMM: 103,
    heightMM: 2302,
    widthMM: 1695,
    volumeL: 108.04,
    nominalDeltaT50KW: 7.611,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 3612,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2202-38",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2200mm",
    depthMM: 103,
    heightMM: 2302,
    widthMM: 1740,
    volumeL: 110.96,
    nominalDeltaT50KW: 7.817,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 3706,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2202-39",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2200mm",
    depthMM: 103,
    heightMM: 2302,
    widthMM: 1785,
    volumeL: 113.88,
    nominalDeltaT50KW: 8.022,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 3801,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2202-40",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2200mm",
    depthMM: 103,
    heightMM: 2302,
    widthMM: 1830,
    volumeL: 116.8,
    nominalDeltaT50KW: 8.228,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 3895,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2502-04",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2500mm",
    depthMM: 103,
    heightMM: 2602,
    widthMM: 210,
    volumeL: 13.16,
    nominalDeltaT50KW: 0.935,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 545,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2502-05",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2500mm",
    depthMM: 103,
    heightMM: 2602,
    widthMM: 255,
    volumeL: 16.45,
    nominalDeltaT50KW: 1.169,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 653,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2502-06",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2500mm",
    depthMM: 103,
    heightMM: 2602,
    widthMM: 300,
    volumeL: 19.74,
    nominalDeltaT50KW: 1.402,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 760,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2502-07",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2500mm",
    depthMM: 103,
    heightMM: 2602,
    widthMM: 345,
    volumeL: 23.03,
    nominalDeltaT50KW: 1.636,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 868,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2502-08",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2500mm",
    depthMM: 103,
    heightMM: 2602,
    widthMM: 390,
    volumeL: 26.32,
    nominalDeltaT50KW: 1.87,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 975,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2502-09",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2500mm",
    depthMM: 103,
    heightMM: 2602,
    widthMM: 435,
    volumeL: 29.61,
    nominalDeltaT50KW: 2.103,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1083,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2502-10",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2500mm",
    depthMM: 103,
    heightMM: 2602,
    widthMM: 480,
    volumeL: 32.9,
    nominalDeltaT50KW: 2.337,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1190,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2502-11",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2500mm",
    depthMM: 103,
    heightMM: 2602,
    widthMM: 525,
    volumeL: 36.19,
    nominalDeltaT50KW: 2.571,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1298,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2502-12",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2500mm",
    depthMM: 103,
    heightMM: 2602,
    widthMM: 570,
    volumeL: 39.48,
    nominalDeltaT50KW: 2.804,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1405,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2502-13",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2500mm",
    depthMM: 103,
    heightMM: 2602,
    widthMM: 615,
    volumeL: 42.77,
    nominalDeltaT50KW: 3.038,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1513,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2502-14",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2500mm",
    depthMM: 103,
    heightMM: 2602,
    widthMM: 660,
    volumeL: 46.06,
    nominalDeltaT50KW: 3.272,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1620,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2502-15",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2500mm",
    depthMM: 103,
    heightMM: 2602,
    widthMM: 705,
    volumeL: 49.35,
    nominalDeltaT50KW: 3.506,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1728,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2502-16",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2500mm",
    depthMM: 103,
    heightMM: 2602,
    widthMM: 750,
    volumeL: 52.64,
    nominalDeltaT50KW: 3.739,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1835,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2502-17",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2500mm",
    depthMM: 103,
    heightMM: 2602,
    widthMM: 795,
    volumeL: 55.93,
    nominalDeltaT50KW: 3.973,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1943,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2502-18",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2500mm",
    depthMM: 103,
    heightMM: 2602,
    widthMM: 840,
    volumeL: 59.22,
    nominalDeltaT50KW: 4.207,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 2050,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2502-19",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2500mm",
    depthMM: 103,
    heightMM: 2602,
    widthMM: 885,
    volumeL: 62.51,
    nominalDeltaT50KW: 4.44,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 2158,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2502-20",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2500mm",
    depthMM: 103,
    heightMM: 2602,
    widthMM: 930,
    volumeL: 65.8,
    nominalDeltaT50KW: 4.674,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 2265,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2502-21",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2500mm",
    depthMM: 103,
    heightMM: 2602,
    widthMM: 975,
    volumeL: 69.09,
    nominalDeltaT50KW: 4.908,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 2373,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2502-22",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2500mm",
    depthMM: 103,
    heightMM: 2602,
    widthMM: 1020,
    volumeL: 72.38,
    nominalDeltaT50KW: 5.141,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 2480,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2502-23",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2500mm",
    depthMM: 103,
    heightMM: 2602,
    widthMM: 1065,
    volumeL: 75.67,
    nominalDeltaT50KW: 5.375,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 2588,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2502-24",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2500mm",
    depthMM: 103,
    heightMM: 2602,
    widthMM: 1110,
    volumeL: 78.96,
    nominalDeltaT50KW: 5.609,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 2695,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2502-25",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2500mm",
    depthMM: 103,
    heightMM: 2602,
    widthMM: 1155,
    volumeL: 82.25,
    nominalDeltaT50KW: 5.843,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 2803,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2502-26",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2500mm",
    depthMM: 103,
    heightMM: 2602,
    widthMM: 1200,
    volumeL: 85.54,
    nominalDeltaT50KW: 6.076,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 2910,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2502-27",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2500mm",
    depthMM: 103,
    heightMM: 2602,
    widthMM: 1245,
    volumeL: 88.83,
    nominalDeltaT50KW: 6.31,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 3018,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2502-28",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2500mm",
    depthMM: 103,
    heightMM: 2602,
    widthMM: 1290,
    volumeL: 92.12,
    nominalDeltaT50KW: 6.544,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 3125,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2502-29",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2500mm",
    depthMM: 103,
    heightMM: 2602,
    widthMM: 1335,
    volumeL: 95.41,
    nominalDeltaT50KW: 6.777,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 3233,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2502-30",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2500mm",
    depthMM: 103,
    heightMM: 2602,
    widthMM: 1380,
    volumeL: 98.7,
    nominalDeltaT50KW: 7.011,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 3340,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2502-31",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2500mm",
    depthMM: 103,
    heightMM: 2602,
    widthMM: 1425,
    volumeL: 101.99,
    nominalDeltaT50KW: 7.245,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 3448,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2502-32",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2500mm",
    depthMM: 103,
    heightMM: 2602,
    widthMM: 1470,
    volumeL: 105.28,
    nominalDeltaT50KW: 7.478,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 3555,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2502-33",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2500mm",
    depthMM: 103,
    heightMM: 2602,
    widthMM: 1515,
    volumeL: 108.57,
    nominalDeltaT50KW: 7.712,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 3663,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2502-34",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2500mm",
    depthMM: 103,
    heightMM: 2602,
    widthMM: 1560,
    volumeL: 111.86,
    nominalDeltaT50KW: 7.946,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 3770,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2502-35",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2500mm",
    depthMM: 103,
    heightMM: 2602,
    widthMM: 1605,
    volumeL: 115.15,
    nominalDeltaT50KW: 8.18,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 3878,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2502-36",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2500mm",
    depthMM: 103,
    heightMM: 2602,
    widthMM: 1650,
    volumeL: 118.44,
    nominalDeltaT50KW: 8.413,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 3985,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2502-37",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2500mm",
    depthMM: 103,
    heightMM: 2602,
    widthMM: 1695,
    volumeL: 121.73,
    nominalDeltaT50KW: 8.647,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 4093,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2502-38",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2500mm",
    depthMM: 103,
    heightMM: 2602,
    widthMM: 1740,
    volumeL: 125.02,
    nominalDeltaT50KW: 8.881,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 4200,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2502-39",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2500mm",
    depthMM: 103,
    heightMM: 2602,
    widthMM: 1785,
    volumeL: 128.31,
    nominalDeltaT50KW: 9.114,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 4308,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-2502-40",
    rangeType: "Florence 3",
    model: "Florence 3 Column 2500mm",
    depthMM: 103,
    heightMM: 2602,
    widthMM: 1830,
    volumeL: 131.6,
    nominalDeltaT50KW: 9.348,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 4415,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-302-04",
    rangeType: "Florence 3",
    model: "Florence 3 Column 300mm",
    depthMM: 103,
    heightMM: 402,
    widthMM: 210,
    volumeL: 2.4,
    nominalDeltaT50KW: 0.13,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 217,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-302-05",
    rangeType: "Florence 3",
    model: "Florence 3 Column 300mm",
    depthMM: 103,
    heightMM: 402,
    widthMM: 255,
    volumeL: 3,
    nominalDeltaT50KW: 0.163,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 243,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-302-06",
    rangeType: "Florence 3",
    model: "Florence 3 Column 300mm",
    depthMM: 103,
    heightMM: 402,
    widthMM: 300,
    volumeL: 3.6,
    nominalDeltaT50KW: 0.195,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 268,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-302-07",
    rangeType: "Florence 3",
    model: "Florence 3 Column 300mm",
    depthMM: 103,
    heightMM: 402,
    widthMM: 345,
    volumeL: 4.2,
    nominalDeltaT50KW: 0.228,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 294,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-302-08",
    rangeType: "Florence 3",
    model: "Florence 3 Column 300mm",
    depthMM: 103,
    heightMM: 402,
    widthMM: 390,
    volumeL: 4.8,
    nominalDeltaT50KW: 0.26,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 319,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-302-09",
    rangeType: "Florence 3",
    model: "Florence 3 Column 300mm",
    depthMM: 103,
    heightMM: 402,
    widthMM: 435,
    volumeL: 5.4,
    nominalDeltaT50KW: 0.293,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 345,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-302-10",
    rangeType: "Florence 3",
    model: "Florence 3 Column 300mm",
    depthMM: 103,
    heightMM: 402,
    widthMM: 480,
    volumeL: 6,
    nominalDeltaT50KW: 0.325,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 370,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-302-11",
    rangeType: "Florence 3",
    model: "Florence 3 Column 300mm",
    depthMM: 103,
    heightMM: 402,
    widthMM: 525,
    volumeL: 6.6,
    nominalDeltaT50KW: 0.358,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 396,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-302-12",
    rangeType: "Florence 3",
    model: "Florence 3 Column 300mm",
    depthMM: 103,
    heightMM: 402,
    widthMM: 570,
    volumeL: 7.2,
    nominalDeltaT50KW: 0.39,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 421,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-302-13",
    rangeType: "Florence 3",
    model: "Florence 3 Column 300mm",
    depthMM: 103,
    heightMM: 402,
    widthMM: 615,
    volumeL: 7.8,
    nominalDeltaT50KW: 0.423,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 447,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-302-14",
    rangeType: "Florence 3",
    model: "Florence 3 Column 300mm",
    depthMM: 103,
    heightMM: 402,
    widthMM: 660,
    volumeL: 8.4,
    nominalDeltaT50KW: 0.455,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 472,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-302-15",
    rangeType: "Florence 3",
    model: "Florence 3 Column 300mm",
    depthMM: 103,
    heightMM: 402,
    widthMM: 705,
    volumeL: 9,
    nominalDeltaT50KW: 0.488,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 498,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-302-16",
    rangeType: "Florence 3",
    model: "Florence 3 Column 300mm",
    depthMM: 103,
    heightMM: 402,
    widthMM: 750,
    volumeL: 9.6,
    nominalDeltaT50KW: 0.52,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 523,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-302-17",
    rangeType: "Florence 3",
    model: "Florence 3 Column 300mm",
    depthMM: 103,
    heightMM: 402,
    widthMM: 795,
    volumeL: 10.2,
    nominalDeltaT50KW: 0.553,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 549,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-302-18",
    rangeType: "Florence 3",
    model: "Florence 3 Column 300mm",
    depthMM: 103,
    heightMM: 402,
    widthMM: 840,
    volumeL: 10.8,
    nominalDeltaT50KW: 0.585,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 574,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-302-19",
    rangeType: "Florence 3",
    model: "Florence 3 Column 300mm",
    depthMM: 103,
    heightMM: 402,
    widthMM: 885,
    volumeL: 11.4,
    nominalDeltaT50KW: 0.618,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 600,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-302-20",
    rangeType: "Florence 3",
    model: "Florence 3 Column 300mm",
    depthMM: 103,
    heightMM: 402,
    widthMM: 930,
    volumeL: 12,
    nominalDeltaT50KW: 0.65,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 625,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-302-21",
    rangeType: "Florence 3",
    model: "Florence 3 Column 300mm",
    depthMM: 103,
    heightMM: 402,
    widthMM: 975,
    volumeL: 12.6,
    nominalDeltaT50KW: 0.683,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 651,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-302-22",
    rangeType: "Florence 3",
    model: "Florence 3 Column 300mm",
    depthMM: 103,
    heightMM: 402,
    widthMM: 1020,
    volumeL: 13.2,
    nominalDeltaT50KW: 0.715,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 676,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-302-23",
    rangeType: "Florence 3",
    model: "Florence 3 Column 300mm",
    depthMM: 103,
    heightMM: 402,
    widthMM: 1065,
    volumeL: 13.8,
    nominalDeltaT50KW: 0.748,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 702,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-302-24",
    rangeType: "Florence 3",
    model: "Florence 3 Column 300mm",
    depthMM: 103,
    heightMM: 402,
    widthMM: 1110,
    volumeL: 14.4,
    nominalDeltaT50KW: 0.78,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 727,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-302-25",
    rangeType: "Florence 3",
    model: "Florence 3 Column 300mm",
    depthMM: 103,
    heightMM: 402,
    widthMM: 1155,
    volumeL: 15,
    nominalDeltaT50KW: 0.813,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 753,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-302-26",
    rangeType: "Florence 3",
    model: "Florence 3 Column 300mm",
    depthMM: 103,
    heightMM: 402,
    widthMM: 1200,
    volumeL: 15.6,
    nominalDeltaT50KW: 0.845,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 778,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-302-27",
    rangeType: "Florence 3",
    model: "Florence 3 Column 300mm",
    depthMM: 103,
    heightMM: 402,
    widthMM: 1245,
    volumeL: 16.2,
    nominalDeltaT50KW: 0.878,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 804,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-302-28",
    rangeType: "Florence 3",
    model: "Florence 3 Column 300mm",
    depthMM: 103,
    heightMM: 402,
    widthMM: 1290,
    volumeL: 16.8,
    nominalDeltaT50KW: 0.91,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 829,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-302-29",
    rangeType: "Florence 3",
    model: "Florence 3 Column 300mm",
    depthMM: 103,
    heightMM: 402,
    widthMM: 1335,
    volumeL: 17.4,
    nominalDeltaT50KW: 0.943,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 855,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-302-30",
    rangeType: "Florence 3",
    model: "Florence 3 Column 300mm",
    depthMM: 103,
    heightMM: 402,
    widthMM: 1380,
    volumeL: 18,
    nominalDeltaT50KW: 0.975,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 880,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-302-31",
    rangeType: "Florence 3",
    model: "Florence 3 Column 300mm",
    depthMM: 103,
    heightMM: 402,
    widthMM: 1425,
    volumeL: 18.6,
    nominalDeltaT50KW: 1.008,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 906,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-302-32",
    rangeType: "Florence 3",
    model: "Florence 3 Column 300mm",
    depthMM: 103,
    heightMM: 402,
    widthMM: 1470,
    volumeL: 19.2,
    nominalDeltaT50KW: 1.04,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 931,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-302-33",
    rangeType: "Florence 3",
    model: "Florence 3 Column 300mm",
    depthMM: 103,
    heightMM: 402,
    widthMM: 1515,
    volumeL: 19.8,
    nominalDeltaT50KW: 1.073,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 957,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-302-34",
    rangeType: "Florence 3",
    model: "Florence 3 Column 300mm",
    depthMM: 103,
    heightMM: 402,
    widthMM: 1560,
    volumeL: 20.4,
    nominalDeltaT50KW: 1.105,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 982,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-302-35",
    rangeType: "Florence 3",
    model: "Florence 3 Column 300mm",
    depthMM: 103,
    heightMM: 402,
    widthMM: 1605,
    volumeL: 21,
    nominalDeltaT50KW: 1.138,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1008,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-302-36",
    rangeType: "Florence 3",
    model: "Florence 3 Column 300mm",
    depthMM: 103,
    heightMM: 402,
    widthMM: 1650,
    volumeL: 21.6,
    nominalDeltaT50KW: 1.17,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1033,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-302-37",
    rangeType: "Florence 3",
    model: "Florence 3 Column 300mm",
    depthMM: 103,
    heightMM: 402,
    widthMM: 1695,
    volumeL: 22.2,
    nominalDeltaT50KW: 1.203,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1059,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-302-38",
    rangeType: "Florence 3",
    model: "Florence 3 Column 300mm",
    depthMM: 103,
    heightMM: 402,
    widthMM: 1740,
    volumeL: 22.8,
    nominalDeltaT50KW: 1.235,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1084,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-302-39",
    rangeType: "Florence 3",
    model: "Florence 3 Column 300mm",
    depthMM: 103,
    heightMM: 402,
    widthMM: 1785,
    volumeL: 23.4,
    nominalDeltaT50KW: 1.268,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1110,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-302-40",
    rangeType: "Florence 3",
    model: "Florence 3 Column 300mm",
    depthMM: 103,
    heightMM: 402,
    widthMM: 1830,
    volumeL: 24,
    nominalDeltaT50KW: 1.3,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1135,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-402-04",
    rangeType: "Florence 3",
    model: "Florence 3 Column 400mm",
    depthMM: 103,
    heightMM: 502,
    widthMM: 210,
    volumeL: 2.8,
    nominalDeltaT50KW: 0.168,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 217,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-402-05",
    rangeType: "Florence 3",
    model: "Florence 3 Column 400mm",
    depthMM: 103,
    heightMM: 502,
    widthMM: 255,
    volumeL: 3.5,
    nominalDeltaT50KW: 0.21,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 243,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-402-06",
    rangeType: "Florence 3",
    model: "Florence 3 Column 400mm",
    depthMM: 103,
    heightMM: 502,
    widthMM: 300,
    volumeL: 4.2,
    nominalDeltaT50KW: 0.252,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 268,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-402-07",
    rangeType: "Florence 3",
    model: "Florence 3 Column 400mm",
    depthMM: 103,
    heightMM: 502,
    widthMM: 345,
    volumeL: 4.9,
    nominalDeltaT50KW: 0.294,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 294,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-402-08",
    rangeType: "Florence 3",
    model: "Florence 3 Column 400mm",
    depthMM: 103,
    heightMM: 502,
    widthMM: 390,
    volumeL: 5.6,
    nominalDeltaT50KW: 0.336,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 319,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-402-09",
    rangeType: "Florence 3",
    model: "Florence 3 Column 400mm",
    depthMM: 103,
    heightMM: 502,
    widthMM: 435,
    volumeL: 6.3,
    nominalDeltaT50KW: 0.378,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 345,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-402-10",
    rangeType: "Florence 3",
    model: "Florence 3 Column 400mm",
    depthMM: 103,
    heightMM: 502,
    widthMM: 480,
    volumeL: 7,
    nominalDeltaT50KW: 0.42,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 370,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-402-11",
    rangeType: "Florence 3",
    model: "Florence 3 Column 400mm",
    depthMM: 103,
    heightMM: 502,
    widthMM: 525,
    volumeL: 7.7,
    nominalDeltaT50KW: 0.462,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 396,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-402-12",
    rangeType: "Florence 3",
    model: "Florence 3 Column 400mm",
    depthMM: 103,
    heightMM: 502,
    widthMM: 570,
    volumeL: 8.4,
    nominalDeltaT50KW: 0.504,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 421,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-402-13",
    rangeType: "Florence 3",
    model: "Florence 3 Column 400mm",
    depthMM: 103,
    heightMM: 502,
    widthMM: 615,
    volumeL: 9.1,
    nominalDeltaT50KW: 0.546,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 447,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-402-14",
    rangeType: "Florence 3",
    model: "Florence 3 Column 400mm",
    depthMM: 103,
    heightMM: 502,
    widthMM: 660,
    volumeL: 9.8,
    nominalDeltaT50KW: 0.588,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 472,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-402-15",
    rangeType: "Florence 3",
    model: "Florence 3 Column 400mm",
    depthMM: 103,
    heightMM: 502,
    widthMM: 705,
    volumeL: 10.5,
    nominalDeltaT50KW: 0.63,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 498,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-402-16",
    rangeType: "Florence 3",
    model: "Florence 3 Column 400mm",
    depthMM: 103,
    heightMM: 502,
    widthMM: 750,
    volumeL: 11.2,
    nominalDeltaT50KW: 0.672,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 523,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-402-17",
    rangeType: "Florence 3",
    model: "Florence 3 Column 400mm",
    depthMM: 103,
    heightMM: 502,
    widthMM: 795,
    volumeL: 11.9,
    nominalDeltaT50KW: 0.714,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 549,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-402-18",
    rangeType: "Florence 3",
    model: "Florence 3 Column 400mm",
    depthMM: 103,
    heightMM: 502,
    widthMM: 840,
    volumeL: 12.6,
    nominalDeltaT50KW: 0.756,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 574,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-402-19",
    rangeType: "Florence 3",
    model: "Florence 3 Column 400mm",
    depthMM: 103,
    heightMM: 502,
    widthMM: 885,
    volumeL: 13.3,
    nominalDeltaT50KW: 0.798,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 600,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-402-20",
    rangeType: "Florence 3",
    model: "Florence 3 Column 400mm",
    depthMM: 103,
    heightMM: 502,
    widthMM: 930,
    volumeL: 14,
    nominalDeltaT50KW: 0.84,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 625,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-402-21",
    rangeType: "Florence 3",
    model: "Florence 3 Column 400mm",
    depthMM: 103,
    heightMM: 502,
    widthMM: 975,
    volumeL: 14.7,
    nominalDeltaT50KW: 0.882,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 651,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-402-22",
    rangeType: "Florence 3",
    model: "Florence 3 Column 400mm",
    depthMM: 103,
    heightMM: 502,
    widthMM: 1020,
    volumeL: 15.4,
    nominalDeltaT50KW: 0.924,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 676,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-402-23",
    rangeType: "Florence 3",
    model: "Florence 3 Column 400mm",
    depthMM: 103,
    heightMM: 502,
    widthMM: 1065,
    volumeL: 16.1,
    nominalDeltaT50KW: 0.966,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 702,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-402-24",
    rangeType: "Florence 3",
    model: "Florence 3 Column 400mm",
    depthMM: 103,
    heightMM: 502,
    widthMM: 1110,
    volumeL: 16.8,
    nominalDeltaT50KW: 1.008,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 727,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-402-25",
    rangeType: "Florence 3",
    model: "Florence 3 Column 400mm",
    depthMM: 103,
    heightMM: 502,
    widthMM: 1155,
    volumeL: 17.5,
    nominalDeltaT50KW: 1.05,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 753,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-402-26",
    rangeType: "Florence 3",
    model: "Florence 3 Column 400mm",
    depthMM: 103,
    heightMM: 502,
    widthMM: 1200,
    volumeL: 18.2,
    nominalDeltaT50KW: 1.092,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 778,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-402-27",
    rangeType: "Florence 3",
    model: "Florence 3 Column 400mm",
    depthMM: 103,
    heightMM: 502,
    widthMM: 1245,
    volumeL: 18.9,
    nominalDeltaT50KW: 1.134,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 804,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-402-28",
    rangeType: "Florence 3",
    model: "Florence 3 Column 400mm",
    depthMM: 103,
    heightMM: 502,
    widthMM: 1290,
    volumeL: 19.6,
    nominalDeltaT50KW: 1.176,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 829,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-402-29",
    rangeType: "Florence 3",
    model: "Florence 3 Column 400mm",
    depthMM: 103,
    heightMM: 502,
    widthMM: 1335,
    volumeL: 20.3,
    nominalDeltaT50KW: 1.218,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 855,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-402-30",
    rangeType: "Florence 3",
    model: "Florence 3 Column 400mm",
    depthMM: 103,
    heightMM: 502,
    widthMM: 1380,
    volumeL: 21,
    nominalDeltaT50KW: 1.26,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 880,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-402-31",
    rangeType: "Florence 3",
    model: "Florence 3 Column 400mm",
    depthMM: 103,
    heightMM: 502,
    widthMM: 1425,
    volumeL: 21.7,
    nominalDeltaT50KW: 1.302,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 906,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-402-32",
    rangeType: "Florence 3",
    model: "Florence 3 Column 400mm",
    depthMM: 103,
    heightMM: 502,
    widthMM: 1470,
    volumeL: 22.4,
    nominalDeltaT50KW: 1.344,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 931,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-402-33",
    rangeType: "Florence 3",
    model: "Florence 3 Column 400mm",
    depthMM: 103,
    heightMM: 502,
    widthMM: 1515,
    volumeL: 23.1,
    nominalDeltaT50KW: 1.386,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 957,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-402-34",
    rangeType: "Florence 3",
    model: "Florence 3 Column 400mm",
    depthMM: 103,
    heightMM: 502,
    widthMM: 1560,
    volumeL: 23.8,
    nominalDeltaT50KW: 1.428,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 982,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-402-35",
    rangeType: "Florence 3",
    model: "Florence 3 Column 400mm",
    depthMM: 103,
    heightMM: 502,
    widthMM: 1605,
    volumeL: 24.5,
    nominalDeltaT50KW: 1.47,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1008,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-402-36",
    rangeType: "Florence 3",
    model: "Florence 3 Column 400mm",
    depthMM: 103,
    heightMM: 502,
    widthMM: 1650,
    volumeL: 25.2,
    nominalDeltaT50KW: 1.512,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1033,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-402-37",
    rangeType: "Florence 3",
    model: "Florence 3 Column 400mm",
    depthMM: 103,
    heightMM: 502,
    widthMM: 1695,
    volumeL: 25.9,
    nominalDeltaT50KW: 1.554,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1059,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-402-38",
    rangeType: "Florence 3",
    model: "Florence 3 Column 400mm",
    depthMM: 103,
    heightMM: 502,
    widthMM: 1740,
    volumeL: 26.6,
    nominalDeltaT50KW: 1.596,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1084,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-402-39",
    rangeType: "Florence 3",
    model: "Florence 3 Column 400mm",
    depthMM: 103,
    heightMM: 502,
    widthMM: 1785,
    volumeL: 27.3,
    nominalDeltaT50KW: 1.638,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1110,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-402-40",
    rangeType: "Florence 3",
    model: "Florence 3 Column 400mm",
    depthMM: 103,
    heightMM: 502,
    widthMM: 1830,
    volumeL: 28,
    nominalDeltaT50KW: 1.68,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1135,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-502-04",
    rangeType: "Florence 3",
    model: "Florence 3 Column 500mm",
    depthMM: 103,
    heightMM: 602,
    widthMM: 210,
    volumeL: 3.2,
    nominalDeltaT50KW: 0.206,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 221,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-502-05",
    rangeType: "Florence 3",
    model: "Florence 3 Column 500mm",
    depthMM: 103,
    heightMM: 602,
    widthMM: 255,
    volumeL: 4,
    nominalDeltaT50KW: 0.257,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 248,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-502-06",
    rangeType: "Florence 3",
    model: "Florence 3 Column 500mm",
    depthMM: 103,
    heightMM: 602,
    widthMM: 300,
    volumeL: 4.8,
    nominalDeltaT50KW: 0.308,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 274,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-502-07",
    rangeType: "Florence 3",
    model: "Florence 3 Column 500mm",
    depthMM: 103,
    heightMM: 602,
    widthMM: 345,
    volumeL: 5.6,
    nominalDeltaT50KW: 0.36,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 301,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-502-08",
    rangeType: "Florence 3",
    model: "Florence 3 Column 500mm",
    depthMM: 103,
    heightMM: 602,
    widthMM: 390,
    volumeL: 6.4,
    nominalDeltaT50KW: 0.411,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 327,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-502-09",
    rangeType: "Florence 3",
    model: "Florence 3 Column 500mm",
    depthMM: 103,
    heightMM: 602,
    widthMM: 435,
    volumeL: 7.2,
    nominalDeltaT50KW: 0.463,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 354,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-502-10",
    rangeType: "Florence 3",
    model: "Florence 3 Column 500mm",
    depthMM: 103,
    heightMM: 602,
    widthMM: 480,
    volumeL: 8,
    nominalDeltaT50KW: 0.514,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 380,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-502-11",
    rangeType: "Florence 3",
    model: "Florence 3 Column 500mm",
    depthMM: 103,
    heightMM: 602,
    widthMM: 525,
    volumeL: 8.8,
    nominalDeltaT50KW: 0.565,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 407,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-502-12",
    rangeType: "Florence 3",
    model: "Florence 3 Column 500mm",
    depthMM: 103,
    heightMM: 602,
    widthMM: 570,
    volumeL: 9.6,
    nominalDeltaT50KW: 0.617,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 433,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-502-13",
    rangeType: "Florence 3",
    model: "Florence 3 Column 500mm",
    depthMM: 103,
    heightMM: 602,
    widthMM: 615,
    volumeL: 10.4,
    nominalDeltaT50KW: 0.668,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 460,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-502-14",
    rangeType: "Florence 3",
    model: "Florence 3 Column 500mm",
    depthMM: 103,
    heightMM: 602,
    widthMM: 660,
    volumeL: 11.2,
    nominalDeltaT50KW: 0.72,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 486,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-502-15",
    rangeType: "Florence 3",
    model: "Florence 3 Column 500mm",
    depthMM: 103,
    heightMM: 602,
    widthMM: 705,
    volumeL: 12,
    nominalDeltaT50KW: 0.771,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 513,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-502-16",
    rangeType: "Florence 3",
    model: "Florence 3 Column 500mm",
    depthMM: 103,
    heightMM: 602,
    widthMM: 750,
    volumeL: 12.8,
    nominalDeltaT50KW: 0.822,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 539,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-502-17",
    rangeType: "Florence 3",
    model: "Florence 3 Column 500mm",
    depthMM: 103,
    heightMM: 602,
    widthMM: 795,
    volumeL: 13.6,
    nominalDeltaT50KW: 0.874,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 566,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-502-18",
    rangeType: "Florence 3",
    model: "Florence 3 Column 500mm",
    depthMM: 103,
    heightMM: 602,
    widthMM: 840,
    volumeL: 14.4,
    nominalDeltaT50KW: 0.925,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 592,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-502-19",
    rangeType: "Florence 3",
    model: "Florence 3 Column 500mm",
    depthMM: 103,
    heightMM: 602,
    widthMM: 885,
    volumeL: 15.2,
    nominalDeltaT50KW: 0.977,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 619,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-502-20",
    rangeType: "Florence 3",
    model: "Florence 3 Column 500mm",
    depthMM: 103,
    heightMM: 602,
    widthMM: 930,
    volumeL: 16,
    nominalDeltaT50KW: 1.028,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 645,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-502-21",
    rangeType: "Florence 3",
    model: "Florence 3 Column 500mm",
    depthMM: 103,
    heightMM: 602,
    widthMM: 975,
    volumeL: 16.8,
    nominalDeltaT50KW: 1.079,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 672,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-502-22",
    rangeType: "Florence 3",
    model: "Florence 3 Column 500mm",
    depthMM: 103,
    heightMM: 602,
    widthMM: 1020,
    volumeL: 17.6,
    nominalDeltaT50KW: 1.131,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 698,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-502-23",
    rangeType: "Florence 3",
    model: "Florence 3 Column 500mm",
    depthMM: 103,
    heightMM: 602,
    widthMM: 1065,
    volumeL: 18.4,
    nominalDeltaT50KW: 1.182,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 725,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-502-24",
    rangeType: "Florence 3",
    model: "Florence 3 Column 500mm",
    depthMM: 103,
    heightMM: 602,
    widthMM: 1110,
    volumeL: 19.2,
    nominalDeltaT50KW: 1.234,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 751,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-502-25",
    rangeType: "Florence 3",
    model: "Florence 3 Column 500mm",
    depthMM: 103,
    heightMM: 602,
    widthMM: 1155,
    volumeL: 20,
    nominalDeltaT50KW: 1.285,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 778,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-502-26",
    rangeType: "Florence 3",
    model: "Florence 3 Column 500mm",
    depthMM: 103,
    heightMM: 602,
    widthMM: 1200,
    volumeL: 20.8,
    nominalDeltaT50KW: 1.336,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 804,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-502-27",
    rangeType: "Florence 3",
    model: "Florence 3 Column 500mm",
    depthMM: 103,
    heightMM: 602,
    widthMM: 1245,
    volumeL: 21.6,
    nominalDeltaT50KW: 1.388,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 831,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-502-28",
    rangeType: "Florence 3",
    model: "Florence 3 Column 500mm",
    depthMM: 103,
    heightMM: 602,
    widthMM: 1290,
    volumeL: 22.4,
    nominalDeltaT50KW: 1.439,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 857,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-502-29",
    rangeType: "Florence 3",
    model: "Florence 3 Column 500mm",
    depthMM: 103,
    heightMM: 602,
    widthMM: 1335,
    volumeL: 23.2,
    nominalDeltaT50KW: 1.491,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 884,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-502-30",
    rangeType: "Florence 3",
    model: "Florence 3 Column 500mm",
    depthMM: 103,
    heightMM: 602,
    widthMM: 1380,
    volumeL: 24,
    nominalDeltaT50KW: 1.542,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 910,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-502-31",
    rangeType: "Florence 3",
    model: "Florence 3 Column 500mm",
    depthMM: 103,
    heightMM: 602,
    widthMM: 1425,
    volumeL: 24.8,
    nominalDeltaT50KW: 1.593,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 937,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-502-32",
    rangeType: "Florence 3",
    model: "Florence 3 Column 500mm",
    depthMM: 103,
    heightMM: 602,
    widthMM: 1470,
    volumeL: 25.6,
    nominalDeltaT50KW: 1.645,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 963,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-502-33",
    rangeType: "Florence 3",
    model: "Florence 3 Column 500mm",
    depthMM: 103,
    heightMM: 602,
    widthMM: 1515,
    volumeL: 26.4,
    nominalDeltaT50KW: 1.696,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 990,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-502-34",
    rangeType: "Florence 3",
    model: "Florence 3 Column 500mm",
    depthMM: 103,
    heightMM: 602,
    widthMM: 1560,
    volumeL: 27.2,
    nominalDeltaT50KW: 1.748,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1016,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-502-35",
    rangeType: "Florence 3",
    model: "Florence 3 Column 500mm",
    depthMM: 103,
    heightMM: 602,
    widthMM: 1605,
    volumeL: 28,
    nominalDeltaT50KW: 1.799,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1043,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-502-36",
    rangeType: "Florence 3",
    model: "Florence 3 Column 500mm",
    depthMM: 103,
    heightMM: 602,
    widthMM: 1650,
    volumeL: 28.8,
    nominalDeltaT50KW: 1.85,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1069,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-502-37",
    rangeType: "Florence 3",
    model: "Florence 3 Column 500mm",
    depthMM: 103,
    heightMM: 602,
    widthMM: 1695,
    volumeL: 29.6,
    nominalDeltaT50KW: 1.902,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1096,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-502-38",
    rangeType: "Florence 3",
    model: "Florence 3 Column 500mm",
    depthMM: 103,
    heightMM: 602,
    widthMM: 1740,
    volumeL: 30.4,
    nominalDeltaT50KW: 1.953,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1122,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-502-39",
    rangeType: "Florence 3",
    model: "Florence 3 Column 500mm",
    depthMM: 103,
    heightMM: 602,
    widthMM: 1785,
    volumeL: 31.2,
    nominalDeltaT50KW: 2.005,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1149,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-502-40",
    rangeType: "Florence 3",
    model: "Florence 3 Column 500mm",
    depthMM: 103,
    heightMM: 602,
    widthMM: 1830,
    volumeL: 32,
    nominalDeltaT50KW: 2.056,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1175,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-602-04",
    rangeType: "Florence 3",
    model: "Florence 3 Column 600mm",
    depthMM: 103,
    heightMM: 702,
    widthMM: 210,
    volumeL: 4,
    nominalDeltaT50KW: 0.242,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 225,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-602-05",
    rangeType: "Florence 3",
    model: "Florence 3 Column 600mm",
    depthMM: 103,
    heightMM: 702,
    widthMM: 255,
    volumeL: 5,
    nominalDeltaT50KW: 0.303,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 253,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-602-06",
    rangeType: "Florence 3",
    model: "Florence 3 Column 600mm",
    depthMM: 103,
    heightMM: 702,
    widthMM: 300,
    volumeL: 6,
    nominalDeltaT50KW: 0.364,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 280,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-602-07",
    rangeType: "Florence 3",
    model: "Florence 3 Column 600mm",
    depthMM: 103,
    heightMM: 702,
    widthMM: 345,
    volumeL: 7,
    nominalDeltaT50KW: 0.424,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 308,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-602-08",
    rangeType: "Florence 3",
    model: "Florence 3 Column 600mm",
    depthMM: 103,
    heightMM: 702,
    widthMM: 390,
    volumeL: 8,
    nominalDeltaT50KW: 0.485,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 335,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-602-09",
    rangeType: "Florence 3",
    model: "Florence 3 Column 600mm",
    depthMM: 103,
    heightMM: 702,
    widthMM: 435,
    volumeL: 9,
    nominalDeltaT50KW: 0.545,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 363,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-602-10",
    rangeType: "Florence 3",
    model: "Florence 3 Column 600mm",
    depthMM: 103,
    heightMM: 702,
    widthMM: 480,
    volumeL: 10,
    nominalDeltaT50KW: 0.606,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 390,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-602-11",
    rangeType: "Florence 3",
    model: "Florence 3 Column 600mm",
    depthMM: 103,
    heightMM: 702,
    widthMM: 525,
    volumeL: 11,
    nominalDeltaT50KW: 0.667,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 418,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-602-12",
    rangeType: "Florence 3",
    model: "Florence 3 Column 600mm",
    depthMM: 103,
    heightMM: 702,
    widthMM: 570,
    volumeL: 12,
    nominalDeltaT50KW: 0.727,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 445,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-602-13",
    rangeType: "Florence 3",
    model: "Florence 3 Column 600mm",
    depthMM: 103,
    heightMM: 702,
    widthMM: 615,
    volumeL: 13,
    nominalDeltaT50KW: 0.788,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 473,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-602-14",
    rangeType: "Florence 3",
    model: "Florence 3 Column 600mm",
    depthMM: 103,
    heightMM: 702,
    widthMM: 660,
    volumeL: 14,
    nominalDeltaT50KW: 0.848,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 500,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-602-15",
    rangeType: "Florence 3",
    model: "Florence 3 Column 600mm",
    depthMM: 103,
    heightMM: 702,
    widthMM: 705,
    volumeL: 15,
    nominalDeltaT50KW: 0.909,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 528,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-602-16",
    rangeType: "Florence 3",
    model: "Florence 3 Column 600mm",
    depthMM: 103,
    heightMM: 702,
    widthMM: 750,
    volumeL: 16,
    nominalDeltaT50KW: 0.97,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 555,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-602-17",
    rangeType: "Florence 3",
    model: "Florence 3 Column 600mm",
    depthMM: 103,
    heightMM: 702,
    widthMM: 795,
    volumeL: 17,
    nominalDeltaT50KW: 1.03,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 583,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-602-18",
    rangeType: "Florence 3",
    model: "Florence 3 Column 600mm",
    depthMM: 103,
    heightMM: 702,
    widthMM: 840,
    volumeL: 18,
    nominalDeltaT50KW: 1.091,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 610,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-602-19",
    rangeType: "Florence 3",
    model: "Florence 3 Column 600mm",
    depthMM: 103,
    heightMM: 702,
    widthMM: 885,
    volumeL: 19,
    nominalDeltaT50KW: 1.151,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 638,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-602-20",
    rangeType: "Florence 3",
    model: "Florence 3 Column 600mm",
    depthMM: 103,
    heightMM: 702,
    widthMM: 930,
    volumeL: 20,
    nominalDeltaT50KW: 1.212,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 665,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-602-21",
    rangeType: "Florence 3",
    model: "Florence 3 Column 600mm",
    depthMM: 103,
    heightMM: 702,
    widthMM: 975,
    volumeL: 21,
    nominalDeltaT50KW: 1.273,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 693,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-602-22",
    rangeType: "Florence 3",
    model: "Florence 3 Column 600mm",
    depthMM: 103,
    heightMM: 702,
    widthMM: 1020,
    volumeL: 22,
    nominalDeltaT50KW: 1.333,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 720,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-602-23",
    rangeType: "Florence 3",
    model: "Florence 3 Column 600mm",
    depthMM: 103,
    heightMM: 702,
    widthMM: 1065,
    volumeL: 23,
    nominalDeltaT50KW: 1.394,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 748,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-602-24",
    rangeType: "Florence 3",
    model: "Florence 3 Column 600mm",
    depthMM: 103,
    heightMM: 702,
    widthMM: 1110,
    volumeL: 24,
    nominalDeltaT50KW: 1.454,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 775,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-602-25",
    rangeType: "Florence 3",
    model: "Florence 3 Column 600mm",
    depthMM: 103,
    heightMM: 702,
    widthMM: 1155,
    volumeL: 25,
    nominalDeltaT50KW: 1.515,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 803,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-602-26",
    rangeType: "Florence 3",
    model: "Florence 3 Column 600mm",
    depthMM: 103,
    heightMM: 702,
    widthMM: 1200,
    volumeL: 26,
    nominalDeltaT50KW: 1.576,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 830,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-602-27",
    rangeType: "Florence 3",
    model: "Florence 3 Column 600mm",
    depthMM: 103,
    heightMM: 702,
    widthMM: 1245,
    volumeL: 27,
    nominalDeltaT50KW: 1.636,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 858,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-602-28",
    rangeType: "Florence 3",
    model: "Florence 3 Column 600mm",
    depthMM: 103,
    heightMM: 702,
    widthMM: 1290,
    volumeL: 28,
    nominalDeltaT50KW: 1.697,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 885,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-602-29",
    rangeType: "Florence 3",
    model: "Florence 3 Column 600mm",
    depthMM: 103,
    heightMM: 702,
    widthMM: 1335,
    volumeL: 29,
    nominalDeltaT50KW: 1.757,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 913,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-602-30",
    rangeType: "Florence 3",
    model: "Florence 3 Column 600mm",
    depthMM: 103,
    heightMM: 702,
    widthMM: 1380,
    volumeL: 30,
    nominalDeltaT50KW: 1.818,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 940,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-602-31",
    rangeType: "Florence 3",
    model: "Florence 3 Column 600mm",
    depthMM: 103,
    heightMM: 702,
    widthMM: 1425,
    volumeL: 31,
    nominalDeltaT50KW: 1.879,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 968,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-602-32",
    rangeType: "Florence 3",
    model: "Florence 3 Column 600mm",
    depthMM: 103,
    heightMM: 702,
    widthMM: 1470,
    volumeL: 32,
    nominalDeltaT50KW: 1.939,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 995,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-602-33",
    rangeType: "Florence 3",
    model: "Florence 3 Column 600mm",
    depthMM: 103,
    heightMM: 702,
    widthMM: 1515,
    volumeL: 33,
    nominalDeltaT50KW: 2,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 1023,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-602-34",
    rangeType: "Florence 3",
    model: "Florence 3 Column 600mm",
    depthMM: 103,
    heightMM: 702,
    widthMM: 1560,
    volumeL: 34,
    nominalDeltaT50KW: 2.06,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 1050,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-602-35",
    rangeType: "Florence 3",
    model: "Florence 3 Column 600mm",
    depthMM: 103,
    heightMM: 702,
    widthMM: 1605,
    volumeL: 35,
    nominalDeltaT50KW: 2.121,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 1078,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-602-36",
    rangeType: "Florence 3",
    model: "Florence 3 Column 600mm",
    depthMM: 103,
    heightMM: 702,
    widthMM: 1650,
    volumeL: 36,
    nominalDeltaT50KW: 2.182,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 1105,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-602-37",
    rangeType: "Florence 3",
    model: "Florence 3 Column 600mm",
    depthMM: 103,
    heightMM: 702,
    widthMM: 1695,
    volumeL: 37,
    nominalDeltaT50KW: 2.242,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 1133,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-602-38",
    rangeType: "Florence 3",
    model: "Florence 3 Column 600mm",
    depthMM: 103,
    heightMM: 702,
    widthMM: 1740,
    volumeL: 38,
    nominalDeltaT50KW: 2.303,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 1160,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-602-39",
    rangeType: "Florence 3",
    model: "Florence 3 Column 600mm",
    depthMM: 103,
    heightMM: 702,
    widthMM: 1785,
    volumeL: 39,
    nominalDeltaT50KW: 2.363,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 1188,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-602-40",
    rangeType: "Florence 3",
    model: "Florence 3 Column 600mm",
    depthMM: 103,
    heightMM: 702,
    widthMM: 1830,
    volumeL: 40,
    nominalDeltaT50KW: 2.424,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 1215,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-687-04",
    rangeType: "Florence 3",
    model: "Florence 3 Column 685mm",
    depthMM: 103,
    heightMM: 787,
    widthMM: 210,
    volumeL: 4.4,
    nominalDeltaT50KW: 0.273,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 225,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-687-05",
    rangeType: "Florence 3",
    model: "Florence 3 Column 685mm",
    depthMM: 103,
    heightMM: 787,
    widthMM: 255,
    volumeL: 5.5,
    nominalDeltaT50KW: 0.342,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 253,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-687-06",
    rangeType: "Florence 3",
    model: "Florence 3 Column 685mm",
    depthMM: 103,
    heightMM: 787,
    widthMM: 300,
    volumeL: 6.6,
    nominalDeltaT50KW: 0.41,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 280,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-687-07",
    rangeType: "Florence 3",
    model: "Florence 3 Column 685mm",
    depthMM: 103,
    heightMM: 787,
    widthMM: 345,
    volumeL: 7.7,
    nominalDeltaT50KW: 0.478,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 308,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-687-08",
    rangeType: "Florence 3",
    model: "Florence 3 Column 685mm",
    depthMM: 103,
    heightMM: 787,
    widthMM: 390,
    volumeL: 8.8,
    nominalDeltaT50KW: 0.546,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 335,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-687-09",
    rangeType: "Florence 3",
    model: "Florence 3 Column 685mm",
    depthMM: 103,
    heightMM: 787,
    widthMM: 435,
    volumeL: 9.9,
    nominalDeltaT50KW: 0.615,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 363,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-687-10",
    rangeType: "Florence 3",
    model: "Florence 3 Column 685mm",
    depthMM: 103,
    heightMM: 787,
    widthMM: 480,
    volumeL: 11,
    nominalDeltaT50KW: 0.683,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 390,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-687-11",
    rangeType: "Florence 3",
    model: "Florence 3 Column 685mm",
    depthMM: 103,
    heightMM: 787,
    widthMM: 525,
    volumeL: 12.1,
    nominalDeltaT50KW: 0.751,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 418,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-687-12",
    rangeType: "Florence 3",
    model: "Florence 3 Column 685mm",
    depthMM: 103,
    heightMM: 787,
    widthMM: 570,
    volumeL: 13.2,
    nominalDeltaT50KW: 0.82,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 445,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-687-13",
    rangeType: "Florence 3",
    model: "Florence 3 Column 685mm",
    depthMM: 103,
    heightMM: 787,
    widthMM: 615,
    volumeL: 14.3,
    nominalDeltaT50KW: 0.888,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 473,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-687-14",
    rangeType: "Florence 3",
    model: "Florence 3 Column 685mm",
    depthMM: 103,
    heightMM: 787,
    widthMM: 660,
    volumeL: 15.4,
    nominalDeltaT50KW: 0.956,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 500,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-687-15",
    rangeType: "Florence 3",
    model: "Florence 3 Column 685mm",
    depthMM: 103,
    heightMM: 787,
    widthMM: 705,
    volumeL: 16.5,
    nominalDeltaT50KW: 1.025,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 528,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-687-16",
    rangeType: "Florence 3",
    model: "Florence 3 Column 685mm",
    depthMM: 103,
    heightMM: 787,
    widthMM: 750,
    volumeL: 17.6,
    nominalDeltaT50KW: 1.093,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 555,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-687-17",
    rangeType: "Florence 3",
    model: "Florence 3 Column 685mm",
    depthMM: 103,
    heightMM: 787,
    widthMM: 795,
    volumeL: 18.7,
    nominalDeltaT50KW: 1.161,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 583,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-687-18",
    rangeType: "Florence 3",
    model: "Florence 3 Column 685mm",
    depthMM: 103,
    heightMM: 787,
    widthMM: 840,
    volumeL: 19.8,
    nominalDeltaT50KW: 1.229,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 610,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-687-19",
    rangeType: "Florence 3",
    model: "Florence 3 Column 685mm",
    depthMM: 103,
    heightMM: 787,
    widthMM: 885,
    volumeL: 20.9,
    nominalDeltaT50KW: 1.298,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 638,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-687-20",
    rangeType: "Florence 3",
    model: "Florence 3 Column 685mm",
    depthMM: 103,
    heightMM: 787,
    widthMM: 930,
    volumeL: 22,
    nominalDeltaT50KW: 1.366,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 665,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-687-21",
    rangeType: "Florence 3",
    model: "Florence 3 Column 685mm",
    depthMM: 103,
    heightMM: 787,
    widthMM: 975,
    volumeL: 23.1,
    nominalDeltaT50KW: 1.434,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 693,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-687-22",
    rangeType: "Florence 3",
    model: "Florence 3 Column 685mm",
    depthMM: 103,
    heightMM: 787,
    widthMM: 1020,
    volumeL: 24.2,
    nominalDeltaT50KW: 1.503,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 720,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-687-23",
    rangeType: "Florence 3",
    model: "Florence 3 Column 685mm",
    depthMM: 103,
    heightMM: 787,
    widthMM: 1065,
    volumeL: 25.3,
    nominalDeltaT50KW: 1.571,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 748,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-687-24",
    rangeType: "Florence 3",
    model: "Florence 3 Column 685mm",
    depthMM: 103,
    heightMM: 787,
    widthMM: 1110,
    volumeL: 26.4,
    nominalDeltaT50KW: 1.639,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 775,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-687-25",
    rangeType: "Florence 3",
    model: "Florence 3 Column 685mm",
    depthMM: 103,
    heightMM: 787,
    widthMM: 1155,
    volumeL: 27.5,
    nominalDeltaT50KW: 1.708,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 803,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-687-26",
    rangeType: "Florence 3",
    model: "Florence 3 Column 685mm",
    depthMM: 103,
    heightMM: 787,
    widthMM: 1200,
    volumeL: 28.6,
    nominalDeltaT50KW: 1.776,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 830,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-687-27",
    rangeType: "Florence 3",
    model: "Florence 3 Column 685mm",
    depthMM: 103,
    heightMM: 787,
    widthMM: 1245,
    volumeL: 29.7,
    nominalDeltaT50KW: 1.844,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 858,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-687-28",
    rangeType: "Florence 3",
    model: "Florence 3 Column 685mm",
    depthMM: 103,
    heightMM: 787,
    widthMM: 1290,
    volumeL: 30.8,
    nominalDeltaT50KW: 1.912,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 885,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-687-29",
    rangeType: "Florence 3",
    model: "Florence 3 Column 685mm",
    depthMM: 103,
    heightMM: 787,
    widthMM: 1335,
    volumeL: 31.9,
    nominalDeltaT50KW: 1.981,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 913,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-687-30",
    rangeType: "Florence 3",
    model: "Florence 3 Column 685mm",
    depthMM: 103,
    heightMM: 787,
    widthMM: 1380,
    volumeL: 33,
    nominalDeltaT50KW: 2.049,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 940,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-687-31",
    rangeType: "Florence 3",
    model: "Florence 3 Column 685mm",
    depthMM: 103,
    heightMM: 787,
    widthMM: 1425,
    volumeL: 34.1,
    nominalDeltaT50KW: 2.117,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 968,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-687-32",
    rangeType: "Florence 3",
    model: "Florence 3 Column 685mm",
    depthMM: 103,
    heightMM: 787,
    widthMM: 1470,
    volumeL: 35.2,
    nominalDeltaT50KW: 2.186,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 995,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-687-33",
    rangeType: "Florence 3",
    model: "Florence 3 Column 685mm",
    depthMM: 103,
    heightMM: 787,
    widthMM: 1515,
    volumeL: 36.3,
    nominalDeltaT50KW: 2.254,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1023,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-687-34",
    rangeType: "Florence 3",
    model: "Florence 3 Column 685mm",
    depthMM: 103,
    heightMM: 787,
    widthMM: 1560,
    volumeL: 37.4,
    nominalDeltaT50KW: 2.322,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1050,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-687-35",
    rangeType: "Florence 3",
    model: "Florence 3 Column 685mm",
    depthMM: 103,
    heightMM: 787,
    widthMM: 1605,
    volumeL: 38.5,
    nominalDeltaT50KW: 2.391,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1078,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-687-36",
    rangeType: "Florence 3",
    model: "Florence 3 Column 685mm",
    depthMM: 103,
    heightMM: 787,
    widthMM: 1650,
    volumeL: 39.6,
    nominalDeltaT50KW: 2.459,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1105,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-687-37",
    rangeType: "Florence 3",
    model: "Florence 3 Column 685mm",
    depthMM: 103,
    heightMM: 787,
    widthMM: 1695,
    volumeL: 40.7,
    nominalDeltaT50KW: 2.527,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1133,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-687-38",
    rangeType: "Florence 3",
    model: "Florence 3 Column 685mm",
    depthMM: 103,
    heightMM: 787,
    widthMM: 1740,
    volumeL: 41.8,
    nominalDeltaT50KW: 2.595,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1160,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-687-39",
    rangeType: "Florence 3",
    model: "Florence 3 Column 685mm",
    depthMM: 103,
    heightMM: 787,
    widthMM: 1785,
    volumeL: 42.9,
    nominalDeltaT50KW: 2.664,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1188,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-687-40",
    rangeType: "Florence 3",
    model: "Florence 3 Column 685mm",
    depthMM: 103,
    heightMM: 787,
    widthMM: 1830,
    volumeL: 44,
    nominalDeltaT50KW: 2.732,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1215,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-767-04",
    rangeType: "Florence 3",
    model: "Florence 3 Column 765mm",
    depthMM: 103,
    heightMM: 867,
    widthMM: 210,
    volumeL: 4.8,
    nominalDeltaT50KW: 0.302,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 229,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-767-05",
    rangeType: "Florence 3",
    model: "Florence 3 Column 765mm",
    depthMM: 103,
    heightMM: 867,
    widthMM: 255,
    volumeL: 6,
    nominalDeltaT50KW: 0.378,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 258,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-767-06",
    rangeType: "Florence 3",
    model: "Florence 3 Column 765mm",
    depthMM: 103,
    heightMM: 867,
    widthMM: 300,
    volumeL: 7.2,
    nominalDeltaT50KW: 0.454,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 286,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-767-07",
    rangeType: "Florence 3",
    model: "Florence 3 Column 765mm",
    depthMM: 103,
    heightMM: 867,
    widthMM: 345,
    volumeL: 8.4,
    nominalDeltaT50KW: 0.529,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 315,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-767-08",
    rangeType: "Florence 3",
    model: "Florence 3 Column 765mm",
    depthMM: 103,
    heightMM: 867,
    widthMM: 390,
    volumeL: 9.6,
    nominalDeltaT50KW: 0.605,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 343,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-767-09",
    rangeType: "Florence 3",
    model: "Florence 3 Column 765mm",
    depthMM: 103,
    heightMM: 867,
    widthMM: 435,
    volumeL: 10.8,
    nominalDeltaT50KW: 0.68,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 372,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-767-10",
    rangeType: "Florence 3",
    model: "Florence 3 Column 765mm",
    depthMM: 103,
    heightMM: 867,
    widthMM: 480,
    volumeL: 12,
    nominalDeltaT50KW: 0.756,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 400,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-767-11",
    rangeType: "Florence 3",
    model: "Florence 3 Column 765mm",
    depthMM: 103,
    heightMM: 867,
    widthMM: 525,
    volumeL: 13.2,
    nominalDeltaT50KW: 0.832,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 429,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-767-12",
    rangeType: "Florence 3",
    model: "Florence 3 Column 765mm",
    depthMM: 103,
    heightMM: 867,
    widthMM: 570,
    volumeL: 14.4,
    nominalDeltaT50KW: 0.907,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 457,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-767-13",
    rangeType: "Florence 3",
    model: "Florence 3 Column 765mm",
    depthMM: 103,
    heightMM: 867,
    widthMM: 615,
    volumeL: 15.6,
    nominalDeltaT50KW: 0.983,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 486,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-767-14",
    rangeType: "Florence 3",
    model: "Florence 3 Column 765mm",
    depthMM: 103,
    heightMM: 867,
    widthMM: 660,
    volumeL: 16.8,
    nominalDeltaT50KW: 1.058,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 514,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-767-15",
    rangeType: "Florence 3",
    model: "Florence 3 Column 765mm",
    depthMM: 103,
    heightMM: 867,
    widthMM: 705,
    volumeL: 18,
    nominalDeltaT50KW: 1.134,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 543,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-767-16",
    rangeType: "Florence 3",
    model: "Florence 3 Column 765mm",
    depthMM: 103,
    heightMM: 867,
    widthMM: 750,
    volumeL: 19.2,
    nominalDeltaT50KW: 1.21,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 571,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-767-17",
    rangeType: "Florence 3",
    model: "Florence 3 Column 765mm",
    depthMM: 103,
    heightMM: 867,
    widthMM: 795,
    volumeL: 20.4,
    nominalDeltaT50KW: 1.285,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 600,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-767-18",
    rangeType: "Florence 3",
    model: "Florence 3 Column 765mm",
    depthMM: 103,
    heightMM: 867,
    widthMM: 840,
    volumeL: 21.6,
    nominalDeltaT50KW: 1.361,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 628,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-767-19",
    rangeType: "Florence 3",
    model: "Florence 3 Column 765mm",
    depthMM: 103,
    heightMM: 867,
    widthMM: 885,
    volumeL: 22.8,
    nominalDeltaT50KW: 1.436,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 657,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-767-20",
    rangeType: "Florence 3",
    model: "Florence 3 Column 765mm",
    depthMM: 103,
    heightMM: 867,
    widthMM: 930,
    volumeL: 24,
    nominalDeltaT50KW: 1.512,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 685,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-767-21",
    rangeType: "Florence 3",
    model: "Florence 3 Column 765mm",
    depthMM: 103,
    heightMM: 867,
    widthMM: 975,
    volumeL: 25.2,
    nominalDeltaT50KW: 1.588,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 714,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-767-22",
    rangeType: "Florence 3",
    model: "Florence 3 Column 765mm",
    depthMM: 103,
    heightMM: 867,
    widthMM: 1020,
    volumeL: 26.4,
    nominalDeltaT50KW: 1.663,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 742,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-767-23",
    rangeType: "Florence 3",
    model: "Florence 3 Column 765mm",
    depthMM: 103,
    heightMM: 867,
    widthMM: 1065,
    volumeL: 27.6,
    nominalDeltaT50KW: 1.739,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 771,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-767-24",
    rangeType: "Florence 3",
    model: "Florence 3 Column 765mm",
    depthMM: 103,
    heightMM: 867,
    widthMM: 1110,
    volumeL: 28.8,
    nominalDeltaT50KW: 1.814,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 799,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-767-25",
    rangeType: "Florence 3",
    model: "Florence 3 Column 765mm",
    depthMM: 103,
    heightMM: 867,
    widthMM: 1155,
    volumeL: 30,
    nominalDeltaT50KW: 1.89,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 828,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-767-26",
    rangeType: "Florence 3",
    model: "Florence 3 Column 765mm",
    depthMM: 103,
    heightMM: 867,
    widthMM: 1200,
    volumeL: 31.2,
    nominalDeltaT50KW: 1.966,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 856,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-767-27",
    rangeType: "Florence 3",
    model: "Florence 3 Column 765mm",
    depthMM: 103,
    heightMM: 867,
    widthMM: 1245,
    volumeL: 32.4,
    nominalDeltaT50KW: 2.041,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 885,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-767-28",
    rangeType: "Florence 3",
    model: "Florence 3 Column 765mm",
    depthMM: 103,
    heightMM: 867,
    widthMM: 1290,
    volumeL: 33.6,
    nominalDeltaT50KW: 2.117,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 913,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-767-29",
    rangeType: "Florence 3",
    model: "Florence 3 Column 765mm",
    depthMM: 103,
    heightMM: 867,
    widthMM: 1335,
    volumeL: 34.8,
    nominalDeltaT50KW: 2.192,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 942,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-767-30",
    rangeType: "Florence 3",
    model: "Florence 3 Column 765mm",
    depthMM: 103,
    heightMM: 867,
    widthMM: 1380,
    volumeL: 36,
    nominalDeltaT50KW: 2.268,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 970,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-767-31",
    rangeType: "Florence 3",
    model: "Florence 3 Column 765mm",
    depthMM: 103,
    heightMM: 867,
    widthMM: 1425,
    volumeL: 37.2,
    nominalDeltaT50KW: 2.344,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 999,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-767-32",
    rangeType: "Florence 3",
    model: "Florence 3 Column 765mm",
    depthMM: 103,
    heightMM: 867,
    widthMM: 1470,
    volumeL: 38.4,
    nominalDeltaT50KW: 2.419,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1027,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-767-33",
    rangeType: "Florence 3",
    model: "Florence 3 Column 765mm",
    depthMM: 103,
    heightMM: 867,
    widthMM: 1515,
    volumeL: 39.6,
    nominalDeltaT50KW: 2.495,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1056,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-767-34",
    rangeType: "Florence 3",
    model: "Florence 3 Column 765mm",
    depthMM: 103,
    heightMM: 867,
    widthMM: 1560,
    volumeL: 40.8,
    nominalDeltaT50KW: 2.57,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1084,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-767-35",
    rangeType: "Florence 3",
    model: "Florence 3 Column 765mm",
    depthMM: 103,
    heightMM: 867,
    widthMM: 1605,
    volumeL: 42,
    nominalDeltaT50KW: 2.646,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1113,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-767-36",
    rangeType: "Florence 3",
    model: "Florence 3 Column 765mm",
    depthMM: 103,
    heightMM: 867,
    widthMM: 1650,
    volumeL: 43.2,
    nominalDeltaT50KW: 2.722,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1141,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-767-37",
    rangeType: "Florence 3",
    model: "Florence 3 Column 765mm",
    depthMM: 103,
    heightMM: 867,
    widthMM: 1695,
    volumeL: 44.4,
    nominalDeltaT50KW: 2.797,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1170,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-767-38",
    rangeType: "Florence 3",
    model: "Florence 3 Column 765mm",
    depthMM: 103,
    heightMM: 867,
    widthMM: 1740,
    volumeL: 45.6,
    nominalDeltaT50KW: 2.873,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1198,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-767-39",
    rangeType: "Florence 3",
    model: "Florence 3 Column 765mm",
    depthMM: 103,
    heightMM: 867,
    widthMM: 1785,
    volumeL: 46.8,
    nominalDeltaT50KW: 2.948,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1227,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-767-40",
    rangeType: "Florence 3",
    model: "Florence 3 Column 765mm",
    depthMM: 103,
    heightMM: 867,
    widthMM: 1830,
    volumeL: 48,
    nominalDeltaT50KW: 3.024,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1255,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-902-04",
    rangeType: "Florence 3",
    model: "Florence 3 Column 900mm",
    depthMM: 103,
    heightMM: 1002,
    widthMM: 210,
    volumeL: 5.2,
    nominalDeltaT50KW: 0.351,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 241,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-902-05",
    rangeType: "Florence 3",
    model: "Florence 3 Column 900mm",
    depthMM: 103,
    heightMM: 1002,
    widthMM: 255,
    volumeL: 6.5,
    nominalDeltaT50KW: 0.439,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 273,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-902-06",
    rangeType: "Florence 3",
    model: "Florence 3 Column 900mm",
    depthMM: 103,
    heightMM: 1002,
    widthMM: 300,
    volumeL: 7.8,
    nominalDeltaT50KW: 0.527,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 304,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-902-07",
    rangeType: "Florence 3",
    model: "Florence 3 Column 900mm",
    depthMM: 103,
    heightMM: 1002,
    widthMM: 345,
    volumeL: 9.1,
    nominalDeltaT50KW: 0.615,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 336,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-902-08",
    rangeType: "Florence 3",
    model: "Florence 3 Column 900mm",
    depthMM: 103,
    heightMM: 1002,
    widthMM: 390,
    volumeL: 10.4,
    nominalDeltaT50KW: 0.702,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 367,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-902-09",
    rangeType: "Florence 3",
    model: "Florence 3 Column 900mm",
    depthMM: 103,
    heightMM: 1002,
    widthMM: 435,
    volumeL: 11.7,
    nominalDeltaT50KW: 0.79,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 399,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-902-10",
    rangeType: "Florence 3",
    model: "Florence 3 Column 900mm",
    depthMM: 103,
    heightMM: 1002,
    widthMM: 480,
    volumeL: 13,
    nominalDeltaT50KW: 0.878,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 430,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-902-11",
    rangeType: "Florence 3",
    model: "Florence 3 Column 900mm",
    depthMM: 103,
    heightMM: 1002,
    widthMM: 525,
    volumeL: 14.3,
    nominalDeltaT50KW: 0.966,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 462,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-902-12",
    rangeType: "Florence 3",
    model: "Florence 3 Column 900mm",
    depthMM: 103,
    heightMM: 1002,
    widthMM: 570,
    volumeL: 15.6,
    nominalDeltaT50KW: 1.054,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 493,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-902-13",
    rangeType: "Florence 3",
    model: "Florence 3 Column 900mm",
    depthMM: 103,
    heightMM: 1002,
    widthMM: 615,
    volumeL: 16.9,
    nominalDeltaT50KW: 1.141,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 525,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-902-14",
    rangeType: "Florence 3",
    model: "Florence 3 Column 900mm",
    depthMM: 103,
    heightMM: 1002,
    widthMM: 660,
    volumeL: 18.2,
    nominalDeltaT50KW: 1.229,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 556,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-902-15",
    rangeType: "Florence 3",
    model: "Florence 3 Column 900mm",
    depthMM: 103,
    heightMM: 1002,
    widthMM: 705,
    volumeL: 19.5,
    nominalDeltaT50KW: 1.317,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 588,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-902-16",
    rangeType: "Florence 3",
    model: "Florence 3 Column 900mm",
    depthMM: 103,
    heightMM: 1002,
    widthMM: 750,
    volumeL: 20.8,
    nominalDeltaT50KW: 1.405,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 619,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-902-17",
    rangeType: "Florence 3",
    model: "Florence 3 Column 900mm",
    depthMM: 103,
    heightMM: 1002,
    widthMM: 795,
    volumeL: 22.1,
    nominalDeltaT50KW: 1.493,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 651,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-902-18",
    rangeType: "Florence 3",
    model: "Florence 3 Column 900mm",
    depthMM: 103,
    heightMM: 1002,
    widthMM: 840,
    volumeL: 23.4,
    nominalDeltaT50KW: 1.58,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 682,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-902-19",
    rangeType: "Florence 3",
    model: "Florence 3 Column 900mm",
    depthMM: 103,
    heightMM: 1002,
    widthMM: 885,
    volumeL: 24.7,
    nominalDeltaT50KW: 1.668,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 714,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-902-20",
    rangeType: "Florence 3",
    model: "Florence 3 Column 900mm",
    depthMM: 103,
    heightMM: 1002,
    widthMM: 930,
    volumeL: 26,
    nominalDeltaT50KW: 1.756,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 745,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-902-21",
    rangeType: "Florence 3",
    model: "Florence 3 Column 900mm",
    depthMM: 103,
    heightMM: 1002,
    widthMM: 975,
    volumeL: 27.3,
    nominalDeltaT50KW: 1.844,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 777,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-902-22",
    rangeType: "Florence 3",
    model: "Florence 3 Column 900mm",
    depthMM: 103,
    heightMM: 1002,
    widthMM: 1020,
    volumeL: 28.6,
    nominalDeltaT50KW: 1.932,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 808,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-902-23",
    rangeType: "Florence 3",
    model: "Florence 3 Column 900mm",
    depthMM: 103,
    heightMM: 1002,
    widthMM: 1065,
    volumeL: 29.9,
    nominalDeltaT50KW: 2.019,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 840,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-902-24",
    rangeType: "Florence 3",
    model: "Florence 3 Column 900mm",
    depthMM: 103,
    heightMM: 1002,
    widthMM: 1110,
    volumeL: 31.2,
    nominalDeltaT50KW: 2.107,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 871,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-902-25",
    rangeType: "Florence 3",
    model: "Florence 3 Column 900mm",
    depthMM: 103,
    heightMM: 1002,
    widthMM: 1155,
    volumeL: 32.5,
    nominalDeltaT50KW: 2.195,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 903,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-902-26",
    rangeType: "Florence 3",
    model: "Florence 3 Column 900mm",
    depthMM: 103,
    heightMM: 1002,
    widthMM: 1200,
    volumeL: 33.8,
    nominalDeltaT50KW: 2.283,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 934,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-902-27",
    rangeType: "Florence 3",
    model: "Florence 3 Column 900mm",
    depthMM: 103,
    heightMM: 1002,
    widthMM: 1245,
    volumeL: 35.1,
    nominalDeltaT50KW: 2.371,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 966,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-902-28",
    rangeType: "Florence 3",
    model: "Florence 3 Column 900mm",
    depthMM: 103,
    heightMM: 1002,
    widthMM: 1290,
    volumeL: 36.4,
    nominalDeltaT50KW: 2.458,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 997,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-902-29",
    rangeType: "Florence 3",
    model: "Florence 3 Column 900mm",
    depthMM: 103,
    heightMM: 1002,
    widthMM: 1335,
    volumeL: 37.7,
    nominalDeltaT50KW: 2.546,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1029,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-902-30",
    rangeType: "Florence 3",
    model: "Florence 3 Column 900mm",
    depthMM: 103,
    heightMM: 1002,
    widthMM: 1380,
    volumeL: 39,
    nominalDeltaT50KW: 2.634,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1060,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-902-31",
    rangeType: "Florence 3",
    model: "Florence 3 Column 900mm",
    depthMM: 103,
    heightMM: 1002,
    widthMM: 1425,
    volumeL: 40.3,
    nominalDeltaT50KW: 2.722,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1092,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-902-32",
    rangeType: "Florence 3",
    model: "Florence 3 Column 900mm",
    depthMM: 103,
    heightMM: 1002,
    widthMM: 1470,
    volumeL: 41.6,
    nominalDeltaT50KW: 2.81,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1123,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-902-33",
    rangeType: "Florence 3",
    model: "Florence 3 Column 900mm",
    depthMM: 103,
    heightMM: 1002,
    widthMM: 1515,
    volumeL: 42.9,
    nominalDeltaT50KW: 2.897,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1155,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-902-34",
    rangeType: "Florence 3",
    model: "Florence 3 Column 900mm",
    depthMM: 103,
    heightMM: 1002,
    widthMM: 1560,
    volumeL: 44.2,
    nominalDeltaT50KW: 2.985,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1186,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-902-35",
    rangeType: "Florence 3",
    model: "Florence 3 Column 900mm",
    depthMM: 103,
    heightMM: 1002,
    widthMM: 1605,
    volumeL: 45.5,
    nominalDeltaT50KW: 3.073,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1218,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-902-36",
    rangeType: "Florence 3",
    model: "Florence 3 Column 900mm",
    depthMM: 103,
    heightMM: 1002,
    widthMM: 1650,
    volumeL: 46.8,
    nominalDeltaT50KW: 3.161,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1249,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-902-37",
    rangeType: "Florence 3",
    model: "Florence 3 Column 900mm",
    depthMM: 103,
    heightMM: 1002,
    widthMM: 1695,
    volumeL: 48.1,
    nominalDeltaT50KW: 3.249,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1281,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-902-38",
    rangeType: "Florence 3",
    model: "Florence 3 Column 900mm",
    depthMM: 103,
    heightMM: 1002,
    widthMM: 1740,
    volumeL: 49.4,
    nominalDeltaT50KW: 3.336,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1312,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-902-39",
    rangeType: "Florence 3",
    model: "Florence 3 Column 900mm",
    depthMM: 103,
    heightMM: 1002,
    widthMM: 1785,
    volumeL: 50.7,
    nominalDeltaT50KW: 3.424,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1344,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F3C-902-40",
    rangeType: "Florence 3",
    model: "Florence 3 Column 900mm",
    depthMM: 103,
    heightMM: 1002,
    widthMM: 1830,
    volumeL: 52,
    nominalDeltaT50KW: 3.512,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1375,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1002-04",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1000mm",
    depthMM: 141,
    heightMM: 1102,
    widthMM: 210,
    volumeL: 7.68,
    nominalDeltaT50KW: 0.504,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 301,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1002-05",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1000mm",
    depthMM: 141,
    heightMM: 1102,
    widthMM: 255,
    volumeL: 9.6,
    nominalDeltaT50KW: 0.63,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 348,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1002-06",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1000mm",
    depthMM: 141,
    heightMM: 1102,
    widthMM: 300,
    volumeL: 11.52,
    nominalDeltaT50KW: 0.755,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 394,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1002-07",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1000mm",
    depthMM: 141,
    heightMM: 1102,
    widthMM: 345,
    volumeL: 13.44,
    nominalDeltaT50KW: 0.881,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 441,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1002-08",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1000mm",
    depthMM: 141,
    heightMM: 1102,
    widthMM: 390,
    volumeL: 15.36,
    nominalDeltaT50KW: 1.007,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 487,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1002-09",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1000mm",
    depthMM: 141,
    heightMM: 1102,
    widthMM: 435,
    volumeL: 17.28,
    nominalDeltaT50KW: 1.133,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 534,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1002-10",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1000mm",
    depthMM: 141,
    heightMM: 1102,
    widthMM: 480,
    volumeL: 19.2,
    nominalDeltaT50KW: 1.259,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 580,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1002-11",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1000mm",
    depthMM: 141,
    heightMM: 1102,
    widthMM: 525,
    volumeL: 21.12,
    nominalDeltaT50KW: 1.385,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 627,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1002-12",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1000mm",
    depthMM: 141,
    heightMM: 1102,
    widthMM: 570,
    volumeL: 23.04,
    nominalDeltaT50KW: 1.511,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 673,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1002-13",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1000mm",
    depthMM: 141,
    heightMM: 1102,
    widthMM: 615,
    volumeL: 24.96,
    nominalDeltaT50KW: 1.637,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 720,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1002-14",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1000mm",
    depthMM: 141,
    heightMM: 1102,
    widthMM: 660,
    volumeL: 26.88,
    nominalDeltaT50KW: 1.763,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 766,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1002-15",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1000mm",
    depthMM: 141,
    heightMM: 1102,
    widthMM: 705,
    volumeL: 28.8,
    nominalDeltaT50KW: 1.889,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 813,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1002-16",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1000mm",
    depthMM: 141,
    heightMM: 1102,
    widthMM: 750,
    volumeL: 30.72,
    nominalDeltaT50KW: 2.014,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 859,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1002-17",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1000mm",
    depthMM: 141,
    heightMM: 1102,
    widthMM: 795,
    volumeL: 32.64,
    nominalDeltaT50KW: 2.14,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 906,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1002-18",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1000mm",
    depthMM: 141,
    heightMM: 1102,
    widthMM: 840,
    volumeL: 34.56,
    nominalDeltaT50KW: 2.266,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 952,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1002-19",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1000mm",
    depthMM: 141,
    heightMM: 1102,
    widthMM: 885,
    volumeL: 36.48,
    nominalDeltaT50KW: 2.392,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 999,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1002-20",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1000mm",
    depthMM: 141,
    heightMM: 1102,
    widthMM: 930,
    volumeL: 38.4,
    nominalDeltaT50KW: 2.518,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1045,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1002-21",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1000mm",
    depthMM: 141,
    heightMM: 1102,
    widthMM: 975,
    volumeL: 40.32,
    nominalDeltaT50KW: 2.644,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1092,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1002-22",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1000mm",
    depthMM: 141,
    heightMM: 1102,
    widthMM: 1020,
    volumeL: 42.24,
    nominalDeltaT50KW: 2.77,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1138,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1002-23",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1000mm",
    depthMM: 141,
    heightMM: 1102,
    widthMM: 1065,
    volumeL: 44.16,
    nominalDeltaT50KW: 2.896,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1185,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1002-24",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1000mm",
    depthMM: 141,
    heightMM: 1102,
    widthMM: 1110,
    volumeL: 46.08,
    nominalDeltaT50KW: 3.022,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1231,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1002-25",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1000mm",
    depthMM: 141,
    heightMM: 1102,
    widthMM: 1155,
    volumeL: 48,
    nominalDeltaT50KW: 3.148,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1278,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1002-26",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1000mm",
    depthMM: 141,
    heightMM: 1102,
    widthMM: 1200,
    volumeL: 49.92,
    nominalDeltaT50KW: 3.273,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1324,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1002-27",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1000mm",
    depthMM: 141,
    heightMM: 1102,
    widthMM: 1245,
    volumeL: 51.84,
    nominalDeltaT50KW: 3.399,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1371,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1002-28",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1000mm",
    depthMM: 141,
    heightMM: 1102,
    widthMM: 1290,
    volumeL: 53.76,
    nominalDeltaT50KW: 3.525,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1417,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1002-29",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1000mm",
    depthMM: 141,
    heightMM: 1102,
    widthMM: 1335,
    volumeL: 55.68,
    nominalDeltaT50KW: 3.651,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1464,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1002-30",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1000mm",
    depthMM: 141,
    heightMM: 1102,
    widthMM: 1380,
    volumeL: 57.6,
    nominalDeltaT50KW: 3.777,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1510,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1002-31",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1000mm",
    depthMM: 141,
    heightMM: 1102,
    widthMM: 1425,
    volumeL: 59.52,
    nominalDeltaT50KW: 3.903,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1557,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1002-32",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1000mm",
    depthMM: 141,
    heightMM: 1102,
    widthMM: 1470,
    volumeL: 61.44,
    nominalDeltaT50KW: 4.029,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1603,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1002-33",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1000mm",
    depthMM: 141,
    heightMM: 1102,
    widthMM: 1515,
    volumeL: 63.36,
    nominalDeltaT50KW: 4.155,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1650,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1002-34",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1000mm",
    depthMM: 141,
    heightMM: 1102,
    widthMM: 1560,
    volumeL: 65.28,
    nominalDeltaT50KW: 4.281,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1696,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1002-35",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1000mm",
    depthMM: 141,
    heightMM: 1102,
    widthMM: 1605,
    volumeL: 67.2,
    nominalDeltaT50KW: 4.407,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1743,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1002-36",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1000mm",
    depthMM: 141,
    heightMM: 1102,
    widthMM: 1650,
    volumeL: 69.12,
    nominalDeltaT50KW: 4.532,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1789,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1002-37",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1000mm",
    depthMM: 141,
    heightMM: 1102,
    widthMM: 1695,
    volumeL: 71.04,
    nominalDeltaT50KW: 4.658,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1836,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1002-38",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1000mm",
    depthMM: 141,
    heightMM: 1102,
    widthMM: 1740,
    volumeL: 72.96,
    nominalDeltaT50KW: 4.784,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1882,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1002-39",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1000mm",
    depthMM: 141,
    heightMM: 1102,
    widthMM: 1785,
    volumeL: 74.88,
    nominalDeltaT50KW: 4.91,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1929,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1002-40",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1000mm",
    depthMM: 141,
    heightMM: 1102,
    widthMM: 1830,
    volumeL: 76.8,
    nominalDeltaT50KW: 5.036,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1975,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1202-04",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1200mm",
    depthMM: 141,
    heightMM: 1302,
    widthMM: 210,
    volumeL: 9,
    nominalDeltaT50KW: 0.595,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 349,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1202-05",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1200mm",
    depthMM: 141,
    heightMM: 1302,
    widthMM: 255,
    volumeL: 11.25,
    nominalDeltaT50KW: 0.744,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 408,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1202-06",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1200mm",
    depthMM: 141,
    heightMM: 1302,
    widthMM: 300,
    volumeL: 13.5,
    nominalDeltaT50KW: 0.893,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 466,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1202-07",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1200mm",
    depthMM: 141,
    heightMM: 1302,
    widthMM: 345,
    volumeL: 15.75,
    nominalDeltaT50KW: 1.042,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 525,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1202-08",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1200mm",
    depthMM: 141,
    heightMM: 1302,
    widthMM: 390,
    volumeL: 18,
    nominalDeltaT50KW: 1.19,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 583,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1202-09",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1200mm",
    depthMM: 141,
    heightMM: 1302,
    widthMM: 435,
    volumeL: 20.25,
    nominalDeltaT50KW: 1.339,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 642,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1202-10",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1200mm",
    depthMM: 141,
    heightMM: 1302,
    widthMM: 480,
    volumeL: 22.5,
    nominalDeltaT50KW: 1.488,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 700,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1202-11",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1200mm",
    depthMM: 141,
    heightMM: 1302,
    widthMM: 525,
    volumeL: 24.75,
    nominalDeltaT50KW: 1.637,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 759,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1202-12",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1200mm",
    depthMM: 141,
    heightMM: 1302,
    widthMM: 570,
    volumeL: 27,
    nominalDeltaT50KW: 1.786,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 817,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1202-13",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1200mm",
    depthMM: 141,
    heightMM: 1302,
    widthMM: 615,
    volumeL: 29.25,
    nominalDeltaT50KW: 1.934,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 876,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1202-14",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1200mm",
    depthMM: 141,
    heightMM: 1302,
    widthMM: 660,
    volumeL: 31.5,
    nominalDeltaT50KW: 2.083,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 934,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1202-15",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1200mm",
    depthMM: 141,
    heightMM: 1302,
    widthMM: 705,
    volumeL: 33.75,
    nominalDeltaT50KW: 2.232,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 993,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1202-16",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1200mm",
    depthMM: 141,
    heightMM: 1302,
    widthMM: 750,
    volumeL: 36,
    nominalDeltaT50KW: 2.381,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1051,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1202-17",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1200mm",
    depthMM: 141,
    heightMM: 1302,
    widthMM: 795,
    volumeL: 38.25,
    nominalDeltaT50KW: 2.53,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1110,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1202-18",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1200mm",
    depthMM: 141,
    heightMM: 1302,
    widthMM: 840,
    volumeL: 40.5,
    nominalDeltaT50KW: 2.678,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1168,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1202-19",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1200mm",
    depthMM: 141,
    heightMM: 1302,
    widthMM: 885,
    volumeL: 42.75,
    nominalDeltaT50KW: 2.827,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1227,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1202-20",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1200mm",
    depthMM: 141,
    heightMM: 1302,
    widthMM: 930,
    volumeL: 45,
    nominalDeltaT50KW: 2.976,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1285,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1202-21",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1200mm",
    depthMM: 141,
    heightMM: 1302,
    widthMM: 975,
    volumeL: 47.25,
    nominalDeltaT50KW: 3.125,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1344,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1202-22",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1200mm",
    depthMM: 141,
    heightMM: 1302,
    widthMM: 1020,
    volumeL: 49.5,
    nominalDeltaT50KW: 3.274,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1402,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1202-23",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1200mm",
    depthMM: 141,
    heightMM: 1302,
    widthMM: 1065,
    volumeL: 51.75,
    nominalDeltaT50KW: 3.422,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1461,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1202-24",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1200mm",
    depthMM: 141,
    heightMM: 1302,
    widthMM: 1110,
    volumeL: 54,
    nominalDeltaT50KW: 3.571,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1519,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1202-25",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1200mm",
    depthMM: 141,
    heightMM: 1302,
    widthMM: 1155,
    volumeL: 56.25,
    nominalDeltaT50KW: 3.72,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1578,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1202-26",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1200mm",
    depthMM: 141,
    heightMM: 1302,
    widthMM: 1200,
    volumeL: 58.5,
    nominalDeltaT50KW: 3.869,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1636,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1202-27",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1200mm",
    depthMM: 141,
    heightMM: 1302,
    widthMM: 1245,
    volumeL: 60.75,
    nominalDeltaT50KW: 4.018,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1695,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1202-28",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1200mm",
    depthMM: 141,
    heightMM: 1302,
    widthMM: 1290,
    volumeL: 63,
    nominalDeltaT50KW: 4.166,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1753,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1202-29",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1200mm",
    depthMM: 141,
    heightMM: 1302,
    widthMM: 1335,
    volumeL: 65.25,
    nominalDeltaT50KW: 4.315,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1812,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1202-30",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1200mm",
    depthMM: 141,
    heightMM: 1302,
    widthMM: 1380,
    volumeL: 67.5,
    nominalDeltaT50KW: 4.464,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1870,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1202-31",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1200mm",
    depthMM: 141,
    heightMM: 1302,
    widthMM: 1425,
    volumeL: 69.75,
    nominalDeltaT50KW: 4.613,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1929,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1202-32",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1200mm",
    depthMM: 141,
    heightMM: 1302,
    widthMM: 1470,
    volumeL: 72,
    nominalDeltaT50KW: 4.762,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1987,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1202-33",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1200mm",
    depthMM: 141,
    heightMM: 1302,
    widthMM: 1515,
    volumeL: 74.25,
    nominalDeltaT50KW: 4.91,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 2046,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1202-34",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1200mm",
    depthMM: 141,
    heightMM: 1302,
    widthMM: 1560,
    volumeL: 76.5,
    nominalDeltaT50KW: 5.059,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 2104,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1202-35",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1200mm",
    depthMM: 141,
    heightMM: 1302,
    widthMM: 1605,
    volumeL: 78.75,
    nominalDeltaT50KW: 5.208,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 2163,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1202-36",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1200mm",
    depthMM: 141,
    heightMM: 1302,
    widthMM: 1650,
    volumeL: 81,
    nominalDeltaT50KW: 5.357,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 2221,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1202-37",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1200mm",
    depthMM: 141,
    heightMM: 1302,
    widthMM: 1695,
    volumeL: 83.25,
    nominalDeltaT50KW: 5.506,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 2280,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1202-38",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1200mm",
    depthMM: 141,
    heightMM: 1302,
    widthMM: 1740,
    volumeL: 85.5,
    nominalDeltaT50KW: 5.654,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 2338,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1202-39",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1200mm",
    depthMM: 141,
    heightMM: 1302,
    widthMM: 1785,
    volumeL: 87.75,
    nominalDeltaT50KW: 5.803,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 2397,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1202-40",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1200mm",
    depthMM: 141,
    heightMM: 1302,
    widthMM: 1830,
    volumeL: 90,
    nominalDeltaT50KW: 5.952,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 2455,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1502-04",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1500mm",
    depthMM: 141,
    heightMM: 1602,
    widthMM: 210,
    volumeL: 10.96,
    nominalDeltaT50KW: 0.73,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 433,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1502-05",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1500mm",
    depthMM: 141,
    heightMM: 1602,
    widthMM: 255,
    volumeL: 13.7,
    nominalDeltaT50KW: 0.913,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 513,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1502-06",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1500mm",
    depthMM: 141,
    heightMM: 1602,
    widthMM: 300,
    volumeL: 16.44,
    nominalDeltaT50KW: 1.096,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 592,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1502-07",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1500mm",
    depthMM: 141,
    heightMM: 1602,
    widthMM: 345,
    volumeL: 19.18,
    nominalDeltaT50KW: 1.278,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 672,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1502-08",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1500mm",
    depthMM: 141,
    heightMM: 1602,
    widthMM: 390,
    volumeL: 21.92,
    nominalDeltaT50KW: 1.461,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 751,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1502-09",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1500mm",
    depthMM: 141,
    heightMM: 1602,
    widthMM: 435,
    volumeL: 24.66,
    nominalDeltaT50KW: 1.643,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 831,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1502-10",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1500mm",
    depthMM: 141,
    heightMM: 1602,
    widthMM: 480,
    volumeL: 27.4,
    nominalDeltaT50KW: 1.826,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 910,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1502-11",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1500mm",
    depthMM: 141,
    heightMM: 1602,
    widthMM: 525,
    volumeL: 30.14,
    nominalDeltaT50KW: 2.009,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 990,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1502-12",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1500mm",
    depthMM: 141,
    heightMM: 1602,
    widthMM: 570,
    volumeL: 32.88,
    nominalDeltaT50KW: 2.191,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1069,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1502-13",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1500mm",
    depthMM: 141,
    heightMM: 1602,
    widthMM: 615,
    volumeL: 35.62,
    nominalDeltaT50KW: 2.374,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1149,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1502-14",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1500mm",
    depthMM: 141,
    heightMM: 1602,
    widthMM: 660,
    volumeL: 38.36,
    nominalDeltaT50KW: 2.556,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1228,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1502-15",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1500mm",
    depthMM: 141,
    heightMM: 1602,
    widthMM: 705,
    volumeL: 41.1,
    nominalDeltaT50KW: 2.739,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1308,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1502-16",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1500mm",
    depthMM: 141,
    heightMM: 1602,
    widthMM: 750,
    volumeL: 43.84,
    nominalDeltaT50KW: 2.922,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1387,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1502-17",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1500mm",
    depthMM: 141,
    heightMM: 1602,
    widthMM: 795,
    volumeL: 46.58,
    nominalDeltaT50KW: 3.104,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1467,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1502-18",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1500mm",
    depthMM: 141,
    heightMM: 1602,
    widthMM: 840,
    volumeL: 49.32,
    nominalDeltaT50KW: 3.287,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1546,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1502-19",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1500mm",
    depthMM: 141,
    heightMM: 1602,
    widthMM: 885,
    volumeL: 52.06,
    nominalDeltaT50KW: 3.469,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1626,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1502-20",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1500mm",
    depthMM: 141,
    heightMM: 1602,
    widthMM: 930,
    volumeL: 54.8,
    nominalDeltaT50KW: 3.652,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1705,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1502-21",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1500mm",
    depthMM: 141,
    heightMM: 1602,
    widthMM: 975,
    volumeL: 57.54,
    nominalDeltaT50KW: 3.835,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1785,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1502-22",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1500mm",
    depthMM: 141,
    heightMM: 1602,
    widthMM: 1020,
    volumeL: 60.28,
    nominalDeltaT50KW: 4.017,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1864,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1502-23",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1500mm",
    depthMM: 141,
    heightMM: 1602,
    widthMM: 1065,
    volumeL: 63.02,
    nominalDeltaT50KW: 4.2,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1944,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1502-24",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1500mm",
    depthMM: 141,
    heightMM: 1602,
    widthMM: 1110,
    volumeL: 65.76,
    nominalDeltaT50KW: 4.382,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2023,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1502-25",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1500mm",
    depthMM: 141,
    heightMM: 1602,
    widthMM: 1155,
    volumeL: 68.5,
    nominalDeltaT50KW: 4.565,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2103,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1502-26",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1500mm",
    depthMM: 141,
    heightMM: 1602,
    widthMM: 1200,
    volumeL: 71.24,
    nominalDeltaT50KW: 4.748,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2182,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1502-27",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1500mm",
    depthMM: 141,
    heightMM: 1602,
    widthMM: 1245,
    volumeL: 73.98,
    nominalDeltaT50KW: 4.93,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2262,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1502-28",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1500mm",
    depthMM: 141,
    heightMM: 1602,
    widthMM: 1290,
    volumeL: 76.72,
    nominalDeltaT50KW: 5.113,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2341,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1502-29",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1500mm",
    depthMM: 141,
    heightMM: 1602,
    widthMM: 1335,
    volumeL: 79.46,
    nominalDeltaT50KW: 5.295,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2421,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1502-30",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1500mm",
    depthMM: 141,
    heightMM: 1602,
    widthMM: 1380,
    volumeL: 82.2,
    nominalDeltaT50KW: 5.478,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2500,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1502-31",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1500mm",
    depthMM: 141,
    heightMM: 1602,
    widthMM: 1425,
    volumeL: 84.94,
    nominalDeltaT50KW: 5.661,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2580,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1502-32",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1500mm",
    depthMM: 141,
    heightMM: 1602,
    widthMM: 1470,
    volumeL: 87.68,
    nominalDeltaT50KW: 5.843,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2659,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1502-33",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1500mm",
    depthMM: 141,
    heightMM: 1602,
    widthMM: 1515,
    volumeL: 90.42,
    nominalDeltaT50KW: 6.026,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2739,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1502-34",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1500mm",
    depthMM: 141,
    heightMM: 1602,
    widthMM: 1560,
    volumeL: 93.16,
    nominalDeltaT50KW: 6.208,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2818,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1502-35",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1500mm",
    depthMM: 141,
    heightMM: 1602,
    widthMM: 1605,
    volumeL: 95.9,
    nominalDeltaT50KW: 6.391,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2898,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1502-36",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1500mm",
    depthMM: 141,
    heightMM: 1602,
    widthMM: 1650,
    volumeL: 98.64,
    nominalDeltaT50KW: 6.574,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2977,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1502-37",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1500mm",
    depthMM: 141,
    heightMM: 1602,
    widthMM: 1695,
    volumeL: 101.38,
    nominalDeltaT50KW: 6.756,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 3057,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1502-38",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1500mm",
    depthMM: 141,
    heightMM: 1602,
    widthMM: 1740,
    volumeL: 104.12,
    nominalDeltaT50KW: 6.939,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 3136,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1502-39",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1500mm",
    depthMM: 141,
    heightMM: 1602,
    widthMM: 1785,
    volumeL: 106.86,
    nominalDeltaT50KW: 7.121,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 3216,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1502-40",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1500mm",
    depthMM: 141,
    heightMM: 1602,
    widthMM: 1830,
    volumeL: 109.6,
    nominalDeltaT50KW: 7.304,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 3295,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1802-04",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1800mm",
    depthMM: 141,
    heightMM: 1902,
    widthMM: 210,
    volumeL: 12.92,
    nominalDeltaT50KW: 0.864,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 465,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1802-05",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1800mm",
    depthMM: 141,
    heightMM: 1902,
    widthMM: 255,
    volumeL: 16.15,
    nominalDeltaT50KW: 1.08,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 553,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1802-06",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1800mm",
    depthMM: 141,
    heightMM: 1902,
    widthMM: 300,
    volumeL: 19.38,
    nominalDeltaT50KW: 1.296,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 640,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1802-07",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1800mm",
    depthMM: 141,
    heightMM: 1902,
    widthMM: 345,
    volumeL: 22.61,
    nominalDeltaT50KW: 1.512,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 728,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1802-08",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1800mm",
    depthMM: 141,
    heightMM: 1902,
    widthMM: 390,
    volumeL: 25.84,
    nominalDeltaT50KW: 1.728,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 815,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1802-09",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1800mm",
    depthMM: 141,
    heightMM: 1902,
    widthMM: 435,
    volumeL: 29.07,
    nominalDeltaT50KW: 1.944,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 903,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1802-10",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1800mm",
    depthMM: 141,
    heightMM: 1902,
    widthMM: 480,
    volumeL: 32.3,
    nominalDeltaT50KW: 2.16,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 990,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1802-11",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1800mm",
    depthMM: 141,
    heightMM: 1902,
    widthMM: 525,
    volumeL: 35.53,
    nominalDeltaT50KW: 2.376,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1078,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1802-12",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1800mm",
    depthMM: 141,
    heightMM: 1902,
    widthMM: 570,
    volumeL: 38.76,
    nominalDeltaT50KW: 2.592,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1165,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1802-13",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1800mm",
    depthMM: 141,
    heightMM: 1902,
    widthMM: 615,
    volumeL: 41.99,
    nominalDeltaT50KW: 2.808,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1253,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1802-14",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1800mm",
    depthMM: 141,
    heightMM: 1902,
    widthMM: 660,
    volumeL: 45.22,
    nominalDeltaT50KW: 3.024,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1340,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1802-15",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1800mm",
    depthMM: 141,
    heightMM: 1902,
    widthMM: 705,
    volumeL: 48.45,
    nominalDeltaT50KW: 3.24,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1428,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1802-16",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1800mm",
    depthMM: 141,
    heightMM: 1902,
    widthMM: 750,
    volumeL: 51.68,
    nominalDeltaT50KW: 3.456,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1515,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1802-17",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1800mm",
    depthMM: 141,
    heightMM: 1902,
    widthMM: 795,
    volumeL: 54.91,
    nominalDeltaT50KW: 3.672,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1603,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1802-18",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1800mm",
    depthMM: 141,
    heightMM: 1902,
    widthMM: 840,
    volumeL: 58.14,
    nominalDeltaT50KW: 3.888,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1690,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1802-19",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1800mm",
    depthMM: 141,
    heightMM: 1902,
    widthMM: 885,
    volumeL: 61.37,
    nominalDeltaT50KW: 4.104,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1778,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1802-20",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1800mm",
    depthMM: 141,
    heightMM: 1902,
    widthMM: 930,
    volumeL: 64.6,
    nominalDeltaT50KW: 4.32,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1865,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1802-21",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1800mm",
    depthMM: 141,
    heightMM: 1902,
    widthMM: 975,
    volumeL: 67.83,
    nominalDeltaT50KW: 4.536,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1953,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1802-22",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1800mm",
    depthMM: 141,
    heightMM: 1902,
    widthMM: 1020,
    volumeL: 71.06,
    nominalDeltaT50KW: 4.752,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2040,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1802-23",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1800mm",
    depthMM: 141,
    heightMM: 1902,
    widthMM: 1065,
    volumeL: 74.29,
    nominalDeltaT50KW: 4.968,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2128,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1802-24",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1800mm",
    depthMM: 141,
    heightMM: 1902,
    widthMM: 1110,
    volumeL: 77.52,
    nominalDeltaT50KW: 5.184,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2215,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1802-25",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1800mm",
    depthMM: 141,
    heightMM: 1902,
    widthMM: 1155,
    volumeL: 80.75,
    nominalDeltaT50KW: 5.4,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2303,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1802-26",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1800mm",
    depthMM: 141,
    heightMM: 1902,
    widthMM: 1200,
    volumeL: 83.98,
    nominalDeltaT50KW: 5.616,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2390,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1802-27",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1800mm",
    depthMM: 141,
    heightMM: 1902,
    widthMM: 1245,
    volumeL: 87.21,
    nominalDeltaT50KW: 5.832,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2478,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1802-28",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1800mm",
    depthMM: 141,
    heightMM: 1902,
    widthMM: 1290,
    volumeL: 90.44,
    nominalDeltaT50KW: 6.048,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2565,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1802-29",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1800mm",
    depthMM: 141,
    heightMM: 1902,
    widthMM: 1335,
    volumeL: 93.67,
    nominalDeltaT50KW: 6.264,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2653,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1802-30",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1800mm",
    depthMM: 141,
    heightMM: 1902,
    widthMM: 1380,
    volumeL: 96.9,
    nominalDeltaT50KW: 6.48,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2740,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1802-31",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1800mm",
    depthMM: 141,
    heightMM: 1902,
    widthMM: 1425,
    volumeL: 100.13,
    nominalDeltaT50KW: 6.696,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2828,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1802-32",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1800mm",
    depthMM: 141,
    heightMM: 1902,
    widthMM: 1470,
    volumeL: 103.36,
    nominalDeltaT50KW: 6.912,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2915,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1802-33",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1800mm",
    depthMM: 141,
    heightMM: 1902,
    widthMM: 1515,
    volumeL: 106.59,
    nominalDeltaT50KW: 7.128,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 3003,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1802-34",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1800mm",
    depthMM: 141,
    heightMM: 1902,
    widthMM: 1560,
    volumeL: 109.82,
    nominalDeltaT50KW: 7.344,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 3090,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1802-35",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1800mm",
    depthMM: 141,
    heightMM: 1902,
    widthMM: 1605,
    volumeL: 113.05,
    nominalDeltaT50KW: 7.56,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 3178,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1802-36",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1800mm",
    depthMM: 141,
    heightMM: 1902,
    widthMM: 1650,
    volumeL: 116.28,
    nominalDeltaT50KW: 7.776,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 3265,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1802-37",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1800mm",
    depthMM: 141,
    heightMM: 1902,
    widthMM: 1695,
    volumeL: 119.51,
    nominalDeltaT50KW: 7.992,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 3353,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1802-38",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1800mm",
    depthMM: 141,
    heightMM: 1902,
    widthMM: 1740,
    volumeL: 122.74,
    nominalDeltaT50KW: 8.208,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 3440,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1802-39",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1800mm",
    depthMM: 141,
    heightMM: 1902,
    widthMM: 1785,
    volumeL: 125.97,
    nominalDeltaT50KW: 8.424,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 3528,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-1802-40",
    rangeType: "Florence 4",
    model: "Florence 4 Column 1800mm",
    depthMM: 141,
    heightMM: 1902,
    widthMM: 1830,
    volumeL: 129.2,
    nominalDeltaT50KW: 8.64,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 3615,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2002-04",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2000mm",
    depthMM: 141,
    heightMM: 2102,
    widthMM: 210,
    volumeL: 14.2,
    nominalDeltaT50KW: 0.952,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 493,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2002-05",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2000mm",
    depthMM: 141,
    heightMM: 2102,
    widthMM: 255,
    volumeL: 17.75,
    nominalDeltaT50KW: 1.191,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 588,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2002-06",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2000mm",
    depthMM: 141,
    heightMM: 2102,
    widthMM: 300,
    volumeL: 21.3,
    nominalDeltaT50KW: 1.429,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 682,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2002-07",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2000mm",
    depthMM: 141,
    heightMM: 2102,
    widthMM: 345,
    volumeL: 24.85,
    nominalDeltaT50KW: 1.667,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 777,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2002-08",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2000mm",
    depthMM: 141,
    heightMM: 2102,
    widthMM: 390,
    volumeL: 28.4,
    nominalDeltaT50KW: 1.905,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 871,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2002-09",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2000mm",
    depthMM: 141,
    heightMM: 2102,
    widthMM: 435,
    volumeL: 31.95,
    nominalDeltaT50KW: 2.143,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 966,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2002-10",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2000mm",
    depthMM: 141,
    heightMM: 2102,
    widthMM: 480,
    volumeL: 35.5,
    nominalDeltaT50KW: 2.381,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1060,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2002-11",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2000mm",
    depthMM: 141,
    heightMM: 2102,
    widthMM: 525,
    volumeL: 39.05,
    nominalDeltaT50KW: 2.619,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1155,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2002-12",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2000mm",
    depthMM: 141,
    heightMM: 2102,
    widthMM: 570,
    volumeL: 42.6,
    nominalDeltaT50KW: 2.857,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1249,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2002-13",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2000mm",
    depthMM: 141,
    heightMM: 2102,
    widthMM: 615,
    volumeL: 46.15,
    nominalDeltaT50KW: 3.095,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1344,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2002-14",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2000mm",
    depthMM: 141,
    heightMM: 2102,
    widthMM: 660,
    volumeL: 49.7,
    nominalDeltaT50KW: 3.333,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1438,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2002-15",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2000mm",
    depthMM: 141,
    heightMM: 2102,
    widthMM: 705,
    volumeL: 53.25,
    nominalDeltaT50KW: 3.572,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1533,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2002-16",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2000mm",
    depthMM: 141,
    heightMM: 2102,
    widthMM: 750,
    volumeL: 56.8,
    nominalDeltaT50KW: 3.81,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1627,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2002-17",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2000mm",
    depthMM: 141,
    heightMM: 2102,
    widthMM: 795,
    volumeL: 60.35,
    nominalDeltaT50KW: 4.048,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1722,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2002-18",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2000mm",
    depthMM: 141,
    heightMM: 2102,
    widthMM: 840,
    volumeL: 63.9,
    nominalDeltaT50KW: 4.286,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1816,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2002-19",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2000mm",
    depthMM: 141,
    heightMM: 2102,
    widthMM: 885,
    volumeL: 67.45,
    nominalDeltaT50KW: 4.524,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1911,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2002-20",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2000mm",
    depthMM: 141,
    heightMM: 2102,
    widthMM: 930,
    volumeL: 71,
    nominalDeltaT50KW: 4.762,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2005,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2002-21",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2000mm",
    depthMM: 141,
    heightMM: 2102,
    widthMM: 975,
    volumeL: 74.55,
    nominalDeltaT50KW: 5,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2100,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2002-22",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2000mm",
    depthMM: 141,
    heightMM: 2102,
    widthMM: 1020,
    volumeL: 78.1,
    nominalDeltaT50KW: 5.238,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2194,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2002-23",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2000mm",
    depthMM: 141,
    heightMM: 2102,
    widthMM: 1065,
    volumeL: 81.65,
    nominalDeltaT50KW: 5.476,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2289,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2002-24",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2000mm",
    depthMM: 141,
    heightMM: 2102,
    widthMM: 1110,
    volumeL: 85.2,
    nominalDeltaT50KW: 5.714,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2383,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2002-25",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2000mm",
    depthMM: 141,
    heightMM: 2102,
    widthMM: 1155,
    volumeL: 88.75,
    nominalDeltaT50KW: 5.953,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2478,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2002-26",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2000mm",
    depthMM: 141,
    heightMM: 2102,
    widthMM: 1200,
    volumeL: 92.3,
    nominalDeltaT50KW: 6.191,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2572,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2002-27",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2000mm",
    depthMM: 141,
    heightMM: 2102,
    widthMM: 1245,
    volumeL: 95.85,
    nominalDeltaT50KW: 6.429,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2667,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2002-28",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2000mm",
    depthMM: 141,
    heightMM: 2102,
    widthMM: 1290,
    volumeL: 99.4,
    nominalDeltaT50KW: 6.667,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2761,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2002-29",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2000mm",
    depthMM: 141,
    heightMM: 2102,
    widthMM: 1335,
    volumeL: 102.95,
    nominalDeltaT50KW: 6.905,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2856,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2002-30",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2000mm",
    depthMM: 141,
    heightMM: 2102,
    widthMM: 1380,
    volumeL: 106.5,
    nominalDeltaT50KW: 7.143,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2950,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2002-31",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2000mm",
    depthMM: 141,
    heightMM: 2102,
    widthMM: 1425,
    volumeL: 110.05,
    nominalDeltaT50KW: 7.381,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 3045,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2002-32",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2000mm",
    depthMM: 141,
    heightMM: 2102,
    widthMM: 1470,
    volumeL: 113.6,
    nominalDeltaT50KW: 7.619,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 3139,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2002-33",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2000mm",
    depthMM: 141,
    heightMM: 2102,
    widthMM: 1515,
    volumeL: 117.15,
    nominalDeltaT50KW: 7.857,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 3234,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2002-34",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2000mm",
    depthMM: 141,
    heightMM: 2102,
    widthMM: 1560,
    volumeL: 120.7,
    nominalDeltaT50KW: 8.095,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 3328,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2002-35",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2000mm",
    depthMM: 141,
    heightMM: 2102,
    widthMM: 1605,
    volumeL: 124.25,
    nominalDeltaT50KW: 8.334,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 3423,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2002-36",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2000mm",
    depthMM: 141,
    heightMM: 2102,
    widthMM: 1650,
    volumeL: 127.8,
    nominalDeltaT50KW: 8.572,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 3517,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2002-37",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2000mm",
    depthMM: 141,
    heightMM: 2102,
    widthMM: 1695,
    volumeL: 131.35,
    nominalDeltaT50KW: 8.81,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 3612,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2002-38",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2000mm",
    depthMM: 141,
    heightMM: 2102,
    widthMM: 1740,
    volumeL: 134.9,
    nominalDeltaT50KW: 9.048,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 3706,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2002-39",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2000mm",
    depthMM: 141,
    heightMM: 2102,
    widthMM: 1785,
    volumeL: 138.45,
    nominalDeltaT50KW: 9.286,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 3801,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2002-40",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2000mm",
    depthMM: 141,
    heightMM: 2102,
    widthMM: 1830,
    volumeL: 142,
    nominalDeltaT50KW: 9.524,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 3895,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-194-04",
    rangeType: "Florence 4",
    model: "Florence 4 Column 200mm",
    depthMM: 141,
    heightMM: 294,
    widthMM: 210,
    volumeL: 2.4,
    nominalDeltaT50KW: 0.104,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 233,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-194-05",
    rangeType: "Florence 4",
    model: "Florence 4 Column 200mm",
    depthMM: 141,
    heightMM: 294,
    widthMM: 255,
    volumeL: 3,
    nominalDeltaT50KW: 0.13,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 263,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-194-06",
    rangeType: "Florence 4",
    model: "Florence 4 Column 200mm",
    depthMM: 141,
    heightMM: 294,
    widthMM: 300,
    volumeL: 3.6,
    nominalDeltaT50KW: 0.156,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 292,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-194-07",
    rangeType: "Florence 4",
    model: "Florence 4 Column 200mm",
    depthMM: 141,
    heightMM: 294,
    widthMM: 345,
    volumeL: 4.2,
    nominalDeltaT50KW: 0.182,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 322,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-194-08",
    rangeType: "Florence 4",
    model: "Florence 4 Column 200mm",
    depthMM: 141,
    heightMM: 294,
    widthMM: 390,
    volumeL: 4.8,
    nominalDeltaT50KW: 0.208,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 351,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-194-09",
    rangeType: "Florence 4",
    model: "Florence 4 Column 200mm",
    depthMM: 141,
    heightMM: 294,
    widthMM: 435,
    volumeL: 5.4,
    nominalDeltaT50KW: 0.234,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 381,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-194-10",
    rangeType: "Florence 4",
    model: "Florence 4 Column 200mm",
    depthMM: 141,
    heightMM: 294,
    widthMM: 480,
    volumeL: 6,
    nominalDeltaT50KW: 0.26,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 410,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-194-11",
    rangeType: "Florence 4",
    model: "Florence 4 Column 200mm",
    depthMM: 141,
    heightMM: 294,
    widthMM: 525,
    volumeL: 6.6,
    nominalDeltaT50KW: 0.286,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 440,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-194-12",
    rangeType: "Florence 4",
    model: "Florence 4 Column 200mm",
    depthMM: 141,
    heightMM: 294,
    widthMM: 570,
    volumeL: 7.2,
    nominalDeltaT50KW: 0.312,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 469,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-194-13",
    rangeType: "Florence 4",
    model: "Florence 4 Column 200mm",
    depthMM: 141,
    heightMM: 294,
    widthMM: 615,
    volumeL: 7.8,
    nominalDeltaT50KW: 0.338,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 499,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-194-14",
    rangeType: "Florence 4",
    model: "Florence 4 Column 200mm",
    depthMM: 141,
    heightMM: 294,
    widthMM: 660,
    volumeL: 8.4,
    nominalDeltaT50KW: 0.364,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 528,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-194-15",
    rangeType: "Florence 4",
    model: "Florence 4 Column 200mm",
    depthMM: 141,
    heightMM: 294,
    widthMM: 705,
    volumeL: 9,
    nominalDeltaT50KW: 0.39,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 558,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-194-16",
    rangeType: "Florence 4",
    model: "Florence 4 Column 200mm",
    depthMM: 141,
    heightMM: 294,
    widthMM: 750,
    volumeL: 9.6,
    nominalDeltaT50KW: 0.416,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 587,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-194-17",
    rangeType: "Florence 4",
    model: "Florence 4 Column 200mm",
    depthMM: 141,
    heightMM: 294,
    widthMM: 795,
    volumeL: 10.2,
    nominalDeltaT50KW: 0.442,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 617,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-194-18",
    rangeType: "Florence 4",
    model: "Florence 4 Column 200mm",
    depthMM: 141,
    heightMM: 294,
    widthMM: 840,
    volumeL: 10.8,
    nominalDeltaT50KW: 0.468,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 646,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-194-19",
    rangeType: "Florence 4",
    model: "Florence 4 Column 200mm",
    depthMM: 141,
    heightMM: 294,
    widthMM: 885,
    volumeL: 11.4,
    nominalDeltaT50KW: 0.494,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 676,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-194-20",
    rangeType: "Florence 4",
    model: "Florence 4 Column 200mm",
    depthMM: 141,
    heightMM: 294,
    widthMM: 930,
    volumeL: 12,
    nominalDeltaT50KW: 0.52,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 705,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-194-21",
    rangeType: "Florence 4",
    model: "Florence 4 Column 200mm",
    depthMM: 141,
    heightMM: 294,
    widthMM: 975,
    volumeL: 12.6,
    nominalDeltaT50KW: 0.546,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 735,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-194-22",
    rangeType: "Florence 4",
    model: "Florence 4 Column 200mm",
    depthMM: 141,
    heightMM: 294,
    widthMM: 1020,
    volumeL: 13.2,
    nominalDeltaT50KW: 0.572,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 764,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-194-23",
    rangeType: "Florence 4",
    model: "Florence 4 Column 200mm",
    depthMM: 141,
    heightMM: 294,
    widthMM: 1065,
    volumeL: 13.8,
    nominalDeltaT50KW: 0.598,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 794,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-194-24",
    rangeType: "Florence 4",
    model: "Florence 4 Column 200mm",
    depthMM: 141,
    heightMM: 294,
    widthMM: 1110,
    volumeL: 14.4,
    nominalDeltaT50KW: 0.624,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 823,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-194-25",
    rangeType: "Florence 4",
    model: "Florence 4 Column 200mm",
    depthMM: 141,
    heightMM: 294,
    widthMM: 1155,
    volumeL: 15,
    nominalDeltaT50KW: 0.65,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 853,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-194-26",
    rangeType: "Florence 4",
    model: "Florence 4 Column 200mm",
    depthMM: 141,
    heightMM: 294,
    widthMM: 1200,
    volumeL: 15.6,
    nominalDeltaT50KW: 0.676,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 882,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-194-27",
    rangeType: "Florence 4",
    model: "Florence 4 Column 200mm",
    depthMM: 141,
    heightMM: 294,
    widthMM: 1245,
    volumeL: 16.2,
    nominalDeltaT50KW: 0.702,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 912,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-194-28",
    rangeType: "Florence 4",
    model: "Florence 4 Column 200mm",
    depthMM: 141,
    heightMM: 294,
    widthMM: 1290,
    volumeL: 16.8,
    nominalDeltaT50KW: 0.728,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 941,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-194-29",
    rangeType: "Florence 4",
    model: "Florence 4 Column 200mm",
    depthMM: 141,
    heightMM: 294,
    widthMM: 1335,
    volumeL: 17.4,
    nominalDeltaT50KW: 0.754,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 971,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-194-30",
    rangeType: "Florence 4",
    model: "Florence 4 Column 200mm",
    depthMM: 141,
    heightMM: 294,
    widthMM: 1380,
    volumeL: 18,
    nominalDeltaT50KW: 0.78,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1000,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-194-31",
    rangeType: "Florence 4",
    model: "Florence 4 Column 200mm",
    depthMM: 141,
    heightMM: 294,
    widthMM: 1425,
    volumeL: 18.6,
    nominalDeltaT50KW: 0.806,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1030,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-194-32",
    rangeType: "Florence 4",
    model: "Florence 4 Column 200mm",
    depthMM: 141,
    heightMM: 294,
    widthMM: 1470,
    volumeL: 19.2,
    nominalDeltaT50KW: 0.832,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1059,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-194-33",
    rangeType: "Florence 4",
    model: "Florence 4 Column 200mm",
    depthMM: 141,
    heightMM: 294,
    widthMM: 1515,
    volumeL: 19.8,
    nominalDeltaT50KW: 0.858,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1089,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-194-34",
    rangeType: "Florence 4",
    model: "Florence 4 Column 200mm",
    depthMM: 141,
    heightMM: 294,
    widthMM: 1560,
    volumeL: 20.4,
    nominalDeltaT50KW: 0.884,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1118,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-194-35",
    rangeType: "Florence 4",
    model: "Florence 4 Column 200mm",
    depthMM: 141,
    heightMM: 294,
    widthMM: 1605,
    volumeL: 21,
    nominalDeltaT50KW: 0.91,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1148,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-194-36",
    rangeType: "Florence 4",
    model: "Florence 4 Column 200mm",
    depthMM: 141,
    heightMM: 294,
    widthMM: 1650,
    volumeL: 21.6,
    nominalDeltaT50KW: 0.936,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1177,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-194-37",
    rangeType: "Florence 4",
    model: "Florence 4 Column 200mm",
    depthMM: 141,
    heightMM: 294,
    widthMM: 1695,
    volumeL: 22.2,
    nominalDeltaT50KW: 0.962,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1207,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-194-38",
    rangeType: "Florence 4",
    model: "Florence 4 Column 200mm",
    depthMM: 141,
    heightMM: 294,
    widthMM: 1740,
    volumeL: 22.8,
    nominalDeltaT50KW: 0.988,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1236,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-194-39",
    rangeType: "Florence 4",
    model: "Florence 4 Column 200mm",
    depthMM: 141,
    heightMM: 294,
    widthMM: 1785,
    volumeL: 23.4,
    nominalDeltaT50KW: 1.014,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1266,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-194-40",
    rangeType: "Florence 4",
    model: "Florence 4 Column 200mm",
    depthMM: 141,
    heightMM: 294,
    widthMM: 1830,
    volumeL: 24,
    nominalDeltaT50KW: 1.04,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1295,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2202-04",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2200mm",
    depthMM: 141,
    heightMM: 2302,
    widthMM: 210,
    volumeL: 15.52,
    nominalDeltaT50KW: 1.04,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 597,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2202-05",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2200mm",
    depthMM: 141,
    heightMM: 2302,
    widthMM: 255,
    volumeL: 19.4,
    nominalDeltaT50KW: 1.3,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 718,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2202-06",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2200mm",
    depthMM: 141,
    heightMM: 2302,
    widthMM: 300,
    volumeL: 23.28,
    nominalDeltaT50KW: 1.56,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 838,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2202-07",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2200mm",
    depthMM: 141,
    heightMM: 2302,
    widthMM: 345,
    volumeL: 27.16,
    nominalDeltaT50KW: 1.82,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 959,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2202-08",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2200mm",
    depthMM: 141,
    heightMM: 2302,
    widthMM: 390,
    volumeL: 31.04,
    nominalDeltaT50KW: 2.08,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1079,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2202-09",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2200mm",
    depthMM: 141,
    heightMM: 2302,
    widthMM: 435,
    volumeL: 34.92,
    nominalDeltaT50KW: 2.34,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1200,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2202-10",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2200mm",
    depthMM: 141,
    heightMM: 2302,
    widthMM: 480,
    volumeL: 38.8,
    nominalDeltaT50KW: 2.6,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1320,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2202-11",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2200mm",
    depthMM: 141,
    heightMM: 2302,
    widthMM: 525,
    volumeL: 42.68,
    nominalDeltaT50KW: 2.86,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1441,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2202-12",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2200mm",
    depthMM: 141,
    heightMM: 2302,
    widthMM: 570,
    volumeL: 46.56,
    nominalDeltaT50KW: 3.12,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1561,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2202-13",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2200mm",
    depthMM: 141,
    heightMM: 2302,
    widthMM: 615,
    volumeL: 50.44,
    nominalDeltaT50KW: 3.38,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1682,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2202-14",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2200mm",
    depthMM: 141,
    heightMM: 2302,
    widthMM: 660,
    volumeL: 54.32,
    nominalDeltaT50KW: 3.64,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1802,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2202-15",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2200mm",
    depthMM: 141,
    heightMM: 2302,
    widthMM: 705,
    volumeL: 58.2,
    nominalDeltaT50KW: 3.9,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1923,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2202-16",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2200mm",
    depthMM: 141,
    heightMM: 2302,
    widthMM: 750,
    volumeL: 62.08,
    nominalDeltaT50KW: 4.16,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 2043,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2202-17",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2200mm",
    depthMM: 141,
    heightMM: 2302,
    widthMM: 795,
    volumeL: 65.96,
    nominalDeltaT50KW: 4.42,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 2164,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2202-18",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2200mm",
    depthMM: 141,
    heightMM: 2302,
    widthMM: 840,
    volumeL: 69.84,
    nominalDeltaT50KW: 4.68,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 2284,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2202-19",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2200mm",
    depthMM: 141,
    heightMM: 2302,
    widthMM: 885,
    volumeL: 73.72,
    nominalDeltaT50KW: 4.94,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 2405,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2202-20",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2200mm",
    depthMM: 141,
    heightMM: 2302,
    widthMM: 930,
    volumeL: 77.6,
    nominalDeltaT50KW: 5.2,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 2525,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2202-21",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2200mm",
    depthMM: 141,
    heightMM: 2302,
    widthMM: 975,
    volumeL: 81.48,
    nominalDeltaT50KW: 5.46,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 2646,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2202-22",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2200mm",
    depthMM: 141,
    heightMM: 2302,
    widthMM: 1020,
    volumeL: 85.36,
    nominalDeltaT50KW: 5.72,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 2766,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2202-23",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2200mm",
    depthMM: 141,
    heightMM: 2302,
    widthMM: 1065,
    volumeL: 89.24,
    nominalDeltaT50KW: 5.98,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 2887,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2202-24",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2200mm",
    depthMM: 141,
    heightMM: 2302,
    widthMM: 1110,
    volumeL: 93.12,
    nominalDeltaT50KW: 6.24,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 3007,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2202-25",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2200mm",
    depthMM: 141,
    heightMM: 2302,
    widthMM: 1155,
    volumeL: 97,
    nominalDeltaT50KW: 6.5,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 3128,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2202-26",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2200mm",
    depthMM: 141,
    heightMM: 2302,
    widthMM: 1200,
    volumeL: 100.88,
    nominalDeltaT50KW: 6.76,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 3248,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2202-27",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2200mm",
    depthMM: 141,
    heightMM: 2302,
    widthMM: 1245,
    volumeL: 104.76,
    nominalDeltaT50KW: 7.02,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 3369,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2202-28",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2200mm",
    depthMM: 141,
    heightMM: 2302,
    widthMM: 1290,
    volumeL: 108.64,
    nominalDeltaT50KW: 7.28,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 3489,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2202-29",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2200mm",
    depthMM: 141,
    heightMM: 2302,
    widthMM: 1335,
    volumeL: 112.52,
    nominalDeltaT50KW: 7.54,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 3610,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2202-30",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2200mm",
    depthMM: 141,
    heightMM: 2302,
    widthMM: 1380,
    volumeL: 116.4,
    nominalDeltaT50KW: 7.8,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 3730,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2202-31",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2200mm",
    depthMM: 141,
    heightMM: 2302,
    widthMM: 1425,
    volumeL: 120.28,
    nominalDeltaT50KW: 8.06,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 3851,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2202-32",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2200mm",
    depthMM: 141,
    heightMM: 2302,
    widthMM: 1470,
    volumeL: 124.16,
    nominalDeltaT50KW: 8.32,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 3971,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2202-33",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2200mm",
    depthMM: 141,
    heightMM: 2302,
    widthMM: 1515,
    volumeL: 128.04,
    nominalDeltaT50KW: 8.58,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 4092,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2202-34",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2200mm",
    depthMM: 141,
    heightMM: 2302,
    widthMM: 1560,
    volumeL: 131.92,
    nominalDeltaT50KW: 8.84,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 4212,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2202-35",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2200mm",
    depthMM: 141,
    heightMM: 2302,
    widthMM: 1605,
    volumeL: 135.8,
    nominalDeltaT50KW: 9.1,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 4333,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2202-36",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2200mm",
    depthMM: 141,
    heightMM: 2302,
    widthMM: 1650,
    volumeL: 139.68,
    nominalDeltaT50KW: 9.36,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 4453,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2202-37",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2200mm",
    depthMM: 141,
    heightMM: 2302,
    widthMM: 1695,
    volumeL: 143.56,
    nominalDeltaT50KW: 9.62,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 4574,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2202-38",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2200mm",
    depthMM: 141,
    heightMM: 2302,
    widthMM: 1740,
    volumeL: 147.44,
    nominalDeltaT50KW: 9.88,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 4694,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2202-39",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2200mm",
    depthMM: 141,
    heightMM: 2302,
    widthMM: 1785,
    volumeL: 151.32,
    nominalDeltaT50KW: 10.14,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 4815,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2202-40",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2200mm",
    depthMM: 141,
    heightMM: 2302,
    widthMM: 1830,
    volumeL: 155.2,
    nominalDeltaT50KW: 10.4,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 4935,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2502-04",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2500mm",
    depthMM: 141,
    heightMM: 2602,
    widthMM: 210,
    volumeL: 17.48,
    nominalDeltaT50KW: 1.171,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 645,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2502-05",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2500mm",
    depthMM: 141,
    heightMM: 2602,
    widthMM: 255,
    volumeL: 21.85,
    nominalDeltaT50KW: 1.464,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 778,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2502-06",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2500mm",
    depthMM: 141,
    heightMM: 2602,
    widthMM: 300,
    volumeL: 26.22,
    nominalDeltaT50KW: 1.757,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 910,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2502-07",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2500mm",
    depthMM: 141,
    heightMM: 2602,
    widthMM: 345,
    volumeL: 30.59,
    nominalDeltaT50KW: 2.05,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1043,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2502-08",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2500mm",
    depthMM: 141,
    heightMM: 2602,
    widthMM: 390,
    volumeL: 34.96,
    nominalDeltaT50KW: 2.342,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1175,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2502-09",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2500mm",
    depthMM: 141,
    heightMM: 2602,
    widthMM: 435,
    volumeL: 39.33,
    nominalDeltaT50KW: 2.635,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1308,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2502-10",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2500mm",
    depthMM: 141,
    heightMM: 2602,
    widthMM: 480,
    volumeL: 43.7,
    nominalDeltaT50KW: 2.928,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1440,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2502-11",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2500mm",
    depthMM: 141,
    heightMM: 2602,
    widthMM: 525,
    volumeL: 48.07,
    nominalDeltaT50KW: 3.221,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1573,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2502-12",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2500mm",
    depthMM: 141,
    heightMM: 2602,
    widthMM: 570,
    volumeL: 52.44,
    nominalDeltaT50KW: 3.514,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1705,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2502-13",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2500mm",
    depthMM: 141,
    heightMM: 2602,
    widthMM: 615,
    volumeL: 56.81,
    nominalDeltaT50KW: 3.806,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1838,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2502-14",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2500mm",
    depthMM: 141,
    heightMM: 2602,
    widthMM: 660,
    volumeL: 61.18,
    nominalDeltaT50KW: 4.099,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1970,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2502-15",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2500mm",
    depthMM: 141,
    heightMM: 2602,
    widthMM: 705,
    volumeL: 65.55,
    nominalDeltaT50KW: 4.392,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 2103,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2502-16",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2500mm",
    depthMM: 141,
    heightMM: 2602,
    widthMM: 750,
    volumeL: 69.92,
    nominalDeltaT50KW: 4.685,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 2235,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2502-17",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2500mm",
    depthMM: 141,
    heightMM: 2602,
    widthMM: 795,
    volumeL: 74.29,
    nominalDeltaT50KW: 4.978,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 2368,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2502-18",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2500mm",
    depthMM: 141,
    heightMM: 2602,
    widthMM: 840,
    volumeL: 78.66,
    nominalDeltaT50KW: 5.27,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 2500,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2502-19",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2500mm",
    depthMM: 141,
    heightMM: 2602,
    widthMM: 885,
    volumeL: 83.03,
    nominalDeltaT50KW: 5.563,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 2633,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2502-20",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2500mm",
    depthMM: 141,
    heightMM: 2602,
    widthMM: 930,
    volumeL: 87.4,
    nominalDeltaT50KW: 5.856,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 2765,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2502-21",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2500mm",
    depthMM: 141,
    heightMM: 2602,
    widthMM: 975,
    volumeL: 91.77,
    nominalDeltaT50KW: 6.149,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 2898,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2502-22",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2500mm",
    depthMM: 141,
    heightMM: 2602,
    widthMM: 1020,
    volumeL: 96.14,
    nominalDeltaT50KW: 6.442,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 3030,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2502-23",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2500mm",
    depthMM: 141,
    heightMM: 2602,
    widthMM: 1065,
    volumeL: 100.51,
    nominalDeltaT50KW: 6.734,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 3163,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2502-24",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2500mm",
    depthMM: 141,
    heightMM: 2602,
    widthMM: 1110,
    volumeL: 104.88,
    nominalDeltaT50KW: 7.027,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 3295,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2502-25",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2500mm",
    depthMM: 141,
    heightMM: 2602,
    widthMM: 1155,
    volumeL: 109.25,
    nominalDeltaT50KW: 7.32,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 3428,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2502-26",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2500mm",
    depthMM: 141,
    heightMM: 2602,
    widthMM: 1200,
    volumeL: 113.62,
    nominalDeltaT50KW: 7.613,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 3560,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2502-27",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2500mm",
    depthMM: 141,
    heightMM: 2602,
    widthMM: 1245,
    volumeL: 117.99,
    nominalDeltaT50KW: 7.906,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 3693,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2502-28",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2500mm",
    depthMM: 141,
    heightMM: 2602,
    widthMM: 1290,
    volumeL: 122.36,
    nominalDeltaT50KW: 8.198,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 3825,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2502-29",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2500mm",
    depthMM: 141,
    heightMM: 2602,
    widthMM: 1335,
    volumeL: 126.73,
    nominalDeltaT50KW: 8.491,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 3958,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2502-30",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2500mm",
    depthMM: 141,
    heightMM: 2602,
    widthMM: 1380,
    volumeL: 131.1,
    nominalDeltaT50KW: 8.784,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 4090,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2502-31",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2500mm",
    depthMM: 141,
    heightMM: 2602,
    widthMM: 1425,
    volumeL: 135.47,
    nominalDeltaT50KW: 9.077,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 4223,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2502-32",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2500mm",
    depthMM: 141,
    heightMM: 2602,
    widthMM: 1470,
    volumeL: 139.84,
    nominalDeltaT50KW: 9.37,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 4355,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2502-33",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2500mm",
    depthMM: 141,
    heightMM: 2602,
    widthMM: 1515,
    volumeL: 144.21,
    nominalDeltaT50KW: 9.662,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 4488,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2502-34",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2500mm",
    depthMM: 141,
    heightMM: 2602,
    widthMM: 1560,
    volumeL: 148.58,
    nominalDeltaT50KW: 9.955,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 4620,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2502-35",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2500mm",
    depthMM: 141,
    heightMM: 2602,
    widthMM: 1605,
    volumeL: 152.95,
    nominalDeltaT50KW: 10.248,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 4753,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2502-36",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2500mm",
    depthMM: 141,
    heightMM: 2602,
    widthMM: 1650,
    volumeL: 157.32,
    nominalDeltaT50KW: 10.541,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 4885,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2502-37",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2500mm",
    depthMM: 141,
    heightMM: 2602,
    widthMM: 1695,
    volumeL: 161.69,
    nominalDeltaT50KW: 10.834,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 5018,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2502-38",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2500mm",
    depthMM: 141,
    heightMM: 2602,
    widthMM: 1740,
    volumeL: 166.06,
    nominalDeltaT50KW: 11.126,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 5150,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2502-39",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2500mm",
    depthMM: 141,
    heightMM: 2602,
    widthMM: 1785,
    volumeL: 170.43,
    nominalDeltaT50KW: 11.419,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 5283,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-2502-40",
    rangeType: "Florence 4",
    model: "Florence 4 Column 2500mm",
    depthMM: 141,
    heightMM: 2602,
    widthMM: 1830,
    volumeL: 174.8,
    nominalDeltaT50KW: 11.712,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 5415,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-302-04",
    rangeType: "Florence 4",
    model: "Florence 4 Column 300mm",
    depthMM: 141,
    heightMM: 402,
    widthMM: 210,
    volumeL: 3.12,
    nominalDeltaT50KW: 0.168,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 241,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-302-05",
    rangeType: "Florence 4",
    model: "Florence 4 Column 300mm",
    depthMM: 141,
    heightMM: 402,
    widthMM: 255,
    volumeL: 3.9,
    nominalDeltaT50KW: 0.211,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 273,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-302-06",
    rangeType: "Florence 4",
    model: "Florence 4 Column 300mm",
    depthMM: 141,
    heightMM: 402,
    widthMM: 300,
    volumeL: 4.68,
    nominalDeltaT50KW: 0.253,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 304,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-302-07",
    rangeType: "Florence 4",
    model: "Florence 4 Column 300mm",
    depthMM: 141,
    heightMM: 402,
    widthMM: 345,
    volumeL: 5.46,
    nominalDeltaT50KW: 0.295,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 336,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-302-08",
    rangeType: "Florence 4",
    model: "Florence 4 Column 300mm",
    depthMM: 141,
    heightMM: 402,
    widthMM: 390,
    volumeL: 6.24,
    nominalDeltaT50KW: 0.337,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 367,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-302-09",
    rangeType: "Florence 4",
    model: "Florence 4 Column 300mm",
    depthMM: 141,
    heightMM: 402,
    widthMM: 435,
    volumeL: 7.02,
    nominalDeltaT50KW: 0.379,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 399,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-302-10",
    rangeType: "Florence 4",
    model: "Florence 4 Column 300mm",
    depthMM: 141,
    heightMM: 402,
    widthMM: 480,
    volumeL: 7.8,
    nominalDeltaT50KW: 0.421,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 430,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-302-11",
    rangeType: "Florence 4",
    model: "Florence 4 Column 300mm",
    depthMM: 141,
    heightMM: 402,
    widthMM: 525,
    volumeL: 8.58,
    nominalDeltaT50KW: 0.463,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 462,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-302-12",
    rangeType: "Florence 4",
    model: "Florence 4 Column 300mm",
    depthMM: 141,
    heightMM: 402,
    widthMM: 570,
    volumeL: 9.36,
    nominalDeltaT50KW: 0.505,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 493,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-302-13",
    rangeType: "Florence 4",
    model: "Florence 4 Column 300mm",
    depthMM: 141,
    heightMM: 402,
    widthMM: 615,
    volumeL: 10.14,
    nominalDeltaT50KW: 0.547,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 525,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-302-14",
    rangeType: "Florence 4",
    model: "Florence 4 Column 300mm",
    depthMM: 141,
    heightMM: 402,
    widthMM: 660,
    volumeL: 10.92,
    nominalDeltaT50KW: 0.589,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 556,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-302-15",
    rangeType: "Florence 4",
    model: "Florence 4 Column 300mm",
    depthMM: 141,
    heightMM: 402,
    widthMM: 705,
    volumeL: 11.7,
    nominalDeltaT50KW: 0.632,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 588,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-302-16",
    rangeType: "Florence 4",
    model: "Florence 4 Column 300mm",
    depthMM: 141,
    heightMM: 402,
    widthMM: 750,
    volumeL: 12.48,
    nominalDeltaT50KW: 0.674,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 619,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-302-17",
    rangeType: "Florence 4",
    model: "Florence 4 Column 300mm",
    depthMM: 141,
    heightMM: 402,
    widthMM: 795,
    volumeL: 13.26,
    nominalDeltaT50KW: 0.716,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 651,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-302-18",
    rangeType: "Florence 4",
    model: "Florence 4 Column 300mm",
    depthMM: 141,
    heightMM: 402,
    widthMM: 840,
    volumeL: 14.04,
    nominalDeltaT50KW: 0.758,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 682,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-302-19",
    rangeType: "Florence 4",
    model: "Florence 4 Column 300mm",
    depthMM: 141,
    heightMM: 402,
    widthMM: 885,
    volumeL: 14.82,
    nominalDeltaT50KW: 0.8,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 714,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-302-20",
    rangeType: "Florence 4",
    model: "Florence 4 Column 300mm",
    depthMM: 141,
    heightMM: 402,
    widthMM: 930,
    volumeL: 15.6,
    nominalDeltaT50KW: 0.842,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 745,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-302-21",
    rangeType: "Florence 4",
    model: "Florence 4 Column 300mm",
    depthMM: 141,
    heightMM: 402,
    widthMM: 975,
    volumeL: 16.38,
    nominalDeltaT50KW: 0.884,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 777,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-302-22",
    rangeType: "Florence 4",
    model: "Florence 4 Column 300mm",
    depthMM: 141,
    heightMM: 402,
    widthMM: 1020,
    volumeL: 17.16,
    nominalDeltaT50KW: 0.926,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 808,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-302-23",
    rangeType: "Florence 4",
    model: "Florence 4 Column 300mm",
    depthMM: 141,
    heightMM: 402,
    widthMM: 1065,
    volumeL: 17.94,
    nominalDeltaT50KW: 0.968,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 840,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-302-24",
    rangeType: "Florence 4",
    model: "Florence 4 Column 300mm",
    depthMM: 141,
    heightMM: 402,
    widthMM: 1110,
    volumeL: 18.72,
    nominalDeltaT50KW: 1.01,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 871,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-302-25",
    rangeType: "Florence 4",
    model: "Florence 4 Column 300mm",
    depthMM: 141,
    heightMM: 402,
    widthMM: 1155,
    volumeL: 19.5,
    nominalDeltaT50KW: 1.053,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 903,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-302-26",
    rangeType: "Florence 4",
    model: "Florence 4 Column 300mm",
    depthMM: 141,
    heightMM: 402,
    widthMM: 1200,
    volumeL: 20.28,
    nominalDeltaT50KW: 1.095,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 934,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-302-27",
    rangeType: "Florence 4",
    model: "Florence 4 Column 300mm",
    depthMM: 141,
    heightMM: 402,
    widthMM: 1245,
    volumeL: 21.06,
    nominalDeltaT50KW: 1.137,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 966,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-302-28",
    rangeType: "Florence 4",
    model: "Florence 4 Column 300mm",
    depthMM: 141,
    heightMM: 402,
    widthMM: 1290,
    volumeL: 21.84,
    nominalDeltaT50KW: 1.179,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 997,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-302-29",
    rangeType: "Florence 4",
    model: "Florence 4 Column 300mm",
    depthMM: 141,
    heightMM: 402,
    widthMM: 1335,
    volumeL: 22.62,
    nominalDeltaT50KW: 1.221,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1029,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-302-30",
    rangeType: "Florence 4",
    model: "Florence 4 Column 300mm",
    depthMM: 141,
    heightMM: 402,
    widthMM: 1380,
    volumeL: 23.4,
    nominalDeltaT50KW: 1.263,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1060,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-302-31",
    rangeType: "Florence 4",
    model: "Florence 4 Column 300mm",
    depthMM: 141,
    heightMM: 402,
    widthMM: 1425,
    volumeL: 24.18,
    nominalDeltaT50KW: 1.305,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1092,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-302-32",
    rangeType: "Florence 4",
    model: "Florence 4 Column 300mm",
    depthMM: 141,
    heightMM: 402,
    widthMM: 1470,
    volumeL: 24.96,
    nominalDeltaT50KW: 1.347,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1123,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-302-33",
    rangeType: "Florence 4",
    model: "Florence 4 Column 300mm",
    depthMM: 141,
    heightMM: 402,
    widthMM: 1515,
    volumeL: 25.74,
    nominalDeltaT50KW: 1.389,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1155,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-302-34",
    rangeType: "Florence 4",
    model: "Florence 4 Column 300mm",
    depthMM: 141,
    heightMM: 402,
    widthMM: 1560,
    volumeL: 26.52,
    nominalDeltaT50KW: 1.431,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1186,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-302-35",
    rangeType: "Florence 4",
    model: "Florence 4 Column 300mm",
    depthMM: 141,
    heightMM: 402,
    widthMM: 1605,
    volumeL: 27.3,
    nominalDeltaT50KW: 1.474,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1218,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-302-36",
    rangeType: "Florence 4",
    model: "Florence 4 Column 300mm",
    depthMM: 141,
    heightMM: 402,
    widthMM: 1650,
    volumeL: 28.08,
    nominalDeltaT50KW: 1.516,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1249,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-302-37",
    rangeType: "Florence 4",
    model: "Florence 4 Column 300mm",
    depthMM: 141,
    heightMM: 402,
    widthMM: 1695,
    volumeL: 28.86,
    nominalDeltaT50KW: 1.558,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1281,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-302-38",
    rangeType: "Florence 4",
    model: "Florence 4 Column 300mm",
    depthMM: 141,
    heightMM: 402,
    widthMM: 1740,
    volumeL: 29.64,
    nominalDeltaT50KW: 1.6,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1312,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-302-39",
    rangeType: "Florence 4",
    model: "Florence 4 Column 300mm",
    depthMM: 141,
    heightMM: 402,
    widthMM: 1785,
    volumeL: 30.42,
    nominalDeltaT50KW: 1.642,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1344,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-302-40",
    rangeType: "Florence 4",
    model: "Florence 4 Column 300mm",
    depthMM: 141,
    heightMM: 402,
    widthMM: 1830,
    volumeL: 31.2,
    nominalDeltaT50KW: 1.684,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1375,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-402-04",
    rangeType: "Florence 4",
    model: "Florence 4 Column 400mm",
    depthMM: 141,
    heightMM: 502,
    widthMM: 210,
    volumeL: 3.8,
    nominalDeltaT50KW: 0.218,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 245,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-402-05",
    rangeType: "Florence 4",
    model: "Florence 4 Column 400mm",
    depthMM: 141,
    heightMM: 502,
    widthMM: 255,
    volumeL: 4.75,
    nominalDeltaT50KW: 0.273,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 278,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-402-06",
    rangeType: "Florence 4",
    model: "Florence 4 Column 400mm",
    depthMM: 141,
    heightMM: 502,
    widthMM: 300,
    volumeL: 5.7,
    nominalDeltaT50KW: 0.328,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 310,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-402-07",
    rangeType: "Florence 4",
    model: "Florence 4 Column 400mm",
    depthMM: 141,
    heightMM: 502,
    widthMM: 345,
    volumeL: 6.65,
    nominalDeltaT50KW: 0.382,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 343,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-402-08",
    rangeType: "Florence 4",
    model: "Florence 4 Column 400mm",
    depthMM: 141,
    heightMM: 502,
    widthMM: 390,
    volumeL: 7.6,
    nominalDeltaT50KW: 0.437,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 375,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-402-09",
    rangeType: "Florence 4",
    model: "Florence 4 Column 400mm",
    depthMM: 141,
    heightMM: 502,
    widthMM: 435,
    volumeL: 8.55,
    nominalDeltaT50KW: 0.491,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 408,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-402-10",
    rangeType: "Florence 4",
    model: "Florence 4 Column 400mm",
    depthMM: 141,
    heightMM: 502,
    widthMM: 480,
    volumeL: 9.5,
    nominalDeltaT50KW: 0.546,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 440,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-402-11",
    rangeType: "Florence 4",
    model: "Florence 4 Column 400mm",
    depthMM: 141,
    heightMM: 502,
    widthMM: 525,
    volumeL: 10.45,
    nominalDeltaT50KW: 0.601,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 473,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-402-12",
    rangeType: "Florence 4",
    model: "Florence 4 Column 400mm",
    depthMM: 141,
    heightMM: 502,
    widthMM: 570,
    volumeL: 11.4,
    nominalDeltaT50KW: 0.655,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 505,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-402-13",
    rangeType: "Florence 4",
    model: "Florence 4 Column 400mm",
    depthMM: 141,
    heightMM: 502,
    widthMM: 615,
    volumeL: 12.35,
    nominalDeltaT50KW: 0.71,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 538,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-402-14",
    rangeType: "Florence 4",
    model: "Florence 4 Column 400mm",
    depthMM: 141,
    heightMM: 502,
    widthMM: 660,
    volumeL: 13.3,
    nominalDeltaT50KW: 0.764,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 570,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-402-15",
    rangeType: "Florence 4",
    model: "Florence 4 Column 400mm",
    depthMM: 141,
    heightMM: 502,
    widthMM: 705,
    volumeL: 14.25,
    nominalDeltaT50KW: 0.819,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 603,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-402-16",
    rangeType: "Florence 4",
    model: "Florence 4 Column 400mm",
    depthMM: 141,
    heightMM: 502,
    widthMM: 750,
    volumeL: 15.2,
    nominalDeltaT50KW: 0.874,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 635,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-402-17",
    rangeType: "Florence 4",
    model: "Florence 4 Column 400mm",
    depthMM: 141,
    heightMM: 502,
    widthMM: 795,
    volumeL: 16.15,
    nominalDeltaT50KW: 0.928,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 668,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-402-18",
    rangeType: "Florence 4",
    model: "Florence 4 Column 400mm",
    depthMM: 141,
    heightMM: 502,
    widthMM: 840,
    volumeL: 17.1,
    nominalDeltaT50KW: 0.983,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 700,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-402-19",
    rangeType: "Florence 4",
    model: "Florence 4 Column 400mm",
    depthMM: 141,
    heightMM: 502,
    widthMM: 885,
    volumeL: 18.05,
    nominalDeltaT50KW: 1.037,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 733,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-402-20",
    rangeType: "Florence 4",
    model: "Florence 4 Column 400mm",
    depthMM: 141,
    heightMM: 502,
    widthMM: 930,
    volumeL: 19,
    nominalDeltaT50KW: 1.092,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 765,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-402-21",
    rangeType: "Florence 4",
    model: "Florence 4 Column 400mm",
    depthMM: 141,
    heightMM: 502,
    widthMM: 975,
    volumeL: 19.95,
    nominalDeltaT50KW: 1.147,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 798,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-402-22",
    rangeType: "Florence 4",
    model: "Florence 4 Column 400mm",
    depthMM: 141,
    heightMM: 502,
    widthMM: 1020,
    volumeL: 20.9,
    nominalDeltaT50KW: 1.201,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 830,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-402-23",
    rangeType: "Florence 4",
    model: "Florence 4 Column 400mm",
    depthMM: 141,
    heightMM: 502,
    widthMM: 1065,
    volumeL: 21.85,
    nominalDeltaT50KW: 1.256,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 863,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-402-24",
    rangeType: "Florence 4",
    model: "Florence 4 Column 400mm",
    depthMM: 141,
    heightMM: 502,
    widthMM: 1110,
    volumeL: 22.8,
    nominalDeltaT50KW: 1.31,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 895,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-402-25",
    rangeType: "Florence 4",
    model: "Florence 4 Column 400mm",
    depthMM: 141,
    heightMM: 502,
    widthMM: 1155,
    volumeL: 23.75,
    nominalDeltaT50KW: 1.365,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 928,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-402-26",
    rangeType: "Florence 4",
    model: "Florence 4 Column 400mm",
    depthMM: 141,
    heightMM: 502,
    widthMM: 1200,
    volumeL: 24.7,
    nominalDeltaT50KW: 1.42,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 960,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-402-27",
    rangeType: "Florence 4",
    model: "Florence 4 Column 400mm",
    depthMM: 141,
    heightMM: 502,
    widthMM: 1245,
    volumeL: 25.65,
    nominalDeltaT50KW: 1.474,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 993,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-402-28",
    rangeType: "Florence 4",
    model: "Florence 4 Column 400mm",
    depthMM: 141,
    heightMM: 502,
    widthMM: 1290,
    volumeL: 26.6,
    nominalDeltaT50KW: 1.529,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1025,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-402-29",
    rangeType: "Florence 4",
    model: "Florence 4 Column 400mm",
    depthMM: 141,
    heightMM: 502,
    widthMM: 1335,
    volumeL: 27.55,
    nominalDeltaT50KW: 1.583,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1058,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-402-30",
    rangeType: "Florence 4",
    model: "Florence 4 Column 400mm",
    depthMM: 141,
    heightMM: 502,
    widthMM: 1380,
    volumeL: 28.5,
    nominalDeltaT50KW: 1.638,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1090,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-402-31",
    rangeType: "Florence 4",
    model: "Florence 4 Column 400mm",
    depthMM: 141,
    heightMM: 502,
    widthMM: 1425,
    volumeL: 29.45,
    nominalDeltaT50KW: 1.693,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1123,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-402-32",
    rangeType: "Florence 4",
    model: "Florence 4 Column 400mm",
    depthMM: 141,
    heightMM: 502,
    widthMM: 1470,
    volumeL: 30.4,
    nominalDeltaT50KW: 1.747,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1155,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-402-33",
    rangeType: "Florence 4",
    model: "Florence 4 Column 400mm",
    depthMM: 141,
    heightMM: 502,
    widthMM: 1515,
    volumeL: 31.35,
    nominalDeltaT50KW: 1.802,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1188,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-402-34",
    rangeType: "Florence 4",
    model: "Florence 4 Column 400mm",
    depthMM: 141,
    heightMM: 502,
    widthMM: 1560,
    volumeL: 32.3,
    nominalDeltaT50KW: 1.856,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1220,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-402-35",
    rangeType: "Florence 4",
    model: "Florence 4 Column 400mm",
    depthMM: 141,
    heightMM: 502,
    widthMM: 1605,
    volumeL: 33.25,
    nominalDeltaT50KW: 1.911,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1253,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-402-36",
    rangeType: "Florence 4",
    model: "Florence 4 Column 400mm",
    depthMM: 141,
    heightMM: 502,
    widthMM: 1650,
    volumeL: 34.2,
    nominalDeltaT50KW: 1.966,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1285,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-402-37",
    rangeType: "Florence 4",
    model: "Florence 4 Column 400mm",
    depthMM: 141,
    heightMM: 502,
    widthMM: 1695,
    volumeL: 35.15,
    nominalDeltaT50KW: 2.02,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1318,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-402-38",
    rangeType: "Florence 4",
    model: "Florence 4 Column 400mm",
    depthMM: 141,
    heightMM: 502,
    widthMM: 1740,
    volumeL: 36.1,
    nominalDeltaT50KW: 2.075,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1350,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-402-39",
    rangeType: "Florence 4",
    model: "Florence 4 Column 400mm",
    depthMM: 141,
    heightMM: 502,
    widthMM: 1785,
    volumeL: 37.05,
    nominalDeltaT50KW: 2.129,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1383,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-402-40",
    rangeType: "Florence 4",
    model: "Florence 4 Column 400mm",
    depthMM: 141,
    heightMM: 502,
    widthMM: 1830,
    volumeL: 38,
    nominalDeltaT50KW: 2.184,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1415,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-502-04",
    rangeType: "Florence 4",
    model: "Florence 4 Column 500mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 210,
    volumeL: 4.44,
    nominalDeltaT50KW: 0.268,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 249,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-502-05",
    rangeType: "Florence 4",
    model: "Florence 4 Column 500mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 255,
    volumeL: 5.55,
    nominalDeltaT50KW: 0.335,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 283,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-502-06",
    rangeType: "Florence 4",
    model: "Florence 4 Column 500mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 300,
    volumeL: 6.66,
    nominalDeltaT50KW: 0.401,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 316,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-502-07",
    rangeType: "Florence 4",
    model: "Florence 4 Column 500mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 345,
    volumeL: 7.77,
    nominalDeltaT50KW: 0.468,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 350,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-502-08",
    rangeType: "Florence 4",
    model: "Florence 4 Column 500mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 390,
    volumeL: 8.88,
    nominalDeltaT50KW: 0.535,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 383,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-502-09",
    rangeType: "Florence 4",
    model: "Florence 4 Column 500mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 435,
    volumeL: 9.99,
    nominalDeltaT50KW: 0.602,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 417,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-502-10",
    rangeType: "Florence 4",
    model: "Florence 4 Column 500mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 480,
    volumeL: 11.1,
    nominalDeltaT50KW: 0.669,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 450,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-502-11",
    rangeType: "Florence 4",
    model: "Florence 4 Column 500mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 525,
    volumeL: 12.21,
    nominalDeltaT50KW: 0.736,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 484,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-502-12",
    rangeType: "Florence 4",
    model: "Florence 4 Column 500mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 570,
    volumeL: 13.32,
    nominalDeltaT50KW: 0.803,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 517,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-502-13",
    rangeType: "Florence 4",
    model: "Florence 4 Column 500mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 615,
    volumeL: 14.43,
    nominalDeltaT50KW: 0.87,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 551,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-502-14",
    rangeType: "Florence 4",
    model: "Florence 4 Column 500mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 660,
    volumeL: 15.54,
    nominalDeltaT50KW: 0.937,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 584,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-502-15",
    rangeType: "Florence 4",
    model: "Florence 4 Column 500mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 705,
    volumeL: 16.65,
    nominalDeltaT50KW: 1.004,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 618,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-502-16",
    rangeType: "Florence 4",
    model: "Florence 4 Column 500mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 750,
    volumeL: 17.76,
    nominalDeltaT50KW: 1.07,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 651,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-502-17",
    rangeType: "Florence 4",
    model: "Florence 4 Column 500mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 795,
    volumeL: 18.87,
    nominalDeltaT50KW: 1.137,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 685,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-502-18",
    rangeType: "Florence 4",
    model: "Florence 4 Column 500mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 840,
    volumeL: 19.98,
    nominalDeltaT50KW: 1.204,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 718,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-502-19",
    rangeType: "Florence 4",
    model: "Florence 4 Column 500mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 885,
    volumeL: 21.09,
    nominalDeltaT50KW: 1.271,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 752,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-502-20",
    rangeType: "Florence 4",
    model: "Florence 4 Column 500mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 930,
    volumeL: 22.2,
    nominalDeltaT50KW: 1.338,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 785,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-502-21",
    rangeType: "Florence 4",
    model: "Florence 4 Column 500mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 975,
    volumeL: 23.31,
    nominalDeltaT50KW: 1.405,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 819,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-502-22",
    rangeType: "Florence 4",
    model: "Florence 4 Column 500mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 1020,
    volumeL: 24.42,
    nominalDeltaT50KW: 1.472,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 852,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-502-23",
    rangeType: "Florence 4",
    model: "Florence 4 Column 500mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 1065,
    volumeL: 25.53,
    nominalDeltaT50KW: 1.539,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 886,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-502-24",
    rangeType: "Florence 4",
    model: "Florence 4 Column 500mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 1110,
    volumeL: 26.64,
    nominalDeltaT50KW: 1.606,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 919,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-502-25",
    rangeType: "Florence 4",
    model: "Florence 4 Column 500mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 1155,
    volumeL: 27.75,
    nominalDeltaT50KW: 1.673,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 953,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-502-26",
    rangeType: "Florence 4",
    model: "Florence 4 Column 500mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 1200,
    volumeL: 28.86,
    nominalDeltaT50KW: 1.739,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 986,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-502-27",
    rangeType: "Florence 4",
    model: "Florence 4 Column 500mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 1245,
    volumeL: 29.97,
    nominalDeltaT50KW: 1.806,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1020,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-502-28",
    rangeType: "Florence 4",
    model: "Florence 4 Column 500mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 1290,
    volumeL: 31.08,
    nominalDeltaT50KW: 1.873,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1053,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-502-29",
    rangeType: "Florence 4",
    model: "Florence 4 Column 500mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 1335,
    volumeL: 32.19,
    nominalDeltaT50KW: 1.94,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1087,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-502-30",
    rangeType: "Florence 4",
    model: "Florence 4 Column 500mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 1380,
    volumeL: 33.3,
    nominalDeltaT50KW: 2.007,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1120,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-502-31",
    rangeType: "Florence 4",
    model: "Florence 4 Column 500mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 1425,
    volumeL: 34.41,
    nominalDeltaT50KW: 2.074,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1154,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-502-32",
    rangeType: "Florence 4",
    model: "Florence 4 Column 500mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 1470,
    volumeL: 35.52,
    nominalDeltaT50KW: 2.141,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1187,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-502-33",
    rangeType: "Florence 4",
    model: "Florence 4 Column 500mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 1515,
    volumeL: 36.63,
    nominalDeltaT50KW: 2.208,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1221,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-502-34",
    rangeType: "Florence 4",
    model: "Florence 4 Column 500mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 1560,
    volumeL: 37.74,
    nominalDeltaT50KW: 2.275,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1254,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-502-35",
    rangeType: "Florence 4",
    model: "Florence 4 Column 500mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 1605,
    volumeL: 38.85,
    nominalDeltaT50KW: 2.342,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1288,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-502-36",
    rangeType: "Florence 4",
    model: "Florence 4 Column 500mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 1650,
    volumeL: 39.96,
    nominalDeltaT50KW: 2.408,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1321,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-502-37",
    rangeType: "Florence 4",
    model: "Florence 4 Column 500mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 1695,
    volumeL: 41.07,
    nominalDeltaT50KW: 2.475,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1355,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-502-38",
    rangeType: "Florence 4",
    model: "Florence 4 Column 500mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 1740,
    volumeL: 42.18,
    nominalDeltaT50KW: 2.542,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1388,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-502-39",
    rangeType: "Florence 4",
    model: "Florence 4 Column 500mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 1785,
    volumeL: 43.29,
    nominalDeltaT50KW: 2.609,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1422,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-502-40",
    rangeType: "Florence 4",
    model: "Florence 4 Column 500mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 1830,
    volumeL: 44.4,
    nominalDeltaT50KW: 2.676,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1455,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-602-04",
    rangeType: "Florence 4",
    model: "Florence 4 Column 600mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 210,
    volumeL: 5.12,
    nominalDeltaT50KW: 0.316,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 253,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-602-05",
    rangeType: "Florence 4",
    model: "Florence 4 Column 600mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 255,
    volumeL: 6.4,
    nominalDeltaT50KW: 0.395,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 288,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-602-06",
    rangeType: "Florence 4",
    model: "Florence 4 Column 600mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 300,
    volumeL: 7.68,
    nominalDeltaT50KW: 0.474,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 322,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-602-07",
    rangeType: "Florence 4",
    model: "Florence 4 Column 600mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 345,
    volumeL: 8.96,
    nominalDeltaT50KW: 0.553,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 357,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-602-08",
    rangeType: "Florence 4",
    model: "Florence 4 Column 600mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 390,
    volumeL: 10.24,
    nominalDeltaT50KW: 0.632,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 391,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-602-09",
    rangeType: "Florence 4",
    model: "Florence 4 Column 600mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 435,
    volumeL: 11.52,
    nominalDeltaT50KW: 0.711,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 426,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-602-10",
    rangeType: "Florence 4",
    model: "Florence 4 Column 600mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 480,
    volumeL: 12.8,
    nominalDeltaT50KW: 0.79,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 460,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-602-11",
    rangeType: "Florence 4",
    model: "Florence 4 Column 600mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 525,
    volumeL: 14.08,
    nominalDeltaT50KW: 0.869,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 495,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-602-12",
    rangeType: "Florence 4",
    model: "Florence 4 Column 600mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 570,
    volumeL: 15.36,
    nominalDeltaT50KW: 0.948,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 529,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-602-13",
    rangeType: "Florence 4",
    model: "Florence 4 Column 600mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 615,
    volumeL: 16.64,
    nominalDeltaT50KW: 1.027,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 564,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-602-14",
    rangeType: "Florence 4",
    model: "Florence 4 Column 600mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 660,
    volumeL: 17.92,
    nominalDeltaT50KW: 1.106,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 598,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-602-15",
    rangeType: "Florence 4",
    model: "Florence 4 Column 600mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 705,
    volumeL: 19.2,
    nominalDeltaT50KW: 1.185,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 633,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-602-16",
    rangeType: "Florence 4",
    model: "Florence 4 Column 600mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 750,
    volumeL: 20.48,
    nominalDeltaT50KW: 1.264,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 667,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-602-17",
    rangeType: "Florence 4",
    model: "Florence 4 Column 600mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 795,
    volumeL: 21.76,
    nominalDeltaT50KW: 1.343,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 702,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-602-18",
    rangeType: "Florence 4",
    model: "Florence 4 Column 600mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 840,
    volumeL: 23.04,
    nominalDeltaT50KW: 1.422,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 736,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-602-19",
    rangeType: "Florence 4",
    model: "Florence 4 Column 600mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 885,
    volumeL: 24.32,
    nominalDeltaT50KW: 1.501,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 771,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-602-20",
    rangeType: "Florence 4",
    model: "Florence 4 Column 600mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 930,
    volumeL: 25.6,
    nominalDeltaT50KW: 1.58,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 805,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-602-21",
    rangeType: "Florence 4",
    model: "Florence 4 Column 600mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 975,
    volumeL: 26.88,
    nominalDeltaT50KW: 1.659,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 840,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-602-22",
    rangeType: "Florence 4",
    model: "Florence 4 Column 600mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 1020,
    volumeL: 28.16,
    nominalDeltaT50KW: 1.738,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 874,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-602-23",
    rangeType: "Florence 4",
    model: "Florence 4 Column 600mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 1065,
    volumeL: 29.44,
    nominalDeltaT50KW: 1.817,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 909,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-602-24",
    rangeType: "Florence 4",
    model: "Florence 4 Column 600mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 1110,
    volumeL: 30.72,
    nominalDeltaT50KW: 1.896,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 943,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-602-25",
    rangeType: "Florence 4",
    model: "Florence 4 Column 600mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 1155,
    volumeL: 32,
    nominalDeltaT50KW: 1.975,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 978,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-602-26",
    rangeType: "Florence 4",
    model: "Florence 4 Column 600mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 1200,
    volumeL: 33.28,
    nominalDeltaT50KW: 2.054,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1012,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-602-27",
    rangeType: "Florence 4",
    model: "Florence 4 Column 600mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 1245,
    volumeL: 34.56,
    nominalDeltaT50KW: 2.133,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1047,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-602-28",
    rangeType: "Florence 4",
    model: "Florence 4 Column 600mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 1290,
    volumeL: 35.84,
    nominalDeltaT50KW: 2.212,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1081,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-602-29",
    rangeType: "Florence 4",
    model: "Florence 4 Column 600mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 1335,
    volumeL: 37.12,
    nominalDeltaT50KW: 2.291,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1116,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-602-30",
    rangeType: "Florence 4",
    model: "Florence 4 Column 600mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 1380,
    volumeL: 38.4,
    nominalDeltaT50KW: 2.37,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1150,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-602-31",
    rangeType: "Florence 4",
    model: "Florence 4 Column 600mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 1425,
    volumeL: 39.68,
    nominalDeltaT50KW: 2.449,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1185,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-602-32",
    rangeType: "Florence 4",
    model: "Florence 4 Column 600mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 1470,
    volumeL: 40.96,
    nominalDeltaT50KW: 2.528,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1219,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-602-33",
    rangeType: "Florence 4",
    model: "Florence 4 Column 600mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 1515,
    volumeL: 42.24,
    nominalDeltaT50KW: 2.607,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1254,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-602-34",
    rangeType: "Florence 4",
    model: "Florence 4 Column 600mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 1560,
    volumeL: 43.52,
    nominalDeltaT50KW: 2.686,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1288,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-602-35",
    rangeType: "Florence 4",
    model: "Florence 4 Column 600mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 1605,
    volumeL: 44.8,
    nominalDeltaT50KW: 2.765,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1323,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-602-36",
    rangeType: "Florence 4",
    model: "Florence 4 Column 600mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 1650,
    volumeL: 46.08,
    nominalDeltaT50KW: 2.844,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1357,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-602-37",
    rangeType: "Florence 4",
    model: "Florence 4 Column 600mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 1695,
    volumeL: 47.36,
    nominalDeltaT50KW: 2.923,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1392,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-602-38",
    rangeType: "Florence 4",
    model: "Florence 4 Column 600mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 1740,
    volumeL: 48.64,
    nominalDeltaT50KW: 3.002,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1426,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-602-39",
    rangeType: "Florence 4",
    model: "Florence 4 Column 600mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 1785,
    volumeL: 49.92,
    nominalDeltaT50KW: 3.081,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1461,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-602-40",
    rangeType: "Florence 4",
    model: "Florence 4 Column 600mm",
    depthMM: 141,
    heightMM: 602,
    widthMM: 1830,
    volumeL: 51.2,
    nominalDeltaT50KW: 3.16,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1495,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-687-04",
    rangeType: "Florence 4",
    model: "Florence 4 Column 685mm",
    depthMM: 141,
    heightMM: 787,
    widthMM: 210,
    volumeL: 5.68,
    nominalDeltaT50KW: 0.356,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 261,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-687-05",
    rangeType: "Florence 4",
    model: "Florence 4 Column 685mm",
    depthMM: 141,
    heightMM: 787,
    widthMM: 255,
    volumeL: 7.1,
    nominalDeltaT50KW: 0.446,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 298,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-687-06",
    rangeType: "Florence 4",
    model: "Florence 4 Column 685mm",
    depthMM: 141,
    heightMM: 787,
    widthMM: 300,
    volumeL: 8.52,
    nominalDeltaT50KW: 0.535,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 334,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-687-07",
    rangeType: "Florence 4",
    model: "Florence 4 Column 685mm",
    depthMM: 141,
    heightMM: 787,
    widthMM: 345,
    volumeL: 9.94,
    nominalDeltaT50KW: 0.624,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 371,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-687-08",
    rangeType: "Florence 4",
    model: "Florence 4 Column 685mm",
    depthMM: 141,
    heightMM: 787,
    widthMM: 390,
    volumeL: 11.36,
    nominalDeltaT50KW: 0.713,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 407,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-687-09",
    rangeType: "Florence 4",
    model: "Florence 4 Column 685mm",
    depthMM: 141,
    heightMM: 787,
    widthMM: 435,
    volumeL: 12.78,
    nominalDeltaT50KW: 0.802,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 444,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-687-10",
    rangeType: "Florence 4",
    model: "Florence 4 Column 685mm",
    depthMM: 141,
    heightMM: 787,
    widthMM: 480,
    volumeL: 14.2,
    nominalDeltaT50KW: 0.891,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 480,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-687-11",
    rangeType: "Florence 4",
    model: "Florence 4 Column 685mm",
    depthMM: 141,
    heightMM: 787,
    widthMM: 525,
    volumeL: 15.62,
    nominalDeltaT50KW: 0.98,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 517,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-687-12",
    rangeType: "Florence 4",
    model: "Florence 4 Column 685mm",
    depthMM: 141,
    heightMM: 787,
    widthMM: 570,
    volumeL: 17.04,
    nominalDeltaT50KW: 1.069,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 553,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-687-13",
    rangeType: "Florence 4",
    model: "Florence 4 Column 685mm",
    depthMM: 141,
    heightMM: 787,
    widthMM: 615,
    volumeL: 18.46,
    nominalDeltaT50KW: 1.158,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 590,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-687-14",
    rangeType: "Florence 4",
    model: "Florence 4 Column 685mm",
    depthMM: 141,
    heightMM: 787,
    widthMM: 660,
    volumeL: 19.88,
    nominalDeltaT50KW: 1.247,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 626,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-687-15",
    rangeType: "Florence 4",
    model: "Florence 4 Column 685mm",
    depthMM: 141,
    heightMM: 787,
    widthMM: 705,
    volumeL: 21.3,
    nominalDeltaT50KW: 1.337,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 663,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-687-16",
    rangeType: "Florence 4",
    model: "Florence 4 Column 685mm",
    depthMM: 141,
    heightMM: 787,
    widthMM: 750,
    volumeL: 22.72,
    nominalDeltaT50KW: 1.426,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 699,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-687-17",
    rangeType: "Florence 4",
    model: "Florence 4 Column 685mm",
    depthMM: 141,
    heightMM: 787,
    widthMM: 795,
    volumeL: 24.14,
    nominalDeltaT50KW: 1.515,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 736,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-687-18",
    rangeType: "Florence 4",
    model: "Florence 4 Column 685mm",
    depthMM: 141,
    heightMM: 787,
    widthMM: 840,
    volumeL: 25.56,
    nominalDeltaT50KW: 1.604,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 772,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-687-19",
    rangeType: "Florence 4",
    model: "Florence 4 Column 685mm",
    depthMM: 141,
    heightMM: 787,
    widthMM: 885,
    volumeL: 26.98,
    nominalDeltaT50KW: 1.693,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 809,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-687-20",
    rangeType: "Florence 4",
    model: "Florence 4 Column 685mm",
    depthMM: 141,
    heightMM: 787,
    widthMM: 930,
    volumeL: 28.4,
    nominalDeltaT50KW: 1.782,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 845,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-687-21",
    rangeType: "Florence 4",
    model: "Florence 4 Column 685mm",
    depthMM: 141,
    heightMM: 787,
    widthMM: 975,
    volumeL: 29.82,
    nominalDeltaT50KW: 1.871,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 882,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-687-22",
    rangeType: "Florence 4",
    model: "Florence 4 Column 685mm",
    depthMM: 141,
    heightMM: 787,
    widthMM: 1020,
    volumeL: 31.24,
    nominalDeltaT50KW: 1.96,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 918,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-687-23",
    rangeType: "Florence 4",
    model: "Florence 4 Column 685mm",
    depthMM: 141,
    heightMM: 787,
    widthMM: 1065,
    volumeL: 32.66,
    nominalDeltaT50KW: 2.049,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 955,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-687-24",
    rangeType: "Florence 4",
    model: "Florence 4 Column 685mm",
    depthMM: 141,
    heightMM: 787,
    widthMM: 1110,
    volumeL: 34.08,
    nominalDeltaT50KW: 2.138,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 991,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-687-25",
    rangeType: "Florence 4",
    model: "Florence 4 Column 685mm",
    depthMM: 141,
    heightMM: 787,
    widthMM: 1155,
    volumeL: 35.5,
    nominalDeltaT50KW: 2.228,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1028,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-687-26",
    rangeType: "Florence 4",
    model: "Florence 4 Column 685mm",
    depthMM: 141,
    heightMM: 787,
    widthMM: 1200,
    volumeL: 36.92,
    nominalDeltaT50KW: 2.317,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1064,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-687-27",
    rangeType: "Florence 4",
    model: "Florence 4 Column 685mm",
    depthMM: 141,
    heightMM: 787,
    widthMM: 1245,
    volumeL: 38.34,
    nominalDeltaT50KW: 2.406,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1101,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-687-28",
    rangeType: "Florence 4",
    model: "Florence 4 Column 685mm",
    depthMM: 141,
    heightMM: 787,
    widthMM: 1290,
    volumeL: 39.76,
    nominalDeltaT50KW: 2.495,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1137,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-687-29",
    rangeType: "Florence 4",
    model: "Florence 4 Column 685mm",
    depthMM: 141,
    heightMM: 787,
    widthMM: 1335,
    volumeL: 41.18,
    nominalDeltaT50KW: 2.584,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1174,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-687-30",
    rangeType: "Florence 4",
    model: "Florence 4 Column 685mm",
    depthMM: 141,
    heightMM: 787,
    widthMM: 1380,
    volumeL: 42.6,
    nominalDeltaT50KW: 2.673,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1210,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-687-31",
    rangeType: "Florence 4",
    model: "Florence 4 Column 685mm",
    depthMM: 141,
    heightMM: 787,
    widthMM: 1425,
    volumeL: 44.02,
    nominalDeltaT50KW: 2.762,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1247,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-687-32",
    rangeType: "Florence 4",
    model: "Florence 4 Column 685mm",
    depthMM: 141,
    heightMM: 787,
    widthMM: 1470,
    volumeL: 45.44,
    nominalDeltaT50KW: 2.851,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1283,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-687-33",
    rangeType: "Florence 4",
    model: "Florence 4 Column 685mm",
    depthMM: 141,
    heightMM: 787,
    widthMM: 1515,
    volumeL: 46.86,
    nominalDeltaT50KW: 2.94,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1320,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-687-34",
    rangeType: "Florence 4",
    model: "Florence 4 Column 685mm",
    depthMM: 141,
    heightMM: 787,
    widthMM: 1560,
    volumeL: 48.28,
    nominalDeltaT50KW: 3.029,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1356,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-687-35",
    rangeType: "Florence 4",
    model: "Florence 4 Column 685mm",
    depthMM: 141,
    heightMM: 787,
    widthMM: 1605,
    volumeL: 49.7,
    nominalDeltaT50KW: 3.119,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1393,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-687-36",
    rangeType: "Florence 4",
    model: "Florence 4 Column 685mm",
    depthMM: 141,
    heightMM: 787,
    widthMM: 1650,
    volumeL: 51.12,
    nominalDeltaT50KW: 3.208,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1429,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-687-37",
    rangeType: "Florence 4",
    model: "Florence 4 Column 685mm",
    depthMM: 141,
    heightMM: 787,
    widthMM: 1695,
    volumeL: 52.54,
    nominalDeltaT50KW: 3.297,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1466,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-687-38",
    rangeType: "Florence 4",
    model: "Florence 4 Column 685mm",
    depthMM: 141,
    heightMM: 787,
    widthMM: 1740,
    volumeL: 53.96,
    nominalDeltaT50KW: 3.386,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1502,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-687-39",
    rangeType: "Florence 4",
    model: "Florence 4 Column 685mm",
    depthMM: 141,
    heightMM: 787,
    widthMM: 1785,
    volumeL: 55.38,
    nominalDeltaT50KW: 3.475,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1539,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-687-40",
    rangeType: "Florence 4",
    model: "Florence 4 Column 685mm",
    depthMM: 141,
    heightMM: 787,
    widthMM: 1830,
    volumeL: 56.8,
    nominalDeltaT50KW: 3.564,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1575,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-767-04",
    rangeType: "Florence 4",
    model: "Florence 4 Column 765mm",
    depthMM: 141,
    heightMM: 867,
    widthMM: 210,
    volumeL: 6.2,
    nominalDeltaT50KW: 0.394,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 265,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-767-05",
    rangeType: "Florence 4",
    model: "Florence 4 Column 765mm",
    depthMM: 141,
    heightMM: 867,
    widthMM: 255,
    volumeL: 7.75,
    nominalDeltaT50KW: 0.493,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 303,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-767-06",
    rangeType: "Florence 4",
    model: "Florence 4 Column 765mm",
    depthMM: 141,
    heightMM: 867,
    widthMM: 300,
    volumeL: 9.3,
    nominalDeltaT50KW: 0.592,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 340,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-767-07",
    rangeType: "Florence 4",
    model: "Florence 4 Column 765mm",
    depthMM: 141,
    heightMM: 867,
    widthMM: 345,
    volumeL: 10.85,
    nominalDeltaT50KW: 0.69,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 378,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-767-08",
    rangeType: "Florence 4",
    model: "Florence 4 Column 765mm",
    depthMM: 141,
    heightMM: 867,
    widthMM: 390,
    volumeL: 12.4,
    nominalDeltaT50KW: 0.789,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 415,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-767-09",
    rangeType: "Florence 4",
    model: "Florence 4 Column 765mm",
    depthMM: 141,
    heightMM: 867,
    widthMM: 435,
    volumeL: 13.95,
    nominalDeltaT50KW: 0.887,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 453,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-767-10",
    rangeType: "Florence 4",
    model: "Florence 4 Column 765mm",
    depthMM: 141,
    heightMM: 867,
    widthMM: 480,
    volumeL: 15.5,
    nominalDeltaT50KW: 0.986,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 490,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-767-11",
    rangeType: "Florence 4",
    model: "Florence 4 Column 765mm",
    depthMM: 141,
    heightMM: 867,
    widthMM: 525,
    volumeL: 17.05,
    nominalDeltaT50KW: 1.085,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 528,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-767-12",
    rangeType: "Florence 4",
    model: "Florence 4 Column 765mm",
    depthMM: 141,
    heightMM: 867,
    widthMM: 570,
    volumeL: 18.6,
    nominalDeltaT50KW: 1.183,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 565,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-767-13",
    rangeType: "Florence 4",
    model: "Florence 4 Column 765mm",
    depthMM: 141,
    heightMM: 867,
    widthMM: 615,
    volumeL: 20.15,
    nominalDeltaT50KW: 1.282,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 603,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-767-14",
    rangeType: "Florence 4",
    model: "Florence 4 Column 765mm",
    depthMM: 141,
    heightMM: 867,
    widthMM: 660,
    volumeL: 21.7,
    nominalDeltaT50KW: 1.38,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 640,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-767-15",
    rangeType: "Florence 4",
    model: "Florence 4 Column 765mm",
    depthMM: 141,
    heightMM: 867,
    widthMM: 705,
    volumeL: 23.25,
    nominalDeltaT50KW: 1.479,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 678,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-767-16",
    rangeType: "Florence 4",
    model: "Florence 4 Column 765mm",
    depthMM: 141,
    heightMM: 867,
    widthMM: 750,
    volumeL: 24.8,
    nominalDeltaT50KW: 1.578,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 715,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-767-17",
    rangeType: "Florence 4",
    model: "Florence 4 Column 765mm",
    depthMM: 141,
    heightMM: 867,
    widthMM: 795,
    volumeL: 26.35,
    nominalDeltaT50KW: 1.676,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 753,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-767-18",
    rangeType: "Florence 4",
    model: "Florence 4 Column 765mm",
    depthMM: 141,
    heightMM: 867,
    widthMM: 840,
    volumeL: 27.9,
    nominalDeltaT50KW: 1.775,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 790,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-767-19",
    rangeType: "Florence 4",
    model: "Florence 4 Column 765mm",
    depthMM: 141,
    heightMM: 867,
    widthMM: 885,
    volumeL: 29.45,
    nominalDeltaT50KW: 1.873,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 828,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-767-20",
    rangeType: "Florence 4",
    model: "Florence 4 Column 765mm",
    depthMM: 141,
    heightMM: 867,
    widthMM: 930,
    volumeL: 31,
    nominalDeltaT50KW: 1.972,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 865,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-767-21",
    rangeType: "Florence 4",
    model: "Florence 4 Column 765mm",
    depthMM: 141,
    heightMM: 867,
    widthMM: 975,
    volumeL: 32.55,
    nominalDeltaT50KW: 2.071,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 903,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-767-22",
    rangeType: "Florence 4",
    model: "Florence 4 Column 765mm",
    depthMM: 141,
    heightMM: 867,
    widthMM: 1020,
    volumeL: 34.1,
    nominalDeltaT50KW: 2.169,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 940,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-767-23",
    rangeType: "Florence 4",
    model: "Florence 4 Column 765mm",
    depthMM: 141,
    heightMM: 867,
    widthMM: 1065,
    volumeL: 35.65,
    nominalDeltaT50KW: 2.268,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 978,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-767-24",
    rangeType: "Florence 4",
    model: "Florence 4 Column 765mm",
    depthMM: 141,
    heightMM: 867,
    widthMM: 1110,
    volumeL: 37.2,
    nominalDeltaT50KW: 2.366,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1015,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-767-25",
    rangeType: "Florence 4",
    model: "Florence 4 Column 765mm",
    depthMM: 141,
    heightMM: 867,
    widthMM: 1155,
    volumeL: 38.75,
    nominalDeltaT50KW: 2.465,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1053,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-767-26",
    rangeType: "Florence 4",
    model: "Florence 4 Column 765mm",
    depthMM: 141,
    heightMM: 867,
    widthMM: 1200,
    volumeL: 40.3,
    nominalDeltaT50KW: 2.564,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1090,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-767-27",
    rangeType: "Florence 4",
    model: "Florence 4 Column 765mm",
    depthMM: 141,
    heightMM: 867,
    widthMM: 1245,
    volumeL: 41.85,
    nominalDeltaT50KW: 2.662,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1128,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-767-28",
    rangeType: "Florence 4",
    model: "Florence 4 Column 765mm",
    depthMM: 141,
    heightMM: 867,
    widthMM: 1290,
    volumeL: 43.4,
    nominalDeltaT50KW: 2.761,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1165,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-767-29",
    rangeType: "Florence 4",
    model: "Florence 4 Column 765mm",
    depthMM: 141,
    heightMM: 867,
    widthMM: 1335,
    volumeL: 44.95,
    nominalDeltaT50KW: 2.859,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1203,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-767-30",
    rangeType: "Florence 4",
    model: "Florence 4 Column 765mm",
    depthMM: 141,
    heightMM: 867,
    widthMM: 1380,
    volumeL: 46.5,
    nominalDeltaT50KW: 2.958,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1240,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-767-31",
    rangeType: "Florence 4",
    model: "Florence 4 Column 765mm",
    depthMM: 141,
    heightMM: 867,
    widthMM: 1425,
    volumeL: 48.05,
    nominalDeltaT50KW: 3.057,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1278,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-767-32",
    rangeType: "Florence 4",
    model: "Florence 4 Column 765mm",
    depthMM: 141,
    heightMM: 867,
    widthMM: 1470,
    volumeL: 49.6,
    nominalDeltaT50KW: 3.155,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1315,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-767-33",
    rangeType: "Florence 4",
    model: "Florence 4 Column 765mm",
    depthMM: 141,
    heightMM: 867,
    widthMM: 1515,
    volumeL: 51.15,
    nominalDeltaT50KW: 3.254,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1353,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-767-34",
    rangeType: "Florence 4",
    model: "Florence 4 Column 765mm",
    depthMM: 141,
    heightMM: 867,
    widthMM: 1560,
    volumeL: 52.7,
    nominalDeltaT50KW: 3.352,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1390,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-767-35",
    rangeType: "Florence 4",
    model: "Florence 4 Column 765mm",
    depthMM: 141,
    heightMM: 867,
    widthMM: 1605,
    volumeL: 54.25,
    nominalDeltaT50KW: 3.451,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1428,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-767-36",
    rangeType: "Florence 4",
    model: "Florence 4 Column 765mm",
    depthMM: 141,
    heightMM: 867,
    widthMM: 1650,
    volumeL: 55.8,
    nominalDeltaT50KW: 3.55,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1465,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-767-37",
    rangeType: "Florence 4",
    model: "Florence 4 Column 765mm",
    depthMM: 141,
    heightMM: 867,
    widthMM: 1695,
    volumeL: 57.35,
    nominalDeltaT50KW: 3.648,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1503,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-767-38",
    rangeType: "Florence 4",
    model: "Florence 4 Column 765mm",
    depthMM: 141,
    heightMM: 867,
    widthMM: 1740,
    volumeL: 58.9,
    nominalDeltaT50KW: 3.747,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1540,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-767-39",
    rangeType: "Florence 4",
    model: "Florence 4 Column 765mm",
    depthMM: 141,
    heightMM: 867,
    widthMM: 1785,
    volumeL: 60.45,
    nominalDeltaT50KW: 3.845,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1578,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-767-40",
    rangeType: "Florence 4",
    model: "Florence 4 Column 765mm",
    depthMM: 141,
    heightMM: 867,
    widthMM: 1830,
    volumeL: 62,
    nominalDeltaT50KW: 3.944,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1615,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-902-04",
    rangeType: "Florence 4",
    model: "Florence 4 Column 900mm",
    depthMM: 141,
    heightMM: 1002,
    widthMM: 210,
    volumeL: 7.12,
    nominalDeltaT50KW: 0.457,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 269,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-902-05",
    rangeType: "Florence 4",
    model: "Florence 4 Column 900mm",
    depthMM: 141,
    heightMM: 1002,
    widthMM: 255,
    volumeL: 8.9,
    nominalDeltaT50KW: 0.572,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 308,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-902-06",
    rangeType: "Florence 4",
    model: "Florence 4 Column 900mm",
    depthMM: 141,
    heightMM: 1002,
    widthMM: 300,
    volumeL: 10.68,
    nominalDeltaT50KW: 0.686,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 346,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-902-07",
    rangeType: "Florence 4",
    model: "Florence 4 Column 900mm",
    depthMM: 141,
    heightMM: 1002,
    widthMM: 345,
    volumeL: 12.46,
    nominalDeltaT50KW: 0.8,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 385,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-902-08",
    rangeType: "Florence 4",
    model: "Florence 4 Column 900mm",
    depthMM: 141,
    heightMM: 1002,
    widthMM: 390,
    volumeL: 14.24,
    nominalDeltaT50KW: 0.914,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 423,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-902-09",
    rangeType: "Florence 4",
    model: "Florence 4 Column 900mm",
    depthMM: 141,
    heightMM: 1002,
    widthMM: 435,
    volumeL: 16.02,
    nominalDeltaT50KW: 1.029,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 462,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-902-10",
    rangeType: "Florence 4",
    model: "Florence 4 Column 900mm",
    depthMM: 141,
    heightMM: 1002,
    widthMM: 480,
    volumeL: 17.8,
    nominalDeltaT50KW: 1.143,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 500,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-902-11",
    rangeType: "Florence 4",
    model: "Florence 4 Column 900mm",
    depthMM: 141,
    heightMM: 1002,
    widthMM: 525,
    volumeL: 19.58,
    nominalDeltaT50KW: 1.257,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 539,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-902-12",
    rangeType: "Florence 4",
    model: "Florence 4 Column 900mm",
    depthMM: 141,
    heightMM: 1002,
    widthMM: 570,
    volumeL: 21.36,
    nominalDeltaT50KW: 1.372,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 577,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-902-13",
    rangeType: "Florence 4",
    model: "Florence 4 Column 900mm",
    depthMM: 141,
    heightMM: 1002,
    widthMM: 615,
    volumeL: 23.14,
    nominalDeltaT50KW: 1.486,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 616,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-902-14",
    rangeType: "Florence 4",
    model: "Florence 4 Column 900mm",
    depthMM: 141,
    heightMM: 1002,
    widthMM: 660,
    volumeL: 24.92,
    nominalDeltaT50KW: 1.6,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 654,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-902-15",
    rangeType: "Florence 4",
    model: "Florence 4 Column 900mm",
    depthMM: 141,
    heightMM: 1002,
    widthMM: 705,
    volumeL: 26.7,
    nominalDeltaT50KW: 1.715,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 693,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-902-16",
    rangeType: "Florence 4",
    model: "Florence 4 Column 900mm",
    depthMM: 141,
    heightMM: 1002,
    widthMM: 750,
    volumeL: 28.48,
    nominalDeltaT50KW: 1.829,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 731,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-902-17",
    rangeType: "Florence 4",
    model: "Florence 4 Column 900mm",
    depthMM: 141,
    heightMM: 1002,
    widthMM: 795,
    volumeL: 30.26,
    nominalDeltaT50KW: 1.943,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 770,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-902-18",
    rangeType: "Florence 4",
    model: "Florence 4 Column 900mm",
    depthMM: 141,
    heightMM: 1002,
    widthMM: 840,
    volumeL: 32.04,
    nominalDeltaT50KW: 2.057,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 808,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-902-19",
    rangeType: "Florence 4",
    model: "Florence 4 Column 900mm",
    depthMM: 141,
    heightMM: 1002,
    widthMM: 885,
    volumeL: 33.82,
    nominalDeltaT50KW: 2.172,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 847,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-902-20",
    rangeType: "Florence 4",
    model: "Florence 4 Column 900mm",
    depthMM: 141,
    heightMM: 1002,
    widthMM: 930,
    volumeL: 35.6,
    nominalDeltaT50KW: 2.286,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 885,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-902-21",
    rangeType: "Florence 4",
    model: "Florence 4 Column 900mm",
    depthMM: 141,
    heightMM: 1002,
    widthMM: 975,
    volumeL: 37.38,
    nominalDeltaT50KW: 2.4,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 924,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-902-22",
    rangeType: "Florence 4",
    model: "Florence 4 Column 900mm",
    depthMM: 141,
    heightMM: 1002,
    widthMM: 1020,
    volumeL: 39.16,
    nominalDeltaT50KW: 2.515,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 962,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-902-23",
    rangeType: "Florence 4",
    model: "Florence 4 Column 900mm",
    depthMM: 141,
    heightMM: 1002,
    widthMM: 1065,
    volumeL: 40.94,
    nominalDeltaT50KW: 2.629,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1001,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-902-24",
    rangeType: "Florence 4",
    model: "Florence 4 Column 900mm",
    depthMM: 141,
    heightMM: 1002,
    widthMM: 1110,
    volumeL: 42.72,
    nominalDeltaT50KW: 2.743,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1039,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-902-25",
    rangeType: "Florence 4",
    model: "Florence 4 Column 900mm",
    depthMM: 141,
    heightMM: 1002,
    widthMM: 1155,
    volumeL: 44.5,
    nominalDeltaT50KW: 2.858,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1078,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-902-26",
    rangeType: "Florence 4",
    model: "Florence 4 Column 900mm",
    depthMM: 141,
    heightMM: 1002,
    widthMM: 1200,
    volumeL: 46.28,
    nominalDeltaT50KW: 2.972,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1116,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-902-27",
    rangeType: "Florence 4",
    model: "Florence 4 Column 900mm",
    depthMM: 141,
    heightMM: 1002,
    widthMM: 1245,
    volumeL: 48.06,
    nominalDeltaT50KW: 3.086,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1155,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-902-28",
    rangeType: "Florence 4",
    model: "Florence 4 Column 900mm",
    depthMM: 141,
    heightMM: 1002,
    widthMM: 1290,
    volumeL: 49.84,
    nominalDeltaT50KW: 3.2,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1193,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-902-29",
    rangeType: "Florence 4",
    model: "Florence 4 Column 900mm",
    depthMM: 141,
    heightMM: 1002,
    widthMM: 1335,
    volumeL: 51.62,
    nominalDeltaT50KW: 3.315,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1232,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-902-30",
    rangeType: "Florence 4",
    model: "Florence 4 Column 900mm",
    depthMM: 141,
    heightMM: 1002,
    widthMM: 1380,
    volumeL: 53.4,
    nominalDeltaT50KW: 3.429,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1270,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-902-31",
    rangeType: "Florence 4",
    model: "Florence 4 Column 900mm",
    depthMM: 141,
    heightMM: 1002,
    widthMM: 1425,
    volumeL: 55.18,
    nominalDeltaT50KW: 3.543,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1309,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-902-32",
    rangeType: "Florence 4",
    model: "Florence 4 Column 900mm",
    depthMM: 141,
    heightMM: 1002,
    widthMM: 1470,
    volumeL: 56.96,
    nominalDeltaT50KW: 3.658,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1347,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-902-33",
    rangeType: "Florence 4",
    model: "Florence 4 Column 900mm",
    depthMM: 141,
    heightMM: 1002,
    widthMM: 1515,
    volumeL: 58.74,
    nominalDeltaT50KW: 3.772,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1386,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-902-34",
    rangeType: "Florence 4",
    model: "Florence 4 Column 900mm",
    depthMM: 141,
    heightMM: 1002,
    widthMM: 1560,
    volumeL: 60.52,
    nominalDeltaT50KW: 3.886,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1424,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-902-35",
    rangeType: "Florence 4",
    model: "Florence 4 Column 900mm",
    depthMM: 141,
    heightMM: 1002,
    widthMM: 1605,
    volumeL: 62.3,
    nominalDeltaT50KW: 4.001,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1463,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-902-36",
    rangeType: "Florence 4",
    model: "Florence 4 Column 900mm",
    depthMM: 141,
    heightMM: 1002,
    widthMM: 1650,
    volumeL: 64.08,
    nominalDeltaT50KW: 4.115,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1501,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-902-37",
    rangeType: "Florence 4",
    model: "Florence 4 Column 900mm",
    depthMM: 141,
    heightMM: 1002,
    widthMM: 1695,
    volumeL: 65.86,
    nominalDeltaT50KW: 4.229,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1540,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-902-38",
    rangeType: "Florence 4",
    model: "Florence 4 Column 900mm",
    depthMM: 141,
    heightMM: 1002,
    widthMM: 1740,
    volumeL: 67.64,
    nominalDeltaT50KW: 4.343,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1578,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-902-39",
    rangeType: "Florence 4",
    model: "Florence 4 Column 900mm",
    depthMM: 141,
    heightMM: 1002,
    widthMM: 1785,
    volumeL: 69.42,
    nominalDeltaT50KW: 4.458,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1617,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F4C-902-40",
    rangeType: "Florence 4",
    model: "Florence 4 Column 900mm",
    depthMM: 141,
    heightMM: 1002,
    widthMM: 1830,
    volumeL: 71.2,
    nominalDeltaT50KW: 4.572,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1655,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1002-04",
    rangeType: "Florence 4",
    model: "Florence 5 Column 1000mm",
    depthMM: 179,
    heightMM: 1102,
    widthMM: 210,
    volumeL: 9.6,
    nominalDeltaT50KW: 0.61,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 341,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1002-05",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1000mm",
    depthMM: 179,
    heightMM: 1102,
    widthMM: 255,
    volumeL: 12,
    nominalDeltaT50KW: 0.762,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 398,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1002-06",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1000mm",
    depthMM: 179,
    heightMM: 1102,
    widthMM: 300,
    volumeL: 14.4,
    nominalDeltaT50KW: 0.914,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 454,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1002-07",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1000mm",
    depthMM: 179,
    heightMM: 1102,
    widthMM: 345,
    volumeL: 16.8,
    nominalDeltaT50KW: 1.067,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 511,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1002-08",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1000mm",
    depthMM: 179,
    heightMM: 1102,
    widthMM: 390,
    volumeL: 19.2,
    nominalDeltaT50KW: 1.219,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 567,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1002-09",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1000mm",
    depthMM: 179,
    heightMM: 1102,
    widthMM: 435,
    volumeL: 21.6,
    nominalDeltaT50KW: 1.372,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 624,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1002-10",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1000mm",
    depthMM: 179,
    heightMM: 1102,
    widthMM: 480,
    volumeL: 24,
    nominalDeltaT50KW: 1.524,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 680,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1002-11",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1000mm",
    depthMM: 179,
    heightMM: 1102,
    widthMM: 525,
    volumeL: 26.4,
    nominalDeltaT50KW: 1.676,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 737,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1002-12",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1000mm",
    depthMM: 179,
    heightMM: 1102,
    widthMM: 570,
    volumeL: 28.8,
    nominalDeltaT50KW: 1.829,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 793,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1002-13",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1000mm",
    depthMM: 179,
    heightMM: 1102,
    widthMM: 615,
    volumeL: 31.2,
    nominalDeltaT50KW: 1.981,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 850,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1002-14",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1000mm",
    depthMM: 179,
    heightMM: 1102,
    widthMM: 660,
    volumeL: 33.6,
    nominalDeltaT50KW: 2.134,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 906,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1002-15",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1000mm",
    depthMM: 179,
    heightMM: 1102,
    widthMM: 705,
    volumeL: 36,
    nominalDeltaT50KW: 2.286,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 963,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1002-16",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1000mm",
    depthMM: 179,
    heightMM: 1102,
    widthMM: 750,
    volumeL: 38.4,
    nominalDeltaT50KW: 2.438,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 1019,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1002-17",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1000mm",
    depthMM: 179,
    heightMM: 1102,
    widthMM: 795,
    volumeL: 40.8,
    nominalDeltaT50KW: 2.591,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 1076,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1002-18",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1000mm",
    depthMM: 179,
    heightMM: 1102,
    widthMM: 840,
    volumeL: 43.2,
    nominalDeltaT50KW: 2.743,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 1132,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1002-19",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1000mm",
    depthMM: 179,
    heightMM: 1102,
    widthMM: 885,
    volumeL: 45.6,
    nominalDeltaT50KW: 2.896,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 1189,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1002-20",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1000mm",
    depthMM: 179,
    heightMM: 1102,
    widthMM: 930,
    volumeL: 48,
    nominalDeltaT50KW: 3.048,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 1245,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1002-21",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1000mm",
    depthMM: 179,
    heightMM: 1102,
    widthMM: 975,
    volumeL: 50.4,
    nominalDeltaT50KW: 3.2,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 1302,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1002-22",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1000mm",
    depthMM: 179,
    heightMM: 1102,
    widthMM: 1020,
    volumeL: 52.8,
    nominalDeltaT50KW: 3.353,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 1358,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1002-23",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1000mm",
    depthMM: 179,
    heightMM: 1102,
    widthMM: 1065,
    volumeL: 55.2,
    nominalDeltaT50KW: 3.505,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 1415,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1002-24",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1000mm",
    depthMM: 179,
    heightMM: 1102,
    widthMM: 1110,
    volumeL: 57.6,
    nominalDeltaT50KW: 3.658,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 1471,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1002-25",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1000mm",
    depthMM: 179,
    heightMM: 1102,
    widthMM: 1155,
    volumeL: 60,
    nominalDeltaT50KW: 3.81,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 1528,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1002-26",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1000mm",
    depthMM: 179,
    heightMM: 1102,
    widthMM: 1200,
    volumeL: 62.4,
    nominalDeltaT50KW: 3.962,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 1584,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1002-27",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1000mm",
    depthMM: 179,
    heightMM: 1102,
    widthMM: 1245,
    volumeL: 64.8,
    nominalDeltaT50KW: 4.115,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 1641,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1002-28",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1000mm",
    depthMM: 179,
    heightMM: 1102,
    widthMM: 1290,
    volumeL: 67.2,
    nominalDeltaT50KW: 4.267,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 1697,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1002-29",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1000mm",
    depthMM: 179,
    heightMM: 1102,
    widthMM: 1335,
    volumeL: 69.6,
    nominalDeltaT50KW: 4.42,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 1754,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1002-30",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1000mm",
    depthMM: 179,
    heightMM: 1102,
    widthMM: 1380,
    volumeL: 72,
    nominalDeltaT50KW: 4.572,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 1810,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1002-31",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1000mm",
    depthMM: 179,
    heightMM: 1102,
    widthMM: 1425,
    volumeL: 74.4,
    nominalDeltaT50KW: 4.724,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 1867,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1002-32",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1000mm",
    depthMM: 179,
    heightMM: 1102,
    widthMM: 1470,
    volumeL: 76.8,
    nominalDeltaT50KW: 4.877,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 1923,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1002-33",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1000mm",
    depthMM: 179,
    heightMM: 1102,
    widthMM: 1515,
    volumeL: 79.2,
    nominalDeltaT50KW: 5.029,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 1980,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1002-34",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1000mm",
    depthMM: 179,
    heightMM: 1102,
    widthMM: 1560,
    volumeL: 81.6,
    nominalDeltaT50KW: 5.182,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 2036,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1002-35",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1000mm",
    depthMM: 179,
    heightMM: 1102,
    widthMM: 1605,
    volumeL: 84,
    nominalDeltaT50KW: 5.334,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 2093,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1002-36",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1000mm",
    depthMM: 179,
    heightMM: 1102,
    widthMM: 1650,
    volumeL: 86.4,
    nominalDeltaT50KW: 5.486,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 2149,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1002-37",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1000mm",
    depthMM: 179,
    heightMM: 1102,
    widthMM: 1695,
    volumeL: 88.8,
    nominalDeltaT50KW: 5.639,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 2206,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1002-38",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1000mm",
    depthMM: 179,
    heightMM: 1102,
    widthMM: 1740,
    volumeL: 91.2,
    nominalDeltaT50KW: 5.791,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 2262,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1002-39",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1000mm",
    depthMM: 179,
    heightMM: 1102,
    widthMM: 1785,
    volumeL: 93.6,
    nominalDeltaT50KW: 5.944,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 2319,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1002-40",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1000mm",
    depthMM: 179,
    heightMM: 1102,
    widthMM: 1830,
    volumeL: 96,
    nominalDeltaT50KW: 6.096,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 2375,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1202-04",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1200mm",
    depthMM: 179,
    heightMM: 1302,
    widthMM: 210,
    volumeL: 11.12,
    nominalDeltaT50KW: 0.72,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 421,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1202-05",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1200mm",
    depthMM: 179,
    heightMM: 1302,
    widthMM: 255,
    volumeL: 13.9,
    nominalDeltaT50KW: 0.9,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 498,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1202-06",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1200mm",
    depthMM: 179,
    heightMM: 1302,
    widthMM: 300,
    volumeL: 16.68,
    nominalDeltaT50KW: 1.08,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 574,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1202-07",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1200mm",
    depthMM: 179,
    heightMM: 1302,
    widthMM: 345,
    volumeL: 19.46,
    nominalDeltaT50KW: 1.26,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 651,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1202-08",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1200mm",
    depthMM: 179,
    heightMM: 1302,
    widthMM: 390,
    volumeL: 22.24,
    nominalDeltaT50KW: 1.44,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 727,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1202-09",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1200mm",
    depthMM: 179,
    heightMM: 1302,
    widthMM: 435,
    volumeL: 25.02,
    nominalDeltaT50KW: 1.62,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 804,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1202-10",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1200mm",
    depthMM: 179,
    heightMM: 1302,
    widthMM: 480,
    volumeL: 27.8,
    nominalDeltaT50KW: 1.8,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 880,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1202-11",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1200mm",
    depthMM: 179,
    heightMM: 1302,
    widthMM: 525,
    volumeL: 30.58,
    nominalDeltaT50KW: 1.98,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 957,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1202-12",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1200mm",
    depthMM: 179,
    heightMM: 1302,
    widthMM: 570,
    volumeL: 33.36,
    nominalDeltaT50KW: 2.16,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1033,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1202-13",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1200mm",
    depthMM: 179,
    heightMM: 1302,
    widthMM: 615,
    volumeL: 36.14,
    nominalDeltaT50KW: 2.34,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1110,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1202-14",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1200mm",
    depthMM: 179,
    heightMM: 1302,
    widthMM: 660,
    volumeL: 38.92,
    nominalDeltaT50KW: 2.52,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1186,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1202-15",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1200mm",
    depthMM: 179,
    heightMM: 1302,
    widthMM: 705,
    volumeL: 41.7,
    nominalDeltaT50KW: 2.7,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1263,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1202-16",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1200mm",
    depthMM: 179,
    heightMM: 1302,
    widthMM: 750,
    volumeL: 44.48,
    nominalDeltaT50KW: 2.88,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1339,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1202-17",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1200mm",
    depthMM: 179,
    heightMM: 1302,
    widthMM: 795,
    volumeL: 47.26,
    nominalDeltaT50KW: 3.06,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1416,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1202-18",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1200mm",
    depthMM: 179,
    heightMM: 1302,
    widthMM: 840,
    volumeL: 50.04,
    nominalDeltaT50KW: 3.24,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1492,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1202-19",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1200mm",
    depthMM: 179,
    heightMM: 1302,
    widthMM: 885,
    volumeL: 52.82,
    nominalDeltaT50KW: 3.42,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1569,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1202-20",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1200mm",
    depthMM: 179,
    heightMM: 1302,
    widthMM: 930,
    volumeL: 55.6,
    nominalDeltaT50KW: 3.6,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1645,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1202-21",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1200mm",
    depthMM: 179,
    heightMM: 1302,
    widthMM: 975,
    volumeL: 58.38,
    nominalDeltaT50KW: 3.78,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1722,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1202-22",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1200mm",
    depthMM: 179,
    heightMM: 1302,
    widthMM: 1020,
    volumeL: 61.16,
    nominalDeltaT50KW: 3.96,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1798,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1202-23",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1200mm",
    depthMM: 179,
    heightMM: 1302,
    widthMM: 1065,
    volumeL: 63.94,
    nominalDeltaT50KW: 4.14,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1875,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1202-24",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1200mm",
    depthMM: 179,
    heightMM: 1302,
    widthMM: 1110,
    volumeL: 66.72,
    nominalDeltaT50KW: 4.32,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1951,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1202-25",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1200mm",
    depthMM: 179,
    heightMM: 1302,
    widthMM: 1155,
    volumeL: 69.5,
    nominalDeltaT50KW: 4.5,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 2028,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1202-26",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1200mm",
    depthMM: 179,
    heightMM: 1302,
    widthMM: 1200,
    volumeL: 72.28,
    nominalDeltaT50KW: 4.68,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 2104,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1202-27",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1200mm",
    depthMM: 179,
    heightMM: 1302,
    widthMM: 1245,
    volumeL: 75.06,
    nominalDeltaT50KW: 4.86,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 2181,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1202-28",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1200mm",
    depthMM: 179,
    heightMM: 1302,
    widthMM: 1290,
    volumeL: 77.84,
    nominalDeltaT50KW: 5.04,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 2257,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1202-29",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1200mm",
    depthMM: 179,
    heightMM: 1302,
    widthMM: 1335,
    volumeL: 80.62,
    nominalDeltaT50KW: 5.22,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 2334,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1202-30",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1200mm",
    depthMM: 179,
    heightMM: 1302,
    widthMM: 1380,
    volumeL: 83.4,
    nominalDeltaT50KW: 5.4,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 2410,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1202-31",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1200mm",
    depthMM: 179,
    heightMM: 1302,
    widthMM: 1425,
    volumeL: 86.18,
    nominalDeltaT50KW: 5.58,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 2487,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1202-32",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1200mm",
    depthMM: 179,
    heightMM: 1302,
    widthMM: 1470,
    volumeL: 88.96,
    nominalDeltaT50KW: 5.76,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 2563,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1202-33",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1200mm",
    depthMM: 179,
    heightMM: 1302,
    widthMM: 1515,
    volumeL: 91.74,
    nominalDeltaT50KW: 5.94,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 2640,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1202-34",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1200mm",
    depthMM: 179,
    heightMM: 1302,
    widthMM: 1560,
    volumeL: 94.52,
    nominalDeltaT50KW: 6.12,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 2716,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1202-35",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1200mm",
    depthMM: 179,
    heightMM: 1302,
    widthMM: 1605,
    volumeL: 97.3,
    nominalDeltaT50KW: 6.3,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 2793,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1202-36",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1200mm",
    depthMM: 179,
    heightMM: 1302,
    widthMM: 1650,
    volumeL: 100.08,
    nominalDeltaT50KW: 6.48,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 2869,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1202-37",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1200mm",
    depthMM: 179,
    heightMM: 1302,
    widthMM: 1695,
    volumeL: 102.86,
    nominalDeltaT50KW: 6.66,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 2946,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1202-38",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1200mm",
    depthMM: 179,
    heightMM: 1302,
    widthMM: 1740,
    volumeL: 105.64,
    nominalDeltaT50KW: 6.84,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 3022,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1202-39",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1200mm",
    depthMM: 179,
    heightMM: 1302,
    widthMM: 1785,
    volumeL: 108.42,
    nominalDeltaT50KW: 7.02,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 3099,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1202-40",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1200mm",
    depthMM: 179,
    heightMM: 1302,
    widthMM: 1830,
    volumeL: 111.2,
    nominalDeltaT50KW: 7.2,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 3175,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1502-04",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1500mm",
    depthMM: 179,
    heightMM: 1602,
    widthMM: 210,
    volumeL: 13.6,
    nominalDeltaT50KW: 0.884,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 525,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1502-05",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1500mm",
    depthMM: 179,
    heightMM: 1602,
    widthMM: 255,
    volumeL: 17,
    nominalDeltaT50KW: 1.105,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 628,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1502-06",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1500mm",
    depthMM: 179,
    heightMM: 1602,
    widthMM: 300,
    volumeL: 20.4,
    nominalDeltaT50KW: 1.325,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 730,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1502-07",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1500mm",
    depthMM: 179,
    heightMM: 1602,
    widthMM: 345,
    volumeL: 23.8,
    nominalDeltaT50KW: 1.546,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 833,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1502-08",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1500mm",
    depthMM: 179,
    heightMM: 1602,
    widthMM: 390,
    volumeL: 27.2,
    nominalDeltaT50KW: 1.767,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 935,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1502-09",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1500mm",
    depthMM: 179,
    heightMM: 1602,
    widthMM: 435,
    volumeL: 30.6,
    nominalDeltaT50KW: 1.988,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1038,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1502-10",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1500mm",
    depthMM: 179,
    heightMM: 1602,
    widthMM: 480,
    volumeL: 34,
    nominalDeltaT50KW: 2.209,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1140,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1502-11",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1500mm",
    depthMM: 179,
    heightMM: 1602,
    widthMM: 525,
    volumeL: 37.4,
    nominalDeltaT50KW: 2.43,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1243,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1502-12",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1500mm",
    depthMM: 179,
    heightMM: 1602,
    widthMM: 570,
    volumeL: 40.8,
    nominalDeltaT50KW: 2.651,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1345,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1502-13",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1500mm",
    depthMM: 179,
    heightMM: 1602,
    widthMM: 615,
    volumeL: 44.2,
    nominalDeltaT50KW: 2.872,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1448,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1502-14",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1500mm",
    depthMM: 179,
    heightMM: 1602,
    widthMM: 660,
    volumeL: 47.6,
    nominalDeltaT50KW: 3.093,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1550,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1502-15",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1500mm",
    depthMM: 179,
    heightMM: 1602,
    widthMM: 705,
    volumeL: 51,
    nominalDeltaT50KW: 3.314,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1653,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1502-16",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1500mm",
    depthMM: 179,
    heightMM: 1602,
    widthMM: 750,
    volumeL: 54.4,
    nominalDeltaT50KW: 3.534,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1755,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1502-17",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1500mm",
    depthMM: 179,
    heightMM: 1602,
    widthMM: 795,
    volumeL: 57.8,
    nominalDeltaT50KW: 3.755,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1858,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1502-18",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1500mm",
    depthMM: 179,
    heightMM: 1602,
    widthMM: 840,
    volumeL: 61.2,
    nominalDeltaT50KW: 3.976,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1960,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1502-19",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1500mm",
    depthMM: 179,
    heightMM: 1602,
    widthMM: 885,
    volumeL: 64.6,
    nominalDeltaT50KW: 4.197,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 2063,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1502-20",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1500mm",
    depthMM: 179,
    heightMM: 1602,
    widthMM: 930,
    volumeL: 68,
    nominalDeltaT50KW: 4.418,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 2165,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1502-21",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1500mm",
    depthMM: 179,
    heightMM: 1602,
    widthMM: 975,
    volumeL: 71.4,
    nominalDeltaT50KW: 4.639,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 2268,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1502-22",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1500mm",
    depthMM: 179,
    heightMM: 1602,
    widthMM: 1020,
    volumeL: 74.8,
    nominalDeltaT50KW: 4.86,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 2370,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1502-23",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1500mm",
    depthMM: 179,
    heightMM: 1602,
    widthMM: 1065,
    volumeL: 78.2,
    nominalDeltaT50KW: 5.081,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 2473,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1502-24",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1500mm",
    depthMM: 179,
    heightMM: 1602,
    widthMM: 1110,
    volumeL: 81.6,
    nominalDeltaT50KW: 5.302,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 2575,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1502-25",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1500mm",
    depthMM: 179,
    heightMM: 1602,
    widthMM: 1155,
    volumeL: 85,
    nominalDeltaT50KW: 5.523,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 2678,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1502-26",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1500mm",
    depthMM: 179,
    heightMM: 1602,
    widthMM: 1200,
    volumeL: 88.4,
    nominalDeltaT50KW: 5.743,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 2780,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1502-27",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1500mm",
    depthMM: 179,
    heightMM: 1602,
    widthMM: 1245,
    volumeL: 91.8,
    nominalDeltaT50KW: 5.964,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 2883,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1502-28",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1500mm",
    depthMM: 179,
    heightMM: 1602,
    widthMM: 1290,
    volumeL: 95.2,
    nominalDeltaT50KW: 6.185,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 2985,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1502-29",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1500mm",
    depthMM: 179,
    heightMM: 1602,
    widthMM: 1335,
    volumeL: 98.6,
    nominalDeltaT50KW: 6.406,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 3088,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1502-30",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1500mm",
    depthMM: 179,
    heightMM: 1602,
    widthMM: 1380,
    volumeL: 102,
    nominalDeltaT50KW: 6.627,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 3190,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1502-31",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1500mm",
    depthMM: 179,
    heightMM: 1602,
    widthMM: 1425,
    volumeL: 105.4,
    nominalDeltaT50KW: 6.848,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 3293,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1502-32",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1500mm",
    depthMM: 179,
    heightMM: 1602,
    widthMM: 1470,
    volumeL: 108.8,
    nominalDeltaT50KW: 7.069,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 3395,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1502-33",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1500mm",
    depthMM: 179,
    heightMM: 1602,
    widthMM: 1515,
    volumeL: 112.2,
    nominalDeltaT50KW: 7.29,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 3498,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1502-34",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1500mm",
    depthMM: 179,
    heightMM: 1602,
    widthMM: 1560,
    volumeL: 115.6,
    nominalDeltaT50KW: 7.511,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 3600,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1502-35",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1500mm",
    depthMM: 179,
    heightMM: 1602,
    widthMM: 1605,
    volumeL: 119,
    nominalDeltaT50KW: 7.732,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 3703,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1502-36",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1500mm",
    depthMM: 179,
    heightMM: 1602,
    widthMM: 1650,
    volumeL: 122.4,
    nominalDeltaT50KW: 7.952,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 3805,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1502-37",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1500mm",
    depthMM: 179,
    heightMM: 1602,
    widthMM: 1695,
    volumeL: 125.8,
    nominalDeltaT50KW: 8.173,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 3908,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1502-38",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1500mm",
    depthMM: 179,
    heightMM: 1602,
    widthMM: 1740,
    volumeL: 129.2,
    nominalDeltaT50KW: 8.394,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 4010,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1502-39",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1500mm",
    depthMM: 179,
    heightMM: 1602,
    widthMM: 1785,
    volumeL: 132.6,
    nominalDeltaT50KW: 8.615,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 4113,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1502-40",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1500mm",
    depthMM: 179,
    heightMM: 1602,
    widthMM: 1830,
    volumeL: 136,
    nominalDeltaT50KW: 8.836,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 4215,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1802-04",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1800mm",
    depthMM: 179,
    heightMM: 1902,
    widthMM: 210,
    volumeL: 16.04,
    nominalDeltaT50KW: 1.045,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 565,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1802-05",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1800mm",
    depthMM: 179,
    heightMM: 1902,
    widthMM: 255,
    volumeL: 20.05,
    nominalDeltaT50KW: 1.307,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 678,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1802-06",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1800mm",
    depthMM: 179,
    heightMM: 1902,
    widthMM: 300,
    volumeL: 24.06,
    nominalDeltaT50KW: 1.568,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 790,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1802-07",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1800mm",
    depthMM: 179,
    heightMM: 1902,
    widthMM: 345,
    volumeL: 28.07,
    nominalDeltaT50KW: 1.829,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 903,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1802-08",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1800mm",
    depthMM: 179,
    heightMM: 1902,
    widthMM: 390,
    volumeL: 32.08,
    nominalDeltaT50KW: 2.09,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1015,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1802-09",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1800mm",
    depthMM: 179,
    heightMM: 1902,
    widthMM: 435,
    volumeL: 36.09,
    nominalDeltaT50KW: 2.352,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1128,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1802-10",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1800mm",
    depthMM: 179,
    heightMM: 1902,
    widthMM: 480,
    volumeL: 40.1,
    nominalDeltaT50KW: 2.613,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1240,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1802-11",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1800mm",
    depthMM: 179,
    heightMM: 1902,
    widthMM: 525,
    volumeL: 44.11,
    nominalDeltaT50KW: 2.874,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1353,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1802-12",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1800mm",
    depthMM: 179,
    heightMM: 1902,
    widthMM: 570,
    volumeL: 48.12,
    nominalDeltaT50KW: 3.136,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1465,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1802-13",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1800mm",
    depthMM: 179,
    heightMM: 1902,
    widthMM: 615,
    volumeL: 52.13,
    nominalDeltaT50KW: 3.397,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1578,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1802-14",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1800mm",
    depthMM: 179,
    heightMM: 1902,
    widthMM: 660,
    volumeL: 56.14,
    nominalDeltaT50KW: 3.658,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1690,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1802-15",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1800mm",
    depthMM: 179,
    heightMM: 1902,
    widthMM: 705,
    volumeL: 60.15,
    nominalDeltaT50KW: 3.92,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1803,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1802-16",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1800mm",
    depthMM: 179,
    heightMM: 1902,
    widthMM: 750,
    volumeL: 64.16,
    nominalDeltaT50KW: 4.181,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1915,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1802-17",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1800mm",
    depthMM: 179,
    heightMM: 1902,
    widthMM: 795,
    volumeL: 68.17,
    nominalDeltaT50KW: 4.442,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2028,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1802-18",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1800mm",
    depthMM: 179,
    heightMM: 1902,
    widthMM: 840,
    volumeL: 72.18,
    nominalDeltaT50KW: 4.703,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2140,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1802-19",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1800mm",
    depthMM: 179,
    heightMM: 1902,
    widthMM: 885,
    volumeL: 76.19,
    nominalDeltaT50KW: 4.965,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2253,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1802-20",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1800mm",
    depthMM: 179,
    heightMM: 1902,
    widthMM: 930,
    volumeL: 80.2,
    nominalDeltaT50KW: 5.226,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2365,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1802-21",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1800mm",
    depthMM: 179,
    heightMM: 1902,
    widthMM: 975,
    volumeL: 84.21,
    nominalDeltaT50KW: 5.487,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2478,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1802-22",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1800mm",
    depthMM: 179,
    heightMM: 1902,
    widthMM: 1020,
    volumeL: 88.22,
    nominalDeltaT50KW: 5.749,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2590,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1802-23",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1800mm",
    depthMM: 179,
    heightMM: 1902,
    widthMM: 1065,
    volumeL: 92.23,
    nominalDeltaT50KW: 6.01,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2703,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1802-24",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1800mm",
    depthMM: 179,
    heightMM: 1902,
    widthMM: 1110,
    volumeL: 96.24,
    nominalDeltaT50KW: 6.271,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2815,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1802-25",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1800mm",
    depthMM: 179,
    heightMM: 1902,
    widthMM: 1155,
    volumeL: 100.25,
    nominalDeltaT50KW: 6.533,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2928,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1802-26",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1800mm",
    depthMM: 179,
    heightMM: 1902,
    widthMM: 1200,
    volumeL: 104.26,
    nominalDeltaT50KW: 6.794,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 3040,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1802-27",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1800mm",
    depthMM: 179,
    heightMM: 1902,
    widthMM: 1245,
    volumeL: 108.27,
    nominalDeltaT50KW: 7.055,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 3153,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1802-28",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1800mm",
    depthMM: 179,
    heightMM: 1902,
    widthMM: 1290,
    volumeL: 112.28,
    nominalDeltaT50KW: 7.316,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 3265,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1802-29",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1800mm",
    depthMM: 179,
    heightMM: 1902,
    widthMM: 1335,
    volumeL: 116.29,
    nominalDeltaT50KW: 7.578,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 3378,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1802-30",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1800mm",
    depthMM: 179,
    heightMM: 1902,
    widthMM: 1380,
    volumeL: 120.3,
    nominalDeltaT50KW: 7.839,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 3490,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1802-31",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1800mm",
    depthMM: 179,
    heightMM: 1902,
    widthMM: 1425,
    volumeL: 124.31,
    nominalDeltaT50KW: 8.1,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 3603,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1802-32",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1800mm",
    depthMM: 179,
    heightMM: 1902,
    widthMM: 1470,
    volumeL: 128.32,
    nominalDeltaT50KW: 8.362,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 3715,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1802-33",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1800mm",
    depthMM: 179,
    heightMM: 1902,
    widthMM: 1515,
    volumeL: 132.33,
    nominalDeltaT50KW: 8.623,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 3828,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1802-34",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1800mm",
    depthMM: 179,
    heightMM: 1902,
    widthMM: 1560,
    volumeL: 136.34,
    nominalDeltaT50KW: 8.884,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 3940,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1802-35",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1800mm",
    depthMM: 179,
    heightMM: 1902,
    widthMM: 1605,
    volumeL: 140.35,
    nominalDeltaT50KW: 9.146,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 4053,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1802-36",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1800mm",
    depthMM: 179,
    heightMM: 1902,
    widthMM: 1650,
    volumeL: 144.36,
    nominalDeltaT50KW: 9.407,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 4165,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1802-37",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1800mm",
    depthMM: 179,
    heightMM: 1902,
    widthMM: 1695,
    volumeL: 148.37,
    nominalDeltaT50KW: 9.668,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 4278,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1802-38",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1800mm",
    depthMM: 179,
    heightMM: 1902,
    widthMM: 1740,
    volumeL: 152.38,
    nominalDeltaT50KW: 9.929,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 4390,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1802-39",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1800mm",
    depthMM: 179,
    heightMM: 1902,
    widthMM: 1785,
    volumeL: 156.39,
    nominalDeltaT50KW: 10.191,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 4503,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-1802-40",
    rangeType: "Florence 5",
    model: "Florence 5 Column 1800mm",
    depthMM: 179,
    heightMM: 1902,
    widthMM: 1830,
    volumeL: 160.4,
    nominalDeltaT50KW: 10.452,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 4615,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2002-04",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2000mm",
    depthMM: 179,
    heightMM: 2102,
    widthMM: 210,
    volumeL: 17.68,
    nominalDeltaT50KW: 1.152,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 605,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2002-05",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2000mm",
    depthMM: 179,
    heightMM: 2102,
    widthMM: 255,
    volumeL: 22.1,
    nominalDeltaT50KW: 1.44,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 728,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2002-06",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2000mm",
    depthMM: 179,
    heightMM: 2102,
    widthMM: 300,
    volumeL: 26.52,
    nominalDeltaT50KW: 1.728,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 850,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2002-07",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2000mm",
    depthMM: 179,
    heightMM: 2102,
    widthMM: 345,
    volumeL: 30.94,
    nominalDeltaT50KW: 2.016,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 973,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2002-08",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2000mm",
    depthMM: 179,
    heightMM: 2102,
    widthMM: 390,
    volumeL: 35.36,
    nominalDeltaT50KW: 2.304,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1095,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2002-09",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2000mm",
    depthMM: 179,
    heightMM: 2102,
    widthMM: 435,
    volumeL: 39.78,
    nominalDeltaT50KW: 2.592,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1218,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2002-10",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2000mm",
    depthMM: 179,
    heightMM: 2102,
    widthMM: 480,
    volumeL: 44.2,
    nominalDeltaT50KW: 2.88,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1340,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2002-11",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2000mm",
    depthMM: 179,
    heightMM: 2102,
    widthMM: 525,
    volumeL: 48.62,
    nominalDeltaT50KW: 3.168,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1463,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2002-12",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2000mm",
    depthMM: 179,
    heightMM: 2102,
    widthMM: 570,
    volumeL: 53.04,
    nominalDeltaT50KW: 3.456,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1585,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2002-13",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2000mm",
    depthMM: 179,
    heightMM: 2102,
    widthMM: 615,
    volumeL: 57.46,
    nominalDeltaT50KW: 3.744,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1708,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2002-14",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2000mm",
    depthMM: 179,
    heightMM: 2102,
    widthMM: 660,
    volumeL: 61.88,
    nominalDeltaT50KW: 4.032,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1830,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2002-15",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2000mm",
    depthMM: 179,
    heightMM: 2102,
    widthMM: 705,
    volumeL: 66.3,
    nominalDeltaT50KW: 4.32,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1953,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2002-16",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2000mm",
    depthMM: 179,
    heightMM: 2102,
    widthMM: 750,
    volumeL: 70.72,
    nominalDeltaT50KW: 4.608,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2075,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2002-17",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2000mm",
    depthMM: 179,
    heightMM: 2102,
    widthMM: 795,
    volumeL: 75.14,
    nominalDeltaT50KW: 4.896,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2198,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2002-18",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2000mm",
    depthMM: 179,
    heightMM: 2102,
    widthMM: 840,
    volumeL: 79.56,
    nominalDeltaT50KW: 5.184,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2320,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2002-19",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2000mm",
    depthMM: 179,
    heightMM: 2102,
    widthMM: 885,
    volumeL: 83.98,
    nominalDeltaT50KW: 5.472,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2443,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2002-20",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2000mm",
    depthMM: 179,
    heightMM: 2102,
    widthMM: 930,
    volumeL: 88.4,
    nominalDeltaT50KW: 5.76,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2565,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2002-21",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2000mm",
    depthMM: 179,
    heightMM: 2102,
    widthMM: 975,
    volumeL: 92.82,
    nominalDeltaT50KW: 6.048,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2688,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2002-22",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2000mm",
    depthMM: 179,
    heightMM: 2102,
    widthMM: 1020,
    volumeL: 97.24,
    nominalDeltaT50KW: 6.336,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2810,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2002-23",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2000mm",
    depthMM: 179,
    heightMM: 2102,
    widthMM: 1065,
    volumeL: 101.66,
    nominalDeltaT50KW: 6.624,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2933,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2002-24",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2000mm",
    depthMM: 179,
    heightMM: 2102,
    widthMM: 1110,
    volumeL: 106.08,
    nominalDeltaT50KW: 6.912,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 3055,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2002-25",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2000mm",
    depthMM: 179,
    heightMM: 2102,
    widthMM: 1155,
    volumeL: 110.5,
    nominalDeltaT50KW: 7.2,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 3178,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2002-26",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2000mm",
    depthMM: 179,
    heightMM: 2102,
    widthMM: 1200,
    volumeL: 114.92,
    nominalDeltaT50KW: 7.488,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 3300,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2002-27",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2000mm",
    depthMM: 179,
    heightMM: 2102,
    widthMM: 1245,
    volumeL: 119.34,
    nominalDeltaT50KW: 7.776,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 3423,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2002-28",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2000mm",
    depthMM: 179,
    heightMM: 2102,
    widthMM: 1290,
    volumeL: 123.76,
    nominalDeltaT50KW: 8.064,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 3545,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2002-29",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2000mm",
    depthMM: 179,
    heightMM: 2102,
    widthMM: 1335,
    volumeL: 128.18,
    nominalDeltaT50KW: 8.352,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 3668,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2002-30",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2000mm",
    depthMM: 179,
    heightMM: 2102,
    widthMM: 1380,
    volumeL: 132.6,
    nominalDeltaT50KW: 8.64,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 3790,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2002-31",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2000mm",
    depthMM: 179,
    heightMM: 2102,
    widthMM: 1425,
    volumeL: 137.02,
    nominalDeltaT50KW: 8.928,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 3913,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2002-32",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2000mm",
    depthMM: 179,
    heightMM: 2102,
    widthMM: 1470,
    volumeL: 141.44,
    nominalDeltaT50KW: 9.216,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 4035,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2002-33",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2000mm",
    depthMM: 179,
    heightMM: 2102,
    widthMM: 1515,
    volumeL: 145.86,
    nominalDeltaT50KW: 9.504,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 4158,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2002-34",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2000mm",
    depthMM: 179,
    heightMM: 2102,
    widthMM: 1560,
    volumeL: 150.28,
    nominalDeltaT50KW: 9.792,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 4280,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2002-35",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2000mm",
    depthMM: 179,
    heightMM: 2102,
    widthMM: 1605,
    volumeL: 154.7,
    nominalDeltaT50KW: 10.08,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 4403,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2002-36",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2000mm",
    depthMM: 179,
    heightMM: 2102,
    widthMM: 1650,
    volumeL: 159.12,
    nominalDeltaT50KW: 10.368,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 4525,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2002-37",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2000mm",
    depthMM: 179,
    heightMM: 2102,
    widthMM: 1695,
    volumeL: 163.54,
    nominalDeltaT50KW: 10.656,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 4648,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2002-38",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2000mm",
    depthMM: 179,
    heightMM: 2102,
    widthMM: 1740,
    volumeL: 167.96,
    nominalDeltaT50KW: 10.944,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 4770,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2002-39",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2000mm",
    depthMM: 179,
    heightMM: 2102,
    widthMM: 1785,
    volumeL: 172.38,
    nominalDeltaT50KW: 11.232,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 4893,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2002-40",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2000mm",
    depthMM: 179,
    heightMM: 2102,
    widthMM: 1830,
    volumeL: 176.8,
    nominalDeltaT50KW: 11.52,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 5015,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-194-04",
    rangeType: "Florence 5",
    model: "Florence 5 Column 200mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 210,
    volumeL: 2.92,
    nominalDeltaT50KW: 0.127,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 269,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-194-05",
    rangeType: "Florence 5",
    model: "Florence 5 Column 200mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 255,
    volumeL: 3.65,
    nominalDeltaT50KW: 0.159,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 308,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-194-06",
    rangeType: "Florence 5",
    model: "Florence 5 Column 200mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 300,
    volumeL: 4.38,
    nominalDeltaT50KW: 0.19,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 346,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-194-07",
    rangeType: "Florence 5",
    model: "Florence 5 Column 200mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 345,
    volumeL: 5.11,
    nominalDeltaT50KW: 0.222,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 385,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-194-08",
    rangeType: "Florence 5",
    model: "Florence 5 Column 200mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 390,
    volumeL: 5.84,
    nominalDeltaT50KW: 0.254,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 423,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-194-09",
    rangeType: "Florence 5",
    model: "Florence 5 Column 200mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 435,
    volumeL: 6.57,
    nominalDeltaT50KW: 0.285,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 462,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-194-10",
    rangeType: "Florence 5",
    model: "Florence 5 Column 200mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 480,
    volumeL: 7.3,
    nominalDeltaT50KW: 0.317,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 500,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-194-11",
    rangeType: "Florence 5",
    model: "Florence 5 Column 200mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 525,
    volumeL: 8.03,
    nominalDeltaT50KW: 0.349,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 539,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-194-12",
    rangeType: "Florence 5",
    model: "Florence 5 Column 200mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 570,
    volumeL: 8.76,
    nominalDeltaT50KW: 0.38,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 577,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-194-13",
    rangeType: "Florence 5",
    model: "Florence 5 Column 200mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 615,
    volumeL: 9.49,
    nominalDeltaT50KW: 0.412,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 616,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-194-14",
    rangeType: "Florence 5",
    model: "Florence 5 Column 200mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 660,
    volumeL: 10.22,
    nominalDeltaT50KW: 0.444,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 654,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-194-15",
    rangeType: "Florence 5",
    model: "Florence 5 Column 200mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 705,
    volumeL: 10.95,
    nominalDeltaT50KW: 0.476,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 693,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-194-16",
    rangeType: "Florence 5",
    model: "Florence 5 Column 200mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 750,
    volumeL: 11.68,
    nominalDeltaT50KW: 0.507,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 731,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-194-17",
    rangeType: "Florence 5",
    model: "Florence 5 Column 200mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 795,
    volumeL: 12.41,
    nominalDeltaT50KW: 0.539,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 770,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-194-18",
    rangeType: "Florence 5",
    model: "Florence 5 Column 200mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 840,
    volumeL: 13.14,
    nominalDeltaT50KW: 0.571,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 808,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-194-19",
    rangeType: "Florence 5",
    model: "Florence 5 Column 200mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 885,
    volumeL: 13.87,
    nominalDeltaT50KW: 0.602,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 847,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-194-20",
    rangeType: "Florence 5",
    model: "Florence 5 Column 200mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 930,
    volumeL: 14.6,
    nominalDeltaT50KW: 0.634,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 885,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-194-21",
    rangeType: "Florence 5",
    model: "Florence 5 Column 200mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 975,
    volumeL: 15.33,
    nominalDeltaT50KW: 0.666,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 924,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-194-22",
    rangeType: "Florence 5",
    model: "Florence 5 Column 200mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 1020,
    volumeL: 16.06,
    nominalDeltaT50KW: 0.697,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 962,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-194-23",
    rangeType: "Florence 5",
    model: "Florence 5 Column 200mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 1065,
    volumeL: 16.79,
    nominalDeltaT50KW: 0.729,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1001,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-194-24",
    rangeType: "Florence 5",
    model: "Florence 5 Column 200mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 1110,
    volumeL: 17.52,
    nominalDeltaT50KW: 0.761,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1039,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-194-25",
    rangeType: "Florence 5",
    model: "Florence 5 Column 200mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 1155,
    volumeL: 18.25,
    nominalDeltaT50KW: 0.793,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1078,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-194-26",
    rangeType: "Florence 5",
    model: "Florence 5 Column 200mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 1200,
    volumeL: 18.98,
    nominalDeltaT50KW: 0.824,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1116,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-194-27",
    rangeType: "Florence 5",
    model: "Florence 5 Column 200mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 1245,
    volumeL: 19.71,
    nominalDeltaT50KW: 0.856,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1155,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-194-28",
    rangeType: "Florence 5",
    model: "Florence 5 Column 200mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 1290,
    volumeL: 20.44,
    nominalDeltaT50KW: 0.888,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1193,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-194-29",
    rangeType: "Florence 5",
    model: "Florence 5 Column 200mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 1335,
    volumeL: 21.17,
    nominalDeltaT50KW: 0.919,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1232,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-194-30",
    rangeType: "Florence 5",
    model: "Florence 5 Column 200mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 1380,
    volumeL: 21.9,
    nominalDeltaT50KW: 0.951,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1270,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-194-31",
    rangeType: "Florence 5",
    model: "Florence 5 Column 200mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 1425,
    volumeL: 22.63,
    nominalDeltaT50KW: 0.983,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1309,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-194-32",
    rangeType: "Florence 5",
    model: "Florence 5 Column 200mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 1470,
    volumeL: 23.36,
    nominalDeltaT50KW: 1.014,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1347,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-194-33",
    rangeType: "Florence 5",
    model: "Florence 5 Column 200mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 1515,
    volumeL: 24.09,
    nominalDeltaT50KW: 1.046,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1386,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-194-34",
    rangeType: "Florence 5",
    model: "Florence 5 Column 200mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 1560,
    volumeL: 24.82,
    nominalDeltaT50KW: 1.078,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1424,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-194-35",
    rangeType: "Florence 5",
    model: "Florence 5 Column 200mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 1605,
    volumeL: 25.55,
    nominalDeltaT50KW: 1.11,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1463,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-194-36",
    rangeType: "Florence 5",
    model: "Florence 5 Column 200mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 1650,
    volumeL: 26.28,
    nominalDeltaT50KW: 1.141,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1501,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-194-37",
    rangeType: "Florence 5",
    model: "Florence 5 Column 200mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 1695,
    volumeL: 27.01,
    nominalDeltaT50KW: 1.173,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1540,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-194-38",
    rangeType: "Florence 5",
    model: "Florence 5 Column 200mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 1740,
    volumeL: 27.74,
    nominalDeltaT50KW: 1.205,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1578,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-194-39",
    rangeType: "Florence 5",
    model: "Florence 5 Column 200mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 1785,
    volumeL: 28.47,
    nominalDeltaT50KW: 1.236,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1617,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-194-40",
    rangeType: "Florence 5",
    model: "Florence 5 Column 200mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 1830,
    volumeL: 29.2,
    nominalDeltaT50KW: 1.268,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1655,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2202-04",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2200mm",
    depthMM: 179,
    heightMM: 2302,
    widthMM: 210,
    volumeL: 19.28,
    nominalDeltaT50KW: 1.259,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 717,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2202-05",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2200mm",
    depthMM: 179,
    heightMM: 2302,
    widthMM: 255,
    volumeL: 24.1,
    nominalDeltaT50KW: 1.574,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 868,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2202-06",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2200mm",
    depthMM: 179,
    heightMM: 2302,
    widthMM: 300,
    volumeL: 28.92,
    nominalDeltaT50KW: 1.888,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1018,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2202-07",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2200mm",
    depthMM: 179,
    heightMM: 2302,
    widthMM: 345,
    volumeL: 33.74,
    nominalDeltaT50KW: 2.203,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1169,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2202-08",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2200mm",
    depthMM: 179,
    heightMM: 2302,
    widthMM: 390,
    volumeL: 38.56,
    nominalDeltaT50KW: 2.518,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1319,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2202-09",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2200mm",
    depthMM: 179,
    heightMM: 2302,
    widthMM: 435,
    volumeL: 43.38,
    nominalDeltaT50KW: 2.832,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1470,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2202-10",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2200mm",
    depthMM: 179,
    heightMM: 2302,
    widthMM: 480,
    volumeL: 48.2,
    nominalDeltaT50KW: 3.147,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1620,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2202-11",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2200mm",
    depthMM: 179,
    heightMM: 2302,
    widthMM: 525,
    volumeL: 53.02,
    nominalDeltaT50KW: 3.462,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1771,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2202-12",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2200mm",
    depthMM: 179,
    heightMM: 2302,
    widthMM: 570,
    volumeL: 57.84,
    nominalDeltaT50KW: 3.776,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1921,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2202-13",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2200mm",
    depthMM: 179,
    heightMM: 2302,
    widthMM: 615,
    volumeL: 62.66,
    nominalDeltaT50KW: 4.091,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2072,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2202-14",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2200mm",
    depthMM: 179,
    heightMM: 2302,
    widthMM: 660,
    volumeL: 67.48,
    nominalDeltaT50KW: 4.406,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2222,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2202-15",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2200mm",
    depthMM: 179,
    heightMM: 2302,
    widthMM: 705,
    volumeL: 72.3,
    nominalDeltaT50KW: 4.721,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2373,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2202-16",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2200mm",
    depthMM: 179,
    heightMM: 2302,
    widthMM: 750,
    volumeL: 77.12,
    nominalDeltaT50KW: 5.035,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2523,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2202-17",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2200mm",
    depthMM: 179,
    heightMM: 2302,
    widthMM: 795,
    volumeL: 81.94,
    nominalDeltaT50KW: 5.35,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2674,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2202-18",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2200mm",
    depthMM: 179,
    heightMM: 2302,
    widthMM: 840,
    volumeL: 86.76,
    nominalDeltaT50KW: 5.665,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2824,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2202-19",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2200mm",
    depthMM: 179,
    heightMM: 2302,
    widthMM: 885,
    volumeL: 91.58,
    nominalDeltaT50KW: 5.979,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2975,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2202-20",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2200mm",
    depthMM: 179,
    heightMM: 2302,
    widthMM: 930,
    volumeL: 96.4,
    nominalDeltaT50KW: 6.294,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 3125,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2202-21",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2200mm",
    depthMM: 179,
    heightMM: 2302,
    widthMM: 975,
    volumeL: 101.22,
    nominalDeltaT50KW: 6.609,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 3276,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2202-22",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2200mm",
    depthMM: 179,
    heightMM: 2302,
    widthMM: 1020,
    volumeL: 106.04,
    nominalDeltaT50KW: 6.923,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 3426,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2202-23",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2200mm",
    depthMM: 179,
    heightMM: 2302,
    widthMM: 1065,
    volumeL: 110.86,
    nominalDeltaT50KW: 7.238,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 3577,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2202-24",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2200mm",
    depthMM: 179,
    heightMM: 2302,
    widthMM: 1110,
    volumeL: 115.68,
    nominalDeltaT50KW: 7.553,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 3727,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2202-25",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2200mm",
    depthMM: 179,
    heightMM: 2302,
    widthMM: 1155,
    volumeL: 120.5,
    nominalDeltaT50KW: 7.868,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 3878,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2202-26",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2200mm",
    depthMM: 179,
    heightMM: 2302,
    widthMM: 1200,
    volumeL: 125.32,
    nominalDeltaT50KW: 8.182,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 4028,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2202-27",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2200mm",
    depthMM: 179,
    heightMM: 2302,
    widthMM: 1245,
    volumeL: 130.14,
    nominalDeltaT50KW: 8.497,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 4179,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2202-28",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2200mm",
    depthMM: 179,
    heightMM: 2302,
    widthMM: 1290,
    volumeL: 134.96,
    nominalDeltaT50KW: 8.812,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 4329,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2202-29",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2200mm",
    depthMM: 179,
    heightMM: 2302,
    widthMM: 1335,
    volumeL: 139.78,
    nominalDeltaT50KW: 9.126,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 4480,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2202-30",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2200mm",
    depthMM: 179,
    heightMM: 2302,
    widthMM: 1380,
    volumeL: 144.6,
    nominalDeltaT50KW: 9.441,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 4630,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2202-31",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2200mm",
    depthMM: 179,
    heightMM: 2302,
    widthMM: 1425,
    volumeL: 149.42,
    nominalDeltaT50KW: 9.756,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 4781,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2202-32",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2200mm",
    depthMM: 179,
    heightMM: 2302,
    widthMM: 1470,
    volumeL: 154.24,
    nominalDeltaT50KW: 10.07,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 4931,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2202-33",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2200mm",
    depthMM: 179,
    heightMM: 2302,
    widthMM: 1515,
    volumeL: 159.06,
    nominalDeltaT50KW: 10.385,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 5082,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2202-34",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2200mm",
    depthMM: 179,
    heightMM: 2302,
    widthMM: 1560,
    volumeL: 163.88,
    nominalDeltaT50KW: 10.7,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 5232,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2202-35",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2200mm",
    depthMM: 179,
    heightMM: 2302,
    widthMM: 1605,
    volumeL: 168.7,
    nominalDeltaT50KW: 11.015,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 5383,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2202-36",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2200mm",
    depthMM: 179,
    heightMM: 2302,
    widthMM: 1650,
    volumeL: 173.52,
    nominalDeltaT50KW: 11.329,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 5533,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2202-37",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2200mm",
    depthMM: 179,
    heightMM: 2302,
    widthMM: 1695,
    volumeL: 178.34,
    nominalDeltaT50KW: 11.644,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 5684,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2202-38",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2200mm",
    depthMM: 179,
    heightMM: 2302,
    widthMM: 1740,
    volumeL: 183.16,
    nominalDeltaT50KW: 11.959,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 5834,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2202-39",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2200mm",
    depthMM: 179,
    heightMM: 2302,
    widthMM: 1785,
    volumeL: 187.98,
    nominalDeltaT50KW: 12.273,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 5985,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2202-40",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2200mm",
    depthMM: 179,
    heightMM: 2302,
    widthMM: 1830,
    volumeL: 192.8,
    nominalDeltaT50KW: 12.588,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 6135,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2502-04",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2500mm",
    depthMM: 179,
    heightMM: 2602,
    widthMM: 210,
    volumeL: 21.76,
    nominalDeltaT50KW: 1.418,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 769,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2502-05",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2500mm",
    depthMM: 179,
    heightMM: 2602,
    widthMM: 255,
    volumeL: 27.2,
    nominalDeltaT50KW: 1.773,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 933,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2502-06",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2500mm",
    depthMM: 179,
    heightMM: 2602,
    widthMM: 300,
    volumeL: 32.64,
    nominalDeltaT50KW: 2.127,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1096,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2502-07",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2500mm",
    depthMM: 179,
    heightMM: 2602,
    widthMM: 345,
    volumeL: 38.08,
    nominalDeltaT50KW: 2.482,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1260,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2502-08",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2500mm",
    depthMM: 179,
    heightMM: 2602,
    widthMM: 390,
    volumeL: 43.52,
    nominalDeltaT50KW: 2.836,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1423,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2502-09",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2500mm",
    depthMM: 179,
    heightMM: 2602,
    widthMM: 435,
    volumeL: 48.96,
    nominalDeltaT50KW: 3.191,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1587,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2502-10",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2500mm",
    depthMM: 179,
    heightMM: 2602,
    widthMM: 480,
    volumeL: 54.4,
    nominalDeltaT50KW: 3.545,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1750,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2502-11",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2500mm",
    depthMM: 179,
    heightMM: 2602,
    widthMM: 525,
    volumeL: 59.84,
    nominalDeltaT50KW: 3.9,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1914,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2502-12",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2500mm",
    depthMM: 179,
    heightMM: 2602,
    widthMM: 570,
    volumeL: 65.28,
    nominalDeltaT50KW: 4.254,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 2077,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2502-13",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2500mm",
    depthMM: 179,
    heightMM: 2602,
    widthMM: 615,
    volumeL: 70.72,
    nominalDeltaT50KW: 4.609,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 2241,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2502-14",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2500mm",
    depthMM: 179,
    heightMM: 2602,
    widthMM: 660,
    volumeL: 76.16,
    nominalDeltaT50KW: 4.963,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 2404,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2502-15",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2500mm",
    depthMM: 179,
    heightMM: 2602,
    widthMM: 705,
    volumeL: 81.6,
    nominalDeltaT50KW: 5.318,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 2568,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2502-16",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2500mm",
    depthMM: 179,
    heightMM: 2602,
    widthMM: 750,
    volumeL: 87.04,
    nominalDeltaT50KW: 5.672,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 2731,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2502-17",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2500mm",
    depthMM: 179,
    heightMM: 2602,
    widthMM: 795,
    volumeL: 92.48,
    nominalDeltaT50KW: 6.027,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 2895,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2502-18",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2500mm",
    depthMM: 179,
    heightMM: 2602,
    widthMM: 840,
    volumeL: 97.92,
    nominalDeltaT50KW: 6.381,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 3058,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2502-19",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2500mm",
    depthMM: 179,
    heightMM: 2602,
    widthMM: 885,
    volumeL: 103.36,
    nominalDeltaT50KW: 6.736,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 3222,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2502-20",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2500mm",
    depthMM: 179,
    heightMM: 2602,
    widthMM: 930,
    volumeL: 108.8,
    nominalDeltaT50KW: 7.09,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 3385,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2502-21",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2500mm",
    depthMM: 179,
    heightMM: 2602,
    widthMM: 975,
    volumeL: 114.24,
    nominalDeltaT50KW: 7.445,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 3549,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2502-22",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2500mm",
    depthMM: 179,
    heightMM: 2602,
    widthMM: 1020,
    volumeL: 119.68,
    nominalDeltaT50KW: 7.799,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 3712,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2502-23",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2500mm",
    depthMM: 179,
    heightMM: 2602,
    widthMM: 1065,
    volumeL: 125.12,
    nominalDeltaT50KW: 8.154,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 3876,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2502-24",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2500mm",
    depthMM: 179,
    heightMM: 2602,
    widthMM: 1110,
    volumeL: 130.56,
    nominalDeltaT50KW: 8.508,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 4039,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2502-25",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2500mm",
    depthMM: 179,
    heightMM: 2602,
    widthMM: 1155,
    volumeL: 136,
    nominalDeltaT50KW: 8.863,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 4203,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2502-26",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2500mm",
    depthMM: 179,
    heightMM: 2602,
    widthMM: 1200,
    volumeL: 141.44,
    nominalDeltaT50KW: 9.217,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 4366,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2502-27",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2500mm",
    depthMM: 179,
    heightMM: 2602,
    widthMM: 1245,
    volumeL: 146.88,
    nominalDeltaT50KW: 9.572,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 4530,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2502-28",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2500mm",
    depthMM: 179,
    heightMM: 2602,
    widthMM: 1290,
    volumeL: 152.32,
    nominalDeltaT50KW: 9.926,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 4693,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2502-29",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2500mm",
    depthMM: 179,
    heightMM: 2602,
    widthMM: 1335,
    volumeL: 157.76,
    nominalDeltaT50KW: 10.281,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 4857,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2502-30",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2500mm",
    depthMM: 179,
    heightMM: 2602,
    widthMM: 1380,
    volumeL: 163.2,
    nominalDeltaT50KW: 10.635,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 5020,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2502-31",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2500mm",
    depthMM: 179,
    heightMM: 2602,
    widthMM: 1425,
    volumeL: 168.64,
    nominalDeltaT50KW: 10.99,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 5184,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2502-32",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2500mm",
    depthMM: 179,
    heightMM: 2602,
    widthMM: 1470,
    volumeL: 174.08,
    nominalDeltaT50KW: 11.344,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 5347,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2502-33",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2500mm",
    depthMM: 179,
    heightMM: 2602,
    widthMM: 1515,
    volumeL: 179.52,
    nominalDeltaT50KW: 11.699,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 5511,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2502-34",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2500mm",
    depthMM: 179,
    heightMM: 2602,
    widthMM: 1560,
    volumeL: 184.96,
    nominalDeltaT50KW: 12.053,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 5674,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2502-35",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2500mm",
    depthMM: 179,
    heightMM: 2602,
    widthMM: 1605,
    volumeL: 190.4,
    nominalDeltaT50KW: 12.408,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 5838,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2502-36",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2500mm",
    depthMM: 179,
    heightMM: 2602,
    widthMM: 1650,
    volumeL: 195.84,
    nominalDeltaT50KW: 12.762,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 6001,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2502-37",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2500mm",
    depthMM: 179,
    heightMM: 2602,
    widthMM: 1695,
    volumeL: 201.28,
    nominalDeltaT50KW: 13.117,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 6165,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2502-38",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2500mm",
    depthMM: 179,
    heightMM: 2602,
    widthMM: 1740,
    volumeL: 206.72,
    nominalDeltaT50KW: 13.471,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 6328,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2502-39",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2500mm",
    depthMM: 179,
    heightMM: 2602,
    widthMM: 1785,
    volumeL: 212.16,
    nominalDeltaT50KW: 13.826,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 6492,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-2502-40",
    rangeType: "Florence 5",
    model: "Florence 5 Column 2500mm",
    depthMM: 179,
    heightMM: 2602,
    widthMM: 1830,
    volumeL: 217.6,
    nominalDeltaT50KW: 14.18,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 6655,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-302-04",
    rangeType: "Florence 5",
    model: "Florence 5 Column 300mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 210,
    volumeL: 3.8,
    nominalDeltaT50KW: 0.206,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 273,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-302-05",
    rangeType: "Florence 5",
    model: "Florence 5 Column 300mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 255,
    volumeL: 4.75,
    nominalDeltaT50KW: 0.257,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 313,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-302-06",
    rangeType: "Florence 5",
    model: "Florence 5 Column 300mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 300,
    volumeL: 5.7,
    nominalDeltaT50KW: 0.308,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 352,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-302-07",
    rangeType: "Florence 5",
    model: "Florence 5 Column 300mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 345,
    volumeL: 6.65,
    nominalDeltaT50KW: 0.36,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 392,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-302-08",
    rangeType: "Florence 5",
    model: "Florence 5 Column 300mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 390,
    volumeL: 7.6,
    nominalDeltaT50KW: 0.411,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 431,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-302-09",
    rangeType: "Florence 5",
    model: "Florence 5 Column 300mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 435,
    volumeL: 8.55,
    nominalDeltaT50KW: 0.463,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 471,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-302-10",
    rangeType: "Florence 5",
    model: "Florence 5 Column 300mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 480,
    volumeL: 9.5,
    nominalDeltaT50KW: 0.514,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 510,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-302-11",
    rangeType: "Florence 5",
    model: "Florence 5 Column 300mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 525,
    volumeL: 10.45,
    nominalDeltaT50KW: 0.565,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 550,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-302-12",
    rangeType: "Florence 5",
    model: "Florence 5 Column 300mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 570,
    volumeL: 11.4,
    nominalDeltaT50KW: 0.617,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 589,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-302-13",
    rangeType: "Florence 5",
    model: "Florence 5 Column 300mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 615,
    volumeL: 12.35,
    nominalDeltaT50KW: 0.668,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 629,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-302-14",
    rangeType: "Florence 5",
    model: "Florence 5 Column 300mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 660,
    volumeL: 13.3,
    nominalDeltaT50KW: 0.72,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 668,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-302-15",
    rangeType: "Florence 5",
    model: "Florence 5 Column 300mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 705,
    volumeL: 14.25,
    nominalDeltaT50KW: 0.771,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 708,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-302-16",
    rangeType: "Florence 5",
    model: "Florence 5 Column 300mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 750,
    volumeL: 15.2,
    nominalDeltaT50KW: 0.822,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 747,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-302-17",
    rangeType: "Florence 5",
    model: "Florence 5 Column 300mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 795,
    volumeL: 16.15,
    nominalDeltaT50KW: 0.874,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 787,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-302-18",
    rangeType: "Florence 5",
    model: "Florence 5 Column 300mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 840,
    volumeL: 17.1,
    nominalDeltaT50KW: 0.925,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 826,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-302-19",
    rangeType: "Florence 5",
    model: "Florence 5 Column 300mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 885,
    volumeL: 18.05,
    nominalDeltaT50KW: 0.977,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 866,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-302-20",
    rangeType: "Florence 5",
    model: "Florence 5 Column 300mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 930,
    volumeL: 19,
    nominalDeltaT50KW: 1.028,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 905,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-302-21",
    rangeType: "Florence 5",
    model: "Florence 5 Column 300mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 975,
    volumeL: 19.95,
    nominalDeltaT50KW: 1.079,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 945,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-302-22",
    rangeType: "Florence 5",
    model: "Florence 5 Column 300mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 1020,
    volumeL: 20.9,
    nominalDeltaT50KW: 1.131,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 984,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-302-23",
    rangeType: "Florence 5",
    model: "Florence 5 Column 300mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 1065,
    volumeL: 21.85,
    nominalDeltaT50KW: 1.182,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 1024,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-302-24",
    rangeType: "Florence 5",
    model: "Florence 5 Column 300mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 1110,
    volumeL: 22.8,
    nominalDeltaT50KW: 1.234,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 1063,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-302-25",
    rangeType: "Florence 5",
    model: "Florence 5 Column 300mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 1155,
    volumeL: 23.75,
    nominalDeltaT50KW: 1.285,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 1103,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-302-26",
    rangeType: "Florence 5",
    model: "Florence 5 Column 300mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 1200,
    volumeL: 24.7,
    nominalDeltaT50KW: 1.336,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 1142,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-302-27",
    rangeType: "Florence 5",
    model: "Florence 5 Column 300mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 1245,
    volumeL: 25.65,
    nominalDeltaT50KW: 1.388,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 1182,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-302-28",
    rangeType: "Florence 5",
    model: "Florence 5 Column 300mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 1290,
    volumeL: 26.6,
    nominalDeltaT50KW: 1.439,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 1221,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-302-29",
    rangeType: "Florence 5",
    model: "Florence 5 Column 300mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 1335,
    volumeL: 27.55,
    nominalDeltaT50KW: 1.491,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 1261,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-302-30",
    rangeType: "Florence 5",
    model: "Florence 5 Column 300mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 1380,
    volumeL: 28.5,
    nominalDeltaT50KW: 1.542,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 1300,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-302-31",
    rangeType: "Florence 5",
    model: "Florence 5 Column 300mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 1425,
    volumeL: 29.45,
    nominalDeltaT50KW: 1.593,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 1340,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-302-32",
    rangeType: "Florence 5",
    model: "Florence 5 Column 300mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 1470,
    volumeL: 30.4,
    nominalDeltaT50KW: 1.645,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 1379,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-302-33",
    rangeType: "Florence 5",
    model: "Florence 5 Column 300mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 1515,
    volumeL: 31.35,
    nominalDeltaT50KW: 1.696,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 1419,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-302-34",
    rangeType: "Florence 5",
    model: "Florence 5 Column 300mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 1560,
    volumeL: 32.3,
    nominalDeltaT50KW: 1.748,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 1458,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-302-35",
    rangeType: "Florence 5",
    model: "Florence 5 Column 300mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 1605,
    volumeL: 33.25,
    nominalDeltaT50KW: 1.799,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 1498,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-302-36",
    rangeType: "Florence 5",
    model: "Florence 5 Column 300mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 1650,
    volumeL: 34.2,
    nominalDeltaT50KW: 1.85,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 1537,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-302-37",
    rangeType: "Florence 5",
    model: "Florence 5 Column 300mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 1695,
    volumeL: 35.15,
    nominalDeltaT50KW: 1.902,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 1577,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-302-38",
    rangeType: "Florence 5",
    model: "Florence 5 Column 300mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 1740,
    volumeL: 36.1,
    nominalDeltaT50KW: 1.953,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 1616,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-302-39",
    rangeType: "Florence 5",
    model: "Florence 5 Column 300mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 1785,
    volumeL: 37.05,
    nominalDeltaT50KW: 2.005,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 1656,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-302-40",
    rangeType: "Florence 5",
    model: "Florence 5 Column 300mm",
    depthMM: 179,
    heightMM: 294,
    widthMM: 1830,
    volumeL: 38,
    nominalDeltaT50KW: 2.056,
    kvValue: 20,
    nCoefficient: 1.28,
    price: {
      value: 1695,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-402-04",
    rangeType: "Florence 5",
    model: "Florence 5 Column 400mm",
    depthMM: 179,
    heightMM: 502,
    widthMM: 210,
    volumeL: 4.64,
    nominalDeltaT50KW: 0.266,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 281,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-402-05",
    rangeType: "Florence 5",
    model: "Florence 5 Column 400mm",
    depthMM: 179,
    heightMM: 502,
    widthMM: 255,
    volumeL: 5.8,
    nominalDeltaT50KW: 0.333,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 323,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-402-06",
    rangeType: "Florence 5",
    model: "Florence 5 Column 400mm",
    depthMM: 179,
    heightMM: 502,
    widthMM: 300,
    volumeL: 6.96,
    nominalDeltaT50KW: 0.399,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 364,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-402-07",
    rangeType: "Florence 5",
    model: "Florence 5 Column 400mm",
    depthMM: 179,
    heightMM: 502,
    widthMM: 345,
    volumeL: 8.12,
    nominalDeltaT50KW: 0.466,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 406,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-402-08",
    rangeType: "Florence 5",
    model: "Florence 5 Column 400mm",
    depthMM: 179,
    heightMM: 502,
    widthMM: 390,
    volumeL: 9.28,
    nominalDeltaT50KW: 0.532,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 447,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-402-09",
    rangeType: "Florence 5",
    model: "Florence 5 Column 400mm",
    depthMM: 179,
    heightMM: 502,
    widthMM: 435,
    volumeL: 10.44,
    nominalDeltaT50KW: 0.599,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 489,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-402-10",
    rangeType: "Florence 5",
    model: "Florence 5 Column 400mm",
    depthMM: 179,
    heightMM: 502,
    widthMM: 480,
    volumeL: 11.6,
    nominalDeltaT50KW: 0.665,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 530,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-402-11",
    rangeType: "Florence 5",
    model: "Florence 5 Column 400mm",
    depthMM: 179,
    heightMM: 502,
    widthMM: 525,
    volumeL: 12.76,
    nominalDeltaT50KW: 0.732,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 572,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-402-12",
    rangeType: "Florence 5",
    model: "Florence 5 Column 400mm",
    depthMM: 179,
    heightMM: 502,
    widthMM: 570,
    volumeL: 13.92,
    nominalDeltaT50KW: 0.798,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 613,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-402-13",
    rangeType: "Florence 5",
    model: "Florence 5 Column 400mm",
    depthMM: 179,
    heightMM: 502,
    widthMM: 615,
    volumeL: 15.08,
    nominalDeltaT50KW: 0.865,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 655,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-402-14",
    rangeType: "Florence 5",
    model: "Florence 5 Column 400mm",
    depthMM: 179,
    heightMM: 502,
    widthMM: 660,
    volumeL: 16.24,
    nominalDeltaT50KW: 0.931,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 696,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-402-15",
    rangeType: "Florence 5",
    model: "Florence 5 Column 400mm",
    depthMM: 179,
    heightMM: 502,
    widthMM: 705,
    volumeL: 17.4,
    nominalDeltaT50KW: 0.998,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 738,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-402-16",
    rangeType: "Florence 5",
    model: "Florence 5 Column 400mm",
    depthMM: 179,
    heightMM: 502,
    widthMM: 750,
    volumeL: 18.56,
    nominalDeltaT50KW: 1.064,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 779,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-402-17",
    rangeType: "Florence 5",
    model: "Florence 5 Column 400mm",
    depthMM: 179,
    heightMM: 502,
    widthMM: 795,
    volumeL: 19.72,
    nominalDeltaT50KW: 1.131,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 821,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-402-18",
    rangeType: "Florence 5",
    model: "Florence 5 Column 400mm",
    depthMM: 179,
    heightMM: 502,
    widthMM: 840,
    volumeL: 20.88,
    nominalDeltaT50KW: 1.197,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 862,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-402-19",
    rangeType: "Florence 5",
    model: "Florence 5 Column 400mm",
    depthMM: 179,
    heightMM: 502,
    widthMM: 885,
    volumeL: 22.04,
    nominalDeltaT50KW: 1.264,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 904,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-402-20",
    rangeType: "Florence 5",
    model: "Florence 5 Column 400mm",
    depthMM: 179,
    heightMM: 502,
    widthMM: 930,
    volumeL: 23.2,
    nominalDeltaT50KW: 1.33,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 945,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-402-21",
    rangeType: "Florence 5",
    model: "Florence 5 Column 400mm",
    depthMM: 179,
    heightMM: 502,
    widthMM: 975,
    volumeL: 24.36,
    nominalDeltaT50KW: 1.397,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 987,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-402-22",
    rangeType: "Florence 5",
    model: "Florence 5 Column 400mm",
    depthMM: 179,
    heightMM: 502,
    widthMM: 1020,
    volumeL: 25.52,
    nominalDeltaT50KW: 1.463,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1028,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-402-23",
    rangeType: "Florence 5",
    model: "Florence 5 Column 400mm",
    depthMM: 179,
    heightMM: 502,
    widthMM: 1065,
    volumeL: 26.68,
    nominalDeltaT50KW: 1.53,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1070,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-402-24",
    rangeType: "Florence 5",
    model: "Florence 5 Column 400mm",
    depthMM: 179,
    heightMM: 502,
    widthMM: 1110,
    volumeL: 27.84,
    nominalDeltaT50KW: 1.596,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1111,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-402-25",
    rangeType: "Florence 5",
    model: "Florence 5 Column 400mm",
    depthMM: 179,
    heightMM: 502,
    widthMM: 1155,
    volumeL: 29,
    nominalDeltaT50KW: 1.663,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1153,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-402-26",
    rangeType: "Florence 5",
    model: "Florence 5 Column 400mm",
    depthMM: 179,
    heightMM: 502,
    widthMM: 1200,
    volumeL: 30.16,
    nominalDeltaT50KW: 1.729,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1194,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-402-27",
    rangeType: "Florence 5",
    model: "Florence 5 Column 400mm",
    depthMM: 179,
    heightMM: 502,
    widthMM: 1245,
    volumeL: 31.32,
    nominalDeltaT50KW: 1.796,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1236,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-402-28",
    rangeType: "Florence 5",
    model: "Florence 5 Column 400mm",
    depthMM: 179,
    heightMM: 502,
    widthMM: 1290,
    volumeL: 32.48,
    nominalDeltaT50KW: 1.862,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1277,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-402-29",
    rangeType: "Florence 5",
    model: "Florence 5 Column 400mm",
    depthMM: 179,
    heightMM: 502,
    widthMM: 1335,
    volumeL: 33.64,
    nominalDeltaT50KW: 1.929,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1319,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-402-30",
    rangeType: "Florence 5",
    model: "Florence 5 Column 400mm",
    depthMM: 179,
    heightMM: 502,
    widthMM: 1380,
    volumeL: 34.8,
    nominalDeltaT50KW: 1.995,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1360,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-402-31",
    rangeType: "Florence 5",
    model: "Florence 5 Column 400mm",
    depthMM: 179,
    heightMM: 502,
    widthMM: 1425,
    volumeL: 35.96,
    nominalDeltaT50KW: 2.062,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1402,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-402-32",
    rangeType: "Florence 5",
    model: "Florence 5 Column 400mm",
    depthMM: 179,
    heightMM: 502,
    widthMM: 1470,
    volumeL: 37.12,
    nominalDeltaT50KW: 2.128,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1443,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-402-33",
    rangeType: "Florence 5",
    model: "Florence 5 Column 400mm",
    depthMM: 179,
    heightMM: 502,
    widthMM: 1515,
    volumeL: 38.28,
    nominalDeltaT50KW: 2.195,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1485,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-402-34",
    rangeType: "Florence 5",
    model: "Florence 5 Column 400mm",
    depthMM: 179,
    heightMM: 502,
    widthMM: 1560,
    volumeL: 39.44,
    nominalDeltaT50KW: 2.261,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1526,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-402-35",
    rangeType: "Florence 5",
    model: "Florence 5 Column 400mm",
    depthMM: 179,
    heightMM: 502,
    widthMM: 1605,
    volumeL: 40.6,
    nominalDeltaT50KW: 2.328,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1568,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-402-36",
    rangeType: "Florence 5",
    model: "Florence 5 Column 400mm",
    depthMM: 179,
    heightMM: 502,
    widthMM: 1650,
    volumeL: 41.76,
    nominalDeltaT50KW: 2.394,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1609,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-402-37",
    rangeType: "Florence 5",
    model: "Florence 5 Column 400mm",
    depthMM: 179,
    heightMM: 502,
    widthMM: 1695,
    volumeL: 42.92,
    nominalDeltaT50KW: 2.461,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1651,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-402-38",
    rangeType: "Florence 5",
    model: "Florence 5 Column 400mm",
    depthMM: 179,
    heightMM: 502,
    widthMM: 1740,
    volumeL: 44.08,
    nominalDeltaT50KW: 2.527,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1692,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-402-39",
    rangeType: "Florence 5",
    model: "Florence 5 Column 400mm",
    depthMM: 179,
    heightMM: 502,
    widthMM: 1785,
    volumeL: 45.24,
    nominalDeltaT50KW: 2.594,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1734,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-402-40",
    rangeType: "Florence 5",
    model: "Florence 5 Column 400mm",
    depthMM: 179,
    heightMM: 502,
    widthMM: 1830,
    volumeL: 46.4,
    nominalDeltaT50KW: 2.66,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1775,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-502-04",
    rangeType: "Florence 5",
    model: "Florence 5 Column 500mm",
    depthMM: 179,
    heightMM: 602,
    widthMM: 210,
    volumeL: 5.44,
    nominalDeltaT50KW: 0.325,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 285,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-502-05",
    rangeType: "Florence 5",
    model: "Florence 5 Column 500mm",
    depthMM: 179,
    heightMM: 602,
    widthMM: 255,
    volumeL: 6.8,
    nominalDeltaT50KW: 0.407,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 328,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-502-06",
    rangeType: "Florence 5",
    model: "Florence 5 Column 500mm",
    depthMM: 179,
    heightMM: 602,
    widthMM: 300,
    volumeL: 8.16,
    nominalDeltaT50KW: 0.488,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 370,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-502-07",
    rangeType: "Florence 5",
    model: "Florence 5 Column 500mm",
    depthMM: 179,
    heightMM: 602,
    widthMM: 345,
    volumeL: 9.52,
    nominalDeltaT50KW: 0.569,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 413,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-502-08",
    rangeType: "Florence 5",
    model: "Florence 5 Column 500mm",
    depthMM: 179,
    heightMM: 602,
    widthMM: 390,
    volumeL: 10.88,
    nominalDeltaT50KW: 0.65,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 455,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-502-09",
    rangeType: "Florence 5",
    model: "Florence 5 Column 500mm",
    depthMM: 179,
    heightMM: 602,
    widthMM: 435,
    volumeL: 12.24,
    nominalDeltaT50KW: 0.732,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 498,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-502-10",
    rangeType: "Florence 5",
    model: "Florence 5 Column 500mm",
    depthMM: 179,
    heightMM: 602,
    widthMM: 480,
    volumeL: 13.6,
    nominalDeltaT50KW: 0.813,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 540,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-502-11",
    rangeType: "Florence 5",
    model: "Florence 5 Column 500mm",
    depthMM: 179,
    heightMM: 602,
    widthMM: 525,
    volumeL: 14.96,
    nominalDeltaT50KW: 0.894,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 583,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-502-12",
    rangeType: "Florence 5",
    model: "Florence 5 Column 500mm",
    depthMM: 179,
    heightMM: 602,
    widthMM: 570,
    volumeL: 16.32,
    nominalDeltaT50KW: 0.976,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 625,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-502-13",
    rangeType: "Florence 5",
    model: "Florence 5 Column 500mm",
    depthMM: 179,
    heightMM: 602,
    widthMM: 615,
    volumeL: 17.68,
    nominalDeltaT50KW: 1.057,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 668,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-502-14",
    rangeType: "Florence 5",
    model: "Florence 5 Column 500mm",
    depthMM: 179,
    heightMM: 602,
    widthMM: 660,
    volumeL: 19.04,
    nominalDeltaT50KW: 1.138,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 710,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-502-15",
    rangeType: "Florence 5",
    model: "Florence 5 Column 500mm",
    depthMM: 179,
    heightMM: 602,
    widthMM: 705,
    volumeL: 20.4,
    nominalDeltaT50KW: 1.22,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 753,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-502-16",
    rangeType: "Florence 5",
    model: "Florence 5 Column 500mm",
    depthMM: 179,
    heightMM: 602,
    widthMM: 750,
    volumeL: 21.76,
    nominalDeltaT50KW: 1.301,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 795,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-502-17",
    rangeType: "Florence 5",
    model: "Florence 5 Column 500mm",
    depthMM: 179,
    heightMM: 602,
    widthMM: 795,
    volumeL: 23.12,
    nominalDeltaT50KW: 1.382,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 838,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-502-18",
    rangeType: "Florence 5",
    model: "Florence 5 Column 500mm",
    depthMM: 179,
    heightMM: 602,
    widthMM: 840,
    volumeL: 24.48,
    nominalDeltaT50KW: 1.463,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 880,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-502-19",
    rangeType: "Florence 5",
    model: "Florence 5 Column 500mm",
    depthMM: 179,
    heightMM: 602,
    widthMM: 885,
    volumeL: 25.84,
    nominalDeltaT50KW: 1.545,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 923,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-502-20",
    rangeType: "Florence 5",
    model: "Florence 5 Column 500mm",
    depthMM: 179,
    heightMM: 602,
    widthMM: 930,
    volumeL: 27.2,
    nominalDeltaT50KW: 1.626,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 965,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-502-21",
    rangeType: "Florence 5",
    model: "Florence 5 Column 500mm",
    depthMM: 179,
    heightMM: 602,
    widthMM: 975,
    volumeL: 28.56,
    nominalDeltaT50KW: 1.707,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1008,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-502-22",
    rangeType: "Florence 5",
    model: "Florence 5 Column 500mm",
    depthMM: 179,
    heightMM: 602,
    widthMM: 1020,
    volumeL: 29.92,
    nominalDeltaT50KW: 1.789,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1050,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-502-23",
    rangeType: "Florence 5",
    model: "Florence 5 Column 500mm",
    depthMM: 179,
    heightMM: 602,
    widthMM: 1065,
    volumeL: 31.28,
    nominalDeltaT50KW: 1.87,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1093,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-502-24",
    rangeType: "Florence 5",
    model: "Florence 5 Column 500mm",
    depthMM: 179,
    heightMM: 602,
    widthMM: 1110,
    volumeL: 32.64,
    nominalDeltaT50KW: 1.951,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1135,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-502-25",
    rangeType: "Florence 5",
    model: "Florence 5 Column 500mm",
    depthMM: 179,
    heightMM: 602,
    widthMM: 1155,
    volumeL: 34,
    nominalDeltaT50KW: 2.033,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1178,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-502-26",
    rangeType: "Florence 5",
    model: "Florence 5 Column 500mm",
    depthMM: 179,
    heightMM: 602,
    widthMM: 1200,
    volumeL: 35.36,
    nominalDeltaT50KW: 2.114,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1220,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-502-27",
    rangeType: "Florence 5",
    model: "Florence 5 Column 500mm",
    depthMM: 179,
    heightMM: 602,
    widthMM: 1245,
    volumeL: 36.72,
    nominalDeltaT50KW: 2.195,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1263,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-502-28",
    rangeType: "Florence 5",
    model: "Florence 5 Column 500mm",
    depthMM: 179,
    heightMM: 602,
    widthMM: 1290,
    volumeL: 38.08,
    nominalDeltaT50KW: 2.276,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1305,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-502-29",
    rangeType: "Florence 5",
    model: "Florence 5 Column 500mm",
    depthMM: 179,
    heightMM: 602,
    widthMM: 1335,
    volumeL: 39.44,
    nominalDeltaT50KW: 2.358,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1348,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-502-30",
    rangeType: "Florence 5",
    model: "Florence 5 Column 500mm",
    depthMM: 179,
    heightMM: 602,
    widthMM: 1380,
    volumeL: 40.8,
    nominalDeltaT50KW: 2.439,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1390,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-502-31",
    rangeType: "Florence 5",
    model: "Florence 5 Column 500mm",
    depthMM: 179,
    heightMM: 602,
    widthMM: 1425,
    volumeL: 42.16,
    nominalDeltaT50KW: 2.52,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1433,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-502-32",
    rangeType: "Florence 5",
    model: "Florence 5 Column 500mm",
    depthMM: 179,
    heightMM: 602,
    widthMM: 1470,
    volumeL: 43.52,
    nominalDeltaT50KW: 2.602,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1475,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-502-33",
    rangeType: "Florence 5",
    model: "Florence 5 Column 500mm",
    depthMM: 179,
    heightMM: 602,
    widthMM: 1515,
    volumeL: 44.88,
    nominalDeltaT50KW: 2.683,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1518,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-502-34",
    rangeType: "Florence 5",
    model: "Florence 5 Column 500mm",
    depthMM: 179,
    heightMM: 602,
    widthMM: 1560,
    volumeL: 46.24,
    nominalDeltaT50KW: 2.764,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1560,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-502-35",
    rangeType: "Florence 5",
    model: "Florence 5 Column 500mm",
    depthMM: 179,
    heightMM: 602,
    widthMM: 1605,
    volumeL: 47.6,
    nominalDeltaT50KW: 2.846,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1603,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-502-36",
    rangeType: "Florence 5",
    model: "Florence 5 Column 500mm",
    depthMM: 179,
    heightMM: 602,
    widthMM: 1650,
    volumeL: 48.96,
    nominalDeltaT50KW: 2.927,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1645,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-502-37",
    rangeType: "Florence 5",
    model: "Florence 5 Column 500mm",
    depthMM: 179,
    heightMM: 602,
    widthMM: 1695,
    volumeL: 50.32,
    nominalDeltaT50KW: 3.008,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1688,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-502-38",
    rangeType: "Florence 5",
    model: "Florence 5 Column 500mm",
    depthMM: 179,
    heightMM: 602,
    widthMM: 1740,
    volumeL: 51.68,
    nominalDeltaT50KW: 3.089,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1730,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-502-39",
    rangeType: "Florence 5",
    model: "Florence 5 Column 500mm",
    depthMM: 179,
    heightMM: 602,
    widthMM: 1785,
    volumeL: 53.04,
    nominalDeltaT50KW: 3.171,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1773,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-502-40",
    rangeType: "Florence 5",
    model: "Florence 5 Column 500mm",
    depthMM: 179,
    heightMM: 602,
    widthMM: 1830,
    volumeL: 54.4,
    nominalDeltaT50KW: 3.252,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1815,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-602-04",
    rangeType: "Florence 5",
    model: "Florence 5 Column 600mm",
    depthMM: 179,
    heightMM: 702,
    widthMM: 210,
    volumeL: 6.28,
    nominalDeltaT50KW: 0.384,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 289,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-602-05",
    rangeType: "Florence 5",
    model: "Florence 5 Column 600mm",
    depthMM: 179,
    heightMM: 702,
    widthMM: 255,
    volumeL: 7.85,
    nominalDeltaT50KW: 0.48,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 333,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-602-06",
    rangeType: "Florence 5",
    model: "Florence 5 Column 600mm",
    depthMM: 179,
    heightMM: 702,
    widthMM: 300,
    volumeL: 9.42,
    nominalDeltaT50KW: 0.575,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 376,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-602-07",
    rangeType: "Florence 5",
    model: "Florence 5 Column 600mm",
    depthMM: 179,
    heightMM: 702,
    widthMM: 345,
    volumeL: 10.99,
    nominalDeltaT50KW: 0.671,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 420,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-602-08",
    rangeType: "Florence 5",
    model: "Florence 5 Column 600mm",
    depthMM: 179,
    heightMM: 702,
    widthMM: 390,
    volumeL: 12.56,
    nominalDeltaT50KW: 0.767,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 463,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-602-09",
    rangeType: "Florence 5",
    model: "Florence 5 Column 600mm",
    depthMM: 179,
    heightMM: 702,
    widthMM: 435,
    volumeL: 14.13,
    nominalDeltaT50KW: 0.863,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 507,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-602-10",
    rangeType: "Florence 5",
    model: "Florence 5 Column 600mm",
    depthMM: 179,
    heightMM: 702,
    widthMM: 480,
    volumeL: 15.7,
    nominalDeltaT50KW: 0.959,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 550,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-602-11",
    rangeType: "Florence 5",
    model: "Florence 5 Column 600mm",
    depthMM: 179,
    heightMM: 702,
    widthMM: 525,
    volumeL: 17.27,
    nominalDeltaT50KW: 1.055,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 594,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-602-12",
    rangeType: "Florence 5",
    model: "Florence 5 Column 600mm",
    depthMM: 179,
    heightMM: 702,
    widthMM: 570,
    volumeL: 18.84,
    nominalDeltaT50KW: 1.151,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 637,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-602-13",
    rangeType: "Florence 5",
    model: "Florence 5 Column 600mm",
    depthMM: 179,
    heightMM: 702,
    widthMM: 615,
    volumeL: 20.41,
    nominalDeltaT50KW: 1.247,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 681,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-602-14",
    rangeType: "Florence 5",
    model: "Florence 5 Column 600mm",
    depthMM: 179,
    heightMM: 702,
    widthMM: 660,
    volumeL: 21.98,
    nominalDeltaT50KW: 1.343,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 724,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-602-15",
    rangeType: "Florence 5",
    model: "Florence 5 Column 600mm",
    depthMM: 179,
    heightMM: 702,
    widthMM: 705,
    volumeL: 23.55,
    nominalDeltaT50KW: 1.439,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 768,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-602-16",
    rangeType: "Florence 5",
    model: "Florence 5 Column 600mm",
    depthMM: 179,
    heightMM: 702,
    widthMM: 750,
    volumeL: 25.12,
    nominalDeltaT50KW: 1.534,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 811,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-602-17",
    rangeType: "Florence 5",
    model: "Florence 5 Column 600mm",
    depthMM: 179,
    heightMM: 702,
    widthMM: 795,
    volumeL: 26.69,
    nominalDeltaT50KW: 1.63,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 855,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-602-18",
    rangeType: "Florence 5",
    model: "Florence 5 Column 600mm",
    depthMM: 179,
    heightMM: 702,
    widthMM: 840,
    volumeL: 28.26,
    nominalDeltaT50KW: 1.726,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 898,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-602-19",
    rangeType: "Florence 5",
    model: "Florence 5 Column 600mm",
    depthMM: 179,
    heightMM: 702,
    widthMM: 885,
    volumeL: 29.83,
    nominalDeltaT50KW: 1.822,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 942,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-602-20",
    rangeType: "Florence 5",
    model: "Florence 5 Column 600mm",
    depthMM: 179,
    heightMM: 702,
    widthMM: 930,
    volumeL: 31.4,
    nominalDeltaT50KW: 1.918,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 985,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-602-21",
    rangeType: "Florence 5",
    model: "Florence 5 Column 600mm",
    depthMM: 179,
    heightMM: 702,
    widthMM: 975,
    volumeL: 32.97,
    nominalDeltaT50KW: 2.014,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1029,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-602-22",
    rangeType: "Florence 5",
    model: "Florence 5 Column 600mm",
    depthMM: 179,
    heightMM: 702,
    widthMM: 1020,
    volumeL: 34.54,
    nominalDeltaT50KW: 2.11,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1072,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-602-23",
    rangeType: "Florence 5",
    model: "Florence 5 Column 600mm",
    depthMM: 179,
    heightMM: 702,
    widthMM: 1065,
    volumeL: 36.11,
    nominalDeltaT50KW: 2.206,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1116,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-602-24",
    rangeType: "Florence 5",
    model: "Florence 5 Column 600mm",
    depthMM: 179,
    heightMM: 702,
    widthMM: 1110,
    volumeL: 37.68,
    nominalDeltaT50KW: 2.302,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1159,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-602-25",
    rangeType: "Florence 5",
    model: "Florence 5 Column 600mm",
    depthMM: 179,
    heightMM: 702,
    widthMM: 1155,
    volumeL: 39.25,
    nominalDeltaT50KW: 2.398,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1203,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-602-26",
    rangeType: "Florence 5",
    model: "Florence 5 Column 600mm",
    depthMM: 179,
    heightMM: 702,
    widthMM: 1200,
    volumeL: 40.82,
    nominalDeltaT50KW: 2.493,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1246,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-602-27",
    rangeType: "Florence 5",
    model: "Florence 5 Column 600mm",
    depthMM: 179,
    heightMM: 702,
    widthMM: 1245,
    volumeL: 42.39,
    nominalDeltaT50KW: 2.589,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1290,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-602-28",
    rangeType: "Florence 5",
    model: "Florence 5 Column 600mm",
    depthMM: 179,
    heightMM: 702,
    widthMM: 1290,
    volumeL: 43.96,
    nominalDeltaT50KW: 2.685,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1333,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-602-29",
    rangeType: "Florence 5",
    model: "Florence 5 Column 600mm",
    depthMM: 179,
    heightMM: 702,
    widthMM: 1335,
    volumeL: 45.53,
    nominalDeltaT50KW: 2.781,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1377,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-602-30",
    rangeType: "Florence 5",
    model: "Florence 5 Column 600mm",
    depthMM: 179,
    heightMM: 702,
    widthMM: 1380,
    volumeL: 47.1,
    nominalDeltaT50KW: 2.877,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1420,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-602-31",
    rangeType: "Florence 5",
    model: "Florence 5 Column 600mm",
    depthMM: 179,
    heightMM: 702,
    widthMM: 1425,
    volumeL: 48.67,
    nominalDeltaT50KW: 2.973,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1464,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-602-32",
    rangeType: "Florence 5",
    model: "Florence 5 Column 600mm",
    depthMM: 179,
    heightMM: 702,
    widthMM: 1470,
    volumeL: 50.24,
    nominalDeltaT50KW: 3.069,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1507,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-602-33",
    rangeType: "Florence 5",
    model: "Florence 5 Column 600mm",
    depthMM: 179,
    heightMM: 702,
    widthMM: 1515,
    volumeL: 51.81,
    nominalDeltaT50KW: 3.165,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1551,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-602-34",
    rangeType: "Florence 5",
    model: "Florence 5 Column 600mm",
    depthMM: 179,
    heightMM: 702,
    widthMM: 1560,
    volumeL: 53.38,
    nominalDeltaT50KW: 3.261,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1594,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-602-35",
    rangeType: "Florence 5",
    model: "Florence 5 Column 600mm",
    depthMM: 179,
    heightMM: 702,
    widthMM: 1605,
    volumeL: 54.95,
    nominalDeltaT50KW: 3.357,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1638,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-602-36",
    rangeType: "Florence 5",
    model: "Florence 5 Column 600mm",
    depthMM: 179,
    heightMM: 702,
    widthMM: 1650,
    volumeL: 56.52,
    nominalDeltaT50KW: 3.452,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1681,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-602-37",
    rangeType: "Florence 5",
    model: "Florence 5 Column 600mm",
    depthMM: 179,
    heightMM: 702,
    widthMM: 1695,
    volumeL: 58.09,
    nominalDeltaT50KW: 3.548,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1725,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-602-38",
    rangeType: "Florence 5",
    model: "Florence 5 Column 600mm",
    depthMM: 179,
    heightMM: 702,
    widthMM: 1740,
    volumeL: 59.66,
    nominalDeltaT50KW: 3.644,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1768,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-602-39",
    rangeType: "Florence 5",
    model: "Florence 5 Column 600mm",
    depthMM: 179,
    heightMM: 702,
    widthMM: 1785,
    volumeL: 61.23,
    nominalDeltaT50KW: 3.74,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1812,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-602-40",
    rangeType: "Florence 5",
    model: "Florence 5 Column 600mm",
    depthMM: 179,
    heightMM: 702,
    widthMM: 1830,
    volumeL: 62.8,
    nominalDeltaT50KW: 3.836,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1855,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-687-04",
    rangeType: "Florence 5",
    model: "Florence 5 Column 685mm",
    depthMM: 179,
    heightMM: 787,
    widthMM: 210,
    volumeL: 7,
    nominalDeltaT50KW: 0.432,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 293,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-687-05",
    rangeType: "Florence 5",
    model: "Florence 5 Column 685mm",
    depthMM: 179,
    heightMM: 787,
    widthMM: 255,
    volumeL: 8.75,
    nominalDeltaT50KW: 0.541,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 338,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-687-06",
    rangeType: "Florence 5",
    model: "Florence 5 Column 685mm",
    depthMM: 179,
    heightMM: 787,
    widthMM: 300,
    volumeL: 10.5,
    nominalDeltaT50KW: 0.649,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 382,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-687-07",
    rangeType: "Florence 5",
    model: "Florence 5 Column 685mm",
    depthMM: 179,
    heightMM: 787,
    widthMM: 345,
    volumeL: 12.25,
    nominalDeltaT50KW: 0.757,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 427,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-687-08",
    rangeType: "Florence 5",
    model: "Florence 5 Column 685mm",
    depthMM: 179,
    heightMM: 787,
    widthMM: 390,
    volumeL: 14,
    nominalDeltaT50KW: 0.865,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 471,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-687-09",
    rangeType: "Florence 5",
    model: "Florence 5 Column 685mm",
    depthMM: 179,
    heightMM: 787,
    widthMM: 435,
    volumeL: 15.75,
    nominalDeltaT50KW: 0.973,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 516,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-687-10",
    rangeType: "Florence 5",
    model: "Florence 5 Column 685mm",
    depthMM: 179,
    heightMM: 787,
    widthMM: 480,
    volumeL: 17.5,
    nominalDeltaT50KW: 1.081,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 560,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-687-11",
    rangeType: "Florence 5",
    model: "Florence 5 Column 685mm",
    depthMM: 179,
    heightMM: 787,
    widthMM: 525,
    volumeL: 19.25,
    nominalDeltaT50KW: 1.189,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 605,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-687-12",
    rangeType: "Florence 5",
    model: "Florence 5 Column 685mm",
    depthMM: 179,
    heightMM: 787,
    widthMM: 570,
    volumeL: 21,
    nominalDeltaT50KW: 1.297,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 649,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-687-13",
    rangeType: "Florence 5",
    model: "Florence 5 Column 685mm",
    depthMM: 179,
    heightMM: 787,
    widthMM: 615,
    volumeL: 22.75,
    nominalDeltaT50KW: 1.405,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 694,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-687-14",
    rangeType: "Florence 5",
    model: "Florence 5 Column 685mm",
    depthMM: 179,
    heightMM: 787,
    widthMM: 660,
    volumeL: 24.5,
    nominalDeltaT50KW: 1.513,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 738,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-687-15",
    rangeType: "Florence 5",
    model: "Florence 5 Column 685mm",
    depthMM: 179,
    heightMM: 787,
    widthMM: 705,
    volumeL: 26.25,
    nominalDeltaT50KW: 1.622,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 783,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-687-16",
    rangeType: "Florence 5",
    model: "Florence 5 Column 685mm",
    depthMM: 179,
    heightMM: 787,
    widthMM: 750,
    volumeL: 28,
    nominalDeltaT50KW: 1.73,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 827,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-687-17",
    rangeType: "Florence 5",
    model: "Florence 5 Column 685mm",
    depthMM: 179,
    heightMM: 787,
    widthMM: 795,
    volumeL: 29.75,
    nominalDeltaT50KW: 1.838,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 872,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-687-18",
    rangeType: "Florence 5",
    model: "Florence 5 Column 685mm",
    depthMM: 179,
    heightMM: 787,
    widthMM: 840,
    volumeL: 31.5,
    nominalDeltaT50KW: 1.946,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 916,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-687-19",
    rangeType: "Florence 5",
    model: "Florence 5 Column 685mm",
    depthMM: 179,
    heightMM: 787,
    widthMM: 885,
    volumeL: 33.25,
    nominalDeltaT50KW: 2.054,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 961,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-687-20",
    rangeType: "Florence 5",
    model: "Florence 5 Column 685mm",
    depthMM: 179,
    heightMM: 787,
    widthMM: 930,
    volumeL: 35,
    nominalDeltaT50KW: 2.162,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1005,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-687-21",
    rangeType: "Florence 5",
    model: "Florence 5 Column 685mm",
    depthMM: 179,
    heightMM: 787,
    widthMM: 975,
    volumeL: 36.75,
    nominalDeltaT50KW: 2.27,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1050,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-687-22",
    rangeType: "Florence 5",
    model: "Florence 5 Column 685mm",
    depthMM: 179,
    heightMM: 787,
    widthMM: 1020,
    volumeL: 38.5,
    nominalDeltaT50KW: 2.378,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1094,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-687-23",
    rangeType: "Florence 5",
    model: "Florence 5 Column 685mm",
    depthMM: 179,
    heightMM: 787,
    widthMM: 1065,
    volumeL: 40.25,
    nominalDeltaT50KW: 2.486,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1139,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-687-24",
    rangeType: "Florence 5",
    model: "Florence 5 Column 685mm",
    depthMM: 179,
    heightMM: 787,
    widthMM: 1110,
    volumeL: 42,
    nominalDeltaT50KW: 2.594,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1183,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-687-25",
    rangeType: "Florence 5",
    model: "Florence 5 Column 685mm",
    depthMM: 179,
    heightMM: 787,
    widthMM: 1155,
    volumeL: 43.75,
    nominalDeltaT50KW: 2.703,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1228,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-687-26",
    rangeType: "Florence 5",
    model: "Florence 5 Column 685mm",
    depthMM: 179,
    heightMM: 787,
    widthMM: 1200,
    volumeL: 45.5,
    nominalDeltaT50KW: 2.811,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1272,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-687-27",
    rangeType: "Florence 5",
    model: "Florence 5 Column 685mm",
    depthMM: 179,
    heightMM: 787,
    widthMM: 1245,
    volumeL: 47.25,
    nominalDeltaT50KW: 2.919,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1317,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-687-28",
    rangeType: "Florence 5",
    model: "Florence 5 Column 685mm",
    depthMM: 179,
    heightMM: 787,
    widthMM: 1290,
    volumeL: 49,
    nominalDeltaT50KW: 3.027,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1361,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-687-29",
    rangeType: "Florence 5",
    model: "Florence 5 Column 685mm",
    depthMM: 179,
    heightMM: 787,
    widthMM: 1335,
    volumeL: 50.75,
    nominalDeltaT50KW: 3.135,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1406,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-687-30",
    rangeType: "Florence 5",
    model: "Florence 5 Column 685mm",
    depthMM: 179,
    heightMM: 787,
    widthMM: 1380,
    volumeL: 52.5,
    nominalDeltaT50KW: 3.243,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1450,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-687-31",
    rangeType: "Florence 5",
    model: "Florence 5 Column 685mm",
    depthMM: 179,
    heightMM: 787,
    widthMM: 1425,
    volumeL: 54.25,
    nominalDeltaT50KW: 3.351,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1495,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-687-32",
    rangeType: "Florence 5",
    model: "Florence 5 Column 685mm",
    depthMM: 179,
    heightMM: 787,
    widthMM: 1470,
    volumeL: 56,
    nominalDeltaT50KW: 3.459,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1539,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-687-33",
    rangeType: "Florence 5",
    model: "Florence 5 Column 685mm",
    depthMM: 179,
    heightMM: 787,
    widthMM: 1515,
    volumeL: 57.75,
    nominalDeltaT50KW: 3.567,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1584,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-687-34",
    rangeType: "Florence 5",
    model: "Florence 5 Column 685mm",
    depthMM: 179,
    heightMM: 787,
    widthMM: 1560,
    volumeL: 59.5,
    nominalDeltaT50KW: 3.675,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1628,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-687-35",
    rangeType: "Florence 5",
    model: "Florence 5 Column 685mm",
    depthMM: 179,
    heightMM: 787,
    widthMM: 1605,
    volumeL: 61.25,
    nominalDeltaT50KW: 3.784,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1673,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-687-36",
    rangeType: "Florence 5",
    model: "Florence 5 Column 685mm",
    depthMM: 179,
    heightMM: 787,
    widthMM: 1650,
    volumeL: 63,
    nominalDeltaT50KW: 3.892,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1717,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-687-37",
    rangeType: "Florence 5",
    model: "Florence 5 Column 685mm",
    depthMM: 179,
    heightMM: 787,
    widthMM: 1695,
    volumeL: 64.75,
    nominalDeltaT50KW: 4,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1762,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-687-38",
    rangeType: "Florence 5",
    model: "Florence 5 Column 685mm",
    depthMM: 179,
    heightMM: 787,
    widthMM: 1740,
    volumeL: 66.5,
    nominalDeltaT50KW: 4.108,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1806,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-687-39",
    rangeType: "Florence 5",
    model: "Florence 5 Column 685mm",
    depthMM: 179,
    heightMM: 787,
    widthMM: 1785,
    volumeL: 68.25,
    nominalDeltaT50KW: 4.216,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1851,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-687-40",
    rangeType: "Florence 5",
    model: "Florence 5 Column 685mm",
    depthMM: 179,
    heightMM: 787,
    widthMM: 1830,
    volumeL: 70,
    nominalDeltaT50KW: 4.324,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1895,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-767-04",
    rangeType: "Florence 5",
    model: "Florence 5 Column 765mm",
    depthMM: 179,
    heightMM: 867,
    widthMM: 210,
    volumeL: 7.68,
    nominalDeltaT50KW: 0.478,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 301,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-767-05",
    rangeType: "Florence 5",
    model: "Florence 5 Column 765mm",
    depthMM: 179,
    heightMM: 867,
    widthMM: 255,
    volumeL: 9.6,
    nominalDeltaT50KW: 0.598,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 348,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-767-06",
    rangeType: "Florence 5",
    model: "Florence 5 Column 765mm",
    depthMM: 179,
    heightMM: 867,
    widthMM: 300,
    volumeL: 11.52,
    nominalDeltaT50KW: 0.717,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 394,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-767-07",
    rangeType: "Florence 5",
    model: "Florence 5 Column 765mm",
    depthMM: 179,
    heightMM: 867,
    widthMM: 345,
    volumeL: 13.44,
    nominalDeltaT50KW: 0.837,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 441,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-767-08",
    rangeType: "Florence 5",
    model: "Florence 5 Column 765mm",
    depthMM: 179,
    heightMM: 867,
    widthMM: 390,
    volumeL: 15.36,
    nominalDeltaT50KW: 0.956,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 487,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-767-09",
    rangeType: "Florence 5",
    model: "Florence 5 Column 765mm",
    depthMM: 179,
    heightMM: 867,
    widthMM: 435,
    volumeL: 17.28,
    nominalDeltaT50KW: 1.076,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 534,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-767-10",
    rangeType: "Florence 5",
    model: "Florence 5 Column 765mm",
    depthMM: 179,
    heightMM: 867,
    widthMM: 480,
    volumeL: 19.2,
    nominalDeltaT50KW: 1.195,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 580,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-767-11",
    rangeType: "Florence 5",
    model: "Florence 5 Column 765mm",
    depthMM: 179,
    heightMM: 867,
    widthMM: 525,
    volumeL: 21.12,
    nominalDeltaT50KW: 1.315,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 627,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-767-12",
    rangeType: "Florence 5",
    model: "Florence 5 Column 765mm",
    depthMM: 179,
    heightMM: 867,
    widthMM: 570,
    volumeL: 23.04,
    nominalDeltaT50KW: 1.434,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 673,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-767-13",
    rangeType: "Florence 5",
    model: "Florence 5 Column 765mm",
    depthMM: 179,
    heightMM: 867,
    widthMM: 615,
    volumeL: 24.96,
    nominalDeltaT50KW: 1.554,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 720,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-767-14",
    rangeType: "Florence 5",
    model: "Florence 5 Column 765mm",
    depthMM: 179,
    heightMM: 867,
    widthMM: 660,
    volumeL: 26.88,
    nominalDeltaT50KW: 1.673,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 766,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-767-15",
    rangeType: "Florence 5",
    model: "Florence 5 Column 765mm",
    depthMM: 179,
    heightMM: 867,
    widthMM: 705,
    volumeL: 28.8,
    nominalDeltaT50KW: 1.793,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 813,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-767-16",
    rangeType: "Florence 5",
    model: "Florence 5 Column 765mm",
    depthMM: 179,
    heightMM: 867,
    widthMM: 750,
    volumeL: 30.72,
    nominalDeltaT50KW: 1.912,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 859,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-767-17",
    rangeType: "Florence 5",
    model: "Florence 5 Column 765mm",
    depthMM: 179,
    heightMM: 867,
    widthMM: 795,
    volumeL: 32.64,
    nominalDeltaT50KW: 2.032,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 906,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-767-18",
    rangeType: "Florence 5",
    model: "Florence 5 Column 765mm",
    depthMM: 179,
    heightMM: 867,
    widthMM: 840,
    volumeL: 34.56,
    nominalDeltaT50KW: 2.151,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 952,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-767-19",
    rangeType: "Florence 5",
    model: "Florence 5 Column 765mm",
    depthMM: 179,
    heightMM: 867,
    widthMM: 885,
    volumeL: 36.48,
    nominalDeltaT50KW: 2.271,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 999,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-767-20",
    rangeType: "Florence 5",
    model: "Florence 5 Column 765mm",
    depthMM: 179,
    heightMM: 867,
    widthMM: 930,
    volumeL: 38.4,
    nominalDeltaT50KW: 2.39,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1045,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-767-21",
    rangeType: "Florence 5",
    model: "Florence 5 Column 765mm",
    depthMM: 179,
    heightMM: 867,
    widthMM: 975,
    volumeL: 40.32,
    nominalDeltaT50KW: 2.51,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1092,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-767-22",
    rangeType: "Florence 5",
    model: "Florence 5 Column 765mm",
    depthMM: 179,
    heightMM: 867,
    widthMM: 1020,
    volumeL: 42.24,
    nominalDeltaT50KW: 2.629,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1138,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-767-23",
    rangeType: "Florence 5",
    model: "Florence 5 Column 765mm",
    depthMM: 179,
    heightMM: 867,
    widthMM: 1065,
    volumeL: 44.16,
    nominalDeltaT50KW: 2.749,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1185,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-767-24",
    rangeType: "Florence 5",
    model: "Florence 5 Column 765mm",
    depthMM: 179,
    heightMM: 867,
    widthMM: 1110,
    volumeL: 46.08,
    nominalDeltaT50KW: 2.868,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1231,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-767-25",
    rangeType: "Florence 5",
    model: "Florence 5 Column 765mm",
    depthMM: 179,
    heightMM: 867,
    widthMM: 1155,
    volumeL: 48,
    nominalDeltaT50KW: 2.988,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1278,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-767-26",
    rangeType: "Florence 5",
    model: "Florence 5 Column 765mm",
    depthMM: 179,
    heightMM: 867,
    widthMM: 1200,
    volumeL: 49.92,
    nominalDeltaT50KW: 3.107,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1324,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-767-27",
    rangeType: "Florence 5",
    model: "Florence 5 Column 765mm",
    depthMM: 179,
    heightMM: 867,
    widthMM: 1245,
    volumeL: 51.84,
    nominalDeltaT50KW: 3.227,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1371,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-767-28",
    rangeType: "Florence 5",
    model: "Florence 5 Column 765mm",
    depthMM: 179,
    heightMM: 867,
    widthMM: 1290,
    volumeL: 53.76,
    nominalDeltaT50KW: 3.346,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1417,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-767-29",
    rangeType: "Florence 5",
    model: "Florence 5 Column 765mm",
    depthMM: 179,
    heightMM: 867,
    widthMM: 1335,
    volumeL: 55.68,
    nominalDeltaT50KW: 3.466,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1464,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-767-30",
    rangeType: "Florence 5",
    model: "Florence 5 Column 765mm",
    depthMM: 179,
    heightMM: 867,
    widthMM: 1380,
    volumeL: 57.6,
    nominalDeltaT50KW: 3.585,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1510,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-767-31",
    rangeType: "Florence 5",
    model: "Florence 5 Column 765mm",
    depthMM: 179,
    heightMM: 867,
    widthMM: 1425,
    volumeL: 59.52,
    nominalDeltaT50KW: 3.705,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1557,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-767-32",
    rangeType: "Florence 5",
    model: "Florence 5 Column 765mm",
    depthMM: 179,
    heightMM: 867,
    widthMM: 1470,
    volumeL: 61.44,
    nominalDeltaT50KW: 3.824,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1603,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-767-33",
    rangeType: "Florence 5",
    model: "Florence 5 Column 765mm",
    depthMM: 179,
    heightMM: 867,
    widthMM: 1515,
    volumeL: 63.36,
    nominalDeltaT50KW: 3.944,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1650,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-767-34",
    rangeType: "Florence 5",
    model: "Florence 5 Column 765mm",
    depthMM: 179,
    heightMM: 867,
    widthMM: 1560,
    volumeL: 65.28,
    nominalDeltaT50KW: 4.063,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1696,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-767-35",
    rangeType: "Florence 5",
    model: "Florence 5 Column 765mm",
    depthMM: 179,
    heightMM: 867,
    widthMM: 1605,
    volumeL: 67.2,
    nominalDeltaT50KW: 4.183,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1743,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-767-36",
    rangeType: "Florence 5",
    model: "Florence 5 Column 765mm",
    depthMM: 179,
    heightMM: 867,
    widthMM: 1650,
    volumeL: 69.12,
    nominalDeltaT50KW: 4.302,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1789,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-767-37",
    rangeType: "Florence 5",
    model: "Florence 5 Column 765mm",
    depthMM: 179,
    heightMM: 867,
    widthMM: 1695,
    volumeL: 71.04,
    nominalDeltaT50KW: 4.422,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1836,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-767-38",
    rangeType: "Florence 5",
    model: "Florence 5 Column 765mm",
    depthMM: 179,
    heightMM: 867,
    widthMM: 1740,
    volumeL: 72.96,
    nominalDeltaT50KW: 4.541,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1882,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-767-39",
    rangeType: "Florence 5",
    model: "Florence 5 Column 765mm",
    depthMM: 179,
    heightMM: 867,
    widthMM: 1785,
    volumeL: 74.88,
    nominalDeltaT50KW: 4.661,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1929,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-767-40",
    rangeType: "Florence 5",
    model: "Florence 5 Column 765mm",
    depthMM: 179,
    heightMM: 867,
    widthMM: 1830,
    volumeL: 76.8,
    nominalDeltaT50KW: 4.78,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1975,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-902-04",
    rangeType: "Florence 5",
    model: "Florence 5 Column 900mm",
    depthMM: 179,
    heightMM: 1002,
    widthMM: 210,
    volumeL: 8.8,
    nominalDeltaT50KW: 0.554,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 309,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-902-05",
    rangeType: "Florence 5",
    model: "Florence 5 Column 900mm",
    depthMM: 179,
    heightMM: 1002,
    widthMM: 255,
    volumeL: 11,
    nominalDeltaT50KW: 0.693,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 358,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-902-06",
    rangeType: "Florence 5",
    model: "Florence 5 Column 900mm",
    depthMM: 179,
    heightMM: 1002,
    widthMM: 300,
    volumeL: 13.2,
    nominalDeltaT50KW: 0.831,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 406,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-902-07",
    rangeType: "Florence 5",
    model: "Florence 5 Column 900mm",
    depthMM: 179,
    heightMM: 1002,
    widthMM: 345,
    volumeL: 15.4,
    nominalDeltaT50KW: 0.97,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 455,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-902-08",
    rangeType: "Florence 5",
    model: "Florence 5 Column 900mm",
    depthMM: 179,
    heightMM: 1002,
    widthMM: 390,
    volumeL: 17.6,
    nominalDeltaT50KW: 1.108,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 503,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-902-09",
    rangeType: "Florence 5",
    model: "Florence 5 Column 900mm",
    depthMM: 179,
    heightMM: 1002,
    widthMM: 435,
    volumeL: 19.8,
    nominalDeltaT50KW: 1.247,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 552,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-902-10",
    rangeType: "Florence 5",
    model: "Florence 5 Column 900mm",
    depthMM: 179,
    heightMM: 1002,
    widthMM: 480,
    volumeL: 22,
    nominalDeltaT50KW: 1.385,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 600,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-902-11",
    rangeType: "Florence 5",
    model: "Florence 5 Column 900mm",
    depthMM: 179,
    heightMM: 1002,
    widthMM: 525,
    volumeL: 24.2,
    nominalDeltaT50KW: 1.524,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 649,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-902-12",
    rangeType: "Florence 5",
    model: "Florence 5 Column 900mm",
    depthMM: 179,
    heightMM: 1002,
    widthMM: 570,
    volumeL: 26.4,
    nominalDeltaT50KW: 1.662,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 697,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-902-13",
    rangeType: "Florence 5",
    model: "Florence 5 Column 900mm",
    depthMM: 179,
    heightMM: 1002,
    widthMM: 615,
    volumeL: 28.6,
    nominalDeltaT50KW: 1.801,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 746,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-902-14",
    rangeType: "Florence 5",
    model: "Florence 5 Column 900mm",
    depthMM: 179,
    heightMM: 1002,
    widthMM: 660,
    volumeL: 30.8,
    nominalDeltaT50KW: 1.939,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 794,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-902-15",
    rangeType: "Florence 5",
    model: "Florence 5 Column 900mm",
    depthMM: 179,
    heightMM: 1002,
    widthMM: 705,
    volumeL: 33,
    nominalDeltaT50KW: 2.078,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 843,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-902-16",
    rangeType: "Florence 5",
    model: "Florence 5 Column 900mm",
    depthMM: 179,
    heightMM: 1002,
    widthMM: 750,
    volumeL: 35.2,
    nominalDeltaT50KW: 2.216,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 891,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-902-17",
    rangeType: "Florence 5",
    model: "Florence 5 Column 900mm",
    depthMM: 179,
    heightMM: 1002,
    widthMM: 795,
    volumeL: 37.4,
    nominalDeltaT50KW: 2.355,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 940,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-902-18",
    rangeType: "Florence 5",
    model: "Florence 5 Column 900mm",
    depthMM: 179,
    heightMM: 1002,
    widthMM: 840,
    volumeL: 39.6,
    nominalDeltaT50KW: 2.493,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 988,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-902-19",
    rangeType: "Florence 5",
    model: "Florence 5 Column 900mm",
    depthMM: 179,
    heightMM: 1002,
    widthMM: 885,
    volumeL: 41.8,
    nominalDeltaT50KW: 2.632,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1037,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-902-20",
    rangeType: "Florence 5",
    model: "Florence 5 Column 900mm",
    depthMM: 179,
    heightMM: 1002,
    widthMM: 930,
    volumeL: 44,
    nominalDeltaT50KW: 2.77,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1085,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-902-21",
    rangeType: "Florence 5",
    model: "Florence 5 Column 900mm",
    depthMM: 179,
    heightMM: 1002,
    widthMM: 975,
    volumeL: 46.2,
    nominalDeltaT50KW: 2.909,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1134,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-902-22",
    rangeType: "Florence 5",
    model: "Florence 5 Column 900mm",
    depthMM: 179,
    heightMM: 1002,
    widthMM: 1020,
    volumeL: 48.4,
    nominalDeltaT50KW: 3.047,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1182,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-902-23",
    rangeType: "Florence 5",
    model: "Florence 5 Column 900mm",
    depthMM: 179,
    heightMM: 1002,
    widthMM: 1065,
    volumeL: 50.6,
    nominalDeltaT50KW: 3.186,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1231,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-902-24",
    rangeType: "Florence 5",
    model: "Florence 5 Column 900mm",
    depthMM: 179,
    heightMM: 1002,
    widthMM: 1110,
    volumeL: 52.8,
    nominalDeltaT50KW: 3.324,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1279,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-902-25",
    rangeType: "Florence 5",
    model: "Florence 5 Column 900mm",
    depthMM: 179,
    heightMM: 1002,
    widthMM: 1155,
    volumeL: 55,
    nominalDeltaT50KW: 3.463,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1328,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-902-26",
    rangeType: "Florence 5",
    model: "Florence 5 Column 900mm",
    depthMM: 179,
    heightMM: 1002,
    widthMM: 1200,
    volumeL: 57.2,
    nominalDeltaT50KW: 3.601,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1376,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-902-27",
    rangeType: "Florence 5",
    model: "Florence 5 Column 900mm",
    depthMM: 179,
    heightMM: 1002,
    widthMM: 1245,
    volumeL: 59.4,
    nominalDeltaT50KW: 3.74,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1425,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-902-28",
    rangeType: "Florence 5",
    model: "Florence 5 Column 900mm",
    depthMM: 179,
    heightMM: 1002,
    widthMM: 1290,
    volumeL: 61.6,
    nominalDeltaT50KW: 3.878,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1473,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-902-29",
    rangeType: "Florence 5",
    model: "Florence 5 Column 900mm",
    depthMM: 179,
    heightMM: 1002,
    widthMM: 1335,
    volumeL: 63.8,
    nominalDeltaT50KW: 4.017,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1522,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-902-30",
    rangeType: "Florence 5",
    model: "Florence 5 Column 900mm",
    depthMM: 179,
    heightMM: 1002,
    widthMM: 1380,
    volumeL: 66,
    nominalDeltaT50KW: 4.155,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1570,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-902-31",
    rangeType: "Florence 5",
    model: "Florence 5 Column 900mm",
    depthMM: 179,
    heightMM: 1002,
    widthMM: 1425,
    volumeL: 68.2,
    nominalDeltaT50KW: 4.294,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1619,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-902-32",
    rangeType: "Florence 5",
    model: "Florence 5 Column 900mm",
    depthMM: 179,
    heightMM: 1002,
    widthMM: 1470,
    volumeL: 70.4,
    nominalDeltaT50KW: 4.432,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1667,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-902-33",
    rangeType: "Florence 5",
    model: "Florence 5 Column 900mm",
    depthMM: 179,
    heightMM: 1002,
    widthMM: 1515,
    volumeL: 72.6,
    nominalDeltaT50KW: 4.571,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1716,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-902-34",
    rangeType: "Florence 5",
    model: "Florence 5 Column 900mm",
    depthMM: 179,
    heightMM: 1002,
    widthMM: 1560,
    volumeL: 74.8,
    nominalDeltaT50KW: 4.709,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1764,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-902-35",
    rangeType: "Florence 5",
    model: "Florence 5 Column 900mm",
    depthMM: 179,
    heightMM: 1002,
    widthMM: 1605,
    volumeL: 77,
    nominalDeltaT50KW: 4.848,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1813,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-902-36",
    rangeType: "Florence 5",
    model: "Florence 5 Column 900mm",
    depthMM: 179,
    heightMM: 1002,
    widthMM: 1650,
    volumeL: 79.2,
    nominalDeltaT50KW: 4.986,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1861,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-902-37",
    rangeType: "Florence 5",
    model: "Florence 5 Column 900mm",
    depthMM: 179,
    heightMM: 1002,
    widthMM: 1695,
    volumeL: 81.4,
    nominalDeltaT50KW: 5.125,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1910,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-902-38",
    rangeType: "Florence 5",
    model: "Florence 5 Column 900mm",
    depthMM: 179,
    heightMM: 1002,
    widthMM: 1740,
    volumeL: 83.6,
    nominalDeltaT50KW: 5.263,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1958,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-902-39",
    rangeType: "Florence 5",
    model: "Florence 5 Column 900mm",
    depthMM: 179,
    heightMM: 1002,
    widthMM: 1785,
    volumeL: 85.8,
    nominalDeltaT50KW: 5.402,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 2007,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F5C-902-40",
    rangeType: "Florence 5",
    model: "Florence 5 Column 900mm",
    depthMM: 179,
    heightMM: 1002,
    widthMM: 1830,
    volumeL: 88,
    nominalDeltaT50KW: 5.54,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 2055,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1002-04",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1000mm",
    depthMM: 217,
    heightMM: 1102,
    widthMM: 210,
    volumeL: 11.48,
    nominalDeltaT50KW: 0.716,
    kvValue: 20,
    nCoefficient: 1.39,
    price: {
      value: 393,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1002-05",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1000mm",
    depthMM: 217,
    heightMM: 1102,
    widthMM: 255,
    volumeL: 14.35,
    nominalDeltaT50KW: 0.895,
    kvValue: 20,
    nCoefficient: 1.39,
    price: {
      value: 463,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1002-06",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1000mm",
    depthMM: 217,
    heightMM: 1102,
    widthMM: 300,
    volumeL: 17.22,
    nominalDeltaT50KW: 1.073,
    kvValue: 20,
    nCoefficient: 1.39,
    price: {
      value: 532,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1002-07",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1000mm",
    depthMM: 217,
    heightMM: 1102,
    widthMM: 345,
    volumeL: 20.09,
    nominalDeltaT50KW: 1.252,
    kvValue: 20,
    nCoefficient: 1.39,
    price: {
      value: 602,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1002-08",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1000mm",
    depthMM: 217,
    heightMM: 1102,
    widthMM: 390,
    volumeL: 22.96,
    nominalDeltaT50KW: 1.431,
    kvValue: 20,
    nCoefficient: 1.39,
    price: {
      value: 671,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1002-09",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1000mm",
    depthMM: 217,
    heightMM: 1102,
    widthMM: 435,
    volumeL: 25.83,
    nominalDeltaT50KW: 1.61,
    kvValue: 20,
    nCoefficient: 1.39,
    price: {
      value: 741,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1002-10",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1000mm",
    depthMM: 217,
    heightMM: 1102,
    widthMM: 480,
    volumeL: 28.7,
    nominalDeltaT50KW: 1.789,
    kvValue: 20,
    nCoefficient: 1.39,
    price: {
      value: 810,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1002-11",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1000mm",
    depthMM: 217,
    heightMM: 1102,
    widthMM: 525,
    volumeL: 31.57,
    nominalDeltaT50KW: 1.968,
    kvValue: 20,
    nCoefficient: 1.39,
    price: {
      value: 880,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1002-12",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1000mm",
    depthMM: 217,
    heightMM: 1102,
    widthMM: 570,
    volumeL: 34.44,
    nominalDeltaT50KW: 2.147,
    kvValue: 20,
    nCoefficient: 1.39,
    price: {
      value: 949,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1002-13",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1000mm",
    depthMM: 217,
    heightMM: 1102,
    widthMM: 615,
    volumeL: 37.31,
    nominalDeltaT50KW: 2.326,
    kvValue: 20,
    nCoefficient: 1.39,
    price: {
      value: 1019,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1002-14",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1000mm",
    depthMM: 217,
    heightMM: 1102,
    widthMM: 660,
    volumeL: 40.18,
    nominalDeltaT50KW: 2.505,
    kvValue: 20,
    nCoefficient: 1.39,
    price: {
      value: 1088,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1002-15",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1000mm",
    depthMM: 217,
    heightMM: 1102,
    widthMM: 705,
    volumeL: 43.05,
    nominalDeltaT50KW: 2.684,
    kvValue: 20,
    nCoefficient: 1.39,
    price: {
      value: 1158,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1002-16",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1000mm",
    depthMM: 217,
    heightMM: 1102,
    widthMM: 750,
    volumeL: 45.92,
    nominalDeltaT50KW: 2.862,
    kvValue: 20,
    nCoefficient: 1.39,
    price: {
      value: 1227,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1002-17",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1000mm",
    depthMM: 217,
    heightMM: 1102,
    widthMM: 795,
    volumeL: 48.79,
    nominalDeltaT50KW: 3.041,
    kvValue: 20,
    nCoefficient: 1.39,
    price: {
      value: 1297,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1002-18",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1000mm",
    depthMM: 217,
    heightMM: 1102,
    widthMM: 840,
    volumeL: 51.66,
    nominalDeltaT50KW: 3.22,
    kvValue: 20,
    nCoefficient: 1.39,
    price: {
      value: 1366,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1002-19",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1000mm",
    depthMM: 217,
    heightMM: 1102,
    widthMM: 885,
    volumeL: 54.53,
    nominalDeltaT50KW: 3.399,
    kvValue: 20,
    nCoefficient: 1.39,
    price: {
      value: 1436,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1002-20",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1000mm",
    depthMM: 217,
    heightMM: 1102,
    widthMM: 930,
    volumeL: 57.4,
    nominalDeltaT50KW: 3.578,
    kvValue: 20,
    nCoefficient: 1.39,
    price: {
      value: 1505,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1002-21",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1000mm",
    depthMM: 217,
    heightMM: 1102,
    widthMM: 975,
    volumeL: 60.27,
    nominalDeltaT50KW: 3.757,
    kvValue: 20,
    nCoefficient: 1.39,
    price: {
      value: 1575,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1002-22",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1000mm",
    depthMM: 217,
    heightMM: 1102,
    widthMM: 1020,
    volumeL: 63.14,
    nominalDeltaT50KW: 3.936,
    kvValue: 20,
    nCoefficient: 1.39,
    price: {
      value: 1644,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1002-23",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1000mm",
    depthMM: 217,
    heightMM: 1102,
    widthMM: 1065,
    volumeL: 66.01,
    nominalDeltaT50KW: 4.115,
    kvValue: 20,
    nCoefficient: 1.39,
    price: {
      value: 1714,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1002-24",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1000mm",
    depthMM: 217,
    heightMM: 1102,
    widthMM: 1110,
    volumeL: 68.88,
    nominalDeltaT50KW: 4.294,
    kvValue: 20,
    nCoefficient: 1.39,
    price: {
      value: 1783,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1002-25",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1000mm",
    depthMM: 217,
    heightMM: 1102,
    widthMM: 1155,
    volumeL: 71.75,
    nominalDeltaT50KW: 4.473,
    kvValue: 20,
    nCoefficient: 1.39,
    price: {
      value: 1853,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1002-26",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1000mm",
    depthMM: 217,
    heightMM: 1102,
    widthMM: 1200,
    volumeL: 74.62,
    nominalDeltaT50KW: 4.651,
    kvValue: 20,
    nCoefficient: 1.39,
    price: {
      value: 1922,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1002-27",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1000mm",
    depthMM: 217,
    heightMM: 1102,
    widthMM: 1245,
    volumeL: 77.49,
    nominalDeltaT50KW: 4.83,
    kvValue: 20,
    nCoefficient: 1.39,
    price: {
      value: 1992,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1002-28",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1000mm",
    depthMM: 217,
    heightMM: 1102,
    widthMM: 1290,
    volumeL: 80.36,
    nominalDeltaT50KW: 5.009,
    kvValue: 20,
    nCoefficient: 1.39,
    price: {
      value: 2061,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1002-29",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1000mm",
    depthMM: 217,
    heightMM: 1102,
    widthMM: 1335,
    volumeL: 83.23,
    nominalDeltaT50KW: 5.188,
    kvValue: 20,
    nCoefficient: 1.39,
    price: {
      value: 2131,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1002-30",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1000mm",
    depthMM: 217,
    heightMM: 1102,
    widthMM: 1380,
    volumeL: 86.1,
    nominalDeltaT50KW: 5.367,
    kvValue: 20,
    nCoefficient: 1.39,
    price: {
      value: 2200,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1002-31",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1000mm",
    depthMM: 217,
    heightMM: 1102,
    widthMM: 1425,
    volumeL: 88.97,
    nominalDeltaT50KW: 5.546,
    kvValue: 20,
    nCoefficient: 1.39,
    price: {
      value: 2270,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1002-32",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1000mm",
    depthMM: 217,
    heightMM: 1102,
    widthMM: 1470,
    volumeL: 91.84,
    nominalDeltaT50KW: 5.725,
    kvValue: 20,
    nCoefficient: 1.39,
    price: {
      value: 2339,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1002-33",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1000mm",
    depthMM: 217,
    heightMM: 1102,
    widthMM: 1515,
    volumeL: 94.71,
    nominalDeltaT50KW: 5.904,
    kvValue: 20,
    nCoefficient: 1.39,
    price: {
      value: 2409,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1002-34",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1000mm",
    depthMM: 217,
    heightMM: 1102,
    widthMM: 1560,
    volumeL: 97.58,
    nominalDeltaT50KW: 6.083,
    kvValue: 20,
    nCoefficient: 1.39,
    price: {
      value: 2478,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1002-35",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1000mm",
    depthMM: 217,
    heightMM: 1102,
    widthMM: 1605,
    volumeL: 100.45,
    nominalDeltaT50KW: 6.262,
    kvValue: 20,
    nCoefficient: 1.39,
    price: {
      value: 2548,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1002-36",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1000mm",
    depthMM: 217,
    heightMM: 1102,
    widthMM: 1650,
    volumeL: 103.32,
    nominalDeltaT50KW: 6.44,
    kvValue: 20,
    nCoefficient: 1.39,
    price: {
      value: 2617,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1002-37",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1000mm",
    depthMM: 217,
    heightMM: 1102,
    widthMM: 1695,
    volumeL: 106.19,
    nominalDeltaT50KW: 6.619,
    kvValue: 20,
    nCoefficient: 1.39,
    price: {
      value: 2687,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1002-38",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1000mm",
    depthMM: 217,
    heightMM: 1102,
    widthMM: 1740,
    volumeL: 109.06,
    nominalDeltaT50KW: 6.798,
    kvValue: 20,
    nCoefficient: 1.39,
    price: {
      value: 2756,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1002-39",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1000mm",
    depthMM: 217,
    heightMM: 1102,
    widthMM: 1785,
    volumeL: 111.93,
    nominalDeltaT50KW: 6.977,
    kvValue: 20,
    nCoefficient: 1.39,
    price: {
      value: 2826,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1002-40",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1000mm",
    depthMM: 217,
    heightMM: 1102,
    widthMM: 1830,
    volumeL: 114.8,
    nominalDeltaT50KW: 7.156,
    kvValue: 20,
    nCoefficient: 1.39,
    price: {
      value: 2895,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1202-04",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1200mm",
    depthMM: 217,
    heightMM: 1302,
    widthMM: 210,
    volumeL: 13.32,
    nominalDeltaT50KW: 0.845,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 485,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1202-05",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1200mm",
    depthMM: 217,
    heightMM: 1302,
    widthMM: 255,
    volumeL: 16.65,
    nominalDeltaT50KW: 1.056,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 578,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1202-06",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1200mm",
    depthMM: 217,
    heightMM: 1302,
    widthMM: 300,
    volumeL: 19.98,
    nominalDeltaT50KW: 1.267,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 670,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1202-07",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1200mm",
    depthMM: 217,
    heightMM: 1302,
    widthMM: 345,
    volumeL: 23.31,
    nominalDeltaT50KW: 1.478,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 763,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1202-08",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1200mm",
    depthMM: 217,
    heightMM: 1302,
    widthMM: 390,
    volumeL: 26.64,
    nominalDeltaT50KW: 1.69,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 855,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1202-09",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1200mm",
    depthMM: 217,
    heightMM: 1302,
    widthMM: 435,
    volumeL: 29.97,
    nominalDeltaT50KW: 1.901,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 948,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1202-10",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1200mm",
    depthMM: 217,
    heightMM: 1302,
    widthMM: 480,
    volumeL: 33.3,
    nominalDeltaT50KW: 2.112,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1040,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1202-11",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1200mm",
    depthMM: 217,
    heightMM: 1302,
    widthMM: 525,
    volumeL: 36.63,
    nominalDeltaT50KW: 2.323,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1133,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1202-12",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1200mm",
    depthMM: 217,
    heightMM: 1302,
    widthMM: 570,
    volumeL: 39.96,
    nominalDeltaT50KW: 2.534,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1225,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1202-13",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1200mm",
    depthMM: 217,
    heightMM: 1302,
    widthMM: 615,
    volumeL: 43.29,
    nominalDeltaT50KW: 2.746,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1318,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1202-14",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1200mm",
    depthMM: 217,
    heightMM: 1302,
    widthMM: 660,
    volumeL: 46.62,
    nominalDeltaT50KW: 2.957,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1410,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1202-15",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1200mm",
    depthMM: 217,
    heightMM: 1302,
    widthMM: 705,
    volumeL: 49.95,
    nominalDeltaT50KW: 3.168,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1503,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1202-16",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1200mm",
    depthMM: 217,
    heightMM: 1302,
    widthMM: 750,
    volumeL: 53.28,
    nominalDeltaT50KW: 3.379,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1595,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1202-17",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1200mm",
    depthMM: 217,
    heightMM: 1302,
    widthMM: 795,
    volumeL: 56.61,
    nominalDeltaT50KW: 3.59,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1688,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1202-18",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1200mm",
    depthMM: 217,
    heightMM: 1302,
    widthMM: 840,
    volumeL: 59.94,
    nominalDeltaT50KW: 3.802,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1780,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1202-19",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1200mm",
    depthMM: 217,
    heightMM: 1302,
    widthMM: 885,
    volumeL: 63.27,
    nominalDeltaT50KW: 4.013,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1873,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1202-20",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1200mm",
    depthMM: 217,
    heightMM: 1302,
    widthMM: 930,
    volumeL: 66.6,
    nominalDeltaT50KW: 4.224,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1965,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1202-21",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1200mm",
    depthMM: 217,
    heightMM: 1302,
    widthMM: 975,
    volumeL: 69.93,
    nominalDeltaT50KW: 4.435,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 2058,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1202-22",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1200mm",
    depthMM: 217,
    heightMM: 1302,
    widthMM: 1020,
    volumeL: 73.26,
    nominalDeltaT50KW: 4.646,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 2150,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1202-23",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1200mm",
    depthMM: 217,
    heightMM: 1302,
    widthMM: 1065,
    volumeL: 76.59,
    nominalDeltaT50KW: 4.858,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 2243,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1202-24",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1200mm",
    depthMM: 217,
    heightMM: 1302,
    widthMM: 1110,
    volumeL: 79.92,
    nominalDeltaT50KW: 5.069,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 2335,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1202-25",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1200mm",
    depthMM: 217,
    heightMM: 1302,
    widthMM: 1155,
    volumeL: 83.25,
    nominalDeltaT50KW: 5.28,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 2428,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1202-26",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1200mm",
    depthMM: 217,
    heightMM: 1302,
    widthMM: 1200,
    volumeL: 86.58,
    nominalDeltaT50KW: 5.491,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 2520,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1202-27",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1200mm",
    depthMM: 217,
    heightMM: 1302,
    widthMM: 1245,
    volumeL: 89.91,
    nominalDeltaT50KW: 5.702,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 2613,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1202-28",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1200mm",
    depthMM: 217,
    heightMM: 1302,
    widthMM: 1290,
    volumeL: 93.24,
    nominalDeltaT50KW: 5.914,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 2705,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1202-29",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1200mm",
    depthMM: 217,
    heightMM: 1302,
    widthMM: 1335,
    volumeL: 96.57,
    nominalDeltaT50KW: 6.125,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 2798,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1202-30",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1200mm",
    depthMM: 217,
    heightMM: 1302,
    widthMM: 1380,
    volumeL: 99.9,
    nominalDeltaT50KW: 6.336,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 2890,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1202-31",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1200mm",
    depthMM: 217,
    heightMM: 1302,
    widthMM: 1425,
    volumeL: 103.23,
    nominalDeltaT50KW: 6.547,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 2983,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1202-32",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1200mm",
    depthMM: 217,
    heightMM: 1302,
    widthMM: 1470,
    volumeL: 106.56,
    nominalDeltaT50KW: 6.758,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 3075,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1202-33",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1200mm",
    depthMM: 217,
    heightMM: 1302,
    widthMM: 1515,
    volumeL: 109.89,
    nominalDeltaT50KW: 6.97,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 3168,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1202-34",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1200mm",
    depthMM: 217,
    heightMM: 1302,
    widthMM: 1560,
    volumeL: 113.22,
    nominalDeltaT50KW: 7.181,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 3260,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1202-35",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1200mm",
    depthMM: 217,
    heightMM: 1302,
    widthMM: 1605,
    volumeL: 116.55,
    nominalDeltaT50KW: 7.392,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 3353,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1202-36",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1200mm",
    depthMM: 217,
    heightMM: 1302,
    widthMM: 1650,
    volumeL: 119.88,
    nominalDeltaT50KW: 7.603,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 3445,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1202-37",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1200mm",
    depthMM: 217,
    heightMM: 1302,
    widthMM: 1695,
    volumeL: 123.21,
    nominalDeltaT50KW: 7.814,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 3538,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1202-38",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1200mm",
    depthMM: 217,
    heightMM: 1302,
    widthMM: 1740,
    volumeL: 126.54,
    nominalDeltaT50KW: 8.026,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 3630,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1202-39",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1200mm",
    depthMM: 217,
    heightMM: 1302,
    widthMM: 1785,
    volumeL: 129.87,
    nominalDeltaT50KW: 8.237,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 3723,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1202-40",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1200mm",
    depthMM: 217,
    heightMM: 1302,
    widthMM: 1830,
    volumeL: 133.2,
    nominalDeltaT50KW: 8.448,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 3815,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1502-04",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1500mm",
    depthMM: 217,
    heightMM: 1602,
    widthMM: 210,
    volumeL: 16.24,
    nominalDeltaT50KW: 1.036,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 597,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1502-05",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1500mm",
    depthMM: 217,
    heightMM: 1602,
    widthMM: 255,
    volumeL: 20.3,
    nominalDeltaT50KW: 1.296,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 718,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1502-06",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1500mm",
    depthMM: 217,
    heightMM: 1602,
    widthMM: 300,
    volumeL: 24.36,
    nominalDeltaT50KW: 1.555,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 838,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1502-07",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1500mm",
    depthMM: 217,
    heightMM: 1602,
    widthMM: 345,
    volumeL: 28.42,
    nominalDeltaT50KW: 1.814,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 959,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1502-08",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1500mm",
    depthMM: 217,
    heightMM: 1602,
    widthMM: 390,
    volumeL: 32.48,
    nominalDeltaT50KW: 2.073,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1079,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1502-09",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1500mm",
    depthMM: 217,
    heightMM: 1602,
    widthMM: 435,
    volumeL: 36.54,
    nominalDeltaT50KW: 2.332,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1200,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1502-10",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1500mm",
    depthMM: 217,
    heightMM: 1602,
    widthMM: 480,
    volumeL: 40.6,
    nominalDeltaT50KW: 2.591,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1320,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1502-11",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1500mm",
    depthMM: 217,
    heightMM: 1602,
    widthMM: 525,
    volumeL: 44.66,
    nominalDeltaT50KW: 2.85,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1441,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1502-12",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1500mm",
    depthMM: 217,
    heightMM: 1602,
    widthMM: 570,
    volumeL: 48.72,
    nominalDeltaT50KW: 3.109,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1561,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1502-13",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1500mm",
    depthMM: 217,
    heightMM: 1602,
    widthMM: 615,
    volumeL: 52.78,
    nominalDeltaT50KW: 3.368,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1682,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1502-14",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1500mm",
    depthMM: 217,
    heightMM: 1602,
    widthMM: 660,
    volumeL: 56.84,
    nominalDeltaT50KW: 3.627,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1802,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1502-15",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1500mm",
    depthMM: 217,
    heightMM: 1602,
    widthMM: 705,
    volumeL: 60.9,
    nominalDeltaT50KW: 3.887,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 1923,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1502-16",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1500mm",
    depthMM: 217,
    heightMM: 1602,
    widthMM: 750,
    volumeL: 64.96,
    nominalDeltaT50KW: 4.146,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 2043,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1502-17",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1500mm",
    depthMM: 217,
    heightMM: 1602,
    widthMM: 795,
    volumeL: 69.02,
    nominalDeltaT50KW: 4.405,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 2164,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1502-18",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1500mm",
    depthMM: 217,
    heightMM: 1602,
    widthMM: 840,
    volumeL: 73.08,
    nominalDeltaT50KW: 4.664,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 2284,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1502-19",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1500mm",
    depthMM: 217,
    heightMM: 1602,
    widthMM: 885,
    volumeL: 77.14,
    nominalDeltaT50KW: 4.923,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 2405,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1502-20",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1500mm",
    depthMM: 217,
    heightMM: 1602,
    widthMM: 930,
    volumeL: 81.2,
    nominalDeltaT50KW: 5.182,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 2525,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1502-21",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1500mm",
    depthMM: 217,
    heightMM: 1602,
    widthMM: 975,
    volumeL: 85.26,
    nominalDeltaT50KW: 5.441,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 2646,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1502-22",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1500mm",
    depthMM: 217,
    heightMM: 1602,
    widthMM: 1020,
    volumeL: 89.32,
    nominalDeltaT50KW: 5.7,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 2766,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1502-23",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1500mm",
    depthMM: 217,
    heightMM: 1602,
    widthMM: 1065,
    volumeL: 93.38,
    nominalDeltaT50KW: 5.959,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 2887,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1502-24",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1500mm",
    depthMM: 217,
    heightMM: 1602,
    widthMM: 1110,
    volumeL: 97.44,
    nominalDeltaT50KW: 6.218,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 3007,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1502-25",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1500mm",
    depthMM: 217,
    heightMM: 1602,
    widthMM: 1155,
    volumeL: 101.5,
    nominalDeltaT50KW: 6.478,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 3128,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1502-26",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1500mm",
    depthMM: 217,
    heightMM: 1602,
    widthMM: 1200,
    volumeL: 105.56,
    nominalDeltaT50KW: 6.737,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 3248,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1502-27",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1500mm",
    depthMM: 217,
    heightMM: 1602,
    widthMM: 1245,
    volumeL: 109.62,
    nominalDeltaT50KW: 6.996,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 3369,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1502-28",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1500mm",
    depthMM: 217,
    heightMM: 1602,
    widthMM: 1290,
    volumeL: 113.68,
    nominalDeltaT50KW: 7.255,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 3489,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1502-29",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1500mm",
    depthMM: 217,
    heightMM: 1602,
    widthMM: 1335,
    volumeL: 117.74,
    nominalDeltaT50KW: 7.514,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 3610,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1502-30",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1500mm",
    depthMM: 217,
    heightMM: 1602,
    widthMM: 1380,
    volumeL: 121.8,
    nominalDeltaT50KW: 7.773,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 3730,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1502-31",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1500mm",
    depthMM: 217,
    heightMM: 1602,
    widthMM: 1425,
    volumeL: 125.86,
    nominalDeltaT50KW: 8.032,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 3851,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1502-32",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1500mm",
    depthMM: 217,
    heightMM: 1602,
    widthMM: 1470,
    volumeL: 129.92,
    nominalDeltaT50KW: 8.291,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 3971,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1502-33",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1500mm",
    depthMM: 217,
    heightMM: 1602,
    widthMM: 1515,
    volumeL: 133.98,
    nominalDeltaT50KW: 8.55,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 4092,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1502-34",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1500mm",
    depthMM: 217,
    heightMM: 1602,
    widthMM: 1560,
    volumeL: 138.04,
    nominalDeltaT50KW: 8.809,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 4212,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1502-35",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1500mm",
    depthMM: 217,
    heightMM: 1602,
    widthMM: 1605,
    volumeL: 142.1,
    nominalDeltaT50KW: 9.069,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 4333,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1502-36",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1500mm",
    depthMM: 217,
    heightMM: 1602,
    widthMM: 1650,
    volumeL: 146.16,
    nominalDeltaT50KW: 9.328,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 4453,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1502-37",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1500mm",
    depthMM: 217,
    heightMM: 1602,
    widthMM: 1695,
    volumeL: 150.22,
    nominalDeltaT50KW: 9.587,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 4574,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1502-38",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1500mm",
    depthMM: 217,
    heightMM: 1602,
    widthMM: 1740,
    volumeL: 154.28,
    nominalDeltaT50KW: 9.846,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 4694,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1502-39",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1500mm",
    depthMM: 217,
    heightMM: 1602,
    widthMM: 1785,
    volumeL: 158.34,
    nominalDeltaT50KW: 10.105,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 4815,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1502-40",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1500mm",
    depthMM: 217,
    heightMM: 1602,
    widthMM: 1830,
    volumeL: 162.4,
    nominalDeltaT50KW: 10.364,
    kvValue: 20,
    nCoefficient: 1.35,
    price: {
      value: 4935,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1802-04",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1800mm",
    depthMM: 217,
    heightMM: 1902,
    widthMM: 210,
    volumeL: 19.2,
    nominalDeltaT50KW: 1.226,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 677,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1802-05",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1800mm",
    depthMM: 217,
    heightMM: 1902,
    widthMM: 255,
    volumeL: 24,
    nominalDeltaT50KW: 1.533,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 818,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1802-06",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1800mm",
    depthMM: 217,
    heightMM: 1902,
    widthMM: 300,
    volumeL: 28.8,
    nominalDeltaT50KW: 1.839,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 958,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1802-07",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1800mm",
    depthMM: 217,
    heightMM: 1902,
    widthMM: 345,
    volumeL: 33.6,
    nominalDeltaT50KW: 2.146,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1099,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1802-08",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1800mm",
    depthMM: 217,
    heightMM: 1902,
    widthMM: 390,
    volumeL: 38.4,
    nominalDeltaT50KW: 2.452,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1239,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1802-09",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1800mm",
    depthMM: 217,
    heightMM: 1902,
    widthMM: 435,
    volumeL: 43.2,
    nominalDeltaT50KW: 2.759,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1380,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1802-10",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1800mm",
    depthMM: 217,
    heightMM: 1902,
    widthMM: 480,
    volumeL: 48,
    nominalDeltaT50KW: 3.065,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1520,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1802-11",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1800mm",
    depthMM: 217,
    heightMM: 1902,
    widthMM: 525,
    volumeL: 52.8,
    nominalDeltaT50KW: 3.372,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1661,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1802-12",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1800mm",
    depthMM: 217,
    heightMM: 1902,
    widthMM: 570,
    volumeL: 57.6,
    nominalDeltaT50KW: 3.678,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1801,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1802-13",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1800mm",
    depthMM: 217,
    heightMM: 1902,
    widthMM: 615,
    volumeL: 62.4,
    nominalDeltaT50KW: 3.985,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1942,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1802-14",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1800mm",
    depthMM: 217,
    heightMM: 1902,
    widthMM: 660,
    volumeL: 67.2,
    nominalDeltaT50KW: 4.291,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2082,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1802-15",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1800mm",
    depthMM: 217,
    heightMM: 1902,
    widthMM: 705,
    volumeL: 72,
    nominalDeltaT50KW: 4.598,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2223,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1802-16",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1800mm",
    depthMM: 217,
    heightMM: 1902,
    widthMM: 750,
    volumeL: 76.8,
    nominalDeltaT50KW: 4.904,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2363,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1802-17",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1800mm",
    depthMM: 217,
    heightMM: 1902,
    widthMM: 795,
    volumeL: 81.6,
    nominalDeltaT50KW: 5.211,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2504,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1802-18",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1800mm",
    depthMM: 217,
    heightMM: 1902,
    widthMM: 840,
    volumeL: 86.4,
    nominalDeltaT50KW: 5.517,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2644,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1802-19",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1800mm",
    depthMM: 217,
    heightMM: 1902,
    widthMM: 885,
    volumeL: 91.2,
    nominalDeltaT50KW: 5.824,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2785,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1802-20",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1800mm",
    depthMM: 217,
    heightMM: 1902,
    widthMM: 930,
    volumeL: 96,
    nominalDeltaT50KW: 6.13,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2925,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1802-21",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1800mm",
    depthMM: 217,
    heightMM: 1902,
    widthMM: 975,
    volumeL: 100.8,
    nominalDeltaT50KW: 6.437,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 3066,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1802-22",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1800mm",
    depthMM: 217,
    heightMM: 1902,
    widthMM: 1020,
    volumeL: 105.6,
    nominalDeltaT50KW: 6.743,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 3206,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1802-23",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1800mm",
    depthMM: 217,
    heightMM: 1902,
    widthMM: 1065,
    volumeL: 110.4,
    nominalDeltaT50KW: 7.05,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 3347,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1802-24",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1800mm",
    depthMM: 217,
    heightMM: 1902,
    widthMM: 1110,
    volumeL: 115.2,
    nominalDeltaT50KW: 7.356,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 3487,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1802-25",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1800mm",
    depthMM: 217,
    heightMM: 1902,
    widthMM: 1155,
    volumeL: 120,
    nominalDeltaT50KW: 7.663,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 3628,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1802-26",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1800mm",
    depthMM: 217,
    heightMM: 1902,
    widthMM: 1200,
    volumeL: 124.8,
    nominalDeltaT50KW: 7.969,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 3768,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1802-27",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1800mm",
    depthMM: 217,
    heightMM: 1902,
    widthMM: 1245,
    volumeL: 129.6,
    nominalDeltaT50KW: 8.276,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 3909,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1802-28",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1800mm",
    depthMM: 217,
    heightMM: 1902,
    widthMM: 1290,
    volumeL: 134.4,
    nominalDeltaT50KW: 8.582,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 4049,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1802-29",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1800mm",
    depthMM: 217,
    heightMM: 1902,
    widthMM: 1335,
    volumeL: 139.2,
    nominalDeltaT50KW: 8.889,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 4190,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1802-30",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1800mm",
    depthMM: 217,
    heightMM: 1902,
    widthMM: 1380,
    volumeL: 144,
    nominalDeltaT50KW: 9.195,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 4330,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1802-31",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1800mm",
    depthMM: 217,
    heightMM: 1902,
    widthMM: 1425,
    volumeL: 148.8,
    nominalDeltaT50KW: 9.502,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 4471,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1802-32",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1800mm",
    depthMM: 217,
    heightMM: 1902,
    widthMM: 1470,
    volumeL: 153.6,
    nominalDeltaT50KW: 9.808,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 4611,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1802-33",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1800mm",
    depthMM: 217,
    heightMM: 1902,
    widthMM: 1515,
    volumeL: 158.4,
    nominalDeltaT50KW: 10.115,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 4752,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1802-34",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1800mm",
    depthMM: 217,
    heightMM: 1902,
    widthMM: 1560,
    volumeL: 163.2,
    nominalDeltaT50KW: 10.421,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 4892,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1802-35",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1800mm",
    depthMM: 217,
    heightMM: 1902,
    widthMM: 1605,
    volumeL: 168,
    nominalDeltaT50KW: 10.728,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 5033,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1802-36",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1800mm",
    depthMM: 217,
    heightMM: 1902,
    widthMM: 1650,
    volumeL: 172.8,
    nominalDeltaT50KW: 11.034,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 5173,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1802-37",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1800mm",
    depthMM: 217,
    heightMM: 1902,
    widthMM: 1695,
    volumeL: 177.6,
    nominalDeltaT50KW: 11.341,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 5314,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1802-38",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1800mm",
    depthMM: 217,
    heightMM: 1902,
    widthMM: 1740,
    volumeL: 182.4,
    nominalDeltaT50KW: 11.647,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 5454,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1802-39",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1800mm",
    depthMM: 217,
    heightMM: 1902,
    widthMM: 1785,
    volumeL: 187.2,
    nominalDeltaT50KW: 11.954,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 5595,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-1802-40",
    rangeType: "Florence 6",
    model: "Florence 6 Column 1800mm",
    depthMM: 217,
    heightMM: 1902,
    widthMM: 1830,
    volumeL: 192,
    nominalDeltaT50KW: 12.26,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 5735,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2002-04",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2000mm",
    depthMM: 217,
    heightMM: 2102,
    widthMM: 210,
    volumeL: 21.16,
    nominalDeltaT50KW: 1.352,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 685,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2002-05",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2000mm",
    depthMM: 217,
    heightMM: 2102,
    widthMM: 255,
    volumeL: 26.45,
    nominalDeltaT50KW: 1.69,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 828,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2002-06",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2000mm",
    depthMM: 217,
    heightMM: 2102,
    widthMM: 300,
    volumeL: 31.74,
    nominalDeltaT50KW: 2.027,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 970,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2002-07",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2000mm",
    depthMM: 217,
    heightMM: 2102,
    widthMM: 345,
    volumeL: 37.03,
    nominalDeltaT50KW: 2.365,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1113,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2002-08",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2000mm",
    depthMM: 217,
    heightMM: 2102,
    widthMM: 390,
    volumeL: 42.32,
    nominalDeltaT50KW: 2.703,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1255,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2002-09",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2000mm",
    depthMM: 217,
    heightMM: 2102,
    widthMM: 435,
    volumeL: 47.61,
    nominalDeltaT50KW: 3.041,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1398,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2002-10",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2000mm",
    depthMM: 217,
    heightMM: 2102,
    widthMM: 480,
    volumeL: 52.9,
    nominalDeltaT50KW: 3.379,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1540,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2002-11",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2000mm",
    depthMM: 217,
    heightMM: 2102,
    widthMM: 525,
    volumeL: 58.19,
    nominalDeltaT50KW: 3.717,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1683,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2002-12",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2000mm",
    depthMM: 217,
    heightMM: 2102,
    widthMM: 570,
    volumeL: 63.48,
    nominalDeltaT50KW: 4.055,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1825,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2002-13",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2000mm",
    depthMM: 217,
    heightMM: 2102,
    widthMM: 615,
    volumeL: 68.77,
    nominalDeltaT50KW: 4.393,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1968,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2002-14",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2000mm",
    depthMM: 217,
    heightMM: 2102,
    widthMM: 660,
    volumeL: 74.06,
    nominalDeltaT50KW: 4.731,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2110,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2002-15",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2000mm",
    depthMM: 217,
    heightMM: 2102,
    widthMM: 705,
    volumeL: 79.35,
    nominalDeltaT50KW: 5.069,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2253,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2002-16",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2000mm",
    depthMM: 217,
    heightMM: 2102,
    widthMM: 750,
    volumeL: 84.64,
    nominalDeltaT50KW: 5.406,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2395,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2002-17",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2000mm",
    depthMM: 217,
    heightMM: 2102,
    widthMM: 795,
    volumeL: 89.93,
    nominalDeltaT50KW: 5.744,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2538,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2002-18",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2000mm",
    depthMM: 217,
    heightMM: 2102,
    widthMM: 840,
    volumeL: 95.22,
    nominalDeltaT50KW: 6.082,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2680,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2002-19",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2000mm",
    depthMM: 217,
    heightMM: 2102,
    widthMM: 885,
    volumeL: 100.51,
    nominalDeltaT50KW: 6.42,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2823,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2002-20",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2000mm",
    depthMM: 217,
    heightMM: 2102,
    widthMM: 930,
    volumeL: 105.8,
    nominalDeltaT50KW: 6.758,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2965,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2002-21",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2000mm",
    depthMM: 217,
    heightMM: 2102,
    widthMM: 975,
    volumeL: 111.09,
    nominalDeltaT50KW: 7.096,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 3108,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2002-22",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2000mm",
    depthMM: 217,
    heightMM: 2102,
    widthMM: 1020,
    volumeL: 116.38,
    nominalDeltaT50KW: 7.434,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 3250,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2002-23",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2000mm",
    depthMM: 217,
    heightMM: 2102,
    widthMM: 1065,
    volumeL: 121.67,
    nominalDeltaT50KW: 7.772,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 3393,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2002-24",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2000mm",
    depthMM: 217,
    heightMM: 2102,
    widthMM: 1110,
    volumeL: 126.96,
    nominalDeltaT50KW: 8.11,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 3535,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2002-25",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2000mm",
    depthMM: 217,
    heightMM: 2102,
    widthMM: 1155,
    volumeL: 132.25,
    nominalDeltaT50KW: 8.448,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 3678,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2002-26",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2000mm",
    depthMM: 217,
    heightMM: 2102,
    widthMM: 1200,
    volumeL: 137.54,
    nominalDeltaT50KW: 8.785,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 3820,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2002-27",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2000mm",
    depthMM: 217,
    heightMM: 2102,
    widthMM: 1245,
    volumeL: 142.83,
    nominalDeltaT50KW: 9.123,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 3963,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2002-28",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2000mm",
    depthMM: 217,
    heightMM: 2102,
    widthMM: 1290,
    volumeL: 148.12,
    nominalDeltaT50KW: 9.461,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 4105,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2002-29",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2000mm",
    depthMM: 217,
    heightMM: 2102,
    widthMM: 1335,
    volumeL: 153.41,
    nominalDeltaT50KW: 9.799,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 4248,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2002-30",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2000mm",
    depthMM: 217,
    heightMM: 2102,
    widthMM: 1380,
    volumeL: 158.7,
    nominalDeltaT50KW: 10.137,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 4390,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2002-31",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2000mm",
    depthMM: 217,
    heightMM: 2102,
    widthMM: 1425,
    volumeL: 163.99,
    nominalDeltaT50KW: 10.475,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 4533,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2002-32",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2000mm",
    depthMM: 217,
    heightMM: 2102,
    widthMM: 1470,
    volumeL: 169.28,
    nominalDeltaT50KW: 10.813,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 4675,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2002-33",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2000mm",
    depthMM: 217,
    heightMM: 2102,
    widthMM: 1515,
    volumeL: 174.57,
    nominalDeltaT50KW: 11.151,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 4818,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2002-34",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2000mm",
    depthMM: 217,
    heightMM: 2102,
    widthMM: 1560,
    volumeL: 179.86,
    nominalDeltaT50KW: 11.489,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 4960,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2002-35",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2000mm",
    depthMM: 217,
    heightMM: 2102,
    widthMM: 1605,
    volumeL: 185.15,
    nominalDeltaT50KW: 11.827,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 5103,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2002-36",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2000mm",
    depthMM: 217,
    heightMM: 2102,
    widthMM: 1650,
    volumeL: 190.44,
    nominalDeltaT50KW: 12.164,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 5245,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2002-37",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2000mm",
    depthMM: 217,
    heightMM: 2102,
    widthMM: 1695,
    volumeL: 195.73,
    nominalDeltaT50KW: 12.502,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 5388,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2002-38",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2000mm",
    depthMM: 217,
    heightMM: 2102,
    widthMM: 1740,
    volumeL: 201.02,
    nominalDeltaT50KW: 12.84,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 5530,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2002-39",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2000mm",
    depthMM: 217,
    heightMM: 2102,
    widthMM: 1785,
    volumeL: 206.31,
    nominalDeltaT50KW: 13.178,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 5673,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2002-40",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2000mm",
    depthMM: 217,
    heightMM: 2102,
    widthMM: 1830,
    volumeL: 211.6,
    nominalDeltaT50KW: 13.516,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 5815,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-194-04",
    rangeType: "Florence 6",
    model: "Florence 6 Column 200mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 210,
    volumeL: 3.44,
    nominalDeltaT50KW: 0.15,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 281,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-194-05",
    rangeType: "Florence 6",
    model: "Florence 6 Column 200mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 255,
    volumeL: 4.3,
    nominalDeltaT50KW: 0.187,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 323,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-194-06",
    rangeType: "Florence 6",
    model: "Florence 6 Column 200mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 300,
    volumeL: 5.16,
    nominalDeltaT50KW: 0.224,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 364,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-194-07",
    rangeType: "Florence 6",
    model: "Florence 6 Column 200mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 345,
    volumeL: 6.02,
    nominalDeltaT50KW: 0.262,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 406,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-194-08",
    rangeType: "Florence 6",
    model: "Florence 6 Column 200mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 390,
    volumeL: 6.88,
    nominalDeltaT50KW: 0.299,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 447,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-194-09",
    rangeType: "Florence 6",
    model: "Florence 6 Column 200mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 435,
    volumeL: 7.74,
    nominalDeltaT50KW: 0.337,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 489,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-194-10",
    rangeType: "Florence 6",
    model: "Florence 6 Column 200mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 480,
    volumeL: 8.6,
    nominalDeltaT50KW: 0.374,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 530,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-194-11",
    rangeType: "Florence 6",
    model: "Florence 6 Column 200mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 525,
    volumeL: 9.46,
    nominalDeltaT50KW: 0.411,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 572,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-194-12",
    rangeType: "Florence 6",
    model: "Florence 6 Column 200mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 570,
    volumeL: 10.32,
    nominalDeltaT50KW: 0.449,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 613,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-194-13",
    rangeType: "Florence 6",
    model: "Florence 6 Column 200mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 615,
    volumeL: 11.18,
    nominalDeltaT50KW: 0.486,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 655,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-194-14",
    rangeType: "Florence 6",
    model: "Florence 6 Column 200mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 660,
    volumeL: 12.04,
    nominalDeltaT50KW: 0.524,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 696,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-194-15",
    rangeType: "Florence 6",
    model: "Florence 6 Column 200mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 705,
    volumeL: 12.9,
    nominalDeltaT50KW: 0.561,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 738,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-194-16",
    rangeType: "Florence 6",
    model: "Florence 6 Column 200mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 750,
    volumeL: 13.76,
    nominalDeltaT50KW: 0.598,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 779,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-194-17",
    rangeType: "Florence 6",
    model: "Florence 6 Column 200mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 795,
    volumeL: 14.62,
    nominalDeltaT50KW: 0.636,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 821,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-194-18",
    rangeType: "Florence 6",
    model: "Florence 6 Column 200mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 840,
    volumeL: 15.48,
    nominalDeltaT50KW: 0.673,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 862,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-194-19",
    rangeType: "Florence 6",
    model: "Florence 6 Column 200mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 885,
    volumeL: 16.34,
    nominalDeltaT50KW: 0.711,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 904,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-194-20",
    rangeType: "Florence 6",
    model: "Florence 6 Column 200mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 930,
    volumeL: 17.2,
    nominalDeltaT50KW: 0.748,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 945,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-194-21",
    rangeType: "Florence 6",
    model: "Florence 6 Column 200mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 975,
    volumeL: 18.06,
    nominalDeltaT50KW: 0.785,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 987,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-194-22",
    rangeType: "Florence 6",
    model: "Florence 6 Column 200mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 1020,
    volumeL: 18.92,
    nominalDeltaT50KW: 0.823,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1028,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-194-23",
    rangeType: "Florence 6",
    model: "Florence 6 Column 200mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 1065,
    volumeL: 19.78,
    nominalDeltaT50KW: 0.86,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1070,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-194-24",
    rangeType: "Florence 6",
    model: "Florence 6 Column 200mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 1110,
    volumeL: 20.64,
    nominalDeltaT50KW: 0.898,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1111,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-194-25",
    rangeType: "Florence 6",
    model: "Florence 6 Column 200mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 1155,
    volumeL: 21.5,
    nominalDeltaT50KW: 0.935,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1153,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-194-26",
    rangeType: "Florence 6",
    model: "Florence 6 Column 200mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 1200,
    volumeL: 22.36,
    nominalDeltaT50KW: 0.972,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1194,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-194-27",
    rangeType: "Florence 6",
    model: "Florence 6 Column 200mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 1245,
    volumeL: 23.22,
    nominalDeltaT50KW: 1.01,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1236,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-194-28",
    rangeType: "Florence 6",
    model: "Florence 6 Column 200mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 1290,
    volumeL: 24.08,
    nominalDeltaT50KW: 1.047,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1277,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-194-29",
    rangeType: "Florence 6",
    model: "Florence 6 Column 200mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 1335,
    volumeL: 24.94,
    nominalDeltaT50KW: 1.085,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1319,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-194-30",
    rangeType: "Florence 6",
    model: "Florence 6 Column 200mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 1380,
    volumeL: 25.8,
    nominalDeltaT50KW: 1.122,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1360,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-194-31",
    rangeType: "Florence 6",
    model: "Florence 6 Column 200mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 1425,
    volumeL: 26.66,
    nominalDeltaT50KW: 1.159,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1402,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-194-32",
    rangeType: "Florence 6",
    model: "Florence 6 Column 200mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 1470,
    volumeL: 27.52,
    nominalDeltaT50KW: 1.197,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1443,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-194-33",
    rangeType: "Florence 6",
    model: "Florence 6 Column 200mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 1515,
    volumeL: 28.38,
    nominalDeltaT50KW: 1.234,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1485,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-194-34",
    rangeType: "Florence 6",
    model: "Florence 6 Column 200mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 1560,
    volumeL: 29.24,
    nominalDeltaT50KW: 1.272,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1526,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-194-35",
    rangeType: "Florence 6",
    model: "Florence 6 Column 200mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 1605,
    volumeL: 30.1,
    nominalDeltaT50KW: 1.309,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1568,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-194-36",
    rangeType: "Florence 6",
    model: "Florence 6 Column 200mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 1650,
    volumeL: 30.96,
    nominalDeltaT50KW: 1.346,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1609,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-194-37",
    rangeType: "Florence 6",
    model: "Florence 6 Column 200mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 1695,
    volumeL: 31.82,
    nominalDeltaT50KW: 1.384,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1651,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-194-38",
    rangeType: "Florence 6",
    model: "Florence 6 Column 200mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 1740,
    volumeL: 32.68,
    nominalDeltaT50KW: 1.421,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1692,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-194-39",
    rangeType: "Florence 6",
    model: "Florence 6 Column 200mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 1785,
    volumeL: 33.54,
    nominalDeltaT50KW: 1.459,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1734,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-194-40",
    rangeType: "Florence 6",
    model: "Florence 6 Column 200mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 1830,
    volumeL: 34.4,
    nominalDeltaT50KW: 1.496,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1775,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2202-04",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2200mm",
    depthMM: 217,
    heightMM: 2302,
    widthMM: 210,
    volumeL: 23.12,
    nominalDeltaT50KW: 1.477,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 841,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2202-05",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2200mm",
    depthMM: 217,
    heightMM: 2302,
    widthMM: 255,
    volumeL: 28.9,
    nominalDeltaT50KW: 1.847,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1023,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2202-06",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2200mm",
    depthMM: 217,
    heightMM: 2302,
    widthMM: 300,
    volumeL: 34.68,
    nominalDeltaT50KW: 2.216,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1204,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2202-07",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2200mm",
    depthMM: 217,
    heightMM: 2302,
    widthMM: 345,
    volumeL: 40.46,
    nominalDeltaT50KW: 2.585,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1386,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2202-08",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2200mm",
    depthMM: 217,
    heightMM: 2302,
    widthMM: 390,
    volumeL: 46.24,
    nominalDeltaT50KW: 2.954,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1567,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2202-09",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2200mm",
    depthMM: 217,
    heightMM: 2302,
    widthMM: 435,
    volumeL: 52.02,
    nominalDeltaT50KW: 3.324,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1749,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2202-10",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2200mm",
    depthMM: 217,
    heightMM: 2302,
    widthMM: 480,
    volumeL: 57.8,
    nominalDeltaT50KW: 3.693,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1930,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2202-11",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2200mm",
    depthMM: 217,
    heightMM: 2302,
    widthMM: 525,
    volumeL: 63.58,
    nominalDeltaT50KW: 4.062,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2112,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2202-12",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2200mm",
    depthMM: 217,
    heightMM: 2302,
    widthMM: 570,
    volumeL: 69.36,
    nominalDeltaT50KW: 4.432,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2293,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2202-13",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2200mm",
    depthMM: 217,
    heightMM: 2302,
    widthMM: 615,
    volumeL: 75.14,
    nominalDeltaT50KW: 4.801,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2475,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2202-14",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2200mm",
    depthMM: 217,
    heightMM: 2302,
    widthMM: 660,
    volumeL: 80.92,
    nominalDeltaT50KW: 5.17,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2656,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2202-15",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2200mm",
    depthMM: 217,
    heightMM: 2302,
    widthMM: 705,
    volumeL: 86.7,
    nominalDeltaT50KW: 5.54,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2838,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2202-16",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2200mm",
    depthMM: 217,
    heightMM: 2302,
    widthMM: 750,
    volumeL: 92.48,
    nominalDeltaT50KW: 5.909,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 3019,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2202-17",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2200mm",
    depthMM: 217,
    heightMM: 2302,
    widthMM: 795,
    volumeL: 98.26,
    nominalDeltaT50KW: 6.278,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 3201,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2202-18",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2200mm",
    depthMM: 217,
    heightMM: 2302,
    widthMM: 840,
    volumeL: 104.04,
    nominalDeltaT50KW: 6.647,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 3382,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2202-19",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2200mm",
    depthMM: 217,
    heightMM: 2302,
    widthMM: 885,
    volumeL: 109.82,
    nominalDeltaT50KW: 7.017,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 3564,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2202-20",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2200mm",
    depthMM: 217,
    heightMM: 2302,
    widthMM: 930,
    volumeL: 115.6,
    nominalDeltaT50KW: 7.386,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 3745,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2202-21",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2200mm",
    depthMM: 217,
    heightMM: 2302,
    widthMM: 975,
    volumeL: 121.38,
    nominalDeltaT50KW: 7.755,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 3927,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2202-22",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2200mm",
    depthMM: 217,
    heightMM: 2302,
    widthMM: 1020,
    volumeL: 127.16,
    nominalDeltaT50KW: 8.125,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 4108,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2202-23",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2200mm",
    depthMM: 217,
    heightMM: 2302,
    widthMM: 1065,
    volumeL: 132.94,
    nominalDeltaT50KW: 8.494,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 4290,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2202-24",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2200mm",
    depthMM: 217,
    heightMM: 2302,
    widthMM: 1110,
    volumeL: 138.72,
    nominalDeltaT50KW: 8.863,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 4471,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2202-25",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2200mm",
    depthMM: 217,
    heightMM: 2302,
    widthMM: 1155,
    volumeL: 144.5,
    nominalDeltaT50KW: 9.233,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 4653,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2202-26",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2200mm",
    depthMM: 217,
    heightMM: 2302,
    widthMM: 1200,
    volumeL: 150.28,
    nominalDeltaT50KW: 9.602,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 4834,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2202-27",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2200mm",
    depthMM: 217,
    heightMM: 2302,
    widthMM: 1245,
    volumeL: 156.06,
    nominalDeltaT50KW: 9.971,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 5016,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2202-28",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2200mm",
    depthMM: 217,
    heightMM: 2302,
    widthMM: 1290,
    volumeL: 161.84,
    nominalDeltaT50KW: 10.34,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 5197,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2202-29",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2200mm",
    depthMM: 217,
    heightMM: 2302,
    widthMM: 1335,
    volumeL: 167.62,
    nominalDeltaT50KW: 10.71,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 5379,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2202-30",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2200mm",
    depthMM: 217,
    heightMM: 2302,
    widthMM: 1380,
    volumeL: 173.4,
    nominalDeltaT50KW: 11.079,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 5560,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2202-31",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2200mm",
    depthMM: 217,
    heightMM: 2302,
    widthMM: 1425,
    volumeL: 179.18,
    nominalDeltaT50KW: 11.448,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 5742,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2202-32",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2200mm",
    depthMM: 217,
    heightMM: 2302,
    widthMM: 1470,
    volumeL: 184.96,
    nominalDeltaT50KW: 11.818,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 5923,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2202-33",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2200mm",
    depthMM: 217,
    heightMM: 2302,
    widthMM: 1515,
    volumeL: 190.74,
    nominalDeltaT50KW: 12.187,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 6105,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2202-34",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2200mm",
    depthMM: 217,
    heightMM: 2302,
    widthMM: 1560,
    volumeL: 196.52,
    nominalDeltaT50KW: 12.556,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 6286,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2202-35",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2200mm",
    depthMM: 217,
    heightMM: 2302,
    widthMM: 1605,
    volumeL: 202.3,
    nominalDeltaT50KW: 12.926,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 6468,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2202-36",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2200mm",
    depthMM: 217,
    heightMM: 2302,
    widthMM: 1650,
    volumeL: 208.08,
    nominalDeltaT50KW: 13.295,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 6649,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2202-37",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2200mm",
    depthMM: 217,
    heightMM: 2302,
    widthMM: 1695,
    volumeL: 213.86,
    nominalDeltaT50KW: 13.664,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 6831,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2202-38",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2200mm",
    depthMM: 217,
    heightMM: 2302,
    widthMM: 1740,
    volumeL: 219.64,
    nominalDeltaT50KW: 14.033,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 7012,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2202-39",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2200mm",
    depthMM: 217,
    heightMM: 2302,
    widthMM: 1785,
    volumeL: 225.42,
    nominalDeltaT50KW: 14.403,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 7194,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2202-40",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2200mm",
    depthMM: 217,
    heightMM: 2302,
    widthMM: 1830,
    volumeL: 231.2,
    nominalDeltaT50KW: 14.772,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 7375,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2502-04",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2500mm",
    depthMM: 217,
    heightMM: 2602,
    widthMM: 210,
    volumeL: 26.04,
    nominalDeltaT50KW: 1.665,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 889,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2502-05",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2500mm",
    depthMM: 217,
    heightMM: 2602,
    widthMM: 255,
    volumeL: 32.55,
    nominalDeltaT50KW: 2.081,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1083,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2502-06",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2500mm",
    depthMM: 217,
    heightMM: 2602,
    widthMM: 300,
    volumeL: 39.06,
    nominalDeltaT50KW: 2.497,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1276,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2502-07",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2500mm",
    depthMM: 217,
    heightMM: 2602,
    widthMM: 345,
    volumeL: 45.57,
    nominalDeltaT50KW: 2.913,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1470,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2502-08",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2500mm",
    depthMM: 217,
    heightMM: 2602,
    widthMM: 390,
    volumeL: 52.08,
    nominalDeltaT50KW: 3.33,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1663,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2502-09",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2500mm",
    depthMM: 217,
    heightMM: 2602,
    widthMM: 435,
    volumeL: 58.59,
    nominalDeltaT50KW: 3.746,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 1857,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2502-10",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2500mm",
    depthMM: 217,
    heightMM: 2602,
    widthMM: 480,
    volumeL: 65.1,
    nominalDeltaT50KW: 4.162,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2050,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2502-11",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2500mm",
    depthMM: 217,
    heightMM: 2602,
    widthMM: 525,
    volumeL: 71.61,
    nominalDeltaT50KW: 4.578,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2244,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2502-12",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2500mm",
    depthMM: 217,
    heightMM: 2602,
    widthMM: 570,
    volumeL: 78.12,
    nominalDeltaT50KW: 4.994,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2437,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2502-13",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2500mm",
    depthMM: 217,
    heightMM: 2602,
    widthMM: 615,
    volumeL: 84.63,
    nominalDeltaT50KW: 5.411,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2631,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2502-14",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2500mm",
    depthMM: 217,
    heightMM: 2602,
    widthMM: 660,
    volumeL: 91.14,
    nominalDeltaT50KW: 5.827,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 2824,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2502-15",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2500mm",
    depthMM: 217,
    heightMM: 2602,
    widthMM: 705,
    volumeL: 97.65,
    nominalDeltaT50KW: 6.243,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 3018,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2502-16",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2500mm",
    depthMM: 217,
    heightMM: 2602,
    widthMM: 750,
    volumeL: 104.16,
    nominalDeltaT50KW: 6.659,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 3211,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2502-17",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2500mm",
    depthMM: 217,
    heightMM: 2602,
    widthMM: 795,
    volumeL: 110.67,
    nominalDeltaT50KW: 7.075,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 3405,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2502-18",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2500mm",
    depthMM: 217,
    heightMM: 2602,
    widthMM: 840,
    volumeL: 117.18,
    nominalDeltaT50KW: 7.492,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 3598,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2502-19",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2500mm",
    depthMM: 217,
    heightMM: 2602,
    widthMM: 885,
    volumeL: 123.69,
    nominalDeltaT50KW: 7.908,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 3792,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2502-20",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2500mm",
    depthMM: 217,
    heightMM: 2602,
    widthMM: 930,
    volumeL: 130.2,
    nominalDeltaT50KW: 8.324,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 3985,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2502-21",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2500mm",
    depthMM: 217,
    heightMM: 2602,
    widthMM: 975,
    volumeL: 136.71,
    nominalDeltaT50KW: 8.74,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 4179,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2502-22",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2500mm",
    depthMM: 217,
    heightMM: 2602,
    widthMM: 1020,
    volumeL: 143.22,
    nominalDeltaT50KW: 9.156,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 4372,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2502-23",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2500mm",
    depthMM: 217,
    heightMM: 2602,
    widthMM: 1065,
    volumeL: 149.73,
    nominalDeltaT50KW: 9.573,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 4566,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2502-24",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2500mm",
    depthMM: 217,
    heightMM: 2602,
    widthMM: 1110,
    volumeL: 156.24,
    nominalDeltaT50KW: 9.989,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 4759,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2502-25",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2500mm",
    depthMM: 217,
    heightMM: 2602,
    widthMM: 1155,
    volumeL: 162.75,
    nominalDeltaT50KW: 10.405,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 4953,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2502-26",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2500mm",
    depthMM: 217,
    heightMM: 2602,
    widthMM: 1200,
    volumeL: 169.26,
    nominalDeltaT50KW: 10.821,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 5146,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2502-27",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2500mm",
    depthMM: 217,
    heightMM: 2602,
    widthMM: 1245,
    volumeL: 175.77,
    nominalDeltaT50KW: 11.237,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 5340,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2502-28",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2500mm",
    depthMM: 217,
    heightMM: 2602,
    widthMM: 1290,
    volumeL: 182.28,
    nominalDeltaT50KW: 11.654,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 5533,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2502-29",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2500mm",
    depthMM: 217,
    heightMM: 2602,
    widthMM: 1335,
    volumeL: 188.79,
    nominalDeltaT50KW: 12.07,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 5727,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2502-30",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2500mm",
    depthMM: 217,
    heightMM: 2602,
    widthMM: 1380,
    volumeL: 195.3,
    nominalDeltaT50KW: 12.486,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 5920,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2502-31",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2500mm",
    depthMM: 217,
    heightMM: 2602,
    widthMM: 1425,
    volumeL: 201.81,
    nominalDeltaT50KW: 12.902,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 6114,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2502-32",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2500mm",
    depthMM: 217,
    heightMM: 2602,
    widthMM: 1470,
    volumeL: 208.32,
    nominalDeltaT50KW: 13.318,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 6307,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2502-33",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2500mm",
    depthMM: 217,
    heightMM: 2602,
    widthMM: 1515,
    volumeL: 214.83,
    nominalDeltaT50KW: 13.735,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 6501,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2502-34",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2500mm",
    depthMM: 217,
    heightMM: 2602,
    widthMM: 1560,
    volumeL: 221.34,
    nominalDeltaT50KW: 14.151,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 6694,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2502-35",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2500mm",
    depthMM: 217,
    heightMM: 2602,
    widthMM: 1605,
    volumeL: 227.85,
    nominalDeltaT50KW: 14.567,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 6888,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2502-36",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2500mm",
    depthMM: 217,
    heightMM: 2602,
    widthMM: 1650,
    volumeL: 234.36,
    nominalDeltaT50KW: 14.983,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 7081,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2502-37",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2500mm",
    depthMM: 217,
    heightMM: 2602,
    widthMM: 1695,
    volumeL: 240.87,
    nominalDeltaT50KW: 15.399,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 7275,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2502-38",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2500mm",
    depthMM: 217,
    heightMM: 2602,
    widthMM: 1740,
    volumeL: 247.38,
    nominalDeltaT50KW: 15.816,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 7468,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2502-39",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2500mm",
    depthMM: 217,
    heightMM: 2602,
    widthMM: 1785,
    volumeL: 253.89,
    nominalDeltaT50KW: 16.232,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 7662,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-2502-40",
    rangeType: "Florence 6",
    model: "Florence 6 Column 2500mm",
    depthMM: 217,
    heightMM: 2602,
    widthMM: 1830,
    volumeL: 260.4,
    nominalDeltaT50KW: 16.648,
    kvValue: 20,
    nCoefficient: 1.32,
    price: {
      value: 7855,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-302-04",
    rangeType: "Florence 6",
    model: "Florence 6 Column 300mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 210,
    volumeL: 4.52,
    nominalDeltaT50KW: 0.243,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 289,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-302-05",
    rangeType: "Florence 6",
    model: "Florence 6 Column 300mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 255,
    volumeL: 5.65,
    nominalDeltaT50KW: 0.304,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 333,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-302-06",
    rangeType: "Florence 6",
    model: "Florence 6 Column 300mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 300,
    volumeL: 6.78,
    nominalDeltaT50KW: 0.364,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 376,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-302-07",
    rangeType: "Florence 6",
    model: "Florence 6 Column 300mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 345,
    volumeL: 7.91,
    nominalDeltaT50KW: 0.425,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 420,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-302-08",
    rangeType: "Florence 6",
    model: "Florence 6 Column 300mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 390,
    volumeL: 9.04,
    nominalDeltaT50KW: 0.486,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 463,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-302-09",
    rangeType: "Florence 6",
    model: "Florence 6 Column 300mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 435,
    volumeL: 10.17,
    nominalDeltaT50KW: 0.546,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 507,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-302-10",
    rangeType: "Florence 6",
    model: "Florence 6 Column 300mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 480,
    volumeL: 11.3,
    nominalDeltaT50KW: 0.607,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 550,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-302-11",
    rangeType: "Florence 6",
    model: "Florence 6 Column 300mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 525,
    volumeL: 12.43,
    nominalDeltaT50KW: 0.668,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 594,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-302-12",
    rangeType: "Florence 6",
    model: "Florence 6 Column 300mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 570,
    volumeL: 13.56,
    nominalDeltaT50KW: 0.728,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 637,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-302-13",
    rangeType: "Florence 6",
    model: "Florence 6 Column 300mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 615,
    volumeL: 14.69,
    nominalDeltaT50KW: 0.789,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 681,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-302-14",
    rangeType: "Florence 6",
    model: "Florence 6 Column 300mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 660,
    volumeL: 15.82,
    nominalDeltaT50KW: 0.85,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 724,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-302-15",
    rangeType: "Florence 6",
    model: "Florence 6 Column 300mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 705,
    volumeL: 16.95,
    nominalDeltaT50KW: 0.911,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 768,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-302-16",
    rangeType: "Florence 6",
    model: "Florence 6 Column 300mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 750,
    volumeL: 18.08,
    nominalDeltaT50KW: 0.971,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 811,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-302-17",
    rangeType: "Florence 6",
    model: "Florence 6 Column 300mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 795,
    volumeL: 19.21,
    nominalDeltaT50KW: 1.032,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 855,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-302-18",
    rangeType: "Florence 6",
    model: "Florence 6 Column 300mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 840,
    volumeL: 20.34,
    nominalDeltaT50KW: 1.093,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 898,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-302-19",
    rangeType: "Florence 6",
    model: "Florence 6 Column 300mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 885,
    volumeL: 21.47,
    nominalDeltaT50KW: 1.153,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 942,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-302-20",
    rangeType: "Florence 6",
    model: "Florence 6 Column 300mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 930,
    volumeL: 22.6,
    nominalDeltaT50KW: 1.214,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 985,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-302-21",
    rangeType: "Florence 6",
    model: "Florence 6 Column 300mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 975,
    volumeL: 23.73,
    nominalDeltaT50KW: 1.275,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1029,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-302-22",
    rangeType: "Florence 6",
    model: "Florence 6 Column 300mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 1020,
    volumeL: 24.86,
    nominalDeltaT50KW: 1.335,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1072,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-302-23",
    rangeType: "Florence 6",
    model: "Florence 6 Column 300mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 1065,
    volumeL: 25.99,
    nominalDeltaT50KW: 1.396,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1116,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-302-24",
    rangeType: "Florence 6",
    model: "Florence 6 Column 300mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 1110,
    volumeL: 27.12,
    nominalDeltaT50KW: 1.457,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1159,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-302-25",
    rangeType: "Florence 6",
    model: "Florence 6 Column 300mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 1155,
    volumeL: 28.25,
    nominalDeltaT50KW: 1.518,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1203,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-302-26",
    rangeType: "Florence 6",
    model: "Florence 6 Column 300mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 1200,
    volumeL: 29.38,
    nominalDeltaT50KW: 1.578,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1246,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-302-27",
    rangeType: "Florence 6",
    model: "Florence 6 Column 300mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 1245,
    volumeL: 30.51,
    nominalDeltaT50KW: 1.639,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1290,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-302-28",
    rangeType: "Florence 6",
    model: "Florence 6 Column 300mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 1290,
    volumeL: 31.64,
    nominalDeltaT50KW: 1.7,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1333,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-302-29",
    rangeType: "Florence 6",
    model: "Florence 6 Column 300mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 1335,
    volumeL: 32.77,
    nominalDeltaT50KW: 1.76,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1377,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-302-30",
    rangeType: "Florence 6",
    model: "Florence 6 Column 300mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 1380,
    volumeL: 33.9,
    nominalDeltaT50KW: 1.821,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1420,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-302-31",
    rangeType: "Florence 6",
    model: "Florence 6 Column 300mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 1425,
    volumeL: 35.03,
    nominalDeltaT50KW: 1.882,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1464,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-302-32",
    rangeType: "Florence 6",
    model: "Florence 6 Column 300mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 1470,
    volumeL: 36.16,
    nominalDeltaT50KW: 1.942,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1507,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-302-33",
    rangeType: "Florence 6",
    model: "Florence 6 Column 300mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 1515,
    volumeL: 37.29,
    nominalDeltaT50KW: 2.003,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1551,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-302-34",
    rangeType: "Florence 6",
    model: "Florence 6 Column 300mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 1560,
    volumeL: 38.42,
    nominalDeltaT50KW: 2.064,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1594,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-302-35",
    rangeType: "Florence 6",
    model: "Florence 6 Column 300mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 1605,
    volumeL: 39.55,
    nominalDeltaT50KW: 2.125,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1638,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-302-36",
    rangeType: "Florence 6",
    model: "Florence 6 Column 300mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 1650,
    volumeL: 40.68,
    nominalDeltaT50KW: 2.185,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1681,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-302-37",
    rangeType: "Florence 6",
    model: "Florence 6 Column 300mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 1695,
    volumeL: 41.81,
    nominalDeltaT50KW: 2.246,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1725,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-302-38",
    rangeType: "Florence 6",
    model: "Florence 6 Column 300mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 1740,
    volumeL: 42.94,
    nominalDeltaT50KW: 2.307,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1768,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-302-39",
    rangeType: "Florence 6",
    model: "Florence 6 Column 300mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 1785,
    volumeL: 44.07,
    nominalDeltaT50KW: 2.367,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1812,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-302-40",
    rangeType: "Florence 6",
    model: "Florence 6 Column 300mm",
    depthMM: 217,
    heightMM: 294,
    widthMM: 1830,
    volumeL: 45.2,
    nominalDeltaT50KW: 2.428,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1855,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-402-04",
    rangeType: "Florence 6",
    model: "Florence 6 Column 400mm",
    depthMM: 217,
    heightMM: 502,
    widthMM: 210,
    volumeL: 5.52,
    nominalDeltaT50KW: 0.314,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 301,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-402-05",
    rangeType: "Florence 6",
    model: "Florence 6 Column 400mm",
    depthMM: 217,
    heightMM: 502,
    widthMM: 255,
    volumeL: 6.9,
    nominalDeltaT50KW: 0.393,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 348,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-402-06",
    rangeType: "Florence 6",
    model: "Florence 6 Column 400mm",
    depthMM: 217,
    heightMM: 502,
    widthMM: 300,
    volumeL: 8.28,
    nominalDeltaT50KW: 0.471,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 394,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-402-07",
    rangeType: "Florence 6",
    model: "Florence 6 Column 400mm",
    depthMM: 217,
    heightMM: 502,
    widthMM: 345,
    volumeL: 9.66,
    nominalDeltaT50KW: 0.55,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 441,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-402-08",
    rangeType: "Florence 6",
    model: "Florence 6 Column 400mm",
    depthMM: 217,
    heightMM: 502,
    widthMM: 390,
    volumeL: 11.04,
    nominalDeltaT50KW: 0.628,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 487,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-402-09",
    rangeType: "Florence 6",
    model: "Florence 6 Column 400mm",
    depthMM: 217,
    heightMM: 502,
    widthMM: 435,
    volumeL: 12.42,
    nominalDeltaT50KW: 0.707,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 534,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-402-10",
    rangeType: "Florence 6",
    model: "Florence 6 Column 400mm",
    depthMM: 217,
    heightMM: 502,
    widthMM: 480,
    volumeL: 13.8,
    nominalDeltaT50KW: 0.785,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 580,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-402-11",
    rangeType: "Florence 6",
    model: "Florence 6 Column 400mm",
    depthMM: 217,
    heightMM: 502,
    widthMM: 525,
    volumeL: 15.18,
    nominalDeltaT50KW: 0.864,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 627,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-402-12",
    rangeType: "Florence 6",
    model: "Florence 6 Column 400mm",
    depthMM: 217,
    heightMM: 502,
    widthMM: 570,
    volumeL: 16.56,
    nominalDeltaT50KW: 0.942,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 673,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-402-13",
    rangeType: "Florence 6",
    model: "Florence 6 Column 400mm",
    depthMM: 217,
    heightMM: 502,
    widthMM: 615,
    volumeL: 17.94,
    nominalDeltaT50KW: 1.021,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 720,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-402-14",
    rangeType: "Florence 6",
    model: "Florence 6 Column 400mm",
    depthMM: 217,
    heightMM: 502,
    widthMM: 660,
    volumeL: 19.32,
    nominalDeltaT50KW: 1.099,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 766,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-402-15",
    rangeType: "Florence 6",
    model: "Florence 6 Column 400mm",
    depthMM: 217,
    heightMM: 502,
    widthMM: 705,
    volumeL: 20.7,
    nominalDeltaT50KW: 1.178,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 813,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-402-16",
    rangeType: "Florence 6",
    model: "Florence 6 Column 400mm",
    depthMM: 217,
    heightMM: 502,
    widthMM: 750,
    volumeL: 22.08,
    nominalDeltaT50KW: 1.256,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 859,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-402-17",
    rangeType: "Florence 6",
    model: "Florence 6 Column 400mm",
    depthMM: 217,
    heightMM: 502,
    widthMM: 795,
    volumeL: 23.46,
    nominalDeltaT50KW: 1.335,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 906,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-402-18",
    rangeType: "Florence 6",
    model: "Florence 6 Column 400mm",
    depthMM: 217,
    heightMM: 502,
    widthMM: 840,
    volumeL: 24.84,
    nominalDeltaT50KW: 1.413,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 952,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-402-19",
    rangeType: "Florence 6",
    model: "Florence 6 Column 400mm",
    depthMM: 217,
    heightMM: 502,
    widthMM: 885,
    volumeL: 26.22,
    nominalDeltaT50KW: 1.492,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 999,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-402-20",
    rangeType: "Florence 6",
    model: "Florence 6 Column 400mm",
    depthMM: 217,
    heightMM: 502,
    widthMM: 930,
    volumeL: 27.6,
    nominalDeltaT50KW: 1.57,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1045,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-402-21",
    rangeType: "Florence 6",
    model: "Florence 6 Column 400mm",
    depthMM: 217,
    heightMM: 502,
    widthMM: 975,
    volumeL: 28.98,
    nominalDeltaT50KW: 1.649,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1092,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-402-22",
    rangeType: "Florence 6",
    model: "Florence 6 Column 400mm",
    depthMM: 217,
    heightMM: 502,
    widthMM: 1020,
    volumeL: 30.36,
    nominalDeltaT50KW: 1.727,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1138,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-402-23",
    rangeType: "Florence 6",
    model: "Florence 6 Column 400mm",
    depthMM: 217,
    heightMM: 502,
    widthMM: 1065,
    volumeL: 31.74,
    nominalDeltaT50KW: 1.806,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1185,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-402-24",
    rangeType: "Florence 6",
    model: "Florence 6 Column 400mm",
    depthMM: 217,
    heightMM: 502,
    widthMM: 1110,
    volumeL: 33.12,
    nominalDeltaT50KW: 1.884,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1231,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-402-25",
    rangeType: "Florence 6",
    model: "Florence 6 Column 400mm",
    depthMM: 217,
    heightMM: 502,
    widthMM: 1155,
    volumeL: 34.5,
    nominalDeltaT50KW: 1.963,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1278,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-402-26",
    rangeType: "Florence 6",
    model: "Florence 6 Column 400mm",
    depthMM: 217,
    heightMM: 502,
    widthMM: 1200,
    volumeL: 35.88,
    nominalDeltaT50KW: 2.041,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1324,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-402-27",
    rangeType: "Florence 6",
    model: "Florence 6 Column 400mm",
    depthMM: 217,
    heightMM: 502,
    widthMM: 1245,
    volumeL: 37.26,
    nominalDeltaT50KW: 2.12,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1371,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-402-28",
    rangeType: "Florence 6",
    model: "Florence 6 Column 400mm",
    depthMM: 217,
    heightMM: 502,
    widthMM: 1290,
    volumeL: 38.64,
    nominalDeltaT50KW: 2.198,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1417,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-402-29",
    rangeType: "Florence 6",
    model: "Florence 6 Column 400mm",
    depthMM: 217,
    heightMM: 502,
    widthMM: 1335,
    volumeL: 40.02,
    nominalDeltaT50KW: 2.277,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1464,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-402-30",
    rangeType: "Florence 6",
    model: "Florence 6 Column 400mm",
    depthMM: 217,
    heightMM: 502,
    widthMM: 1380,
    volumeL: 41.4,
    nominalDeltaT50KW: 2.355,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1510,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-402-31",
    rangeType: "Florence 6",
    model: "Florence 6 Column 400mm",
    depthMM: 217,
    heightMM: 502,
    widthMM: 1425,
    volumeL: 42.78,
    nominalDeltaT50KW: 2.434,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1557,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-402-32",
    rangeType: "Florence 6",
    model: "Florence 6 Column 400mm",
    depthMM: 217,
    heightMM: 502,
    widthMM: 1470,
    volumeL: 44.16,
    nominalDeltaT50KW: 2.512,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1603,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-402-33",
    rangeType: "Florence 6",
    model: "Florence 6 Column 400mm",
    depthMM: 217,
    heightMM: 502,
    widthMM: 1515,
    volumeL: 45.54,
    nominalDeltaT50KW: 2.591,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1650,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-402-34",
    rangeType: "Florence 6",
    model: "Florence 6 Column 400mm",
    depthMM: 217,
    heightMM: 502,
    widthMM: 1560,
    volumeL: 46.92,
    nominalDeltaT50KW: 2.669,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1696,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-402-35",
    rangeType: "Florence 6",
    model: "Florence 6 Column 400mm",
    depthMM: 217,
    heightMM: 502,
    widthMM: 1605,
    volumeL: 48.3,
    nominalDeltaT50KW: 2.748,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1743,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-402-36",
    rangeType: "Florence 6",
    model: "Florence 6 Column 400mm",
    depthMM: 217,
    heightMM: 502,
    widthMM: 1650,
    volumeL: 49.68,
    nominalDeltaT50KW: 2.826,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1789,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-402-37",
    rangeType: "Florence 6",
    model: "Florence 6 Column 400mm",
    depthMM: 217,
    heightMM: 502,
    widthMM: 1695,
    volumeL: 51.06,
    nominalDeltaT50KW: 2.905,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1836,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-402-38",
    rangeType: "Florence 6",
    model: "Florence 6 Column 400mm",
    depthMM: 217,
    heightMM: 502,
    widthMM: 1740,
    volumeL: 52.44,
    nominalDeltaT50KW: 2.983,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1882,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-402-39",
    rangeType: "Florence 6",
    model: "Florence 6 Column 400mm",
    depthMM: 217,
    heightMM: 502,
    widthMM: 1785,
    volumeL: 53.82,
    nominalDeltaT50KW: 3.062,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1929,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-402-40",
    rangeType: "Florence 6",
    model: "Florence 6 Column 400mm",
    depthMM: 217,
    heightMM: 502,
    widthMM: 1830,
    volumeL: 55.2,
    nominalDeltaT50KW: 3.14,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1975,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-502-04",
    rangeType: "Florence 6",
    model: "Florence 6 Column 500mm",
    depthMM: 217,
    heightMM: 602,
    widthMM: 210,
    volumeL: 6.52,
    nominalDeltaT50KW: 0.383,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 309,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-502-05",
    rangeType: "Florence 6",
    model: "Florence 6 Column 500mm",
    depthMM: 217,
    heightMM: 602,
    widthMM: 255,
    volumeL: 8.15,
    nominalDeltaT50KW: 0.479,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 358,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-502-06",
    rangeType: "Florence 6",
    model: "Florence 6 Column 500mm",
    depthMM: 217,
    heightMM: 602,
    widthMM: 300,
    volumeL: 9.78,
    nominalDeltaT50KW: 0.575,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 406,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-502-07",
    rangeType: "Florence 6",
    model: "Florence 6 Column 500mm",
    depthMM: 217,
    heightMM: 602,
    widthMM: 345,
    volumeL: 11.41,
    nominalDeltaT50KW: 0.671,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 455,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-502-08",
    rangeType: "Florence 6",
    model: "Florence 6 Column 500mm",
    depthMM: 217,
    heightMM: 602,
    widthMM: 390,
    volumeL: 13.04,
    nominalDeltaT50KW: 0.766,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 503,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-502-09",
    rangeType: "Florence 6",
    model: "Florence 6 Column 500mm",
    depthMM: 217,
    heightMM: 602,
    widthMM: 435,
    volumeL: 14.67,
    nominalDeltaT50KW: 0.862,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 552,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-502-10",
    rangeType: "Florence 6",
    model: "Florence 6 Column 500mm",
    depthMM: 217,
    heightMM: 602,
    widthMM: 480,
    volumeL: 16.3,
    nominalDeltaT50KW: 0.958,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 600,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-502-11",
    rangeType: "Florence 6",
    model: "Florence 6 Column 500mm",
    depthMM: 217,
    heightMM: 602,
    widthMM: 525,
    volumeL: 17.93,
    nominalDeltaT50KW: 1.054,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 649,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-502-12",
    rangeType: "Florence 6",
    model: "Florence 6 Column 500mm",
    depthMM: 217,
    heightMM: 602,
    widthMM: 570,
    volumeL: 19.56,
    nominalDeltaT50KW: 1.15,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 697,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-502-13",
    rangeType: "Florence 6",
    model: "Florence 6 Column 500mm",
    depthMM: 217,
    heightMM: 602,
    widthMM: 615,
    volumeL: 21.19,
    nominalDeltaT50KW: 1.245,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 746,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-502-14",
    rangeType: "Florence 6",
    model: "Florence 6 Column 500mm",
    depthMM: 217,
    heightMM: 602,
    widthMM: 660,
    volumeL: 22.82,
    nominalDeltaT50KW: 1.341,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 794,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-502-15",
    rangeType: "Florence 6",
    model: "Florence 6 Column 500mm",
    depthMM: 217,
    heightMM: 602,
    widthMM: 705,
    volumeL: 24.45,
    nominalDeltaT50KW: 1.437,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 843,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-502-16",
    rangeType: "Florence 6",
    model: "Florence 6 Column 500mm",
    depthMM: 217,
    heightMM: 602,
    widthMM: 750,
    volumeL: 26.08,
    nominalDeltaT50KW: 1.533,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 891,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-502-17",
    rangeType: "Florence 6",
    model: "Florence 6 Column 500mm",
    depthMM: 217,
    heightMM: 602,
    widthMM: 795,
    volumeL: 27.71,
    nominalDeltaT50KW: 1.629,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 940,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-502-18",
    rangeType: "Florence 6",
    model: "Florence 6 Column 500mm",
    depthMM: 217,
    heightMM: 602,
    widthMM: 840,
    volumeL: 29.34,
    nominalDeltaT50KW: 1.724,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 988,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-502-19",
    rangeType: "Florence 6",
    model: "Florence 6 Column 500mm",
    depthMM: 217,
    heightMM: 602,
    widthMM: 885,
    volumeL: 30.97,
    nominalDeltaT50KW: 1.82,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1037,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-502-20",
    rangeType: "Florence 6",
    model: "Florence 6 Column 500mm",
    depthMM: 217,
    heightMM: 602,
    widthMM: 930,
    volumeL: 32.6,
    nominalDeltaT50KW: 1.916,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1085,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-502-21",
    rangeType: "Florence 6",
    model: "Florence 6 Column 500mm",
    depthMM: 217,
    heightMM: 602,
    widthMM: 975,
    volumeL: 34.23,
    nominalDeltaT50KW: 2.012,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1134,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-502-22",
    rangeType: "Florence 6",
    model: "Florence 6 Column 500mm",
    depthMM: 217,
    heightMM: 602,
    widthMM: 1020,
    volumeL: 35.86,
    nominalDeltaT50KW: 2.108,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1182,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-502-23",
    rangeType: "Florence 6",
    model: "Florence 6 Column 500mm",
    depthMM: 217,
    heightMM: 602,
    widthMM: 1065,
    volumeL: 37.49,
    nominalDeltaT50KW: 2.203,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1231,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-502-24",
    rangeType: "Florence 6",
    model: "Florence 6 Column 500mm",
    depthMM: 217,
    heightMM: 602,
    widthMM: 1110,
    volumeL: 39.12,
    nominalDeltaT50KW: 2.299,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1279,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-502-25",
    rangeType: "Florence 6",
    model: "Florence 6 Column 500mm",
    depthMM: 217,
    heightMM: 602,
    widthMM: 1155,
    volumeL: 40.75,
    nominalDeltaT50KW: 2.395,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1328,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-502-26",
    rangeType: "Florence 6",
    model: "Florence 6 Column 500mm",
    depthMM: 217,
    heightMM: 602,
    widthMM: 1200,
    volumeL: 42.38,
    nominalDeltaT50KW: 2.491,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1376,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-502-27",
    rangeType: "Florence 6",
    model: "Florence 6 Column 500mm",
    depthMM: 217,
    heightMM: 602,
    widthMM: 1245,
    volumeL: 44.01,
    nominalDeltaT50KW: 2.587,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1425,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-502-28",
    rangeType: "Florence 6",
    model: "Florence 6 Column 500mm",
    depthMM: 217,
    heightMM: 602,
    widthMM: 1290,
    volumeL: 45.64,
    nominalDeltaT50KW: 2.682,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1473,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-502-29",
    rangeType: "Florence 6",
    model: "Florence 6 Column 500mm",
    depthMM: 217,
    heightMM: 602,
    widthMM: 1335,
    volumeL: 47.27,
    nominalDeltaT50KW: 2.778,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1522,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-502-30",
    rangeType: "Florence 6",
    model: "Florence 6 Column 500mm",
    depthMM: 217,
    heightMM: 602,
    widthMM: 1380,
    volumeL: 48.9,
    nominalDeltaT50KW: 2.874,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1570,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-502-31",
    rangeType: "Florence 6",
    model: "Florence 6 Column 500mm",
    depthMM: 217,
    heightMM: 602,
    widthMM: 1425,
    volumeL: 50.53,
    nominalDeltaT50KW: 2.97,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1619,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-502-32",
    rangeType: "Florence 6",
    model: "Florence 6 Column 500mm",
    depthMM: 217,
    heightMM: 602,
    widthMM: 1470,
    volumeL: 52.16,
    nominalDeltaT50KW: 3.066,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1667,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-502-33",
    rangeType: "Florence 6",
    model: "Florence 6 Column 500mm",
    depthMM: 217,
    heightMM: 602,
    widthMM: 1515,
    volumeL: 53.79,
    nominalDeltaT50KW: 3.161,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1716,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-502-34",
    rangeType: "Florence 6",
    model: "Florence 6 Column 500mm",
    depthMM: 217,
    heightMM: 602,
    widthMM: 1560,
    volumeL: 55.42,
    nominalDeltaT50KW: 3.257,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1764,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-502-35",
    rangeType: "Florence 6",
    model: "Florence 6 Column 500mm",
    depthMM: 217,
    heightMM: 602,
    widthMM: 1605,
    volumeL: 57.05,
    nominalDeltaT50KW: 3.353,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1813,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-502-36",
    rangeType: "Florence 6",
    model: "Florence 6 Column 500mm",
    depthMM: 217,
    heightMM: 602,
    widthMM: 1650,
    volumeL: 58.68,
    nominalDeltaT50KW: 3.449,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1861,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-502-37",
    rangeType: "Florence 6",
    model: "Florence 6 Column 500mm",
    depthMM: 217,
    heightMM: 602,
    widthMM: 1695,
    volumeL: 60.31,
    nominalDeltaT50KW: 3.545,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1910,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-502-38",
    rangeType: "Florence 6",
    model: "Florence 6 Column 500mm",
    depthMM: 217,
    heightMM: 602,
    widthMM: 1740,
    volumeL: 61.94,
    nominalDeltaT50KW: 3.64,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1958,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-502-39",
    rangeType: "Florence 6",
    model: "Florence 6 Column 500mm",
    depthMM: 217,
    heightMM: 602,
    widthMM: 1785,
    volumeL: 63.57,
    nominalDeltaT50KW: 3.736,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2007,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-502-40",
    rangeType: "Florence 6",
    model: "Florence 6 Column 500mm",
    depthMM: 217,
    heightMM: 602,
    widthMM: 1830,
    volumeL: 65.2,
    nominalDeltaT50KW: 3.832,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2055,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-602-04",
    rangeType: "Florence 6",
    model: "Florence 6 Column 600mm",
    depthMM: 217,
    heightMM: 702,
    widthMM: 210,
    volumeL: 7.52,
    nominalDeltaT50KW: 0.451,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 321,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-602-05",
    rangeType: "Florence 6",
    model: "Florence 6 Column 600mm",
    depthMM: 217,
    heightMM: 702,
    widthMM: 255,
    volumeL: 9.4,
    nominalDeltaT50KW: 0.564,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 373,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-602-06",
    rangeType: "Florence 6",
    model: "Florence 6 Column 600mm",
    depthMM: 217,
    heightMM: 702,
    widthMM: 300,
    volumeL: 11.28,
    nominalDeltaT50KW: 0.677,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 424,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-602-07",
    rangeType: "Florence 6",
    model: "Florence 6 Column 600mm",
    depthMM: 217,
    heightMM: 702,
    widthMM: 345,
    volumeL: 13.16,
    nominalDeltaT50KW: 0.79,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 476,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-602-08",
    rangeType: "Florence 6",
    model: "Florence 6 Column 600mm",
    depthMM: 217,
    heightMM: 702,
    widthMM: 390,
    volumeL: 15.04,
    nominalDeltaT50KW: 0.902,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 527,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-602-09",
    rangeType: "Florence 6",
    model: "Florence 6 Column 600mm",
    depthMM: 217,
    heightMM: 702,
    widthMM: 435,
    volumeL: 16.92,
    nominalDeltaT50KW: 1.015,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 579,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-602-10",
    rangeType: "Florence 6",
    model: "Florence 6 Column 600mm",
    depthMM: 217,
    heightMM: 702,
    widthMM: 480,
    volumeL: 18.8,
    nominalDeltaT50KW: 1.128,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 630,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-602-11",
    rangeType: "Florence 6",
    model: "Florence 6 Column 600mm",
    depthMM: 217,
    heightMM: 702,
    widthMM: 525,
    volumeL: 20.68,
    nominalDeltaT50KW: 1.241,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 682,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-602-12",
    rangeType: "Florence 6",
    model: "Florence 6 Column 600mm",
    depthMM: 217,
    heightMM: 702,
    widthMM: 570,
    volumeL: 22.56,
    nominalDeltaT50KW: 1.354,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 733,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-602-13",
    rangeType: "Florence 6",
    model: "Florence 6 Column 600mm",
    depthMM: 217,
    heightMM: 702,
    widthMM: 615,
    volumeL: 24.44,
    nominalDeltaT50KW: 1.466,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 785,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-602-14",
    rangeType: "Florence 6",
    model: "Florence 6 Column 600mm",
    depthMM: 217,
    heightMM: 702,
    widthMM: 660,
    volumeL: 26.32,
    nominalDeltaT50KW: 1.579,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 836,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-602-15",
    rangeType: "Florence 6",
    model: "Florence 6 Column 600mm",
    depthMM: 217,
    heightMM: 702,
    widthMM: 705,
    volumeL: 28.2,
    nominalDeltaT50KW: 1.692,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 888,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-602-16",
    rangeType: "Florence 6",
    model: "Florence 6 Column 600mm",
    depthMM: 217,
    heightMM: 702,
    widthMM: 750,
    volumeL: 30.08,
    nominalDeltaT50KW: 1.805,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 939,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-602-17",
    rangeType: "Florence 6",
    model: "Florence 6 Column 600mm",
    depthMM: 217,
    heightMM: 702,
    widthMM: 795,
    volumeL: 31.96,
    nominalDeltaT50KW: 1.918,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 991,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-602-18",
    rangeType: "Florence 6",
    model: "Florence 6 Column 600mm",
    depthMM: 217,
    heightMM: 702,
    widthMM: 840,
    volumeL: 33.84,
    nominalDeltaT50KW: 2.03,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1042,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-602-19",
    rangeType: "Florence 6",
    model: "Florence 6 Column 600mm",
    depthMM: 217,
    heightMM: 702,
    widthMM: 885,
    volumeL: 35.72,
    nominalDeltaT50KW: 2.143,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1094,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-602-20",
    rangeType: "Florence 6",
    model: "Florence 6 Column 600mm",
    depthMM: 217,
    heightMM: 702,
    widthMM: 930,
    volumeL: 37.6,
    nominalDeltaT50KW: 2.256,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1145,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-602-21",
    rangeType: "Florence 6",
    model: "Florence 6 Column 600mm",
    depthMM: 217,
    heightMM: 702,
    widthMM: 975,
    volumeL: 39.48,
    nominalDeltaT50KW: 2.369,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1197,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-602-22",
    rangeType: "Florence 6",
    model: "Florence 6 Column 600mm",
    depthMM: 217,
    heightMM: 702,
    widthMM: 1020,
    volumeL: 41.36,
    nominalDeltaT50KW: 2.482,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1248,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-602-23",
    rangeType: "Florence 6",
    model: "Florence 6 Column 600mm",
    depthMM: 217,
    heightMM: 702,
    widthMM: 1065,
    volumeL: 43.24,
    nominalDeltaT50KW: 2.594,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1300,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-602-24",
    rangeType: "Florence 6",
    model: "Florence 6 Column 600mm",
    depthMM: 217,
    heightMM: 702,
    widthMM: 1110,
    volumeL: 45.12,
    nominalDeltaT50KW: 2.707,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1351,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-602-25",
    rangeType: "Florence 6",
    model: "Florence 6 Column 600mm",
    depthMM: 217,
    heightMM: 702,
    widthMM: 1155,
    volumeL: 47,
    nominalDeltaT50KW: 2.82,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1403,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-602-26",
    rangeType: "Florence 6",
    model: "Florence 6 Column 600mm",
    depthMM: 217,
    heightMM: 702,
    widthMM: 1200,
    volumeL: 48.88,
    nominalDeltaT50KW: 2.933,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1454,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-602-27",
    rangeType: "Florence 6",
    model: "Florence 6 Column 600mm",
    depthMM: 217,
    heightMM: 702,
    widthMM: 1245,
    volumeL: 50.76,
    nominalDeltaT50KW: 3.046,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1506,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-602-28",
    rangeType: "Florence 6",
    model: "Florence 6 Column 600mm",
    depthMM: 217,
    heightMM: 702,
    widthMM: 1290,
    volumeL: 52.64,
    nominalDeltaT50KW: 3.158,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1557,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-602-29",
    rangeType: "Florence 6",
    model: "Florence 6 Column 600mm",
    depthMM: 217,
    heightMM: 702,
    widthMM: 1335,
    volumeL: 54.52,
    nominalDeltaT50KW: 3.271,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1609,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-602-30",
    rangeType: "Florence 6",
    model: "Florence 6 Column 600mm",
    depthMM: 217,
    heightMM: 702,
    widthMM: 1380,
    volumeL: 56.4,
    nominalDeltaT50KW: 3.384,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1660,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-602-31",
    rangeType: "Florence 6",
    model: "Florence 6 Column 600mm",
    depthMM: 217,
    heightMM: 702,
    widthMM: 1425,
    volumeL: 58.28,
    nominalDeltaT50KW: 3.497,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1712,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-602-32",
    rangeType: "Florence 6",
    model: "Florence 6 Column 600mm",
    depthMM: 217,
    heightMM: 702,
    widthMM: 1470,
    volumeL: 60.16,
    nominalDeltaT50KW: 3.61,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1763,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-602-33",
    rangeType: "Florence 6",
    model: "Florence 6 Column 600mm",
    depthMM: 217,
    heightMM: 702,
    widthMM: 1515,
    volumeL: 62.04,
    nominalDeltaT50KW: 3.722,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1815,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-602-34",
    rangeType: "Florence 6",
    model: "Florence 6 Column 600mm",
    depthMM: 217,
    heightMM: 702,
    widthMM: 1560,
    volumeL: 63.92,
    nominalDeltaT50KW: 3.835,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1866,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-602-35",
    rangeType: "Florence 6",
    model: "Florence 6 Column 600mm",
    depthMM: 217,
    heightMM: 702,
    widthMM: 1605,
    volumeL: 65.8,
    nominalDeltaT50KW: 3.948,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1918,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-602-36",
    rangeType: "Florence 6",
    model: "Florence 6 Column 600mm",
    depthMM: 217,
    heightMM: 702,
    widthMM: 1650,
    volumeL: 67.68,
    nominalDeltaT50KW: 4.061,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1969,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-602-37",
    rangeType: "Florence 6",
    model: "Florence 6 Column 600mm",
    depthMM: 217,
    heightMM: 702,
    widthMM: 1695,
    volumeL: 69.56,
    nominalDeltaT50KW: 4.174,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 2021,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-602-38",
    rangeType: "Florence 6",
    model: "Florence 6 Column 600mm",
    depthMM: 217,
    heightMM: 702,
    widthMM: 1740,
    volumeL: 71.44,
    nominalDeltaT50KW: 4.286,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 2072,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-602-39",
    rangeType: "Florence 6",
    model: "Florence 6 Column 600mm",
    depthMM: 217,
    heightMM: 702,
    widthMM: 1785,
    volumeL: 73.32,
    nominalDeltaT50KW: 4.399,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 2124,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-602-40",
    rangeType: "Florence 6",
    model: "Florence 6 Column 600mm",
    depthMM: 217,
    heightMM: 702,
    widthMM: 1830,
    volumeL: 75.2,
    nominalDeltaT50KW: 4.512,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 2175,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-687-04",
    rangeType: "Florence 6",
    model: "Florence 6 Column 685mm",
    depthMM: 217,
    heightMM: 787,
    widthMM: 210,
    volumeL: 8.36,
    nominalDeltaT50KW: 0.508,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 329,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-687-05",
    rangeType: "Florence 6",
    model: "Florence 6 Column 685mm",
    depthMM: 217,
    heightMM: 787,
    widthMM: 255,
    volumeL: 10.45,
    nominalDeltaT50KW: 0.636,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 383,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-687-06",
    rangeType: "Florence 6",
    model: "Florence 6 Column 685mm",
    depthMM: 217,
    heightMM: 787,
    widthMM: 300,
    volumeL: 12.54,
    nominalDeltaT50KW: 0.763,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 436,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-687-07",
    rangeType: "Florence 6",
    model: "Florence 6 Column 685mm",
    depthMM: 217,
    heightMM: 787,
    widthMM: 345,
    volumeL: 14.63,
    nominalDeltaT50KW: 0.89,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 490,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-687-08",
    rangeType: "Florence 6",
    model: "Florence 6 Column 685mm",
    depthMM: 217,
    heightMM: 787,
    widthMM: 390,
    volumeL: 16.72,
    nominalDeltaT50KW: 1.017,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 543,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-687-09",
    rangeType: "Florence 6",
    model: "Florence 6 Column 685mm",
    depthMM: 217,
    heightMM: 787,
    widthMM: 435,
    volumeL: 18.81,
    nominalDeltaT50KW: 1.144,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 597,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-687-10",
    rangeType: "Florence 6",
    model: "Florence 6 Column 685mm",
    depthMM: 217,
    heightMM: 787,
    widthMM: 480,
    volumeL: 20.9,
    nominalDeltaT50KW: 1.271,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 650,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-687-11",
    rangeType: "Florence 6",
    model: "Florence 6 Column 685mm",
    depthMM: 217,
    heightMM: 787,
    widthMM: 525,
    volumeL: 22.99,
    nominalDeltaT50KW: 1.398,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 704,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-687-12",
    rangeType: "Florence 6",
    model: "Florence 6 Column 685mm",
    depthMM: 217,
    heightMM: 787,
    widthMM: 570,
    volumeL: 25.08,
    nominalDeltaT50KW: 1.525,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 757,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-687-13",
    rangeType: "Florence 6",
    model: "Florence 6 Column 685mm",
    depthMM: 217,
    heightMM: 787,
    widthMM: 615,
    volumeL: 27.17,
    nominalDeltaT50KW: 1.652,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 811,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-687-14",
    rangeType: "Florence 6",
    model: "Florence 6 Column 685mm",
    depthMM: 217,
    heightMM: 787,
    widthMM: 660,
    volumeL: 29.26,
    nominalDeltaT50KW: 1.779,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 864,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-687-15",
    rangeType: "Florence 6",
    model: "Florence 6 Column 685mm",
    depthMM: 217,
    heightMM: 787,
    widthMM: 705,
    volumeL: 31.35,
    nominalDeltaT50KW: 1.907,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 918,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-687-16",
    rangeType: "Florence 6",
    model: "Florence 6 Column 685mm",
    depthMM: 217,
    heightMM: 787,
    widthMM: 750,
    volumeL: 33.44,
    nominalDeltaT50KW: 2.034,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 971,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-687-17",
    rangeType: "Florence 6",
    model: "Florence 6 Column 685mm",
    depthMM: 217,
    heightMM: 787,
    widthMM: 795,
    volumeL: 35.53,
    nominalDeltaT50KW: 2.161,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 1025,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-687-18",
    rangeType: "Florence 6",
    model: "Florence 6 Column 685mm",
    depthMM: 217,
    heightMM: 787,
    widthMM: 840,
    volumeL: 37.62,
    nominalDeltaT50KW: 2.288,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 1078,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-687-19",
    rangeType: "Florence 6",
    model: "Florence 6 Column 685mm",
    depthMM: 217,
    heightMM: 787,
    widthMM: 885,
    volumeL: 39.71,
    nominalDeltaT50KW: 2.415,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 1132,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-687-20",
    rangeType: "Florence 6",
    model: "Florence 6 Column 685mm",
    depthMM: 217,
    heightMM: 787,
    widthMM: 930,
    volumeL: 41.8,
    nominalDeltaT50KW: 2.542,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 1185,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-687-21",
    rangeType: "Florence 6",
    model: "Florence 6 Column 685mm",
    depthMM: 217,
    heightMM: 787,
    widthMM: 975,
    volumeL: 43.89,
    nominalDeltaT50KW: 2.669,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 1239,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-687-22",
    rangeType: "Florence 6",
    model: "Florence 6 Column 685mm",
    depthMM: 217,
    heightMM: 787,
    widthMM: 1020,
    volumeL: 45.98,
    nominalDeltaT50KW: 2.796,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 1292,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-687-23",
    rangeType: "Florence 6",
    model: "Florence 6 Column 685mm",
    depthMM: 217,
    heightMM: 787,
    widthMM: 1065,
    volumeL: 48.07,
    nominalDeltaT50KW: 2.923,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 1346,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-687-24",
    rangeType: "Florence 6",
    model: "Florence 6 Column 685mm",
    depthMM: 217,
    heightMM: 787,
    widthMM: 1110,
    volumeL: 50.16,
    nominalDeltaT50KW: 3.05,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 1399,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-687-25",
    rangeType: "Florence 6",
    model: "Florence 6 Column 685mm",
    depthMM: 217,
    heightMM: 787,
    widthMM: 1155,
    volumeL: 52.25,
    nominalDeltaT50KW: 3.178,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 1453,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-687-26",
    rangeType: "Florence 6",
    model: "Florence 6 Column 685mm",
    depthMM: 217,
    heightMM: 787,
    widthMM: 1200,
    volumeL: 54.34,
    nominalDeltaT50KW: 3.305,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 1506,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-687-27",
    rangeType: "Florence 6",
    model: "Florence 6 Column 685mm",
    depthMM: 217,
    heightMM: 787,
    widthMM: 1245,
    volumeL: 56.43,
    nominalDeltaT50KW: 3.432,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 1560,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-687-28",
    rangeType: "Florence 6",
    model: "Florence 6 Column 685mm",
    depthMM: 217,
    heightMM: 787,
    widthMM: 1290,
    volumeL: 58.52,
    nominalDeltaT50KW: 3.559,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 1613,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-687-29",
    rangeType: "Florence 6",
    model: "Florence 6 Column 685mm",
    depthMM: 217,
    heightMM: 787,
    widthMM: 1335,
    volumeL: 60.61,
    nominalDeltaT50KW: 3.686,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 1667,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-687-30",
    rangeType: "Florence 6",
    model: "Florence 6 Column 685mm",
    depthMM: 217,
    heightMM: 787,
    widthMM: 1380,
    volumeL: 62.7,
    nominalDeltaT50KW: 3.813,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 1720,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-687-31",
    rangeType: "Florence 6",
    model: "Florence 6 Column 685mm",
    depthMM: 217,
    heightMM: 787,
    widthMM: 1425,
    volumeL: 64.79,
    nominalDeltaT50KW: 3.94,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 1774,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-687-32",
    rangeType: "Florence 6",
    model: "Florence 6 Column 685mm",
    depthMM: 217,
    heightMM: 787,
    widthMM: 1470,
    volumeL: 66.88,
    nominalDeltaT50KW: 4.067,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 1827,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-687-33",
    rangeType: "Florence 6",
    model: "Florence 6 Column 685mm",
    depthMM: 217,
    heightMM: 787,
    widthMM: 1515,
    volumeL: 68.97,
    nominalDeltaT50KW: 4.194,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 1881,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-687-34",
    rangeType: "Florence 6",
    model: "Florence 6 Column 685mm",
    depthMM: 217,
    heightMM: 787,
    widthMM: 1560,
    volumeL: 71.06,
    nominalDeltaT50KW: 4.321,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 1934,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-687-35",
    rangeType: "Florence 6",
    model: "Florence 6 Column 685mm",
    depthMM: 217,
    heightMM: 787,
    widthMM: 1605,
    volumeL: 73.15,
    nominalDeltaT50KW: 4.449,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 1988,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-687-36",
    rangeType: "Florence 6",
    model: "Florence 6 Column 685mm",
    depthMM: 217,
    heightMM: 787,
    widthMM: 1650,
    volumeL: 75.24,
    nominalDeltaT50KW: 4.576,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 2041,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-687-37",
    rangeType: "Florence 6",
    model: "Florence 6 Column 685mm",
    depthMM: 217,
    heightMM: 787,
    widthMM: 1695,
    volumeL: 77.33,
    nominalDeltaT50KW: 4.703,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 2095,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-687-38",
    rangeType: "Florence 6",
    model: "Florence 6 Column 685mm",
    depthMM: 217,
    heightMM: 787,
    widthMM: 1740,
    volumeL: 79.42,
    nominalDeltaT50KW: 4.83,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 2148,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-687-39",
    rangeType: "Florence 6",
    model: "Florence 6 Column 685mm",
    depthMM: 217,
    heightMM: 787,
    widthMM: 1785,
    volumeL: 81.51,
    nominalDeltaT50KW: 4.957,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 2202,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-687-40",
    rangeType: "Florence 6",
    model: "Florence 6 Column 685mm",
    depthMM: 217,
    heightMM: 787,
    widthMM: 1830,
    volumeL: 83.6,
    nominalDeltaT50KW: 5.084,
    kvValue: 20,
    nCoefficient: 1.36,
    price: {
      value: 2255,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-767-04",
    rangeType: "Florence 6",
    model: "Florence 6 Column 765mm",
    depthMM: 217,
    heightMM: 867,
    widthMM: 210,
    volumeL: 9.16,
    nominalDeltaT50KW: 0.562,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 341,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-767-05",
    rangeType: "Florence 6",
    model: "Florence 6 Column 765mm",
    depthMM: 217,
    heightMM: 867,
    widthMM: 255,
    volumeL: 11.45,
    nominalDeltaT50KW: 0.702,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 398,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-767-06",
    rangeType: "Florence 6",
    model: "Florence 6 Column 765mm",
    depthMM: 217,
    heightMM: 867,
    widthMM: 300,
    volumeL: 13.74,
    nominalDeltaT50KW: 0.842,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 454,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-767-07",
    rangeType: "Florence 6",
    model: "Florence 6 Column 765mm",
    depthMM: 217,
    heightMM: 867,
    widthMM: 345,
    volumeL: 16.03,
    nominalDeltaT50KW: 0.983,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 511,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-767-08",
    rangeType: "Florence 6",
    model: "Florence 6 Column 765mm",
    depthMM: 217,
    heightMM: 867,
    widthMM: 390,
    volumeL: 18.32,
    nominalDeltaT50KW: 1.123,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 567,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-767-09",
    rangeType: "Florence 6",
    model: "Florence 6 Column 765mm",
    depthMM: 217,
    heightMM: 867,
    widthMM: 435,
    volumeL: 20.61,
    nominalDeltaT50KW: 1.264,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 624,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-767-10",
    rangeType: "Florence 6",
    model: "Florence 6 Column 765mm",
    depthMM: 217,
    heightMM: 867,
    widthMM: 480,
    volumeL: 22.9,
    nominalDeltaT50KW: 1.404,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 680,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-767-11",
    rangeType: "Florence 6",
    model: "Florence 6 Column 765mm",
    depthMM: 217,
    heightMM: 867,
    widthMM: 525,
    volumeL: 25.19,
    nominalDeltaT50KW: 1.544,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 737,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-767-12",
    rangeType: "Florence 6",
    model: "Florence 6 Column 765mm",
    depthMM: 217,
    heightMM: 867,
    widthMM: 570,
    volumeL: 27.48,
    nominalDeltaT50KW: 1.685,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 793,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-767-13",
    rangeType: "Florence 6",
    model: "Florence 6 Column 765mm",
    depthMM: 217,
    heightMM: 867,
    widthMM: 615,
    volumeL: 29.77,
    nominalDeltaT50KW: 1.825,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 850,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-767-14",
    rangeType: "Florence 6",
    model: "Florence 6 Column 765mm",
    depthMM: 217,
    heightMM: 867,
    widthMM: 660,
    volumeL: 32.06,
    nominalDeltaT50KW: 1.966,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 906,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-767-15",
    rangeType: "Florence 6",
    model: "Florence 6 Column 765mm",
    depthMM: 217,
    heightMM: 867,
    widthMM: 705,
    volumeL: 34.35,
    nominalDeltaT50KW: 2.106,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 963,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-767-16",
    rangeType: "Florence 6",
    model: "Florence 6 Column 765mm",
    depthMM: 217,
    heightMM: 867,
    widthMM: 750,
    volumeL: 36.64,
    nominalDeltaT50KW: 2.246,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1019,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-767-17",
    rangeType: "Florence 6",
    model: "Florence 6 Column 765mm",
    depthMM: 217,
    heightMM: 867,
    widthMM: 795,
    volumeL: 38.93,
    nominalDeltaT50KW: 2.387,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1076,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-767-18",
    rangeType: "Florence 6",
    model: "Florence 6 Column 765mm",
    depthMM: 217,
    heightMM: 867,
    widthMM: 840,
    volumeL: 41.22,
    nominalDeltaT50KW: 2.527,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1132,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-767-19",
    rangeType: "Florence 6",
    model: "Florence 6 Column 765mm",
    depthMM: 217,
    heightMM: 867,
    widthMM: 885,
    volumeL: 43.51,
    nominalDeltaT50KW: 2.668,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1189,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-767-20",
    rangeType: "Florence 6",
    model: "Florence 6 Column 765mm",
    depthMM: 217,
    heightMM: 867,
    widthMM: 930,
    volumeL: 45.8,
    nominalDeltaT50KW: 2.808,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1245,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-767-21",
    rangeType: "Florence 6",
    model: "Florence 6 Column 765mm",
    depthMM: 217,
    heightMM: 867,
    widthMM: 975,
    volumeL: 48.09,
    nominalDeltaT50KW: 2.948,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1302,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-767-22",
    rangeType: "Florence 6",
    model: "Florence 6 Column 765mm",
    depthMM: 217,
    heightMM: 867,
    widthMM: 1020,
    volumeL: 50.38,
    nominalDeltaT50KW: 3.089,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1358,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-767-23",
    rangeType: "Florence 6",
    model: "Florence 6 Column 765mm",
    depthMM: 217,
    heightMM: 867,
    widthMM: 1065,
    volumeL: 52.67,
    nominalDeltaT50KW: 3.229,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1415,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-767-24",
    rangeType: "Florence 6",
    model: "Florence 6 Column 765mm",
    depthMM: 217,
    heightMM: 867,
    widthMM: 1110,
    volumeL: 54.96,
    nominalDeltaT50KW: 3.37,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1471,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-767-25",
    rangeType: "Florence 6",
    model: "Florence 6 Column 765mm",
    depthMM: 217,
    heightMM: 867,
    widthMM: 1155,
    volumeL: 57.25,
    nominalDeltaT50KW: 3.51,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1528,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-767-26",
    rangeType: "Florence 6",
    model: "Florence 6 Column 765mm",
    depthMM: 217,
    heightMM: 867,
    widthMM: 1200,
    volumeL: 59.54,
    nominalDeltaT50KW: 3.65,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1584,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-767-27",
    rangeType: "Florence 6",
    model: "Florence 6 Column 765mm",
    depthMM: 217,
    heightMM: 867,
    widthMM: 1245,
    volumeL: 61.83,
    nominalDeltaT50KW: 3.791,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1641,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-767-28",
    rangeType: "Florence 6",
    model: "Florence 6 Column 765mm",
    depthMM: 217,
    heightMM: 867,
    widthMM: 1290,
    volumeL: 64.12,
    nominalDeltaT50KW: 3.931,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1697,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-767-29",
    rangeType: "Florence 6",
    model: "Florence 6 Column 765mm",
    depthMM: 217,
    heightMM: 867,
    widthMM: 1335,
    volumeL: 66.41,
    nominalDeltaT50KW: 4.072,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1754,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-767-30",
    rangeType: "Florence 6",
    model: "Florence 6 Column 765mm",
    depthMM: 217,
    heightMM: 867,
    widthMM: 1380,
    volumeL: 68.7,
    nominalDeltaT50KW: 4.212,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1810,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-767-31",
    rangeType: "Florence 6",
    model: "Florence 6 Column 765mm",
    depthMM: 217,
    heightMM: 867,
    widthMM: 1425,
    volumeL: 70.99,
    nominalDeltaT50KW: 4.352,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1867,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-767-32",
    rangeType: "Florence 6",
    model: "Florence 6 Column 765mm",
    depthMM: 217,
    heightMM: 867,
    widthMM: 1470,
    volumeL: 73.28,
    nominalDeltaT50KW: 4.493,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1923,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-767-33",
    rangeType: "Florence 6",
    model: "Florence 6 Column 765mm",
    depthMM: 217,
    heightMM: 867,
    widthMM: 1515,
    volumeL: 75.57,
    nominalDeltaT50KW: 4.633,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1980,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-767-34",
    rangeType: "Florence 6",
    model: "Florence 6 Column 765mm",
    depthMM: 217,
    heightMM: 867,
    widthMM: 1560,
    volumeL: 77.86,
    nominalDeltaT50KW: 4.774,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 2036,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-767-35",
    rangeType: "Florence 6",
    model: "Florence 6 Column 765mm",
    depthMM: 217,
    heightMM: 867,
    widthMM: 1605,
    volumeL: 80.15,
    nominalDeltaT50KW: 4.914,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 2093,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-767-36",
    rangeType: "Florence 6",
    model: "Florence 6 Column 765mm",
    depthMM: 217,
    heightMM: 867,
    widthMM: 1650,
    volumeL: 82.44,
    nominalDeltaT50KW: 5.054,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 2149,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-767-37",
    rangeType: "Florence 6",
    model: "Florence 6 Column 765mm",
    depthMM: 217,
    heightMM: 867,
    widthMM: 1695,
    volumeL: 84.73,
    nominalDeltaT50KW: 5.195,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 2206,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-767-38",
    rangeType: "Florence 6",
    model: "Florence 6 Column 765mm",
    depthMM: 217,
    heightMM: 867,
    widthMM: 1740,
    volumeL: 87.02,
    nominalDeltaT50KW: 5.335,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 2262,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-767-39",
    rangeType: "Florence 6",
    model: "Florence 6 Column 765mm",
    depthMM: 217,
    heightMM: 867,
    widthMM: 1785,
    volumeL: 89.31,
    nominalDeltaT50KW: 5.476,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 2319,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-767-40",
    rangeType: "Florence 6",
    model: "Florence 6 Column 765mm",
    depthMM: 217,
    heightMM: 867,
    widthMM: 1830,
    volumeL: 91.6,
    nominalDeltaT50KW: 5.616,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 2375,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-902-04",
    rangeType: "Florence 6",
    model: "Florence 6 Column 900mm",
    depthMM: 217,
    heightMM: 1002,
    widthMM: 210,
    volumeL: 10.48,
    nominalDeltaT50KW: 0.65,
    kvValue: 20,
    nCoefficient: 1.4,
    price: {
      value: 349,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-902-05",
    rangeType: "Florence 6",
    model: "Florence 6 Column 900mm",
    depthMM: 217,
    heightMM: 1002,
    widthMM: 255,
    volumeL: 13.1,
    nominalDeltaT50KW: 0.813,
    kvValue: 20,
    nCoefficient: 1.4,
    price: {
      value: 408,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-902-06",
    rangeType: "Florence 6",
    model: "Florence 6 Column 900mm",
    depthMM: 217,
    heightMM: 1002,
    widthMM: 300,
    volumeL: 15.72,
    nominalDeltaT50KW: 0.976,
    kvValue: 20,
    nCoefficient: 1.4,
    price: {
      value: 466,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-902-07",
    rangeType: "Florence 6",
    model: "Florence 6 Column 900mm",
    depthMM: 217,
    heightMM: 1002,
    widthMM: 345,
    volumeL: 18.34,
    nominalDeltaT50KW: 1.138,
    kvValue: 20,
    nCoefficient: 1.4,
    price: {
      value: 525,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-902-08",
    rangeType: "Florence 6",
    model: "Florence 6 Column 900mm",
    depthMM: 217,
    heightMM: 1002,
    widthMM: 390,
    volumeL: 20.96,
    nominalDeltaT50KW: 1.301,
    kvValue: 20,
    nCoefficient: 1.4,
    price: {
      value: 583,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-902-09",
    rangeType: "Florence 6",
    model: "Florence 6 Column 900mm",
    depthMM: 217,
    heightMM: 1002,
    widthMM: 435,
    volumeL: 23.58,
    nominalDeltaT50KW: 1.463,
    kvValue: 20,
    nCoefficient: 1.4,
    price: {
      value: 642,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-902-10",
    rangeType: "Florence 6",
    model: "Florence 6 Column 900mm",
    depthMM: 217,
    heightMM: 1002,
    widthMM: 480,
    volumeL: 26.2,
    nominalDeltaT50KW: 1.626,
    kvValue: 20,
    nCoefficient: 1.4,
    price: {
      value: 700,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-902-11",
    rangeType: "Florence 6",
    model: "Florence 6 Column 900mm",
    depthMM: 217,
    heightMM: 1002,
    widthMM: 525,
    volumeL: 28.82,
    nominalDeltaT50KW: 1.789,
    kvValue: 20,
    nCoefficient: 1.4,
    price: {
      value: 759,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-902-12",
    rangeType: "Florence 6",
    model: "Florence 6 Column 900mm",
    depthMM: 217,
    heightMM: 1002,
    widthMM: 570,
    volumeL: 31.44,
    nominalDeltaT50KW: 1.951,
    kvValue: 20,
    nCoefficient: 1.4,
    price: {
      value: 817,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-902-13",
    rangeType: "Florence 6",
    model: "Florence 6 Column 900mm",
    depthMM: 217,
    heightMM: 1002,
    widthMM: 615,
    volumeL: 34.06,
    nominalDeltaT50KW: 2.114,
    kvValue: 20,
    nCoefficient: 1.4,
    price: {
      value: 876,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-902-14",
    rangeType: "Florence 6",
    model: "Florence 6 Column 900mm",
    depthMM: 217,
    heightMM: 1002,
    widthMM: 660,
    volumeL: 36.68,
    nominalDeltaT50KW: 2.276,
    kvValue: 20,
    nCoefficient: 1.4,
    price: {
      value: 934,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-902-15",
    rangeType: "Florence 6",
    model: "Florence 6 Column 900mm",
    depthMM: 217,
    heightMM: 1002,
    widthMM: 705,
    volumeL: 39.3,
    nominalDeltaT50KW: 2.439,
    kvValue: 20,
    nCoefficient: 1.4,
    price: {
      value: 993,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-902-16",
    rangeType: "Florence 6",
    model: "Florence 6 Column 900mm",
    depthMM: 217,
    heightMM: 1002,
    widthMM: 750,
    volumeL: 41.92,
    nominalDeltaT50KW: 2.602,
    kvValue: 20,
    nCoefficient: 1.4,
    price: {
      value: 1051,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-902-17",
    rangeType: "Florence 6",
    model: "Florence 6 Column 900mm",
    depthMM: 217,
    heightMM: 1002,
    widthMM: 795,
    volumeL: 44.54,
    nominalDeltaT50KW: 2.764,
    kvValue: 20,
    nCoefficient: 1.4,
    price: {
      value: 1110,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-902-18",
    rangeType: "Florence 6",
    model: "Florence 6 Column 900mm",
    depthMM: 217,
    heightMM: 1002,
    widthMM: 840,
    volumeL: 47.16,
    nominalDeltaT50KW: 2.927,
    kvValue: 20,
    nCoefficient: 1.4,
    price: {
      value: 1168,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-902-19",
    rangeType: "Florence 6",
    model: "Florence 6 Column 900mm",
    depthMM: 217,
    heightMM: 1002,
    widthMM: 885,
    volumeL: 49.78,
    nominalDeltaT50KW: 3.089,
    kvValue: 20,
    nCoefficient: 1.4,
    price: {
      value: 1227,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-902-20",
    rangeType: "Florence 6",
    model: "Florence 6 Column 900mm",
    depthMM: 217,
    heightMM: 1002,
    widthMM: 930,
    volumeL: 52.4,
    nominalDeltaT50KW: 3.252,
    kvValue: 20,
    nCoefficient: 1.4,
    price: {
      value: 1285,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-902-21",
    rangeType: "Florence 6",
    model: "Florence 6 Column 900mm",
    depthMM: 217,
    heightMM: 1002,
    widthMM: 975,
    volumeL: 55.02,
    nominalDeltaT50KW: 3.415,
    kvValue: 20,
    nCoefficient: 1.4,
    price: {
      value: 1344,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-902-22",
    rangeType: "Florence 6",
    model: "Florence 6 Column 900mm",
    depthMM: 217,
    heightMM: 1002,
    widthMM: 1020,
    volumeL: 57.64,
    nominalDeltaT50KW: 3.577,
    kvValue: 20,
    nCoefficient: 1.4,
    price: {
      value: 1402,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-902-23",
    rangeType: "Florence 6",
    model: "Florence 6 Column 900mm",
    depthMM: 217,
    heightMM: 1002,
    widthMM: 1065,
    volumeL: 60.26,
    nominalDeltaT50KW: 3.74,
    kvValue: 20,
    nCoefficient: 1.4,
    price: {
      value: 1461,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-902-24",
    rangeType: "Florence 6",
    model: "Florence 6 Column 900mm",
    depthMM: 217,
    heightMM: 1002,
    widthMM: 1110,
    volumeL: 62.88,
    nominalDeltaT50KW: 3.902,
    kvValue: 20,
    nCoefficient: 1.4,
    price: {
      value: 1519,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-902-25",
    rangeType: "Florence 6",
    model: "Florence 6 Column 900mm",
    depthMM: 217,
    heightMM: 1002,
    widthMM: 1155,
    volumeL: 65.5,
    nominalDeltaT50KW: 4.065,
    kvValue: 20,
    nCoefficient: 1.4,
    price: {
      value: 1578,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-902-26",
    rangeType: "Florence 6",
    model: "Florence 6 Column 900mm",
    depthMM: 217,
    heightMM: 1002,
    widthMM: 1200,
    volumeL: 68.12,
    nominalDeltaT50KW: 4.228,
    kvValue: 20,
    nCoefficient: 1.4,
    price: {
      value: 1636,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-902-27",
    rangeType: "Florence 6",
    model: "Florence 6 Column 900mm",
    depthMM: 217,
    heightMM: 1002,
    widthMM: 1245,
    volumeL: 70.74,
    nominalDeltaT50KW: 4.39,
    kvValue: 20,
    nCoefficient: 1.4,
    price: {
      value: 1695,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-902-28",
    rangeType: "Florence 6",
    model: "Florence 6 Column 900mm",
    depthMM: 217,
    heightMM: 1002,
    widthMM: 1290,
    volumeL: 73.36,
    nominalDeltaT50KW: 4.553,
    kvValue: 20,
    nCoefficient: 1.4,
    price: {
      value: 1753,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-902-29",
    rangeType: "Florence 6",
    model: "Florence 6 Column 900mm",
    depthMM: 217,
    heightMM: 1002,
    widthMM: 1335,
    volumeL: 75.98,
    nominalDeltaT50KW: 4.715,
    kvValue: 20,
    nCoefficient: 1.4,
    price: {
      value: 1812,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-902-30",
    rangeType: "Florence 6",
    model: "Florence 6 Column 900mm",
    depthMM: 217,
    heightMM: 1002,
    widthMM: 1380,
    volumeL: 78.6,
    nominalDeltaT50KW: 4.878,
    kvValue: 20,
    nCoefficient: 1.4,
    price: {
      value: 1870,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-902-31",
    rangeType: "Florence 6",
    model: "Florence 6 Column 900mm",
    depthMM: 217,
    heightMM: 1002,
    widthMM: 1425,
    volumeL: 81.22,
    nominalDeltaT50KW: 5.041,
    kvValue: 20,
    nCoefficient: 1.4,
    price: {
      value: 1929,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-902-32",
    rangeType: "Florence 6",
    model: "Florence 6 Column 900mm",
    depthMM: 217,
    heightMM: 1002,
    widthMM: 1470,
    volumeL: 83.84,
    nominalDeltaT50KW: 5.203,
    kvValue: 20,
    nCoefficient: 1.4,
    price: {
      value: 1987,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-902-33",
    rangeType: "Florence 6",
    model: "Florence 6 Column 900mm",
    depthMM: 217,
    heightMM: 1002,
    widthMM: 1515,
    volumeL: 86.46,
    nominalDeltaT50KW: 5.366,
    kvValue: 20,
    nCoefficient: 1.4,
    price: {
      value: 2046,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-902-34",
    rangeType: "Florence 6",
    model: "Florence 6 Column 900mm",
    depthMM: 217,
    heightMM: 1002,
    widthMM: 1560,
    volumeL: 89.08,
    nominalDeltaT50KW: 5.528,
    kvValue: 20,
    nCoefficient: 1.4,
    price: {
      value: 2104,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-902-35",
    rangeType: "Florence 6",
    model: "Florence 6 Column 900mm",
    depthMM: 217,
    heightMM: 1002,
    widthMM: 1605,
    volumeL: 91.7,
    nominalDeltaT50KW: 5.691,
    kvValue: 20,
    nCoefficient: 1.4,
    price: {
      value: 2163,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-902-36",
    rangeType: "Florence 6",
    model: "Florence 6 Column 900mm",
    depthMM: 217,
    heightMM: 1002,
    widthMM: 1650,
    volumeL: 94.32,
    nominalDeltaT50KW: 5.854,
    kvValue: 20,
    nCoefficient: 1.4,
    price: {
      value: 2221,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-902-37",
    rangeType: "Florence 6",
    model: "Florence 6 Column 900mm",
    depthMM: 217,
    heightMM: 1002,
    widthMM: 1695,
    volumeL: 96.94,
    nominalDeltaT50KW: 6.016,
    kvValue: 20,
    nCoefficient: 1.4,
    price: {
      value: 2280,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-902-38",
    rangeType: "Florence 6",
    model: "Florence 6 Column 900mm",
    depthMM: 217,
    heightMM: 1002,
    widthMM: 1740,
    volumeL: 99.56,
    nominalDeltaT50KW: 6.179,
    kvValue: 20,
    nCoefficient: 1.4,
    price: {
      value: 2338,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-902-39",
    rangeType: "Florence 6",
    model: "Florence 6 Column 900mm",
    depthMM: 217,
    heightMM: 1002,
    widthMM: 1785,
    volumeL: 102.18,
    nominalDeltaT50KW: 6.341,
    kvValue: 20,
    nCoefficient: 1.4,
    price: {
      value: 2397,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "SMC-F6C-902-40",
    rangeType: "Florence 6",
    model: "Florence 6 Column 900mm",
    depthMM: 217,
    heightMM: 1002,
    widthMM: 1830,
    volumeL: 104.8,
    nominalDeltaT50KW: 6.504,
    kvValue: 20,
    nCoefficient: 1.4,
    price: {
      value: 2455,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-360-04",
    rangeType: "Grace 4",
    model: "Grace 4 Column 360mm",
    depthMM: 175,
    heightMM: 350,
    widthMM: 272,
    volumeL: 2.2,
    nominalDeltaT50KW: 0.175,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 243,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-360-05",
    rangeType: "Grace 4",
    model: "Grace 4 Column 360mm",
    depthMM: 175,
    heightMM: 350,
    widthMM: 333,
    volumeL: 2.75,
    nominalDeltaT50KW: 0.219,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 270,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-360-06",
    rangeType: "Grace 4",
    model: "Grace 4 Column 360mm",
    depthMM: 175,
    heightMM: 350,
    widthMM: 393,
    volumeL: 3.3,
    nominalDeltaT50KW: 0.262,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 297,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-360-07",
    rangeType: "Grace 4",
    model: "Grace 4 Column 360mm",
    depthMM: 175,
    heightMM: 350,
    widthMM: 454,
    volumeL: 3.85,
    nominalDeltaT50KW: 0.306,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 324,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-360-08",
    rangeType: "Grace 4",
    model: "Grace 4 Column 360mm",
    depthMM: 175,
    heightMM: 350,
    widthMM: 514,
    volumeL: 4.4,
    nominalDeltaT50KW: 0.35,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 351,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-360-09",
    rangeType: "Grace 4",
    model: "Grace 4 Column 360mm",
    depthMM: 175,
    heightMM: 350,
    widthMM: 575,
    volumeL: 4.95,
    nominalDeltaT50KW: 0.393,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 378,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-360-10",
    rangeType: "Grace 4",
    model: "Grace 4 Column 360mm",
    depthMM: 175,
    heightMM: 350,
    widthMM: 635,
    volumeL: 5.5,
    nominalDeltaT50KW: 0.437,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 405,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-360-11",
    rangeType: "Grace 4",
    model: "Grace 4 Column 360mm",
    depthMM: 175,
    heightMM: 350,
    widthMM: 696,
    volumeL: 6.05,
    nominalDeltaT50KW: 0.481,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 432,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-360-12",
    rangeType: "Grace 4",
    model: "Grace 4 Column 360mm",
    depthMM: 175,
    heightMM: 350,
    widthMM: 756,
    volumeL: 6.6,
    nominalDeltaT50KW: 0.524,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 459,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-360-13",
    rangeType: "Grace 4",
    model: "Grace 4 Column 360mm",
    depthMM: 175,
    heightMM: 350,
    widthMM: 817,
    volumeL: 7.15,
    nominalDeltaT50KW: 0.568,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 486,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-360-14",
    rangeType: "Grace 4",
    model: "Grace 4 Column 360mm",
    depthMM: 175,
    heightMM: 350,
    widthMM: 877,
    volumeL: 7.7,
    nominalDeltaT50KW: 0.612,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 513,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-360-15",
    rangeType: "Grace 4",
    model: "Grace 4 Column 360mm",
    depthMM: 175,
    heightMM: 350,
    widthMM: 938,
    volumeL: 8.25,
    nominalDeltaT50KW: 0.656,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 540,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-360-16",
    rangeType: "Grace 4",
    model: "Grace 4 Column 360mm",
    depthMM: 175,
    heightMM: 350,
    widthMM: 998,
    volumeL: 8.8,
    nominalDeltaT50KW: 0.699,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 567,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-360-17",
    rangeType: "Grace 4",
    model: "Grace 4 Column 360mm",
    depthMM: 175,
    heightMM: 350,
    widthMM: 1059,
    volumeL: 9.35,
    nominalDeltaT50KW: 0.743,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 594,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-360-18",
    rangeType: "Grace 4",
    model: "Grace 4 Column 360mm",
    depthMM: 175,
    heightMM: 350,
    widthMM: 1119,
    volumeL: 9.9,
    nominalDeltaT50KW: 0.787,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 621,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-360-19",
    rangeType: "Grace 4",
    model: "Grace 4 Column 360mm",
    depthMM: 175,
    heightMM: 350,
    widthMM: 1180,
    volumeL: 10.45,
    nominalDeltaT50KW: 0.83,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 718,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-360-20",
    rangeType: "Grace 4",
    model: "Grace 4 Column 360mm",
    depthMM: 175,
    heightMM: 350,
    widthMM: 1240,
    volumeL: 11,
    nominalDeltaT50KW: 0.874,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 745,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-360-21",
    rangeType: "Grace 4",
    model: "Grace 4 Column 360mm",
    depthMM: 175,
    heightMM: 350,
    widthMM: 1301,
    volumeL: 11.55,
    nominalDeltaT50KW: 0.918,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 772,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-360-22",
    rangeType: "Grace 4",
    model: "Grace 4 Column 360mm",
    depthMM: 175,
    heightMM: 350,
    widthMM: 1361,
    volumeL: 12.1,
    nominalDeltaT50KW: 0.961,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 799,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-360-23",
    rangeType: "Grace 4",
    model: "Grace 4 Column 360mm",
    depthMM: 175,
    heightMM: 350,
    widthMM: 1422,
    volumeL: 12.65,
    nominalDeltaT50KW: 1.005,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 826,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-360-24",
    rangeType: "Grace 4",
    model: "Grace 4 Column 360mm",
    depthMM: 175,
    heightMM: 350,
    widthMM: 1482,
    volumeL: 13.2,
    nominalDeltaT50KW: 1.049,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 853,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-360-25",
    rangeType: "Grace 4",
    model: "Grace 4 Column 360mm",
    depthMM: 175,
    heightMM: 350,
    widthMM: 1543,
    volumeL: 13.75,
    nominalDeltaT50KW: 1.093,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 880,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-360-26",
    rangeType: "Grace 4",
    model: "Grace 4 Column 360mm",
    depthMM: 175,
    heightMM: 350,
    widthMM: 1603,
    volumeL: 14.3,
    nominalDeltaT50KW: 1.136,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 907,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-360-27",
    rangeType: "Grace 4",
    model: "Grace 4 Column 360mm",
    depthMM: 175,
    heightMM: 350,
    widthMM: 1664,
    volumeL: 14.85,
    nominalDeltaT50KW: 1.18,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 934,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-360-28",
    rangeType: "Grace 4",
    model: "Grace 4 Column 360mm",
    depthMM: 175,
    heightMM: 350,
    widthMM: 1724,
    volumeL: 15.4,
    nominalDeltaT50KW: 1.224,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 961,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-360-29",
    rangeType: "Grace 4",
    model: "Grace 4 Column 360mm",
    depthMM: 175,
    heightMM: 350,
    widthMM: 1785,
    volumeL: 15.95,
    nominalDeltaT50KW: 1.267,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 988,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-360-30",
    rangeType: "Grace 4",
    model: "Grace 4 Column 360mm",
    depthMM: 175,
    heightMM: 350,
    widthMM: 1845,
    volumeL: 16.5,
    nominalDeltaT50KW: 1.311,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 1015,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-360-31",
    rangeType: "Grace 4",
    model: "Grace 4 Column 360mm",
    depthMM: 175,
    heightMM: 350,
    widthMM: 1906,
    volumeL: 17.05,
    nominalDeltaT50KW: 1.355,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 1042,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-360-32",
    rangeType: "Grace 4",
    model: "Grace 4 Column 360mm",
    depthMM: 175,
    heightMM: 350,
    widthMM: 1966,
    volumeL: 17.6,
    nominalDeltaT50KW: 1.398,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 1069,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-360-33",
    rangeType: "Grace 4",
    model: "Grace 4 Column 360mm",
    depthMM: 175,
    heightMM: 350,
    widthMM: 2027,
    volumeL: 18.15,
    nominalDeltaT50KW: 1.442,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 1096,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-360-34",
    rangeType: "Grace 4",
    model: "Grace 4 Column 360mm",
    depthMM: 175,
    heightMM: 350,
    widthMM: 2087,
    volumeL: 18.7,
    nominalDeltaT50KW: 1.486,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 1123,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-360-35",
    rangeType: "Grace 4",
    model: "Grace 4 Column 360mm",
    depthMM: 175,
    heightMM: 350,
    widthMM: 2148,
    volumeL: 19.25,
    nominalDeltaT50KW: 1.53,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 1150,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-360-36",
    rangeType: "Grace 4",
    model: "Grace 4 Column 360mm",
    depthMM: 175,
    heightMM: 350,
    widthMM: 2208,
    volumeL: 19.8,
    nominalDeltaT50KW: 1.573,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 1177,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-360-37",
    rangeType: "Grace 4",
    model: "Grace 4 Column 360mm",
    depthMM: 175,
    heightMM: 350,
    widthMM: 2269,
    volumeL: 20.35,
    nominalDeltaT50KW: 1.617,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 1204,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-360-38",
    rangeType: "Grace 4",
    model: "Grace 4 Column 360mm",
    depthMM: 175,
    heightMM: 350,
    widthMM: 2329,
    volumeL: 20.9,
    nominalDeltaT50KW: 1.661,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 1231,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-360-39",
    rangeType: "Grace 4",
    model: "Grace 4 Column 360mm",
    depthMM: 175,
    heightMM: 350,
    widthMM: 2390,
    volumeL: 21.45,
    nominalDeltaT50KW: 1.704,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 1258,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-360-40",
    rangeType: "Grace 4",
    model: "Grace 4 Column 360mm",
    depthMM: 175,
    heightMM: 350,
    widthMM: 2450,
    volumeL: 22,
    nominalDeltaT50KW: 1.748,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 1285,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-500-04",
    rangeType: "Grace 4",
    model: "Grace 4 Column 500mm",
    depthMM: 175,
    heightMM: 500,
    widthMM: 272,
    volumeL: 4.4,
    nominalDeltaT50KW: 0.259,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 267,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-500-05",
    rangeType: "Grace 4",
    model: "Grace 4 Column 500mm",
    depthMM: 175,
    heightMM: 500,
    widthMM: 333,
    volumeL: 5.5,
    nominalDeltaT50KW: 0.324,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 300,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-500-06",
    rangeType: "Grace 4",
    model: "Grace 4 Column 500mm",
    depthMM: 175,
    heightMM: 500,
    widthMM: 393,
    volumeL: 6.6,
    nominalDeltaT50KW: 0.389,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 333,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-500-07",
    rangeType: "Grace 4",
    model: "Grace 4 Column 500mm",
    depthMM: 175,
    heightMM: 500,
    widthMM: 454,
    volumeL: 7.7,
    nominalDeltaT50KW: 0.454,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 366,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-500-08",
    rangeType: "Grace 4",
    model: "Grace 4 Column 500mm",
    depthMM: 175,
    heightMM: 500,
    widthMM: 514,
    volumeL: 8.8,
    nominalDeltaT50KW: 0.518,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 399,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-500-09",
    rangeType: "Grace 4",
    model: "Grace 4 Column 500mm",
    depthMM: 175,
    heightMM: 500,
    widthMM: 575,
    volumeL: 9.9,
    nominalDeltaT50KW: 0.583,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 432,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-500-10",
    rangeType: "Grace 4",
    model: "Grace 4 Column 500mm",
    depthMM: 175,
    heightMM: 500,
    widthMM: 635,
    volumeL: 11,
    nominalDeltaT50KW: 0.648,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 465,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-500-11",
    rangeType: "Grace 4",
    model: "Grace 4 Column 500mm",
    depthMM: 175,
    heightMM: 500,
    widthMM: 696,
    volumeL: 12.1,
    nominalDeltaT50KW: 0.713,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 498,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-500-12",
    rangeType: "Grace 4",
    model: "Grace 4 Column 500mm",
    depthMM: 175,
    heightMM: 500,
    widthMM: 756,
    volumeL: 13.2,
    nominalDeltaT50KW: 0.778,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 531,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-500-13",
    rangeType: "Grace 4",
    model: "Grace 4 Column 500mm",
    depthMM: 175,
    heightMM: 500,
    widthMM: 817,
    volumeL: 14.3,
    nominalDeltaT50KW: 0.842,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 564,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-500-14",
    rangeType: "Grace 4",
    model: "Grace 4 Column 500mm",
    depthMM: 175,
    heightMM: 500,
    widthMM: 877,
    volumeL: 15.4,
    nominalDeltaT50KW: 0.907,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 597,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-500-15",
    rangeType: "Grace 4",
    model: "Grace 4 Column 500mm",
    depthMM: 175,
    heightMM: 500,
    widthMM: 938,
    volumeL: 16.5,
    nominalDeltaT50KW: 0.972,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 630,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-500-16",
    rangeType: "Grace 4",
    model: "Grace 4 Column 500mm",
    depthMM: 175,
    heightMM: 500,
    widthMM: 998,
    volumeL: 17.6,
    nominalDeltaT50KW: 1.037,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 663,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-500-17",
    rangeType: "Grace 4",
    model: "Grace 4 Column 500mm",
    depthMM: 175,
    heightMM: 500,
    widthMM: 1059,
    volumeL: 18.7,
    nominalDeltaT50KW: 1.102,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 696,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-500-18",
    rangeType: "Grace 4",
    model: "Grace 4 Column 500mm",
    depthMM: 175,
    heightMM: 500,
    widthMM: 1119,
    volumeL: 19.8,
    nominalDeltaT50KW: 1.166,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 729,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-500-19",
    rangeType: "Grace 4",
    model: "Grace 4 Column 500mm",
    depthMM: 175,
    heightMM: 500,
    widthMM: 1180,
    volumeL: 20.9,
    nominalDeltaT50KW: 1.231,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 832,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-500-20",
    rangeType: "Grace 4",
    model: "Grace 4 Column 500mm",
    depthMM: 175,
    heightMM: 500,
    widthMM: 1240,
    volumeL: 22,
    nominalDeltaT50KW: 1.296,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 865,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-500-21",
    rangeType: "Grace 4",
    model: "Grace 4 Column 500mm",
    depthMM: 175,
    heightMM: 500,
    widthMM: 1301,
    volumeL: 23.1,
    nominalDeltaT50KW: 1.361,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 898,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-500-22",
    rangeType: "Grace 4",
    model: "Grace 4 Column 500mm",
    depthMM: 175,
    heightMM: 500,
    widthMM: 1361,
    volumeL: 24.2,
    nominalDeltaT50KW: 1.426,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 931,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-500-23",
    rangeType: "Grace 4",
    model: "Grace 4 Column 500mm",
    depthMM: 175,
    heightMM: 500,
    widthMM: 1422,
    volumeL: 25.3,
    nominalDeltaT50KW: 1.49,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 964,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-500-24",
    rangeType: "Grace 4",
    model: "Grace 4 Column 500mm",
    depthMM: 175,
    heightMM: 500,
    widthMM: 1482,
    volumeL: 26.4,
    nominalDeltaT50KW: 1.555,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 997,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-500-25",
    rangeType: "Grace 4",
    model: "Grace 4 Column 500mm",
    depthMM: 175,
    heightMM: 500,
    widthMM: 1543,
    volumeL: 27.5,
    nominalDeltaT50KW: 1.62,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 1030,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-500-26",
    rangeType: "Grace 4",
    model: "Grace 4 Column 500mm",
    depthMM: 175,
    heightMM: 500,
    widthMM: 1603,
    volumeL: 28.6,
    nominalDeltaT50KW: 1.685,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 1063,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-500-27",
    rangeType: "Grace 4",
    model: "Grace 4 Column 500mm",
    depthMM: 175,
    heightMM: 500,
    widthMM: 1664,
    volumeL: 29.7,
    nominalDeltaT50KW: 1.75,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 1096,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-500-28",
    rangeType: "Grace 4",
    model: "Grace 4 Column 500mm",
    depthMM: 175,
    heightMM: 500,
    widthMM: 1724,
    volumeL: 30.8,
    nominalDeltaT50KW: 1.814,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 1129,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-500-29",
    rangeType: "Grace 4",
    model: "Grace 4 Column 500mm",
    depthMM: 175,
    heightMM: 500,
    widthMM: 1785,
    volumeL: 31.9,
    nominalDeltaT50KW: 1.879,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 1162,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-500-30",
    rangeType: "Grace 4",
    model: "Grace 4 Column 500mm",
    depthMM: 175,
    heightMM: 500,
    widthMM: 1845,
    volumeL: 33,
    nominalDeltaT50KW: 1.944,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 1195,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-500-31",
    rangeType: "Grace 4",
    model: "Grace 4 Column 500mm",
    depthMM: 175,
    heightMM: 500,
    widthMM: 1906,
    volumeL: 34.1,
    nominalDeltaT50KW: 2.009,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 1228,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-500-32",
    rangeType: "Grace 4",
    model: "Grace 4 Column 500mm",
    depthMM: 175,
    heightMM: 500,
    widthMM: 1966,
    volumeL: 35.2,
    nominalDeltaT50KW: 2.074,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 1261,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-500-33",
    rangeType: "Grace 4",
    model: "Grace 4 Column 500mm",
    depthMM: 175,
    heightMM: 500,
    widthMM: 2027,
    volumeL: 36.3,
    nominalDeltaT50KW: 2.138,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 1294,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-500-34",
    rangeType: "Grace 4",
    model: "Grace 4 Column 500mm",
    depthMM: 175,
    heightMM: 500,
    widthMM: 2087,
    volumeL: 37.4,
    nominalDeltaT50KW: 2.203,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 1327,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-500-35",
    rangeType: "Grace 4",
    model: "Grace 4 Column 500mm",
    depthMM: 175,
    heightMM: 500,
    widthMM: 2148,
    volumeL: 38.5,
    nominalDeltaT50KW: 2.268,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 1360,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-500-36",
    rangeType: "Grace 4",
    model: "Grace 4 Column 500mm",
    depthMM: 175,
    heightMM: 500,
    widthMM: 2208,
    volumeL: 39.6,
    nominalDeltaT50KW: 2.333,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 1393,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-500-37",
    rangeType: "Grace 4",
    model: "Grace 4 Column 500mm",
    depthMM: 175,
    heightMM: 500,
    widthMM: 2269,
    volumeL: 40.7,
    nominalDeltaT50KW: 2.398,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 1426,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-500-38",
    rangeType: "Grace 4",
    model: "Grace 4 Column 500mm",
    depthMM: 175,
    heightMM: 500,
    widthMM: 2329,
    volumeL: 41.8,
    nominalDeltaT50KW: 2.462,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 1459,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-500-39",
    rangeType: "Grace 4",
    model: "Grace 4 Column 500mm",
    depthMM: 175,
    heightMM: 500,
    widthMM: 2390,
    volumeL: 42.9,
    nominalDeltaT50KW: 2.527,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 1492,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-500-40",
    rangeType: "Grace 4",
    model: "Grace 4 Column 500mm",
    depthMM: 175,
    heightMM: 500,
    widthMM: 2450,
    volumeL: 44,
    nominalDeltaT50KW: 2.592,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 1525,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-660-04",
    rangeType: "Grace 4",
    model: "Grace 4 Column 660mm",
    depthMM: 175,
    heightMM: 650,
    widthMM: 272,
    volumeL: 3.24,
    nominalDeltaT50KW: 0.341,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 295,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-660-05",
    rangeType: "Grace 4",
    model: "Grace 4 Column 660mm",
    depthMM: 175,
    heightMM: 650,
    widthMM: 333,
    volumeL: 4.05,
    nominalDeltaT50KW: 0.427,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 335,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-660-06",
    rangeType: "Grace 4",
    model: "Grace 4 Column 660mm",
    depthMM: 175,
    heightMM: 650,
    widthMM: 393,
    volumeL: 4.86,
    nominalDeltaT50KW: 0.512,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 375,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-660-07",
    rangeType: "Grace 4",
    model: "Grace 4 Column 660mm",
    depthMM: 175,
    heightMM: 650,
    widthMM: 454,
    volumeL: 5.67,
    nominalDeltaT50KW: 0.597,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 415,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-660-08",
    rangeType: "Grace 4",
    model: "Grace 4 Column 660mm",
    depthMM: 175,
    heightMM: 650,
    widthMM: 514,
    volumeL: 6.48,
    nominalDeltaT50KW: 0.682,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 455,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-660-09",
    rangeType: "Grace 4",
    model: "Grace 4 Column 660mm",
    depthMM: 175,
    heightMM: 650,
    widthMM: 575,
    volumeL: 7.29,
    nominalDeltaT50KW: 0.768,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 495,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-660-10",
    rangeType: "Grace 4",
    model: "Grace 4 Column 660mm",
    depthMM: 175,
    heightMM: 650,
    widthMM: 635,
    volumeL: 8.1,
    nominalDeltaT50KW: 0.853,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 535,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-660-11",
    rangeType: "Grace 4",
    model: "Grace 4 Column 660mm",
    depthMM: 175,
    heightMM: 650,
    widthMM: 696,
    volumeL: 8.91,
    nominalDeltaT50KW: 0.938,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 575,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-660-12",
    rangeType: "Grace 4",
    model: "Grace 4 Column 660mm",
    depthMM: 175,
    heightMM: 650,
    widthMM: 756,
    volumeL: 9.72,
    nominalDeltaT50KW: 1.024,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 615,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-660-13",
    rangeType: "Grace 4",
    model: "Grace 4 Column 660mm",
    depthMM: 175,
    heightMM: 650,
    widthMM: 817,
    volumeL: 10.53,
    nominalDeltaT50KW: 1.109,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 655,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-660-14",
    rangeType: "Grace 4",
    model: "Grace 4 Column 660mm",
    depthMM: 175,
    heightMM: 650,
    widthMM: 877,
    volumeL: 11.34,
    nominalDeltaT50KW: 1.194,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 695,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-660-15",
    rangeType: "Grace 4",
    model: "Grace 4 Column 660mm",
    depthMM: 175,
    heightMM: 650,
    widthMM: 938,
    volumeL: 12.15,
    nominalDeltaT50KW: 1.28,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 735,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-660-16",
    rangeType: "Grace 4",
    model: "Grace 4 Column 660mm",
    depthMM: 175,
    heightMM: 650,
    widthMM: 998,
    volumeL: 12.96,
    nominalDeltaT50KW: 1.365,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 775,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-660-17",
    rangeType: "Grace 4",
    model: "Grace 4 Column 660mm",
    depthMM: 175,
    heightMM: 650,
    widthMM: 1059,
    volumeL: 13.77,
    nominalDeltaT50KW: 1.45,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 815,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-660-18",
    rangeType: "Grace 4",
    model: "Grace 4 Column 660mm",
    depthMM: 175,
    heightMM: 650,
    widthMM: 1119,
    volumeL: 14.58,
    nominalDeltaT50KW: 1.535,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 855,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-660-19",
    rangeType: "Grace 4",
    model: "Grace 4 Column 660mm",
    depthMM: 175,
    heightMM: 650,
    widthMM: 1180,
    volumeL: 15.39,
    nominalDeltaT50KW: 1.621,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 965,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-660-20",
    rangeType: "Grace 4",
    model: "Grace 4 Column 660mm",
    depthMM: 175,
    heightMM: 650,
    widthMM: 1240,
    volumeL: 16.2,
    nominalDeltaT50KW: 1.706,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1005,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-660-21",
    rangeType: "Grace 4",
    model: "Grace 4 Column 660mm",
    depthMM: 175,
    heightMM: 650,
    widthMM: 1301,
    volumeL: 17.01,
    nominalDeltaT50KW: 1.791,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1045,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-660-22",
    rangeType: "Grace 4",
    model: "Grace 4 Column 660mm",
    depthMM: 175,
    heightMM: 650,
    widthMM: 1361,
    volumeL: 17.82,
    nominalDeltaT50KW: 1.877,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1085,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-660-23",
    rangeType: "Grace 4",
    model: "Grace 4 Column 660mm",
    depthMM: 175,
    heightMM: 650,
    widthMM: 1422,
    volumeL: 18.63,
    nominalDeltaT50KW: 1.962,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1125,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-660-24",
    rangeType: "Grace 4",
    model: "Grace 4 Column 660mm",
    depthMM: 175,
    heightMM: 650,
    widthMM: 1482,
    volumeL: 19.44,
    nominalDeltaT50KW: 2.047,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1165,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-660-25",
    rangeType: "Grace 4",
    model: "Grace 4 Column 660mm",
    depthMM: 175,
    heightMM: 650,
    widthMM: 1543,
    volumeL: 20.25,
    nominalDeltaT50KW: 2.133,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1205,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-660-26",
    rangeType: "Grace 4",
    model: "Grace 4 Column 660mm",
    depthMM: 175,
    heightMM: 650,
    widthMM: 1603,
    volumeL: 21.06,
    nominalDeltaT50KW: 2.218,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1245,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-660-27",
    rangeType: "Grace 4",
    model: "Grace 4 Column 660mm",
    depthMM: 175,
    heightMM: 650,
    widthMM: 1664,
    volumeL: 21.87,
    nominalDeltaT50KW: 2.303,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1285,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-660-28",
    rangeType: "Grace 4",
    model: "Grace 4 Column 660mm",
    depthMM: 175,
    heightMM: 650,
    widthMM: 1724,
    volumeL: 22.68,
    nominalDeltaT50KW: 2.388,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1325,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-660-29",
    rangeType: "Grace 4",
    model: "Grace 4 Column 660mm",
    depthMM: 175,
    heightMM: 650,
    widthMM: 1785,
    volumeL: 23.49,
    nominalDeltaT50KW: 2.474,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1365,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-660-30",
    rangeType: "Grace 4",
    model: "Grace 4 Column 660mm",
    depthMM: 175,
    heightMM: 650,
    widthMM: 1845,
    volumeL: 24.3,
    nominalDeltaT50KW: 2.559,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1405,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-660-31",
    rangeType: "Grace 4",
    model: "Grace 4 Column 660mm",
    depthMM: 175,
    heightMM: 650,
    widthMM: 1906,
    volumeL: 25.11,
    nominalDeltaT50KW: 2.644,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1445,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-660-32",
    rangeType: "Grace 4",
    model: "Grace 4 Column 660mm",
    depthMM: 175,
    heightMM: 650,
    widthMM: 1966,
    volumeL: 25.92,
    nominalDeltaT50KW: 2.73,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1485,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-660-33",
    rangeType: "Grace 4",
    model: "Grace 4 Column 660mm",
    depthMM: 175,
    heightMM: 650,
    widthMM: 2027,
    volumeL: 26.73,
    nominalDeltaT50KW: 2.815,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1525,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-660-34",
    rangeType: "Grace 4",
    model: "Grace 4 Column 660mm",
    depthMM: 175,
    heightMM: 650,
    widthMM: 2087,
    volumeL: 27.54,
    nominalDeltaT50KW: 2.9,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1565,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-660-35",
    rangeType: "Grace 4",
    model: "Grace 4 Column 660mm",
    depthMM: 175,
    heightMM: 650,
    widthMM: 2148,
    volumeL: 28.35,
    nominalDeltaT50KW: 2.986,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1605,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-660-36",
    rangeType: "Grace 4",
    model: "Grace 4 Column 660mm",
    depthMM: 175,
    heightMM: 650,
    widthMM: 2208,
    volumeL: 29.16,
    nominalDeltaT50KW: 3.071,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1645,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-660-37",
    rangeType: "Grace 4",
    model: "Grace 4 Column 660mm",
    depthMM: 175,
    heightMM: 650,
    widthMM: 2269,
    volumeL: 29.97,
    nominalDeltaT50KW: 3.156,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1685,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-660-38",
    rangeType: "Grace 4",
    model: "Grace 4 Column 660mm",
    depthMM: 175,
    heightMM: 650,
    widthMM: 2329,
    volumeL: 30.78,
    nominalDeltaT50KW: 3.241,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1725,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-660-39",
    rangeType: "Grace 4",
    model: "Grace 4 Column 660mm",
    depthMM: 175,
    heightMM: 650,
    widthMM: 2390,
    volumeL: 31.59,
    nominalDeltaT50KW: 3.327,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1765,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-660-40",
    rangeType: "Grace 4",
    model: "Grace 4 Column 660mm",
    depthMM: 175,
    heightMM: 650,
    widthMM: 2450,
    volumeL: 32.4,
    nominalDeltaT50KW: 3.412,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1805,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-760-04",
    rangeType: "Grace 4",
    model: "Grace 4 Column 760mm",
    depthMM: 175,
    heightMM: 750,
    widthMM: 272,
    volumeL: 4,
    nominalDeltaT50KW: 0.41,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 307,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-760-05",
    rangeType: "Grace 4",
    model: "Grace 4 Column 760mm",
    depthMM: 175,
    heightMM: 750,
    widthMM: 333,
    volumeL: 5,
    nominalDeltaT50KW: 0.512,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 350,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-760-06",
    rangeType: "Grace 4",
    model: "Grace 4 Column 760mm",
    depthMM: 175,
    heightMM: 750,
    widthMM: 393,
    volumeL: 6,
    nominalDeltaT50KW: 0.614,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 393,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-760-07",
    rangeType: "Grace 4",
    model: "Grace 4 Column 760mm",
    depthMM: 175,
    heightMM: 750,
    widthMM: 454,
    volumeL: 7,
    nominalDeltaT50KW: 0.717,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 436,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-760-08",
    rangeType: "Grace 4",
    model: "Grace 4 Column 760mm",
    depthMM: 175,
    heightMM: 750,
    widthMM: 514,
    volumeL: 8,
    nominalDeltaT50KW: 0.819,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 479,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-760-09",
    rangeType: "Grace 4",
    model: "Grace 4 Column 760mm",
    depthMM: 175,
    heightMM: 750,
    widthMM: 575,
    volumeL: 9,
    nominalDeltaT50KW: 0.922,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 522,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-760-10",
    rangeType: "Grace 4",
    model: "Grace 4 Column 760mm",
    depthMM: 175,
    heightMM: 750,
    widthMM: 635,
    volumeL: 10,
    nominalDeltaT50KW: 1.024,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 565,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-760-11",
    rangeType: "Grace 4",
    model: "Grace 4 Column 760mm",
    depthMM: 175,
    heightMM: 750,
    widthMM: 696,
    volumeL: 11,
    nominalDeltaT50KW: 1.126,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 608,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-760-12",
    rangeType: "Grace 4",
    model: "Grace 4 Column 760mm",
    depthMM: 175,
    heightMM: 750,
    widthMM: 756,
    volumeL: 12,
    nominalDeltaT50KW: 1.229,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 651,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-760-13",
    rangeType: "Grace 4",
    model: "Grace 4 Column 760mm",
    depthMM: 175,
    heightMM: 750,
    widthMM: 817,
    volumeL: 13,
    nominalDeltaT50KW: 1.331,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 694,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-760-14",
    rangeType: "Grace 4",
    model: "Grace 4 Column 760mm",
    depthMM: 175,
    heightMM: 750,
    widthMM: 877,
    volumeL: 14,
    nominalDeltaT50KW: 1.434,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 737,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-760-15",
    rangeType: "Grace 4",
    model: "Grace 4 Column 760mm",
    depthMM: 175,
    heightMM: 750,
    widthMM: 938,
    volumeL: 15,
    nominalDeltaT50KW: 1.536,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 780,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-760-16",
    rangeType: "Grace 4",
    model: "Grace 4 Column 760mm",
    depthMM: 175,
    heightMM: 750,
    widthMM: 998,
    volumeL: 16,
    nominalDeltaT50KW: 1.638,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 823,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-760-17",
    rangeType: "Grace 4",
    model: "Grace 4 Column 760mm",
    depthMM: 175,
    heightMM: 750,
    widthMM: 1059,
    volumeL: 17,
    nominalDeltaT50KW: 1.741,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 936,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-760-18",
    rangeType: "Grace 4",
    model: "Grace 4 Column 760mm",
    depthMM: 175,
    heightMM: 750,
    widthMM: 1119,
    volumeL: 18,
    nominalDeltaT50KW: 1.843,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 979,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-760-19",
    rangeType: "Grace 4",
    model: "Grace 4 Column 760mm",
    depthMM: 175,
    heightMM: 750,
    widthMM: 1180,
    volumeL: 19,
    nominalDeltaT50KW: 1.946,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1022,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-760-20",
    rangeType: "Grace 4",
    model: "Grace 4 Column 760mm",
    depthMM: 175,
    heightMM: 750,
    widthMM: 1240,
    volumeL: 20,
    nominalDeltaT50KW: 2.048,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1065,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-760-21",
    rangeType: "Grace 4",
    model: "Grace 4 Column 760mm",
    depthMM: 175,
    heightMM: 750,
    widthMM: 1301,
    volumeL: 21,
    nominalDeltaT50KW: 2.15,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1108,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-760-22",
    rangeType: "Grace 4",
    model: "Grace 4 Column 760mm",
    depthMM: 175,
    heightMM: 750,
    widthMM: 1361,
    volumeL: 22,
    nominalDeltaT50KW: 2.253,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1151,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-760-23",
    rangeType: "Grace 4",
    model: "Grace 4 Column 760mm",
    depthMM: 175,
    heightMM: 750,
    widthMM: 1422,
    volumeL: 23,
    nominalDeltaT50KW: 2.355,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1194,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-760-24",
    rangeType: "Grace 4",
    model: "Grace 4 Column 760mm",
    depthMM: 175,
    heightMM: 750,
    widthMM: 1482,
    volumeL: 24,
    nominalDeltaT50KW: 2.458,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1237,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-760-25",
    rangeType: "Grace 4",
    model: "Grace 4 Column 760mm",
    depthMM: 175,
    heightMM: 750,
    widthMM: 1543,
    volumeL: 25,
    nominalDeltaT50KW: 2.56,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1280,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-760-26",
    rangeType: "Grace 4",
    model: "Grace 4 Column 760mm",
    depthMM: 175,
    heightMM: 750,
    widthMM: 1603,
    volumeL: 26,
    nominalDeltaT50KW: 2.662,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1323,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-760-27",
    rangeType: "Grace 4",
    model: "Grace 4 Column 760mm",
    depthMM: 175,
    heightMM: 750,
    widthMM: 1664,
    volumeL: 27,
    nominalDeltaT50KW: 2.765,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1366,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-760-28",
    rangeType: "Grace 4",
    model: "Grace 4 Column 760mm",
    depthMM: 175,
    heightMM: 750,
    widthMM: 1724,
    volumeL: 28,
    nominalDeltaT50KW: 2.867,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1409,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-760-29",
    rangeType: "Grace 4",
    model: "Grace 4 Column 760mm",
    depthMM: 175,
    heightMM: 750,
    widthMM: 1785,
    volumeL: 29,
    nominalDeltaT50KW: 2.97,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1452,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-760-30",
    rangeType: "Grace 4",
    model: "Grace 4 Column 760mm",
    depthMM: 175,
    heightMM: 750,
    widthMM: 1845,
    volumeL: 30,
    nominalDeltaT50KW: 3.072,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1495,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-760-31",
    rangeType: "Grace 4",
    model: "Grace 4 Column 760mm",
    depthMM: 175,
    heightMM: 750,
    widthMM: 1906,
    volumeL: 31,
    nominalDeltaT50KW: 3.174,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1538,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-760-32",
    rangeType: "Grace 4",
    model: "Grace 4 Column 760mm",
    depthMM: 175,
    heightMM: 750,
    widthMM: 1966,
    volumeL: 32,
    nominalDeltaT50KW: 3.277,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1581,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-760-33",
    rangeType: "Grace 4",
    model: "Grace 4 Column 760mm",
    depthMM: 175,
    heightMM: 750,
    widthMM: 2027,
    volumeL: 33,
    nominalDeltaT50KW: 3.379,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1624,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-760-34",
    rangeType: "Grace 4",
    model: "Grace 4 Column 760mm",
    depthMM: 175,
    heightMM: 750,
    widthMM: 2087,
    volumeL: 34,
    nominalDeltaT50KW: 3.482,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1667,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-760-35",
    rangeType: "Grace 4",
    model: "Grace 4 Column 760mm",
    depthMM: 175,
    heightMM: 750,
    widthMM: 2148,
    volumeL: 35,
    nominalDeltaT50KW: 3.584,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1710,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-760-36",
    rangeType: "Grace 4",
    model: "Grace 4 Column 760mm",
    depthMM: 175,
    heightMM: 750,
    widthMM: 2208,
    volumeL: 36,
    nominalDeltaT50KW: 3.686,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1753,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-760-37",
    rangeType: "Grace 4",
    model: "Grace 4 Column 760mm",
    depthMM: 175,
    heightMM: 750,
    widthMM: 2269,
    volumeL: 37,
    nominalDeltaT50KW: 3.789,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1796,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-760-38",
    rangeType: "Grace 4",
    model: "Grace 4 Column 760mm",
    depthMM: 175,
    heightMM: 750,
    widthMM: 2329,
    volumeL: 38,
    nominalDeltaT50KW: 3.891,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1839,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-760-39",
    rangeType: "Grace 4",
    model: "Grace 4 Column 760mm",
    depthMM: 175,
    heightMM: 750,
    widthMM: 2390,
    volumeL: 39,
    nominalDeltaT50KW: 3.994,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1882,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-760-40",
    rangeType: "Grace 4",
    model: "Grace 4 Column 760mm",
    depthMM: 175,
    heightMM: 750,
    widthMM: 2450,
    volumeL: 40,
    nominalDeltaT50KW: 4.096,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1925,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-960-04",
    rangeType: "Grace 4",
    model: "Grace 4 Column 960mm",
    depthMM: 175,
    heightMM: 950,
    widthMM: 272,
    volumeL: 4.4,
    nominalDeltaT50KW: 0.509,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 359,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-960-05",
    rangeType: "Grace 4",
    model: "Grace 4 Column 960mm",
    depthMM: 175,
    heightMM: 950,
    widthMM: 333,
    volumeL: 5.5,
    nominalDeltaT50KW: 0.636,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 415,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-960-06",
    rangeType: "Grace 4",
    model: "Grace 4 Column 960mm",
    depthMM: 175,
    heightMM: 950,
    widthMM: 393,
    volumeL: 6.6,
    nominalDeltaT50KW: 0.763,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 471,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-960-07",
    rangeType: "Grace 4",
    model: "Grace 4 Column 960mm",
    depthMM: 175,
    heightMM: 950,
    widthMM: 454,
    volumeL: 7.7,
    nominalDeltaT50KW: 0.89,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 527,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-960-08",
    rangeType: "Grace 4",
    model: "Grace 4 Column 960mm",
    depthMM: 175,
    heightMM: 950,
    widthMM: 514,
    volumeL: 8.8,
    nominalDeltaT50KW: 1.018,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 583,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-960-09",
    rangeType: "Grace 4",
    model: "Grace 4 Column 960mm",
    depthMM: 175,
    heightMM: 950,
    widthMM: 575,
    volumeL: 9.9,
    nominalDeltaT50KW: 1.145,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 639,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-960-10",
    rangeType: "Grace 4",
    model: "Grace 4 Column 960mm",
    depthMM: 175,
    heightMM: 950,
    widthMM: 635,
    volumeL: 11,
    nominalDeltaT50KW: 1.272,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 695,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-960-11",
    rangeType: "Grace 4",
    model: "Grace 4 Column 960mm",
    depthMM: 175,
    heightMM: 950,
    widthMM: 696,
    volumeL: 12.1,
    nominalDeltaT50KW: 1.399,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 751,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-960-12",
    rangeType: "Grace 4",
    model: "Grace 4 Column 960mm",
    depthMM: 175,
    heightMM: 950,
    widthMM: 756,
    volumeL: 13.2,
    nominalDeltaT50KW: 1.526,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 807,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-960-13",
    rangeType: "Grace 4",
    model: "Grace 4 Column 960mm",
    depthMM: 175,
    heightMM: 950,
    widthMM: 817,
    volumeL: 14.3,
    nominalDeltaT50KW: 1.654,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 933,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-960-14",
    rangeType: "Grace 4",
    model: "Grace 4 Column 960mm",
    depthMM: 175,
    heightMM: 950,
    widthMM: 877,
    volumeL: 15.4,
    nominalDeltaT50KW: 1.781,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 989,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-960-15",
    rangeType: "Grace 4",
    model: "Grace 4 Column 960mm",
    depthMM: 175,
    heightMM: 950,
    widthMM: 938,
    volumeL: 16.5,
    nominalDeltaT50KW: 1.908,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1045,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-960-16",
    rangeType: "Grace 4",
    model: "Grace 4 Column 960mm",
    depthMM: 175,
    heightMM: 950,
    widthMM: 998,
    volumeL: 17.6,
    nominalDeltaT50KW: 2.035,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1101,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-960-17",
    rangeType: "Grace 4",
    model: "Grace 4 Column 960mm",
    depthMM: 175,
    heightMM: 950,
    widthMM: 1059,
    volumeL: 18.7,
    nominalDeltaT50KW: 2.162,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1157,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-960-18",
    rangeType: "Grace 4",
    model: "Grace 4 Column 960mm",
    depthMM: 175,
    heightMM: 950,
    widthMM: 1119,
    volumeL: 19.8,
    nominalDeltaT50KW: 2.29,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1213,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-960-19",
    rangeType: "Grace 4",
    model: "Grace 4 Column 960mm",
    depthMM: 175,
    heightMM: 950,
    widthMM: 1180,
    volumeL: 20.9,
    nominalDeltaT50KW: 2.417,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1269,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-960-20",
    rangeType: "Grace 4",
    model: "Grace 4 Column 960mm",
    depthMM: 175,
    heightMM: 950,
    widthMM: 1240,
    volumeL: 22,
    nominalDeltaT50KW: 2.544,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1325,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-960-21",
    rangeType: "Grace 4",
    model: "Grace 4 Column 960mm",
    depthMM: 175,
    heightMM: 950,
    widthMM: 1301,
    volumeL: 23.1,
    nominalDeltaT50KW: 2.671,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1381,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-960-22",
    rangeType: "Grace 4",
    model: "Grace 4 Column 960mm",
    depthMM: 175,
    heightMM: 950,
    widthMM: 1361,
    volumeL: 24.2,
    nominalDeltaT50KW: 2.798,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1437,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-960-23",
    rangeType: "Grace 4",
    model: "Grace 4 Column 960mm",
    depthMM: 175,
    heightMM: 950,
    widthMM: 1422,
    volumeL: 25.3,
    nominalDeltaT50KW: 2.926,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1493,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-960-24",
    rangeType: "Grace 4",
    model: "Grace 4 Column 960mm",
    depthMM: 175,
    heightMM: 950,
    widthMM: 1482,
    volumeL: 26.4,
    nominalDeltaT50KW: 3.053,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1549,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-960-25",
    rangeType: "Grace 4",
    model: "Grace 4 Column 960mm",
    depthMM: 175,
    heightMM: 950,
    widthMM: 1543,
    volumeL: 27.5,
    nominalDeltaT50KW: 3.18,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1605,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-960-26",
    rangeType: "Grace 4",
    model: "Grace 4 Column 960mm",
    depthMM: 175,
    heightMM: 950,
    widthMM: 1603,
    volumeL: 28.6,
    nominalDeltaT50KW: 3.307,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1661,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-960-27",
    rangeType: "Grace 4",
    model: "Grace 4 Column 960mm",
    depthMM: 175,
    heightMM: 950,
    widthMM: 1664,
    volumeL: 29.7,
    nominalDeltaT50KW: 3.434,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1717,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-960-28",
    rangeType: "Grace 4",
    model: "Grace 4 Column 960mm",
    depthMM: 175,
    heightMM: 950,
    widthMM: 1724,
    volumeL: 30.8,
    nominalDeltaT50KW: 3.562,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1773,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-960-29",
    rangeType: "Grace 4",
    model: "Grace 4 Column 960mm",
    depthMM: 175,
    heightMM: 950,
    widthMM: 1785,
    volumeL: 31.9,
    nominalDeltaT50KW: 3.689,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1829,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-960-30",
    rangeType: "Grace 4",
    model: "Grace 4 Column 960mm",
    depthMM: 175,
    heightMM: 950,
    widthMM: 1845,
    volumeL: 33,
    nominalDeltaT50KW: 3.816,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1885,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-960-31",
    rangeType: "Grace 4",
    model: "Grace 4 Column 960mm",
    depthMM: 175,
    heightMM: 950,
    widthMM: 1906,
    volumeL: 34.1,
    nominalDeltaT50KW: 3.943,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1941,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-960-32",
    rangeType: "Grace 4",
    model: "Grace 4 Column 960mm",
    depthMM: 175,
    heightMM: 950,
    widthMM: 1966,
    volumeL: 35.2,
    nominalDeltaT50KW: 4.07,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1997,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-960-33",
    rangeType: "Grace 4",
    model: "Grace 4 Column 960mm",
    depthMM: 175,
    heightMM: 950,
    widthMM: 2027,
    volumeL: 36.3,
    nominalDeltaT50KW: 4.198,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2053,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-960-34",
    rangeType: "Grace 4",
    model: "Grace 4 Column 960mm",
    depthMM: 175,
    heightMM: 950,
    widthMM: 2087,
    volumeL: 37.4,
    nominalDeltaT50KW: 4.325,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2109,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-960-35",
    rangeType: "Grace 4",
    model: "Grace 4 Column 960mm",
    depthMM: 175,
    heightMM: 950,
    widthMM: 2148,
    volumeL: 38.5,
    nominalDeltaT50KW: 4.452,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2165,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-960-36",
    rangeType: "Grace 4",
    model: "Grace 4 Column 960mm",
    depthMM: 175,
    heightMM: 950,
    widthMM: 2208,
    volumeL: 39.6,
    nominalDeltaT50KW: 4.579,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2221,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-960-37",
    rangeType: "Grace 4",
    model: "Grace 4 Column 960mm",
    depthMM: 175,
    heightMM: 950,
    widthMM: 2269,
    volumeL: 40.7,
    nominalDeltaT50KW: 4.706,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2277,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-960-38",
    rangeType: "Grace 4",
    model: "Grace 4 Column 960mm",
    depthMM: 175,
    heightMM: 950,
    widthMM: 2329,
    volumeL: 41.8,
    nominalDeltaT50KW: 4.834,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2333,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-960-39",
    rangeType: "Grace 4",
    model: "Grace 4 Column 960mm",
    depthMM: 175,
    heightMM: 950,
    widthMM: 2390,
    volumeL: 42.9,
    nominalDeltaT50KW: 4.961,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2389,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G4C-960-40",
    rangeType: "Grace 4",
    model: "Grace 4 Column 960mm",
    depthMM: 175,
    heightMM: 950,
    widthMM: 2450,
    volumeL: 44,
    nominalDeltaT50KW: 5.088,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 2445,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-485-04",
    rangeType: "Grace 6",
    model: "Grace 6 Column 485mm",
    depthMM: 255,
    heightMM: 500,
    widthMM: 272,
    volumeL: 2.8,
    nominalDeltaT50KW: 0.355,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 303,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-485-05",
    rangeType: "Grace 6",
    model: "Grace 6 Column 485mm",
    depthMM: 255,
    heightMM: 500,
    widthMM: 333,
    volumeL: 3.5,
    nominalDeltaT50KW: 0.444,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 345,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-485-06",
    rangeType: "Grace 6",
    model: "Grace 6 Column 485mm",
    depthMM: 255,
    heightMM: 500,
    widthMM: 393,
    volumeL: 4.2,
    nominalDeltaT50KW: 0.533,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 387,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-485-07",
    rangeType: "Grace 6",
    model: "Grace 6 Column 485mm",
    depthMM: 255,
    heightMM: 500,
    widthMM: 454,
    volumeL: 4.9,
    nominalDeltaT50KW: 0.622,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 429,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-485-08",
    rangeType: "Grace 6",
    model: "Grace 6 Column 485mm",
    depthMM: 255,
    heightMM: 500,
    widthMM: 514,
    volumeL: 5.6,
    nominalDeltaT50KW: 0.71,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 471,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-485-09",
    rangeType: "Grace 6",
    model: "Grace 6 Column 485mm",
    depthMM: 255,
    heightMM: 500,
    widthMM: 575,
    volumeL: 6.3,
    nominalDeltaT50KW: 0.799,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 513,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-485-10",
    rangeType: "Grace 6",
    model: "Grace 6 Column 485mm",
    depthMM: 255,
    heightMM: 500,
    widthMM: 635,
    volumeL: 7,
    nominalDeltaT50KW: 0.888,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 555,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-485-11",
    rangeType: "Grace 6",
    model: "Grace 6 Column 485mm",
    depthMM: 255,
    heightMM: 500,
    widthMM: 696,
    volumeL: 7.7,
    nominalDeltaT50KW: 0.977,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 597,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-485-12",
    rangeType: "Grace 6",
    model: "Grace 6 Column 485mm",
    depthMM: 255,
    heightMM: 500,
    widthMM: 756,
    volumeL: 8.4,
    nominalDeltaT50KW: 1.066,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 639,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-485-13",
    rangeType: "Grace 6",
    model: "Grace 6 Column 485mm",
    depthMM: 255,
    heightMM: 500,
    widthMM: 817,
    volumeL: 9.1,
    nominalDeltaT50KW: 1.154,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 681,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-485-14",
    rangeType: "Grace 6",
    model: "Grace 6 Column 485mm",
    depthMM: 255,
    heightMM: 500,
    widthMM: 877,
    volumeL: 9.8,
    nominalDeltaT50KW: 1.243,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 723,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-485-15",
    rangeType: "Grace 6",
    model: "Grace 6 Column 485mm",
    depthMM: 255,
    heightMM: 500,
    widthMM: 938,
    volumeL: 10.5,
    nominalDeltaT50KW: 1.332,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 765,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-485-16",
    rangeType: "Grace 6",
    model: "Grace 6 Column 485mm",
    depthMM: 255,
    heightMM: 500,
    widthMM: 998,
    volumeL: 11.2,
    nominalDeltaT50KW: 1.421,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 877,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-485-17",
    rangeType: "Grace 6",
    model: "Grace 6 Column 485mm",
    depthMM: 255,
    heightMM: 500,
    widthMM: 1059,
    volumeL: 11.9,
    nominalDeltaT50KW: 1.51,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 919,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-485-18",
    rangeType: "Grace 6",
    model: "Grace 6 Column 485mm",
    depthMM: 255,
    heightMM: 500,
    widthMM: 1119,
    volumeL: 12.6,
    nominalDeltaT50KW: 1.598,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 961,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-485-19",
    rangeType: "Grace 6",
    model: "Grace 6 Column 485mm",
    depthMM: 255,
    heightMM: 500,
    widthMM: 1180,
    volumeL: 13.3,
    nominalDeltaT50KW: 1.687,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1003,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-485-20",
    rangeType: "Grace 6",
    model: "Grace 6 Column 485mm",
    depthMM: 255,
    heightMM: 500,
    widthMM: 1240,
    volumeL: 14,
    nominalDeltaT50KW: 1.776,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1045,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-485-21",
    rangeType: "Grace 6",
    model: "Grace 6 Column 485mm",
    depthMM: 255,
    heightMM: 500,
    widthMM: 1301,
    volumeL: 14.7,
    nominalDeltaT50KW: 1.865,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1087,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-485-22",
    rangeType: "Grace 6",
    model: "Grace 6 Column 485mm",
    depthMM: 255,
    heightMM: 500,
    widthMM: 1361,
    volumeL: 15.4,
    nominalDeltaT50KW: 1.954,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1129,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-485-23",
    rangeType: "Grace 6",
    model: "Grace 6 Column 485mm",
    depthMM: 255,
    heightMM: 500,
    widthMM: 1422,
    volumeL: 16.1,
    nominalDeltaT50KW: 2.042,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1171,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-485-24",
    rangeType: "Grace 6",
    model: "Grace 6 Column 485mm",
    depthMM: 255,
    heightMM: 500,
    widthMM: 1482,
    volumeL: 16.8,
    nominalDeltaT50KW: 2.131,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1213,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-485-25",
    rangeType: "Grace 6",
    model: "Grace 6 Column 485mm",
    depthMM: 255,
    heightMM: 500,
    widthMM: 1543,
    volumeL: 17.5,
    nominalDeltaT50KW: 2.22,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1255,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-485-26",
    rangeType: "Grace 6",
    model: "Grace 6 Column 485mm",
    depthMM: 255,
    heightMM: 500,
    widthMM: 1603,
    volumeL: 18.2,
    nominalDeltaT50KW: 2.309,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1297,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-485-27",
    rangeType: "Grace 6",
    model: "Grace 6 Column 485mm",
    depthMM: 255,
    heightMM: 500,
    widthMM: 1664,
    volumeL: 18.9,
    nominalDeltaT50KW: 2.398,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1339,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-485-28",
    rangeType: "Grace 6",
    model: "Grace 6 Column 485mm",
    depthMM: 255,
    heightMM: 500,
    widthMM: 1724,
    volumeL: 19.6,
    nominalDeltaT50KW: 2.486,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1381,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-485-29",
    rangeType: "Grace 6",
    model: "Grace 6 Column 485mm",
    depthMM: 255,
    heightMM: 500,
    widthMM: 1785,
    volumeL: 20.3,
    nominalDeltaT50KW: 2.575,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1423,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-485-30",
    rangeType: "Grace 6",
    model: "Grace 6 Column 485mm",
    depthMM: 255,
    heightMM: 500,
    widthMM: 1845,
    volumeL: 21,
    nominalDeltaT50KW: 2.664,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1465,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-485-31",
    rangeType: "Grace 6",
    model: "Grace 6 Column 485mm",
    depthMM: 255,
    heightMM: 500,
    widthMM: 1906,
    volumeL: 21.7,
    nominalDeltaT50KW: 2.753,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1507,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-485-32",
    rangeType: "Grace 6",
    model: "Grace 6 Column 485mm",
    depthMM: 255,
    heightMM: 500,
    widthMM: 1966,
    volumeL: 22.4,
    nominalDeltaT50KW: 2.842,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1549,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-485-33",
    rangeType: "Grace 6",
    model: "Grace 6 Column 485mm",
    depthMM: 255,
    heightMM: 500,
    widthMM: 2027,
    volumeL: 23.1,
    nominalDeltaT50KW: 2.93,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1591,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-485-34",
    rangeType: "Grace 6",
    model: "Grace 6 Column 485mm",
    depthMM: 255,
    heightMM: 500,
    widthMM: 2087,
    volumeL: 23.8,
    nominalDeltaT50KW: 3.019,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1633,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-485-35",
    rangeType: "Grace 6",
    model: "Grace 6 Column 485mm",
    depthMM: 255,
    heightMM: 500,
    widthMM: 2148,
    volumeL: 24.5,
    nominalDeltaT50KW: 3.108,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1675,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-485-36",
    rangeType: "Grace 6",
    model: "Grace 6 Column 485mm",
    depthMM: 255,
    heightMM: 500,
    widthMM: 2208,
    volumeL: 25.2,
    nominalDeltaT50KW: 3.197,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1717,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-485-37",
    rangeType: "Grace 6",
    model: "Grace 6 Column 485mm",
    depthMM: 255,
    heightMM: 500,
    widthMM: 2269,
    volumeL: 25.9,
    nominalDeltaT50KW: 3.286,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1759,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-485-38",
    rangeType: "Grace 6",
    model: "Grace 6 Column 485mm",
    depthMM: 255,
    heightMM: 500,
    widthMM: 2329,
    volumeL: 26.6,
    nominalDeltaT50KW: 3.374,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1801,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-485-39",
    rangeType: "Grace 6",
    model: "Grace 6 Column 485mm",
    depthMM: 255,
    heightMM: 500,
    widthMM: 2390,
    volumeL: 27.3,
    nominalDeltaT50KW: 3.463,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1843,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-485-40",
    rangeType: "Grace 6",
    model: "Grace 6 Column 485mm",
    depthMM: 255,
    heightMM: 500,
    widthMM: 2450,
    volumeL: 28,
    nominalDeltaT50KW: 3.552,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1885,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-660-04",
    rangeType: "Grace 6",
    model: "Grace 6 Column 660mm",
    depthMM: 255,
    heightMM: 650,
    widthMM: 272,
    volumeL: 5.2,
    nominalDeltaT50KW: 0.481,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 371,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-660-05",
    rangeType: "Grace 6",
    model: "Grace 6 Column 660mm",
    depthMM: 255,
    heightMM: 650,
    widthMM: 333,
    volumeL: 6.5,
    nominalDeltaT50KW: 0.602,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 430,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-660-06",
    rangeType: "Grace 6",
    model: "Grace 6 Column 660mm",
    depthMM: 255,
    heightMM: 650,
    widthMM: 393,
    volumeL: 7.8,
    nominalDeltaT50KW: 0.722,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 489,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-660-07",
    rangeType: "Grace 6",
    model: "Grace 6 Column 660mm",
    depthMM: 255,
    heightMM: 650,
    widthMM: 454,
    volumeL: 9.1,
    nominalDeltaT50KW: 0.842,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 548,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-660-08",
    rangeType: "Grace 6",
    model: "Grace 6 Column 660mm",
    depthMM: 255,
    heightMM: 650,
    widthMM: 514,
    volumeL: 10.4,
    nominalDeltaT50KW: 0.962,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 607,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-660-09",
    rangeType: "Grace 6",
    model: "Grace 6 Column 660mm",
    depthMM: 255,
    heightMM: 650,
    widthMM: 575,
    volumeL: 11.7,
    nominalDeltaT50KW: 1.083,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 666,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-660-10",
    rangeType: "Grace 6",
    model: "Grace 6 Column 660mm",
    depthMM: 255,
    heightMM: 650,
    widthMM: 635,
    volumeL: 13,
    nominalDeltaT50KW: 1.203,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 725,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-660-11",
    rangeType: "Grace 6",
    model: "Grace 6 Column 660mm",
    depthMM: 255,
    heightMM: 650,
    widthMM: 696,
    volumeL: 14.3,
    nominalDeltaT50KW: 1.323,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 784,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-660-12",
    rangeType: "Grace 6",
    model: "Grace 6 Column 660mm",
    depthMM: 255,
    heightMM: 650,
    widthMM: 756,
    volumeL: 15.6,
    nominalDeltaT50KW: 1.444,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 843,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-660-13",
    rangeType: "Grace 6",
    model: "Grace 6 Column 660mm",
    depthMM: 255,
    heightMM: 650,
    widthMM: 817,
    volumeL: 16.9,
    nominalDeltaT50KW: 1.564,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 972,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-660-14",
    rangeType: "Grace 6",
    model: "Grace 6 Column 660mm",
    depthMM: 255,
    heightMM: 650,
    widthMM: 877,
    volumeL: 18.2,
    nominalDeltaT50KW: 1.684,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1031,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-660-15",
    rangeType: "Grace 6",
    model: "Grace 6 Column 660mm",
    depthMM: 255,
    heightMM: 650,
    widthMM: 938,
    volumeL: 19.5,
    nominalDeltaT50KW: 1.805,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1090,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-660-16",
    rangeType: "Grace 6",
    model: "Grace 6 Column 660mm",
    depthMM: 255,
    heightMM: 650,
    widthMM: 998,
    volumeL: 20.8,
    nominalDeltaT50KW: 1.925,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1149,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-660-17",
    rangeType: "Grace 6",
    model: "Grace 6 Column 660mm",
    depthMM: 255,
    heightMM: 650,
    widthMM: 1059,
    volumeL: 22.1,
    nominalDeltaT50KW: 2.045,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1208,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-660-18",
    rangeType: "Grace 6",
    model: "Grace 6 Column 660mm",
    depthMM: 255,
    heightMM: 650,
    widthMM: 1119,
    volumeL: 23.4,
    nominalDeltaT50KW: 2.165,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1267,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-660-19",
    rangeType: "Grace 6",
    model: "Grace 6 Column 660mm",
    depthMM: 255,
    heightMM: 650,
    widthMM: 1180,
    volumeL: 24.7,
    nominalDeltaT50KW: 2.286,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1326,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-660-20",
    rangeType: "Grace 6",
    model: "Grace 6 Column 660mm",
    depthMM: 255,
    heightMM: 650,
    widthMM: 1240,
    volumeL: 26,
    nominalDeltaT50KW: 2.406,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1385,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-660-21",
    rangeType: "Grace 6",
    model: "Grace 6 Column 660mm",
    depthMM: 255,
    heightMM: 650,
    widthMM: 1301,
    volumeL: 27.3,
    nominalDeltaT50KW: 2.526,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1444,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-660-22",
    rangeType: "Grace 6",
    model: "Grace 6 Column 660mm",
    depthMM: 255,
    heightMM: 650,
    widthMM: 1361,
    volumeL: 28.6,
    nominalDeltaT50KW: 2.647,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1503,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-660-23",
    rangeType: "Grace 6",
    model: "Grace 6 Column 660mm",
    depthMM: 255,
    heightMM: 650,
    widthMM: 1422,
    volumeL: 29.9,
    nominalDeltaT50KW: 2.767,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1562,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-660-24",
    rangeType: "Grace 6",
    model: "Grace 6 Column 660mm",
    depthMM: 255,
    heightMM: 650,
    widthMM: 1482,
    volumeL: 31.2,
    nominalDeltaT50KW: 2.887,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1621,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-660-25",
    rangeType: "Grace 6",
    model: "Grace 6 Column 660mm",
    depthMM: 255,
    heightMM: 650,
    widthMM: 1543,
    volumeL: 32.5,
    nominalDeltaT50KW: 3.008,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1680,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-660-26",
    rangeType: "Grace 6",
    model: "Grace 6 Column 660mm",
    depthMM: 255,
    heightMM: 650,
    widthMM: 1603,
    volumeL: 33.8,
    nominalDeltaT50KW: 3.128,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1739,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-660-27",
    rangeType: "Grace 6",
    model: "Grace 6 Column 660mm",
    depthMM: 255,
    heightMM: 650,
    widthMM: 1664,
    volumeL: 35.1,
    nominalDeltaT50KW: 3.248,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1798,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-660-28",
    rangeType: "Grace 6",
    model: "Grace 6 Column 660mm",
    depthMM: 255,
    heightMM: 650,
    widthMM: 1724,
    volumeL: 36.4,
    nominalDeltaT50KW: 3.368,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1857,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-660-29",
    rangeType: "Grace 6",
    model: "Grace 6 Column 660mm",
    depthMM: 255,
    heightMM: 650,
    widthMM: 1785,
    volumeL: 37.7,
    nominalDeltaT50KW: 3.489,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1916,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-660-30",
    rangeType: "Grace 6",
    model: "Grace 6 Column 660mm",
    depthMM: 255,
    heightMM: 650,
    widthMM: 1845,
    volumeL: 39,
    nominalDeltaT50KW: 3.609,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 1975,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-660-31",
    rangeType: "Grace 6",
    model: "Grace 6 Column 660mm",
    depthMM: 255,
    heightMM: 650,
    widthMM: 1906,
    volumeL: 40.3,
    nominalDeltaT50KW: 3.729,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 2034,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-660-32",
    rangeType: "Grace 6",
    model: "Grace 6 Column 660mm",
    depthMM: 255,
    heightMM: 650,
    widthMM: 1966,
    volumeL: 41.6,
    nominalDeltaT50KW: 3.85,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 2093,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-660-33",
    rangeType: "Grace 6",
    model: "Grace 6 Column 660mm",
    depthMM: 255,
    heightMM: 650,
    widthMM: 2027,
    volumeL: 42.9,
    nominalDeltaT50KW: 3.97,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 2152,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-660-34",
    rangeType: "Grace 6",
    model: "Grace 6 Column 660mm",
    depthMM: 255,
    heightMM: 650,
    widthMM: 2087,
    volumeL: 44.2,
    nominalDeltaT50KW: 4.09,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 2211,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-660-35",
    rangeType: "Grace 6",
    model: "Grace 6 Column 660mm",
    depthMM: 255,
    heightMM: 650,
    widthMM: 2148,
    volumeL: 45.5,
    nominalDeltaT50KW: 4.211,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 2270,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-660-36",
    rangeType: "Grace 6",
    model: "Grace 6 Column 660mm",
    depthMM: 255,
    heightMM: 650,
    widthMM: 2208,
    volumeL: 46.8,
    nominalDeltaT50KW: 4.331,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 2329,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-660-37",
    rangeType: "Grace 6",
    model: "Grace 6 Column 660mm",
    depthMM: 255,
    heightMM: 650,
    widthMM: 2269,
    volumeL: 48.1,
    nominalDeltaT50KW: 4.451,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 2388,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-660-38",
    rangeType: "Grace 6",
    model: "Grace 6 Column 660mm",
    depthMM: 255,
    heightMM: 650,
    widthMM: 2329,
    volumeL: 49.4,
    nominalDeltaT50KW: 4.571,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 2447,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-660-39",
    rangeType: "Grace 6",
    model: "Grace 6 Column 660mm",
    depthMM: 255,
    heightMM: 650,
    widthMM: 2390,
    volumeL: 50.7,
    nominalDeltaT50KW: 4.692,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 2506,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-660-40",
    rangeType: "Grace 6",
    model: "Grace 6 Column 660mm",
    depthMM: 255,
    heightMM: 650,
    widthMM: 2450,
    volumeL: 52,
    nominalDeltaT50KW: 4.812,
    kvValue: 20,
    nCoefficient: 1.31,
    price: {
      value: 2565,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-760-04",
    rangeType: "Grace 6",
    model: "Grace 6 Column 760mm",
    depthMM: 255,
    heightMM: 750,
    widthMM: 272,
    volumeL: 5.6,
    nominalDeltaT50KW: 0.565,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 395,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-760-05",
    rangeType: "Grace 6",
    model: "Grace 6 Column 760mm",
    depthMM: 255,
    heightMM: 750,
    widthMM: 333,
    volumeL: 7,
    nominalDeltaT50KW: 0.707,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 460,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-760-06",
    rangeType: "Grace 6",
    model: "Grace 6 Column 760mm",
    depthMM: 255,
    heightMM: 750,
    widthMM: 393,
    volumeL: 8.4,
    nominalDeltaT50KW: 0.848,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 525,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-760-07",
    rangeType: "Grace 6",
    model: "Grace 6 Column 760mm",
    depthMM: 255,
    heightMM: 750,
    widthMM: 454,
    volumeL: 9.8,
    nominalDeltaT50KW: 0.989,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 590,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-760-08",
    rangeType: "Grace 6",
    model: "Grace 6 Column 760mm",
    depthMM: 255,
    heightMM: 750,
    widthMM: 514,
    volumeL: 11.2,
    nominalDeltaT50KW: 1.13,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 655,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-760-09",
    rangeType: "Grace 6",
    model: "Grace 6 Column 760mm",
    depthMM: 255,
    heightMM: 750,
    widthMM: 575,
    volumeL: 12.6,
    nominalDeltaT50KW: 1.272,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 720,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-760-10",
    rangeType: "Grace 6",
    model: "Grace 6 Column 760mm",
    depthMM: 255,
    heightMM: 750,
    widthMM: 635,
    volumeL: 14,
    nominalDeltaT50KW: 1.413,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 785,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-760-11",
    rangeType: "Grace 6",
    model: "Grace 6 Column 760mm",
    depthMM: 255,
    heightMM: 750,
    widthMM: 696,
    volumeL: 15.4,
    nominalDeltaT50KW: 1.554,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 920,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-760-12",
    rangeType: "Grace 6",
    model: "Grace 6 Column 760mm",
    depthMM: 255,
    heightMM: 750,
    widthMM: 756,
    volumeL: 16.8,
    nominalDeltaT50KW: 1.696,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 985,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-760-13",
    rangeType: "Grace 6",
    model: "Grace 6 Column 760mm",
    depthMM: 255,
    heightMM: 750,
    widthMM: 817,
    volumeL: 18.2,
    nominalDeltaT50KW: 1.837,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1050,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-760-14",
    rangeType: "Grace 6",
    model: "Grace 6 Column 760mm",
    depthMM: 255,
    heightMM: 750,
    widthMM: 877,
    volumeL: 19.6,
    nominalDeltaT50KW: 1.978,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1115,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-760-15",
    rangeType: "Grace 6",
    model: "Grace 6 Column 760mm",
    depthMM: 255,
    heightMM: 750,
    widthMM: 938,
    volumeL: 21,
    nominalDeltaT50KW: 2.12,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1180,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-760-16",
    rangeType: "Grace 6",
    model: "Grace 6 Column 760mm",
    depthMM: 255,
    heightMM: 750,
    widthMM: 998,
    volumeL: 22.4,
    nominalDeltaT50KW: 2.261,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1245,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-760-17",
    rangeType: "Grace 6",
    model: "Grace 6 Column 760mm",
    depthMM: 255,
    heightMM: 750,
    widthMM: 1059,
    volumeL: 23.8,
    nominalDeltaT50KW: 2.402,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1310,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-760-18",
    rangeType: "Grace 6",
    model: "Grace 6 Column 760mm",
    depthMM: 255,
    heightMM: 750,
    widthMM: 1119,
    volumeL: 25.2,
    nominalDeltaT50KW: 2.543,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1375,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-760-19",
    rangeType: "Grace 6",
    model: "Grace 6 Column 760mm",
    depthMM: 255,
    heightMM: 750,
    widthMM: 1180,
    volumeL: 26.6,
    nominalDeltaT50KW: 2.685,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1440,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-760-20",
    rangeType: "Grace 6",
    model: "Grace 6 Column 760mm",
    depthMM: 255,
    heightMM: 750,
    widthMM: 1240,
    volumeL: 28,
    nominalDeltaT50KW: 2.826,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1505,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-760-21",
    rangeType: "Grace 6",
    model: "Grace 6 Column 760mm",
    depthMM: 255,
    heightMM: 750,
    widthMM: 1301,
    volumeL: 29.4,
    nominalDeltaT50KW: 2.967,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1570,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-760-22",
    rangeType: "Grace 6",
    model: "Grace 6 Column 760mm",
    depthMM: 255,
    heightMM: 750,
    widthMM: 1361,
    volumeL: 30.8,
    nominalDeltaT50KW: 3.109,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1635,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-760-23",
    rangeType: "Grace 6",
    model: "Grace 6 Column 760mm",
    depthMM: 255,
    heightMM: 750,
    widthMM: 1422,
    volumeL: 32.2,
    nominalDeltaT50KW: 3.25,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1700,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-760-24",
    rangeType: "Grace 6",
    model: "Grace 6 Column 760mm",
    depthMM: 255,
    heightMM: 750,
    widthMM: 1482,
    volumeL: 33.6,
    nominalDeltaT50KW: 3.391,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1765,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-760-25",
    rangeType: "Grace 6",
    model: "Grace 6 Column 760mm",
    depthMM: 255,
    heightMM: 750,
    widthMM: 1543,
    volumeL: 35,
    nominalDeltaT50KW: 3.533,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1830,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-760-26",
    rangeType: "Grace 6",
    model: "Grace 6 Column 760mm",
    depthMM: 255,
    heightMM: 750,
    widthMM: 1603,
    volumeL: 36.4,
    nominalDeltaT50KW: 3.674,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1895,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-760-27",
    rangeType: "Grace 6",
    model: "Grace 6 Column 760mm",
    depthMM: 255,
    heightMM: 750,
    widthMM: 1664,
    volumeL: 37.8,
    nominalDeltaT50KW: 3.815,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1960,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-760-28",
    rangeType: "Grace 6",
    model: "Grace 6 Column 760mm",
    depthMM: 255,
    heightMM: 750,
    widthMM: 1724,
    volumeL: 39.2,
    nominalDeltaT50KW: 3.956,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 2025,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-760-29",
    rangeType: "Grace 6",
    model: "Grace 6 Column 760mm",
    depthMM: 255,
    heightMM: 750,
    widthMM: 1785,
    volumeL: 40.6,
    nominalDeltaT50KW: 4.098,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 2090,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-760-30",
    rangeType: "Grace 6",
    model: "Grace 6 Column 760mm",
    depthMM: 255,
    heightMM: 750,
    widthMM: 1845,
    volumeL: 42,
    nominalDeltaT50KW: 4.239,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 2155,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-760-31",
    rangeType: "Grace 6",
    model: "Grace 6 Column 760mm",
    depthMM: 255,
    heightMM: 750,
    widthMM: 1906,
    volumeL: 43.4,
    nominalDeltaT50KW: 4.38,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 2220,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-760-32",
    rangeType: "Grace 6",
    model: "Grace 6 Column 760mm",
    depthMM: 255,
    heightMM: 750,
    widthMM: 1966,
    volumeL: 44.8,
    nominalDeltaT50KW: 4.522,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 2285,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-760-33",
    rangeType: "Grace 6",
    model: "Grace 6 Column 760mm",
    depthMM: 255,
    heightMM: 750,
    widthMM: 2027,
    volumeL: 46.2,
    nominalDeltaT50KW: 4.663,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 2350,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-760-34",
    rangeType: "Grace 6",
    model: "Grace 6 Column 760mm",
    depthMM: 255,
    heightMM: 750,
    widthMM: 2087,
    volumeL: 47.6,
    nominalDeltaT50KW: 4.804,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 2415,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-760-35",
    rangeType: "Grace 6",
    model: "Grace 6 Column 760mm",
    depthMM: 255,
    heightMM: 750,
    widthMM: 2148,
    volumeL: 49,
    nominalDeltaT50KW: 4.946,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 2480,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-760-36",
    rangeType: "Grace 6",
    model: "Grace 6 Column 760mm",
    depthMM: 255,
    heightMM: 750,
    widthMM: 2208,
    volumeL: 50.4,
    nominalDeltaT50KW: 5.087,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 2545,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-760-37",
    rangeType: "Grace 6",
    model: "Grace 6 Column 760mm",
    depthMM: 255,
    heightMM: 750,
    widthMM: 2269,
    volumeL: 51.8,
    nominalDeltaT50KW: 5.228,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 2610,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-760-38",
    rangeType: "Grace 6",
    model: "Grace 6 Column 760mm",
    depthMM: 255,
    heightMM: 750,
    widthMM: 2329,
    volumeL: 53.2,
    nominalDeltaT50KW: 5.369,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 2675,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-760-39",
    rangeType: "Grace 6",
    model: "Grace 6 Column 760mm",
    depthMM: 255,
    heightMM: 750,
    widthMM: 2390,
    volumeL: 54.6,
    nominalDeltaT50KW: 5.511,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 2740,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-760-40",
    rangeType: "Grace 6",
    model: "Grace 6 Column 760mm",
    depthMM: 255,
    heightMM: 750,
    widthMM: 2450,
    volumeL: 56,
    nominalDeltaT50KW: 5.652,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 2805,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-960-04",
    rangeType: "Grace 6",
    model: "Grace 6 Column 960mm",
    depthMM: 255,
    heightMM: 950,
    widthMM: 272,
    volumeL: 6,
    nominalDeltaT50KW: 0.699,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 455,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-960-05",
    rangeType: "Grace 6",
    model: "Grace 6 Column 960mm",
    depthMM: 255,
    heightMM: 950,
    widthMM: 333,
    volumeL: 7.5,
    nominalDeltaT50KW: 0.874,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 535,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-960-06",
    rangeType: "Grace 6",
    model: "Grace 6 Column 960mm",
    depthMM: 255,
    heightMM: 950,
    widthMM: 393,
    volumeL: 9,
    nominalDeltaT50KW: 1.049,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 615,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-960-07",
    rangeType: "Grace 6",
    model: "Grace 6 Column 960mm",
    depthMM: 255,
    heightMM: 950,
    widthMM: 454,
    volumeL: 10.5,
    nominalDeltaT50KW: 1.224,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 695,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-960-08",
    rangeType: "Grace 6",
    model: "Grace 6 Column 960mm",
    depthMM: 255,
    heightMM: 950,
    widthMM: 514,
    volumeL: 12,
    nominalDeltaT50KW: 1.398,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 775,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-960-09",
    rangeType: "Grace 6",
    model: "Grace 6 Column 960mm",
    depthMM: 255,
    heightMM: 950,
    widthMM: 575,
    volumeL: 13.5,
    nominalDeltaT50KW: 1.573,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 925,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-960-10",
    rangeType: "Grace 6",
    model: "Grace 6 Column 960mm",
    depthMM: 255,
    heightMM: 950,
    widthMM: 635,
    volumeL: 15,
    nominalDeltaT50KW: 1.748,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1005,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-960-11",
    rangeType: "Grace 6",
    model: "Grace 6 Column 960mm",
    depthMM: 255,
    heightMM: 950,
    widthMM: 696,
    volumeL: 16.5,
    nominalDeltaT50KW: 1.923,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1085,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-960-12",
    rangeType: "Grace 6",
    model: "Grace 6 Column 960mm",
    depthMM: 255,
    heightMM: 950,
    widthMM: 756,
    volumeL: 18,
    nominalDeltaT50KW: 2.098,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1165,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-960-13",
    rangeType: "Grace 6",
    model: "Grace 6 Column 960mm",
    depthMM: 255,
    heightMM: 950,
    widthMM: 817,
    volumeL: 19.5,
    nominalDeltaT50KW: 2.272,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1245,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-960-14",
    rangeType: "Grace 6",
    model: "Grace 6 Column 960mm",
    depthMM: 255,
    heightMM: 950,
    widthMM: 877,
    volumeL: 21,
    nominalDeltaT50KW: 2.447,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1325,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-960-15",
    rangeType: "Grace 6",
    model: "Grace 6 Column 960mm",
    depthMM: 255,
    heightMM: 950,
    widthMM: 938,
    volumeL: 22.5,
    nominalDeltaT50KW: 2.622,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1405,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-960-16",
    rangeType: "Grace 6",
    model: "Grace 6 Column 960mm",
    depthMM: 255,
    heightMM: 950,
    widthMM: 998,
    volumeL: 24,
    nominalDeltaT50KW: 2.797,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1485,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-960-17",
    rangeType: "Grace 6",
    model: "Grace 6 Column 960mm",
    depthMM: 255,
    heightMM: 950,
    widthMM: 1059,
    volumeL: 25.5,
    nominalDeltaT50KW: 2.972,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1565,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-960-18",
    rangeType: "Grace 6",
    model: "Grace 6 Column 960mm",
    depthMM: 255,
    heightMM: 950,
    widthMM: 1119,
    volumeL: 27,
    nominalDeltaT50KW: 3.146,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1645,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-960-19",
    rangeType: "Grace 6",
    model: "Grace 6 Column 960mm",
    depthMM: 255,
    heightMM: 950,
    widthMM: 1180,
    volumeL: 28.5,
    nominalDeltaT50KW: 3.321,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1725,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-960-20",
    rangeType: "Grace 6",
    model: "Grace 6 Column 960mm",
    depthMM: 255,
    heightMM: 950,
    widthMM: 1240,
    volumeL: 30,
    nominalDeltaT50KW: 3.496,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1805,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-960-21",
    rangeType: "Grace 6",
    model: "Grace 6 Column 960mm",
    depthMM: 255,
    heightMM: 950,
    widthMM: 1301,
    volumeL: 31.5,
    nominalDeltaT50KW: 3.671,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1885,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-960-22",
    rangeType: "Grace 6",
    model: "Grace 6 Column 960mm",
    depthMM: 255,
    heightMM: 950,
    widthMM: 1361,
    volumeL: 33,
    nominalDeltaT50KW: 3.846,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 1965,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-960-23",
    rangeType: "Grace 6",
    model: "Grace 6 Column 960mm",
    depthMM: 255,
    heightMM: 950,
    widthMM: 1422,
    volumeL: 34.5,
    nominalDeltaT50KW: 4.02,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 2045,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-960-24",
    rangeType: "Grace 6",
    model: "Grace 6 Column 960mm",
    depthMM: 255,
    heightMM: 950,
    widthMM: 1482,
    volumeL: 36,
    nominalDeltaT50KW: 4.195,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 2125,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-960-25",
    rangeType: "Grace 6",
    model: "Grace 6 Column 960mm",
    depthMM: 255,
    heightMM: 950,
    widthMM: 1543,
    volumeL: 37.5,
    nominalDeltaT50KW: 4.37,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 2205,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-960-26",
    rangeType: "Grace 6",
    model: "Grace 6 Column 960mm",
    depthMM: 255,
    heightMM: 950,
    widthMM: 1603,
    volumeL: 39,
    nominalDeltaT50KW: 4.545,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 2285,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-960-27",
    rangeType: "Grace 6",
    model: "Grace 6 Column 960mm",
    depthMM: 255,
    heightMM: 950,
    widthMM: 1664,
    volumeL: 40.5,
    nominalDeltaT50KW: 4.72,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 2365,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-960-28",
    rangeType: "Grace 6",
    model: "Grace 6 Column 960mm",
    depthMM: 255,
    heightMM: 950,
    widthMM: 1724,
    volumeL: 42,
    nominalDeltaT50KW: 4.894,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 2445,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-960-29",
    rangeType: "Grace 6",
    model: "Grace 6 Column 960mm",
    depthMM: 255,
    heightMM: 950,
    widthMM: 1785,
    volumeL: 43.5,
    nominalDeltaT50KW: 5.069,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 2525,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-960-30",
    rangeType: "Grace 6",
    model: "Grace 6 Column 960mm",
    depthMM: 255,
    heightMM: 950,
    widthMM: 1845,
    volumeL: 45,
    nominalDeltaT50KW: 5.244,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 2605,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-960-31",
    rangeType: "Grace 6",
    model: "Grace 6 Column 960mm",
    depthMM: 255,
    heightMM: 950,
    widthMM: 1906,
    volumeL: 46.5,
    nominalDeltaT50KW: 5.419,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 2685,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-960-32",
    rangeType: "Grace 6",
    model: "Grace 6 Column 960mm",
    depthMM: 255,
    heightMM: 950,
    widthMM: 1966,
    volumeL: 48,
    nominalDeltaT50KW: 5.594,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 2765,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-960-33",
    rangeType: "Grace 6",
    model: "Grace 6 Column 960mm",
    depthMM: 255,
    heightMM: 950,
    widthMM: 2027,
    volumeL: 49.5,
    nominalDeltaT50KW: 5.768,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 2845,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-960-34",
    rangeType: "Grace 6",
    model: "Grace 6 Column 960mm",
    depthMM: 255,
    heightMM: 950,
    widthMM: 2087,
    volumeL: 51,
    nominalDeltaT50KW: 5.943,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 2925,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-960-35",
    rangeType: "Grace 6",
    model: "Grace 6 Column 960mm",
    depthMM: 255,
    heightMM: 950,
    widthMM: 2148,
    volumeL: 52.5,
    nominalDeltaT50KW: 6.118,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 3005,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-960-36",
    rangeType: "Grace 6",
    model: "Grace 6 Column 960mm",
    depthMM: 255,
    heightMM: 950,
    widthMM: 2208,
    volumeL: 54,
    nominalDeltaT50KW: 6.293,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 3085,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-960-37",
    rangeType: "Grace 6",
    model: "Grace 6 Column 960mm",
    depthMM: 255,
    heightMM: 950,
    widthMM: 2269,
    volumeL: 55.5,
    nominalDeltaT50KW: 6.468,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 3165,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-960-38",
    rangeType: "Grace 6",
    model: "Grace 6 Column 960mm",
    depthMM: 255,
    heightMM: 950,
    widthMM: 2329,
    volumeL: 57,
    nominalDeltaT50KW: 6.642,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 3245,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-960-39",
    rangeType: "Grace 6",
    model: "Grace 6 Column 960mm",
    depthMM: 255,
    heightMM: 950,
    widthMM: 2390,
    volumeL: 58.5,
    nominalDeltaT50KW: 6.817,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 3325,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-G6C-960-40",
    rangeType: "Grace 6",
    model: "Grace 6 Column 960mm",
    depthMM: 255,
    heightMM: 950,
    widthMM: 2450,
    volumeL: 60,
    nominalDeltaT50KW: 6.992,
    kvValue: 20,
    nCoefficient: 1.34,
    price: {
      value: 3405,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-460-04",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 460mm",
    depthMM: 70,
    heightMM: 460,
    widthMM: 263,
    volumeL: 1.6,
    nominalDeltaT50KW: 0.141,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 227,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-460-05",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 460mm",
    depthMM: 70,
    heightMM: 460,
    widthMM: 322,
    volumeL: 2,
    nominalDeltaT50KW: 0.177,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 250,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-460-06",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 460mm",
    depthMM: 70,
    heightMM: 460,
    widthMM: 380,
    volumeL: 2.4,
    nominalDeltaT50KW: 0.212,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 273,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-460-07",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 460mm",
    depthMM: 70,
    heightMM: 460,
    widthMM: 438,
    volumeL: 2.8,
    nominalDeltaT50KW: 0.247,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 296,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-460-08",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 460mm",
    depthMM: 70,
    heightMM: 460,
    widthMM: 496,
    volumeL: 3.2,
    nominalDeltaT50KW: 0.282,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 319,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-460-09",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 460mm",
    depthMM: 70,
    heightMM: 460,
    widthMM: 555,
    volumeL: 3.6,
    nominalDeltaT50KW: 0.318,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 342,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-460-10",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 460mm",
    depthMM: 70,
    heightMM: 460,
    widthMM: 613,
    volumeL: 4,
    nominalDeltaT50KW: 0.353,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 365,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-460-11",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 460mm",
    depthMM: 70,
    heightMM: 460,
    widthMM: 671,
    volumeL: 4.4,
    nominalDeltaT50KW: 0.388,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 388,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-460-12",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 460mm",
    depthMM: 70,
    heightMM: 460,
    widthMM: 730,
    volumeL: 4.8,
    nominalDeltaT50KW: 0.424,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 411,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-460-13",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 460mm",
    depthMM: 70,
    heightMM: 460,
    widthMM: 788,
    volumeL: 5.2,
    nominalDeltaT50KW: 0.459,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 434,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-460-14",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 460mm",
    depthMM: 70,
    heightMM: 460,
    widthMM: 846,
    volumeL: 5.6,
    nominalDeltaT50KW: 0.494,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 457,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-460-15",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 460mm",
    depthMM: 70,
    heightMM: 460,
    widthMM: 905,
    volumeL: 6,
    nominalDeltaT50KW: 0.53,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 480,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-460-16",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 460mm",
    depthMM: 70,
    heightMM: 460,
    widthMM: 963,
    volumeL: 6.4,
    nominalDeltaT50KW: 0.565,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 503,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-460-17",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 460mm",
    depthMM: 70,
    heightMM: 460,
    widthMM: 1021,
    volumeL: 6.8,
    nominalDeltaT50KW: 0.6,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 526,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-460-18",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 460mm",
    depthMM: 70,
    heightMM: 460,
    widthMM: 1079,
    volumeL: 7.2,
    nominalDeltaT50KW: 0.635,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 549,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-460-19",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 460mm",
    depthMM: 70,
    heightMM: 460,
    widthMM: 1138,
    volumeL: 7.6,
    nominalDeltaT50KW: 0.671,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 642,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-460-20",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 460mm",
    depthMM: 70,
    heightMM: 460,
    widthMM: 1196,
    volumeL: 8,
    nominalDeltaT50KW: 0.706,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 665,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-460-21",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 460mm",
    depthMM: 70,
    heightMM: 460,
    widthMM: 1254,
    volumeL: 8.4,
    nominalDeltaT50KW: 0.741,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 688,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-460-22",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 460mm",
    depthMM: 70,
    heightMM: 460,
    widthMM: 1313,
    volumeL: 8.8,
    nominalDeltaT50KW: 0.777,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 711,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-460-23",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 460mm",
    depthMM: 70,
    heightMM: 460,
    widthMM: 1371,
    volumeL: 9.2,
    nominalDeltaT50KW: 0.812,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 734,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-460-24",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 460mm",
    depthMM: 70,
    heightMM: 460,
    widthMM: 1429,
    volumeL: 9.6,
    nominalDeltaT50KW: 0.847,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 757,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-460-25",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 460mm",
    depthMM: 70,
    heightMM: 460,
    widthMM: 1488,
    volumeL: 10,
    nominalDeltaT50KW: 0.883,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 780,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-460-26",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 460mm",
    depthMM: 70,
    heightMM: 460,
    widthMM: 1546,
    volumeL: 10.4,
    nominalDeltaT50KW: 0.918,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 803,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-460-27",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 460mm",
    depthMM: 70,
    heightMM: 460,
    widthMM: 1604,
    volumeL: 10.8,
    nominalDeltaT50KW: 0.953,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 826,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-460-28",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 460mm",
    depthMM: 70,
    heightMM: 460,
    widthMM: 1662,
    volumeL: 11.2,
    nominalDeltaT50KW: 0.988,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 849,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-460-29",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 460mm",
    depthMM: 70,
    heightMM: 460,
    widthMM: 1721,
    volumeL: 11.6,
    nominalDeltaT50KW: 1.024,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 872,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-460-30",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 460mm",
    depthMM: 70,
    heightMM: 460,
    widthMM: 1779,
    volumeL: 12,
    nominalDeltaT50KW: 1.059,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 895,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-460-31",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 460mm",
    depthMM: 70,
    heightMM: 460,
    widthMM: 1837,
    volumeL: 12.4,
    nominalDeltaT50KW: 1.094,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 918,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-460-32",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 460mm",
    depthMM: 70,
    heightMM: 460,
    widthMM: 1896,
    volumeL: 12.8,
    nominalDeltaT50KW: 1.13,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 941,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-460-33",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 460mm",
    depthMM: 70,
    heightMM: 460,
    widthMM: 1954,
    volumeL: 13.2,
    nominalDeltaT50KW: 1.165,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 964,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-460-34",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 460mm",
    depthMM: 70,
    heightMM: 460,
    widthMM: 2012,
    volumeL: 13.6,
    nominalDeltaT50KW: 1.2,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 987,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-460-35",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 460mm",
    depthMM: 70,
    heightMM: 460,
    widthMM: 2071,
    volumeL: 14,
    nominalDeltaT50KW: 1.236,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 1010,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-460-36",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 460mm",
    depthMM: 70,
    heightMM: 460,
    widthMM: 2129,
    volumeL: 14.4,
    nominalDeltaT50KW: 1.271,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 1033,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-460-37",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 460mm",
    depthMM: 70,
    heightMM: 460,
    widthMM: 2187,
    volumeL: 14.8,
    nominalDeltaT50KW: 1.306,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 1056,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-460-38",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 460mm",
    depthMM: 70,
    heightMM: 460,
    widthMM: 2245,
    volumeL: 15.2,
    nominalDeltaT50KW: 1.341,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 1079,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-460-39",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 460mm",
    depthMM: 70,
    heightMM: 460,
    widthMM: 2304,
    volumeL: 15.6,
    nominalDeltaT50KW: 1.377,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 1102,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-460-40",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 460mm",
    depthMM: 70,
    heightMM: 460,
    widthMM: 2362,
    volumeL: 16,
    nominalDeltaT50KW: 1.412,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 1125,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-760-04",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 760mm",
    depthMM: 70,
    heightMM: 760,
    widthMM: 263,
    volumeL: 2,
    nominalDeltaT50KW: 0.234,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 255,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-760-05",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 760mm",
    depthMM: 70,
    heightMM: 760,
    widthMM: 322,
    volumeL: 2.5,
    nominalDeltaT50KW: 0.293,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 285,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-760-06",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 760mm",
    depthMM: 70,
    heightMM: 760,
    widthMM: 380,
    volumeL: 3,
    nominalDeltaT50KW: 0.352,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 315,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-760-07",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 760mm",
    depthMM: 70,
    heightMM: 760,
    widthMM: 438,
    volumeL: 3.5,
    nominalDeltaT50KW: 0.41,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 345,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-760-08",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 760mm",
    depthMM: 70,
    heightMM: 760,
    widthMM: 496,
    volumeL: 4,
    nominalDeltaT50KW: 0.469,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 375,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-760-09",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 760mm",
    depthMM: 70,
    heightMM: 760,
    widthMM: 555,
    volumeL: 4.5,
    nominalDeltaT50KW: 0.527,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 405,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-760-10",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 760mm",
    depthMM: 70,
    heightMM: 760,
    widthMM: 613,
    volumeL: 5,
    nominalDeltaT50KW: 0.586,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 435,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-760-11",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 760mm",
    depthMM: 70,
    heightMM: 760,
    widthMM: 671,
    volumeL: 5.5,
    nominalDeltaT50KW: 0.645,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 465,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-760-12",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 760mm",
    depthMM: 70,
    heightMM: 760,
    widthMM: 730,
    volumeL: 6,
    nominalDeltaT50KW: 0.703,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 495,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-760-13",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 760mm",
    depthMM: 70,
    heightMM: 760,
    widthMM: 788,
    volumeL: 6.5,
    nominalDeltaT50KW: 0.762,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 525,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-760-14",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 760mm",
    depthMM: 70,
    heightMM: 760,
    widthMM: 846,
    volumeL: 7,
    nominalDeltaT50KW: 0.82,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 555,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-760-15",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 760mm",
    depthMM: 70,
    heightMM: 760,
    widthMM: 905,
    volumeL: 7.5,
    nominalDeltaT50KW: 0.879,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 585,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-760-16",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 760mm",
    depthMM: 70,
    heightMM: 760,
    widthMM: 963,
    volumeL: 8,
    nominalDeltaT50KW: 0.938,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 615,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-760-17",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 760mm",
    depthMM: 70,
    heightMM: 760,
    widthMM: 1021,
    volumeL: 8.5,
    nominalDeltaT50KW: 0.996,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 645,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-760-18",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 760mm",
    depthMM: 70,
    heightMM: 760,
    widthMM: 1079,
    volumeL: 9,
    nominalDeltaT50KW: 1.055,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 675,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-760-19",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 760mm",
    depthMM: 70,
    heightMM: 760,
    widthMM: 1138,
    volumeL: 9.5,
    nominalDeltaT50KW: 1.113,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 775,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-760-20",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 760mm",
    depthMM: 70,
    heightMM: 760,
    widthMM: 1196,
    volumeL: 10,
    nominalDeltaT50KW: 1.172,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 805,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-760-21",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 760mm",
    depthMM: 70,
    heightMM: 760,
    widthMM: 1254,
    volumeL: 10.5,
    nominalDeltaT50KW: 1.231,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 835,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-760-22",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 760mm",
    depthMM: 70,
    heightMM: 760,
    widthMM: 1313,
    volumeL: 11,
    nominalDeltaT50KW: 1.289,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 865,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-760-23",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 760mm",
    depthMM: 70,
    heightMM: 760,
    widthMM: 1371,
    volumeL: 11.5,
    nominalDeltaT50KW: 1.348,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 895,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-760-24",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 760mm",
    depthMM: 70,
    heightMM: 760,
    widthMM: 1429,
    volumeL: 12,
    nominalDeltaT50KW: 1.406,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 925,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-760-25",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 760mm",
    depthMM: 70,
    heightMM: 760,
    widthMM: 1488,
    volumeL: 12.5,
    nominalDeltaT50KW: 1.465,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 955,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-760-26",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 760mm",
    depthMM: 70,
    heightMM: 760,
    widthMM: 1546,
    volumeL: 13,
    nominalDeltaT50KW: 1.524,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 985,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-760-27",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 760mm",
    depthMM: 70,
    heightMM: 760,
    widthMM: 1604,
    volumeL: 13.5,
    nominalDeltaT50KW: 1.582,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1015,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-760-28",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 760mm",
    depthMM: 70,
    heightMM: 760,
    widthMM: 1662,
    volumeL: 14,
    nominalDeltaT50KW: 1.641,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1045,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-760-29",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 760mm",
    depthMM: 70,
    heightMM: 760,
    widthMM: 1721,
    volumeL: 14.5,
    nominalDeltaT50KW: 1.699,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1075,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-760-30",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 760mm",
    depthMM: 70,
    heightMM: 760,
    widthMM: 1779,
    volumeL: 15,
    nominalDeltaT50KW: 1.758,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1105,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-760-31",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 760mm",
    depthMM: 70,
    heightMM: 760,
    widthMM: 1837,
    volumeL: 15.5,
    nominalDeltaT50KW: 1.817,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1135,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-760-32",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 760mm",
    depthMM: 70,
    heightMM: 760,
    widthMM: 1896,
    volumeL: 16,
    nominalDeltaT50KW: 1.875,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1165,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-760-33",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 760mm",
    depthMM: 70,
    heightMM: 760,
    widthMM: 1954,
    volumeL: 16.5,
    nominalDeltaT50KW: 1.934,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1195,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-760-34",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 760mm",
    depthMM: 70,
    heightMM: 760,
    widthMM: 2012,
    volumeL: 17,
    nominalDeltaT50KW: 1.992,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1225,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-760-35",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 760mm",
    depthMM: 70,
    heightMM: 760,
    widthMM: 2071,
    volumeL: 17.5,
    nominalDeltaT50KW: 2.051,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1255,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-760-36",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 760mm",
    depthMM: 70,
    heightMM: 760,
    widthMM: 2129,
    volumeL: 18,
    nominalDeltaT50KW: 2.11,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1285,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-760-37",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 760mm",
    depthMM: 70,
    heightMM: 760,
    widthMM: 2187,
    volumeL: 18.5,
    nominalDeltaT50KW: 2.168,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1315,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-760-38",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 760mm",
    depthMM: 70,
    heightMM: 760,
    widthMM: 2245,
    volumeL: 19,
    nominalDeltaT50KW: 2.227,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1345,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-760-39",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 760mm",
    depthMM: 70,
    heightMM: 760,
    widthMM: 2304,
    volumeL: 19.5,
    nominalDeltaT50KW: 2.285,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1375,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-760-40",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 760mm",
    depthMM: 70,
    heightMM: 760,
    widthMM: 2362,
    volumeL: 20,
    nominalDeltaT50KW: 2.344,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1405,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-960-04",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 960mm",
    depthMM: 70,
    heightMM: 960,
    widthMM: 263,
    volumeL: 2.64,
    nominalDeltaT50KW: 0.294,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 263,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-960-05",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 960mm",
    depthMM: 70,
    heightMM: 960,
    widthMM: 322,
    volumeL: 3.3,
    nominalDeltaT50KW: 0.368,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 295,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-960-06",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 960mm",
    depthMM: 70,
    heightMM: 960,
    widthMM: 380,
    volumeL: 3.96,
    nominalDeltaT50KW: 0.442,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 327,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-960-07",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 960mm",
    depthMM: 70,
    heightMM: 960,
    widthMM: 438,
    volumeL: 4.62,
    nominalDeltaT50KW: 0.515,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 359,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-960-08",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 960mm",
    depthMM: 70,
    heightMM: 960,
    widthMM: 496,
    volumeL: 5.28,
    nominalDeltaT50KW: 0.589,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 391,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-960-09",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 960mm",
    depthMM: 70,
    heightMM: 960,
    widthMM: 555,
    volumeL: 5.94,
    nominalDeltaT50KW: 0.662,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 423,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-960-10",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 960mm",
    depthMM: 70,
    heightMM: 960,
    widthMM: 613,
    volumeL: 6.6,
    nominalDeltaT50KW: 0.736,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 455,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-960-11",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 960mm",
    depthMM: 70,
    heightMM: 960,
    widthMM: 671,
    volumeL: 7.26,
    nominalDeltaT50KW: 0.81,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 487,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-960-12",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 960mm",
    depthMM: 70,
    heightMM: 960,
    widthMM: 730,
    volumeL: 7.92,
    nominalDeltaT50KW: 0.883,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 519,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-960-13",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 960mm",
    depthMM: 70,
    heightMM: 960,
    widthMM: 788,
    volumeL: 8.58,
    nominalDeltaT50KW: 0.957,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 551,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-960-14",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 960mm",
    depthMM: 70,
    heightMM: 960,
    widthMM: 846,
    volumeL: 9.24,
    nominalDeltaT50KW: 1.03,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 583,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-960-15",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 960mm",
    depthMM: 70,
    heightMM: 960,
    widthMM: 905,
    volumeL: 9.9,
    nominalDeltaT50KW: 1.104,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 615,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-960-16",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 960mm",
    depthMM: 70,
    heightMM: 960,
    widthMM: 963,
    volumeL: 10.56,
    nominalDeltaT50KW: 1.178,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 647,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-960-17",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 960mm",
    depthMM: 70,
    heightMM: 960,
    widthMM: 1021,
    volumeL: 11.22,
    nominalDeltaT50KW: 1.251,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 679,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-960-18",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 960mm",
    depthMM: 70,
    heightMM: 960,
    widthMM: 1079,
    volumeL: 11.88,
    nominalDeltaT50KW: 1.325,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 711,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-960-19",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 960mm",
    depthMM: 70,
    heightMM: 960,
    widthMM: 1138,
    volumeL: 12.54,
    nominalDeltaT50KW: 1.398,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 813,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-960-20",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 960mm",
    depthMM: 70,
    heightMM: 960,
    widthMM: 1196,
    volumeL: 13.2,
    nominalDeltaT50KW: 1.472,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 845,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-960-21",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 960mm",
    depthMM: 70,
    heightMM: 960,
    widthMM: 1254,
    volumeL: 13.86,
    nominalDeltaT50KW: 1.546,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 877,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-960-22",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 960mm",
    depthMM: 70,
    heightMM: 960,
    widthMM: 1313,
    volumeL: 14.52,
    nominalDeltaT50KW: 1.619,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 909,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-960-23",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 960mm",
    depthMM: 70,
    heightMM: 960,
    widthMM: 1371,
    volumeL: 15.18,
    nominalDeltaT50KW: 1.693,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 941,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-960-24",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 960mm",
    depthMM: 70,
    heightMM: 960,
    widthMM: 1429,
    volumeL: 15.84,
    nominalDeltaT50KW: 1.766,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 973,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-960-25",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 960mm",
    depthMM: 70,
    heightMM: 960,
    widthMM: 1488,
    volumeL: 16.5,
    nominalDeltaT50KW: 1.84,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1005,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-960-26",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 960mm",
    depthMM: 70,
    heightMM: 960,
    widthMM: 1546,
    volumeL: 17.16,
    nominalDeltaT50KW: 1.914,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1037,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-960-27",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 960mm",
    depthMM: 70,
    heightMM: 960,
    widthMM: 1604,
    volumeL: 17.82,
    nominalDeltaT50KW: 1.987,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1069,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-960-28",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 960mm",
    depthMM: 70,
    heightMM: 960,
    widthMM: 1662,
    volumeL: 18.48,
    nominalDeltaT50KW: 2.061,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1101,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-960-29",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 960mm",
    depthMM: 70,
    heightMM: 960,
    widthMM: 1721,
    volumeL: 19.14,
    nominalDeltaT50KW: 2.134,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1133,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-960-30",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 960mm",
    depthMM: 70,
    heightMM: 960,
    widthMM: 1779,
    volumeL: 19.8,
    nominalDeltaT50KW: 2.208,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1165,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-960-31",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 960mm",
    depthMM: 70,
    heightMM: 960,
    widthMM: 1837,
    volumeL: 20.46,
    nominalDeltaT50KW: 2.282,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1197,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-960-32",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 960mm",
    depthMM: 70,
    heightMM: 960,
    widthMM: 1896,
    volumeL: 21.12,
    nominalDeltaT50KW: 2.355,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1229,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-960-33",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 960mm",
    depthMM: 70,
    heightMM: 960,
    widthMM: 1954,
    volumeL: 21.78,
    nominalDeltaT50KW: 2.429,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1261,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-960-34",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 960mm",
    depthMM: 70,
    heightMM: 960,
    widthMM: 2012,
    volumeL: 22.44,
    nominalDeltaT50KW: 2.502,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1293,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-960-35",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 960mm",
    depthMM: 70,
    heightMM: 960,
    widthMM: 2071,
    volumeL: 23.1,
    nominalDeltaT50KW: 2.576,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1325,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-960-36",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 960mm",
    depthMM: 70,
    heightMM: 960,
    widthMM: 2129,
    volumeL: 23.76,
    nominalDeltaT50KW: 2.65,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1357,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-960-37",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 960mm",
    depthMM: 70,
    heightMM: 960,
    widthMM: 2187,
    volumeL: 24.42,
    nominalDeltaT50KW: 2.723,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1389,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-960-38",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 960mm",
    depthMM: 70,
    heightMM: 960,
    widthMM: 2245,
    volumeL: 25.08,
    nominalDeltaT50KW: 2.797,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1421,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-960-39",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 960mm",
    depthMM: 70,
    heightMM: 960,
    widthMM: 2304,
    volumeL: 25.74,
    nominalDeltaT50KW: 2.87,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1453,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M2C-960-40",
    rangeType: "Mercury 2",
    model: "Mercury 2 Column 960mm",
    depthMM: 70,
    heightMM: 960,
    widthMM: 2362,
    volumeL: 26.4,
    nominalDeltaT50KW: 2.944,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1485,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-560-04",
    rangeType: "Mercury 2",
    model: "Mercury 3 Column 560mm",
    depthMM: 101,
    heightMM: 560,
    widthMM: 263,
    volumeL: 1.6,
    nominalDeltaT50KW: 0.215,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 263,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-560-05",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 560mm",
    depthMM: 101,
    heightMM: 560,
    widthMM: 322,
    volumeL: 2,
    nominalDeltaT50KW: 0.269,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 295,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-560-06",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 560mm",
    depthMM: 101,
    heightMM: 560,
    widthMM: 380,
    volumeL: 2.4,
    nominalDeltaT50KW: 0.323,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 327,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-560-07",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 560mm",
    depthMM: 101,
    heightMM: 560,
    widthMM: 438,
    volumeL: 2.8,
    nominalDeltaT50KW: 0.377,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 359,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-560-08",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 560mm",
    depthMM: 101,
    heightMM: 560,
    widthMM: 496,
    volumeL: 3.2,
    nominalDeltaT50KW: 0.43,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 391,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-560-09",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 560mm",
    depthMM: 101,
    heightMM: 560,
    widthMM: 555,
    volumeL: 3.6,
    nominalDeltaT50KW: 0.484,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 423,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-560-10",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 560mm",
    depthMM: 101,
    heightMM: 560,
    widthMM: 613,
    volumeL: 4,
    nominalDeltaT50KW: 0.538,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 455,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-560-11",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 560mm",
    depthMM: 101,
    heightMM: 560,
    widthMM: 671,
    volumeL: 4.4,
    nominalDeltaT50KW: 0.592,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 487,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-560-12",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 560mm",
    depthMM: 101,
    heightMM: 560,
    widthMM: 730,
    volumeL: 4.8,
    nominalDeltaT50KW: 0.646,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 519,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-560-13",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 560mm",
    depthMM: 101,
    heightMM: 560,
    widthMM: 788,
    volumeL: 5.2,
    nominalDeltaT50KW: 0.699,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 551,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-560-14",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 560mm",
    depthMM: 101,
    heightMM: 560,
    widthMM: 846,
    volumeL: 5.6,
    nominalDeltaT50KW: 0.753,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 583,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-560-15",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 560mm",
    depthMM: 101,
    heightMM: 560,
    widthMM: 905,
    volumeL: 6,
    nominalDeltaT50KW: 0.807,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 615,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-560-16",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 560mm",
    depthMM: 101,
    heightMM: 560,
    widthMM: 963,
    volumeL: 6.4,
    nominalDeltaT50KW: 0.861,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 647,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-560-17",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 560mm",
    depthMM: 101,
    heightMM: 560,
    widthMM: 1021,
    volumeL: 6.8,
    nominalDeltaT50KW: 0.915,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 679,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-560-18",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 560mm",
    depthMM: 101,
    heightMM: 560,
    widthMM: 1079,
    volumeL: 7.2,
    nominalDeltaT50KW: 0.968,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 711,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-560-19",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 560mm",
    depthMM: 101,
    heightMM: 560,
    widthMM: 1138,
    volumeL: 7.6,
    nominalDeltaT50KW: 1.022,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 813,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-560-20",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 560mm",
    depthMM: 101,
    heightMM: 560,
    widthMM: 1196,
    volumeL: 8,
    nominalDeltaT50KW: 1.076,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 845,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-560-21",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 560mm",
    depthMM: 101,
    heightMM: 560,
    widthMM: 1254,
    volumeL: 8.4,
    nominalDeltaT50KW: 1.13,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 877,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-560-22",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 560mm",
    depthMM: 101,
    heightMM: 560,
    widthMM: 1313,
    volumeL: 8.8,
    nominalDeltaT50KW: 1.184,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 909,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-560-23",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 560mm",
    depthMM: 101,
    heightMM: 560,
    widthMM: 1371,
    volumeL: 9.2,
    nominalDeltaT50KW: 1.237,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 941,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-560-24",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 560mm",
    depthMM: 101,
    heightMM: 560,
    widthMM: 1429,
    volumeL: 9.6,
    nominalDeltaT50KW: 1.291,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 973,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-560-25",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 560mm",
    depthMM: 101,
    heightMM: 560,
    widthMM: 1488,
    volumeL: 10,
    nominalDeltaT50KW: 1.345,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1005,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-560-26",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 560mm",
    depthMM: 101,
    heightMM: 560,
    widthMM: 1546,
    volumeL: 10.4,
    nominalDeltaT50KW: 1.399,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1037,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-560-27",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 560mm",
    depthMM: 101,
    heightMM: 560,
    widthMM: 1604,
    volumeL: 10.8,
    nominalDeltaT50KW: 1.453,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1069,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-560-28",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 560mm",
    depthMM: 101,
    heightMM: 560,
    widthMM: 1662,
    volumeL: 11.2,
    nominalDeltaT50KW: 1.506,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1101,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-560-29",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 560mm",
    depthMM: 101,
    heightMM: 560,
    widthMM: 1721,
    volumeL: 11.6,
    nominalDeltaT50KW: 1.56,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1133,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-560-30",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 560mm",
    depthMM: 101,
    heightMM: 560,
    widthMM: 1779,
    volumeL: 12,
    nominalDeltaT50KW: 1.614,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1165,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-560-31",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 560mm",
    depthMM: 101,
    heightMM: 560,
    widthMM: 1837,
    volumeL: 12.4,
    nominalDeltaT50KW: 1.668,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1197,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-560-32",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 560mm",
    depthMM: 101,
    heightMM: 560,
    widthMM: 1896,
    volumeL: 12.8,
    nominalDeltaT50KW: 1.722,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1229,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-560-33",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 560mm",
    depthMM: 101,
    heightMM: 560,
    widthMM: 1954,
    volumeL: 13.2,
    nominalDeltaT50KW: 1.775,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1261,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-560-34",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 560mm",
    depthMM: 101,
    heightMM: 560,
    widthMM: 2012,
    volumeL: 13.6,
    nominalDeltaT50KW: 1.829,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1293,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-560-35",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 560mm",
    depthMM: 101,
    heightMM: 560,
    widthMM: 2071,
    volumeL: 14,
    nominalDeltaT50KW: 1.883,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1325,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-560-36",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 560mm",
    depthMM: 101,
    heightMM: 560,
    widthMM: 2129,
    volumeL: 14.4,
    nominalDeltaT50KW: 1.937,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1357,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-560-37",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 560mm",
    depthMM: 101,
    heightMM: 560,
    widthMM: 2187,
    volumeL: 14.8,
    nominalDeltaT50KW: 1.991,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1389,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-560-38",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 560mm",
    depthMM: 101,
    heightMM: 560,
    widthMM: 2245,
    volumeL: 15.2,
    nominalDeltaT50KW: 2.044,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1421,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-560-39",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 560mm",
    depthMM: 101,
    heightMM: 560,
    widthMM: 2304,
    volumeL: 15.6,
    nominalDeltaT50KW: 2.098,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1453,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-560-40",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 560mm",
    depthMM: 101,
    heightMM: 560,
    widthMM: 2362,
    volumeL: 16,
    nominalDeltaT50KW: 2.152,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1485,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-660-04",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 660mm",
    depthMM: 101,
    heightMM: 660,
    widthMM: 263,
    volumeL: 2.56,
    nominalDeltaT50KW: 0.266,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 279,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-660-05",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 660mm",
    depthMM: 101,
    heightMM: 660,
    widthMM: 322,
    volumeL: 3.2,
    nominalDeltaT50KW: 0.332,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 315,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-660-06",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 660mm",
    depthMM: 101,
    heightMM: 660,
    widthMM: 380,
    volumeL: 3.84,
    nominalDeltaT50KW: 0.398,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 351,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-660-07",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 660mm",
    depthMM: 101,
    heightMM: 660,
    widthMM: 438,
    volumeL: 4.48,
    nominalDeltaT50KW: 0.465,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 387,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-660-08",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 660mm",
    depthMM: 101,
    heightMM: 660,
    widthMM: 496,
    volumeL: 5.12,
    nominalDeltaT50KW: 0.531,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 423,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-660-09",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 660mm",
    depthMM: 101,
    heightMM: 660,
    widthMM: 555,
    volumeL: 5.76,
    nominalDeltaT50KW: 0.598,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 459,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-660-10",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 660mm",
    depthMM: 101,
    heightMM: 660,
    widthMM: 613,
    volumeL: 6.4,
    nominalDeltaT50KW: 0.664,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 495,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-660-11",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 660mm",
    depthMM: 101,
    heightMM: 660,
    widthMM: 671,
    volumeL: 7.04,
    nominalDeltaT50KW: 0.73,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 531,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-660-12",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 660mm",
    depthMM: 101,
    heightMM: 660,
    widthMM: 730,
    volumeL: 7.68,
    nominalDeltaT50KW: 0.797,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 567,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-660-13",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 660mm",
    depthMM: 101,
    heightMM: 660,
    widthMM: 788,
    volumeL: 8.32,
    nominalDeltaT50KW: 0.863,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 603,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-660-14",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 660mm",
    depthMM: 101,
    heightMM: 660,
    widthMM: 846,
    volumeL: 8.96,
    nominalDeltaT50KW: 0.93,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 639,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-660-15",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 660mm",
    depthMM: 101,
    heightMM: 660,
    widthMM: 905,
    volumeL: 9.6,
    nominalDeltaT50KW: 0.996,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 675,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-660-16",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 660mm",
    depthMM: 101,
    heightMM: 660,
    widthMM: 963,
    volumeL: 10.24,
    nominalDeltaT50KW: 1.062,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 711,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-660-17",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 660mm",
    depthMM: 101,
    heightMM: 660,
    widthMM: 1021,
    volumeL: 10.88,
    nominalDeltaT50KW: 1.129,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 747,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-660-18",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 660mm",
    depthMM: 101,
    heightMM: 660,
    widthMM: 1079,
    volumeL: 11.52,
    nominalDeltaT50KW: 1.195,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 783,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-660-19",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 660mm",
    depthMM: 101,
    heightMM: 660,
    widthMM: 1138,
    volumeL: 12.16,
    nominalDeltaT50KW: 1.262,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 889,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-660-20",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 660mm",
    depthMM: 101,
    heightMM: 660,
    widthMM: 1196,
    volumeL: 12.8,
    nominalDeltaT50KW: 1.328,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 925,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-660-21",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 660mm",
    depthMM: 101,
    heightMM: 660,
    widthMM: 1254,
    volumeL: 13.44,
    nominalDeltaT50KW: 1.394,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 961,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-660-22",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 660mm",
    depthMM: 101,
    heightMM: 660,
    widthMM: 1313,
    volumeL: 14.08,
    nominalDeltaT50KW: 1.461,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 997,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-660-23",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 660mm",
    depthMM: 101,
    heightMM: 660,
    widthMM: 1371,
    volumeL: 14.72,
    nominalDeltaT50KW: 1.527,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1033,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-660-24",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 660mm",
    depthMM: 101,
    heightMM: 660,
    widthMM: 1429,
    volumeL: 15.36,
    nominalDeltaT50KW: 1.594,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1069,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-660-25",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 660mm",
    depthMM: 101,
    heightMM: 660,
    widthMM: 1488,
    volumeL: 16,
    nominalDeltaT50KW: 1.66,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1105,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-660-26",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 660mm",
    depthMM: 101,
    heightMM: 660,
    widthMM: 1546,
    volumeL: 16.64,
    nominalDeltaT50KW: 1.726,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1141,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-660-27",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 660mm",
    depthMM: 101,
    heightMM: 660,
    widthMM: 1604,
    volumeL: 17.28,
    nominalDeltaT50KW: 1.793,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1177,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-660-28",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 660mm",
    depthMM: 101,
    heightMM: 660,
    widthMM: 1662,
    volumeL: 17.92,
    nominalDeltaT50KW: 1.859,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1213,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-660-29",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 660mm",
    depthMM: 101,
    heightMM: 660,
    widthMM: 1721,
    volumeL: 18.56,
    nominalDeltaT50KW: 1.926,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1249,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-660-30",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 660mm",
    depthMM: 101,
    heightMM: 660,
    widthMM: 1779,
    volumeL: 19.2,
    nominalDeltaT50KW: 1.992,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1285,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-660-31",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 660mm",
    depthMM: 101,
    heightMM: 660,
    widthMM: 1837,
    volumeL: 19.84,
    nominalDeltaT50KW: 2.058,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1321,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-660-32",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 660mm",
    depthMM: 101,
    heightMM: 660,
    widthMM: 1896,
    volumeL: 20.48,
    nominalDeltaT50KW: 2.125,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1357,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-660-33",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 660mm",
    depthMM: 101,
    heightMM: 660,
    widthMM: 1954,
    volumeL: 21.12,
    nominalDeltaT50KW: 2.191,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1393,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-660-34",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 660mm",
    depthMM: 101,
    heightMM: 660,
    widthMM: 2012,
    volumeL: 21.76,
    nominalDeltaT50KW: 2.258,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1429,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-660-35",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 660mm",
    depthMM: 101,
    heightMM: 660,
    widthMM: 2071,
    volumeL: 22.4,
    nominalDeltaT50KW: 2.324,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1465,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-660-36",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 660mm",
    depthMM: 101,
    heightMM: 660,
    widthMM: 2129,
    volumeL: 23.04,
    nominalDeltaT50KW: 2.39,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1501,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-660-37",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 660mm",
    depthMM: 101,
    heightMM: 660,
    widthMM: 2187,
    volumeL: 23.68,
    nominalDeltaT50KW: 2.457,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1537,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-660-38",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 660mm",
    depthMM: 101,
    heightMM: 660,
    widthMM: 2245,
    volumeL: 24.32,
    nominalDeltaT50KW: 2.523,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1573,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-660-39",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 660mm",
    depthMM: 101,
    heightMM: 660,
    widthMM: 2304,
    volumeL: 24.96,
    nominalDeltaT50KW: 2.59,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1609,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-660-40",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 660mm",
    depthMM: 101,
    heightMM: 660,
    widthMM: 2362,
    volumeL: 25.6,
    nominalDeltaT50KW: 2.656,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1645,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-760-04",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 760mm",
    depthMM: 101,
    heightMM: 760,
    widthMM: 263,
    volumeL: 2.8,
    nominalDeltaT50KW: 0.31,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 291,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-760-05",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 760mm",
    depthMM: 101,
    heightMM: 760,
    widthMM: 322,
    volumeL: 3.5,
    nominalDeltaT50KW: 0.388,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 330,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-760-06",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 760mm",
    depthMM: 101,
    heightMM: 760,
    widthMM: 380,
    volumeL: 4.2,
    nominalDeltaT50KW: 0.465,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 369,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-760-07",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 760mm",
    depthMM: 101,
    heightMM: 760,
    widthMM: 438,
    volumeL: 4.9,
    nominalDeltaT50KW: 0.543,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 408,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-760-08",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 760mm",
    depthMM: 101,
    heightMM: 760,
    widthMM: 496,
    volumeL: 5.6,
    nominalDeltaT50KW: 0.62,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 447,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-760-09",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 760mm",
    depthMM: 101,
    heightMM: 760,
    widthMM: 555,
    volumeL: 6.3,
    nominalDeltaT50KW: 0.698,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 486,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-760-10",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 760mm",
    depthMM: 101,
    heightMM: 760,
    widthMM: 613,
    volumeL: 7,
    nominalDeltaT50KW: 0.775,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 525,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-760-11",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 760mm",
    depthMM: 101,
    heightMM: 760,
    widthMM: 671,
    volumeL: 7.7,
    nominalDeltaT50KW: 0.853,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 564,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-760-12",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 760mm",
    depthMM: 101,
    heightMM: 760,
    widthMM: 730,
    volumeL: 8.4,
    nominalDeltaT50KW: 0.93,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 603,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-760-13",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 760mm",
    depthMM: 101,
    heightMM: 760,
    widthMM: 788,
    volumeL: 9.1,
    nominalDeltaT50KW: 1.008,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 642,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-760-14",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 760mm",
    depthMM: 101,
    heightMM: 760,
    widthMM: 846,
    volumeL: 9.8,
    nominalDeltaT50KW: 1.085,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 681,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-760-15",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 760mm",
    depthMM: 101,
    heightMM: 760,
    widthMM: 905,
    volumeL: 10.5,
    nominalDeltaT50KW: 1.163,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 720,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-760-16",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 760mm",
    depthMM: 101,
    heightMM: 760,
    widthMM: 963,
    volumeL: 11.2,
    nominalDeltaT50KW: 1.24,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 759,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-760-17",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 760mm",
    depthMM: 101,
    heightMM: 760,
    widthMM: 1021,
    volumeL: 11.9,
    nominalDeltaT50KW: 1.318,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 798,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-760-18",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 760mm",
    depthMM: 101,
    heightMM: 760,
    widthMM: 1079,
    volumeL: 12.6,
    nominalDeltaT50KW: 1.395,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 837,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-760-19",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 760mm",
    depthMM: 101,
    heightMM: 760,
    widthMM: 1138,
    volumeL: 13.3,
    nominalDeltaT50KW: 1.473,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 946,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-760-20",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 760mm",
    depthMM: 101,
    heightMM: 760,
    widthMM: 1196,
    volumeL: 14,
    nominalDeltaT50KW: 1.55,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 985,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-760-21",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 760mm",
    depthMM: 101,
    heightMM: 760,
    widthMM: 1254,
    volumeL: 14.7,
    nominalDeltaT50KW: 1.628,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1024,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-760-22",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 760mm",
    depthMM: 101,
    heightMM: 760,
    widthMM: 1313,
    volumeL: 15.4,
    nominalDeltaT50KW: 1.705,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1063,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-760-23",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 760mm",
    depthMM: 101,
    heightMM: 760,
    widthMM: 1371,
    volumeL: 16.1,
    nominalDeltaT50KW: 1.783,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1102,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-760-24",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 760mm",
    depthMM: 101,
    heightMM: 760,
    widthMM: 1429,
    volumeL: 16.8,
    nominalDeltaT50KW: 1.86,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1141,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-760-25",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 760mm",
    depthMM: 101,
    heightMM: 760,
    widthMM: 1488,
    volumeL: 17.5,
    nominalDeltaT50KW: 1.938,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1180,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-760-26",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 760mm",
    depthMM: 101,
    heightMM: 760,
    widthMM: 1546,
    volumeL: 18.2,
    nominalDeltaT50KW: 2.015,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1219,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-760-27",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 760mm",
    depthMM: 101,
    heightMM: 760,
    widthMM: 1604,
    volumeL: 18.9,
    nominalDeltaT50KW: 2.093,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1258,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-760-28",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 760mm",
    depthMM: 101,
    heightMM: 760,
    widthMM: 1662,
    volumeL: 19.6,
    nominalDeltaT50KW: 2.17,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1297,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-760-29",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 760mm",
    depthMM: 101,
    heightMM: 760,
    widthMM: 1721,
    volumeL: 20.3,
    nominalDeltaT50KW: 2.248,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1336,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-760-30",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 760mm",
    depthMM: 101,
    heightMM: 760,
    widthMM: 1779,
    volumeL: 21,
    nominalDeltaT50KW: 2.325,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1375,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-760-31",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 760mm",
    depthMM: 101,
    heightMM: 760,
    widthMM: 1837,
    volumeL: 21.7,
    nominalDeltaT50KW: 2.403,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1414,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-760-32",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 760mm",
    depthMM: 101,
    heightMM: 760,
    widthMM: 1896,
    volumeL: 22.4,
    nominalDeltaT50KW: 2.48,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1453,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-760-33",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 760mm",
    depthMM: 101,
    heightMM: 760,
    widthMM: 1954,
    volumeL: 23.1,
    nominalDeltaT50KW: 2.558,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1492,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-760-34",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 760mm",
    depthMM: 101,
    heightMM: 760,
    widthMM: 2012,
    volumeL: 23.8,
    nominalDeltaT50KW: 2.635,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1531,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-760-35",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 760mm",
    depthMM: 101,
    heightMM: 760,
    widthMM: 2071,
    volumeL: 24.5,
    nominalDeltaT50KW: 2.713,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1570,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-760-36",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 760mm",
    depthMM: 101,
    heightMM: 760,
    widthMM: 2129,
    volumeL: 25.2,
    nominalDeltaT50KW: 2.79,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1609,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-760-37",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 760mm",
    depthMM: 101,
    heightMM: 760,
    widthMM: 2187,
    volumeL: 25.9,
    nominalDeltaT50KW: 2.868,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1648,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-760-38",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 760mm",
    depthMM: 101,
    heightMM: 760,
    widthMM: 2245,
    volumeL: 26.6,
    nominalDeltaT50KW: 2.945,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1687,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-760-39",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 760mm",
    depthMM: 101,
    heightMM: 760,
    widthMM: 2304,
    volumeL: 27.3,
    nominalDeltaT50KW: 3.023,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1726,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-760-40",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 760mm",
    depthMM: 101,
    heightMM: 760,
    widthMM: 2362,
    volumeL: 28,
    nominalDeltaT50KW: 3.1,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1765,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-860-04",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 860mm",
    depthMM: 101,
    heightMM: 860,
    widthMM: 263,
    volumeL: 2.92,
    nominalDeltaT50KW: 0.35,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 315,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-860-05",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 860mm",
    depthMM: 101,
    heightMM: 860,
    widthMM: 322,
    volumeL: 3.65,
    nominalDeltaT50KW: 0.437,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 360,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-860-06",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 860mm",
    depthMM: 101,
    heightMM: 860,
    widthMM: 380,
    volumeL: 4.38,
    nominalDeltaT50KW: 0.524,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 405,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-860-07",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 860mm",
    depthMM: 101,
    heightMM: 860,
    widthMM: 438,
    volumeL: 5.11,
    nominalDeltaT50KW: 0.612,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 450,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-860-08",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 860mm",
    depthMM: 101,
    heightMM: 860,
    widthMM: 496,
    volumeL: 5.84,
    nominalDeltaT50KW: 0.699,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 495,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-860-09",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 860mm",
    depthMM: 101,
    heightMM: 860,
    widthMM: 555,
    volumeL: 6.57,
    nominalDeltaT50KW: 0.787,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 540,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-860-10",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 860mm",
    depthMM: 101,
    heightMM: 860,
    widthMM: 613,
    volumeL: 7.3,
    nominalDeltaT50KW: 0.874,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 585,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-860-11",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 860mm",
    depthMM: 101,
    heightMM: 860,
    widthMM: 671,
    volumeL: 8.03,
    nominalDeltaT50KW: 0.961,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 630,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-860-12",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 860mm",
    depthMM: 101,
    heightMM: 860,
    widthMM: 730,
    volumeL: 8.76,
    nominalDeltaT50KW: 1.049,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 675,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-860-13",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 860mm",
    depthMM: 101,
    heightMM: 860,
    widthMM: 788,
    volumeL: 9.49,
    nominalDeltaT50KW: 1.136,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 720,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-860-14",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 860mm",
    depthMM: 101,
    heightMM: 860,
    widthMM: 846,
    volumeL: 10.22,
    nominalDeltaT50KW: 1.224,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 765,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-860-15",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 860mm",
    depthMM: 101,
    heightMM: 860,
    widthMM: 905,
    volumeL: 10.95,
    nominalDeltaT50KW: 1.311,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 810,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-860-16",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 860mm",
    depthMM: 101,
    heightMM: 860,
    widthMM: 963,
    volumeL: 11.68,
    nominalDeltaT50KW: 1.398,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 855,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-860-17",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 860mm",
    depthMM: 101,
    heightMM: 860,
    widthMM: 1021,
    volumeL: 12.41,
    nominalDeltaT50KW: 1.486,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 900,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-860-18",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 860mm",
    depthMM: 101,
    heightMM: 860,
    widthMM: 1079,
    volumeL: 13.14,
    nominalDeltaT50KW: 1.573,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 945,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-860-19",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 860mm",
    depthMM: 101,
    heightMM: 860,
    widthMM: 1138,
    volumeL: 13.87,
    nominalDeltaT50KW: 1.661,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1060,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-860-20",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 860mm",
    depthMM: 101,
    heightMM: 860,
    widthMM: 1196,
    volumeL: 14.6,
    nominalDeltaT50KW: 1.748,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1105,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-860-21",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 860mm",
    depthMM: 101,
    heightMM: 860,
    widthMM: 1254,
    volumeL: 15.33,
    nominalDeltaT50KW: 1.835,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1150,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-860-22",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 860mm",
    depthMM: 101,
    heightMM: 860,
    widthMM: 1313,
    volumeL: 16.06,
    nominalDeltaT50KW: 1.923,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1195,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-860-23",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 860mm",
    depthMM: 101,
    heightMM: 860,
    widthMM: 1371,
    volumeL: 16.79,
    nominalDeltaT50KW: 2.01,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1240,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-860-24",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 860mm",
    depthMM: 101,
    heightMM: 860,
    widthMM: 1429,
    volumeL: 17.52,
    nominalDeltaT50KW: 2.098,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1285,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-860-25",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 860mm",
    depthMM: 101,
    heightMM: 860,
    widthMM: 1488,
    volumeL: 18.25,
    nominalDeltaT50KW: 2.185,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1330,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-860-26",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 860mm",
    depthMM: 101,
    heightMM: 860,
    widthMM: 1546,
    volumeL: 18.98,
    nominalDeltaT50KW: 2.272,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1375,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-860-27",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 860mm",
    depthMM: 101,
    heightMM: 860,
    widthMM: 1604,
    volumeL: 19.71,
    nominalDeltaT50KW: 2.36,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1420,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-860-28",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 860mm",
    depthMM: 101,
    heightMM: 860,
    widthMM: 1662,
    volumeL: 20.44,
    nominalDeltaT50KW: 2.447,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1465,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-860-29",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 860mm",
    depthMM: 101,
    heightMM: 860,
    widthMM: 1721,
    volumeL: 21.17,
    nominalDeltaT50KW: 2.535,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1510,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-860-30",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 860mm",
    depthMM: 101,
    heightMM: 860,
    widthMM: 1779,
    volumeL: 21.9,
    nominalDeltaT50KW: 2.622,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1555,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-860-31",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 860mm",
    depthMM: 101,
    heightMM: 860,
    widthMM: 1837,
    volumeL: 22.63,
    nominalDeltaT50KW: 2.709,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1600,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-860-32",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 860mm",
    depthMM: 101,
    heightMM: 860,
    widthMM: 1896,
    volumeL: 23.36,
    nominalDeltaT50KW: 2.797,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1645,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-860-33",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 860mm",
    depthMM: 101,
    heightMM: 860,
    widthMM: 1954,
    volumeL: 24.09,
    nominalDeltaT50KW: 2.884,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1690,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-860-34",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 860mm",
    depthMM: 101,
    heightMM: 860,
    widthMM: 2012,
    volumeL: 24.82,
    nominalDeltaT50KW: 2.972,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1735,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-860-35",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 860mm",
    depthMM: 101,
    heightMM: 860,
    widthMM: 2071,
    volumeL: 25.55,
    nominalDeltaT50KW: 3.059,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1780,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-860-36",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 860mm",
    depthMM: 101,
    heightMM: 860,
    widthMM: 2129,
    volumeL: 26.28,
    nominalDeltaT50KW: 3.146,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1825,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-860-37",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 860mm",
    depthMM: 101,
    heightMM: 860,
    widthMM: 2187,
    volumeL: 27.01,
    nominalDeltaT50KW: 3.234,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1870,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-860-38",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 860mm",
    depthMM: 101,
    heightMM: 860,
    widthMM: 2245,
    volumeL: 27.74,
    nominalDeltaT50KW: 3.321,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1915,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-860-39",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 860mm",
    depthMM: 101,
    heightMM: 860,
    widthMM: 2304,
    volumeL: 28.47,
    nominalDeltaT50KW: 3.409,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1960,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M3C-860-40",
    rangeType: "Mercury 3",
    model: "Mercury 3 Column 860mm",
    depthMM: 101,
    heightMM: 860,
    widthMM: 2362,
    volumeL: 29.2,
    nominalDeltaT50KW: 3.496,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 2005,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-360-04",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 360mm",
    depthMM: 140,
    heightMM: 360,
    widthMM: 263,
    volumeL: 2.2,
    nominalDeltaT50KW: 0.176,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 283,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-360-05",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 360mm",
    depthMM: 140,
    heightMM: 360,
    widthMM: 322,
    volumeL: 2.75,
    nominalDeltaT50KW: 0.22,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 320,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-360-06",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 360mm",
    depthMM: 140,
    heightMM: 360,
    widthMM: 380,
    volumeL: 3.3,
    nominalDeltaT50KW: 0.264,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 357,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-360-07",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 360mm",
    depthMM: 140,
    heightMM: 360,
    widthMM: 438,
    volumeL: 3.85,
    nominalDeltaT50KW: 0.308,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 394,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-360-08",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 360mm",
    depthMM: 140,
    heightMM: 360,
    widthMM: 496,
    volumeL: 4.4,
    nominalDeltaT50KW: 0.352,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 431,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-360-09",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 360mm",
    depthMM: 140,
    heightMM: 360,
    widthMM: 555,
    volumeL: 4.95,
    nominalDeltaT50KW: 0.396,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 468,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-360-10",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 360mm",
    depthMM: 140,
    heightMM: 360,
    widthMM: 613,
    volumeL: 5.5,
    nominalDeltaT50KW: 0.44,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 505,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-360-11",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 360mm",
    depthMM: 140,
    heightMM: 360,
    widthMM: 671,
    volumeL: 6.05,
    nominalDeltaT50KW: 0.484,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 542,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-360-12",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 360mm",
    depthMM: 140,
    heightMM: 360,
    widthMM: 730,
    volumeL: 6.6,
    nominalDeltaT50KW: 0.528,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 579,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-360-13",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 360mm",
    depthMM: 140,
    heightMM: 360,
    widthMM: 788,
    volumeL: 7.15,
    nominalDeltaT50KW: 0.572,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 616,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-360-14",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 360mm",
    depthMM: 140,
    heightMM: 360,
    widthMM: 846,
    volumeL: 7.7,
    nominalDeltaT50KW: 0.616,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 653,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-360-15",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 360mm",
    depthMM: 140,
    heightMM: 360,
    widthMM: 905,
    volumeL: 8.25,
    nominalDeltaT50KW: 0.66,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 690,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-360-16",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 360mm",
    depthMM: 140,
    heightMM: 360,
    widthMM: 963,
    volumeL: 8.8,
    nominalDeltaT50KW: 0.704,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 727,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-360-17",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 360mm",
    depthMM: 140,
    heightMM: 360,
    widthMM: 1021,
    volumeL: 9.35,
    nominalDeltaT50KW: 0.748,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 764,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-360-18",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 360mm",
    depthMM: 140,
    heightMM: 360,
    widthMM: 1079,
    volumeL: 9.9,
    nominalDeltaT50KW: 0.792,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 801,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-360-19",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 360mm",
    depthMM: 140,
    heightMM: 360,
    widthMM: 1138,
    volumeL: 10.45,
    nominalDeltaT50KW: 0.836,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 908,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-360-20",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 360mm",
    depthMM: 140,
    heightMM: 360,
    widthMM: 1196,
    volumeL: 11,
    nominalDeltaT50KW: 0.88,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 945,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-360-21",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 360mm",
    depthMM: 140,
    heightMM: 360,
    widthMM: 1254,
    volumeL: 11.55,
    nominalDeltaT50KW: 0.924,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 982,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-360-22",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 360mm",
    depthMM: 140,
    heightMM: 360,
    widthMM: 1313,
    volumeL: 12.1,
    nominalDeltaT50KW: 0.968,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 1019,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-360-23",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 360mm",
    depthMM: 140,
    heightMM: 360,
    widthMM: 1371,
    volumeL: 12.65,
    nominalDeltaT50KW: 1.012,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 1056,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-360-24",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 360mm",
    depthMM: 140,
    heightMM: 360,
    widthMM: 1429,
    volumeL: 13.2,
    nominalDeltaT50KW: 1.056,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 1093,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-360-25",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 360mm",
    depthMM: 140,
    heightMM: 360,
    widthMM: 1488,
    volumeL: 13.75,
    nominalDeltaT50KW: 1.1,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 1130,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-360-26",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 360mm",
    depthMM: 140,
    heightMM: 360,
    widthMM: 1546,
    volumeL: 14.3,
    nominalDeltaT50KW: 1.144,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 1167,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-360-27",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 360mm",
    depthMM: 140,
    heightMM: 360,
    widthMM: 1604,
    volumeL: 14.85,
    nominalDeltaT50KW: 1.188,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 1204,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-360-28",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 360mm",
    depthMM: 140,
    heightMM: 360,
    widthMM: 1662,
    volumeL: 15.4,
    nominalDeltaT50KW: 1.232,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 1241,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-360-29",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 360mm",
    depthMM: 140,
    heightMM: 360,
    widthMM: 1721,
    volumeL: 15.95,
    nominalDeltaT50KW: 1.276,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 1278,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-360-30",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 360mm",
    depthMM: 140,
    heightMM: 360,
    widthMM: 1779,
    volumeL: 16.5,
    nominalDeltaT50KW: 1.32,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 1315,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-360-31",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 360mm",
    depthMM: 140,
    heightMM: 360,
    widthMM: 1837,
    volumeL: 17.05,
    nominalDeltaT50KW: 1.364,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 1352,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-360-32",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 360mm",
    depthMM: 140,
    heightMM: 360,
    widthMM: 1896,
    volumeL: 17.6,
    nominalDeltaT50KW: 1.408,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 1389,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-360-33",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 360mm",
    depthMM: 140,
    heightMM: 360,
    widthMM: 1954,
    volumeL: 18.15,
    nominalDeltaT50KW: 1.452,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 1426,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-360-34",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 360mm",
    depthMM: 140,
    heightMM: 360,
    widthMM: 2012,
    volumeL: 18.7,
    nominalDeltaT50KW: 1.496,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 1463,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-360-35",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 360mm",
    depthMM: 140,
    heightMM: 360,
    widthMM: 2071,
    volumeL: 19.25,
    nominalDeltaT50KW: 1.54,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 1500,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-360-36",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 360mm",
    depthMM: 140,
    heightMM: 360,
    widthMM: 2129,
    volumeL: 19.8,
    nominalDeltaT50KW: 1.584,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 1537,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-360-37",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 360mm",
    depthMM: 140,
    heightMM: 360,
    widthMM: 2187,
    volumeL: 20.35,
    nominalDeltaT50KW: 1.628,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 1574,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-360-38",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 360mm",
    depthMM: 140,
    heightMM: 360,
    widthMM: 2245,
    volumeL: 20.9,
    nominalDeltaT50KW: 1.672,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 1611,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-360-39",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 360mm",
    depthMM: 140,
    heightMM: 360,
    widthMM: 2304,
    volumeL: 21.45,
    nominalDeltaT50KW: 1.716,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 1648,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-360-40",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 360mm",
    depthMM: 140,
    heightMM: 360,
    widthMM: 2362,
    volumeL: 22,
    nominalDeltaT50KW: 1.76,
    kvValue: 20,
    nCoefficient: 1.22,
    price: {
      value: 1685,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-460-04",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 460mm",
    depthMM: 140,
    heightMM: 460,
    widthMM: 263,
    volumeL: 2.44,
    nominalDeltaT50KW: 0.232,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 303,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-460-05",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 460mm",
    depthMM: 140,
    heightMM: 460,
    widthMM: 322,
    volumeL: 3.05,
    nominalDeltaT50KW: 0.29,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 345,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-460-06",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 460mm",
    depthMM: 140,
    heightMM: 460,
    widthMM: 380,
    volumeL: 3.66,
    nominalDeltaT50KW: 0.348,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 387,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-460-07",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 460mm",
    depthMM: 140,
    heightMM: 460,
    widthMM: 438,
    volumeL: 4.27,
    nominalDeltaT50KW: 0.406,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 429,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-460-08",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 460mm",
    depthMM: 140,
    heightMM: 460,
    widthMM: 496,
    volumeL: 4.88,
    nominalDeltaT50KW: 0.464,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 471,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-460-09",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 460mm",
    depthMM: 140,
    heightMM: 460,
    widthMM: 555,
    volumeL: 5.49,
    nominalDeltaT50KW: 0.522,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 513,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-460-10",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 460mm",
    depthMM: 140,
    heightMM: 460,
    widthMM: 613,
    volumeL: 6.1,
    nominalDeltaT50KW: 0.58,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 555,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-460-11",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 460mm",
    depthMM: 140,
    heightMM: 460,
    widthMM: 671,
    volumeL: 6.71,
    nominalDeltaT50KW: 0.638,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 597,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-460-12",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 460mm",
    depthMM: 140,
    heightMM: 460,
    widthMM: 730,
    volumeL: 7.32,
    nominalDeltaT50KW: 0.696,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 639,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-460-13",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 460mm",
    depthMM: 140,
    heightMM: 460,
    widthMM: 788,
    volumeL: 7.93,
    nominalDeltaT50KW: 0.754,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 681,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-460-14",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 460mm",
    depthMM: 140,
    heightMM: 460,
    widthMM: 846,
    volumeL: 8.54,
    nominalDeltaT50KW: 0.812,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 723,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-460-15",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 460mm",
    depthMM: 140,
    heightMM: 460,
    widthMM: 905,
    volumeL: 9.15,
    nominalDeltaT50KW: 0.87,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 765,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-460-16",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 460mm",
    depthMM: 140,
    heightMM: 460,
    widthMM: 963,
    volumeL: 9.76,
    nominalDeltaT50KW: 0.928,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 807,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-460-17",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 460mm",
    depthMM: 140,
    heightMM: 460,
    widthMM: 1021,
    volumeL: 10.37,
    nominalDeltaT50KW: 0.986,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 849,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-460-18",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 460mm",
    depthMM: 140,
    heightMM: 460,
    widthMM: 1079,
    volumeL: 10.98,
    nominalDeltaT50KW: 1.044,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 891,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-460-19",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 460mm",
    depthMM: 140,
    heightMM: 460,
    widthMM: 1138,
    volumeL: 11.59,
    nominalDeltaT50KW: 1.102,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 1003,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-460-20",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 460mm",
    depthMM: 140,
    heightMM: 460,
    widthMM: 1196,
    volumeL: 12.2,
    nominalDeltaT50KW: 1.16,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 1045,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-460-21",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 460mm",
    depthMM: 140,
    heightMM: 460,
    widthMM: 1254,
    volumeL: 12.81,
    nominalDeltaT50KW: 1.218,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 1087,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-460-22",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 460mm",
    depthMM: 140,
    heightMM: 460,
    widthMM: 1313,
    volumeL: 13.42,
    nominalDeltaT50KW: 1.276,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 1129,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-460-23",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 460mm",
    depthMM: 140,
    heightMM: 460,
    widthMM: 1371,
    volumeL: 14.03,
    nominalDeltaT50KW: 1.334,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 1171,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-460-24",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 460mm",
    depthMM: 140,
    heightMM: 460,
    widthMM: 1429,
    volumeL: 14.64,
    nominalDeltaT50KW: 1.392,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 1213,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-460-25",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 460mm",
    depthMM: 140,
    heightMM: 460,
    widthMM: 1488,
    volumeL: 15.25,
    nominalDeltaT50KW: 1.45,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 1255,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-460-26",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 460mm",
    depthMM: 140,
    heightMM: 460,
    widthMM: 1546,
    volumeL: 15.86,
    nominalDeltaT50KW: 1.508,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 1297,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-460-27",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 460mm",
    depthMM: 140,
    heightMM: 460,
    widthMM: 1604,
    volumeL: 16.47,
    nominalDeltaT50KW: 1.566,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 1339,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-460-28",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 460mm",
    depthMM: 140,
    heightMM: 460,
    widthMM: 1662,
    volumeL: 17.08,
    nominalDeltaT50KW: 1.624,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 1381,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-460-29",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 460mm",
    depthMM: 140,
    heightMM: 460,
    widthMM: 1721,
    volumeL: 17.69,
    nominalDeltaT50KW: 1.682,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 1423,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-460-30",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 460mm",
    depthMM: 140,
    heightMM: 460,
    widthMM: 1779,
    volumeL: 18.3,
    nominalDeltaT50KW: 1.74,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 1465,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-460-31",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 460mm",
    depthMM: 140,
    heightMM: 460,
    widthMM: 1837,
    volumeL: 18.91,
    nominalDeltaT50KW: 1.798,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 1507,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-460-32",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 460mm",
    depthMM: 140,
    heightMM: 460,
    widthMM: 1896,
    volumeL: 19.52,
    nominalDeltaT50KW: 1.856,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 1549,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-460-33",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 460mm",
    depthMM: 140,
    heightMM: 460,
    widthMM: 1954,
    volumeL: 20.13,
    nominalDeltaT50KW: 1.914,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 1591,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-460-34",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 460mm",
    depthMM: 140,
    heightMM: 460,
    widthMM: 2012,
    volumeL: 20.74,
    nominalDeltaT50KW: 1.972,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 1633,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-460-35",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 460mm",
    depthMM: 140,
    heightMM: 460,
    widthMM: 2071,
    volumeL: 21.35,
    nominalDeltaT50KW: 2.03,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 1675,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-460-36",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 460mm",
    depthMM: 140,
    heightMM: 460,
    widthMM: 2129,
    volumeL: 21.96,
    nominalDeltaT50KW: 2.088,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 1717,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-460-37",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 460mm",
    depthMM: 140,
    heightMM: 460,
    widthMM: 2187,
    volumeL: 22.57,
    nominalDeltaT50KW: 2.146,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 1759,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-460-38",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 460mm",
    depthMM: 140,
    heightMM: 460,
    widthMM: 2245,
    volumeL: 23.18,
    nominalDeltaT50KW: 2.204,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 1801,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-460-39",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 460mm",
    depthMM: 140,
    heightMM: 460,
    widthMM: 2304,
    volumeL: 23.79,
    nominalDeltaT50KW: 2.262,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 1843,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-460-40",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 460mm",
    depthMM: 140,
    heightMM: 460,
    widthMM: 2362,
    volumeL: 24.4,
    nominalDeltaT50KW: 2.32,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 1885,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-560-04",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 560mm",
    depthMM: 140,
    heightMM: 560,
    widthMM: 263,
    volumeL: 2.8,
    nominalDeltaT50KW: 0.278,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 311,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-560-05",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 560mm",
    depthMM: 140,
    heightMM: 560,
    widthMM: 322,
    volumeL: 3.5,
    nominalDeltaT50KW: 0.347,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 355,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-560-06",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 560mm",
    depthMM: 140,
    heightMM: 560,
    widthMM: 380,
    volumeL: 4.2,
    nominalDeltaT50KW: 0.416,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 399,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-560-07",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 560mm",
    depthMM: 140,
    heightMM: 560,
    widthMM: 438,
    volumeL: 4.9,
    nominalDeltaT50KW: 0.486,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 443,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-560-08",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 560mm",
    depthMM: 140,
    heightMM: 560,
    widthMM: 496,
    volumeL: 5.6,
    nominalDeltaT50KW: 0.555,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 487,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-560-09",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 560mm",
    depthMM: 140,
    heightMM: 560,
    widthMM: 555,
    volumeL: 6.3,
    nominalDeltaT50KW: 0.625,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 531,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-560-10",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 560mm",
    depthMM: 140,
    heightMM: 560,
    widthMM: 613,
    volumeL: 7,
    nominalDeltaT50KW: 0.694,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 575,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-560-11",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 560mm",
    depthMM: 140,
    heightMM: 560,
    widthMM: 671,
    volumeL: 7.7,
    nominalDeltaT50KW: 0.763,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 619,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-560-12",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 560mm",
    depthMM: 140,
    heightMM: 560,
    widthMM: 730,
    volumeL: 8.4,
    nominalDeltaT50KW: 0.833,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 663,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-560-13",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 560mm",
    depthMM: 140,
    heightMM: 560,
    widthMM: 788,
    volumeL: 9.1,
    nominalDeltaT50KW: 0.902,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 707,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-560-14",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 560mm",
    depthMM: 140,
    heightMM: 560,
    widthMM: 846,
    volumeL: 9.8,
    nominalDeltaT50KW: 0.972,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 751,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-560-15",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 560mm",
    depthMM: 140,
    heightMM: 560,
    widthMM: 905,
    volumeL: 10.5,
    nominalDeltaT50KW: 1.041,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 795,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-560-16",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 560mm",
    depthMM: 140,
    heightMM: 560,
    widthMM: 963,
    volumeL: 11.2,
    nominalDeltaT50KW: 1.11,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 839,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-560-17",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 560mm",
    depthMM: 140,
    heightMM: 560,
    widthMM: 1021,
    volumeL: 11.9,
    nominalDeltaT50KW: 1.18,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 883,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-560-18",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 560mm",
    depthMM: 140,
    heightMM: 560,
    widthMM: 1079,
    volumeL: 12.6,
    nominalDeltaT50KW: 1.249,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 927,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-560-19",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 560mm",
    depthMM: 140,
    heightMM: 560,
    widthMM: 1138,
    volumeL: 13.3,
    nominalDeltaT50KW: 1.319,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1041,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-560-20",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 560mm",
    depthMM: 140,
    heightMM: 560,
    widthMM: 1196,
    volumeL: 14,
    nominalDeltaT50KW: 1.388,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1085,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-560-21",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 560mm",
    depthMM: 140,
    heightMM: 560,
    widthMM: 1254,
    volumeL: 14.7,
    nominalDeltaT50KW: 1.457,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1129,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-560-22",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 560mm",
    depthMM: 140,
    heightMM: 560,
    widthMM: 1313,
    volumeL: 15.4,
    nominalDeltaT50KW: 1.527,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1173,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-560-23",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 560mm",
    depthMM: 140,
    heightMM: 560,
    widthMM: 1371,
    volumeL: 16.1,
    nominalDeltaT50KW: 1.596,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1217,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-560-24",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 560mm",
    depthMM: 140,
    heightMM: 560,
    widthMM: 1429,
    volumeL: 16.8,
    nominalDeltaT50KW: 1.666,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1261,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-560-25",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 560mm",
    depthMM: 140,
    heightMM: 560,
    widthMM: 1488,
    volumeL: 17.5,
    nominalDeltaT50KW: 1.735,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1305,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-560-26",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 560mm",
    depthMM: 140,
    heightMM: 560,
    widthMM: 1546,
    volumeL: 18.2,
    nominalDeltaT50KW: 1.804,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1349,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-560-27",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 560mm",
    depthMM: 140,
    heightMM: 560,
    widthMM: 1604,
    volumeL: 18.9,
    nominalDeltaT50KW: 1.874,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1393,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-560-28",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 560mm",
    depthMM: 140,
    heightMM: 560,
    widthMM: 1662,
    volumeL: 19.6,
    nominalDeltaT50KW: 1.943,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1437,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-560-29",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 560mm",
    depthMM: 140,
    heightMM: 560,
    widthMM: 1721,
    volumeL: 20.3,
    nominalDeltaT50KW: 2.013,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1481,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-560-30",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 560mm",
    depthMM: 140,
    heightMM: 560,
    widthMM: 1779,
    volumeL: 21,
    nominalDeltaT50KW: 2.082,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1525,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-560-31",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 560mm",
    depthMM: 140,
    heightMM: 560,
    widthMM: 1837,
    volumeL: 21.7,
    nominalDeltaT50KW: 2.151,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1569,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-560-32",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 560mm",
    depthMM: 140,
    heightMM: 560,
    widthMM: 1896,
    volumeL: 22.4,
    nominalDeltaT50KW: 2.221,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1613,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-560-33",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 560mm",
    depthMM: 140,
    heightMM: 560,
    widthMM: 1954,
    volumeL: 23.1,
    nominalDeltaT50KW: 2.29,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1657,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-560-34",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 560mm",
    depthMM: 140,
    heightMM: 560,
    widthMM: 2012,
    volumeL: 23.8,
    nominalDeltaT50KW: 2.36,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1701,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-560-35",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 560mm",
    depthMM: 140,
    heightMM: 560,
    widthMM: 2071,
    volumeL: 24.5,
    nominalDeltaT50KW: 2.429,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1745,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-560-36",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 560mm",
    depthMM: 140,
    heightMM: 560,
    widthMM: 2129,
    volumeL: 25.2,
    nominalDeltaT50KW: 2.498,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1789,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-560-37",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 560mm",
    depthMM: 140,
    heightMM: 560,
    widthMM: 2187,
    volumeL: 25.9,
    nominalDeltaT50KW: 2.568,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1833,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-560-38",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 560mm",
    depthMM: 140,
    heightMM: 560,
    widthMM: 2245,
    volumeL: 26.6,
    nominalDeltaT50KW: 2.637,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1877,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-560-39",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 560mm",
    depthMM: 140,
    heightMM: 560,
    widthMM: 2304,
    volumeL: 27.3,
    nominalDeltaT50KW: 2.707,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1921,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-560-40",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 560mm",
    depthMM: 140,
    heightMM: 560,
    widthMM: 2362,
    volumeL: 28,
    nominalDeltaT50KW: 2.776,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1965,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-760-04",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 760mm",
    depthMM: 140,
    heightMM: 760,
    widthMM: 263,
    volumeL: 3.6,
    nominalDeltaT50KW: 0.382,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 335,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-760-05",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 760mm",
    depthMM: 140,
    heightMM: 760,
    widthMM: 322,
    volumeL: 4.5,
    nominalDeltaT50KW: 0.478,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 385,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-760-06",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 760mm",
    depthMM: 140,
    heightMM: 760,
    widthMM: 380,
    volumeL: 5.4,
    nominalDeltaT50KW: 0.574,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 435,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-760-07",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 760mm",
    depthMM: 140,
    heightMM: 760,
    widthMM: 438,
    volumeL: 6.3,
    nominalDeltaT50KW: 0.669,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 485,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-760-08",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 760mm",
    depthMM: 140,
    heightMM: 760,
    widthMM: 496,
    volumeL: 7.2,
    nominalDeltaT50KW: 0.765,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 535,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-760-09",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 760mm",
    depthMM: 140,
    heightMM: 760,
    widthMM: 555,
    volumeL: 8.1,
    nominalDeltaT50KW: 0.86,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 585,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-760-10",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 760mm",
    depthMM: 140,
    heightMM: 760,
    widthMM: 613,
    volumeL: 9,
    nominalDeltaT50KW: 0.956,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 635,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-760-11",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 760mm",
    depthMM: 140,
    heightMM: 760,
    widthMM: 671,
    volumeL: 9.9,
    nominalDeltaT50KW: 1.052,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 685,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-760-12",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 760mm",
    depthMM: 140,
    heightMM: 760,
    widthMM: 730,
    volumeL: 10.8,
    nominalDeltaT50KW: 1.147,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 735,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-760-13",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 760mm",
    depthMM: 140,
    heightMM: 760,
    widthMM: 788,
    volumeL: 11.7,
    nominalDeltaT50KW: 1.243,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 785,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-760-14",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 760mm",
    depthMM: 140,
    heightMM: 760,
    widthMM: 846,
    volumeL: 12.6,
    nominalDeltaT50KW: 1.338,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 835,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-760-15",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 760mm",
    depthMM: 140,
    heightMM: 760,
    widthMM: 905,
    volumeL: 13.5,
    nominalDeltaT50KW: 1.434,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 885,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-760-16",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 760mm",
    depthMM: 140,
    heightMM: 760,
    widthMM: 963,
    volumeL: 14.4,
    nominalDeltaT50KW: 1.53,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 935,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-760-17",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 760mm",
    depthMM: 140,
    heightMM: 760,
    widthMM: 1021,
    volumeL: 15.3,
    nominalDeltaT50KW: 1.625,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 985,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-760-18",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 760mm",
    depthMM: 140,
    heightMM: 760,
    widthMM: 1079,
    volumeL: 16.2,
    nominalDeltaT50KW: 1.721,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1035,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-760-19",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 760mm",
    depthMM: 140,
    heightMM: 760,
    widthMM: 1138,
    volumeL: 17.1,
    nominalDeltaT50KW: 1.816,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1155,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-760-20",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 760mm",
    depthMM: 140,
    heightMM: 760,
    widthMM: 1196,
    volumeL: 18,
    nominalDeltaT50KW: 1.912,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1205,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-760-21",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 760mm",
    depthMM: 140,
    heightMM: 760,
    widthMM: 1254,
    volumeL: 18.9,
    nominalDeltaT50KW: 2.008,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1255,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-760-22",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 760mm",
    depthMM: 140,
    heightMM: 760,
    widthMM: 1313,
    volumeL: 19.8,
    nominalDeltaT50KW: 2.103,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1305,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-760-23",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 760mm",
    depthMM: 140,
    heightMM: 760,
    widthMM: 1371,
    volumeL: 20.7,
    nominalDeltaT50KW: 2.199,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1355,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-760-24",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 760mm",
    depthMM: 140,
    heightMM: 760,
    widthMM: 1429,
    volumeL: 21.6,
    nominalDeltaT50KW: 2.294,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1405,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-760-25",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 760mm",
    depthMM: 140,
    heightMM: 760,
    widthMM: 1488,
    volumeL: 22.5,
    nominalDeltaT50KW: 2.39,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1455,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-760-26",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 760mm",
    depthMM: 140,
    heightMM: 760,
    widthMM: 1546,
    volumeL: 23.4,
    nominalDeltaT50KW: 2.486,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1505,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-760-27",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 760mm",
    depthMM: 140,
    heightMM: 760,
    widthMM: 1604,
    volumeL: 24.3,
    nominalDeltaT50KW: 2.581,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1555,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-760-28",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 760mm",
    depthMM: 140,
    heightMM: 760,
    widthMM: 1662,
    volumeL: 25.2,
    nominalDeltaT50KW: 2.677,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1605,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-760-29",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 760mm",
    depthMM: 140,
    heightMM: 760,
    widthMM: 1721,
    volumeL: 26.1,
    nominalDeltaT50KW: 2.772,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1655,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-760-30",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 760mm",
    depthMM: 140,
    heightMM: 760,
    widthMM: 1779,
    volumeL: 27,
    nominalDeltaT50KW: 2.868,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1705,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-760-31",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 760mm",
    depthMM: 140,
    heightMM: 760,
    widthMM: 1837,
    volumeL: 27.9,
    nominalDeltaT50KW: 2.964,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1755,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-760-32",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 760mm",
    depthMM: 140,
    heightMM: 760,
    widthMM: 1896,
    volumeL: 28.8,
    nominalDeltaT50KW: 3.059,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1805,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-760-33",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 760mm",
    depthMM: 140,
    heightMM: 760,
    widthMM: 1954,
    volumeL: 29.7,
    nominalDeltaT50KW: 3.155,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1855,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-760-34",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 760mm",
    depthMM: 140,
    heightMM: 760,
    widthMM: 2012,
    volumeL: 30.6,
    nominalDeltaT50KW: 3.25,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1905,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-760-35",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 760mm",
    depthMM: 140,
    heightMM: 760,
    widthMM: 2071,
    volumeL: 31.5,
    nominalDeltaT50KW: 3.346,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1955,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-760-36",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 760mm",
    depthMM: 140,
    heightMM: 760,
    widthMM: 2129,
    volumeL: 32.4,
    nominalDeltaT50KW: 3.442,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 2005,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-760-37",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 760mm",
    depthMM: 140,
    heightMM: 760,
    widthMM: 2187,
    volumeL: 33.3,
    nominalDeltaT50KW: 3.537,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 2055,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-760-38",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 760mm",
    depthMM: 140,
    heightMM: 760,
    widthMM: 2245,
    volumeL: 34.2,
    nominalDeltaT50KW: 3.633,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 2105,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-760-39",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 760mm",
    depthMM: 140,
    heightMM: 760,
    widthMM: 2304,
    volumeL: 35.1,
    nominalDeltaT50KW: 3.728,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 2155,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-760-40",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 760mm",
    depthMM: 140,
    heightMM: 760,
    widthMM: 2362,
    volumeL: 36,
    nominalDeltaT50KW: 3.824,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 2205,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-860-04",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 860mm",
    depthMM: 140,
    heightMM: 860,
    widthMM: 263,
    volumeL: 4,
    nominalDeltaT50KW: 0.436,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 347,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-860-05",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 860mm",
    depthMM: 140,
    heightMM: 860,
    widthMM: 322,
    volumeL: 5,
    nominalDeltaT50KW: 0.545,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 400,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-860-06",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 860mm",
    depthMM: 140,
    heightMM: 860,
    widthMM: 380,
    volumeL: 6,
    nominalDeltaT50KW: 0.654,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 453,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-860-07",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 860mm",
    depthMM: 140,
    heightMM: 860,
    widthMM: 438,
    volumeL: 7,
    nominalDeltaT50KW: 0.763,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 506,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-860-08",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 860mm",
    depthMM: 140,
    heightMM: 860,
    widthMM: 496,
    volumeL: 8,
    nominalDeltaT50KW: 0.872,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 559,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-860-09",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 860mm",
    depthMM: 140,
    heightMM: 860,
    widthMM: 555,
    volumeL: 9,
    nominalDeltaT50KW: 0.981,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 612,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-860-10",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 860mm",
    depthMM: 140,
    heightMM: 860,
    widthMM: 613,
    volumeL: 10,
    nominalDeltaT50KW: 1.09,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 665,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-860-11",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 860mm",
    depthMM: 140,
    heightMM: 860,
    widthMM: 671,
    volumeL: 11,
    nominalDeltaT50KW: 1.199,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 718,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-860-12",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 860mm",
    depthMM: 140,
    heightMM: 860,
    widthMM: 730,
    volumeL: 12,
    nominalDeltaT50KW: 1.308,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 771,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-860-13",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 860mm",
    depthMM: 140,
    heightMM: 860,
    widthMM: 788,
    volumeL: 13,
    nominalDeltaT50KW: 1.417,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 824,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-860-14",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 860mm",
    depthMM: 140,
    heightMM: 860,
    widthMM: 846,
    volumeL: 14,
    nominalDeltaT50KW: 1.526,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 877,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-860-15",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 860mm",
    depthMM: 140,
    heightMM: 860,
    widthMM: 905,
    volumeL: 15,
    nominalDeltaT50KW: 1.635,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 930,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-860-16",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 860mm",
    depthMM: 140,
    heightMM: 860,
    widthMM: 963,
    volumeL: 16,
    nominalDeltaT50KW: 1.744,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1053,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-860-17",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 860mm",
    depthMM: 140,
    heightMM: 860,
    widthMM: 1021,
    volumeL: 17,
    nominalDeltaT50KW: 1.853,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1106,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-860-18",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 860mm",
    depthMM: 140,
    heightMM: 860,
    widthMM: 1079,
    volumeL: 18,
    nominalDeltaT50KW: 1.962,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1159,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-860-19",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 860mm",
    depthMM: 140,
    heightMM: 860,
    widthMM: 1138,
    volumeL: 19,
    nominalDeltaT50KW: 2.071,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1212,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-860-20",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 860mm",
    depthMM: 140,
    heightMM: 860,
    widthMM: 1196,
    volumeL: 20,
    nominalDeltaT50KW: 2.18,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1265,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-860-21",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 860mm",
    depthMM: 140,
    heightMM: 860,
    widthMM: 1254,
    volumeL: 21,
    nominalDeltaT50KW: 2.289,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1318,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-860-22",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 860mm",
    depthMM: 140,
    heightMM: 860,
    widthMM: 1313,
    volumeL: 22,
    nominalDeltaT50KW: 2.398,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1371,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-860-23",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 860mm",
    depthMM: 140,
    heightMM: 860,
    widthMM: 1371,
    volumeL: 23,
    nominalDeltaT50KW: 2.507,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1424,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-860-24",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 860mm",
    depthMM: 140,
    heightMM: 860,
    widthMM: 1429,
    volumeL: 24,
    nominalDeltaT50KW: 2.616,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1477,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-860-25",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 860mm",
    depthMM: 140,
    heightMM: 860,
    widthMM: 1488,
    volumeL: 25,
    nominalDeltaT50KW: 2.725,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1530,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-860-26",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 860mm",
    depthMM: 140,
    heightMM: 860,
    widthMM: 1546,
    volumeL: 26,
    nominalDeltaT50KW: 2.834,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1583,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-860-27",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 860mm",
    depthMM: 140,
    heightMM: 860,
    widthMM: 1604,
    volumeL: 27,
    nominalDeltaT50KW: 2.943,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1636,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-860-28",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 860mm",
    depthMM: 140,
    heightMM: 860,
    widthMM: 1662,
    volumeL: 28,
    nominalDeltaT50KW: 3.052,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1689,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-860-29",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 860mm",
    depthMM: 140,
    heightMM: 860,
    widthMM: 1721,
    volumeL: 29,
    nominalDeltaT50KW: 3.161,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1742,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-860-30",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 860mm",
    depthMM: 140,
    heightMM: 860,
    widthMM: 1779,
    volumeL: 30,
    nominalDeltaT50KW: 3.27,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1795,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-860-31",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 860mm",
    depthMM: 140,
    heightMM: 860,
    widthMM: 1837,
    volumeL: 31,
    nominalDeltaT50KW: 3.379,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1848,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-860-32",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 860mm",
    depthMM: 140,
    heightMM: 860,
    widthMM: 1896,
    volumeL: 32,
    nominalDeltaT50KW: 3.488,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1901,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-860-33",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 860mm",
    depthMM: 140,
    heightMM: 860,
    widthMM: 1954,
    volumeL: 33,
    nominalDeltaT50KW: 3.597,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1954,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-860-34",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 860mm",
    depthMM: 140,
    heightMM: 860,
    widthMM: 2012,
    volumeL: 34,
    nominalDeltaT50KW: 3.706,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 2007,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-860-35",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 860mm",
    depthMM: 140,
    heightMM: 860,
    widthMM: 2071,
    volumeL: 35,
    nominalDeltaT50KW: 3.815,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 2060,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-860-36",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 860mm",
    depthMM: 140,
    heightMM: 860,
    widthMM: 2129,
    volumeL: 36,
    nominalDeltaT50KW: 3.924,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 2113,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-860-37",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 860mm",
    depthMM: 140,
    heightMM: 860,
    widthMM: 2187,
    volumeL: 37,
    nominalDeltaT50KW: 4.033,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 2166,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-860-38",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 860mm",
    depthMM: 140,
    heightMM: 860,
    widthMM: 2245,
    volumeL: 38,
    nominalDeltaT50KW: 4.142,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 2219,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-860-39",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 860mm",
    depthMM: 140,
    heightMM: 860,
    widthMM: 2304,
    volumeL: 39,
    nominalDeltaT50KW: 4.251,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 2272,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-860-40",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 860mm",
    depthMM: 140,
    heightMM: 860,
    widthMM: 2362,
    volumeL: 40,
    nominalDeltaT50KW: 4.36,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 2325,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-960-04",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 960mm",
    depthMM: 140,
    heightMM: 960,
    widthMM: 263,
    volumeL: 4.4,
    nominalDeltaT50KW: 0.484,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 407,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-960-05",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 960mm",
    depthMM: 140,
    heightMM: 960,
    widthMM: 322,
    volumeL: 5.5,
    nominalDeltaT50KW: 0.605,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 475,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-960-06",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 960mm",
    depthMM: 140,
    heightMM: 960,
    widthMM: 380,
    volumeL: 6.6,
    nominalDeltaT50KW: 0.726,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 543,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-960-07",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 960mm",
    depthMM: 140,
    heightMM: 960,
    widthMM: 438,
    volumeL: 7.7,
    nominalDeltaT50KW: 0.847,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 611,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-960-08",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 960mm",
    depthMM: 140,
    heightMM: 960,
    widthMM: 496,
    volumeL: 8.8,
    nominalDeltaT50KW: 0.968,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 679,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-960-09",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 960mm",
    depthMM: 140,
    heightMM: 960,
    widthMM: 555,
    volumeL: 9.9,
    nominalDeltaT50KW: 1.089,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 747,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-960-10",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 960mm",
    depthMM: 140,
    heightMM: 960,
    widthMM: 613,
    volumeL: 11,
    nominalDeltaT50KW: 1.21,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 815,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-960-11",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 960mm",
    depthMM: 140,
    heightMM: 960,
    widthMM: 671,
    volumeL: 12.1,
    nominalDeltaT50KW: 1.331,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 883,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-960-12",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 960mm",
    depthMM: 140,
    heightMM: 960,
    widthMM: 730,
    volumeL: 13.2,
    nominalDeltaT50KW: 1.452,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 951,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-960-13",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 960mm",
    depthMM: 140,
    heightMM: 960,
    widthMM: 788,
    volumeL: 14.3,
    nominalDeltaT50KW: 1.573,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1019,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-960-14",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 960mm",
    depthMM: 140,
    heightMM: 960,
    widthMM: 846,
    volumeL: 15.4,
    nominalDeltaT50KW: 1.694,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1157,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-960-15",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 960mm",
    depthMM: 140,
    heightMM: 960,
    widthMM: 905,
    volumeL: 16.5,
    nominalDeltaT50KW: 1.815,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1225,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-960-16",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 960mm",
    depthMM: 140,
    heightMM: 960,
    widthMM: 963,
    volumeL: 17.6,
    nominalDeltaT50KW: 1.936,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1293,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-960-17",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 960mm",
    depthMM: 140,
    heightMM: 960,
    widthMM: 1021,
    volumeL: 18.7,
    nominalDeltaT50KW: 2.057,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1361,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-960-18",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 960mm",
    depthMM: 140,
    heightMM: 960,
    widthMM: 1079,
    volumeL: 19.8,
    nominalDeltaT50KW: 2.178,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1429,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-960-19",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 960mm",
    depthMM: 140,
    heightMM: 960,
    widthMM: 1138,
    volumeL: 20.9,
    nominalDeltaT50KW: 2.299,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1497,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-960-20",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 960mm",
    depthMM: 140,
    heightMM: 960,
    widthMM: 1196,
    volumeL: 22,
    nominalDeltaT50KW: 2.42,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1565,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-960-21",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 960mm",
    depthMM: 140,
    heightMM: 960,
    widthMM: 1254,
    volumeL: 23.1,
    nominalDeltaT50KW: 2.541,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1633,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-960-22",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 960mm",
    depthMM: 140,
    heightMM: 960,
    widthMM: 1313,
    volumeL: 24.2,
    nominalDeltaT50KW: 2.662,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1701,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-960-23",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 960mm",
    depthMM: 140,
    heightMM: 960,
    widthMM: 1371,
    volumeL: 25.3,
    nominalDeltaT50KW: 2.783,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1769,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-960-24",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 960mm",
    depthMM: 140,
    heightMM: 960,
    widthMM: 1429,
    volumeL: 26.4,
    nominalDeltaT50KW: 2.904,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1837,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-960-25",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 960mm",
    depthMM: 140,
    heightMM: 960,
    widthMM: 1488,
    volumeL: 27.5,
    nominalDeltaT50KW: 3.025,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1905,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-960-26",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 960mm",
    depthMM: 140,
    heightMM: 960,
    widthMM: 1546,
    volumeL: 28.6,
    nominalDeltaT50KW: 3.146,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 1973,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-960-27",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 960mm",
    depthMM: 140,
    heightMM: 960,
    widthMM: 1604,
    volumeL: 29.7,
    nominalDeltaT50KW: 3.267,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 2041,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-960-28",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 960mm",
    depthMM: 140,
    heightMM: 960,
    widthMM: 1662,
    volumeL: 30.8,
    nominalDeltaT50KW: 3.388,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 2109,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-960-29",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 960mm",
    depthMM: 140,
    heightMM: 960,
    widthMM: 1721,
    volumeL: 31.9,
    nominalDeltaT50KW: 3.509,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 2177,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-960-30",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 960mm",
    depthMM: 140,
    heightMM: 960,
    widthMM: 1779,
    volumeL: 33,
    nominalDeltaT50KW: 3.63,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 2245,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-960-31",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 960mm",
    depthMM: 140,
    heightMM: 960,
    widthMM: 1837,
    volumeL: 34.1,
    nominalDeltaT50KW: 3.751,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 2313,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-960-32",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 960mm",
    depthMM: 140,
    heightMM: 960,
    widthMM: 1896,
    volumeL: 35.2,
    nominalDeltaT50KW: 3.872,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 2381,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-960-33",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 960mm",
    depthMM: 140,
    heightMM: 960,
    widthMM: 1954,
    volumeL: 36.3,
    nominalDeltaT50KW: 3.993,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 2449,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-960-34",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 960mm",
    depthMM: 140,
    heightMM: 960,
    widthMM: 2012,
    volumeL: 37.4,
    nominalDeltaT50KW: 4.114,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 2517,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-960-35",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 960mm",
    depthMM: 140,
    heightMM: 960,
    widthMM: 2071,
    volumeL: 38.5,
    nominalDeltaT50KW: 4.235,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 2585,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-960-36",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 960mm",
    depthMM: 140,
    heightMM: 960,
    widthMM: 2129,
    volumeL: 39.6,
    nominalDeltaT50KW: 4.356,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 2653,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-960-37",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 960mm",
    depthMM: 140,
    heightMM: 960,
    widthMM: 2187,
    volumeL: 40.7,
    nominalDeltaT50KW: 4.477,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 2721,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-960-38",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 960mm",
    depthMM: 140,
    heightMM: 960,
    widthMM: 2245,
    volumeL: 41.8,
    nominalDeltaT50KW: 4.598,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 2789,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-960-39",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 960mm",
    depthMM: 140,
    heightMM: 960,
    widthMM: 2304,
    volumeL: 42.9,
    nominalDeltaT50KW: 4.719,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 2857,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M4C-960-40",
    rangeType: "Mercury 4",
    model: "Mercury 4 Column 960mm",
    depthMM: 140,
    heightMM: 960,
    widthMM: 2362,
    volumeL: 44,
    nominalDeltaT50KW: 4.84,
    kvValue: 20,
    nCoefficient: 1.27,
    price: {
      value: 2925,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-360-04",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 360mm",
    depthMM: 215,
    heightMM: 360,
    widthMM: 263,
    volumeL: 2.68,
    nominalDeltaT50KW: 0.252,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 327,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-360-05",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 360mm",
    depthMM: 215,
    heightMM: 360,
    widthMM: 322,
    volumeL: 3.35,
    nominalDeltaT50KW: 0.316,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 375,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-360-06",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 360mm",
    depthMM: 215,
    heightMM: 360,
    widthMM: 380,
    volumeL: 4.02,
    nominalDeltaT50KW: 0.379,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 423,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-360-07",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 360mm",
    depthMM: 215,
    heightMM: 360,
    widthMM: 438,
    volumeL: 4.69,
    nominalDeltaT50KW: 0.442,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 471,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-360-08",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 360mm",
    depthMM: 215,
    heightMM: 360,
    widthMM: 496,
    volumeL: 5.36,
    nominalDeltaT50KW: 0.505,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 519,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-360-09",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 360mm",
    depthMM: 215,
    heightMM: 360,
    widthMM: 555,
    volumeL: 6.03,
    nominalDeltaT50KW: 0.568,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 567,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-360-10",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 360mm",
    depthMM: 215,
    heightMM: 360,
    widthMM: 613,
    volumeL: 6.7,
    nominalDeltaT50KW: 0.631,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 615,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-360-11",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 360mm",
    depthMM: 215,
    heightMM: 360,
    widthMM: 671,
    volumeL: 7.37,
    nominalDeltaT50KW: 0.694,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 663,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-360-12",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 360mm",
    depthMM: 215,
    heightMM: 360,
    widthMM: 730,
    volumeL: 8.04,
    nominalDeltaT50KW: 0.757,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 711,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-360-13",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 360mm",
    depthMM: 215,
    heightMM: 360,
    widthMM: 788,
    volumeL: 8.71,
    nominalDeltaT50KW: 0.82,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 759,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-360-14",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 360mm",
    depthMM: 215,
    heightMM: 360,
    widthMM: 846,
    volumeL: 9.38,
    nominalDeltaT50KW: 0.883,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 807,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-360-15",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 360mm",
    depthMM: 215,
    heightMM: 360,
    widthMM: 905,
    volumeL: 10.05,
    nominalDeltaT50KW: 0.947,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 855,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-360-16",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 360mm",
    depthMM: 215,
    heightMM: 360,
    widthMM: 963,
    volumeL: 10.72,
    nominalDeltaT50KW: 1.01,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 903,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-360-17",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 360mm",
    depthMM: 215,
    heightMM: 360,
    widthMM: 1021,
    volumeL: 11.39,
    nominalDeltaT50KW: 1.073,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 951,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-360-18",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 360mm",
    depthMM: 215,
    heightMM: 360,
    widthMM: 1079,
    volumeL: 12.06,
    nominalDeltaT50KW: 1.136,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 999,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-360-19",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 360mm",
    depthMM: 215,
    heightMM: 360,
    widthMM: 1138,
    volumeL: 12.73,
    nominalDeltaT50KW: 1.199,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 1117,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-360-20",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 360mm",
    depthMM: 215,
    heightMM: 360,
    widthMM: 1196,
    volumeL: 13.4,
    nominalDeltaT50KW: 1.262,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 1165,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-360-21",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 360mm",
    depthMM: 215,
    heightMM: 360,
    widthMM: 1254,
    volumeL: 14.07,
    nominalDeltaT50KW: 1.325,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 1213,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-360-22",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 360mm",
    depthMM: 215,
    heightMM: 360,
    widthMM: 1313,
    volumeL: 14.74,
    nominalDeltaT50KW: 1.388,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 1261,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-360-23",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 360mm",
    depthMM: 215,
    heightMM: 360,
    widthMM: 1371,
    volumeL: 15.41,
    nominalDeltaT50KW: 1.451,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 1309,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-360-24",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 360mm",
    depthMM: 215,
    heightMM: 360,
    widthMM: 1429,
    volumeL: 16.08,
    nominalDeltaT50KW: 1.514,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 1357,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-360-25",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 360mm",
    depthMM: 215,
    heightMM: 360,
    widthMM: 1488,
    volumeL: 16.75,
    nominalDeltaT50KW: 1.578,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 1405,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-360-26",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 360mm",
    depthMM: 215,
    heightMM: 360,
    widthMM: 1546,
    volumeL: 17.42,
    nominalDeltaT50KW: 1.641,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 1453,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-360-27",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 360mm",
    depthMM: 215,
    heightMM: 360,
    widthMM: 1604,
    volumeL: 18.09,
    nominalDeltaT50KW: 1.704,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 1501,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-360-28",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 360mm",
    depthMM: 215,
    heightMM: 360,
    widthMM: 1662,
    volumeL: 18.76,
    nominalDeltaT50KW: 1.767,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 1549,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-360-29",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 360mm",
    depthMM: 215,
    heightMM: 360,
    widthMM: 1721,
    volumeL: 19.43,
    nominalDeltaT50KW: 1.83,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 1597,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-360-30",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 360mm",
    depthMM: 215,
    heightMM: 360,
    widthMM: 1779,
    volumeL: 20.1,
    nominalDeltaT50KW: 1.893,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 1645,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-360-31",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 360mm",
    depthMM: 215,
    heightMM: 360,
    widthMM: 1837,
    volumeL: 20.77,
    nominalDeltaT50KW: 1.956,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 1693,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-360-32",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 360mm",
    depthMM: 215,
    heightMM: 360,
    widthMM: 1896,
    volumeL: 21.44,
    nominalDeltaT50KW: 2.019,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 1741,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-360-33",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 360mm",
    depthMM: 215,
    heightMM: 360,
    widthMM: 1954,
    volumeL: 22.11,
    nominalDeltaT50KW: 2.082,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 1789,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-360-34",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 360mm",
    depthMM: 215,
    heightMM: 360,
    widthMM: 2012,
    volumeL: 22.78,
    nominalDeltaT50KW: 2.145,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 1837,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-360-35",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 360mm",
    depthMM: 215,
    heightMM: 360,
    widthMM: 2071,
    volumeL: 23.45,
    nominalDeltaT50KW: 2.209,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 1885,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-360-36",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 360mm",
    depthMM: 215,
    heightMM: 360,
    widthMM: 2129,
    volumeL: 24.12,
    nominalDeltaT50KW: 2.272,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 1933,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-360-37",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 360mm",
    depthMM: 215,
    heightMM: 360,
    widthMM: 2187,
    volumeL: 24.79,
    nominalDeltaT50KW: 2.335,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 1981,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-360-38",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 360mm",
    depthMM: 215,
    heightMM: 360,
    widthMM: 2245,
    volumeL: 25.46,
    nominalDeltaT50KW: 2.398,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 2029,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-360-39",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 360mm",
    depthMM: 215,
    heightMM: 360,
    widthMM: 2304,
    volumeL: 26.13,
    nominalDeltaT50KW: 2.461,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 2077,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-360-40",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 360mm",
    depthMM: 215,
    heightMM: 360,
    widthMM: 2362,
    volumeL: 26.8,
    nominalDeltaT50KW: 2.524,
    kvValue: 20,
    nCoefficient: 1.23,
    price: {
      value: 2125,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-960-04",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 960mm",
    depthMM: 215,
    heightMM: 960,
    widthMM: 263,
    volumeL: 6,
    nominalDeltaT50KW: 0.683,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 459,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-960-05",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 960mm",
    depthMM: 215,
    heightMM: 960,
    widthMM: 322,
    volumeL: 7.5,
    nominalDeltaT50KW: 0.854,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 540,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-960-06",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 960mm",
    depthMM: 215,
    heightMM: 960,
    widthMM: 380,
    volumeL: 9,
    nominalDeltaT50KW: 1.024,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 621,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-960-07",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 960mm",
    depthMM: 215,
    heightMM: 960,
    widthMM: 438,
    volumeL: 10.5,
    nominalDeltaT50KW: 1.195,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 702,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-960-08",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 960mm",
    depthMM: 215,
    heightMM: 960,
    widthMM: 496,
    volumeL: 12,
    nominalDeltaT50KW: 1.366,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 783,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-960-09",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 960mm",
    depthMM: 215,
    heightMM: 960,
    widthMM: 555,
    volumeL: 13.5,
    nominalDeltaT50KW: 1.536,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 864,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-960-10",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 960mm",
    depthMM: 215,
    heightMM: 960,
    widthMM: 613,
    volumeL: 15,
    nominalDeltaT50KW: 1.707,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1015,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-960-11",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 960mm",
    depthMM: 215,
    heightMM: 960,
    widthMM: 671,
    volumeL: 16.5,
    nominalDeltaT50KW: 1.878,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1096,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-960-12",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 960mm",
    depthMM: 215,
    heightMM: 960,
    widthMM: 730,
    volumeL: 18,
    nominalDeltaT50KW: 2.048,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1177,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-960-13",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 960mm",
    depthMM: 215,
    heightMM: 960,
    widthMM: 788,
    volumeL: 19.5,
    nominalDeltaT50KW: 2.219,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1258,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-960-14",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 960mm",
    depthMM: 215,
    heightMM: 960,
    widthMM: 846,
    volumeL: 21,
    nominalDeltaT50KW: 2.39,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1339,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-960-15",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 960mm",
    depthMM: 215,
    heightMM: 960,
    widthMM: 905,
    volumeL: 22.5,
    nominalDeltaT50KW: 2.561,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1420,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-960-16",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 960mm",
    depthMM: 215,
    heightMM: 960,
    widthMM: 963,
    volumeL: 24,
    nominalDeltaT50KW: 2.731,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1501,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-960-17",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 960mm",
    depthMM: 215,
    heightMM: 960,
    widthMM: 1021,
    volumeL: 25.5,
    nominalDeltaT50KW: 2.902,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1582,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-960-18",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 960mm",
    depthMM: 215,
    heightMM: 960,
    widthMM: 1079,
    volumeL: 27,
    nominalDeltaT50KW: 3.073,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1663,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-960-19",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 960mm",
    depthMM: 215,
    heightMM: 960,
    widthMM: 1138,
    volumeL: 28.5,
    nominalDeltaT50KW: 3.243,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1744,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-960-20",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 960mm",
    depthMM: 215,
    heightMM: 960,
    widthMM: 1196,
    volumeL: 30,
    nominalDeltaT50KW: 3.414,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1825,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-960-21",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 960mm",
    depthMM: 215,
    heightMM: 960,
    widthMM: 1254,
    volumeL: 31.5,
    nominalDeltaT50KW: 3.585,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1906,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-960-22",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 960mm",
    depthMM: 215,
    heightMM: 960,
    widthMM: 1313,
    volumeL: 33,
    nominalDeltaT50KW: 3.755,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 1987,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-960-23",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 960mm",
    depthMM: 215,
    heightMM: 960,
    widthMM: 1371,
    volumeL: 34.5,
    nominalDeltaT50KW: 3.926,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 2068,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-960-24",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 960mm",
    depthMM: 215,
    heightMM: 960,
    widthMM: 1429,
    volumeL: 36,
    nominalDeltaT50KW: 4.097,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 2149,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-960-25",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 960mm",
    depthMM: 215,
    heightMM: 960,
    widthMM: 1488,
    volumeL: 37.5,
    nominalDeltaT50KW: 4.268,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 2230,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-960-26",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 960mm",
    depthMM: 215,
    heightMM: 960,
    widthMM: 1546,
    volumeL: 39,
    nominalDeltaT50KW: 4.438,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 2311,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-960-27",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 960mm",
    depthMM: 215,
    heightMM: 960,
    widthMM: 1604,
    volumeL: 40.5,
    nominalDeltaT50KW: 4.609,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 2392,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-960-28",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 960mm",
    depthMM: 215,
    heightMM: 960,
    widthMM: 1662,
    volumeL: 42,
    nominalDeltaT50KW: 4.78,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 2473,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-960-29",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 960mm",
    depthMM: 215,
    heightMM: 960,
    widthMM: 1721,
    volumeL: 43.5,
    nominalDeltaT50KW: 4.95,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 2554,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-960-30",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 960mm",
    depthMM: 215,
    heightMM: 960,
    widthMM: 1779,
    volumeL: 45,
    nominalDeltaT50KW: 5.121,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 2635,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-960-31",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 960mm",
    depthMM: 215,
    heightMM: 960,
    widthMM: 1837,
    volumeL: 46.5,
    nominalDeltaT50KW: 5.292,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 2716,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-960-32",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 960mm",
    depthMM: 215,
    heightMM: 960,
    widthMM: 1896,
    volumeL: 48,
    nominalDeltaT50KW: 5.462,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 2797,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-960-33",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 960mm",
    depthMM: 215,
    heightMM: 960,
    widthMM: 1954,
    volumeL: 49.5,
    nominalDeltaT50KW: 5.633,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 2878,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-960-34",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 960mm",
    depthMM: 215,
    heightMM: 960,
    widthMM: 2012,
    volumeL: 51,
    nominalDeltaT50KW: 5.804,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 2959,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-960-35",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 960mm",
    depthMM: 215,
    heightMM: 960,
    widthMM: 2071,
    volumeL: 52.5,
    nominalDeltaT50KW: 5.975,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 3040,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-960-36",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 960mm",
    depthMM: 215,
    heightMM: 960,
    widthMM: 2129,
    volumeL: 54,
    nominalDeltaT50KW: 6.145,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 3121,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-960-37",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 960mm",
    depthMM: 215,
    heightMM: 960,
    widthMM: 2187,
    volumeL: 55.5,
    nominalDeltaT50KW: 6.316,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 3202,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-960-38",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 960mm",
    depthMM: 215,
    heightMM: 960,
    widthMM: 2245,
    volumeL: 57,
    nominalDeltaT50KW: 6.487,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 3283,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-960-39",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 960mm",
    depthMM: 215,
    heightMM: 960,
    widthMM: 2304,
    volumeL: 58.5,
    nominalDeltaT50KW: 6.657,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 3364,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-M6C-960-40",
    rangeType: "Mercury 6",
    model: "Mercury 6 Column 960mm",
    depthMM: 215,
    heightMM: 960,
    widthMM: 2362,
    volumeL: 60,
    nominalDeltaT50KW: 6.828,
    kvValue: 20,
    nCoefficient: 1.37,
    price: {
      value: 3445,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-660-04",
    rangeType: "Rococo I",
    model: "Rococo I 660mm",
    depthMM: 140,
    heightMM: 660,
    widthMM: 334,
    volumeL: 4.4,
    nominalDeltaT50KW: 0.276,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 359,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-660-05",
    rangeType: "Rococo I",
    model: "Rococo I 660mm",
    depthMM: 140,
    heightMM: 660,
    widthMM: 410,
    volumeL: 5.5,
    nominalDeltaT50KW: 0.345,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 415,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-660-06",
    rangeType: "Rococo I",
    model: "Rococo I 660mm",
    depthMM: 140,
    heightMM: 660,
    widthMM: 486,
    volumeL: 6.6,
    nominalDeltaT50KW: 0.414,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 471,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-660-07",
    rangeType: "Rococo I",
    model: "Rococo I 660mm",
    depthMM: 140,
    heightMM: 660,
    widthMM: 562,
    volumeL: 7.7,
    nominalDeltaT50KW: 0.483,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 527,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-660-08",
    rangeType: "Rococo I",
    model: "Rococo I 660mm",
    depthMM: 140,
    heightMM: 660,
    widthMM: 638,
    volumeL: 8.8,
    nominalDeltaT50KW: 0.552,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 583,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-660-09",
    rangeType: "Rococo I",
    model: "Rococo I 660mm",
    depthMM: 140,
    heightMM: 660,
    widthMM: 714,
    volumeL: 9.9,
    nominalDeltaT50KW: 0.621,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 639,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-660-10",
    rangeType: "Rococo I",
    model: "Rococo I 660mm",
    depthMM: 140,
    heightMM: 660,
    widthMM: 790,
    volumeL: 11,
    nominalDeltaT50KW: 0.69,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 695,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-660-11",
    rangeType: "Rococo I",
    model: "Rococo I 660mm",
    depthMM: 140,
    heightMM: 660,
    widthMM: 866,
    volumeL: 12.1,
    nominalDeltaT50KW: 0.759,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 751,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-660-12",
    rangeType: "Rococo I",
    model: "Rococo I 660mm",
    depthMM: 140,
    heightMM: 660,
    widthMM: 942,
    volumeL: 13.2,
    nominalDeltaT50KW: 0.828,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 807,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-660-13",
    rangeType: "Rococo I",
    model: "Rococo I 660mm",
    depthMM: 140,
    heightMM: 660,
    widthMM: 1018,
    volumeL: 14.3,
    nominalDeltaT50KW: 0.897,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 863,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-660-14",
    rangeType: "Rococo I",
    model: "Rococo I 660mm",
    depthMM: 140,
    heightMM: 660,
    widthMM: 1094,
    volumeL: 15.4,
    nominalDeltaT50KW: 0.966,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 919,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-660-15",
    rangeType: "Rococo I",
    model: "Rococo I 660mm",
    depthMM: 140,
    heightMM: 660,
    widthMM: 1170,
    volumeL: 16.5,
    nominalDeltaT50KW: 1.035,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1045,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-660-16",
    rangeType: "Rococo I",
    model: "Rococo I 660mm",
    depthMM: 140,
    heightMM: 660,
    widthMM: 1246,
    volumeL: 17.6,
    nominalDeltaT50KW: 1.104,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1101,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-660-17",
    rangeType: "Rococo I",
    model: "Rococo I 660mm",
    depthMM: 140,
    heightMM: 660,
    widthMM: 1322,
    volumeL: 18.7,
    nominalDeltaT50KW: 1.173,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1157,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-660-18",
    rangeType: "Rococo I",
    model: "Rococo I 660mm",
    depthMM: 140,
    heightMM: 660,
    widthMM: 1398,
    volumeL: 19.8,
    nominalDeltaT50KW: 1.242,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1213,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-660-19",
    rangeType: "Rococo I",
    model: "Rococo I 660mm",
    depthMM: 140,
    heightMM: 660,
    widthMM: 1474,
    volumeL: 20.9,
    nominalDeltaT50KW: 1.311,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1269,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-660-20",
    rangeType: "Rococo I",
    model: "Rococo I 660mm",
    depthMM: 140,
    heightMM: 660,
    widthMM: 1550,
    volumeL: 22,
    nominalDeltaT50KW: 1.38,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1325,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-660-21",
    rangeType: "Rococo I",
    model: "Rococo I 660mm",
    depthMM: 140,
    heightMM: 660,
    widthMM: 1626,
    volumeL: 23.1,
    nominalDeltaT50KW: 1.449,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1381,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-660-22",
    rangeType: "Rococo I",
    model: "Rococo I 660mm",
    depthMM: 140,
    heightMM: 660,
    widthMM: 1702,
    volumeL: 24.2,
    nominalDeltaT50KW: 1.518,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1437,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-660-23",
    rangeType: "Rococo I",
    model: "Rococo I 660mm",
    depthMM: 140,
    heightMM: 660,
    widthMM: 1778,
    volumeL: 25.3,
    nominalDeltaT50KW: 1.587,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1493,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-660-24",
    rangeType: "Rococo I",
    model: "Rococo I 660mm",
    depthMM: 140,
    heightMM: 660,
    widthMM: 1854,
    volumeL: 26.4,
    nominalDeltaT50KW: 1.656,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1549,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-660-25",
    rangeType: "Rococo I",
    model: "Rococo I 660mm",
    depthMM: 140,
    heightMM: 660,
    widthMM: 1930,
    volumeL: 27.5,
    nominalDeltaT50KW: 1.725,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1605,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-660-26",
    rangeType: "Rococo I",
    model: "Rococo I 660mm",
    depthMM: 140,
    heightMM: 660,
    widthMM: 2006,
    volumeL: 28.6,
    nominalDeltaT50KW: 1.794,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1661,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-660-27",
    rangeType: "Rococo I",
    model: "Rococo I 660mm",
    depthMM: 140,
    heightMM: 660,
    widthMM: 2082,
    volumeL: 29.7,
    nominalDeltaT50KW: 1.863,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1717,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-660-28",
    rangeType: "Rococo I",
    model: "Rococo I 660mm",
    depthMM: 140,
    heightMM: 660,
    widthMM: 2158,
    volumeL: 30.8,
    nominalDeltaT50KW: 1.932,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1773,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-660-29",
    rangeType: "Rococo I",
    model: "Rococo I 660mm",
    depthMM: 140,
    heightMM: 660,
    widthMM: 2234,
    volumeL: 31.9,
    nominalDeltaT50KW: 2.001,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1829,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-660-30",
    rangeType: "Rococo I",
    model: "Rococo I 660mm",
    depthMM: 140,
    heightMM: 660,
    widthMM: 2310,
    volumeL: 33,
    nominalDeltaT50KW: 2.07,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1885,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-660-31",
    rangeType: "Rococo I",
    model: "Rococo I 660mm",
    depthMM: 140,
    heightMM: 660,
    widthMM: 2386,
    volumeL: 34.1,
    nominalDeltaT50KW: 2.139,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1941,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-660-32",
    rangeType: "Rococo I",
    model: "Rococo I 660mm",
    depthMM: 140,
    heightMM: 660,
    widthMM: 2462,
    volumeL: 35.2,
    nominalDeltaT50KW: 2.208,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1997,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-660-33",
    rangeType: "Rococo I",
    model: "Rococo I 660mm",
    depthMM: 140,
    heightMM: 660,
    widthMM: 2538,
    volumeL: 36.3,
    nominalDeltaT50KW: 2.277,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 2053,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-660-34",
    rangeType: "Rococo I",
    model: "Rococo I 660mm",
    depthMM: 140,
    heightMM: 660,
    widthMM: 2614,
    volumeL: 37.4,
    nominalDeltaT50KW: 2.346,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 2109,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-660-35",
    rangeType: "Rococo I",
    model: "Rococo I 660mm",
    depthMM: 140,
    heightMM: 660,
    widthMM: 2690,
    volumeL: 38.5,
    nominalDeltaT50KW: 2.415,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 2165,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-660-36",
    rangeType: "Rococo I",
    model: "Rococo I 660mm",
    depthMM: 140,
    heightMM: 660,
    widthMM: 2766,
    volumeL: 39.6,
    nominalDeltaT50KW: 2.484,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 2221,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-660-37",
    rangeType: "Rococo I",
    model: "Rococo I 660mm",
    depthMM: 140,
    heightMM: 660,
    widthMM: 2842,
    volumeL: 40.7,
    nominalDeltaT50KW: 2.553,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 2277,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-660-38",
    rangeType: "Rococo I",
    model: "Rococo I 660mm",
    depthMM: 140,
    heightMM: 660,
    widthMM: 2918,
    volumeL: 41.8,
    nominalDeltaT50KW: 2.622,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 2333,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-660-39",
    rangeType: "Rococo I",
    model: "Rococo I 660mm",
    depthMM: 140,
    heightMM: 660,
    widthMM: 2994,
    volumeL: 42.9,
    nominalDeltaT50KW: 2.691,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 2389,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-660-40",
    rangeType: "Rococo I",
    model: "Rococo I 660mm",
    depthMM: 140,
    heightMM: 660,
    widthMM: 3070,
    volumeL: 44,
    nominalDeltaT50KW: 2.76,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 2445,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-950-04",
    rangeType: "Rococo I",
    model: "Rococo I 950mm",
    depthMM: 140,
    heightMM: 950,
    widthMM: 334,
    volumeL: 5.2,
    nominalDeltaT50KW: 0.417,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 427,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-950-05",
    rangeType: "Rococo I",
    model: "Rococo I 950mm",
    depthMM: 140,
    heightMM: 950,
    widthMM: 410,
    volumeL: 6.5,
    nominalDeltaT50KW: 0.522,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 500,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-950-06",
    rangeType: "Rococo I",
    model: "Rococo I 950mm",
    depthMM: 140,
    heightMM: 950,
    widthMM: 486,
    volumeL: 7.8,
    nominalDeltaT50KW: 0.626,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 573,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-950-07",
    rangeType: "Rococo I",
    model: "Rococo I 950mm",
    depthMM: 140,
    heightMM: 950,
    widthMM: 562,
    volumeL: 9.1,
    nominalDeltaT50KW: 0.73,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 646,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-950-08",
    rangeType: "Rococo I",
    model: "Rococo I 950mm",
    depthMM: 140,
    heightMM: 950,
    widthMM: 638,
    volumeL: 10.4,
    nominalDeltaT50KW: 0.834,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 719,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-950-09",
    rangeType: "Rococo I",
    model: "Rococo I 950mm",
    depthMM: 140,
    heightMM: 950,
    widthMM: 714,
    volumeL: 11.7,
    nominalDeltaT50KW: 0.939,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 792,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-950-10",
    rangeType: "Rococo I",
    model: "Rococo I 950mm",
    depthMM: 140,
    heightMM: 950,
    widthMM: 790,
    volumeL: 13,
    nominalDeltaT50KW: 1.043,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 865,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-950-11",
    rangeType: "Rococo I",
    model: "Rococo I 950mm",
    depthMM: 140,
    heightMM: 950,
    widthMM: 866,
    volumeL: 14.3,
    nominalDeltaT50KW: 1.147,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 938,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-950-12",
    rangeType: "Rococo I",
    model: "Rococo I 950mm",
    depthMM: 140,
    heightMM: 950,
    widthMM: 942,
    volumeL: 15.6,
    nominalDeltaT50KW: 1.252,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1011,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-950-13",
    rangeType: "Rococo I",
    model: "Rococo I 950mm",
    depthMM: 140,
    heightMM: 950,
    widthMM: 1018,
    volumeL: 16.9,
    nominalDeltaT50KW: 1.356,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1154,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-950-14",
    rangeType: "Rococo I",
    model: "Rococo I 950mm",
    depthMM: 140,
    heightMM: 950,
    widthMM: 1094,
    volumeL: 18.2,
    nominalDeltaT50KW: 1.46,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1227,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-950-15",
    rangeType: "Rococo I",
    model: "Rococo I 950mm",
    depthMM: 140,
    heightMM: 950,
    widthMM: 1170,
    volumeL: 19.5,
    nominalDeltaT50KW: 1.565,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1300,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-950-16",
    rangeType: "Rococo I",
    model: "Rococo I 950mm",
    depthMM: 140,
    heightMM: 950,
    widthMM: 1246,
    volumeL: 20.8,
    nominalDeltaT50KW: 1.669,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1373,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-950-17",
    rangeType: "Rococo I",
    model: "Rococo I 950mm",
    depthMM: 140,
    heightMM: 950,
    widthMM: 1322,
    volumeL: 22.1,
    nominalDeltaT50KW: 1.773,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1446,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-950-18",
    rangeType: "Rococo I",
    model: "Rococo I 950mm",
    depthMM: 140,
    heightMM: 950,
    widthMM: 1398,
    volumeL: 23.4,
    nominalDeltaT50KW: 1.877,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1519,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-950-19",
    rangeType: "Rococo I",
    model: "Rococo I 950mm",
    depthMM: 140,
    heightMM: 950,
    widthMM: 1474,
    volumeL: 24.7,
    nominalDeltaT50KW: 1.982,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1592,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-950-20",
    rangeType: "Rococo I",
    model: "Rococo I 950mm",
    depthMM: 140,
    heightMM: 950,
    widthMM: 1550,
    volumeL: 26,
    nominalDeltaT50KW: 2.086,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1665,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-950-21",
    rangeType: "Rococo I",
    model: "Rococo I 950mm",
    depthMM: 140,
    heightMM: 950,
    widthMM: 1626,
    volumeL: 27.3,
    nominalDeltaT50KW: 2.19,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1738,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-950-22",
    rangeType: "Rococo I",
    model: "Rococo I 950mm",
    depthMM: 140,
    heightMM: 950,
    widthMM: 1702,
    volumeL: 28.6,
    nominalDeltaT50KW: 2.295,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1811,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-950-23",
    rangeType: "Rococo I",
    model: "Rococo I 950mm",
    depthMM: 140,
    heightMM: 950,
    widthMM: 1778,
    volumeL: 29.9,
    nominalDeltaT50KW: 2.399,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1884,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-950-24",
    rangeType: "Rococo I",
    model: "Rococo I 950mm",
    depthMM: 140,
    heightMM: 950,
    widthMM: 1854,
    volumeL: 31.2,
    nominalDeltaT50KW: 2.503,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 1957,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-950-25",
    rangeType: "Rococo I",
    model: "Rococo I 950mm",
    depthMM: 140,
    heightMM: 950,
    widthMM: 1930,
    volumeL: 32.5,
    nominalDeltaT50KW: 2.608,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 2030,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-950-26",
    rangeType: "Rococo I",
    model: "Rococo I 950mm",
    depthMM: 140,
    heightMM: 950,
    widthMM: 2006,
    volumeL: 33.8,
    nominalDeltaT50KW: 2.712,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 2103,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-950-27",
    rangeType: "Rococo I",
    model: "Rococo I 950mm",
    depthMM: 140,
    heightMM: 950,
    widthMM: 2082,
    volumeL: 35.1,
    nominalDeltaT50KW: 2.816,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 2176,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-950-28",
    rangeType: "Rococo I",
    model: "Rococo I 950mm",
    depthMM: 140,
    heightMM: 950,
    widthMM: 2158,
    volumeL: 36.4,
    nominalDeltaT50KW: 2.92,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 2249,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-950-29",
    rangeType: "Rococo I",
    model: "Rococo I 950mm",
    depthMM: 140,
    heightMM: 950,
    widthMM: 2234,
    volumeL: 37.7,
    nominalDeltaT50KW: 3.025,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 2322,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-950-30",
    rangeType: "Rococo I",
    model: "Rococo I 950mm",
    depthMM: 140,
    heightMM: 950,
    widthMM: 2310,
    volumeL: 39,
    nominalDeltaT50KW: 3.129,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 2395,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-950-31",
    rangeType: "Rococo I",
    model: "Rococo I 950mm",
    depthMM: 140,
    heightMM: 950,
    widthMM: 2386,
    volumeL: 40.3,
    nominalDeltaT50KW: 3.233,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 2468,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-950-32",
    rangeType: "Rococo I",
    model: "Rococo I 950mm",
    depthMM: 140,
    heightMM: 950,
    widthMM: 2462,
    volumeL: 41.6,
    nominalDeltaT50KW: 3.338,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 2541,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-950-33",
    rangeType: "Rococo I",
    model: "Rococo I 950mm",
    depthMM: 140,
    heightMM: 950,
    widthMM: 2538,
    volumeL: 42.9,
    nominalDeltaT50KW: 3.442,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 2614,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-950-34",
    rangeType: "Rococo I",
    model: "Rococo I 950mm",
    depthMM: 140,
    heightMM: 950,
    widthMM: 2614,
    volumeL: 44.2,
    nominalDeltaT50KW: 3.546,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 2687,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-950-35",
    rangeType: "Rococo I",
    model: "Rococo I 950mm",
    depthMM: 140,
    heightMM: 950,
    widthMM: 2690,
    volumeL: 45.5,
    nominalDeltaT50KW: 3.651,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 2760,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-950-36",
    rangeType: "Rococo I",
    model: "Rococo I 950mm",
    depthMM: 140,
    heightMM: 950,
    widthMM: 2766,
    volumeL: 46.8,
    nominalDeltaT50KW: 3.755,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 2833,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-950-37",
    rangeType: "Rococo I",
    model: "Rococo I 950mm",
    depthMM: 140,
    heightMM: 950,
    widthMM: 2842,
    volumeL: 48.1,
    nominalDeltaT50KW: 3.859,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 2906,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-950-38",
    rangeType: "Rococo I",
    model: "Rococo I 950mm",
    depthMM: 140,
    heightMM: 950,
    widthMM: 2918,
    volumeL: 49.4,
    nominalDeltaT50KW: 3.963,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 2979,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-950-39",
    rangeType: "Rococo I",
    model: "Rococo I 950mm",
    depthMM: 140,
    heightMM: 950,
    widthMM: 2994,
    volumeL: 50.7,
    nominalDeltaT50KW: 4.068,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 3052,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC1-950-40",
    rangeType: "Rococo I",
    model: "Rococo I 950mm",
    depthMM: 140,
    heightMM: 950,
    widthMM: 3070,
    volumeL: 52,
    nominalDeltaT50KW: 4.172,
    kvValue: 20,
    nCoefficient: 1.29,
    price: {
      value: 3125,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-510-04",
    rangeType: "Rococo II",
    model: "Rococo II 510mm",
    depthMM: 220,
    heightMM: 510,
    widthMM: 350,
    volumeL: 7.2,
    nominalDeltaT50KW: 0.31,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 315,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-510-05",
    rangeType: "Rococo II",
    model: "Rococo II 510mm",
    depthMM: 220,
    heightMM: 510,
    widthMM: 430,
    volumeL: 9,
    nominalDeltaT50KW: 0.388,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 360,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-510-06",
    rangeType: "Rococo II",
    model: "Rococo II 510mm",
    depthMM: 220,
    heightMM: 510,
    widthMM: 510,
    volumeL: 10.8,
    nominalDeltaT50KW: 0.465,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 405,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-510-07",
    rangeType: "Rococo II",
    model: "Rococo II 510mm",
    depthMM: 220,
    heightMM: 510,
    widthMM: 590,
    volumeL: 12.6,
    nominalDeltaT50KW: 0.543,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 450,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-510-08",
    rangeType: "Rococo II",
    model: "Rococo II 510mm",
    depthMM: 220,
    heightMM: 510,
    widthMM: 670,
    volumeL: 14.4,
    nominalDeltaT50KW: 0.62,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 495,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-510-09",
    rangeType: "Rococo II",
    model: "Rococo II 510mm",
    depthMM: 220,
    heightMM: 510,
    widthMM: 750,
    volumeL: 16.2,
    nominalDeltaT50KW: 0.698,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 540,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-510-10",
    rangeType: "Rococo II",
    model: "Rococo II 510mm",
    depthMM: 220,
    heightMM: 510,
    widthMM: 830,
    volumeL: 18,
    nominalDeltaT50KW: 0.775,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 585,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-510-11",
    rangeType: "Rococo II",
    model: "Rococo II 510mm",
    depthMM: 220,
    heightMM: 510,
    widthMM: 910,
    volumeL: 19.8,
    nominalDeltaT50KW: 0.853,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 630,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-510-12",
    rangeType: "Rococo II",
    model: "Rococo II 510mm",
    depthMM: 220,
    heightMM: 510,
    widthMM: 990,
    volumeL: 21.6,
    nominalDeltaT50KW: 0.93,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 675,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-510-13",
    rangeType: "Rococo II",
    model: "Rococo II 510mm",
    depthMM: 220,
    heightMM: 510,
    widthMM: 1070,
    volumeL: 23.4,
    nominalDeltaT50KW: 1.008,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 720,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-510-14",
    rangeType: "Rococo II",
    model: "Rococo II 510mm",
    depthMM: 220,
    heightMM: 510,
    widthMM: 1150,
    volumeL: 25.2,
    nominalDeltaT50KW: 1.085,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 765,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-510-15",
    rangeType: "Rococo II",
    model: "Rococo II 510mm",
    depthMM: 220,
    heightMM: 510,
    widthMM: 1230,
    volumeL: 27,
    nominalDeltaT50KW: 1.163,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 880,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-510-16",
    rangeType: "Rococo II",
    model: "Rococo II 510mm",
    depthMM: 220,
    heightMM: 510,
    widthMM: 1310,
    volumeL: 28.8,
    nominalDeltaT50KW: 1.24,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 925,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-510-17",
    rangeType: "Rococo II",
    model: "Rococo II 510mm",
    depthMM: 220,
    heightMM: 510,
    widthMM: 1390,
    volumeL: 30.6,
    nominalDeltaT50KW: 1.318,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 970,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-510-18",
    rangeType: "Rococo II",
    model: "Rococo II 510mm",
    depthMM: 220,
    heightMM: 510,
    widthMM: 1470,
    volumeL: 32.4,
    nominalDeltaT50KW: 1.395,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1015,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-510-19",
    rangeType: "Rococo II",
    model: "Rococo II 510mm",
    depthMM: 220,
    heightMM: 510,
    widthMM: 1550,
    volumeL: 34.2,
    nominalDeltaT50KW: 1.473,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1060,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-510-20",
    rangeType: "Rococo II",
    model: "Rococo II 510mm",
    depthMM: 220,
    heightMM: 510,
    widthMM: 1630,
    volumeL: 36,
    nominalDeltaT50KW: 1.55,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1105,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-510-21",
    rangeType: "Rococo II",
    model: "Rococo II 510mm",
    depthMM: 220,
    heightMM: 510,
    widthMM: 1710,
    volumeL: 37.8,
    nominalDeltaT50KW: 1.628,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1150,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-510-22",
    rangeType: "Rococo II",
    model: "Rococo II 510mm",
    depthMM: 220,
    heightMM: 510,
    widthMM: 1790,
    volumeL: 39.6,
    nominalDeltaT50KW: 1.705,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1195,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-510-23",
    rangeType: "Rococo II",
    model: "Rococo II 510mm",
    depthMM: 220,
    heightMM: 510,
    widthMM: 1870,
    volumeL: 41.4,
    nominalDeltaT50KW: 1.783,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1240,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-510-24",
    rangeType: "Rococo II",
    model: "Rococo II 510mm",
    depthMM: 220,
    heightMM: 510,
    widthMM: 1950,
    volumeL: 43.2,
    nominalDeltaT50KW: 1.86,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1285,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-510-25",
    rangeType: "Rococo II",
    model: "Rococo II 510mm",
    depthMM: 220,
    heightMM: 510,
    widthMM: 2030,
    volumeL: 45,
    nominalDeltaT50KW: 1.938,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1330,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-510-26",
    rangeType: "Rococo II",
    model: "Rococo II 510mm",
    depthMM: 220,
    heightMM: 510,
    widthMM: 2110,
    volumeL: 46.8,
    nominalDeltaT50KW: 2.015,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1375,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-510-27",
    rangeType: "Rococo II",
    model: "Rococo II 510mm",
    depthMM: 220,
    heightMM: 510,
    widthMM: 2190,
    volumeL: 48.6,
    nominalDeltaT50KW: 2.093,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1420,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-510-28",
    rangeType: "Rococo II",
    model: "Rococo II 510mm",
    depthMM: 220,
    heightMM: 510,
    widthMM: 2270,
    volumeL: 50.4,
    nominalDeltaT50KW: 2.17,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1465,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-510-29",
    rangeType: "Rococo II",
    model: "Rococo II 510mm",
    depthMM: 220,
    heightMM: 510,
    widthMM: 2350,
    volumeL: 52.2,
    nominalDeltaT50KW: 2.248,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1510,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-510-30",
    rangeType: "Rococo II",
    model: "Rococo II 510mm",
    depthMM: 220,
    heightMM: 510,
    widthMM: 2430,
    volumeL: 54,
    nominalDeltaT50KW: 2.325,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1555,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-510-31",
    rangeType: "Rococo II",
    model: "Rococo II 510mm",
    depthMM: 220,
    heightMM: 510,
    widthMM: 2510,
    volumeL: 55.8,
    nominalDeltaT50KW: 2.403,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1600,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-510-32",
    rangeType: "Rococo II",
    model: "Rococo II 510mm",
    depthMM: 220,
    heightMM: 510,
    widthMM: 2590,
    volumeL: 57.6,
    nominalDeltaT50KW: 2.48,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1645,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-510-33",
    rangeType: "Rococo II",
    model: "Rococo II 510mm",
    depthMM: 220,
    heightMM: 510,
    widthMM: 2670,
    volumeL: 59.4,
    nominalDeltaT50KW: 2.558,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1690,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-510-34",
    rangeType: "Rococo II",
    model: "Rococo II 510mm",
    depthMM: 220,
    heightMM: 510,
    widthMM: 2750,
    volumeL: 61.2,
    nominalDeltaT50KW: 2.635,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1735,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-510-35",
    rangeType: "Rococo II",
    model: "Rococo II 510mm",
    depthMM: 220,
    heightMM: 510,
    widthMM: 2830,
    volumeL: 63,
    nominalDeltaT50KW: 2.713,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1780,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-510-36",
    rangeType: "Rococo II",
    model: "Rococo II 510mm",
    depthMM: 220,
    heightMM: 510,
    widthMM: 2910,
    volumeL: 64.8,
    nominalDeltaT50KW: 2.79,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1825,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-510-37",
    rangeType: "Rococo II",
    model: "Rococo II 510mm",
    depthMM: 220,
    heightMM: 510,
    widthMM: 2990,
    volumeL: 66.6,
    nominalDeltaT50KW: 2.868,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1870,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-510-38",
    rangeType: "Rococo II",
    model: "Rococo II 510mm",
    depthMM: 220,
    heightMM: 510,
    widthMM: 3070,
    volumeL: 68.4,
    nominalDeltaT50KW: 2.945,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1915,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-510-39",
    rangeType: "Rococo II",
    model: "Rococo II 510mm",
    depthMM: 220,
    heightMM: 510,
    widthMM: 3150,
    volumeL: 70.2,
    nominalDeltaT50KW: 3.023,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1960,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-510-40",
    rangeType: "Rococo II",
    model: "Rococo II 510mm",
    depthMM: 220,
    heightMM: 510,
    widthMM: 3230,
    volumeL: 72,
    nominalDeltaT50KW: 3.1,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 2005,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-660-04",
    rangeType: "Rococo II",
    model: "Rococo II 660mm",
    depthMM: 207,
    heightMM: 660,
    widthMM: 330,
    volumeL: 8,
    nominalDeltaT50KW: 0.396,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 347,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-660-05",
    rangeType: "Rococo II",
    model: "Rococo II 660mm",
    depthMM: 207,
    heightMM: 660,
    widthMM: 405,
    volumeL: 10,
    nominalDeltaT50KW: 0.495,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 400,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-660-06",
    rangeType: "Rococo II",
    model: "Rococo II 660mm",
    depthMM: 207,
    heightMM: 660,
    widthMM: 480,
    volumeL: 12,
    nominalDeltaT50KW: 0.594,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 453,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-660-07",
    rangeType: "Rococo II",
    model: "Rococo II 660mm",
    depthMM: 207,
    heightMM: 660,
    widthMM: 555,
    volumeL: 14,
    nominalDeltaT50KW: 0.693,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 506,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-660-08",
    rangeType: "Rococo II",
    model: "Rococo II 660mm",
    depthMM: 207,
    heightMM: 660,
    widthMM: 630,
    volumeL: 16,
    nominalDeltaT50KW: 0.792,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 559,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-660-09",
    rangeType: "Rococo II",
    model: "Rococo II 660mm",
    depthMM: 207,
    heightMM: 660,
    widthMM: 705,
    volumeL: 18,
    nominalDeltaT50KW: 0.891,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 612,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-660-10",
    rangeType: "Rococo II",
    model: "Rococo II 660mm",
    depthMM: 207,
    heightMM: 660,
    widthMM: 780,
    volumeL: 20,
    nominalDeltaT50KW: 0.99,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 665,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-660-11",
    rangeType: "Rococo II",
    model: "Rococo II 660mm",
    depthMM: 207,
    heightMM: 660,
    widthMM: 855,
    volumeL: 22,
    nominalDeltaT50KW: 1.089,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 718,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-660-12",
    rangeType: "Rococo II",
    model: "Rococo II 660mm",
    depthMM: 207,
    heightMM: 660,
    widthMM: 930,
    volumeL: 24,
    nominalDeltaT50KW: 1.188,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 841,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-660-13",
    rangeType: "Rococo II",
    model: "Rococo II 660mm",
    depthMM: 207,
    heightMM: 660,
    widthMM: 1005,
    volumeL: 26,
    nominalDeltaT50KW: 1.287,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 894,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-660-14",
    rangeType: "Rococo II",
    model: "Rococo II 660mm",
    depthMM: 207,
    heightMM: 660,
    widthMM: 1080,
    volumeL: 28,
    nominalDeltaT50KW: 1.386,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 947,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-660-15",
    rangeType: "Rococo II",
    model: "Rococo II 660mm",
    depthMM: 207,
    heightMM: 660,
    widthMM: 1155,
    volumeL: 30,
    nominalDeltaT50KW: 1.485,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1000,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-660-16",
    rangeType: "Rococo II",
    model: "Rococo II 660mm",
    depthMM: 207,
    heightMM: 660,
    widthMM: 1230,
    volumeL: 32,
    nominalDeltaT50KW: 1.584,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1053,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-660-17",
    rangeType: "Rococo II",
    model: "Rococo II 660mm",
    depthMM: 207,
    heightMM: 660,
    widthMM: 1305,
    volumeL: 34,
    nominalDeltaT50KW: 1.683,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1106,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-660-18",
    rangeType: "Rococo II",
    model: "Rococo II 660mm",
    depthMM: 207,
    heightMM: 660,
    widthMM: 1380,
    volumeL: 36,
    nominalDeltaT50KW: 1.782,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1159,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-660-19",
    rangeType: "Rococo II",
    model: "Rococo II 660mm",
    depthMM: 207,
    heightMM: 660,
    widthMM: 1455,
    volumeL: 38,
    nominalDeltaT50KW: 1.881,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1212,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-660-20",
    rangeType: "Rococo II",
    model: "Rococo II 660mm",
    depthMM: 207,
    heightMM: 660,
    widthMM: 1530,
    volumeL: 40,
    nominalDeltaT50KW: 1.98,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1265,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-660-21",
    rangeType: "Rococo II",
    model: "Rococo II 660mm",
    depthMM: 207,
    heightMM: 660,
    widthMM: 1605,
    volumeL: 42,
    nominalDeltaT50KW: 2.079,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1318,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-660-22",
    rangeType: "Rococo II",
    model: "Rococo II 660mm",
    depthMM: 207,
    heightMM: 660,
    widthMM: 1680,
    volumeL: 44,
    nominalDeltaT50KW: 2.178,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1371,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-660-23",
    rangeType: "Rococo II",
    model: "Rococo II 660mm",
    depthMM: 207,
    heightMM: 660,
    widthMM: 1755,
    volumeL: 46,
    nominalDeltaT50KW: 2.277,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1424,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-660-24",
    rangeType: "Rococo II",
    model: "Rococo II 660mm",
    depthMM: 207,
    heightMM: 660,
    widthMM: 1830,
    volumeL: 48,
    nominalDeltaT50KW: 2.376,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1477,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-660-25",
    rangeType: "Rococo II",
    model: "Rococo II 660mm",
    depthMM: 207,
    heightMM: 660,
    widthMM: 1905,
    volumeL: 50,
    nominalDeltaT50KW: 2.475,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1530,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-660-26",
    rangeType: "Rococo II",
    model: "Rococo II 660mm",
    depthMM: 207,
    heightMM: 660,
    widthMM: 1980,
    volumeL: 52,
    nominalDeltaT50KW: 2.574,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1583,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-660-27",
    rangeType: "Rococo II",
    model: "Rococo II 660mm",
    depthMM: 207,
    heightMM: 660,
    widthMM: 2055,
    volumeL: 54,
    nominalDeltaT50KW: 2.673,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1636,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-660-28",
    rangeType: "Rococo II",
    model: "Rococo II 660mm",
    depthMM: 207,
    heightMM: 660,
    widthMM: 2130,
    volumeL: 56,
    nominalDeltaT50KW: 2.772,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1689,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-660-29",
    rangeType: "Rococo II",
    model: "Rococo II 660mm",
    depthMM: 207,
    heightMM: 660,
    widthMM: 2205,
    volumeL: 58,
    nominalDeltaT50KW: 2.871,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1742,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-660-30",
    rangeType: "Rococo II",
    model: "Rococo II 660mm",
    depthMM: 207,
    heightMM: 660,
    widthMM: 2280,
    volumeL: 60,
    nominalDeltaT50KW: 2.97,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1795,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-660-31",
    rangeType: "Rococo II",
    model: "Rococo II 660mm",
    depthMM: 207,
    heightMM: 660,
    widthMM: 2355,
    volumeL: 62,
    nominalDeltaT50KW: 3.069,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1848,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-660-32",
    rangeType: "Rococo II",
    model: "Rococo II 660mm",
    depthMM: 207,
    heightMM: 660,
    widthMM: 2430,
    volumeL: 64,
    nominalDeltaT50KW: 3.168,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1901,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-660-33",
    rangeType: "Rococo II",
    model: "Rococo II 660mm",
    depthMM: 207,
    heightMM: 660,
    widthMM: 2505,
    volumeL: 66,
    nominalDeltaT50KW: 3.267,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1954,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-660-34",
    rangeType: "Rococo II",
    model: "Rococo II 660mm",
    depthMM: 207,
    heightMM: 660,
    widthMM: 2580,
    volumeL: 68,
    nominalDeltaT50KW: 3.366,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 2007,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-660-35",
    rangeType: "Rococo II",
    model: "Rococo II 660mm",
    depthMM: 207,
    heightMM: 660,
    widthMM: 2655,
    volumeL: 70,
    nominalDeltaT50KW: 3.465,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 2060,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-660-36",
    rangeType: "Rococo II",
    model: "Rococo II 660mm",
    depthMM: 207,
    heightMM: 660,
    widthMM: 2730,
    volumeL: 72,
    nominalDeltaT50KW: 3.564,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 2113,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-660-37",
    rangeType: "Rococo II",
    model: "Rococo II 660mm",
    depthMM: 207,
    heightMM: 660,
    widthMM: 2805,
    volumeL: 74,
    nominalDeltaT50KW: 3.663,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 2166,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-660-38",
    rangeType: "Rococo II",
    model: "Rococo II 660mm",
    depthMM: 207,
    heightMM: 660,
    widthMM: 2880,
    volumeL: 76,
    nominalDeltaT50KW: 3.762,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 2219,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-660-39",
    rangeType: "Rococo II",
    model: "Rococo II 660mm",
    depthMM: 207,
    heightMM: 660,
    widthMM: 2955,
    volumeL: 78,
    nominalDeltaT50KW: 3.861,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 2272,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-660-40",
    rangeType: "Rococo II",
    model: "Rococo II 660mm",
    depthMM: 207,
    heightMM: 660,
    widthMM: 3030,
    volumeL: 80,
    nominalDeltaT50KW: 3.96,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 2325,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-760-04",
    rangeType: "Rococo II",
    model: "Rococo II 760mm",
    depthMM: 215,
    heightMM: 760,
    widthMM: 346,
    volumeL: 12.4,
    nominalDeltaT50KW: 0.46,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 375,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-760-05",
    rangeType: "Rococo II",
    model: "Rococo II 760mm",
    depthMM: 215,
    heightMM: 760,
    widthMM: 425,
    volumeL: 15.5,
    nominalDeltaT50KW: 0.575,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 435,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-760-06",
    rangeType: "Rococo II",
    model: "Rococo II 760mm",
    depthMM: 215,
    heightMM: 760,
    widthMM: 504,
    volumeL: 18.6,
    nominalDeltaT50KW: 0.689,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 495,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-760-07",
    rangeType: "Rococo II",
    model: "Rococo II 760mm",
    depthMM: 215,
    heightMM: 760,
    widthMM: 583,
    volumeL: 21.7,
    nominalDeltaT50KW: 0.804,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 555,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-760-08",
    rangeType: "Rococo II",
    model: "Rococo II 760mm",
    depthMM: 215,
    heightMM: 760,
    widthMM: 662,
    volumeL: 24.8,
    nominalDeltaT50KW: 0.919,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 615,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-760-09",
    rangeType: "Rococo II",
    model: "Rococo II 760mm",
    depthMM: 215,
    heightMM: 760,
    widthMM: 741,
    volumeL: 27.9,
    nominalDeltaT50KW: 1.034,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 675,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-760-10",
    rangeType: "Rococo II",
    model: "Rococo II 760mm",
    depthMM: 215,
    heightMM: 760,
    widthMM: 820,
    volumeL: 31,
    nominalDeltaT50KW: 1.149,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 735,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-760-11",
    rangeType: "Rococo II",
    model: "Rococo II 760mm",
    depthMM: 215,
    heightMM: 760,
    widthMM: 899,
    volumeL: 34.1,
    nominalDeltaT50KW: 1.264,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 865,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-760-12",
    rangeType: "Rococo II",
    model: "Rococo II 760mm",
    depthMM: 215,
    heightMM: 760,
    widthMM: 978,
    volumeL: 37.2,
    nominalDeltaT50KW: 1.379,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 925,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-760-13",
    rangeType: "Rococo II",
    model: "Rococo II 760mm",
    depthMM: 215,
    heightMM: 760,
    widthMM: 1057,
    volumeL: 40.3,
    nominalDeltaT50KW: 1.494,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 985,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-760-14",
    rangeType: "Rococo II",
    model: "Rococo II 760mm",
    depthMM: 215,
    heightMM: 760,
    widthMM: 1136,
    volumeL: 43.4,
    nominalDeltaT50KW: 1.609,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1045,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-760-15",
    rangeType: "Rococo II",
    model: "Rococo II 760mm",
    depthMM: 215,
    heightMM: 760,
    widthMM: 1215,
    volumeL: 46.5,
    nominalDeltaT50KW: 1.724,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1105,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-760-16",
    rangeType: "Rococo II",
    model: "Rococo II 760mm",
    depthMM: 215,
    heightMM: 760,
    widthMM: 1294,
    volumeL: 49.6,
    nominalDeltaT50KW: 1.838,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1165,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-760-17",
    rangeType: "Rococo II",
    model: "Rococo II 760mm",
    depthMM: 215,
    heightMM: 760,
    widthMM: 1373,
    volumeL: 52.7,
    nominalDeltaT50KW: 1.953,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1225,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-760-18",
    rangeType: "Rococo II",
    model: "Rococo II 760mm",
    depthMM: 215,
    heightMM: 760,
    widthMM: 1452,
    volumeL: 55.8,
    nominalDeltaT50KW: 2.068,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1285,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-760-19",
    rangeType: "Rococo II",
    model: "Rococo II 760mm",
    depthMM: 215,
    heightMM: 760,
    widthMM: 1531,
    volumeL: 58.9,
    nominalDeltaT50KW: 2.183,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1345,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-760-20",
    rangeType: "Rococo II",
    model: "Rococo II 760mm",
    depthMM: 215,
    heightMM: 760,
    widthMM: 1610,
    volumeL: 62,
    nominalDeltaT50KW: 2.298,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1405,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-760-21",
    rangeType: "Rococo II",
    model: "Rococo II 760mm",
    depthMM: 215,
    heightMM: 760,
    widthMM: 1689,
    volumeL: 65.1,
    nominalDeltaT50KW: 2.413,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1465,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-760-22",
    rangeType: "Rococo II",
    model: "Rococo II 760mm",
    depthMM: 215,
    heightMM: 760,
    widthMM: 1768,
    volumeL: 68.2,
    nominalDeltaT50KW: 2.528,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1525,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-760-23",
    rangeType: "Rococo II",
    model: "Rococo II 760mm",
    depthMM: 215,
    heightMM: 760,
    widthMM: 1847,
    volumeL: 71.3,
    nominalDeltaT50KW: 2.643,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1585,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-760-24",
    rangeType: "Rococo II",
    model: "Rococo II 760mm",
    depthMM: 215,
    heightMM: 760,
    widthMM: 1926,
    volumeL: 74.4,
    nominalDeltaT50KW: 2.758,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1645,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-760-25",
    rangeType: "Rococo II",
    model: "Rococo II 760mm",
    depthMM: 215,
    heightMM: 760,
    widthMM: 2005,
    volumeL: 77.5,
    nominalDeltaT50KW: 2.873,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1705,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-760-26",
    rangeType: "Rococo II",
    model: "Rococo II 760mm",
    depthMM: 215,
    heightMM: 760,
    widthMM: 2084,
    volumeL: 80.6,
    nominalDeltaT50KW: 2.987,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1765,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-760-27",
    rangeType: "Rococo II",
    model: "Rococo II 760mm",
    depthMM: 215,
    heightMM: 760,
    widthMM: 2163,
    volumeL: 83.7,
    nominalDeltaT50KW: 3.102,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1825,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-760-28",
    rangeType: "Rococo II",
    model: "Rococo II 760mm",
    depthMM: 215,
    heightMM: 760,
    widthMM: 2242,
    volumeL: 86.8,
    nominalDeltaT50KW: 3.217,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1885,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-760-29",
    rangeType: "Rococo II",
    model: "Rococo II 760mm",
    depthMM: 215,
    heightMM: 760,
    widthMM: 2321,
    volumeL: 89.9,
    nominalDeltaT50KW: 3.332,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 1945,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-760-30",
    rangeType: "Rococo II",
    model: "Rococo II 760mm",
    depthMM: 215,
    heightMM: 760,
    widthMM: 2400,
    volumeL: 93,
    nominalDeltaT50KW: 3.447,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 2005,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-760-31",
    rangeType: "Rococo II",
    model: "Rococo II 760mm",
    depthMM: 215,
    heightMM: 760,
    widthMM: 2479,
    volumeL: 96.1,
    nominalDeltaT50KW: 3.562,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 2065,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-760-32",
    rangeType: "Rococo II",
    model: "Rococo II 760mm",
    depthMM: 215,
    heightMM: 760,
    widthMM: 2558,
    volumeL: 99.2,
    nominalDeltaT50KW: 3.677,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 2125,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-760-33",
    rangeType: "Rococo II",
    model: "Rococo II 760mm",
    depthMM: 215,
    heightMM: 760,
    widthMM: 2637,
    volumeL: 102.3,
    nominalDeltaT50KW: 3.792,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 2185,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-760-34",
    rangeType: "Rococo II",
    model: "Rococo II 760mm",
    depthMM: 215,
    heightMM: 760,
    widthMM: 2716,
    volumeL: 105.4,
    nominalDeltaT50KW: 3.907,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 2245,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-760-35",
    rangeType: "Rococo II",
    model: "Rococo II 760mm",
    depthMM: 215,
    heightMM: 760,
    widthMM: 2795,
    volumeL: 108.5,
    nominalDeltaT50KW: 4.022,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 2305,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-760-36",
    rangeType: "Rococo II",
    model: "Rococo II 760mm",
    depthMM: 215,
    heightMM: 760,
    widthMM: 2874,
    volumeL: 111.6,
    nominalDeltaT50KW: 4.136,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 2365,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-760-37",
    rangeType: "Rococo II",
    model: "Rococo II 760mm",
    depthMM: 215,
    heightMM: 760,
    widthMM: 2953,
    volumeL: 114.7,
    nominalDeltaT50KW: 4.251,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 2425,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-760-38",
    rangeType: "Rococo II",
    model: "Rococo II 760mm",
    depthMM: 215,
    heightMM: 760,
    widthMM: 3032,
    volumeL: 117.8,
    nominalDeltaT50KW: 4.366,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 2485,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-760-39",
    rangeType: "Rococo II",
    model: "Rococo II 760mm",
    depthMM: 215,
    heightMM: 760,
    widthMM: 3111,
    volumeL: 120.9,
    nominalDeltaT50KW: 4.481,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 2545,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-760-40",
    rangeType: "Rococo II",
    model: "Rococo II 760mm",
    depthMM: 215,
    heightMM: 760,
    widthMM: 3190,
    volumeL: 124,
    nominalDeltaT50KW: 4.596,
    kvValue: 20,
    nCoefficient: 1.25,
    price: {
      value: 2605,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-950-04",
    rangeType: "Rococo II",
    model: "Rococo II 950mm",
    depthMM: 205,
    heightMM: 950,
    widthMM: 342,
    volumeL: 12.4,
    nominalDeltaT50KW: 0.563,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 455,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-950-05",
    rangeType: "Rococo II",
    model: "Rococo II 950mm",
    depthMM: 205,
    heightMM: 950,
    widthMM: 420,
    volumeL: 15.5,
    nominalDeltaT50KW: 0.704,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 535,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-950-06",
    rangeType: "Rococo II",
    model: "Rococo II 950mm",
    depthMM: 205,
    heightMM: 950,
    widthMM: 498,
    volumeL: 18.6,
    nominalDeltaT50KW: 0.845,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 615,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-950-07",
    rangeType: "Rococo II",
    model: "Rococo II 950mm",
    depthMM: 205,
    heightMM: 950,
    widthMM: 576,
    volumeL: 21.7,
    nominalDeltaT50KW: 0.986,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 695,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-950-08",
    rangeType: "Rococo II",
    model: "Rococo II 950mm",
    depthMM: 205,
    heightMM: 950,
    widthMM: 654,
    volumeL: 24.8,
    nominalDeltaT50KW: 1.126,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 845,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-950-09",
    rangeType: "Rococo II",
    model: "Rococo II 950mm",
    depthMM: 205,
    heightMM: 950,
    widthMM: 732,
    volumeL: 27.9,
    nominalDeltaT50KW: 1.267,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 925,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-950-10",
    rangeType: "Rococo II",
    model: "Rococo II 950mm",
    depthMM: 205,
    heightMM: 950,
    widthMM: 810,
    volumeL: 31,
    nominalDeltaT50KW: 1.408,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 1005,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-950-11",
    rangeType: "Rococo II",
    model: "Rococo II 950mm",
    depthMM: 205,
    heightMM: 950,
    widthMM: 888,
    volumeL: 34.1,
    nominalDeltaT50KW: 1.549,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 1085,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-950-12",
    rangeType: "Rococo II",
    model: "Rococo II 950mm",
    depthMM: 205,
    heightMM: 950,
    widthMM: 966,
    volumeL: 37.2,
    nominalDeltaT50KW: 1.69,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 1165,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-950-13",
    rangeType: "Rococo II",
    model: "Rococo II 950mm",
    depthMM: 205,
    heightMM: 950,
    widthMM: 1044,
    volumeL: 40.3,
    nominalDeltaT50KW: 1.83,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 1245,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-950-14",
    rangeType: "Rococo II",
    model: "Rococo II 950mm",
    depthMM: 205,
    heightMM: 950,
    widthMM: 1122,
    volumeL: 43.4,
    nominalDeltaT50KW: 1.971,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 1325,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-950-15",
    rangeType: "Rococo II",
    model: "Rococo II 950mm",
    depthMM: 205,
    heightMM: 950,
    widthMM: 1200,
    volumeL: 46.5,
    nominalDeltaT50KW: 2.112,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 1405,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-950-16",
    rangeType: "Rococo II",
    model: "Rococo II 950mm",
    depthMM: 205,
    heightMM: 950,
    widthMM: 1278,
    volumeL: 49.6,
    nominalDeltaT50KW: 2.253,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 1485,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-950-17",
    rangeType: "Rococo II",
    model: "Rococo II 950mm",
    depthMM: 205,
    heightMM: 950,
    widthMM: 1356,
    volumeL: 52.7,
    nominalDeltaT50KW: 2.394,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 1565,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-950-18",
    rangeType: "Rococo II",
    model: "Rococo II 950mm",
    depthMM: 205,
    heightMM: 950,
    widthMM: 1434,
    volumeL: 55.8,
    nominalDeltaT50KW: 2.534,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 1645,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-950-19",
    rangeType: "Rococo II",
    model: "Rococo II 950mm",
    depthMM: 205,
    heightMM: 950,
    widthMM: 1512,
    volumeL: 58.9,
    nominalDeltaT50KW: 2.675,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 1725,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-950-20",
    rangeType: "Rococo II",
    model: "Rococo II 950mm",
    depthMM: 205,
    heightMM: 950,
    widthMM: 1590,
    volumeL: 62,
    nominalDeltaT50KW: 2.816,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 1805,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-950-21",
    rangeType: "Rococo II",
    model: "Rococo II 950mm",
    depthMM: 205,
    heightMM: 950,
    widthMM: 1668,
    volumeL: 65.1,
    nominalDeltaT50KW: 2.957,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 1885,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-950-22",
    rangeType: "Rococo II",
    model: "Rococo II 950mm",
    depthMM: 205,
    heightMM: 950,
    widthMM: 1746,
    volumeL: 68.2,
    nominalDeltaT50KW: 3.098,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 1965,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-950-23",
    rangeType: "Rococo II",
    model: "Rococo II 950mm",
    depthMM: 205,
    heightMM: 950,
    widthMM: 1824,
    volumeL: 71.3,
    nominalDeltaT50KW: 3.238,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 2045,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-950-24",
    rangeType: "Rococo II",
    model: "Rococo II 950mm",
    depthMM: 205,
    heightMM: 950,
    widthMM: 1902,
    volumeL: 74.4,
    nominalDeltaT50KW: 3.379,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 2125,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-950-25",
    rangeType: "Rococo II",
    model: "Rococo II 950mm",
    depthMM: 205,
    heightMM: 950,
    widthMM: 1980,
    volumeL: 77.5,
    nominalDeltaT50KW: 3.52,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 2205,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-950-26",
    rangeType: "Rococo II",
    model: "Rococo II 950mm",
    depthMM: 205,
    heightMM: 950,
    widthMM: 2058,
    volumeL: 80.6,
    nominalDeltaT50KW: 3.661,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 2285,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-950-27",
    rangeType: "Rococo II",
    model: "Rococo II 950mm",
    depthMM: 205,
    heightMM: 950,
    widthMM: 2136,
    volumeL: 83.7,
    nominalDeltaT50KW: 3.802,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 2365,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-950-28",
    rangeType: "Rococo II",
    model: "Rococo II 950mm",
    depthMM: 205,
    heightMM: 950,
    widthMM: 2214,
    volumeL: 86.8,
    nominalDeltaT50KW: 3.942,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 2445,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-950-29",
    rangeType: "Rococo II",
    model: "Rococo II 950mm",
    depthMM: 205,
    heightMM: 950,
    widthMM: 2292,
    volumeL: 89.9,
    nominalDeltaT50KW: 4.083,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 2525,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-950-30",
    rangeType: "Rococo II",
    model: "Rococo II 950mm",
    depthMM: 205,
    heightMM: 950,
    widthMM: 2370,
    volumeL: 93,
    nominalDeltaT50KW: 4.224,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 2605,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-950-31",
    rangeType: "Rococo II",
    model: "Rococo II 950mm",
    depthMM: 205,
    heightMM: 950,
    widthMM: 2448,
    volumeL: 96.1,
    nominalDeltaT50KW: 4.365,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 2685,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-950-32",
    rangeType: "Rococo II",
    model: "Rococo II 950mm",
    depthMM: 205,
    heightMM: 950,
    widthMM: 2526,
    volumeL: 99.2,
    nominalDeltaT50KW: 4.506,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 2765,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-950-33",
    rangeType: "Rococo II",
    model: "Rococo II 950mm",
    depthMM: 205,
    heightMM: 950,
    widthMM: 2604,
    volumeL: 102.3,
    nominalDeltaT50KW: 4.646,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 2845,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-950-34",
    rangeType: "Rococo II",
    model: "Rococo II 950mm",
    depthMM: 205,
    heightMM: 950,
    widthMM: 2682,
    volumeL: 105.4,
    nominalDeltaT50KW: 4.787,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 2925,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-950-35",
    rangeType: "Rococo II",
    model: "Rococo II 950mm",
    depthMM: 205,
    heightMM: 950,
    widthMM: 2760,
    volumeL: 108.5,
    nominalDeltaT50KW: 4.928,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 3005,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-950-36",
    rangeType: "Rococo II",
    model: "Rococo II 950mm",
    depthMM: 205,
    heightMM: 950,
    widthMM: 2838,
    volumeL: 111.6,
    nominalDeltaT50KW: 5.069,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 3085,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-950-37",
    rangeType: "Rococo II",
    model: "Rococo II 950mm",
    depthMM: 205,
    heightMM: 950,
    widthMM: 2916,
    volumeL: 114.7,
    nominalDeltaT50KW: 5.21,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 3165,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-950-38",
    rangeType: "Rococo II",
    model: "Rococo II 950mm",
    depthMM: 205,
    heightMM: 950,
    widthMM: 2994,
    volumeL: 117.8,
    nominalDeltaT50KW: 5.35,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 3245,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-950-39",
    rangeType: "Rococo II",
    model: "Rococo II 950mm",
    depthMM: 205,
    heightMM: 950,
    widthMM: 3072,
    volumeL: 120.9,
    nominalDeltaT50KW: 5.491,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 3325,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC2-950-40",
    rangeType: "Rococo II",
    model: "Rococo II 950mm",
    depthMM: 205,
    heightMM: 950,
    widthMM: 3150,
    volumeL: 124,
    nominalDeltaT50KW: 5.632,
    kvValue: 20,
    nCoefficient: 1.24,
    price: {
      value: 3405,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-470-04",
    rangeType: "Rococo III",
    model: "Rococo III 470mm",
    depthMM: 256,
    heightMM: 470,
    widthMM: 342,
    volumeL: 6,
    nominalDeltaT50KW: 0.333,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 371,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-470-05",
    rangeType: "Rococo III",
    model: "Rococo III 470mm",
    depthMM: 256,
    heightMM: 470,
    widthMM: 420,
    volumeL: 7.5,
    nominalDeltaT50KW: 0.416,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 430,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-470-06",
    rangeType: "Rococo III",
    model: "Rococo III 470mm",
    depthMM: 256,
    heightMM: 470,
    widthMM: 498,
    volumeL: 9,
    nominalDeltaT50KW: 0.499,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 489,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-470-07",
    rangeType: "Rococo III",
    model: "Rococo III 470mm",
    depthMM: 256,
    heightMM: 470,
    widthMM: 576,
    volumeL: 10.5,
    nominalDeltaT50KW: 0.582,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 548,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-470-08",
    rangeType: "Rococo III",
    model: "Rococo III 470mm",
    depthMM: 256,
    heightMM: 470,
    widthMM: 654,
    volumeL: 12,
    nominalDeltaT50KW: 0.666,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 607,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-470-09",
    rangeType: "Rococo III",
    model: "Rococo III 470mm",
    depthMM: 256,
    heightMM: 470,
    widthMM: 732,
    volumeL: 13.5,
    nominalDeltaT50KW: 0.749,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 666,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-470-10",
    rangeType: "Rococo III",
    model: "Rococo III 470mm",
    depthMM: 256,
    heightMM: 470,
    widthMM: 810,
    volumeL: 15,
    nominalDeltaT50KW: 0.832,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 725,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-470-11",
    rangeType: "Rococo III",
    model: "Rococo III 470mm",
    depthMM: 256,
    heightMM: 470,
    widthMM: 888,
    volumeL: 16.5,
    nominalDeltaT50KW: 0.915,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 784,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-470-12",
    rangeType: "Rococo III",
    model: "Rococo III 470mm",
    depthMM: 256,
    heightMM: 470,
    widthMM: 966,
    volumeL: 18,
    nominalDeltaT50KW: 0.998,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 843,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-470-13",
    rangeType: "Rococo III",
    model: "Rococo III 470mm",
    depthMM: 256,
    heightMM: 470,
    widthMM: 1044,
    volumeL: 19.5,
    nominalDeltaT50KW: 1.082,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 902,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-470-14",
    rangeType: "Rococo III",
    model: "Rococo III 470mm",
    depthMM: 256,
    heightMM: 470,
    widthMM: 1122,
    volumeL: 21,
    nominalDeltaT50KW: 1.165,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 1031,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-470-15",
    rangeType: "Rococo III",
    model: "Rococo III 470mm",
    depthMM: 256,
    heightMM: 470,
    widthMM: 1200,
    volumeL: 22.5,
    nominalDeltaT50KW: 1.248,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 1090,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-470-16",
    rangeType: "Rococo III",
    model: "Rococo III 470mm",
    depthMM: 256,
    heightMM: 470,
    widthMM: 1278,
    volumeL: 24,
    nominalDeltaT50KW: 1.331,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 1149,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-470-17",
    rangeType: "Rococo III",
    model: "Rococo III 470mm",
    depthMM: 256,
    heightMM: 470,
    widthMM: 1356,
    volumeL: 25.5,
    nominalDeltaT50KW: 1.414,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 1208,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-470-18",
    rangeType: "Rococo III",
    model: "Rococo III 470mm",
    depthMM: 256,
    heightMM: 470,
    widthMM: 1434,
    volumeL: 27,
    nominalDeltaT50KW: 1.498,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 1267,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-470-19",
    rangeType: "Rococo III",
    model: "Rococo III 470mm",
    depthMM: 256,
    heightMM: 470,
    widthMM: 1512,
    volumeL: 28.5,
    nominalDeltaT50KW: 1.581,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 1326,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-470-20",
    rangeType: "Rococo III",
    model: "Rococo III 470mm",
    depthMM: 256,
    heightMM: 470,
    widthMM: 1590,
    volumeL: 30,
    nominalDeltaT50KW: 1.664,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 1385,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-470-21",
    rangeType: "Rococo III",
    model: "Rococo III 470mm",
    depthMM: 256,
    heightMM: 470,
    widthMM: 1668,
    volumeL: 31.5,
    nominalDeltaT50KW: 1.747,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 1444,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-470-22",
    rangeType: "Rococo III",
    model: "Rococo III 470mm",
    depthMM: 256,
    heightMM: 470,
    widthMM: 1746,
    volumeL: 33,
    nominalDeltaT50KW: 1.83,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 1503,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-470-23",
    rangeType: "Rococo III",
    model: "Rococo III 470mm",
    depthMM: 256,
    heightMM: 470,
    widthMM: 1824,
    volumeL: 34.5,
    nominalDeltaT50KW: 1.914,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 1562,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-470-24",
    rangeType: "Rococo III",
    model: "Rococo III 470mm",
    depthMM: 256,
    heightMM: 470,
    widthMM: 1902,
    volumeL: 36,
    nominalDeltaT50KW: 1.997,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 1621,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-470-25",
    rangeType: "Rococo III",
    model: "Rococo III 470mm",
    depthMM: 256,
    heightMM: 470,
    widthMM: 1980,
    volumeL: 37.5,
    nominalDeltaT50KW: 2.08,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 1680,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-470-26",
    rangeType: "Rococo III",
    model: "Rococo III 470mm",
    depthMM: 256,
    heightMM: 470,
    widthMM: 2058,
    volumeL: 39,
    nominalDeltaT50KW: 2.163,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 1739,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-470-27",
    rangeType: "Rococo III",
    model: "Rococo III 470mm",
    depthMM: 256,
    heightMM: 470,
    widthMM: 2136,
    volumeL: 40.5,
    nominalDeltaT50KW: 2.246,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 1798,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-470-28",
    rangeType: "Rococo III",
    model: "Rococo III 470mm",
    depthMM: 256,
    heightMM: 470,
    widthMM: 2214,
    volumeL: 42,
    nominalDeltaT50KW: 2.33,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 1857,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-470-29",
    rangeType: "Rococo III",
    model: "Rococo III 470mm",
    depthMM: 256,
    heightMM: 470,
    widthMM: 2292,
    volumeL: 43.5,
    nominalDeltaT50KW: 2.413,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 1916,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-470-30",
    rangeType: "Rococo III",
    model: "Rococo III 470mm",
    depthMM: 256,
    heightMM: 470,
    widthMM: 2370,
    volumeL: 45,
    nominalDeltaT50KW: 2.496,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 1975,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-470-31",
    rangeType: "Rococo III",
    model: "Rococo III 470mm",
    depthMM: 256,
    heightMM: 470,
    widthMM: 2448,
    volumeL: 46.5,
    nominalDeltaT50KW: 2.579,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 2034,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-470-32",
    rangeType: "Rococo III",
    model: "Rococo III 470mm",
    depthMM: 256,
    heightMM: 470,
    widthMM: 2526,
    volumeL: 48,
    nominalDeltaT50KW: 2.662,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 2093,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-470-33",
    rangeType: "Rococo III",
    model: "Rococo III 470mm",
    depthMM: 256,
    heightMM: 470,
    widthMM: 2604,
    volumeL: 49.5,
    nominalDeltaT50KW: 2.746,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 2152,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-470-34",
    rangeType: "Rococo III",
    model: "Rococo III 470mm",
    depthMM: 256,
    heightMM: 470,
    widthMM: 2682,
    volumeL: 51,
    nominalDeltaT50KW: 2.829,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 2211,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-470-35",
    rangeType: "Rococo III",
    model: "Rococo III 470mm",
    depthMM: 256,
    heightMM: 470,
    widthMM: 2760,
    volumeL: 52.5,
    nominalDeltaT50KW: 2.912,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 2270,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-470-36",
    rangeType: "Rococo III",
    model: "Rococo III 470mm",
    depthMM: 256,
    heightMM: 470,
    widthMM: 2838,
    volumeL: 54,
    nominalDeltaT50KW: 2.995,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 2329,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-470-37",
    rangeType: "Rococo III",
    model: "Rococo III 470mm",
    depthMM: 256,
    heightMM: 470,
    widthMM: 2916,
    volumeL: 55.5,
    nominalDeltaT50KW: 3.078,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 2388,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-470-38",
    rangeType: "Rococo III",
    model: "Rococo III 470mm",
    depthMM: 256,
    heightMM: 470,
    widthMM: 2994,
    volumeL: 57,
    nominalDeltaT50KW: 3.162,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 2447,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-470-39",
    rangeType: "Rococo III",
    model: "Rococo III 470mm",
    depthMM: 256,
    heightMM: 470,
    widthMM: 3072,
    volumeL: 58.5,
    nominalDeltaT50KW: 3.245,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 2506,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-470-40",
    rangeType: "Rococo III",
    model: "Rococo III 470mm",
    depthMM: 256,
    heightMM: 470,
    widthMM: 3150,
    volumeL: 60,
    nominalDeltaT50KW: 3.328,
    kvValue: 20,
    nCoefficient: 1.2,
    price: {
      value: 2565,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-768-04",
    rangeType: "Rococo III",
    model: "Rococo III 768mm",
    depthMM: 256,
    heightMM: 768,
    widthMM: 338,
    volumeL: 11.2,
    nominalDeltaT50KW: 0.558,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 511,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-768-05",
    rangeType: "Rococo III",
    model: "Rococo III 768mm",
    depthMM: 256,
    heightMM: 768,
    widthMM: 415,
    volumeL: 14,
    nominalDeltaT50KW: 0.698,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 605,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-768-06",
    rangeType: "Rococo III",
    model: "Rococo III 768mm",
    depthMM: 256,
    heightMM: 768,
    widthMM: 492,
    volumeL: 16.8,
    nominalDeltaT50KW: 0.838,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 699,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-768-07",
    rangeType: "Rococo III",
    model: "Rococo III 768mm",
    depthMM: 256,
    heightMM: 768,
    widthMM: 569,
    volumeL: 19.6,
    nominalDeltaT50KW: 0.977,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 793,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-768-08",
    rangeType: "Rococo III",
    model: "Rococo III 768mm",
    depthMM: 256,
    heightMM: 768,
    widthMM: 646,
    volumeL: 22.4,
    nominalDeltaT50KW: 1.117,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 887,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-768-09",
    rangeType: "Rococo III",
    model: "Rococo III 768mm",
    depthMM: 256,
    heightMM: 768,
    widthMM: 723,
    volumeL: 25.2,
    nominalDeltaT50KW: 1.256,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1051,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-768-10",
    rangeType: "Rococo III",
    model: "Rococo III 768mm",
    depthMM: 256,
    heightMM: 768,
    widthMM: 800,
    volumeL: 28,
    nominalDeltaT50KW: 1.396,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1145,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-768-11",
    rangeType: "Rococo III",
    model: "Rococo III 768mm",
    depthMM: 256,
    heightMM: 768,
    widthMM: 877,
    volumeL: 30.8,
    nominalDeltaT50KW: 1.536,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1239,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-768-12",
    rangeType: "Rococo III",
    model: "Rococo III 768mm",
    depthMM: 256,
    heightMM: 768,
    widthMM: 954,
    volumeL: 33.6,
    nominalDeltaT50KW: 1.675,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1333,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-768-13",
    rangeType: "Rococo III",
    model: "Rococo III 768mm",
    depthMM: 256,
    heightMM: 768,
    widthMM: 1031,
    volumeL: 36.4,
    nominalDeltaT50KW: 1.815,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1427,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-768-14",
    rangeType: "Rococo III",
    model: "Rococo III 768mm",
    depthMM: 256,
    heightMM: 768,
    widthMM: 1108,
    volumeL: 39.2,
    nominalDeltaT50KW: 1.954,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1521,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-768-15",
    rangeType: "Rococo III",
    model: "Rococo III 768mm",
    depthMM: 256,
    heightMM: 768,
    widthMM: 1185,
    volumeL: 42,
    nominalDeltaT50KW: 2.094,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1615,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-768-16",
    rangeType: "Rococo III",
    model: "Rococo III 768mm",
    depthMM: 256,
    heightMM: 768,
    widthMM: 1262,
    volumeL: 44.8,
    nominalDeltaT50KW: 2.234,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1709,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-768-17",
    rangeType: "Rococo III",
    model: "Rococo III 768mm",
    depthMM: 256,
    heightMM: 768,
    widthMM: 1339,
    volumeL: 47.6,
    nominalDeltaT50KW: 2.373,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1803,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-768-18",
    rangeType: "Rococo III",
    model: "Rococo III 768mm",
    depthMM: 256,
    heightMM: 768,
    widthMM: 1416,
    volumeL: 50.4,
    nominalDeltaT50KW: 2.513,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1897,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-768-19",
    rangeType: "Rococo III",
    model: "Rococo III 768mm",
    depthMM: 256,
    heightMM: 768,
    widthMM: 1493,
    volumeL: 53.2,
    nominalDeltaT50KW: 2.652,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 1991,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-768-20",
    rangeType: "Rococo III",
    model: "Rococo III 768mm",
    depthMM: 256,
    heightMM: 768,
    widthMM: 1570,
    volumeL: 56,
    nominalDeltaT50KW: 2.792,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 2085,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-768-21",
    rangeType: "Rococo III",
    model: "Rococo III 768mm",
    depthMM: 256,
    heightMM: 768,
    widthMM: 1647,
    volumeL: 58.8,
    nominalDeltaT50KW: 2.932,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 2179,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-768-22",
    rangeType: "Rococo III",
    model: "Rococo III 768mm",
    depthMM: 256,
    heightMM: 768,
    widthMM: 1724,
    volumeL: 61.6,
    nominalDeltaT50KW: 3.071,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 2273,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-768-23",
    rangeType: "Rococo III",
    model: "Rococo III 768mm",
    depthMM: 256,
    heightMM: 768,
    widthMM: 1801,
    volumeL: 64.4,
    nominalDeltaT50KW: 3.211,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 2367,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-768-24",
    rangeType: "Rococo III",
    model: "Rococo III 768mm",
    depthMM: 256,
    heightMM: 768,
    widthMM: 1878,
    volumeL: 67.2,
    nominalDeltaT50KW: 3.35,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 2461,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-768-25",
    rangeType: "Rococo III",
    model: "Rococo III 768mm",
    depthMM: 256,
    heightMM: 768,
    widthMM: 1955,
    volumeL: 70,
    nominalDeltaT50KW: 3.49,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 2555,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-768-26",
    rangeType: "Rococo III",
    model: "Rococo III 768mm",
    depthMM: 256,
    heightMM: 768,
    widthMM: 2032,
    volumeL: 72.8,
    nominalDeltaT50KW: 3.63,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 2649,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-768-27",
    rangeType: "Rococo III",
    model: "Rococo III 768mm",
    depthMM: 256,
    heightMM: 768,
    widthMM: 2109,
    volumeL: 75.6,
    nominalDeltaT50KW: 3.769,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 2743,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-768-28",
    rangeType: "Rococo III",
    model: "Rococo III 768mm",
    depthMM: 256,
    heightMM: 768,
    widthMM: 2186,
    volumeL: 78.4,
    nominalDeltaT50KW: 3.909,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 2837,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-768-29",
    rangeType: "Rococo III",
    model: "Rococo III 768mm",
    depthMM: 256,
    heightMM: 768,
    widthMM: 2263,
    volumeL: 81.2,
    nominalDeltaT50KW: 4.048,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 2931,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-768-30",
    rangeType: "Rococo III",
    model: "Rococo III 768mm",
    depthMM: 256,
    heightMM: 768,
    widthMM: 2340,
    volumeL: 84,
    nominalDeltaT50KW: 4.188,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 3025,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-768-31",
    rangeType: "Rococo III",
    model: "Rococo III 768mm",
    depthMM: 256,
    heightMM: 768,
    widthMM: 2417,
    volumeL: 86.8,
    nominalDeltaT50KW: 4.328,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 3119,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-768-32",
    rangeType: "Rococo III",
    model: "Rococo III 768mm",
    depthMM: 256,
    heightMM: 768,
    widthMM: 2494,
    volumeL: 89.6,
    nominalDeltaT50KW: 4.467,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 3213,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-768-33",
    rangeType: "Rococo III",
    model: "Rococo III 768mm",
    depthMM: 256,
    heightMM: 768,
    widthMM: 2571,
    volumeL: 92.4,
    nominalDeltaT50KW: 4.607,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 3307,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-768-34",
    rangeType: "Rococo III",
    model: "Rococo III 768mm",
    depthMM: 256,
    heightMM: 768,
    widthMM: 2648,
    volumeL: 95.2,
    nominalDeltaT50KW: 4.746,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 3401,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-768-35",
    rangeType: "Rococo III",
    model: "Rococo III 768mm",
    depthMM: 256,
    heightMM: 768,
    widthMM: 2725,
    volumeL: 98,
    nominalDeltaT50KW: 4.886,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 3495,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-768-36",
    rangeType: "Rococo III",
    model: "Rococo III 768mm",
    depthMM: 256,
    heightMM: 768,
    widthMM: 2802,
    volumeL: 100.8,
    nominalDeltaT50KW: 5.026,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 3589,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-768-37",
    rangeType: "Rococo III",
    model: "Rococo III 768mm",
    depthMM: 256,
    heightMM: 768,
    widthMM: 2879,
    volumeL: 103.6,
    nominalDeltaT50KW: 5.165,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 3683,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-768-38",
    rangeType: "Rococo III",
    model: "Rococo III 768mm",
    depthMM: 256,
    heightMM: 768,
    widthMM: 2956,
    volumeL: 106.4,
    nominalDeltaT50KW: 5.305,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 3777,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-768-39",
    rangeType: "Rococo III",
    model: "Rococo III 768mm",
    depthMM: 256,
    heightMM: 768,
    widthMM: 3033,
    volumeL: 109.2,
    nominalDeltaT50KW: 5.444,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 3871,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-768-40",
    rangeType: "Rococo III",
    model: "Rococo III 768mm",
    depthMM: 256,
    heightMM: 768,
    widthMM: 3110,
    volumeL: 112,
    nominalDeltaT50KW: 5.584,
    kvValue: 20,
    nCoefficient: 1.26,
    price: {
      value: 3965,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-970-04",
    rangeType: "Rococo III",
    model: "Rococo III 970mm",
    depthMM: 256,
    heightMM: 970,
    widthMM: 338,
    volumeL: 14,
    nominalDeltaT50KW: 0.695,
    kvValue: 20,
    nCoefficient: 1.21,
    price: {
      value: 579,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-970-05",
    rangeType: "Rococo III",
    model: "Rococo III 970mm",
    depthMM: 256,
    heightMM: 970,
    widthMM: 415,
    volumeL: 17.5,
    nominalDeltaT50KW: 0.869,
    kvValue: 20,
    nCoefficient: 1.21,
    price: {
      value: 690,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-970-06",
    rangeType: "Rococo III",
    model: "Rococo III 970mm",
    depthMM: 256,
    heightMM: 970,
    widthMM: 492,
    volumeL: 21,
    nominalDeltaT50KW: 1.043,
    kvValue: 20,
    nCoefficient: 1.21,
    price: {
      value: 801,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-970-07",
    rangeType: "Rococo III",
    model: "Rococo III 970mm",
    depthMM: 256,
    heightMM: 970,
    widthMM: 569,
    volumeL: 24.5,
    nominalDeltaT50KW: 1.217,
    kvValue: 20,
    nCoefficient: 1.21,
    price: {
      value: 912,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-970-08",
    rangeType: "Rococo III",
    model: "Rococo III 970mm",
    depthMM: 256,
    heightMM: 970,
    widthMM: 646,
    volumeL: 28,
    nominalDeltaT50KW: 1.39,
    kvValue: 20,
    nCoefficient: 1.21,
    price: {
      value: 1093,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-970-09",
    rangeType: "Rococo III",
    model: "Rococo III 970mm",
    depthMM: 256,
    heightMM: 970,
    widthMM: 723,
    volumeL: 31.5,
    nominalDeltaT50KW: 1.564,
    kvValue: 20,
    nCoefficient: 1.21,
    price: {
      value: 1204,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-970-10",
    rangeType: "Rococo III",
    model: "Rococo III 970mm",
    depthMM: 256,
    heightMM: 970,
    widthMM: 800,
    volumeL: 35,
    nominalDeltaT50KW: 1.738,
    kvValue: 20,
    nCoefficient: 1.21,
    price: {
      value: 1315,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-970-11",
    rangeType: "Rococo III",
    model: "Rococo III 970mm",
    depthMM: 256,
    heightMM: 970,
    widthMM: 877,
    volumeL: 38.5,
    nominalDeltaT50KW: 1.912,
    kvValue: 20,
    nCoefficient: 1.21,
    price: {
      value: 1426,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-970-12",
    rangeType: "Rococo III",
    model: "Rococo III 970mm",
    depthMM: 256,
    heightMM: 970,
    widthMM: 954,
    volumeL: 42,
    nominalDeltaT50KW: 2.086,
    kvValue: 20,
    nCoefficient: 1.21,
    price: {
      value: 1537,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-970-13",
    rangeType: "Rococo III",
    model: "Rococo III 970mm",
    depthMM: 256,
    heightMM: 970,
    widthMM: 1031,
    volumeL: 45.5,
    nominalDeltaT50KW: 2.259,
    kvValue: 20,
    nCoefficient: 1.21,
    price: {
      value: 1648,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-970-14",
    rangeType: "Rococo III",
    model: "Rococo III 970mm",
    depthMM: 256,
    heightMM: 970,
    widthMM: 1108,
    volumeL: 49,
    nominalDeltaT50KW: 2.433,
    kvValue: 20,
    nCoefficient: 1.21,
    price: {
      value: 1759,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-970-15",
    rangeType: "Rococo III",
    model: "Rococo III 970mm",
    depthMM: 256,
    heightMM: 970,
    widthMM: 1185,
    volumeL: 52.5,
    nominalDeltaT50KW: 2.607,
    kvValue: 20,
    nCoefficient: 1.21,
    price: {
      value: 1870,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-970-16",
    rangeType: "Rococo III",
    model: "Rococo III 970mm",
    depthMM: 256,
    heightMM: 970,
    widthMM: 1262,
    volumeL: 56,
    nominalDeltaT50KW: 2.781,
    kvValue: 20,
    nCoefficient: 1.21,
    price: {
      value: 1981,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-970-17",
    rangeType: "Rococo III",
    model: "Rococo III 970mm",
    depthMM: 256,
    heightMM: 970,
    widthMM: 1339,
    volumeL: 59.5,
    nominalDeltaT50KW: 2.955,
    kvValue: 20,
    nCoefficient: 1.21,
    price: {
      value: 2092,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-970-18",
    rangeType: "Rococo III",
    model: "Rococo III 970mm",
    depthMM: 256,
    heightMM: 970,
    widthMM: 1416,
    volumeL: 63,
    nominalDeltaT50KW: 3.128,
    kvValue: 20,
    nCoefficient: 1.21,
    price: {
      value: 2203,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-970-19",
    rangeType: "Rococo III",
    model: "Rococo III 970mm",
    depthMM: 256,
    heightMM: 970,
    widthMM: 1493,
    volumeL: 66.5,
    nominalDeltaT50KW: 3.302,
    kvValue: 20,
    nCoefficient: 1.21,
    price: {
      value: 2314,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-970-20",
    rangeType: "Rococo III",
    model: "Rococo III 970mm",
    depthMM: 256,
    heightMM: 970,
    widthMM: 1570,
    volumeL: 70,
    nominalDeltaT50KW: 3.476,
    kvValue: 20,
    nCoefficient: 1.21,
    price: {
      value: 2425,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-970-21",
    rangeType: "Rococo III",
    model: "Rococo III 970mm",
    depthMM: 256,
    heightMM: 970,
    widthMM: 1647,
    volumeL: 73.5,
    nominalDeltaT50KW: 3.65,
    kvValue: 20,
    nCoefficient: 1.21,
    price: {
      value: 2536,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-970-22",
    rangeType: "Rococo III",
    model: "Rococo III 970mm",
    depthMM: 256,
    heightMM: 970,
    widthMM: 1724,
    volumeL: 77,
    nominalDeltaT50KW: 3.824,
    kvValue: 20,
    nCoefficient: 1.21,
    price: {
      value: 2647,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-970-23",
    rangeType: "Rococo III",
    model: "Rococo III 970mm",
    depthMM: 256,
    heightMM: 970,
    widthMM: 1801,
    volumeL: 80.5,
    nominalDeltaT50KW: 3.997,
    kvValue: 20,
    nCoefficient: 1.21,
    price: {
      value: 2758,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-970-24",
    rangeType: "Rococo III",
    model: "Rococo III 970mm",
    depthMM: 256,
    heightMM: 970,
    widthMM: 1878,
    volumeL: 84,
    nominalDeltaT50KW: 4.171,
    kvValue: 20,
    nCoefficient: 1.21,
    price: {
      value: 2869,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-970-25",
    rangeType: "Rococo III",
    model: "Rococo III 970mm",
    depthMM: 256,
    heightMM: 970,
    widthMM: 1955,
    volumeL: 87.5,
    nominalDeltaT50KW: 4.345,
    kvValue: 20,
    nCoefficient: 1.21,
    price: {
      value: 2980,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-970-26",
    rangeType: "Rococo III",
    model: "Rococo III 970mm",
    depthMM: 256,
    heightMM: 970,
    widthMM: 2032,
    volumeL: 91,
    nominalDeltaT50KW: 4.519,
    kvValue: 20,
    nCoefficient: 1.21,
    price: {
      value: 3091,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-970-27",
    rangeType: "Rococo III",
    model: "Rococo III 970mm",
    depthMM: 256,
    heightMM: 970,
    widthMM: 2109,
    volumeL: 94.5,
    nominalDeltaT50KW: 4.693,
    kvValue: 20,
    nCoefficient: 1.21,
    price: {
      value: 3202,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-970-28",
    rangeType: "Rococo III",
    model: "Rococo III 970mm",
    depthMM: 256,
    heightMM: 970,
    widthMM: 2186,
    volumeL: 98,
    nominalDeltaT50KW: 4.866,
    kvValue: 20,
    nCoefficient: 1.21,
    price: {
      value: 3313,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-970-29",
    rangeType: "Rococo III",
    model: "Rococo III 970mm",
    depthMM: 256,
    heightMM: 970,
    widthMM: 2263,
    volumeL: 101.5,
    nominalDeltaT50KW: 5.04,
    kvValue: 20,
    nCoefficient: 1.21,
    price: {
      value: 3424,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-970-30",
    rangeType: "Rococo III",
    model: "Rococo III 970mm",
    depthMM: 256,
    heightMM: 970,
    widthMM: 2340,
    volumeL: 105,
    nominalDeltaT50KW: 5.214,
    kvValue: 20,
    nCoefficient: 1.21,
    price: {
      value: 3535,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-970-31",
    rangeType: "Rococo III",
    model: "Rococo III 970mm",
    depthMM: 256,
    heightMM: 970,
    widthMM: 2417,
    volumeL: 108.5,
    nominalDeltaT50KW: 5.388,
    kvValue: 20,
    nCoefficient: 1.21,
    price: {
      value: 3646,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-970-32",
    rangeType: "Rococo III",
    model: "Rococo III 970mm",
    depthMM: 256,
    heightMM: 970,
    widthMM: 2494,
    volumeL: 112,
    nominalDeltaT50KW: 5.562,
    kvValue: 20,
    nCoefficient: 1.21,
    price: {
      value: 3757,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-970-33",
    rangeType: "Rococo III",
    model: "Rococo III 970mm",
    depthMM: 256,
    heightMM: 970,
    widthMM: 2571,
    volumeL: 115.5,
    nominalDeltaT50KW: 5.735,
    kvValue: 20,
    nCoefficient: 1.21,
    price: {
      value: 3868,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-970-34",
    rangeType: "Rococo III",
    model: "Rococo III 970mm",
    depthMM: 256,
    heightMM: 970,
    widthMM: 2648,
    volumeL: 119,
    nominalDeltaT50KW: 5.909,
    kvValue: 20,
    nCoefficient: 1.21,
    price: {
      value: 3979,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-970-35",
    rangeType: "Rococo III",
    model: "Rococo III 970mm",
    depthMM: 256,
    heightMM: 970,
    widthMM: 2725,
    volumeL: 122.5,
    nominalDeltaT50KW: 6.083,
    kvValue: 20,
    nCoefficient: 1.21,
    price: {
      value: 4090,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-970-36",
    rangeType: "Rococo III",
    model: "Rococo III 970mm",
    depthMM: 256,
    heightMM: 970,
    widthMM: 2802,
    volumeL: 126,
    nominalDeltaT50KW: 6.257,
    kvValue: 20,
    nCoefficient: 1.21,
    price: {
      value: 4201,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-970-37",
    rangeType: "Rococo III",
    model: "Rococo III 970mm",
    depthMM: 256,
    heightMM: 970,
    widthMM: 2879,
    volumeL: 129.5,
    nominalDeltaT50KW: 6.431,
    kvValue: 20,
    nCoefficient: 1.21,
    price: {
      value: 4312,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-970-38",
    rangeType: "Rococo III",
    model: "Rococo III 970mm",
    depthMM: 256,
    heightMM: 970,
    widthMM: 2956,
    volumeL: 133,
    nominalDeltaT50KW: 6.604,
    kvValue: 20,
    nCoefficient: 1.21,
    price: {
      value: 4423,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-970-39",
    rangeType: "Rococo III",
    model: "Rococo III 970mm",
    depthMM: 256,
    heightMM: 970,
    widthMM: 3033,
    volumeL: 136.5,
    nominalDeltaT50KW: 6.778,
    kvValue: 20,
    nCoefficient: 1.21,
    price: {
      value: 4534,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-RC3-970-40",
    rangeType: "Rococo III",
    model: "Rococo III 970mm",
    depthMM: 256,
    heightMM: 970,
    widthMM: 3110,
    volumeL: 140,
    nominalDeltaT50KW: 6.952,
    kvValue: 20,
    nCoefficient: 1.21,
    price: {
      value: 4645,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-420-04",
    rangeType: "Vulcan III",
    model: "Vulcan III 420mm",
    depthMM: 256,
    heightMM: 420,
    widthMM: 272,
    volumeL: 2.8,
    nominalDeltaT50KW: 0.227,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 267,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-420-05",
    rangeType: "Vulcan III",
    model: "Vulcan III 420mm",
    depthMM: 256,
    heightMM: 420,
    widthMM: 333,
    volumeL: 3.5,
    nominalDeltaT50KW: 0.284,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 290,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-420-06",
    rangeType: "Vulcan III",
    model: "Vulcan III 420mm",
    depthMM: 256,
    heightMM: 420,
    widthMM: 393,
    volumeL: 4.2,
    nominalDeltaT50KW: 0.34,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 313,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-420-07",
    rangeType: "Vulcan III",
    model: "Vulcan III 420mm",
    depthMM: 256,
    heightMM: 420,
    widthMM: 454,
    volumeL: 4.9,
    nominalDeltaT50KW: 0.397,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 336,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-420-08",
    rangeType: "Vulcan III",
    model: "Vulcan III 420mm",
    depthMM: 256,
    heightMM: 420,
    widthMM: 514,
    volumeL: 5.6,
    nominalDeltaT50KW: 0.454,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 359,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-420-09",
    rangeType: "Vulcan III",
    model: "Vulcan III 420mm",
    depthMM: 256,
    heightMM: 420,
    widthMM: 575,
    volumeL: 6.3,
    nominalDeltaT50KW: 0.51,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 382,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-420-10",
    rangeType: "Vulcan III",
    model: "Vulcan III 420mm",
    depthMM: 256,
    heightMM: 420,
    widthMM: 635,
    volumeL: 7,
    nominalDeltaT50KW: 0.567,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 405,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-420-11",
    rangeType: "Vulcan III",
    model: "Vulcan III 420mm",
    depthMM: 256,
    heightMM: 420,
    widthMM: 696,
    volumeL: 7.7,
    nominalDeltaT50KW: 0.624,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 428,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-420-12",
    rangeType: "Vulcan III",
    model: "Vulcan III 420mm",
    depthMM: 256,
    heightMM: 420,
    widthMM: 756,
    volumeL: 8.4,
    nominalDeltaT50KW: 0.68,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 451,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-420-13",
    rangeType: "Vulcan III",
    model: "Vulcan III 420mm",
    depthMM: 256,
    heightMM: 420,
    widthMM: 817,
    volumeL: 9.1,
    nominalDeltaT50KW: 0.737,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 474,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-420-14",
    rangeType: "Vulcan III",
    model: "Vulcan III 420mm",
    depthMM: 256,
    heightMM: 420,
    widthMM: 877,
    volumeL: 9.8,
    nominalDeltaT50KW: 0.794,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 497,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-420-15",
    rangeType: "Vulcan III",
    model: "Vulcan III 420mm",
    depthMM: 256,
    heightMM: 420,
    widthMM: 938,
    volumeL: 10.5,
    nominalDeltaT50KW: 0.851,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 520,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-420-16",
    rangeType: "Vulcan III",
    model: "Vulcan III 420mm",
    depthMM: 256,
    heightMM: 420,
    widthMM: 998,
    volumeL: 11.2,
    nominalDeltaT50KW: 0.907,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 543,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-420-17",
    rangeType: "Vulcan III",
    model: "Vulcan III 420mm",
    depthMM: 256,
    heightMM: 420,
    widthMM: 1059,
    volumeL: 11.9,
    nominalDeltaT50KW: 0.964,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 566,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-420-18",
    rangeType: "Vulcan III",
    model: "Vulcan III 420mm",
    depthMM: 256,
    heightMM: 420,
    widthMM: 1119,
    volumeL: 12.6,
    nominalDeltaT50KW: 1.021,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 589,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-420-19",
    rangeType: "Vulcan III",
    model: "Vulcan III 420mm",
    depthMM: 256,
    heightMM: 420,
    widthMM: 1180,
    volumeL: 13.3,
    nominalDeltaT50KW: 1.077,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 742,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-420-20",
    rangeType: "Vulcan III",
    model: "Vulcan III 420mm",
    depthMM: 256,
    heightMM: 420,
    widthMM: 1240,
    volumeL: 14,
    nominalDeltaT50KW: 1.134,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 765,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-420-21",
    rangeType: "Vulcan III",
    model: "Vulcan III 420mm",
    depthMM: 256,
    heightMM: 420,
    widthMM: 1301,
    volumeL: 14.7,
    nominalDeltaT50KW: 1.191,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 788,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-420-22",
    rangeType: "Vulcan III",
    model: "Vulcan III 420mm",
    depthMM: 256,
    heightMM: 420,
    widthMM: 1361,
    volumeL: 15.4,
    nominalDeltaT50KW: 1.247,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 811,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-420-23",
    rangeType: "Vulcan III",
    model: "Vulcan III 420mm",
    depthMM: 256,
    heightMM: 420,
    widthMM: 1422,
    volumeL: 16.1,
    nominalDeltaT50KW: 1.304,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 834,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-420-24",
    rangeType: "Vulcan III",
    model: "Vulcan III 420mm",
    depthMM: 256,
    heightMM: 420,
    widthMM: 1482,
    volumeL: 16.8,
    nominalDeltaT50KW: 1.361,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 857,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-420-25",
    rangeType: "Vulcan III",
    model: "Vulcan III 420mm",
    depthMM: 256,
    heightMM: 420,
    widthMM: 1543,
    volumeL: 17.5,
    nominalDeltaT50KW: 1.418,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 880,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-420-26",
    rangeType: "Vulcan III",
    model: "Vulcan III 420mm",
    depthMM: 256,
    heightMM: 420,
    widthMM: 1603,
    volumeL: 18.2,
    nominalDeltaT50KW: 1.474,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 903,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-420-27",
    rangeType: "Vulcan III",
    model: "Vulcan III 420mm",
    depthMM: 256,
    heightMM: 420,
    widthMM: 1664,
    volumeL: 18.9,
    nominalDeltaT50KW: 1.531,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 926,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-420-28",
    rangeType: "Vulcan III",
    model: "Vulcan III 420mm",
    depthMM: 256,
    heightMM: 420,
    widthMM: 1724,
    volumeL: 19.6,
    nominalDeltaT50KW: 1.588,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 949,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-420-29",
    rangeType: "Vulcan III",
    model: "Vulcan III 420mm",
    depthMM: 256,
    heightMM: 420,
    widthMM: 1785,
    volumeL: 20.3,
    nominalDeltaT50KW: 1.644,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 972,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-420-30",
    rangeType: "Vulcan III",
    model: "Vulcan III 420mm",
    depthMM: 256,
    heightMM: 420,
    widthMM: 1845,
    volumeL: 21,
    nominalDeltaT50KW: 1.701,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 995,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-420-31",
    rangeType: "Vulcan III",
    model: "Vulcan III 420mm",
    depthMM: 256,
    heightMM: 420,
    widthMM: 1906,
    volumeL: 21.7,
    nominalDeltaT50KW: 1.758,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1018,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-420-32",
    rangeType: "Vulcan III",
    model: "Vulcan III 420mm",
    depthMM: 256,
    heightMM: 420,
    widthMM: 1966,
    volumeL: 22.4,
    nominalDeltaT50KW: 1.814,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1041,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-420-33",
    rangeType: "Vulcan III",
    model: "Vulcan III 420mm",
    depthMM: 256,
    heightMM: 420,
    widthMM: 2027,
    volumeL: 23.1,
    nominalDeltaT50KW: 1.871,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1064,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-420-34",
    rangeType: "Vulcan III",
    model: "Vulcan III 420mm",
    depthMM: 256,
    heightMM: 420,
    widthMM: 2087,
    volumeL: 23.8,
    nominalDeltaT50KW: 1.928,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1087,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-420-35",
    rangeType: "Vulcan III",
    model: "Vulcan III 420mm",
    depthMM: 256,
    heightMM: 420,
    widthMM: 2148,
    volumeL: 24.5,
    nominalDeltaT50KW: 1.985,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1110,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-420-36",
    rangeType: "Vulcan III",
    model: "Vulcan III 420mm",
    depthMM: 256,
    heightMM: 420,
    widthMM: 2208,
    volumeL: 25.2,
    nominalDeltaT50KW: 2.041,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1133,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-420-37",
    rangeType: "Vulcan III",
    model: "Vulcan III 420mm",
    depthMM: 256,
    heightMM: 420,
    widthMM: 2269,
    volumeL: 25.9,
    nominalDeltaT50KW: 2.098,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1156,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-420-38",
    rangeType: "Vulcan III",
    model: "Vulcan III 420mm",
    depthMM: 256,
    heightMM: 420,
    widthMM: 2329,
    volumeL: 26.6,
    nominalDeltaT50KW: 2.155,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1179,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-420-39",
    rangeType: "Vulcan III",
    model: "Vulcan III 420mm",
    depthMM: 256,
    heightMM: 420,
    widthMM: 2390,
    volumeL: 27.3,
    nominalDeltaT50KW: 2.211,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1202,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-420-40",
    rangeType: "Vulcan III",
    model: "Vulcan III 420mm",
    depthMM: 256,
    heightMM: 420,
    widthMM: 2450,
    volumeL: 28,
    nominalDeltaT50KW: 2.268,
    kvValue: 20,
    nCoefficient: 1.3,
    price: {
      value: 1225,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-690-04",
    rangeType: "Vulcan III",
    model: "Vulcan III 690mm",
    depthMM: 256,
    heightMM: 690,
    widthMM: 272,
    volumeL: 3,
    nominalDeltaT50KW: 0.338,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 303,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-690-05",
    rangeType: "Vulcan III",
    model: "Vulcan III 690mm",
    depthMM: 256,
    heightMM: 690,
    widthMM: 333,
    volumeL: 3.75,
    nominalDeltaT50KW: 0.423,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 335,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-690-06",
    rangeType: "Vulcan III",
    model: "Vulcan III 690mm",
    depthMM: 256,
    heightMM: 690,
    widthMM: 393,
    volumeL: 4.5,
    nominalDeltaT50KW: 0.507,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 367,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-690-07",
    rangeType: "Vulcan III",
    model: "Vulcan III 690mm",
    depthMM: 256,
    heightMM: 690,
    widthMM: 454,
    volumeL: 5.25,
    nominalDeltaT50KW: 0.592,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 399,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-690-08",
    rangeType: "Vulcan III",
    model: "Vulcan III 690mm",
    depthMM: 256,
    heightMM: 690,
    widthMM: 514,
    volumeL: 6,
    nominalDeltaT50KW: 0.676,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 431,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-690-09",
    rangeType: "Vulcan III",
    model: "Vulcan III 690mm",
    depthMM: 256,
    heightMM: 690,
    widthMM: 575,
    volumeL: 6.75,
    nominalDeltaT50KW: 0.761,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 463,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-690-10",
    rangeType: "Vulcan III",
    model: "Vulcan III 690mm",
    depthMM: 256,
    heightMM: 690,
    widthMM: 635,
    volumeL: 7.5,
    nominalDeltaT50KW: 0.845,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 495,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-690-11",
    rangeType: "Vulcan III",
    model: "Vulcan III 690mm",
    depthMM: 256,
    heightMM: 690,
    widthMM: 696,
    volumeL: 8.25,
    nominalDeltaT50KW: 0.93,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 527,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-690-12",
    rangeType: "Vulcan III",
    model: "Vulcan III 690mm",
    depthMM: 256,
    heightMM: 690,
    widthMM: 756,
    volumeL: 9,
    nominalDeltaT50KW: 1.014,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 559,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-690-13",
    rangeType: "Vulcan III",
    model: "Vulcan III 690mm",
    depthMM: 256,
    heightMM: 690,
    widthMM: 817,
    volumeL: 9.75,
    nominalDeltaT50KW: 1.099,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 591,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-690-14",
    rangeType: "Vulcan III",
    model: "Vulcan III 690mm",
    depthMM: 256,
    heightMM: 690,
    widthMM: 877,
    volumeL: 10.5,
    nominalDeltaT50KW: 1.183,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 623,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-690-15",
    rangeType: "Vulcan III",
    model: "Vulcan III 690mm",
    depthMM: 256,
    heightMM: 690,
    widthMM: 938,
    volumeL: 11.25,
    nominalDeltaT50KW: 1.268,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 655,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-690-16",
    rangeType: "Vulcan III",
    model: "Vulcan III 690mm",
    depthMM: 256,
    heightMM: 690,
    widthMM: 998,
    volumeL: 12,
    nominalDeltaT50KW: 1.352,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 687,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-690-17",
    rangeType: "Vulcan III",
    model: "Vulcan III 690mm",
    depthMM: 256,
    heightMM: 690,
    widthMM: 1059,
    volumeL: 12.75,
    nominalDeltaT50KW: 1.437,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 719,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-690-18",
    rangeType: "Vulcan III",
    model: "Vulcan III 690mm",
    depthMM: 256,
    heightMM: 690,
    widthMM: 1119,
    volumeL: 13.5,
    nominalDeltaT50KW: 1.521,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 751,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-690-19",
    rangeType: "Vulcan III",
    model: "Vulcan III 690mm",
    depthMM: 256,
    heightMM: 690,
    widthMM: 1180,
    volumeL: 14.25,
    nominalDeltaT50KW: 1.606,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 913,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-690-20",
    rangeType: "Vulcan III",
    model: "Vulcan III 690mm",
    depthMM: 256,
    heightMM: 690,
    widthMM: 1240,
    volumeL: 15,
    nominalDeltaT50KW: 1.69,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 945,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-690-21",
    rangeType: "Vulcan III",
    model: "Vulcan III 690mm",
    depthMM: 256,
    heightMM: 690,
    widthMM: 1301,
    volumeL: 15.75,
    nominalDeltaT50KW: 1.775,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 977,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-690-22",
    rangeType: "Vulcan III",
    model: "Vulcan III 690mm",
    depthMM: 256,
    heightMM: 690,
    widthMM: 1361,
    volumeL: 16.5,
    nominalDeltaT50KW: 1.859,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1009,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-690-23",
    rangeType: "Vulcan III",
    model: "Vulcan III 690mm",
    depthMM: 256,
    heightMM: 690,
    widthMM: 1422,
    volumeL: 17.25,
    nominalDeltaT50KW: 1.944,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1041,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-690-24",
    rangeType: "Vulcan III",
    model: "Vulcan III 690mm",
    depthMM: 256,
    heightMM: 690,
    widthMM: 1482,
    volumeL: 18,
    nominalDeltaT50KW: 2.028,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1073,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-690-25",
    rangeType: "Vulcan III",
    model: "Vulcan III 690mm",
    depthMM: 256,
    heightMM: 690,
    widthMM: 1543,
    volumeL: 18.75,
    nominalDeltaT50KW: 2.113,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1105,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-690-26",
    rangeType: "Vulcan III",
    model: "Vulcan III 690mm",
    depthMM: 256,
    heightMM: 690,
    widthMM: 1603,
    volumeL: 19.5,
    nominalDeltaT50KW: 2.197,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1137,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-690-27",
    rangeType: "Vulcan III",
    model: "Vulcan III 690mm",
    depthMM: 256,
    heightMM: 690,
    widthMM: 1664,
    volumeL: 20.25,
    nominalDeltaT50KW: 2.282,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1169,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-690-28",
    rangeType: "Vulcan III",
    model: "Vulcan III 690mm",
    depthMM: 256,
    heightMM: 690,
    widthMM: 1724,
    volumeL: 21,
    nominalDeltaT50KW: 2.366,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1201,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-690-29",
    rangeType: "Vulcan III",
    model: "Vulcan III 690mm",
    depthMM: 256,
    heightMM: 690,
    widthMM: 1785,
    volumeL: 21.75,
    nominalDeltaT50KW: 2.451,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1233,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-690-30",
    rangeType: "Vulcan III",
    model: "Vulcan III 690mm",
    depthMM: 256,
    heightMM: 690,
    widthMM: 1845,
    volumeL: 22.5,
    nominalDeltaT50KW: 2.535,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1265,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-690-31",
    rangeType: "Vulcan III",
    model: "Vulcan III 690mm",
    depthMM: 256,
    heightMM: 690,
    widthMM: 1906,
    volumeL: 23.25,
    nominalDeltaT50KW: 2.62,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1297,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-690-32",
    rangeType: "Vulcan III",
    model: "Vulcan III 690mm",
    depthMM: 256,
    heightMM: 690,
    widthMM: 1966,
    volumeL: 24,
    nominalDeltaT50KW: 2.704,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1329,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-690-33",
    rangeType: "Vulcan III",
    model: "Vulcan III 690mm",
    depthMM: 256,
    heightMM: 690,
    widthMM: 2027,
    volumeL: 24.75,
    nominalDeltaT50KW: 2.789,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1361,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-690-34",
    rangeType: "Vulcan III",
    model: "Vulcan III 690mm",
    depthMM: 256,
    heightMM: 690,
    widthMM: 2087,
    volumeL: 25.5,
    nominalDeltaT50KW: 2.873,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1393,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-690-35",
    rangeType: "Vulcan III",
    model: "Vulcan III 690mm",
    depthMM: 256,
    heightMM: 690,
    widthMM: 2148,
    volumeL: 26.25,
    nominalDeltaT50KW: 2.958,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1425,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-690-36",
    rangeType: "Vulcan III",
    model: "Vulcan III 690mm",
    depthMM: 256,
    heightMM: 690,
    widthMM: 2208,
    volumeL: 27,
    nominalDeltaT50KW: 3.042,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1457,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-690-37",
    rangeType: "Vulcan III",
    model: "Vulcan III 690mm",
    depthMM: 256,
    heightMM: 690,
    widthMM: 2269,
    volumeL: 27.75,
    nominalDeltaT50KW: 3.127,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1489,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-690-38",
    rangeType: "Vulcan III",
    model: "Vulcan III 690mm",
    depthMM: 256,
    heightMM: 690,
    widthMM: 2329,
    volumeL: 28.5,
    nominalDeltaT50KW: 3.211,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1521,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-690-39",
    rangeType: "Vulcan III",
    model: "Vulcan III 690mm",
    depthMM: 256,
    heightMM: 690,
    widthMM: 2390,
    volumeL: 29.25,
    nominalDeltaT50KW: 3.296,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1553,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
  {
    productKey: "CI-VLC3-690-40",
    rangeType: "Vulcan III",
    model: "Vulcan III 690mm",
    depthMM: 256,
    heightMM: 690,
    widthMM: 2450,
    volumeL: 30,
    nominalDeltaT50KW: 3.38,
    kvValue: 20,
    nCoefficient: 1.33,
    price: {
      value: 1585,
      currency: Currency.GBP,
    },
    ratingDataType: "n-coefficient",
  },
];
