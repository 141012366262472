import * as TM from "transformation-matrix";
import { GetPressureLossOptions } from "../../../../common/src/api/calculations/entity-pressure-drops";
import {
  CoreContext,
  CostBreakdown,
  PressureLossResult,
} from "../../../../common/src/api/calculations/types";
import CoreVertex, {
  VERTEX_ARROW_HEIGHT,
  VERTEX_ARROW_LENGTH,
} from "../../../../common/src/api/coreObjects/coreVertex";
import { SelectionTarget } from "../../../../common/src/api/coreObjects/lib/types";
import { CalculationConcrete } from "../../../../common/src/api/document/calculations-objects/calculation-concrete";
import { CalculationData } from "../../../../common/src/api/document/calculations-objects/calculation-field";
import {
  AttachableBackgroundEntityConcrete,
  DrawableEntityConcrete,
} from "../../../../common/src/api/document/entities/concrete-entity";
import { EntityType } from "../../../../common/src/api/document/entities/types";
import { VertexEntity } from "../../../../common/src/api/document/entities/vertices/vertex-entity";
import { Coord } from "../../../../common/src/lib/coord";
import CanvasContext from "../lib/canvas-context";
import { EntityDrawingArgs } from "../lib/drawable-object";
import { EntityPopupContent } from "../lib/entity-popups/types";
import { Interaction, InteractionType } from "../lib/interaction";
import { CalculatedObject } from "../lib/object-traits/calculated-object";
import { CenteredObject } from "../lib/object-traits/centered-object";
import { ConnectableObject } from "../lib/object-traits/connectable";
import CoolDraggableObject from "../lib/object-traits/cool-draggable-object";
import { Core2Drawable } from "../lib/object-traits/core2drawable";

import Flatten from "@flatten-js/core";
import { lerp } from "../../../../common/src/lib/mathUtils/mathutils";
import {
  assertUnreachable,
  cloneSimple,
} from "../../../../common/src/lib/utils";
import { MainEventBus } from "../../store/main-event-bus";
import { MoveIntent } from "../lib/black-magic/cool-drag";
import {
  HoverSiblingResult,
  HoverableObject,
} from "../lib/object-traits/hoverable-object";
import { CollisionLayer } from "../lib/object-traits/magic-draggable-object";
import { SelectableObject } from "../lib/object-traits/selectable";
import { SnappableObject } from "../lib/object-traits/snappable-object";
import { DrawingContext, ObjectConstructArgs } from "../lib/types";
import { DrawingMode } from "../types";
import {
  DrawableObjectConcrete,
  PolygonObjectConcrete,
  isAttachableObjectAny,
  isHoverableObjectAny,
} from "./concrete-object";
import DrawableEdge from "./drawableEdge";
import DrawableFen from "./drawableFenestration";
import DrawableLine from "./drawableLine";
import DrawableRoom from "./drawableRoom";
import DrawableWall from "./drawableWall";
import { applyHoverEffects } from "./utils";

const Base = HoverableObject(
  CalculatedObject(
    SelectableObject(
      CoolDraggableObject(
        ConnectableObject(
          CenteredObject(SnappableObject(Core2Drawable(CoreVertex))),
        ),
      ),
    ),
  ),
);

export default class DrawableVertex extends Base {
  getHoverSiblings(): HoverSiblingResult[] {
    const result: HoverSiblingResult[] = [];

    switch (this.entity.vertexContext) {
      case "room":
      case "unheated-area":
      case "heated-area":
        const polygons = this.globalStore.getPolygonsByVertex(this.entity.uid);

        for (const polygon of polygons) {
          const object = this.globalStore.get(polygon) as DrawableRoom;
          if (object) {
            result.concat(object.getHoverSiblings());
          } else {
            throw new Error("Unexpected type");
          }
        }

        break;
      case "annotation":
        const conns = [...this.globalStore.getConnections(this.entity.uid)];
        if (this.entity.parentUid) {
          conns.push(this.entity.parentUid);
        }

        for (const conn of conns) {
          const o = this.globalStore.get(conn);
          if (o && isHoverableObjectAny(o) && !o.isHovering) {
            result.push({
              object: o,
              cascade: true,
            });
          }
        }

        break;
      default:
        assertUnreachable(this.entity.vertexContext);
    }

    return result;
  }
  getPopupContent(): EntityPopupContent[] | null {
    // throw new Error("Method not implemented.");
    return null;
  }
  type: EntityType.VERTEX = EntityType.VERTEX;

  // We could not add this to the base drawable class because
  // of some typescript thing so they have to be added at the concrete class.
  constructor(args: ObjectConstructArgs<VertexEntity>) {
    super(args.context, args.obj);
    this.onSelect = args.onSelect;
    this.onInteractionComplete = args.onInteractionComplete;
    this.document = args.document;
  }

  get collisionLayers(): CollisionLayer[] {
    switch (this.entity.vertexContext) {
      case "room":
        return [CollisionLayer.ROOM];
      case "unheated-area":
        return [CollisionLayer.UNHEATED_AREA];
      case "heated-area":
        return [CollisionLayer.HEATED_AREA];
      case "annotation":
        return [];
    }
    assertUnreachable(this.entity.vertexContext);
    return [];
  }

  locateCalculationBoxWorld(
    _context: DrawingContext,
    _data: CalculationData[],
    _scale: number,
  ): TM.Matrix[] {
    throw new Error("Method not implemented.");
  }
  getFrictionPressureLossKPA(
    _options: GetPressureLossOptions,
  ): PressureLossResult {
    throw new Error("Method not implemented.");
  }
  collectCalculations(_context: CoreContext): CalculationConcrete {
    throw new Error("Method not implemented.");
  }
  costBreakdown(_context: CoreContext): CostBreakdown | null {
    throw new Error("Method not implemented.");
  }
  preCalculationValidation(_context: CoreContext): SelectionTarget | null {
    throw new Error("Method not implemented.");
  }

  minimumConnections: number = 0;
  maximumConnections: number | null = null;
  dragPriority: number;
  lastDrawnRadius: number = 200;
  drawConnectable(context: DrawingContext, args: EntityDrawingArgs): void {
    if (!this.isActive()) {
      return;
    }

    switch (this.entity.shape) {
      case "arrow":
        this.drawVertexArrow(context, args);
        break;
      case "circle":
        this.drawVertexCircle(context, args);
        break;
      case "nothing":
        return;
      default:
        this.drawVertexCircle(context, args);
    }
  }

  drawVertexArrow(
    context: DrawingContext,
    { selected }: EntityDrawingArgs,
  ): void {
    applyHoverEffects(context, this);
    if (selected) {
      context.ctx.globalAlpha = 0.5;
    }

    const lines = this.globalStore.getConnections(this.uid);
    const ctx = context.ctx;

    if (lines.length === 1) {
      const line = this.globalStore.get(lines[0]) as DrawableObjectConcrete;

      if (line.type === EntityType.LINE) {
        const [aw, bw] = line.worldEndpoints();
        const vec = Flatten.vector([bw.x - aw.x, bw.y - aw.y]).normalize();
        let orth = vec.rotate90CCW();

        for (const _side of [0, 1]) {
          orth = orth.rotate90CW().rotate90CW();

          this.withWorldAngle(context, { x: 0, y: 0 }, () => {
            ctx.beginPath();
            ctx.strokeStyle = line.entity.color?.hex ?? "black";
            ctx.lineWidth = 3;
            const horz = -VERTEX_ARROW_LENGTH;
            const vert = VERTEX_ARROW_HEIGHT;
            const x = vec.x * horz + orth.x * vert;
            const y = vec.y * horz + orth.y * vert;
            ctx.moveTo(0, 0);
            ctx.lineTo(x, y);
            ctx.stroke();
          });
        }
      }
    }
  }

  drawVertexCircle(
    context: DrawingContext,
    { selected, forExport }: EntityDrawingArgs,
  ): void {
    if (forExport) {
      return;
    }

    const ctx = context.ctx;
    const screenScale = context.vp.currToScreenScale(ctx);

    let radius = 0;
    switch (this.entity.vertexContext) {
      case "room":
      case "unheated-area":
      case "heated-area":
        if (
          this.document.uiState.drawingMode === DrawingMode.Calculations ||
          this.document.uiState.drawingMode === DrawingMode.Export
        ) {
          return;
        }
        if (
          this.document.uiState.toolHandlerName === "insert-room" ||
          this.document.uiState.toolHandlerName === "insert-roof" ||
          this.document.uiState.toolHandlerName === "insert-wall"
        ) {
          return;
        }

        const myPolys = this.globalStore.getPolygonsByVertex(this.entity.uid);

        if (screenScale < 0.01) {
          if (!selected && !myPolys.some((p) => context.selectedUids.has(p))) {
            return;
          }
        }

        const myPolyObjects = myPolys.map(
          (p) => this.globalStore.get(p) as PolygonObjectConcrete,
        );
        if (!myPolyObjects.some((o) => o.isShapeable())) {
          return;
        }

        if (
          !this.isHovering &&
          !myPolyObjects.some(
            (o) => o.isHovering || context.selectedUids.has(o.uid),
          )
        ) {
          return;
        }
        radius = Math.max(lerp(2 / screenScale, 130, 0.4), 2 / screenScale);
        break;
      case "annotation":
        if (this.document.uiState.drawingMode !== DrawingMode.Design) {
          return;
        }
        radius = 20;
        break;
      default:
        assertUnreachable(this.entity.vertexContext);
    }

    if (this.isHovering) {
      context.ctx.fillStyle = "#DDEEFF";
    } else if (selected) {
      context.ctx.fillStyle = "#BBDDFF";
    } else {
      context.ctx.fillStyle = "#FFFFFF";
    }

    ctx.strokeStyle = "#004499";
    ctx.lineWidth = 1.5 / screenScale;
    ctx.beginPath();
    ctx.arc(0, 0, radius, 0, Math.PI * 2);
    ctx.fill();
    ctx.stroke();

    this.lastDrawnRadius = radius;
    this.radius = radius;
  }

  inBounds(oc: Coord, radius: number = 0): boolean {
    if (!this.isActive()) {
      return false;
    }
    let l = this.toObjectLength(90);
    if (this.document.uiState.bigHitbox.includes(this.entity.uid)) {
      l = this.radius;
    }

    switch (this.entity.vertexContext) {
      case "annotation":
      case "unheated-area":
      case "heated-area":
        const coords = this.toWorldCoord(oc);
        const boundary = Flatten.circle(
          Flatten.point(coords.x, coords.y),
          radius,
        );
        return boundary.box.intersect(this.shape.box);
      case "room":
        break;
      default:
        assertUnreachable(this.entity.vertexContext);
    }

    return oc.x * oc.x + oc.y * oc.y <= (l + radius) * (l + radius);
  }

  prepareDelete(
    context: CanvasContext,
    calleeEntityUid?: string,
  ): DrawableObjectConcrete[] {
    switch (this.entity.vertexContext) {
      case "room":
      case "unheated-area":
      case "heated-area":
        if (context.globalStore.getConnections(this.entity.uid).length === 0) {
          // happens during moveOnto delete
          return [this];
        }
        const room = context.globalStore.get<DrawableRoom>(
          context.globalStore.getPolygonsByVertex(this.entity.uid)[0],
        );
        if (room.entity.edgeUid.length <= 3) {
          const result: DrawableObjectConcrete[] = [];
          room.prepareDelete(context).forEach((x) => {
            if (!result.some((y) => y.uid === x.uid)) result.push(x);
          });
          return result;
        }
        const edges = cloneSimple(
          context.globalStore.getConnections(this.entity.uid),
        );

        const result: DrawableObjectConcrete[] = [this];
        edges.forEach((edge) => {
          context.globalStore.getFensByRoomEdge(edge).forEach((fen) => {
            const fenObject = context.globalStore.get<DrawableFen>(fen);
            if (fenObject) {
              result.push(fenObject);
            }
          });
        });
        context.globalStore.getWallsByRoomEdge(edges[1]).forEach((wall) => {
          const wallObject = context.globalStore.get<DrawableWall>(wall);
          if (wallObject) {
            result.push(wallObject);
          }
        });
        result.push(context.globalStore.get(edges[1]));
        let obj = context.globalStore.get<DrawableEdge>(edges[1]);
        const newUid = obj.entity.endpointUid.filter(
          (uid) => uid !== this.entity.uid,
        )[0];
        obj = context.globalStore.get<DrawableEdge>(edges[0]);
        for (let i = 0; i < obj.entity.endpointUid.length; i++) {
          if (obj.entity.endpointUid[i] === this.entity.uid) {
            obj.entity.endpointUid[i] = newUid;
            break;
          }
        }
        (room.entity.edgeUid as any) = room.entity.edgeUid.filter(
          (uid) => uid !== edges[1],
        );
        return result;
      case "annotation":
        let conns = [...context.globalStore.getConnections(this.entity.uid)];
        if (calleeEntityUid) {
          conns = conns.filter((c) => c !== calleeEntityUid);
        }

        const entities = conns.map((c) => context.globalStore.get(c));
        const toDelete = entities
          .filter(
            (e) =>
              e.type === EntityType.ANNOTATION || e.type === EntityType.LINE,
          )
          .map((e) => e.prepareDelete(context, this.uid))
          .flat();

        return [this, ...toDelete];
      default:
        assertUnreachable(this.entity.vertexContext);
    }

    return [];
  }

  getCoolDragCorrelations(
    myMove: MoveIntent,
    _from?: DrawableObjectConcrete | undefined,
  ): { object: DrawableObjectConcrete; move: MoveIntent }[] {
    switch (this.entity.vertexContext) {
      case "room":
      case "unheated-area":
      case "heated-area":
        const res = [this];
        return res.map((obj: DrawableObjectConcrete) => {
          return {
            object: obj,
            move: myMove,
          };
        });

      case "annotation":
        return [];

      default:
        assertUnreachable(this.entity.vertexContext);
    }

    return [];
  }
  offerInteraction(interaction: Interaction): DrawableEntityConcrete[] | null {
    if (!this.isActive()) return null;
    if (this.entity.vertexContext === "room") {
      if (
        interaction.type === InteractionType.SNAP_ONTO_RECEIVE ||
        interaction.type === InteractionType.SNAP_ONTO_SEND
      ) {
        const superResult = super.offerInteraction(interaction);
        if (superResult) {
          if (interaction.type === InteractionType.SNAP_ONTO_RECEIVE) {
            // Extra check: The vertices need to be from the same polygon and adjacent.
            // TODO: non adjacent vertices. But patterns are more complicated
            const connsA = this.globalStore.getConnections(this.uid);
            const connsB = this.globalStore.getConnections(interaction.src.uid);

            if (connsA.some((c) => connsB.includes(c))) {
              const room = this.globalStore.get(
                this.globalStore.getPolygonsByVertex(this.entity.uid)[0],
              ) as DrawableRoom;
              if (room.entity.edgeUid.length > 3) {
                return superResult;
              }
            }
          } else {
            return superResult;
          }
        }
      }
    }
    if (interaction.type === InteractionType.INSERT) {
      return [this.entity];
    }
    return null;
  }
  isActive(): boolean {
    switch (this.entity.vertexContext) {
      case "room":
        return this.document.uiState.drawingMode === DrawingMode.FloorPlan;
      case "annotation":
        const conns = this.globalStore.getConnections(this.uid);
        if (conns && conns.length > 0) {
          const line = this.globalStore.get(conns[0]) as DrawableLine;
          if (line) {
            return line.isActive();
          }
        }
    }

    return true;
  }
  canAcceptBackground(entity: AttachableBackgroundEntityConcrete): boolean {
    switch (entity.type) {
      case EntityType.BACKGROUND_IMAGE:
        return true;
      case EntityType.ROOM:
        return false;
      default:
        return false;
    }
  }
  getCopiedObjects(): DrawableObjectConcrete[] {
    return [];
  }

  get effectiveCenter(): Coord {
    switch (this.entity.vertexType) {
      case "fixed":
        return this.entity.center;

      case "linked":
        if (this.entity.parentUid) {
          const parent = this.globalStore.get(this.entity.parentUid);

          if (parent && isAttachableObjectAny(parent)) {
            const attachCoords = parent.getAttachCoords();

            switch (this.entity.vertex.relativePos) {
              case "left":
                return attachCoords[0];
              case "right":
                return attachCoords[1];
              case "top":
                return attachCoords[2];
              case "bottom":
                return attachCoords[3];
              default:
                assertUnreachable(this.entity.vertex.relativePos);
            }
          }
        } else {
          // parent was deleted
          MainEventBus.$emit("delete-entity", this);
        }

        break;
      default:
        assertUnreachable(this.entity);
    }

    return this.entity.center;
  }
}
