import { constant, noop } from "lodash";
import { Units } from "../../../../lib/measurements";
import { assertType } from "../../../../lib/utils";
import { CoreContext } from "../../../calculations/types";
import {
  DEFAULT_EQUIPMENT_INFLUENCE_RADIUS_MM,
  MAX_EQUIPMENT_INFLUENCE_RADIUS_MM,
} from "../../../coreObjects/corePlant";
import { SupportedLocales } from "../../../locale";
import { getTooltip, getTooltipsEntityName } from "../../../tooltips/tooltips";
import {
  FieldType,
  PropertyField,
  withPropertyTracking,
} from "../property-field";
import { ConditionalPropertyField, filterConditionalFields } from "../utils";
import { createDHWEnergyTabFields } from "./dhw-cylinder-fields";
import { makeDualSystemPlantTabs } from "./dual-system-fields";
import { makeDuctManifoldPlantIO } from "./duct-manifold-fields";
import {
  createHeatPumpEnergyTabFields,
  createHeatPumpNoiseTabFields,
} from "./heat-pump-fields";
import { createInletsTabFields } from "./inlet-fields";
import {
  createManifoldDimensionsTabFields,
  createManifoldTechnicalTabFields,
} from "./manifold-fields";
import { createOutletsTabFields } from "./outlet-fields";
import { fillPlantDefaults } from "./plant-defaults";
import PlantEntity, {
  DualSystemPlantEntity,
  DuctManifoldPlantEntity,
  RadiatorPlantEntity,
} from "./plant-entity";
import { PlantType, RadiatorPlant } from "./plant-types";
import {
  createRadiatorDimensionsTabFields,
  createRadiatorTechnicalTabFields,
} from "./radiator-fields";
import { createSpecsTabFields } from "./specs-fields";
import { PlantFieldHandlers } from "./types";
import {
  isDHWCylinderPlant,
  isDualSystemNodePlant,
  isHeatPumpPlant,
  isHotWaterRheem,
  isRadiatorPlant,
} from "./utils";

export function makePlantTabs(
  context: CoreContext,
  entity: PlantEntity,
  handlers?: PlantFieldHandlers,
): ConditionalPropertyField {
  const filled = fillPlantDefaults(context, entity);
  const { drawing, catalog } = context;
  const isHeatPump = isHeatPumpPlant(entity.plant);
  const isDHWCylinder = isDHWCylinderPlant(entity.plant);
  const isRadiator = isRadiatorPlant(entity.plant);
  const isManifold = filled.plant.type === PlantType.MANIFOLD;
  const useGeneralSpec = !isRadiator && !isManifold;
  const isDuctManifold = entity.plant.type === PlantType.DUCT_MANIFOLD;

  const res: ConditionalPropertyField = {
    type: FieldType.Tabs,
    id: "plant-tabs",
    isShown: true,
    tabs: [],
  };

  if (useGeneralSpec) {
    res.tabs.push({
      tabId: "specs",
      tabName: "Specs",
      fields: createSpecsTabFields(context, filled, drawing, catalog, handlers),
    });
  }

  if (isRadiator) {
    assertType<RadiatorPlantEntity>(filled);
    const radiator = entity.plant as RadiatorPlant;
    const readOnly =
      radiator.radiatorType === "specify" &&
      (radiator.widthMM.type === "upper" ||
        radiator.manufacturer !== "generic");
    res.tabs.push(
      {
        tabId: "dimensions",
        tabName: "Dimensions",
        fields: createRadiatorDimensionsTabFields(
          filled,
          radiator,
          context,
          readOnly,
        ),
      },
      {
        tabId: "technical",
        tabName: "Technical",
        fields: createRadiatorTechnicalTabFields(filled, context, readOnly),
      },
    );
  }
  if (isManifold) {
    res.tabs.push(
      {
        tabId: "dimensions",
        tabName: "General",
        fields: createManifoldDimensionsTabFields(filled, context, handlers),
      },
      {
        tabId: "technical",
        tabName: "Technical",
        fields: createManifoldTechnicalTabFields(
          filled,
          context,
          entity,
          handlers,
        ),
      },
    );
  }

  if (isHeatPump) {
    res.tabs.push(
      {
        tabId: "energy",
        tabName: "Energy",
        fields: createHeatPumpEnergyTabFields(context),
      },
      {
        tabId: "noise",
        tabName: "Noise",
        fields: createHeatPumpNoiseTabFields(),
      },
    );
  }
  if (isDHWCylinder && context.locale === SupportedLocales.UK) {
    res.tabs.push({
      tabId: "energy",
      tabName: "Energy",
      fields: createDHWEnergyTabFields(filled, context),
    });
  }

  if (isDuctManifold) {
    res.tabs.push({
      tabId: "io",
      tabName: "Inlet/Outlet",
      fields: makeDuctManifoldPlantIO(
        context,
        filled as DuctManifoldPlantEntity,
        handlers,
      ),
    });
  } else {
    res.tabs.push(
      {
        tabId: "inlets",
        tabName: "Inlets",
        fields: createInletsTabFields(filled, context, handlers),
      },
      {
        tabId: "outlets",
        tabName: "Outlets",
        fields: createOutletsTabFields(context, filled, handlers),
      },
    );
  }

  return res;
}

export function makePlantEntityFields(
  context: CoreContext,
  entity: PlantEntity,
  handlers?: PlantFieldHandlers,
): PropertyField[] {
  const { drawing } = context;
  const filled = fillPlantDefaults(context, entity);

  const isDrainageGreaseInterTrap =
    filled.plant.type === PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP;
  const isRheem =
    filled.plant.type === PlantType.RETURN_SYSTEM &&
    isHotWaterRheem(drawing, filled.plant.returnType);
  const isDualSystem = isDualSystemNodePlant(filled.plant);
  const tooltipsEntityName = getTooltipsEntityName(filled, drawing);
  const isHeatPump = isHeatPumpPlant(entity.plant);
  const isLocaleUK = context.locale === SupportedLocales.UK;

  const heatPumpFields: ConditionalPropertyField[] = [
    {
      property: "plant.manufacturer",
      title: "Manufacturer",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Text,
      params: null,
      multiFieldId: "manufacturer",
      isShown: isHeatPump && isLocaleUK,
    },
    {
      property: "plant.model",
      title: "Model",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Text,
      params: null,
      multiFieldId: "model",
      isShown: isHeatPump && isLocaleUK,
    },
    {
      property: "plant.MCSCertificateNumber",
      title: "MCS Certificate Number",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Text,
      params: null,
      multiFieldId: "MCSCertificateNumber",
      isShown: isHeatPump && isLocaleUK,
    },
  ];

  const res: ConditionalPropertyField[] = [
    {
      property: "name",
      title: "Name",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Text,
      params: null,
      multiFieldId: "name",
      isShown: !isRheem && !isDrainageGreaseInterTrap,
    },
    ...heatPumpFields,
    {
      property: "rotation",
      title: "Rotation",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Rotation,
      params: {
        step: 45,
        disableFreeInput: false,
      },
      multiFieldId: "rotation",
      isShown: true,
      settingPropertyParam: {
        updateValue: handlers?.setRotation,
      },
    },
    {
      property: "influenceRadius", // ghost property
      title: "Influence Radius",
      isCalculated: false,
      hasDefault: false,
      units: Units.Millimeters,
      type: FieldType.Number,
      settingPropertyParam: {
        fetchValue:
          handlers?.getEquipmentInfluenceRadiusMM ??
          constant(DEFAULT_EQUIPMENT_INFLUENCE_RADIUS_MM),
        updateValue: handlers?.setEquipmentInfluenceRadiusMM ?? noop,
      },
      params: { min: 0, max: MAX_EQUIPMENT_INFLUENCE_RADIUS_MM },
      multiFieldId: "influenceRadius",
      isShown: true,
      hint: "Ancillary items within this range will move when the equipment is rotated or resized. This is a universal value accross the platform.",
    },
    {
      property: "rightToLeft",
      title: "Right to Left?",
      hint: getTooltip(tooltipsEntityName, "Right to Left?"),
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Boolean,
      params: null,
      multiFieldId: "rightToLeft",
      isShown: true,
    },
    ...(isDualSystem
      ? [
          makeDualSystemPlantTabs(
            context,
            filled as DualSystemPlantEntity,
            handlers,
          ),
        ]
      : [makePlantTabs(context, entity, handlers)]),
  ];

  return filterConditionalFields(res).map(
    withPropertyTracking(context, entity),
  );
}
