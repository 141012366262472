import axios from "axios";
import { APIResult } from "../../../common/src/api/document/types";
import { Subscription } from "../../../common/src/models/Subscription";
import { getFailureFromAxiosRequestErrorEvent } from "../lib/axios-utils";

export async function getAllSubscriptions(): Promise<
  APIResult<Subscription[]>
> {
  try {
    return (await axios.get("/api/subscription/")).data;
  } catch (e: any) {
    return getFailureFromAxiosRequestErrorEvent(e);
  }
}

export async function associateSubscription(
  subscriptionId: number,
  orgId: string,
): Promise<APIResult<Subscription>> {
  try {
    return (
      await axios.post("/api/subscription/associate", {
        subscriptionId,
        orgId,
      })
    ).data;
  } catch (e: any) {
    return getFailureFromAxiosRequestErrorEvent(e);
  }
}

export async function deleteSubscription(id: string): Promise<APIResult<void>> {
  try {
    return (await axios.delete(`/api/subscription/${id}`)).data;
  } catch (e: any) {
    return getFailureFromAxiosRequestErrorEvent(e);
  }
}
