import PlantEntity from "../../../../../common/src/api/document/entities/plants/plant-entity";
import { Units, UnitsContext } from "../../../lib/measurements";
import { assertUnreachable } from "../../../lib/utils";
import { CoreContext, DrawingLayout } from "../../calculations/types";
import { ComponentId } from "../../catalog/underfloor-heating/ufh-types";
import {
  DHWCylinderPlant,
  HotWaterPlantManufacturers,
  PlantType,
} from "../entities/plants/plant-types";
import { isRadiatorPlant } from "../entities/plants/utils";
import { CalculationField, FieldCategory } from "./calculation-field";
import {
  CalcFieldSelector,
  Calculation,
  CalculationType,
  LiveCalculation,
} from "./types";

// the number[] are related for return loops where we have multiple
export default interface PlantCalculation extends Calculation {
  type: CalculationType.PlantCalculation;
  pressureDropKPA: number | null;
  circulationFlowRateLS: (number | null)[];
  circulationPressureLoss: (number | null)[];

  pumpDutyKPA: number | null;
  pumpDutyString: string | null;
  pumpFlowRateLS: number | null;
  capacityL: number | null;
  maxCapacityAvailableL: number | null;
  heatingRatingKW: number | null;
  chilledRatingKW: number | null;
  returnDeltaC: number | null;
  returnAverageC: number | null;
  // radiator specific field
  heatingRatingAt50DtKW: number | null;

  // used as an intermediate step in looking up manufacturer models for eg. Dutypoint pump-tanks
  modelReference: string | null;

  returnLoopHeatLossKW: (number | null)[];
  returnHeatingSystemLoopHeatLossKW: (number | null)[];

  circulatingPumpModel: (string | null)[];
  entryPressureKPA: number | null;
  exitPressureKPA: number | null;

  isInletHydrated: boolean | null;

  // Consumption is how much gas is needed by this entitiy.
  gasConsumptionMJH: number | null;
  // gas flow rate is the actual gas flow rate to this entity.
  gasFlowRateMJH: number | null;
  gasPressureKPA: number | null;

  totalSystemVolumeL: number | null;
  totalPipeVolumeL: number | null;
  totalHeatEmitterVolumeL: number | null;
  // SEED-51 this represents the total volume of preheat inlet coils of
  // other plants in the return system loop
  totalPlantVolumeL: number | null;
  // combined heating load from return loops
  totalHeatingLoadKW: number | null;
  // combined cooling load from return loops
  totalChilledLoadKW: number | null;

  size: string | null;
  model: string | string[] | null;
  widthMM: number | null;
  heightMM: number | null;
  depthMM: number | null;

  minPressureKPA: number | null;
  maxPressureKPA: number | null;

  // SEED-104 This is the index-circuit length
  indexCircuitLengthM: number | null;

  problemSystemNodes: SystemNodeWarning[];

  // SEED-893 energy report, need to track DHW Cylinder belongs to heat pumps
  DHWCylinderInfo: DHWReturns[] | null;

  // SEED-938 used for vent calculations
  intakeIndexCircuitPressureDropKPA: number | null;
  exhaustIndexCircuitPressureDropKPA: number | null;
  supplyIndexCircuitPressureDropKPA: number | null;
  extractIndexCircuitPressureDropKPA: number | null;
  supplyFlowRateLS: number | null;
  extractFlowRateLS: number | null;

  // The fan duty is different to the index circuit pressure drop because
  // the fan overcomes the intake/exhaust pressure drop too.
  supplyFanDutyKPA: number | null;
  extractFanDutyKPA: number | null;

  supplyFanDutyString: string | null;
  extractFanDutyString: string | null;

  // SCOP value for Heat Pumps and SPF for DHW Cylinders
  SCOP: number | null;
  SPF: number | null;

  // SEED-1149 - calculated radiator internal volume
  volumeL: number | null;
  // this field was created for the manifold to distinguish
  // between internal volume and system volume including the loops.
  internalVolumeL: number | null;
  // SEED-1149 - kvValue for pressure drop
  kvValue: number | null;

  // temp manifold IDs
  manifoldId: string | null;
  associatedRoomUid: string[] | null; // not accurate for manifolds

  // UFH
  // list of loopIds in order.
  manifoldLoopOrder: string[];

  loopIndexCircuitLengthM: number | null;
  manifoldLoopStats: ManifoldLoopStat[];
  loopIndexPressureDropKPA: number | null;
  loopIndexHasActuator: boolean | null;
  loopIndexVelocityMS: number | null;
  loopTotalFlowRateLS: number | null;
  manifoldComponentPressureDropKPA: number | null;
  manifoldManufacturers: ManifoldManufacturers;
}

export type ManifoldLoopStat = {
  loopId: string;
  pressureDropKPA: number | null;
  volumeL: number | null;
  balancingValveKPA: number | null;
  flowRateLS: number | null;
  roomUid: string;
  lengthM: number | null;
  heatOutputW: number | null;
  heatingAreaM2: number | null;
  color: string | null;
};

export type DHWReturns = {
  DHWCylinderPlant: DHWCylinderPlant;
  DHWSPF: number;
  DHWTotalHeatLoadKW: number;
  DHWReference: string;
  heatPumpReference: string;
  outletTemperatureC: number | null;
  heatPumpSuppliedEnergyRatio: number;
};

export type SystemNodeWarningType = "HEAT_EMITTER_CONNECTED_WRONG_WAY";

export interface SystemNodeWarning {
  uid: string;
  type: SystemNodeWarningType;
}

export interface PlantLiveCalculation extends LiveCalculation {
  heatingRatingKW: number | null;
  chilledRatingKW: number | null;
  returnDeltaC: number | null;
  returnAverageC: number | null;

  // these are only used for specify radiators automatic sizing
  widthMM: number | null;
  heightMM: number | null;
  depthMM: number | null;

  // Included by underfloor manifolds
  pressureDropKPA: number | null;

  // SEED-1095 add new fields for live calculations
  volumeL: number | null;
  internalVolumeL: number | null;
  kvValue: number | null;

  // SEED-1091
  SCOP: number | null;
  SPF: number | null;

  // temp manifold IDs
  manifoldId: string | null;
  associatedRoomUid: string[] | null;
  heatSourceOutletUid: string | null;

  problemSystemNodes: SystemNodeWarning[];

  manifoldLoopStats: ManifoldLoopStat[];
  manifoldManufacturers: ManifoldManufacturers;
}

interface ManifoldManufacturers {
  manifoldModel: string | null;
  actuatorModel: string | null;
  pumpPackModel: string | null;
  ballValveModel: string | null;

  pumpId: ComponentId | null;
  blendingValveId: ComponentId | null;
}

export const FastLivePlantCalculationFields: CalcFieldSelector<PlantLiveCalculation> =
  {
    connected: true,
    SCOP: true,
  };

export const StickyPlantCalcualtionFields: CalcFieldSelector<PlantCalculation> =
  {
    model: true,
    modelReference: true,
    size: true,
    widthMM: true,
    depthMM: true,
    gasConsumptionMJH: true,
    gasPressureKPA: true,
    returnLoopHeatLossKW: true,
    pressureDropKPA: true,
  };

export function makePlantCalculationFields(
  context: CoreContext,
  value: PlantEntity,
): CalculationField[] {
  const { drawing, catalog, globalStore } = context;
  const calc = globalStore.getOrCreateCalculation(value);

  let iAmDrainage = false;
  let iAmPressure = false;
  let iAmMechanical = false;
  let iAmVentilation = false;
  switch (value.plant.type) {
    case PlantType.DUCT_MANIFOLD:
      iAmVentilation = true;
      break;
    case PlantType.AHU_VENT:
      iAmVentilation = iAmMechanical = true;
      break;
    case PlantType.RADIATOR:
    case PlantType.VOLUMISER:
    case PlantType.AHU:
    case PlantType.FCU:
    case PlantType.MANIFOLD:
    case PlantType.UFH:
      iAmMechanical = true;
      break;
    case PlantType.TANK:
    case PlantType.PUMP:
    case PlantType.PUMP_TANK:
    case PlantType.FILTER:
    case PlantType.RO:
      iAmPressure = true;
      break;
    case PlantType.RETURN_SYSTEM:
      iAmPressure = iAmMechanical = true;
    case PlantType.CUSTOM:
      iAmDrainage = iAmPressure = iAmMechanical = true;
      break;
    case PlantType.DRAINAGE_PIT:
    case PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP:
      iAmDrainage = true;
      break;
    default:
      assertUnreachable(value.plant);
  }

  if (
    value.plant.type === PlantType.AHU ||
    value.plant.type === PlantType.AHU_VENT ||
    value.plant.type === PlantType.FCU
  ) {
    iAmVentilation = true;
  }

  // initial layouts based on plant type
  const layouts: DrawingLayout[] = [];
  if (iAmPressure) {
    layouts.push("pressure");
  }
  if (iAmDrainage) {
    layouts.push("drainage");
  }
  if (iAmMechanical) {
    layouts.push("mechanical");
  }
  if (iAmVentilation) {
    layouts.push("ventilation");
  }
  const onlyLayouts = (...layouts: DrawingLayout[]) => {
    return layouts.filter((l) => layouts.includes(l));
  };

  const results: CalculationField[] = [];

  results.push({
    property: "reference",
    title: "Reference",
    short: "",
    shortTitle: "",
    units: Units.None,
    category: FieldCategory.EntityName,
    layouts,
    defaultEnabled: false,
  });

  if (value.plant.type !== PlantType.AHU_VENT) {
    results.push({
      property: "pressureDropKPA",
      title: "Pressure Drop (PD)",
      short: "",
      shortTitle: "PD",
      units: Units.KiloPascals,
      layouts,
      category: FieldCategory.Pressure,
    });
  }

  if (
    value.plant.type === PlantType.PUMP ||
    value.plant.type === PlantType.PUMP_TANK
  ) {
    let componentName = "";
    const manufacturerUid = value.plant.manufacturer;

    switch (value.plant.type) {
      case PlantType.PUMP:
        componentName =
          catalog.pump.manufacturer.find((m) => m.uid === manufacturerUid)
            ?.componentName || "";
        break;
      case PlantType.PUMP_TANK:
        componentName =
          catalog.pumpTank.manufacturer.find((m) => m.uid === manufacturerUid)
            ?.componentName || "";
        break;
      default:
        assertUnreachable(value.plant);
    }
    results.push(
      {
        property: "pumpDutyString",
        title: "Booster Pump Duty (BPD)",
        short: "",
        shortTitle: "",
        bold: true,
        defaultEnabled: false,
        units: Units.None,
        layouts,
        category: FieldCategory.Pressure,
      },
      {
        property: "model",
        title: "Model",
        short: "",
        shortTitle: "",
        units: Units.None,
        category: FieldCategory.PumpManufacturer,
        format: (v: any) => componentName + " " + v,
        layouts: onlyLayouts("pressure"),
        // static: true,
      },
    );

    results.push({
      property: "capacityL",
      title: "Capacity",
      short: "",
      defaultEnabled: false,
      format: (v: any) => (componentName ? componentName + " " : "") + v,
      units: Units.Liters,
      layouts,
      category: FieldCategory.Size,
    });
  }

  if (value.plant.type === PlantType.FILTER) {
    results.push({
      property: "model",
      title: "Model",
      short: "",
      shortTitle: "",
      units: Units.None,
      category: FieldCategory.FilterManufacturer,
      layouts: onlyLayouts("pressure"),
    });
  }

  if (value.plant.type === PlantType.RO) {
    results.push(
      {
        property: "pumpDutyString",
        title: "Booster Pump Duty (BPD)",
        short: "",
        shortTitle: "",
        bold: true,
        defaultEnabled: false,
        units: Units.None,
        layouts,
        category: FieldCategory.Pressure,
      },
      {
        property: "model",
        title: "Model",
        short: "",
        shortTitle: "",
        units: Units.None,
        category: FieldCategory.RoManufacturer,
        layouts: onlyLayouts("pressure"),
      },
    );
  }

  if (isRadiatorPlant(value.plant)) {
    results.push(
      {
        property: "entryPressureKPA",
        title: "Entry Pressure",
        short: "",
        units: Units.KiloPascals,
        layouts: onlyLayouts("mechanical"),
        category: FieldCategory.Pressure,
      },
      {
        property: "exitPressureKPA",
        title: "Exit Pressure",
        short: "",
        units: Units.KiloPascals,
        layouts: onlyLayouts("mechanical"),
        category: FieldCategory.Pressure,
      },
      {
        property: "volumeL",
        title: "Volume",
        short: "",
        units: Units.Liters,
        layouts: onlyLayouts("mechanical"),
        category: FieldCategory.Volume,
      },
    );
  }

  results.push({
    property: "heatingRatingKW",
    title: "Heating Rating",
    short: "",
    units: Units.KiloWatts,
    unitContext: UnitsContext.MECHANICAL_ENERGY_MEASUREMENT,
    layouts: onlyLayouts("mechanical"),
    category: FieldCategory.HeatLoss,
  });

  results.push({
    property: "chilledRatingKW",
    title: "Chilled Rating",
    short: "",
    units: Units.KiloWatts,
    unitContext: UnitsContext.MECHANICAL_ENERGY_MEASUREMENT,
    layouts: onlyLayouts("mechanical"),
    category: FieldCategory.HeatLoss,
  });

  if (value.plant.type === PlantType.RETURN_SYSTEM) {
    const manufacturer = drawing.metadata.catalog.hotWaterPlant.find(
      (i) => i.uid === "hotWaterPlant",
    )?.manufacturer as HotWaterPlantManufacturers;

    const hasGasInput = value.plant.addGasInlet;

    if (hasGasInput) {
      results.push(
        {
          property: "gasFlowRateMJH",
          title: "Gas Demand",
          short: "",
          shortTitle: "Gas Demand",
          units: Units.MegajoulesPerHour,
          layouts,
          category: FieldCategory.FlowRate,
          unitContext: UnitsContext.GAS_ENERGY_MEASUREMENT,
        },
        {
          property: "gasPressureKPA",
          title: "Gas Pressure",
          short: "",
          shortTitle: "Gas Pressure",
          units: Units.KiloPascals,
          layouts,
          category: FieldCategory.Pressure,
        },
      );
    }

    results.push(
      {
        property: "totalSystemVolumeL",
        title: "Total System Volume (TSV)",
        short: "",
        shortTitle: "TSV",
        units: Units.Liters,
        layouts,
        category: FieldCategory.Volume,
      },
      {
        property: "totalPipeVolumeL",
        title: "Total Pipe Volume (TPiV)",
        short: "",
        shortTitle: "TPiV",
        units: Units.Liters,
        layouts,
        category: FieldCategory.Volume,
      },
      {
        property: "totalPlantVolumeL",
        title: "Total Plant Volume (TPV)",
        short: "",
        shortTitle: "TPV",
        units: Units.Liters,
        layouts,
        category: FieldCategory.Volume,
      },
      {
        property: "totalHeatEmitterVolumeL",
        title: "Total Emitter Volume (TEV)",
        short: "",
        shortTitle: "TEV",
        units: Units.Liters,
        layouts,
        category: FieldCategory.Volume,
      },
      {
        property: "totalHeatingLoadKW",
        title: "Total Heating Load (THL)",
        short: "",
        shortTitle: "THL",
        units: Units.KiloWatts,
        layouts,
        category: FieldCategory.HeatLoss,
        unitContext: UnitsContext.MECHANICAL_ENERGY_MEASUREMENT,
      },
      {
        property: "totalChilledLoadKW",
        title: "Total Cooling Load (TCL)",
        short: "",
        shortTitle: "TCL",
        units: Units.KiloWatts,
        layouts,
        category: FieldCategory.HeatLoss,
        unitContext: UnitsContext.MECHANICAL_ENERGY_MEASUREMENT,
      },
      {
        property: "indexCircuitLengthM",
        title: "Index Circuit Length (ICL)",
        short: "",
        shortTitle: "ICL",
        units: Units.Meters,
        layouts,
        category: FieldCategory.Length,
      },
      ...((manufacturer === "rheem" &&
        ([
          {
            property: "model",
            title: "Model",
            short: "",
            units: Units.None,
            category: FieldCategory.FlowRate,
            static: true,
            layouts,
            defaultEnabled: false,
          },
          {
            property: "size",
            title: "Dimensions",
            short: "",
            units: Units.None,
            layouts,
            category: FieldCategory.FlowRate,
            static: true,
          },
        ] as CalculationField[])) ||
        []),
    );
  }

  if (value.plant.type === PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP) {
    results.push(
      {
        property: "size",
        title: "Size",
        short: "",
        units: Units.None,
        category: FieldCategory.GreaseInterceptorTrap,
        layouts: onlyLayouts("drainage"),
        static: true,
      },
      {
        property: "model",
        title: "Model",
        short: "",
        units: Units.None,
        category: FieldCategory.GreaseInterceptorTrap,
        layouts: onlyLayouts("drainage"),
        static: true,
      },
    );
  }

  if (value.plant.type === PlantType.VOLUMISER) {
    results.push({
      property: "totalPlantVolumeL",
      title: "Volume",
      short: "",
      units: Units.Liters,
      layouts,
      category: FieldCategory.Volume,
    });
  }

  if (value.plant.type === PlantType.AHU_VENT) {
    results.push(
      {
        property: "supplyFlowRateLS",
        title: "Supply Flow Rate",
        short: "",
        unitContext: UnitsContext.VENTILATION,
        units: Units.LitersPerSecond,
        layouts: onlyLayouts("ventilation"),
        category: FieldCategory.FlowRate,
      },
      {
        property: "extractFlowRateLS",
        title: "Extraction Flow Rate",
        short: "",
        unitContext: UnitsContext.VENTILATION,
        units: Units.LitersPerSecond,
        layouts: onlyLayouts("ventilation"),
        category: FieldCategory.FlowRate,
      },
      {
        property: "intakeIndexCircuitPressureDropKPA",
        title: "Intake Pressure Drop",
        short: "",
        units: Units.KiloPascals,
        unitContext: UnitsContext.VENTILATION,
        systemUid: value.plant.intakeSystemUid,
        layouts: onlyLayouts("ventilation"),
        category: FieldCategory.Pressure,
      },
      {
        property: "supplyIndexCircuitPressureDropKPA",
        title: "Supply Pressure Drop",
        short: "",
        units: Units.KiloPascals,
        unitContext: UnitsContext.VENTILATION,
        systemUid: value.plant.supplySystemUid,
        layouts: onlyLayouts("ventilation"),
        category: FieldCategory.Pressure,
      },
      {
        property: "exhaustIndexCircuitPressureDropKPA",
        title: "Exhaust Pressure Drop",
        short: "",
        units: Units.KiloPascals,
        unitContext: UnitsContext.VENTILATION,
        systemUid: value.plant.exhaustSystemUid,
        layouts: onlyLayouts("ventilation"),
        category: FieldCategory.Pressure,
      },
      {
        property: "extractIndexCircuitPressureDropKPA",
        title: "Extract Pressure Drop",
        short: "",
        units: Units.KiloPascals,
        unitContext: UnitsContext.VENTILATION,
        systemUid: value.plant.extractSystemUid,
        layouts: onlyLayouts("ventilation"),
        category: FieldCategory.Pressure,
      },
      {
        property: "supplyFanDutyString",
        title: "Supply Fan Duty",
        short: "",
        units: Units.None,
        layouts: onlyLayouts("ventilation"),
        category: FieldCategory.Pressure,
      },
      {
        property: "extractFanDutyString",
        title: "Extract Fan Duty",
        short: "",
        bold: true,
        units: Units.None,
        layouts: onlyLayouts("ventilation"),
        category: FieldCategory.Pressure,
      },
    );
  }

  if (context.featureAccess.fullUnderfloorHeatingLoops) {
    if (value.plant.type === PlantType.MANIFOLD) {
      if (calc.pumpDutyString) {
        results.push({
          property: "pumpDutyString",
          title: "Pump Duty",
          short: "",
          shortTitle: "",
          bold: true,
          defaultEnabled: false,
          units: Units.None,
          layouts,
          category: FieldCategory.Pressure,
        });
      }

      if (calc.loopIndexCircuitLengthM) {
        results.push({
          property: "loopIndexCircuitLengthM",
          title: "Loop Index Circuit Length",
          short: "",
          bold: true,
          defaultEnabled: false,
          units: Units.Meters,
          layouts,
          category: FieldCategory.Pressure,
        });
      }

      if (calc.volumeL) {
        results.push({
          property: "volumeL",
          title: "Volume",
          short: "",
          shortTitle: "",
          bold: true,
          defaultEnabled: false,
          units: Units.Liters,
          layouts,
          category: FieldCategory.Pressure,
        });
      }

      if (calc.manifoldManufacturers.manifoldModel) {
        results.push({
          property: "manifoldManufacturers.manifoldModel",
          title: "Manifold Model",
          short: "",
          units: Units.None,
          layouts,
          category: FieldCategory.ManifoldManufacturer,
        });
      }

      if (calc.manifoldManufacturers.pumpPackModel) {
        results.push({
          property: "manifoldManufacturers.pumpPackModel.1",
          title: "Pump Pack Model",
          short: "",
          units: Units.None,
          layouts,
          category: FieldCategory.ManifoldManufacturer,
        });
      }

      if (calc.manifoldManufacturers.pumpId) {
        results.push({
          property: "manifoldManufacturers.pumpId.1",
          title: "Pump Model",
          short: "",
          units: Units.None,
          layouts,
          category: FieldCategory.ManifoldManufacturer,
        });
      }

      if (calc.manifoldManufacturers.blendingValveId) {
        results.push({
          property: "manifoldManufacturer.blendingValveId.1",
          title: "Blending Valve Model",
          short: "",
          units: Units.None,
          layouts,
          category: FieldCategory.ManifoldManufacturer,
        });
      }
    }
  }

  return results;
}

export function emptyPlantCalculation(): PlantCalculation {
  return {
    type: CalculationType.PlantCalculation,
    cost: null,
    costBreakdown: null,
    expandedEntities: null,

    gasFlowRateMJH: null,
    gasPressureKPA: null,
    gasConsumptionMJH: null,

    entryPressureKPA: null,
    exitPressureKPA: null,
    maxCapacityAvailableL: null,
    modelReference: null,
    heatingRatingKW: null,
    chilledRatingKW: null,
    returnDeltaC: null,
    returnAverageC: null,
    heatingRatingAt50DtKW: null,

    pressureDropKPA: null,
    circulationFlowRateLS: [],
    circulationPressureLoss: [],
    pumpDutyKPA: null,
    pumpDutyString: null,
    pumpFlowRateLS: null,
    capacityL: null,
    returnLoopHeatLossKW: [],
    returnHeatingSystemLoopHeatLossKW: [],
    circulatingPumpModel: [],

    totalSystemVolumeL: null,
    totalPipeVolumeL: null,
    totalPlantVolumeL: null,
    totalHeatEmitterVolumeL: null,
    totalHeatingLoadKW: null,
    totalChilledLoadKW: null,

    isInletHydrated: null,

    warnings: null,
    size: null,
    model: null,
    widthMM: null,
    depthMM: null,
    heightMM: null,

    // this is used by filter plants for their required pressure ranges
    minPressureKPA: null,
    maxPressureKPA: null,

    indexCircuitLengthM: null,
    problemSystemNodes: [],
    DHWCylinderInfo: null,
    supplyFlowRateLS: null,
    extractFlowRateLS: null,
    intakeIndexCircuitPressureDropKPA: null,
    exhaustIndexCircuitPressureDropKPA: null,
    extractIndexCircuitPressureDropKPA: null,
    supplyIndexCircuitPressureDropKPA: null,
    supplyFanDutyKPA: null,
    extractFanDutyKPA: null,
    supplyFanDutyString: null,
    extractFanDutyString: null,

    SCOP: null,
    SPF: null,
    volumeL: null,
    internalVolumeL: null,
    kvValue: null,
    manifoldId: null,
    associatedRoomUid: null,

    manifoldLoopOrder: [],
    manifoldLoopStats: [],
    loopIndexCircuitLengthM: null,
    loopIndexPressureDropKPA: null,
    loopIndexHasActuator: null, // for pressure drop calc later.
    loopTotalFlowRateLS: null,
    manifoldComponentPressureDropKPA: null,
    loopIndexVelocityMS: null,
    manifoldManufacturers: {
      manifoldModel: null,
      actuatorModel: null,
      pumpPackModel: null,
      ballValveModel: null,
      blendingValveId: null,
      pumpId: null,
    },
  };
}

export function emptyPlantLiveCalculation(): PlantLiveCalculation {
  return {
    connected: null,
    warnings: [],
    heatingRatingKW: null,
    chilledRatingKW: null,
    returnDeltaC: null,
    returnAverageC: null,
    heightMM: null,
    widthMM: null,
    depthMM: null,
    volumeL: null,
    internalVolumeL: null,
    pressureDropKPA: null,
    kvValue: null,
    SCOP: null,
    SPF: null,
    manifoldId: null,
    associatedRoomUid: null,
    heatSourceOutletUid: null,
    manifoldLoopStats: [],
    problemSystemNodes: [],
    manifoldManufacturers: {
      manifoldModel: null,
      actuatorModel: null,
      pumpPackModel: null,
      ballValveModel: null,
      blendingValveId: null,
      pumpId: null,
    },
  };
}
