import Flatten from "@flatten-js/core";
import { isUnderfloor } from "../../../../common/src/api/config";
import CoreAreaSegment from "../../../../common/src/api/coreObjects/coreAreaSegment";
import CoreVertex from "../../../../common/src/api/coreObjects/coreVertex";
import { UnderfloorHeatingLoopCalculation } from "../../../../common/src/api/document/calculations-objects/underfloor-heating-loop-calculation";
import AreaSegmentEntity, {
  getDrawableRoom,
  isHeatedAreaSegmentEntity,
} from "../../../../common/src/api/document/entities/area-segment-entity";
import { DrawableEntityConcrete } from "../../../../common/src/api/document/entities/concrete-entity";
import { isRoomRoomEntity } from "../../../../common/src/api/document/entities/rooms/room-entity";
import { EntityType } from "../../../../common/src/api/document/entities/types";
import { isRoomLoopLayoutFrozen } from "../../../../common/src/api/document/entities/underfloor-heating/ufh-freeze-layouts";
import { getFlowSystem } from "../../../../common/src/api/document/utils";
import { Coord, Coord3D } from "../../../../common/src/lib/coord";
import { GlobalStore } from "../../../../common/src/lib/globalstore/global-store";
import { polygonArea } from "../../../../common/src/lib/mathUtils/mathutils";
import { assertUnreachable } from "../../../../common/src/lib/utils";
import CanvasContext from "../lib/canvas-context";
import { DrawingArgs, EntityDrawingArgs } from "../lib/drawable-object";
import { EntityPopupContent } from "../lib/entity-popups/types";
import { Interaction, InteractionType } from "../lib/interaction";
import { CalculatedObject } from "../lib/object-traits/calculated-object";
import { CenteredObject } from "../lib/object-traits/centered-object";
import { Core2Drawable } from "../lib/object-traits/core2drawable";
import { DraggableObject } from "../lib/object-traits/draggable-object";
import {
  HoverSiblingResult,
  HoverableObject,
} from "../lib/object-traits/hoverable-object";
import {
  CollisionLayer,
  MagicDraggableObject,
} from "../lib/object-traits/magic-draggable-object";
import { PolygonObject } from "../lib/object-traits/polygon-object";
import { SelectableObject } from "../lib/object-traits/selectable";
import {
  SnapIntention,
  SnapTarget,
  SnappableObject,
} from "../lib/object-traits/snappable-object";
import { DrawingContext, ObjectConstructArgs } from "../lib/types";
import { DrawingMode } from "../types";
import { DrawableObjectConcrete, isCenteredObject } from "./concrete-object";
import DrawableEdge from "./drawableEdge";
import DrawableRoom from "./drawableRoom";
import DrawableVertex from "./drawableVertex";

const Base = CalculatedObject(
  SelectableObject(
    MagicDraggableObject(
      DraggableObject(
        HoverableObject(
          CenteredObject(
            SnappableObject(PolygonObject(Core2Drawable(CoreAreaSegment))),
          ),
        ),
      ),
    ),
  ),
);

export default class DrawableAreaSegment extends Base {
  type: EntityType.AREA_SEGMENT = EntityType.AREA_SEGMENT;

  constructor(args: ObjectConstructArgs<AreaSegmentEntity>) {
    super(args.context, args.obj);
    this.onSelect = args.onSelect;
    this.onInteractionComplete = args.onInteractionComplete;
    this.document = args.document;
  }

  getEdgeSpacing() {
    return 10;
  }

  // @ts-ignore
  get collisionLayers(): CollisionLayer[] {
    switch (this.entity.areaType) {
      case "heated-area":
        return [CollisionLayer.HEATED_AREA];
      case "unheated-area":
        return [CollisionLayer.UNHEATED_AREA];
      default:
        assertUnreachable(this.entity);
        return [];
    }
  }

  getSnapTargets(_request: SnapIntention[], _mouseWc: Coord): SnapTarget[] {
    throw new Error("Method not implemented.");
  }

  getHoverSiblings(): HoverSiblingResult[] {
    const result: HoverSiblingResult[] = [];
    result.push({
      object: this,
      cascade: false,
    });

    this.entity.edgeUid.forEach((edgeUid: string) => {
      const edge = this.globalStore.get(edgeUid) as DrawableEdge;

      if (edge) {
        result.push({
          object: edge,
          cascade: false,
        });
      } else {
        throw new Error("Edge not found");
      }
    });

    this.collectVerticesInOrder().forEach((vertex: CoreVertex) => {
      const drawableVertex = this.globalStore.get(vertex.uid) as DrawableVertex;
      if (drawableVertex) {
        result.push({
          object: drawableVertex,
          cascade: false,
        });
      } else {
        throw new Error("Vertex not found");
      }
    });

    return result;
  }

  getPopupContent(): EntityPopupContent[] | null {
    const result: EntityPopupContent[] = [];

    loopLayoutFrozen: {
      if (isHeatedAreaSegmentEntity(this.entity)) {
        const calc = this.globalStore.getOrCreateLiveCalculation(this.entity);
        const room = getDrawableRoom(this.context, calc.roomUid!);

        if (!room) {
          break loopLayoutFrozen;
        }

        if (
          isRoomRoomEntity(room.entity) &&
          isRoomLoopLayoutFrozen(room.entity, this.context.featureAccess)
        ) {
          DrawableRoom.addLoopFrozenPopupContent(result);
        }
      }
    }

    if (result.length === 0) {
      return null;
    }
    return result;
  }

  onRedrawNeeded() {
    super.onRedrawNeeded();
  }

  ufhLoopToDraw(): UnderfloorHeatingLoopCalculation[] | null {
    const globalStore: GlobalStore = this.globalStore;
    const liveCalcs = globalStore.getOrCreateLiveCalculation<AreaSegmentEntity>(
      this.entity,
    );
    const calcs = globalStore.getOrCreateCalculation<AreaSegmentEntity>(
      this.entity,
    );
    const loopsStats = liveCalcs.loopsStats || calcs.loopsStats;

    if (loopsStats) {
      return loopsStats;
    }

    return null;
  }

  canDraw(context: DrawingContext, args: DrawingArgs): boolean {
    if (!super.canDraw(context, args)) {
      return false;
    }

    if (args.forExport) {
      return false;
    }

    if (
      context.featureAccess.fullUnderfloorHeatingLoops &&
      context.doc.uiState.drawingMode !== DrawingMode.Design
    ) {
      return false;
    }

    switch (this.entity.areaType) {
      case "heated-area":
        return (
          context.featureAccess.fullUnderfloorHeatingLoops &&
          isUnderfloor(
            getFlowSystem(context.doc.drawing, context.doc.activeflowSystemUid),
          )
        );
      case "unheated-area":
        if (context.featureAccess.fullUnderfloorHeatingLoops) {
          return isUnderfloor(
            getFlowSystem(context.doc.drawing, context.doc.activeflowSystemUid),
          );
        }
        return context.doc.uiState.drawingMode !== DrawingMode.FloorPlan;
      default:
        return true;
    }
  }

  drawEntity(context: DrawingContext, args: EntityDrawingArgs): void {
    const lvl = context.globalStore.levelOfEntity.get(this.uid);
    const activeLvl = context.doc.uiState.levelUid;
    if (lvl !== activeLvl) {
      // rooms are drawn on the level above which triggers area segments to be drawn - we do not want to draw them
      return;
    }

    const { ctx } = context;
    {
      context.vp.prepareContext(context.ctx);

      const baseWidth = 0.1;
      ctx.globalAlpha = 0.4;
      if (this.isHovering || args.selected) {
        ctx.globalAlpha = 0.7;
      }
      ctx.lineWidth = baseWidth;
      ctx.strokeStyle = this.baseColor();
      ctx.fillStyle = this.baseColor();
      ctx.beginPath();

      const vertices = this.collectVerticesInOrder();

      const pointsWC: Coord[] = vertices.map((v: CoreVertex) =>
        v.toWorldCoord(),
      );

      if (pointsWC.length) pointsWC.push(pointsWC[0]);
      for (let i = 0; i < pointsWC.length; i++) {
        if (i === 0) {
          ctx.moveTo(pointsWC[i].x, pointsWC[i].y);
        } else {
          ctx.lineTo(pointsWC[i].x, pointsWC[i].y);
        }
      }

      ctx.closePath();
      ctx.stroke();
      ctx.fill();
    }
  }

  baseColor() {
    switch (this.entity.areaType) {
      case "heated-area":
        if (this.isHovering) {
          return "rgba(255, 255, 160, 0.4)";
        }
        return "rgba(255, 255, 160, 0.2)";
      case "unheated-area":
        return "#000000";
    }
    assertUnreachable(this.entity);
  }

  isActive(): boolean {
    switch (this.document.uiState.drawingMode) {
      case DrawingMode.FloorPlan:
        return !this.context.featureAccess.fullUnderfloorHeatingLoops;
      case DrawingMode.Export:
      case DrawingMode.History:
      case DrawingMode.Calculations:
        return true;

      case DrawingMode.Design:
        return isUnderfloor(
          getFlowSystem(
            this.document.drawing,
            this.document.activeflowSystemUid,
          ),
        );
    }
    assertUnreachable(this.document.uiState.drawingMode);
  }

  isShapeable(): boolean {
    switch (this.document.uiState.drawingMode) {
      case DrawingMode.FloorPlan:
        return !this.context.featureAccess.fullUnderfloorHeatingLoops;
      case DrawingMode.Export:
      case DrawingMode.History:
      case DrawingMode.Calculations:
        return true;
      case DrawingMode.Design:
        return isUnderfloor(
          getFlowSystem(
            this.document.drawing,
            this.document.activeflowSystemUid,
          ),
        );
    }
    assertUnreachable(this.document.uiState.drawingMode);
  }

  inBounds(
    oc: Coord | Coord3D,
    _radius: number = 0,
    shape: Flatten.Polygon = this.shape,
  ): boolean {
    const wc = this.toWorldCoord(oc);

    let inside = false;
    const wcPoint = new Flatten.Point(wc.x, wc.y);
    const line = new Flatten.Segment(
      wcPoint,
      new Flatten.Point(wc.x + 1e7, wc.y),
    );
    const intersections = shape.intersect(line);
    inside = intersections.length % 2 === 1;
    if (inside) {
      const vertices = shape.vertices;
      const segs: Flatten.Segment[] = [];
      for (let i = 0; i < vertices.length; i++) {
        const a = vertices[i],
          b = vertices[(i + 1) % vertices.length];
        const seg = new Flatten.Segment(a, b);
        segs.push(seg);
      }

      segs.forEach((seg) => {
        if (seg.distanceTo(wcPoint)[0] < 1) {
          inside = false;
        }
      });
    }

    return inside;
  }

  inBoundsWorld(
    wc: Coord,
    radius: number = 0,
    shape: Flatten.Polygon = this.shape,
  ): boolean {
    return this.inBounds(this.toObjectCoord(wc), radius, shape);
  }

  prepareDelete(
    context: CanvasContext,
    _calleeEntityUid?: string,
  ): DrawableObjectConcrete[] {
    const result: DrawableObjectConcrete[] = [];

    this.entity.edgeUid.forEach((edgeUid: string) => {
      if (context.globalStore.getPolygonsByEdge(edgeUid).length > 1) {
        return;
      }
      const edge = context.globalStore.get(edgeUid);
      if (edge) {
        result.push(edge);
      }
    });

    this.collectVerticesInOrder().forEach((vertex: CoreVertex) => {
      const drawableVertex = context.globalStore.get(vertex.uid);
      if (context.globalStore.getPolygonsByVertex(vertex.uid).length > 1) {
        return;
      }
      if (drawableVertex) {
        result.push(drawableVertex);
      }
    });

    // rebase all entities that belong to me
    context.globalStore.forEach((v) => {
      if (v.entity.parentUid === this.entity.uid && isCenteredObject(v)) {
        v.debase(context);
      }
    });
    context.globalStore.getArcsByPolygon(this.uid).forEach((arc) => {
      const arcObj = context.globalStore.get(arc);
      if (arcObj) {
        result.push(arcObj);
      }
    });

    result.push(this);
    return result;
  }

  offerInteraction(interaction: Interaction): DrawableEntityConcrete[] | null {
    if (!this.isActive()) return null;
    switch (interaction.type) {
      case InteractionType.INSERT:
        return [this.entity];
      case InteractionType.SNAP_ONTO_RECEIVE:
      case InteractionType.EXTEND_NETWORK:
      case InteractionType.CONTINUING_CONDUIT:
      case InteractionType.STARTING_CONDUIT:
      case InteractionType.SNAP_ONTO_SEND:
        return null;
    }
    return null;
  }

  getCopiedObjects(): DrawableObjectConcrete[] {
    return this.collectEdgesInOrder().map(
      (e) => this.globalStore.get(e.uid) as DrawableEdge,
    );
  }

  debase(context: CanvasContext) {
    super.debase(context);
  }

  get isInteractable(): boolean {
    switch (this.document.uiState.drawingMode) {
      case DrawingMode.FloorPlan:
        return !this.context.featureAccess.fullUnderfloorHeatingLoops;
      case DrawingMode.Export:
      case DrawingMode.History:
      case DrawingMode.Calculations:
        return false;
      case DrawingMode.Design:
        return isUnderfloor(
          getFlowSystem(
            this.document.drawing,
            this.document.activeflowSystemUid,
          ),
        );
      default:
        assertUnreachable(this.document.uiState.drawingMode);
        return false;
    }
  }

  shouldShowDimension(context: DrawingContext) {
    return context.selectedUids.has(this.uid);
  }

  isValid() {
    const coordsInOrder = this.collectVerticesInOrder().map(
      (v) => v.entity.center,
    );
    const areaM2 = polygonArea(coordsInOrder) / 1e6;
    const invalidOverlappingAreaAM2 = this.invalidOverlappingAreaM2();
    // Has to do this due to the unreliability of the polygon intesection algo.
    return invalidOverlappingAreaAM2 < areaM2 / 10;
  }

  magicDragPolygon() {
    return this;
  }

  magicDragVertices() {
    return this.collectVerticesInOrder().map((v) => v.uid);
  }

  performMove(vec: Flatten.Vector) {
    const result: { point: Flatten.Point; uid: string }[] = [];
    this.collectVerticesInOrder().forEach((vertex: CoreVertex) => {
      const drawableVertex = this.globalStore.get(vertex.uid) as DrawableVertex;
      if (drawableVertex) {
        const wc = drawableVertex.toWorldCoord();
        const newPoint = new Flatten.Point(wc.x + vec.x, wc.y + vec.y);
        result.push({
          point: newPoint,
          uid: vertex.uid,
        });
      } else {
        throw new Error("Vertex not found");
      }
    });
    return result;
  }
}
