import Flatten from "@flatten-js/core";
import {
  isParallelRad,
  isRightAngleRad,
} from "../../../../lib/mathUtils/mathutils";
import { EPS } from "../../../../lib/utils";
import { RoomCalculation } from "../../../document/calculations-objects/room-calculation";
import { CoilCoord3D } from "../../underfloor-heating/coil-coords";
import { UFH_BELOW_FLOOR_HEIGHT_MM } from "../../underfloor-heating/consts";
import { LoopSite } from "../../underfloor-heating/underfloor-heating";
import {
  BACKTRAC_LOOP_SPACING_MUL,
  IGNORE_COLLISION_EPS,
  IntentionNode,
  LOOP_WALL_SPACING_MULT,
  LoopGenerator,
  foreachIntention,
  traceResultCmp,
} from "./loop-generator";
import { LoopObstacle } from "./loop-obstacles";

const MAX_SERPENTINE_EXTENSION_ITERATIONS = 200;

export interface SerpentineIntentionNode extends IntentionNode {
  state: "straight" | "turn" | "perimeter";
}

export interface SerpentineIntentionPath {
  coord: Flatten.Point;
  state: "straight" | "turn" | "perimeter";
}

export type SerpentineExtension = {
  type: "straight" | "turn" | "side" | "side-backtrack";
  startCoord: Flatten.Point;
  direction: Flatten.Vector;
  // For straight and turn, the _prior_ intention node, ie this is the one to attach to.
  // For side, this is the one to split.
  intention: SerpentineIntentionNode;
  firstLength: number;
  forceLength?: number;
};

export function serializeSerpentineExtension(ext: SerpentineExtension): string {
  return `${ext.type} ${ext.startCoord.x} ${ext.startCoord.y} ${ext.direction.x} ${ext.direction.y} ${ext.firstLength}`;
}

export class SerpentineGenerator {
  static generateSerpentineLoop(
    obstacles: LoopObstacle[],
    site: LoopSite,
    transitSpacingMM: number,
    loopSpacingMM: number,
    normal: Flatten.Vector,
    center: Flatten.Point,
    fromManifold: CoilCoord3D[],
    toManifold: CoilCoord3D[],
    debugObj?: RoomCalculation["debug"],
  ) {
    const { intentionTree } = this.generateSerpentineIntentions(
      obstacles,
      center,
      normal,
      loopSpacingMM,
      transitSpacingMM,
      site.underfloorHeating.loopDirectionDEG,
      debugObj,
    );

    const incidentLines: Flatten.Line[] =
      LoopGenerator.getIncidentLines(intentionTree);
    // Remove consecutive parallel lines
    for (let i = incidentLines.length - 1; i > 0; i--) {
      const angle = incidentLines[i].norm.angleTo(incidentLines[i - 1].norm);
      if (isParallelRad(0, angle, Math.PI * 0.01)) {
        incidentLines.splice(i, 1);
      }
    }

    const startingBoundary = Flatten.line(
      center,
      center.translate(normal.rotate90CCW()),
    );

    incidentLines.push(startingBoundary);
    incidentLines.unshift(startingBoundary);

    const roomLoop: CoilCoord3D[] = [];

    for (let i = 0; i < incidentLines.length - 1; i++) {
      const intersection = incidentLines[i].intersect(incidentLines[i + 1]);

      const angleToNext = incidentLines[i].norm.angleTo(
        incidentLines[i + 1].norm,
      );
      if (isParallelRad(0, angleToNext, Math.PI * 0.05)) {
        console.warn("Parallel lines in serpentine loop", {
          a: incidentLines[i],
          b: incidentLines[i + 1],
          uid: site.entity.uid,
          i,
          length: incidentLines.length,
        });
      }

      if (intersection.length > 0) {
        roomLoop.push({
          x: intersection[0].x,
          y: intersection[0].y,
          z: UFH_BELOW_FLOOR_HEIGHT_MM,
          turnRadiusMM: loopSpacingMM / 2,
          counterflowCenterDist: 50,
        });
      }
    }

    // remove duplicates
    for (let i = roomLoop.length - 1; i > 0; i--) {
      const curr = roomLoop[i];
      const prev = roomLoop[i - 1];
      const currP = Flatten.point(curr.x, curr.y);
      const prevP = Flatten.point(prev.x, prev.y);

      if (currP.distanceTo(prevP)[0] < IGNORE_COLLISION_EPS) {
        roomLoop.splice(i, 1);
      }
      if (currP.distanceTo(prevP)[0] < transitSpacingMM) {
        curr.maxRadiusMM = 10;
        curr.turnRadiusMM = 10;
        prev.maxRadiusMM = 10;
        prev.turnRadiusMM = 10;
      }
    }

    return LoopGenerator.smoothOutWholeLoop(
      {
        roomLoop,
        toManifold,
        fromManifold,
      },
      {
        minRadiusMM: site.underfloorHeating.minBendRadiusMM!,
        avoidCenterBulb: false,
        loopSpacingMM,
      },
    );
  }

  static generateSerpentineIntentions(
    obstacles: LoopObstacle[],
    center: Flatten.Point,
    normal: Flatten.Vector,
    loopSpacingMM: number,
    transitSpacingMM: number,
    loopDirectionDEG: number | null,
    debugObj?: RoomCalculation["debug"],
  ): {
    intentionTree: IntentionNode;
    debug: {
      color: string;
      chain: Flatten.Point[];
    }[];
  } {
    let intentionTree: SerpentineIntentionNode | null = {
      coord: center,
      next: [],
      state: "perimeter",
      spacingMM: transitSpacingMM,
    };

    const { paths, directionVector } =
      LoopGenerator.followWallBothSidesFromStart(
        obstacles,
        center,
        normal,
        loopSpacingMM,
        transitSpacingMM,
        loopDirectionDEG,
        "furtherest",
        debugObj,
      );

    const needsToFlowBack =
      paths.length >= 2 &&
      paths[0].sweepedSide !== paths[1].sweepedSide &&
      paths[0].sweepedLengthMM > loopSpacingMM &&
      paths[1].sweepedLengthMM > loopSpacingMM;

    let lastIntention = intentionTree;

    if (needsToFlowBack) {
      // There is _both_ a left and right side we need to cover. So travel
      // (along the wall) to the closest side so we can sweep back in one go.
      const bestFollow = paths.sort((a, b) => a.lengthMM - b.lengthMM)[0];

      // 2. Trace to the edge.
      // slice 2 to skip the first two points that are already in interactions.
      let isFirst = true;
      for (const path of bestFollow.path) {
        const thisIntention: SerpentineIntentionNode = {
          coord: path,
          next: [],
          state: "perimeter",
          spacingMM: isFirst ? transitSpacingMM : loopSpacingMM,
        };
        lastIntention.next.push(thisIntention);
        lastIntention = thisIntention;
        isFirst = false;
      }
    } else {
      // extend outwards
      const starts = LoopGenerator.getEntranceOffsetsMM(
        obstacles,
        center,
        normal,
        loopSpacingMM,
        transitSpacingMM,
      );
      const extendDistMM = Math.max(
        loopSpacingMM * LOOP_WALL_SPACING_MULT,
        starts.cw ?? 0,
        starts.ccw ?? 0,
      );
      const thisIntention: SerpentineIntentionNode = {
        coord: center.translate(normal.multiply(extendDistMM)),
        next: [],
        state: "perimeter",
        spacingMM: transitSpacingMM,
      };
      lastIntention.next.push(thisIntention);
      lastIntention = thisIntention;
    }
    foreachIntention(intentionTree, (from, to) => {
      obstacles.push({
        segment: Flatten.segment(from.coord, to.coord),
        radiusMM: to.spacingMM,
      });
      to.color = "#AA5555";
    });

    const extensions: SerpentineExtension[] = [];

    // 3. Start looping!
    const currPos = lastIntention!.coord;
    const currDirec = directionVector;

    const generated = this.generateSerpentinePart(
      lastIntention!,
      currDirec,
      obstacles,
      loopSpacingMM,
      transitSpacingMM,
      undefined,
      undefined,
      undefined,
      true,
    );

    if (!generated) {
      // Try again but let extensions happen from the starting one.
      lastIntention.state = "turn";
      this.generateSerpentinePart(
        lastIntention!,
        currDirec,
        obstacles,
        loopSpacingMM,
        transitSpacingMM,
      );
    }

    const seenExtensions = new Set<string>();
    // Find extensions available.
    for (let i = 0; i < 1000; i++) {
      extensions.splice(0, extensions.length);
      extensions.push(
        ...this.getSerpentineExtensions(
          intentionTree!,
          obstacles,
          loopSpacingMM,
          directionVector,
        ).filter((ext) => {
          const key = serializeSerpentineExtension(ext);
          if (seenExtensions.has(key)) {
            return false;
          }
          return true;
        }),
      );

      if (i >= MAX_SERPENTINE_EXTENSION_ITERATIONS) {
        break;
      }

      const evaluateExtension = (extension: SerpentineExtension) => {
        const length = extension.forceLength ?? extension.firstLength;
        switch (extension.type) {
          case "side-backtrack":
            return 100 + length / 1e6;
          case "straight":
            return 50 + length / 1e6;
          case "side":
            return 25 + length / 1e6;
          case "turn":
            return 0 + length / 1e6;
        }
      };

      extensions.sort((a, b) => evaluateExtension(b) - evaluateExtension(a));

      if (extensions.length === 0) {
        break;
      }

      for (const currExtension of extensions) {
        let generated = 0;

        if (currExtension.type === "straight") {
          generated += this.generateSerpentinePart(
            currExtension.intention,
            directionVector,
            obstacles,
            loopSpacingMM,
            transitSpacingMM,
            "straight",
          );
        } else if (currExtension.type === "turn") {
          generated += this.generateSerpentinePart(
            currExtension.intention,
            directionVector,
            obstacles,
            loopSpacingMM,
            transitSpacingMM,
            "turn",
          );
        } else {
          const newIntention: SerpentineIntentionNode = {
            coord: currExtension.intention.coord,
            next: currExtension.intention.next,
            state: currExtension.intention.state,
            spacingMM: loopSpacingMM,
          };
          currExtension.intention.coord = currExtension.startCoord;
          currExtension.intention.next = [newIntention];

          let thisGenerated = 0;
          if (
            isParallelRad(
              currExtension.direction.angleTo(directionVector),
              0,
              Math.PI * 0.05,
            )
          ) {
            // turn first
            thisGenerated = this.generateSerpentinePart(
              currExtension.intention,
              directionVector,
              obstacles,
              loopSpacingMM,
              transitSpacingMM,
              "straight",
              directionVector.dot(currExtension.direction) > 0 ? 1 : -1,
            );
          } else {
            thisGenerated = this.generateSerpentinePart(
              currExtension.intention,
              directionVector,
              obstacles,
              loopSpacingMM,
              transitSpacingMM,
              "turn",
              null,
              currExtension.direction.dot(directionVector.rotate90CW()) > 0
                ? "cw"
                : "ccw",
            );
          }
          generated += thisGenerated;

          if (!thisGenerated) {
            // backtrack the intention split
            currExtension.intention.coord = newIntention.coord;
            currExtension.intention.next = newIntention.next;
          }
        }

        if (generated > 0) {
          break;
        } else {
          seenExtensions.add(serializeSerpentineExtension(currExtension));
        }
      }
    }

    let i = 0;
    foreachIntention(intentionTree, (from, to) => {
      i++;
      let color = to.color ?? (i % 2 === 0 ? "#FF00FF" : "#00FF00");
      if (to.spacingMM < 70) {
        color = "#550022";
      }
      debugObj?.push({
        color,
        chain: [from.coord, to.coord],
        role: "loop",
      });
    });

    return {
      intentionTree: LoopGenerator.sanitizeIntentionTree(intentionTree!),
      debug: extensions
        .map((ext) => ({
          color:
            ext.type === "straight"
              ? "#FF0000"
              : ext.type === "turn"
                ? "#00FF00"
                : ext.type === "side"
                  ? "#00FFFF"
                  : "#FF00FF",
          chain: [
            ext.startCoord,
            ext.startCoord.translate(
              ext.direction.normalize().multiply(ext.firstLength),
            ),
          ],
        }))
        .concat([
          {
            color: "#AA3300",
            chain: [
              Flatten.point(200, 200),
              Flatten.point(200, 200).translate(
                directionVector.normalize().multiply(300),
              ),
            ],
          },

          {
            color: "#FFAA77",
            chain: [
              Flatten.point(200, 200),
              Flatten.point(200, 200).translate(
                directionVector.rotate90CCW().normalize().multiply(200),
              ),
            ],
          },
        ])
        .concat(
          debugObj
            ? debugObj.map((d) => ({
                color: d.color,
                chain: d.chain.map((c) => Flatten.point(c.x, c.y)),
              }))
            : [],
        ),
    };
  }

  static getSerpentineExtensions(
    intentionRoot: SerpentineIntentionNode,
    obstacles: LoopObstacle[],
    loopSpacingMM: number,
    align: Flatten.Vector,
  ): SerpentineExtension[] {
    const extensions: SerpentineExtension[] = [];
    foreachIntention(intentionRoot, (from, to) => {
      if (from.coord.distanceTo(to.coord)[0] < IGNORE_COLLISION_EPS) {
        return;
      }
      // 1. Check for straight up extensions
      if (to.state === "straight") {
        // check both ways
        if (from.coord.distanceTo(to.coord)[0] < EPS) {
          return;
        }
        const direc = Flatten.vector(from.coord, to.coord).normalize();
        const result1 = LoopGenerator.traceToEnd({
          curr: to.coord,
          radius: loopSpacingMM,
          obstacles,
          direction: direc,
          onlyBlocks: true,
          hitLenienceMM: loopSpacingMM * 0.5,
        });
        if (result1 && result1.type !== "miss") {
          extensions.push({
            type: "straight",
            direction: direc,
            intention: to,
            startCoord: to.coord,
            firstLength: result1.point.distanceTo(to.coord)[0],
          });
        }
        const result2 = LoopGenerator.traceToEnd({
          curr: from.coord,
          radius: loopSpacingMM,
          obstacles,
          direction: direc.multiply(-1),
          onlyBlocks: true,
          hitLenienceMM: loopSpacingMM * 0.5,
        });
        if (result2 && result2.type !== "miss") {
          extensions.push({
            type: "straight",
            direction: direc.multiply(-1),
            intention: from,
            startCoord: from.coord,
            firstLength: result2.point.distanceTo(from.coord)[0],
          });
        }
      } else if (to.state === "turn") {
        // check both ways
        const direc = Flatten.vector(from.coord, to.coord).normalize();
        const result1 = LoopGenerator.traceToEnd({
          curr: to.coord,
          radius: loopSpacingMM,
          obstacles,
          direction: direc,
          onlyBlocks: true,
          hitLenienceMM: loopSpacingMM * 0.5,
        });
        if (result1 && result1.type !== "miss") {
          extensions.push({
            type: "turn",
            direction: direc,
            intention: to,
            startCoord: to.coord,
            firstLength: result1.point.distanceTo(to.coord)[0],
          });
        }
        const result2 = LoopGenerator.traceToEnd({
          curr: from.coord,
          radius: loopSpacingMM,
          obstacles,
          direction: direc.multiply(-1),
          onlyBlocks: true,
          hitLenienceMM: loopSpacingMM * 0.5,
        });
        if (result2 && result2.type !== "miss") {
          extensions.push({
            type: "turn",
            direction: direc.multiply(-1),
            intention: from,
            startCoord: from.coord,
            firstLength: result2.point.distanceTo(from.coord)[0],
          });
        }
      }

      // 2. extensions with existing obstacles
      const maybeAddSideExtension = (
        from: SerpentineIntentionNode,
        to: SerpentineIntentionNode,
        start: Flatten.Point,
        direction: Flatten.Vector,
        forceLength?: number,
        type?: "side" | "side-backtrack",
      ) => {
        const mySeg = Flatten.segment(from.coord, to.coord);
        const myLine = Flatten.line(from.coord, to.coord);
        const proj = start.distanceTo(myLine)[1].end;
        if (
          proj.distanceTo(mySeg.start)[0] + proj.distanceTo(mySeg.end)[0] >
          mySeg.length + EPS
        ) {
          // not on the segment
          return;
        }
        const result = LoopGenerator.traceToEnd({
          curr: start,
          radius: loopSpacingMM,
          obstacles,
          direction,
          onlyBlocks: true,
          hitLenienceMM: loopSpacingMM * 0.5,
        });
        if (result && result.type !== "miss") {
          const firstLength = result.point.distanceTo(start)[0];
          if (forceLength !== undefined) {
            if (firstLength + loopSpacingMM * 0.5 < forceLength) {
              return;
            }
          }
          extensions.push({
            type: type ?? "side",
            direction,
            intention: to,
            startCoord: start,
            firstLength: result.point.distanceTo(start)[0],
            forceLength,
          });
        } else {
          // extensions.push({
          //   type: type ?? "side",
          //   direction,
          //   intention: to,
          //   startCoord: start,
          //   firstLength: loopSpacingMM / 2,
          // });
        }
      };
      if (to.state === "straight" || to.state === "perimeter") {
        const extendVector = (
          to.state === "straight" ? align.rotate90CCW() : align
        ).normalize();
        for (const guide of obstacles) {
          if (guide.segment.length < loopSpacingMM) {
            continue;
          }
          const myLine = Flatten.line(from.coord, to.coord);
          const myVec = Flatten.vector(from.coord, to.coord);
          const guideLine = Flatten.line(
            guide.segment.start,
            guide.segment.end,
          );
          const guideVector = Flatten.vector(
            guide.segment.start,
            guide.segment.end,
          );

          if (
            guide.segment.distanceTo(myLine)[0] >
            loopSpacingMM * 2 + guide.radiusMM
          ) {
            // too far away
            continue;
          }

          const angleToExtend = myVec.angleTo(guideVector);

          if (isParallelRad(0, myVec.angleTo(guideVector), Math.PI * 0.05)) {
            // parallel
            continue;
          }

          const ix = guideLine.intersect(myLine);
          const guideVecStart =
            guide.segment.start.distanceTo(ix[0])[0] < EPS
              ? Flatten.vector(0.0, 0.1)
              : Flatten.vector(guide.segment.start, ix[0]);
          const guideVecEnd =
            guide.segment.end.distanceTo(ix[0])[0] < EPS
              ? Flatten.vector(0.0, 0.1)
              : Flatten.vector(guide.segment.end, ix[0]);

          const crosses = [
            myVec.cross(guideVecStart),
            myVec.cross(guideVecEnd),
          ];

          if (
            isParallelRad(0, guideVector.angleTo(extendVector), Math.PI * 0.01)
          ) {
            // parallel. Try both sides, both directions.
            const ix = guideLine.intersect(myLine);
            const distance =
              (guide.radiusMM + loopSpacingMM) / Math.sin(angleToExtend);
            for (const [s1, s2] of [
              [1, 1],
              [1, -1],
              [-1, 1],
              [-1, -1],
            ]) {
              const effectiveExtendMul = s2 * -1;

              if (crosses.some((c) => c * effectiveExtendMul > 0)) {
                maybeAddSideExtension(
                  from,
                  to,
                  ix[0].translate(
                    myVec.normalize().multiply(distance).multiply(s1),
                  ),
                  myVec.rotate90CCW().normalize().multiply(s2),
                );
              }
            }
          } else {
            // At some angle to the line, we have one side that we extend a certain distance for, another side
            // we just move enough to escape the spacing.
            // Calculate how much we have to extend to miss the wall
            const angleA = myVec.angleTo(guideVector);
            const angleC = guideVector.angleTo(extendVector);
            const wallPadding =
              (guide.radiusMM + loopSpacingMM) / Math.abs(Math.sin(angleC));
            const aDist =
              wallPadding + loopSpacingMM * BACKTRAC_LOOP_SPACING_MUL;
            const distance = Math.abs(
              (aDist * Math.sin(angleC)) / Math.sin(angleA),
            );
            const myExtendCrossMul = myVec.cross(extendVector) > 0 ? 1 : -1;

            const myGuideCrossMul = myVec.cross(guideVector) > 0 ? 1 : -1;
            const guideExtendCrossMul =
              guideVector.cross(extendVector) > 0 ? 1 : -1;

            for (const ownDirection of [1, -1]) {
              const extendMul =
                ownDirection * guideExtendCrossMul * myGuideCrossMul;
              const effectiveExtendMul = myExtendCrossMul * extendMul * -1;
              if (crosses.some((c) => c * effectiveExtendMul > 0)) {
                // This is the one that extends into the wall a little bit because it was angled
                const startPoint = ix[0].translate(
                  myVec.normalize().multiply(distance).multiply(ownDirection),
                );

                maybeAddSideExtension(
                  from,
                  to,
                  startPoint,
                  extendVector.multiply(extendMul),
                  loopSpacingMM * 2,
                  to.next.length === 0 &&
                    to.coord.distanceTo(startPoint)[0] < loopSpacingMM * 4
                    ? "side-backtrack"
                    : "side",
                );

                const easyDist = Math.abs(
                  (guide.radiusMM + loopSpacingMM) / Math.sin(angleToExtend),
                );
                // This one is for right angle extend
                maybeAddSideExtension(
                  from,
                  to,
                  ix[0].translate(
                    myVec.normalize().multiply(easyDist).multiply(ownDirection),
                  ),
                  extendVector.multiply(extendMul),
                );
              }
            }
          }
        }
      }
    });

    return extensions;
  }

  static generateSerpentinePart(
    intentionNode: SerpentineIntentionNode,
    alignVector: Flatten.Vector,
    obstacles: LoopObstacle[],
    loopSpacingMM: number,
    transitSpacingMM: number,
    state: "straight" | "turn" = "straight",

    // *initial* direction of the straight part, relative to alignVector.
    straightDirection: 1 | -1 | null = null,

    // which way we are heading, relative to alignVector.
    creepDirection: "cw" | "ccw" | null = null,
    isInitial = false,
  ) {
    // Straight committed is when turning failed so we just go straight to either
    // complete the remaining loop, or jump past the next critical point.

    let currNode = intentionNode;
    let currPos = intentionNode.coord;
    let lengthGenerated = 0;

    const acceptPoint = (
      coord: Flatten.Point,
      state: "straight" | "turn" | "perimeter",
    ) => {
      if (coord.distanceTo(currPos)[0] < EPS) {
        console.warn("Zero length segment in serpentine generation");
      }
      const newNode: SerpentineIntentionNode = {
        coord,
        next: [],
        state,
        spacingMM: loopSpacingMM,
      };
      const length = currPos.distanceTo(coord)[0];
      lengthGenerated += length;
      currNode.next.push(newNode);

      obstacles.push({
        segment: Flatten.segment(currNode.coord, newNode.coord),
        radiusMM: loopSpacingMM,
      });
      currNode = newNode;
      currPos = coord;
    };

    for (let i = 0; i < 500; i++) {
      const printState = (msg?: string) => {
        console.log(msg, {
          i,
          state,
          creepDirection,
          currPos,
          straightDirection,
          alignVector,
        });
      };

      if (state === "straight") {
        let lenience = loopSpacingMM * 0.5;
        if (straightDirection === null) {
          // try both ways
          let result1 = LoopGenerator.traceToEnd({
            curr: currPos,
            radius: loopSpacingMM,
            obstacles,
            direction: alignVector,
            onlyBlocks: true,
            hitLenienceMM: lenience,
          });
          let result2 = LoopGenerator.traceToEnd({
            curr: currPos,
            radius: loopSpacingMM,
            obstacles,
            direction: alignVector.multiply(-1),
            onlyBlocks: true,
            hitLenienceMM: lenience,
          });
          if (result1 === null && result2 === null) {
            // false start due to this being immediately out the gate
            // and transit lines too close by
            lenience = loopSpacingMM * 1;
            result1 = LoopGenerator.traceToEnd({
              curr: currPos,
              radius: loopSpacingMM,
              obstacles,
              direction: alignVector,
              onlyBlocks: true,
              hitLenienceMM: lenience,
            });
            result2 = LoopGenerator.traceToEnd({
              curr: currPos,
              radius: loopSpacingMM,
              obstacles,
              direction: alignVector.multiply(-1),
              onlyBlocks: true,
              hitLenienceMM: lenience,
            });
          }
          if (traceResultCmp(result1, result2) >= 0) {
            straightDirection = 1;
          } else {
            straightDirection = -1;
          }
        }

        const result = LoopGenerator.traceToEnd({
          curr: currPos,
          radius: loopSpacingMM,
          obstacles,
          direction: alignVector.multiply(straightDirection),
          onlyBlocks: true,
          hitLenienceMM: lenience,
          minLengthMM: isInitial ? loopSpacingMM * 1.5 : loopSpacingMM * 0.5,
        });
        if (!result || result.type === "miss") {
          // printState("Failed to go straight. Exiting");
          break;
        }
        acceptPoint(result.point, "straight");
        state = "turn";
      } else if (state === "turn") {
        if (creepDirection === null) {
          const cwVec = alignVector.rotate90CW();
          const ccwVec = alignVector.rotate90CCW();
          const resultCW = LoopGenerator.traceToEnd({
            curr: currPos,
            radius: loopSpacingMM,
            obstacles,
            direction: cwVec,
            onlyBlocks: true,
          });
          const resultCCW = LoopGenerator.traceToEnd({
            curr: currPos,
            radius: loopSpacingMM,
            obstacles,
            direction: ccwVec,
            onlyBlocks: true,
          });

          if (traceResultCmp(resultCW, resultCCW) >= 0) {
            creepDirection = "cw";
          } else {
            creepDirection = "ccw";
          }
        }

        const progressVec = alignVector
          .rotate90CW()
          .multiply(creepDirection === "cw" ? 1 : -1)
          .normalize();

        const test = LoopGenerator.traceToEnd({
          curr: currPos,
          radius: loopSpacingMM,
          obstacles,
          direction: progressVec,
          minLengthMM: loopSpacingMM * 1.2,
          onlyBlocks: true,
          hitLenienceMM: loopSpacingMM * 0.5,
        });
        if (!test || test.type === "miss") {
          break;
        }

        // should be forgiving if wall is at a right angle to us, otherwise
        // don't be forgiving as we are running into an angle at a wall which
        // will only be "into" the wall a little bit.
        let shouldBeForgiving = false;
        if (test.type === "hit") {
          const hitVector = Flatten.vector(
            test.hit.segment.start,
            test.hit.segment.end,
          );
          const angle = hitVector.angleTo(progressVec);
          shouldBeForgiving = isRightAngleRad(angle, Math.PI * 0.1);
        }

        const movedLength = currPos.distanceTo(test.point)[0];
        const shiftDistanceMM = Math.min(
          loopSpacingMM * 2,
          shouldBeForgiving
            ? (movedLength + loopSpacingMM * 2) / 2
            : loopSpacingMM * 2,
        );
        acceptPoint(
          currPos.translate(progressVec.multiply(shiftDistanceMM)),
          "turn",
        );
        if (straightDirection !== null) {
          straightDirection *= -1;
        }
        state = "straight";
      }
    }

    return lengthGenerated;
  }
}
