import Flatten from "@flatten-js/core";
import { Coord, coordDist } from "../../lib/coord";
import { cloneSimple } from "../../lib/utils";
import { GetPressureLossOptions } from "../calculations/entity-pressure-drops";
import {
  CoreContext,
  CostBreakdown,
  PressureLossResult,
} from "../calculations/types";
import { getWallByFen } from "../calculations/utils";
import { CalculationConcrete } from "../document/calculations-objects/calculation-concrete";
import { FenLiveCalculation } from "../document/calculations-objects/fenestration-calculation";
import { CalculatableEntityConcrete } from "../document/entities/concrete-entity";
import {
  FenEntity,
  FenType,
  fillDefaultFenFields,
  getFenestrationName,
} from "../document/entities/fenestration-entity";
import { EntityType } from "../document/entities/types";
import { fillDefaultWallFields } from "../document/entities/wall-entity";
import { CoreVirtualEdge } from "./core-traits/coreVirtualEdge";
import { CoreCalculatableObject } from "./lib/CoreCalculatableObject";
import {
  addVectors,
  multiplyVector,
  normalizeVector,
  subtractVectors,
} from "./lib/geometry-utils";
import {
  externalSegmentDetermineDirectionCW,
  isFenManifestedOnRoomEdge,
  movePerpendicularByDistanceCW,
} from "./utils";

const Base = CoreVirtualEdge(CoreCalculatableObject<FenEntity>);

export default class CoreFen extends Base {
  type: EntityType.FENESTRATION = EntityType.FENESTRATION;

  get refPath(): string {
    return `${this.entity.type}.${this.entity.fenType}`;
  }

  get filledEntity(): FenEntity {
    return fillDefaultFenFields(this.context, this.entity);
  }

  getFrictionPressureLossKPA(
    options: GetPressureLossOptions,
  ): PressureLossResult {
    throw new Error("Method not implemented.");
  }
  getCalculationEntities(context: CoreContext): CalculatableEntityConcrete[] {
    const entity = cloneSimple(this.entity);
    entity.uid = this.getCalculationUid(context);
    if (entity.polygonEdgeUid)
      (entity as any).polygonEdgeUid = [
        this.globalStore
          .getObjectOfTypeOrThrow(EntityType.EDGE, entity.polygonEdgeUid[0])
          .getCalculationEntities(context)[0].uid,
      ];
    return [entity];
  }
  collectCalculations(context: CoreContext): CalculationConcrete {
    return context.globalStore.getOrCreateCalculation(
      this.getCalculationEntities(context)[0],
    );
  }

  collectLiveCalculations(context: CoreContext): FenLiveCalculation {
    return context.globalStore.getOrCreateLiveCalculation(
      this.getCalculationEntities(context)[0],
    );
  }

  costBreakdown(context: CoreContext): CostBreakdown | null {
    // Don't have it
    return null;
  }
  getWorldSegments(): [Coord, Coord][] {
    if (!this.entity.polygonEdgeUid || !this.entity.offsetPosMM) {
      return [];
    }
    let roomEdge = this.globalStore.getObjectOfTypeOrThrow(
      EntityType.EDGE,
      this.entity.polygonEdgeUid[0],
    );
    let coords = roomEdge.worldEndpoints();
    let vec = subtractVectors(coords[1], coords[0]);
    vec = normalizeVector(vec);
    let point1 = addVectors(
      coords[0],
      multiplyVector(
        vec,
        this.entity.offsetPosMM -
          (this.entity.fenestration.lengthM || 0.8) * 500,
      ),
    );
    let point2 = addVectors(
      coords[0],
      multiplyVector(
        vec,
        this.entity.offsetPosMM +
          (this.entity.fenestration.lengthM || 0.8) * 500,
      ),
    );

    return [[point1, point2]];
  }

  getFenLengthMM(): number {
    const segments = this.getWorldSegments();
    return coordDist(segments[0][0], segments[0][1]);
  }

  // @ts-ignore
  get shape() {
    if (this.entity.fenType === FenType.DOOR) {
      // square inside
      const p = new Flatten.Polygon();
      let ls = this.getWorldSegments()[0];

      ls = externalSegmentDetermineDirectionCW(
        this.context,
        ls,
        this.entity.polygonEdgeUid ?? [],
      );
      let wallUid = getWallByFen(this.context.globalStore, this);
      if (wallUid === undefined) {
        return super.shape;
      }

      let wall = this.context.globalStore.getObjectOfTypeOrThrow(
        EntityType.WALL,
        wallUid,
      );
      let filledWall = fillDefaultWallFields(this.context, wall.entity);
      let baseWidth = filledWall.widthMM ?? 200;
      ls = movePerpendicularByDistanceCW(ls[0], ls[1], baseWidth);

      let filledEntity = fillDefaultFenFields(this.context, this.entity);
      const [p0prime, p1prime] =
        filledEntity.rotation < 180
          ? movePerpendicularByDistanceCW(ls[0], ls[1], -baseWidth)
          : movePerpendicularByDistanceCW(ls[0], ls[1], baseWidth);

      p.addFace([
        Flatten.point(ls[0].x, ls[0].y),
        Flatten.point(ls[1].x, ls[1].y),
        Flatten.point(p1prime.x, p1prime.y),
        Flatten.point(p0prime.x, p0prime.y),
      ]);
      return p;
    }
    return super.shape;
  }

  get isManifested(): boolean {
    const edgeUid = this.entity.polygonEdgeUid?.[0];
    if (!edgeUid) {
      return false;
    }
    const edge = this.context.globalStore.getObjectOfTypeOrThrow(
      EntityType.EDGE,
      edgeUid,
    );
    if (isFenManifestedOnRoomEdge(this, edge)) {
      return true;
    }
    return false;
  }

  get areaM2() {
    let filledEntity = fillDefaultFenFields(this.context, this.entity);
    return (
      (filledEntity.fenestration.lengthM ?? 0) *
      (filledEntity.fenestration.heightM ?? 0)
    );
  }
  get friendlyTypeName(): string {
    return getFenestrationName(this.entity.fenType);
  }

  isInternalFene(): boolean {
    let wallUid = getWallByFen(this.globalStore, this);
    if (wallUid === undefined) return false;
    let wall = this.globalStore.getObjectOfTypeOrThrow(
      EntityType.WALL,
      wallUid,
    );

    return wall.isInternalWall();
  }
}
