import CoreLine from "../../../../common/src/api/coreObjects/coreLine";
import {
  findBoxFromLine,
  findVertexCFromLineBC,
  isLineAB,
} from "../../../../common/src/api/document/entities/annotations/utils";
import { DrawableEntityConcrete } from "../../../../common/src/api/document/entities/concrete-entity";
import {
  LineEntity,
  fillDefaultLineFields,
} from "../../../../common/src/api/document/entities/lines/line-entity";
import { EntityType } from "../../../../common/src/api/document/entities/types";
import { assertUnreachable } from "../../../../common/src/lib/utils";
import { MoveIntent } from "../lib/black-magic/cool-drag";
import CanvasContext from "../lib/canvas-context";
import { EntityDrawingArgs } from "../lib/drawable-object";
import { EntityPopupContent } from "../lib/entity-popups/types";
import { Interaction, InteractionType } from "../lib/interaction";
import CoolDraggableObject from "../lib/object-traits/cool-draggable-object";
import { Core2Drawable } from "../lib/object-traits/core2drawable";
import { EdgeObject } from "../lib/object-traits/edge-object";
import {
  HoverSiblingResult,
  HoverableObject,
} from "../lib/object-traits/hoverable-object";
import { SelectableObject } from "../lib/object-traits/selectable";
import { SnappableObject } from "../lib/object-traits/snappable-object";
import { DrawingContext, ObjectConstructArgs } from "../lib/types";
import {
  DrawableObjectConcrete,
  isHoverableObjectAny,
} from "./concrete-object";
import DrawableAnnotation from "./drawableAnnotation";
import { applyHoverEffects } from "./utils";

const Base = SelectableObject(
  CoolDraggableObject(
    HoverableObject(SnappableObject(EdgeObject(Core2Drawable(CoreLine)))),
  ),
);

export const MIN_LINE_PIXEL_WIDTH = 0.5;

export default class DrawableLine extends Base {
  type: EntityType.LINE = EntityType.LINE;

  // We could not add this to the base drawable class because
  // of some typescript thing so they have to be added at the concrete class.
  constructor(args: ObjectConstructArgs<LineEntity>) {
    super(args.context, args.obj);
    this.onSelect = args.onSelect;
    this.onInteractionComplete = args.onInteractionComplete;
    this.document = args.document;
  }

  getHoverSiblings(): HoverSiblingResult[] {
    const result: HoverSiblingResult[] = [];

    for (const endpoint of this.entity.endpointUid) {
      const o = this.globalStore.get(endpoint);
      if (o && isHoverableObjectAny(o) && !o.isHovering) {
        result.push({
          object: o,
          cascade: true,
        });
      }
    }

    return result;
  }

  getPopupContent(): EntityPopupContent[] | null {
    return null;
  }

  drawEntity(context: DrawingContext, args: EntityDrawingArgs): void {
    if (!this.isActive()) {
      return;
    }

    applyHoverEffects(context, this);
    if (args.selected) {
      context.ctx.globalAlpha = 0.5;
    }

    const { graphics, ctx } = context;
    const s = graphics.worldToSurfaceScale;
    const targetWidth = 1;

    const baseWidth = Math.max(
      MIN_LINE_PIXEL_WIDTH / s,
      targetWidth / graphics.unitWorldLength,
      (MIN_LINE_PIXEL_WIDTH / s) * (5 + Math.log(s)),
    );
    this.lastDrawnWidthInternal = baseWidth;
    ctx.lineCap = "round";

    const baseColor: string = fillDefaultLineFields(context, this.entity).color!
      .hex;

    ctx.strokeStyle = baseColor;
    ctx.fillStyle = baseColor;
    ctx.beginPath();
    ctx.strokeStyle = baseColor;
    ctx.lineWidth = baseWidth;

    ctx.moveTo(this.worldEndpoints()[0].x, this.worldEndpoints()[0].y);
    ctx.lineTo(this.worldEndpoints()[1].x, this.worldEndpoints()[1].y);
    ctx.stroke();
  }

  isActive() {
    const box = findBoxFromLine(this.context, this);
    if (box) {
      return (box as DrawableAnnotation).isActive();
    }

    return true;
  }

  prepareDelete(
    context: CanvasContext,
    calleeEntityUid?: string,
  ): DrawableObjectConcrete[] {
    let conns: string[] = this.entity.endpointUid;
    if (calleeEntityUid) {
      conns = conns.filter((c) => c !== calleeEntityUid);
    }

    const entities = conns.map((c) => context.globalStore.get(c));
    const toDelete = entities
      .map((e) => e?.prepareDelete(context, this.uid))
      .filter(Boolean)
      .flat();

    return [this, ...toDelete];
  }

  getCoolDragCorrelations(
    _myMove: MoveIntent,
    _from?: DrawableObjectConcrete | undefined,
  ): { object: DrawableObjectConcrete; move: MoveIntent }[] {
    const res: DrawableObjectConcrete[] = [this];

    const box = findBoxFromLine(this.context, this);
    if (box) {
      res.push(box as DrawableObjectConcrete);
    }

    if (!isLineAB(this.context, this)) {
      const vertexC = findVertexCFromLineBC(this.context, this);
      if (vertexC) {
        res.push(vertexC as DrawableObjectConcrete);
      }
    }

    return res.map((obj: DrawableObjectConcrete) => {
      return {
        object: obj,
        move: {
          type: "literal",
          dontPreserveAngle: true,
        },
      };
    });
  }

  offerInteraction(interaction: Interaction): DrawableEntityConcrete[] | null {
    if (!this.isActive()) return null;
    switch (interaction.type) {
      case InteractionType.INSERT:
        return [this.entity];
      case InteractionType.SNAP_ONTO_RECEIVE:
      case InteractionType.EXTEND_NETWORK:
      case InteractionType.CONTINUING_CONDUIT:
      case InteractionType.STARTING_CONDUIT:
      case InteractionType.SNAP_ONTO_SEND:
      case InteractionType.LINK_ENTITY:
        return null;
      default:
        assertUnreachable(interaction);
    }

    return null;
  }

  getCopiedObjects(): DrawableObjectConcrete[] {
    return this.entity.endpointUid.map(
      (uid) => this.globalStore.get(uid)! as DrawableObjectConcrete,
    );
  }
}
