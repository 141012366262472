import { DrawingState } from "../../../../common/src/api/document/drawing";
import { initialDrawing } from "../../../../common/src/api/document/initial-drawing";
import { SupportedLocales } from "../../../../common/src/api/locale";
import { reconstructDrawing } from "../../../../common/src/api/upgrade";
import { immutableAppend } from "../../../../common/src/lib/utils";
import { DiffRecordT } from "../../../../flatbuffers/generated/diff-record";
import { ActiveReplayState } from "../../../src/store/document/types";
import { getDocumentHistorySnapshot } from "../../api/document";
import { MainEventBus } from "../../store/main-event-bus";
import store from "../../store/store";

export const getDrawingStateAtOrderIndex = async (
  documentId: number,
  orderIndex: number,
) => {
  const locale = store.getters["document/locale"] as SupportedLocales;
  if (orderIndex === 0) {
    // if order index is 0, 'getDocumentHistorySnapshot'/'reconstructDrawing' don't seem to correctly return empty document
    return initialDrawing(locale);
  }
  const response = await getDocumentHistorySnapshot(
    documentId,
    orderIndex,
    undefined,
  );
  if (!response.success)
    throw `Failed to get document history snapshot for orderIndex ${orderIndex} on document ${documentId}`;
  const { closestSnapshot, followingOperations, startVersion } = response.data;
  const historicalDrawing = reconstructDrawing(
    closestSnapshot,
    startVersion,
    followingOperations,
    locale,
  );
  return historicalDrawing;
};

export const renderDrawingState = (drawingState: DrawingState) => {
  store.dispatch("document/swapDrawing", drawingState);
  MainEventBus.$emit("redraw");
};

export const renderDocumentAtOrderIndex = async (
  documentId: number,
  orderIndex: number,
) => {
  const historicalDrawing = await getDrawingStateAtOrderIndex(
    documentId,
    orderIndex,
  );
  renderDrawingState(historicalDrawing);
};

export const stringifyDiffEvent = (e: DiffRecordT) => {
  return `${e.userName} triggered diff ${e.orderIndex} on document ${e.documentId} from diff ${e.previousOrderIndex}`;
};

export const renderDiffRecord = async (
  event: DiffRecordT,
  replayState: ActiveReplayState,
) => {
  const newReplayState: ActiveReplayState = {
    ...replayState,
    eventsToLog: immutableAppend(replayState.eventsToLog, event),
  };
  return newReplayState;
};
