import Flatten from "@flatten-js/core";
import * as TM from "transformation-matrix";
import CoreVelux from "../../../../common/src/api/coreObjects/coreArchitectureElement";
import { CalculationData } from "../../../../common/src/api/document/calculations-objects/calculation-field";
import {
  ArchitectureElementEntity,
  fillDefaultArcFields,
} from "../../../../common/src/api/document/entities/architectureElement-entity";
import { DrawableEntityConcrete } from "../../../../common/src/api/document/entities/concrete-entity";
import { EntityType } from "../../../../common/src/api/document/entities/types";
import { Coord, Coord3D } from "../../../../common/src/lib/coord";
import { pointInsidePolygon } from "../../../../common/src/lib/mathUtils/mathutils";
import { assertUnreachable } from "../../../../common/src/lib/utils";
import { MainEventBus } from "../../store/main-event-bus";
import CanvasContext from "../lib/canvas-context";
import { EntityDrawingArgs } from "../lib/drawable-object";
import { EntityPopupContent } from "../lib/entity-popups/types";
import { Interaction } from "../lib/interaction";
import { CalculatedObject } from "../lib/object-traits/calculated-object";
import { Core2Drawable } from "../lib/object-traits/core2drawable";
import { DraggableObject } from "../lib/object-traits/draggable-object";
import {
  HoverSiblingResult,
  HoverableObject,
} from "../lib/object-traits/hoverable-object";
import { SelectableObject } from "../lib/object-traits/selectable";
import {
  SnapIntention,
  SnapTarget,
  SnappableObject,
} from "../lib/object-traits/snappable-object";
import { DrawingContext, ObjectConstructArgs } from "../lib/types";
import { DrawingMode } from "../types";
import { DrawableObjectConcrete } from "./concrete-object";
import { drawDimensionBaseLevel } from "./utils";

const Base = CalculatedObject(
  SelectableObject(
    DraggableObject(HoverableObject(SnappableObject(Core2Drawable(CoreVelux)))),
  ),
);

export default class DrawableVelux extends Base {
  type: EntityType.ARCHITECTURE_ELEMENT = EntityType.ARCHITECTURE_ELEMENT;

  // We could not add this to the base drawable class because
  // of some typescript thing so they have to be added at the concrete class.
  constructor(args: ObjectConstructArgs<ArchitectureElementEntity>) {
    super(args.context, args.obj);
    this.onSelect = args.onSelect;
    this.onInteractionComplete = args.onInteractionComplete;
    this.document = args.document;
  }
  getSnapTargets(_request: SnapIntention[], _mouseWc: Coord): SnapTarget[] {
    throw new Error("Method not implemented.");
  }
  getHoverSiblings(): HoverSiblingResult[] {
    const result: HoverSiblingResult[] = [];
    result.push({
      object: this,
      cascade: false,
    });

    return result;
  }
  getPopupContent(): EntityPopupContent[] | null {
    return null;
  }

  locateCalculationBoxWorld(
    _context: DrawingContext,
    _data: CalculationData[],
    _scale: number,
  ): TM.Matrix[] {
    throw new Error("Method not implemented.");
  }
  drawEntity(context: DrawingContext, _args: EntityDrawingArgs): void {
    const filled = fillDefaultArcFields(context, this.entity);
    context.vp.prepareContext(context.ctx);
    const { ctx } = context;
    const oldTransform = ctx.getTransform();
    const oldStrokeStyle = ctx.strokeStyle;
    const oldFillStyle = ctx.fillStyle;
    const oldGlobalAlpha = ctx.globalAlpha;

    let baseColor = filled.color?.hex ?? "#64aa4c";
    if (this.entity.parentUid) {
      const parentEntity = this.globalStore.getObjectOfTypeOrThrow(
        EntityType.ROOM,
        this.entity.parentUid,
      );
      const polygonCw = parentEntity
        .collectVerticesInOrder()
        .map((v) => v.toWorldCoord());
      if (!pointInsidePolygon(this.entity.center, polygonCw)) {
        baseColor = "#D0342C";
      }
    }
    ctx.lineWidth = 1;
    ctx.beginPath();

    const polygonCW = this.getPolygonCW();
    ctx.moveTo(polygonCW[0].x, polygonCW[0].y);
    for (let i = 1; i < polygonCW.length; i++) {
      ctx.lineTo(polygonCW[i].x, polygonCW[i].y);
    }
    ctx.lineTo(polygonCW[0].x, polygonCW[0].y);
    ctx.closePath();
    ctx.strokeStyle = baseColor;
    ctx.stroke();
    ctx.fillStyle = baseColor;
    ctx.globalAlpha = 0.5;
    ctx.fill();
    ctx.strokeStyle = oldStrokeStyle;
    ctx.fillStyle = oldFillStyle;
    ctx.globalAlpha = oldGlobalAlpha;
    ctx.setTransform(oldTransform);

    // Draw Measurements
    if (this.shouldShowDimension(context)) {
      for (let i = 1; i <= polygonCW.length; i++) {
        drawDimensionBaseLevel(
          context,
          ctx,
          [polygonCW[i - 1], polygonCW[i % polygonCW.length]],
          0,
          100,
        );
      }
    }
  }

  isActive(): boolean {
    switch (this.document.uiState.drawingMode) {
      case DrawingMode.FloorPlan:
      case DrawingMode.Export:
      case DrawingMode.History:
      case DrawingMode.Calculations:
        return true;

      case DrawingMode.Design:
        if (this.document.uiState.drawingLayout === "mechanical") {
          return true;
        }
        return false;
    }
    assertUnreachable(this.document.uiState.drawingMode);
  }
  shouldShowDimension(context: DrawingContext) {
    if (context.selectedUids.has(this.uid)) return true;
  }

  inBounds(
    oc: Coord | Coord3D,
    _radius: number = 0,
    shape: Flatten.Polygon = this.shape,
  ): boolean {
    const wc = this.toWorldCoord(oc);
    let inside = false;
    const wcPoint = new Flatten.Point(wc.x, wc.y);
    const line = new Flatten.Segment(
      wcPoint,
      new Flatten.Point(wc.x + 1e7, wc.y),
    );
    const intersections = shape.intersect(line);
    inside = intersections.length % 2 === 1;
    if (inside) {
      const vertices = shape.vertices;
      const segs: Flatten.Segment[] = [];
      for (let i = 0; i < vertices.length; i++) {
        const a = vertices[i],
          b = vertices[(i + 1) % vertices.length];
        const seg = new Flatten.Segment(a, b);
        segs.push(seg);
      }

      segs.forEach((seg) => {
        if (seg.distanceTo(wcPoint)[0] < 1) {
          inside = false;
        }
      });
    }
    return inside;
    // throw new Error("Method not implemented.");
  }

  inBoundsWorld(
    wc: Coord,
    radius: number = 0,
    shape: Flatten.Polygon = this.shape,
  ): boolean {
    return this.inBounds(this.toObjectCoord(wc), radius, shape);
  }

  prepareDelete(
    _context: CanvasContext,
    _calleeEntityUid?: string,
  ): DrawableObjectConcrete[] {
    const result: DrawableObjectConcrete[] = [];
    result.push(this);
    return result;
  }
  offerInteraction(_interaction: Interaction): DrawableEntityConcrete[] | null {
    // TODO: implement
    return null;
  }

  onDragStart(
    _event: MouseEvent,
    _objectCoord: Coord,
    _context: CanvasContext,
    _isMultiDrag: boolean,
  ): any {}
  onDrag(
    _event: MouseEvent,
    grabbedObjectCoord: Coord,
    eventObjectCoord: Coord,
    _grabState: any,
    _context: CanvasContext,
    _isMultiDrag: boolean,
  ): void {
    const targetCoord = {
      x: this.entity.center.x + eventObjectCoord.x - grabbedObjectCoord.x,
      y: this.entity.center.y + eventObjectCoord.y - grabbedObjectCoord.y,
    };
    if (this.entity.parentUid) {
      const parentEntity = this.globalStore.getObjectOfTypeOrThrow(
        EntityType.ROOM,
        this.entity.parentUid,
      );
      const polygonCw = parentEntity
        .collectVerticesInOrder()
        .map((v) => v.toWorldCoord());

      if (!pointInsidePolygon(targetCoord, polygonCw)) {
        return;
      }
    }

    this.entity.center.x += eventObjectCoord.x - grabbedObjectCoord.x;
    this.entity.center.y += eventObjectCoord.y - grabbedObjectCoord.y;
  }

  onDragFinish(
    event: MouseEvent,
    _context: CanvasContext,
    _isMultiDrag: boolean,
  ): void {
    this.onInteractionComplete(event);
    MainEventBus.$emit("validate-and-commit");
  }

  getCopiedObjects(): DrawableObjectConcrete[] {
    return [];
  }
}
