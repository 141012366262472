// Used to provide informative and friendly error messages to the user

import { GlobalFDR } from "./global-fdr";

export interface ExecutionStep {
  text: string;
  data: string[];
  focusedData?: string[];
}

export const TESTING_BREAKPOINT_ERROR = "Testing breakpoint reached";

// decorator to take a function with CalculationEngine as its first parameter and call engine.log() on it
export function TraceCalculation<A extends Array<any>>(
  name?: string,
  getData?: (...args: A) => string[],
) {
  return function (
    // accepts classes that statically implement FlightDataRecorder, not just CalculationEngine
    target: any,
    propertyKey: string,
    descriptor: TypedPropertyDescriptor<(...args: A) => any>,
  ) {
    if (!descriptor?.value) {
      // Probably playwright, skip it.
      return descriptor;
    }

    const originalMethod = descriptor.value;
    descriptor.value = function (...rest: A) {
      name = name || (propertyKey as any);
      if (GlobalFDR.breakAt && GlobalFDR.breakAt === name) {
        throw new Error(TESTING_BREAKPOINT_ERROR);
      }

      if (GlobalFDR.shouldBreak && GlobalFDR.shouldBreak(name!, ...rest)) {
        throw new Error(TESTING_BREAKPOINT_ERROR);
      }

      const step: ExecutionStep = {
        text: name!,
        data: getData ? getData(...rest) : [],
      };
      GlobalFDR.pushLog(step);

      const result = originalMethod?.apply(this, rest);

      // if result is promise
      if (result && result.then) {
        result
          .then(() => {
            GlobalFDR.popLog();
          })
          .catch((e: any) => {
            if (
              e.message === TESTING_BREAKPOINT_ERROR ||
              GlobalFDR.errorMsg === TESTING_BREAKPOINT_ERROR
            ) {
              // This is fine, we just hit the desired breakpoint to run the test
              console.log("Testing breakpoint hit 4");
            } else {
              throw e;
            }
          });
      } else {
        GlobalFDR.popLog();
      }

      return result;
    };
    return descriptor;
  };
}
