import { Units } from "../../../../lib/measurements";
import {
  assertUnreachable,
  cloneSimple,
  parseCatalogNumberExact,
  parseCatalogNumberOrMin,
} from "../../../../lib/utils";
import { CoreContext } from "../../../calculations/types";
import { Catalog } from "../../../catalog/types";
import {
  SupportedDrainageMethods,
  SupportedPsdStandards,
  isGermanStandard,
  isLUStandard,
  isPressure,
} from "../../../config";
import { I18N } from "../../../locale/values";
import { DrawingState } from "../../drawing";
import { getFlowSystem } from "../../utils";
import { FieldType, PropertyField } from "../property-field";
import {
  DrawableEntity,
  NamedEntity,
  PositionedEntity,
} from "../simple-entities";
import { EntityType } from "../types";

export interface RoughInRecord {
  uid: string;
  minPressureKPA: number | null;
  maxPressureKPA: number | null;
  loadingUnits: number | null;
  designFlowRateLS: number | null;
  continuousFlowLS: number | null;
  allowAllSystems: boolean;
}

export default interface FixtureEntity
  extends DrawableEntity,
    NamedEntity,
    PositionedEntity {
  type: EntityType.FIXTURE;
  name: string;
  abbreviation: string;

  roughIns: {
    [key: string]: RoughInRecord;
  };
  roughInsInOrder: string[];

  pipeDistanceMM: number;
  outletAboveFloorM: number | null;
  warmTempC: number | null;

  drainageFixtureUnits: number | null;

  loadingUnitVariant?: string | null;
}

export function makeFixtureFields(
  context: CoreContext,
  entity: FixtureEntity,
): PropertyField[] {
  const { locale, drawing } = context;

  const res: PropertyField[] = [
    {
      property: "entityName",
      title: "Name",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Text,
      params: null,
      multiFieldId: "entityName",
    },
    {
      property: "pipeDistanceMM",
      title: "Size",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Number,
      params: { min: 100, max: 200, step: 10 },
      multiFieldId: "pipeDistanceMM",
      units: Units.Percent,
    },
    {
      property: "rotation",
      title: "Rotation: (Degrees)",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Rotation,
      params: {
        step: 45,
        disableFreeInput: false,
      },
      multiFieldId: null,
    },
    {
      property: "outletAboveFloorM",
      title: "Height Above Floor",
      hasDefault: true,
      highlightOnOverride: true,
      isCalculated: false,
      type: FieldType.Number,
      params: { min: null, max: null },
      multiFieldId: "outletAboveFloorM",
      units: Units.Meters,
    },
  ];

  const psdStrategy = drawing
    ? drawing.metadata.calculationParams.psdMethod
    : SupportedPsdStandards.as35002021LoadingUnits;

  const tabContainer = createFixtureTabs(context, entity);
  res.push(tabContainer);

  if (psdStrategy === SupportedPsdStandards.cibseGuideG) {
    res.splice(0, 0, {
      property: "loadingUnitVariant",
      title: "Loading Unit Variant",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Choice,
      params: {
        choices: [
          { name: "Low", key: "low" },
          { name: "Medium", key: "medium" },
          { name: "High", key: "high" },
        ],
      },
      multiFieldId: null,
    });
  }

  return res;
}

export function createFixtureTabs(
  context: CoreContext,
  entity: FixtureEntity,
): PropertyField {
  const { drawing, locale } = context;
  const psdStrategy = drawing
    ? drawing.metadata.calculationParams.psdMethod
    : SupportedPsdStandards.as35002021LoadingUnits;

  const tabContainer: PropertyField = {
    type: FieldType.Tabs,
    id: "fixture-tabs",
    tabs: [],
  };

  // create non-drainage tabs
  for (const suid of Object.keys(entity.roughIns)) {
    if (isPressure(drawing.metadata.flowSystems[suid])) {
      const system = getFlowSystem(drawing, suid)!;

      tabContainer.tabs.push({
        tabId: suid + "-tab",
        tabName: system.name,
        fields: [
          {
            property: "roughIns." + suid + ".allowAllSystems",
            title: "Allow Other Systems to Connect?",
            hasDefault: false,
            isCalculated: false,
            type: FieldType.Boolean,
            params: null,
            multiFieldId: suid + "-allowAllSystems",
          },

          {
            property: "roughIns." + suid + ".designFlowRateLS",
            title: "Full Flow Rate",
            hasDefault: true,
            highlightOnOverride: true,
            isCalculated: false,
            type: FieldType.Number,
            params: { min: 0, max: null },
            multiFieldId: suid + "-designFlowRateLS",
            units: Units.LitersPerSecond,
            hideFromPropertyWindow: isLUStandard(psdStrategy),
          },

          {
            property: "roughIns." + suid + ".continuousFlowLS",
            title: "Continuous Flow",
            hasDefault: true,
            highlightOnOverride: true,
            isCalculated: false,
            type: FieldType.Number,
            params: { min: 0, max: null },
            multiFieldId: suid + "-continuousFlowLS",
            units: Units.LitersPerSecond,
          },
          {
            property: "roughIns." + suid + ".loadingUnits",
            title: I18N.loadingUnits[locale],
            hasDefault: true,
            highlightOnOverride: true,
            isCalculated: false,
            type: FieldType.Number,
            params: { min: 0, max: null },
            multiFieldId: suid + "-loadingUnits",
            hideFromPropertyWindow: !isLUStandard(psdStrategy),
          },
          {
            property: "roughIns." + suid + ".minPressureKPA",
            title: "Min. Inlet Pressure",
            hasDefault: true,
            highlightOnOverride: true,
            isCalculated: false,
            type: FieldType.Number,
            params: { min: 0, max: null },
            multiFieldId: suid + "-minPressureKPA",
            units: Units.KiloPascals,
          },
          {
            property: "roughIns." + suid + ".maxPressureKPA",
            title: "Max. Inlet Pressure",
            hasDefault: true,
            highlightOnOverride: true,
            isCalculated: false,
            type: FieldType.Number,
            params: { min: 0, max: null },
            multiFieldId: suid + "-maxPressureKPA",
            units: Units.KiloPascals,
          },
        ],
      });
    }
  }

  // drainage
  tabContainer.tabs.push({
    tabId: "drainage-tab",
    tabName: "Drainage",
    fields: [
      {
        property: "drainageFixtureUnits",
        title: "Drainage Fixture Unit",
        hasDefault: true,
        highlightOnOverride: true,
        isCalculated: false,
        type: FieldType.Number,
        params: { min: 0, max: null },
        multiFieldId: "asnzFixtureUnits",
      },
    ],
  });

  return tabContainer;
}

export function fillFixtureFields(
  context: CoreContext,
  entity: FixtureEntity,
): FixtureEntity {
  const { drawing, catalog } = context;
  const result = cloneSimple(entity);

  if (result.warmTempC === null) {
    result.warmTempC = parseCatalogNumberOrMin(
      catalog.fixtures[result.name]["warmTempC"],
    );
  }

  if (result.outletAboveFloorM === null) {
    result.outletAboveFloorM = parseCatalogNumberOrMin(
      catalog.fixtures[result.name]["outletAboveFloorM"],
    );
  }

  if (result.drainageFixtureUnits === null) {
    result.drainageFixtureUnits = getCatalogFixtureDrainageUnits({
      fixtureName: result.name,
      catalog,
      drawing,
    });
  }

  const psdStrategy = drawing
    ? drawing.metadata.calculationParams.psdMethod
    : SupportedPsdStandards.as35002021LoadingUnits;

  if (
    result.loadingUnitVariant === undefined ||
    result.loadingUnitVariant === null
  ) {
    result.loadingUnitVariant =
      drawing?.metadata.calculationParams.loadingUnitVariant || "low";
  }

  for (const systemUid of Object.keys(result.roughIns)) {
    if (isPressure(drawing.metadata.flowSystems[systemUid])) {
      const target = result.roughIns[systemUid];
      if (target.minPressureKPA === null) {
        target.minPressureKPA = parseCatalogNumberExact(
          catalog.fixtures[result.name].minInletPressureKPA,
        );
      }
      if (target.maxPressureKPA === null) {
        target.maxPressureKPA = parseCatalogNumberExact(
          catalog.fixtures[result.name].maxInletPressureKPA,
        );
      }

      if (target.designFlowRateLS === null) {
        target.designFlowRateLS = getFixtureDesignFlowRate({
          fixtureName: result.name,
          systemUid,
          drawing,
          catalog,
        });
      }

      if (isLUStandard(psdStrategy)) {
        if (target.loadingUnits === null) {
          target.loadingUnits = getFixtureLoadingUnits({
            fixtureName: result.name,
            systemUid,
            psdStrategy,
            loadingUnitVariant: result.loadingUnitVariant,
            catalog,
          });
        }
      }

      if (target.continuousFlowLS === null) {
        target.continuousFlowLS = getFixtureContinuousFlow({
          fixtureName: result.name,
          systemUid,
          catalog,
        });
      }
    }
  }

  return result;
}

export function getFixtureLoadingUnits(options: {
  fixtureName: string;
  systemUid: string;
  fallbackSystemUid?: string;
  psdStrategy: SupportedPsdStandards;
  loadingUnitVariant: string;
  catalog: Catalog;
}) {
  const {
    fixtureName,
    psdStrategy,
    loadingUnitVariant,
    systemUid,
    catalog,
    fallbackSystemUid,
  } = options;

  if (isLUStandard(psdStrategy)) {
    if (psdStrategy === SupportedPsdStandards.cibseGuideG) {
      if (catalog.fixtures[fixtureName].loadingUnits[psdStrategy][systemUid]) {
        return parseCatalogNumberOrMin(
          catalog.fixtures[fixtureName].loadingUnits[psdStrategy][
            loadingUnitVariant
          ],
        );
      } else if (
        fallbackSystemUid &&
        catalog.fixtures[fixtureName].loadingUnits[psdStrategy][
          fallbackSystemUid
        ]
      ) {
        return parseCatalogNumberOrMin(
          catalog.fixtures[fixtureName].loadingUnits[psdStrategy][
            loadingUnitVariant
          ],
        );
      }
    } else {
      const LUs = catalog.fixtures[fixtureName].loadingUnits[psdStrategy];
      if (LUs[systemUid]) {
        return parseCatalogNumberOrMin(LUs[systemUid]);
      } else if (fallbackSystemUid && LUs[fallbackSystemUid]) {
        return parseCatalogNumberOrMin(LUs[fallbackSystemUid]);
      }
    }
  }
  return null;
}

export function getFixtureCalculationUnits(options: {
  fixtureName: string;
  systemUid: string;
  fallbackSystemUid?: string;
  psdStrategy: SupportedPsdStandards;
  loadingUnitVariant: string;
  catalog: Catalog;
  drawing: DrawingState;
}) {
  const { psdStrategy } = options;
  if (isGermanStandard(psdStrategy)) {
    return getFixtureDesignFlowRate(options);
  } else {
    return getFixtureLoadingUnits(options);
  }
}

export function getFixtureDesignFlowRate(options: {
  catalog: Catalog;
  fixtureName: string;
  drawing: DrawingState | undefined;
  systemUid: string;
  fallbackSystemUid?: string;
}) {
  const { catalog, fixtureName, drawing, systemUid, fallbackSystemUid } =
    options;
  const selectedMaterialManufacturer =
    drawing?.metadata?.catalog?.fixtures?.find(
      (obj) => obj.uid === fixtureName,
    );
  const manufacturer = selectedMaterialManufacturer?.manufacturer || "generic";
  const selectedOption = selectedMaterialManufacturer?.selected || "default";
  const qLS = catalog.fixtures[fixtureName].qLS[manufacturer][selectedOption];
  if (qLS[systemUid]) {
    return parseCatalogNumberOrMin(qLS[systemUid]);
  } else if (fallbackSystemUid && qLS[fallbackSystemUid]) {
    return parseCatalogNumberOrMin(qLS[fallbackSystemUid]);
  }
  return null;
}

export function getFixtureContinuousFlow(options: {
  fixtureName: string;
  catalog: Catalog;
  systemUid: string;
  fallbackSystemUid?: string;
}) {
  const { fixtureName, catalog, systemUid, fallbackSystemUid } = options;
  const continuousFlowLS = catalog.fixtures[fixtureName].continuousFlowLS;
  if (continuousFlowLS) {
    if (continuousFlowLS[systemUid]) {
      return parseCatalogNumberOrMin(continuousFlowLS[systemUid]);
    } else if (fallbackSystemUid && continuousFlowLS[fallbackSystemUid]) {
      return parseCatalogNumberOrMin(continuousFlowLS[fallbackSystemUid]);
    }
  }
  return 0;
}

export function getCatalogFixtureDrainageUnits(options: {
  fixtureName: string;
  catalog: Catalog;
  drawing: DrawingState;
}) {
  const { fixtureName, drawing, catalog } = options;
  switch (drawing.metadata.calculationParams.drainageMethod) {
    case SupportedDrainageMethods.AS2021FixtureUnits:
      return parseCatalogNumberExact(
        catalog.fixtures[fixtureName].asnzFixtureUnits,
      )!;
    case SupportedDrainageMethods.EN1205622000DischargeUnits:
      return parseCatalogNumberExact(
        catalog.fixtures[fixtureName].enDrainageSystem[
          drawing.metadata.calculationParams.drainageSystem
        ],
      )!;
    case SupportedDrainageMethods.UPC2018DrainageFixtureUnits:
      return parseCatalogNumberExact(
        catalog.fixtures[fixtureName].upcFixtureUnits,
      )!;
    case SupportedDrainageMethods.NCBIndia2016FlushTankPrivate:
      return parseCatalogNumberExact(
        catalog.fixtures[fixtureName].nbcIndiaFlushTankPrivateDFU,
      )!;
    case SupportedDrainageMethods.NCBIndia2016FlushTankPublic:
      return parseCatalogNumberExact(
        catalog.fixtures[fixtureName].nbcIndiaFlushTankPublicDFU,
      )!;
    case SupportedDrainageMethods.NBCIndia2016FlushValvePrivate:
      return parseCatalogNumberExact(
        catalog.fixtures[fixtureName].nbcIndiaFlushValvePrivateDFU,
      )!;
    case SupportedDrainageMethods.NBCIndia2016FlushValvePublic:
      return parseCatalogNumberExact(
        catalog.fixtures[fixtureName].nbcIndiaFlushValvePublicDFU,
      );
  }
  assertUnreachable(drawing.metadata.calculationParams.drainageMethod);
}
