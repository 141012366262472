import { Coord } from "../../../lib/coord";
import { getEntitiesByLevel } from "../../../lib/globalstore/utils";
import { polygonDirectedAreaM2 } from "../../../lib/mathUtils/mathutils";
import { polygonPolygonContains } from "../../../lib/mathUtils/polygonUtils";
import {
  convertMeasurementSystem,
  Precision,
  Units,
} from "../../../lib/measurements";
import { assertUnreachable, cloneSimple } from "../../../lib/utils";
import { SpaceType } from "../../catalog/heatload/types";
import {
  MeanWaterTempC,
  PipeDiameterMM,
  RoomTempC,
  UFHFloorData,
} from "../../catalog/types";
import { isUnderfloor } from "../../config";
import CoreRoom from "../../coreObjects/coreRoom";
import {
  FenEntity,
  FenType,
} from "../../document/entities/fenestration-entity";
import { ManifoldPlant } from "../../document/entities/plants/plant-types";
import {
  RoomEntity,
  RoomType,
} from "../../document/entities/rooms/room-entity";
import { EntityType } from "../../document/entities/types";
import { getFlowSystem } from "../../document/utils";
import { CoreContext } from "../types";
import UnionFind from "../union-find";
import { getEffectiveHeatLoad } from "../utils";
import {
  UnderfloorHeatingSettings,
  UnderfloorHeatingSettingsV3,
} from "./types";

// Helper function, select a room among multiple room entities to represent that room
export function selectLeaderRoom(
  context: CoreContext,
  rooms: RoomEntity[],
  cacheRoomPolygon?: Map<string, Coord[]>,
): RoomEntity | null {
  const getBottomY = (polygon: Coord[]): number => {
    return Math.max(...polygon.map((coord) => coord.y));
  };

  const getAverageY = (polygon: Coord[]): number => {
    return polygon.reduce((acc, coord) => acc + coord.y, 0) / polygon.length;
  };

  const getArea = (polygon: Coord[]): number => {
    return Math.abs(polygonDirectedAreaM2(polygon));
  };
  const sortedRooms = rooms
    .slice()
    .filter((room) => room.room.roomType === RoomType.ROOM)
    .sort((a, b) => {
      const polygonA =
        cacheRoomPolygon?.get(a.uid) ||
        context.globalStore
          .getObjectOfType(EntityType.ROOM, a.uid)
          ?.collectVerticesInOrder()
          .map((v) => {
            return v.toWorldCoord();
          });
      const polygonB =
        cacheRoomPolygon?.get(b.uid) ||
        context.globalStore
          .getObjectOfType(EntityType.ROOM, b.uid)
          ?.collectVerticesInOrder()
          .map((v) => {
            return v.toWorldCoord();
          });

      if (!polygonA || !polygonB) return 0;

      const bottomA = getBottomY(polygonA);
      const bottomB = getBottomY(polygonB);
      const areaA = getArea(polygonA);
      const areaB = getArea(polygonB);

      if (Math.abs(bottomA - bottomB) < 100) {
        if (Math.abs(areaA - areaB) < 100) {
          // If they have the same area, sort by their centers.
          return getAverageY(polygonA) - getAverageY(polygonB);
        }
        return areaB - areaA;
      }

      return bottomB - bottomA;
    });

  return sortedRooms[0] || null;
}

export function getBuildingsFromRooms(
  context: CoreContext,
  rooms: CoreRoom[],
): CoreRoom[][] {
  const u: UnionFind<string> = new UnionFind();

  const roomsByLevel = getEntitiesByLevel(context.globalStore, rooms);

  const levelUidsByHeight = Object.values(context.drawing.levels)
    .sort((a, b) => -(a.floorHeightM - b.floorHeightM))
    .map((l) => l.uid);

  for (const r of rooms) {
    u.join(r.uid, r.uid);
    for (const w of r.collectWalls()) {
      if (w.isInternalWall() && w.isManifested) {
        const roomUids = w.entity.polygonEdgeUid.map((uid) => {
          const edge = context.globalStore.getObjectOfTypeOrThrow(
            EntityType.EDGE,
            uid,
          );
          return context.globalStore.getPolygonsByEdge(edge.uid)[0];
        });
        for (const uid of roomUids) {
          u.join(r.uid, uid);
        }
      }
    }

    // Now join rooms across floors into buildings.
    // N^2 but no-one is drawing buildings with 1000 rooms on two consecutive floors.
    const level = context.globalStore.levelOfEntity.get(r.uid)!;
    const levelIndex = levelUidsByHeight.indexOf(level);
    if (levelIndex > 0) {
      const belowLevel = levelUidsByHeight[levelIndex - 1];
      const belowRooms = roomsByLevel.get(belowLevel);
      if (belowRooms) {
        for (const belowRoom of belowRooms) {
          if (
            polygonPolygonContains(r.shape, belowRoom.shape) ||
            polygonPolygonContains(belowRoom.shape, r.shape) ||
            r.shape.intersect(belowRoom.shape).length > 0
          ) {
            u.join(r.uid, belowRoom.uid);
          }
        }
      }
    }
  }

  const buildings: CoreRoom[][] = [];
  const buildingUids = u.groups();
  for (const buildingUid of buildingUids) {
    const building: CoreRoom[] = [];
    for (const roomUid of buildingUid) {
      const room = context.globalStore.getObjectOfType(
        EntityType.ROOM,
        roomUid,
      );
      if (room) {
        building.push(room);
      }
    }
    buildings.push(building);
  }

  return buildings;
}

export function selectLeaderRoomsPerFloor(
  context: CoreContext,
  rooms: RoomEntity[],
): Map<string, RoomEntity> {
  const byLevels = getEntitiesByLevel(
    context.globalStore,
    rooms.map((r) =>
      context.globalStore.getObjectOfTypeOrThrow(EntityType.ROOM, r.uid),
    ),
  );
  const leaders = new Map<string, RoomEntity>();
  for (const level of byLevels.keys()) {
    const levelRooms = byLevels.get(level)!;
    const leader = selectLeaderRoom(
      context,
      levelRooms.map((r) => r.entity),
    )!;
    if (leader) {
      leaders.set(level, leader);
    }
  }
  return leaders;
}

export function spaceTypes(
  context: CoreContext,
): { name: SpaceType; temperature: number }[] {
  const ret = [];
  const effective = getEffectiveHeatLoad(context.catalog, context.drawing);
  const heatingOptions = effective.heatingAirChangeRate;

  for (const [roomName, temperature] of Object.entries(
    effective.roomTemperatureC,
  )) {
    // only show common vent and heating rooms
    if (!heatingOptions[roomName]) continue;

    const rawTemp = convertMeasurementSystem(
      context.drawing.metadata.units,
      Units.Celsius,
      temperature,
      Precision.DISPLAY,
    )[1];

    const temp = Number(Number(rawTemp).toFixed(0));

    ret.push({
      name: roomName as SpaceType,
      temperature: temp,
    });
  }

  return ret;
}

type UFHData = UFHFloorData[PipeDiameterMM][RoomTempC][MeanWaterTempC][0];

interface NestedUFHData {
  [key: number]: {
    [key: number]: UFHData[];
  };
}

export function calculateUFHLoopLength(
  roomAreaM2: number,
  pipeSpacingMM: number,
): number {
  return (1000 / pipeSpacingMM) * roomAreaM2;
}

export function calculateUFHArea(
  pipeLengthM: number,
  pipeSpacingMM: number,
): number {
  return (pipeLengthM / 1000) * pipeSpacingMM;
}

export function findOptimalSpacing(
  data: UFHData[],
  targetOutputWperM2: number,
  targetSpacingMM: number | null,
  maxSpacingMM: number | null,
): UFHData | null {
  if (targetSpacingMM) {
    // targetSpacingMM is a user input so it should override and be used even if
    // it is higher than the maxSpacingMM
    return (
      data.find((entry) => entry.pipeSpacingMM === targetSpacingMM) ?? null
    );
  }
  const sortedData = data.sort((a, b) => a.outputWperM2 - b.outputWperM2);

  // Find the first element with an outputWperM2 greater than the target
  for (const element of sortedData) {
    if (element.outputWperM2 >= targetOutputWperM2) {
      if (maxSpacingMM !== null && element.pipeSpacingMM <= maxSpacingMM) {
        return element;
      } else if (maxSpacingMM === null) {
        return element;
      }
    }
  }

  // return the best one we can
  return sortedData[sortedData.length - 1];
}

export function interpolateUFHData(
  data: NestedUFHData,
  firstKey: number,
  secondKey: number,
): UFHData[] | null {
  if (data[firstKey] && data[firstKey][secondKey]) {
    return cloneSimple(data[firstKey][secondKey]);
  }

  const firstKeys = Object.keys(data)
    .map(Number)
    .sort((a, b) => a - b);
  const { lower: firstLower, higher: firstHigher } = findClosestKeys(
    firstKeys,
    firstKey,
  );

  if (data[firstLower] && data[firstHigher]) {
    const secondKeysLower = Object.keys(data[firstLower])
      .map(Number)
      .sort((a, b) => a - b);
    const secondKeysHigher = Object.keys(data[firstHigher])
      .map(Number)
      .sort((a, b) => a - b);
    const secondKeysCommon = secondKeysLower.filter((key) =>
      secondKeysHigher.includes(key),
    );
    const { lower: secondLower, higher: secondHigher } = findClosestKeys(
      secondKeysCommon,
      secondKey,
    );

    const factorSecond =
      (secondKey - secondLower) / (secondHigher - secondLower);

    const interpolatedLower = interpolateUFHValues(
      data[firstLower][secondLower],
      data[firstLower][secondHigher],
      factorSecond,
    );
    const interpolatedHigher = interpolateUFHValues(
      data[firstHigher][secondLower],
      data[firstHigher][secondHigher],
      factorSecond,
    );

    const factorFirst = (firstKey - firstLower) / (firstHigher - firstLower);

    return interpolateUFHValues(
      interpolatedLower,
      interpolatedHigher,
      factorFirst,
    );
  }

  return null;
}

function findClosestKeys(
  keys: number[],
  target: number,
): { lower: number; higher: number } {
  if (keys.length === 1) {
    return { lower: keys[0], higher: keys[0] };
  }

  let lower = keys[0];
  let higher = keys[keys.length - 1];

  for (const key of keys) {
    if (key < target && key >= lower) {
      lower = key;
    }
    if (key > target && key <= higher) {
      higher = key;
    }
  }

  // If target is outside the range of keys
  if (target <= lower) {
    lower = keys[0];
    higher = keys[1];
  }
  if (target >= higher) {
    lower = keys[keys.length - 2];
    higher = keys[keys.length - 1];
  }

  return { lower, higher };
}

function interpolateUFHValues(
  lowerData: UFHData[],
  higherData: UFHData[],
  factor: number,
): UFHData[] {
  return lowerData.map((entry, index) => ({
    pipeSpacingMM: entry.pipeSpacingMM, // Assuming pipeSpacingMM remains constant for interpolation
    outputWperM2:
      entry.outputWperM2 +
      (higherData[index].outputWperM2 - entry.outputWperM2) * factor,
    floorTempC:
      entry.floorTempC +
      (higherData[index].floorTempC - entry.floorTempC) * factor,
  }));
}

export function isFenUFHCompatible(
  context: CoreContext,
  fen: FenEntity,
): boolean {
  switch (fen.fenType) {
    case FenType.DOOR:
    case FenType.LOOP_ENTRY:
      return true;
    case FenType.WINDOW:
      return false;
  }
  assertUnreachable(fen);
}

export function getUnderfloorSettings(
  context: CoreContext,
  manifold: ManifoldPlant,
): (UnderfloorHeatingSettings & Partial<UnderfloorHeatingSettingsV3>) | null {
  const system = getFlowSystem(context.drawing, manifold.ufhSystemUid);
  if (!system || !isUnderfloor(system)) {
    return null;
  }
  return system;
}
