import { Color } from "../../../../lib/color";
import { Coord } from "../../../../lib/coord";
import {
  convertMeasurementSystemNonNull,
  Units,
  UnitsContext,
} from "../../../../lib/measurements";
import {
  assertType,
  assertUnreachable,
  Choice,
  cloneNaive,
} from "../../../../lib/utils";
import { createEntryWithUnit } from "../../../calculations/heatloss/heat-loss-result-type";
import { CoreContext } from "../../../calculations/types";
import {
  LoopShape,
  SpiralChirality,
} from "../../../calculations/underfloor-heating/types";
import { getEffectiveHeatLoad } from "../../../calculations/utils";
import {
  ACRUnits,
  AirChangeRateValueSpec,
  ChimneyType,
  SpaceType,
} from "../../../catalog/heatload/types";
import { FloorFinish } from "../../../catalog/types";
import { PipePhysicalMaterial } from "../../../config";
import { LoopSiteCalculation } from "../../calculations-objects/underfloor-heating-loop-calculation";
import { airChangeStandardToLabel } from "../../drawing";
import { UnderfloorHeatingFlowSystem } from "../../flow-systems";
import { getFlowSystem } from "../../utils";
import { ManifoldPlant, PlantType } from "../plants/plant-types";
import {
  ChoiceSelectTableEntry,
  FieldType,
  PropertyField,
  PropertyObject,
  TabsField,
  withPropertyTracking,
} from "../property-field";
import {
  getFullUFHRoomDefaults,
  makeUFHFields,
} from "../shared-fields/ufh-fields";
import { DrawableEntity, NamedEntity, PolygonEntity } from "../simple-entities";
import { EntityType } from "../types";
import {
  getAvailableHeatLoadComponentMaterial,
  getAvailableHeatSource,
} from "../utils";
import { RoofType } from "./roof-type";
import {
  RoomInternalHeatSource,
  RoomInternalHeatSourceItem,
} from "./room-type";
import { getRoomFloorMaterialType } from "./utils";

export interface RoomEntity extends DrawableEntity, NamedEntity, PolygonEntity {
  type: EntityType.ROOM;
  color: Color | null;
  room: RoomConcrete;
  virtualCenter: Coord;
}

export enum RoomType {
  ROOM = "ROOM",
  ROOF = "ROOF",
}

export type RoomRoomEntity = RoomEntity & {
  room: RoomEntityConcrete;
};

export type VroomVroomEntity = RoomRoomEntity; // vroom vroom

export type RoofRoomEntity = RoomEntity & {
  room: RoofEntityConcrete;
};

export type RoomPropBase = {
  roomType: RoomType;
};

export function isRoomRoomEntity(
  entity: DrawableEntity,
): entity is RoomRoomEntity {
  return isRoomEntity(entity) && entity.room.roomType === RoomType.ROOM;
}

export function isRoomEntity(entity: DrawableEntity): entity is RoomEntity {
  return entity.type === EntityType.ROOM;
}

// Can return null if not connected to a manifold.
export function getRoomUfhMode(
  context: CoreContext,
  room: RoomRoomEntity,
): "full" | "approximate" | null {
  // if (!context.featureAccess.fullUnderfloorHeatingLoops) {
  //   return "approximate";
  // }

  const manifold = context.globalStore.getObjectOfType(
    EntityType.PLANT,
    room.room.underfloorHeating.manifoldUid!,
  );
  const ufhMode =
    manifold && manifold.entity.plant.type === PlantType.MANIFOLD
      ? manifold.entity.plant.ufhMode
      : null;
  return ufhMode;
}

export interface RoomFieldHandlers {
  addUnheatedArea: (context: CoreContext) => void;
}

export type RoomACRFields = {
  // air change rate (ACR) coefficient fields
  achACR: number | null; // air changes per hour
  lpsACR: number | null; // litres per second
  lpsm2ACR: number | null; // litres per second per square metre
  lpsPersonACR: number | null; // litres per second per person
  lpsShowerACR: number | null; // litres per second per shower
  lpsBathACR: number | null; // litres per second per bath
  lpsWCACR: number | null; // litres per second per WC
  lpsUrinalACR: number | null; // litres per second per urinal
  lpsMachineACR: number | null; // litres per second per machine
  lpsRoomACR: number | null; // litres per second per room
  cmsACR: number | null; // cubic metres per second
  cfmft2ACR: number | null; // cubic feet per minute per square foot
  cfmACR: number | null; // cubic feet per minute

  // air changer rate (ACR) count fields
  personCount: number | null;
  showerCount: number | null;
  bathCount: number | null;
  wcCount: number | null;
  urinalCount: number | null;
  machineCount: number | null;
  roomCount: number | null;
};

export interface UFHLoopDesignParameters {
  loopShape: LoopShape | null;
  // for serpentine and linear - across the floorboards so we need to give them an angle to work with.
  loopDirectionDEG: number | null;
  minBendRadiusMM: number | null;
  chirality: SpiralChirality | null;

  // New fields
  floorFinish: FloorFinish | null;
  edgeExpansionFoamManufacturer: string | null;
  edgeExpansionFoamModel: string | null;

  coilManufacturer: string | null;
  genericCoilRollLengthsM: number[] | null;

  pipeDiameterMM: number | null;
  pipeMaterial: PipePhysicalMaterial | null;
  maxLoopLengthM: number | null;
  loopSpacingMM: number | null;
  hasActuator: boolean;

  // MVP fields
  manifoldUid: string | null;

  coilColors: (Color | null)[];
}

export interface UfhRoomParameters extends UFHLoopDesignParameters {
  exteriorLoopLengthM: number | null;

  // Total room heat outputW
  heatOutputW: number | null;

  freezeLayout: boolean;
  frozenLoopsStats: LoopSiteCalculation["loopsStats"];
}

export interface RoomEntityConcrete extends RoomPropBase {
  roomType: RoomType.ROOM;
  spaceType: SpaceType | string | null;
  roomTemperatureC: number | null;
  externalTemperatureC: number | null;
  groundTemperatureC: number | null;
  spareHeatGainPercent: number | null;
  spareHeatLossPercent: number | null;
  roomHeightM: number | null;
  roofMaterialUid: string | null;
  floorMaterialUid: string | null;
  chimneySetting: ChimneyType;

  internalHeatSource: RoomInternalHeatSource;
  totalHeatLossWatt: number | null;

  ventACR: RoomACRFields;
  heatingACR: RoomACRFields;
  heatingSpaceType: SpaceType | string | null;

  floorType: "bottom" | "suspended" | "party" | null;

  underfloorHeating: UfhRoomParameters;
}

export function loopShapeHasDirectionProperty(shape: LoopShape): boolean {
  switch (shape) {
    case "linear":
    case "serpentine":
      return true;
    case "spiral":
      return false;
  }
  assertUnreachable(shape);
}

export function loopShapeHasChiralityProperty(shape: LoopShape): boolean {
  switch (shape) {
    case "linear":
    case "serpentine":
      return false;
    case "spiral":
      return true;
  }
  assertUnreachable(shape);
}

export interface RoofEntityConcrete extends RoomPropBase {
  roomType: RoomType.ROOF;
  roofMaterialUid: string | null;
  externalWallMaterialUid: string | null;
  selectedRoofType: RoofType;
  heightM: number | null;
  lengthM: number | null;
  widthM: number | null;
  raiseHeightM: number | null;
  peakHeightM: number | null;
  rotation: number;
}

export type RoomConcrete = RoomEntityConcrete | RoofEntityConcrete;

export function ACRUnitToRoomFields(unit: ACRUnits): {
  coefficientField: keyof RoomACRFields;
  countField?: keyof RoomACRFields;
} {
  switch (unit) {
    case ACRUnits.ACH:
      return { coefficientField: "achACR" };
    case ACRUnits.Ls:
      return { coefficientField: "lpsACR" };
    case ACRUnits.LsPerM2:
      return { coefficientField: "lpsm2ACR" };
    case ACRUnits.LsPerPerson:
      return { coefficientField: "lpsPersonACR", countField: "personCount" };
    case ACRUnits.LsPerShower:
      return { coefficientField: "lpsShowerACR", countField: "showerCount" };
    case ACRUnits.LsPerBath:
      return { coefficientField: "lpsBathACR", countField: "bathCount" };
    case ACRUnits.LsPerWC:
      return { coefficientField: "lpsWCACR", countField: "wcCount" };
    case ACRUnits.LsPerUrinal:
      return { coefficientField: "lpsUrinalACR", countField: "urinalCount" };
    case ACRUnits.LsPerMachine:
      return { coefficientField: "lpsMachineACR", countField: "machineCount" };
    case ACRUnits.LsPerRoom:
      return { coefficientField: "lpsRoomACR", countField: "roomCount" };
    case ACRUnits.M3s:
      return { coefficientField: "cmsACR" };
    case ACRUnits.CfmPerF2:
      return { coefficientField: "cfmft2ACR" };
    case ACRUnits.Cfm:
      return { coefficientField: "cfmACR" };
  }

  assertUnreachable(unit);
}

export function getACRCountFieldsLabel(
  countField: keyof RoomACRFields,
): string {
  switch (countField) {
    case "personCount":
      return "Person Count";
    case "showerCount":
      return "Shower Count";
    case "bathCount":
      return "Bath Count";
    case "wcCount":
      return "WC Count";
    case "urinalCount":
      return "Urinal Count";
    case "machineCount":
      return "Machine Count";
    case "roomCount":
      return "Room Count";
  }

  return countField;
}

export const ChimneyTypeChoice: Choice<ChimneyType>[] = [
  {
    name: "No Chimney",
    key: "no",
  },
  {
    name: "With Throat Restrictor",
    key: "with throat restrictor",
  },
  {
    name: "Without Throat Restrictor",
    key: "without throat restrictor",
  },
];

export const FLOOR_TYPE_CHOICES: Choice<RoomEntityConcrete["floorType"]>[] = [
  {
    name: "Bottom Floor",
    key: "bottom",
  },
  {
    name: "Suspended Floor",
    key: "suspended",
  },
  {
    name: "Party Floor",
    key: "party",
  },
];

export const DEFAULT_EXTERIOR_PIPE_LENGTH_M = 5;

export function fillDefaultNormalRoomFields(
  context: CoreContext,
  filled: RoomEntity,
  room: RoomEntityConcrete,
) {
  const { catalog, drawing } = context;
  const effective = getEffectiveHeatLoad(catalog, drawing);
  const liveCalcs = context.globalStore.getOrCreateLiveCalculation(filled);
  const calcs = context.globalStore.getOrCreateCalculation(filled);
  const {
    defaultMaterial,
    wallSpec,
    defaultRoomTemperatureC,
    externalWinterTemperatureC,
    groundTemperatureC,
    spareHeatGainPrecent,
    spareHeatLossPrecent,
  } = drawing.metadata.heatLoss;

  if (room.spaceType === null) {
    room.spaceType = SpaceType.Lounge;
  }
  if (room.heatingSpaceType === null) {
    room.heatingSpaceType = SpaceType.Lounge;
  }

  // vent air change rates
  if (effective.ventAirChangeRate[room.spaceType]) {
    for (const acr of effective.ventAirChangeRate[room.spaceType]) {
      const { coefficientField } = ACRUnitToRoomFields(acr.unit);
      if (room.ventACR[coefficientField] == null) {
        room.ventACR[coefficientField] = Number(acr.coeff);
      }
    }
  }

  // heating air change rates
  if (effective.heatingAirChangeRate[room.heatingSpaceType]) {
    for (const acr of effective.heatingAirChangeRate[room.heatingSpaceType]) {
      const { coefficientField } = ACRUnitToRoomFields(acr.unit);
      if (room.heatingACR[coefficientField] == null) {
        room.heatingACR[coefficientField] = Number(acr.coeff);
      }
    }
  }

  if (isRoomRoomEntity(filled) && filled.room.floorType === null) {
    filled.room.floorType = liveCalcs.floorType ?? calcs.floorType ?? "bottom";
  }

  const floorMaterialType = getRoomFloorMaterialType(filled);

  if (filled.color === null) {
    filled.color =
      context.drawing.metadata.heatLoss.defaultColor[floorMaterialType];
  }
  if (filled.entityName === null) {
    filled.entityName = liveCalcs.reference;
  }
  if (room.chimneySetting === null) {
    room.chimneySetting = "no";
  }
  if (room.totalHeatLossWatt === null) {
    room.totalHeatLossWatt = liveCalcs.totalHeatLossWatt;
  }
  if (filled.entityName === null) {
    filled.entityName = room.spaceType;
  }

  if (room.roomHeightM === null) {
    room.roomHeightM = wallSpec.roomHeightM;
  }

  if (room.roofMaterialUid === null) {
    room.roofMaterialUid = defaultMaterial["Roof"];
  }

  if (room.floorMaterialUid === null) {
    room.floorMaterialUid = defaultMaterial[floorMaterialType];
  }

  if (room.roomTemperatureC === null) {
    room.roomTemperatureC =
      effective.roomTemperatureC[room.spaceType] ?? defaultRoomTemperatureC;
  }

  if (room.externalTemperatureC === null) {
    room.externalTemperatureC = externalWinterTemperatureC;
  }

  if (room.groundTemperatureC === null) {
    room.groundTemperatureC = groundTemperatureC;
  }

  if (room.spareHeatGainPercent === null) {
    room.spareHeatGainPercent = spareHeatGainPrecent;
  }

  if (room.spareHeatLossPercent === null) {
    room.spareHeatLossPercent = spareHeatLossPrecent;
  }

  room.underfloorHeating = getFullUFHRoomDefaults(context, liveCalcs, room);
}

function fillDefaultRoofField(
  context: CoreContext,
  entity: RoomEntity,
  room: RoofEntityConcrete,
) {
  let { drawing } = context;
  let heatloadSpec = drawing.metadata.heatLoss;
  let { defaultMaterial } = heatloadSpec;

  if (entity.color === null) {
    entity.color = context.drawing.metadata.heatLoss.defaultColor["Roof"];
  }
  if (entity.entityName === null) {
    entity.entityName = "Roof";
  }

  if (room.roofMaterialUid === null) {
    room.roofMaterialUid = defaultMaterial["Roof"];
  }

  if (room.selectedRoofType === null) {
    room.selectedRoofType = RoofType.Flat;
  }

  let roofSpec = context.drawing.metadata.heatLoss.roofSpec;
  let roofTypeSpec = roofSpec[room.selectedRoofType];
  switch (roofTypeSpec.roofType) {
    case RoofType.Flat:
      break;
    case RoofType.Shed:
    case RoofType.Gable:
      if (room.heightM === null) room.heightM = roofTypeSpec.heightM;
      break;
    case RoofType.Hip:
      if (room.heightM === null) room.heightM = roofTypeSpec.heightM;
      if (room.lengthM === null) room.lengthM = roofTypeSpec.lengthM;
      break;
    case RoofType.Hex:
    case RoofType.Sloped:
      if (room.heightM === null) room.heightM = roofTypeSpec.heightM;
      if (room.widthM === null) room.widthM = roofTypeSpec.widthM;
      break;
    case RoofType.RaisedShed:
    case RoofType.RaisedGable:
      if (room.raiseHeightM === null)
        room.raiseHeightM = roofTypeSpec.raiseHeightM;
      if (room.peakHeightM === null)
        room.peakHeightM = roofTypeSpec.peakHeightM;
      break;
    case RoofType.RaisedHip:
      if (room.raiseHeightM === null)
        room.raiseHeightM = roofTypeSpec.raiseHeightM;
      if (room.peakHeightM === null)
        room.peakHeightM = roofTypeSpec.peakHeightM;
      if (room.lengthM === null) room.lengthM = roofTypeSpec.lengthM;
      break;
    case RoofType.RaisedHex:
    case RoofType.RaisedSloped:
      if (room.raiseHeightM === null)
        room.raiseHeightM = roofTypeSpec.raiseHeightM;
      if (room.peakHeightM === null)
        room.peakHeightM = roofTypeSpec.peakHeightM;
      if (room.widthM === null) room.widthM = roofTypeSpec.widthM;
      break;
    default:
      assertUnreachable(roofTypeSpec);
  }

  if (room.externalWallMaterialUid === null) {
    room.externalWallMaterialUid = defaultMaterial["External Wall"];
  }
}

export function fillDefaultRoomFields<T extends RoomEntity>(
  context: CoreContext,
  entity: T,
): T {
  const filled = cloneNaive(entity);

  switch (filled.room.roomType) {
    case RoomType.ROOF:
      fillDefaultRoofField(context, filled, filled.room);
      break;
    case RoomType.ROOM:
      fillDefaultNormalRoomFields(context, filled, filled.room);
      break;
  }
  return filled;
}

type RoomSettingFields = {
  [K in RoomType]: (
    context: CoreContext,
    entity: RoomEntity,
  ) => PropertyField[];
};
type RoofParamSettingFields = {
  [K in RoofType]: (
    context: CoreContext,
    entity: RoomConcrete,
  ) => PropertyField[];
};

export const ROOF_ADVERT_PARAM = {
  [RoofType.Flat]: {
    url: null,
    titleHtml: '<p style="font-size: small; margin:3px auto;">Roof Types</p>',
    subtitleHtml: "",
    imagePath: "/img/roof/roofType.png",
  },
  [RoofType.Shed]: {
    url: null,
    titleHtml: '<p style="font-size: small; margin:3px auto;">Shed Roof</p>',
    subtitleHtml: "",
    imagePath: "/img/roof/Shed.png",
  },
  [RoofType.Gable]: {
    url: null,
    titleHtml: '<p style="font-size: small; margin:3px auto;">Gable Roof</p>',
    subtitleHtml: "",
    imagePath: "/img/roof/Gable.png",
  },
  [RoofType.Hip]: {
    url: null,
    titleHtml: '<p style="font-size: small; margin:3px auto;">Hip Roof</p>',
    subtitleHtml: "",
    imagePath: "/img/roof/Hip.png",
  },
  [RoofType.Hex]: {
    url: null,
    titleHtml: '<p style="font-size: small; margin:3px auto;">Hex Roof</p>',
    subtitleHtml: "",
    imagePath: "/img/roof/Hex.png",
  },
  [RoofType.Sloped]: {
    url: null,
    titleHtml: '<p style="font-size: small; margin:3px auto;">Sloped Roof</p>',
    subtitleHtml: "",
    imagePath: "/img/roof/Slope.png",
  },
  [RoofType.RaisedShed]: {
    url: null,
    titleHtml:
      '<p style="font-size: small; margin:3px auto;">Raised Shed Roof</p>',
    subtitleHtml: "",
    imagePath: "/img/roof/RaisedShed.png",
  },
  [RoofType.RaisedGable]: {
    url: null,
    titleHtml:
      '<p style="font-size: small; margin:3px auto;">Raised Gable Roof</p>',
    subtitleHtml: "",
    imagePath: "/img/roof/RaisedGable.png",
  },
  [RoofType.RaisedHip]: {
    url: null,
    titleHtml:
      '<p style="font-size: small; margin:3px auto;">Raised Hip Roof</p>',
    subtitleHtml: "",
    imagePath: "/img/roof/RaisedHip.png",
  },
  [RoofType.RaisedHex]: {
    url: null,
    titleHtml:
      '<p style="font-size: small; margin:3px auto;">Raised Hex Roof</p>',
    subtitleHtml: "",
    imagePath: "/img/roof/RaisedHex.png",
  },
  [RoofType.RaisedSloped]: {
    url: null,
    titleHtml:
      '<p style="font-size: small; margin:3px auto;">Raised Slope Roof</p>',
    subtitleHtml: "",
    imagePath: "/img/roof/RaisedSlope.png",
  },
};

const getRoomPropertyField = (context: CoreContext): RoomSettingFields => {
  return {
    [RoomType.ROOM]: (context: CoreContext, filled: RoomEntity) => {
      assertType<RoomEntityConcrete>(filled.room);
      const result: PropertyField[] = [];
      const effective = getEffectiveHeatLoad(context.catalog, context.drawing);
      const spaceTypes = Object.keys(effective.ventAirChangeRate);
      const floorMaterialType = getRoomFloorMaterialType(filled);

      result.push(
        {
          property: "room.spaceType",
          title: "Room Type",
          hasDefault: true,
          isCalculated: false,
          type: FieldType.Choice,
          params: {
            choices: Object.values(spaceTypes).map((roomType) => ({
              name: roomType,
              key: roomType,
            })),
          },
          multiFieldId: "room-spaceType",
        },
        {
          property: "room.roomTemperatureC",
          title: "Room Design Temperature",
          hasDefault: true,
          isCalculated: false,
          type: FieldType.Number,
          params: { min: -55, max: 60 },
          multiFieldId: "room-roomTemperatureC",
          units: Units.Celsius,
        },
        {
          property: "room.externalTemperatureC",
          title: "External Temperature",
          hasDefault: true,
          isCalculated: false,
          type: FieldType.Number,
          params: { min: -55, max: 60 },
          multiFieldId: "room-externalTemperatureC",
          units: Units.Celsius,
        },
        {
          property: "room.groundTemperatureC",
          title: "Ground Temperature",
          hasDefault: true,
          isCalculated: false,
          type: FieldType.Number,
          params: { min: -55, max: 60 },
          multiFieldId: "room-groundTemperatureC",
          units: Units.Celsius,
        },
        {
          property: "room.spareHeatGainPercent",
          title: "Summer Spare Capacity",
          hasDefault: true,
          isCalculated: false,
          type: FieldType.Number,
          params: { min: 0, max: 100 },
          multiFieldId: "room-spareHeatGainPercent",
          units: Units.Percent,
        },
        {
          property: "room.spareHeatLossPercent",
          title: "Winter Spare Capacity",
          hasDefault: true,
          isCalculated: false,
          type: FieldType.Number,
          params: { min: 0, max: 100 },
          multiFieldId: "room-spareHeatLossPercent",
          units: Units.Percent,
        },
      );

      result.push(
        {
          property: "room.roomHeightM",
          title: "Room Height",
          hasDefault: true,
          isCalculated: false,
          type: FieldType.Number,
          params: { min: 0, max: null },
          multiFieldId: "room-roomHeightM",
          units: Units.Meters,
        },
        {
          property: "room.roofMaterialUid",
          title: "Roof Material",
          hasDefault: true,
          isCalculated: false,
          type: FieldType.Choice,
          params: {
            choices: getAvailableHeatLoadComponentMaterial(context, "Roof"),
          },
          multiFieldId: "room-roofMaterialUid",
        },
        {
          property: "room.floorType",
          title: "Floor Type",
          hasDefault: false,
          isCalculated: true,
          type: FieldType.Choice,
          params: { choices: FLOOR_TYPE_CHOICES },
          multiFieldId: "room-floorType",
        },
        {
          property: "room.floorMaterialUid",
          title: "Floor Material",
          hasDefault: true,
          isCalculated: false,
          type: FieldType.Choice,
          params: {
            choices: getAvailableHeatLoadComponentMaterial(
              context,
              floorMaterialType,
            ),
          },
          multiFieldId: "room-floorMaterialUid",
        },
        {
          property: "room.chimneySetting",
          title: "Chimney Setting",
          hasDefault: true,
          isCalculated: false,
          type: FieldType.Choice,
          params: {
            choices: ChimneyTypeChoice,
          },
          multiFieldId: "room-chimneySetting",
        },
        {
          property: "room.totalHeatLossWatt",
          title: "Total Heat Loss",
          hasDefault: false,
          isCalculated: true,
          type: FieldType.Number,
          units: Units.Watts,
          unitContext: UnitsContext.HEAT_LOAD_ENERGY_MEASUREMENT,
          params: { min: 0, max: null },
          multiFieldId: "room-totalHeatLossWatt",
        },
      );
      return result;
    },
    [RoomType.ROOF]: (context: CoreContext, entity: RoomEntity) => {
      if (entity.room.roomType !== RoomType.ROOF) {
        throw new Error("Invalid Room Type");
      }

      let fields: PropertyField[] = [];
      fields.push(
        {
          property: "room.roofMaterialUid",
          title: "Roof Material",
          hasDefault: true,
          isCalculated: false,
          type: FieldType.Choice,
          params: {
            choices: getAvailableHeatLoadComponentMaterial(context, "Roof"),
          },
          multiFieldId: "room-roofMaterialUid",
        },
        {
          property: "room.externalWallMaterialUid",
          title: "External Wall Material",
          hasDefault: true,
          isCalculated: false,
          type: FieldType.Choice,
          params: {
            choices: getAvailableHeatLoadComponentMaterial(
              context,
              "External Wall",
            ),
          },
          multiFieldId: "room-externalWallMaterialUid",
        },
        {
          type: FieldType.Choice,
          property: "room.selectedRoofType",
          title: "Roof Type",
          multiFieldId: "Roof Type",
          hasDefault: true,
          isCalculated: false,
          params: {
            choices: [
              RoofType.Flat,
              RoofType.Shed,
              RoofType.Gable,
              RoofType.Hip,
              RoofType.Hex,
              RoofType.Sloped,
              RoofType.RaisedShed,
              RoofType.RaisedGable,
              RoofType.RaisedHip,
              RoofType.RaisedHex,
              RoofType.RaisedSloped,
            ].map((roofType) => {
              return {
                name: roofType,
                key: roofType,
              };
            }),
          },
        },
        ...getRoofPropertyField(context)[entity.room.selectedRoofType](
          context,
          entity.room,
        ),
      );

      return fields;
    },
  };
};

const getRoofPropertyField = (context: CoreContext): RoofParamSettingFields => {
  return {
    [RoofType.Flat]: (context: CoreContext, entityConcrete: RoomConcrete) => {
      return [
        {
          type: FieldType.Image,
          title: "Roof Type",
          hasDefault: false,
          isCalculated: false,
          multiFieldId: "",
          property: "",
          params: ROOF_ADVERT_PARAM[RoofType.Flat],
        },
      ];
    },
    [RoofType.Shed]: (context: CoreContext, entityConcrete: RoomConcrete) => {
      return [
        {
          type: FieldType.Image,
          title: "Roof Type",
          hasDefault: false,
          isCalculated: false,
          multiFieldId: "",
          property: "",
          params: ROOF_ADVERT_PARAM[RoofType.Shed],
        },
        {
          property: "room.rotation",
          title: "Flip Direction",
          hasDefault: false,
          isCalculated: false,
          type: FieldType.Rotation,
          params: {
            step: 90,
            disableFreeInput: false,
          },
          multiFieldId: "room-rotation",
        },
        {
          type: FieldType.Number,
          property: "room.heightM",
          title: "Height",
          multiFieldId: "room-heightM",
          hasDefault: true,
          isCalculated: false,
          params: {
            min: 0,
            max: null,
          },
          units: Units.Meters,
        },
      ];
    },
    [RoofType.Gable]: (context: CoreContext, entityConcrete: RoomConcrete) => {
      return [
        {
          type: FieldType.Image,
          title: "Roof Type",
          hasDefault: false,
          isCalculated: false,
          multiFieldId: "",
          property: "",
          params: ROOF_ADVERT_PARAM[RoofType.Gable],
        },
        {
          property: "room.rotation",
          title: "Flip Direction",
          hasDefault: false,
          isCalculated: false,
          type: FieldType.Rotation,
          params: {
            step: 90,
            disableFreeInput: false,
          },
          multiFieldId: "room-rotation",
        },
        {
          type: FieldType.Number,
          property: "room.heightM",
          title: "Height",
          multiFieldId: "room-heightM",
          hasDefault: true,
          isCalculated: false,
          params: {
            min: 0,
            max: null,
          },
          units: Units.Meters,
        },
      ];
    },
    [RoofType.Hip]: (context: CoreContext, entityConcrete: RoomConcrete) => {
      return [
        {
          type: FieldType.Image,
          title: "Roof Type",
          hasDefault: false,
          isCalculated: false,
          multiFieldId: "",
          property: "",
          params: ROOF_ADVERT_PARAM[RoofType.Hip],
        },
        {
          property: "room.rotation",
          title: "Flip Direction",
          hasDefault: false,
          isCalculated: false,
          type: FieldType.Rotation,
          params: {
            step: 90,
            disableFreeInput: false,
          },
          multiFieldId: "room-rotation",
        },
        {
          type: FieldType.Number,
          property: "room.lengthM",
          title: "Length",
          multiFieldId: "room-lengthM",
          hasDefault: true,
          isCalculated: false,
          params: {
            min: 0,
            max: null,
          },
          units: Units.Meters,
        },
        {
          type: FieldType.Number,
          property: "room.heightM",
          title: "Height",
          multiFieldId: "room-heightM",
          hasDefault: true,
          isCalculated: false,
          params: {
            min: 0,
            max: null,
          },
          units: Units.Meters,
        },
      ];
    },
    [RoofType.Hex]: (context: CoreContext, entityConcrete: RoomConcrete) => {
      return [
        {
          type: FieldType.Image,
          title: "Roof Type",
          hasDefault: false,
          isCalculated: false,
          multiFieldId: "",
          property: "",
          params: ROOF_ADVERT_PARAM[RoofType.Hex],
        },
        {
          property: "room.rotation",
          title: "Flip Direction",
          hasDefault: false,
          isCalculated: false,
          type: FieldType.Rotation,
          params: {
            step: 90,
            disableFreeInput: false,
          },
          multiFieldId: "room-rotation",
        },
        {
          type: FieldType.Number,
          property: "room.widthM",
          title: "width",
          multiFieldId: "room-widthM",
          hasDefault: true,
          isCalculated: false,
          params: {
            min: 0,
            max: null,
          },
          units: Units.Meters,
        },
        {
          type: FieldType.Number,
          property: "room.heightM",
          title: "Height",
          multiFieldId: "room-heightM",
          hasDefault: true,
          isCalculated: false,
          params: {
            min: 0,
            max: null,
          },
          units: Units.Meters,
        },
      ];
    },
    [RoofType.Sloped]: (context: CoreContext, entityConcrete: RoomConcrete) => {
      return [
        {
          type: FieldType.Image,
          title: "Roof Type",
          hasDefault: false,
          isCalculated: false,
          multiFieldId: "",
          property: "",
          params: ROOF_ADVERT_PARAM[RoofType.Sloped],
        },
        {
          property: "room.rotation",
          title: "Flip Direction",
          hasDefault: false,
          isCalculated: false,
          type: FieldType.Rotation,
          params: {
            step: 90,
            disableFreeInput: false,
          },
          multiFieldId: "room-rotation",
        },
        {
          type: FieldType.Number,
          property: "room.widthM",
          title: "width",
          multiFieldId: "room-widthM",
          hasDefault: true,
          isCalculated: false,
          params: {
            min: 0,
            max: null,
          },
          units: Units.Meters,
        },
        {
          type: FieldType.Number,
          property: "room.heightM",
          title: "Height",
          multiFieldId: "room-heightM",
          hasDefault: true,
          isCalculated: false,
          params: {
            min: 0,
            max: null,
          },
          units: Units.Meters,
        },
      ];
    },
    [RoofType.RaisedShed]: (
      context: CoreContext,
      entityConcrete: RoomConcrete,
    ) => {
      return [
        {
          type: FieldType.Image,
          title: "Roof Type",
          hasDefault: false,
          isCalculated: false,
          multiFieldId: "",
          property: "",
          params: ROOF_ADVERT_PARAM[RoofType.RaisedShed],
        },
        {
          property: "room.rotation",
          title: "Flip Direction",
          hasDefault: false,
          isCalculated: false,
          type: FieldType.Rotation,
          params: {
            step: 90,
            disableFreeInput: false,
          },
          multiFieldId: "room-rotation",
        },
        {
          type: FieldType.Number,
          property: "room.raiseHeightM",
          title: "Raise Height (H1)",
          multiFieldId: "room-raiseHeightM",
          hasDefault: true,
          isCalculated: false,
          params: {
            min: 0,
            max: null,
          },
          units: Units.Meters,
        },
        {
          type: FieldType.Number,
          property: "room.peakHeightM",
          title: "Peak Height (H2)",
          multiFieldId: "room-peakHeightM",
          hasDefault: true,
          isCalculated: false,
          params: {
            min: 0,
            max: null,
          },
          units: Units.Meters,
        },
      ];
    },
    [RoofType.RaisedGable]: (
      context: CoreContext,
      entityConcrete: RoomConcrete,
    ) => {
      return [
        {
          type: FieldType.Image,
          title: "Roof Type",
          hasDefault: false,
          isCalculated: false,
          multiFieldId: "",
          property: "",
          params: ROOF_ADVERT_PARAM[RoofType.RaisedGable],
        },
        {
          property: "room.rotation",
          title: "Flip Direction",
          hasDefault: false,
          isCalculated: false,
          type: FieldType.Rotation,
          params: {
            step: 90,
            disableFreeInput: false,
          },
          multiFieldId: "room-rotation",
        },
        {
          type: FieldType.Number,
          property: "room.raiseHeightM",
          title: "Raise Height (H1)",
          multiFieldId: "room-raiseHeight",
          hasDefault: true,
          isCalculated: false,
          params: {
            min: 0,
            max: null,
          },
          units: Units.Meters,
        },
        {
          type: FieldType.Number,
          property: "room.peakHeightM",
          title: "Peak Height (H2)",
          multiFieldId: "peakHeightM",
          hasDefault: true,
          isCalculated: false,
          params: {
            min: 0,
            max: null,
          },
          units: Units.Meters,
        },
      ];
    },
    [RoofType.RaisedHip]: (
      context: CoreContext,
      entityConcrete: RoomConcrete,
    ) => {
      return [
        {
          type: FieldType.Image,
          title: "Roof Type",
          hasDefault: false,
          isCalculated: false,
          multiFieldId: "",
          property: "",
          params: ROOF_ADVERT_PARAM[RoofType.RaisedHip],
        },
        {
          property: "room.rotation",
          title: "Flip Direction",
          hasDefault: false,
          isCalculated: false,
          type: FieldType.Rotation,
          params: {
            step: 90,
            disableFreeInput: false,
          },
          multiFieldId: "room-rotation",
        },
        {
          type: FieldType.Number,
          property: "room.lengthM",
          title: "lengthM",
          multiFieldId: "lengthM",
          hasDefault: true,
          isCalculated: false,
          params: {
            min: 0,
            max: null,
          },
          units: Units.Meters,
        },
        {
          type: FieldType.Number,
          property: "room.raiseHeightM",
          title: "Raise Height (H1)",
          multiFieldId: "room-raiseHeight",
          hasDefault: true,
          isCalculated: false,
          params: {
            min: 0,
            max: null,
          },
          units: Units.Meters,
        },
        {
          type: FieldType.Number,
          property: "room.peakHeightM",
          title: "Peak Height (H2)",
          multiFieldId: "peakHeightM",
          hasDefault: true,
          isCalculated: false,
          params: {
            min: 0,
            max: null,
          },
          units: Units.Meters,
        },
      ];
    },
    [RoofType.RaisedHex]: (
      context: CoreContext,
      entityConcrete: RoomConcrete,
    ) => {
      return [
        {
          type: FieldType.Image,
          title: "Roof Type",
          hasDefault: false,
          isCalculated: false,
          multiFieldId: "",
          property: "",
          params: ROOF_ADVERT_PARAM[RoofType.RaisedHex],
        },
        {
          property: "room.rotation",
          title: "Flip Direction",
          hasDefault: false,
          isCalculated: false,
          type: FieldType.Rotation,
          params: {
            step: 90,
            disableFreeInput: false,
          },
          multiFieldId: "room-rotation",
        },
        {
          type: FieldType.Number,
          property: "room.widthM",
          title: "width",
          multiFieldId: "room-widthM",
          hasDefault: true,
          isCalculated: false,
          params: {
            min: 0,
            max: null,
          },
          units: Units.Meters,
        },
        {
          type: FieldType.Number,
          property: "room.raiseHeightM",
          title: "Raise Height (H1)",
          multiFieldId: "room-raiseHeight",
          hasDefault: true,
          isCalculated: false,
          params: {
            min: 0,
            max: null,
          },
          units: Units.Meters,
        },
        {
          type: FieldType.Number,
          property: "room.peakHeightM",
          title: "Peak Height (H1 + H2)",
          multiFieldId: "peakHeightM",
          hasDefault: true,
          isCalculated: false,
          params: {
            min: 0,
            max: null,
          },
          units: Units.Meters,
        },
      ];
    },
    [RoofType.RaisedSloped]: (
      context: CoreContext,
      entityConcrete: RoomConcrete,
    ) => {
      return [
        {
          property: "room.rotation",
          title: "Flip Direction",
          hasDefault: false,
          isCalculated: false,
          type: FieldType.Rotation,
          params: {
            step: 90,
            disableFreeInput: false,
          },
          multiFieldId: "room-rotation",
        },
        {
          type: FieldType.Image,
          title: "Roof Type",
          hasDefault: false,
          isCalculated: false,
          multiFieldId: "",
          property: "",
          params: ROOF_ADVERT_PARAM[RoofType.RaisedSloped],
        },
        {
          type: FieldType.Number,
          property: "room.widthM",
          title: "width",
          multiFieldId: "room-widthM",
          hasDefault: true,
          isCalculated: false,
          params: {
            min: 0,
            max: null,
          },
          units: Units.Meters,
        },
        {
          type: FieldType.Number,
          property: "room.raiseHeightM",
          title: "Raise Height (H1)",
          multiFieldId: "room-raiseHeight",
          hasDefault: true,
          isCalculated: false,
          params: {
            min: 0,
            max: null,
          },
          units: Units.Meters,
        },
        {
          type: FieldType.Number,
          property: "room.peakHeightM",
          title: "Peak Height (H2)",
          multiFieldId: "peakHeightM",
          hasDefault: true,
          isCalculated: false,
          params: {
            min: 0,
            max: null,
          },
          units: Units.Meters,
        },
      ];
    },
  };
};

function makeRoomFieldsHome(
  context: CoreContext,
  entity: RoomEntity,
): PropertyField[] {
  const filled = fillDefaultRoomFields(context, entity);
  let commonFields: PropertyField[] = [
    {
      property: "entityName",
      title: "Name",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Text,
      params: null,
      multiFieldId: "entityName",
    },
    {
      property: "color",
      title: "Color",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Color,
      params: null,
      multiFieldId: "color",
    },
  ];

  let roomFields = getRoomPropertyField(context)[entity.room.roomType](
    context,
    filled,
  );
  return [...commonFields, ...roomFields];
}

function makeRoomFlowRatesFields(
  context: CoreContext,
  entity: RoomEntity,
): PropertyField[] {
  assertType<RoomEntityConcrete>(entity.room);
  const filled = fillDefaultRoomFields(context, entity);
  const effective = getEffectiveHeatLoad(context.catalog, context.drawing);
  const ventSpaceTypes = Object.keys(effective.ventAirChangeRate);
  const heatingSpaceTypes = Object.keys(effective.heatingAirChangeRate);
  const fields: PropertyField[] = [];

  const heatLossMetadata = context.drawing.metadata.heatLoss;
  const ventStandard = airChangeStandardToLabel(
    heatLossMetadata.ventAirChangesRateStandard,
  );
  const heatingStandard = airChangeStandardToLabel(
    heatLossMetadata.heatingAirChangesRateStandard,
  );

  // Heating
  fields.push(
    {
      property: "roomFlowRatesHeatTitle",
      title: "Heating",
      type: FieldType.Title,
      hasDefault: false,
      isCalculated: false,
      params: null,
      multiFieldId: "roomFlowRatesHeatTitle",
    },
    {
      property: "room.heatingSpaceType",
      title: "Heating Room Type",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Choice,
      hint: `Available room types based on ${heatingStandard}`,
      params: {
        choices: Object.values(heatingSpaceTypes).map((roomType) => ({
          name: roomType,
          key: roomType,
        })),
      },
      multiFieldId: "room-heatingSpaceType",
    },
  );
  if (
    entity.room.heatingSpaceType &&
    effective.heatingAirChangeRate[entity.room.heatingSpaceType]
  ) {
    fields.push(
      ...getACRFields(
        effective.heatingAirChangeRate[entity.room.heatingSpaceType],
        "heatingACR",
      ),
    );
  }

  fields.push({
    type: FieldType.Divider,
    property: "divider",
    title: "",
    hasDefault: false,
    isCalculated: false,
    multiFieldId: "divider",
  });

  // Ventilation
  fields.push(
    {
      property: "roomFlowRatesVentTitle",
      title: "Ventilation",
      type: FieldType.Title,
      hasDefault: false,
      isCalculated: false,
      params: null,
      multiFieldId: "roomFlowRatesVentTitle",
    },
    {
      property: "room.spaceType",
      title: "Ventilation Room Type",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Choice,
      hint: `Available room types based on ${ventStandard}`,
      params: {
        choices: Object.values(ventSpaceTypes).map((roomType) => ({
          name: roomType,
          key: roomType,
        })),
      },
      multiFieldId: "room-spaceType",
    },
  );
  if (
    entity.room.spaceType &&
    effective.ventAirChangeRate[entity.room.spaceType]
  ) {
    fields.push(
      ...getACRFields(
        effective.ventAirChangeRate[entity.room.spaceType],
        "ventACR",
      ),
    );
  }

  return fields;
}

function getACRFields(
  specs: AirChangeRateValueSpec[],
  prefix: "ventACR" | "heatingACR",
): PropertyField[] {
  const fields: PropertyField[] = [];

  for (const acr of specs) {
    const { coefficientField, countField } = ACRUnitToRoomFields(acr.unit);

    fields.push({
      property: `room.${prefix}.${coefficientField}`,
      title: `Air Change Rate`,
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Number,
      rawUnits: acr.unit,
      params: { min: 0, max: null },
      multiFieldId: `room-${prefix}-${coefficientField}`,
    });

    if (countField) {
      fields.push({
        property: `room.${prefix}.${countField}`,
        title: getACRCountFieldsLabel(countField),
        hasDefault: false,
        isCalculated: false,
        requiresInput: true,
        type: FieldType.Number,
        params: { min: 0, max: null },
        multiFieldId: `room-${prefix}-${countField}`,
      });
    }
  }

  return fields;
}

function makeUnderfloorTabFields(
  context: CoreContext,
  entity: RoomRoomEntity,
  handlers?: RoomFieldHandlers,
) {
  const liveCalcs = context.globalStore.getOrCreateLiveCalculation(entity);

  const manifold = entity.room.underfloorHeating.manifoldUid
    ? context.globalStore.getObjectOfType(
        EntityType.PLANT,
        entity.room.underfloorHeating.manifoldUid,
      )
    : null;

  const flowSystem = manifold
    ? (getFlowSystem(
        context.drawing,
        (manifold.entity.plant as ManifoldPlant).ufhSystemUid,
      ) as UnderfloorHeatingFlowSystem)
    : null;

  const filled = fillDefaultRoomFields(context, entity);

  return makeUFHFields(
    getRoomUfhMode(context, filled),
    context.globalStore.levelOfEntity.get(entity.uid) ?? null,
    flowSystem,
    context,
    {
      floorFinish:
        filled.room.underfloorHeating.floorFinish ??
        flowSystem?.floorFinish ??
        null,
      pipeMaterial: filled.room.underfloorHeating.pipeMaterial ?? null,
      loopShape:
        filled.room.underfloorHeating.loopShape ??
        flowSystem?.loopShape ??
        "serpentine",
      coils:
        liveCalcs.underfloorHeating.loopsStats.filter((x) => x.areaUid === null)
          .length ?? 1,
    },
    "room.underfloorHeating",
    "room",
    entity,
    handlers,
  );
}

function makeInternalHeatSourceFields(
  context: CoreContext,
  entity: RoomRoomEntity,
  handlers?: RoomFieldHandlers,
): PropertyField[] {
  return [
    {
      property: "room.internalHeatSource",
      title: "",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.ChoiceSelect,
      params: {
        choiceParams: {
          title: "Add heat source",
          choices: getAvailableHeatSource(context),
          newChoiceEntry: (key: string) => {
            let heatSource =
              context.drawing.metadata.heatLoss.internalHeatSource[key];
            if (!heatSource) {
              throw new Error("Invalid Heat Source Without Definition");
            }

            return {
              name: heatSource.heatSourceName,
              size: 1,
              heatSourceRatingWatts: null,
            };
          },
        },
        tableParams: {
          title: "",
          name: {
            title: "Heat Source Name",
            displayValue: (
              propertyObject: Object,
              entry: ChoiceSelectTableEntry,
            ) => {
              let propertyObjectCast =
                propertyObject as RoomInternalHeatSourceItem;
              return propertyObjectCast.name;
            },

            editable: false,
            units: Units.None,
          },
          quantity: {
            title: "Quantity",
            displayValue: (
              propertyObject: Object,
              entry: ChoiceSelectTableEntry,
            ) => {
              let propertyObjectCast =
                propertyObject as RoomInternalHeatSourceItem;
              return `${propertyObjectCast.size}`;
            },
            input: (
              events: Event,
              propertyObject: Object,
              entry: ChoiceSelectTableEntry,
            ) => {
              let propertyObjectCast =
                propertyObject as RoomInternalHeatSourceItem;
              let value = (events.target as HTMLInputElement).value;
              propertyObjectCast.size = Number(value);
            },
            editable: true,
            units: Units.None,
          },
          unit: {
            title: "Units",
            displayValue: (
              propertyObject: Object,
              entry: ChoiceSelectTableEntry,
            ) => {
              let propertyObjectCast =
                propertyObject as RoomInternalHeatSourceItem;

              // Customized units
              if (propertyObjectCast.heatSourceRatingWatts) {
                let convert = convertMeasurementSystemNonNull(
                  context.drawing.metadata.units,
                  Units.Watts,
                  propertyObjectCast.heatSourceRatingWatts || 0,
                  UnitsContext.HEAT_LOAD_ENERGY_MEASUREMENT,
                );
                return `${Number(convert[1]).toFixed(2)}`;
              } else {
                // Get unit from setting
                let key = propertyObjectCast.name;
                let heatSource =
                  context.drawing.metadata.heatLoss.internalHeatSource[key];
                if (!heatSource) {
                  throw new Error("Invalid Heat Source Without Definition");
                }

                let convert = convertMeasurementSystemNonNull(
                  context.drawing.metadata.units,
                  Units.Watts,
                  heatSource.heatSourceWatts || 0,
                  UnitsContext.HEAT_LOAD_ENERGY_MEASUREMENT,
                );
                return `${Number(convert[1]).toFixed(2)}`;
              }
            },
            displayUnit: () => {
              let convert = convertMeasurementSystemNonNull(
                context.drawing.metadata.units,
                Units.Watts,
                0,
                UnitsContext.HEAT_LOAD_ENERGY_MEASUREMENT,
              );
              return `${convert[0]}`;
            },

            editable: false,
            units: Units.Watts,
            unitsContext: UnitsContext.HEAT_LOAD_ENERGY_MEASUREMENT,
          },
        },
        resultParams: {
          title: "Total Heat Gain from Internal Heat Source",
          displaySum: (propertyObject: PropertyObject) => {
            let propertyObjectCast = propertyObject as RoomInternalHeatSource;
            let sum = 0;
            Object.values(propertyObjectCast).forEach((entry) => {
              if (entry.heatSourceRatingWatts) {
                sum += entry.size * entry.heatSourceRatingWatts;
              } else {
                let key = entry.name;
                let heatSource =
                  context.drawing.metadata.heatLoss.internalHeatSource[key];
                if (!heatSource) {
                  throw new Error("Invalid Heat Source Without Definition");
                }

                sum += entry.size * heatSource.heatSourceWatts;
              }
            });

            return `${createEntryWithUnit(
              context.drawing.metadata.units,
              sum,
              Units.Watts,
              null,
              UnitsContext.HEAT_LOAD_ENERGY_MEASUREMENT,
            )}`;
          },
        },
      },
      multiFieldId: "room-internalHeatSource",
      units: Units.Watts,
      unitContext: UnitsContext.HEAT_LOAD_ENERGY_MEASUREMENT,
    },
  ];
}

export const UNDERFLOOR_HEATING_TAB_ID = "underfloor-mvp-tab";

export type RoomPropertyMode = "underfloor" | "heatloss" | "other";

export function makeRoomFields(
  context: CoreContext,
  entity: RoomEntity,
  propertyMode: RoomPropertyMode = "other",
  handlers?: RoomFieldHandlers,
): PropertyField[] {
  const workflows = context.drawing.metadata.workflows;
  const underfloorHeatingEnabled = workflows.mech_underfloor_heating.enabled;
  const heatGainEnabled = workflows.heat_gain.enabled;
  type Tab = TabsField["tabs"][0];

  switch (entity.room.roomType) {
    case RoomType.ROOM:
      const tabs: Tab[] = [
        {
          tabId: "room-tab",
          tabName: "Room",
          fields: makeRoomFieldsHome(context, entity as RoomRoomEntity),
          hidden: propertyMode === "underfloor",
        },
        {
          tabId: "flow_rates",
          tabName: "Air Changes",
          fields: makeRoomFlowRatesFields(context, entity as RoomRoomEntity),
          hidden: propertyMode === "underfloor",
        },
        {
          tabId: UNDERFLOOR_HEATING_TAB_ID,
          tabName: "Underfloor Heating",
          fields: makeUnderfloorTabFields(
            context,
            entity as RoomRoomEntity,
            handlers,
          ),
          hidden: !underfloorHeatingEnabled || propertyMode !== "underfloor",
        },
        {
          tabId: "internal_heatsource",
          tabName: "Internal Heat Source",
          fields: makeInternalHeatSourceFields(
            context,
            entity as RoomRoomEntity,
            handlers,
          ),
          hidden: !heatGainEnabled || propertyMode === "underfloor",
        },
      ];

      return [
        withPropertyTracking(
          context,
          entity,
        )({
          type: FieldType.Tabs,
          id: "room-tabs",
          tabs,
        }),
      ];
    case RoomType.ROOF:
      return makeRoomFieldsHome(context, entity).map((field: PropertyField) =>
        withPropertyTracking(context, entity)(field),
      );
  }
  assertUnreachable(entity.room);
}

export function getRoomName(
  context: CoreContext,
  roomEntity?: RoomEntity | null,
): string {
  if (!roomEntity) return "Unnamed";
  const filledRoom = fillDefaultRoomFields(context, roomEntity);
  return filledRoom.entityName ?? "Unnamed";
}
