import Flatten from "@flatten-js/core";
import { assertUnreachable } from "../../lib/utils";
import {
  AnnotationEntity,
  fillDefaultAnnotationFields,
} from "../document/entities/annotations/annotation-entity";
import { EntityType } from "../document/entities/types";
import CoreCentered from "./core-traits/coreCentered";
import CoreBaseBackedObject from "./lib/coreBaseBackedObject";

const Base = CoreCentered(CoreBaseBackedObject<AnnotationEntity>);

export default class CoreAnnotation extends Base {
  type: EntityType.ANNOTATION = EntityType.ANNOTATION;

  get refPath(): string {
    return `${this.entity.type}.${this.entity.annoType}`;
  }

  get filledEntity(): AnnotationEntity {
    return fillDefaultAnnotationFields(this.context, this.entity);
  }

  getCoreNeighbours(): CoreBaseBackedObject[] {
    return [];
  }

  get shape() {
    const filled = fillDefaultAnnotationFields(this.context, this.entity);

    switch (this.entity.annoType) {
      case "box":
        const p = new Flatten.Polygon();
        const l = -filled.anno.widthMM! / 2;
        const r = filled.anno.widthMM! / 2;
        const t = -filled.anno.heightMM! / 2;
        const b = filled.anno.heightMM! / 2;

        const tl = this.toWorldCoord({ x: l, y: t });
        const tr = this.toWorldCoord({ x: r, y: t });
        const bl = this.toWorldCoord({ x: l, y: b });
        const br = this.toWorldCoord({ x: r, y: b });
        const tlp = Flatten.point(tl.x, tl.y);
        const trp = Flatten.point(tr.x, tr.y);
        const blp = Flatten.point(bl.x, bl.y);
        const brp = Flatten.point(br.x, br.y);

        p.addFace([
          Flatten.segment(tlp, trp),
          Flatten.segment(trp, brp),
          Flatten.segment(brp, blp),
          Flatten.segment(blp, tlp),
        ]);

        return p;
      default:
        assertUnreachable(this.entity.annoType);
    }

    return new Flatten.Polygon();
  }
}
