import Flatten from "@flatten-js/core";
import { Coord } from "../../lib/coord";
import { cloneNaive } from "../../lib/utils";
import { GetPressureLossOptions } from "../calculations/entity-pressure-drops";
import {
  CoreContext,
  CostBreakdown,
  PressureLossResult,
} from "../calculations/types";
import { CalculationConcrete } from "../document/calculations-objects/calculation-concrete";
import {
  WallLiveCalculation,
  emptyWallCalculation,
  emptyWallLiveCalculation,
} from "../document/calculations-objects/wall-calculation";
import { CalculatableEntityConcrete } from "../document/entities/concrete-entity";
import { EntityType } from "../document/entities/types";
import {
  WallEntity,
  WallType,
  fillDefaultWallFields,
} from "../document/entities/wall-entity";
import { CoreVirtualEdge } from "./core-traits/coreVirtualEdge";
import { CoreCalculatableObject } from "./lib/CoreCalculatableObject";
import {
  externalSegmentDetermineDirectionCW,
  isInternalWallManifested,
} from "./utils";

const Base = CoreVirtualEdge(CoreCalculatableObject<WallEntity>);
const DistanceThreshold = 200;

export default class CoreWall extends Base {
  type: EntityType.WALL = EntityType.WALL;

  get refPath(): string {
    // Note: WallType is a generated field, we cant use it for a ref path at this stage
    return `${this.entity.type}`;
  }

  get filledEntity(): WallEntity {
    return fillDefaultWallFields(this.context, this.entity);
  }

  getFrictionPressureLossKPA(
    options: GetPressureLossOptions,
  ): PressureLossResult {
    throw new Error("Method not implemented.");
  }

  getCalculationEntities(context: CoreContext): CalculatableEntityConcrete[] {
    if (!this.isManifested) return [];
    const entity = cloneNaive(this.entity);

    entity.uid = this.getCalculationUid(context);
    (entity as any).polygonEdgeUid = [
      this.globalStore
        .getObjectOfTypeOrThrow(EntityType.EDGE, entity.polygonEdgeUid[0])
        .getCalculationEntities(context)[0].uid,
    ];
    if (this.entity.polygonEdgeUid.length > 1) {
      entity.polygonEdgeUid.push(
        this.globalStore
          .getObjectOfTypeOrThrow(
            EntityType.EDGE,
            this.entity.polygonEdgeUid[1]!,
          )
          .getCalculationEntities(context)[0].uid,
      );
    }
    return [entity];
  }

  collectCalculations(context: CoreContext): CalculationConcrete {
    const tmp = this.getCalculationEntities(context)[0];
    return tmp
      ? context.globalStore.getOrCreateCalculation(tmp)
      : emptyWallCalculation();
  }

  collectLiveCalculations(context: CoreContext): WallLiveCalculation {
    const tmp = this.getCalculationEntities(context)[0];
    return tmp
      ? context.globalStore.getOrCreateLiveCalculation(tmp)
      : emptyWallLiveCalculation();
  }

  costBreakdown(context: CoreContext): CostBreakdown | null {
    return null;
  }

  lastSegments: [Coord, Coord][] | null = null;

  getWorldSegments(): [Coord, Coord][] {
    if (this.lastSegments !== null) return this.lastSegments;

    try {
      if (this.entity.polygonEdgeUid.length === 2) {
        let worldEndpoints = this.worldEndpoints().map(
          (o) => new Flatten.Point(o.x, o.y),
        );
        let lines = this.entity.polygonEdgeUid.map((uid) => {
          let edge = this.globalStore.getObjectOfTypeOrThrow(
            EntityType.EDGE,
            uid,
          );
          let endpoints = edge.worldEndpoints();
          return new Flatten.Line(
            new Flatten.Point(endpoints[0].x, endpoints[0].y),
            new Flatten.Point(endpoints[1].x, endpoints[1].y),
          );
        });
        let points: Coord[] = [];
        for (let point of worldEndpoints) {
          let line = lines[0];
          if (line.distanceTo(point) < lines[1].distanceTo(point)) {
            line = lines[1];
          }
          let proj = point.projectionOn(line);
          points.push({
            x: (proj.x + point.x) / 2,
            y: (proj.y + point.y) / 2,
          });
        }
        return (this.lastSegments = [[points[0], points[1]]]);
      } else {
        let edge = this.globalStore.getObjectOfTypeOrThrow(
          EntityType.EDGE,
          this.entity.polygonEdgeUid[0],
        );

        let vec = edge.vector;
        let len = (a: Coord, b: Coord) =>
          Math.sqrt((a.x - b.x) ** 2 + (a.y - b.y) ** 2);
        let proj = (p: Coord) => p.x * vec.x + p.y * vec.y;

        let walls = this.globalStore
          .getWallsByRoomEdge(this.entity.polygonEdgeUid[0])
          .filter((x) => x !== this.entity.uid)
          .map((x) =>
            this.globalStore.getObjectOfTypeOrThrow(EntityType.WALL, x),
          )
          .filter((x) => x.entity.polygonEdgeUid.length === 2 && x.isManifested)
          .map((x) => x.getWorldSegments()[0])
          .filter(Boolean)
          .map((x) => {
            return proj(x[0]) > proj(x[1]) ? [x[1], x[0]] : x;
          });

        walls.sort((a, b) => {
          return proj(a[0]) - proj(b[0]);
        });
        let res: [Coord, Coord][] = [];
        let curr = this.globalStore
            .getObjectOfTypeOrThrow(
              EntityType.VERTEX,
              edge.entity.endpointUid[0],
            )
            .toWorldCoord(),
          last = this.globalStore
            .getObjectOfTypeOrThrow(
              EntityType.VERTEX,
              edge.entity.endpointUid[1],
            )
            .toWorldCoord();
        for (let wall of walls) {
          if (
            proj(curr) > proj(wall[0]) ||
            len(curr, wall[0]) < DistanceThreshold
          ) {
            if (proj(curr) < proj(wall[1])) curr = wall[1];
          } else {
            res.push([curr, wall[0]]);
            curr = wall[1];
          }
        }
        if (proj(curr) < proj(last) && len(curr, last) > DistanceThreshold)
          res.push([curr, last]);

        if (
          Math.abs(edge.worldEndpoints()[0].x - edge.worldEndpoints()[1].x) <
            1 &&
          Math.abs(edge.worldEndpoints()[0].y - edge.worldEndpoints()[1].y) < 1
        ) {
          return (this.lastSegments = res);
        }
        let line = new Flatten.Line(
          new Flatten.Point(
            edge.worldEndpoints()[0].x,
            edge.worldEndpoints()[0].y,
          ),
          new Flatten.Point(
            edge.worldEndpoints()[1].x,
            edge.worldEndpoints()[1].y,
          ),
        );

        return (this.lastSegments = res.map((x) => {
          let projs = [
            new Flatten.Point(x[0].x, x[0].y).projectionOn(line),
            new Flatten.Point(x[1].x, x[1].y).projectionOn(line),
          ];
          return [
            { x: projs[0].x, y: projs[0].y },
            { x: projs[1].x, y: projs[1].y },
          ];
        }));
      }
    } catch (e) {
      return (this.lastSegments = []);
    }
  }

  onRedrawNeeded(): void {
    this.lastSegments = null;
    this.lastManifested = null;
    super.onRedrawNeeded();
  }

  get lengthM() {
    let coords = this.getWorldSegments();

    let retM = 0;
    for (let coord of coords) {
      retM += Math.sqrt(
        (coord[0].x - coord[1].x) * (coord[0].x - coord[1].x) +
          (coord[1].y - coord[0].y) * (coord[1].y - coord[0].y),
      );
    }
    return retM / 1000;
  }

  lastManifested: boolean | null = null;

  get isManifested(): boolean {
    if (this.lastManifested !== null) return this.lastManifested;

    if (this.entity.polygonEdgeUid.length === 1) {
      return (this.lastManifested = this.getWorldSegments().length > 0);
    }

    return (this.lastManifested = isInternalWallManifested(
      this.context,
      this.entity,
    ));
  }

  isAutoInternalWall(): boolean {
    try {
      return this.entity.polygonEdgeUid.length === 2 && this.isManifested;
    } catch {
      return false;
    }
  }

  isCustomInternalWall(): boolean {
    return (
      this.entity.wallType === WallType.internal &&
      this.isManifested &&
      !this.isAutoInternalWall()
    );
  }

  isPartyWall(): boolean {
    return this.entity.wallType === WallType.party;
  }

  isInternalWall(): boolean {
    return (
      this.isCustomInternalWall() ||
      this.isPartyWall() ||
      this.isAutoInternalWall()
    );
  }

  normalizedCCWIfExternalWall(segment: [Coord, Coord]): [Coord, Coord] {
    return externalSegmentDetermineDirectionCW(
      this.context,
      segment,
      this.entity.polygonEdgeUid,
    );
  }
}
