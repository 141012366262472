
import { defineComponent } from "vue";
import { AccessLevel, User } from "../../../common/src/models/User";
import { DocumentState } from "../store/document/types";
export default defineComponent({
  computed: {
    appVersion() {
      return this.$store.getters.appVersion;
    },
    shouldHide() {
      if (this.$route.query.hideVersion === "true") {
        return true;
      }
      return false;
    },

    profile(): User {
      return this.$store.getters["profile/profile"];
    },

    isAdmin(): boolean {
      return this.profile?.accessLevel <= AccessLevel.ADMIN;
    },

    shouldShowDocAndOperation(): boolean {
      return (
        this.isAdmin && !this.shouldHide && this.documentState.documentLoaded
      );
    },

    documentState(): DocumentState {
      return this.$store.getters["document/document"];
    },

    docAndOperation(): string {
      return ` - ${this.documentState.documentId}@${this.documentState.nextId}`;
    },
  },
});
