import { cloneDeep, shuffle } from "lodash";
import TinyQueue from "tinyqueue";
import { v4 } from "uuid";
import { cloneSimple } from "../../lib/utils";
import { EdgeType, FlowEdge } from "./types";

enum SPEventType {
  REMOVE_NODE,
  REMOVE_EDGE,
}

type SPEvent<N> = { type: SPEventType.REMOVE_NODE; node: N };

export const VISIT_RESULT_WRONG_WAY = -1;
export type VISIT_RESULT_WRONG_WAY = -1;

export interface DijkstraOptions<N, E> {
  curr: N;
  getDistance: (edge: Edge<N, E>, weight: number) => number;
  visitNode?: (dijk: DijkstraNode<N, E>) => boolean | void;
  visitEdge?: (edge: Edge<N, E>, weight: number) => boolean | void;
  excludedNodes?: Set<string>;
  excludedEdges?: Set<string>;
  directed?: boolean;
  reversed?: boolean;

  // in directed graphs, allows dijkstra to try undirected edges from both directions
  // perhaps expecting different weights from one direction to the other.
  // The effect is just that each undirected edge is traversed in both directions
  // allowing observer functions to witness both weights, but ultimately has no effect
  // on the final result of dijkstra. If set to true, then excludedEdges is indexed
  // by edge.uid + "." + sn(edge.from)
  tryBothDirections?: boolean;
}

export default class Graph<N, E> {
  adjacencyList: Map<string, Array<Edge<N, E>>> = new Map<
    string,
    Array<Edge<N, E>>
  >();
  edgeList: Map<string, Edge<N, E>> = new Map<string, Edge<N, E>>();
  reverseAdjacencyList: Map<string, Array<Edge<N, E>>> = new Map<
    string,
    Array<Edge<N, E>>
  >();
  id2Node: Map<string, N> = new Map<string, N>();

  // hash of the node to be used to index graphs later
  sn: (node: N) => string;

  visited = 0;
  bridgeTimeTick = 0;

  constructor(
    sn: (node: N) => string,
    adjacencyList?: Map<string, Array<Edge<N, E>>>,
    edgeList?: Map<string, Edge<N, E>>,
    reverseAdjacencyList?: Map<string, Array<Edge<N, E>>>,
    id2Node?: Map<string, N>,
    visited?: number,
    bridgeTimeTick?: number,
    private cloneEdgeValue?: (edge: E) => E,
  ) {
    this.sn = sn;
    if (adjacencyList) this.adjacencyList = cloneDeep(adjacencyList);
    if (edgeList) this.edgeList = cloneDeep(edgeList);
    if (reverseAdjacencyList)
      this.reverseAdjacencyList = cloneDeep(reverseAdjacencyList);
    if (id2Node) this.id2Node = cloneDeep(id2Node);
    if (visited) this.visited = cloneDeep(visited);
    if (bridgeTimeTick) this.bridgeTimeTick = cloneDeep(bridgeTimeTick);
  }

  _loadFromSubgraph(sub: SubGraph<N, E>) {
    const [nodes, edges] = sub;

    // we need to dedup edge uids because we add undirected edges twice
    // but we don't specify whether subgraphs are supposed to have those
    // double edges in there or whether they are supposed to be single only.
    const seenEdges = new Set<string>();
    for (const n of nodes) {
      this.addNode(n);
    }
    for (const e of edges) {
      if (seenEdges.has(e.uid)) {
        continue;
      }
      seenEdges.add(e.uid);

      if (e.isReversed) {
        // has to be directed
        this.addDirectedEdge(e.to, e.from, e.value, e.uid, true);
      } else if (e.isDirected) {
        this.addDirectedEdge(e.from, e.to, e.value, e.uid, e.isDirected);
      } else {
        this.addEdge(e.to, e.from, e.value, e.uid);
      }
    }
  }

  static fromSubgraph<N, E>(
    sub: SubGraph<N, E>,
    sn: (node: N) => string,
  ): Graph<N, E> {
    const result = new Graph<N, E>(sn);
    result._loadFromSubgraph(sub);
    return result;
  }

  toSubgraph(): SubGraph<N, E> {
    const nodes: N[] = [];
    const edges: Edge<N, E>[] = [];
    for (const [_, n] of this.id2Node) {
      nodes.push(n);
    }
    for (const [_, e] of this.edgeList) {
      edges.push(e);
    }
    return [nodes, edges];
  }

  clone() {
    return Graph.fromSubgraph(this.toSubgraph(), this.sn);
  }

  static canonizeEdges<N, E>(edges: Edge<N, E>[]) {
    const result: DirectedEdge<N, E>[] = [];
    for (const e of edges) {
      if (e.isReversed) {
        result.push({
          from: e.to,
          to: e.from,
          value: e.value,
          uid: e.uid,
          isDirected: true,
          isReversed: false,
        });
      } else {
        result.push({
          ...e,
          isDirected: true,
          isReversed: false,
        });
      }
    }
    return result;
  }

  addNode(node: N) {
    const nk = this.sn(node);
    if (!this.adjacencyList.has(nk)) {
      this.adjacencyList.set(nk, []);
      this.reverseAdjacencyList.set(nk, []);
      this.id2Node.set(nk, node);
    }
  }

  addDirectedEdge(
    from: N,
    to: N,
    edgeValue: E,
    uid?: string,
    isDirected: boolean = true,
  ): { forward: Edge<N, E>; backward: Edge<N, E> } {
    if (uid === undefined) {
      uid = v4();
    }
    this.addNode(from);
    this.addNode(to);
    const val = this.adjacencyList.get(this.sn(from))!;
    const reverseVal = this.reverseAdjacencyList.get(this.sn(to))!;
    const forward = {
      from,
      to,
      value: edgeValue,
      isDirected,
      uid,
      isReversed: false,
    };
    const backward = {
      from: to,
      to: from,
      value: edgeValue,
      isDirected,
      uid,
      isReversed: isDirected,
    };
    val.push(forward);
    reverseVal.push(backward);
    this.edgeList.set(uid, forward);
    return { forward, backward };
  }

  addEdge(a: N, b: N, edgeValue: E, uid?: string): Edge<N, E> {
    if (uid === undefined) {
      uid = v4();
    }
    const result = this.addDirectedEdge(a, b, edgeValue, uid, false);
    this.addDirectedEdge(b, a, edgeValue, uid, false);
    return result.forward;
  }

  removeEdge(edge: Edge<N, E>) {
    const from = this.sn(edge.from);
    const to = this.sn(edge.to);
    const list1 = this.adjacencyList.get(from);
    const list2 = this.reverseAdjacencyList.get(from);
    const list3 = this.adjacencyList.get(to);
    const list4 = this.reverseAdjacencyList.get(to);
    for (const list of [list1, list2, list3, list4]) {
      if (list) {
        const index = list.findIndex((e) => e.uid === edge.uid);
        if (index !== -1) {
          list.splice(index, 1);
        }
      }
    }
    this.edgeList.delete(edge.uid);
  }

  splitUndirectedEdge(edge: Edge<N, E>, newNode: N) {
    this.addNode(newNode);
    const e1 = this.addEdge(edge.from, newNode, edge.value);
    const e2 = this.addEdge(
      newNode,
      edge.to,
      this.cloneEdgeValue
        ? this.cloneEdgeValue(edge.value)
        : cloneSimple(edge.value),
    );
    this.removeEdge(edge);
    return [e1, e2];
  }

  displaceVertex(from: N, to: N) {
    const fromk = this.sn(from);
    const tok = this.sn(to);
    const edges = this.adjacencyList.get(fromk);
    const reverseEdges = this.reverseAdjacencyList.get(fromk);
    if (!edges || !reverseEdges) {
      throw new Error("missing node " + from);
    }
    for (const edge of edges) {
      edge.from = to;
      this.edgeList.set(edge.uid, edge);
    }
    for (const edge of reverseEdges) {
      edge.from = to;
    }
    this.adjacencyList.delete(fromk);
    this.reverseAdjacencyList.delete(fromk);
    this.adjacencyList.set(tok, edges);
    this.reverseAdjacencyList.set(tok, reverseEdges);
  }

  /**
   * For a tree, forest or dag, (and <= 1 root per tree), gets a full traversal order in DFS,
   * to get a nice node order for dynamic programming, without having to rewrite
   * the recursive graph traversal.
   *
   * @param roots Starting nodes for traversal.
   * @return Traversal order (from leaves to root, every node in order guarantees that all
   * children are visited.)
   */
  dagTraversal(roots: N[]): Array<Traversal<N, E>> {
    const traversals: Array<Traversal<N, E>> = new Array<Traversal<N, E>>();
    const seen: Set<string> = new Set<string>();
    for (const root of roots) {
      this.dagTraversalRecursive(root, null, seen, traversals);
    }
    return traversals;
  }

  // Only applies to undirected graphs.
  /* iterative version of
  bridgeUtil(
    u: N,
    visited: Set<string>,
    disc: Map<string, number>,
    low: Map<string, number>,
    parent: Map<string, N>,
    bridges: Array<Edge<N, E>>
  ) {
    const uk = this.sn(u);
    visited.add(uk);
    this.bridgeTimeTick++;
    disc.set(uk, this.bridgeTimeTick);
    low.set(uk, this.bridgeTimeTick);

    for (const e of [
      ...this.adjacencyList.get(uk)!,
      ...this.reverseAdjacencyList.get(uk)!,
    ]) {
      const v = e.to;
      const vk = this.sn(v);

      if (!visited.has(vk)) {
        parent.set(vk, u);
        this.bridgeUtil(v, visited, disc, low, parent, bridges);

        low.set(uk, Math.min(low.get(uk)!, low.get(vk)!));

        if (low.get(vk)! > disc.get(uk)!) {
          bridges.push(e);
        }
      } else if (vk !== (parent.get(uk) ? this.sn(parent.get(uk)!) : "")) {
        low.set(uk, Math.min(low.get(uk)!, disc.get(vk)!));
      }
    }
  }

  Could not think of a better way than to deconstruct function calling mechanism.
  */
  bridgeUtil(
    u: N,
    visited: Set<string>,
    disc: Map<string, number>,
    low: Map<string, number>,
    parent: Map<string, N>,
    bridges: Array<Edge<N, E>>,
  ) {
    let v!: N;
    let vk!: string;
    let uk!: string;
    let edges!: Array<Edge<N, E>>;
    let edges_i!: number;
    let e!: Edge<N, E>;
    let returnLabel: string = "";

    // Storing *previous* local variable states. (Not the current ones.)
    const stack: Array<{
      u: N; // OMG don't forget to save this :> wasted hours
      v: N;
      vk: string;
      uk: string;
      edges: Array<Edge<N, E>>;
      edges_i: number;
      e: Edge<N, E>;
      returnLabel: string;
    }> = [];

    let goto = "fn_start";
    program: while (true) {
      switch (goto) {
        case "fn_start":
          uk = this.sn(u);
          visited.add(uk);

          this.bridgeTimeTick++;
          disc.set(uk, this.bridgeTimeTick);
          low.set(uk, this.bridgeTimeTick);

          edges = [
            ...this.adjacencyList.get(uk)!,
            ...this.reverseAdjacencyList.get(uk)!,
          ];
          edges_i = 0;
        case "loop_start":
          let str = "";
          for (let k = 0; k < stack.length; k++) {
            str += "    ";
          }
          if (edges_i >= edges.length) {
            goto = "loop_end";
            continue;
          }

          e = edges![edges_i!];
          v = e.to;
          vk = this.sn(v);

          if (!visited.has(vk)) {
            parent.set(vk, u);
            stack.push({
              u,
              v,
              vk,
              uk,
              edges,
              edges_i,
              e,
              returnLabel: "bridgeutil_return",
            });
            u = v;
            goto = "fn_start";
            continue;
          } else if (vk !== (parent.get(uk) ? this.sn(parent.get(uk)!) : "")) {
            low.set(uk, Math.min(low.get(uk)!, disc.get(vk)!));
          }
        case "loop_post":
          edges_i!++;
          goto = "loop_start";
          continue;
        case "loop_end":
          goto = "fn_end";
          continue;
        case "bridgeutil_return":
          low.set(uk, Math.min(low.get(uk)!, low.get(vk!)!));

          if (low.get(vk!)! > disc.get(uk)!) {
            bridges.push(e!);
          }
          goto = "loop_post";
          continue;
        case "fn_end":
          if (!stack.length) {
            break program;
          }
          ({ u, v, vk, uk, edges, edges_i, e, returnLabel } = stack.pop()!);
          goto = returnLabel;
          continue;
      }
    }
  }

  findBridges(): Array<Edge<N, E>> {
    const visited = new Set<string>();
    const disc = new Map<string, number>();
    const low = new Map<string, number>();
    const parent = new Map<string, N>();

    const bridges: Array<Edge<N, E>> = [];

    for (const n of this.id2Node.values()) {
      if (!visited.has(this.sn(n))) {
        this.bridgeUtil(n, visited, disc, low, parent, bridges);
      }
    }

    return bridges;
  }

  findBridgeSeparatedComponents(): [Array<Edge<N, E>>, Array<SubGraph<N, E>>] {
    const bridges = this.findBridges();

    const components: Array<SubGraph<N, E>> = [];

    const visited = new Set<string>();
    const visitedEdges = new Set<string>();
    const bridgeEdges = new Set<string>();
    for (const b of bridges) {
      visitedEdges.add(b.uid);
      bridgeEdges.add(b.uid);
    }
    // find the resulting bridge-sea
    for (const e of this.edgeList.values()) {
      if (!visitedEdges.has(e.uid) || bridgeEdges.has(e.uid)) {
        const component: SubGraph<N, E> = [[], []];

        if (bridgeEdges.has(e.uid)) {
          component[0].push(e.from);
          component[0].push(e.to);
          component[1].push(e);
        } else {
          this.dfs(
            e.to,
            (node) => {
              component[0].push(node);
            },
            undefined,
            (edge) => {
              component[1].push(edge);
            },
            undefined,
            visited,
            visitedEdges,
            false,
            false,
          );
        }

        components.push(component);
      }
    }

    return [bridges, components];
  }

  dagTraversalRecursive(
    curr: N,
    parentPath: Edge<N, E> | null,
    seen: Set<string>,
    traversals: Array<Traversal<N, E>>,
  ) {
    const ck = this.sn(curr);
    if (seen.has(ck)) {
      return;
    }
    seen.add(ck);
    const traversal: Traversal<N, E> = {
      node: curr,
      parent: parentPath,
      children: [],
    };

    const nei = this.adjacencyList.get(ck);
    if (nei) {
      for (const next of nei) {
        if (parentPath === null || parentPath.from !== next.to) {
          traversal.children.push(next);
        }
        if (!seen.has(this.sn(next.to))) {
          this.dagTraversalRecursive(next.to, next, seen, traversals);
        }
      }
    } else {
      throw new Error("Node missing from adjacency list " + curr);
    }

    traversals.push(traversal);
  }

  dfs(
    start: N,
    visitNode?: (node: N) => boolean | void,
    leaveNode?: (node: N, wasCancelled?: boolean) => void,
    visitEdge?: (edge: Edge<N, E>) => boolean | VISIT_RESULT_WRONG_WAY | void,
    leaveEdge?: (edge: Edge<N, E>, wasCancelled?: boolean) => void,
    seen?: Set<string>,
    seenEdges?: Set<string>,
    directed: boolean = true,
    reversed: boolean = false,

    // in directed graphs, allows dfs to try undirected edges from both directions.
    // If set to true, then seenEdges is indexed
    // by edge.uid + "." + sn(edge.from) instead of just edge.uid
    tryBothDirections?: boolean,
  ) {
    return this.graphSearch(
      "dfs",
      start,
      visitNode,
      leaveNode,
      visitEdge,
      leaveEdge,
      seen,
      seenEdges,
      directed,
      reversed,
      tryBothDirections,
    );
  }

  /**
   * Guaranteed that visitEdge is called exactly once for all reachable edges, and visitNode
   * is called once for each visitable node.
   *
   * Small guide after reading
   * - Visit node: Calls when pop out an element from queue
   * - Visit edge: calls when inspect edge from recently popped out node
   * - Leave condition:
   */
  graphSearch(
    mode: "dfs" | "bfs",
    start: N,
    visitNode?: (node: N) => boolean | void,
    leaveNode?: (node: N, wasCancelled?: boolean) => void,
    visitEdge?: (edge: Edge<N, E>) => boolean | VISIT_RESULT_WRONG_WAY | void,
    leaveEdge?: (edge: Edge<N, E>, wasCancelled?: boolean) => void,
    seen?: Set<string>,
    seenEdges?: Set<string>,
    directed: boolean = true,
    reversed: boolean = false,

    // in directed graphs, allows dfs to try undirected edges from both directions.
    // If set to true, then seenEdges is indexed
    // by edge.uid + "." + sn(edge.from) instead of just edge.uid
    tryBothDirections?: boolean,
  ) {
    this.visited++;
    if (seenEdges === undefined) {
      seenEdges = new Set<string>();
    }
    if (seen === undefined) {
      seen = new Set<string>();
    }

    let curr = start;
    const stack: N[] = [start];
    let head = 0;

    // While graph is not empty
    while (head < stack.length) {
      if (mode === "dfs") {
        curr = stack.pop()!;
      } else {
        curr = stack[head++];
      }

      const currS = this.sn(curr);

      // Check visit status
      if (seen.has(currS)) {
        continue;
      }
      seen.add(currS);

      // If visitNode Condition specified, and returns true
      // skip the node. (visitNode return true is should not visit)
      if (visitNode) {
        const shouldNotVisitNode = visitNode(curr);
        if (shouldNotVisitNode !== undefined && shouldNotVisitNode) {
          if (leaveNode) {
            leaveNode(curr, true);
          }
          continue;
        }
      }

      let getNeigbours = (reversed: boolean, directed: boolean) => {
        const forward = this.adjacencyList.get(currS);
        const backward = this.reverseAdjacencyList.get(currS);
        if (!forward || !backward) {
          throw new Error("cannot find node " + currS);
        }

        if (!directed) {
          return [...forward, ...backward];
        }
        if (!reversed) {
          return forward;
        } else {
          return backward;
        }
      };

      // Get neigbours of current node
      let nei = getNeigbours(reversed, directed);

      for (const next of nei) {
        const edgeIndex = tryBothDirections
          ? next.uid + "." + this.sn(next.from)
          : next.uid;
        if (seenEdges!.has(edgeIndex)) {
          continue;
        }
        seenEdges!.add(edgeIndex);

        // Check visitEdge condition, it runs while inspecting this node
        if (visitEdge) {
          const shouldNotVisitEdge = visitEdge(next);
          if (shouldNotVisitEdge !== undefined && shouldNotVisitEdge) {
            if (leaveEdge) {
              leaveEdge(next, true);
            }
            if (shouldNotVisitEdge === VISIT_RESULT_WRONG_WAY) {
              seenEdges.delete(edgeIndex);
            }
            continue;
          }
        }
        if (!seen!.has(this.sn(next.to))) {
          stack.push(next.to);
        }
        if (leaveEdge) {
          leaveEdge(next);
        }
      }

      if (leaveNode) {
        leaveNode(curr);
      }
    }
  }

  bfs(
    start: N,
    visitNode?: (node: N) => boolean | void,
    visitEdge?: (edge: Edge<N, E>) => boolean | VISIT_RESULT_WRONG_WAY | void,
    seen?: Set<string>,
    seenEdges?: Set<string>,
    directed: boolean = true,
    reversed: boolean = false,
    tryBothDirections?: boolean,
  ) {
    return this.graphSearch(
      "bfs",
      start,
      visitNode,
      undefined,
      visitEdge,
      undefined,
      seen,
      seenEdges,
      directed,
      reversed,
      tryBothDirections,
    );
  }

  /**
   * Guaranteed that visitEdge is called exactly once for all reachable edges, and visitNode
   * is called once for each visitable node.
   * 
   * This is an iterative version of:
   * 
   dfsRecursive(
     start: N,
     visitNode?: (node: N) => boolean | void,
     leaveNode?: (node: N, wasCancelled?: boolean) => void,
     visitEdge?: (edge: Edge<N, E>) => boolean | void,
     leaveEdge?: (edge: Edge<N, E>, wasCancelled?: boolean) => void,
     seen?: Set<string>,
     seenEdges?: Set<string>,
     directed: boolean = true,
     reversed: boolean = false,

    // in directed graphs, allows dfs to try undirected edges from both directions.
    // If set to true, then seenEdges is indexed
    // by edge.uid + "." + sn(edge.from) instead of just edge.uid
    tryBothDirections?: boolean,
    randomize: boolean = false
   ) {
    this.visited++;
    if (seenEdges === undefined) {
      seenEdges = new Set<string>();
    }
    if (seen === undefined) {
      seen = new Set<string>();
    }

    const currS = this.sn(start);

    if (seen.has(currS)) {
      return;
    }
    seen.add(currS);

    if (visitNode) {
      const should = visitNode(start);
      if (should !== undefined && should) {
        if (leaveNode) {
          leaveNode(start, true);
        }
        return;
      }
    }

    let nei;
    const forward = this.adjacencyList.get(currS);
    const backward = this.reverseAdjacencyList.get(currS);
    if (!forward || !backward) {
      throw new Error("cannot find node " + currS);
    }
    if (!reversed) {
      nei = forward;
    } else {
      nei = backward;
    }
    if (!directed) {
      nei = [...forward, ...backward];
    }

    if (randomize) {
      nei = shuffle(nei);
    }

    for (const next of nei) {
      const edgeIndex = tryBothDirections
        ? next.uid + "." + this.sn(next.from)
        : next.uid;
      if (seenEdges!.has(edgeIndex)) {
        continue;
      }
      seenEdges!.add(edgeIndex);

      if (visitEdge) {
        const should = visitEdge(next);
        if (should !== undefined && should) {
          if (leaveEdge) {
            leaveEdge(next, true);
          }

          continue;
        }
      }
      if (!seen!.has(this.sn(next.to))) {
        this.dfsRecursive(
          next.to,
          visitNode,
          leaveNode,
          visitEdge,
          leaveEdge,
          seen,
          seenEdges,
          directed,
          reversed,
          tryBothDirections,
          randomize
        );
      }
      if (leaveEdge) {
        leaveEdge(next);
      }
    }

    if (leaveNode) {
      leaveNode(start);
    }

  }
   
    The iterative function form is needed because the webworker threads have too low stack size.
   */
  dfsRecursive(
    start: N,
    visitNode?: (node: N) => boolean | void,
    leaveNode?: (node: N, wasCancelled?: boolean) => void,
    visitEdge?: (edge: Edge<N, E>) => boolean | void,
    leaveEdge?: (edge: Edge<N, E>, wasCancelled?: boolean) => void,
    seen?: Set<string>,
    seenEdges?: Set<string>,
    directed: boolean = true,
    reversed: boolean = false,

    // in directed graphs, allows dfs to try undirected edges from both directions.
    // If set to true, then seenEdges is indexed
    // by edge.uid + "." + sn(edge.from) instead of just edge.uid
    tryBothDirections?: boolean,
    randomize: boolean = false,
  ) {
    let nei!: Edge<N, E>[];
    let nei_i!: number;
    let next!: Edge<N, E>;
    let returnLabel!: string;

    const stack: Array<{
      start: N;
      next: Edge<N, E>;
      nei: Edge<N, E>[];
      nei_i: number;
      returnLabel: string;
    }> = [];

    let goto = "fn_start";
    while (true) {
      switch (goto) {
        case "fn_start":
          this.visited++;
          if (seenEdges === undefined) {
            seenEdges = new Set<string>();
          }
          if (seen === undefined) {
            seen = new Set<string>();
          }

          const currS = this.sn(start);

          if (seen.has(currS)) {
            goto = "fn_end";
            continue;
          }
          seen.add(currS);

          if (visitNode) {
            const should = visitNode(start);
            if (should !== undefined && should) {
              if (leaveNode) {
                leaveNode(start, true);
              }
              goto = "fn_end";
              continue;
            }
          }

          const forward = this.adjacencyList.get(currS);
          const backward = this.reverseAdjacencyList.get(currS);
          if (!forward || !backward) {
            throw new Error("cannot find node " + currS);
          }
          if (!reversed) {
            nei = forward;
          } else {
            nei = backward;
          }
          if (!directed) {
            nei = [...forward, ...backward];
          }

          if (randomize) {
            nei = shuffle(nei);
          }
          nei_i = 0;
        case "loop":
          if (nei_i >= nei.length) {
            goto = "loop_exit";
            continue;
          }

          next = nei[nei_i];
          const edgeIndex = tryBothDirections
            ? next.uid + "." + this.sn(next.from)
            : next.uid;
          if (seenEdges!.has(edgeIndex)) {
            goto = "loop_end";
            continue;
          }
          seenEdges!.add(edgeIndex);

          if (visitEdge) {
            const should = visitEdge(next);
            if (should !== undefined && should) {
              if (leaveEdge) {
                leaveEdge(next, true);
              }
              goto = "loop_end";
              continue;
            }
          }
          if (!seen!.has(this.sn(next.to))) {
            stack.push({
              start,
              next,
              nei,
              nei_i,
              returnLabel: "dfs_return",
            });
            start = next.to;
            goto = "fn_start";
            continue;
          }
        case "dfs_return":
          if (leaveEdge) {
            leaveEdge(next);
          }
        case "loop_end":
          nei_i++;
          goto = "loop";
          continue;
        case "loop_exit":
          if (leaveNode) {
            leaveNode(start);
          }
        case "fn_end":
          if (stack.length === 0) {
            return;
          }
          ({ start, next, nei, nei_i, returnLabel } = stack.pop()!);
          goto = returnLabel;
          continue;
      }
    }
  }

  dijkstra(options: DijkstraOptions<N, E>) {
    let {
      curr,
      getDistance,
      visitNode,
      visitEdge,
      excludedNodes,
      excludedEdges,
      directed,
      reversed,
      tryBothDirections,
    } = options;
    if (directed === undefined) {
      directed = true;
    }
    if (reversed === undefined) {
      reversed = false;
    }

    if (excludedEdges === undefined) {
      excludedEdges = new Set<string>();
    }
    if (excludedNodes === undefined) {
      excludedNodes = new Set<string>();
    }

    const start: Array<DijkstraNode<N, E>> = [
      {
        weight: 0,
        node: curr,
      },
    ];
    // Priority Queue
    const q = new TinyQueue(start, (a, b) => {
      return a.weight - b.weight;
    });

    while (q.length) {
      const top = q.pop()!;

      if (visitEdge && top.parent && visitEdge(top.parent, top.weight)) {
        continue;
      }

      if (excludedNodes.has(this.sn(top.node))) {
        continue;
      }

      if (visitNode && visitNode(top)) {
        continue;
      }

      excludedNodes.add(this.sn(top.node));

      const forward_orig = this.adjacencyList.get(this.sn(top.node));
      const backward_orig = this.reverseAdjacencyList.get(this.sn(top.node));
      const forward = forward_orig ? Array.from(forward_orig) : null;
      const backward = backward_orig ? Array.from(backward_orig) : null;

      if (!forward || !backward) {
        throw new Error("cannot find node " + this.sn(top.node));
      }

      let nei = reversed ? backward : forward;
      if (!directed) {
        nei = [...forward, ...backward];
      }

      for (const edge of nei) {
        const edgeUid = tryBothDirections
          ? edge.uid + "." + this.sn(edge.from)
          : edge.uid;

        if (excludedEdges!.has(edgeUid)) {
          continue;
        }
        excludedEdges!.add(edgeUid);

        q.push({
          weight: top.weight + getDistance(edge, top.weight),
          node: edge.to,
          parent: edge,
        });
      }
    }
  }

  /**
   * Only makes sense for directed graphs.
   */
  connectedComponents(): Array<SubGraph<N, E>> {
    const components: Array<SubGraph<N, E>> = new Array<SubGraph<N, E>>();
    const seen: Set<string> = new Set<string>();

    this.adjacencyList.forEach((adj, node) => {
      components.push(this.getConnectedComponent(node, seen));
    });

    return components;
  }

  getConnectedComponent(node: N | string, seen?: Set<string>): SubGraph<N, E> {
    if (!seen) {
      seen = new Set<string>();
    }

    let nodeS: string;
    if (typeof node === "string") {
      nodeS = node;
    } else {
      nodeS = this.sn(node);
    }

    const subGraph: SubGraph<N, E> = [[], []];
    if (!seen.has(nodeS)) {
      this.dfs(
        this.id2Node.get(nodeS)!,
        (n) => {
          subGraph[0].push(n);
        },
        undefined,
        (e) => {
          subGraph[1].push(e);
        },
        undefined,
        seen,
      );
    }

    return subGraph;
  }

  anyPath(options: {
    from: N;
    to: Set<N> | N[] | N;
    seenNodes?: Set<N>;
    seenEdges?: Set<string>;
    directed?: boolean;
    reversed?: boolean;
    edgeFilter?: (e: Edge<N, E>) => boolean;
    randomize?: boolean;
  }): Array<Edge<N, E>> | null {
    let {
      from,
      to,
      seenNodes,
      seenEdges,
      directed,
      reversed,
      edgeFilter,
      randomize,
    } = options;
    if (directed === undefined) {
      directed = true;
    }
    if (reversed === undefined) {
      reversed = false;
    }

    const cache: Array<Edge<N, E>> = [];
    let result: Array<Edge<N, E>> | null = null;
    let found = false;

    let seenNodesS: Set<string> | undefined;
    if (seenNodes) {
      seenNodesS = new Set<string>();
      for (const n of seenNodes) {
        seenNodesS!.add(this.sn(n));
      }
    }

    const toS = new Set<string>();
    if (to instanceof Set || to instanceof Array) {
      for (const n of to) {
        toS.add(this.sn(n));
      }
    } else {
      toS.add(this.sn(to));
    }

    this.dfsRecursive(
      from,
      (n) => {
        if (toS.has(this.sn(n))) {
          found = true;
          result = cloneSimple(cache);
        }
        return found;
      },
      undefined,
      (e) => {
        if (found) {
          return true;
        }
        cache.push(e);
        if (edgeFilter) {
          if (!edgeFilter(e)) {
            return true;
          }
        }
        return false;
      },
      (e) => {
        cache.pop();
      },
      seenNodesS,
      seenEdges,
      directed,
      reversed,
      undefined,
      randomize,
    );

    return result;
  }

  shortestPath(
    from: N,
    to: Set<N> | N[] | N,

    // omitting getDistance uses bfs
    getDistance?: (edge: Edge<N, E>) => number,
    seenNodes?: Set<string>,
    seenEdges?: Set<string>,
    directed: boolean = true,
    reversed: boolean = false,
  ): [Array<Edge<N, E>>, number] | null {
    const parentOf: Map<string, Edge<N, E>> = new Map<string, Edge<N, E>>();
    let found = false;

    const toS: Set<string> = new Set<string>();
    if (to instanceof Set) {
      for (const n of to) {
        toS.add(this.sn(n));
      }
    } else if (to instanceof Array) {
      for (const n of to) {
        toS.add(this.sn(n));
      }
    } else {
      toS.add(this.sn(to));
    }

    let destination: N | null = null;
    let dist = 0;

    if (getDistance) {
      this.dijkstra({
        curr: from,
        getDistance,
        visitNode: (dijk) => {
          if (dijk.parent) {
            parentOf.set(this.sn(dijk.node), dijk.parent);
          }
          if (found) {
            return true;
          }
          if (toS.has(this.sn(dijk.node))) {
            destination = dijk.node;
            found = true;
            dist = dijk.weight;
            return true;
          }
        },
        excludedNodes: seenNodes,
        excludedEdges: seenEdges,
        directed,
        reversed,
      });
    } else {
      this.bfs(
        from,
        (n) => {
          if (found) {
            return true;
          }
          if (toS.has(this.sn(n))) {
            destination = n;
            found = true;
            return true;
          }
        },
        (e) => {
          if (found) {
            return true;
          }
          if (e.from && e.to && !parentOf.has(this.sn(e.to))) {
            parentOf.set(this.sn(e.to), e);
          }
        },
        seenNodes,
        seenEdges,
        directed,
        reversed,
      );
    }

    if (destination === null) {
      return null;
    }

    const path: Array<Edge<N, E>> = [];

    let curr: N = destination;
    while (this.sn(curr) !== this.sn(from)) {
      const prev = parentOf.get(this.sn(curr))!;
      path.unshift(prev);
      curr = prev.from;
    }

    return [path, dist];
  }

  reachable(root: N): SubGraph<N, E> {
    const subGraph: SubGraph<N, E> = [[], []];
    this.dfs(
      root,
      (n) => {
        subGraph[0].push(n);
      },
      undefined,
      (e) => {
        subGraph[1].push(e);
      },
    );
    return subGraph;
  }

  edgeCycleCover(options?: {
    directed?: boolean;
    shortest?: boolean;
    random?: boolean;
  }): Array<Array<Edge<N, E>>> {
    const directed = options?.directed ?? true;
    const shortest = options?.shortest ?? false;
    const random = options?.random ?? false;

    const result: Array<Array<Edge<N, E>>> = [];
    const seenEdges: Set<string> = new Set<string>();

    const nodes = Array.from(this.adjacencyList.keys());
    if (random) {
      shuffle(nodes);
    }

    for (const n of nodes) {
      for (const e of this.adjacencyList.get(n)!.slice()) {
        const ret = this.getCycleCovering(
          seenEdges,
          e,
          directed,
          () => true,
          shortest,
          random,
        );
        if (ret) {
          result.push(ret);
        }
      }
    }

    if (random) {
      shuffle(result);
    }

    return result;
  }

  getCycleCovering(
    seenEdges: Set<string>,
    e: Edge<N, E>,
    directed: boolean,
    edgeFilter: (e: Edge<N, E>) => boolean,
    shortest: boolean = false,
    random: boolean = false,
  ) {
    if (
      seenEdges.has(e.uid) ||
      (e.value as unknown as FlowEdge).type === EdgeType.FITTING_FLOW
    ) {
      return null;
    }

    const newEdges = new Set<string>([e.uid]);
    const thisCycle = shortest
      ? this.shortestPath(
          e.to,
          e.from,
          undefined,
          undefined,
          newEdges,
          directed,
          false,
        )?.[0]
      : this.anyPath({
          from: e.to,
          to: e.from,
          seenEdges: newEdges,
          directed,
          edgeFilter,
          randomize: random,
        });

    if (thisCycle) {
      thisCycle.push(e);
      for (const ee of thisCycle) {
        seenEdges.add(ee.uid);
      }
      return thisCycle;
    }
    return null;
  }

  sourceArcCover(
    sources: N[],
    accountedFor?: Set<string>,
    randomize: boolean = false,
  ): Array<Array<Edge<N, E>>> {
    const result: Array<Array<Edge<N, E>>> = [];
    const notAccountedFor = new Set<string>();
    // Find flows of bridges, which have definite flow. TODO: This can be reduced in algorithmic complexity.
    const done = new Set<string>();
    this.edgeList.forEach((v, k) => {
      if (accountedFor && accountedFor.has(k)) {
        return;
      }

      if (done.has(k)) {
        return;
      }

      // Find two sources that can reach both ends.
      const srcPath1 = this.anyPath({
        from: v.from,
        to: sources,
        seenEdges: new Set([k]),
        randomize,
      });
      if (srcPath1) {
        const srcPath2 = this.anyPath({
          from: v.to,
          to: sources,
          seenEdges: new Set([k]),
          randomize,
        });

        if (srcPath2) {
          // Two sources should not be the same, because if so, they would be a loop instead.

          const path1Reversed = this.reversePath(srcPath1);
          const fullPath: Array<Edge<N, E>> = [
            ...path1Reversed,
            v,
            ...srcPath2,
          ];
          result.push(fullPath);
          for (const e of fullPath) {
            done.add(e.uid);
          }
        } else {
          notAccountedFor.add(k);
        }
      } else {
        notAccountedFor.add(k);
      }
    });

    return result;
  }

  /**
   * Returns a list of paths that together cover all the edges.
   * This is used for example in hot water loops where we need an initial
   * distribution of flow that is non zero in every pipe, to guarantee
   * convergence in the flow rate phase.
   *
   * Note: it requires that the system is a connected dag and every source
   * + sink is specified.
   */
  DAGFlowPathCover(options: {
    sources: N[];
    sinks: N[];
    onEdge?: (e: Edge<N, E>, msg?: string) => void;
  }) {
    const { sources, sinks, onEdge } = options;
    const result: Array<Array<Edge<N, E>>> = [];
    const accountedFor = new Set<string>();

    for (const [uid, edge] of this.edgeList.entries()) {
      if (accountedFor.has(uid)) {
        continue;
      }

      if (onEdge) {
        onEdge(edge);
      }

      const fromPathRev = this.anyPath({
        from: edge.from,
        to: sources,
        reversed: true,
      });
      const fromPath = fromPathRev && this.reversePath(fromPathRev);

      const toPath = this.anyPath({
        from: edge.to,
        to: sinks,
      });

      if (fromPath && toPath) {
        const path = [...fromPath, edge, ...toPath];
        result.push(path);
        for (const e of path) {
          accountedFor.add(e.uid);
        }
      } else {
        if (onEdge) {
          onEdge(edge, "From found: " + !!fromPath + ", to found: " + !!toPath);
        }
        console.error(edge);
        throw new Error(
          "Cannot find path from source to sink while covering DAG",
        );
      }
    }

    return result;
  }

  reversePath(path: Array<Edge<N, E>>) {
    return path.reverse().map((e): Edge<N, E> => {
      return {
        from: e.to,
        isDirected: e.isDirected,
        isReversed: e.isDirected ? !e.isReversed : false,
        to: e.from,
        uid: e.uid,
        value: e.value,
      };
    });
  }
}

export type SubGraph<N, E> = [N[], Array<Edge<N, E>>];

export interface Edge<N, E> {
  from: N;
  to: N;
  value: E;
  isDirected: boolean;
  isReversed: boolean;
  uid: string;
}

// edge representation that's easier to handle (eg has no isReversed prop to trip up on)
// not used internally in the graph class - only passed around in external methods.
export interface DirectedEdge<N, E> {
  from: N;
  to: N;
  value: E;
  isDirected: true;
  isReversed: false;
  uid: string;
}

export interface Traversal<N, E> {
  node: N;
  children: Array<Edge<N, E>>;
  parent: Edge<N, E> | null;
}

export interface DijkstraNode<N, E> {
  weight: number;
  node: N;
  parent?: Edge<N, E>;
}
