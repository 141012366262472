import { Module } from "vuex";
import { EntityType } from "../../../../common/src/api/document/entities/types";
import {
  assertUnreachable,
  cloneSimple,
} from "../../../../common/src/lib/utils";
import { RootState } from "../types";
import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { DocumentState, initialDocumentState } from "./types";

export const state: DocumentState = cloneSimple(initialDocumentState);

const namespaced: boolean = true;

export const document: Module<DocumentState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};

// Higher priority replaces lower priority
export function getDragPriority(type: EntityType): number {
  switch (type) {
    case EntityType.SYSTEM_NODE:
      return 200;
    case EntityType.LOAD_NODE:
      return 100;
    case EntityType.FLOW_SOURCE:
    case EntityType.VERTEX:
      return 50;
    case EntityType.MULTIWAY_VALVE:
      return 40;
    case EntityType.DIRECTED_VALVE:
      return 30;
    case EntityType.RISER:
      return 10;
    case EntityType.FITTING:
    case EntityType.GAS_APPLIANCE:
      return 5;
    case EntityType.BIG_VALVE:
    case EntityType.FIXTURE:
    case EntityType.PLANT:
    case EntityType.BACKGROUND_IMAGE:
    case EntityType.CONDUIT:
    case EntityType.EDGE:
    case EntityType.LINE:
    case EntityType.COMPOUND:
    case EntityType.ROOM:
    case EntityType.ARCHITECTURE_ELEMENT:
    case EntityType.WALL:
    case EntityType.FENESTRATION:
    case EntityType.ANNOTATION:
    case EntityType.DAMPER:
    case EntityType.AREA_SEGMENT:
      throw new Error("not a connectable");
  }
  assertUnreachable(type);
}
