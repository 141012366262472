import Flatten from "@flatten-js/core";
import {
  assertUnreachable,
  cloneSimple,
  interpolateTable,
  lowerBoundNumberTable,
  parseCatalogNumberExact,
} from "../../lib/utils";
import { GetPressureLossOptions } from "../calculations/entity-pressure-drops";
import {
  fittingFrictionLossMH,
  getFluidDensityOfSystem,
  head2kpa,
} from "../calculations/pressure-drops";
import {
  CoreContext,
  CostBreakdown,
  PressureLossResult,
} from "../calculations/types";
import { ComponentPressureLossMethod, StandardFlowSystemUids } from "../config";
import BigValveCalculation, {
  BigValveLiveCalculation,
} from "../document/calculations-objects/big-valve-calculation";
import { SelectedMaterialManufacturer } from "../document/drawing";
import BigValveEntity, {
  BigValveType,
  fillDefaultBigValveFields,
} from "../document/entities/big-valve/big-valve-entity";
import { ValveType } from "../document/entities/directed-valves/valve-types";
import { EntityType } from "../document/entities/types";
import CoreCentered from "./core-traits/coreCentered";
import CoreConduit from "./coreConduit";
import { BIG_VALVE_DEFAULT_PIPE_WIDTH_MM } from "./corePlant";
import CoreSystemNode from "./coreSystemNode";
import { CoreCalculatableObject } from "./lib/CoreCalculatableObject";
import CoreBaseBackedObject from "./lib/coreBaseBackedObject";
import {
  getIdentityCalculationEntityUid,
  getRpzdBigValveHeightMM,
  getRpzdManufacturer,
  getRpzdPressureLossKPA,
  getValveK,
  largestPipeSizeNominalMM,
} from "./utils";

export default class CoreBigValve extends CoreCentered(
  CoreCalculatableObject<BigValveEntity>,
) {
  type: EntityType.BIG_VALVE = EntityType.BIG_VALVE;

  get refPath(): string {
    return `${this.entity.type}.${this.entity.valve.type}`;
  }

  get filledEntity(): BigValveEntity {
    return fillDefaultBigValveFields(this.context, this.entity);
  }

  getFrictionPressureLossKPA(
    options: GetPressureLossOptions,
  ): PressureLossResult {
    let { context, flowLS, from, to, signed } = options;
    const ga =
      context.drawing.metadata.calculationParams.gravitationalAcceleration;
    let sign = 1;
    if (flowLS < 0) {
      const oldFrom = from;
      from = to;
      to = oldFrom;
      flowLS = -flowLS;
      if (signed) {
        sign = -1;
      }
    }

    const { drawing, catalog } = context;
    const entity = this.entity;

    // it is directional so we must guard every one.
    let valid = false;
    switch (entity.valve.type) {
      case BigValveType.TMV:
        if (
          from.connectable === entity.hotRoughInUid &&
          to.connectable === entity.valve.warmOutputUid
        ) {
          valid = true;
        }
        if (
          from.connectable === entity.coldRoughInUid &&
          to.connectable === entity.valve.coldOutputUid
        ) {
          valid = true;
        }
        break;
      case BigValveType.TEMPERING:
        if (
          from.connectable === entity.hotRoughInUid &&
          to.connectable === entity.valve.warmOutputUid
        ) {
          valid = true;
        }
        break;
      case BigValveType.RPZD_HOT_COLD:
        if (
          from.connectable === entity.hotRoughInUid &&
          to.connectable === entity.valve.hotOutputUid
        ) {
          valid = true;
        }
        if (
          from.connectable === entity.coldRoughInUid &&
          to.connectable === entity.valve.coldOutputUid
        ) {
          valid = true;
        }
        break;
    }
    if (!valid) {
      // Water not flowing the correct direction
      return { pressureLossKPA: sign * (1e10 + flowLS) };
    }

    switch (
      context.drawing.metadata.calculationParams.componentPressureLossMethod
    ) {
      case ComponentPressureLossMethod.INDIVIDUALLY:
        // Find pressure loss from pipe size changes
        break;
      case ComponentPressureLossMethod.PERCENT_ON_TOP_OF_PIPE:
        return { pressureLossKPA: 0 };
      default:
        assertUnreachable(
          context.drawing.metadata.calculationParams
            .componentPressureLossMethod,
        );
    }

    // The actial pressure drop depends on the connection
    let pdKPA: number | null = null;
    if (entity.valve.type === BigValveType.RPZD_HOT_COLD) {
      const systemUid = this.globalStore.getObjectOfType(
        EntityType.SYSTEM_NODE,
        from.connectable,
      )!.entity.systemUid;
      const calcs = context.globalStore.getOrCreateCalculation(this.entity);
      const size = calcs.rpzdSizeMM![systemUid]!;
      const res = getRpzdPressureLossKPA(
        context,
        this.entity.valve.catalogId,
        size,
        flowLS,
        systemUid,
        ValveType.RPZD_SINGLE,
      );
      return res;
    } else if (from.connectable === entity.coldRoughInUid) {
      if (
        entity.valve.type === BigValveType.TEMPERING ||
        to.connectable !== entity.valve.coldOutputUid
      ) {
        throw new Error(
          "Invalid configuration - cold input must connect to cold out only",
        );
      }

      // pressure drop is an elbow and a tee for the cold part.
      const kValue1 = getValveK(
        "tThruBranch",
        context.catalog,
        BIG_VALVE_DEFAULT_PIPE_WIDTH_MM,
      );
      const kValue2 = getValveK(
        "90Elbow",
        context.catalog,
        BIG_VALVE_DEFAULT_PIPE_WIDTH_MM,
      );

      if (kValue1 === null || kValue2 === null) {
        return { pressureLossKPA: null };
      }

      const kValue = kValue1 + kValue2;
      const volLM = (BIG_VALVE_DEFAULT_PIPE_WIDTH_MM ** 2 * Math.PI) / 4 / 1000;
      const velocityMS = flowLS / volLM;
      return {
        pressureLossKPA:
          sign *
          head2kpa(
            fittingFrictionLossMH(velocityMS, kValue, ga),
            getFluidDensityOfSystem(context, StandardFlowSystemUids.ColdWater)!,
            ga,
          ),
      };
    } else {
      switch (entity.valve.type) {
        case BigValveType.TMV: {
          const manufacturer =
            drawing.metadata.catalog.mixingValves.find(
              (material: SelectedMaterialManufacturer) =>
                material.uid === "tmv",
            )?.manufacturer || "generic";
          const pdKPAfield = interpolateTable(
            catalog.mixingValves.tmv.pressureLossKPAbyFlowRateLS[manufacturer],
            flowLS,
          );
          pdKPA = parseCatalogNumberExact(pdKPAfield);
          break;
        }
        case BigValveType.TEMPERING: {
          const manufacturer =
            drawing.metadata.catalog.mixingValves.find(
              (material: SelectedMaterialManufacturer) =>
                material.uid === "temperingValve",
            )?.manufacturer || "generic";
          const pdKPAfield = interpolateTable(
            catalog.mixingValves.temperingValve.pressureLossKPAbyFlowRateLS[
              manufacturer
            ],
            flowLS,
          );
          pdKPA = parseCatalogNumberExact(pdKPAfield);
          break;
        }
        default:
          assertUnreachable(entity.valve);
      }
      if (pdKPA === null) {
        throw new Error("pressure drop for TMV not available");
      }
    }

    // https://neutrium.net/equipment/conversion-between-head-and-pressure/
    return {
      pressureLossKPA: sign * pdKPA,
    };
  }
  preCalculationValidation(context: CoreContext) {
    return null;
  }
  getCalculationEntities(context: CoreContext): [BigValveEntity] {
    const e: BigValveEntity = cloneSimple(this.entity);
    e.parentUid = getIdentityCalculationEntityUid(context, e.parentUid);
    e.uid = this.getCalculationUid(context);

    e.hotRoughInUid = this.globalStore
      .getObjectOfTypeOrThrow(EntityType.SYSTEM_NODE, e.hotRoughInUid)
      .getCalculationNode(context, this.uid).uid;
    e.coldRoughInUid = this.globalStore
      .getObjectOfTypeOrThrow(EntityType.SYSTEM_NODE, e.coldRoughInUid)
      .getCalculationNode(context, this.uid).uid;

    switch (e.valve.type) {
      case BigValveType.TMV:
        e.valve.warmOutputUid = this.globalStore
          .getObjectOfTypeOrThrow(EntityType.SYSTEM_NODE, e.valve.warmOutputUid)
          .getCalculationNode(context, this.uid).uid;
        e.valve.coldOutputUid = this.globalStore
          .getObjectOfTypeOrThrow(EntityType.SYSTEM_NODE, e.valve.coldOutputUid)
          .getCalculationNode(context, this.uid).uid;
        break;
      case BigValveType.TEMPERING:
        e.valve.warmOutputUid = this.globalStore
          .getObjectOfTypeOrThrow(EntityType.SYSTEM_NODE, e.valve.warmOutputUid)
          .getCalculationNode(context, this.uid).uid;
        break;
      case BigValveType.RPZD_HOT_COLD:
        e.valve.hotOutputUid = this.globalStore
          .getObjectOfTypeOrThrow(EntityType.SYSTEM_NODE, e.valve.hotOutputUid)
          .getCalculationNode(context, this.uid).uid;
        e.valve.coldOutputUid = this.globalStore
          .getObjectOfTypeOrThrow(EntityType.SYSTEM_NODE, e.valve.coldOutputUid)
          .getCalculationNode(context, this.uid).uid;
        break;
      default:
        assertUnreachable(e.valve);
    }

    return [e];
  }

  collectCalculations(context: CoreContext): BigValveCalculation {
    return context.globalStore.getOrCreateCalculation(
      this.getCalculationEntities(context)[0],
    );
  }

  collectLiveCalculations(context: CoreContext): BigValveLiveCalculation {
    return context.globalStore.getOrCreateLiveCalculation(
      this.getCalculationEntities(context)[0],
    );
  }

  costBreakdown(context: CoreContext): CostBreakdown | null {
    let pipeSize = largestPipeSizeNominalMM(context, this.entity) || undefined;
    switch (this.entity.valve.type) {
      case BigValveType.TMV:
        return {
          cost: context.priceTable.Equipment.TMV,
          breakdown: [
            {
              type: "valve",
              qty: 1,
              path: `Equipment.TMV`,
              pipeSize: pipeSize,
              manufacturer:
                context.drawing.metadata.catalog.mixingValves.find(
                  (i) => i.uid === "tmv",
                )?.manufacturer || "generic",
            },
          ],
        };
      case BigValveType.TEMPERING:
        return {
          cost: context.priceTable.Equipment["Tempering Valve"],
          breakdown: [
            {
              type: "valve",
              qty: 1,
              path: `Equipment.Tempering Valve`,
              pipeSize: pipeSize,
              manufacturer:
                context.drawing.metadata.catalog.mixingValves.find(
                  (i) => i.uid === "temperingValve",
                )?.manufacturer || "generic",
            },
          ],
        };
      case BigValveType.RPZD_HOT_COLD:
        const calc = context.globalStore.getOrCreateCalculation(this.entity);
        if (!calc.rpzdSizeMM) {
          return null;
        }
        const hotSize = calc.rpzdSizeMM[StandardFlowSystemUids.HotWater];
        const coldSize = calc.rpzdSizeMM[StandardFlowSystemUids.ColdWater];

        const realHotSize = lowerBoundNumberTable(calc.rpzdSizeMM, hotSize);
        const realColdSize = lowerBoundNumberTable(calc.rpzdSizeMM, coldSize);

        if (realHotSize !== null && realColdSize !== null) {
          return {
            cost:
              context.priceTable.Equipment.RPZD[realHotSize!] +
              context.priceTable.Equipment.RPZD[realColdSize!],
            breakdown: [
              {
                type: "valve",
                qty: 1,
                path: `Equipment.RPZD.${realColdSize}`,
                pipeSize: pipeSize,
                manufacturer: getRpzdManufacturer(context),
              },
              {
                type: "valve",
                qty: 1,
                path: `Equipment.RPZD.${realHotSize}`,
                pipeSize: pipeSize,
                manufacturer: getRpzdManufacturer(context),
              },
            ],
          };
        } else {
          return { cost: 0, breakdown: [] };
        }

      default:
        assertUnreachable(this.entity.valve);
    }
    return null;
  }

  getCoreNeighbours(): CoreBaseBackedObject[] {
    const res: CoreBaseBackedObject[] = [];
    for (const sn of this.getInletsOutlets()) {
      if (sn) {
        res.push(...sn.getCoreNeighbours());
      }
    }
    return res;
  }

  getInletsOutlets(): CoreSystemNode[] {
    const result: string[] = [
      this.entity.coldRoughInUid,
      this.entity.hotRoughInUid,
    ];
    return result
      .map((uid) =>
        this.globalStore.getObjectOfTypeOrThrow(EntityType.SYSTEM_NODE, uid),
      )
      .concat(this.getOutlets());
  }

  getOutlets(): CoreSystemNode[] {
    const result: string[] = [];
    switch (this.entity.valve.type) {
      case BigValveType.TMV:
        result.push(
          this.entity.valve.warmOutputUid,
          this.entity.valve.coldOutputUid,
        );
        break;
      case BigValveType.TEMPERING:
        result.push(this.entity.valve.warmOutputUid);
        break;
      case BigValveType.RPZD_HOT_COLD:
        result.push(
          this.entity.valve.hotOutputUid,
          this.entity.valve.coldOutputUid,
        );
        break;
      default:
        assertUnreachable(this.entity.valve);
    }
    return result.map((uid) => this.globalStore.get(uid) as CoreSystemNode);
  }

  getOutPipes(): CoreConduit[] {
    const res: CoreConduit[] = [];
    for (const sn of this.getOutlets()) {
      if (sn) {
        res.push(...sn.getConnectedSidePipe(""));
      }
    }
    return res;
  }

  get shape(): Flatten.Polygon {
    switch (this.entity.valve.type) {
      case BigValveType.TMV:
      case BigValveType.TEMPERING:
        const p = new Flatten.Polygon();
        let l = -this.entity.pipeDistanceMM;
        let r = this.entity.pipeDistanceMM;
        let t = 0;
        let b = (this.entity.pipeDistanceMM * 200) / 150;

        const tl = this.toWorldCoord({ x: l, y: t });
        const tr = this.toWorldCoord({ x: r, y: t });
        const bl = this.toWorldCoord({ x: l, y: b });
        const br = this.toWorldCoord({ x: r, y: b });
        const tlp = Flatten.point(tl.x, tl.y);
        const trp = Flatten.point(tr.x, tr.y);
        const blp = Flatten.point(bl.x, bl.y);
        const brp = Flatten.point(br.x, br.y);

        p.addFace([
          Flatten.segment(tlp, trp),
          Flatten.segment(trp, brp),
          Flatten.segment(brp, blp),
          Flatten.segment(blp, tlp),
        ]);

        return p;
      case BigValveType.RPZD_HOT_COLD:
        const valveHeightMM = getRpzdBigValveHeightMM(this.entity);

        const p1 = new Flatten.Polygon();

        let l1 = -valveHeightMM * 1.5;
        let r1 = valveHeightMM * 1.5;
        let t1 = -valveHeightMM * 2.5;
        let b1 = valveHeightMM * 2.5;

        const tl1 = this.toWorldCoord({ x: l1, y: t1 });
        const tr1 = this.toWorldCoord({ x: r1, y: t1 });
        const bl1 = this.toWorldCoord({ x: l1, y: b1 });
        const br1 = this.toWorldCoord({ x: r1, y: b1 });
        const tlp1 = Flatten.point(tl1.x, tl1.y);
        const trp1 = Flatten.point(tr1.x, tr1.y);
        const blp1 = Flatten.point(bl1.x, bl1.y);
        const brp1 = Flatten.point(br1.x, br1.y);

        p1.addFace([
          Flatten.segment(tlp1, trp1),
          Flatten.segment(trp1, brp1),
          Flatten.segment(brp1, blp1),
          Flatten.segment(blp1, tlp1),
        ]);

        return p1;
      default:
        assertUnreachable(this.entity.valve);
    }
    // @ts-ignore
    return super.shape;
  }
}
