import { max } from "lodash";
import { Color, getColorFromPalette } from "../../../../lib/color";
import {
  Units,
  UnitsContext,
  convertMeasurementSystem,
} from "../../../../lib/measurements";
import {
  assertType,
  assertUnreachable,
  betterObjectKeys,
} from "../../../../lib/utils";
import { CoreContext } from "../../../calculations/types";
import { UFH_DEFAULT_COLOR_PALETTE } from "../../../calculations/underfloor-heating/consts";
import { LoopShape } from "../../../calculations/underfloor-heating/types";
import { GenericUFH } from "../../../catalog/manufacturers/generic/generic-ufh";
import {
  FloorFinish,
  doesFloorFinishNeedExpansionFoam,
} from "../../../catalog/types";
import { PipePhysicalMaterial, isUnderfloor } from "../../../config";
import { LiveCalculationForEntity } from "../../calculations-objects/calculation-concrete";
import { UnderfloorHeatingLoopCalculation } from "../../calculations-objects/underfloor-heating-loop-calculation";
import { UnderfloorHeatingFlowSystem } from "../../flow-systems";
import { getFlowSystem } from "../../utils";
import { HeatedAreaSegmentEntity } from "../area-segment-entity";
import { PlantType } from "../plants/plant-types";
import { FieldType, PropertyField } from "../property-field";
import {
  DEFAULT_EXTERIOR_PIPE_LENGTH_M,
  RoomEntity,
  RoomEntityConcrete,
  RoomFieldHandlers,
  RoomRoomEntity,
  UFHLoopDesignParameters,
  UfhRoomParameters,
  isRoomRoomEntity,
  loopShapeHasChiralityProperty,
  loopShapeHasDirectionProperty,
} from "../rooms/room-entity";
import {
  getCoilManufacturersForMaterial,
  getDefaultUnderFloorPipeDiameter,
  getUnderFloorLoopSpacing,
  getUnderFloorPipeDiameters,
  getUnderFloorPipeMaterialsCIBSE,
  getUnderfloorHeatingCoils,
  getUnderfloorHeatingEdgeExpansionFoams,
  getUnderfloorHeatingFloorFinish,
} from "../rooms/utils";
import { EntityType } from "../types";

export function makeUFHFields(
  mode: "approximate" | "full" | null,
  levelUid: string | null,
  flowSystem: UnderfloorHeatingFlowSystem | null,
  context: CoreContext,
  params: {
    floorFinish: FloorFinish | null;
    pipeMaterial: PipePhysicalMaterial | null;
    loopShape: LoopShape;
    coils: number;
  },
  prefix: string,
  forContext: "room" | "heated-area",
  entity: RoomRoomEntity | HeatedAreaSegmentEntity,
  handlers?: RoomFieldHandlers,
) {
  if (prefix && !prefix.endsWith(".")) {
    prefix += ".";
  }
  const { loopShape, coils } = params;
  let { floorFinish, pipeMaterial } = params;
  const result: PropertyField[] = [];
  const floorFinishes = getUnderfloorHeatingFloorFinish(context.catalog);
  if (!floorFinish) {
    floorFinish = flowSystem?.floorFinish ?? floorFinishes[0];
  }

  const needsExpansionFoam =
    entity.type === EntityType.ROOM &&
    doesFloorFinishNeedExpansionFoam(floorFinish);

  const expansionFoams = getUnderfloorHeatingEdgeExpansionFoams(
    context.catalog,
  );
  const expansionFoamManufacturers =
    context.catalog.underfloorHeating.edgeExpansionFoam.manufacturer;

  const pipeMaterials = getUnderFloorPipeMaterialsCIBSE(
    context.catalog,
    floorFinish,
  );

  if (!pipeMaterial) {
    pipeMaterial = flowSystem?.pipeMaterial ?? pipeMaterials[0];
  }

  const coilManufacturers = getCoilManufacturersForMaterial(
    context.catalog,
    pipeMaterial,
  );

  assertType<PipePhysicalMaterial>(pipeMaterial);
  const pipeDiameters = getUnderFloorPipeDiameters(
    context.catalog,
    floorFinish,
    pipeMaterial,
  );

  const loopSpacing = getUnderFloorLoopSpacing(context.catalog);

  // when mode is null and not connected to a manifold, show full.
  // An alternative would be to show nothing, we can flip to that later
  // if it's more appropriate.
  mode = mode ?? "full";

  if (mode === "full") {
    result.push({
      type: FieldType.Choice,
      property: `${prefix}loopShape`,
      title: `Loop Shape`,
      hasDefault: true,
      isCalculated: false,
      multiFieldId: "room-ufh-loopShape",
      params: {
        choices: [
          {
            name: "Linear (beta)",
            key: "linear",
          },
          {
            name: "Spiral",
            key: "spiral",
          },
          {
            name: "Serpentine",
            key: "serpentine",
          },
        ],
      },
    });

    if (loopShapeHasDirectionProperty(loopShape)) {
      result.push({
        type: FieldType.Rotation,
        property: `${prefix}loopDirectionDEG`,
        title: `Loop Direction`,
        hasDefault: true,
        isCalculated: true,
        multiFieldId: "room-ufh-loopDirectionDEG",
        params: {
          step: 90,
          disableFreeInput: false,
        },
      });
    }

    if (loopShapeHasChiralityProperty(loopShape)) {
      result.push({
        type: FieldType.Choice,
        property: `${prefix}chirality`,
        title: `Spiral Chirality`,
        hasDefault: true,
        isCalculated: true,
        multiFieldId: "room-ufh-chirality",
        params: {
          choices: [
            {
              name: "Clockwise",
              key: "clockwise",
            },
            {
              name: "Counterclockwise",
              key: "counterclockwise",
            },
          ],
        },
      });
    }
  }

  if (mode === "approximate") {
    result.push({
      type: FieldType.Button,
      property: "addUnheatedArea",
      title: "+ Add Unheated Area",
      hasDefault: false,
      isCalculated: false,
      multiFieldId: null,
      size: "sm",
      pill: true,
      variant: "outline-success",
      params: {
        handler: async () => handlers?.addUnheatedArea?.(context),
      },
    });
  }

  result.push({
    property: `${prefix}manifoldUid`,
    title: "Manifold",
    hasDefault: false,
    isCalculated: false,
    type: FieldType.EntityPicker,
    params: {
      type: "single",
      entityTypes: [EntityType.PLANT],
      levelUid,
      emptyListPlaceholder: "Empty - Add in the 'Design' mode",
      filter: (entity) => {
        return (
          entity.type === EntityType.PLANT &&
          entity.plant.type === PlantType.MANIFOLD
        );
      },
      getColor: (entity) => {
        if (
          entity.type === EntityType.PLANT &&
          entity.plant.type === PlantType.MANIFOLD
        ) {
          return entity.plant.color.hex;
        } else {
          return null;
        }
      },
      beforeSet: (value) => {
        // remove the frozen loops stats if user disconnected room from manifold
        if (!value) {
          const obj = context.globalStore.get(entity.uid);
          if (isRoomRoomEntity(obj.entity)) {
            obj.entity.room.underfloorHeating.frozenLoopsStats = [];
          }
        }
      },
    },
    multiFieldId: "room-underfloorHeating-manifoldUid",
  });

  if (forContext === "room") {
    result.push(
      {
        property: `${prefix}floorFinish`,
        title: "Floor System",
        hasDefault: !!flowSystem?.floorFinish,
        isCalculated: false,
        type: FieldType.Choice,
        params: {
          choices: floorFinishes.map((m) => ({
            name:
              getDefaultUnderFloorPipeDiameter(
                context.catalog,
                m,
                pipeMaterial,
              ) +
              "∅ - " +
              m,
            key: m,
          })),
        },
        beforeSet: (_value: string) => {
          switch (entity.type) {
            case EntityType.ROOM:
              entity.room.underfloorHeating.pipeMaterial = null;
              entity.room.underfloorHeating.pipeDiameterMM = null;
              entity.room.underfloorHeating.edgeExpansionFoamManufacturer =
                null;
              entity.room.underfloorHeating.edgeExpansionFoamModel = null;
              break;
            case EntityType.AREA_SEGMENT:
              entity.underfloorHeating.pipeMaterial = null;
              entity.underfloorHeating.pipeDiameterMM = null;
              entity.underfloorHeating.edgeExpansionFoamManufacturer = null;
              entity.underfloorHeating.edgeExpansionFoamModel = null;
              break;
            default:
              assertUnreachable(entity);
          }
        },
        multiFieldId: "room-underfloorHeating-floorFinish",
      },
      {
        property: `${prefix}edgeExpansionFoamManufacturer`,
        title: "Edge Expansion Foam Manufacturer",
        hasDefault: true,
        isCalculated: false,
        type: FieldType.Choice,
        hideFromPropertyWindow: !needsExpansionFoam,
        params: {
          choices: expansionFoamManufacturers.map((manufacturer) => ({
            name: manufacturer.name,
            key: manufacturer.uid,
          })),
        },
        beforeSet: () => {
          switch (entity.type) {
            case EntityType.ROOM:
              entity.room.underfloorHeating.edgeExpansionFoamModel = null;
              entity.room.underfloorHeating.coilManufacturer = null;
              entity.room.underfloorHeating.pipeDiameterMM = null;
              entity.room.underfloorHeating.pipeMaterial = null;
              entity.room.underfloorHeating.loopSpacingMM = null;
              return;
            default:
              entity.underfloorHeating.coilManufacturer = null;
              entity.underfloorHeating.pipeDiameterMM = null;
              entity.underfloorHeating.pipeMaterial = null;
              entity.underfloorHeating.loopSpacingMM = null;
              return;
          }
        },
        multiFieldId: "room-underfloorHeating-edgeExpansionFoamManufacturer",
      },
      {
        property: `${prefix}edgeExpansionFoamModel`,
        title: "Edge Expansion Foam Model",
        hasDefault: true,
        isCalculated: false,
        type: FieldType.Choice,
        hideFromPropertyWindow:
          !needsExpansionFoam ||
          (entity as RoomRoomEntity).room.underfloorHeating
            .edgeExpansionFoamManufacturer === null ||
          (entity as RoomRoomEntity).room.underfloorHeating
            .edgeExpansionFoamManufacturer === "generic",
        params: {
          choices: expansionFoams
            .filter(({ manufacturer }) => {
              const edgeExpansionFoamManufacturer = (entity as RoomRoomEntity)
                .room.underfloorHeating.edgeExpansionFoamManufacturer;
              return manufacturer.uid === edgeExpansionFoamManufacturer;
            })
            .map(({ model }) => ({
              name: model.description,
              key: model.model,
            })),
        },
        multiFieldId: "room-underfloorHeating-edgeExpansionFoamModel",
      },
    );

    if (mode === "approximate") {
      result.push({
        property: `${prefix}exteriorLoopLengthM`,
        title: "Total Transit Pipe Length",
        hasDefault: true,
        isCalculated: false,
        type: FieldType.Number,
        units: Units.Meters,
        params: { min: 0, max: null },
        hint: "Combined length of the pipe running from the manifold to the room and back.",
        multiFieldId: "room-underfloorHeating-exteriorLoopLengthM",
      });
    }
  }
  result.push(
    {
      property: `${prefix}pipeMaterial`,
      title: "Pipe Material",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Choice,
      params: {
        choices: pipeMaterials.map((m) => {
          const pipe = context.catalog.pipes[m];
          return {
            name: pipe?.name ?? m,
            key: m,
          };
        }),
      },
      beforeSet: (_value: string) => {
        switch (entity.type) {
          case EntityType.ROOM:
            entity.room.underfloorHeating.coilManufacturer = null;
            entity.room.underfloorHeating.loopSpacingMM = null;
            break;
          case EntityType.AREA_SEGMENT:
            entity.underfloorHeating.coilManufacturer = null;
            entity.underfloorHeating.loopSpacingMM = null;
            break;
          default:
            assertUnreachable(entity);
        }
      },
      multiFieldId: "room-underfloorHeating-pipeMaterial",
    },
    {
      property: `${prefix}coilManufacturer`,
      title: "Pipe Manufacturer",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Choice,
      params: {
        choices: coilManufacturers.map((manufacturer) => ({
          name: manufacturer.name,
          key: manufacturer.uid,
        })),
      },
      multiFieldId: "room-underfloorHeating-coilManufacturer",
    },
    {
      property: `${prefix}pipeDiameterMM`,
      title: "Pipe Diameter",
      hasDefault: !!flowSystem?.pipeDiameterMM,
      isCalculated: false,
      type: FieldType.Choice,
      units: Units.Millimeters,
      hideFromPropertyWindow: true,
      params: {
        choices: pipeDiameters.map((f) => {
          const [u, v] = convertMeasurementSystem(
            context.drawing.metadata.units,
            Units.Millimeters,
            f,
          );

          return {
            name: `${v} ${u}`,
            key: f,
          };
        }),
      },
      beforeSet: () => {
        switch (entity.type) {
          case EntityType.ROOM:
            entity.room.underfloorHeating.loopSpacingMM = null;
            return;
          default:
            entity.underfloorHeating.loopSpacingMM = null;
            return;
        }
      },
      multiFieldId: "room-underfloorHeating-pipeDiameterMM",
    },
  );

  if (forContext === "room") {
    result.push({
      property: `${prefix}loopSpacingMM`,
      title: "Loop Spacing",
      hasDefault: false,
      isCalculated: true,
      type: FieldType.Choice,
      units: Units.Millimeters,
      params: {
        choices: loopSpacing.map((m) => ({
          name: String(m),
          key: m,
        })),
      },
      multiFieldId: "room-underfloorHeating-loopSpacingMM",
    });
  } else {
    result.push({
      property: `${prefix}loopSpacingMM`,
      title: "Loop Spacing",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Choice,
      units: Units.Millimeters,
      params: {
        choices: loopSpacing.map((m) => ({
          name: String(m),
          key: m,
        })),
      },
      multiFieldId: "room-underfloorHeating-loopSpacingMM",
      readonly: true,
      hint: "Loop spacing can be set on the whole room, but not on heated areas individually.",
    });
  }

  if (mode === "full") {
    if (coils === 1) {
      result.push({
        property: `${prefix}coilColors.0`,
        title: "Underfloor Heating Coil Color",
        hasDefault: false,
        isCalculated: true,
        type: FieldType.Color,
        params: {
          palette:
            flowSystem?.palette ?? UFH_DEFAULT_COLOR_PALETTE.splice(0, 4),
        },
        multiFieldId: "room-underfloorHeating-coilColours",
      });
    } else {
      for (let i = 0; i < coils; i++) {
        result.push({
          property: `${prefix}coilColors.${i}`,
          title: `Coil Color (${String.fromCharCode(97 + i)})`,
          hasDefault: false,
          isCalculated: true,
          type: FieldType.Color,
          params: {
            palette:
              flowSystem?.palette ?? UFH_DEFAULT_COLOR_PALETTE.splice(0, 4),
          },
          multiFieldId: `room-underfloorHeating-coilColors.${i}`,
        });
      }
    }
    result.push({
      property: `${prefix}minBendRadiusMM`,
      title: "Minimum Bend Radius",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Number,
      units: Units.Millimeters,
      params: { min: 0, max: null },
      hint: "Defaults to pipe diameter * 5",
      multiFieldId: "room-underfloorHeating-minBendRadiusMM",
    });
    const largestRollM = Math.max(...(flowSystem?.rollLengthsM ?? []));
    result.push({
      property: `${prefix}maxLoopLengthM`,
      title: "Maximum Loop Length",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Number,
      units: Units.Meters,
      params: { min: 0, max: isFinite(largestRollM) ? largestRollM : null },
      multiFieldId: "room-underfloorHeating-minBendRadiusMM",
    });

    result.push({
      property: `${prefix}hasActuator`,
      title: "Has Actuator",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Boolean,
      multiFieldId: "room-underfloorHeating-hasActuator",
      params: null,
    });
  }

  if (forContext === "room") {
    result.push({
      property: `${prefix}heatOutputW`,
      title: "Heat Output",
      hasDefault: false,
      isCalculated: true,
      type: FieldType.Number,
      unitContext: UnitsContext.HEAT_LOAD_ENERGY_MEASUREMENT,
      units: Units.Watts,
      params: { min: 0, max: null },
      multiFieldId: "room-underfloorHeating-heatOutputW",
    });
  }

  return result;
}

// TODO: Fix cycle hack
export function getUFHManifold(
  context: CoreContext,
  manifoldUid: string | null | undefined,
): any | null {
  if (!manifoldUid) {
    return null;
  }

  const manifold = context.globalStore.getObjectOfType(
    EntityType.PLANT,
    manifoldUid,
  );

  if (!manifold) {
    return null;
  }

  if (
    manifold.entity.type === EntityType.PLANT &&
    manifold.entity.plant.type === PlantType.MANIFOLD
  ) {
    return manifold.entity;
  }

  return null;
}

export function getUFHLoopDesignFlowSystem(
  context: CoreContext,
  manifoldUid: string | null | undefined,
): UnderfloorHeatingFlowSystem | null {
  const manifold = getUFHManifold(context, manifoldUid);
  if (!manifold) {
    return null;
  }

  const flowSystem = getFlowSystem(
    context.drawing,
    manifold.plant.ufhSystemUid,
  );

  if (isUnderfloor(flowSystem)) {
    return flowSystem;
  }
  return null;
}

/**
 * Order is as follows:
 * 1. User entered on the entity
 * 2. Live Calcs
 * 3. Flow System (Must have a manifold selected and UFHv3 turned on)
 * 4. Legacy UFHv2
 */
export function getFullUFHRoomDefaults(
  context: CoreContext,
  liveCalcs: LiveCalculationForEntity<RoomEntity>,
  room: RoomEntityConcrete,
): UfhRoomParameters {
  const existing = room.underfloorHeating;
  return {
    exteriorLoopLengthM:
      existing.exteriorLoopLengthM ?? DEFAULT_EXTERIOR_PIPE_LENGTH_M,
    heatOutputW:
      existing.heatOutputW ?? liveCalcs.underfloorHeating.heatOutputW,
    freezeLayout: room.underfloorHeating.freezeLayout,
    frozenLoopsStats: room.underfloorHeating.frozenLoopsStats,
    ...getUFHLoopDesignParameterDefaults(
      context,
      existing,
      liveCalcs.underfloorHeating.loopsStats.filter((x) => x.areaUid === null),
      undefined,
      liveCalcs.underfloorHeating.loopDirectionDEG,
      liveCalcs.underfloorHeating.loopSpacingMM,
    ),
  };
}

/**
 * Order is as follows:
 * 1. User entered on the entity
 * 2. Live Calcs (Rooms Only)
 * 3. Parent Room (Heated areas only)
 * 4. Flow System (Must have a manifold selected and UFHv3 turned on)
 * 5. Legacy UFHv2
 */
export function getUFHLoopDesignParameterDefaults(
  context: CoreContext,
  existing: UFHLoopDesignParameters,
  loopStats: UnderfloorHeatingLoopCalculation[],
  parent?: UfhRoomParameters,
  calculatedLoopDirectionDEG?: number | null,
  calculatedLoopSpacingMM?: number | null,
): UFHLoopDesignParameters {
  const flowSystemUfhSettings = getUFHLoopDesignFlowSystem(
    context,
    existing.manifoldUid,
  );

  const pipeMaterial =
    existing.pipeMaterial ??
    parent?.pipeMaterial ??
    flowSystemUfhSettings?.pipeMaterial ??
    "pexSdr74";

  const defaultCoilManufacturer =
    context.drawing.metadata.catalog.underfloorHeatingCoils.find(
      (x) => x.uid === pipeMaterial,
    )?.manufacturer ?? "generic";

  const loopSpacing = getUnderFloorLoopSpacing(context.catalog);
  const palette = flowSystemUfhSettings?.palette ?? UFH_DEFAULT_COLOR_PALETTE;
  const coilColors: Color[] = loopStats.map((x) => ({
    hex: x.color ?? getColorFromPalette(x.manifoldIndex ?? 0, palette),
  }));

  const expansionFoams = getUnderfloorHeatingEdgeExpansionFoams(
    context.catalog,
  );

  const coils = getUnderfloorHeatingCoils(context.catalog).filter(
    (x) =>
      x.model.pipeMaterial === pipeMaterial || x.model.pipeMaterial === null,
  );

  const maxLoopLength = max(coils.map((x) => x.model.rollLengthM));

  const floorFinish =
    existing.floorFinish ??
    parent?.floorFinish ??
    flowSystemUfhSettings?.floorFinish ??
    betterObjectKeys(GenericUFH)[0];

  const pipeDiameters = getUnderFloorPipeDiameters(
    context.catalog,
    floorFinish,
    pipeMaterial,
  );
  const pipeDiameterMM =
    existing.pipeDiameterMM ?? parent?.pipeDiameterMM ?? pipeDiameters[0];

  const defaultExpansionFoamManufacturer =
    context.drawing.metadata.catalog.underfloorHeating.find(
      (x) => x.uid === "edgeExpansionFoam",
    )?.manufacturer ?? "generic";

  const edgeExpansionFoamManufacturer =
    existing.edgeExpansionFoamManufacturer ??
    parent?.edgeExpansionFoamManufacturer ??
    defaultExpansionFoamManufacturer;
  const defaultExpansionFoamModel = expansionFoams.find(
    (x) => x.manufacturer.uid === edgeExpansionFoamManufacturer,
  )?.model.model;

  const needsExpansionFoam = doesFloorFinishNeedExpansionFoam(floorFinish);

  let edgeExpansionFoamModel =
    existing.edgeExpansionFoamModel ??
    parent?.edgeExpansionFoamModel ??
    defaultExpansionFoamModel ??
    "generic";
  return {
    loopShape:
      existing.loopShape ??
      parent?.loopShape ??
      flowSystemUfhSettings?.loopShape ??
      "serpentine",
    loopDirectionDEG:
      existing.loopDirectionDEG ??
      calculatedLoopDirectionDEG ??
      parent?.loopDirectionDEG ??
      flowSystemUfhSettings?.loopDirectionDEG ??
      0,
    minBendRadiusMM:
      existing.minBendRadiusMM ??
      parent?.minBendRadiusMM ??
      flowSystemUfhSettings?.minBendRadiusMM ??
      pipeDiameterMM * 5,
    chirality:
      existing.chirality ??
      parent?.chirality ??
      flowSystemUfhSettings?.spiralChirality ??
      "clockwise",
    floorFinish,
    pipeDiameterMM,
    pipeMaterial,
    maxLoopLengthM:
      existing.maxLoopLengthM ??
      maxLoopLength ??
      parent?.maxLoopLengthM ??
      flowSystemUfhSettings?.maxLoopLengthM ??
      200,
    loopSpacingMM:
      existing.loopSpacingMM ??
      calculatedLoopSpacingMM ??
      parent?.loopSpacingMM ??
      loopSpacing[0],
    manifoldUid: existing.manifoldUid,
    coilColors: coilColors.map((x, idx) =>
      existing.coilColors ? (existing.coilColors[idx] ?? x) : x,
    ),
    hasActuator: existing.hasActuator ?? parent?.hasActuator ?? true,
    edgeExpansionFoamManufacturer: needsExpansionFoam
      ? edgeExpansionFoamManufacturer
      : null,
    edgeExpansionFoamModel: needsExpansionFoam ? edgeExpansionFoamModel : null,
    coilManufacturer:
      existing.coilManufacturer ??
      parent?.coilManufacturer ??
      defaultCoilManufacturer,
    genericCoilRollLengthsM: flowSystemUfhSettings?.rollLengthsM ?? [],
  };
}
