import { EPS } from "../../lib/utils";
import { EntityType } from "../document/entities/types";
import { getObjectFrictionPressureLossKPA } from "./entity-pressure-drops";
import { FlowAssignment } from "./flow-assignment";
import { GlobalFDR } from "./global-fdr";
import Graph, { Edge } from "./graph";
import { ternarySearchForGlobalMin } from "./search-functions";
import {
  CoreContext,
  EdgeType,
  FlowEdge,
  FlowNode,
  PressurePushMode,
} from "./types";
import { FLOW_SOURCE_EDGE } from "./utils";

export const MINIMUM_FLOW_RATE_CHANGE = 0.00001;

export interface FlowSolverParams {
  scoreEscape?: number;
  adjustmentEscape?: number;
}

type AdjustmentFunction = (
  flows: FlowAssignment,
  path: Array<Edge<FlowNode, FlowEdge>>,
  network: Graph<FlowNode, FlowEdge>,
  expectedDifferenceHead: number,
  context: CoreContext,
  iters: number,
) => { adj: number; score: number };

export default class FlowSolver {
  network: Graph<FlowNode, FlowEdge>;
  context: CoreContext;
  ga: number;

  constructor(network: Graph<FlowNode, FlowEdge>, context: CoreContext) {
    this.network = network;
    this.context = context;
    this.ga =
      context.drawing.metadata.calculationParams.gravitationalAcceleration;
  }

  solveFlowLS(
    demandsLS: Map<string, number>,
    suppliesKPA: Map<string, number>,
    options?: FlowSolverParams,
  ): FlowAssignment {
    return this.internalSolveFlowsLS(
      demandsLS,
      suppliesKPA,
      adjustPathHardyCross,
      options,
    );
  }

  // solves flows correctly for direction quickly - a good approximation
  // to set direction of pipes for generic returns.
  solveFlowDirectionOnly(
    demandsLS: Map<string, number>,
    suppliesKPA: Map<string, number>,
    options?: FlowSolverParams,
  ): FlowAssignment {
    return this.internalSolveFlowsLS(
      demandsLS,
      suppliesKPA,
      adjustPathDirectionOnly,
      options,
    );
  }

  // Given a flow network of pipes, joints with given diameters, and the flow sources and sinks,
  // output the real flow through each pipe. The real physical flow is the only flow that meets the
  // following conditions:
  // 1. All flows in and out of each fitting sum to zero
  // 2. Head loss (/pressure drop) in each loop sum to zero

  private internalSolveFlowsLS(
    demandsLS: Map<string, number>,
    suppliesKPA: Map<string, number>,
    adjustPathFn: AdjustmentFunction,
    options?: FlowSolverParams,
  ): FlowAssignment {
    const MAXIMUM_SCORE_CHANGE = options?.scoreEscape;
    const MAXIMUM_FLOW_RATE_CHANGE = options?.adjustmentEscape ?? 0.0001;
    console.time("solveFlowLS");
    const createRandomCover = () => {
      const cycles = this.network.edgeCycleCover({
        directed: false,
        random: true,
        shortest: Math.random() < 0.5,
      });

      // uids of pipes that have their flow in a calculation.
      const accountedFor = new Set<string>();
      for (const cycle of cycles) {
        for (const e of cycle) {
          accountedFor.add(e.uid);
        }
      }

      const sources = Array.from(suppliesKPA.keys()).map((suid) => ({
        connectable: suid,
        connection: FLOW_SOURCE_EDGE,
      }));

      const arcCover = this.network.sourceArcCover(sources, accountedFor, true);
      for (const arc of arcCover) {
        for (const e of arc) {
          accountedFor.add(e.uid);
        }
      }

      return {
        cycles,
        arcCover,
      };
    };

    let { cycles, arcCover } = createRandomCover();

    // notAccountedFor should only contain unambiguous branches in the graph. Once the flow is established for them,
    // they don't need to be changed since it's the only flow possible.
    // now that we have the loops covering all ambiguous sources, it's time to provide flow.

    // give any valid flow initially.
    const flowRates = this.getInitialFlowRates(demandsLS, suppliesKPA);

    // return flowRates;low-s
    let iters = 1;
    let lastIter = false;
    while (true) {
      iters++;
      if (iters > 1500) {
        console.error("Flow solver exceeded 1500 iterations");
        break;
      }
      if (iters % 3 === 0) {
        let { cycles: newCycles, arcCover: newArcCover } = createRandomCover();
        cycles = newCycles;
        arcCover = newArcCover;
      }
      console.log("New iteration =====================", iters);
      let adjustments = 0;
      let scores = 0;
      for (const c of cycles) {
        const { adj, score } = adjustPathFn(
          flowRates,
          c,
          this.network,
          0,
          this.context,
          iters,
          // @ts-ignore
          lastIter,
        );
        const thisAdj = Math.abs(adj);
        adjustments += thisAdj;
        scores += score;
        // console.log("cycle", thisAdj, adjustments);
      }
      for (const a of arcCover) {
        const fromKPA = suppliesKPA.get(a[0].from.connectable);
        const toKPA = suppliesKPA.get(a[a.length - 1].to.connectable);
        if (fromKPA === undefined || toKPA === undefined) {
          throw new Error("Endpoint of arc is not a source");
        }

        const diffKPA = toKPA - fromKPA;
        const { adj, score } = adjustPathFn(
          flowRates,
          a,
          this.network,
          diffKPA,
          this.context,
          iters,
        );
        const thisAdj = Math.abs(adj);
        adjustments += thisAdj;
        scores += score;

        // console.log("arc", thisAdj, adjustments);
      }

      if (lastIter) {
        break;
      }
      if (
        (!MAXIMUM_FLOW_RATE_CHANGE || adjustments < MAXIMUM_FLOW_RATE_CHANGE) &&
        (!MAXIMUM_SCORE_CHANGE || scores < MAXIMUM_SCORE_CHANGE)
      ) {
        lastIter = true;
      }
    }
    // console.log("Done iterating");
    return flowRates;
  }

  getInitialFlowRates(
    demandsLS: Map<string, number>,
    suppliesKPA: Map<string, number>,
  ): FlowAssignment {
    const result: FlowAssignment = new FlowAssignment();

    demandsLS.forEach((f, n) => {
      // find a source for this flow.
      const path = this.network.anyPath({
        from: {
          connectable: n,
          connection: this.context.globalStore.get(n)!.entity.parentUid!,
        },
        to: Array.from(suppliesKPA.keys()).map((k) => {
          const o = this.context.globalStore.get(k)!;
          if (o.entity.type === EntityType.FLOW_SOURCE) {
            return {
              connectable: k,
              connection: FLOW_SOURCE_EDGE,
            };
          } else if (o.entity.type === EntityType.SYSTEM_NODE) {
            return {
              connectable: k,
              connection: o.entity.parentUid!,
            };
          } else {
            throw new Error("Invalid entity type");
          }
        }),
        reversed: true,
      });
      if (path) {
        path.reverse().forEach((e) => {
          result.addFlow(e.uid, this.network.sn(e.to), f);
        });
      }
    });

    return result;
  }
}

// Tracks the pressure drop along the path, and augments the path so that the pressure difference is the same as
// the expected difference.
// Returns the delta speed, for iteration exit purposes.
export function adjustPathHardyCross(
  flows: FlowAssignment,
  path: Array<Edge<FlowNode, FlowEdge>>,
  network: Graph<FlowNode, FlowEdge>,
  expectedDifferenceKPA: number = 0,
  context: CoreContext,
  iterations: number = 100,
): { adj: number; score: number } {
  // Augment the path backwards
  // Use ternary search to find the smallest value of the sum of concave functions.
  let totalPressureLossKPA: number = 0;
  try {
    const { value: bestAdjustment, score: bestScore } =
      ternarySearchForGlobalMin({
        fn: (num) => {
          totalPressureLossKPA = 0;
          for (const v of path) {
            const connector = context.globalStore.getCalculatableOrThrow(
              v.value.uid,
            );

            const deltaKPA = getObjectFrictionPressureLossKPA({
              context,
              object: connector,
              flowLS: flows.getFlow(v.uid, network.sn(v.from)) + num,
              from: v.from,
              to: v.to,
              signed: true,
              pressurePushMode: PressurePushMode.PSD,
            }).pressureLossKPA;
            // TODO: magic to make this work with static pressure drops.
            if (deltaKPA === null) {
              // throw new Error("Could not get friction loss of pipe");
            }

            totalPressureLossKPA += deltaKPA || 0;
          }
          return Math.abs(-expectedDifferenceKPA - totalPressureLossKPA);
        },
        maxIterations: iterations + 3,
      });

    for (const v of path) {
      flows.addFlow(v.uid, network.sn(v.from), bestAdjustment);
    }

    return { adj: bestAdjustment, score: bestScore };
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.log(
      "error while adjusting path: " +
        JSON.stringify(
          path.map((v) => context.globalStore.get(v.value.uid)!.type),
        ) +
        " expected difference: " +
        expectedDifferenceKPA,
    );

    // tslint:disable-next-line:no-console
    console.log(
      "og flows: " +
        JSON.stringify(
          path.map((p) => flows.getFlow(p.uid, network.sn(p.from))),
        ),
    );
    // tslint:disable-next-line:no-console
    console.log("uids: " + JSON.stringify(path.map((p) => p.value.uid)));

    // tslint:disable-next-line:no-console
    console.log("last ones:");
    const o = context.globalStore.getObjectOfTypeOrThrow(
      EntityType.FITTING,
      path[0].value.uid,
    );
    for (let i = -0.25; i <= 0.25; i += 0.01) {
      // tslint:disable-next-line:no-console
      console.log(
        o.getFrictionPressureLossKPA({
          context,
          flowLS: i,
          from: path[1].from,
          to: path[1].to,
          signed: true,
          pressurePushMode: PressurePushMode.PSD,
        }),
      );
    }

    throw e;
  }
}

export function adjustPathDirectionOnly(
  flows: FlowAssignment,
  path: Array<Edge<FlowNode, FlowEdge>>,
  network: Graph<FlowNode, FlowEdge>,
  expectedDifferenceKPA: number,
  context: CoreContext,
  iters: number,
  lastIter: boolean = false,
): { adj: number; score: number } {
  let length;
  const pipeLengthCache: Record<string, number> = {};
  // Augment the path backwards
  // Use ternary search to find the smallest value of the sum of concave functions.
  let totalHeadLoss: number = 0;

  // Force the flow through the right direction.
  let high: number | undefined = undefined;
  let highElem: string | undefined = undefined;
  let low: number | undefined = undefined;
  let lowElem: string | undefined = undefined;

  for (const v of path) {
    if (v.isDirected) {
      const flow = flows.getFlow(v.uid, network.sn(v.from));
      if (v.isReversed) {
        if (high === undefined || high < -flow) {
          high = -flow;
          highElem = v.value.uid;
        }
      } else {
        if (low === undefined || low > -flow) {
          low = -flow;
          lowElem = v.value.uid;
        }
      }
    }
  }

  if (low !== undefined && high !== undefined && low >= high) {
    // cannot process this invalid loop
    if (low > high + EPS) {
      // This is an expected case. We need to wait for the rest
      // of the loop to converge before this local cycle makes sense.
      // GlobalFDR.focusData([
      //   highElem!,
      //   lowElem!,
      //   low.toString(),
      //   high.toString(),
      // ]);
      // GlobalFDR.pushLog({
      //   text: "Examining cycle",
      //   data: path.map((p) => p.value.uid),
      // });
      // throw new Error(
      //   "There are directed entities with conflicting direction in the return loop."
      // );
    }
    return { adj: 0, score: 1e12 };
  }

  const { value: bestAdjustment, score: bestScore } = ternarySearchForGlobalMin(
    {
      low,
      high,
      fn: (num, final) => {
        const lastIteration = final && lastIter;
        if (lastIteration) {
          // console.log("last iteration", num);
        }
        totalHeadLoss = 0;
        for (const v of path) {
          let componentHeadLoss = 0;
          const flowRateLS = flows.getFlow(v.uid, network.sn(v.from)) + num;
          if (v.value.type === EdgeType.CONDUIT) {
            // We just need a function shape that will converge, and converge quickly.
            pipeLengthCache[v.value.uid] = length =
              pipeLengthCache[v.value.uid] ||
              context.globalStore.getObjectOfTypeOrThrow(
                EntityType.CONDUIT,
                v.value.uid,
              ).computedLengthM;
            componentHeadLoss = length * flowRateLS * Math.abs(flowRateLS);
          } else {
            if (v.isDirected && num != 0) {
              if (!v.isReversed) {
                if (flowRateLS < 0) {
                  componentHeadLoss = -1e10 - Math.abs(flowRateLS) * 1e2;
                  if (lastIteration) {
                    console.error("Penalizing for wrong direction", {
                      flowRateLS,
                      v,
                      num,
                      componentHeadLoss,
                      totalHeadLoss,
                      path: path.map((p) => p.value.uid),
                    });
                    GlobalFDR.focusData(path.map((p) => p.value.uid));
                    // throw new Error("Wrong direction");
                  }
                }
              } else {
                if (flowRateLS > 0) {
                  componentHeadLoss = 1e10 + flowRateLS * 1e2;
                  if (lastIteration) {
                    console.error("Penalizing for wrong direction", {
                      flowRateLS,
                      v,
                      num,
                      componentHeadLoss,
                      totalHeadLoss,
                      path: path.map((p) => p.value.uid),
                    });
                    GlobalFDR.focusData(path.map((p) => p.value.uid));
                    // throw new Error("Wrong direction");
                  }
                }
              }
            }
          }
          totalHeadLoss += componentHeadLoss;
        }

        return Math.abs(-expectedDifferenceKPA - totalHeadLoss);
      },
      maxIterations: iters + 3,
    },
  );

  // console.log("Final score after ternary search", bestScore, bestAdjustment);

  for (const v of path) {
    flows.addFlow(v.uid, network.sn(v.from), bestAdjustment);
  }

  return { adj: bestAdjustment, score: bestScore };
}
