import Flatten from "@flatten-js/core";
import * as TM from "transformation-matrix";
import { Coord, Coord3D, coordDist } from "../../../lib/coord";
import { assertUnreachableAggressive, cloneSimple } from "../../../lib/utils";
import { CoreContext } from "../../calculations/types";
import { getWallByFen } from "../../calculations/utils";
import { VirtualEdgeEntityConcrete } from "../../document/entities/concrete-entity";
import { FenType } from "../../document/entities/fenestration-entity";
import { EntityType } from "../../document/entities/types";
import { fillDefaultWallFields } from "../../document/entities/wall-entity";
import { CoreCalculatableObject } from "../lib/CoreCalculatableObject";
import Cached from "../lib/cached";
import CoreBaseBackedObject from "../lib/coreBaseBackedObject";
import { GuessEntity } from "../lib/types";
import {
  externalSegmentDetermineDirectionCW,
  movePerpendicularByDistanceCW,
} from "../utils";

export interface ICoreVirtualEdge {
  worldEndpoints(excludeUid?: string | null): Coord3D[];
  shape: Flatten.Segment | Flatten.Point | Flatten.Polygon;
  getWorldSegments(): [Coord, Coord][];
}

export function CoreVirtualEdge<
  T extends abstract new (...args: any[]) => CoreCalculatableObject<I>,
  I extends VirtualEdgeEntityConcrete = GuessEntity<T>,
>(Base: T) {
  abstract class Generated extends Base implements ICoreVirtualEdge {
    getHash(): string {
      let keys = [...this.entity.polygonEdgeUid!].map((uid) =>
        this.globalStore.get(uid)?.getHash(),
      );
      keys.sort();
      const key = keys.join(" ");
      return key;
    }

    get computedLengthM(): number {
      const [wa, wb] = this.worldEndpoints();
      return (
        Math.sqrt(
          (wa.x - wb.x) ** 2 + (wa.y - wb.y) ** 2 + (wa.z - wb.z) ** 2,
        ) / 1000
      );
    }

    // Returns the world coordinates of the two endpoints
    @Cached(
      (kek) => {
        return new Set(
          [
            kek,
            ...kek
              .getCoreNeighbours()
              .map((o) => o?.getParentChain())
              .flat(),
          ].map((o) => o?.uid),
        );
      },
      (excludeUid) => excludeUid,
    )
    worldEndpoints(excludeUid: string | null = null): Coord3D[] {
      let res: Coord3D[] = [];
      this.entity.polygonEdgeUid?.forEach((uid) => {
        const edge = this.globalStore.getObjectOfTypeOrThrow(
          EntityType.EDGE,
          uid,
        );
        res.push(...edge.worldEndpoints());
      });
      let kx = res[0].x - res[1].x,
        ky = res[0].y - res[1].y;
      res.sort((a, b) => kx * (a.x - b.x) + ky * (a.y - b.y));
      if (res.length > 2) res = res.slice(1, 3);
      return res;
    }

    get position(): TM.Matrix {
      // We don't draw by object location because the object doesn't really have an own location. Instead, its
      // location is determined by other objects.
      return TM.identity();
    }

    preCalculationValidation(context: CoreContext) {
      return null;
    }

    getCoreNeighbours(): CoreBaseBackedObject[] {
      if (!this.entity.polygonEdgeUid) return [];
      return this.entity.polygonEdgeUid
        .map((uid) => [
          this.globalStore.get(uid),
          ...this.globalStore.get(uid).getCoreNeighbours().flat(),
        ])
        .flat();
    }

    getVisualDeps() {
      if (this.entity.polygonEdgeUid)
        return super
          .getVisualDeps()
          .concat(cloneSimple(this.entity.polygonEdgeUid));
      return super.getVisualDeps();
    }

    abstract get isManifested(): boolean;

    get shape(): Flatten.Segment | Flatten.Point | Flatten.Polygon {
      let obj = this.globalStore.getVirtualEdgeOrThrow(this.uid);
      switch (obj.type) {
        case EntityType.WALL: {
          if (!obj.isManifested) return Flatten.point([0, 0]);

          let coreWall = this.context.globalStore.getObjectOfTypeOrThrow(
            EntityType.WALL,
            obj.uid,
          );
          const segments = coreWall.getWorldSegments();

          let shape: Flatten.Polygon = new Flatten.Polygon();
          // if only points, return points
          // if any segments, return only segments
          const pointShapes: Flatten.Point[] = [];
          const segmentShapes: Flatten.Segment[] = [];

          for (const [a, b] of segments) {
            let filledWallEntity = fillDefaultWallFields(
              this.context,
              coreWall.entity,
            );
            let [aAdj, bAdj] = coreWall.normalizedCCWIfExternalWall([a, b]);
            let baseWidth = filledWallEntity.widthMM ?? 0;

            if (coordDist(a, b) < 0.1) {
              pointShapes.push(Flatten.point(a.x, a.y));
            } else {
              [aAdj, bAdj] = movePerpendicularByDistanceCW(
                aAdj,
                bAdj,
                baseWidth / 2,
              );
              if (
                isNaN(aAdj.x) ||
                isNaN(aAdj.y) ||
                isNaN(bAdj.x) ||
                isNaN(bAdj.y)
              ) {
                console.error("NaN in wall shape", a, b, aAdj, bAdj);
              }
              if (coordDist(aAdj, bAdj) < 0.1) {
                console.error("wall shape zero width", a, b, aAdj, bAdj);
              }
              segmentShapes.push(
                Flatten.segment(aAdj.x, aAdj.y, bAdj.x, bAdj.y),
              );
            }
          }

          if (segmentShapes.length > 0) {
            shape.addFace(segmentShapes);
          } else {
            shape.addFace(pointShapes);
          }
          const box = shape.box;
          if (
            isNaN(box.xmin) ||
            isNaN(box.ymin) ||
            isNaN(box.xmax) ||
            isNaN(box.ymax)
          ) {
            console.error("Wall bounding box is NaN", {
              uid: obj.uid,
              segments,
              shape,
              isSegmentClose: segments.some(([a, b]) => coordDist(a, b) < 0.1),
              segmentLength: segments.length,
            });
          }

          return shape;
          break;
        }
        case EntityType.FENESTRATION:
          let ls = obj.getWorldSegments()[0];

          ls = externalSegmentDetermineDirectionCW(
            this.context,
            ls,
            this.entity.polygonEdgeUid ?? [],
          );
          let fens = this.context.globalStore.getObjectOfTypeOrThrow(
            EntityType.FENESTRATION,
            this.entity.uid,
          );

          let wallUid = getWallByFen(this.context.globalStore, fens);
          if (wallUid === undefined) {
            return Flatten.segment(ls[0].x, ls[0].y, ls[1].x, ls[1].y);
          }

          let wall = this.context.globalStore.getObjectOfTypeOrThrow(
            EntityType.WALL,
            wallUid,
          );
          let filledWall = fillDefaultWallFields(this.context, wall.entity);
          let baseWidth = filledWall.widthMM ?? 200;
          switch (obj.entity.fenType) {
            case FenType.DOOR:
              throw new Error(
                "Should not have called 'shape' on coreVirtualEdge.ts for door fenestration type. This functionality is served by coreFenestration.ts",
              );
            case FenType.LOOP_ENTRY:
            case FenType.WINDOW: {
              ls = movePerpendicularByDistanceCW(ls[0], ls[1], baseWidth / 2);
              return Flatten.segment(ls[0].x, ls[0].y, ls[1].x, ls[1].y);
            }
            default:
              return assertUnreachableAggressive(obj.entity);
          }
      }
    }

    abstract getWorldSegments(): [Coord, Coord][];
  }

  return Generated;
}
