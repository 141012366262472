import Flatten from "@flatten-js/core";
import CoreBaseBackedObject from "../../../../../common/src/api/coreObjects/lib/coreBaseBackedObject";
import { DrawableEntityConcrete } from "../../../../../common/src/api/document/entities/concrete-entity";
import { EntityType } from "../../../../../common/src/api/document/entities/types";
import { Coord } from "../../../../../common/src/lib/coord";
import { EPS } from "../../../../../common/src/lib/utils";
import { MainEventBus } from "../../../store/main-event-bus";
import {
  DrawableObjectConcrete,
  PolygonObjectConcrete,
} from "../../objects/concrete-object";
import DrawableEdge from "../../objects/drawableEdge";
import DrawableVertex from "../../objects/drawableVertex";
import CanvasContext from "../canvas-context";
import { IDrawableObject } from "./core2drawable";

export interface IMagicDraggableObject {
  inBounds(objectCoord: Coord, objectRadius?: number): boolean;
  onDragStart(
    event: MouseEvent,
    objectCoord: Coord,
    context: CanvasContext,
    isMultiDrag: boolean,
  ): any;
  onDrag(
    event: MouseEvent,
    grabbedObjectCoord: Coord,
    eventObjectCoord: Coord,
    grabState: any,
    context: CanvasContext,
    isMultiDrag: boolean,
  ): void;
  onDragFinish(
    event: MouseEvent,
    context: CanvasContext,
    isMultiDrag: boolean,
  ): void;
}

export enum CollisionLayer {
  ROOM,
  UNHEATED_AREA,
  HEATED_AREA,
}

export function MagicDraggableObject<
  T extends abstract new (
    ...args: any[]
  ) => CoreBaseBackedObject<DrawableEntityConcrete> & IDrawableObject,
>(Base: T) {
  // @ts-ignore abstract class expression limitation in the language. In practice this is fine.
  abstract class Generated extends Base {
    slowSnapVertices: { vertex: Flatten.Point; uid: string }[] = [];
    slowSnapEdges: { edge: Flatten.Segment; uid: string }[] = [];
    thisPolygon: PolygonObjectConcrete | null = null;
    initialOverlap: number | null = null;
    dragVertices: string[] = [];
    //snapping vertex to the wall and
    abstract magicDragPolygon(): PolygonObjectConcrete;
    abstract magicDragVertices(): string[];
    abstract performMove(
      vec: Flatten.Vector,
    ): { point: Flatten.Point; uid: string }[]; // return the moved vertices in order
    magicDragVector(
      event: MouseEvent,
      _grabbedObjectCoord: Coord,
      _eventObjectCoord: Coord,
      grabState: any,
      context: CanvasContext,
      _isMultiDrag: boolean,
    ): Flatten.Vector {
      const currWc = context.viewPort.toWorldCoord({
        x: event.clientX,
        y: event.clientY,
      });
      return new Flatten.Vector(currWc.x - grabState.x, currWc.y - grabState.y);
    }

    abstract get collisionLayers(): CollisionLayer[];

    abstract getEdgeSpacing(): number;

    layersCollide(layers: CollisionLayer[]): boolean {
      return this.collisionLayers.some((layer) => layers.includes(layer));
    }

    onDragStart(
      event: MouseEvent,
      _objectCoord: Coord,
      context: CanvasContext,
      _isMultiDrag: boolean,
    ): any {
      this.thisPolygon = this.magicDragPolygon();
      this.initialOverlap = this.thisPolygon?.invalidOverlappingAreaM2();
      this.dragVertices = this.magicDragVertices();
      this.globalStore.entitiesInLevel
        .get(this.document.uiState.levelUid)
        ?.forEach((uid) => {
          const obj = this.globalStore.get(uid) as DrawableObjectConcrete;
          if (
            obj.type === EntityType.VERTEX &&
            this.layersCollide(obj.collisionLayers)
          ) {
            if (this.globalStore.getPolygonsByVertex(obj.uid)[0] === this.uid)
              return;
            const c = obj.toWorldCoord();
            this.slowSnapVertices.push({
              vertex: new Flatten.Point(c.x, c.y),
              uid: obj.uid,
            });
          } else if (
            obj.type === EntityType.EDGE &&
            this.layersCollide(obj.collisionLayers)
          ) {
            if (this.globalStore.getPolygonsByEdge(obj.uid)[0] === this.uid)
              return;
            const seg = obj.segment;
            this.slowSnapEdges.push({
              edge: new Flatten.Segment(
                new Flatten.Point(seg.ps.x, seg.ps.y),
                new Flatten.Point(seg.pe.x, seg.pe.y),
              ),
              uid: obj.uid,
            });
          }
        });
      return context.viewPort.toWorldCoord({
        x: event.offsetX,
        y: event.offsetY,
      });
    }
    onDrag(
      event: MouseEvent,
      grabbedObjectCoord: Coord,
      eventObjectCoord: Coord,
      grabState: any,
      context: CanvasContext,
      isMultiDrag: boolean,
      fastSearch: boolean = true,
    ): void {
      if (context.$store.getters["document/isViewOnly"] || isMultiDrag) {
        return;
      }
      if (!this.thisPolygon) return;
      const objs = this.thisPolygon.collectVerticesInOrder().filter((obj) => {
        return this.dragVertices.includes(obj.uid);
      });
      const mouseVec = this.magicDragVector(
        event,
        grabbedObjectCoord,
        eventObjectCoord,
        grabState,
        context,
        isMultiDrag,
      );
      let minX = 1e7,
        minY = 1e7,
        maxX = -1e7,
        maxY = -1e7;
      const moved = this.performMove(mouseVec);
      const polygon = new Flatten.Polygon();
      polygon.addFace(moved.map((p) => p.point));
      const posMap = new Map<string, Flatten.Point>();
      moved.forEach((item) => {
        posMap.set(item.uid, item.point);
      });
      const vertices = objs.map((obj) => {
        return { vertex: posMap.get(obj.uid)!, uid: obj.uid };
      });
      const edges = this.thisPolygon
        .collectEdgesInOrder()
        .filter((edge) => {
          return (
            this.dragVertices.includes(edge.entity.endpointUid[0]) ||
            this.dragVertices.includes(edge.entity.endpointUid[1])
          );
        })
        .map((edge) => {
          return new Flatten.Segment(
            posMap.get(edge.entity.endpointUid[0])!,
            posMap.get(edge.entity.endpointUid[1])!,
          );
        });
      edges.forEach((edge) => {
        minX = Math.min(minX, edge.ps.x, edge.pe.x);
        minY = Math.min(minY, edge.ps.y, edge.pe.y);
        maxX = Math.max(maxX, edge.ps.x, edge.pe.x);
        maxY = Math.max(maxY, edge.ps.y, edge.pe.y);
      });
      const EDGE_SPACING = this.getEdgeSpacing();
      const SNAP_THRESHOLD = EDGE_SPACING * 2 + 100;
      const snapVertices = fastSearch
        ? this.globalStore.spatialIndex
            .get(this.document.uiState.levelUid!)
            ?.search({
              minX: minX - SNAP_THRESHOLD,
              minY: minY - SNAP_THRESHOLD,
              maxX: maxX + SNAP_THRESHOLD,
              maxY: maxY + SNAP_THRESHOLD,
            })
            .map((item) => this.globalStore.get(item.uid) as DrawableVertex)
            .filter((obj) => {
              return (
                obj.type === EntityType.VERTEX &&
                this.layersCollide(obj.collisionLayers) &&
                this.globalStore.getPolygonsByVertex(obj.uid)[0] !==
                  this.thisPolygon!.uid
              );
            })
            .map((obj) => {
              const wc = obj.toWorldCoord();
              return {
                vertex: new Flatten.Point(wc.x, wc.y),
                uid: obj.uid,
              };
            })!
        : this.slowSnapVertices;
      const snapEdges = fastSearch
        ? this.globalStore.spatialIndex
            .get(this.document.uiState.levelUid!)
            ?.search({
              minX: minX - SNAP_THRESHOLD,
              minY: minY - SNAP_THRESHOLD,
              maxX: maxX + SNAP_THRESHOLD,
              maxY: maxY + SNAP_THRESHOLD,
            })
            .map((item) => this.globalStore.get(item.uid) as DrawableEdge)
            .filter((obj) => {
              return (
                obj.type === EntityType.EDGE &&
                this.layersCollide(obj.collisionLayers) &&
                this.globalStore.getPolygonsByEdge(obj.uid)[0] !==
                  this.thisPolygon!.uid
              );
            })
            .map((obj) => ({ edge: obj.segment, uid: obj.uid }))!
        : this.slowSnapEdges;

      let vec = new Flatten.Vector(0, 0);
      const overlap =
        this.thisPolygon.invalidOverlappingAreaM2(moved) + EPS >
        this.initialOverlap!;
      let mindis = overlap ? 1e7 : SNAP_THRESHOLD;
      // snapping
      if (!event.shiftKey || overlap) {
        // vertex -- vertex
        vertices.forEach(({ vertex, uid }) => {
          snapVertices.forEach(({ vertex: snapVertex, uid: snapVertexUid }) => {
            let newVec = new Flatten.Vector(
              snapVertex.x - vertex.x,
              snapVertex.y - vertex.y,
            );
            const dis = newVec.length;
            if (dis < mindis) {
              if (!event.shiftKey) {
                const snapEdgeEndpoints = this.globalStore
                  .getConnections(snapVertexUid)
                  .map((edgeUid) => {
                    const edge = this.globalStore.get(edgeUid) as DrawableEdge;
                    if (edge.entity.endpointUid[0] === snapVertexUid) {
                      return {
                        point: (
                          this.globalStore.get(
                            edge.entity.endpointUid[1],
                          ) as DrawableVertex
                        ).toWorldCoord(),
                        order: 1,
                      };
                    } else {
                      return {
                        point: (
                          this.globalStore.get(
                            edge.entity.endpointUid[0],
                          ) as DrawableVertex
                        ).toWorldCoord(),
                        order: 0,
                      };
                    }
                  })
                  .sort((a, b) => a.order - b.order)
                  .map((item) => item.point);
                let snapVec = [
                  Flatten.vector(
                    Flatten.point(
                      snapEdgeEndpoints[0].x,
                      snapEdgeEndpoints[0].y,
                    ),
                    snapVertex,
                  ),
                  Flatten.vector(
                    snapVertex,
                    Flatten.point(
                      snapEdgeEndpoints[1].x,
                      snapEdgeEndpoints[1].y,
                    ),
                  ),
                ]
                  .map((vec) => vec.normalize())
                  .reduce((a, b) => a.add(b).normalize());
                const edgeEndpoints = this.globalStore
                  .getConnections(uid)
                  .map((edgeUid) => {
                    const edge = this.globalStore.get(edgeUid) as DrawableEdge;
                    if (edge.entity.endpointUid[0] === uid) {
                      return {
                        point: posMap.get(edge.entity.endpointUid[1])!,
                        order: 1,
                      };
                    } else {
                      return {
                        point: posMap.get(edge.entity.endpointUid[0])!,
                        order: 0,
                      };
                    }
                  })
                  .sort((a, b) => a.order - b.order)
                  .map((item) => item.point);
                let vervec = [
                  Flatten.vector(
                    Flatten.point(edgeEndpoints[0].x, edgeEndpoints[0].y),
                    snapVertex,
                  ),
                  Flatten.vector(
                    snapVertex,
                    Flatten.point(edgeEndpoints[1].x, edgeEndpoints[1].y),
                  ),
                ]
                  .map((vec) => vec.normalize())
                  .reduce((a, b) => a.add(b).normalize());
                let lsver = {
                  x:
                    snapVertex.x +
                    snapVec.rotate90CCW().multiply(EDGE_SPACING / 3 + 1).x,
                  y:
                    snapVertex.y +
                    snapVec.rotate90CCW().multiply(EDGE_SPACING / 3 + 1).y,
                };
                if (
                  (
                    this.globalStore.getPolygonObjectOrThrow(
                      this.globalStore.getPolygonsByVertex(snapVertexUid)[0]!,
                    ) as PolygonObjectConcrete
                  ).inBoundsWorld(lsver)
                ) {
                  snapVec = snapVec.rotate90CW();
                } else {
                  snapVec = snapVec.rotate90CCW();
                }

                lsver = (
                  this.globalStore.get(uid) as DrawableObjectConcrete
                ).toWorldCoord();
                lsver = {
                  x:
                    lsver.x +
                    vervec.rotate90CCW().multiply(EDGE_SPACING / 3 + 1).x,
                  y:
                    lsver.y +
                    vervec.rotate90CCW().multiply(EDGE_SPACING / 3 + 1).y,
                };
                if (this.thisPolygon!.inBoundsWorld(lsver)) {
                  vervec = vervec.rotate90CCW();
                } else vervec = vervec.rotate90CW();
                const nvervec = vervec
                  .add(snapVec)
                  .normalize()
                  .multiply(EDGE_SPACING);
                newVec = newVec.add(nvervec);
              }
              if (
                this.thisPolygon!.invalidOverlappingAreaM2(
                  this.performMove(newVec.add(mouseVec)),
                ) <=
                this.initialOverlap! + EPS
              ) {
                mindis = dis;
                vec = newVec;
              }
            }
          });
        });
        // edge -- vertex
        if (mindis >= SNAP_THRESHOLD || overlap) {
          edges.forEach((edge) => {
            snapVertices.forEach(({ vertex }) => {
              const dis = vertex.distanceTo(edge);

              const point = dis[1].pe.equalTo(vertex) ? dis[1].ps : dis[1].pe;
              if (dis[0] < mindis) {
                const newVec = new Flatten.Vector(
                  vertex.x - point.x,
                  vertex.y - point.y,
                );
                let dirVec = Flatten.vector(0, 0);
                if (!event.shiftKey) {
                  dirVec = new Flatten.Vector(edge.ps, edge.pe)
                    .normalize()
                    .multiply(EDGE_SPACING);
                }
                const lsver = {
                  x:
                    (edge.ps.x + edge.pe.x) / 2 +
                    dirVec.rotate90CCW().multiply(EDGE_SPACING / 3 + 1).x,
                  y:
                    (edge.ps.y + edge.pe.y) / 2 +
                    dirVec.rotate90CCW().multiply(EDGE_SPACING / 3 + 1).y,
                };
                if (this.thisPolygon?.inBoundsWorld(lsver, 0, polygon)) {
                  dirVec = dirVec.rotate90CCW();
                } else dirVec = dirVec.rotate90CW();
                if (
                  this.thisPolygon!.invalidOverlappingAreaM2(
                    this.performMove(newVec.add(mouseVec).add(dirVec)),
                  ) <=
                  this.initialOverlap! + EPS
                ) {
                  vec = newVec.add(dirVec);
                  mindis = dis[0];
                }
              }
            });
          });
          // vertex -- edge
          snapEdges.forEach(({ edge, uid: edgeUid }) => {
            vertices.forEach(({ vertex }) => {
              const dis = vertex.distanceTo(edge);
              const point = dis[1].pe.equalTo(vertex) ? dis[1].ps : dis[1].pe;
              if (dis[0] < mindis) {
                const newVec = new Flatten.Vector(
                  point.x - vertex.x,
                  point.y - vertex.y,
                );
                let dirVec = Flatten.vector(0, 0);
                if (!event.shiftKey) {
                  dirVec = new Flatten.Vector(edge.ps, edge.pe)
                    .normalize()
                    .multiply(EDGE_SPACING);
                }
                const lsver = {
                  x:
                    (edge.ps.x + edge.pe.x) / 2 +
                    dirVec.rotate90CW().multiply(EDGE_SPACING / 3 + 1).x,
                  y:
                    (edge.ps.y + edge.pe.y) / 2 +
                    dirVec.rotate90CW().multiply(EDGE_SPACING / 3 + 1).y,
                };
                if (
                  (
                    this.globalStore.getPolygonObjectOrThrow(
                      this.globalStore.getPolygonsByEdge(edgeUid)[0],
                    ) as PolygonObjectConcrete
                  )?.inBoundsWorld(lsver)
                ) {
                  dirVec = dirVec.rotate90CCW();
                } else dirVec = dirVec.rotate90CW();
                if (
                  this.thisPolygon!.invalidOverlappingAreaM2(
                    this.performMove(newVec.add(mouseVec).add(dirVec)),
                  ) <=
                  this.initialOverlap! + EPS
                ) {
                  vec = newVec.add(dirVec);
                  mindis = dis[0];
                }
              }
            });
          });
        }
      }
      if (
        fastSearch &&
        this.thisPolygon!.invalidOverlappingAreaM2(
          this.performMove(vec.add(mouseVec)),
        ) >
          this.initialOverlap! + EPS
      ) {
        return this.onDrag(
          event,
          grabbedObjectCoord,
          eventObjectCoord,
          grabState,
          context,
          isMultiDrag,
          false,
        );
      }
      // TODO not working
      objs.forEach((obj) => {
        const wc = obj.toWorldCoord();
        wc.x += vec.x + mouseVec.x;
        wc.y += vec.y + mouseVec.y;
        obj.entity.parentUid = null;
        obj.entity.center.x = wc.x;
        obj.entity.center.y = wc.y;
      });

      if (this.thisPolygon.type === EntityType.ROOM) {
        this.thisPolygon.entity.virtualCenter.x += vec.x + mouseVec.x;
        this.thisPolygon.entity.virtualCenter.y += vec.y + mouseVec.y;
      }
      context.scheduleDraw();
    }
    onDragFinish(
      event: MouseEvent,
      _context: CanvasContext,
      _isMultiDrag: boolean,
    ): void {
      this.thisPolygon = null;
      this.dragVertices = [];
      this.slowSnapVertices = [];
      this.slowSnapEdges = [];
      this.onInteractionComplete(event);
      MainEventBus.$emit("validate-and-commit");
    }
  }

  return Generated;
}
