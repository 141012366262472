import { Units, UnitsContext } from "../../../lib/measurements";
import { cloneSimple } from "../../../lib/utils";
import { CoreContext } from "../../calculations/types";
import { getTooltip } from "../../tooltips/tooltips";
import {
  FieldType,
  PropertyField,
  withPropertyTracking,
} from "./property-field";
import { DrawableEntity, PositionedEntity } from "./simple-entities";
import { EntityType } from "./types";

export default interface GasApplianceEntity
  extends DrawableEntity,
    PositionedEntity {
  type: EntityType.GAS_APPLIANCE;
  name: string;
  abbreviation: string;
  inletUid: string;

  outletAboveFloorM: number;
  inletPressureKPA: number | null;
  widthMM: number;
  heightMM: number;
  flowRateMJH: number | null;
  diversity: number | null;
}

export function makeGasApplianceFields(
  context: CoreContext,
  entity: GasApplianceEntity,
): PropertyField[] {
  const res: PropertyField[] = [
    {
      property: "name",
      title: "Name",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Text,
      params: null,
      multiFieldId: "name",
    },
    {
      property: "abbreviation",
      title: "Abbreviation",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Text,
      params: null,
      multiFieldId: "abbreviation",
    },
    {
      property: "rotation",
      title: "Rotation: (Degrees)",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Rotation,
      params: {
        step: 45,
        disableFreeInput: false,
      },
      multiFieldId: null,
    },
    {
      property: "outletAboveFloorM",
      title: "Height Above Floor",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Number,
      params: { min: null, max: null },
      multiFieldId: "outletAboveFloorM",
      units: Units.Meters,
    },
    {
      property: "inletPressureKPA",
      title: "Inlet Pressure",
      hint: "The default value may have been determined by an upstream gas pressure regulator.",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: "inletPressureKPA",
      units: Units.GasKiloPascals,
      unitContext: UnitsContext.GAS_ENERGY_MEASUREMENT,
    },
    {
      property: "widthMM",
      title: "Width",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: "widthMM",
      units: Units.Millimeters,
    },
    {
      property: "heightMM",
      title: "Height",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: "heightMM",
      units: Units.Millimeters,
    },
    {
      property: "flowRateMJH",
      title: "Flow Rate",
      hasDefault: false,
      isCalculated: false,
      requiresInput: true,
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: "flowRateMJH",
      units: Units.MegajoulesPerHour,
      unitContext: UnitsContext.GAS_ENERGY_MEASUREMENT,
    },
    {
      property: "diversity",
      title: "Diversity",
      hint: getTooltip("GasAppliance", "Diversity"),
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: "diversity",
      units: Units.Percent,
    },
  ];

  return res.map(withPropertyTracking(context, entity));
}

// These are calculated values required by the gas appliance.
// TODO: SEED-167 make the changes persist across sessions and users
// TODO: SEED-112 These calculation results should be available live-updated and processed in the background
export interface GasApplianceDrawingModeCalculations {
  gasPressureKPA: number | null;
}

export function fillGasApplianceFields(
  context: CoreContext,
  entity: GasApplianceEntity,
): GasApplianceEntity {
  const { globalStore } = context;
  const calculations = globalStore.getCalculation(entity);

  // TODO: remove this dependency thing and use calculation DFS to propagate things downstream.
  const result = cloneSimple(entity);

  if (result.inletPressureKPA === null) {
    result.inletPressureKPA = calculations?.gasPressureKPA ?? 0;
  }

  if (result.diversity === null) {
    result.diversity = 100;
  }

  return result;
}
