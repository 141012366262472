import * as TM from "transformation-matrix";
import { CenteredEntityConcrete } from "../../document/entities/concrete-entity";
import CoreBaseBackedObject from "../lib/coreBaseBackedObject";
import { GuessEntity } from "../lib/types";

export default function CoreCentered<
  T extends abstract new (...args: any[]) => CoreBaseBackedObject<I>,
  I extends CenteredEntityConcrete = GuessEntity<T>,
>(Base: T) {
  abstract class Generated extends Base {
    getHash(): string {
      const key = this.entity.center.x + " " + this.entity.center.y;
      // const hash = createHash("sha256");
      // hash.update(key.toString());
      // return hash.digest("hex");
      return key;
    }

    get position(): TM.Matrix {
      const scale = 1 / this.fromParentToWorldLength(1);
      if ("rotation" in this.entity) {
        return TM.transform(
          TM.translate(this.entity.center.x, this.entity.center.y),
          TM.rotateDEG(this.entity.rotation),
          TM.scale(scale, scale),
        );
      } else {
        return TM.transform(
          TM.translate(this.entity.center.x, this.entity.center.y),
          TM.scale(scale, scale),
        );
      }
    }
  }

  return Generated;
}
