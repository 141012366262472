import * as TM from "transformation-matrix";
import { isGas } from "../../../../common/src/api/config";
import CoreGasAppliance from "../../../../common/src/api/coreObjects/coreGasAppliance";
import { CalculationData } from "../../../../common/src/api/document/calculations-objects/calculation-field";
import BigValveEntity from "../../../../common/src/api/document/entities/big-valve/big-valve-entity";
import { DrawableEntityConcrete } from "../../../../common/src/api/document/entities/concrete-entity";
import GasApplianceEntity from "../../../../common/src/api/document/entities/gas-appliance";
import { EntityType } from "../../../../common/src/api/document/entities/types";
import { Color } from "../../../../common/src/lib/color";
import { Coord } from "../../../../common/src/lib/coord";
import { DEFAULT_FONT_NAME } from "../../config";
import { rgb2style } from "../../lib/utils";
import {
  getCoolDragCorrelations,
  MoveIntent,
} from "../lib/black-magic/cool-drag";
import CanvasContext from "../lib/canvas-context";
import { EntityDrawingArgs } from "../lib/drawable-object";
import { EntityPopupContent } from "../lib/entity-popups/types";
import { Interaction, InteractionType } from "../lib/interaction";
import { AttachableObject } from "../lib/object-traits/attachable-object";
import { CalculatedObject } from "../lib/object-traits/calculated-object";
import { FIELD_HEIGHT } from "../lib/object-traits/calculated-object-const";
import { CenteredObject } from "../lib/object-traits/centered-object";
import CoolDraggableObject from "../lib/object-traits/cool-draggable-object";
import { Core2Drawable } from "../lib/object-traits/core2drawable";
import {
  HoverableObject,
  HoverSiblingResult,
} from "../lib/object-traits/hoverable-object";
import { SelectableObject } from "../lib/object-traits/selectable";
import {
  getBlockLikeSnapLocations,
  SnapIntention,
  SnappableObject,
  SnapTarget,
} from "../lib/object-traits/snappable-object";
import { DrawingContext, ObjectConstructArgs } from "../lib/types";
import { getHighlightColor } from "../lib/utils";
import { DrawableObjectConcrete } from "./concrete-object";
import DrawableSystemNode from "./drawableSystemNode";
import { applyHoverEffects, isLayoutActive } from "./utils";

const Base = CalculatedObject(
  SelectableObject(
    AttachableObject(
      CoolDraggableObject(
        HoverableObject(
          CenteredObject(SnappableObject(Core2Drawable(CoreGasAppliance))),
        ),
      ),
    ),
  ),
);

export default class DrawableGasAppliance extends Base {
  // We could not add this to the base drawable class because
  // of some typescript thing so they have to be added at the concrete class.
  constructor(args: ObjectConstructArgs<GasApplianceEntity>) {
    super(args.context, args.obj);
    this.onSelect = args.onSelect;
    this.onInteractionComplete = args.onInteractionComplete;
    this.document = args.document;
  }

  getSnapTargets(request: SnapIntention[], mouseWc: Coord): SnapTarget[] {
    return getBlockLikeSnapLocations(
      request,
      mouseWc,
      this.entity.inletUid
        ? [
            this.globalStore.getObjectOfTypeOrThrow(
              EntityType.SYSTEM_NODE,
              this.entity.inletUid,
            ),
          ]
        : [],
      this.toWorldCoord(),
      this.toWorldAngleDeg(0) * (Math.PI / 180),
    );
  }

  locateCalculationBoxWorld(
    context: DrawingContext,
    data: CalculationData[],
    scale: number,
  ): TM.Matrix[] {
    const angle = (this.toWorldAngleDeg(0) / 180) * Math.PI;
    const height = data.length * FIELD_HEIGHT;
    const wc = this.toWorldCoord();

    return [
      0,
      Math.PI / 4,
      -Math.PI / 4,
      Math.PI / 2,
      -Math.PI / 2,
      (Math.PI * 3) / 4,
      (-Math.PI * 3) / 4,
      Math.PI,
    ]
      .map((delta) => {
        return [
          TM.transform(
            TM.identity(),
            TM.translate(wc.x, wc.y),
            TM.rotate(angle + Math.PI + delta),
            TM.translate(0, -this.entity.widthMM),
            TM.scale(scale),
            TM.translate(0, -height / 2),
            TM.rotate(-angle - Math.PI - delta),
          ),
          TM.transform(
            TM.identity(),
            TM.translate(wc.x, wc.y),
            TM.rotate(angle + Math.PI + delta),
            TM.translate(0, -this.entity.widthMM),
            TM.scale(scale),
            TM.translate(0, -height / 2),
            TM.rotate(-angle - Math.PI - delta),
          ),
        ];
      })
      .flat();
  }

  isActive(): boolean {
    return isLayoutActive(this.context, this.document.uiState, "pressure");
  }

  drawEntity(
    context: DrawingContext,
    { selected, hasOverriddenField }: EntityDrawingArgs,
  ): void {
    applyHoverEffects(context, this);
    const scale = context.vp.currToSurfaceScale(context.ctx);
    const ww = Math.max(10 / this.toWorldLength(1), 1 / scale);

    const { ctx } = context;
    ctx.lineWidth = ww;

    const xm1 = -this.entity.widthMM / 2 + (this.entity.widthMM * -0) / 8;
    const x3 = -this.entity.widthMM / 2 + (this.entity.widthMM * 3) / 8;
    const x4 = -this.entity.widthMM / 2 + (this.entity.widthMM * 4) / 8;
    const x5 = -this.entity.widthMM / 2 + (this.entity.widthMM * 5) / 8;
    const x9 = -this.entity.widthMM / 2 + (this.entity.widthMM * 8) / 8;

    const ym1 = 0 + (this.entity.heightMM * -1) / 8;
    const y0 = 0;
    const y1 = 0 + (this.entity.heightMM * 1) / 8;
    const y3 = 0 + (this.entity.heightMM * 3) / 8;
    const y7 = 0 + (this.entity.heightMM * 7) / 8;

    ctx.fillStyle = "rgba(255, 255, 230, 0.8)";
    ctx.strokeStyle = "#000";
    ctx.beginPath();
    ctx.fillRect(xm1, ym1, x9 - xm1, y7 - ym1);
    ctx.rect(xm1, ym1, x9 - xm1, y7 - ym1);
    ctx.stroke();

    if (selected || hasOverriddenField) {
      ctx.fillStyle = rgb2style(
        getHighlightColor(selected, hasOverriddenField ? [Color.YELLOW] : [], {
          hex: "#A0C8A0",
        }),
        0.6,
      );
      ctx.fillRect(xm1, ym1, x9 - xm1, y7 - ym1);
    }

    ctx.strokeStyle = "#888800";

    ctx.beginPath();
    // single
    ctx.moveTo(x4, y0);
    ctx.lineTo(x4, y3);
    ctx.moveTo(x3, y1);
    ctx.lineTo(x5, y1);

    ctx.stroke();

    this.withWorldAngle(
      context,
      { x: 0, y: this.entity.heightMM * 0.6 },
      () => {
        ctx.font = this.entity.widthMM / 4 + "pt " + DEFAULT_FONT_NAME;
        const abbreviation = this.entity.abbreviation
          ? this.entity.abbreviation
          : "";
        const bounds = ctx.measureText(abbreviation);
        const left = -bounds.width / 2;
        ctx.fillStyle = "#000";
        ctx.fillTextStable(abbreviation, left, this.entity.heightMM * 0.15);
      },
    );
  }

  refreshObjectInternal(_obj: BigValveEntity): void {
    //
  }

  inBounds(objectCoord: Coord) {
    if (
      objectCoord.x >= -this.entity.widthMM * (2 / 3) &&
      objectCoord.x <= this.entity.widthMM * (2 / 3)
    ) {
      if (
        objectCoord.y >= this.entity.heightMM * (-1 / 4) &&
        objectCoord.y <= this.entity.heightMM
      ) {
        return true;
      }
    }
    return false;
  }

  prepareDelete(
    context: CanvasContext,
    _calleeEntityUid?: string,
  ): DrawableObjectConcrete[] {
    const result: DrawableObjectConcrete[] = [];
    result.push(
      ...(this.drawableStore
        .get(this.entity.inletUid)
        ?.prepareDelete(context) ?? []),
    );
    result.push(this);

    return result.filter(Boolean);
  }

  offerJoiningInteraction(
    systemUid: string | undefined,
    interaction: Interaction,
  ) {
    if (
      !systemUid ||
      isGas(this.document.drawing.metadata.flowSystems[systemUid])
    ) {
      const obj = this.drawableStore.get(this.entity.inletUid);
      if (obj && obj.offerInteraction(interaction)) {
        return [obj.entity, this.entity];
      }
    }
    return null;
  }

  offerInteraction(interaction: Interaction): DrawableEntityConcrete[] | null {
    switch (interaction.type) {
      case InteractionType.CONTINUING_CONDUIT:
      case InteractionType.STARTING_CONDUIT:
        return this.offerJoiningInteraction(
          interaction.system?.uid,
          interaction,
        );
      case InteractionType.SNAP_ONTO_RECEIVE:
        if (interaction.src.type === EntityType.FITTING) {
          return this.offerJoiningInteraction(
            interaction.src.systemUid,
            interaction,
          );
        }
        return null;
      case InteractionType.INSERT:
      case InteractionType.SNAP_ONTO_SEND:
      case InteractionType.EXTEND_NETWORK:
        return null;
      case InteractionType.LINK_ENTITY:
        return [this.entity];
    }
  }

  getCoolDragCorrelations(
    myMove: MoveIntent,
    _from?: DrawableObjectConcrete | undefined,
  ): { object: DrawableObjectConcrete; move: MoveIntent }[] {
    return getCoolDragCorrelations({
      globalStore: this.globalStore,
      systemNodes: [
        this.globalStore.get(this.entity.inletUid) as DrawableSystemNode,
      ],
      self: this,
      includedDistanceMM: 300,
      includedShape: this.shape || undefined,
      selfMove: myMove,
    });
  }

  getCopiedObjects(): DrawableObjectConcrete[] {
    const res: DrawableObjectConcrete[] = [this];

    res.push(
      this.globalStore.get(this.entity.inletUid)! as DrawableObjectConcrete,
    );

    return res;
  }

  onUpdate() {
    super.onUpdate();
  }

  getPopupContent(): EntityPopupContent[] {
    return [];
  }

  getHoverSiblings(): HoverSiblingResult[] {
    return [];
  }

  getAttachCoords(): [Coord, Coord, Coord, Coord] {
    return [
      // left
      {
        x: -this.entity.widthMM / 2 - this.attachmentOffset,
        y: (this.entity.heightMM * 3) / 8,
      },
      // right
      {
        x: this.entity.widthMM / 2 + this.attachmentOffset,
        y: (this.entity.heightMM * 3) / 8,
      },
      // top
      { x: 0, y: -(this.entity.heightMM * 1) / 8 - this.attachmentOffset },
      // bottom
      { x: 0, y: (this.entity.heightMM * 7) / 8 + this.attachmentOffset },
    ];
  }
}
